import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const manufacturerService = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/manufacturers`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/manufacturers/${id}`,
      method: "get",
    });

    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/manufacturers`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/manufacturers/${id}`,
      method: "patch",
      data: request,
    });

    return apiRequest(options);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/manufacturers/${id}`,
      method: "delete",
    });
    const toastMessageConfig = {
      success: {
        message: "Manufacturer deleted successfully",
      },
    };

    return apiRequest(options, toastMessageConfig);
  },
};
