import React from "react";
import { miStyles } from "@miview/theme";
import { MiButton } from "@miview/components";
import { THEME } from "./theme";

const MiFilterTray = (props) => {
  const {
    toggle,
    open,
    clearFilters,
    filtersSet,
    filterAction,
    style,
    children,
    hideFilterButton,
    ...rest
  } = props;
  const { classes } = miStyles();

  return (
    <div
      className={[classes.miFilterTray, open ? classes.trayOpen : ""].join(" ")}
      {...rest}
      style={{ ...style }}
    >
      <div className={classes.miFilterTrayInner} style={{ ...styles.tray }}>
        <div
          className={classes.miFilterTrayHeader}
          style={{ ...THEME.TEXT_SECTION_TITLE_MED, ...styles.header }}
        >
          <i
            className="material-icons"
            onClick={toggle}
            style={{ ...styles.closeButton }}
          >
            close
          </i>
          Filters
        </div>
        <div className={classes.miFilterTrayBody} style={{ ...styles.body }}>
          {children}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexFlow: "wrap",
              justifyContent: "space-between",
            }}
          >
            {filterAction && !hideFilterButton ? (
              <MiButton
                title={"Filter Data"}
                inverse={filtersSet}
                color={THEME.BLUE_PRIMARY}
                style={{ marginTop: "2rem" }}
                onClick={() => filterAction({}, true)}
              />
            ) : (
              ""
            )}
            <MiButton
              title={"Clear Filters"}
              inverse={filtersSet}
              color={THEME.BLUE_PRIMARY}
              style={{ marginTop: "2rem" }}
              onClick={clearFilters}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiFilterTray;

const styles = {
  tray: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: 280,
    overflowX: "hidden",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: THEME.GREY_LIGHT,
    borderRadius: "0.25rem",
    padding: ".5rem",
    marginTop: "1rem",
    marginRight: "1rem",
    backgroundColor: "white",
  },
  header: {
    color: THEME.GREY_MEDIUM,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: THEME.GREY_LIGHT,
    padding: ".25rem .5rem",
    display: "flex",
    alignItems: "center",
  },
  closeButton: {
    color: THEME.GREY_MEDIUM,
    userSelect: "none",
    cursor: "pointer",
  },
  body: {
    padding: ".5rem",
  },
};
