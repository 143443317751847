import React from "react";
import Input from "@mui/material/Input";
import {
  FormControl as MuiFormControl,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  Checkbox,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import { THEME } from "@miview/theme";
import { styled } from "@mui/system";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "250",
    },
  },
};

export const MiMultipleSelect = ({
  options = [],
  onChange,
  value = [],
  readOnly = false,
  clickButton,
  label = "",
  ...rest
}) => {
  const handleChange = (event) => {
    onChange && onChange(event.target.value);
  };

  const getSelectedLabels = (selectedValues) => {
    return options
      .filter((item) => selectedValues.includes(item.value))
      .map((item) => item.text);
  };

  const FormControl = styled(MuiFormControl)({
    width: "100%",
    border: `1px solid ${THEME.GREY_LIGHT}`,
    borderRadius: "3px",
  });

  const Select = styled(MuiSelect)({
    ".MuiSelect-select": {
      display: "flex",
      flexWrap: "wrap",
    },
  });

  const MenuItem = styled(MuiMenuItem)({
    height: 30,
  });

  const getSelectedChips = (selectedValues) =>
    getSelectedLabels(selectedValues).map((chipValue) => (
      <Chip
        id={`option-${chipValue}-chip`}
        key={chipValue}
        label={chipValue}
        size={"small"}
        style={{ margin: "2px 2px" }}
      />
    ));

  return (
    <div
      onClick={() => {
        if (readOnly && clickButton) {
          clickButton();
        }
      }}
      style={{ maxWidth: "100%" }}
    >
      <div
        style={{
          fontSize: ".8rem",
          color: THEME.GREY_MEDIUM,
          marginBottom: 2,
          marginLeft: 0,
        }}
      >
        {label}
      </div>
      <FormControl>
        <Select
          labelId="demo-multiple-checkbox-label"
          multiple
          value={value}
          onChange={handleChange}
          input={<Input />}
          renderValue={getSelectedChips}
          MenuProps={MenuProps}
          disabled={readOnly}
          {...rest}
        >
          {options.map(({ text, value: option }) => {
            return (
              <MenuItem id={`option-${text}`} key={option} value={option}>
                <Checkbox
                  id={`option-${text}-checkbox`}
                  checked={value.includes(option)}
                />
                <ListItemText secondary={text} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
