import React from "react";
import {
  MiButton,
  MiTable,
  MiInputTextbox,
  MiInputSelectBox,
} from "@miview/components";
import { Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  mdiInboxArrowDown,
  mdiNotePlusOutline,
  mdiNoteTextOutline,
} from "@mdi/js";
import { ButtonIconText } from "../../components";
import { en } from "@miview/constants";

const useStyles = makeStyles()((theme, _params, classes) => ({
  fieldBorder: {
    [`&.${classes.disabled} .${classes.notchedOutline}`]: {
      borderColor: theme.palette.secondary.red,
    },
  },
  root: {},
  disabled: {},
  notchedOutline: {},
  input: {
    textAlign: "center",
    padding: 10.5,
  },
  typography: {
    fontWeight: theme.fontWeight.medium,
    padding: 0,
    color: theme.palette.primary.blue,
  },
}));

const ReceivingPOFormTable = ({
  handleNotesChange,
  data,
  handleReceiveOrderChange,
  handleActionChange,
  handleReceiveAll,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();

  const columns = [
    {
      field: "itemName",
      headerName: en.item,
      sortable: false,
      flex: 1.5,
      renderCell: ({ value }) => {
        return <Typography className={classes.typography}>{value}</Typography>;
      },
    },
    {
      field: "description",
      headerName: en.description,
      sortable: false,
      flex: 3,
    },
    {
      field: "quantityOrdered",
      headerName: en.ordered,
      sortable: false,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <MiInputTextbox
            minWidth={60}
            value={value}
            disabled={true}
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
          />
        );
      },
    },
    {
      field: "quantityReceived",
      headerName: en.received,
      sortable: false,
      flex: 1,
      renderCell: ({ row, value }) => {
        return (
          <MiInputTextbox
            minWidth={60}
            value={value || "0"}
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            handleChange={(e) => handleReceiveOrderChange(row, e.target.value)}
          />
        );
      },
    },
    {
      field: "difference",
      headerName: en.difference,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        const diff = row.quantityOrdered - row.quantityReceived || "-";
        return (
          <MiInputTextbox
            minWidth={60}
            value={diff}
            InputProps={{
              classes: {
                input: classes.input,
                root:
                  row.quantityOrdered < row.quantityReceived
                    ? classes.fieldBorder
                    : classes.root,
                disabled: classes.disabled,
                notchedOutline: classes.notchedOutline,
              },
            }}
            disabled={true}
          />
        );
      },
    },
    {
      field: "action",
      headerName: en.actions,
      sortable: false,
      flex: 3,
      renderCell: ({ row }) => {
        const diff = row.quantityOrdered - row.quantityReceived;
        return (
          <MiInputSelectBox
            minWidth={220}
            disabled={!diff}
            disableNone={true}
            menuItems={[
              {
                label: "Backorder",
                value: "Backorder",
              },
              {
                label: "Cancel",
                value: "Cancel",
              },
              {
                label: "New PO",
                value: "NewPO",
              },
            ]}
            value={row.action}
            displayEmpty={true}
            renderValue={(value) => (value ? value : "Select Actions")}
            handleChange={(e) => {
              handleActionChange(row, e);
            }}
            error={row.action === ""}
          />
        );
      },
    },
    {
      field: "notes",
      headerName: en.notes,
      sortable: false,
      flex: 2,
      renderCell: ({ row, value }) => {
        return (
          <ButtonIconText
            text={value || en.enterNote}
            path={value ? mdiNoteTextOutline : mdiNotePlusOutline}
            color={
              value
                ? theme.palette.mediumAccent.blue
                : theme.palette.secondary.grey
            }
            onClick={() => handleNotesChange(row, value)}
          />
        );
      },
    },
    {
      field: "receiveAll",
      flex: 2,
      sortable: false,
      renderHeader: () => {
        return (
          <MiButton
            icon={mdiInboxArrowDown}
            title={en.receiveAll}
            backgroundColor={theme.palette.primary.main}
            color={theme.palette.primary.white}
            onClick={handleReceiveAll}
          />
        );
      },
    },
  ];

  return (
    <MiTable
      columns={columns}
      rows={data}
      hideFooter={true}
      disableColumnMenu={true}
      disableColumnReorder={true}
      autoPageSize={true}
      autoHeight={true}
      sx={{
        "& .MuiDataGrid-cell": {
          padding: "0px 0px 0px 30px !important",
        },
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          padding: "0px 0px 0px 20px !important",
        },
      }}
    />
  );
};

export default ReceivingPOFormTable;
