import React, { useEffect, useState } from "react";
import { MiHeader } from "../../components/Standard/MiView";
import { THEME } from "@miview/theme";
import moment from "moment";
import {
  issueService,
  issueCategoryService,
  issueStatusService,
  issueDescriptionService,
} from "@miview/api";
import { useRouter, useEdit, useComponentState } from "@miview/hooks";
import { toList } from "@miview/utils";
import {
  ImageViewer,
  Image,
  MiLink,
  MiSnackbar,
  MiTab,
  MiCard,
  MiBox,
  MiDetailFields,
} from "@miview/components";
import { HTTP_STATUSES } from "@miview/constants";
import { mdiViewList } from "@mdi/js";

const PunchListDetails = () => {
  const [punchItem, setPunchItem] = useState({});
  const [punchItemStatuses, setPunchItemStatuses] = useState([]);
  const [activeTab, setActiveTab] = useState("Details");
  const [editMode, setEditMode] = useState(false);
  const [issueCategories, setIssueCategories] = useState([]);
  const [issueDescriptions, setIssueDescriptions] = useState([]);
  const [assignablePeople, setAssignablePeople] = useState([]);
  const [punchItemAttachments, setPunchItemAttachments] = useState([]);
  const [activeImage, setActiveImage] = useState();
  const [fullScreenStatus, setFullScreenStatus] = useState(false);

  const router = useRouter();
  const edit = useEdit(punchItem);
  const stateManager = useComponentState();

  const { id } = router.params;

  useEffect(() => {
    getIssue();
    getIssueStatuses();
    getIssueCategories();
    getIssueDescriptions();
  }, []);

  const getIssue = () => {
    stateManager.run(async () => {
      const response = await issueService.get(id);
      if (response.status === HTTP_STATUSES.OK) {
        setPunchItem(response.data);
        setPunchItemAttachments(response.data.imageNames);
        getAssignablePersons(response.data);
      }
    });
  };

  const getIssueStatuses = () => {
    stateManager.run(async () => {
      const response = await issueStatusService.getAll();
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "statusId", "statusName");
        setPunchItemStatuses(mapped);
      }
    });
  };

  const getIssueCategories = () => {
    stateManager.run(async () => {
      const response = await issueCategoryService.getAll();
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "id", "name");
        setIssueCategories(mapped);
      }
    });
  };

  const getIssueDescriptions = () => {
    stateManager.run(async () => {
      const response = await issueDescriptionService.getAll();
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "id", "description");
        setIssueDescriptions(mapped);
      }
    });
  };

  const getAssignablePersons = ({ assigneeId, assigneeName }) => {
    stateManager.run(async () => {
      const response = await issueService.getAssignablePersons(id);
      if (response.status === HTTP_STATUSES.OK) {
        const options = toList(response.data, "userId", "name");

        const assigneeIds = options.map((e) => e.value);
        if (assigneeName !== "" && !assigneeIds.includes(assigneeId)) {
          options.push({
            value: assigneeId,
            text: assigneeName,
          });
        }

        setAssignablePeople(options);
      }
    });
  };

  const cancelPunchDetails = () => {
    edit.reset();
    setEditMode(false);
  };

  const savePunchDetails = () => {
    stateManager.run(async () => {
      const response = await issueService.update(id, edit.edits);
      if (response.status === HTTP_STATUSES.OK) {
        getIssue();
      }
      setEditMode(false);
    });
  };

  const fullscreen = (e) => {
    setActiveImage(e.target.src);
    setFullScreenStatus(true);
  };

  const unFullscreen = () => {
    setFullScreenStatus(false);
  };

  const enableEdit = () => {
    setEditMode(true);
  };

  const renderAttachments = () => {
    return punchItemAttachments.map((i, index) => {
      return (
        <div
          key={index}
          style={{
            height: 220,
            paddingLeft: 5,
            backgroundColor: "white",
            flexShrink: "0",
          }}
        >
          <Image imgSrc={i} onClick={(e) => fullscreen(e)} />
        </div>
      );
    });
  };

  const detailFields = [
    {
      label: "Status",
      fieldType: "select",
      value: edit.getValue("statusId"),
      options: punchItemStatuses,
      setValue: (e) => edit.update({ statusId: e }),
      readOnly: !editMode,
      clickButton: enableEdit,
    },
    {
      label: "Issue Category",
      fieldType: "select",
      value: edit.getValue("issueCategoryId"),
      options: issueCategories,
      setValue: (e) => edit.update({ issueCategoryId: e }),
      readOnly: !editMode,
      clickButton: enableEdit,
    },
    {
      label: "Fail Reason",
      value: edit.getValue("failReason"),
      setValue: (e) => edit.update({ failReason: e }),
      readOnly: !editMode,
      clickButton: enableEdit,
    },
    {
      label: "Description",
      fieldType: "select",
      value: edit.getValue("issueDescriptionId"),
      setValue: (e) => edit.update({ issueDescriptionId: e }),
      options: issueDescriptions,
      readOnly: !editMode,
      clickButton: enableEdit,
    },
    {
      label: "Assigned To",
      fieldType: "select",
      value: edit.getValue("assigneeId"),
      options: assignablePeople,
      setValue: (e) => edit.update({ assigneeId: e }),
      readOnly: !editMode,
      clickButton: enableEdit,
    },
    {
      label: "Abort Walk",
      fieldType: "checkbox",
      value: edit.getValue("abortWalk"),
      setValue: (e) => edit.update({ abortWalk: e }),
      readOnly: !editMode,
      clickButton: enableEdit,
    },
    {
      label: "Hold",
      fieldType: "checkbox",
      value: edit.getValue("hold"),
      setValue: (e) => edit.update({ hold: e }),
      readOnly: !editMode,
      clickButton: enableEdit,
    },
  ];

  return (
    <div className="animated fadeIn" style={{ padding: 0 }}>
      {stateManager.statusTag("issueDetailStatus")}
      <MiHeader
        color={THEME.BLUE_PRIMARY}
        backAction={router.goBack}
        inverse={false}
        icon="arrow_back"
        title={edit.getValue("name")}
        fieldSet={[
          { label: "Assignee", value: edit.getValue("assigneeName") },
          { label: "Status", value: edit.getValue("statusName") },
        ]}
      />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        <MiBox styles={{ width: "70%" }}>
          <div style={tabLayout}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: 120 }}>
                <MiTab
                  tabs={[{ label: "Details", value: "Details" }]}
                  onTabChange={setActiveTab}
                  currenttab={activeTab}
                />
              </div>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            {activeTab === "Details" && (
              <div className="animated fadeIn">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    flex: 1,
                  }}
                >
                  <MiSnackbar
                    visible={editMode}
                    handleCancelClick={cancelPunchDetails}
                    handleSaveClick={savePunchDetails}
                  />
                  <div
                    id="noOverflow"
                    style={{ paddingBottom: "15px", maxWidth: "100%" }}
                  >
                    <div
                      style={{
                        overflowX: "auto",
                        overflowY: "hidden",
                        borderRadius: ".25rem",
                      }}
                    >
                      <div
                        id="ImageWrapper"
                        style={{
                          backgroundColor: "#eaeaea",
                          display: "flex",
                          flexDirection: "row",
                          zIndex: -1,
                        }}
                      >
                        {renderAttachments()}
                      </div>
                    </div>
                  </div>
                  <MiDetailFields
                    detailFields={detailFields}
                    additionalItemDependencies={editMode}
                    headerProps={{
                      icon: "list",
                      title: "Detail Fields",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      margin: "0 10px 12px 0",
                    }}
                  >
                    <div
                      style={{
                        fontSize: ".8rem",
                        color: THEME.GREY_MEDIUM,
                        marginBottom: 2,
                      }}
                    >
                      Reported Walk
                    </div>
                    <MiLink
                      title={edit.getValue("walkName")}
                      to={`/walks/${edit.getValue("walkId")}`}
                      style={{ paddingBottom: 8 }}
                    />
                    <div
                      style={{
                        fontSize: ".8rem",
                        color: THEME.GREY_MEDIUM,
                        marginBottom: 2,
                      }}
                    >
                      Walk Verified On
                    </div>
                    <MiLink
                      title={edit.getValue("walkVerifiedOn")}
                      to={`/walks/${edit.getValue("walkIdVerifiedOn")}`}
                      style={{ paddingBottom: 8 }}
                    ></MiLink>
                  </div>
                </div>
              </div>
            )}
          </div>
        </MiBox>
        <MiBox styles={{ width: "30%" }}>
          <div style={tabLayout}>
            <div style={{ width: 120 }}>
              <MiTab
                tabs={[{ label: "History", value: "History" }]}
                currenttab="History"
              />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ paddingBottom: 7 }}>
              <MiCard
                color={THEME.TANGERINE_ACCENT}
                icon={mdiViewList}
                title={
                  "First Reported: " +
                  moment(edit.getValue("createTime")).format("L")
                }
                fieldset={[
                  {
                    label: "Reported By:",
                    icon: "person",
                    value: edit.getValue("createdBy"),
                  },
                ]}
              />
            </div>
            <div style={{ paddingBottom: 7 }}>
              {edit.getValue("resolvedDate") && (
                <MiCard
                  color={THEME.TANGERINE_ACCENT}
                  icon={mdiViewList}
                  title={
                    "Work Completed On: " +
                    moment(edit.getValue("resolvedDate")).format("L")
                  }
                  fieldset={[
                    {
                      label: "Completed By:",
                      icon: "person",
                      value: edit.getValue("resolvedBy"),
                    },
                  ]}
                />
              )}
            </div>
            <div style={{ paddingBottom: 7 }}>
              {edit.getValue("verifiedDate") && (
                <MiCard
                  color={THEME.TANGERINE_ACCENT}
                  icon={mdiViewList}
                  title={
                    "Work Reviewed On: " +
                    moment(edit.getValue("verifiedDate")).format("L")
                  }
                  fieldset={[
                    {
                      label: "Reviewed By:",
                      icon: "person",
                      value: edit.getValue("reviewedBy"),
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </MiBox>
      </div>
      <ImageViewer
        fullScreenStatus={fullScreenStatus}
        activeImage={activeImage}
        images={punchItemAttachments}
        onUnfullscreen={unFullscreen}
      />
    </div>
  );
};

const tabLayout = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  borderBottomStyle: "solid",
  borderBottomColor: THEME.GREY_LIGHT,
  marginBottom: 20,
  borderBottomWidth: 1,
  alignItems: "flex-end",
  paddingTop: 5,
};

export default PunchListDetails;
