import React from "react";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
const drawerWidth = "210px";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
  },
  hide: {
    visibility: "hidden",
  },
  hideChevronIcon: {
    display: "none",
  },
  ChevronIconVisible: {},
  drawer: {
    width: "5%",
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "45px",
  },
  menuButton: {
    marginLeft: "-5px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    marginTop: "50px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export const MiSideBar = ({ navItems, config }) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [dropDown, setDropDown] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setDropDown(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={cx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: cx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={cx(classes.menuButton, {
              [classes.hide]: open,
            })}
            size="large">
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={handleDrawerClose}
            className={
              open
                ? cx(classes.ChevronIconVisible)
                : cx(classes.hideChevronIcon)
            }
            size="large">
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {navItems.map((text) => {
            return (
              <div key={text.name}>
                <ListItem
                  button
                  style={{
                    backgroundColor: `${
                      text.url === config.history.location.pathname
                        ? "#3bdb9b"
                        : ""
                    }`,
                  }}
                  onClick={
                    text.children
                      ? () => setDropDown(!dropDown)
                      : () => config.history.push(text.url)
                  }
                >
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <div className={text.icon ? text.icon : ""}></div>
                  </ListItemIcon>
                  <ListItemText primary={text.name} />
                </ListItem>
                <div style={{ display: `${dropDown ? "block" : "none"}` }}>
                  {text.children
                    ? text.children.map((child) => (
                        <ListItem
                          button
                          style={{
                            backgroundColor: `${
                              child.url === config.history.location.pathname
                                ? "#3bdb9b"
                                : ""
                            }`,
                            marginLeft: "20px",
                            borderLeft: `${
                              child.url === config.history.location.pathname
                                ? "2px solid #08422a"
                                : "2px solid #c0d1ca"
                            }`,
                          }}
                          key={child.name}
                          onClick={() => config.history.push(child.url)}
                        >
                          <ListItemText primary={child.name} />
                        </ListItem>
                      ))
                    : ""}
                </div>
              </div>
            );
          })}
        </List>
        <Divider />
      </Drawer>
    </div>
  );
};
