export const KEY_CODES = {
  rightArrow: 39,
  downArrow: 40,
  q: 81,
};

export const KEY_MODIFIERS = {
  control: "Control",
  shift: "Shift",
};
