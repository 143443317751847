import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_INSPECTIONS = `${API_V3}/inspections`;

export const inspectionService = {
  getAll: async ({ params, filters, etag, signal, sort }) => {
    const options = await apiOptions({
      url: `${API_INSPECTIONS}`,
      method: "get",
      params,
      filters,
      etag,
      signal,
      sort,
    });

    return apiRequest(options);
  },

  getIssues: async ({ id }) => {
    const options = await apiOptions({
      url: `${API_INSPECTIONS}/${id}/issues`,
      method: "get",
    });
    return apiRequest(options);
  },

  getAssignableUsers: async ({ id }) => {
    const options = await apiOptions({
      url: `${API_INSPECTIONS}/${id}/issues/assignableUsers`,
      method: "get",
    });

    return apiRequest(options);
  },
};
