import React, { useState, useRef, useEffect } from "react";
import {
  ListItemText,
  Grid,
  useTheme,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  MiBox,
  MiDrawer,
  MiInputTextbox,
  MiLoader,
  MiPageHeader,
  MiAlert,
  MiModalConfirm,
  MiIcon,
} from "@miview/components";
import { PoTable, PoCard } from "../../components";
import PurchaseOrderPreview from "./PurchaseOrderPreview";
import {
  mdiCallSplit,
  mdiCardTextOutline,
  mdiFormatListChecks,
  mdiStarCircleOutline,
  mdiFileRemoveOutline,
} from "@mdi/js";
import PurchaseOrder from "./PurchaseOrder";
import CheckInventoryPrint from "./CheckInventoryPrint";
import {
  RequisitionService,
  pricingService,
  accountService,
} from "@miview/api";
import { en, TOAST_TYPES } from "@miview/constants";
import { createToast } from "@miview/toast";
import { useReactToPrint } from "react-to-print";

const useStyles = makeStyles()((theme) => ({
  drawerPaper: {
    width: "100%",
  },
  iconTitle: {
    display: "flex",
    alignItems: "center",
  },
  iconCircle: {
    width: 50,
    height: 50,
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: 20,
  },
  headerStyle: {
    marginBottom: 20,
  },
  asideContainer: {
    minWidth: 352,
  },
  loading: {
    width: "100%",
    position: "relative",
    top: "50%",
    left: "50%",
  },
  checkButton: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.white,
    fontWeight: theme.fontWeight.medium,
    width: 157,
    height: 45,
    textTransform: "none",
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  gridHeight: {
    height: "100%",
  },
  splitHighLight: {
    backgroundColor: theme.palette.light.main,
    borderRadius: 50,
  },
  alert: {
    "& > div > div > div > div": {
      backgroundColor: theme.palette.primary.bluegrey,
      fontWeight: theme.fontWeight.medium,
      fontSize: theme.fontSize.medium,
      marginBottom: 15,
      "& > div svg": {
        color: theme.palette.medium.yellow,
      },
    },
  },
  dialogPaper: {
    width: 351,
  },
  input: {
    textAlign: "center",
    padding: 10.5,
  },
  root: {
    overflow: "hidden",
    borderRadius: 5,
    width: 60,
    height: 49,
    padding: 0,
  },
}));

const UseStylesForTextField = makeStyles()((theme) => {
  return {
    root: {
      borderColor: (props) =>
        props.disabled
          ? theme.palette.lightAccent.grey
          : theme.palette.light.grey,
      borderWidth: 0.5,
      backgroundColor: (props) =>
        props.disabled
          ? theme.palette.mediumLight.grey
          : theme.palette.primary.white,
      borderStyle: "solid",
      overflow: "hidden",
      borderRadius: 5,
      width: 60,
      height: 49,
      padding: 0,
      color: theme.palette.primary.bluegrey,
      fontSize: 13,
    },
    input: {
      textAlign: "center",
      padding: 10.5,
    },
  };
});

const CreatePurchaseOrder = ({
  closePurchaseOrderDrawer,
  reqId,
  selectedItems,
  reqData,
  onSave,
  isOriginalSupplierAlert = false,
  supplierName,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [confirmModal, setConfirmModal] = useState({ open: false });
  const [reviewPoDrawer, setReviewPoDrawer] = useState({ open: false });
  const [requisitionData, setRequisitionData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (reqId) {
      fetchData();
    } else {
      setRequisitionData(reqData);
      setLoading(false);
    }
  }, []);

  const onSplitClicked = (row, flag) => {
    const split = requisitionData.map((req) => {
      let newitem = req.data.map((item) => {
        if (item.id === row.row.id && item.itemid === row.row.itemid) {
          let newacc = item.accounts.map((acc) => {
            let updatedSum = 0;
            let quantity = 0;
            acc.split = flag;
            if (acc.isPreferredVendor && !flag) {
              updatedSum = acc?.currentcost * row?.row?.quantity || 0;
              quantity = row?.row?.quantity;
            }
            return {
              ...acc,
              orderedqty: quantity,
              totalsum: updatedSum,
            };
          });
          return { ...item, isSplitOn: flag, accounts: newacc };
        }
        return { ...item };
      });
      return { ...req, data: newitem };
    });
    setRequisitionData(split);
  };

  const getComputeQuantityAndSum = (
    id,
    flag,
    diff,
    value,
    requisitionItem,
    acc
  ) => {
    let updatedQty = 0;
    let updatedSum = 0;
    if (flag) {
      updatedQty = acc.orderedqty;
      updatedSum = acc.totalsum;
    }
    if (acc.uid === id) {
      updatedQty = flag
        ? (value <= diff && value) || diff + acc.orderedqty
        : requisitionItem.onhand <= requisitionItem.quantity &&
          requisitionItem.quantity - requisitionItem.onhand;
      updatedSum = acc.currentcost * updatedQty;
    }
    return { updatedQty, updatedSum };
  };
  const handleItemQuantityChanged = (id, value, flag) => {
    value = Number(value);
    const itemChange = requisitionData.map((req) => {
      let newitem = req.data.map((item) => {
        let qtyByAccounts = item.accounts.reduce((previous, current) => {
          return previous + current.orderedqty;
        }, 0);
        let diff = item.quantity - qtyByAccounts - item.onhand;
        let uidSplit = id?.split("_");
        let newacc = item.accounts.map((acc) => {
          if (
            uidSplit.length === 3 &&
            req.categoryid === uidSplit[0] &&
            item.itemid === uidSplit[1]
          ) {
            let { updatedQty, updatedSum } = getComputeQuantityAndSum(
              id,
              flag,
              diff,
              value,
              item,
              acc
            );
            return {
              ...acc,
              orderedqty: updatedQty,
              totalsum: updatedSum,
            };
          }
          return { ...acc };
        });

        return { ...item, accounts: newacc };
      });
      return { ...req, data: newitem };
    });
    setRequisitionData(itemChange);
  };

  const handleTextChanged = (id, value) => {
    value = Number(value);
    const textChange = requisitionData.map((req) => {
      let newitem = req.data.map((item) => {
        if (item.id === id) {
          let onHandValue = value <= item.quantity ? value : item.quantity;
          return { ...item, onhand: onHandValue };
        }
        return item;
      });
      return { ...req, data: newitem };
    });

    setRequisitionData(textChange);
  };

  const groupByCategoryId = (requisitions, categoryID) => {
    return requisitions.reduce((acc, obj) => {
      const key = obj[categoryID];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  const accountItems = (
    accounts,
    itemCost,
    key,
    prefferredVendors,
    data,
    quantity
  ) => {
    const accItems = accounts.map((myacc) => {
      let resultantItemCost = itemCost.find(
        (ic) => ic.accountId === myacc.id && ic.itemId === parseInt(key)
      );
      let isPreferred =
        prefferredVendors.filter(
          (pv) =>
            pv.accountId === myacc.id &&
            pv.itemCategoryId === parseInt(data.CatId)
        ).length > 0
          ? true
          : false;
      const currentcost = resultantItemCost?.currentCost || 0;
      return {
        uid: `${data.CatId}_${key}_${myacc.id}`,
        title: myacc.name,
        accid: myacc.id,
        currentcost: currentcost,
        change: resultantItemCost?.percentChange || 0,
        orderedqty: isPreferred ? quantity : 0,
        totalsum: quantity * currentcost,
        isPreferredVendor: isPreferred,
      };
    });
    return accItems.sort(function (current, next) {
      return current.title.localeCompare(next.title);
    });
  };

  const fetchData = async () => {
    try {
      const filters = {};
      const params = {};

      const req_id = reqId;
      await Promise.all([
        req_id.map((r) => {
          return RequisitionService.requisitions
            .getAllItems(r, params, filters)
            .then((i) => i.data);
        }),
        accountService.itemCost.getAll({ pageSize: 250 }, {}, 1),
        accountService.accounts.getAll(params, {
          parentId: null,
          statusId: 1,
        }),
        pricingService.vendors.getAll(params, filters),
      ]).then(async (results) => {
        const req_response = await Promise.all(results[0]);
        const allRequisitions = req_response.flatMap((item) => item);

        const itemCost = results[1].data;
        const accounts = results[2].data.slice(0, 5);
        const prefferredVendors = results[3].data;
        const reqAggsByCategory = groupByCategoryId(
          allRequisitions,
          "categoryId"
        );
        const keys = Object.keys(reqAggsByCategory);
        const categoryWiseMapping = [];
        keys.forEach((key) => {
          categoryWiseMapping.push({
            CatId: key,
            CatName: reqAggsByCategory[key][0].categoryName,
            ItemsInfo: [...reqAggsByCategory[key]],
          });
        });
        const finalReqObj = [];
        categoryWiseMapping.forEach((category) => {
          const catId = category.CatId;
          const catName = category.CatName;
          const reqAggsByItem = groupByCategoryId(category.ItemsInfo, "itemId");
          const keysItem = Object.keys(reqAggsByItem);
          const itemArray = [];
          keysItem.forEach((key) => {
            let quantity = reqAggsByItem[key].reduce((previous, current) => {
              return previous + current.quantity;
            }, 0);
            let onHand = "0";
            itemArray.push({
              id: key,
              itemid: key,
              itemname: reqAggsByItem[key][0].itemName,
              itemdescription: reqAggsByItem[key][0].itemDescription,
              itemNotes: reqAggsByItem[key][0].notes,
              itemUnit: reqAggsByItem[key][0].unit,
              itemSKU: reqAggsByItem[key][0].sku,
              quantity: quantity,
              onhand: onHand,
              isSplitOn: false,
              incoming: "0",
              accounts: accountItems(
                accounts,
                itemCost,
                key,
                prefferredVendors,
                category,
                quantity
              ),
            });
          });
          finalReqObj.push({
            categoryid: catId,
            categoryname: catName,
            data: itemArray,
          });
        });
        setRequisitionData(finalReqObj);
        setLoading(false);
      });
    } catch (error) {
      createToast(en.errorBody, TOAST_TYPES.ERROR);
    }
  };

  const MiInputElement = ({ id, value, disabled, handleChange }) => {
    const { classes: textCSSClasses } = UseStylesForTextField({
      disabled,
    });

    return (
      <MiInputTextbox
        id={id}
        disabled={disabled}
        minWidth={60}
        fullWidth={false}
        value={value ?? 0}
        handleChange={handleChange}
        InputProps={{
          classes: textCSSClasses,
        }}
      />
    );
  };

  const fixedColumns = [
    {
      field: "name",
      headerName: en.items,
      width: 250,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <ListItemText
            secondaryTypographyProps={{
              style: {
                color: theme.palette.secondary.bluegrey,
                fontSize: theme.fontSize.small,
              },
            }}
            primaryTypographyProps={{
              style: {
                color: theme.palette.primary.blue,
                fontSize: theme.fontSize.medium,
                cursor: "pointer",
              },
            }}
            primary={row?.itemname}
            secondary={row?.itemdescription}
          />
        );
      },
    },
    {
      field: "needed",
      headerName: en.needed,
      width: 70,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <MiInputElement
            id={params.row.id}
            value={params.row.quantity}
            disabled
          />
        );
      },
    },
    {
      field: "onHand",
      headerName: en.onHand,
      width: 70,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <MiInputTextbox
            id={params.row.id}
            value={params.row.onhand ?? "0"}
            disabled={false}
            InputProps={{
              classes: {
                input: classes.input,
                root: classes.root,
              },
            }}
            minWidth={60}
            fullWidth={false}
            handleChange={(e) => handleTextChanged(e.target.id, e.target.value)}
          />
        );
      },
    },
    {
      field: "inComing",
      headerName: en.inComing,
      width: 75,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <MiInputElement
            id={params.row.id}
            value={params.row.incoming ?? "--"}
            disabled
          />
        );
      },
    },
  ];

  const preferredVendorCell = {
    field: en.preferredVendor,
    headerName: en.preferredVendor,
    minWidth: 180,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({ value }) => {
      return (
        <PoCard
          {...value}
          disabled={value?.disabled ?? false}
          isSplitOn={value?.split ?? false}
          onChange={(e) => {
            handleItemQuantityChanged(e.target.id, e.target.value, value.split);
          }}
        />
      );
    },
  };

  const splitColumn = {
    field: "split",
    headerName: en.split,
    width: 60,
    disableColumnMenu: true,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: (row) => {
      return (
        <IconButton size="large">
          <MiIcon
            path={mdiCallSplit}
            size={1}
            style={
              row.row.isSplitOn
                ? {
                    backgroundColor: theme.palette.light.main,
                    borderRadius: 50,
                  }
                : {}
            }
            onClick={() => onSplitClicked(row, !row.row.isSplitOn)}
          />
        </IconButton>
      );
    },
  };

  const getPreferredVendorColumnPlaceholder = () => {
    const vendorColumn = { ...preferredVendorCell };
    vendorColumn.isPreferredVendor = true;
    vendorColumn.renderHeader = () => {
      return (
        <>
          <MiIcon
            path={mdiStarCircleOutline}
            color={theme.palette.medium.yellow}
            size={1}
          />
          <Typography style={{ marginLeft: 6 }}>
            {vendorColumn.field}
          </Typography>
        </>
      );
    };
    return vendorColumn;
  };

  const getPreferredVendorDefaultValues = () => {
    return {
      accid: -1,
      change: 0,
      currentcost: 0,
      isPreferredVendor: true,
      orderedqty: 0,
      split: false,
      title: en.notApplicable,
      totalsum: 0,
      uid: "NA",
    };
  };

  const getVendorColumns = (rowData) => {
    const vendors = [];
    let hasPreferredVendorInColumn = false;
    const supplierNames = {};
    const quantity = rowData.quantity;
    const onHandValue = rowData.onhand;
    const totalOrderQty = rowData.accounts.reduce((acc, account) => {
      acc += account.orderedqty ?? 0;
      return acc;
    }, 0);
    const isMaxReached = quantity < onHandValue + totalOrderQty;
    const resetOrderItems = onHandValue && !rowData.isSplitOn && isMaxReached;
    rowData.accounts.forEach((item) => {
      if (resetOrderItems) {
        item.orderedqty = 0;
      }
      if (!supplierNames[item.title]) {
        supplierNames[item.title] = item.title;
        item.split = rowData.isSplitOn;
        const vendor = { ...preferredVendorCell };
        if (item.title) {
          vendor.field = item.title;
          vendor.renderHeader = () => {
            return (
              <>
                {item.isPreferredVendor && (
                  <MiIcon
                    path={mdiStarCircleOutline}
                    color={theme.palette.medium.yellow}
                    size={1}
                  />
                )}
                <Typography style={{ marginLeft: 6 }}>{item.title}</Typography>
              </>
            );
          };
        }
        vendor.isPreferredVendor = item.isPreferredVendor;
        vendors.push(vendor);
        if (!hasPreferredVendorInColumn && vendor.isPreferredVendor) {
          hasPreferredVendorInColumn = true;
        }
      }
    });
    return {
      vendors,
      hasPreferredVendorInColumn,
    };
  };

  const getTableColumns = (rows) => {
    let accordionColumns = [...fixedColumns];
    for (let row of rows) {
      if (row.accounts.length) {
        const vendorData = getVendorColumns(row);
        if (!vendorData.hasPreferredVendorInColumn) {
          accordionColumns.push(getPreferredVendorColumnPlaceholder());
        }
        vendorData.vendors.sort((a) => (a.isPreferredVendor ? -1 : 0));
        accordionColumns.push(...vendorData.vendors, splitColumn);
      }
    }
    return accordionColumns;
  };

  const getTableRows = ({ data }) => {
    let rowData = [];
    let hasPreferredVendorInRow = false;
    data.map((item) => {
      const { accounts, ...rest } = item;
      let rows = { ...rest };
      if (accounts.length) {
        const supplierTitleMap = accounts.reduce((rw, supplier) => {
          const { title, isPreferredVendor } = supplier;
          let supplierTitle = title;
          if (!title && isPreferredVendor) {
            supplier.title = en.notApplicable;
            supplierTitle = en.preferredVendor;
          }

          if (!hasPreferredVendorInRow && isPreferredVendor) {
            hasPreferredVendorInRow = true;
          }

          rw[supplierTitle] = supplier;
          return rw;
        }, {});
        rows = { ...rows, ...supplierTitleMap };
      }
      if (!hasPreferredVendorInRow) {
        rows[en.preferredVendor] = getPreferredVendorDefaultValues();
      }
      rowData.push(rows);
      return item;
    });
    return rowData;
  };

  const getSupplierAccordion = () => {
    return requisitionData.map((product, index) => (
      <PoTable
        key={`table-${index}`}
        title={product.categoryname}
        columns={getTableColumns(product.data)}
        rows={getTableRows(product)}
        hideFooter={true}
      />
    ));
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const renderHeader = () => {
    const renderLeftIcon = () => {
      return (
        <Grid className={classes.iconCircle}>
          <MiIcon
            path={mdiCardTextOutline}
            size={1.5}
            color={theme.palette.primary.white}
          />
        </Grid>
      );
    };
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.headerStyle}
      >
        <Grid item className={classes.iconTitle}>
          <MiPageHeader
            title={en.purchaseOrderAssemblyNewHomes}
            color={theme.palette.secondary.bluegrey}
            leftIcon={renderLeftIcon()}
            titleVariant={"h4"}
          />
        </Grid>
        <Grid item>
          <Button className={classes.checkButton} onClick={handlePrint}>
            <MiIcon path={mdiFormatListChecks} size={1} />
            {en.checkInventory}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const onCancel = () => setConfirmModal({ open: false });

  const onApprove = () => {
    onCancel();
    closePurchaseOrderDrawer();
    createToast(en.purchaseOrderAssemblyDiscarded, TOAST_TYPES.INFO);
  };

  const handleOnDiscard = () => {
    let mProps = {
      color: theme.palette.secondary.red,
      icon: mdiFileRemoveOutline,
      title: en.discardPoAssembly,
      description: en.confirmYouDLikeToDiscardThisOrder,
      textPositive: en.discard,
      buttonLeftStyles: { width: 130, height: 36 },
      buttonRightStyles: { width: 130, height: 36 },
    };
    setConfirmModal({ ...mProps, open: true });
  };

  const closeReviewPoDrawer = () => {
    setReviewPoDrawer({ open: false });
  };

  const handleOnReviewPo = (data) => {
    if (data.length) {
      closeReviewPoDrawer();
      setReviewPoDrawer({ open: true, data });
    } else {
      createToast(en.purchaseOrderItemSelection, TOAST_TYPES.WARNING);
    }
  };
  const renderNewPoAlert = () => {
    return (
      <Grid className={classes.alert}>
        <MiAlert
          open={true}
          message={`${en.originalSupplier}: ${supplierName}`}
          severity={TOAST_TYPES.WARNING}
          s
        />
      </Grid>
    );
  };
  return (
    <>
      <MiDrawer
        anchor="right"
        open={true}
        classes={{ paper: classes.drawerPaper }}
        width="100%"
      >
        {loading ? (
          <Grid className={classes.loading}>
            <MiLoader color={theme.palette.primary.white} />
          </Grid>
        ) : (
          <Grid container wrap="nowrap" className={classes.gridHeight}>
            <Grid item xs={10}>
              <MiBox>
                <Grid className={classes.containerStyle}>
                  {renderHeader()}
                  {isOriginalSupplierAlert && renderNewPoAlert()}
                  {getSupplierAccordion()}
                </Grid>
              </MiBox>
            </Grid>
            <Grid item xs={2} className={classes.asideContainer}>
              <PurchaseOrderPreview
                buttonTwoStyle={{
                  border: `1px solid ${theme.palette.secondary.red}`,
                  width: 160,
                  height: 30,
                  fontWeight: theme.fontWeight.medium,
                  fontSize: theme.fontSize.small,
                }}
                buttonTwoBg={theme.palette.primary.white}
                buttonTwoColor={theme.palette.secondary.bluegrey}
                discardClick={handleOnDiscard}
                reviewPOClick={handleOnReviewPo}
                closeClick={closePurchaseOrderDrawer}
                buttonOneStyle={{
                  width: 160,
                  height: 30,
                  fontWeight: theme.fontWeight.medium,
                  fontSize: theme.fontSize.small,
                }}
                buttonOneBg={theme.palette.primary.main}
                buttonOneColor={theme.palette.primary.white}
                data={requisitionData}
              />
            </Grid>
          </Grid>
        )}
      </MiDrawer>
      {confirmModal.open && (
        <MiModalConfirm
          {...confirmModal}
          handleNegative={onCancel}
          handlePositive={onApprove}
          dialogClasses={{ paper: classes.dialogPaper }}
        />
      )}
      {reviewPoDrawer.open && (
        <PurchaseOrder
          {...reviewPoDrawer}
          selectedItems={selectedItems}
          onSave={onSave}
          closeReviewPoDrawer={closeReviewPoDrawer}
        />
      )}
      <CheckInventoryPrint
        ref={componentRef}
        requisitionData={requisitionData}
        reqId={reqId}
      />
    </>
  );
};

export default CreatePurchaseOrder;
