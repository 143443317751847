import { makeStyles } from "tss-react/mui";

export const useMiTimelineStyles = makeStyles()((theme) => ({
  blocking: { height: "110px", width: "100%", backgroundColor: "#eee" },
  toggleBtn: {
    maxHeight: "0",
    padding: ".15rem",
    margin: "0",
    boxShadow: "0px 0px 0px",
    transition: "all .5s ease-out",
    left: "1rem",
    bottom: ".4rem",
    zIndex: 100,
    lineHeight: ".8rem",
    fontSize: ".6rem",
    border: "1px solid #F4F6F9",
    backgroundColor: "#FFF",
    borderRadius: ".4rem",
    textAlign: "center",
    position: "absolute",
    width: "2rem",
    cursor: "pointer",
    "&:before": {
      textDecoration: "none",
      content: "'\\25B2'",
      fontSize: ".5rem",
      color: "#0070D2",
      transition: "all .5s ease-out",
    },
    "&.down:before": {
      content: '"\\25BC"',
      marginTop: ".0rem",
      transition: "all .5s ease-in-out",
    },
  },
  projectTimeline: {
    maxHeight: "200px",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "0",
    position: "relative",
    "& .activated .timeline-circle": { background: "#4BCA81" },
    "& .activated .timeline-circle-canceled": {
      background: "#E70F0F",
    },
  },
  timeline: { padding: ".5rem" },
  timelineList: {
    display: "flex",
    msFlexPack: "justify",
    justifyContent: "space-between",
    position: "relative",
    "& i": {
      zIndex: 100,
      color: "white",
      fontSize: ".6rem",
      textAlign: "center",
      verticalAlign: "middle",
      lineHeight: "1.5rem",
    },
  },
  timelineGroup: {
    msFlex: "2 0 auto",
    flex: "2 0 auto",
    textAlign: "center",
    width: "0%",
    "&:first-of-type": {
      textAlign: "left",
      msFlexPositive: "1",
      flexGrow: 1,
    },
    "&:last-child": {
      textAlign: "right",
      msFlexPositive: "1",
      flexGrow: 1,
    },
  },
  timelineCircle: {
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: "#efede8",
    display: "inline-block",
    verticalAlign: "middle",
    zIndex: 1,
    position: "relative",
  },
  timelineBar: {
    position: "absolute",
    left: ".6rem",
    right: ".5rem",
    top: "1.13rem",
    height: "0.3rem",
    display: "block",
    width: "calc(100% - 1.1rem)",
    background: "#efede8",
  },
  timelineBarActivated: {
    width: "0%",
    position: "absolute",
    left: "0",
    top: "0",
    height: "100%",
    background: "#4BCA81",
    transition: "width 0.2s ease",
  },
  timelineBarActivatedCanceled: {
    width: "99.1%",
    position: "absolute",
    left: ".5rem",
    top: "0",
    height: "100%",
    background: "#E70F0F",
    transition: "width 0.2s ease",
  },
  timelineBarActivated0: { width: "5%" },
  timelineBarActivated1: { width: "0%" },
  timelineBarActivated2: { width: "16.5%" },
  timelineBarActivated3: { width: "33%" },
  timelineBarActivated4: { width: "50%" },
  timelineBarActivated5: { width: "66.5%" },
  timelineBarActivated6: { width: "83%" },
  timelineBarActivated7: { width: "100%" },
  timelineText: {
    display: "block",
    marginTop: ".5rem",
    fontSize: "0.685rem",
    lineHeight: "100%",
    fontWeight: 300,
    color: "#54698d",
    textTransform: "uppercase",
    letterSpacing: "0.0625rem",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  time: {
    fontSize: "0.685rem",
    lineHeight: "100%",
    fontWeight: 300,
    color: "#54698d",
    textTransform: "uppercase",
    letterSpacing: "0.0625rem",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  timeOut: {
    maxHeight: "200px",
    overflow: "visible",
    transition: "all .5s ease-in-out",
  },
  timeOutHide: {
    opacity: 0,
    padding: "0",
    maxHeight: "0",
    boxShadow: "0px 0px 0px",
    overflow: "hidden",
    transition: "all .5s ease-in-out",
  },
  toggle: { display: "none" },
  pathItem: {
    position: "relative",
    msFlex: "1",
    flex: 1,
    marginLeft: "0.375rem",
    marginRight: "0.4375rem",
    minWidth: "5rem",
    textAlign: "center",
    "&:before": {
      top: "0",
      height: "calc((2rem / 2) + 0.0625rem)",
      transform: "skew(28deg) translate3d(0, 0, 0)",
      content: "''",
      position: "absolute",
      left: "-0.25rem",
      right: "-0.3125rem",
      cursor: "pointer",
    },
    "&:after": {
      bottom: "0",
      height: "1rem",
      transform: "skew(-30deg) translate3d(0, 0, 0)",
      content: "''",
      position: "absolute",
      left: "-0.25rem",
      right: "-0.3125rem",
      cursor: "pointer",
    },
    "&:first-of-type": {
      marginLeft: "0",
      borderTopLeftRadius: "2rem",
      borderBottomLeftRadius: "2rem",
      paddingLeft: "0.625rem",
    },
    "&:first-of-type:before, &:first-of-type:after": {
      left: "1.125rem",
    },
    "&:last-child": {
      marginRight: "0",
      borderTopRightRadius: "2rem",
      borderBottomRightRadius: "2rem",
      paddingRight: "0.625rem",
    },
    "&:last-child .pathLink": { paddingLeft: "0.25rem" },
    "&:last-child:before, &:last-child:after": {
      right: "0.625rem",
    },
    '[dir="rtl"] &:before': {
      transform: "skew(-30deg) translate3d(0, 0, 0)",
    },
    '[dir="rtl"] &:after': {
      transform: "skew(28deg) translate3d(0, 0, 0)",
    },
  },
  pathTrack: {
    msFlexWrap: "wrap",
    flexWrap: "wrap",
    msFlexAlign: "start",
    alignItems: "flex-start",
  },
  pathTitle: {
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  pathScroller: {
    msFlex: "1",
    flex: 1,
    maxWidth: "100%",
    overflow: "hidden",
  },
  pathScrollerContainer: {
    msFlex: "1",
    flex: 1,
    msFlexPreferredSize: "100%",
    flexBasis: "100%",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      projectTimeline: { display: "none" },
    },
    [theme.breakpoints.up("md")]: {
      msFlexPreferredSize: "auto",
      flexBasis: "auto",
    },
  },
  pathNav: {
    "& .is-incomplete": { background: "#ecebea" },
    "& .is-incomplete:before": {
      background: "#ecebea",
    },
    "& .is-incomplete:after": {
      background: "#ecebea",
    },
    "& .is-incomplete .pathLink": { color: "#080707" },
    "& .is-incomplete:hover": { background: "#dddbda" },
    "& .is-incomplete:hover:before": {
      background: "#dddbda",
    },
    "& .is-incomplete:hover:after": {
      background: "#dddbda",
    },
    "& .is-complete": { background: "#4bca81" },
    "& .is-complete:before": {
      background: "#4bca81",
    },
    "& .is-complete:after": {
      background: "#4bca81",
    },
    "& .is-complete .pathStage": {
      transform: "translate(-50%, -50%) rotateX(0deg)",
    },
    "& .is-complete .pathTitle": { transform: "rotateX(180deg)" },
    "& .is-complete .pathLink": { color: "white" },
    "& .is-complete:hover": { background: "#04844b" },
    "& .is-complete:hover:before": {
      background: "#04844b",
    },
    "& .is-complete:hover:after": {
      background: "#04844b",
    },
    "& .is-complete:hover .pathStage": {
      transform: "translate(-50%, -50%) rotateX(-180deg)",
    },
    "& .is-complete:hover .pathTitle": { transform: "rotateX(0deg)" },
    "& .is-current": { backgroundColor: "#55899b" },
    "& .is-current:before": {
      backgroundColor: "white",
      backgroundImage:
        "linear-gradient(90deg, #005fb2, #005fb2 0.125rem, transparent 0.125rem), linear-gradient(90deg, #005fb2, #005fb2 0.125rem, transparent 0.125rem), linear-gradient(#005fb2, #005fb2 0.125rem, transparent 0.125rem)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "0.125rem 100%, 0.125rem 100%, 100% 0.125rem",
    },
    "& .is-current:after": {
      backgroundColor: "white",
      backgroundImage:
        "linear-gradient(90deg, #005fb2, #005fb2 0.125rem, transparent 0.125rem), linear-gradient(90deg, #005fb2, #005fb2 0.125rem, transparent 0.125rem), linear-gradient(#005fb2, #005fb2 0.125rem, transparent 0.125rem)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "0.125rem 100%, 0.125rem 100%, 100% 0.125rem",
    },
  },
  chevron: {
    height: "34px",
    position: "relative",
    fontSize: ".875rem",
    "&.is-complete": { background: "#3CBA6A" },
    "&.is-incomplete": { background: "#DDDDDD !important" },
    "&.is-incomplete:after": {
      content: '""',
      position: "absolute",
      left: "0",
      bottom: "0",
      width: "0",
      height: "0",
      borderLeft: "17px solid white",
      borderTop: "17px solid transparent",
      borderBottom: "17px solid transparent",
    },
    "&.is-incomplete:before": {
      content: '""',
      position: "absolute",
      right: "-17px",
      bottom: "0",
      width: "0",
      height: "0",
      borderLeft: "17px solid #DDD",
      borderTop: "17px solid transparent",
      borderBottom: "17px solid transparent",
    },
    "&.is-incomplete:last-child:before": {
      content: '""',
      position: "absolute",
      right: "10px",
      bottom: "0",
      width: "0",
      height: "0",
      borderLeft: "0px solid transparent",
      borderTop: "0px solid transparent",
      borderBottom: "0px solid transparent",
    },
    "&.is-incomplete:first-of-type:after": {
      content: '""',
      position: "absolute",
      left: "0",
      bottom: "0",
      width: "0",
      height: "0",
      borderLeft: "0px solid #DDD",
      borderTop: "0px solid transparent",
      borderBottom: "0px solid transparent",
    },
    "&.is-complete:after": {
      content: '""',
      position: "absolute",
      left: "0",
      bottom: "0",
      width: "0",
      height: "0",
      borderLeft: "17px solid white",
      borderTop: "17px solid transparent",
      borderBottom: "17px solid transparent",
    },
    "&.is-complete:before": {
      content: '""',
      position: "absolute",
      right: "-17px",
      bottom: "0",
      width: "0",
      height: "0",
      borderLeft: "17px solid #3CBA6A",
      borderTop: "17px solid transparent",
      borderBottom: "17px solid transparent",
    },
    "&.is-complete:last-child:before": {
      content: '""',
      position: "absolute",
      right: "10px",
      bottom: "0",
      width: "0",
      height: "0",
      borderLeft: "0px solid transparent",
      borderTop: "0px solid transparent",
      borderBottom: "0px solid transparent",
    },
    "&.is-complete:first-of-type:after": {
      content: '""',
      position: "absolute",
      left: "0",
      bottom: "0",
      width: "0",
      height: "0",
      borderLeft: "0px solid #3CBA6A",
      borderTop: "0px solid transparent",
      borderBottom: "0px solid transparent",
    },
  },
}));
