export const en = {
  failureTypeNameField: "failureTypeName",
  requiredUpdateHeader: "Time to update!",
  requiredUpdateText:
    "This version of the application is no longer supported, please update to the latest version or contact your administrator for assistance.",
  issueDescriptionField: "description",
  jobStatus: "Status",
  jobType: "Job Type",
  jobName: "Job Name",
  address: "Address",
  builder: "Builder",
  community: "Community",
  sqFootage: "Square Footage",
  floors: "Floors",
  bathrooms: "Bathrooms",
  zipcode: "Zipcode",
  duedate: "Due Date",
  scheduledate: "Schedule Date",
  stage: "Stage",
  amount: "Amount",
  jobs: "Jobs",
  midia: "Mi Dia",
  past: "Past",
  today: "Today",
  future: "Future",
  acceptJob: "Accept Job",
  declineJob: "Decline Job",
  startJob: "Start Job",
  previewJob: "Preview Job",
  startRemote: "Start Remote",
  takePictureToStart: "Please take a picture to start",
  submit: "Submit",
  preview: "Preview",
  message: "Message",
  extras: "Extras",
  unableToStart: "Unable to start job",
  reasonUnableToStart: "Reason unable to start.",
  selectReason: "Select Reason",
  selectLanguage: "Select Language",
  checklist: "Checklist",
  areas: "Areas",
  noTasks: "There are no tasks.",
  na: "N/A",
  addNote: "Add Note",
  addPhoto: "Add Photo",
  viewDocument: "View Document",
  cancel: "Cancel",
  save: "Save",
  addANote: "Add a Note",
  confirm: "Confirm",
  details: "Details",
  profile: "Profile",
  changePassword: "Change Password",
  changepin: "Change PIN",
  changeLanguage: "Change Language",
  username: "Username",
  oldPassword: "Old Password",
  newPassword: "New Password",
  confirmPassword: "Confirm New Password",
  logout: "Logout",
  advanced: "Advanced",
  forgotPassword: "Forgot Password",
  signIn: "Sign In",
  password: "Password",
  passwordReset: "Password Reset",
  resetPassword: "Reset Password",
  sync: "Sync",
  pendingSync: "Pending Sync",
  imageSync: "Image Sync",
  dataSync: "Data Sync",
  errors: "Errors",
  dumpLocalStorage: "Dump All Local Storage",
  dumpCurrentSyncs: "Dump Current Syncs",
  messages: "Messages",
  addMessage: "Add Message",
  tagContact: "Tag Contact",
  close: "close",
  tag: "Tag",
  title: "Title",
  noMessage: "There are no Messages.",
  addExtra: "Add Extra",
  saveExtra: "Save Extra",
  reasonRequestingExtra: "Add Reason for Requesting Extra",
  selectArea: "Select Area",
  noSyncData: "There is currently no data to sync.",
  finishJob: "Finish Job",
  finalReview: "Final Review",
  completed: "Completed",
  duration: "Duration (mins)",
  incomplete: "Incomplete",
  naItem: "N/A Item",
  deleteSelectedItem: "Delete selected Item",
  itemDeletedSuccessful: "Item deleted successfully",
  itemDeletedFailed: "Item deletion failed",
  quoteSaveSuccessful: "Quote Save successful",
  quoteSaveFailed: "Quote saving failed",
  quoteCancelled: "Quote cancelled",
  downloadSuccessful: "Download successful",
  downloadFailed: "Download failed",
  complete: "Complete",
  checklistItem: "Checklist Item",
  reviewSpecSheet: "Review Spec Sheet",
  status: "Status",
  description: "Description",
  noDetails: "No details",
  imageRequired: "An Image is required for this item.",
  Phone: "Phone",
  Email: "Email",
  support: "Support",
  techSupport: "Tech Support",
  notifications: "Notifications",
  noNotifications: "There are no notifications.",
  confirmLogout: "Confirm Logout",
  noJobsForPeriod: "There are no jobs for this time period.",
  pullRefresh: "Pull to Refresh",
  noAnswer_message: "You have not done all the tasks",
  annotateImage_title: "Highlight Issue on Image",
  noExtras_message: "There are no Extras for this job.",
  deleteExtraAlert: "Are you sure you want to delete Extra Item?",
  jobSignatureRequired: "Required: Sign to verify the job.",
  submitWithSignature: "Submit with Signature",
  logoutAlert: "Logout Alert",
  logoutAlert_title: "Are you sure you want to logout?",
  logoutAlert_message:
    "You currently have active syncs in progress. If you logout, you will lose these data.",
  loginFailed_toast: "Login failed. ",
  sessionExpired_toast: "Session expired. Please login. ",
  checkEmail_toast: "Check email for reset code. ",
  networkError_toast: "Network error. Please check your connection. ",
  workingOffline_toast: "You are currently working offline. ",
  syncFailed_toast: "Data sync failed. ",
  syncInProgress_toast: "Data sync in progress. ",
  errorUpdating_toast: "Error updating data. ",
  successAdded_toast: "Successful adding data. ",
  successCancelled_toast: "Successful cancelled request. ",
  locationTooFar_toast: "Your location is too far from the property. ",
  noLocation_toast: "Unable to get location. ",
  walks: "Walks",
  stages: "Stages",
  punch: "Punch",
  search_address: "Search Address",
  search: "Search",
  bonus: "Bonus",
  pay: "Pay",
  total_pay: "Total Pay",
  pending: "Pending",
  total: "Total",
  deduction: "Deduction",
  deducted: "Deducted",
  earned: "Earned",
  paid: "Paid",
  pay_period: "Pay Period",
  current: "Current",
  monday: "Mon",
  tuesday: "Tue",
  wednesday: "Wed",
  thursday: "Thu",
  friday: "Fri",
  saturday: "Sat",
  sunday: "Sun",
  forgot_PIN: "Forgot PIN",
  reset_PIN: "Reset PIN",
  enter_PIN: "Enter PIN",
  enter_new_pin: "Enter New PIN",
  enter_password: "Enter Password",
  incorrect_password: "Incorrect password!",
  incorrect_PIN: "Incorrect PIN",
  enter_complete_PIN: "Enter Complete PIN",
  num_only_warning: "Enter numeric characters only",
  inprogress: "In Progress",
  hold: "Hold",
  notstarted: "Not Started",
  scheduled: "Scheduled",
  late: "Late",
  work: "Work",
  no_matching_records_message: "There are no matching records found!",
  accountManagement: "Account Management",
  idPattern: "ID Pattern",
  divisor: "Divisor",
  lastModified: "Last Modified",
  addAccountType: "Add Account Type",
  createAccountType: "Create Account Type",
  contactName: "Contact Name",
  contactManagement: "Contact Management",
  contactType: "Contact Type",
  addContactType: "Add Contact Type",
  deleteContactType: "Delete Contact Type",
  contactTypeAddedCreatedSuccessMessage:
    "Contact type added/created successfully",
  contactTypeEditedSuccessfully: "Contact type edited successfully",
  contactTypeDeleted: "Contact type deleted",
  create: "Create",
  delete: "Delete",
  edit: "Edit",
  active: "Active",
  inActive: "Inactive",
  makeActive: "Make Active",
  makeInActive: "Make Inactive",
  name: "Name",
  city: "City",
  state: "State",
  accountType: "Account Type",
  accountName: "Account Name",
  multiSelect: "multiSelect",
  filterItems: "Filter Items",
  idPatterns: "ID Patterns",
  filterLocations: "Filter Locations",
  filterLocationCity: "Filter LocationCity",
  filterStatus: "Filter Status",
  accountTypeCreateSuccessMessage: "Account type added/created successfully",
  theActionWasNotSuccessful: "The action was not successful!",
  accountTypeEditedSuccessfully: "Account type edited successfully",
  accountTypeDeleted: "Account type deleted",
  openInNewTab: "Open in New Tab",
  archive: "Archive",

  errorBody: "An error occurred while updating or retrieving data",
  purchaseOrders: "Purchase Orders",
  reviewPo: "Review POs",
  purchaseOrder: "Purchase Order",
  BacktoPricing: "Back to Pricing",
  subtotal: "Subtotal",
  tax: "Tax",
  CancelPurchaseOrder: "Cancel Purchase Order",
  receiving: "Receiving",
  supplier: "Supplier",
  receivedBy: "Received By",
  receivedDate: "Received Date",
  receivingLocation: "Receiving Location",
  packingSlip: "Packing Slip",
  attachFile: "Attach File",
  notes: "Notes",
  enterNote: "Enter Note",
  enableNote: "This is a note about this item ...",
  receiveAll: "Receive All",
  sendEmail: "Send Email",
  sendManually: "Send Manually",
  backToPricing: "Back to Pricing",
  cc: "CC",
  bcc: "BCC",
  subject: "Subject",
  attachments: "Attachments",
  cancelPO: "cancel PO.pdf",
  requisitionOrderNew: "Requisition Orders - New Homes",
  requisitions: "Requisitions",
  requisitionSelect: "No requisitions selected!",
  createMessage:
    "In order to create a purchase order you must add requisition(s).",
  contact: "Contact",
  schedule: "Schedule",
  scheduleDate: "Scheduled Date",
  requested: "Requested Billing Date",
  delivery: "Delivery",
  deliveryDate: "Delivery Date",
  warehouseLocation: "Warehouse Location",
  additional: "Additional",
  memo: "Memo",
  instructions: "Instructions",
  specialInstructions: "Special Instructions",
  receive: "Receive",
  to: "To",
  editor: "Editor",
  inventoryCheck: "Inventory Check",
  requisitionOrder: "Requisition Order # ",
  cancelPo: "Cancel PO",
  receivePo: "Receive PO",
  backOrderPO: "BackOrder PO",
  enterTag: "Enter Tag",
  enterMemo: "Enter Memo",
  enterInstructions: "Enter Instructions",
  item: "Item",
  quantity: "Quantity",
  ordered: "Ordered",
  received: "Received",
  difference: "Difference",
  actions: "Actions",
  sku: "SKU",
  unit: "Unit",
  unitPrice: "Unit Price",
  cancelled: "Cancelled",
  preferredVendor: "preferredVendor",
  saveAndNext: "Save & Next",
  saveAndSend: "Save & Send",
  backToPricingDescription:
    "Confirm you�d like to discard entered information and return to pricing",
  discard: "Discard",
  purchaseOrderMarkedAsPaid: "Purchase Order marked as paid!",
  markAsPaid: "Mark as Paid",
  fileExtError: "Accepted upload formats are PDF, JPG, JPEG.",
  browseFiles: "Browse Files",
  notApplicable: "Not Applicable",
  addWarehouseLocation: "Add Warehouse Location",
  warehouseLocationWasAddedSuccessfully:
    "Warehouse location was added successfully",
  warehouseLocationWasEditedSuccessfully:
    "Warehouse location was edited successfully",
  warehouseLocationWasDeletedSuccessfully:
    "Warehouse location was deleted successfully",
  warehouseLocationWasActivatedSuccessfully:
    "Warehouse location was activated successfully",
  warehouseLocationWasInactivatedSuccessfully:
    "Warehouse location was inactivated successfully",
  warehouseLocationAssociatedPurchaseOrders:
    "Warehouse Location has associated Purchase Orders",
  mi_team: "MiTeam",
  all_punchmen: "All Punchmen",
  crews: "Crews",
  pleaseTryAgainLater: "Please try again later",
  purchaseOrderReceivedSuccessfully: "Purchase Order received successfully!",
  purchaseOrderSetToBackOrder: "Purchase Order set to Backorder!",
  purchaseOrderCancelled: "Purchase Order Cancelled",
  pleaseSelectAnActions:
    "Please select an Actions option for the highlighted Actions field.",
  items: "Items",
  needed: "Needed",
  onHand: "On Hand",
  inComing: "Incoming",
  split: "Split",
  checkInventory: "Check Inventory",
  enterNoteHere: "Enter Note Here",
  productLine: "Product Line",
  discardPoAssembly: "Discard PO Assembly",
  purchaseOrderAssemblyNewHomes: "Purchase Order Assembly - New Homes",
  confirmYouDLikeToDiscardThisOrder: "Confirm you'd like to discard this order",
  Number: "PO #",
  wareHouseDelivery: "WareHouse Delivery",
  review: "Review",
  issued: "Issued",
  confirmYouDLikeToCancelThisOrder: "Confirm you'd like to cancel this order",
  purchaseOrderAssemblyDiscarded: "Purchase order assembly discarded.",
  originalSupplier: "Original Supplier",
  poweredBy: "Powered By",
  employee: "Employee",
  signature: "Signature",
  date: "Date",
  purchaseOrdersWereSentSuccessfully: "Purchase Orders were sent successfully!",
  purchaseOrderWasSentSuccessfully: "Purchase Order was sent successfully!",
  purchaseOrderIssuedManually: "Purchase Order issued manually!",
  general: "General",
  audit: "Audit",
  enterReason: "Enter Reason",
  newNote: "New Note",
  editNote: "Edit Note",
  enterNotes: "Enter Notes",
  enterSpecialInstructions: "Enter Special Instructions",
  purchaseOrderPdf: "Purchase-Order.pdf",
  cancelPOPdf: "Cancel-PO.pdf",
  printPreview: "Print Preview",
  download: "Download",
  backToReceiving: "Back to Receiving",
  purchaseOrderItemSelection:
    "Please select purchase order item from PO assembly",
  langUpatedSuccess: "Language Preference Updated Successfully!",
  relaunchApp:
    "Please re launch your application or log-out and back in for these changes to take effect",
  storageError: "Storage Error: Please contact admin",
  enterPassword: "Please enter your password",
  networkErr: "Network Error!",
  passwordVerified: "Password Verified Successfully!",
  deleteQuoteItemAlert: "Are you sure you want to delete this quote item?",
  notEnteredName: "You have not entered a name",
  notEnteredCity: "You have not entered a city",
  notSelectedStatus: "You have not selected a status",
  notEnteredAddress: "You have not entered a address",
  notSelectedState: "You have not selected a state",
  emptyWarehouseLocationListText:
    "There are no warehouse locations set up yet.",
  emptyWarehouseLocationListHelperText:
    'Tip: Get started by clicking the "+ Add Warehouse Location" button.',
  requestManuallyAlert: "Are you sure you want to mark the quote as REQUESTED?",
  quoteMarkedRequestedManually: "Quote marked as REQUESTED",
  acceptQuoteAlert: "Are you sure you want to accept the quote?",
  acceptQuoteMessage: "Quote marked as ACCEPTED",
  messaging: "Messaging",
  menu: "Menu",
  manageWork: "Manage Work",
  homes: "Homes",
  logInAs: "Log In As",
  markQuoteRequestedButton: "Mark Requested",
  markQuoteAcceptedButton: "Accept Quote",
  markQuoteCancelledButton: "Cancel Quote",
  add: "Add",
  itemNumber: "Item #",
  unitOfMeasure: "Unit of Measure",
  currentCost: "Current Cost",
  newCost: "New Cost",
  recipient: "Recipient",
  effective: "Effective",
  expiration: "Expiration",
  supplierQuoteNumber: "Supplier Quote #",
  attachment: "Attachment",
  deleteQuoteItem: "Delete Quote Item",
  cancelQuote: "Cancel Quote",
  requestedManually: "Requested Manually",
  acceptQuote: "Accept Quote",
  addItems: "Add Items",
  cancelReason: "Cancel Reason",
  cancelQuoteRequest: "Cancel Quote Request",
  cancelQuoteDescription:
    "Are you sure you would like to cancel this quote request? This action can not be undone.",
  createTemplate: "Create Template",
  folder: "Folder",
  inspections: "Inspections",
  tractor: "Tractor",
};
