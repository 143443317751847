import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  MiInputTextbox,
  MiInputSelectBox,
  MiDatePickerInput,
} from "@miview/components";
import {
  Grid,
  IconButton,
  InputAdornment,
  useTheme,
  Avatar,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AttachFile as AttachFileIcon } from "@mui/icons-material";
import SubHeader from "../../components/SubHeader";
import FileUploadModal from "../../components/FileUploadModal";
import { purchaseOrderService } from "@miview/api";
import { en, HTTP_STATUSES } from "@miview/constants";
import { getInitials } from "@miview/utils";
const useStyles = makeStyles()((theme) => ({
  subHeader: {
    paddingRight: 10,
  },
  formGrid: {
    marginTop: 25,
    paddingBottom: 25,
  },
  gridStyle: {
    paddingLeft: 20,
  },
  additional: {
    paddingLeft: 40,
  },
  adornedIconStyle: {
    paddingLeft: 0,
  },
  fileStylesAttach: {
    paddingRight: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  root: {
    width: 24,
    height: 24,
    display: "inline-flex",
    overflow: "initial",
    backgroundColor: theme.palette.dark.bluegrey,
    fontSize: theme.fontSize.medium,
    marginRight: 10,
    textTransform: "capitalize",
  },
}));

const ReceivingPOForm = ({
  order,
  handleOrderChange,
  contactsList,
  locationList,
  isMissingRequiredField,
  setIsMissingRequiredField,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const getFormattedDate = (dValue = "") => {
    return dValue ? moment(dValue).format("MM DD YY") : "";
  };
  const [showFileUpload, setShowFileUpload] = useState({ open: false });
  const [fileName, setFileName] = useState(en.attachFile);
  const [fileID, setFileID] = useState();

  const handleChange = (field, value) => {
    handleOrderChange(field, value);
    if (field === "receiveNotes") {
      setIsMissingRequiredField(false);
    }
  };

  const getFile = async () => {
    const response =
      await purchaseOrderService.purchaseOrder.getAllPurchaseOrderAttachments(
        order.edits.id
      );
    if (response.status < HTTP_STATUSES.BAD_REQUEST && response.data.length) {
      setFileName(response.data[0].displayName);
      setFileID(response.data[0].id);
    }
  };

  useEffect(() => {
    getFile();
  }, []);

  const onShowFileUploadRequest = () => {
    const onCancelFileUpload = () =>
      setShowFileUpload({
        open: false,
      });
    setShowFileUpload({
      open: true,
      onCancelFileUpload,
    });
  };

  const supplierItem = [{ label: "accountName", value: 0 }];
  const supplierName = supplierItem.map((item) => {
    return {
      label: order.getValue(item.label),
      value: item.value,
      icon: (
        <Avatar className={classes.root}>
          {getInitials(order.getValue(item.label))}
        </Avatar>
      ),
    };
  });
  const receivedBy = contactsList.map((eachReceivedBy) => {
    return {
      label: eachReceivedBy.label,
      value: eachReceivedBy.value,
      icon: (
        <Avatar className={classes.root}>
          {getInitials(eachReceivedBy.label) || ""}
        </Avatar>
      ),
    };
  });

  return (
    <Grid container>
      <Grid item xs={3} className={classes.gridStyle}>
        <Grid>
          <SubHeader
            text={en.supplier}
            headerColor={theme.palette.primary.main}
          />
        </Grid>
        <Grid className={classes.formGrid}>
          <MiInputSelectBox
            labelTextColor={theme.palette.medium.bluegrey}
            labelText={en.supplier}
            menuItems={supplierName}
            defaultValue="0"
            required={true}
            disabled={true}
            handleChange={(e) => handleChange("accountName", e.target.value)}
            style={{ height: 40 }}
          />
        </Grid>
      </Grid>
      <Grid item xs={3} className={classes.gridStyle}>
        <Grid>
          <SubHeader
            text={en.receiving}
            headerColor={theme.palette.primary.main}
          />
        </Grid>
        <Grid className={classes.formGrid}>
          <MiInputSelectBox
            labelText={en.receivedBy}
            labelTextColor={theme.palette.medium.bluegrey}
            menuItems={receivedBy}
            name={order.getValue("contactName")}
            defaultValue={order.getValue("contactId")}
            required={true}
            handleChange={(e) => {
              handleChange("contactId", e.target.value);
            }}
            disableNone={true}
            style={{ height: 40 }}
          />
        </Grid>
        <Grid>
          <MiDatePickerInput
            type={"date"}
            labelText={en.receivedDate}
            labelTextColor={theme.palette.medium.bluegrey}
            value={getFormattedDate(order.getValue("receivedDate"))}
            handleChange={(val) => handleChange("receivedDate", val)}
            inputFormat="MM/DD/YYYY"
            required={true}
          />
        </Grid>
      </Grid>
      <Grid item xs={3} className={classes.gridStyle}>
        <Grid>
          <SubHeader
            text={en.details}
            headerColor={theme.palette.primary.main}
          />
        </Grid>
        <Grid className={classes.formGrid}>
          <MiInputSelectBox
            labelText={en.receivingLocation}
            labelTextColor={theme.palette.medium.bluegrey}
            menuItems={locationList}
            defaultValue={order.getValue("deliveryLocationId")}
            required={true}
            handleChange={(e) =>
              handleChange("deliveryLocationId", e.target.value)
            }
            disableNone={true}
          />
        </Grid>
        <Grid>
          <MiInputTextbox
            labelText={en.packingSlip}
            labelTextColor={theme.palette.medium.bluegrey}
            value={fileName}
            handleChange={(e) => setFileName(e.target.value)}
            required={true}
            onClick={onShowFileUploadRequest}
            InputProps={{
              classes: { adornedStart: classes.adornedIconStyle },
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={onShowFileUploadRequest}
                    className={classes.fileStylesAttach}
                    size="large"
                  >
                    <AttachFileIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={3} className={classes.gridStyle}>
        <Grid>
          <SubHeader text={en.notes} headerColor={theme.palette.primary.main} />
        </Grid>
        <Grid className={classes.formGrid}>
          <MiInputTextbox
            labelText={en.notes}
            labelTextColor={
              isMissingRequiredField
                ? theme.palette.secondary.red
                : theme.palette.medium.bluegrey
            }
            value={order.getValue("receiveNotes")}
            placeholder={"-"}
            handleChange={(e) => handleChange("receiveNotes", e.target.value)}
            helperText={isMissingRequiredField && en.enterNotes}
            error={isMissingRequiredField}
            rows={6}
            multiline
            required={true}
          />
        </Grid>
      </Grid>
      {
        <FileUploadModal
          {...showFileUpload}
          setFileName={setFileName}
          order={order.edits}
          fileID={fileID}
          setFileID={setFileID}
          getFile={getFile}
        />
      }
    </Grid>
  );
};
export default ReceivingPOForm;
