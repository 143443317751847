import React, { memo, useState, Fragment } from "react";
import { Virtuoso } from "react-virtuoso";
import {
  MiArea,
  MiAreaForm,
  MiStack,
  MiAreaItem,
  MiAreaItemForm,
  MiStackForm,
} from "../../../components/Standard/MiView";
import { useAreaStyles } from "../../../components/Standard/MiAreaStyles";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { usePlanItemUpdate } from "../hooks";
import { AreaItemAdd } from "../../../components/Standard/StyledComponents/AreaItemAdd";
import { PaddedDiv } from "../../../components/Standard/StyledComponents/PaddedDiv";
import { MaterialModalContainer } from "../components/MaterialModalContainer";
import { MiDragToZone, MiLoader } from "@miview/components";
import { planItemService, planAreaService } from "@miview/api";
import { useEdit } from "@miview/hooks";
import { HTTP_STATUSES } from "@miview/constants";

export const filterAndSortItems = (items, selectedStageId) => {
  if (items) {
    const filteredItems =
      Number(selectedStageId) === 0
        ? items
        : items.filter((i) => i.stageId === Number(selectedStageId));

    return filteredItems || [];
  }

  return [];
};

const PLANAREA_FORM_INITIAL_STATE = {
  categoryId: 0,
  planAreaId: 0,
  propertyPlanAreaId: 0,
  propertySectionId: 0,
  planAreaName: "",
  floorId: "",
  isGroundFloor: false,
  imageUrl: "",
  adminEdit: false,
  isRequired: false,
};

const PLANITEM_FORM_INITIAL_STATE = {
  itemId: "",
  areaId: "",
  itemName: "",
  description: "",
  topImageName: "",
  frontImageName: "",
  materialId: "",
  materialCategoryId: "",
  materialName: "",
  materialBrand: "",
  materialImageName: "",
  quantity: "",
  categoryId: "",
  walkTypeId: "",
  ruleId: "",
  isDeleted: "",
  type: undefined,
  measurementBackTypeId: "",
  measurementBackValue: "",
  measurementSideTypeId: "",
  measurementSideValue: "",
  stackSizeId: "",
  validateBack: true,
  validateSide: true,
};

export const MasterSpecPreview = memo(
  ({
    isMobile,
    itemDragging,
    setItemDragging,
    reorderItems,
    rightColumnHeight,
    setImagePreviewed,
    activeTab,
    propertySections,
    stages,
    floors,
    measurementTypesBack,
    setPlanAreasPreview,
    measurementTypesSide,
    stackSizes,
    materialBrands,
    materialCategories,
    handleSavePlanArea,
    planAreasPreview,
    planAreasLoading,
    refreshData,
    walkTypes,
    planId,
  }) => {
    const { classes } = useAreaStyles();
    const [selectedStageId, setSelectedStageId] = useState("0");
    const [addingNew, setAddingNew] = useState("");
    const canEditItems = activeTab === "Areas";
    const { deleteOrRestorePlanItem } = usePlanItemUpdate(setPlanAreasPreview);
    const [materialsModalOpen, setMaterialsModalOpen] = useState(false);
    const [optionConfiguring] = useState();
    const [selectedPlanItem, setSelectedPlanItem] = useState(
      PLANITEM_FORM_INITIAL_STATE
    );
    const [selectedPlanArea, setSelectedPlanArea] = useState(
      PLANAREA_FORM_INITIAL_STATE
    );
    const planItemEdit = useEdit(selectedPlanItem);
    const planAreaEdit = useEdit(selectedPlanArea);

    const savePlanItem = async () => {
      const response = await planItemService.update(
        selectedPlanItem.itemId,
        planItemEdit.edits
      );

      if (response.status === HTTP_STATUSES.OK) {
        setSelectedPlanItem(PLANITEM_FORM_INITIAL_STATE);
        planItemEdit.reset();
        refreshData();
      }
    };

    const createPlanItem = async () => {
      const response = await planItemService.create({
        ...planItemEdit.edits,
        planAreaId: selectedPlanItem.planAreaId,
        planId: planId,
      });

      if (response.status === HTTP_STATUSES.OK) {
        setSelectedPlanItem(PLANITEM_FORM_INITIAL_STATE);
        planItemEdit.reset();
        refreshData();
      }
    };

    const handleDeleteArea = async (id) => {
      const response = await planAreaService.delete(id);

      if (response.status === HTTP_STATUSES.OK) {
        refreshData();
      }
    };

    const savePlanArea = () => {
      handleSavePlanArea(selectedPlanArea.planAreaId, {
        ...selectedPlanArea,
        ...planAreaEdit.edits,
      });
      setSelectedPlanArea(PLANAREA_FORM_INITIAL_STATE);
      refreshData();
    };

    const onCancelAreaEdit = () => {
      planAreaEdit.reset();
      setSelectedPlanArea(PLANAREA_FORM_INITIAL_STATE);
    };

    const onCancelPlanItemEdit = () => {
      planItemEdit.reset();
      setSelectedPlanItem(PLANITEM_FORM_INITIAL_STATE);
      setAddingNew("");
    };

    const handleSelectedPlanItem = (item) => {
      setSelectedPlanItem(item);
    };

    const handleOnChangeStageId = (e) => {
      setSelectedStageId(e.target.value);
    };

    const handleAddItem = (planAreaId) => {
      planItemEdit.reset();
      setSelectedPlanItem({
        ...PLANITEM_FORM_INITIAL_STATE,
        planAreaId: planAreaId,
      });
      setAddingNew("area");
    };

    const renderAddNewItem = (handleAddClick, planAreaId) => {
      if (
        !planItemEdit.getValue("itemId") &&
        !planItemEdit.getValue("ruleId") &&
        planItemEdit.getValue("planAreaId") === planAreaId
      ) {
        if (addingNew === "Stack") {
          return renderStackForm("new");
        }

        return renderItemForm("new");
      }

      return (
        <PaddedDiv>
          <AreaItemAdd>
            <Button
              aria-label="add-planItem"
              className={classes.areaIconButton}
              style={{ marginRight: 10 }}
              onClick={handleAddClick}
            >
              <AddIcon />
            </Button>
          </AreaItemAdd>
        </PaddedDiv>
      );
    };

    const renderItem = (item, index, areaId, canReorder) => {
      if (item.measurementBackTypeId) {
        return (
          <MiStack
            isMasterSpecs
            areaId={areaId}
            index={index}
            itemDetails={item}
            key={item.itemId}
            walkTypes={walkTypes}
            setEditingItem={handleSelectedPlanItem}
            deleteOrRestoreItem={deleteOrRestorePlanItem}
            setImagePreviewed={setImagePreviewed}
            canEdit={canEditItems}
            canReorder={canReorder}
            setItemDragging={setItemDragging}
            stackSizes={stackSizes}
            measurementTypesBack={measurementTypesBack}
            measurementTypesSide={measurementTypesSide}
          />
        );
      }

      return (
        <MiAreaItem
          isMasterSpecs
          areaId={areaId}
          index={index}
          itemDetails={item}
          edit={planItemEdit}
          editMode={item.itemId === selectedPlanItem.itemId}
          key={item.itemId}
          walkTypes={walkTypes}
          setEditingItem={handleSelectedPlanItem}
          deleteOrRestoreItem={deleteOrRestorePlanItem}
          setImagePreviewed={setImagePreviewed}
          canEdit={canEditItems}
          editing={planItemEdit.getValue("itemId") === item.itemId}
          canReorder={canReorder}
          setItemDragging={setItemDragging}
        />
      );
    };

    const renderItemForm = (edit) => {
      return (
        <MiAreaItemForm
          key={edit}
          edit={planItemEdit}
          handleCancel={onCancelPlanItemEdit}
          handleUpdate={edit === "new" ? createPlanItem : savePlanItem}
          walkTypes={walkTypes}
          materialsModalOpen={materialsModalOpen}
          setMaterialsModalOpen={setMaterialsModalOpen}
        />
      );
    };

    const renderStackForm = (edit) => {
      return (
        <MiStackForm
          itemDetails={selectedPlanItem}
          key={edit}
          edit={planItemEdit}
          handleUpdate={savePlanItem}
          walkTypes={walkTypes}
          materialsModalOpen={materialsModalOpen}
          setMaterialsModalOpen={setMaterialsModalOpen}
          stackSizes={stackSizes}
          measurementTypesBack={measurementTypesBack}
          measurementTypesSide={measurementTypesSide}
        />
      );
    };

    const renderMiscItems = (items, planAreaId) => {
      const resolvedItems = filterAndSortItems(items, selectedStageId);

      return (
        <Fragment>
          <MiDragToZone
            index={0}
            areaId={planAreaId}
            itemDragging={itemDragging}
            reorderItems={reorderItems}
            setItemDragging={setItemDragging}
            filteredItems={resolvedItems}
          />
          {renderItems(resolvedItems, planAreaId, true)}
        </Fragment>
      );
    };

    const renderItems = (items, planAreaId, canReorder) => {
      if (items) {
        return (
          <Fragment>
            {items.map((item, index) => {
              if (selectedPlanItem.itemId === item.itemId) {
                if (item.measurementBackTypeId) {
                  return renderStackForm("edit");
                }
                return renderItemForm("edit");
              }

              return (
                <Fragment key={`${item.itemId}`}>
                  {renderItem(item, index, planAreaId, canReorder)}
                  {canReorder ? (
                    <MiDragToZone
                      index={index + 1}
                      itemDragging={itemDragging}
                      areaId={planAreaId}
                      reorderItems={reorderItems}
                      setItemDragging={setItemDragging}
                      filteredItems={items}
                    />
                  ) : null}
                </Fragment>
              );
            })}
          </Fragment>
        );
      }

      return null;
    };

    const renderArea = (section) => {
      if (
        section.planAreaId === selectedPlanArea.planAreaId &&
        selectedPlanArea.adminEdit
      ) {
        return (
          <MiAreaForm
            edit={planAreaEdit}
            savePlanArea={savePlanArea}
            floors={floors}
            propertySections={propertySections}
            isDeleted={section.isDeleted}
            isRule={section.planAreaOptionRuleId}
            onCancelAreaEdit={onCancelAreaEdit}
          />
        );
      }

      const handleClickEdit = () => {
        setSelectedPlanArea({
          ...section,
          adminEdit: true,
          type: "edit",
          planAreaName: section.name,
          imageUrl: section.imageName,
        });
      };

      const handleClickDelete = () => handleDeleteArea(section.planAreaId);

      const menuOptions = [
        {
          name: "Edit",
          action: handleClickEdit,
        },
        {
          name: "Delete",
          action: handleClickDelete,
        },
      ];

      return (
        <MiArea
          name={section.name}
          menuOptions={menuOptions}
          edit={planAreaEdit}
          onDoubleClick={handleClickEdit}
          imageUrl={section.imageName}
          canEdit={canEditItems}
          isDeleted={section.isDeleted}
          isRule={section.planAreaOptionRuleId}
          setImagePreviewed={setImagePreviewed}
        />
      );
    };

    const filteredPlanAreas = planAreasPreview.filter((a) =>
      !a.isDraft && optionConfiguring ? a.items.length : true
    );

    const renderAreas = (index) => {
      const section = filteredPlanAreas[index];

      const handleAddClick = () => {
        handleAddItem(section.planAreaId);
      };

      return (
        <div key={section.planAreaId} className="AreaContainer">
          {renderArea(section)}
          <div id="items">
            {renderMiscItems(section.items, section.planAreaId)}
            {canEditItems &&
              renderAddNewItem(handleAddClick, section.planAreaId)}
          </div>
        </div>
      );
    };

    const handleRenderAreas = () => {
      return filteredPlanAreas.map((area, i) => {
        return renderAreas(i);
      });
    };

    const renderPreviewData = () => {
      if (isMobile) {
        return handleRenderAreas();
      }

      return (
        <Virtuoso
          style={{ height: rightColumnHeight - 88 }}
          totalCount={filteredPlanAreas.length}
          item={(index) => renderAreas(index)}
        />
      );
    };

    return (
      <div onDragEnd={() => setItemDragging(false)}>
        <div style={styles.previewTitle}>
          <Fragment>
            Spec Preview
            {planAreasLoading && (
              <span style={styles.loader}>
                <MiLoader type="linear" />
              </span>
            )}
            <div style={styles.flexSpace} />
            <select
              id="stageSelect"
              style={styles.stageSelect}
              onChange={handleOnChangeStageId}
              value={selectedStageId}
            >
              <option value="0">All Stages</option>
              {stages.map((s) => {
                return (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                );
              })}
            </select>
          </Fragment>
        </div>
        {renderPreviewData()}
        <MaterialModalContainer
          materialsModalOpen={materialsModalOpen}
          setMaterialsModalOpen={setMaterialsModalOpen}
          materialCategories={materialCategories}
          materialBrands={materialBrands}
          edit={planItemEdit}
        />
      </div>
    );
  }
);

const styles = {
  previewTitle: {
    color: "#32C5FF",
    fontWeight: "bold",
    fontSize: 16,
    padding: 4,
    borderBottom: "1px solid #dddbda",
    margin: 6,
    display: "flex",
  },
  loader: {
    color: "black",
    fontSize: 14,
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    textAlign: "left",
  },
  raised: {
    zIndex: 1000,
  },
  flexSpace: {
    flex: 1,
  },
  stageSelect: {
    width: 120,
  },
};
