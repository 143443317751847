import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Fade from "@mui/material/Fade";
import { MiLoader } from "@miview/components";
import { useEventListener } from "../../utils";

const windowMedia = window.matchMedia("(max-width: 960px)");

const MiBuilderLayout = (props) => {
  const [isMobile, setIsMobile] = useState(windowMedia.matches);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [rightColumnFixed, setRightColumnFixed] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);

  const handleSetMobile = () => {
    if (isMobile !== windowMedia.matches) {
      return setIsMobile(windowMedia.matches);
    }

    if (window.innerHeight !== windowHeight) {
      setWindowHeight(window.innerHeight);
    }
  };

  const handleSetRightColumnStyling = () => {
    const stickyElem = document.getElementById("builderLayout-rightColumn");

    if (stickyElem?.offsetTop) {
      const currStickyPos = stickyElem.offsetTop;
      const variant = pageOffset > window.pageYOffset ? -36 : 60;

      if (window.pageYOffset + variant > currStickyPos) {
        setRightColumnFixed(true);
      } else {
        setRightColumnFixed(false);
      }
    } else {
      setRightColumnFixed(false);
    }

    setPageOffset(window.pageYOffset);
  };

  useEventListener("resize", handleSetMobile);
  useEventListener("scroll", handleSetRightColumnStyling);

  const rightColumnHeight = rightColumnFixed
    ? windowHeight - 218 + 96
    : windowHeight - 218;

  const getStickyStyling = () => {
    if (rightColumnFixed && !isMobile) {
      return {
        position: "fixed",
        top: 60,
        width: "calc(50% - 115px)",
        height: rightColumnHeight,
      };
    }

    return {
      position: "relative",
      top: "initial",
      height: isMobile ? "auto" : rightColumnHeight,
    };
  };

  return (
    <div ref={props.innerRef}>
      {props.header}
      {props.loading && <MiLoader type="linear" />}
      <Fade in={!props.loadingMessage}>
        <div style={styles.innerFade}>
          <div style={styles.content}>
            <Grid container spacing={1}>
              <Grid item md={props.rightColumnOn ? 6 : 12} xs={12}>
                <div
                  style={{
                    marginHorizontal: "1rem",
                    marginBottom: 10,
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    borderColor: "#DDD",
                    borderRadius: "0.25rem",
                    borderWidth: 1,
                    backgroundColor: "white",
                    borderStyle: "solid",
                    padding: "0.25rem",
                  }}
                >
                  {props.stepsBar}
                </div>
                <div style={styles.leftColumn}>{props.children}</div>
              </Grid>
              {props.rightColumnOn && (
                <Grid item md={6} xs={12}>
                  <div
                    id="builderLayout-rightColumn"
                    style={{
                      ...styles.rightColumnContainer,
                      ...getStickyStyling(),
                    }}
                  >
                    {props.rightColumn({ rightColumnHeight, isMobile })}
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </Fade>
    </div>
  );
};

MiBuilderLayout.propTypes = {
  children: PropTypes.object,
  rightColumn: PropTypes.func,
  stepsBar: PropTypes.object,
  header: PropTypes.object,
  loadingMessage: PropTypes.string,
  rightColumnOn: PropTypes.bool,
  rightMenu: PropTypes.func,
  rightMenuOpen: PropTypes.bool,
  setRightMenuOpen: PropTypes.func,
};

const styles = {
  innerFade: {
    padding: 10,
  },
  leftColumn: {
    marginBottom: 2,
    backgroundColor: "white",
    padding: 12,
    borderRadius: "5px",
  },
  content: {
    // paddingTop: 10,
  },
  networkIssue: {
    textAlign: "center",
    marginTop: 30,
    fontSize: 16,
  },
  rightColumnContainer: {
    borderRadius: 5,
    backgroundColor: "white",
    border: "1px solid rgb(221, 219, 218)",
  },
  rightMenuContainer: {
    position: "absolute",
    top: 207,
    right: 0,
    zIndex: 100,
    maxHeight: "100%",
    pointerEvents: "none",
  },
  rightMenuButton: {
    backgroundColor: "black",
    color: "white",
    display: "flex",
    padding: "4px 6px 4px 10px",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    transform: "rotate(270deg)",
    position: "relative",
    height: 34,
    cursor: "pointer",
    pointerEvents: "auto",
    width: 105,
    top: 85,
  },
  rightMenuButtonOpen: {
    left: -69,
  },
  rightMenuButtonClose: {
    left: 35,
  },
  rightMenu: {
    margin: "0 0px 5px 0px",
    minHeight: 500,
    backgroundColor: "white",
    position: "relative",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    // height: 640,
    // overflowY: "scroll",
    pointerEvents: "auto",
    border: "1px solid rgb(221, 219, 218)",
  },
  mobileSpecContainer: {
    borderRadius: 5,
    backgroundColor: "white",
    margin: 4,
    padding: 4,
  },
};

export default MiBuilderLayout;
