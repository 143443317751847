import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { MiIcon } from "../MiIcon/MiIcon";

const MuiRoot = styled("div")(({ theme }) => ({
  marginBottom: ".5rem",
  width: "100%",
  display: "flex",
  position: "relative",
  padding: 10,
  backgroundColor: theme.palette.lightShade.grey,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: ".25rem",
}));

const MuiTitle = styled("div")(({ theme }) => ({
  fontSize: ".875rem",
  margin: 0,
  padding: 0,
  fontWeight: "300",
  color: theme.palette.primary.grey,
}));

export const MiSectionHeader = ({
  title,
  icon,
  button,
  rightArea,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <div {...rest} data-testid="MiSectionHeader">
      <MuiRoot>
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon && (
            <MiIcon
              data-testid="MiSectionHeader-Icon"
              path={icon}
              style={{
                color: theme.palette.secondary.grey,
                fontSize: 20,
                marginRight: ".3rem",
              }}
            />
          )}
          <MuiTitle>{title}</MuiTitle>
        </div>
        {button}
        {rightArea}
      </MuiRoot>
    </div>
  );
};

MiSectionHeader.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  button: PropTypes.element,
  handleClick: PropTypes.func,
};
