import { optionService } from "@miview/api";

export const updatePropertyOptions = async ({
  propertyId,
  option,
  isChecked,
}) => {
  try {
    if (isChecked) {
      await optionService.removePropertyOption(option.optionId, propertyId);
    } else {
      await optionService.addPropertyOption(option.optionId, propertyId);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
