import React from "react";
import { makeStyles } from 'tss-react/mui';

const paddedDivStyles = {
  wrapper: { padding: "5px 10px 5px 10px" },
};
const usePaddedDivStyles = makeStyles()(paddedDivStyles);

export const PaddedDiv = (props) => {
  const { classes } = usePaddedDivStyles();
  return <div className={classes.wrapper}>{props.children}</div>;
};
