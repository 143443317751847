import React, { useState } from "react";
import { useRouter } from "@miview/hooks";
import { cityService } from "@miview/api";
import { MiPageHeader, MiList, MiLink, MiIcon } from "@miview/components";
import { mdiCityVariantOutline } from "@mdi/js";
import { useTheme } from "@mui/material";

const Cities = () => {
  const [citiesData, setCitiesData] = useState([]);
  const router = useRouter();
  const theme = useTheme();

  const openCity = (cityId) => {
    router.navigate(`/cities/${cityId}`);
  };

  const columns = [
    {
      field: "cityName",
      align: "left",
      headerName: "City",
      flex: 1,
      renderCell: (params) => {
        return (
          <MiLink
            onClick={(p) => {
              p.preventDefault();
              openCity(params.id);
            }}
            title={params.value}
          />
        );
      },
    },
    {
      field: "stateNameShort",
      headerName: "State",
      align: "left",
      renderCell: (p) => p.value,
      flex: 1,
    },
    {
      field: "numberOfCommunities",
      align: "left",
      headerName: "# of Communities",
      renderCell: (p) => p.value,
      flex: 1,
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiCityVariantOutline}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <div>
      <MiPageHeader
        title="Cities"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
      />
      <MiList
        headerTitle="Cities"
        headerIcon="fa-building"
        data={citiesData}
        setData={setCitiesData}
        dataProvider={cityService.getCities}
        columns={columns}
        getRowId={(row) => row.cityId}
        disableColumnMenu
      />
    </div>
  );
};

export default Cities;
