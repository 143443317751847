import React, { useState, useEffect } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { GOOGLE_API_KEY } from "@miview/constants";

const MapWrapper = (props) => {
  const {
    lat: latitude,
    lng: longitude,
    google,
    recenter,
    onCoordUpdate,
  } = props;
  const [center, setCenter] = useState({
    lat: 32.9879907,
    lng: -96.9271625,
  });
  const [markerPosition, setMarkerPosition] = useState();

  useEffect(() => {
    if (latitude && longitude) {
      setCenter({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (latitude && longitude) {
      setMarkerPosition({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [longitude, latitude]);

  useEffect(() => {
    if (latitude && longitude) {
      setCenter({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [recenter]);

  const onMapClick = (p, map, e) => {
    const { lat, lng } = e.latLng;
    onCoordUpdate(lat(), lng());
    setMarkerPosition({
      lat: lat(),
      lng: lng(),
    });
  };

  return (
    <div className="google-map" style={{ width: 320, height: 300 }}>
      <Map
        style={{ borderRadius: ".25rem", width: "100%", height: "100%" }}
        google={google}
        zoom={14}
        center={center}
        initialCenter={center}
        onReady={() => {
          if (latitude === undefined || longitude === undefined) {
            setMarkerPosition({
              lat: 32.98799,
              lng: -96.92716,
            });
          }
        }}
        onClick={(props, map, event) => {
          onMapClick(props, map, event);
        }}
      >
        <Marker name={"Current location"} position={markerPosition} />
      </Map>
    </div>
  );
};

export const MapContainer = GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(MapWrapper);
