import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Container } from "@mui/material";
import SupplierEdit from "./SupplierEdit";
import CustomerInfoForm from "../../../../components/CustomerInfoForm";
import TermsAndRatesForm from "../../../../components/TermsAndRatesForm";
import en from "../../../../locale/en.json";
import { accountService } from "@miview/api";
import { useEdit, useRouter } from "@miview/hooks";
import { HTTP_STATUSES } from "@miview/constants";
import { MiBox } from "@miview/components";

const useStyles = makeStyles()(() => ({
  maxWidthContainer: {
    maxWidth: "95%",
    marginTop: "25px",
    paddingBottom: 20,
  },
}));

const requiredFields = ["legalName", "paymentTermId"];

const SupplierPaymentInfo = (props) => {
  const { data, paymentTermsData, fetchData } = props;
  const [editMode, setEditMode] = useState(false);
  const [isMissingRequiredField, setIsMissingRequiredField] = useState(false);
  const [duplicateAccounts, setDuplicateAccounts] = useState(false);
  const router = useRouter();
  const edit = useEdit(data);
  const { classes } = useStyles();

  const { id } = router.query;

  const editModeOnChange = (mode) => {
    edit.reset();
    setEditMode(mode);
  };

  const handleOnSubmit = async () => {
    if (duplicateAccounts) {
      setIsMissingRequiredField(true);
      return;
    }
    if (edit.allFilled(...requiredFields)) {
      setIsMissingRequiredField(false);
      let updateData = { ...data, ...edit.edits };
      const response = await accountService.accounts.replace({
        id,
        data: updateData,
        successMessage: en.changesSaved,
        failureMessage: en.actionNotSuccessful,
      });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        setEditMode(false);
        fetchData();
      }
    } else {
      setIsMissingRequiredField(true);
    }
  };

  return (
    <MiBox>
      <Container className={classes.maxWidthContainer}>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <CustomerInfoForm
              editMode={editMode}
              edit={edit}
              isMissingRequiredField={isMissingRequiredField}
              setDuplicateAccounts={setDuplicateAccounts}
            />
          </Grid>
          <Grid item xs={6}>
            <TermsAndRatesForm
              editMode={editMode}
              edit={edit}
              billingRates={data?.billingRate}
              paymentTerms={paymentTermsData}
              isMissingRequiredField={isMissingRequiredField}
            />
          </Grid>
        </Grid>
        <SupplierEdit
          mode={editMode}
          onChange={editModeOnChange}
          onSave={handleOnSubmit}
          type={en.submit}
        />
      </Container>
    </MiBox>
  );
};
export default SupplierPaymentInfo;
