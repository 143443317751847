import React from "react";
import { THEME } from "../../../components/Standard/theme";
import { MiModal } from "@miview/components";
import { useTheme } from "@mui/material/styles";

export const CompleteWorkModal = (props) => {
  const { open, onClose, onConfirm, stageTypeName } = props;

  const theme = useTheme();

  return (
    <MiModal
      color={THEME.RED_PRIMARY}
      title={"Complete Walk"}
      width={500}
      onClose={onClose}
      open={open}
      actions={[
        {
          onClick: onClose,
          color: theme.palette.primary.grey,
          name: "Cancel",
        },
        {
          onClick: onConfirm,
          color: theme.palette.primary.main,
          name: "Confirm",
          inverse: true,
        },
      ]}
    >
      <p>
        The {stageTypeName} stage will be set to &quot;Ready for
        Inspection&quot; and any open walks and jobs will be Completed.
      </p>
      <p>Do you want to proceed?</p>
    </MiModal>
  );
};
