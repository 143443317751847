import React from "react";
import Grid from "@mui/material/Grid";
import { MiModal, MiChip } from "@miview/components";

export const JsonModal = (props) => {
  const { title, selected, actions, open, onClose, ...rest } = props;

  const jsonParsed = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return undefined;
    }
  };

  const renderJson = () => {
    const jsonVal = jsonParsed(selected?.resultRaw);
    if (jsonVal) {
      return (
        <Grid container>
          <pre>{JSON.stringify(jsonVal, null, 4)}</pre>
        </Grid>
      );
    }
    return (
      <Grid>
        <MiChip
          label={
            "There is no JSON or there was an error attempting to view JSON"
          }
        />
      </Grid>
    );
  };

  return (
    <MiModal
      title={title}
      actions={actions}
      open={open}
      onClose={onClose}
      {...rest}
    >
      {renderJson()}
    </MiModal>
  );
};
