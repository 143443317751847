import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MiIcon } from "@miview/components";

const ButtonIconText = ({ path, text, size, color, onClick }) => {
  const theme = useTheme();
  color = color || theme.palette.secondary.bluegrey;
  return (
    <Box display="flex">
      <MiIcon
        path={path}
        size={size}
        color={color}
        fontSize="small"
        onClick={onClick}
      />
      <Typography style={{ marginLeft: 10 }}>{text}</Typography>
    </Box>
  );
};

ButtonIconText.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ButtonIconText.defaultProps = {
  size: 1,
};

export default React.memo(ButtonIconText);
