import { useState } from "react";
import { useComponentState } from "../useComponentState/useComponentState";
import { createUUID, groupBy, sumField } from "@miview/utils";
import { propertyService, propertyStageService } from "@miview/api";
import { HTTP_STATUSES, CYCLE_TYPES } from "@miview/constants";
import moment from "moment";

const stagesUuid = createUUID();

const getDiff = (start, end) => {
  return moment(end).diff(moment(start), "days");
};

const mapIntervalModel = (intervalArray, stage) => {
  return intervalArray.map((x, i, array) => {
    const initialStageDays = getDiff(stage?.scheduledStartDate, x.startDate);
    const days = getDiff(x.startDate, x.endDate);
    return {
      ...x,
      typeName: CYCLE_TYPES[x.type]?.label || "Deprecated Types",
      initialGap: i === 0 && initialStageDays > 0 ? initialStageDays : null,
      days: days,
      gapDiff:
        array.length === i + 1
          ? null
          : getDiff(x.endDate, array[i + 1]?.startDate),
    };
  });
};

const getStageIntervalModel = (response, stages) => {
  const intervalDictionary = {};
  response.forEach((item, index) => {
    if (!item.data?.length) {
      return;
    }
    const grouped = groupBy(item.data, (item) => item["type"]);
    const intervalSections = Object.entries(grouped)
      .map(([type, intervals], index) => {
        if (type.includes("Issue")) {
          return null;
        }
        const mapped = mapIntervalModel(intervals, stages[index]);
        const CYCLE = CYCLE_TYPES[type] || {};
        return {
          ...CYCLE,
          type,
          intervals: mapped,
          totalDays: sumField(mapped, "days"),
        };
      })
      .filter((x) => x);
    intervalDictionary[stages[index].id] = intervalSections;
  });
  return intervalDictionary;
};

export const usePropertyStages = () => {
  const [stages, setStages] = useState([]);
  const [stagesMessage, setStagesMessage] = useState("Loading...");
  const [stageIntervals, setStageIntervals] = useState({});

  const stateManager = useComponentState();

  const getStages = async (propertyId) => {
    stateManager.abort(stagesUuid);
    await stateManager.run(async (signal) => {
      setStagesMessage("Loading...");
      setStages([]);

      const stagesResponse = await propertyService.getStages({
        id: propertyId,
        signal,
      });

      if (stagesResponse.status !== HTTP_STATUSES.OK) {
        return setStagesMessage("Stages unavailable");
      }

      if (stagesResponse.data.length < 1) {
        setStagesMessage("No stages found");
        setStages([]);
      }

      setStagesMessage("");
      setStages(stagesResponse.data);
      await getStageIntervals(stagesResponse.data);
    }, stagesUuid);
  };

  const getStageIntervals = async (pstages) => {
    if (!pstages.length) {
      return;
    }
    const promiseIntervals = pstages.map((p) =>
      propertyStageService.getStatusIntervals(p.id)
    );

    const results = await Promise.all(promiseIntervals);
    setStageIntervals(getStageIntervalModel(results, pstages));
  };

  return {
    getStages,
    getStageIntervals,
    stages,
    stagesMessage,
    stageIntervals,
  };
};
