export const SCHEDULING_NAV = {
  items: [
    {
      name: "Homes",
      url: "/scheduling/homes",
      icon: "Home",
    },
    {
      name: "Communities",
      url: "/scheduling/communities",
      icon: "Apartment",
    },
    {
      permissionName: "CanViewPlan",
      name: "Plans",
      url: "/scheduling/plans",
      icon: "Home",
    },
    {
      permissionName: "CanViewMaterials",
      name: "Materials",
      url: "/scheduling/materials",
      icon: "DynamicFeed",
    },
    {
      name: "Builders",
      url: "/scheduling/builders",
      icon: "HomeWork",
    },
    {
      name: "Imported Work",
      url: "/scheduling/importedwork",
      // TODO fix icon
      icon: "List",
    },
  ],
};
