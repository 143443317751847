const { makeStyles } = require("tss-react/mui");

export const useRoleDetailsStyles = makeStyles()(() => ({
  frame: { padding: "1rem" },
  flexbox: { display: "flex" },
  columnPadding: { paddingRight: "20px" },
  contentSection: { display: "flex", justifyContent: "space-between" },
  triColumn: { paddingRight: "20px", flex: "1 0 33%" },
  propertyColumn: { paddingRight: "20px", flex: "1 0 40%" },
  buttonColumn: {
    paddingRight: "20px",
    display: "flex",
    justifyContent: "space-between",
    flex: "1 0 20%",
  },
  commandFooter: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "1rem",
  },
}));
