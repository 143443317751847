import React, { useEffect, useState } from "react";
import { stageTypeService } from "@miview/api";
import { useTheme } from "@mui/material";
import { PAGINATION, HTTP_STATUSES, TOAST_TYPES } from "@miview/constants";
import { useEdit } from "@miview/hooks";
import { createToast } from "@miview/toast";
import {
  MiPageHeader,
  MiLink,
  MiIcon,
  MiList,
  MiDetailFields,
  MiModal,
  MiButton,
  MiModalConfirm,
} from "@miview/components";
import { mdiDelete, mdiPageNextOutline, mdiPlus } from "@mdi/js";

const INITIAL_VALUES = {
  name: "",
  order: "",
  description: "",
  abbreviation: "",
  scheduleNextStage: false,
};

const StageManagement = () => {
  const [stages, setStages] = useState();
  const [stageId, setStageId] = useState();
  const [stageName, setStageName] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const edit = useEdit(INITIAL_VALUES);
  const theme = useTheme();

  useEffect(() => {
    getStageTypes();
  }, []);

  const getStageTypes = async () => {
    const response = await stageTypeService.getAll();
    if (response.status === HTTP_STATUSES.OK) {
      setStages(response.data);
    }
  };

  const toggleIsAddModalOpen = () => {
    setIsAddModalOpen(!isAddModalOpen);
    edit.reset();
  };

  const toggleDelete = (cell) => {
    if (cell) {
      setStageId(cell.id);
      setStageName(cell.name);
    } else {
      setStageId(undefined);
      setStageName(undefined);
    }
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const deleteStage = async () => {
    const response = await stageTypeService.delete(stageId);
    if (response.status === HTTP_STATUSES.OK) {
      createToast("Successful", TOAST_TYPES.SUCCESS);
      getStageTypes();
      toggleDelete();
    }
  };

  const saveNewStage = async () => {
    const response = await stageTypeService.create(edit.edits);
    if (response.status === HTTP_STATUSES.OK) {
      createToast("Successful", TOAST_TYPES.SUCCESS);
      getStageTypes();
      toggleIsAddModalOpen();
    }
  };

  const columns = [
    {
      field: "name",
      flex: 1,
      headerName: "Stage",
      // eslint-disable-next-line react/display-name
      renderCell: (e) => (
        <MiLink
          style={{ marginLeft: "0px", paddingLeft: "0px" }}
          to={`/stagetypes/${e.row.id}`}
          title={e.value}
        />
      ),
    },
    {
      field: "description",
      flex: 3,
      headerName: "Description",
    },
    {
      field: "abbreviation",
      flex: 0.7,
      headerName: "Abbreviation",
    },
    {
      field: "order",
      flex: 0.4,
      headerName: "Order",
    },
    {
      field: "scheduleNextStage",
      flex: 1,
      headerName: "Schedule Next Stage",
      valueGetter: (params) => (params.row.scheduleNextStage ? "Yes" : "No"),
    },
  ];

  const getRowActions = () => {
    return [
      {
        text: "DELETE",
        onClick: (e) => toggleDelete(e),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
    ];
  };

  const modalFields = [
    {
      label: "Stage Name",
      value: edit.getValue("name"),
      setValue: (e) => edit.update({ name: e }),
      width: "100%",
      required: true,
    },
    {
      label: "Description",
      value: edit.getValue("description"),
      setValue: (e) => edit.update({ description: e }),
      width: "100%",
      required: true,
    },
    {
      label: "Abbreviation",
      value: edit.getValue("abbreviation"),
      setValue: (e) =>
        edit.update({ abbreviation: e.toString().substring(0, 5) }),
      width: "100%",
      required: true,
    },
    {
      label: "Order",
      value: edit.getValue("order"),
      setValue: (e) => edit.update({ order: e }),
      fieldType: "number",
      width: "100%",
      required: true,
    },
    {
      label: "Schedule Next Stage",
      value: edit.getValue("scheduleNextStage"),
      setValue: (e) => edit.update({ scheduleNextStage: e }),
      fieldType: "checkbox",
      width: "100%",
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiPageNextOutline}
        color={theme.palette.primary.blue}
        size={1}
      />
    );
  };

  const headerContent = () => (
    <MiButton
      title="Stage"
      icon={mdiPlus}
      inverse={true}
      onClick={toggleIsAddModalOpen}
      color={theme.palette.primary.main}
    />
  );

  return (
    <>
      <MiPageHeader
        title={"Stage Management"}
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.blue}
        action={headerContent()}
      />
      <MiList
        style={{ minHeight: "100vh" }}
        data={stages}
        columns={columns}
        searchColumns={["name"]}
        paginationMode={PAGINATION.CLIENT}
        getRowActions={getRowActions}
      />
      <MiModal
        title="Create A New Stage"
        open={isAddModalOpen}
        onClose={toggleIsAddModalOpen}
        actions={[
          {
            name: "Cancel",
            onClick: toggleIsAddModalOpen,
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: saveNewStage,
            color: theme.palette.primary.green,
            inverse: true,
            disabled: !edit.allFilled(
              "name",
              "description",
              "abbreviation",
              "order"
            ),
          },
        ]}
      >
        <MiDetailFields detailFields={modalFields} />
      </MiModal>
      <MiModalConfirm
        title={"Confirm Delete"}
        description={`Delete Stage: ${stageName}?`}
        open={isDeleteModalOpen}
        handlePositive={deleteStage}
        handleNegative={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
};

export default StageManagement;
