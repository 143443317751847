import React, { useEffect, useState } from "react";
import { Image } from "../index.ts";
import { getImageUrl } from "@miview/utils";
import { useCdn } from "@miview/hooks";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  fadeIn: {
    animation: "$fadeIn .3s ease-in-out",
  },
  fadeOut: {
    animation: "$fadeOut .3s ease-out",
  },
  scaleIn: {
    animation: "$scaleIn2 .3s ease-in-out",
    maxHeight: "70%",
    [theme.breakpoints.down("lg")]: { maxHeight: "60%" },
  },
  scaleOut: {
    animation: "$scaleOut2 .3s ease-in-out",
    maxHeight: "70%",
    [theme.breakpoints.down("lg")]: { maxHeight: "60%" },
  },
  "@keyframes fadeIn": { "0%": { opacity: 0 }, "100%": { opacity: 0.6 } },
  "@keyframes fadeOut": { "0%": { opacity: 0.6 }, "100%": { opacity: 0 } },
  "@keyframes scaleIn2": {
    "0%": { transform: "scale(0.5)" },
    "100%": { transform: "scale(1)" },
  },
  "@keyframes scaleOut2": {
    "0%": { transform: "scale(1)" },
    "100%": { transform: "scale(0.5)" },
  },
}));

export const ImageViewer = (props) => {
  const { fullScreenStatus, onUnfullscreen, images, selectedImage } = props;
  const [zoomedIn, setZoomedIn] = useState(false);
  const [fullScreenActive, setFullScreenActive] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  const cdn = useCdn();
  const { classes: miClasses } = useStyles({ fullScreenActive });

  useEffect(() => {
    if (fullScreenStatus) {
      fullscreen();
    } else if (!fullScreenStatus) {
      unfullscreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenStatus]);

  useEffect(() => {
    const selectedIndex = images.indexOf(selectedImage);
    if (selectedIndex !== -1) {
      setActiveImage(selectedIndex);
    } else {
      setActiveImage(0);
    }
  }, [images, selectedImage]);

  const fullscreen = () => {
    setZoomedIn(true);
    setTimeout(() => {
      setFullScreenActive(true);
    }, 200);
  };

  const unfullscreen = () => {
    setZoomedIn(false);
    setTimeout(() => {
      onUnfullscreen();
      setFullScreenActive(false);
    }, 200);
  };

  const handleClick = (event, index) => {
    event.stopPropagation();
    setActiveImage(index);
  };

  if (!fullScreenActive) {
    return null;
  }

  return (
    <div
      style={{
        zIndex: 99999999999,
        width: "100%",
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0,0,0,0.5)",
      }}
      onClick={() => unfullscreen()}
      className={zoomedIn ? miClasses.fadeIn : miClasses.fadeOut}
    >
      <div
        id="scaleWrapper"
        className={zoomedIn ? miClasses.scaleIn : miClasses.scaleOut}
        style={{ backgroundColor: "white" }}
      >
        <img
          alt=""
          src={getImageUrl(images[activeImage], cdn)}
          style={{
            boxShadow: "1rem 1rem 1rem rgba(0,0,0,0.7)",
            maxHeight: "100%",
          }}
        />
      </div>
      {images.length > 1 && (
        <div style={{ display: "flex", margin: 20 }}>
          {images.map((image, index) => (
            <Image
              key={index}
              imgSrc={image}
              onClick={(event) => handleClick(event, index)}
              style={{ padding: 5 }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
