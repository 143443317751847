import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import "./toast.css";
import { Snackbar, Alert } from "@mui/material";

export const Toast = inject(({ globalToastStore }) => ({
  store: globalToastStore,
}))(
  observer(({ store = {} }) => {
    const { message, type, options = {}, messageId } = store;
    const [open, setOpen] = useState(false);

    useEffect(() => {
      if (message) {
        setOpen(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageId]);
    return (
      <Snackbar
        className="toast"
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        {...options}
      >
        <Alert variant="filled" severity={type}>
          {message}
        </Alert>
      </Snackbar>
    );
  })
);
