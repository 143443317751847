import { apiOptions, apiRequest } from "../utils";
import { API_V3 } from "../constants";

export const notificationService = {
  getRecent: async (params) => {
    const options = await apiOptions({
      url: `${API_V3}/notifications/recent`,
      method: "get",
      params,
    });

    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/notifications/${id}`,
      method: "patch",
      data: request,
    });

    return apiRequest(options);
  },
};
