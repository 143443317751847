import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

export const MiToolTip = ({ title, placement, children, ...rest }) => {
  return (
    <Tooltip title={title} placement={placement} {...rest}>
      {children}
    </Tooltip>
  );
};

MiToolTip.propTypes = {
  /**
   * Text inside the toolTip
   */
  title: PropTypes.string,
  /**
   * position of the toolTip
   */
  placement: PropTypes.string,
  /**
   * toolTip will surround this children element
   */
  children: PropTypes.element.isRequired,
};

MiToolTip.defaultProps = {
  /**
   * position of the toolTip. Default value set to 'bottom'
   */
  placement: "bottom",
};
