import React from "react";
import { MiModalConfirm } from "@miview/components";
import { useTheme } from "@mui/styles";

const DeleteContactModal = ({
  showDeleteContactModal,
  selectedContact,
  handleDeletePropertyContact,
  closeDeleteContactModal,
}) => {
  const theme = useTheme();
  return (
    <MiModalConfirm
      color={theme.palette.primary.red}
      title={"Confirm Delete"}
      handlePositive={() => handleDeletePropertyContact()}
      handleNegative={() => closeDeleteContactModal()}
      description={`Delete the following Contact: ${selectedContact.fullName} - ${selectedContact.title}`}
      open={showDeleteContactModal}
    />
  );
};

export default DeleteContactModal;
