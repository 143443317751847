import React from "react";
import PropTypes from "prop-types";
import { MiLink } from "../MiLink/MiLink";
import { makeStyles } from "tss-react/mui";
import { Divider } from "@mui/material";
import { MiIcon } from "../MiIcon/MiIcon";
import { ORIENTATION } from "@miview/constants";
import { getValueFormat } from "@miview/utils";

const useStyles = makeStyles()((theme, { props }) => {
  return {
    miFieldsetMainContainer: {
      display: "flex",
      flexDirection:
        props.orientation === ORIENTATION.VERTICAL ? "column" : "row",
      flexWrap: "wrap",
      marginTop: "12px",
      marginLeft: "5px",
    },
    miFieldsetSubContainer: {
      display: "flex",
      marginRight: "10px",
      color: theme.palette.primary.blue,
    },
    miFieldsetLabel: {
      color: theme.palette.primary.blue,
      display: "flex",
      marginRight: "3px",
    },

    miFieldsetDividerContainer: {
      marginLeft: "10px",
    },
  };
});

export const MiFieldSet = (props) => {
  const {
    hasDivider,
    dividerOrientation,
    fieldSetArray,
    containerStyle,
    labelStyle,
  } = props;
  const { classes } = useStyles({ props });

  return (
    <div className={classes.miFieldsetMainContainer} style={containerStyle}>
      {fieldSetArray.map((item, index) => {
        if (!item.value) {
          return null;
        }
        const dividerLength = hasDivider && index + 1 !== fieldSetArray.length;
        return (
          <div
            key={index}
            title={item.label}
            className={classes.miFieldsetSubContainer}
          >
            {item.icon && (
              <MiIcon path={item.icon.iconPath} color={item.icon.iconColor} />
            )}
            {item.label && (
              <div className={classes.miFieldsetLabel} style={labelStyle}>
                {item.label + ": "}
              </div>
            )}
            {item.to ? (
              <MiLink to={item.to} title={item.value} />
            ) : (
              <div className={classes.miFieldsetLabel} style={labelStyle}>
                {item.value}
              </div>
            )}
            {item.fieldType && (
              <div>{getValueFormat(item.value, item.fieldType)}</div>
            )}
            {dividerLength && (
              <Divider
                orientation={dividerOrientation}
                className={classes.miFieldsetDividerContainer}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

MiFieldSet.propTypes = {
  hasDivider: PropTypes.bool,
  orientation: PropTypes.string,
  dividerOrientation: PropTypes.string,
  fieldSetArray: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.object,
      value: PropTypes.any,
      to: PropTypes.string,
      fieldType: PropTypes.string,
    })
  ),
  containerStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  defaultLabelStyle: PropTypes.object,
};

MiFieldSet.defaultProps = {
  hasDivider: true,
  orientation: ORIENTATION.HORIZONTAL,
  dividerOrientation: ORIENTATION.VERTICAL,
  fieldSetArray: [],
  containerStyle: {},
  labelStyle: {},
  defaultLabelStyle: {},
};
