import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_PAY_DISPUTES = `${API_V3}/paydisputes`;
const API_COUNTS = `/count`;

export const payDisputeService = {
  getAll: async (params, filters) => {
    const options = await apiOptions({
      url: `${API_PAY_DISPUTES}`,
      method: "get",
      params,
      filters,
    });
    const result = await apiRequest(options);
    return result;
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_PAY_DISPUTES}/${id}`,
    });
    const result = await apiRequest(options);
    return result;
  },

  getCounts: async (params) => {
    const options = await apiOptions({
      url: `${API_PAY_DISPUTES}${API_COUNTS}`,
      params,
    });
    const result = await apiRequest(options);
    return result;
  },

  approve: async (id, data) => {
    const options = await apiOptions({
      url: `${API_PAY_DISPUTES}/${id}/approve`,
      method: "post",
      data,
    });
    const result = await apiRequest(options);
    return result;
  },

  decline: async (id, data) => {
    const options = await apiOptions({
      url: `${API_PAY_DISPUTES}/${id}/decline`,
      method: "post",
      data,
    });
    const result = await apiRequest(options);
    return result;
  },

  create: async (id, data) => {
    const options = await apiOptions({
      url: `${API_PAY_DISPUTES}/${id}/disputes`,
      method: "post",
      data,
    });
    const result = await apiRequest(options);
    return result;
  },

  update: async (id, data) => {
    const options = await apiOptions({
      url: `${API_PAY_DISPUTES}/${id}`,
      method: "patch",
      data,
    });
    const result = await apiRequest(options);
    return result;
  },
};
