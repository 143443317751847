/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  MiPageHeader,
  MiBox,
  MiInputSelectBox,
  MiIconButton,
  MiIcon,
} from "@miview/components";
import { Divider, Grid, Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { itemService, pricingService, accountService } from "@miview/api";
import { mdiPencil, mdiCheck, mdiClose } from "@mdi/js";
import moment from "moment";
import en from "../.././../locale/en.json";
import { HTTP_STATUSES } from "@miview/constants";
import { useComponentState } from "@miview/hooks";

const useStyles = makeStyles()((theme) => ({
  inputFieldContainer: {
    marginTop: 5,
    width: "40%",
    minWidth: 200,
  },
  icon: {
    color: theme.palette.medium.green,
    fontSize: theme.fontSize.xlarge,
  },
  categoryName: {
    marginTop: 10,
  },
  centerText: {
    display: "flex",
    alignItems: "center",
  },
  listHeader: {
    color: theme.palette.primary.teal,
  },
}));

export const PreferredVendor = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const stateManager = useComponentState();

  const [allVendors, setAllVendors] = useState([]);
  const [preferredVendors, setPreferredVendors] = useState({});
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [selectedPreferredVendorId, setSelectedPreferredVendorId] = useState();
  const [categories, setCategories] = useState([]);

  const getPrefferedVendors = async () => {
    stateManager.run(async () => {
      const vendorResponse = await pricingService.vendors.getAll();

      if (vendorResponse.status === HTTP_STATUSES.OK) {
        const preferredVendorsDictionary = Object.assign(
          {},
          ...vendorResponse.data.map((x) => ({ [x.itemCategoryId]: x }))
        );
        setPreferredVendors(preferredVendorsDictionary);
      }
    });
  };

  const getAllVendors = async () => {
    stateManager.run(async () => {
      const accountsResponse = await accountService.accounts.getAll();

      if (accountsResponse.status === HTTP_STATUSES.OK) {
        const allVendorsInfo = accountsResponse.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setAllVendors(allVendorsInfo);
      }
    });
  };

  const getAllCategories = async () => {
    stateManager.run(async () => {
      const masterItemResponse = await itemService.itemMaster.getCategories({
        expand: "all",
      });

      if (masterItemResponse.status === HTTP_STATUSES.OK) {
        setCategories(masterItemResponse.data);
      }
    });
  };

  useEffect(() => {
    getPrefferedVendors();
    getAllVendors();
    getAllCategories();
  }, []);

  const handleSubmit = async () => {
    if (preferredVendors[selectedCategory]) {
      return pricingService.vendors.update(
        { accountId: selectedAccount, itemCategoryId: selectedCategory },
        selectedPreferredVendorId,
        en.vendorUpdate
      );
    } else {
      const createPreferredVendorPayload = {
        accountId: selectedAccount,
        itemCategoryId: selectedCategory,
        status: "Active",
        effectiveDate: moment(new Date()).format("MM/DD/YYYY"),
      };
      return pricingService.vendors.create(
        selectedCategory,
        createPreferredVendorPayload,
        en.vendorUpdate
      );
    }
  };

  const onHandleClick = (categoryId, vendorId, preferredVendorId) => {
    setSelectedCategory(categoryId);
    setSelectedAccount(vendorId);
    setSelectedPreferredVendorId(preferredVendorId);
  };

  const onSaveClick = async () => {
    const response = await handleSubmit();
    if (response.status === HTTP_STATUSES.OK) {
      const updatedVendors = {
        ...preferredVendors,
        [selectedCategory]: response?.data,
      };
      setPreferredVendors({ ...updatedVendors });
    }
    onCancelClick();
  };

  const onCancelClick = () => {
    setSelectedAccount(null);
    setSelectedCategory(null);
    setSelectedPreferredVendorId(null);
  };

  const renderVendor = (categoryId) => {
    if (categoryId === selectedCategory) {
      return (
        <Grid>
          <MiInputSelectBox
            className={useStyles.inputFieldContainer}
            menuItems={allVendors}
            valueField={"value"}
            labelField={"label"}
            value={selectedAccount || ""}
            handleChange={(e) => {
              setSelectedAccount(e.target.value || null);
            }}
          ></MiInputSelectBox>
        </Grid>
      );
    }
    return (
      <Grid item lg={6}>
        {preferredVendors[categoryId]
          ? preferredVendors[categoryId].accountName
          : "---"}
      </Grid>
    );
  };
  const List = () => {
    return (
      <Grid style={{ padding: 10 }}>
        <Grid item xs={12} className={classes.row}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant="body1" className={classes.listHeader}>
                {"Product Line"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" className={classes.listHeader}>
                {"Preferred Vendor"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {categories.map((category, index) => (
            <Grid key={index}>
              <Divider />
              <Grid
                container
                xs={12}
                key={category.id}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={3}>
                  {category.name}
                </Grid>
                <Grid item xs={4}>
                  <Grid container direction="row" alignItems="center" xs={12}>
                    {renderVendor(category.id)}
                    {category.id === selectedCategory ? (
                      <Grid item direction="row" alignItems="center">
                        <MiIconButton onClick={onSaveClick}>
                          <MiIcon path={mdiCheck} color={"green"} />
                        </MiIconButton>
                        <MiIconButton onClick={onCancelClick}>
                          <MiIcon path={mdiClose} color={"red"} />
                        </MiIconButton>
                      </Grid>
                    ) : (
                      <MiIconButton
                        onClick={() =>
                          onHandleClick(
                            category.id,
                            preferredVendors[category.id]?.accountId,
                            preferredVendors[category.id]?.id
                          )
                        }
                      >
                        <MiIcon path={mdiPencil} />
                      </MiIconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid>
      <MiPageHeader
        title={en.preferredVendor}
        color={theme.palette.primary.teal}
        hasDivider={true}
        loading={stateManager.isBusy()}
      />
      <MiBox>{allVendors && preferredVendors && categories && <List />}</MiBox>
    </Grid>
  );
};
