import React from "react";
import { makeStyles } from "tss-react/mui";
import { MiInputSelectBox } from "@miview/components";

const useStyles = makeStyles()((theme) => ({
  componentCustomSelect: {
    width: "100%",

    "& > div > div": {
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%",
    },

    "& > div > div > label": {
      position: "relative",
      left: "0",
      top: "0",
      marginBottom: 5,
      color: theme.palette.primary.teal,
    },

    "& > div > div > label + div > div": {
      backgroundColor: `${theme.palette.primary.white}!important`,
    },

    "& fieldset": {
      border: "none",
    },
  },
}));

export const TdSelectBox = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.componentCustomSelect}>
      <MiInputSelectBox {...props} />
    </div>
  );
};

export default TdSelectBox;
