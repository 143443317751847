import { apiOptions, apiRequest } from "../../index.js";
import { API_HEADERS_TEMP, API_INTEGRATION_SERVICE_URL } from "../../constants";

const API_RUNS = `runs`;

export const runService = {
  getAll: async (params, filters) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_RUNS}`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  get: async ({ id, params, filters }) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_RUNS}/${id}`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  getRunDetails: async ({ id, params, filters }) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_RUNS}/${id}/rundetails`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
};
