import React from "react";
import { makeStyles } from "tss-react/mui";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const useStyles = makeStyles()((theme) => ({
  root: { display: "flex", alignItems: "center" },
  phoneNumberStyle: {
    marginRight: 10,
  },
  blue: {
    color: theme.palette.primary.blue,
  },
}));

export const TdPhoneNumber = ({ value, active }) => {
  const { classes, cx } = useStyles();
  return (
    <div className={classes.root}>
      <LocalPhoneIcon
        className={
          active
            ? cx(classes.phoneNumberStyle, classes.blue)
            : classes.phoneNumberStyle
        }
      />
      {value}
    </div>
  );
};

export default TdPhoneNumber;
