import {
  RED,
  PINK,
  BLUE,
  TEAL,
  GREEN,
  GREY,
  ORANGE,
  BLUEGREY,
  LIGHTBLUE,
  WHITE,
  PURPLE,
  INDIGO,
  AMBER,
  YELLOW,
  BLACK,
  DEEPPURPLE,
} from "../defaultColors";

export const colors = {
  main: {
    primary: BLUE[500],
    secondary: BLUEGREY[800],
    accent: GREY[500],
    light: BLUE[50],
  },
  button: {
    primary: BLUE[500],
    secondary: GREEN[500],
    accent: GREY[500],
  },
  header: {
    primary: BLUE[500],
    secondary: GREEN[500],
    accent: GREY[500],
    dark: BLUE[700],
  },
  bluegrey: {
    primary: BLUEGREY[800],
    secondary: BLUEGREY[900],
    dark: BLUEGREY[700],
    medium: BLUEGREY[600],
    accent: BLUEGREY[500],
    mediumAccent: BLUEGREY["a500"],
    lightAccent: BLUEGREY[300],
    light: BLUEGREY[50],
    mediumLight: BLUEGREY[200],
    lightShade: BLUEGREY[100],
    mediumShade: BLUEGREY[400],
  },
  blue: {
    primary: BLUE[500],
    secondary: BLUE[700],
    mediumAccent: BLUE[800],
    dark: BLUE[900],
    accent: BLUE["a700"],
    light: BLUE[50],
    medium: BLUE[600],
    darkAccent: BLUE["a900"],
    primaryShade: BLUE["a500"],
  },
  grey: {
    primary: GREY[700],
    secondary: GREY[500],
    medium: GREY[400],
    accent: GREY[300],
    dark: GREY[900],
    lightAccent: GREY[200],
    light: GREY[50],
    mediumLight: GREY[100],
    darkAccent: GREY[800],
    lightShade: GREY[100],
    mediumDark: GREY[600],
  },
  orange: {
    primary: ORANGE[700],
    medium: ORANGE[300],
    secondary: ORANGE[500],
    accent: ORANGE["a700"],
    light: ORANGE[50],
  },
  green: {
    primary: GREEN[800],
    medium: GREEN[600],
    secondary: GREEN[500],
    accent: GREEN["a700"],
    light: GREEN[50],
  },
  teal: {
    primary: TEAL[600],
    secondary: TEAL[300],
    light: TEAL[50],
    dark: TEAL[900],
    darkAccent: TEAL[800],
    medium: TEAL[700],
  },
  red: {
    primary: RED[900],
    secondary: RED[500],
    accent: RED["a700"],
    darkAccent: RED["a900"],
    light: RED[50],
    medium: RED[700],
  },
  pink: {
    primary: PINK[500],
    light: PINK[200],
  },
  lightblue: {
    primary: LIGHTBLUE[800],
    secondary: LIGHTBLUE["a50"],
  },
  white: {
    primary: WHITE,
  },
  black: {
    primary: BLACK,
  },
  purple: {
    primary: PURPLE[900],
    secondary: PURPLE[600],
    light: PURPLE[50],
    accent: PURPLE["a400"],
    medium: DEEPPURPLE[300],
  },
  indigo: {
    primary: INDIGO["a200"],
    light: INDIGO[50],
  },
  overlay: {
    primary: "rgba(0, 0, 0, 0.5)",
    secondary: "rgba(0, 0, 0, 0.2)",
    light: "rgba(255, 255, 255, 0.2)",
  },
  amber: {
    primary: AMBER[500],
    light: AMBER[50],
  },
  yellow: {
    medium: YELLOW[500],
  },
};
