import React from "react";
import { MiModal, MiDetailFields } from "@miview/components";
import { useTheme } from "@mui/material";
import { useEdit, useComponentState, useRouter } from "@miview/hooks";
import { walkService } from "@miview/api";
import { createToast } from "@miview/toast";
import { HTTP_STATUSES, TOAST_TYPES } from "@miview/constants";

const RescheduleModal = ({ onClose, open, walkId }) => {
  const theme = useTheme();
  const stateManager = useComponentState();
  const edit = useEdit();
  const router = useRouter();

  const onModalClose = () => {
    edit.reset();
    onClose();
  };

  const handleReschedule = () => {
    stateManager.run(async () => {
      const response = await walkService.reschedule(walkId, {
        scheduledStartDate: edit.getValue("nextAttemptScheduledStartDate"),
        cancelReason: edit.getValue("cancelReason"),
      });
      if (response.status === HTTP_STATUSES.OK) {
        onModalClose();
        router.navigate(`/walks/${response.data}`);
      }
      if (response.status === HTTP_STATUSES.NO_CONTENT) {
        createToast(
          "Inspection can't be rescheduled when the stage is closed",
          TOAST_TYPES.WARNING
        );
        onModalClose();
      }
    });
  };

  const rescheduleFields = [
    {
      label: "Rescheduled Date",
      value: edit.getValue("nextAttemptScheduledStartDate"),
      required: true,
      setValue: (e) => edit.update({ nextAttemptScheduledStartDate: e }),
      width: "100%",
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
    },
    {
      label: "Reschedule Reason",
      value: edit.getValue("cancelReason"),
      setValue: (e) => edit.update({ cancelReason: e }),
      multiline: true,
      required: true,
      rows: 4,
      fieldType: "text",
      width: "100%",
      minWidth: 0,
    },
  ];

  return (
    <MiModal
      title="Reschedule"
      open={open}
      onClose={onModalClose}
      actions={[
        {
          name: "Cancel",
          style: { minWidth: 90 },
          onClick: onModalClose,
          color: theme.palette.primary.grey,
          inverse: false,
        },
        {
          name: "Submit",
          onClick: handleReschedule,
          style: { minWidth: 90 },
          color: theme.palette.primary.main,
          disabled:
            !edit.allFilled("nextAttemptScheduledStartDate", "cancelReason") ||
            stateManager.isBusy(),
          inverse: true,
        },
      ]}
      loading={stateManager.isBusy()}
    >
      <MiDetailFields detailFields={rescheduleFields} />
    </MiModal>
  );
};

export default RescheduleModal;
