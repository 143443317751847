import React from "react";
import { Grid, useTheme } from "@mui/material";
import { SubHeader } from ".";
import en from "../locale/en.json";
import { MiInputTextbox, MiInputPhone } from "@miview/components";
import { makeStyles } from "tss-react/mui";
import { isPhoneNumber, isValidEmail } from "@miview/utils";

const useStyles = makeStyles()(() => ({
  formAlign: {
    marginTop: 15,
    paddingRight: 10,
  },
}));

const GeneralContactForm = ({ editMode, edit, isMissingRequiredField }) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const phoneError =
    isMissingRequiredField &&
    (!edit.getValue("phone") || !isPhoneNumber(edit.getValue("phone")));
  const mobileError =
    isMissingRequiredField &&
    edit.getValue("mobile") &&
    !isPhoneNumber(edit.getValue("mobile"));
  const faxError =
    isMissingRequiredField &&
    edit.getValue("fax") &&
    !isPhoneNumber(edit.getValue("fax"));
  const emailError =
    isMissingRequiredField &&
    edit.getValue("email") &&
    !isValidEmail(edit.getValue("email"));

  return (
    <Grid container>
      <Grid item xs={12}>
        <SubHeader text={en.generalContact} />
      </Grid>
      <Grid item xs={6} className={classes.formAlign}>
        <MiInputPhone
          labelTextColor={
            phoneError
              ? theme.palette.secondary.red
              : theme.palette.primary.main
          }
          labelText={en.phone}
          required
          error={phoneError}
          helperText={phoneError ? "Phone number is not valid" : ""}
          disabled={!editMode}
          minWidth={150}
          value={edit.getValue("phone")}
          handleChange={(e) => edit.update({ phone: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} className={classes.formAlign}>
        <MiInputPhone
          labelTextColor={theme.palette.primary.main}
          labelText={en.mobile}
          error={mobileError}
          helperText={mobileError ? "Phone number is not valid" : ""}
          disabled={!editMode}
          value={edit.getValue("mobile")}
          handleChange={(e) => edit.update({ mobile: e.target.value })}
          minWidth={150}
        />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.Email}
          disabled={!editMode}
          error={emailError}
          value={edit.getValue("email")}
          helperText={emailError ? "Enter a valid email" : ""}
          handleChange={(e) => edit.update({ email: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} className={classes.formAlign}>
        <MiInputPhone
          labelTextColor={theme.palette.primary.main}
          labelText={en.fax}
          disabled={!editMode}
          minWidth={150}
          error={faxError}
          helperText={faxError ? "Phone number is not valid" : ""}
          value={edit.getValue("fax")}
          handleChange={(e) => edit.update({ fax: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.website}
          disabled={!editMode}
          value={edit.getValue("website")}
          handleChange={(e) => edit.update({ website: e.target.value })}
          minWidth={150}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(GeneralContactForm);
