import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const stageTypeService = {
  getAll: async ({ signal, params, sort } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/stagetypes`,
      method: "get",
      params: params,
      signal,
      sort,
    });
    return apiRequest(options);
  },

  get: async ({ id, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/stagetypes/${id}`,
      method: "get",
      signal,
    });

    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/stagetypes`,
      method: "post",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure creating stage type",
      },
      success: {
        message: "Stage Type created successfully",
      },
    };

    return apiRequest(options, toastMessageConfig);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/stagetypes/${id}`,
      method: "patch",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving stage type",
      },
      success: {
        message: "Stage Type saved successfully",
      },
    };

    return apiRequest(options, toastMessageConfig);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/stagetypes/${id}`,
      method: "delete",
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure deleting stage type",
      },
      success: {
        message: "Stage Type deleted successfully",
      },
    };

    return apiRequest(options, toastMessageConfig);
  },
};
