import { styled } from "@mui/system";

export const PlanItemImageLabel = styled("div")({
  fontSize: "0.8rem",
  color: "rgb(150, 148, 146)",
  marginBottom: "4px",
  paddingTop: "4px",
  textAlign: "center",
  textTransform: "capitalize",
  cursor: "pointer",
  "&:hover": { color: "blue", textDecoration: "underline" },
});

export const PlanItemImageFocus = styled("div")({
  border: "none",
  backgroundColor: "white",
  position: "relative",
  height: "88px",
  padding: "0 6px 6px",
  "&:focus": {
    zIndex: 10,
    boxShadow: "0 0 0 5px rgba(21, 156, 228, 0.4)",
  },
});
