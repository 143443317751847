import { useState } from "react";
import { propertyService, systemTypeService, planService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const usePlanOptions = (planId, propertyId) => {
  const [planOptions, setPlanOptions] = useState(propertyId ? {} : []);
  const [optionTypes, setOptionTypes] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState({
    availableOptions: [],
  });
  const [propertyOptionsLoading, setPropertyOptionsLoading] = useState(false);

  const handleGetOptionsByProperty = async () => {
    try {
      setPropertyOptionsLoading(true);
      const response = await propertyService.getOptions(propertyId);
      if (response.status === HTTP_STATUSES.OK) {
        setPropertyOptions(response.data);
        setPlanOptions(response.data.customOptions);
      }
    } finally {
      setPropertyOptionsLoading(false);
    }
  };

  const handleGetOptionsByPlan = async () => {
    const response = await planService.getOptions(planId);
    if (response.status === HTTP_STATUSES.OK) {
      setPlanOptions(response.data);
    }
  };

  const handleGetOptionTypes = async () => {
    try {
      setPropertyOptionsLoading(true);
      const response = await systemTypeService.getSystemTypesByName({
        name: "OptionType",
      });
      if (response.status === HTTP_STATUSES.OK) {
        setOptionTypes(
          response.data.map((t) => ({
            ...t,
            value: t.systemTypeId,
            text: t.mainValue,
          }))
        );
      }
    } finally {
      setPropertyOptionsLoading(false);
    }
  };

  return {
    planOptions,
    setPlanOptions,
    propertyOptions,
    setPropertyOptions,
    optionTypes,
    setOptionTypes,
    propertyOptionsLoading,
    handleGetOptionsByProperty,
    handleGetOptionTypes,
    handleGetOptionsByPlan,
  };
};
