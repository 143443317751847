import React, { useState } from "react";
import { CreateEditTradeModal } from "./CreateEditTradeModal";
import { DeleteTradeModal } from "./DeleteTradeModal";
import { tradeService } from "@miview/api";
import { useTheme } from "@mui/material/styles";
import { MiPageHeader, MiButton, MiList, MiIcon } from "@miview/components";
import { PAGINATION } from "@miview/constants";
import { mdiHammerWrench, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";

const Trades = () => {
  const [trades, setTrades] = useState([]);
  const [isCreateTradeModalOpen, setIsCreateTradeModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [refresh, setRefresh] = useState(false);

  const theme = useTheme();

  const closeCreateTradeModal = () => {
    setSelectedRow({});
    setIsCreateTradeModalOpen(false);
    setRefresh(!refresh);
  };

  const closeDeleteTradeModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedRow({});
    setRefresh(!refresh);
  };

  const columns = [
    {
      field: "name",
      align: "left",
      headerName: "Trade Name",
      renderCell: (p) => p.value,
      flex: 1,
    },
  ];

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => {
          setSelectedRow(row);
          setIsDeleteModalOpen(true);
        },
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
      {
        text: "EDIT",
        onClick: () => {
          setSelectedRow(row);
          setIsCreateTradeModalOpen(true);
        },
        renderIcon: () => <MiIcon path={mdiPencil} size={1} />,
      },
    ];
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiHammerWrench}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Trade"
        icon={mdiPlus}
        inverse={true}
        onClick={() => setIsCreateTradeModalOpen(true)}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <div>
      <MiPageHeader
        title="Trades"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
      />
      <MiList
        data={trades}
        setData={setTrades}
        dataProvider={tradeService.getAll}
        getRowActions={getRowActions}
        actionIconStyle={{ color: theme.palette.secondary.grey }}
        columns={columns}
        refresh={refresh}
        defaultPageSize={50}
        searchColumns={["name"]}
        paginationMode={PAGINATION.CLIENT}
      />
      <CreateEditTradeModal
        open={isCreateTradeModalOpen}
        onClose={closeCreateTradeModal}
        selection={selectedRow}
      />
      <DeleteTradeModal
        open={isDeleteModalOpen}
        onClose={closeDeleteTradeModal}
        selection={selectedRow}
      />
    </div>
  );
};

export default Trades;
