export * from "./en";
export * from "./es";
export const LOCALE = {
  en: "en",
  es: "es",
};
export const LOCALES = [
  { label: "English", value: LOCALE.en },
  { label: "Spanish", value: LOCALE.es },
];
