import React, { useEffect, useState } from "react";
import {
  walkTypeTemplateService,
  propertySectionService,
  systemTypeService,
  walkTypeTemplateItemService,
} from "@miview/api";
import { MiModal, MiDetailFields, MiRadioGroup } from "@miview/components";
import { useEdit, useComponentState } from "@miview/hooks";
import { toList } from "@miview/utils";
import { HTTP_STATUSES } from "@miview/constants";
import { useTheme } from "@mui/styles";

export const CreateTemplateItemModal = (props) => {
  const { item, close, walkTypeTemplateId, isEditModalOpen } = props;

  const forceFields = [
    {
      value: 0,
      label: "Force Picture",
      forcePicture: true,
      forceDocument: false,
    },
    {
      value: 1,
      label: "Force Document",
      forcePicture: false,
      forceDocument: true,
    },
    { value: 2, label: "None", forcePicture: false, forceDocument: false },
  ];

  const [propertySections, setPropertySections] = useState([]);
  const [drawingTypes, setDrawingTypes] = useState([]);
  const [drawingCategoryTypes, setDrawingCategoryTypes] = useState([]);

  const isNew = !item;

  const theme = useTheme();
  const edit = useEdit(item);
  const stateManager = useComponentState();

  const getPropertySections = () => {
    stateManager.run(async () => {
      const response = await propertySectionService.getAll({
        params: { orderBy: "name" },
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "id", "name");
        setPropertySections(mapped);
      }
    });
  };

  const getDrawingTypes = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: "DrawingType",
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "systemTypeId", "mainValue");
        setDrawingTypes(mapped);
      }
    });
  };

  const getDrawingCategories = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: "DrawingCategoryType",
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "systemTypeId", "mainValue");
        setDrawingCategoryTypes(mapped);
      }
    });
  };

  useEffect(() => {
    getPropertySections();
    getDrawingTypes();
    getDrawingCategories();
  }, []);

  const createWalkTemplateItem = async () => {
    await stateManager.run(async () => {
      await walkTypeTemplateService.createItem(walkTypeTemplateId, edit.edits);
    });
    close(true);
  };

  const updateWalkTemplateItem = async () => {
    await stateManager.run(async () => {
      await walkTypeTemplateItemService.replace(edit.getValue("id"), {
        ...item,
        ...edit.edits,
      });
    });
    close(true);
  };

  const setForceFieldsChange = (id) => {
    edit.update({
      forcePicture: forceFields[Number(id)].forcePicture,
      forceDocument: forceFields[Number(id)].forceDocument,
    });
  };

  const getSelectedCheckboxValue = () => {
    const forcePicture = edit.getValue("forcePicture");
    const forceDocument = edit.getValue("forceDocument");

    if (forcePicture !== null && forceDocument !== null) {
      const checkedItem = forceFields.find(
        (item) =>
          item.forcePicture === forcePicture &&
          item.forceDocument === forceDocument
      );
      return checkedItem.value;
    } else {
      return Number(2);
    }
  };

  const saveWalkTemplateItem = () => {
    if (isNew) {
      createWalkTemplateItem();
    } else {
      updateWalkTemplateItem();
    }
  };

  const detailFields = [
    {
      label: "Item Name",
      field: "itemName",
      value: edit.getValue("name"),
      setValue: (e) => edit.update({ name: e }),
      required: true,
      width: "100%",
    },
    {
      label: "Description",
      field: "description",
      value: edit.getValue("description"),
      setValue: (e) => edit.update({ description: e }),
      required: true,
      width: "100%",
    },
    {
      label: "Property Section",
      field: "propertySection",
      fieldType: "select",
      options: propertySections,
      value: edit.getValue("propertySectionId"),
      setValue: (e) => {
        edit.update({ propertySectionId: e });
      },
      required: true,
      width: "50%",
    },
    {
      label: "Display Order",
      field: "displayOrder",
      value: edit.getValue("displayOrder"),
      setValue: (e) => edit.update({ displayOrder: e }),
      width: "50%",
    },
    {
      label: "Document Type",
      field: "drawing-type-select",
      fieldType: "select",
      value: edit.getValue("documentTypeId"),
      setValue: (e) => {
        if (e !== "") {
          edit.update({ documentTypeId: e });
        } else {
          edit.update({ documentTypeId: null });
        }
      },
      options: drawingTypes,
      selectNone: true,
      width: "50%",
    },
    {
      label: "Document Category",
      field: "category-select",
      fieldType: "select",
      value: edit.getValue("documentCategoryId"),
      setValue: (e) => {
        if (e !== "") {
          edit.update({ documentCategoryId: e });
        } else {
          edit.update({ documentCategoryId: null });
        }
      },
      options: drawingCategoryTypes,
      selectNone: true,
      width: "50%",
    },
    {
      label: "Image",
      field: "attachment-file",
      fieldType: "image",
      value: edit.getValue("image") || edit.getValue("imageName"),
      setValue: (e) => {
        edit.update({
          image: e.base64,
          fileName: e.fileName,
          fileType: e.fileType,
        });
      },
      width: "25%",
    },
    {
      label: "Image Description",
      value: edit.getValue("imageDescription"),
      setValue: (e) => edit.update({ imageDescription: e }),
      width: "50%",
    },
    {
      label: "Can have punch item",
      fieldType: "checkbox",
      value: edit.getValue("canHavePunchItem"),
      setValue: () =>
        edit.update({
          canHavePunchItem: !edit.getValue("canHavePunchItem"),
        }),
      width: "25%",
      minWidth: 240,
    },
    {
      label: "Editable",
      fieldType: "checkbox",
      value: edit.getValue("editable"),
      setValue: () => edit.update({ editable: !edit.getValue("editable") }),
      width: "15%",
      minWidth: 150,
    },
    {
      label: "Can N/A",
      fieldType: "checkbox",
      value: edit.getValue("canNa"),
      setValue: () => edit.update({ canNa: !edit.getValue("canNa") }),
      width: "15%",
      minWidth: 150,
    },
    {
      label: "Repeat",
      fieldType: "checkbox",
      value: edit.getValue("repeat"),
      setValue: () => edit.update({ repeat: !edit.getValue("repeat") }),
      width: "15%",
      minWidth: 150,
    },
  ];

  return (
    <MiModal
      key={0}
      title={isNew ? "Create Template Item" : "Edit Template Item"}
      open={isEditModalOpen}
      onClose={() => close(true)}
      actions={[
        {
          name: "Cancel",
          style: { minWidth: 90 },
          onClick: () => close(true),
          color: theme.palette.medium.grey,
          inverse: false,
        },
        {
          name: "Save",
          onClick: saveWalkTemplateItem,
          style: { minWidth: 90 },
          color: theme.palette.primary.main,
          inverse: true,
          disabled: !edit.allFilled("name", "description", "propertySectionId"),
        },
      ]}
    >
      <MiDetailFields detailFields={detailFields} />
      <MiRadioGroup
        data={forceFields}
        handleChange={setForceFieldsChange}
        orientation="row"
        value={getSelectedCheckboxValue()}
      />
    </MiModal>
  );
};
