import { apiOptions, apiRequest } from "../../index.js";
import { API_HEADERS_TEMP, API_INTEGRATION_SERVICE_URL } from "../../constants";

const API_INTEGRATION_TYPES = `/integrationTypes`;

export const integrationTypes = {
  getAll: async (params, filters) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATION_TYPES}`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  get: async (name) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATION_TYPES}/${name}`,
      method: "get",
      headers: API_HEADERS_TEMP,
    });
    return apiRequest(options);
  },
};
