import { colors } from "../miColors";
import { palette } from "../miPalette";
import { breakpoint, zIndex, shadows as boxShadows } from "../miStandard";
import { fontWeight, fontSize } from "../miFontstyles";
import flexStyles from "../flexStyles";

export const MiTHEME = {
  colors,
  palette,
  zIndex,
  breakpoint,
  fontSize,
  fontWeight,
  boxShadows,
  flexStyles,
  typography: {
    fontWeightLight: fontWeight.light,
    fontWeightRegular: fontWeight.regular,
    fontWeightMedium: fontWeight.medium,
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "red" },
      },
    },
  },
};
