export const PAY_TYPES = {
  SUBCONTRACTOR: "SubContractor",
  HOURLY: "Hourly",
  EXEMPT: "Exempt",
  NOTAPPLICABLE: "NotApplicable",
};

export const PAY_TYPE_OPTIONS = [
  {
    label: "Sub-contractor",
    value: PAY_TYPES.SUBCONTRACTOR,
  },
  {
    label: "Hourly",
    value: PAY_TYPES.HOURLY,
  },
  {
    label: "Exempt",
    value: PAY_TYPES.EXEMPT,
  },
  {
    label: "Not Applicable",
    value: PAY_TYPES.NOTAPPLICABLE,
  },
];
