import React, { useState, useEffect } from "react";
import { createFilter } from "@miview/utils";
import { pricingService } from "@miview/api";
import { HTTP_STATUSES, en } from "@miview/constants";
import { MiTable } from "@miview/components";
import { useTheme } from "@mui/material";
import { PriceCompareDrawer } from "./PriceCompareDrawer";

export const PriceCompareTable = (props) => {
  const { quoteItems } = props;

  const [rows, setRows] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const theme = useTheme();

  const getItemPrices = async () => {
    if (!quoteItems?.length) {
      return;
    }
    const filterBuilder = createFilter();
    filterBuilder.equals({ itemId: quoteItems.map((e) => e.itemId) });
    filterBuilder.greaterThanOrEqual({ currentQuotePrice: 0 });
    const response = await pricingService.quotes.getAccountItems({
      filters: filterBuilder.getFilter(),
      sort: [
        { sort: "asc", field: "itemId" },
        { sort: "asc", field: "currentQuotePrice" },
      ],
    });
    if (response.status === HTTP_STATUSES.OK) {
      const newRows = quoteItems.map((e) => {
        return { ...e };
      });
      let lastItemId = null;
      for (const itemPrice of response.data) {
        if (lastItemId === itemPrice.itemId || !itemPrice.currentQuotePrice) {
          continue;
        }
        lastItemId = itemPrice.itemId;
        const row = newRows.find((e) => e.itemId === itemPrice.itemId);
        row.lowestPrice = itemPrice.currentQuotePrice;
        row.lowestAccount = itemPrice.accountName;
        row.percentDifference =
          100 *
          (1 - Number(itemPrice.currentQuotePrice) / Number(row.quotePrice));
      }
      setRows(newRows);
    }
  };

  useEffect(() => {
    getItemPrices();
  }, [quoteItems]);

  const compareColumns = [
    {
      field: "itemName",
      headerName: en.itemNumber,
      flex: 1,
    },
    {
      field: "description",
      headerName: en.description,
      flex: 1,
    },
    {
      field: "quotePrice",
      headerName: "Quoted Cost",
      minWidth: 190,
      flex: 1,
      renderCell: (params) => {
        return <div>${params.value}</div>;
      },
    },
    {
      field: "lowestPrice",
      headerName: "Lowest Price",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <div>{params.row.accountName}</div>
            <div>${params.value}</div>
            <div
              style={{
                color: params.row.percentDifference < 0 ? "red" : "green",
              }}
            >
              {isNaN(params.row.percentDifference)
                ? "--"
                : Math.abs(params.row.percentDifference).toFixed(2)}
              %
            </div>
          </div>
        );
      },
    },
  ];

  const closeCompareDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedRow();
  };

  if (!quoteItems?.length) {
    return <div>No items found</div>;
  }
  return (
    <>
      <PriceCompareDrawer
        open={isDrawerOpen}
        itemId={selectedRow?.itemId}
        itemNumber={selectedRow?.itemName}
        comparePrice={selectedRow?.quotePrice}
        onClose={closeCompareDrawer}
      />
      <MiTable
        columns={compareColumns}
        rows={rows}
        disableColumnMenu={true}
        disableSelectionOnClick
        hideFooter={true}
        autoHeight={true}
        onRowClick={(e) => {
          setIsDrawerOpen(true);
          setSelectedRow(e.row);
        }}
        sx={{
          minHeight: "inherit",
          border: "inherit",
          "& .MuiDataGrid-columnHeader": {
            background: theme.palette.lightShade.grey,
            minHeight: 60,
          },
          "& .MuiDataGrid-footerContainer p": {
            marginBottom: "0px",
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </>
  );
};
