import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Popover,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";

const useStyles = makeStyles()((theme) => ({
  filterLabel: {
    color: theme.palette.primary.bluegrey,
    fontWeight: theme.fontWeight.regular,
    marginRight: 23,
  },
  formControlLabel: {
    color: theme.palette.secondary.bluegrey,
    fontWeight: theme.fontWeight.medium,
    marginBottom: 8,
  },
  popOverForm: {
    paddingTop: 16,
    paddingLeft: 24,
    minWidth: 240,
  },
  checkBox: {
    paddingTop: 4,
    paddingBottom: 4,
  },
}));

export const MiListFilterMenu = ({
  label,
  values,
  selectedValues,
  onChange,
  onClose,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { classes } = useStyles();

  const handlePopOverClose = () => {
    if (onClose) {
      onClose();
    }
    setAnchorEl(null);
  };

  const item = (li, index) => {
    const checked = selectedValues.find((sItem) => sItem === li) !== undefined;
    return (
      <FormControlLabel
        key={index}
        control={
          <Checkbox
            disableRipple
            color="primary"
            name={li}
            checked={checked}
            onChange={onChange}
            className={classes.checkBox}
          />
        }
        label={li}
      />
    );
  };

  const openPopOver = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={openPopOver}
      >
        <Typography className={classes.filterLabel}>{label}</Typography>
        <Icon path={mdiChevronDown} size={1} />
      </Box>
      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={handlePopOverClose}
        disableRestoreFocus
      >
        <FormControl className={classes.popOverForm}>
          <Typography className={classes.formControlLabel}>{label}</Typography>
          <FormGroup>{values.map((li, index) => item(li, index))}</FormGroup>
        </FormControl>
      </Popover>
    </>
  );
};

MiListFilterMenu.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};
