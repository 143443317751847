import { createTheme as createMuiTheme } from "@mui/material/styles";
import { MiTHEME } from "../miTheme";

export const createTheme = () =>
  createMuiTheme({
    spacing: (factor) => `${0.25 * factor}`,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    zIndex: {
      modal: 1000,
    },
    ...MiTHEME,
  });
