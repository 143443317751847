import React, { useState, useEffect } from "react";
import { NewPropertyInfoCollapse } from "./NewPropertyInfoCollapse";
import { WalkHistoryCollapse } from "./WalkHistoryCollapse";
import { AssigneesCollapse } from "./AssigneesCollapse";
import { DrawingsCollapse } from "./DrawingsCollapse";
import { PunchListsCollapse } from "./PunchListsCollapse";
import { StageInfoCollapse } from "./StageInfoCollapse";
import { InspectionsCollapse } from "./InspectionsCollapse";
import PropertyActivity from "./PropertyActivity";
import { MiHeader } from "../../../components/Standard/MiView";
import {
  MiLink,
  MiMultiSelect,
  MiButton,
  MiTab,
  MiBox,
} from "@miview/components";
import MessageCollapse from "../../../components/Standard/MessageCollapse/MessageCollapse.js";
import { useTabStyles } from "../../../utils";
import { useRouter, useComponentState } from "@miview/hooks";
import { propertyService } from "@miview/api";
import {
  INSPECTIONS,
  INSPECTIONJOBS,
  DETAILS,
  ISSUES,
  WALKS,
  JOBS,
  STAGES,
  HTTP_STATUSES,
} from "@miview/constants";
import { useTheme } from "@mui/styles";

const Property = (props) => {
  const { match } = props;
  const { classes } = useTabStyles();
  const router = useRouter();
  const stateManager = useComponentState();
  const [property, setProperty] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [activeTab, setActiveTab] = useState(DETAILS);
  const [activeSecondaryTab, setActiveSecondaryTab] = useState("Documents");

  const [resetCoords, setResetCoords] = useState();
  const [picklist, setPicklist] = useState();
  const theme = useTheme();

  const tabs = [
    { value: "Details", label: "Details" },
    { value: "Issues", label: "Issues" },
    { value: "Walks", label: "Walks" },
    { value: "Jobs", label: "Jobs" },
    { value: "Stages", label: "Stages" },
    { value: "Inspections", label: "Inspections" },
    { value: "Insp. Jobs", label: "Insp. Jobs" },
  ];
  const docsTabs = [
    { value: "Documents", label: "Documents" },
    { value: "Contacts", label: "Contacts" },
    { value: "Messaging", label: "Messaging" },
    { value: "Activities", label: "Activities" },
  ];

  const { id: propertyId, activeTab: activeTabParam } = router.params;

  useEffect(() => {
    document.title = property
      ? `Home - ${property.addressLine1}`
      : "MiView - Homes";
  });

  useEffect(() => {
    refresh();
  }, [propertyId]);

  useEffect(() => {
    setActiveTab(activeTabParam || DETAILS);
  }, [match]);

  useEffect(() => {
    if (resetCoords && property != null) {
      setResetCoords(false);
    }
  }, [resetCoords]);

  const refresh = () => {
    stateManager.run(async () => {
      const response = await propertyService.get(propertyId);
      if (response.status === HTTP_STATUSES.OK) {
        setProperty(response.data);
        setupStatusPicklist(response.data);
      }
    });
  };

  const setupStatusPicklist = (updatedProperty) => {
    stateManager.run(async () => {
      const response = await propertyService.getSetupStatuses();
      if (response.status === HTTP_STATUSES.OK) {
        const setupStatuses = updatedProperty.customFields?.find(
          (f) => f.name === "PropertySetupStatus"
        )?.value;

        let pickles = [];
        if (setupStatuses) {
          pickles = response.data.map((x) => ({
            id: x.id,
            value:
              setupStatuses.filter((s) => s.value && s.id === x.id).length > 0,
            label: x.name,
          }));
        } else {
          pickles = response.data.map((x) => ({
            id: x.id,
            value: false,
            label: x.name,
          }));
        }
        setPicklist(pickles);
      }
    });
  };

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const handleHomeSpecClick = () => {
    router.navigate(`/homes/${propertyId}/specs/0`);
  };

  const handleClickTab = (tab) => {
    router.navigate(`/homes/${propertyId}/${tab}`);
    setActiveTab(tab);
  };
  const onPicklistClick = async (id) => {
    stateManager.run(async () => {
      const newValue = updateTimeline(id);
      //make call
      const response = await propertyService.updatePropertySetupStatus(
        property.propertyId,
        JSON.stringify({ id: id, value: newValue })
      );
      //handle response
      if (response === false) {
        handleErrorTimeline(id);
      }
    });
  };

  const updateTimeline = (id) => {
    if (Array.isArray(picklist)) {
      const update = [...picklist];
      const index = update.findIndex((j) => j.id === id);
      update[index].value = !update[index].value;
      setPicklist(update);

      return update[index].value;
    }
  };

  const handleErrorTimeline = (id) => {
    updateTimeline(id);
  };

  const handleGoBack = () => {
    router.navigate(`/homes`);
  };

  return (
    <div>
      {stateManager.statusTag("propertyDetailsStatus")}
      {property.propertyId && (
        <MiHeader
          color={theme.palette.primary.blue}
          title={`${property.addressLine1}, ${property.cityName}`}
          inverse={false}
          icon="arrow_back"
          backAction={handleGoBack}
          fieldSet={[
            {
              label: "Community",
              value: (
                <MiLink
                  to={`/communities/${property.communityId}`}
                  title={property.communityName}
                  style={{ padding: 0, margin: 0 }}
                />
              ),
            },
            {
              label: "Builder",
              value: (
                <MiLink
                  to={`/builder/${property.builderId}`}
                  title={property.builderName}
                  style={{ padding: 0, margin: 0 }}
                />
              ),
            },
            { label: "ZipCode", value: property.zipCode },
            {
              label: "Plan",
              value: (
                <MiLink
                  to={`/plans/${property.planId}`}
                  title={property.planName}
                  style={{ padding: 0, margin: 0 }}
                />
              ),
            },
          ]}
        >
          <MiButton
            title="Configure Spec"
            inverse={false}
            color={theme.palette.primary.purple}
            disabled={!property.planId}
            onClick={handleHomeSpecClick}
            variant="outlined"
          />
        </MiHeader>
      )}
      <MiBox styles={{ margin: "16px 16px 8px 16px" }}>
        <MiMultiSelect picklist={picklist} action={onPicklistClick} />
      </MiBox>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0 0.5rem",
          width: "100%",
        }}
      >
        <MiBox
          styles={{
            flex: 7,
            marginRight: "1rem",
            marginBottom: "1rem",
            width: "80%",
          }}
        >
          <div
            style={{
              width: "100%",
              marginBottom: 20,
            }}
          >
            {tabs && (
              <MiTab
                tabs={tabs}
                currenttab={activeTab}
                onTabChange={handleClickTab}
              />
            )}

            <div className={classes.tabsContainerMobile}>
              <MiTab
                tabs={tabs}
                currenttab={activeTab}
                onTabChange={handleClickTab}
              />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            {activeTab === DETAILS && (
              <div>
                <NewPropertyInfoCollapse
                  isButtonOpen={activeTab === DETAILS}
                  propertyId={propertyId}
                  property={property}
                  editMode={editMode}
                  toggleEdit={toggleEdit}
                  setResetCoords={setResetCoords}
                  refresh={refresh}
                />
              </div>
            )}
            {activeTab === ISSUES && (
              <PunchListsCollapse propertyId={propertyId} />
            )}
            {activeTab === WALKS && (
              <WalkHistoryCollapse
                IsJobs={false}
                PropertyId={propertyId}
                activeTab={activeTab}
              />
            )}
            {activeTab === JOBS && (
              <WalkHistoryCollapse
                PropertyId={propertyId}
                IsJobs={true}
                activeTab={activeTab}
              />
            )}
            {activeTab === STAGES && (
              <StageInfoCollapse
                propertyId={propertyId}
                isButtonOpen={activeTab === STAGES}
              />
            )}
            {activeTab === INSPECTIONS && (
              <InspectionsCollapse PropertyId={propertyId} />
            )}
            {activeTab === INSPECTIONJOBS && (
              <WalkHistoryCollapse
                PropertyId={propertyId}
                IsJobs={true}
                activeTab={activeTab}
              />
            )}
          </div>
        </MiBox>
        <MiBox
          styles={{
            flex: 3,
            marginRight: "0.5rem",
            marginBottom: "1rem",
            width: "80%",
          }}
        >
          <div
            style={{
              marginBottom: 20,
            }}
          >
            {docsTabs && (
              <MiTab
                tabs={docsTabs}
                currenttab={activeSecondaryTab}
                onTabChange={setActiveSecondaryTab}
                orientation="horizontal"
              />
            )}

            <div className={classes.tabsContainerMobile}>
              <MiTab
                tabs={docsTabs}
                currenttab={activeSecondaryTab}
                onTabChange={setActiveSecondaryTab}
              />
            </div>
          </div>
          {property.propertyId && (
            <div style={{ flex: 1 }}>
              {activeSecondaryTab === "Documents" && (
                <div>
                  <DrawingsCollapse
                    propertyId={propertyId}
                    isButtonOpen={activeSecondaryTab === "Documents"}
                  />
                </div>
              )}
              {activeSecondaryTab === "Contacts" && (
                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <AssigneesCollapse
                      style={{ width: "100%" }}
                      propertyId={propertyId}
                      isButtonOpen={activeSecondaryTab === "Contacts"}
                      propertyName={property.addressLine1}
                    />
                  </div>
                </div>
              )}
              {activeSecondaryTab === "Messaging" && (
                <MessageCollapse
                  messageContext={global.MESSAGE_CONTEXT.HOME}
                  id={property.propertyId}
                />
              )}
              {activeSecondaryTab === "Activities" && (
                <div>
                  <PropertyActivity
                    propertyId={property.propertyId}
                    filterBy={global.FILTER.PROPERTY}
                  />
                </div>
              )}
            </div>
          )}
        </MiBox>
      </div>
    </div>
  );
};

export default Property;
