import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const API_FILES = `${API_V3}/files`;
export const API_CDNURI = `${API_FILES}/cdnuri`;

export const fileService = {
  getCDNURI: async () => {
    const options = await apiOptions({ url: API_CDNURI });
    return apiRequest(options);
  },

  post: async (fileName, walkIdParam, stageWalkItemIdParam, file) => {
    const options = await apiOptions({
      url: `${API_FILES}/${fileName}?walkId=${walkIdParam}&stageWalkItemId=${stageWalkItemIdParam}`,
      method: "POST",
      data: file,
      headers: {
        "Content-Type": "image/jpeg",
      },
    });
    return apiRequest(options);
  },

  postImage: async (params) => {
    const options = await apiOptions({
      url: `${API_FILES}/annotate`,
      method: "POST",
      data: params,
    });
    return apiRequest(options);
  },

  post64: async (file) => {
    const options = await apiOptions({
      url: `${API_FILES}`,
      method: "POST",
      data: file,
      headers: {
        "Content-Type": "application/octet",
      },
    });
    return apiRequest(options);
  },
};
