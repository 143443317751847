import React, { useState, useEffect } from "react";
import { TabulatedPage } from "./TabulatedPage";
import { propertySectionCategoryService } from "@miview/api";
import { createToast } from "@miview/toast";
import { TOAST_TYPES, HTTP_STATUSES } from "@miview/constants";
import { useRouter } from "@miview/hooks";
import { MiDetailFields, MiTab } from "@miview/components";

const AreaCategory = () => {
  const [toggleEdit, setToggleEdit] = useState(false);
  const [activeTab] = useState(global.ACTIVE_TAB.DETAILS);
  const [categoryEdits, setCategoryEdits] = useState();
  const router = useRouter();
  const [propertySectionCategory, setPropertySectionCategory] = useState();
  const { id } = router.params;

  useEffect(() => {
    propertySectionCategoryService.get(id).then((response) => {
      if (response.status === HTTP_STATUSES.OK) {
        setPropertySectionCategory(response.data);
      }
    });
  }, []);

  useEffect(() => {
    resetCategoryEdits();
  }, [propertySectionCategory]);

  const detailFields = categoryEdits
    ? [
        {
          label: "Category Name",
          fieldType: "text",
          value: categoryEdits.name,
          readOnly: !toggleEdit,
          clickButton: () => setToggleEdit(!toggleEdit),
          setValue: (value) =>
            setCategoryEdits({ ...categoryEdits, name: value }),
        },
      ]
    : [];

  const resetCategoryEdits = () => {
    setCategoryEdits({
      ...propertySectionCategory,
    });
  };

  const cancelEditArea = () => {
    resetCategoryEdits();
    setToggleEdit(!toggleEdit);
  };

  const updateData = () => {
    var userObj = {
      name: categoryEdits.name,
    };
    propertySectionCategoryService.update(id, userObj);
  };
  const handleSave = () => {
    if (categoryEdits.name.trim() === "") {
      createToast(`Category Name cannot be blank.`, TOAST_TYPES.ERROR);
      return;
    }
    updateData();
    setToggleEdit(!toggleEdit);
  };
  return (
    <TabulatedPage
      toggleEdit={toggleEdit}
      cancelEdit={cancelEditArea}
      onSave={handleSave}
      pageTitle="Area Category Details"
      fieldSet={[]}
      renderTabs={() => (
        <MiTab
          tabs={[{ label: "Details", value: global.ACTIVE_TAB.DETAILS }]}
          currenttab={activeTab}
        />
      )}
      renderPage={() =>
        activeTab == global.ACTIVE_TAB.DETAILS && (
          <MiDetailFields detailFields={detailFields} />
        )
      }
    />
  );
};

export default AreaCategory;
