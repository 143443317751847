import React, { useEffect, useState } from "react";
import { MiTransferList, MiModal } from "@miview/components";
import { useComponentState } from "@miview/hooks";
import { propertyService, peopleService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { useTheme } from "@mui/styles";

const AssigneesTransferModal = (props) => {
  const { propertyId, setModal, open, setData } = props;
  const [allPeople, setAllPeople] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [propertyContacts, setPropertyContacts] = useState([]);
  const [availablePeople, setAvailablePeople] = useState([]);
  const stateManager = useComponentState();
  const theme = useTheme();

  const sortPeople = () => {
    const propertyContact = allContacts.filter(
      (e) => e.isPropertyContact === true
    );
    const unAssignedContacts = allPeople.map((e) => {
      return allContacts.find((item) => item.personId === e.personId)
        ? { ...e, disable: true, title: e.titleName }
        : { ...e, title: e.titleName };
    });

    setPropertyContacts(propertyContact);
    setAvailablePeople(unAssignedContacts);
  };

  const getPeople = () => {
    stateManager.run(async () => {
      const response = await peopleService.getAll({
        params: {
          pageSize: 9999,
          orderBy: "firstName",
        },
      });
      if (response.status === HTTP_STATUSES.OK) {
        setAllPeople(response.data);
      }
    });
  };

  const getPropertyContacts = () => {
    stateManager.run(async () => {
      const response = await propertyService.getContacts(propertyId);
      if (response.status === HTTP_STATUSES.OK) {
        setAllContacts(response.data);
        setData(response.data);
      }
    });
  };
  useEffect(() => {
    getPeople();
    getPropertyContacts();
    sortPeople();
  }, []);

  useEffect(() => {
    sortPeople();
  }, [allContacts, allPeople]);

  const actions = [
    {
      onClick: () => {
        sortPeople();
        setModal(false);
      },
      color: theme.palette.primary.grey,
      name: "Cancel",
      inverse: false,
    },
    {
      onClick: () => updateAssignees(),
      color: theme.palette.primary.green,
      name: "Save",
      inverse: true,
    },
  ];

  const updateAssignees = async () => {
    const response = await propertyService.updateProperty(propertyId, {
      contacts: propertyContacts,
    });
    if (response.status === HTTP_STATUSES.OK) {
      getPropertyContacts();
      setModal(false);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <MiModal
        title={"Add Assignees"}
        open={open}
        onClose={() => {
          setModal(false);
        }}
        actions={actions}
        maxWidth="80%"
      >
        {
          <div style={{ marginBottom: "30px" }}>
            <MiTransferList
              id="assigned-contacts"
              headerLeft="Available"
              headerRight="Assigned"
              rightHandler={setPropertyContacts}
              leftHandler={setAvailablePeople}
              listItemsLeft={availablePeople}
              listItemsRight={propertyContacts.sort((a, b) =>
                a.fullName < b.fullName ? -1 : 1
              )}
              labelField="fullName"
              secondLabelField="title"
            />
          </div>
        }
      </MiModal>
    </div>
  );
};

export default AssigneesTransferModal;
