import React, { useRef, useState } from "react";
import {
  TimePicker,
  DateTimePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MiInputTextbox } from "../index.ts";
import moment from "moment";
import PropTypes from "prop-types";

const getDateFormatted = (value, renderDateFormat, inputFormat) => {
  if (!value) {
    return null;
  }
  if (renderDateFormat) {
    return renderDateFormat(value);
  }
  return moment(value).format(inputFormat);
};

export const MiDatePickerInput = (props) => {
  const {
    type = "dateTime",
    value,
    handleChange,
    labelText,
    required,
    variant,
    inputFormat,
    dateFormat,
    editable,
    renderDateFormat,
    minInputWidth,
    ...rest
  } = props;

  const formattedValue = getDateFormatted(value, renderDateFormat, inputFormat);
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState();

  const onChange = (val) => {
    if (val?.isValid()) {
      handleChange(val.format(dateFormat));
      return;
    }
    handleChange(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const renderInput = (params) => (
    <MiInputTextbox
      {...params}
      labelText={labelText}
      variant={variant}
      required={required}
      editable={editable}
      handleClick={handleClick}
      minWidth={minInputWidth}
      inputProps={{
        ...params.inputProps,
        value: formattedValue ? formattedValue : "",
      }}
    />
  );

  const renderPicker = () => {
    switch (type) {
      case "date":
        return (
          <DesktopDatePicker
            renderInput={renderInput}
            onChange={onChange}
            inputRef={inputRef}
            value={formattedValue}
            inputFormat={inputFormat}
            PopperProps={{
              anchorEl: anchorEl,
            }}
            {...rest}
          />
        );
      case "time":
        return (
          <TimePicker
            renderInput={renderInput}
            onChange={onChange}
            value={formattedValue}
            inputFormat={inputFormat}
            {...rest}
          />
        );
      default:
        return (
          <DateTimePicker
            renderInput={renderInput}
            onChange={onChange}
            value={formattedValue}
            inputFormat={inputFormat}
            {...rest}
          />
        );
    }
  };
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {renderPicker()}
      </LocalizationProvider>
    </div>
  );
};

MiDatePickerInput.propTypes = {
  type: PropTypes.oneOf(["date", "time", "dateTime"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleChange: PropTypes.func.isRequired,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  /**
   * Date format for display purpose.
   */
  inputFormat: PropTypes.string.isRequired,
  /**
   * Date format of the input data. Ex: if input value is 05/13/2022 then dateFormat value must be 'MM/DD/YYYY'.
   */
  dateFormat: PropTypes.string.isRequired,
};
