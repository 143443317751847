import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "tss-react/mui";
import { Avatar, useTheme } from "@mui/material";
import {
  MiBox,
  MiPageHeader,
  MiTable,
  MiLink,
  MiListFilterHeader,
} from "@miview/components";
import { accountService, purchaseOrderService } from "@miview/api";
import PurchaseOrderTabs from "./PurchaseOrderTabs";
import { PurchasingOrderStatus } from "../../components/Status";
import { FILTER_CHIPS } from "../../constants";
import { getInitials, simpleSort } from "@miview/utils";
import { FILTER_LABELS, en, ORDER_STATUS } from "@miview/constants";
import { formatDate } from "../../../../helpers/dateTools";

const useStyles = makeStyles()((theme) => ({
  AvatarStyle: {
    width: 24,
    height: 24,
    display: "inline-flex",
    overflow: "initial",
    textAlign: "center",
    backgroundColor: theme.palette.dark.bluegrey,
    fontSize: theme.fontSize.medium,
    marginRight: 10,
    textTransform: "capitalize",
  },
  selectBoxFocus: {
    backgroundColor: "transparent",
    "&:focus": {
      background: "transparent",
    },
    display: "inline-flex",
    minWidth: "110px",
  },
  filterLabel: {
    color: theme.palette.primary.bluegrey,
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.regular,
  },
  labelShrink: {
    "&.Mui-focused": {
      color: theme.palette.primary.bluegrey,
    },
    color: theme.palette.primary.bluegrey,
    fontSize: 17,
    fontWeight: theme.fontWeight.regular,
  },
}));

export const PurchasingOrder = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [openDrawer, setOpenDrawer] = useState({ open: false });
  const [searchText, setSearchText] = useState("");
  const [filterIcon, setFilterIcon] = useState(false);
  const [supplierFilterValue, setSupplierFilterValue] = useState(null);
  const [deliveryFilterValue, setDeliveryFilterValue] = useState(null);
  const [pageSizeSelected, setPageSizeSelected] = useState(50);
  const [selectedChipFilters, setSelectedChipFilters] = useState([
    FILTER_CHIPS[0],
  ]);
  const [filterArray, setFilterArray] = useState([
    {
      label: FILTER_LABELS.SUPPLIER,
      type: "select",
      value: supplierFilterValue,
      options: [],
      clearFilterOption: "true",
      handleClickClearFilter: () => setSupplierFilterValue(null),
    },
    {
      label: FILTER_LABELS.DELIVERY,
      type: "select",
      value: deliveryFilterValue,
      options: [],
      clearFilterOption: "true",
      handleClickClearFilter: () => setDeliveryFilterValue(null),
    },
  ]);

  const theme = useTheme();

  const handleDrawerCancel = () => {
    setOpenDrawer({ open: false });
  };

  const handleDrawer = ({ id }) => {
    setOpenDrawer({
      open: true,
      id,
    });
  };

  const columns = [
    {
      field: "issueDate",
      headerName: en.issueDate,
      flex: 1.5,
      renderCell: (params) => {
        return formatDate(params.row.issueDate);
      },
    },
    {
      field: "number",
      headerName: en.Number,
      flex: 1.5,
      renderCell: ({ value, row }) => {
        return (
          <MiLink
            onClick={() => handleDrawer(row)}
            title={value}
            style={{
              fontWeight: theme.fontWeight.medium,
              textDecoration: "none",
              padding: 0,
              margin: 0,
            }}
          />
        );
      },
    },
    {
      field: "accountName",
      headerName: en.supplier,
      flex: 2,
    },
    {
      field: "tag",
      headerName: en.descriptionS,
      flex: 2,
    },
    {
      field: "deliveryDate",
      headerName: en.deliveryDate,
      flex: 1.5,
      renderCell: (params) => {
        return formatDate(params.row.deliveryDate);
      },
    },
    {
      field: "receivedDate",
      headerName: en.receivedDate,
      flex: 1.5,
      renderCell: (params) => {
        let value = formatDate(params.row.receivedDate);
        return params.row.status === ORDER_STATUS.CANCEL ||
          params.row.status === ORDER_STATUS.ISSUED ||
          params.row.status === ORDER_STATUS.BACKORDER
          ? "--"
          : value;
      },
    },
    {
      field: "deliveryLocation",
      headerName: en.deliveryLocation,
      flex: 2,
    },
    {
      field: "amount",
      headerName: en.amount,
      flex: 1,
    },
    {
      field: "status",
      headerName: en.status,
      flex: 1.5,
      renderCell: (params) => {
        return <PurchasingOrderStatus value={params.row.status} />;
      },
    },
  ];

  const fetchData = async (params = {}, filterParams = {}) => {
    const orders = await purchaseOrderService.purchaseOrder.getAll(
      { pageSize: 9999, orderBy: "issueDate", descending: true, ...params },
      filterParams
    );
    if (orders.data) {
      setList(orders.data);
    }
    setLoading(false);
  };

  const lowerCase = (val) => {
    if (val) {
      return val.toLowerCase();
    }

    return val;
  };

  const sortByValue = (data) => {
    return data.sort((a, b) => {
      return simpleSort(lowerCase(a.label), lowerCase(b.label));
    });
  };

  const getFilterItems = async (params = {}, filterParams = {}) => {
    const supplierResponse = await accountService.accounts.getAll(params, {
      parentId: null,
      ...filterParams,
    });

    const deliveryResponse = await accountService.deliveryLocations.getAll(
      params,
      filterParams
    );

    let supplierOptions = [];
    let locationOptions = [];

    if (supplierResponse.data) {
      const suppliers = supplierResponse.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
          icon: (
            <Avatar className={classes.AvatarStyle}>
              {getInitials(item.name)}
            </Avatar>
          ),
        };
      });
      supplierOptions = sortByValue(suppliers);
    }

    if (deliveryResponse.data) {
      const locations = deliveryResponse.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      locationOptions = sortByValue(locations);
    }

    const updatedFilters = filterArray.map((ele) => {
      if (ele.label === FILTER_LABELS.SUPPLIER) {
        return { ...ele, options: supplierOptions };
      }
      if (ele.label === FILTER_LABELS.DELIVERY) {
        return { ...ele, options: locationOptions };
      }
      return ele;
    });
    setFilterArray(updatedFilters);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    getFilterItems();
  }, []);

  const aggregateSearchAndFilter = useCallback(async () => {
    setList([]);
    setLoading(true);
    const filterParams = {};
    const params = {};
    if (searchText) {
      params.search = searchText;
    }
    if (supplierFilterValue !== null) {
      filterParams.accountId = supplierFilterValue;
    }
    if (deliveryFilterValue !== null) {
      filterParams.deliveryLocationId = deliveryFilterValue;
    }
    if (selectedChipFilters.length) {
      const filterChipValues = selectedChipFilters.map((item) => item.status);
      const filterSelectedValues = filterChipValues.filter(
        (item) => item !== "All POs"
      );
      filterParams.status = filterSelectedValues;
    }
    await fetchData(params, filterParams);
  }, [
    deliveryFilterValue,
    searchText,
    selectedChipFilters,
    supplierFilterValue,
  ]);

  const handleSearch = async (searchTerm) => {
    setSearchText(searchTerm);
  };

  const onRefresh = () => {
    setList([]);
    setLoading(true);
    setSupplierFilterValue(null);
    setDeliveryFilterValue(null);
    setSearchText("");
    setSelectedChipFilters([FILTER_CHIPS[0]]);
  };

  const handleOnChangeChipFilter = async (selectedChipValue, clickedItem) => {
    if (selectedChipValue.length) {
      const filterAllPo = selectedChipValue.filter(
        (item) => item.status !== "All POs"
      );
      if (clickedItem?.status === "All POs") {
        setSelectedChipFilters([{ ...clickedItem }]);
      } else {
        setSelectedChipFilters(filterAllPo);
      }
    } else {
      setSelectedChipFilters([{ ...FILTER_CHIPS[0] }]);
    }
  };

  const handleFilterChange = async (val, label) => {
    const newArr = filterArray.map((ele) => {
      if (ele.label === label) {
        return { ...ele, value: val };
      }
      return ele;
    });
    setFilterArray(newArr);
    setSupplierFilterValue(newArr[0].value);
    setDeliveryFilterValue(newArr[1].value);
  };

  const handleOnFilterIcon = () => {
    setFilterIcon(!filterIcon);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  useEffect(() => {
    aggregateSearchAndFilter();
  }, [aggregateSearchAndFilter]);

  return (
    <>
      <MiPageHeader
        title={en.purchaseOrder}
        color={theme.palette.primary.main}
        hasDivider={true}
      />
      <MiBox>
        <MiListFilterHeader
          filterIconStyles={{
            color: theme.palette.primary.main,
          }}
          handleSearch={handleSearch}
          handleRefreshClick={onRefresh}
          handleFilterIconClick={handleOnFilterIcon}
          handleChange={handleFilterChange}
          clearSearch={handleClearSearch}
          searchTerm={searchText}
          filterIcon={filterIcon}
          menuMargin={"4px 0px"}
          disableNone={false}
          inputLabelClasses={{
            focused: classes.filterLabel,
            root: classes.filterLabel,
            shrink: classes.labelShrink,
          }}
          selectBoxClasses={{
            select: classes.selectBoxFocus,
          }}
          searchIconColor={theme.palette.secondary.bluegrey}
          filterArray={[
            ...filterArray,
            {
              type: "filterAction",
              values: FILTER_CHIPS,
              valueField: "status",
              selectedItems: selectedChipFilters,
              handleChange: handleOnChangeChipFilter,
              activeColor: theme.palette.primary.secondary,
              chipStyle: {
                paddingLeft: 8,
                paddingRight: 8,
                fontSize: theme.fontSize.medium,
                fontWeight: theme.fontWeight.regular,
              },
            },
          ]}
        />
        <MiTable
          columns={columns}
          rows={list}
          disableColumnMenu={true}
          loading={loading}
          disableColumnReorder={true}
          sx={{
            "& .MuiDataGrid-cell": {
              padding: "0px 0px 0px 30px !important",
            },
            "& p": {
              marginBottom: "0px",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },

            "& .MuiDataGrid-columnHeaderTitleContainer": {
              padding: "0px 0px 0px 20px !important",
            },
          }}
          rowsPerPageOptions={[50, 100, 150]}
          pageSize={pageSizeSelected}
          pagination={true}
          hideFooter={false}
          onPageSizeChange={(newPageSize) => setPageSizeSelected(newPageSize)}
        />
      </MiBox>
      {openDrawer.open && (
        <PurchaseOrderTabs
          {...openDrawer}
          refreshData={onRefresh}
          handleDrawerCancel={handleDrawerCancel}
        />
      )}
    </>
  );
};
