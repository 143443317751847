import { apiOptions, apiRequest } from "../../index.js";
import { API_HEADERS_TEMP, API_INTEGRATION_SERVICE_URL } from "../../constants";

const API_STAGES = `/stages`;

export const stages = {
  getAll: async (params, filters) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_STAGES}`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  get: async (id, params, filters) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_STAGES}/${id}`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  accept: async (id, params, filters) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_STAGES}/${id}/accept`,
      method: "post",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  update: async (id, data) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_STAGES}/${id}`,
      method: "patch",
      data,
      headers: API_HEADERS_TEMP,
    });
    const toastMessageConfig = {
      success: {
        message: "Successfully saved the job",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
};
