import { useRouter } from "@miview/hooks";
import { usePlanOptions, usePlanAreas } from ".";
import { planService, propertyService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const useResetPage = () => {
  const {
    query: { propertyId },
  } = useRouter();
  const {
    clearToggles,
    optionsToggledOn,
    optionConfiguring,
    setOptionConfiguring,
  } = usePlanOptions();
  const { setPlanAreas, setPlanAreasPreview, setPlanAreasLoading } =
    usePlanAreas();

  const resetPage = async (property) => {
    try {
      if (optionConfiguring || optionsToggledOn.length > 0) {
        clearToggles();
        setOptionConfiguring(0);
        setPlanAreasLoading("Loading...");

        const planAreas = await planService.getAreas(property.planId);
        const planAreasPreview = await propertyService.getPreview(propertyId);

        if (planAreas.status === HTTP_STATUSES.OK) {
          setPlanAreas(planAreas.data);
        }
        if (planAreasPreview.status === HTTP_STATUSES.OK) {
          setPlanAreasPreview(planAreasPreview.data);
        }
        setPlanAreasLoading("");
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return resetPage;
};
