import React, { forwardRef, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import { TextField, FormControl } from "@mui/material";
import { styled } from "@mui/system";

const Wrapper = styled(FormControl, {
  shouldForwardProp: (props) => props !== "minWidth",
})(({ minWidth }) => ({
  minWidth: minWidth !== undefined ? minWidth : 220,
  display: "flex",
  margin: "5px 0px 5px 0px",
}));

const TextFieldWithMask = forwardRef((props, ref) => {
  const { onChange, value, ...rest } = props;
  return (
    <IMaskInput
      {...rest}
      inputRef={ref}
      value={String(value)}
      unmask={true}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

const StyledTextField = styled(TextField, {
  shouldForwardProp: (props) =>
    !["paddingRight", "marginBottom", "editable", "readOnly"].includes(props),
})((props) => {
  return {
    "& .MuiOutlinedInput-root": {
      "& legend": { display: props.label ? undefined : "none" },
      "& fieldset": { top: props.label ? undefined : 0 },
    },
    "& .MuiOutlinedInput-input": {
      fontSize: props.theme.fontSize.small,
      lineHeight: "normal",
    },
    "& .MuiInput-root": {
      fontSize: props.theme.fontSize.small,
    },
  };
});

export const MiInputTextbox = ({
  helperText = "",
  labelText,
  labelTextColor,
  type = "text",
  value,
  handleChange,
  InputProps = {},
  inputProps = {},
  styles,
  minWidth,
  variant = "standard",
  InputLabelProps,
  focusOnMount,
  mask,
  definitions,
  minValue = -Infinity,
  handleClick,
  ...rest
}) => {
  const defineMask = mask || /.*/;

  const inputRef = useRef(null);

  useEffect(() => {
    if (focusOnMount) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Wrapper style={styles} minWidth={minWidth}>
      <StyledTextField
        {...rest}
        inputRef={inputRef}
        label={labelText}
        type={type}
        fullWidth={true}
        size="small"
        variant={variant}
        helperText={helperText}
        value={value || value === 0 ? value : ""}
        onChange={handleChange}
        onClick={handleClick}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
          style: { color: labelTextColor },
        }}
        InputProps={{
          ...InputProps,
          ...(mask || definitions
            ? {
                inputComponent: TextFieldWithMask,
                inputProps: {
                  mask: defineMask,
                  definitions,
                },
              }
            : {}),
        }}
        inputProps={
          type === "number" ? { ...inputProps, min: minValue } : inputProps
        }
      />
    </Wrapper>
  );
};

MiInputTextbox.propTypes = {
  labelText: PropTypes.string,
  helperText: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func,
  styles: PropTypes.object,
};
