import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { walkFailureTypeService, systemTypeService } from "@miview/api";
import {
  MiPageHeader,
  MiButton,
  MiList,
  MiModal,
  MiDetailFields,
  MiIcon,
  MiModalConfirm,
} from "@miview/components";
import { useEdit, useComponentState } from "@miview/hooks";
import { toList } from "@miview/utils";
import { PAGINATION, HTTP_STATUSES } from "@miview/constants";
import { mdiThumbDownOutline, mdiPencil, mdiPlus, mdiDelete } from "@mdi/js";

const INITIAL_EDIT_STATE = {
  name: "",
  causeId: "",
  defaultTrade: "",
  nameSpanish: "",
};

export const WalkFailuresTab = (props) => {
  const { tabs } = props;
  const [isNewReasonModalOpen, setIsNewReasonModalOpen] = useState(false);
  const [isEditReasonModalOpen, setIsEditReasonModalOpen] = useState(false);
  const [isDeleteReasonModalOpen, setIsDeleteReasonModalOpen] = useState(false);
  const [editSelection, setEditSelection] = useState(INITIAL_EDIT_STATE);
  const [causeIdObjs, setCauseIdObjs] = useState([]);
  const [failureTypes, setFailureTypes] = useState();
  const [refresh, setRefresh] = useState(false);
  const stateManager = useComponentState();
  const theme = useTheme();
  const edit = useEdit(editSelection);

  useEffect(() => {
    getCauseIds();
  }, []);

  const saveNewFailureType = async () => {
    return stateManager.run(async () => {
      const response = await walkFailureTypeService.create(edit.edits);
      if (response.status === HTTP_STATUSES.OK) {
        closeNewModal();
        setRefresh(!refresh);
      }
    });
  };

  const editFailureType = async () => {
    return stateManager.run(async () => {
      const response = await walkFailureTypeService.update(
        edit.getValue("id"),
        edit.edits
      );
      if (response.status === HTTP_STATUSES.OK) {
        closeEditModal();
        setRefresh(!refresh);
      }
    });
  };

  const deleteNewFailureType = async () => {
    return stateManager.run(async () => {
      const response = await walkFailureTypeService.delete(edit.getValue("id"));

      if (response.status === HTTP_STATUSES.OK) {
        closeDeleteModal();
        edit.reset();
        setRefresh(!refresh);
      }
    });
  };

  const getCauseIds = async () => {
    return stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: "WalkCause",
      });

      if (response.status === HTTP_STATUSES.OK) {
        setCauseIdObjs(toList(response.data, "systemTypeId", "mainValue"));
      }
    });
  };

  const openNewModal = () => {
    setIsNewReasonModalOpen(!isNewReasonModalOpen);
    edit.reset();
  };

  const closeNewModal = () => {
    setIsNewReasonModalOpen(!isNewReasonModalOpen);
    edit.reset();
  };

  const openEditModal = (row) => {
    setEditSelection(row);
    setIsEditReasonModalOpen(!isEditReasonModalOpen);
  };

  const closeEditModal = () => {
    edit.reset();
    setEditSelection({});
    setIsEditReasonModalOpen(!isEditReasonModalOpen);
  };

  const openDeleteModal = (row) => {
    setEditSelection(row);
    setIsDeleteReasonModalOpen(!isDeleteReasonModalOpen);
  };

  const closeDeleteModal = () => {
    edit.reset();
    setEditSelection({});
    setIsDeleteReasonModalOpen(!isDeleteReasonModalOpen);
  };

  const getRowActions = () => {
    return [
      {
        text: "EDIT",
        onClick: openEditModal,
        renderIcon: () => <MiIcon path={mdiPencil} size={1} />,
      },
      {
        text: "DELETE",
        onClick: openDeleteModal,
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
    ];
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "causeId",
      headerName: "Cause ID",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        return causeIdObjs.find((causeId) => causeId.value === params.value)
          ?.text;
      },
    },
    {
      field: "nameSpanish",
      headerName: "Spanish Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
  ];

  const manageAreaFields = [
    {
      label: "Name",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "50%",
    },
    {
      label: "Cause ID",
      fieldType: "select",
      options: causeIdObjs,
      value: edit.getValue("causeId"),
      setValue: (e) => {
        edit.update({ causeId: e });
      },
      required: true,
      width: "50%",
    },
    {
      label: "Default Trade",
      fieldType: "select",
      options: causeIdObjs,
      value: edit.getValue("defaultTrade"),
      setValue: (e) => {
        edit.update({ defaultTrade: e });
      },
      required: true,
      width: "50%",
    },
    {
      label: "Spanish Name",
      value: edit.getValue("nameSpanish"),
      setValue: (e) => edit.update({ nameSpanish: e }),
      width: "50%",
      required: true,
    },
  ];

  const actionButton = () => {
    return (
      <MiButton
        title="Walk Failure Reason"
        icon={mdiPlus}
        inverse={true}
        onClick={openNewModal}
        color={theme.palette.primary.main}
      />
    );
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiThumbDownOutline}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <div>
      <MiPageHeader
        title="Failure Reasons"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
        loading={stateManager.isBusy()}
      />
      <MiList
        data={failureTypes}
        tabs={tabs}
        setData={setFailureTypes}
        dataProvider={walkFailureTypeService.getAll}
        columns={columns}
        disableColumnMenu
        paginationMode={PAGINATION.CLIENT}
        getRowActions={getRowActions}
        refresh={refresh}
      />
      <MiModal
        key={0}
        maxWidth={800}
        title="Create Walk Failure Reason"
        open={isNewReasonModalOpen}
        onClose={closeNewModal}
        actions={[
          {
            name: "Cancel",
            style: { minWidth: 90 },
            onClick: closeNewModal,
            color: theme.palette.primary.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: saveNewFailureType,
            style: { minWidth: 90 },
            color: theme.palette.primary.green,
            inverse: true,
            disabled: !edit.allFilled(...Object.keys(INITIAL_EDIT_STATE)),
          },
        ]}
      >
        <MiDetailFields detailFields={manageAreaFields} />
      </MiModal>
      <MiModal
        key={1}
        maxWidth={800}
        title="Edit Failure Reason"
        open={isEditReasonModalOpen}
        onClose={closeEditModal}
        actions={[
          {
            name: "Cancel",
            style: { minWidth: 90 },
            onClick: closeEditModal,
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: editFailureType,
            style: { minWidth: 90 },
            color: theme.palette.primary.green,
            inverse: true,
            disabled: !edit.allFilled("name", "causeId"),
          },
        ]}
      >
        <MiDetailFields detailFields={manageAreaFields} />
      </MiModal>
      <MiModalConfirm
        title={"Confirm Delete"}
        description={`Delete Failure Reason: ${editSelection.name}?`}
        open={isDeleteReasonModalOpen}
        handlePositive={deleteNewFailureType}
        handleNegative={closeDeleteModal}
      />
    </div>
  );
};
