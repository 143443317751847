import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_MESSAGES = `${API_V3}/messages`;
const API_CONVERSATIONS = `/conversations`;
const API_UNREAD = `/unread`;
const API_READ = `/read`;

export const messageService = {
  getAll: async (params, filters) => {
    const options = await apiOptions({
      url: `${API_MESSAGES}`,
      params,
      filters,
    });
    return apiRequest(options);
  },

  post: async (data) => {
    const options = await apiOptions({
      url: `${API_MESSAGES}`,
      data,
      method: "post",
    });
    return apiRequest(options);
  },

  getUnread: async (toastMessageConfig) => {
    const options = await apiOptions({
      url: `${API_MESSAGES}${API_CONVERSATIONS}${API_UNREAD}`,
      method: "get",
    });

    return apiRequest(options, toastMessageConfig);
  },

  readMessages: async (data) => {
    const options = await apiOptions({
      url: `${API_MESSAGES}/messages${API_READ}`,
      method: "patch",
      data,
    });
    return apiRequest(options);
  },
};
