import { useState } from "react";
import { inspectionService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { useComponentState } from "../useComponentState/useComponentState";

export const useRedTagAssignees = () => {
  const [assignees, setAssignees] = useState([]);
  const stateManager = useComponentState();

  const getAssignableUsers = (walkId, handleAssigneeResponse, selectedItem) => {
    stateManager.run(async () => {
      const response = await inspectionService.getAssignableUsers({
        id: walkId,
      });
      if (response.status === HTTP_STATUSES.OK) {
        handleAssigneeResponse(response.data, setAssignees, selectedItem);
      }
    });
  };

  return { assignees, getAssignableUsers };
};
