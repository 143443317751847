import React, { useState } from "react";

export const ALERT_INITIAL_STATE = {
  show: false,
  title: "",
  message: "",
  controls: [],
};

export const CONTEXT_INITIAL_STATE = {
  ...ALERT_INITIAL_STATE,
  create: () => null,
  dismiss: () => null,
};

export const AlertContext = React.createContext({ ...CONTEXT_INITIAL_STATE });

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(ALERT_INITIAL_STATE);

  const create = (title, message, controls) => {
    setAlert({
      show: true,
      title,
      message,
      controls,
    });
  };

  const dismiss = () => {
    setAlert(ALERT_INITIAL_STATE);
  };

  const contextValue = {
    ...alert,
    create,
    dismiss,
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
};
