import React from "react";
import { useMiTimelineStyles } from "./MiTimelineStyles";
import { THEME } from "./theme";

const Timeline = (props) => {
  const { classes } = useMiTimelineStyles();

  let barWidth = "0%";
  let pickIndex = 0;
  let currentStatusNum = 0;
  let pickIndexNum = 0;

  if (props.picklist && props.currentStatus) {
    pickIndex = props.picklist.findIndex(
      (item) => item.value == props.currentStatus
    );
    if (pickIndex == -1) {
      pickIndex = props.picklist.length;
    }

    let percent = Math.floor((pickIndex / (props.picklist.length - 1)) * 100);
    if (percent > 100) {
      percent = 99.1;
    }

    barWidth = percent.toString() + "%";
    pickIndexNum = Number(
      props.picklist[props.picklist.length - 1].value.replace(/^\D+/g, "")
    );
    currentStatusNum = Number(props.currentStatus.replace(/^\D+/g, ""));
  }

  return (
    <div>
      <div className={classes.projectTimeline}>
        <div className={`${classes.timeOut} ${classes.timeline}`}>
          <span className={classes.timelineBar}>
            <span
              className={
                currentStatusNum > pickIndexNum
                  ? classes.timelineBarActivatedCanceled
                  : classes.timelineBarActivated
              }
              style={{ width: barWidth }}
              id="timeline"
            ></span>
          </span>
          <div className={classes.timelineList}>
            {props.picklist &&
              props.picklist.map((item, index) => (
                <div key={"key_" + index} className={classes.timelineGroup}>
                  <span
                    className={
                      currentStatusNum > pickIndexNum
                        ? classes.timelineCircleCanceled
                        : classes.timelineCircle
                    }
                    style={
                      currentStatusNum > pickIndexNum
                        ? { ...styles.late }
                        : index < pickIndex
                        ? { ...styles.past }
                        : index == pickIndex
                        ? { ...styles.current }
                        : null
                    }
                  >
                    <i
                      className="material-icons"
                      aria-hidden="true"
                      style={styles.iconCurrent}
                    >
                      {pickIndexNum < currentStatusNum
                        ? "close"
                        : index < pickIndex
                        ? "check"
                        : null}
                    </i>
                  </span>
                  <span className={classes.timelineText}>{item.value}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  current: {
    backgroundColor: "#FFF",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: THEME.GREEN_PRIMARY,
  },
  late: {
    backgroundColor: THEME.RED_PRIMARY,
    borderWidth: 3,
    borderStyle: "solid",
    borderRadius: "50%",
    borderColor: THEME.RED_PRIMARY,
  },
  past: {
    backgroundColor: THEME.GREEN_PRIMARY,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: THEME.GREEN_PRIMARY,
  },
  iconCurrent: {
    fontSize: "1.3rem",
    textAlign: "center",
    marginTop: "-4px",
    marginLeft: "-1.5px",
  },
  iconLate: {
    fontSize: "1.3rem",
    textAlign: "center",
    justifyContent: "center",
    marginTop: "-4px",
    marginLeft: "-4px",
    marginRight: "-3px",
  },
};

export default Timeline;
