import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  MiPageHeader,
  MiButton,
  MiModal,
  MiList,
  MiLink,
  MiDetailFields,
  MiIcon,
  MiModalConfirm,
} from "@miview/components";
import { useRouter, useComponentState, useEdit } from "@miview/hooks";
import {
  planService,
  accountServiceLegacy as accountService,
  communityService,
} from "@miview/api";
import { ACCOUNT_TYPES, HTTP_STATUSES } from "@miview/constants";
import { useTheme } from "@mui/material/styles";
import { mdiFloorPlan, mdiContentCopy, mdiDelete, mdiPlus } from "@mdi/js";

const Plans = () => {
  const [plansData, setPlansData] = useState([]);
  const [newPlanInProgress, setNewPlanInProgress] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletePlanId, setDeletePlanId] = useState();
  const [deletePlanName, setDeletePlanName] = useState("");
  const [builders, setBuilders] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const router = useRouter();
  const stateManager = useComponentState();
  const theme = useTheme();
  const edit = useEdit({
    name: "",
    builderId: "",
    communityId: "",
    spec: "",
  });

  useEffect(() => {
    document.title = "Plans";
    getBuilders();
    getCommunities();
  }, []);

  const getBuilders = async () => {
    stateManager.run(async () => {
      const response = await accountService.getAll({
        params: { pageSize: 9999 },
        filters: { type: ACCOUNT_TYPES.BUILDER },
        sort: [{ field: "name", sort: "asc" }],
      });
      if (response.status === HTTP_STATUSES.OK) {
        setBuilders(response.data);
      }
    });
  };

  const getCommunities = async () => {
    stateManager.run(async () => {
      const response = await communityService.getAll({
        params: { pageSize: 9999 },
      });

      if (response.status === HTTP_STATUSES.OK) {
        let communitiesList = response.data.map((c) => {
          return { value: c.id, text: c.name };
        });

        communitiesList.sort(function (a, b) {
          var nameA = a.text.toUpperCase();
          var nameB = b.text.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });

        setCommunities(communitiesList);
      }
    });
  };

  const closeNewPlanModal = () => {
    setNewPlanInProgress(false);
    edit.reset();
  };

  const openPlan = (planId) => {
    router.push(`/plans/${planId}`);
  };

  const openBuilder = (builderId) => {
    router.push(`/builder/${builderId}`);
  };

  const openCommunity = (communityId) => {
    router.push(`/Communities/${communityId}`);
  };

  const clonePlan = async (activeRow) => {
    const response = await planService.clone(activeRow.id);
    if (response.status === HTTP_STATUSES.OK && response.data) {
      router.push(`/plans/${response.data}`);
    }
    setRefresh(!refresh);
  };

  const saveNewPlan = async () => {
    stateManager.run(async () => {
      const response = await planService.create(edit.edits);
      if (response.status === HTTP_STATUSES.OK) {
        closeNewPlanModal();
        router.push(`/plans/${response.data}`);
      }
    });
  };

  const closeDeleteModal = () => {
    setDeletePlanId();
    setDeletePlanName("");
    setIsDeleting(false);
  };

  const openDeleteModal = (row) => {
    setDeletePlanId(row.id);
    setDeletePlanName(row.name);
    setIsDeleting(true);
  };

  const deletePlanItem = async () => {
    const response = await planService.delete(deletePlanId);
    if (response.status === HTTP_STATUSES.OK) {
      setIsDeleting(false);
      setRefresh(!refresh);
    }
  };

  const handleSelectedRow = (row, action) => {
    switch (action) {
      case "DELETE":
        openDeleteModal(row);
        break;
      case "COPY":
        clonePlan(row);
        break;
      default:
    }
  };

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => handleSelectedRow(row, "DELETE"),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
      {
        text: "COPY",
        onClick: () => handleSelectedRow(row, "COPY"),
        renderIcon: () => <MiIcon path={mdiContentCopy} size={1} />,
      },
    ];
  };

  const columns = [
    {
      field: "name",
      headerName: "Plan Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <MiLink
            onClick={(p) => {
              p.preventDefault();
              openPlan(params.id);
            }}
            title={params.value}
          />
        );
      },
    },
    {
      field: "builderName",
      headerName: "Builder",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <MiLink
            onClick={(p) => {
              p.preventDefault();
              openBuilder(params.row.builderId);
            }}
            title={params.value}
          />
        );
      },
    },
    {
      field: "community",
      headerName: "Community",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <MiLink
            onClick={(p) => {
              p.preventDefault();
              openCommunity(params.row.communityId);
            }}
            title={params.value}
          />
        );
      },
    },
    {
      field: "createTime",
      headerName: "Created Date",
      headerAlign: "left",
      align: "left",
      flex: 1,
      valueFormatter: (params) => {
        return moment(params.value).format("ddd, MMM DD, YYYY");
      },
    },
  ];

  const modalFields = [
    {
      label: "Plan Name",
      value: edit.getValue("name"),
      setValue: (e) => edit.update({ name: e }),
      required: true,
      focusOnMount: true,
      width: "100%",
    },
    {
      label: "Builder",
      value: edit.getValue("builderId"),
      options: builders,
      fieldType: "autoComplete",
      setValue: (_e, value) => edit.update({ builderId: value?.id }),
      width: "100%",
    },
    {
      label: "Community",
      value: edit.getValue("communityId"),
      options: communities,
      fieldType: "select",
      setValue: (e) => edit.update({ communityId: e }),
      width: "100%",
    },
    {
      label: "Import SpecSheet",
      setValue: (e) => {
        edit.update({
          spec: e.base64,
          fileName: e.fileName,
          fileType: e.fileType,
        });
      },
      fieldType: "file",
      value: edit.getValue("spec"),
      fileType: edit.getValue("fileType"),
      width: "100%",
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon path={mdiFloorPlan} size={1} color={theme.palette.primary.main} />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Plan"
        icon={mdiPlus}
        inverse={true}
        onClick={() => setNewPlanInProgress(true)}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <>
      <MiPageHeader
        title="Plans"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
        loading={stateManager.isBusy()}
      />
      {stateManager.statusTag("planListStatus")}
      <MiList
        data={plansData}
        setData={setPlansData}
        dataProvider={planService.getPlans}
        columns={columns}
        getRowActions={getRowActions}
        actionIconStyle={{ color: theme.palette.secondary.grey }}
        disableColumnMenu
        refresh={refresh}
      />
      <MiModal
        title="Create A Plan"
        open={newPlanInProgress}
        onClose={closeNewPlanModal}
        width="90%"
        maxWidth={600}
        actions={[
          {
            name: "Cancel",
            style: { minWidth: 90 },
            onClick: closeNewPlanModal,
            color: theme.palette.primary.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: saveNewPlan,
            style: { minWidth: 90 },
            color: theme.palette.primary.green,
            inverse: true,
            disabled: !edit.allFilled("name") || stateManager.isBusy(),
          },
        ]}
      >
        <MiDetailFields detailFields={modalFields} />
      </MiModal>
      <MiModalConfirm
        title={"Confirm Delete"}
        description={`Delete Plan: ${deletePlanName}?`}
        open={isDeleting}
        handlePositive={deletePlanItem}
        handleNegative={closeDeleteModal}
      />
    </>
  );
};

export default Plans;
