import React, { useState, useEffect, useRef } from "react";
import { THEME } from "../../../components/Standard/theme";
import UploadPlatMapModal from "./UploadPlatMapModal";
import { miStyles } from "@miview/theme";
import { useRouter } from "@miview/hooks";
import { mdiUpload } from "@mdi/js";
import { MiButton } from "@miview/components";

const CommunityPlat = (props) => {
  const { imageUrl, properties, communityId, refresh } = props;
  const { classes } = miStyles();

  const canvasRef = useRef(null);
  const tooltipRef = useRef(null);
  const lightGreen = "rgba(0,255,0,0.2)";
  const lightYellow = "rgba(255,255,0,0.2)";
  const lightBlue = "rgba(0,0,255,0.2)";
  const lightRed = "rgba(255,0,0,0.2)";
  const empty = "rgba(0,0,0,0)";
  const [propertyCoords, setPropertyCoords] = useState([]);
  const [mousePosX, setMousePosX] = useState(0);
  const [mousePosY, setMousePosY] = useState(0);
  const [lockTooltipPos, setLockToolTipPos] = useState(false);
  const [toolTipClass, setTooltipClass] = useState(classes.fadeIn);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { navigate } = useRouter();

  useEffect(() => {
    if (canvasRef.current) {
      clearScreen();
    }
  }, [canvasRef.current]);

  const toggleModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  const clearScreen = () => {
    let ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  useEffect(() => {
    if (properties && imageUrl) {
      fillRegions();
    }
  }, [properties]);

  const drawCoords = (pairs, ctx) => {
    let first = true;
    for (let i = 0; i < pairs.length; i++) {
      let coord = pairs[i];
      if (first) {
        ctx.moveTo(coord.x, coord.y);
        first = false;
      } else {
        ctx.lineTo(coord.x, coord.y);
      }
    }
  };

  const determineColor = (currentStage) => {
    let color = empty;
    let statusId = currentStage.propertyStatusId;
    if (currentStage.isLate) {
      color = lightRed;
    } else if (statusId == 4) {
      color = lightGreen;
    } else if (statusId == 22 || statusId == 2) {
      color = lightBlue;
    } else if (statusId == 5 || statusId == 6) {
      color = lightYellow;
    }
    return color;
  };

  const fillRegions = () => {
    let ctx = canvasRef.current.getContext("2d");
    let propertyRegions = [];
    for (let j = 0; j < properties.length; j++) {
      ctx.beginPath();
      let property = properties[j];
      let pairs = [];

      if (property.coordinates) {
        pairs = property.coordinates.split(",").map((x) => {
          let pair = x.split(" ");
          return { x: pair[0], y: pair[1] };
        });
        propertyRegions.push({
          Name: property.addressLine1,
          Coords: pairs,
          Id: property.propertyId,
        });
      }
      drawCoords(pairs, ctx);
      ctx.fillStyle = determineColor(property.currentStage);
      ctx.fill();
    }
    setPropertyCoords(propertyRegions);
  };

  const canvasHover = (e) => {
    let hit = detectCanvasHit(e, (property) => {
      tooltipRef.current.innerText = property.Name;
      setTooltipClass(classes.fadeIn);
      setTimeout(() => {
        tooltipRef.current.style.visibility = "visible";
      }, 150);
      if (!lockTooltipPos) {
        setMousePosX(e.clientX - 35);
        setMousePosY(e.clientY - 35);
      }
      setLockToolTipPos(true);
    });
    if (!hit) {
      setTooltipClass(classes.fadeOut);
      setLockToolTipPos(false);
      setTimeout(() => {
        tooltipRef.current.style.visibility = "hidden";
      }, 150);
    }
  };

  const detectCanvasHit = (e, Action) => {
    let ctx = canvasRef.current.getContext("2d");
    let rect = canvasRef.current.getBoundingClientRect();
    let current = {
      x: Math.round(e.clientX - rect.left),
      y: Math.round(e.clientY - rect.top),
    };
    for (let i = 0; i < propertyCoords.length; i++) {
      ctx.beginPath();
      drawCoords(propertyCoords[i].Coords, ctx);
      if (ctx.isPointInPath(current.x, current.y)) {
        Action(propertyCoords[i]);
        return true;
      }
    }
    return false;
  };

  const canvasClick = (e) => {
    detectCanvasHit(e, (property) => {
      navigate("/homes/" + property.Id);
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "1rem",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        ref={tooltipRef}
        className={toolTipClass}
        style={{
          position: "fixed",
          top: mousePosY,
          left: mousePosX,
          visibility: "hidden",
          backgroundColor: "white",
          padding: "5px",
          borderRadius: "2px",
          border: "1px solid #999",
        }}
      ></div>
      <div style={border}>
        {imageUrl && (
          <canvas
            ref={canvasRef}
            onClick={(e) => canvasClick(e)}
            onMouseMove={(e) => canvasHover(e)}
            width={1000}
            height={700}
            style={{
              background: "url(" + imageUrl + ")",
              backgroundSize: "1000px 700px",
              backgroundRepeat: "no-repeat",
            }}
          ></canvas>
        )}
        {!imageUrl && (
          <div style={flexCenter}>
            <p>No Plat map found, please upload one.</p>
            <MiButton
              title="Upload Plat Map"
              icon={mdiUpload}
              inverse={true}
              onClick={toggleModal}
              color={THEME.GREEN_PRIMARY}
            />

            {showUploadModal && (
              <UploadPlatMapModal
                communityId={communityId}
                toggle={toggleModal}
                refresh={refresh}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
const flexCenter = {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
};
const border = {
  border: "1px solid black",
  minHeight: 700,
  minWidth: 1000,
  ...flexCenter,
};
export default CommunityPlat;
