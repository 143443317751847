import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const searchService = {
  getPropertiesByAddress: async (address) => {
    const params = { address };
    const options = await apiOptions({
      url: `${API_V3}/search/property`,
      method: "get",
      params,
    });

    return apiRequest(options);
  },

  globalSearch: async (query) => {
    const params = { query };
    const options = await apiOptions({
      url: `${API_V3}/search/global`,
      method: "get",
      params,
    });

    return apiRequest(options);
  },
};
