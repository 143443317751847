import React, { useEffect, useState } from "react";
import {
  walkTypeTemplateService,
  walkTypeTemplateItemService,
} from "@miview/api";
import { useTheme } from "@mui/material";
import { MiTable, MiButton, MiModal, MiIcon } from "@miview/components";
import { useEdit, useComponentState } from "@miview/hooks";
import { HTTP_STATUSES } from "@miview/constants";
import { CreateTemplateItemModal } from "./CreateTemplateItemModal";
import { mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";

export const WalkTemplateItems = (props) => {
  const { templateId } = props;

  const [walkTemplateItems, setWalkTemplateItems] = useState([]);
  const [editRow, setEditRow] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const remove = useEdit();
  const stateManager = useComponentState();
  const theme = useTheme();

  useEffect(() => {
    getItems();
  }, [templateId]);

  const getItems = () => {
    stateManager.run(async () => {
      const response = await walkTypeTemplateService.getItems(templateId);
      if (response.status === HTTP_STATUSES.OK) {
        setWalkTemplateItems(response.data);
      }
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Item Name",
      headerAlign: "left",
      align: "left",
      flex: 2,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      align: "left",
      flex: 2,
    },
    {
      field: "propertySection",
      headerName: "Property Section",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "displayOrder",
      headerName: "Display Order",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "forcePicture",
      headerName: "Force Picture",
      headerAlign: "left",
      align: "left",
      renderCell: (c) =>
        c.value ? <i className="material-icons">check</i> : "",
      flex: 1,
    },
    {
      field: "forceDocument",
      headerName: "Force Document",
      headerAlign: "center",
      align: "center",
      renderCell: (c) =>
        c.value ? <i className="material-icons">check</i> : "",
      flex: 1,
    },
    {
      field: "canHavePunchItem",
      headerName: "Can Have Punch Item",
      headerAlign: "center",
      align: "center",
      renderCell: (c) =>
        c.value ? <i className="material-icons">check</i> : "",
      flex: 1,
    },
    {
      field: "editable",
      headerName: "Editable",
      headerAlign: "center",
      align: "center",
      renderCell: (c) =>
        c.value ? <i className="material-icons">check</i> : "",
      flex: 1,
    },
    {
      field: "repeat",
      headerName: "Repeat",
      headerAlign: "center",
      align: "center",
      renderCell: (c) =>
        c.value ? <i className="material-icons">check</i> : "",
      flex: 1,
    },
  ];

  const openEditModal = (item) => {
    setEditRow(item);
    setIsEditModalOpen(true);
  };

  const closeEditModal = (didSave) => {
    setEditRow({});
    setIsEditModalOpen(false);
    if (didSave) {
      getItems();
    }
  };

  const openDeleteModal = (cell) => {
    if (cell) {
      setIsDeleteModalOpen(true);
      remove.update({
        id: cell.id,
        description: cell.description,
      });
    }
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    remove.reset();
  };

  const deleteWalkTemplateItem = () => {
    stateManager.run(async () => {
      const response = await walkTypeTemplateItemService.delete(
        remove.getValue("id")
      );
      if (response.status === HTTP_STATUSES.OK) {
        getItems();
        closeDeleteModal();
      }
    });
  };

  const renderDeleteModal = () => {
    if (!isDeleteModalOpen) {
      return null;
    }
    return (
      <MiModal
        key={1}
        title={"Confirm Delete"}
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        actions={[
          {
            name: "Cancel",
            onClick: () => setIsDeleteModalOpen(false),
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Delete",
            onClick: deleteWalkTemplateItem,
            color: theme.palette.primary.red,
            inverse: true,
          },
        ]}
      >
        <div style={{ width: "100%", padding: "5px" }}>
          {`Delete ${remove.getValue("description")}?`}
        </div>
      </MiModal>
    );
  };

  const renderEditModal = () => {
    if (!isEditModalOpen) {
      return null;
    }
    return (
      <CreateTemplateItemModal
        item={editRow}
        close={closeEditModal}
        walkTypeTemplateId={templateId}
        isEditModalOpen={isEditModalOpen}
      />
    );
  };

  const getRowActions = () => {
    return [
      {
        text: "EDIT",
        onClick: (e) => openEditModal(e),
        renderIcon: () => <MiIcon path={mdiPencil} size={1} />,
      },
      {
        text: "DELETE",
        onClick: (e) => openDeleteModal(e),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
    ];
  };

  return (
    <>
      {renderDeleteModal()}
      {renderEditModal()}
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <MiButton
          id="addTemplateItem"
          title="Template Item"
          icon={mdiPlus}
          inverse={true}
          onClick={() => openEditModal()}
          color={theme.palette.primary.main}
        />
      </div>
      <MiTable
        columns={columns}
        rows={walkTemplateItems}
        pageSize={30}
        pagination
        disableColumnMenu
        getRowActions={getRowActions}
      />
    </>
  );
};
