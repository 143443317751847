import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import PropTypes from "prop-types";
import { propertyService } from "@miview/api";
import { formatDate } from "../../../helpers/dateTools";
import { HTTP_STATUSES } from "@miview/constants";
import { MiTable, MiLink } from "@miview/components";

export const StageInfoCollapse = (props) => {
  const { propertyId, isButtonOpen } = props;
  const [status, setStatus] = useState("Loading...");
  const [stageWorkHistory, setStageWorkHistory] = useState([]);

  const columns = [
    {
      field: "name",
      headerName: "Stage",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row, value }) => {
        return (
          <MiLink
            to={`/stages/${row.id}`}
            style={{
              marginLeft: 0,
              paddingLeft: 0,
              whiteSpace: "normal",
            }}
            title={value}
          />
        );
      },
    },
    {
      field: "stageTypeName",
      headerName: "Stage",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "scheduledStartDate",
      headerName: "Scheduled Start Date",
      headerAlign: "left",
      align: "left",
      flex: 1,
      valueGetter: ({ value }) => formatDate(value) || "--",
    },
    {
      field: "actualCycleTime",
      headerName: "Total Cycle Time",
      headerAlign: "left",
      align: "left",
      flex: 1,
      valueGetter: ({ value }) => value || "--",
    },
    {
      field: "statusName",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "notes",
      headerName: "Reason for Change",
      headerAlign: "left",
      align: "left",
      flex: 1,
      valueGetter: ({ value }) => value || "--",
    },
  ];

  const handleGetStages = async () => {
    const response = await propertyService.getStages({
      id: propertyId,
      params: {
        pageSize: 200,
      },
    });
    if (response.status === HTTP_STATUSES.OK) {
      setStageWorkHistory(response.data);
      setStatus("");
      return;
    }
    setStatus("Error Loading Stages");
  };

  useEffect(() => {
    handleGetStages();
  }, [propertyId]);

  if (status) {
    return <div>{status}</div>;
  }

  return (
    <div style={{ overflowY: "hidden" }} className="animated fadeIn">
      <Collapse isOpen={isButtonOpen}>
        {stageWorkHistory.length > 0 && (
          <MiTable
            tableContainerStyle={{
              width: "100%",
              minHeight: "100px",
            }}
            columns={columns}
            toolbarProps={{ visible: false }}
            rows={stageWorkHistory}
            style={{ minHeight: "100px" }}
            headerHeight={40}
            autoHeight={true}
            hideFooter={false}
            pagination
            disableColumnMenu
          />
        )}
      </Collapse>
    </div>
  );
};

StageInfoCollapse.propTypes = {
  isButtonOpen: PropTypes.bool,
};
