import React, { useState } from "react";
import { walkService } from "@miview/api";
import { getImageUrl, createFilter, createUUID } from "@miview/utils";
import { useComponentState, useCdn } from "@miview/hooks";
import { HTTP_STATUSES, WALK_STATUSES_LIST, COMPLETE } from "@miview/constants";
import { MiCard, MiIconButton, MiLoader, MiPopover } from "@miview/components";
import { ItemIconHelper, TableIconHelper } from "./constants";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Icon from "@mdi/react";

export const InspectionPopover = ({
  data,
  statusChip,
  setSelectedImage,
  setImages,
}) => {
  const [anchorTarget, setAnchorTarget] = useState(null);
  const [jobItems, setJobItems] = useState([]);
  const stateManager = useComponentState();
  const uuid = createUUID();
  const theme = useTheme();
  const cdn = useCdn();

  const getIncompletePlanItems = () => {
    stateManager.abort(uuid);
    return stateManager.run(async (signal) => {
      const jobItemFilter = createFilter();
      jobItemFilter.equals({ status: WALK_STATUSES_LIST.INCOMPLETE });
      const jobItemResponse = await walkService.getWalkItems({
        id: data.id,
        filters: jobItemFilter.getFilter(),
        signal,
      });
      if (jobItemResponse.status === HTTP_STATUSES.OK) {
        setJobItems(jobItemResponse.data);
      }
    }, uuid);
  };

  const handleClick = (e) => {
    if (data.row.status !== WALK_STATUSES_LIST.INCOMPLETE) {
      return;
    }
    getIncompletePlanItems();
    setAnchorTarget(e.target);
  };

  const onClose = () => {
    setAnchorTarget();
  };

  const getPlanItemImage = (item) => {
    const imageItem = item.images.length > 0 ? item.images[0] : "";
    const images = item.images
      ? item.images.map((imgDetails) => imgDetails.imageName)
      : [];

    return (
      <div
        style={{
          height: 50,
          width: 50,
        }}
        onClick={() => {
          setImages(images);
          setSelectedImage(imageItem.imageName);
        }}
      >
        {imageItem && (
          <img
            style={{ width: "100%", height: "100%" }}
            src={getImageUrl(imageItem, cdn)}
          />
        )}
      </div>
    );
  };

  const { status } = data.row;
  return (
    <div>
      {status && status !== COMPLETE && (
        <MiIconButton onClick={handleClick}>
          <Icon
            path={TableIconHelper[status].tableIconPath}
            key={data.value}
            size={1}
            color={TableIconHelper[status].tableIconColor}
          />
        </MiIconButton>
      )}

      <MiPopover anchorTarget={anchorTarget} onClose={onClose}>
        <Grid container rowSpacing={1}>
          <Grid
            item
            xs={12}
            style={{
              padding: "10px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ marginRight: "10px", fontWeight: "500" }}>
              {`${data.row.walkTypeName} ${
                data.row.attemptNumber > 1 ? `#${data.row.attemptNumber}` : ""
              }`}
            </div>
            {statusChip(data.row.status)}
          </Grid>
        </Grid>
        {stateManager.isBusy() && <MiLoader type="linear" />}
        <Grid container rowSpacing={1} columnSpacing={1}>
          {jobItems.length === 0 && !stateManager.isBusy() && (
            <Grid item xs={12} style={{ textAlign: "center", padding: "10px" }}>
              No Incomplete Items
            </Grid>
          )}
          {jobItems.map((item) => {
            return (
              <Grid
                item
                xs={12}
                key={`${item.id}${item.walkId}`}
                style={{ padding: "5px 15px" }}
              >
                <MiCard
                  title={item.name}
                  titleStyle={{
                    fontWeight: 500,
                    color: theme.palette.primary.main,
                  }}
                  icon={ItemIconHelper[item.status].itemIconPath}
                  iconProps={{
                    color: ItemIconHelper[item.status].itemIconColor,
                    size: 1.2,
                  }}
                  rightArea={getPlanItemImage(item)}
                  style={{ minHeight: "80px" }}
                />
              </Grid>
            );
          })}
        </Grid>
      </MiPopover>
    </div>
  );
};
