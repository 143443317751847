import React from "react";
import { appStorage } from "@miview/utils";
import { createRoot } from "react-dom/client";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { VariablesFilePath } from "./constants";

var getApiUrl = async () => {
  try {
    const variablesStream = await fetch(`${window.location.origin}/${VariablesFilePath}`,
    {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    });
    
    const variables = await variablesStream.json();
    const result = variables.REACT_APP_API_URL;
    return result;
  } catch {
    return process.env.REACT_APP_LOCAL_API_URL;
  }
};

const startApp = (apiUrl) => {
  const root = createRoot(document.getElementById("root"));
  root.render(<App apiUrl={apiUrl} />);
  registerServiceWorker();
  appStorage.setItem("url", apiUrl);
};

if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
  startApp(
    process.env.REACT_APP_LOCAL_API_URL || process.env.REACT_APP_DEV_API_URL
  );
} else {
  getApiUrl().then((api) => {
    startApp(api);
  });
}