import { getToken, getRootUrl } from "./";
import { createAndedFields, createQueryString } from "@miview/utils";

export const apiOptions = async ({
  params = {},
  url = "",
  method = "get",
  noToken,
  data,
  etag,
  headers,
  filters,
  sort,
  responseType,
  signal,
} = {}) => {
  let rootUrl = "";
  if (!url.includes("http")) {
    rootUrl = await getRootUrl();
  }

  if (filters && !params?.query) {
    if (!filters.linkOperator) {
      params = { ...params, query: oldQueryMethod(filters) };
    } else {
      params = { ...params, query: createQueryString(filters) };
    }
  }

  if (sort) {
    params = {
      ...params,
      orderBy: getOrderByParam(sort),
      descending: getDescendingParam(sort),
    };
  }

  const options = {
    method: method.toLowerCase(),
    url: `${rootUrl}${url}`,
    headers: {
      "Content-Type": "application/json",
    },
    params,
    responseType,
    signal,
  };

  if (etag) {
    options.headers["if-none-match"] = etag;
  }

  if (process.env.NODE_ENV === "development") {
    const additionalHeaders = {
      "Miview-TenantId": 1,
      "Miview-UserId": "c2eb3b1e-1a3c-4d9e-b1f2-f9a7d7b6559b",
      "Miview-Permissions": "CanViewAccounts",
    };
    options.headers = { ...options.headers, ...additionalHeaders };
  }
  if (headers) {
    options.headers = { ...options.headers, ...headers };
  }

  if (!noToken) {
    const userToken = await getToken();
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  switch (options.method) {
    case "post":
    case "patch":
    case "put":
      return {
        ...options,
        data,
      };
    case "delete":
    case "get":
    default:
      return options;
  }
};

const oldQueryMethod = (filters) => {
  const pairs = Object.entries(filters)
    .filter(([, value]) => value !== undefined)
    .map(([columnField, value]) => {
      return { columnField, value };
    });
  const query = createAndedFields(pairs);
  return query;
};

const getOrderByParam = (sortModel) => {
  return sortModel?.map((sort) => sort.field).join(",");
};

const getDescendingParam = (sortModel) => {
  return sortModel[0]?.sort === "desc";
};
