import React, { useState } from "react";
import { MiTab } from "@miview/components";
import { PAY_SETTINGS_TABS } from "@miview/constants";
import { BonusRulesTab } from "./BonusRulesTab";
import { PayActivityTab } from "./PayActivityTab";
import { ExpenseTypeTab } from "./ExpenseTypeTab";

const BonusSettings = () => {
  const [activeTab, setActiveTab] = useState(PAY_SETTINGS_TABS.RULES);

  const tabs = [
    {
      label: "Rules",
      value: PAY_SETTINGS_TABS.RULES,
    },
    {
      label: "Pay Activity Types",
      value: PAY_SETTINGS_TABS.PAY_ACTIVITY_TYPES,
    },
    {
      label: "Expense Types",
      value: PAY_SETTINGS_TABS.EXPENSE_TYPES,
    },
  ];

  const Tabs = () => {
    return (
      <div style={cardMain}>
        <MiTab tabs={tabs} currenttab={activeTab} onTabChange={setActiveTab} />
      </div>
    );
  };

  return (
    <div>
      {activeTab === PAY_SETTINGS_TABS.RULES && <BonusRulesTab tabs={Tabs} />}
      {activeTab === PAY_SETTINGS_TABS.PAY_ACTIVITY_TYPES && (
        <PayActivityTab tabs={Tabs} />
      )}
      {activeTab === PAY_SETTINGS_TABS.EXPENSE_TYPES && (
        <ExpenseTypeTab tabs={Tabs} />
      )}
    </div>
  );
};

const cardMain = {
  width: "100%",
  marginBottom: 20,
};

export default BonusSettings;
