import { makeStyles } from "tss-react/mui";

const areaItemFormStyles = {
  areaItemFormBox: {
    boxShadow: "0px 0px 5px 2px rgba(235, 235, 235, 1)",
    width: "100%",
    borderRadius: "5px",
    minHeight: "50px",
    padding: "5px",
  },
  areaItemFormLabel: {
    "& p": {
      color: "#5c5c5c",
      marginTop: "0px",
      marginBottom: "0px",
      paddingLeft: "0.8rem",
    },
  },
  areaItemFormDetail: { flexGrow: 1 },
  areaItemFormEditIcon: {
    paddingLeft: "6px",
    cursor: "pointer",
    color: "gray",
    "& i": { fontSize: "16px" },
  },
  areaItemFormImage: {
    backgroundColor: "rgb(214, 214, 214)",
    height: "60px",
    width: "60px",
    borderRadius: "5px",
    border: "1px solid gray",
    cursor: "pointer",
    "& img": {
      height: "60px",
      width: "60px",
      borderRadius: "5px",
      border: "1px solid gray",
    },
    "&:hover": { backgroundColor: "lightblue" },
    "& img:hover": { opacity: 0.5 },
  },
  areaItemFormMaterial: { display: "flex" },
  areaItemFormDescription: {
    "& p": {
      color: "#999999",
      paddingLeft: "0.8rem",
      marginBottom: "0",
    },
  },
  areaItemFormMaterialSection: { padding: "5px" },
  createDrawingInput: {
    width: 0.1,
    height: "0.1",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },
  createDrawingLabel: {
    margin: "0",
    height: "40px",
    textTransform: "capitalize",
    textAlign: "center",
    flex: 0,
  },
  areaItemFormAddMaterialButton: {
    marginTop: "8px",
    textDecoration: "underline",
    cursor: "pointer",
    color: "green",
  },
  areaItemFormMaterialsEdit: { display: "flex" },
  areaItemFormMaterialInput: { marginBottom: "0px" },
  areaLabelIconInner: { height: "20px", width: "20px" },
  areaItemFormRestoreIcon: {
    color: "green",
    opacity: 1,
    height: "20px",
    width: "20px",
  },
  areaItemFormFormButtons: { display: "flex", marginTop: "20px" },
  areaItemFormRemoveImage: {
    margin: "0px 0px 0px 17px",
    textDecoration: "underline",
    fontSize: "12px",
    cursor: "pointer",
  },
};

export const useAreaItemFormStyles = makeStyles()(areaItemFormStyles);
