import React, { useState } from "react";
import { MiModal } from "../../../components/Standard/MiView";
import { MiCheckbox, MiInputTextbox } from "@miview/components";
import { THEME } from "../../../components/Standard/theme";

export const PublishModal = ({ propertyId, setPublishModal, publish }) => {
  const [textArea, setTextArea] = useState("");
  const [checkbox, setCheckBox] = useState(true);

  return (
    <MiModal
      title="Publish"
      saveAction={() => {
        let data = {};
        const message = textArea ? { message: textArea } : {};
        if (checkbox) {
          data = { ...message, shouldNotifyCrew: checkbox };
        }
        publish(propertyId, data);
        setPublishModal(false);
      }}
      toggle={() => setPublishModal(false)}
      saveIcon="check"
      saveTitle="Publish"
      color={THEME.BLUE_PRIMARY}
    >
      <div style={styles.textStyle}>
        You are about to publish your changes down to the field, please make
        sure that you have made all of your updates before publishing.
      </div>
      <div style={styles.formContainer}>
        <MiInputTextbox
          labelText="Notes"
          value={textArea}
          type="textarea"
          inputStyle={styles.textArea}
          height={104}
          handleChange={(e) => {
            setTextArea(e.target.value);
          }}
        />
        <MiCheckbox
          label="Notify Field"
          checked={checkbox}
          onChange={() => setCheckBox(!checkbox)}
        />
      </div>
    </MiModal>
  );
};

const styles = {
  textArea: {
    resize: "none",
  },
  textStyle: {
    textAlign: "center",
  },

  formContainer: {
    margin: "20px 100px 0 100px",
  },
};
