import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { withStyles } from "tss-react/mui";
import { THEME } from "@miview/theme";
import { MiInputTextbox, MiInputSelectBox, MiButton } from "@miview/components";
import { areaStyles } from "./MiAreaStyles";
import { createBackgroundColor } from "./MiArea";
import { PlanItemImageLabel } from "./StyledComponents/PlanItemImageLabel";
import { PlanItemImageFocus } from "./StyledComponents/PlanItemImageFocus";
import { getImageUrl, getImageBase64FromFile } from "@miview/utils";
import { useCdn } from "@miview/hooks";

class ImageContainer extends Component {
  componentDidMount() {
    this.nv.addEventListener("paste", this.onPasteImageHandler, true);
  }

  componentWillUnmount() {
    this.nv.removeEventListener("paste", this.onPasteImageHandler, true);
  }

  onPasteImageHandler = (pasteEvent) => {
    const items = pasteEvent.clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") == -1) {
        continue;
      }
      const blob = items[i].getAsFile();

      getImageBase64FromFile(blob, this.props.handleUpdateImage);
    }
  };

  render() {
    const { handleUpdateImage, imageSrc, planAreaId, classes, cdn } =
      this.props;
    const handleCreateDrawing = (e) => {
      getImageBase64FromFile(e.target.files[0], handleUpdateImage);
    };

    const renderImage = () => {
      if (imageSrc) {
        return <img src={getImageUrl(imageSrc, cdn)} />;
      } else {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 18,
            }}
          >
            <CameraAltIcon className={classes.areaImageIcon} />
          </div>
        );
      }
    };

    return (
      <PlanItemImageFocus
        tabIndex="0"
        forwardedRef={(elem) => (this.nv = elem)}
      >
        <div>
          <PlanItemImageLabel>Image</PlanItemImageLabel>
          <input
            className={classes.createDrawingInput}
            type="file"
            id={`areaDrawing-${planAreaId}`}
            name={`areaDrawing-${planAreaId}`}
            accept="image/png, image/jpeg"
            onChange={handleCreateDrawing}
          ></input>
          <label
            htmlFor={`areaDrawing-${planAreaId}`}
            className={classes.createDrawingLabel}
          >
            <Tooltip
              title="Click to upload or paste an image from clipboard"
              aria-label="Add Image"
            >
              <div className={classes.areaItemFormImage}>{renderImage()}</div>
            </Tooltip>
          </label>
        </div>
      </PlanItemImageFocus>
    );
  }
}

export const MiAreaForm = ({
  savePlanArea,
  floors,
  propertySections,
  isDeleted,
  isRule,
  classes,
  onCancelAreaEdit,
  edit,
}) => {
  const cdn = useCdn();

  const handleSave = () => {
    savePlanArea();
  };

  const handleCancel = () => {
    onCancelAreaEdit();
  };

  const saveIsDisabled = () => {
    return false;
  };

  const handleUpdateImage = (imageData) => {
    edit.update({ image: imageData });
  };

  const handleRemoveImage = () => {
    edit.update({
      image: null,
      imageUrl: null,
    });
  };

  const renderRemoveImageLink = () => {
    const imageSrc = edit.getValue("imageUrl");

    if (imageSrc) {
      return (
        <div
          style={{
            color: THEME.RED_PRIMARY,
          }}
          className={classes.areaItemFormRemoveImage}
          onClick={() => handleRemoveImage()}
        >
          Remove
        </div>
      );
    }

    return null;
  };

  return (
    <div className={classes.areaHeader}>
      <div
        className={classes.iconWrapper}
        style={{
          backgroundColor: createBackgroundColor(isDeleted, isRule),
        }}
      ></div>
      <div className={classes.areaLabelForm}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1 }}>
            <div style={{ marginTop: 2, display: "block", padding: "5px 0px" }}>
              <MiInputTextbox
                labelText="Plan Area Name"
                required
                value={edit.getValue("planAreaName")}
                handleChange={(e) =>
                  edit.update({ planAreaName: e.target.value })
                }
              />
            </div>
            <div style={{ padding: "0 0 5px 0px" }}>
              <MiInputSelectBox
                labelText="Area"
                value={edit.getValue("propertySectionId")}
                required
                handleChange={(e) =>
                  edit.update({ propertySectionId: e.target.value })
                }
                defaultOption="--"
                labelField={"text"}
                menuItems={propertySections}
              />
            </div>
            <div>
              {!edit.getValue("isGroundFloor") && floors?.length > 1 && (
                <MiInputSelectBox
                  labelText="Floor"
                  value={edit.getValue("floorId")}
                  labelField={"text"}
                  handleChange={(e) => edit.update({ floorId: e.target.value })}
                  defaultOption="--"
                  menuItems={floors}
                />
              )}
            </div>
          </div>
          <div style={{ marginRight: 10 }}>
            <ImageContainer
              handleUpdateImage={handleUpdateImage}
              imageSrc={edit.getValue("image") || edit.getValue("imageUrl")}
              classes={classes}
              planAreaId={edit.getValue("planAreaId")}
              cdn={cdn}
            />
            {renderRemoveImageLink()}
          </div>
        </div>
        <div
          className={classes.areaItemFormFormButtons}
          style={{ margin: "0px 2px 6px 4px" }}
        >
          <MiButton onClick={handleCancel} color="gray" title="Cancel" />
          <div style={{ flex: 1 }} />
          <MiButton
            onClick={handleSave}
            color="white"
            style={{
              backgroundColor: THEME.BLUE_PRIMARY,
              width: 100,
              borderColor: THEME.BLUE_PRIMARY,
            }}
            title="Save"
            disabled={saveIsDisabled()}
          />
        </div>
      </div>
    </div>
  );
};

MiAreaForm.propTypes = {
  edit: PropTypes.object,
  savePlanArea: PropTypes.func,
  floors: PropTypes.array,
  propertySections: PropTypes.array,
};

export default withStyles(memo(MiAreaForm), areaStyles);
