import React from "react";
import { materialCategoryService } from "@miview/api";
import { MiModal, MiDetailFields } from "@miview/components";
import { useTheme } from "@mui/material";
import { useEdit } from "@miview/hooks";

const CreateMaterialCategoryModal = ({ onClose, open }) => {
  const theme = useTheme();
  const edit = useEdit({
    name: "",
    displayOrder: "",
  });

  const onModalClose = () => {
    edit.reset();
    onClose();
  };
  
  const onSave = async () => {
    let categoryObj = {
      ...edit.edits,
      isRequiredDefault: false,
    };

    await materialCategoryService.create(categoryObj);
    onModalClose();
  };

  const regex = /^\d*$/;

  const createMaterialInput = [
    {
      label: "Name",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "100%",
    },
    {
      label: "Display Order",
      value: edit.getValue("displayOrder"),
      setValue: (e) => {
        if (regex.test(e)) {
          edit.update({ displayOrder: e });
        }
      },
      width: "100%",
    },
  ];

  return (
    <MiModal
      title="Create Material Category"
      open={open}
      onClose={onModalClose}
      actions={[
        {
          name: "Cancel",
          style: { minWidth: 90 },
          onClick: onModalClose,
          color: theme.palette.medium.grey,
          inverse: false,
        },
        {
          name: "Save",
          onClick: onSave,
          style: { minWidth: 90 },
          color: theme.palette.primary.green,
          inverse: true,
          disabled: !edit.allFilled("name"),
        },
      ]}
    >
      <MiDetailFields detailFields={createMaterialInput} />
    </MiModal>
  );
};

export default CreateMaterialCategoryModal;
