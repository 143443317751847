import React, { useMemo, useState } from "react";
import { MiLink, MiModalConfirm, MiIcon, MiList } from "@miview/components";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  MailOutline as MailOutlineIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  Block as BlockIcon,
} from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { pricingService } from "@miview/api";
import moment from "moment";
import {
  QUOTE_STATUS,
  HTTP_STATUSES,
  QUOTE_ATTACHMENT_DISCRIMINATOR,
} from "@miview/constants";
import { createFilter, combineFilters, openInNewTab } from "@miview/utils";
import {
  mdiContentCopy,
  mdiFileSign,
  mdiTrashCanOutline,
  mdiDownload,
  mdiPaperclip,
} from "@mdi/js";
import { QuoteStatus } from "./Status";
import en from "../locale/en.json";
import CancelQuoteModal from "./CancelQuoteModal";

const useStyles = makeStyles()((theme) => ({
  fileStyle: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: 20,
      color: theme.palette.lightShade.bluegrey,
    },
  },
  attachment: {
    "& :hover": {
      cursor: "pointer",
    },
  },
}));

const QuotesComponent = ({ accountId }) => {
  const [quotes, setQuotes] = useState([]);
  const [selectedChipStatus, setSelectedChipStatus] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const theme = useTheme();
  const { classes } = useStyles();

  const getQuotes = (config) => {
    const f = createFilter();
    if (accountId) {
      f.equals({ accountId });
    }
    if (selectedChipStatus) {
      f.equals({ status: selectedChipStatus });
    }

    const filters = combineFilters(config.filters, f.getFilter());
    return pricingService.quotes.getAll({ ...config, filters });
  };

  const handleMakeANewCopy = async () => {
    const response = await pricingService.quotes.copy({
      id: selectedRow.id,
      successMessage: en.quoteSuccessfullyCopied,
    });
    if (response.status === HTTP_STATUSES.CREATED) {
      setQuotes([...quotes, response.data]);
      closeCopyModal();
    }
  };

  const openCopyModal = (row) => {
    setIsCopyModalOpen(true);
    setSelectedRow(row);
  };

  const closeCopyModal = () => {
    setIsCopyModalOpen(false);
    setSelectedRow(null);
  };

  const cancelQuote = async (reason) => {
    const response = await pricingService.quotes.update({
      id: selectedRow.id,
      request: { status: QUOTE_STATUS.CANCELLED, cancelReason: reason },
      successMessage: en.requestCancelled,
    });
    if (response.status === HTTP_STATUSES.OK) {
      const newQuotes = [...quotes];
      newQuotes.splice(quotes.indexOf(selectedRow), 1, response.data);
      setQuotes(newQuotes);
      closeCancelModal();
    }
  };

  const openCancelModal = (row) => {
    setSelectedRow(row);
    setIsCancelModalOpen(true);
  };

  const closeCancelModal = () => {
    setSelectedRow(null);
    setIsCancelModalOpen(false);
  };

  const deleteQuote = async () => {
    const response = await pricingService.quotes.delete({
      id: selectedRow.id,
      successMessage: en.quoteSuccessfullyDeleted,
    });
    if (response.status === HTTP_STATUSES.NO_CONTENT) {
      setQuotes(quotes.filter((e) => e.id !== selectedRow.id));
      closeDeleteModal();
    }
  };

  const openDeleteModal = (row) => {
    setIsDeleteModalOpen(true);
    setSelectedRow(row);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedRow(null);
  };

  const columns = useMemo(() => [
    {
      field: "quoteNumber",
      headerName: en.quoteNumber,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <MiLink
            title={params.value}
            color={theme.palette.primary.blue}
            to={`/purchasing/quotes/${params.row.id}`}
            style={{
              margin: 0,
              fontWeight: theme.fontWeight.medium,
              fontSize: theme.fontSize.medium,
              padding: 0,
              textDecoration: "none",
            }}
          />
        );
      },
    },
    {
      field: "accountName",
      headerName: en.accountName,
      flex: 1,
    },
    {
      field: "effectiveDate",
      headerName: en.quoteEffectiveDate,
      minWidth: 140,
      flex: 1,
      renderCell: (params) =>
        moment(params.row.effectiveDate).format("DD/MM/YY"),
    },
    {
      field: "externalQuoteNumber",
      headerName: en.quoteID,
      flex: 1,
    },
    {
      field: "description",
      headerName: en.Description,
      flex: 1,
    },
    {
      field: "status",
      headerName: en.status,
      headerClassName: "supplier-header",
      minWidth: 190,
      flex: 1,
      renderCell: (params) => {
        return <QuoteStatus value={params.value} />;
      },
    },
    {
      field: "attachments",
      headerName: en.file,
      flex: 1,
      minWidth: 190,
      renderCell: (params) => {
        const attachment = params.value?.find(
          (e) =>
            e.discriminator === QUOTE_ATTACHMENT_DISCRIMINATOR.QUOTE_ATTACHMENT
        );
        if (attachment) {
          return (
            <div className={`${classes.fileStyle} ${classes.attachment}`}>
              <MiIcon
                onClick={() => openInNewTab(attachment.uri)}
                path={mdiDownload}
              />
              {attachment.displayName}
            </div>
          );
        }
        return (
          <div className={classes.fileStyle}>
            <MiIcon path={mdiPaperclip} /> No Attachment
          </div>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: ({ row }) => {
        let actionButtons = [];
        if (row.status === QUOTE_STATUS.REQUESTED) {
          actionButtons.push(
            <GridActionsCellItem
              icon={<MailOutlineIcon />}
              label={en.resendRequest}
              onClick={() => null}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<BlockIcon />}
              label={en.cancelRequest}
              onClick={() => openCancelModal(row)}
              showInMenu
            />
          );
        }
        if (
          row.status === QUOTE_STATUS.ACCEPTED ||
          row.status === QUOTE_STATUS.CANCELLED
        ) {
          actionButtons.push(
            <GridActionsCellItem
              icon={<FileCopyOutlinedIcon />}
              label={en.makeANewCopy}
              onClick={() => openCopyModal(row)}
              showInMenu
            />
          );
        }
        if (row.status === QUOTE_STATUS.DRAFT) {
          actionButtons.push(
            <GridActionsCellItem
              icon={<DeleteOutlinedIcon />}
              label={en.delete}
              onClick={() => openDeleteModal(row)}
              showInMenu
            />
          );
        }
        return actionButtons;
      },
    },
  ]);

  const getEmptyListProps = () => {
    return {
      icon: mdiFileSign,
      text: "There are no quotes created.",
      helperText: "Tip: Get started by clicking the 'Add Quotes' button.",
    };
  };

  const handleChipChange = async (selectedChip) => {
    setSelectedChipStatus(selectedChip[0].status);
  };

  const renderSuppliers = () => {
    return (
      <MiList
        columns={columns}
        data={quotes}
        setData={setQuotes}
        dataProvider={getQuotes}
        refresh={selectedChipStatus}
        emptyListProps={getEmptyListProps()}
        filterArray={[
          {
            options: [],
          },
          {
            type: "filterAction",
            activeColor: theme.palette.primary.main,
            color: theme.palette.accent.grey,
            handleChange: handleChipChange,
            values: [
              {
                display: "All",
                status: null,
              },
              {
                display: en.statusDraft,
                status: QUOTE_STATUS.DRAFT,
              },
              {
                display: en.statusRequested,
                status: QUOTE_STATUS.REQUESTED,
              },
              {
                display: en.statusAccepted,
                status: QUOTE_STATUS.ACCEPTED,
              },
              {
                display: en.statusCancelled,
                status: QUOTE_STATUS.CANCELLED,
              },
            ],
            selectedItems: [
              {
                display: selectedChipStatus || "All",
                status: selectedChipStatus,
              },
            ],
            size: "medium",
            chipType: "radio",
            valueField: "display",
          },
        ]}
      />
    );
  };

  const renderCopyModal = () => {
    return (
      <MiModalConfirm
        open={isCopyModalOpen}
        title={en.quoteCopyTitle}
        icon={mdiContentCopy}
        description={en.quoteCopyDescription.replace(
          "%s",
          selectedRow?.quoteNumber
        )}
        color={theme.palette.primary.main}
        handlePositive={handleMakeANewCopy}
        handleNegative={closeCopyModal}
      />
    );
  };

  const renderDeleteModal = () => {
    return (
      <MiModalConfirm
        open={isDeleteModalOpen}
        title={en.quoteDeleteTitle}
        icon={mdiTrashCanOutline}
        description={en.quoteDeleteDescription.replace(
          "%s",
          selectedRow?.quoteNumber
        )}
        color={theme.palette.primary.red}
        handlePositive={deleteQuote}
        handleNegative={closeDeleteModal}
      />
    );
  };

  const renderCancelModal = () => {
    return (
      <CancelQuoteModal
        isCancelModalOpen={isCancelModalOpen}
        closeCancelModal={closeCancelModal}
        cancelQuote={cancelQuote}
      />
    );
  };

  return (
    <>
      {renderCopyModal()}
      {renderDeleteModal()}
      {renderCancelModal()}
      {renderSuppliers()}
    </>
  );
};

export default QuotesComponent;
