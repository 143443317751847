export const toList = (data = [], key = "key", value = "value") => {
  return data.length
    ? data.map((item) => {
        return {
          value: item[key],
          text: item[value],
        };
      })
    : [];
};
