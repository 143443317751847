import React from "react";
import { makeStyles } from "tss-react/mui";
import { AppBar as MuiAppBar, Grid, Toolbar, IconButton } from "@mui/material";
import {
  ToolIcons,
  UserAvatar,
  SearchBar,
  HeaderLogo,
  MiBanner,
} from "../index.ts";
import { APP_BAR_HEIGHT, DRAWER_WIDTH } from "@miview/constants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const useStyles = makeStyles()((theme, { props }) => {
  return {
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      width: `100%`,
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
      borderBottom: "1px solid #DDD",
    },
    menuLeft: {
      width: DRAWER_WIDTH,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("sm")]: {
        width: "55px",
      },
    },
    menuLeftClosed: {
      width: `55px`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    leftContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(0, 1),
      minHeight: "60px",
      backgroundColor: "white",
      borderRight: `1px solid ${theme.palette.accent.grey}`,
      [theme.breakpoints.down("sm")]: { borderRight: "0px" },
    },
    primaryToolBar: {
      minHeight: APP_BAR_HEIGHT,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      flex: 1,
      [theme.breakpoints.up("sm")]: { minHeight: "60px" },
    },
    secondaryToolbar: {
      height: 40,
    },
    searchContainer: {
      flex: 3,
      justifyContent: "flex-end",
    },
    rightAppBarContainer: {
      display: "flex",
      flex: 2,
      maxWidth: "50%",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    headerPush: {
      marginTop: APP_BAR_HEIGHT,
      width: "100%",
    },
    actionContainer: {
      textAlign: "center",
    },
    iconGrid: {
      [theme.breakpoints.down("sm")]: { display: "none" },
    },
    hideChevronIcon: {
      display: "none",
    },
    ChevronIconVisible: {},
    barWidth: {
      width: props.addOnDrawerWidth,
    },
  };
});

export const MiAppBar = ({
  open,
  mainApp,
  handleDrawer,
  rightContent,
  user,
  addOnDrawerWidth,
  settingApp,
}) => {
  const { classes, cx } = useStyles({
    props: {
      open,
      drawerWidth: DRAWER_WIDTH,
      addOnDrawerWidth,
    },
  });

  const getIconClass = () => {
    if (open) {
      return classes.ChevronIconVisible;
    }
    return classes.hideChevronIcon;
  };

  const renderLeftMenu = () => {
    return (
      <div className={classes.toolbar}>
        <HeaderLogo
          open={open}
          isTopNav={true}
          app={mainApp}
          largeLogo
          onClick={() => handleDrawer(!open)}
        />
        <IconButton
          onClick={() => handleDrawer(!open)}
          className={getIconClass()}
        >
          <ChevronLeftIcon />
        </IconButton>
      </div>
    );
  };

  const renderRightIcons = () => {
    return (
      <Grid className={classes.iconGrid}>{rightContent && rightContent()}</Grid>
    );
  };
  return (
    <MuiAppBar id="appbar" position="fixed" className={classes.appBar}>
      <MiBanner />
      <Toolbar className={classes.primaryToolBar}>
        <Grid className={classes.leftContainer}>
          <Grid
            className={cx(classes.menuLeft, {
              [classes.menuLeftClosed]: !open,
            })}
          >
            {renderLeftMenu()}
          </Grid>
          {settingApp?.isSettingNav && (
            <Grid className={`${classes.toolbar} ${classes.barWidth}`}>
              <HeaderLogo open={true} app={settingApp} />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8}>
            <SearchBar />
          </Grid>
          <Grid item xs={4}>
            <ToolIcons>
              {renderRightIcons()}
              <UserAvatar user={user} />
            </ToolIcons>
          </Grid>
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
};
