import React, { useState, useEffect } from "react";
import { walkService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { useComponentState } from "@miview/hooks";
import { MiModal, MiInputTextbox } from "@miview/components";
import { useTheme } from "@mui/material/styles";

export const CancelWorkModal = (props) => {
  const { open, onClose, walkId, isInspection } = props;

  const [cancelReason, setCancelReason] = useState("");

  const theme = useTheme();
  const stateManager = useComponentState();

  useEffect(() => {
    setCancelReason("");
  }, [open]);

  const cancelWalk = () => {
    stateManager.run(async () => {
      const response = await walkService.cancelStageWalk(
        walkId,
        cancelReason,
        ` ${isInspection ? "Inspection" : "Work"} cancelled successfully`
      );
      if (response.status === HTTP_STATUSES.OK) {
        onClose();
      }
    });
  };

  return (
    <MiModal
      title="Cancel"
      open={open}
      onClose={onClose}
      actions={[
        {
          onClick: onClose,
          color: theme.palette.primary.grey,
          name: "Cancel",
        },
        {
          onClick: cancelWalk,
          color: theme.palette.primary.main,
          name: "Submit",
          inverse: true,
          disabled: !cancelReason || stateManager.isBusy(),
        },
      ]}
    >
      <MiInputTextbox
        labelText="Cancel Reason"
        value={cancelReason}
        handleChange={(e) => setCancelReason(e.target.value)}
        required
      />
    </MiModal>
  );
};
