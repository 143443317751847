import { RoofLogo_MiPurchasing, RoofLogo, roofLogoAdmin } from "@miview/assets";
import { MiTHEME } from "@miview/theme";

export const APPLIST = [
  {
    logo: RoofLogo,
    name: "MiTrade",
    url: "/homes",
    color: MiTHEME.colors.blue.primary,
  },
  {
    logo: RoofLogo_MiPurchasing,
    name: "MiAccounting",
    url: "/accounting/accountpayable",
    color: MiTHEME.colors.teal.primary,
    permissionName: "CanViewAccounting",
  },
  {
    logo: RoofLogo_MiPurchasing,
    name: "MiPurchasing",
    url: "/purchasing/suppliers",
    color: MiTHEME.colors.teal.primary,
    permissionName: "CanAccessMiPurchasing",
  },
  {
    logo: roofLogoAdmin,
    name: "MiScheduling",
    url: "/scheduling/importedwork",
    color: MiTHEME.colors.purple.secondary,
    permissionName: "CanAccessMiScheduling",
  },
];
