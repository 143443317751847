import React, { useState } from "react";
import {
  MiButton,
  MiModal,
  MiTable,
  MiInputTextbox,
  MiInputSelectBox,
  MiBox,
  MiModalConfirm,
  MiPlaceHolderList,
  MiIcon,
} from "@miview/components";
import { useEdit, useComponentState } from "@miview/hooks";
import { Grid, alpha } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { mdiApps, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import { integrationService } from "@miview/api";
import { INTEGRATIONS, MAPPING_TYPES } from "@miview/constants";

const defaultValues = {
  id: 1,
  integrationId: "drhorton",
  internalId: "",
  internalName: "",
  externalId: "",
  externalName: "",
  mappingTypeId: 1,
  mappingTypeDisplayName: "Divisions",
};
const defaultErrorFields = {
  externalName: false,
  externalId: false,
  internalId: false,
};

const useStyles = makeStyles()((theme) => {
  return {
    addContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    addRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    spacingMedium: {
      padding: 25,
    },
    iconContainer: {
      padding: 10,
      width: 150,
      height: 150,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 100,
      backgroundColor: alpha(theme.palette.secondary.grey, 0.2),
      margin: 10,
    },
  };
});

export const Divisions = (props) => {
  const { data, accounts, fetchData, handleDeleteDivision } = props;
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(defaultValues);
  const [editing, setEditing] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [errorFields, setErrorFields] = useState({
    externalName: false,
    externalId: false,
    internalId: false,
  });

  const edit = useEdit(selected);
  const { classes } = useStyles();

  const stateManager = useComponentState();

  const isInputInvalid = () => {
    const errors = {
      externalName: edit.getValue("externalName") === "",
      externalId: edit.getValue("externalId") === "",
      internalId: edit.getValue("internalId") === "",
    };

    setErrorFields(errors);
    return errors.externalName || errors.externalId || errors.internalId;
  };

  const handleAddDivision = () => {
    setSelected(defaultValues);
    setShowModal(true);
    setEditing(false);
  };

  const handleCloseModal = () => {
    edit.reset();
    setErrorFields(defaultErrorFields);
    setShowModal(false);
  };

  const handleSaveClick = () => {
    if (isInputInvalid()) {
      return;
    }

    const body = {
      ...edit.edits,
    };

    setShowModal(false);
    if (editing) {
      updateMapping(INTEGRATIONS.DRHORTON, selected.id, body);
    } else {
      createMapping(body);
    }
    setEditing(false);
    edit.reset();
    setErrorFields(defaultErrorFields);
  };

  const placeHolderActions = [
    {
      icon: "fa-plus",
      onClick: handleAddDivision,
      color: theme.palette.primary.main,
      title: "Add Division",
      inverse: true,
    },
  ];

  const isSaveDisabled = () => {
    return !edit.allFilled("externalName", "externalId", "internalId");
  };

  const actions = [
    {
      onClick: handleCloseModal,
      color: theme.palette.primary.grey,
      name: "Cancel",
    },
    {
      onClick: handleSaveClick,
      color: theme.palette.primary.main,
      name: "Save",
      inverse: true,
      disabled: isSaveDisabled(),
    },
  ];

  const columns = [
    {
      field: "externalName",
      headerName: "Division Name",
      disableColumnMenu: true,
      flex: 1,
    },
    { field: "externalId", headerName: "Division Code", width: "150", flex: 1 },
    {
      field: "internalName",
      headerName: "Mapped Account",
      width: "150",
      flex: 1,
    },
  ];

  const handleSelectedRow = (row) => {
    setSelected(row);
    setEditing(true);
    setShowModal(true);
  };

  const addInternalName = (requestBody) => {
    const selectedAccount = accounts.find(
      (account) => account.id === requestBody.internalId
    );
    if (!selectedAccount) {
      return { ...requestBody, mappingTypeId: MAPPING_TYPES.DIVISIONS };
    }

    const internalName = selectedAccount.name;
    return {
      ...requestBody,
      mappingTypeId: MAPPING_TYPES.DIVISIONS,
      internalName,
    };
  };

  const createMapping = async (body) => {
    const response = await integrationService.integrations.createMapping(
      INTEGRATIONS.DRHORTON,
      addInternalName(body)
    );
    if (response.status === 200) {
      setEditing(false);
      fetchData();
    }
  };

  const updateMapping = async (integrationId, id, body) => {
    const response = await integrationService.integrations.updateMapping(
      integrationId,
      id,
      addInternalName(body)
    );
    if (response.status === 200) {
      setEditing(false);
      fetchData();
    }
  };

  const deleteMapping = async (integrationId, id) => {
    await integrationService.integrations.deleteMapping(integrationId, id);
    setSelected(null);
    handleDeleteDivision(id);
  };

  const handleShowConfirm = (row) => {
    setSelected(row);
    setShowConfirm(true);
  };

  const handleCancelDelete = () => {
    setSelected(null);
    setShowConfirm(false);
  };

  const handleDelete = () => {
    deleteMapping(INTEGRATIONS.DRHORTON, selected.id);
    setShowConfirm(false);
  };

  const getRowActions = (row) => {
    const DeleteMenuItem = {
      text: "Delete",
      onClick: () => handleShowConfirm(row),
      renderIcon: () => <Delete fontSize={"small"} />,
    };

    const EditMenuItem = {
      text: "Edit",
      onClick: () => handleSelectedRow(row),
      renderIcon: () => <Edit fontSize={"small"} />,
    };

    return [EditMenuItem, DeleteMenuItem];
  };

  const renderTable = () => {
    return (
      <Grid>
        <MiTable
          columns={columns}
          rows={data}
          disableColumnMenu={true}
          getRowActions={getRowActions}
        />
      </Grid>
    );
  };

  const renderPlaceholderIcon = (icon) => {
    return (
      <div className={classes.iconContainer}>
        <MiIcon path={icon} size={3} color={theme.palette.secondary.grey} />
      </div>
    );
  };

  const renderContent = () => {
    if (data?.length) {
      return (
        <Grid>
          <div className={classes.addRight}>
            <MiButton
              icon={mdiPlus}
              title="Add Division"
              style={{ minWidth: 90 }}
              inverse={true}
              onClick={handleAddDivision}
              color={theme.palette.primary.main}
            />
          </div>
          {renderTable()}
        </Grid>
      );
    }
    return (
      <Grid container className={classes.spacingMedium}>
        <MiPlaceHolderList
          renderIcon={renderPlaceholderIcon(mdiApps)}
          text="This page doesn't have any divisions yet."
          helperText="Tip: Get started by clicking the 'Add Division' button."
          actions={placeHolderActions}
        />
      </Grid>
    );
  };

  const handleOnChange = (e) => {
    edit.update({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <MiBox>{renderContent()}</MiBox>
      <MiModal
        title={editing ? "Edit Division" : "Add Division"}
        actions={actions}
        open={showModal}
        onClose={handleCloseModal}
        maxWidth="lg"
      >
        {stateManager.statusTag("addDivision")}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MiInputTextbox
            name="externalName"
            labelText="Division Name"
            labelTextColor={theme.palette.primary.main}
            value={edit.getValue("externalName")}
            handleChange={handleOnChange}
            error={errorFields.externalName}
            styles={{ marginRight: 10 }}
            required
          />
          <MiInputTextbox
            name="externalId"
            labelText="Division Code"
            labelTextColor={theme.palette.primary.main}
            value={edit.getValue("externalId")}
            handleChange={handleOnChange}
            error={errorFields.externalId}
            styles={{ marginRight: 10 }}
            required
          />
          <MiInputSelectBox
            name="internalId"
            labelText="Map to Account Name"
            labelTextColor={theme.palette.primary.main}
            value={edit.getValue("internalId")}
            handleChange={handleOnChange}
            menuItems={accounts}
            error={errorFields.internalId}
            required
          />
        </div>
      </MiModal>
      <MiModalConfirm
        open={showConfirm}
        title={"Confirm Delete"}
        handleNegative={handleCancelDelete}
        handlePositive={handleDelete}
        description={"Are you sure you want to delete this division?"}
        color={theme.palette.primary.red}
        icon={mdiTrashCanOutline}
      />
    </div>
  );
};
