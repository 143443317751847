import { apiOptions, apiRequest } from "../../utils";
import { API_HEADER, ITEM_MASTER_BASE_URL } from "../../constants";

const baseUrl = "/api/v1";

export const itemMaster = {
  getAll: async (queryParams, filterParams) => {
    const options = await apiOptions({
      url: `${ITEM_MASTER_BASE_URL}${baseUrl}/itemmaster/items`,
      method: "get",
      headers: API_HEADER,
      params: queryParams,
      filters: filterParams,
    });
    return apiRequest(options);
  },

  get: async (itemId) => {
    const options = await apiOptions({
      url: `${ITEM_MASTER_BASE_URL}${baseUrl}/itemmaster/items/${itemId}`,
      method: "get",
      headers: API_HEADER,
    });
    return apiRequest(options);
  },

  getBrands: async () => {
    const options = await apiOptions({
      url: `${ITEM_MASTER_BASE_URL}${baseUrl}/itemmaster/brands`,
      method: "get",
      headers: API_HEADER,
    });
    return apiRequest(options);
  },

  getCategories: async (queryParams) => {
    const options = await apiOptions({
      url: `${ITEM_MASTER_BASE_URL}${baseUrl}/itemmaster/categories`,
      method: "get",
      headers: API_HEADER,
      params: queryParams,
    });
    return apiRequest(options);
  },
};
