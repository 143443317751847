import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_ACCOUNTING_TRANSACTION = `${API_V3}/accountingtransactions`;

export const accountingTransactionService = {
  getAll: async (params, filters, sort) => {
    const options = await apiOptions({
      url: API_ACCOUNTING_TRANSACTION,
      params,
      filters,
      sort,
    });
    return apiRequest(options);
  },
  get: async (id) => {
    const options = await apiOptions({
      url: `${API_ACCOUNTING_TRANSACTION}/${id}`,
    });
    return apiRequest(options);
  },
  update: async ({ id, request }) => {
    const options = await apiOptions({
      url: `${API_ACCOUNTING_TRANSACTION}/${id}`,
      method: "patch",
      data: request,
    });
    return apiRequest(options);
  },
  sum: async ({ params, filters }) => {
    const options = await apiOptions({
      url: `${API_ACCOUNTING_TRANSACTION}/sum`,
      params,
      filters,
    });
    return apiRequest(options);
  },
  exportQuickBooksIif: async (accountingTransactionIds) => {
    const options = await apiOptions({
      url: `${API_ACCOUNTING_TRANSACTION}/export/qb`,
      data: accountingTransactionIds,
      method: "post",
      responseType: "blob",
    });
    return apiRequest(options);
  },
  exportQuickBooksBonusIif: async (payPeriodId) => {
    const options = await apiOptions({
      url: `${API_ACCOUNTING_TRANSACTION}/export/qb/${payPeriodId}`,
      method: "post",
      responseType: "blob",
    });
    return apiRequest(options);
  },
  exportQuickBooksPayIif: async (payPeriodId) => {
    const options = await apiOptions({
      url: `${API_ACCOUNTING_TRANSACTION}/export/qb/pay/${payPeriodId}`,
      method: "post",
      responseType: "blob",
    });
    return apiRequest(options);
  },
};
