export const AND_SEPARATOR = ";";
export const OR_SEPARATOR = ",";
export const ASSIGNMENT_SEPARATOR = "=";

export const AND_FILTER = "and";
export const OR_FILTER = "or";

export const EQUALS_OPERATOR = "equals";
export const IS_OPERATOR = "is";
export const IS_ANY_OF = "isAnyOf";
export const RANGE_OPERATOR = "range";
export const STARTS_WITH_OPERATOR = "startsWith";
export const ENDS_WITH_OPERATOR = "endsWith";
export const CONTAINS_OPERATOR = "contains";
export const ON_OR_AFTER_OPERATOR = "onOrAfter";
export const ON_OR_BEFORE_OPERATOR = "onOrBefore";
export const IS_EMPTY_OPERATOR = "isEmpty";
export const ON_OPERATOR = "on";

export const OPERATOR_LIST = [
  EQUALS_OPERATOR,
  IS_OPERATOR,
  IS_ANY_OF,
  RANGE_OPERATOR,
  STARTS_WITH_OPERATOR,
  ENDS_WITH_OPERATOR,
  CONTAINS_OPERATOR,
  ON_OR_AFTER_OPERATOR,
  ON_OR_BEFORE_OPERATOR,
  IS_EMPTY_OPERATOR,
  ON_OPERATOR,
];
