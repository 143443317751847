import React from "react";
import PropTypes from "prop-types";
import { TextField, InputLabel } from "@mui/material";
import { VALID_FILE_TYPES_FOR_IMAGES_UPLOAD } from "@miview/constants";
import { getImageUrl } from "@miview/utils";
import { ImageContainer } from "../MiImageClipboard/MiImageClipboard";
import { clipboardImageStyles } from "../MiImageClipboard/MiImageClipboardStyles";
import { MiImagePreview } from "../MiImagePreview/MiImagePreview";
import { useCdn } from "@miview/hooks";

export const MiImageFilePicker = ({
  labelText,
  value,
  handleChange,
  variant = "standard",
  inputProps = { accept: VALID_FILE_TYPES_FOR_IMAGES_UPLOAD },
  style,
  readOnly,
  clipBoardItem,
  idx,
  showInput,
  onClick,
  fileType,
  ...rest
}) => {
  const { classes } = clipboardImageStyles();
  const cdn = useCdn();

  return clipBoardItem ? (
    <ImageContainer
      idx={idx}
      classes={classes}
      handleUpdateImage={handleChange}
      imageSrc={value}
    />
  ) : (
    <div data-testid="MiImageFilePicker">
      {labelText && (
        <InputLabel style={{ marginBottom: -5 }} id="image-label" shrink>
          {labelText + " Image"}
        </InputLabel>
      )}
      {value && VALID_FILE_TYPES_FOR_IMAGES_UPLOAD.includes(fileType) && (
        <MiImagePreview src={getImageUrl(value, cdn)} onClick={onClick} />
      )}
      {(!readOnly || !value || showInput) && (
        <TextField
          {...rest}
          labelId="image-label"
          style={{ width: "100%" }}
          variant={variant}
          onChange={handleChange}
          type="file"
          InputLabelProps={{ shrink: true }}
          inputProps={inputProps}
        />
      )}
    </div>
  );
};

MiImageFilePicker.propTypes = {
  labelText: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  variant: PropTypes.string,
  style: PropTypes.object,
  readOnly: PropTypes.bool,
};
