import React from "react";
import PropTypes from "prop-types";
import { MiIcon, MiChip, MiInputSelectBox } from "../index.ts";
import { makeStyles } from "tss-react/mui";
import { mdiClose } from "@mdi/js";

export const MiInputMultiSelectBox = ({
  multiple,
  value,
  readOnly,
  menuItems = [],
  handleChange,
  ...rest
}) => {
  const useStyles = makeStyles()((theme) => ({
    chiplabel: {
      borderRadius: 3,
      height: 20,
      fontWeight: theme.fontWeight.medium,
      fontSize: theme.fontSize.xsmall,
      color: theme.palette.secondary.bluegrey,
      backgroundColor: theme.palette.primary.light,
    },
    icon: {
      color: theme.palette.primary.grey,
      cursor: "pointer",
    },
  }));

  const { classes } = useStyles();

  const handleClearClick = () => {
    handleChange({ target: { value: [] } });
  };

  const getSelectedLabels = (selectedValues) => {
    return menuItems.filter((item) => selectedValues.includes(item.value));
  };

  const getSelectedChips = (selectedValues) => {
    const arr = getSelectedLabels(selectedValues);
    return (
      <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
        {arr.map((option) => {
          return (
            <div key={option.id || option.value}>
              <MiChip label={option.label} className={classes.chiplabel} />
              {!!option.secondaryInfo && (
                <div>
                  <i>Pay Date: </i>
                  {option.secondaryInfo}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <MiInputSelectBox
        {...rest}
        menuItems={menuItems}
        renderValue={getSelectedChips}
        multiple={multiple}
        value={value}
        handleChange={handleChange}
        sx={{
          "& .MuiSelect-iconStandard": { display: value.length ? "none" : "" },
        }}
        endAdornment={
          value.length && !readOnly ? (
            <MiIcon
              onClick={handleClearClick}
              path={mdiClose}
              className={classes.icon}
            />
          ) : null
        }
      />
    </div>
  );
};

MiInputMultiSelectBox.propTypes = {
  multiple: PropTypes.bool,
  value: PropTypes.array,
};

MiInputMultiSelectBox.defaultProps = {
  value: [],
  multiple: true,
};
