import { appStorage } from "@miview/utils";

export const generateRoutesByPermissions = (routeList) => {
  const permissions = appStorage.getItem("permissions");
  if (!permissions) {
    return routeList;
  }
  return routeList.filter(
    (e) => !e.permissionName || permissions.includes(e.permissionName)
  );
};
