import React, { useState } from "react";
import {
  MiTab,
  ImageViewer,
  MiSectionHeader,
  MiBox,
  MiDetailFields,
  MiButton,
} from "@miview/components";
import { makeStyles } from "tss-react/mui";
import { mdiPlus } from "@mdi/js";
import { useTheme, alpha, Divider } from "@mui/material";
import IssueCard from "./IssueCard";
import DeleteRedTagModal from "./DeleteRedTagModal";
import IssueCardContainer from "./IssueCardContainer";
import { groupBy, sortData } from "@miview/utils";
import { TRADE_TYPES, ISSUE_CATEGORIES } from "@miview/constants";

const useStyles = makeStyles()(() => ({
  redTagField: { marginTop: "10px", display: "flex", flexWrap: "wrap" },
  tabHeader: {
    marginTop: "-10px",
  },
  sectionHeader: {
    paddingTop: "10px",
  },
  card: { marginBottom: 10 },
  fieldContainer: { flex: 1 },
  addIssueButton: { marginTop: "13px", alignSelf: "center" },
}));

export const getTabs = (isInspection) => {
  return [
    {
      value: global.ACTIVE_TAB.PUNCH_ITEMS,
      label: isInspection ? "Red Tag Issues" : "Issues",
    },
  ];
};

export const WorkDetailSidebar = (props) => {
  const {
    trades,
    issues,
    assignees,
    plumbingId,
    isRedTagMode,
    isInspection,
    redTagEdit,
    handleHide,
    isPlumbing,
    selectedEditItem,
    handleUpdateIssues,
    handleDeleteIssues,
    editIssues,
    handleSaveSelectedItem,
    handleCancelSelectedItem,
  } = props;

  const [openImageUrls, setOpenImageUrls] = useState([]);
  const [isDeleteRedTagModalOpen, setIsDeleteRedTagModalOpen] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState({});
  const theme = useTheme();
  const { classes } = useStyles();

  const colorDict = {
    Cathedral: {
      primary: theme.palette.primary.purple,
      secondary: alpha(theme.palette.primary.purple, 0.2),
    },
    Others: {
      primary: theme.palette.medium.blue,
      secondary: alpha(theme.palette.medium.blue, 0.2),
    },
  };

  const RED_TAG_GROUPING_HEADERS = {
    OTHERS: "Others",
    CATHEDRAL: "Cathedral",
  };

  const groupAndMap = (array) => {
    let newArr;
    let sections;
    if (isInspection) {
      newArr = groupBy(array, (item) =>
        item["fixTradeName"] !== TRADE_TYPES.PLUMBING
          ? RED_TAG_GROUPING_HEADERS.OTHERS
          : RED_TAG_GROUPING_HEADERS.CATHEDRAL
      );
      sections = Object.entries(newArr).map(([title, data]) => ({
        title,
        data: groupBy(data, (item) =>
          title !== "Others" ? item["assigneeTitleName"] : item["fixTradeName"]
        ),
      }));
    } else {
      newArr = groupBy(array, "issueCategoryName");
      newArr = groupBy(array, (item) =>
        item["issueCategoryName"] !== RED_TAG_GROUPING_HEADERS.CATHEDRAL
          ? RED_TAG_GROUPING_HEADERS.OTHERS
          : RED_TAG_GROUPING_HEADERS.CATHEDRAL
      );
      sections = Object.entries(newArr).map(([title, data]) => ({
        title,
        data: groupBy(data, "assigneeTitleName"),
      }));
    }

    return sortData(sections, "title", "asc");
  };

  const setMaxInputLength = (value) => {
    return value.toString().length <= 250;
  };

  const renderIssues = () => {
    return issues.length ? (
      groupAndMap(issues).map((item, index) => (
        <IssueCardContainer
          key={"key_" + index}
          title={
            item.title +
            ` (${Object.values(item.data).reduce(
              (acc, curr) => acc + curr.length,
              0
            )})`
          }
          titleFontSize={theme.fontSize.medium}
          titleColor={colorDict[item.title].primary}
          boxShadow={`-2px 0px 0px 0px ${
            colorDict[item.title].primary
          },0px 0px 0px 1px ${colorDict[item.title].secondary} `}
        >
          {Object.keys(item.data).map((elem, idx) => (
            <IssueCardContainer
              key={idx + elem}
              title={elem + ` (${item.data[elem].length})`}
              boxShadow={"0px"}
              borderRadius={6}
              titleColor={theme.palette.primary.grey}
            >
              {item.data[elem].map((issue, idx) => {
                return (
                  <div key={idx} className={classes.card}>
                    {idx % 2 !== 0 && <Divider />}
                    {selectedEditItem?.id === issue.id ? (
                      <div style={{ marginTop: "5px" }}>
                        <MiDetailFields
                          detailFields={detailFields}
                          customWrapperStyle={{ width: "100%" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MiButton
                            title="Cancel"
                            color={theme.palette.primary.grey}
                            onClick={handleCancelSelectedItem}
                          />
                          <MiButton
                            title="Save"
                            inverse
                            color={theme.palette.primary.blue}
                            onClick={handleSaveSelectedItem}
                          />
                        </div>
                      </div>
                    ) : (
                      <IssueCard
                        item={issue}
                        isInspection={isInspection}
                        deleteIssues={deleteIssues}
                        handleOpenImageUrls={handleOpenImageUrls}
                        handleDeleteIssues={handleDeleteIssues}
                        editIssues={editIssues}
                      />
                    )}
                  </div>
                );
              })}
            </IssueCardContainer>
          ))}
        </IssueCardContainer>
      ))
    ) : (
      <div className={classes.sectionHeader}>
        <MiSectionHeader title="There are no Issues" />
      </div>
    );
  };

  const detailFields = [
    {
      label: "Description",
      value: redTagEdit.getValue("failReason"),
      setValue: (e) => redTagEdit.update({ failReason: e }),
      required: true,
      width: "35%",
      marginRight: 5,
      minWidth: 250,
      verificationFunction: setMaxInputLength,
    },
    {
      label: "Trade",
      fieldType: "select",
      value: redTagEdit.getValue("fixTradeId"),
      options: trades,
      required: true,
      setValue: (e) => {
        if (e === plumbingId) {
          redTagEdit.update({
            fixTradeId: plumbingId,
            assigneeId: assignees?.length == 1 ? assignees[0].userId : "",
          });
        } else {
          redTagEdit.reset();
          redTagEdit.update({ fixTradeId: e });
        }
        handleHide(e, trades);
      },
      marginRight: 5,
      width: "11%",
      minWidth: 85,
    },
    {
      label: "Fault",
      fieldType: "select",
      value: redTagEdit.getValue("issueCategoryId"),
      options: ISSUE_CATEGORIES,
      setValue: (e) => redTagEdit.update({ issueCategoryId: e }),
      width: "14%",
      required: isPlumbing,
      readOnly: !isPlumbing,
      marginRight: 5,
      minWidth: 100,
    },
    {
      label: "Assignee",
      fieldType: "select",
      value: redTagEdit.getValue("assigneeId"),
      options: assignees,
      setValue: (e) => redTagEdit.update({ assigneeId: e }),
      width: "28%",
      required: isPlumbing,
      readOnly: !isPlumbing,
      marginRight: 5,
      minWidth: 50,
    },
    {
      fieldType: "image",
      value: redTagEdit.getValue("image"),
      setValue: (e) => redTagEdit.update({ image: e }),
      width: "5%",
      minWidth: 40,
      clipBoardItem: true,
    },
  ];

  const handleOpenImageUrls = (item) => {
    setOpenImageUrls([item]);
  };

  const deleteIssues = (issue) => {
    setIsDeleteRedTagModalOpen(true);
    setSelectedIssue(issue);
  };

  const closeDeleteRedTagModal = () => {
    setIsDeleteRedTagModalOpen(false);
    setSelectedIssue({});
  };

  const handleDelete = () => {
    handleDeleteIssues(selectedIssue);
    setIsDeleteRedTagModalOpen(false);
  };

  return (
    <>
      <ImageViewer
        images={openImageUrls}
        selectedImage={openImageUrls[0]}
        onUnfullscreen={() => setOpenImageUrls([])}
        fullScreenStatus={openImageUrls.length > 0}
      />
      <DeleteRedTagModal
        open={isDeleteRedTagModalOpen}
        onClose={closeDeleteRedTagModal}
        handleDelete={handleDelete}
      />
      <MiBox styles={{ width: "69%" }}>
        <div className={classes.tabHeader}>
          <MiTab tabs={getTabs(isInspection)} />
        </div>
        <div className={classes.fieldContainer}>
          {isRedTagMode && Object.keys(selectedEditItem).length === 0 ? (
            <div className={classes.redTagField}>
              <MiDetailFields
                detailFields={detailFields}
                customWrapperStyle={{ width: "90%" }}
              />
              <div
                style={{
                  width: "5%",
                  minWidth: 80,
                  display: "flex",
                }}
              >
                <MiButton
                  title="Issue"
                  color={theme.palette.primary.blue}
                  className={classes.addIssueButton}
                  onClick={handleUpdateIssues}
                  icon={mdiPlus}
                  disabled={
                    isPlumbing
                      ? !redTagEdit.allFilled(
                          "failReason",
                          "fixTradeId",
                          "issueCategoryId",
                          "assigneeId"
                        )
                      : !redTagEdit.allFilled("failReason", "fixTradeId")
                  }
                  inverse
                />
              </div>
            </div>
          ) : null}
          {renderIssues()}
        </div>
      </MiBox>
    </>
  );
};
