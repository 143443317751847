import React, { memo, Component, Fragment, useState } from "react";
import PropTypes from "prop-types";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Tooltip from "@mui/material/Tooltip";
import { THEME } from "./theme";
import { useAreaItemFormStyles } from "./MiAreaItemFormStyles";
import { PaddedDiv } from "./StyledComponents/PaddedDiv";
import { PlanItemImageLabel } from "./StyledComponents/PlanItemImageLabel";
import { PlanItemImageFocus } from "./StyledComponents/PlanItemImageFocus";
import { getImageUrl, getImageBase64FromFile } from "@miview/utils";
import { MiButton, MiDetailFields, MiInputTextbox } from "@miview/components";
import { useCdn } from "@miview/hooks";

const boxColor = (edit) => {
  if (edit.getValue("isDeleted")) {
    return THEME.RED_PRIMARY;
  }

  if (edit.getValue("isNew")) {
    return THEME.ORANGE_SECONDARY;
  }

  if (edit.getValue("isChanged")) {
    return THEME.PURPLE_LIGHT;
  }

  return "gray";
};

export class ImageContainer extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.nv.addEventListener("paste", this.onPasteImageHandler, true);
  }

  componentWillUnmount() {
    this.nv.removeEventListener("paste", this.onPasteImageHandler, true);
  }

  onPasteImageHandler = (pasteEvent) => {
    if (this.props.quickMode) {
      return;
    }
    const items = pasteEvent.clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") == -1) {
        continue;
      }
      const blob = items[i].getAsFile();

      getImageBase64FromFile(blob, (e) =>
        this.props.handleUpdateImage(e, this.props.position)
      );
    }
  };

  render() {
    const { imageSrc, itemId, handleUpdateImage, position, classes, cdn } =
      this.props;
    const handleCreateDrawing = (e) => {
      getImageBase64FromFile(e.target.files[0], (f) =>
        handleUpdateImage(f, position)
      );
    };

    const renderImage = () => {
      if (imageSrc) {
        return <img id="image-container" src={getImageUrl(imageSrc, cdn)} />;
      } else {
        return (
          <div
            id="image-container"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 18,
            }}
          >
            <CameraAltIcon className={classes.areaImageIcon} />
          </div>
        );
      }
    };

    return (
      <PlanItemImageFocus
        tabIndex="0"
        forwardedRef={(elem) => (this.nv = elem)}
      >
        <div>
          <PlanItemImageLabel>{position}</PlanItemImageLabel>
          <input
            className={classes.createDrawingInput}
            type="file"
            id={`drawing-${itemId || 0}-${position}`}
            name={`drawing-${itemId || 0}-${position}`}
            accept="image/png, image/jpeg"
            onChange={handleCreateDrawing}
          ></input>
          <label
            htmlFor={`drawing-${itemId || 0}-${position}`}
            className={classes.createDrawingLabel}
          >
            <Tooltip
              title="Click to upload or paste an image from clipboard"
              aria-label="Add Image"
            >
              <div className={classes.areaItemFormImage}>{renderImage()}</div>
            </Tooltip>
          </label>
        </div>
      </PlanItemImageFocus>
    );
  }
}

ImageContainer.propTypes = {
  handleUpdateImage: PropTypes.func,
  position: PropTypes.string,
};

const MiAreaItemForm = ({
  handleUpdate,
  handleCancel,
  setMaterialsModalOpen,
  walkTypes,
  quickMode,
  edit,
  propertyPlanAreaId,
  isCanNaCheckboxVisible,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { classes } = useAreaItemFormStyles();
  const cdn = useCdn();

  const handleRemoveImage = (position) => {
    edit.update({
      [`${position}Image`]: null,
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    await handleUpdate({ ...edit.edits, propertyPlanAreaId });
    edit.reset();
    setIsSaving(false);
  };

  const handleUpdateImage = (imageData, position) => {
    edit.update({
      [`${position}Image`]: imageData,
    });
  };

  const removeMaterial = () => {
    edit.update({
      materialId: null,
      materialItemNumber: undefined,
      materialName: undefined,
      materialImageName: undefined,
      materialCategoryId: null,
      brandName: null,
    });
  };

  const saveIsDisabled = () => {
    return edit.allFilled("itemName", "walkTypeId") || isSaving;
  };

  const detailFields = [
    {
      label: "Name",
      value: edit.getValue("itemName"),
      setValue: (e) => edit.update({ itemName: e }),
      width: "100%",
      required: true,
    },
    {
      label: "Description",
      value: edit.getValue("description"),
      setValue: (e) => edit.update({ description: e }),
      width: "100%",
    },
    {
      label: "Work",
      fieldType: "select",
      value: edit.getValue("walkTypeId"),
      options: walkTypes.map((t) => {
        return { value: t.walkTypeId, text: t.walkTypeName };
      }),
      required: true,
      setValue: (e) => edit.update({ walkTypeId: e }),
      width: "100%",
    },
    {
      label: "Quantity",
      fieldType: "number",
      width: "33%",
      value: edit.getValue("quantity"),
      minWidth: 100,
      setValue: (e) => edit.update({ quantity: e }),
    },
    {
      label: `Can Be Not Applicable`,
      fieldType: "checkbox",
      value: edit.getValue("canBeNotApplicable"),
      setValue: (e) => edit.update({ canBeNotApplicable: e }),
      width: "33%",
      minWidth: 100,
      hide: !isCanNaCheckboxVisible,
    },
  ];

  const renderRemoveImageLink = (position) => {
    const imageSrc =
      edit.getValue(`${position}Image`) ||
      edit.getValue(`${position}ImageName`);
    if (imageSrc) {
      return (
        <div
          style={{
            color: THEME.RED_PRIMARY,
          }}
          className={classes.areaItemFormRemoveImage}
          onClick={() => handleRemoveImage(position)}
        >
          Remove
        </div>
      );
    }

    return null;
  };

  const renderMaterialTools = () => {
    if (edit.getValue("materialId")) {
      return (
        <div
          className={`${classes.areaItemFormDescription} ${classes.areaItemFormMaterial}`}
        >
          <div
            style={{ marginTop: 6, width: "100%" }}
            onClick={() => setMaterialsModalOpen(true)}
          >
            <MiInputTextbox
              labelText="Material"
              value={`${edit.getValue("materialName")} - ${edit.getValue(
                "materialItemNumber"
              )}`}
              readOnly={true}
              handleChange={() => null}
            />
          </div>
          <RemoveCircleIcon
            style={{
              color: THEME.RED_PRIMARY,
              cursor: "pointer",
              marginTop: 31,
              marginLeft: 10,
            }}
            onClick={removeMaterial}
          />
        </div>
      );
    }

    return (
      <div>
        <span
          onClick={() => setMaterialsModalOpen(true)}
          className={classes.areaItemFormAddMaterialButton}
          style={{ textDecoration: "underline" }}
        >
          + Material
        </span>
      </div>
    );
  };

  return (
    <PaddedDiv>
      <div
        className={classes.areaItemFormBox}
        style={{
          borderLeft: `12px solid ${boxColor(edit)}`,
          padding: "8px 8px 8px 12px",
        }}
      >
        <div style={{ display: "flex" }}>
          <div className={classes.areaItemFormDetail}>
            <MiDetailFields detailFields={detailFields} />
            {renderMaterialTools()}
          </div>
          {edit.getValue("materialId") ? (
            <div className={classes.areaItemFormImage} style={{ margin: 20 }}>
              <img
                src={getImageUrl(edit.getValue("materialImageName"), cdn)}
                onClick={() => setMaterialsModalOpen(true)}
              />
            </div>
          ) : (
            <Fragment>
              <div>
                <ImageContainer
                  position="top"
                  imageSrc={
                    edit.getValue("topImage") || edit.getValue("topImageName")
                  }
                  itemId={edit.getValue("itemId")}
                  handleUpdateImage={(e) => handleUpdateImage(e, "top")}
                  classes={classes}
                  quickMode={quickMode}
                  cdn={cdn}
                />
                {renderRemoveImageLink("top")}
              </div>
              <div>
                <ImageContainer
                  position="front"
                  imageSrc={
                    edit.getValue("frontImage") ||
                    edit.getValue("frontImageName")
                  }
                  itemId={edit.getValue("itemId")}
                  handleUpdateImage={(e) => handleUpdateImage(e, "front")}
                  classes={classes}
                  quickMode={quickMode}
                  cdn={cdn}
                />
                {renderRemoveImageLink("front")}
              </div>
            </Fragment>
          )}
        </div>
        <div className={classes.areaItemFormFormButtons}>
          <MiButton onClick={handleCancel} color="gray" title="Cancel" />
          <div style={{ flex: 1 }} />
          <MiButton
            onClick={handleSave}
            color="white"
            style={{
              backgroundColor: THEME.BLUE_PRIMARY,
              width: 100,
              borderColor: THEME.BLUE_PRIMARY,
            }}
            title="Save"
            disabled={!saveIsDisabled()}
          />
        </div>
      </div>
    </PaddedDiv>
  );
};

MiAreaItemForm.propTypes = {
  editId: PropTypes.number,
  setEditId: PropTypes.func,
  formData: PropTypes.object,
  updateForm: PropTypes.func,
  handleUpdate: PropTypes.func,
  setMaterialsModalOpen: PropTypes.func,
  disableMaterials: PropTypes.bool,
  walkTypes: PropTypes.array,
  resetForm: PropTypes.func,
};

export default memo(MiAreaItemForm);
