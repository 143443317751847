export const getCurrencyString = (amount) => {
  const val = Math.abs(amount);
  let negative = "";
  if (amount < 0) {
    negative = "-";
  }
  return `${negative}${val.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "USD",
    style: "currency",
  })}`;
};
