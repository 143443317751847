/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { makeStyles } from 'tss-react/mui';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { MoreVert } from "@mui/icons-material";
import moment from "moment";
import FolderCollapse from "./Drawer/FolderCollapse";
import { getUniqueItems } from "../../../utils";

const useStyles = makeStyles()((theme) => ({
  mainTable: {
    "& td, & th": {
      padding: "5px 10px",
      color: theme.palette.primary.bluegrey,
    },
    "& thead th": {
      padding: "15px 10px",
      fontWeight: theme.fontWeight.medium,
    },
    "& thead .padding-left": {
      paddingLeft: 66,
    },
    "& .icon": {
      color: theme.palette.medium.grey,
    },
    "& .cursor": {
      cursor: "pointer",
    },
    "& .selected": {
      background: theme.palette.primary.teal,
    },
    "& .selected td": {
      color: theme.palette.primary.white,
    },
    "& .selected td button": {
      color: theme.palette.primary.white,
    },
    "& .space": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: 10,
      },
    },
  },
}));

const NewHomesTemplatesList = (props) => {
  const { itemTemplates, getTemplate } = props;
  const { classes } = useStyles();
  const [activeRow, setActiveRow] = useState([]);
  const [selectedtemplate, setSelectedTemplate] = useState([]);

  const handleCollapseContent = (rowID, openState) => {
    if (!openState) {
      setActiveRow(getUniqueItems([...activeRow].concat(rowID)));
    } else {
      const temp = activeRow?.filter((item) => item !== rowID);
      setActiveRow(temp);
    }
  };
  const onTemplateSelect = (row) => {
    setSelectedTemplate([row?.id]);
    getTemplate(row);
  };

  const ChildRows = ({ row }) => {
    return (
      <TableRow
        key={row.name}
        className={selectedtemplate?.includes(row?.id) ? "cursor selected" : ""}
        onClick={() => onTemplateSelect(row)}
      >
        <TableCell scope="row">
          <FolderCollapse fileMode={true} value={row?.name} ID={row?.id} />
        </TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{moment(row.issueDate)?.format("MM/DD/YYYY")}</TableCell>
        <TableCell>{row.requestedBy}</TableCell>
        <TableCell align="right" className="icon">
          <MoreVert />
        </TableCell>
      </TableRow>
    );
  };

  const Rows = ({ row }) => {
    return (
      <>
        <TableRow key={row.name}>
          <TableCell scope="row">
            <FolderCollapse
              fileMode={row.file ? true : false}
              parent={row.children.length > 0 ? true : false}
              value={row.name}
              handleCollapseButton={handleCollapseContent}
              handleCollapseArrow={activeRow?.includes(row?.id)}
              ID={row?.id}
            />
          </TableCell>
          <TableCell>{row.description}</TableCell>
          <TableCell>{row.modified}</TableCell>
          <TableCell>{row.modifiedBy}</TableCell>
          <TableCell align="right" className="icon">
            <MoreVert />
          </TableCell>
        </TableRow>
        {row?.children?.length > 0 &&
          activeRow?.includes(row?.id) &&
          row?.children?.map((item, index) => (
            <ChildRows key={index} row={item} />
          ))}
      </>
    );
  };

  const renderTableCustom = () => {
    return (
      <TableContainer className={classes.mainTable}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="padding-left">
                <div className="space">
                  <FolderOutlinedIcon /> Name{" "}
                </div>
              </TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Modified</TableCell>
              <TableCell>Modified By</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemTemplates.map((row) => (
              <Rows key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  return <>{renderTableCustom()}</>;
};

export default NewHomesTemplatesList;
