import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Session } from "@miview/components";
import { ACCOUNTING_NAV } from "./routes/accounting";
import { MiTHEME } from "@miview/theme";
import { RoofLogo_MiPurchasing } from "@miview/assets";
import { APPLIST } from "@miview/constants";
import DefaultHeader from "./containers/DefaultLayout/DefaultHeader";
import DefaultFooter from "./containers/DefaultLayout/DefaultFooter";

const createMui5Theme = createTheme({
  ...MiTHEME,
  palette: {
    ...MiTHEME.palette,
    primary: {
      ...MiTHEME.palette.primary,
      main: MiTHEME.colors.teal.primary,
      secondary: MiTHEME.colors.teal.secondary,
      accent: MiTHEME.colors.grey.primary,
      light: MiTHEME.colors.teal.light,
    },
  },
  spacing: (factor) => `${0.25 * factor}`,
  colors: undefined,
});

export const AppProvider = ({ children }) => {
  return <ThemeProvider theme={createMui5Theme}>{children}</ThemeProvider>;
};

const renderRightIcons = () => {
  return <DefaultHeader />;
};

const MAIN_APP = {
  logo: RoofLogo_MiPurchasing,
  name: "MiAccounting",
  url: "/accounting/accountpayable",
};

export const AccountingRoutes = () => {
  const [nav, setNav] = useState([]);
  const permissions = localStorage.getItem("permissions");

  useEffect(() => {
    setNav(buildNavBasedOnPermissions(ACCOUNTING_NAV, permissions));
  }, []);
  const buildNavBasedOnPermissions = (nav, permissions) => {
    var titleObjectIndex = {};
    var newNav = {
      items: [],
    };
    permissions = permissions || [];
    nav.items.forEach((item) => {
      if (item.title) {
        titleObjectIndex[item.name] = newNav.items.length;
      }
      if (item.children) {
        var childNav = buildNavBasedOnPermissions(
          { items: item.children },
          permissions
        );
        item.children = childNav.items ? childNav.items : [];
      }

      if (
        (item.permissionName &&
          permissions.indexOf(item.permissionName) > -1) ||
        !item.permissionName
      ) {
        newNav.items.push(item);
      }
    });
    return newNav;
  };

  const renderFooter = () => {
    return <DefaultFooter />;
  };

  return (
    <AppProvider>
      <CssBaseline />
      <Session
        nav={nav}
        mainApp={MAIN_APP}
        apps={APPLIST}
        renderRightIcons={renderRightIcons}
        admin={true}
        renderFooter={renderFooter}
      />
    </AppProvider>
  );
};
