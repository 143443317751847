import React from "react";
import { makeStyles, withStyles } from "tss-react/mui";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { THEME } from "./theme";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ padding: 4 }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const StyledTabs = withStyles(Tabs, () => ({
  root: {
    minHeight: 0,
    borderBottom: `1px solid ${THEME.GREY_LIGHT}`,
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
  flexContainer: {
    flexDirection: "row-reverse",
  },
  fixed: {
    height: 34,
  },
}));

const StyledTab = withStyles(
  (props) => <Tab disableRipple {...props} />,
  (theme, _params, classes) => ({
    root: {
      textTransform: "none",
      minWidth: 72,
      padding: "0px 10px 10px",
      fontWeight: theme.typography.fontWeightRegular,
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      [`&.${classes.selected}`]: {
        color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#40a9ff",
        outline: "none",
      },
    },
    selected: {},
  })
);

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
}));

const MiPreviewTabs = (props) => {
  const { classes } = useStyles();
  const { previewTab, setPreviewTab, ariaLabel, tabs } = props;

  const handleChange = (event, newValue) => {
    setPreviewTab(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <StyledTabs
          value={previewTab}
          onChange={handleChange}
          aria-label={ariaLabel || "MiPreviewTabs"}
        >
          {tabs.map((tab, i) => {
            return <StyledTab key={i} label={tab.label} />;
          })}
        </StyledTabs>
      </div>
    </div>
  );
};

MiPreviewTabs.TabPanel = TabPanel;

export default MiPreviewTabs;
