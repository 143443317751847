import React from "react";
import { styled } from "@mui/material";
import { ORIENTATION } from "@miview/constants";

export const NewRow = styled("div")(
  {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  ({ orientation }) => {
    const isVertical = orientation === ORIENTATION.VERTICAL;
    return {
      flexDirection: isVertical ? "column" : "row",
      alignItems: isVertical ? "flex-start" : "center",
    };
  }
);

export const StyledRow = (props) => {
  return <NewRow orientation={props.orientation}>{props.children}</NewRow>;
};
