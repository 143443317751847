import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Grid,
  useTheme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiButton, MiInputTextbox, MiIcon } from "@miview/components";
import { STATUS } from "../constants";
import {
  mdiArchiveOutline,
  mdiMinusCircleOffOutline,
  mdiProgressCheck,
  mdiTrashCanOutline,
  mdiAccountOffOutline,
  mdiAccountCheck,
  mdiFileRemoveOutline,
  mdiCancel,
  mdiNoteTextOutline,
} from "@mdi/js";

const useStyles = makeStyles()((theme, { props }) => ({
  title: {
    color: theme.palette.secondary.bluegrey,
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.medium,
    alignItems: "center",
  },
  description: {
    color: theme.palette.dark.bluegrey,
    fontSize: theme.fontSize.small,
    align: "center",
  },
  iconBackground: {
    width: "50px",
    height: "50px",
    borderRadius: "50px",
    marginBottom: "20px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    backgroundColor: props.backgroundColor,
  },
  buttonAlignment: {
    padding: props?.buttonPadding || "25px 30px",
  },
  styledButtonAlign: {
    padding: props?.buttonPadding || "25px 30px",
    borderTop: `1px solid ${theme.palette.medium.grey}`,
    backgroundColor: theme.palette.lightAccent.grey,
    marginTop: 20,
  },
  dialogPaper: {
    width: props?.dialogWidth,
  },
  dgContentStyle: {
    padding: "20px 20px 0px 20px",
  },
  dgTitleStyle: {
    padding: "20px 20px 0px 20px",
  },
  textArea: {
    padding: "20px 20px 0px 20px",
  },
  textField: {
    padding: "20px 50px 0px 50px",
  },
}));

const StatusConfirmationModal = ({
  open,
  title,
  description1,
  description2,
  cancelTxt,
  approveTxt,
  onCancel,
  onApprove,
  isTextBox,
  isTextArea,
  type,
  buttonStyle,
  dialogWidth,
  isDeleteButton,
  onDelete,
  deleteTxt,
  buttonPadding,
  textAreaPlaceholder,
  textBoxValue,
  handleChangeTextBox,
  textAreaValue,
  isNormalActionButtons,
  isStyledActionButtons,
  approveTextColor,
  approveButtonColor,
  txtFilledColor,
  txtEmptyColor,
  isTextAreaModified,
  isDisableApproveBtn,
}) => {
  const theme = useTheme();
  const types = {
    [STATUS.active]: {
      icon: (
        <MiIcon
          path={mdiProgressCheck}
          size={1}
          color={theme.palette.primary.main}
        />
      ),
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.light.main,
    },
    [STATUS.inactive]: {
      icon: (
        <MiIcon
          path={mdiMinusCircleOffOutline}
          size={1}
          color={theme.palette.primary.amber}
        />
      ),
      color: theme.palette.primary.amber,
      backgroundColor: theme.palette.light.amber,
    },
    [STATUS.active2]: {
      icon: (
        <MiIcon
          path={mdiAccountCheck}
          size={1}
          color={theme.palette.primary.main}
        />
      ),
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.light.main,
    },
    [STATUS.inActive2]: {
      icon: (
        <MiIcon
          path={mdiAccountOffOutline}
          size={1}
          color={theme.palette.primary.amber}
        />
      ),
      color: theme.palette.primary.amber,
      backgroundColor: theme.palette.light.amber,
    },
    [STATUS.archived]: {
      icon: (
        <MiIcon
          path={mdiArchiveOutline}
          size={1}
          color={theme.palette.primary.blue}
        />
      ),
      color: theme.palette.primary.blue,
      backgroundColor: theme.palette.light.blue,
    },
    [STATUS.delete]: {
      icon: (
        <MiIcon
          path={mdiTrashCanOutline}
          size={1}
          color={theme.palette.secondary.red}
        />
      ),
      color: theme.palette.secondary.red,
      backgroundColor: theme.palette.light.red,
    },
    [STATUS.cancel]: {
      icon: (
        <MiIcon
          path={mdiFileRemoveOutline}
          size={1}
          color={theme.palette.secondary.red}
        />
      ),
      color: theme.palette.secondary.red,
      backgroundColor: theme.palette.light.red,
    },
    [STATUS.cancel2]: {
      icon: (
        <MiIcon path={mdiCancel} size={1} color={theme.palette.primary.white} />
      ),
      color: theme.palette.secondary.red,
      backgroundColor: theme.palette.secondary.red,
    },
    [STATUS.text]: {
      icon: (
        <MiIcon
          path={mdiNoteTextOutline}
          size={1}
          color={theme.palette.primary.blue}
        />
      ),
      color: theme.palette.primary.blue,
      backgroundColor: theme.palette.light.blue,
    },
  };
  const { classes } = useStyles({
    props: {
      backgroundColor: types[type].backgroundColor,
      dialogWidth,
      buttonPadding,
    },
  });

  const [txtArea, setTxtArea] = useState(textAreaValue || "");

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle classes={{ root: classes.dgTitleStyle }}>
        <Box display="flex" justifyContent="center" maxWidth="sx">
          <div className={classes.iconBackground}>{types[type].icon}</div>
        </Box>
        <Typography align="center" component="div" className={classes.title}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        disableTypography
        classes={{ root: classes.dgContentStyle }}
      >
        <Typography align="center" className={classes.description}>
          {description1}
        </Typography>
        <Typography align="center" className={classes.description}>
          {description2}
        </Typography>

        {isTextBox && (
          <Grid align="center" className={classes.textField}>
            <MiInputTextbox
              placeholder="Enter Reason"
              value={textBoxValue}
              handleChange={handleChangeTextBox}
            />
          </Grid>
        )}
        {isTextArea && (
          <Grid align="center">
            <MiInputTextbox
              placeholder={textAreaPlaceholder}
              labelTextColor={theme.palette.medium.bluegrey}
              value={txtArea}
              handleChange={(e) => setTxtArea(e.target.value)}
              rows={6}
              multiline
            />
          </Grid>
        )}
      </DialogContent>
      {isNormalActionButtons && (
        <DialogActions className={classes.buttonAlignment}>
          <Grid container direction="row" justifyContent="space-evenly">
            <Grid item>
              <MiButton
                title={cancelTxt}
                onClick={onCancel}
                style={{
                  fontSize: theme.fontSize.small,
                  fontWeight: theme.fontWeight.medium,
                  ...buttonStyle,
                }}
              />
            </Grid>
            {isDeleteButton && (
              <Grid item>
                <MiButton
                  title={deleteTxt}
                  onClick={onDelete}
                  inverse={true}
                  color={theme.palette.secondary.red}
                  style={{
                    fontSize: theme.fontSize.small,
                    fontWeight: theme.fontWeight.medium,
                    ...buttonStyle,
                  }}
                />
              </Grid>
            )}
            <Grid item>
              <MiButton
                title={approveTxt}
                onClick={() => onApprove(txtArea)}
                color={
                  (txtArea === "" && approveTextColor) ||
                  theme.palette.primary.white
                }
                backgroundColor={
                  approveButtonColor ||
                  (txtArea === "" ? txtEmptyColor : txtFilledColor) ||
                  types[type].color
                }
                style={{
                  fontSize: theme.fontSize.small,
                  fontWeight: theme.fontWeight.medium,
                  ...buttonStyle,
                }}
                disabled={
                  (txtArea === "" && isTextAreaModified) || isDisableApproveBtn
                }
              />
            </Grid>
          </Grid>
        </DialogActions>
      )}
      {isStyledActionButtons && (
        <DialogActions className={classes.styledButtonAlign}>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <MiButton
                title={cancelTxt}
                onClick={onCancel}
                backgroundColor={theme.palette.primary.white}
                color={theme.palette.dark.bluegrey}
                style={{
                  fontSize: theme.fontSize.small,
                  fontWeight: theme.fontWeight.medium,
                  ...buttonStyle,
                }}
              />
            </Grid>
            <Grid item>
              <MiButton
                title={approveTxt}
                onClick={() => onApprove()}
                inverse={true}
                color={approveButtonColor || types[type].color}
                style={{
                  fontSize: theme.fontSize.small,
                  fontWeight: theme.fontWeight.medium,
                  ...buttonStyle,
                }}
              />
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

StatusConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description1: PropTypes.string.isRequired,
  description2: PropTypes.string.isRequired,
  approveTxt: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  cancelTxt: PropTypes.string,
  type: PropTypes.string.isRequired,
  isTextBox: PropTypes.bool,
  isDeleteButton: PropTypes.bool,
  isDisableApproveBtn: PropTypes.bool,
  isTextAreaModified: PropTypes.bool,
};

StatusConfirmationModal.defaultProps = {
  cancelTxt: "Cancel",
  deleteTxt: "Delete",
  isStyledActionButtons: false,
  isNormalActionButtons: true,
};

export default React.memo(StatusConfirmationModal);
