import React from "react";
import { LinearProgress, CircularProgress, alpha } from "@mui/material";
import { styled } from "@mui/system";

const getBackground = (color, type, primaryColor) => {
  if (type !== "linear") {
    return "transparent";
  }
  return alpha(color || primaryColor, 0.2);
};

const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (props) =>
    !["color", "loaderColor", "type"].includes(props),
})(({ theme, color, loaderColor, type }) => ({
  width: "100%",
  height: 5,
  borderRadius: 5,
  color: loaderColor,
  backgroundColor: getBackground(color, type, theme.palette.primary.main),
}));

const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp: (props) =>
    !["color", "loaderColor", "type"].includes(props),
})(({ theme, color, loaderColor, type }) => ({
  width: "100%",
  height: 5,
  borderRadius: 5,
  color: loaderColor,
  backgroundColor: getBackground(color, type, theme.palette.primary.main),
}));

export const MiLoader = (props) => {
  const { type, width, color, loaderColor, ...rest } = props;

  const renderProgressType = () => {
    if (type === "linear") {
      return (
        <StyledLinearProgress
          data-testid={"LinearProgress"}
          variant="indeterminate"
          {...rest}
        />
      );
    }
    return (
      <StyledCircularProgress
        data-testid={"CircularProgress"}
        variant="indeterminate"
        {...rest}
      />
    );
  };

  return (
    <div
      style={{
        width: width || "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {renderProgressType()}
    </div>
  );
};
