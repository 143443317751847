import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const countyService = {
  getAll: async ({ filters, pageSize, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/counties`,
      method: "get",
      signal,
      params,
      filters,
    });

    return apiRequest(options);
  },
};
