import React from "react";
import { MiModalConfirm } from "@miview/components";
import { walkTypeService } from "@miview/api";

export const DeleteWalkTypePredecessorModal = (props) => {
  const { walkTypeId, selection, close, isVisible } = props;

  const onSave = async () => {
    await walkTypeService.deletePredecessor(
      walkTypeId,
      selection.predecessorId
    );
    close(true);
  };

  return (
    <MiModalConfirm
      open={isVisible}
      title="Confirm Delete"
      description={`Delete the following prerequisite: ${selection.predecessorName}?`}
      handlePositive={onSave}
      handleNegative={() => close(false)}
    ></MiModalConfirm>
  );
};
