import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import FileCopy from "@mui/icons-material/FileCopy";
import AccountTree from "@mui/icons-material/AccountTree";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRouter, useComponentState } from "@miview/hooks";
import { accountServiceLegacy as accountService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { MiButton } from "@miview/components";
import {
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { mdiPlus } from "@mdi/js";
import NewCommunityModal from "./NewCommunityModal";

const BuilderCommunities = ({ builderId }) => {
  const [isNewCommunityModalOpen, setIsNewCommunityModalOpen] = useState(false);
  const [communities, setCommunities] = useState([]);

  const { classes } = useStyles();
  const router = useRouter();
  const stateManager = useComponentState();
  const theme = useTheme();

  useEffect(() => {
    handleGetCommunities();
  }, []);

  const handleSaveCommunity = (selectedCommunityId) => {
    stateManager.run(async () => {
      const response = await accountService.addCommunity(
        builderId,
        selectedCommunityId
      );
      if (response.status === HTTP_STATUSES.OK) {
        handleGetCommunities();
        setIsNewCommunityModalOpen(false);
      }
    });
  };

  const handleGetCommunities = () => {
    stateManager.run(async () => {
      const response = await accountService.getCommunities(builderId);
      if (response.status === HTTP_STATUSES.OK) {
        setCommunities(response.data);
      }
    });
  };

  const renderCommunityPlanList = (plans) => {
    return plans?.map((plan) => (
      <div
        key={plan.id}
        onClick={() => router.push(`/plans/${plan.id}`)}
        className={classes.renderCommunityPlanList}
      >
        <p>{plan.name} (V1.0)</p>
        <div>
          <FileCopy className={classes.fileCopyIcon} />
          <AccountTree className={classes.accountTreeIcon} />
        </div>
      </div>
    ));
  };

  const renderCommunityList = () => {
    return communities.map((community, i) => (
      <Accordion key={i} className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummary}
        >
          <div>
            <div className={classes.communityNameContainer}>
              {community.communityName}
            </div>
            <div style={{ marginTop: 8, fontSize: 12 }}>
              <span style={{ marginRight: 6 }}>{community.city}</span> |
              <span style={{ margin: "0 6px" }}>
                {community.propertyCount} Homes
              </span>
              <span style={{ marginLeft: 6 }}>
                {community.plans.length} Plans
              </span>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block", boxShadow: "none" }}>
          <>
            {community.plans.length
              ? renderCommunityPlanList(community.plans)
              : "No plans added yet"}
          </>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <div className={classes.root}>
      {stateManager.statusTag("builderCommunityDetailStatus")}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <MiButton
          title="New Community"
          onClick={() => setIsNewCommunityModalOpen(true)}
          disabled={false}
          icon={mdiPlus}
          color={theme.palette.primary.main}
          inverse
        />
      </div>
      {renderCommunityList()}
      <NewCommunityModal
        builderId={builderId}
        handleOnSave={handleSaveCommunity}
        closeNewCommunityModal={() => setIsNewCommunityModalOpen(false)}
        isNewCommunityModalOpen={isNewCommunityModalOpen}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    boxShadow: "none",
    "&::before": {
      backgroundColor: "transparent",
    },
  },
  renderCommunityPlanList: {
    border: `1px solid ${theme.palette.light.grey}`,
    color: theme.palette.secondary.purple,
    borderRadius: 5,
    width: "100%",
    margin: 10,
    height: 50,
    display: "flex",
    alignItems: "center",
    paddingLeft: 18,
    cursor: "pointer",
    justifyContent: "space-between",
  },
  accordionSummary: {
    border: `1px solid ${theme.palette.light.grey}`,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 6,
  },
  communityNameContainer: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  fileCopyIcon: { color: theme.palette.primary.main, marginRight: 10 },
  accountTreeIcon: { color: theme.palette.primary.orange, marginRight: 26 },
}));

export default BuilderCommunities;
