import React from "react";
import { THEME } from "@miview/theme";

export const MiFormSectionHeader = (props) => {
  const { icon, title, button, ...rest } = props;
  const isFA = icon?.startsWith("fa-");

  return (
    <div {...rest}>
      <div style={styles.section}>
        <div style={{ display: "flex" }}>
          {icon && (
            <i
              className={isFA ? "fa " + icon : "material-icons"}
              style={{
                color: THEME.GREY_MEDIUM,
                fontSize: 20,
                marginRight: ".2rem",
              }}
            >
              {isFA ? "" : icon}
            </i>
          )}
          <span style={styles.title}>{title}</span>
        </div>
        {button}
      </div>
    </div>
  );
};

const styles = {
  section: {
    marginBottom: ".5rem",
    width: "100%",
    display: "flex",
    position: "relative",
    padding: 10,
    backgroundColor: THEME.GREY_LIGHT_BACKGROUND,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: ".25rem",
  },
  title: {
    fontSize: ".875rem",
    margin: 0,
    padding: 0,
    fontWeight: "300",
    color: THEME.GREY_MEDIUM_ALT,
  },
};
