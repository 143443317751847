import React from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "tss-react/mui";
import { createTheme } from "@miview/theme";
import {
  MiCheckbox,
  MiListFilterMenu,
  MiMultiselectChip,
  DateRangePicker,
} from "../index.ts";
import {
  Select,
  InputLabel,
  InputBase,
  FormControl,
  ListItemText,
  MenuItem,
  Box,
  Button,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles()((theme, { props }) => ({
  filterList: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    padding: "5px",
  },
  search_Outer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  featureFilter: {
    borderRadius: theme.shape.borderRadius,
    border: "1px solid",
    borderColor: theme.palette.lightAccent.grey,
    minHeight: 42,
    justifyContent: "center",
    alignItems: "center",
    width: "18%",
    marginRight: 7,
    height: "100%",
  },
  search: {
    display: "flex",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid",
    padding: 5,
    borderColor: theme.palette.lightAccent.grey,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "90%",
    },
    justifyContent: "flex-start",
  },
  searchIcon: {
    alignSelf: "center",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: props.searchIconColor || theme.palette.secondary.grey,
  },
  filterIcons: {
    marginLeft: 5,
    border: "1px solid",
    borderColor: theme.palette.lightAccent.grey,
    padding: 10,
    borderRadius: "4px",
    color: theme.palette.primary.teal,
    marginRight: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.lightAccent.grey,
      cursor: "pointer",
    },
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  filter: {
    marginLeft: 0,
    marginTop: 4,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "100%",
    },
    display: "flex",
    alignItems: "center",
    padding: 10,
    backgroundColor: theme.palette.light.grey,
  },
  checkbox: {
    display: "flex",
  },
  checkboxContainer: {
    marginleft: 10,
  },
  formControl: {
    marginLeft: 10,
    minWidth: 130,
    maxWidth: 300,
  },
  listItem: {
    margin: props.menuMargin || 0,
    "& [class*='MuiTypography-body1']": {
      lineHeight: 1.2,
    },
    box: {
      marginRight: 40,
    },
  },
  filterChip: {
    display: "flex",
  },
  button: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: theme.palette.dark.bluegrey,
    fontSize: theme.fontSize.medium,
    alignItems: "center",
  },
  clearFilter: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 10,
  },
  clearFilterText: {
    marginRight: 10,
    fontWeight: 400,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MiListFilterHeader = (props) => {
  const {
    featureFilterComponent,
    filterArray,
    handleRefreshClick,
    handleSearch,
    handleChange,
    filterIconStyles,
    featureFilterStyles,
    component,
    searchTerm,
    clearSearch,
    filterIcon,
    handleFilterIconClick,
    children,
    filterIconBgColor,
    searchIconColor,
    disableNone = true,
    menuMargin,
    inputLabelClasses,
    selectBoxClasses,
    renderRefreshButton,
  } = props;

  const theme = createTheme();
  const { classes } = useStyles({ props: { searchIconColor, menuMargin } });

  const onHandleSearch = (e) => {
    handleSearch(e.target.value);
  };

  const renderFilterToggle = () => {
    if (filterArray.length === 0) {
      return null;
    }
    return (
      <div
        className={classes.filterIcons}
        onClick={handleFilterIconClick}
        style={{
          ...filterIconStyles,
          backgroundColor: filterIcon
            ? filterIconBgColor || theme.palette.light.teal
            : "",
        }}
      >
        <div className="fa fa-filter fa-lg"></div>
      </div>
    );
  };

  const renderToggles = () => {
    const checkboxes = filterArray.filter((item) => item.type === "checkbox");
    if (!checkboxes.length) {
      return null;
    }

    return (
      <div className={classes.checkbox}>
        {checkboxes.map((el) => (
          <div key={el.label} className={classes.checkboxContainer}>
            <MiCheckbox
              checked={el.value}
              onChange={() => handleChange(!el.value, el.label)}
              color="primary"
              label={el.label}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderFilterItems = (ele) => {
    return (
      <FormControl className={classes.formControl} key={ele.label}>
        <InputLabel
          id="demo-simple-select-helper-label"
          classes={{
            ...inputLabelClasses,
          }}
        >
          {ele.label}
        </InputLabel>

        <Select
          labelId={`demo-mutiple-checkbox-label-${ele.label}`}
          id={`demo-mutiple-checkbox-${ele.label}`}
          value={ele.value}
          onChange={(e) => handleChange(e.target.value, ele.label)}
          MenuProps={MenuProps}
          label={ele.label}
          disableUnderline={true}
          classes={{ ...selectBoxClasses }}
        >
          {disableNone && (
            <MenuItem value="">
              <ListItemText
                className={classes.listItem}
                primary="None"
              ></ListItemText>
            </MenuItem>
          )}
          {ele.options.map((item, idx) => (
            <MenuItem key={idx} value={item.value}>
              {item.icon}
              <ListItemText className={classes.listItem} primary={item.label} />
            </MenuItem>
          ))}
          {ele.clearFilterOption && (
            <div className={classes.clearFilter} value="">
              <Button
                className={classes.button}
                disableRipple
                onClick={ele.handleClickClearFilter}
              >
                <Typography className={classes.clearFilterText}>
                  {"Clear Filter"}
                </Typography>
                <CloseIcon
                  fontSize="small"
                  style={{ color: theme.palette.dark.bluegrey }}
                />
              </Button>
            </div>
          )}
        </Select>
      </FormControl>
    );
  };

  const renderDropdowns = () => {
    const dropdowns = filterArray.filter((item) => item.type === "select");
    if (!dropdowns.length) {
      return null;
    }
    return <div>{dropdowns.map((ele) => renderFilterItems(ele))}</div>;
  };

  const renderMultiSelectDropdowns = () => {
    const dropDowns = filterArray.filter((item) => item.type === "multiSelect");
    if (!dropDowns.length) {
      return null;
    }
    return (
      <Box display="flex">
        {dropDowns.map((ele, index) => (
          <Box key={index} className={classes.box}>
            <MiListFilterMenu {...ele} />
          </Box>
        ))}
      </Box>
    );
  };

  const renderFilterActions = () => {
    const actions = filterArray.filter((item) => item.type === "filterAction");
    if (!actions.length) {
      return null;
    }
    return (
      <>
        {actions.map((ele, index) => (
          <Box key={index} className={classes.filterChip}>
            <MiMultiselectChip
              type={ele.chipType}
              items={ele.values}
              valueField={ele.valueField}
              size={"small"}
              selectedItems={ele.selectedItems}
              handleChange={ele.handleChange}
              activeColor={ele.activeColor}
              color={ele.color}
              chipStyle={ele.chipStyle}
            />
          </Box>
        ))}
      </>
    );
  };
  const renderDateRangeToggle = () => {
    const actions = filterArray.filter((item) => item.type === "dateRange");
    if (!actions.length) {
      return null;
    }
    return (
      <>
        {actions.map((ele) => (
          <DateRangePicker
            key={ele.label}
            getSelectedRange={ele.getRange}
            rootStyles={ele.rootStyles}
          />
        ))}
      </>
    );
  };

  const renderFilters = () => {
    if (filterArray.length === 0 || !filterIcon) {
      return null;
    }
    return (
      <div className={classes.filter}>
        {renderMultiSelectDropdowns()}
        {renderDropdowns()}
        {renderToggles()}
        {renderFilterActions()}
        {renderDateRangeToggle()}
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search"
          onChange={onHandleSearch}
          value={searchTerm || ""}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
        />
        {searchTerm && (
          <CloseIcon
            style={{ alignSelf: "center", cursor: "pointer" }}
            onClick={clearSearch}
          />
        )}
      </div>
    );
  };

  const renderFeatureComponent = () => {
    if (!featureFilterComponent) {
      return null;
    }
    return (
      <div
        className={classes.featureFilter}
        style={{
          ...featureFilterStyles,
        }}
      >
        {featureFilterComponent}
      </div>
    );
  };

  const renderComponent = () => {
    if (!component) {
      return null;
    }
    return <div>{component}</div>;
  };

  return (
    <div className={classes.filterList}>
      <div className={classes.search_Outer}>
        {renderFeatureComponent()}
        {renderSearch()}
        {renderRefreshButton && (
          <div
            className={classes.filterIcons}
            onClick={handleRefreshClick}
            style={{ ...filterIconStyles }}
          >
            <div className="fa fa-refresh fa-lg"></div>
          </div>
        )}
        {renderFilterToggle()}
        {children}
      </div>
      {renderFilters()}
      {renderComponent()}
    </div>
  );
};

MiListFilterHeader.propTypes = {
  featureFilterComponent: PropTypes.func,
  filterArray: PropTypes.array,
  toggles: PropTypes.array,
  handleRefreshClick: PropTypes.func,
  refreshIconStyles: PropTypes.object,
  filterIconStyles: PropTypes.object,
  featureFilterStyles: PropTypes.object,
  filterIconBgColor: PropTypes.string,
};

MiListFilterHeader.defaultProps = {
  filterArray: [],
  toggles: [],
};
