import React, { useState, useEffect } from "react";
import PropertiesCollapse from "./PropertiesCollapse";
import AssigneesCollapse from "./AssigneesCollapse";
import { useTheme } from "@mui/material";
import PlansCollapse from "./PlansCollapse";
import {
  communityService,
  cityService,
  systemTypeService,
  propertyService,
} from "@miview/api";
import {
  MiSnackbar,
  MiDetailFields,
  MiTab,
  MapContainer,
  MiButton,
  MiBox,
  MiPageHeader,
  MiIcon,
  MiFieldSet,
} from "@miview/components";
import { useEdit, useComponentState, useRouter } from "@miview/hooks";
import { HTTP_STATUSES, STATES } from "@miview/constants";
import { createUUID, createFilter, toList } from "@miview/utils";
import { mdiArrowLeft, mdiPlus } from "@mdi/js";

const tabs = [
  {
    label: "Details",
    value: global.ACTIVE_TAB.DETAILS,
  },
  {
    label: "Homes",
    value: global.ACTIVE_TAB.PROPERTIES,
  },
  {
    label: "Plans",
    value: global.ACTIVE_TAB.PLANS,
  },
];
const uuid = createUUID();

const Community = () => {
  const [community, setCommunity] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [editAssigneesOpen, setEditAssigneesOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [properties, setProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const [propertiesLoaded, setPropertiesLoaded] = useState();
  const [regions, setRegions] = useState();
  const [cities, setCities] = useState([]);
  const [powerTypes, setPowerTypes] = useState([]);
  const [activeTab, setActiveTab] = useState(global.ACTIVE_TAB.DETAILS);
  const [activeTabSidebar, setActiveTabSidebar] = useState(
    global.ACTIVE_TAB.CONTACTS
  );
  const [recenter, setRecenter] = useState(false);

  const edit = useEdit(community);
  const stateManager = useComponentState();
  const router = useRouter();
  const theme = useTheme();

  const { id: communityId } = router.params;

  useEffect(() => {
    document.title = community
      ? `Community - ${community.communityName}`
      : "MiView - Communities";
  }, [community]);

  useEffect(() => {
    getPowerTypeList();
  }, []);

  useEffect(() => {
    getProperties();
  }, [searchTerm]);

  useEffect(() => {
    getCommunity();
    getContacts();
    getRegions();
  }, [communityId]);

  const getPowerTypeList = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: "PowerType",
      });

      if (response.status === HTTP_STATUSES.OK) {
        const list = toList(response.data, "systemTypeId", "mainValue");
        setPowerTypes(list);
      }
    });
  };

  const updateCities = (stateNameShort) => {
    stateManager.run(async () => {
      const response = await cityService.getCities({
        params: { pageSize: 9999, orderBy: "cityName" },
        filters: { stateNameShort },
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "cityId", "cityName");
        setCities(mapped);
      }
    });
  };

  const getCommunity = () => {
    stateManager.run(async () => {
      const response = await communityService.getCommunity({ communityId });
      if (response.status === HTTP_STATUSES.OK) {
        setCommunity(response.data);
        const stateNameShort = response.data.stateNameShort;
        if (stateNameShort) {
          updateCities(stateNameShort);
        }
      }
    });
  };

  const getContacts = () => {
    stateManager.abort(uuid);
    stateManager.run(async (signal) => {
      const response = await communityService.getContacts({
        id: communityId,
        signal,
        params: { pageSize: 9999 },
      });
      if (response.status === HTTP_STATUSES.OK) {
        setContacts(response.data);
      }
    }, uuid);
  };

  const getRegions = () => {
    stateManager.run(async () => {
      const response = await communityService.getRegions({});
      if (response.status === HTTP_STATUSES.OK) {
        //get only top level regions
        let topLevelRegions = response.data.filter(
          (r) => r.parentId === undefined
        );

        //add children to top level regions
        topLevelRegions.forEach((p) => {
          let children = getChildRegions(p, response.data); //recursive
          p.children = children;
        });

        //flatten list with with child regions indented
        let regionHierarchyList = getFlatRegionHierarchyList(topLevelRegions);

        setRegions(regionHierarchyList.length ? regionHierarchyList : []);
      }
    });
  };

  const coordinatesDidUpdate = (latitude, longitude) => {
    edit.update({ latitude, longitude });
  };

  const toggleEdit = () => setEditMode(!editMode);

  const getChildRegions = (parent, regions) => {
    if (!regions) {
      return [];
    }
    let children = [];
    regions.forEach((r) => {
      if (r.parentId == parent.regionId) {
        r.children = getChildRegions(r, regions);
        children.push(r);
      }
    });
    return children;
  };

  const getFlatRegionHierarchyList = (regions, indent) => {
    if (!regions) {
      return [];
    }
    indent = indent || "";
    let regionList = [];
    regions.forEach((r) => {
      regionList.push({
        regionId: r.regionId,
        regionName: indent + r.regionName,
      });
      let childItems = getFlatRegionHierarchyList(
        r.children,
        indent + "(" + r.regionName + ") "
      );
      regionList.push(...childItems);
    });

    return regionList;
  };

  const getRegionNameWithParent = (id, regionList) => {
    const region = regionList.filter((r) => r.regionId === id)[0];

    if (region) {
      let regionName = region.regionName;

      if (region.parentId) {
        regionName =
          "(" +
          regionList.filter((r) => r.regionId === region.parentId)[0]
            .regionName +
          ") " +
          regionName;
      }
      return regionName;
    } else {
      return "";
    }
  };

  const getProperties = async () => {
    const filterHelper = createFilter();
    filterHelper.equals({ communityId });
    filterHelper.contains({ addressLine1: searchTerm });
    const response = await propertyService.getAll({
      pageSize: 9999,
      filters: filterHelper.getFilter(),
    });
    if (response.status === HTTP_STATUSES.OK) {
      setProperties(response.data);
      setPropertiesLoaded(true);
    }
  };

  const onSetActiveTab = (tab) => {
    switch (tab) {
      case global.ACTIVE_TAB.DETAILS:
        setActiveTab(global.ACTIVE_TAB.DETAILS);
        break;
      case global.ACTIVE_TAB.PROPERTIES:
        setActiveTab(global.ACTIVE_TAB.PROPERTIES);
        if (!propertiesLoaded) {
          getProperties();
        }
        break;
      case global.ACTIVE_TAB.BUILDERS:
        setActiveTab(global.ACTIVE_TAB.BUILDERS);
        break;
      case global.ACTIVE_TAB.CONTACTS:
        setActiveTabSidebar(global.ACTIVE_TAB.CONTACTS);
        break;
      case global.ACTIVE_TAB.PLANS:
        setActiveTab(global.ACTIVE_TAB.PLANS);
        break;
      default:
        break;
    }
  };

  const updateAssignees = (newcontacts, accountId, type, reassignWalks) => {
    stateManager.run(async () => {
      await communityService.updateAssignees({
        id: communityId,
        request: newcontacts,
        params: {
          accountId,
          contactType: type,
          reassignWalks,
        },
      });
      getContacts();
      setEditAssigneesOpen(false);
    });
  };

  const cancelEdit = () => {
    setEditMode(!editMode);
    edit.reset();
    updateCities(community.stateNameShort);
  };

  const refresh = () => {
    stateManager.run(async () => {
      const response = await communityService.getCommunity({ communityId });
      if (response.status === HTTP_STATUSES.OK) {
        setCommunity(response.data);
      }
    });
  };

  const saveEdits = () => {
    stateManager.run(async () => {
      await communityService.updateCommunity({
        id: community.communityId,
        request: edit.edits,
      });
      edit.reset();
      refresh();
      setEditMode(!editMode);
    });
  };

  const getLatitudeError = () => {
    if (isNaN(edit.getValue("latitude"))) {
      return "Improper Format";
    }
  };

  const getLongitudeError = () => {
    if (isNaN(edit.getValue("longitude"))) {
      return "Improper Format";
    }
  };

  const isValid = () => {
    const lat = edit.getValue("latitude");
    const long = edit.getValue("longitude");
    return (
      edit.getValue("cityId") &&
      edit.getValue("communityName") &&
      edit.getValue("regionId") &&
      lat &&
      !isNaN(lat) &&
      long &&
      !isNaN(long)
    );
  };

  const detailFields = [
    {
      label: "Name",
      value: edit.getValue("communityName"),
      required: true,
      setValue: (e) => edit.update({ communityName: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "City",
      fieldType: "select",
      value: edit.getValue("cityId"),
      options: cities,
      required: true,
      setValue: (e) => edit.update({ cityId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "State",
      fieldType: "select",
      value: edit.getValue("stateNameShort"),
      required: true,
      options: STATES.map((s) => ({
        text: s.name,
        value: s.shortName,
      })),
      setValue: (e) => {
        updateCities(e);
        edit.update({
          stateNameShort: e,
          cityId: null,
        });
      },
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Region",
      fieldType: "select",
      value: edit.getValue("regionId"),
      required: true,
      options: regions?.map((r) => ({
        value: r.regionId,
        text: r.regionName,
      })),
      setValue: (e) => edit.update({ regionId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Power Type",
      fieldType: "select",
      value: edit.getValue("powerTypeId"),
      options: powerTypes,
      setValue: (e) => edit.update({ powerTypeId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Latitude",
      error: getLatitudeError(),
      value: edit.getValue("latitude"),
      required: true,
      setValue: (e) => {
        edit.update({ latitude: e });
        setRecenter(!recenter);
      },
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Longitude",
      error: getLongitudeError(),
      value: edit.getValue("longitude"),
      required: true,
      setValue: (e) => {
        edit.update({ longitude: e });
        setRecenter(!recenter);
      },
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
  ];

  return (
    <>
      {stateManager.statusTag("communityDetailsStatus")}
      <MiPageHeader
        color={theme.palette.primary.main}
        title={edit.getValue("communityName")}
        loading={stateManager.isBusy()}
        leftIcon={
          <MiIcon
            style={{ cursor: "pointer" }}
            path={mdiArrowLeft}
            size={1.5}
            color={theme.palette.primary.main}
            onClick={router.goBack}
          />
        }
        details={
          <MiFieldSet
            hasDivider={true}
            orientation={"Horizontal"}
            dividerOrientation={"vertical"}
            fieldSetArray={[
              {
                label: "City",
                value: cities.find((e) => e.value === edit.getValue("cityId"))
                  ?.text,
              },
              { label: "State", value: edit.getValue("stateNameShort") },
              {
                label: "Region",
                value:
                  regions && edit.getValue("regionId")
                    ? getRegionNameWithParent(
                        edit.getValue("regionId"),
                        regions
                      )
                    : "",
              },
              { label: "Homes", value: edit.getValue("numberOfProperties") },
            ]}
          />
        }
      />
      <div style={{ display: "flex" }}>
        <MiBox styles={{ width: "80%" }}>
          <div style={{ marginBottom: 20, paddingTop: 5 }}>
            <MiTab
              tabs={tabs}
              currenttab={activeTab}
              onTabChange={onSetActiveTab}
            />
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            {activeTab === global.ACTIVE_TAB.DETAILS && (
              <>
                <MiDetailFields
                  detailFields={detailFields}
                  headerProps={{
                    icon: "list",
                    title: "Detail Fields",
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    maxHeight: 300,
                    position: "relative",
                  }}
                >
                  {!editMode && (
                    <div
                      onDoubleClick={toggleEdit}
                      style={{
                        position: "absolute",
                        zIndex: 10,
                        background: "rgba(0, 0, 0, 0.1)",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  )}
                  <MapContainer
                    lat={edit.getValue("latitude")}
                    lng={edit.getValue("longitude")}
                    recenter={recenter}
                    onCoordUpdate={coordinatesDidUpdate}
                    onClick={() => setEditMode(true)}
                    toggleEdit={toggleEdit}
                  />
                </div>
                <MiSnackbar
                  visible={editMode}
                  handleCancelClick={cancelEdit}
                  handleSaveClick={saveEdits}
                  disableSave={!isValid()}
                />
              </>
            )}
            {activeTab === global.ACTIVE_TAB.PROPERTIES && (
              <PropertiesCollapse
                style={{ width: "100%" }}
                properties={properties}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                imageUrl={community.imageUrl}
                communityId={communityId}
                refresh={refresh}
              />
            )}
            {activeTab === global.ACTIVE_TAB.PLANS && (
              <PlansCollapse
                style={{ width: "100%" }}
                communityId={communityId}
                communityName={community.communityName}
              />
            )}
          </div>
        </MiBox>
        <MiBox styles={{ width: "35%" }}>
          <div style={{ marginBottom: 20, paddingTop: 5 }}>
            <MiTab
              style={{ marginBottom: 20, paddingTop: 5 }}
              tabs={[{ label: "Contacts", value: global.ACTIVE_TAB.CONTACTS }]}
              currenttab={activeTabSidebar}
            />
          </div>
          {activeTabSidebar === global.ACTIVE_TAB.CONTACTS && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", paddingBottom: 7 }}>
                <MiButton
                  title="Assignee"
                  variant="outlined"
                  color={theme.palette.primary.main}
                  onClick={() => setEditAssigneesOpen(true)}
                  icon={mdiPlus}
                />
              </div>
              <AssigneesCollapse
                style={{ width: "100%" }}
                contacts={contacts}
                communityId={communityId}
                editAssigneesOpen={editAssigneesOpen}
                setEditAssigneesOpen={setEditAssigneesOpen}
                communityName={edit.getValue("communityName")}
                updateAssignees={updateAssignees}
              />
            </div>
          )}
        </MiBox>
      </div>
    </>
  );
};

export default Community;
