export const ACCOUNTING_NAV = {
  items: [
    {
      permissionName: "CanViewAccounting",
      name: "Accounts Payable",
      url: "/accounting/accountpayable",
      icon: "MonetizationOn",
    },
  ],
};
