import React from "react";
import PropTypes from "prop-types";
import { Image } from "../Image/Image";
import { Typography } from "@mui/material";

export const MiImagePreview = ({ src, helperText, onClick }) =>
  src ? (
    <Image style={imgStyle} imgSrc={src} onClick={onClick} />
  ) : (
    <Typography variant="subtitle2">{helperText}</Typography>
  );

MiImagePreview.propTypes = {
  src: PropTypes.string,
  helperText: PropTypes.string,
  onClick: PropTypes.func,
};

MiImagePreview.defaultProps = {
  helperText: "Please select an Image for Preview",
  onClick: null,
};

const imgStyle = {
  border: "2px solid #73818f",
  maxHeight: "auto",
  height: "auto",
  width: "100%",
  maxWidth: "220px",
};
