import React, { useMemo } from "react";
import {
  MiInputTextbox,
  MiInputSelectBox,
  MiDatePickerInput,
} from "@miview/components";
import { Avatar, Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import moment from "moment";
import SubHeader from "./SubHeader";
import { en } from "@miview/constants";
import { getInitials } from "@miview/utils";

const useStyles = makeStyles()((theme) => ({
  inputBoxAlign: {
    height: 40,
    "&.MuiOutlinedInput-root-612.Mui-error .MuiOutlinedInput-notchedOutline-618":
      {
        borderColor: theme.palette.medium.grey,
      },
  },
  root: {
    width: 24,
    height: 24,
    display: "inline-flex",
    overflow: "initial",
    textAlign: "center",
    backgroundColor: theme.palette.dark.bluegrey,
    fontSize: theme.fontSize.medium,
    marginRight: 10,
  },
}));

const PurchaseOrderForm = ({
  disabled,
  edit,
  contacts,
  locations,
  account,
  isMissingRequiredField,
  setIsMissingRequiredField,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const specialInstructionError =
    isMissingRequiredField && !edit.getValue("specialInstructions");

  const handleChange = (field, value) => {
    edit.update({ [field]: value });
  };
  const getFormattedDate = (dValue = "") => {
    return dValue ? moment(dValue).format("MM DD YY") : "";
  };

  const supplierItem = useMemo(() => [account], [account]);
  const updateAvatarIcons = (options) =>
    options.map((item) => {
      return {
        ...item,
        icon: (
          <Avatar className={classes.root}>
            {getInitials(item.label ?? "")}
          </Avatar>
        ),
      };
    });
  const supplierName = updateAvatarIcons(supplierItem);
  const contactName = updateAvatarIcons(contacts);

  return (
    <Grid container spacing={5}>
      <Grid item xs={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <SubHeader
              text={en.supplier}
              headerColor={theme.palette.primary.teal}
            />
          </Grid>
          <Grid item xs={12}>
            <MiInputSelectBox
              labelText={en.supplier}
              labelTextColor={theme.palette.medium.bluegrey}
              handleChange={(e) => edit.update("accountName", e.target.value)}
              required={true}
              minWidth={150}
              menuItems={supplierName}
              defaultValue={account?.value}
              disabled={true}
              disableNone={true}
              style={{ height: 40 }}
            />
          </Grid>
          <Grid item xs={12}>
            <MiInputSelectBox
              labelText={en.contact}
              labelTextColor={theme.palette.medium.bluegrey}
              minWidth={10}
              defaultValue={edit.getValue("contactName")}
              required={true}
              disabled={disabled}
              disableNone={true}
              handleChange={(e) => edit.update({ contactName: e.target.value })}
              menuItems={contactName}
              style={{ height: 40 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <SubHeader
              text={en.delivery}
              headerColor={theme.palette.primary.teal}
            />
          </Grid>
          <Grid item xs={12}>
            <MiDatePickerInput
              type={"date"}
              labelText={en.deliveryDate}
              labelTextColor={theme.palette.medium.bluegrey}
              value={getFormattedDate(edit.getValue("deliveryDate"))}
              handleChange={(val) => handleChange("deliveryDate", val)}
              inputFormat="MM/DD/YYYY"
              dateFormat="MM DD YY"
              required={true}
              disabled={disabled}
              InputProps={{
                classes: {
                  root: classes.inputBoxAlign,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MiInputSelectBox
              labelText={en.deliveryLocation}
              labelTextColor={theme.palette.medium.bluegrey}
              menuItems={locations}
              defaultValue={edit.getValue("deliveryLocation")}
              required={true}
              disabled={disabled}
              disableNone={true}
              handleChange={(e) =>
                edit.update({ deliveryLocation: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <SubHeader
              text={en.schedule}
              headerColor={theme.palette.primary.teal}
            />
          </Grid>
          <Grid item xs={12}>
            <MiDatePickerInput
              type={"date"}
              labelText={en.scheduleDate}
              labelTextColor={theme.palette.medium.bluegrey}
              inputFormat="MM/DD/YYYY"
              dateFormat="MM DD YY"
              required={true}
              value={getFormattedDate(edit.getValue("billingDate"))}
              handleChange={(val) => handleChange("billingDate", val)}
              disabled={disabled}
              InputProps={{
                classes: {
                  root: classes.inputBoxAlign,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MiDatePickerInput
              type={"date"}
              labelText={en.requested}
              labelTextColor={theme.palette.medium.bluegrey}
              value={getFormattedDate(edit.getValue("requestedBillingDate"))}
              handleChange={(val) => handleChange("requestedBillingDate", val)}
              inputFormat="MM/DD/YYYY"
              dateFormat="MM DD YY"
              required={true}
              disabled={disabled}
              InputProps={{
                classes: {
                  root: classes.inputBoxAlign,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <SubHeader
              text={en.additional}
              headerColor={theme.palette.primary.teal}
            />
          </Grid>
          <Grid item xs={12}>
            <MiInputTextbox
              labelText={en.tag}
              labelTextColor={theme.palette.medium.bluegrey}
              value={edit.getValue("tag")}
              handleChange={(e) => edit.update({ tag: e.target.value })}
              minWidth={200}
              disabled={disabled}
              placeholder={en.enterTag}
              InputProps={{
                classes: {
                  root: classes.inputBoxAlign,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MiInputTextbox
              labelText={en.memo}
              labelTextColor={theme.palette.medium.bluegrey}
              value={edit.getValue("memo")}
              handleChange={(e) => edit.update({ memo: e.target.value })}
              minWidth={200}
              disabled={disabled}
              placeholder={en.enterMemo}
              InputProps={{
                classes: {
                  root: classes.inputBoxAlign,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <SubHeader
              text={en.instructions}
              headerColor={theme.palette.primary.teal}
            />
          </Grid>
          <Grid item xs={12}>
            <MiInputTextbox
              labelText={en.specialInstructions}
              labelTextColor={
                specialInstructionError
                  ? theme.palette.secondary.red
                  : theme.palette.medium.bluegrey
              }
              required
              minWidth={180}
              value={edit.getValue("specialInstructions")}
              handleChange={(e) => {
                edit.update({ specialInstructions: e.target.value });
                setIsMissingRequiredField(false);
              }}
              rows={6}
              multiline
              disabled={disabled}
              placeholder={en.enterInstructions}
              helperText={
                specialInstructionError && en.enterSpecialInstructions
              }
              error={specialInstructionError}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PurchaseOrderForm;
