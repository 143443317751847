import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.medium,
  },
  inactive: {
    color: theme.palette.light.grey,
  },
  redText: {
    fontSize: theme.fontSize.medium,
  },
  redColor: {
    color: theme.palette.primary.red,
  },
  phoneTextStyle: {
    marginRight: 6,
    color: "#2185FC",
  },
  greyColor: {
    color: theme.palette.light.grey,
  },
}));

const TableTdComponents = ({ active, value }) => {
  const { classes, cx } = useStyles();
  if (active === 1) {
    return <div className={classes.root}>{value} </div>;
  } else {
    return <div className={cx(classes.root, classes.inactive)}> {value} </div>;
  }
};

export const BolderText = ({ value }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      {typeof value === "number" ? "$" + value : value}
    </div>
  );
};

export const RedText = ({ value, color }) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.redText, color && classes.redColor)}>
      {value}
    </div>
  );
};

export default TableTdComponents;
