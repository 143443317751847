import React from "react";
import { MiInputTextbox } from "../MiInputTextBox/MiInputTextBox";

export const MiInputPhone = (props) => {
  return (
    <MiInputTextbox
      {...props}
      mask="(#00) 000-0000"
      definitions={{ "#": /[1-9]/ }}
    />
  );
};
