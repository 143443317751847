/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { BolderText, RedText } from "../../../components/TableTdComponents";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { MiTable } from "@miview/components";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    maxWidth: 1100,
  },

  borderedTable: {
    marginTop: -20,
    "& .MuiDataGrid-main": {
      borderRadius: "4px",
      overflow: "hidden",
      boxShadow:
        "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
    },
    "& .MuiDataGrid-autoHeight": {
      minHeight: "inherit",
    },
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: theme.palette.light.teal,
    },
  },
}));

const SearchRenderItems = (props) => {
  const { masterItems = [], addTemplateItems } = props;
  const { classes } = useStyles();

  const columns = [
    {
      field: "AddCart",
      headerName: (
        <IconButton className={classes.addButtonStyle} size="large">
          <PlaylistAddIcon />
        </IconButton>
      ),
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <IconButton
            className={classes.addButtonStyle}
            onClick={() => addTemplateItems(params?.row)}
            size="large"
          >
            <PlaylistAddIcon />
          </IconButton>
        );
      },
    },
    {
      field: "number",
      headerName: "Item #",
      minWidth: 190,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <BolderText value={params?.value?.toString()} />;
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        return <BolderText value={params?.value?.toString()} />;
      },
    },
    {
      field: "sku",
      headerName: "SKU",
    },

    {
      field: "listPrice",
      headerName: "Current Cost",
      headerClassName: "supplier-header",
      renderCell: (params) => {
        return <BolderText value={params?.value?.toString()} />;
      },
    },
    {
      field: "expdate",
      headerName: "Exp Date",
      renderCell: (params) => {
        return (
          <RedText
            value={params?.value?.toString()}
            color={params.row.status === 2 ? true : false}
          />
        );
      },
    },
  ];

  const renderItemTable = () => {
    return (
      <div className={classes.borderedTable}>
        <MiTable
          columns={columns}
          rows={masterItems}
          disableColumnMenu={true}
          hideFooter={true}
        />
      </div>
    );
  };

  return <div className={classes.root}>{renderItemTable()}</div>;
};

export default SearchRenderItems;
