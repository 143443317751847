import React, { useState } from "react";
import { MiBox, MiTable } from "@miview/components";
import moment from "moment";
import {
  Edit as EditIcon,
  PlayCircleFilled as RunIcon,
} from "@mui/icons-material";
import { ScheduleModal } from "./ScheduleModal";
import { RunDays } from "./RunDays";
import { integrationService } from "@miview/api";

export const Schedules = (props) => {
  const { data, fetchData } = props;
  const [selectedRow, setSelectedRow] = useState();

  const renderStartTime = ({ value }) => {
    if (!value) {
      return null;
    }
    return moment(value, "HH:mm:SS").format("h:mm A");
  };

  const renderStartDate = ({ value }) => {
    if (!value) {
      return null;
    }
    return moment(value).format("MM/DD/YY");
  };

  const renderLastRunDate = ({ value }) => {
    if (!value) {
      return null;
    }
    return moment(value).format("MM/DD/YY h:mm A");
  };

  const renderInterval = ({ value }) => {
    if (!value) {
      return null;
    }
    const val = moment(value, "h:mm:ss").hours();
    if (val === 0) {
      return `24 Hr.`;
    }
    return `${val} Hr.`;
  };

  const columns = [
    {
      field: "serviceTypeDisplayName",
      headerName: "API",
      flex: 1,
    },
    {
      field: "serviceTypeDescription",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "interval",
      headerName: "Frequency",
      flex: 1,
      renderCell: renderInterval,
    },
    {
      field: "runDays",
      headerName: "Repeats",
      flex: 1,
      minWidth: 205,
      renderCell: ({ value }) => <RunDays days={value} />,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      flex: 1,
      renderCell: renderStartTime,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      renderCell: renderStartDate,
      minWidth: 180,
    },
    {
      field: "lastRunDate",
      headerName: "Last Ran",
      flex: 1,
      renderCell: renderLastRunDate,
      minWidth: 180,
    },
    {
      field: "isEnabled",
      headerName: "Status",
      flex: 1,
      renderCell: ({ value }) => (value ? "Enabled" : "Disabled"),
    },
  ];

  const handleEdit = (row) => {
    setSelectedRow(row);
  };

  const handleCancelEdit = () => {
    setSelectedRow();
  };

  const handleRun = (row) => {
    integrationService.services.execute(row.id);
  };

  const getRowActions = (row) => {
    return [
      {
        text: "Edit",
        onClick: () => handleEdit(row),
        renderIcon: () => <EditIcon fontSize={"small"} />,
      },
      {
        text: "Run",
        disabled: !row.isEnabled,
        onClick: () => handleRun(row),
        renderIcon: () => <RunIcon fontSize={"small"} />,
      },
    ];
  };

  return (
    <div>
      {!!selectedRow && (
        <ScheduleModal
          data={selectedRow}
          handleClose={handleCancelEdit}
          fetchData={fetchData}
        />
      )}
      <MiBox>
        <MiTable columns={columns} rows={data} getRowActions={getRowActions} />
      </MiBox>
    </div>
  );
};
