import React from "react";
import { THEME } from "@miview/theme";
import { Typography, useTheme } from "@mui/material";
import { DefaultHome } from "@miview/assets";
import { getImageUrl } from "@miview/utils";
import { useCdn } from "@miview/hooks";
import { MiIcon } from "../MiIcon/MiIcon";
import { mdiCamera } from "@mdi/js";

export const Image = (props) => {
  const { imgSrc, onClick, toggle, label = "", style = {} } = props;
  const cdn = useCdn();
  const theme = useTheme();

  return (
    <>
      {label && (
        <Typography
          style={{ fontSize: theme.fontSize.small }}
          variant="subtitle2"
        >
          {label}
        </Typography>
      )}
      <div style={{ width: "100%", height: 220, maxHeight: 220, ...style }}>
        <img
          src={imgSrc ? getImageUrl(imgSrc, cdn) : DefaultHome}
          style={{
            ...styles.actualImgStyle,
            ...style,
            cursor: onClick ? "pointer" : "default",
          }}
          onClick={onClick}
          alt="property"
        />
        {toggle && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              padding: 5,
              borderRadius: ".3rem",
              fontSize: "1rem",
              backgroundColor: THEME.TRANSPARENT_OVERLAY_LIGHT,
              color: "white",
              cursor: "pointer",
            }}
            onClick={toggle}
          >
            <MiIcon path={mdiCamera} size={1} />
          </div>
        )}
      </div>
    </>
  );
};

const styles = {
  actualImgStyle: {
    maxHeight: "100%",
    maxWidth: "100%",
    width: "auto",
    height: "auto",
    objectFit: "cover",
    borderRadius: ".25rem",
  },
  disabled: {
    opacity: 0.2,
  },
};
