import React, { useState } from "react";
import { userService } from "@miview/api";
import { useTheme } from "@mui/material";
import { getImageBase64FromFile } from "@miview/utils";
import { MiImageFilePicker, MiImagePreview, MiModal } from "@miview/components";
import { HTTP_STATUSES } from "@miview/constants";
import { useComponentState } from "@miview/hooks";

const UploadProfilePictureModal = (props) => {
  const { userId, onSave, onClose, open } = props;

  const [image, setImage] = useState();

  const theme = useTheme();
  const stateManager = useComponentState();

  const sendUpdateUserImage = () => {
    stateManager.run(async () => {
      const response = await userService.update(userId, { image });
      if (response.status === HTTP_STATUSES.OK) {
        onSave();
      }
    });
  };

  const createImagePreview = (e) => {
    const file = e.target.files[0];
    getImageBase64FromFile(file, setImage);
  };

  const actions = [
    {
      onClick: () => {
        onClose();
        setImage("");
      },
      color: theme.palette.primary.grey,
      name: "Close",
      inverse: false,
    },
    {
      onClick: sendUpdateUserImage,
      color: theme.palette.primary.main,
      name: "Submit",
      inverse: true,
      disabled: !image || stateManager.isBusy(),
    },
  ];

  return (
    <MiModal
      title="Update Profile Picture"
      actions={actions}
      open={open}
      isCloseButton={false}
    >
      <MiImageFilePicker labelText="Image" handleChange={createImagePreview} />
      <MiImagePreview src={image} />
    </MiModal>
  );
};

export default UploadProfilePictureModal;
