export const reorderItems =
  ({ apiReorder, refreshData }) =>
  async ({ itemDragging, index, areaId, filteredItems }) => {
    try {
      if (index === 0) {
        const requestPayload = {
          id: itemDragging.itemId,
          displayOrder: null,
          ruleId: itemDragging.ruleId || undefined,
          planAreaId: areaId,
          optionsToggledOn: [],
        };
        await apiReorder(requestPayload);

        return await refreshData();
      }

      const newDisplayOrder = filteredItems[index - 1].displayOrder;

      const requestPayload = {
        id: itemDragging.itemId,
        displayOrder: newDisplayOrder,
        ruleId: itemDragging.ruleId || undefined,
        planAreaId: areaId,
        optionsToggledOn: [],
      };
      await apiReorder(requestPayload);

      await refreshData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
