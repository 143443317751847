import React, { useState, useEffect } from "react";
import { createToast } from "@miview/toast";
import {
  MiPageHeader,
  MiButton,
  MiList,
  MiLink,
  MiIcon,
  MiInputSelectBox,
  MiModal,
} from "@miview/components";
import { useTheme } from "@mui/material";
import { TOAST_TYPES, HTTP_STATUSES } from "@miview/constants";
import { planService, communityService } from "@miview/api";
import { createFilter } from "@miview/utils";
import { useRouter, useComponentState } from "@miview/hooks";
import { mdiDelete, mdiPlus } from "@mdi/js";

const PlansCollapse = (props) => {
  const { communityId, communityName } = props;

  const [communityPlans, setCommunityPlans] = useState([]);
  const [filteredCommunityPlans, setFilteredCommunityPlans] = useState([]);
  const [planId, setPlanId] = useState("");
  const [planName, setPlanName] = useState("");
  const [itemInProgress, setItemInProgress] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const router = useRouter();
  const theme = useTheme();
  const stateManager = useComponentState();

  useEffect(() => {
    getNewCommunityPlans();
  }, []);

  const getNewCommunityPlans = () => {
    stateManager.run(async () => {
      const response = await planService.getPlans({
        params: { pageSize: 100, orderBy: "name" },
      });
      if (response.status === HTTP_STATUSES.OK) {
        setFilteredCommunityPlans(
          response.data.filter((x) => x.communityId !== communityId)
        );
      }
    });
  };

  const getCommunityPlans = ({ ...params }) => {
    return stateManager.run(async () => {
      params.filters = createFilter().equals({ communityId });
      return await planService.getPlans({ ...params });
    });
  };

  const toggleModal = () => setItemInProgress(!itemInProgress);

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => toggleDelete(row),
        renderIcon: () => (
          <MiIcon path={mdiDelete} size={1} color={theme.palette.primary.red} />
        ),
      },
    ];
  };

  const addPlanToCommunity = async () => {
    if (!(await isDuplicate())) {
      const response = await communityService.addPlan({
        id: communityId,
        planId,
      });
      if (response.status === HTTP_STATUSES.OK) {
        toggleModal();
        setPlanId("");
        setRefresh(!refresh);
        getNewCommunityPlans();
      }
    } else {
      const duplicateName = filteredCommunityPlans.find(
        (elem) => elem.id === planId
      );
      createToast(
        `Plan ${duplicateName.name} already exists on ${communityName}.`,
        TOAST_TYPES.ERROR
      );
    }
  };

  const isDuplicate = async () => {
    const params = {};
    params.filters = createFilter().equals({
      communityId,
      id: planId,
    });
    const result = await planService.getPlans({ ...params });
    return result.data.length > 0;
  };

  const removePlanFromCommunity = async () => {
    const response = communityService.removePlan({ id: communityId, planId });
    if (response.status === HTTP_STATUSES.OK) {
      setRefresh(!refresh);
      setIsDeleting(false);
      setPlanId("");
      setPlanName("");
    }
  };

  const toggleDelete = (e) => {
    setIsDeleting(true);
    setPlanId(e.id);
    setPlanName(e.name);
  };

  const openPlan = (planId) => router.push(`/plans/${planId}`);

  const columns = [
    {
      field: "name",
      align: "left",
      headerName: "Plan Name",
      flex: 1,
      resizable: false,
      renderCell: (e) => (
        <MiLink
          onClick={(p) => {
            p.preventDefault();
            openPlan(e.id);
          }}
          title={e.value}
        />
      ),
    },
  ];

  const actionButton = () => {
    return (
      <MiButton
        title="Plan"
        icon={mdiPlus}
        inverse={true}
        onClick={() => setItemInProgress(true)}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <>
      <MiPageHeader
        title="Add Plans To This Community"
        color={theme.palette.primary.main}
        action={actionButton()}
        loading={stateManager.isBusy()}
      />
      <MiList
        data={communityPlans}
        setData={setCommunityPlans}
        dataProvider={getCommunityPlans}
        defaultPageSize={20}
        hideFilters={true}
        columns={columns}
        getRowActions={getRowActions}
        disableColumnMenu
        refresh={refresh}
      />
      <MiModal
        title="Add Plan to this Community"
        onClose={() => {
          setItemInProgress(false);
          setPlanId("");
        }}
        open={itemInProgress}
        actions={[
          {
            name: "Cancel",
            style: { minWidth: 90 },
            onClick: () => setItemInProgress(false),
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: addPlanToCommunity,
            style: { minWidth: 90 },
            color: theme.palette.primary.green,
            inverse: true,
            disabled: !planId,
          },
        ]}
      >
        <MiInputSelectBox
          labelText="Plan"
          id="PlanInput"
          labelField="text"
          menuItems={filteredCommunityPlans.map((i) => {
            return { value: i.id, text: i.name };
          })}
          value={planId}
          handleChange={(e) => setPlanId(e.target.value)}
        />
      </MiModal>
      <MiModal
        title="Confirm Delete"
        open={isDeleting}
        titleColor={theme.palette.primary.red}
        modalTitleStyle={{
          textAlign: "center",
          color: theme.palette.primary.red,
          fontWeight: 700,
          fontSize: theme.fontSize.xxlarge,
        }}
        onClose={() => {
          setIsDeleting(false);
          setPlanId("");
        }}
        maxWidth={"80%"}
        actions={[
          {
            name: "Cancel",
            style: { minWidth: 90 },
            onClick: () => {
              setIsDeleting(false);
              setPlanId("");
            },
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Confirm",
            onClick: () => removePlanFromCommunity(),
            style: { minWidth: 90 },
            color: theme.palette.primary.red,
            inverse: true,
          },
        ]}
      >
        <p>{`Remove ${planName} from ${communityName} ?`}</p>
      </MiModal>
    </>
  );
};

export default PlansCollapse;
