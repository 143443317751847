import React, { useState, useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiModal } from "@miview/components";
import CreateGeneral from "./SupplierCreate/CreateGeneral";
import CreatePayment from "./SupplierCreate/CreatePayment";
import en from "../../../locale/en.json";
import { useEdit, useComponentState } from "@miview/hooks";
import { HTTP_STATUSES, ACCOUNT_TYPES } from "@miview/constants";
import { isPhoneNumber, isValidEmail, createFilter } from "@miview/utils";
import { accountService } from "@miview/api";

const GENERAL = "general";
const PAYMENTINFO = "paymentinfo";

const REQUIRED_REMITTANCE_FIELDS = [
  "billingHouseNumber",
  "billingCity",
  "billingState",
  "billingZipCode",
];

const REQUIRED_FIELDS = [
  "name",
  "status",
  "alternateIdNumber",
  "phone",
  "addressHouseNumber",
  "addressCity",
  "addressState",
  "addressZipCode",
  "legalName",
  "paymentTermId",
  "code",
];

function AddNewSupplierModal({ open, handleClose }) {
  const [currentTab, setCurrentTab] = useState(GENERAL);
  const [isValid, setIsValid] = useState(true);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [supplierType, setSupplierType] = useState({});
  const [duplicateAccountsByName, setDuplicateAccountsByName] = useState(false);
  const [duplicateAccountsByLegalName, setDuplicateAccountsByLegalName] =
    useState(false);

  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    errorMessageStyle: {
      textAlign: "right",
      paddingRight: 30,
      color: theme.palette.secondary.red,
    },
  }));
  const { classes } = useStyles();

  const edit = useEdit();
  const stateManager = useComponentState();

  useEffect(() => {
    fetchData();
    getSupplierAccountType();
  }, []);

  const fetchData = async () => {
    stateManager.run(async () => {
      const response = await accountService.paymentTerms.getAll();
      if (response.status === HTTP_STATUSES.OK) {
        setPaymentTerms(response.data);
      }
    });
  };

  const getSupplierAccountType = async () => {
    stateManager.run(async () => {
      const f = createFilter();
      f.equals({ name: ACCOUNT_TYPES.SUPPLIER });
      const response = await accountService.accountTypes.getAll({
        filters: f.getFilter(),
      });
      if (response.status === HTTP_STATUSES.OK && response.data.length) {
        setSupplierType(response.data[0]);
      }
    });
  };

  const handleChangeTab = (value) => {
    setCurrentTab(value);
  };

  const handleSave = async () => {
    stateManager.run(async () => {
      if (
        !edit.allFilled(...REQUIRED_FIELDS) ||
        (!edit.getValue("useCorporateAddress") &&
          !edit.allFilled(...REQUIRED_REMITTANCE_FIELDS)) ||
        notValidateNumericInputs() ||
        duplicateAccountsByName ||
        duplicateAccountsByLegalName
      ) {
        setIsValid(false);
        return;
      }
      setIsValid(true);
      const request = { ...edit.edits };
      request.accountTypeId = supplierType.id;
      if (edit.getValue("useCorporateAddress")) {
        request.billingHouseNumber = edit.getValue("addressHouseNumber");
        request.billingStreetNumber = edit.getValue("addressStreetNumber");
        request.billingCity = edit.getValue("addressCity");
        request.billingState = edit.getValue("addressState");
        request.billingZipCode = edit.getValue("addressZipCode");
      }
      const response = await accountService.accounts.create({
        data: request,
      });
      if (response.status === HTTP_STATUSES.CREATED) {
        edit.reset();
        handleClose();
        handleChangeTab(GENERAL);
      }
    });
  };

  const notValidateNumericInputs = () => {
    return (
      (edit.getValue("email") && !isValidEmail(edit.getValue("email"))) ||
      (edit.getValue("phone") && !isPhoneNumber(edit.getValue("phone"))) ||
      (edit.getValue("fax") && !isPhoneNumber(edit.getValue("fax"))) ||
      (edit.getValue("mobile") && !isPhoneNumber(edit.getValue("mobile")))
    );
  };

  const actions = {
    [GENERAL]: [
      {
        onClick: handleClose,
        color: theme.palette.primary.grey,
        name: "Cancel",
      },
      {
        onClick: () => handleChangeTab(PAYMENTINFO),
        color: theme.palette.primary.main,
        name: "Next",
        inverse: true,
      },
    ],
    [PAYMENTINFO]: [
      {
        onClick: handleClose,
        color: theme.palette.primary.grey,
        name: "Cancel",
      },
      {
        onClick: () => handleChangeTab(GENERAL),
        color: theme.palette.primary.main,
        name: "Previous",
        inverse: true,
      },
      {
        onClick: handleSave,
        color: theme.palette.primary.main,
        name: "Save",
        inverse: true,
      },
    ],
  };

  const switchContent = () => {
    switch (currentTab) {
      case GENERAL:
        return (
          <CreateGeneral
            edit={edit}
            isValid={isValid}
            setDuplicateAccounts={setDuplicateAccountsByName}
          />
        );
      case PAYMENTINFO:
        return (
          <CreatePayment
            edit={edit}
            isValid={isValid}
            paymentTerms={paymentTerms}
            setDuplicateAccounts={setDuplicateAccountsByLegalName}
          />
        );
      default:
        return null;
    }
  };

  const renderBody = () => {
    return (
      <Grid>
        <Grid container>
          <Grid item xs={12}>
            {switchContent()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderErrorCount = () => {
    if (isValid) {
      return null;
    }
    return (
      <div className={classes.errorMessageStyle}>
        There are errors in the form
      </div>
    );
  };

  return (
    <>
      {stateManager.statusTag("createSupplierModal")}
      <MiModal
        title={en.createNewSupplier}
        open={open}
        onClose={handleClose}
        maxWidth={"lg"}
        loading={stateManager.isBusy()}
        actions={actions[currentTab]}
      >
        {renderBody()}
        {renderErrorCount()}
      </MiModal>
    </>
  );
}

export default React.memo(AddNewSupplierModal);
