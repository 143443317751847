import React from "react";
import { THEME } from "./theme";
import { Link } from "react-router-dom";
import { MiLoader } from "@miview/components";
import { URLS } from "@miview/constants";

const MiHeader = (props) => {
  const {
    color,
    inverse,
    backAction,
    icon,
    title,
    fieldSet,
    children,
    style,
    loading,
    rightStyles,
    leftStyles,
    outerContainerStyles,
    ...rest
  } = props;
  const tooltip = props.tooltip || title;
  const isFA = icon?.startsWith("fa-");

  return (
    <div
      style={{
        position: "sticky",
        zIndex: 1200,
        top: Object.values(URLS).includes(global.baseUrl) ? "85px" : "61px",
        ...outerContainerStyles,
      }}
    >
      {loading && (
        <div style={{ height: 0 }}>
          <MiLoader type="linear" style={{ height: 3 }} />
        </div>
      )}
      <div
        className="miheader-container"
        style={{
          color: inverse ? "white" : color,
          backgroundColor: inverse ? color : "white",
          ...styles.container,
          ...style,
        }}
        {...rest}
        title={tooltip ? tooltip : title}
      >
        <div
          className="miheader-left"
          style={{ ...styles.left, ...leftStyles }}
        >
          <div
            className="miheader-icon"
            onClick={backAction}
            style={{
              ...THEME.TEXT_HEADER_LARGE,
              ...styles.icon,
              cursor: backAction === undefined ? "default" : "pointer",
            }}
          >
            <i
              className={isFA ? "fa " + icon : "material-icons"}
              style={{
                color: inverse ? "white" : color,
                fontSize: isFA ? "1.5rem" : "2.1rem",
                marginTop: isFA ? 0 : 10,
                marginRight: isFA ? 3 : 0,
              }}
            >
              {isFA ? "" : icon}
            </i>
          </div>
          <div style={{ ...styles.main }}>
            <div
              className="miheader-top"
              style={{ ...THEME.TEXT_HEADER_LARGE }}
            >
              <div className="miheader-title" style={styles.title}>
                {title}
              </div>
            </div>
            {fieldSet && (
              <div className="miheader-bottom" style={{ ...styles.bottom }}>
                {fieldSet.map((field, index) => {
                  const elementId = field.label
                    ? field.label.replace(/[^A-z]+/g, "") + "FieldValue"
                    : "";
                  return (
                    <div
                      key={index}
                      title={field.label}
                      id={elementId}
                      style={{
                        ...styles.dataField,
                        borderRightColor: inverse ? "white" : THEME.GREY_DARK,
                        borderRightWidth: index + 1 === fieldSet.length ? 0 : 1,
                      }}
                    >
                      {field.label ? field.label + ": " : ""}

                      {field.to ? (
                        <Link
                          to={field.to}
                          style={{
                            paddingLeft: 2,
                            color: THEME.BLUE_PRIMARY,
                          }}
                        >
                          {field.value}
                        </Link>
                      ) : (
                        field.value
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {children && (
          <div
            className="miheader-right"
            style={{
              ...styles.right,
              ...rightStyles,
              backgroundColor: THEME.TRANSPARENT_OVERLAY_WHITE,
            }}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default MiHeader;

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: 15,
  },
  top: {
    fontSize: "2em",
  },
  main: {
    flex: 1,
  },
  title: { justifyContent: "center" },
  icon: {
    flex: 0,
    paddingRight: 5,
    height: "100%",
    cursor: "pointer",
    userSelect: "none",
  },
  bottom: {
    marginTop: 12,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  dataField: {
    marginRight: 20,
    borderRightWidth: "1px",
    borderRightStyle: "solid",
    paddingRight: 20,
  },
  right: {
    padding: "0 30px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  left: {
    flex: 1,
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
  },
};
