export const fontSize = {
  headerLarge: "2.125rem", //34
  header: "1.625rem", //26,
  headerSmall: "1.5rem", //24,
  xxlarge: "1.25rem", //20,
  xlarge: "1.125rem", //18,
  large: "1rem", //16,
  medium: "0.875rem", //14,
  small: "0.75rem", //12,
  xsmall: "0.625rem", //10,
  xxsmall: "0.5rem", //8,
};

export const fontWeight = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900,
};
