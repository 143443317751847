export const THEME = {
  TRANSPARENT_OVERLAY: "rgba(0,0,0,0.5)",
  TRANSPARENT_OVERLAY_LIGHT: "rgba(0,0,0,0.2)",
  TRANSPARENT_OVERLAY_WHITE: "rgba(255,255,255,0.2)",

  GREY_DARK: "#3e3e3c",
  GREY_MEDIUM: "#969492",
  GREY_MEDIUM_LIGHT: "rgb(167,167,167)",
  GREY_MEDIUM_ALT: "#5C5C5C",
  GREY_LIGHT: "#DDDDDD",
  GREY_LIGHT_ALT: "#999999",
  GREY_LIGHT_BACKGROUND: "#F4F4F4",

  PURPLE_PRIMARY: "#B620E0",
  PURPLE_SECONDARY: "#A88BFF",
  PURPLE_BACKGROUND: "#8B6FDD",
  PURPLE_LIGHT: "#BFA7E1",
  PURPLE_DARK: "#3A048A",

  BLUE_PRIMARY: "#3EA9E2",
  BLUE_SECONDARY: "#087DE4",
  BLUE_ACCENT: "#63BAEB",
  BLUE_LIGHT: "#BDE4FF",
  BLUE_DARK: "#163D85",
  BLUE_DARK_ALT: "#2A303E",
  BLUE_DARK_ALT_2: "#1589EE",
  BLUE_TURQUOISE: "#00AFB9",

  GREEN_PRIMARY: "#3CBA6A",
  GREEN_ACCENT: "#53CC7F",
  GREEN_LIGHT: "#C5FECE",
  GREEN_ACCENT_ALT: "#30C6B4",
  GREEN_DARK: "#027E46",

  ORANGE_PRIMARY: "#F69100",
  ORANGE_SECONDARY: "#FFA500",
  ORANGE_ACCENT: "#FCC05B",
  YELLOW_PRIMARY: "#FFF03F",

  TANGERINE: "#F06147",
  TANGERINE_ACCENT: "#FF735A",

  RED_PRIMARY: "#B51B1B",
  RED_DARK: "#870500",
  RED_ACCENT: "#D4504C",

  ACTION_PRIMARY: "#3CBA6A", //green
  ACTION_SECONDARY: "#3EA9E2", //blue
  ACTION_NEGATIVE: "#B51B1B", //red
  ACTION_SUCCESS: "#3CBA6A",
  ACTION_ORANGE: "#F6A600",
  ACTION_PURPLE: "#B620E0",

  HEADER_PRIMARY: "#3EA9E2",
  HEADER_SCONDARY: "#DDDDDD",

  TEXT_BODY_REGULAR: {
    fontSize: 12,
    lineHeight: 1.2,
    // paddingTop: 12 - (12 * 1.2),
    fontWeight: "500",
  },

  TEXT_SECTION_TITLE: {
    fontSize: 15,
    lineHeight: 1.1,
    // paddingTop: 15 - (15 * 1.1),
    fontWeight: "800",
  },

  TEXT_SECTION_TITLE_MED: {
    fontSize: 15,
    lineHeight: 1.1,
    // paddingTop: 15 - (15 * 1.1),
    fontWeight: "500",
  },

  TEXT_SECTION_TITLE_THIN: {
    fontSize: 15,
    lineHeight: 1.2,
    // paddingTop: 15 - (15 * 1.2),
    fontWeight: "300",
  },

  TEXT_HEADER: {
    fontSize: 24,
    lineHeight: 1.1,
    // paddingTop: 24 - (24 * 1.1),
    fontWeight: "800",
  },

  TEXT_HEADER_LARGE: {
    fontSize: 32,
    lineHeight: 1.2,
    // paddingTop: 32 - (32 * 1.1),
    fontWeight: "300",
  },

  MODAL_HEADER: {
    fontSize: 24,
    lineHeight: 1.1,
    fontWeight: "500",
  },

  BUTTON_TEXT: {
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: "300",
  },
  BOX_MAIN: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    borderColor: "#DDD",
    borderRadius: "0.25rem",
    borderWidth: 1,
    backgroundColor: "white",
    borderStyle: "solid",
    padding: "1rem",
  },

  PADDING_SMALL: {
    padding: ".5rem",
  },

  PADDING_MEDIUM: {
    padding: "1rem",
  },

  PADDING_LARGE: {
    padding: "2rem",
  },
  CARD: {
    padding: "1rem",
    borderWidth: "1px",
    borderColor: "#ddd",
    borderStyle: "solid",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
  },

  STEPS_BAR: {
    0: "#570083",
    1: "#1589EE",
    2: "#f49116",
    3: "#499166",
    review: "#4BCA81",
  },
};
