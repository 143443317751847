import React from "react";
import { useTheme } from "@mui/material/styles";
import { MiModal, MiInputSelectBox, MiCheckbox } from "@miview/components";
import { en } from "@miview/constants";

export const TransactionPaidModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    selectedPayPeriod,
    setSelectedPayPeriod,
    shouldCreateImport,
    setShouldCreateImport,
    payPeriods,
  } = props;

  const theme = useTheme();

  const actions = [
    {
      onClick: onClose,
      color: theme.palette.primary.grey,
      name: en.close,
      inverse: false,
    },
    {
      onClick: onSubmit,
      color: theme.palette.primary.green,
      name: en.confirm,
      inverse: true,
      disabled: !selectedPayPeriod,
    },
  ];

  return (
    <MiModal title={"Select Pay Period"} open={open} actions={actions}>
      <MiInputSelectBox
        menuItems={payPeriods}
        styles={{ margin: 16, width: "500px" }}
        labelText="Pay Periods"
        value={selectedPayPeriod}
        handleChange={(e) => setSelectedPayPeriod(e.target.value)}
        required
        disableNone
      />
      <MiCheckbox
        checked={shouldCreateImport}
        onChange={() => setShouldCreateImport(!shouldCreateImport)}
        label={"Export for QuickBooks Import"}
      />
    </MiModal>
  );
};
