import React from "react";
import PropTypes from "prop-types";
import { THEME } from "@miview/theme";
import { MiDetailFields } from "@miview/components";

export const MiAddArea = ({
  floors,
  numberOfFloors,
  isTemplate,
  propertySections,
  area,
  edit,
  sectionDisabled,
}) => {
  const handleOnChangePropertySection = (e) => {
    const section = propertySections.filter((x) => x.value == Number(e));
    edit.update({
      propertySectionId: e,
      isGroundFloor: section[0]?.isGroundFloor,
    });
  };

  const onRequiredCheck = () => {
    edit.update({
      isRequired: !edit.getValue("isRequired"),
    });
  };

  const detailFields = [
    {
      label: "Area",
      fieldType: "select",
      value: edit.getValue("propertySectionId"),
      options: propertySections,
      required: true,
      setValue: handleOnChangePropertySection,
      width: "50%",
      readOnly: sectionDisabled,
    },
    {
      id: `planAreaName-${area?.id || "new"}`,
      name: "name",
      label: "Plan Area Name",
      value: edit.getValue("name"),
      setValue: (e) => edit.update({ name: e }),
      width: "50%",
      required: true,
    },
    {
      label: "Floor",
      name: "floorId",
      fieldType: "select",
      value: edit.getValue("floorId"),
      options: floors,
      hide: !(numberOfFloors > 1 && !edit.isGroundFloor),
      setValue: (e) => edit.update({ floorId: e.target.value }),
      width: "50%",
    },
    {
      label: "Required",
      fieldType: "checkbox",
      value: edit.getValue("isRequired"),
      setValue: onRequiredCheck,
      width: "50%",
      hide: !isTemplate,
    },
  ];

  return (
    <div style={styles.planAreaDetailsEdit}>
      <MiDetailFields detailFields={detailFields} />
    </div>
  );
};

MiAddArea.propTypes = {
  floors: PropTypes.array,
  numberOfFloors: PropTypes.number,
  propertySections: PropTypes.array,
  category: PropTypes.object,
  isEditing: PropTypes.bool,
  area: PropTypes.object,
  setMaterialsModalOpen: PropTypes.func,
  planAreasHook: PropTypes.object,
  saveAreaIsDisabled: PropTypes.func,
  materialCategories: PropTypes.array,
  materialBrands: PropTypes.array,
};

const styles = {
  planAreaDetailsEdit: {
    borderRadius: 2,
    width: "100%",
    padding: "14px 0px 14px 14px",
  },
  nextButton: {
    margin: "0 0 0 auto",
    width: 80,
    backgroundColor: THEME.BLUE_SECONDARY,
    border: "none",
    color: "white",
  },
};
