import React from "react";
import { materialCategoryService } from "@miview/api";
import { MiModal, MiDetailFields } from "@miview/components";
import { useTheme } from "@mui/material";
import { useEdit } from "@miview/hooks";
import { HTTP_STATUSES } from "@miview/constants";

const EditMaterialCategoryModal = ({ onClose, selection, open }) => {
  const theme = useTheme();
  const edit = useEdit(selection);

  const onSave = async () => {
    const categoryObj = {
      ...edit.edits,
      isRequiredDefault: false,
    };

    const response = await materialCategoryService.update(
      edit.getValue("id"),
      categoryObj
    );

    if (response.status === HTTP_STATUSES.OK) {
      onModalClose();
    }
  };

  const onModalClose = () => {
    edit.reset();
    onClose();
  };

  const handleEditMaterialCategoryDisplayOrderChange = (e) => {
    const regex = /^\d*$/;
    if (regex.test(e)) {
      edit.update({ displayOrder: e });
    }
  };

  const editMaterialFields = [
    {
      label: "Name",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "100%",
    },
    {
      label: "Display Order",
      value: edit.getValue("displayOrder"),
      setValue: handleEditMaterialCategoryDisplayOrderChange,
      width: "100%",
    },
  ];

  return (
    <MiModal
      title="Edit Material Category"
      open={open}
      onClose={onModalClose}
      actions={[
        {
          name: "Cancel",
          style: { minWidth: 90 },
          onClick: onModalClose,
          color: theme.palette.medium.grey,
          inverse: false,
        },
        {
          name: "Save",
          onClick: onSave,
          style: { minWidth: 90 },
          color: theme.palette.primary.green,
          inverse: true,
          disabled: !edit.allFilled("name"),
        },
      ]}
    >
      <MiDetailFields detailFields={editMaterialFields} />
    </MiModal>
  );
};

export default EditMaterialCategoryModal;
