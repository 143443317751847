import React from "react";
import { makeStyles } from "tss-react/mui";
import { Drawer, Grid } from "@mui/material";
import PropTypes from "prop-types";

const drawerWidth = 500;

const useStyles = makeStyles()((_, { width, variant }) => ({
  drawer: {
    width: "auto",
    flexShrink: 0,
    flexDirection: "column",
    flex: 1,
    height: "100%",
    justifyContent: "space-between",
  },
  drawerPaper: {
    width: width || drawerWidth,
  },
  paper: {
    top: variant === "persistent" || variant === "permanent" ? "60px" : 0,
  },
}));

export const MiDrawer = (props) => {
  const { anchor, children, open, header, width, actions, variant, ...rest } =
    props;
  const { classes, cx } = useStyles({ width, variant });

  return (
    <Drawer
      {...rest}
      anchor={anchor}
      open={open}
      variant={variant}
      classes={{ paper: classes.paper }}
    >
      <Grid
        className={cx(classes.drawer, {
          [classes.drawerPaper]: open,
        })}
      >
        {header}
        {children}
        {actions}
      </Grid>
    </Drawer>
  );
};

MiDrawer.defaultProps = {
  anchor: "left",
};

MiDrawer.propTypes = {
  /**
   * Specifies which side the drawer must open
   */
  anchor: PropTypes.oneOf(["left", "right", "top", "bottom"]),
  /**
   * Specifies how the drawer opens
   */
  variant: PropTypes.oneOf(["permanent", "persistent", "temporary"]),
  /**
   * To control the open/close state of the drawer
   */
  open: PropTypes.bool,
  /**
   * Children that needs to render inside the open drawer
   */
  children: PropTypes.element,
};
