import React from "react";
import { IconButton, useTheme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { SideToggleButton, AccountNumber } from "../../../components";
import { MiPlaceHolderList, MiTable, MiIcon } from "@miview/components";
import { TemplateStatus } from "../../../components/Status";
import { STATUS } from "../../../constants";
import en from "../../../locale/en.json";
import { getUniqueItems } from "../../../utils";
import { mdiFolderOpenOutline } from "@mdi/js";

const useStyles = makeStyles()((theme) => ({
  circleStyle: {
    width: 175,
    height: 175,
    backgroundColor: theme.palette.lightAccent.grey,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
  },
  addButtonStyle: {
    padding: 5,
    marginLeft: "10px",
    color: theme.palette.medium.grey,
  },
}));

const NewHomesTable = ({
  requisitions = [],
  handleItemAddCount,
  sidebarVisiblity,
  onShowDrawerFunction,
  handleSelectModal,
  selectedItems = [],
  setSelectedItems,
  totalcount,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const quotesMultiSelect = () => {
    const temp = requisitions?.filter((item) => item.status === "Open");
    setSelectedItems(temp);
  };

  const selectQuotesFunction = (ID) => {
    if (ID) {
      const temp = requisitions?.filter((item) => item.id === ID);
      setSelectedItems(getUniqueItems([...selectedItems].concat(temp)));
    }
  };

  const columns = [
    {
      field: "AddCart",
      headerName: (
        <IconButton className={classes.addButtonStyle} size="large">
          <PlaylistAddIcon />
        </IconButton>
      ),
      disableColumnMenu: true,
      renderCell: (params) => {
        return params?.row?.status === "Open" ? (
          <IconButton
            className={classes.addButtonStyle}
            onClick={() => selectQuotesFunction(params?.row?.id)}
            size="large">
            <PlaylistAddIcon />
          </IconButton>
        ) : (
          ""
        );
      },
    },

    {
      field: "number",
      headerName: "Request #",
      minWidth: 190,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <AccountNumber
            OnClick={() => onShowDrawerFunction(params?.row)}
            value={params?.value?.toString()}
          />
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "scheduledDate",
      headerName: "Due Date",
      minWidth: 190,
    },
    {
      field: "requestedBy",
      headerName: "Requestor",
      minWidth: 190,
    },
    {
      field: "requestedDeliveryDate",
      headerName: "Delivery",
      minWidth: 190,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 180,
      renderCell: (params) => {
        return <TemplateStatus value={params.row?.status} />;
      },
    },
  ];

  const renderSuppliers = () => {
    if (requisitions) {
      <MiPlaceHolderList
        renderIcon={
          <div className={classes.circleStyle}>
            <MiIcon
              path={mdiFolderOpenOutline}
              size={4}
              color={theme.palette.lightShade.bluegrey}
            />
          </div>
        }
        text={en.ItLooksLikeThereArentAnyRequests}
        helperText={en.TipStartAByCreatingATemplateOrABlankRequest}
        actions={[
          {
            title: en.createRequisition,
            inverse: true,
            color: theme.palette.primary.teal,
            onClick: handleSelectModal,
          },
        ]}
      />;
    }
    return (
      <MiTable
        hideFooter={false}
        columns={columns}
        rows={requisitions}
        disableColumnMenu={true}
        pagination={true}
        rowsPerPageOptions={[25, 50, 100]}
        rowsCount={totalcount}
        getRowClassName={(params) =>
          params.row.statusId === STATUS.inactive ? "inActiveRow" : ""
        }
        onColumnHeaderClick={(params) => {
          if (params?.field === "AddCart") {
            quotesMultiSelect();
          }
        }}
        autoHeight={true}
        sx={{
          "& .MuiDataGrid-footerContainer p": {
            marginBottom: "0px",
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    );
  };

  return (
    <>
      {renderSuppliers()}
      {!sidebarVisiblity && (
        <SideToggleButton
          onClick={handleItemAddCount}
          count={selectedItems.length}
        />
      )}
    </>
  );
};

export default NewHomesTable;
