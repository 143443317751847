import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const timezoneService = {
  getAll: async () => {
    const options = await apiOptions({
      url: `${API_V3}/timezones`,
      method: "get",
    });

    return apiRequest(options);
  },
};
