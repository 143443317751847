import React, { useState, useEffect } from "react";
import {
  MiInputTextbox,
  MiLink,
  MiModal,
  MiModalConfirm,
  MiList,
  MiButton,
  MiPageHeader,
  MiIcon,
} from "@miview/components";
import { useTheme } from "@mui/material";
import { bonusSettingsService } from "@miview/api";
import { createToast } from "@miview/toast";
import { TOAST_TYPES, HTTP_STATUSES, PAGINATION } from "@miview/constants";
import { simpleSort } from "@miview/utils";
import { useComponentState, useRouter } from "@miview/hooks";
import { mdiPlus, mdiDelete, mdiCashMultiple, mdiCheck } from "@mdi/js";

export const BonusRulesTab = (props) => {
  const { tabs } = props;

  const [bonusSettingsAllData, setBonusSettingsAllData] = useState([]);
  const [bonusSettings, setBonusSettings] = useState([]);
  const [deleteSelection, setDeleteSelection] = useState();
  const [isRulesDeleteModalOpen, setIsRulesDeleteModalOpen] = useState(false);
  const [isPayRuleCreateModalOpen, setIsPayRuleCreateModalOpen] =
    useState(false);
  const [description, setDescription] = useState("");

  const stateManager = useComponentState();
  const router = useRouter();
  const theme = useTheme();

  useEffect(() => {
    getBonusSettings();
  }, []);

  const getBonusSettings = () => {
    stateManager.run(async () => {
      const response = await bonusSettingsService.getBuilderSettings();
      if (response.status === HTTP_STATUSES.OK) {
        setBonusSettingsAllData(response.data);
        if (response.data.PayByWalkType) {
          setBonusSettings(
            response.data.PayByWalkType.map((item) => {
              return { ...item, id: item.description };
            }).sort(
              (a, b) =>
                simpleSort(b.isEnabled, a.isEnabled) ||
                simpleSort(a.description, b.description)
            )
          );
        }
      }
    });
  };

  const deleteRule = () => {
    stateManager.run(async () => {
      const filteredPayByWalkType = bonusSettingsAllData.PayByWalkType.filter(
        (obj) => obj.description !== deleteSelection.description
      );
      const finalObj = {
        ...bonusSettingsAllData,
        PayByWalkType: filteredPayByWalkType,
      };
      const stringify = JSON.stringify(finalObj);
      const addQuotes = `'${stringify}'`;
      const response = await bonusSettingsService.putBuilderSettings(addQuotes);
      if (response.status === HTTP_STATUSES.OK) {
        createToast("Rule Deleted Successfully", TOAST_TYPES.SUCCESS);
        getBonusSettings();
        setIsRulesDeleteModalOpen(false);
      }
    });
  };

  const checkUniqueDescription = () => {
    return bonusSettings.some((ele) => description === ele.description);
  };

  const addDescription = () => {
    const regex = /^\s{0,50}$/;
    if (regex.test(description)) {
      createToast("Description cannot be blank", TOAST_TYPES.ERROR);
    } else if (!checkUniqueDescription()) {
      router.push(`/paysettings/${description.trim()}`);
    } else {
      createToast("Duplicate description!", TOAST_TYPES.ERROR);
    }
  };

  const handleDescriptionChange = (event) => {
    const regex = /^[\w\s-]{0,50}$/;
    if (regex.test(event.target.value)) {
      setDescription(event.target.value);
    }
  };

  const closeCreateModal = () => {
    setIsPayRuleCreateModalOpen(false);
    setDescription("");
  };

  const actions = [
    {
      onClick: closeCreateModal,
      color: theme.palette.primary.grey,
      name: "Cancel",
      inverse: false,
    },
    {
      onClick: addDescription,
      color: theme.palette.primary.main,
      name: "Submit",
      inverse: true,
      disabled: !description,
    },
  ];

  const openRulesDeleteModal = (obj) => {
    setDeleteSelection(obj);
    setIsRulesDeleteModalOpen(true);
  };

  const closeRulesdeleteModal = () => {
    setIsRulesDeleteModalOpen(false);
    setDeleteSelection({});
  };

  const columns = [
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderCell: (p) => {
        return (
          <MiLink to={`/paysettings/${p.value}`} title={p.row.description} />
        );
      },
    },
    {
      field: "payActivityName",
      headerName: "Pay Activity Type",
      flex: 1,
    },
    {
      field: "titleName",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "isEnabled",
      headerName: "Enabled",
      renderCell: (c) => c.value && <MiIcon path={mdiCheck} />,
    },
  ];

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => openRulesDeleteModal(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
    ];
  };

  const headerContent = () => (
    <MiButton
      title="Pay Rule"
      icon={mdiPlus}
      inverse
      onClick={() => setIsPayRuleCreateModalOpen(true)}
      color={theme.palette.primary.main}
    />
  );

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiCashMultiple}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <>
      <MiPageHeader
        title="Pay Settings"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={headerContent()}
        loading={stateManager.isBusy()}
      />
      <MiList
        data={bonusSettings}
        columns={columns}
        tabs={tabs}
        defaultPageSize={20}
        getRowId={(e) => e.description}
        searchColumns={["description", "payActivityName", "titleName"]}
        paginationMode={PAGINATION.CLIENT}
        getRowActions={getRowActions}
      />
      <MiModal
        title="Add A New Pay Rule"
        open={isPayRuleCreateModalOpen}
        onClose={closeCreateModal}
        actions={actions}
      >
        <MiInputTextbox
          labelText="Description"
          styles={{ margin: 16 }}
          labelTextColor={theme.palette.secondary.grey}
          handleChange={handleDescriptionChange}
          value={description}
          required
        />
      </MiModal>
      <MiModalConfirm
        title="Confirm Delete"
        open={isRulesDeleteModalOpen}
        description={`Delete the following Rule: ${deleteSelection?.description}?`}
        handlePositive={deleteRule}
        handleNegative={closeRulesdeleteModal}
      />
    </>
  );
};
