import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/AddCircle";
import { useComponentState } from "@miview/hooks";
import { communityService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { MiInputTextbox, MiButton, MiModal } from "@miview/components";
import { CreateCommunityModal } from "../../Locations/Communities/CreateCommunityModal";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const NewCommunityModal = (props) => {
  const { closeNewCommunityModal, handleOnSave, isNewCommunityModalOpen } =
    props;

  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [communityList, setCommunityList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredCommunities, setFilteredCommunities] = useState([]);
  const [createCommunityModal, setCreateCommunityModal] = useState(false);

  const { classes } = useStyles();
  const stateManager = useComponentState();
  const theme = useTheme();

  useEffect(() => {
    getCommunities();
  }, []);

  useEffect(() => {
    setSelectedCommunity(null);
    if (search) {
      const list = communityList.filter((c) => {
        return c.name.toLowerCase().includes(search.toLowerCase());
      });
      return setFilteredCommunities(list);
    }
    setFilteredCommunities([]);
  }, [search]);

  const getCommunities = () => {
    stateManager.run(async () => {
      const response = await communityService.getAll({
        params: { pageSize: 9999 },
      });
      if (response.status === HTTP_STATUSES.OK) {
        setCommunityList(response.data);
      }
    });
  };

  const renderCommunitySelectList = () => {
    return filteredCommunities.map((c) => {
      const selected = c.id === selectedCommunity?.id;
      return (
        <div
          key={c.id}
          onClick={() => setSelectedCommunity(c)}
          className={classes.communitySelectList}
          style={{
            border: `1px solid ${
              selected ? theme.palette.primary.main : theme.palette.light.grey
            }`,
            backgroundColor: selected
              ? theme.palette.light.grey
              : "transparent",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <p className={classes.communityName}>{c.name}</p>
            <p>{c.cityName ? `${c.cityName}, ${c.stateNameShort}` : ""}</p>
          </div>
          {!selected && <AddIcon className={classes.addIcon} />}
        </div>
      );
    });
  };

  const renderSelectCommunity = () => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <MiInputTextbox
            placeholder="Select Community"
            styles={{ width: "100%" }}
            value={search}
            required
            name="search"
            handleChange={(e) => setSearch(e.target.value)}
          />
          <div style={{ maxHeight: 300, overflowY: "auto" }}>
            {renderCommunitySelectList()}
          </div>
        </div>
        <p style={{ margin: "4px 16px", fontSize: 14 }}>or</p>
        <MiButton
          title="Create New"
          onClick={() => setCreateCommunityModal(true)}
          disabled={false}
          color={theme.palette.primary.main}
          inverse
        />
      </div>
    );
  };

  const handleCloseCreateModal = () => {
    closeNewCommunityModal();
    setSearch("");
  };

  const closeCreateModal = (success, newCommunityId) => {
    setCreateCommunityModal(false);
    if (success) {
      handleOnSave(newCommunityId);
      handleCloseCreateModal();
    }
  };

  return (
    <>
      {stateManager.statusTag("builderCommunityModalStatus")}
      <CreateCommunityModal
        isVisible={createCommunityModal}
        close={closeCreateModal}
      />
      <MiModal
        title="Select or Create Community"
        color={theme.palette.primary.main}
        open={isNewCommunityModalOpen}
        onClose={handleCloseCreateModal}
        actions={[
          {
            name: "Cancel",
            onClick: handleCloseCreateModal,
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: () => handleOnSave(selectedCommunity?.id),
            color: theme.palette.primary.main,
            inverse: true,
            disabled: !selectedCommunity,
          },
        ]}
      >
        {renderSelectCommunity()}
      </MiModal>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    boxShadow: "none",
    "&::before": {
      backgroundColor: "transparent",
    },
  },
  communitySelectList: {
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: 8,
    margin: 10,
  },
  addIcon: {
    height: 30,
    width: 30,
    color: theme.palette.primary.green,
  },
  communityName: { color: theme.palette.primary.main, fontSize: 14 },
}));

export default NewCommunityModal;
