import React, { useEffect, useState, useMemo } from "react";
import { Grid, useTheme, Typography, Divider, IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  MiChip,
  MiPageHeader,
  MiTab,
  MiDrawer,
  MiLoader,
  MiButton,
  MiSnackbar,
  MiModalConfirm,
  MiInputTextbox,
  MiIcon,
} from "@miview/components";
import { purchaseOrderService } from "@miview/api";
import {
  mdiCardTextOutline,
  mdiTrayArrowDown,
  mdiFileSearch,
  mdiClose,
  mdiCancel,
} from "@mdi/js";
import {
  en,
  HTTP_STATUSES,
  TAX_RATE,
  TOAST_TYPES,
  ORDER_STATUS,
} from "@miview/constants";
import { useEdit } from "@miview/hooks";
import { PurchasingOrderStatus } from "../../components/Status";
import PurchaseOrderForm from "../../components/PurchaseOrderForm";
import PurchaseOrderTable from "../../components/PurchaseOrderTable";
import Receiving from "./Receiving";
import SendEmail from "./SentEmail";
import { createToast } from "@miview/toast";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: "10px 20px 0px 20px",
  },
  iconStyle: {
    display: "flex",
    alignItems: "center",
  },
  headerButtonStyle: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  iconCircle: {
    width: 50,
    height: 50,
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: 10,
  },
  pageHeaderDetails: {
    borderRadius: 3,
    height: 20,
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.xsmall,
    color: theme.palette.secondary.bluegrey,
    backgroundColor: theme.palette.light.main,
  },
  headerStyle: {
    marginBottom: -10,
  },
  form: {
    marginTop: 10,
  },
  table: {
    marginTop: 10,
  },
  iconAlign: {
    paddingRight: 20,
  },
  footerStyle: {
    paddingLeft: 20,
    padding: 3,
    width: "100%",
    textAlign: "right",
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.medium,
    color: theme.palette.secondary.bluegrey,
  },
  amountGrid: {
    paddingLeft: 20,
    padding: 3,
    width: "100%",
    textAlign: "right",
    fontSize: theme.fontSize.medium,
    color: theme.palette.dark.bluegrey,
    fontWeight: theme.fontWeight.regular,
  },
  totalStyle: {
    paddingLeft: 20,
    padding: 3,
    width: "100%",
    textAlign: "right",
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.xlarge,
    color: theme.palette.secondary.bluegrey,
  },
  totalAmount: {
    paddingLeft: 20,
    padding: 3,
    width: "100%",
    textAlign: "right",
    fontSize: theme.fontSize.xlarge,
    color: theme.palette.dark.bluegrey,
    fontWeight: theme.fontWeight.regular,
  },
  iconHoverStyle: {
    color: theme.palette.dark.bluegrey,
    "&:hover": {
      backgroundColor: "transparent",
    },
    zIndex: theme.zIndex.drawer,
  },
  drawerWidth: {
    width: "100%",
  },
  cancelledColor: {
    transform: "rotate(-15deg)",
    fontWeight: " 500",
    fontSize: "200px",
    color: theme.palette.secondary.red,
    opacity: 0.3,
  },
  loading: {
    width: "100%",
    position: "relative",
    top: "50%",
    left: "50%",
  },
  waterMark: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  grid: {
    margin: 0,
  },
  textField: {
    padding: "20px 50px 0px 50px",
  },
  dialogPaper: {
    width: 351,
  },
}));
const PurchaseOrderTabs = ({ handleDrawerCancel, id, refreshData }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [openReceiveDrawer, setOpenReceiveDrawer] = useState({ open: false });
  const [confirmModal, setConfirmModal] = useState({ open: false });
  const [showEmailEditor, setShowEmailEditor] = useState({ open: false });
  const [list, setList] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [visibleButton, setVisibleButton] = useState(true);
  const [reasonText, setReasonText] = useState("");
  const edit = useEdit(orderDetails);
  const tabs = [
    {
      label: en.general,
      value: "general",
    },
    {
      label: en.receiving,
      value: "receiving",
    },
    {
      label: en.audit,
      value: "audit",
    },
  ];
  const renderLeftIcon = () => {
    return (
      <Grid container className={classes.iconCircle}>
        <MiIcon
          path={mdiCardTextOutline}
          size={1.5}
          color={theme.palette.primary.white}
        />
      </Grid>
    );
  };
  const fetchData = async (params = {}, filterParams = {}) => {
    const orderItems =
      await purchaseOrderService.purchaseOrder.getAllPurchaseOrderItems(
        params,
        filterParams,
        id
      );
    if (orderItems?.data) {
      setList(orderItems.data);
    }
    const order = await purchaseOrderService.purchaseOrder.get(id);
    if (order?.data) {
      setOrderDetails(order.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const account = useMemo(
    () => ({
      label: edit.getValue("accountName"),
      value: edit.getValue("accountName"),
    }),
    [edit]
  );
  const contacts = useMemo(
    () => [
      {
        label: edit.getValue("contactName"),
        value: edit.getValue("contactName"),
      },
    ],
    [edit]
  );
  const locations = useMemo(
    () => [
      {
        label: edit.getValue("deliveryLocation"),
        value: edit.getValue("deliveryLocation"),
      },
    ],
    [edit]
  );
  const receiveOrPaidTotal =
    orderDetails?.status === "Paid" || orderDetails?.status === "Received";
  const total = list.reduce((previousValue, currentValue) => {
    if (receiveOrPaidTotal) {
      return (
        previousValue + currentValue.quantityReceived * currentValue.unitPrice
      );
    } else {
      return (
        previousValue + currentValue.quantityOrdered * currentValue.unitPrice
      );
    }
  }, 0);

  const invoiceSubtotal = Number(total).toFixed(2);
  const taxes = TAX_RATE * total;
  const invoiceTaxes = Number(taxes).toFixed(2);
  const invoiceTotal = taxes + total;
  const invoiceTotals = Number(invoiceTotal).toFixed(2);

  const handleCancelBar = () => {
    setVisibleButton(true);
  };

  const handleOpenReceivePo = () => {
    setVisibleButton(false);
    const handleCancel = () => {
      setOpenReceiveDrawer({ open: false });
    };
    setOpenReceiveDrawer({
      open: true,
      handleCancel,
      handleDrawerCancel,
      id,
      accountId: orderDetails.accountId,
    });
  };

  const handleMarkAsPaid = async () => {
    const mStatus = { status: "Paid" };
    const response = await purchaseOrderService.purchaseOrder.update({
      id: orderDetails.id,
      data: mStatus,
      successMessage: en.purchaseOrderMarkedAsPaid,
    });
    if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
      handleDrawerCancel();
      refreshData();
    }
  };

  const onCancel = () => setConfirmModal({ open: false });

  const handleOnSave = async () => {
    const mStatus = { status: "Cancelled" };
    const response = await purchaseOrderService.purchaseOrder.update({
      id: orderDetails.id,
      data: mStatus,
    });
    if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
      createToast(en.purchaseOrderCancelled, TOAST_TYPES.ERROR);
      setShowEmailEditor({ open: false });
      handleDrawerCancel();
      refreshData();
    }
  };
  const handleOnBack = () => {
    setShowEmailEditor({ open: false });
  };
  const onApprove = () => {
    if (reasonText !== "") {
      onCancel();
      setShowEmailEditor({
        open: true,
        stepLabelData: [{ ...orderDetails }],
        isEmailMessage: false,
      });
    }
  };

  const handleOnCancel = () => {
    let mProps = {
      icon: mdiCancel,
      title: en.CancelPurchaseOrder,
      description: en.confirmYouDLikeToCancelThisOrder,
      textPositive: en.confirm,
      buttonLeftStyles: {
        width: 100,
        height: 36,
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.dark.bluegrey,
      },
      buttonRightStyles: { width: 100, height: 36 },
      isStyledAction: true,
    };
    setConfirmModal({ ...mProps, open: true });
  };

  const renderHeader = () => {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.headerStyle}
      >
        <Grid item className={classes.iconStyle}>
          <MiPageHeader
            title={en.purchaseOrder}
            color={theme.palette.secondary.bluegrey}
            leftIcon={renderLeftIcon()}
            titleVariant={"h4"}
          />
          <MiChip
            label={`PO ${orderDetails.number}` || ""}
            className={classes.pageHeaderDetails}
          />
        </Grid>
        <Grid item>
          <PurchasingOrderStatus value={orderDetails.status || ""} />
        </Grid>
        <Grid item className={classes.headerButtonStyle}>
          {visibleButton &&
            (orderDetails.status === ORDER_STATUS.BACKORDER ||
              orderDetails.status === ORDER_STATUS.ISSUED) && (
              <>
                <Grid>
                  <MiButton
                    title={en.cancelPo}
                    inverse={true}
                    type={"button"}
                    color={theme.palette.secondary.red}
                    style={{ height: 45, width: 150, fontWeight: 500 }}
                    onClick={handleOnCancel}
                  />
                </Grid>
                <Grid className={classes.iconAlign}>
                  <MiButton
                    title={en.receivePo}
                    inverse={true}
                    type={"button"}
                    color={theme.palette.primary.main}
                    style={{ height: 45, width: 150, fontWeight: 500 }}
                    onClick={handleOpenReceivePo}
                  />
                </Grid>
              </>
            )}
          {visibleButton && orderDetails.status === ORDER_STATUS.RECEIVED && (
            <Grid className={classes.iconAlign}>
              <MiButton
                title={en.markAsPaid}
                inverse={true}
                type={"button"}
                color={theme.palette.primary.main}
                style={{
                  height: 45,
                  width: 150,
                  fontWeight: theme.fontWeight.medium,
                }}
                onClick={handleMarkAsPaid}
              />
            </Grid>
          )}
          <Grid className={classes.iconAlign}>
            <IconButton
              disableRipple
              size="small"
              className={classes.iconHoverStyle}
            >
              <MiIcon
                path={mdiFileSearch}
                size={1}
                color={theme.palette.primary.grey}
              />
            </IconButton>
          </Grid>
          <Grid className={classes.iconAlign}>
            <IconButton
              disableRipple
              size="small"
              className={classes.iconHoverStyle}
            >
              <MiIcon
                path={mdiTrayArrowDown}
                color={theme.palette.primary.grey}
                size={1}
              />
            </IconButton>
          </Grid>
          <Grid>
            <IconButton
              disableRipple
              size="small"
              className={classes.iconHoverStyle}
            >
              <MiIcon
                path={mdiClose}
                size={1}
                color={theme.palette.primary.grey}
                onClick={handleDrawerCancel}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderInputComp = () => {
    return (
      <Grid align="center" className={classes.textField}>
        <MiInputTextbox
          placeholder={en.enterReason}
          value={reasonText}
          handleChange={(e) => setReasonText(e.target.value)}
        />
      </Grid>
    );
  };

  return (
    <>
      <MiDrawer
        open={true}
        anchor="right"
        classes={{ paper: classes.drawerWidth }}
        width="100%"
      >
        {loading ? (
          <Grid className={classes.loading}>
            <MiLoader color={theme.palette.primary.white} />
          </Grid>
        ) : (
          <Grid container spacing={5} className={classes.root}>
            <Grid item xs={12}>
              {renderHeader()}
              <MiTab
                tabIndicatorColor={theme.palette.dark.teal}
                tabs={tabs}
              ></MiTab>
            </Grid>
            <Grid item xs={12} className={classes.form}>
              <PurchaseOrderForm
                loading={loading}
                {...orderDetails}
                disabled={true}
                edit={edit}
                account={account}
                contacts={contacts}
                locations={locations}
              />
            </Grid>
            <Grid item xs={12} className={classes.table}>
              <PurchaseOrderTable data={list} orderDetails={orderDetails} />
            </Grid>
            <Grid
              item
              xs={12}
              container
              className={classes.grid}
              spacing={10}
              justifyContent="flex-end"
            >
              <Grid container>
                <Grid item xs={10}></Grid>
                <Grid item xs={1}>
                  <Grid>
                    <Typography className={classes.footerStyle}>
                      {en.subtotal}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid>
                    <Typography className={classes.footerStyle}>
                      {en.tax}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid>
                    <Typography className={classes.totalStyle}>
                      {en.total}
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Grid>
                    <Typography className={classes.amountGrid}>
                      ${invoiceSubtotal}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid>
                    <Typography className={classes.amountGrid}>
                      ${invoiceTaxes}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid>
                    <Typography className={classes.totalAmount}>
                      ${invoiceTotals}
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {orderDetails.status === ORDER_STATUS.CANCEL && (
              <Grid className={classes.waterMark}>
                <Typography className={classes.cancelledColor}>
                  {en.cancelled}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} className={classes.floatingAction}>
              <MiSnackbar
                visible={!visibleButton}
                justifyCenter={false}
                cancelTitle={en.cancel}
                handleCancelClick={handleCancelBar}
                buttonLeftStyles={{ width: 150, height: 45, fontWeight: 500 }}
                saveTitle={en.receive}
                handleSaveClick={handleOpenReceivePo}
                buttonTwoColor={theme.palette.primary.blue}
                buttonTwoStyles={{
                  width: 150,
                  height: 45,
                }}
                buttonOneStyles={{ width: 150, height: 45, fontWeight: 500 }}
              />
            </Grid>
          </Grid>
        )}
      </MiDrawer>
      {openReceiveDrawer.open && (
        <Receiving {...openReceiveDrawer} refreshData={refreshData} />
      )}
      {confirmModal.open && (
        <MiModalConfirm
          {...confirmModal}
          handleNegative={onCancel}
          handlePositive={onApprove}
          dialogClasses={{ paper: classes.dialogPaper }}
          component={renderInputComp()}
          color={theme.palette.secondary.red}
          approveTextColor={
            reasonText === ""
              ? theme.palette.lightAccent.bluegrey
              : theme.palette.primary.white
          }
          approveBgColor={
            reasonText === ""
              ? theme.palette.accent.grey
              : theme.palette.secondary.red
          }
          dialogActionStyle={{
            padding: "25px 30px",
            borderTop: `1px solid ${theme.palette.medium.grey}`,
            backgroundColor: theme.palette.lightAccent.grey,
            margin: "20px 0px 0px 0px ",
          }}
        />
      )}
      {showEmailEditor.open && (
        <SendEmail
          {...showEmailEditor}
          handleOnSave={handleOnSave}
          handleOnBack={handleOnBack}
          handleOnSaveManually={handleOnSave}
          title={`${en.cancelPo} | ${
            orderDetails.number
          } | ${new Date().toLocaleDateString()}`}
          isBackButtonText={true}
        />
      )}
    </>
  );
};

export default PurchaseOrderTabs;
