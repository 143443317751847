import React from "react";
import PropTypes from "prop-types";
import { getImageUrl } from "@miview/utils";
import { MiModal } from "./MiView";
import { useCdn } from "@miview/hooks";

export const MiImagePreviewModal = ({
  imagePreviewed = "",
  setImagePreviewed,
  description = "",
}) => {
  const cdn = useCdn();

  return (
    <MiModal
      disableHeaderFooter
      width="initial"
      maxWidth={800}
      toggle={() => setImagePreviewed("")}
    >
      <img style={styles.modal} src={getImageUrl(imagePreviewed, cdn)} />
      <div style={styles.description}>{description || ""}</div>
    </MiModal>
  );
};

MiImagePreviewModal.propTypes = {
  imagePreviewed: PropTypes.string,
  setImagePreviewed: PropTypes.func,
};

const styles = {
  modal: {
    maxWidth: "100%",
  },
  description: {
    padding: 10,
  },
};
