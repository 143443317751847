import React from "react";
import { THEME } from "@miview/theme";
import { MiIcon } from "../index.ts";
import PropTypes from "prop-types";

/**
 * - This is chip
 */
export const Chip = (props) => {
  const { style, text, textColor, color, icon, children, pill } = props;
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          ...styles.badge,
          ...style,
          backgroundColor: color ? color : THEME.GREY_LIGHT_ALT,
        }}
      >
        {!!icon && (
          <MiIcon name={icon} size={15} color={THEME.GREY_MEDIUM_ALT} />
        )}
        <div
          style={{
            color: textColor ? textColor : THEME.GREY_MEDIUM_ALT,
            fontSize: THEME.TEXT_BODY_REGULAR.fontSize,
            marginLeft: icon ? 2 : 0,
          }}
        >
          {text}
        </div>
        {children}
      </div>
      {pill}
    </div>
  );
};

const styles = {
  badge: {
    padding: 3,
    paddingHorizontal: 7,
    borderRadius: 50,
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
};

Chip.propTypes = {
  /**
   * overriding styles
   */
  style: PropTypes.object,
  /**
   * pill text
   */
  text: PropTypes.string,
  /**
   * Text color
   */
  textColor: PropTypes.string,
  /**
   * pill color
   */
  color: PropTypes.string,
  /**
   * This is icon name
   */
  icon: PropTypes.string,
  /**
   * child component
   */
  children: PropTypes.element,
  /**
   * pill to show at the top right corner
   */
  pill: PropTypes.element,
};
