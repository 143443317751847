import { makeStyles } from "tss-react/mui";

const miDragDropZoneStyles = {
  dragDropZoneDefault: { padding: "6px", height: "100%" },
  dragDropZoneDragging: {
    border: "3px dashed #f79232",
    padding: "3px",
    height: "100%",
  },
};

export const useMiDragDropZoneStyles = makeStyles()(miDragDropZoneStyles);
