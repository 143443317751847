import React from "react";
import { MiModal } from "@miview/components";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import NewHomesTemplatesList from "./NewHomesTemplatesList";

const useStyles = makeStyles()((theme) => ({
  templateMainContainer: {
    padding: "10px 10px 20px",
    width: 780,
  },
  miHeader: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  miHeaderIcon: {
    width: 50,
    height: 50,
    background: theme.palette.primary.teal,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: 30,
    color: theme.palette.primary.white,
  },
  miHeaderHeading: {
    color: theme.palette.secondary.bluegrey,
    fontSize: theme.fontSize.headerSmall,
    marginRight: 30,
  },
}));

const NewHomesTemplatesModal = (props) => {
  const { open, onCancelTemplateModal, templateSelected, onApprove } = props;
  const theme = useTheme();
  const { classes } = useStyles();
  const MiHeader = () => {
    return (
      <div className={classes.miHeader}>
        <div className={classes.miHeaderIcon}>
          <ListAltOutlinedIcon />
        </div>
        <div className={classes.miHeaderHeading}>Template Manager</div>
      </div>
    );
  };

  return (
    <MiModal
      open={open}
      onClose={onCancelTemplateModal}
      title={MiHeader()}
      maxWidth="md"
      isCloseButton={false}
      modalTitleStyle={{}}
      actions={[
        {
          name: "Cancel",
          onClick: onCancelTemplateModal,
          color: theme.palette.dark.bluegrey,
          style: {
            fontWeight: theme.fontWeight.medium,
            fontSize: theme.fontSize.medium,
            margin: 0,
          },
        },
        {
          name: "Create",
          disabled: !templateSelected,
          onClick: onApprove,
          color: templateSelected ? theme.palette.primary.teal : "",
          style: {
            fontWeight: theme.fontWeight.medium,
            fontSize: theme.fontSize.medium,
            margin: 0,
          },
        },
      ]}
    >
      <div className={classes.templateMainContainer}>
        <NewHomesTemplatesList {...props} />
      </div>
    </MiModal>
  );
};

export default React.memo(NewHomesTemplatesModal);
