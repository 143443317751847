import React, { useEffect } from "react";
import PropTypes, { object } from "prop-types";
import SupplierContactForm from "../../../components/SupplierContactForm";
import { Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import en from "../../../locale/en.json";
import { MiModal } from "@miview/components";
import { useEdit } from "@miview/hooks";
import { isPhoneNumber, isValidEmail } from "@miview/utils";
import { CONTACT_STATUS } from "../../../constants";

const useStyles = makeStyles()(() => ({
  contentStyle: {
    width: 718,
    paddingRight: 20,
    paddingLeft: 20,
  },
}));

const CREATE_DEFAULTS = { status: CONTACT_STATUS.active };

export const AddContactsModal = ({
  onCancel,
  onSave,
  row,
  isDisable,
  branches,
  contactTypes,
  isVisible,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const edit = useEdit(row || CREATE_DEFAULTS);

  const isEdit = !!row;

  useEffect(() => {
    if (!isVisible) {
      edit.reset();
    }
  }, [isVisible]);

  const isValid = () => {
    const email = edit.getValue("email");
    const phone = edit.getValue("phone");
    return (!email || isValidEmail(email)) && (!phone || isPhoneNumber(phone));
  };

  const save = () => {
    if (!isValid()) {
      return;
    }
    if (isEdit) {
      onSave({ ...edit.edits });
    } else {
      onSave({ ...CREATE_DEFAULTS, ...edit.edits });
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <MiModal
      open={true}
      maxWidth="md"
      actions={[
        {
          name: en.cancel,
          onClick: onCancel,
          disableRipple: true,
          style: {
            width: 120,
            height: 36,
            padding: "4px 10px",
            backgroundColor: theme.palette.primary.white,
            fontSize: theme.fontSize.small,
            fontWeight: theme.fontWeight.medium,
            color: theme.palette.dark.bluegrey,
          },
        },
        {
          name: en.save,
          disableRipple: true,
          onClick: save,
          inverse: false,
          style: {
            width: 120,
            height: 36,
            padding: "4px 10px",
            marginRight: 15,
            fontSize: theme.fontSize.small,
            fontWeight: theme.fontWeight.medium,
            color: theme.palette.primary.white,
            backgroundColor: theme.palette.primary.main,
          },
        },
      ]}
      title={isEdit ? en.editContactInformation : en.createNewContact}
      isCloseButton={false}
      modalTitleStyle={{
        padding: "40px 40px 0px 40px",
        color: theme.palette.secondary.bluegrey,
      }}
    >
      <Grid container className={classes.contentStyle}>
        <SupplierContactForm
          edit={edit}
          onCancel={onCancel}
          branchesContact={branches}
          rolesContact={contactTypes}
          isDisable={isDisable}
          isNew={!isEdit}
        />
      </Grid>
    </MiModal>
  );
};

AddContactsModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  branches: PropTypes.arrayOf(object).isRequired,
  contactTypes: PropTypes.arrayOf(object).isRequired,
};
