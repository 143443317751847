import React from "react";
import { Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CONTACT_STATUS } from "../constants";
import en from "../locale/en.json";
import {
  MiCheckbox,
  MiInputSelectBox,
  MiInputTextbox,
  MiInputPhone,
} from "@miview/components";
import { isPhoneNumber, isValidEmail } from "@miview/utils";

const useStyles = makeStyles()(() => ({
  subContainer1: {
    paddingRight: 10,
  },
  subContainer2: {
    paddingLeft: 10,
  },
  mainContainer: {
    marginBottom: 30,
  },
  checkboxContainer: {
    marginTop: 5,
  },
  formAlign: {
    marginTop: 20,
  },
}));
const SupplierContactForm = ({
  edit,
  branchesContact,
  rolesContact,
  isDisable,
  isNew,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const branchSelection = branchesContact.map((bc) => ({
    label: bc.name,
    value: bc.id,
  }));

  const onStatusCheckBoxChange = () => {
    if (edit.getValue("status") !== CONTACT_STATUS.active) {
      edit.update({ status: CONTACT_STATUS.active });
    } else {
      edit.update({ status: CONTACT_STATUS.inactive });
    }
  };

  const isEmailValid = () => {
    const email = edit.getValue("email");
    return !email || isValidEmail(email);
  };

  const getEmailErrorMessage = () => {
    if (!isEmailValid()) {
      return en.invalidEmailError;
    }
  };

  const isPhoneValid = () => {
    const phone = edit.getValue("phone");
    return !phone || isPhoneNumber(phone);
  };

  const getPhoneErrorMessage = () => {
    if (!isPhoneValid()) {
      return en.invalidPhoneError;
    }
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={6} className={classes.subContainer1}>
        <Grid container>
          <Grid item xs={12} className={classes.formAlign}>
            <MiInputTextbox
              labelTextColor={theme.palette.primary.main}
              labelText={en.firstName}
              disabled={isDisable}
              value={edit.getValue("firstName")}
              handleChange={(e) => edit.update({ firstName: e.target.value })}
              required
            />
          </Grid>
          <Grid item xs={12} className={classes.formAlign}>
            <MiInputTextbox
              labelTextColor={theme.palette.primary.main}
              labelText={en.email}
              disabled={isDisable}
              value={edit.getValue("email")}
              handleChange={(e) => edit.update({ email: e.target.value })}
              helperText={getEmailErrorMessage()}
              error={!isEmailValid()}
            />
          </Grid>
          <Grid item xs={12} className={classes.formAlign}>
            <MiInputSelectBox
              labelTextColor={theme.palette.primary.main}
              labelText={en.branch}
              disableNone={true}
              displayEmpty={true}
              isPlaceholder={true}
              menuItems={branchSelection}
              value={edit.getValue("accountId") || ""}
              handleChange={(e) => edit.update({ accountId: e.target.value })}
              disabled={isDisable || !isNew}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} className={classes.subContainer2}>
        <Grid container>
          <Grid item xs={12} className={classes.formAlign}>
            <MiInputTextbox
              labelTextColor={theme.palette.primary.main}
              labelText={en.lastName}
              disabled={isDisable}
              value={edit.getValue("lastName")}
              handleChange={(e) => edit.update({ lastName: e.target.value })}
              required
            />
          </Grid>
          <Grid item xs={12} className={classes.formAlign}>
            <MiInputPhone
              labelTextColor={theme.palette.primary.main}
              labelText={en.phoneNo}
              disabled={isDisable}
              value={edit.getValue("phone")}
              handleChange={(e) => edit.update({ phone: e.target.value })}
              helperText={getPhoneErrorMessage()}
              error={!isPhoneValid()}
            />
          </Grid>
          <Grid item xs={12} className={classes.formAlign}>
            <MiInputSelectBox
              labelTextColor={theme.palette.primary.main}
              labelText={en.role}
              disableNone={true}
              displayEmpty={true}
              isPlaceholder={true}
              menuItems={rolesContact.map((rc) => ({
                label: rc.name,
                value: rc.id,
              }))}
              value={edit.getValue("contactTypeId") || ""}
              handleChange={(e) =>
                edit.update({ contactTypeId: e.target.value })
              }
              disabled={isDisable}
              required
            />
          </Grid>
          <Grid item xs={12} className={classes.checkboxContainer}>
            <MiCheckbox
              checked={edit.getValue("status") === CONTACT_STATUS.active}
              label={en.active}
              onChange={onStatusCheckBoxChange}
              disabled={isDisable}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default React.memo(SupplierContactForm);
