import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const optionService = {
  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/options/${id}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  createOrUpdateRule: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/options/${id}/rules`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/options/createDraft`,
      method: "post",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving option",
      },
      success: {
        message: "Option saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  submit: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/options/submitDraft`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  createAreaRule: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/options/${id}/arearules`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  addPropertyOption: async (id, propertyId) => {
    const options = await apiOptions({
      url: `${API_V3}/options/${id}/properties/${propertyId}`,
      method: "post",
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure applying option",
      },
      success: {
        message: "Option applied successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  removePropertyOption: async (id, propertyId) => {
    const options = await apiOptions({
      url: `${API_V3}/options/${id}/properties/${propertyId}`,
      method: "delete",
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure removing option",
      },
      success: {
        message: "Option removed successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
};
