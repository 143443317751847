import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiButton, MiIcon } from "../index.ts";

const useStyles = makeStyles()((theme) => {
  return {
    iconContainer: {
      backgroundColor: alpha(theme.palette.primary.main, 0.18),
      color: theme.palette.primary.main,
      width: 80,
      height: 80,
      borderRadius: 100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});

const RenderIcon = ({ icon }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.iconContainer}>
      <MiIcon path={icon} size={1.3} />
    </div>
  );
};

export const MiPlaceHolderList = ({
  icon,
  hideHelper,
  text,
  helperText,
  actions = [],
}) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "200px" }}
    >
      <RenderIcon icon={icon} />
      <Grid item style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Typography variant="subtitle1">{text}</Typography>
      </Grid>
      <Grid item style={{ paddingBottom: 0 }}>
        <Typography variant="subtitle2" color="textSecondary">
          {hideHelper ? null : helperText}
        </Typography>
      </Grid>
      <Grid item>
        <Grid item>
          {actions.map((action, index) => (
            <MiButton
              icon={action.icon}
              title={action.title}
              inverse={action.inverse}
              onClick={action.onClick}
              color={action.color}
              key={index}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

MiPlaceHolderList.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  helperText: PropTypes.string,
  actions: PropTypes.array,
};
