import React, { useState, useRef, useEffect } from "react";
import { THEME } from "@miview/theme";
import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import AddIcon from "@mui/icons-material/Add";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useCdn } from "@miview/hooks";
import ResizeObserver from "resize-observer-polyfill";
import { useEventListener } from "../../../utils";
import CadBoundaryOffsetField from "./CadBoundaryOffsetField";
import DeleteIcon from "@mui/icons-material/Delete";
import { cadBoundaryService } from "@miview/api";
import { getImageUrl, getImageBase64FromFile } from "@miview/utils";
import {
  MiCheckbox,
  MiInputSelectBox,
  MiModalConfirm,
} from "@miview/components";
import { HTTP_STATUSES } from "@miview/constants";

const constrain = (value, max, min) => {
  if (value >= max) {
    return Math.floor(max);
  } else if (value <= min) {
    return Math.floor(min);
  } else {
    return Math.floor(value);
  }
};

const boundaryStyles = makeStyles()((_, { props }) => ({
  horizontal: {
    height: "5px",
    width: props.boundX,
    zIndex: 3,
    top: props.point - 2,
    left: 0,
    position: "absolute",
    backgroundColor: props.color,
    cursor: props.selected ? "grabbing" : "initial",
    "&:hover": {
      cursor: "grab",
    },
  },
  vertical: {
    height: props.boundY,
    width: "5px",
    zIndex: 3,
    left: props.point - 2,
    top: 0,
    position: "absolute",
    backgroundColor: props.color,
    cursor: props.selected ? "grabbing" : "initial",
    "&:hover": {
      cursor: "grab",
    },
  },
}));

const BoundaryLine = (props) => {
  const { classes } = boundaryStyles({ props: props });
  const { isHorizontal, selected, ...rest } = props;
  const orientationClass = isHorizontal ? classes.horizontal : classes.vertical;

  return (
    <div className={`${orientationClass}`} draggable={selected} {...rest} />
  );
};

const imageInputStyles = makeStyles()(() => ({
  imageInputWrapper: {
    width: "20rem",
    margin: "auto",
    border: "1px dashed #666",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#2584C5",
    "&:hover": {
      backgroundColor: "#f4f9ff",
    },
  },
  imageInput: {
    display: "none",
  },
  imageInputLabel: {
    textAlign: "center",
    padding: "18px 10px",
    margin: 0,
    border: "2px solid blue",
  },
  changeCadButton: {
    color: "white",
    backgroundColor: THEME.ACTION_SECONDARY,
    "&:hover": {
      backgroundColor: THEME.ACTION_SECONDARY,
    },
    width: "100%",
    fontSize: 13,
    fontWeight: "400",
  },
}));

const ImageInput = (props) => {
  const { propertyId, stageTypeId, isReplace = false, setImage } = props;
  const { classes } = imageInputStyles();
  const pasteRef = useRef(null);
  const fileInputRef = useRef(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (pasteRef?.current) {
      pasteRef.current.addEventListener("paste", handleImagePaste, true);
    }

    return () => {
      if (pasteRef?.current) {
        pasteRef.current.removeEventListener("paste", handleImagePaste, true);
      }
    };
  }, []);

  const handleImagePaste = (e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") == -1) {
        continue;
      }

      const file = items[i].getAsFile();
      handleSetCad(file);
    }
  };

  const handleUpdateCadImage = async (image) => {
    setImage(image);
    await cadBoundaryService.updateCadImage({ propertyId, stageTypeId, image });
  };

  const handleRemoveCad = () => {
    setOpenConfirmModal(true);
  };

  const cancelRemoveCad = () => {
    setOpenConfirmModal(false);
  };

  const confirmRemoveCad = async () => {
    await handleUpdateCadImage(null);
  };

  const handleSetCad = (file) => {
    getImageBase64FromFile(file, handleUpdateCadImage);
  };

  const input = (
    <input
      id="RoughCadImageInput"
      ref={fileInputRef}
      className={classes.imageInput}
      type="file"
      accept="image/png, image/jpeg"
      onChange={(e) => handleSetCad(e.target.files[0])}
    />
  );

  const base = (
    <div ref={pasteRef} className={classes.imageInputWrapper}>
      {input}
      <label htmlFor="RoughCadImageInput" className={classes.imageInputLabel}>
        <div>ROUGH CAD</div>
        <AddIcon className={classes.imageInputIcon} />
      </label>
    </div>
  );

  const replace = (
    <div>
      <MiModalConfirm
        key={1}
        title={"Confirm Delete"}
        description={"Delete CAD image?"}
        open={openConfirmModal}
        handlePositive={confirmRemoveCad}
        handleNegative={cancelRemoveCad}
      />
      {input}
      <label htmlFor="RoughCadImageInput">
        <Button className={classes.changeCadButton} onClick={handleRemoveCad}>
          Remove CAD
        </Button>
      </label>
    </div>
  );

  return isReplace ? replace : base;
};

const stackStyles = makeStyles()((_, { props }) => {
  return {
    stack: {
      position: "absolute",
      top: props.locationY - props.stackIconFontSize / 2,
      left: props.locationX - props.stackIconFontSize / 2,
      fontSize: props.stackIconFontSize,
      opacity: props.isSelected ? 1 : 0.8,
      zIndex: props.isSelected ? 101 : 100,
      color: props.isSelected ? THEME.BLUE_DARK_ALT_2 : THEME.GREY_MEDIUM_ALT,
    },
  };
});

const StackLocation = (props) => {
  const isSelected = props.id === "stackIconEditing";
  const { classes } = stackStyles({
    props: {
      ...props,
      isSelected,
      stackIconFontSize: isSelected ? 42 : 38,
    },
  });
  return <RadioButtonCheckedIcon className={classes.stack} />;
};

const useStyles = makeStyles()(() => ({
  base: {
    height: "100%",
  },
  renderCad: {
    minHeight: "100%",
    display: "flex",
    flexFlow: "column",
  },
  cadWrapper: {
    display: "flex",
    minHeight: "100%",
    flex: 1,
  },
  imgWrapper: {
    display: "flex",
    margin: "10px",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 0,
    width: "80%",
  },
  centerWrapper: {
    zIndex: 2,
    position: "relative",
    maxWidth: "100%",
  },
  image: {
    position: "absolute",
    zIndex: 0,
    maxWidth: "100%",
  },
  wallList: {
    display: "flex",
    flexDirection: "column",
    marginRight: 15,
    justifyContent: "space-between",
  },
  wallButton: {
    width: "100%",
    minHeight: 25,
    alignItems: "center",
    justifyContent: "start",
    margin: 2,
    padding: 0,
    paddingLeft: 4,
    paddingRight: 2,
    fontSize: "0.6rem",
  },
  ghost: {
    display: "hidden",
  },
  changeCadWrapper: {
    width: "100%",
    marginTop: 8,
  },
}));

export const RoughInCanvas = (props) => {
  const {
    measurementTypes,
    propertyId,
    stageTypeId,
    stacks,
    handleUpdateStack,
    setCadBoundaries,
    cadBoundaries,
    refreshCadImage,
    edit,
  } = props;
  const { classes } = useStyles({ props });
  const containerRef = useRef(null);
  const ghostRef = useRef(null); // Used to remove dragging ghost image
  const [size, setSize] = useState({ x: 0, y: 0 });
  const [initialSize, setInitialSize] = useState(null);
  const [stackPositions, setStackPositions] = useState(null);
  const [cadImage, setCadImage] = useState(null);
  const [selectedBoundary, setSelectedBoundary] = useState(null);
  const active = edit.getValue("cadImageX") && edit.getValue("cadImageY");
  const canvasRef = useRef();
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);

  const cdn = useCdn();

  const updateCadBoundaries = async () => {
    const response = await cadBoundaryService.getAll({
      propertyId,
      stageTypeId,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setCadBoundaries(response.data);
    }
  };

  const itemId = edit.getValue("itemId");

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef && containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef && containerRef.current]);

  useEffect(() => {
    updateCadBoundaries();
    cadBoundaryService
      .getCadImage({ propertyId, stageTypeId })
      .then((response) => {
        setCadImage(response?.data);
      });
  }, [propertyId, stageTypeId, refreshCadImage]);

  useEffect(() => {
    setStackPositions(
      stacks
        .filter((x) => x.cadImageX && x.cadImageY && itemId !== x.itemId)
        .map((x) => {
          return {
            itemId: x.itemId,
            cadImageX: x.cadImageX,
            cadImageY: x.cadImageY,
          };
        })
    );
  }, [stacks]);

  const setImageDimensions = (e) => {
    let height = initialSize?.y || e?.target?.naturalHeight || 600;
    let width = initialSize?.x || e?.target?.naturalWidth || 400;

    const ratio = canvasRef.current?.offsetWidth / width;

    if (ratio) {
      height = height * ratio;
      width = canvasRef.current?.offsetWidth;
    }

    if (height + 327 > window.innerHeight) {
      width = ((window.innerHeight - 327) * width) / height;
      height = window.innerHeight - 327;
    }

    setImageHeight(height - 20);
    setImageWidth(width - 20);

    if (e?.target?.naturalHeight) {
      setInitialSize({
        x: e.target.naturalWidth,
        y: e.target.naturalHeight,
      });
    }
  };

  useEventListener("resize", setImageDimensions);

  const handleResize = (entries) => {
    const entry = entries[0].contentRect;

    setSize({
      x: entry.width,
      y: entry.height,
    });
  };

  const handleAddBoundary = (e) => {
    const selected = measurementTypes.find(
      ({ systemTypeId }) => systemTypeId === e.target.value
    );
    const newBoundary = {
      propertyId: propertyId,
      stageTypeId: stageTypeId,
      measurementTypeId: selected.systemTypeId,
      measurementType: selected.name,
      value: 0,
    };
    cadBoundaryService.create(newBoundary).then((result) => {
      setCadBoundaries([
        ...cadBoundaries,
        {
          ...newBoundary,
          color: selected.color,
          id: result.data,
          mainValue: selected.mainValue,
        },
      ]);
    });
  };

  const handleUpdateCadBoundary = async (data) => {
    await cadBoundaryService.update(data.id, data);
    await updateCadBoundaries();
  };

  const handleRemoveBoundary = (selected) => {
    cadBoundaryService.delete(selected.id);
    setCadBoundaries(cadBoundaries.filter((x) => x.id !== selected.id));
  };

  function throttle(fn, wait) {
    let time = Date.now();
    return function (...args) {
      if (time + wait - Date.now() < 0) {
        fn(...args);
        time = Date.now();
      }
    };
  }

  const handleDrag = throttle((e) => {
    e.preventDefault();
    const rect = e.target.offsetParent.getBoundingClientRect();
    const isHorizontal = selectedBoundary.measurementType
      .toLowerCase()
      .includes("back");
    const position = isHorizontal ? e.clientY : e.clientX;
    if (position === 0) {
      return;
    }

    const offset = isHorizontal ? rect.y : rect.x;
    const maxValue = isHorizontal ? size.y : size.x;
    const newPosition = constrain(position - offset, maxValue, 0);

    setSelectedBoundary({
      ...selectedBoundary,
      value: Math.floor(newPosition),
    });
  }, 50);

  const handleDragStart = (e) => {
    // Remove ghost image
    e.dataTransfer.setDragImage(ghostRef.current, 0, 0);
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    const isHorizontal = selectedBoundary.measurementType
      .toLowerCase()
      .includes("back");
    const initialValue = isHorizontal ? initialSize.y : initialSize.x;
    const currentValue = isHorizontal ? size.y : size.x;
    const multiplier = initialValue / currentValue;
    const boundaryValue = Math.round(multiplier * selectedBoundary.value);

    setCadBoundaries(
      cadBoundaries.map((x) => {
        if (x.id === selectedBoundary.id) {
          return {
            ...selectedBoundary,
            value: boundaryValue,
          };
        }

        return x;
      })
    );

    cadBoundaryService.update(selectedBoundary.id, {
      ...selectedBoundary,
      value: boundaryValue,
    });
    setSelectedBoundary(null);
  };

  const handleBrickLedgeChanged = (target) => {
    cadBoundaryService.update(target.id, {
      ...target,
      hasBrickLedge: !target.hasBrickLedge,
    });
    setCadBoundaries(
      cadBoundaries.map((x) => {
        if (x.id === target.id) {
          return {
            ...target,
            hasBrickLedge: !target.hasBrickLedge,
          };
        }

        return x;
      })
    );
  };

  const handleStackPlacementClick = (
    e,
    pos = { wallX: undefined, wallY: undefined }
  ) => {
    e.preventDefault();
    if (!itemId) {
      return;
    }

    let rectElement = e.target;
    while (rectElement.id !== "canvas") {
      rectElement = rectElement.parentElement;
    }

    // Fetch offset
    const rect = rectElement.getBoundingClientRect();

    // Get raw coordinates
    const xRaw = pos.wallX
      ? pos.wallX
      : constrain(e.clientX - rect.x, size.x, 0);
    const yRaw = pos.wallY
      ? pos.wallY
      : constrain(e.clientY - rect.y, size.y, 0);

    // Calculate multiplier to revert coordinates to be in relation to original image size
    const newPos = {
      cadImageX: Math.round(xRaw * (initialSize.x / size.x)),
      cadImageY: Math.round(yRaw * (initialSize.y / size.y)),
    };

    handleUpdateStack(newPos);
  };

  const renderInput = () => {
    return (
      <div className={classes.cadWrapper}>
        <div className={classes.wallList}>{renderBoundaryButtons()}</div>
        <ImageInput
          setImage={setCadImage}
          propertyId={propertyId}
          stageTypeId={stageTypeId}
        />
      </div>
    );
  };

  const renderStacks = () => {
    return (
      <div>
        {stackPositions.map((x) => {
          // Handle open stack form separately
          if (active && itemId === x.itemId) {
            return;
          }

          // Scale coordinates to be relative to current image size
          return (
            <StackLocation
              id="stackIcon"
              key={`stackLocation${x.itemId}`}
              boundX={size.x}
              boundY={size.y}
              locationX={x.cadImageX * (size.x / initialSize.x)}
              locationY={x.cadImageY * (size.y / initialSize.y)}
            />
          );
        })}

        {/* Handle form outside of loop in case it is a new stack */}
        {active && (
          <StackLocation
            id="stackIconEditing"
            key={`stackLocationEditing`}
            boundX={size.x}
            boundY={size.y}
            locationX={edit.getValue("cadImageX") * (size.x / initialSize.x)}
            locationY={edit.getValue("cadImageY") * (size.y / initialSize.y)}
          />
        )}
      </div>
    );
  };

  const renderCadBoundaries = () => {
    return cadBoundaries.map((x) => {
      const isHorizontal = x.measurementType.toLowerCase().includes("back");
      const initialSizeVal = isHorizontal ? initialSize.y : initialSize.x;
      const sizeVal = isHorizontal ? size.y : size.x;
      const multiplier = sizeVal / initialSizeVal;
      if (selectedBoundary && x.id === selectedBoundary.id) {
        return (
          <BoundaryLine
            key={`boundaryLine${x.id}${x.color}`}
            color={x.color}
            point={selectedBoundary.value}
            isHorizontal={isHorizontal}
            boundX={size.x}
            boundY={size.y}
            selected={true}
            onDrag={(e) => handleDrag(e)}
            onDragStart={(e) => handleDragStart(e)}
            onDragEnd={(e) => handleDragEnd(e)}
            onDragOver={(e) => e.preventDefault()}
          />
        );
      }

      return (
        <BoundaryLine
          key={`boundaryLine${x.id}${x.color}`}
          color={x.color}
          point={x.value * multiplier}
          isHorizontal={isHorizontal}
          boundX={size.x}
          boundY={size.y}
          selected={false}
          onMouseDown={(e) => {
            if (active) {
              const pos = {
                wallX: (isHorizontal ? undefined : x.value) * multiplier,
                wallY: (isHorizontal ? x.value : undefined) * multiplier,
              };
              handleStackPlacementClick(e, pos);
            } else {
              setSelectedBoundary({ ...x, value: x.value * multiplier });
            }
          }}
        />
      );
    });
  };

  const renderBoundaryButtons = () => {
    return (
      <div>
        <MiInputSelectBox
          menuItems={measurementTypes.filter(
            ({ systemTypeId }) =>
              !cadBoundaries?.some(
                ({ measurementTypeId }) => systemTypeId === measurementTypeId
              )
          )}
          labelField="text"
          labelText="Add Boundary"
          handleChange={handleAddBoundary}
          disableNone
        />
        {cadBoundaries?.map((x) => {
          if (!x.color) {
            return;
          }

          return (
            <div
              style={{
                color: x.color,
                border: `2px solid ${THEME.GREY_LIGHT}`,
                borderRadius: "3px",
                marginTop: "0.5rem",
                padding: "5px",
              }}
              key={`wallButton${x.id}`}
            >
              <div>{x.mainValue}</div>
              <DeleteIcon
                style={{
                  color: "red",
                  float: "right",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveBoundary({ ...x })}
              />
              <MiCheckbox
                checked={x.hasBrickLedge}
                label={"Brick Ledge"}
                onChange={() => handleBrickLedgeChanged({ ...x })}
              />
              <CadBoundaryOffsetField
                value={x.offset}
                data={x}
                handleSave={handleUpdateCadBoundary}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderCad = () => {
    const cadUrl = getImageUrl(cadImage, cdn);

    return (
      <div className={classes.renderCad}>
        <div className={classes.cadWrapper}>
          <div className={classes.wallList}>
            {renderBoundaryButtons()}
            <div className={classes.changeCadWrapper}>
              <ImageInput
                propertyId={propertyId}
                stageTypeId={stageTypeId}
                isReplace={true}
                setImage={setCadImage}
              />
            </div>
          </div>

          <div className={classes.imgWrapper} ref={canvasRef}>
            <div
              id="canvas"
              className={classes.centerWrapper}
              style={{ height: imageHeight, width: imageWidth }}
              onClick={handleStackPlacementClick}
            >
              <img
                className={classes.image}
                style={{ height: imageHeight, width: imageWidth }}
                src={cadUrl}
                draggable={false}
                ref={containerRef}
                onLoad={setImageDimensions}
              />
              {cadBoundaries && size && initialSize && renderCadBoundaries()}
              {stacks && size && initialSize && renderStacks()}
            </div>
          </div>

          <div className={classes.ghost} ref={ghostRef} />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.base}>{cadImage ? renderCad() : renderInput()}</div>
  );
};
