import React, { useState, useEffect } from "react";
import { THEME } from "../../../components/Standard/theme";
import { logout } from "../../../api/login";
import { userService } from "@miview/api";
import { CathedralLogo, MiViewLogo } from "@miview/assets";
import { useRouter } from "@miview/hooks";
import { MiButton, MiInputTextbox } from "@miview/components";
import { HTTP_STATUSES } from "@miview/constants";

const ResetScreen = () => {
  const passLength = 6;
  const [state, changeState] = useState({
    password1: "",
    password2: "",
    isValid: false,
    isMatch: false,
    userId: " ",
    code: "",
  });
  const { push, location } = useRouter();
  useEffect(() => {
    const query = new URLSearchParams(location?.search);
    let code = "";
    let userId = "";

    for (let param of query.entries()) {
      if (param[0] === "code") {
        code = param[1];
      }
      if (param[0] === "userId") {
        userId = param[1];
      }
    }
    changeState({ ...state, code: code, userId: userId });
  }, []);

  const save = async () => {
    const data = {
      NewPassword: state.password1,
      PasswordResetCode: state.code,
      userId: state.userId,
    };
    const response = await userService.resetPassword(state.userId, data);

    if (response.status === HTTP_STATUSES.OK) {
      changeState({
        ...state,
        password1: " ",
        password2: "",
      });
    }

    await logout();
    push("/login");
  };

  const cancel = async () => {
    await logout();
    push("/login");
  };

  const isValid = (password) => {
    return password.length >= 6;
  };

  const changedHandler = (event, input) => {
    const newPassword = event.target.value;

    let pass1 = "";
    let pass2 = "";
    if (input === "input1") {
      changeState((previousState) => ({
        ...previousState,
        password1: newPassword,
      }));
      pass1 = newPassword;
      pass2 = state.password2;
    } else {
      changeState((previousState) => ({
        ...previousState,
        password2: newPassword,
      }));
      pass1 = state.password1;
      pass2 = newPassword;
    }

    //state here seems to be off by 1 char which is why we capture it earlier
    let valid = isValid(pass1);
    let match = pass1 === pass2;
    changeState((previousState) => ({
      ...previousState,
      isValid: valid,
      isMatch: match,
    }));
  };

  const inputStyle = {
    display: "inline-block",
    width: "230px",
    marginRight: "-30px",
  };

  const handleKeyPress = (event) => {
    if (event.key != "Enter") {
      return;
    }
    save();
  };
  return (
    <div>
      <div
        className="app flex-div align-items-center"
        style={{ display: "flex" }}
      >
        {
          <div
            style={{
              backgroundColor: "#F5F5F5",
              position: "absolute",
              top: "30%",
            }}
          >
            <div
              className="justify-content-center"
              style={{ marginBottom: 25 }}
            >
              <img
                style={{
                  marginRight: 10,
                  display: "block",
                  zIndex: 1,
                  width: 400,
                  paddingTop: "",
                }}
                src={CathedralLogo}
              />
            </div>
            <div className="justify-content-center">
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 4,
                  padding: "40px",
                  border: "1px solid #c8ced3",
                  backgroundClip: "border-box",
                  display: "flex",
                  flexBasis: "0%",
                  width: "450px",
                }}
              >
                <div>
                  <h1>Reset Your Password</h1>
                  <br />
                  <label
                    htmlFor="password"
                    style={{ display: "inline-block", width: "120px" }}
                  >
                    Enter a new Password.
                  </label>
                  <MiInputTextbox
                    type="password"
                    id="password"
                    variant="outlined"
                    placeholder=""
                    value={state.password1}
                    onKeyPress={handleKeyPress}
                    styles={inputStyle}
                    handleChange={(event) => {
                      changedHandler(event, "input1");
                    }}
                  />

                  <label
                    style={{ display: "inline-block", width: "120px" }}
                    htmlFor="passwordRepeat"
                  >
                    Re-enter Password
                  </label>
                  <MiInputTextbox
                    type="password"
                    id="passwordRepeat"
                    placeholder=""
                    variant="outlined"
                    value={state.password2}
                    onKeyPress={handleKeyPress}
                    styles={inputStyle}
                    handleChange={(event) => changedHandler(event, "input2")}
                  />
                  <div>
                    <label
                      style={{
                        minHeight: 50,
                        color: state.isValid && state.isMatch ? "green" : "red",
                        display: "block",
                      }}
                    >
                      {!state.isValid
                        ? "Passwords must be at least " +
                          passLength +
                          " characters."
                        : !state.isMatch
                        ? "Both Passwords must match!"
                        : "The passwords match!"}
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <MiButton
                        color={THEME.GREY_MEDIUM}
                        inverse
                        onClick={cancel}
                        style={{ minWidth: 120 }}
                        title="Cancel"
                      />
                      <MiButton
                        color={THEME.BLUE_PRIMARY}
                        inverse
                        onClick={save}
                        disabled={!state.isValid || !state.isMatch}
                        style={{ minWidth: 120 }}
                        title="Save"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      {
        <img
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "200px",
            marginRight: "10px",
            float: "right",
          }}
          src={MiViewLogo}
        />
      }
    </div>
  );
};

ResetScreen.propTypes = {};

export default ResetScreen;
