import { apiOptions, apiRequest } from "../utils";
import { API_V3 } from "../constants";

export const stageSummaryService = {
  getAll: async ({ filters, sort, signal, params } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/stagesummaries`,
      method: "get",
      filters,
      sort,
      signal,
      params,
    });
    return await apiRequest(options);
  },
  get: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/stagesummaries/${id}`,
      method: "get",
    });
    return await apiRequest(options);
  },
  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/stagesummaries`,
      method: "post",
      data: request,
    });
    return await apiRequest(options);
  },
  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/stagesummaries/${id}`,
      method: "patch",
      data: request,
    });
    return await apiRequest(options);
  },
  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/stagesummaries/${id}`,
      method: "delete",
    });
    return await apiRequest(options);
  },
};
