import React, { useState, useEffect } from "react";
import { holidayService } from "@miview/api";
import { createToast } from "@miview/toast";
import { TOAST_TYPES, PAGINATION } from "@miview/constants";
import {
  MiDatePickerInput,
  MiModal,
  MiPageHeader,
  MiButton,
  MiIcon,
  MiList,
  MiInputTextbox,
  MiModalConfirm,
} from "@miview/components";
import { useTheme } from "@mui/material/styles";
import { mdiCalendarStarOutline, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import moment from "moment";

export const HolidaysPage = () => {
  const [holidays, setHolidays] = useState([]);
  const [holidayName, setHolidayName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [editSelection, setEditSelection] = useState({});
  const [isHolidayCreateModalOpen, setIsHolidayCreateModalOpen] =
    useState(false);
  const [isHolidayEditModalOpen, setIsHolidayEditModalOpen] = useState(false);
  const [isHolidayDeleteModalOpen, setIsHolidayDeleteModalOpen] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  const theme = useTheme();
  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    getHolidays();
  }, []);

  const getHolidays = () => {
    holidayService.getAll().then((res) => setHolidays(res.data));
  };

  const handleHolidayNameChange = (e) => {
    setHolidayName(e.target.value);
  };

  const addHoliday = () => {
    if (startDate > endDate) {
      createToast(
        "Start Date Cannot be greater than End Date",
        TOAST_TYPES.ERROR
      );
      return;
    }
    holidayService
      .create({
        holidayName: holidayName,
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        if (res.status === 200) {
          createToast("Holiday Created Successfully", TOAST_TYPES.SUCCESS);
          setRefresh(!refresh);
          closeHolidayCreateModal();
        }
      });
  };

  const updateHoliday = () => {
    if (startDate > endDate) {
      createToast(
        "Start Date Cannot be greater than End Date",
        TOAST_TYPES.ERROR
      );
      return;
    }
    holidayService
      .update(editSelection.id, {
        holidayName: holidayName,
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        if (res.status === 200) {
          createToast("Holiday Edited Successfully", TOAST_TYPES.SUCCESS);
          setRefresh(!refresh);
          closeHolidayEditModal();
        }
      });
  };
  const deleteHoliday = () => {
    holidayService.delete(editSelection.id).then((res) => {
      if (res.status === 200) {
        createToast("Holiday Deleted Successfully", TOAST_TYPES.SUCCESS);
        setRefresh(!refresh);
        closeHolidayDeleteModal();
      }
    });
  };
  const closeHolidayCreateModal = () => {
    setIsHolidayCreateModalOpen(false);
    setStartDate(null);
    setEndDate(null);
    setHolidayName("");
  };

  const openHolidayEditModal = (obj) => {
    setEditSelection(obj);
    setHolidayName(obj.holidayName);
    setStartDate(obj.startDate);
    setEndDate(obj.endDate);
    setIsHolidayEditModalOpen(true);
  };
  const closeHolidayEditModal = () => {
    setIsHolidayEditModalOpen(false);
    setEditSelection({});
    setHolidayName("");
    setStartDate(null);
    setEndDate(null);
  };
  const openHolidayDeleteModal = (obj) => {
    setEditSelection(obj);
    setIsHolidayDeleteModalOpen(true);
  };
  const closeHolidayDeleteModal = () => {
    setEditSelection({});
    setIsHolidayDeleteModalOpen(false);
  };

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => openHolidayDeleteModal(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={"20px"} />,
      },
      {
        text: "EDIT",
        onClick: () => openHolidayEditModal(row),
        renderIcon: () => <MiIcon path={mdiPencil} size={"20px"} />,
      },
    ];
  };

  const columns = [
    {
      field: "holidayName",
      headerName: "Holiday Name",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      renderCell: (p) => moment(p.value).utc().format(dateFormat),
      flex: 1,
    },
    {
      field: "endDate",
      headerName: "End Date",
      renderCell: (p) => moment(p.value).utc().format(dateFormat),
      flex: 1,
    },
  ];
  const getSelectedDate = (date) => {
    if (!date) {
      return null;
    }
    return moment(date).utc().format(dateFormat);
  };

  const isDateInvalid = (val) => {
    return val === "Invalid date";
  };
  const handleStartDateChange = (val) => {
    if (isDateInvalid(val)) {
      setStartDate(null);
      return;
    }
    setStartDate(val);
  };

  const handleEndDateChange = (val) => {
    if (isDateInvalid(val)) {
      setEndDate(null);
      return;
    }
    setEndDate(val);
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiCalendarStarOutline}
        color={theme.palette.primary.main}
      />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Add Holiday"
        icon={mdiPlus}
        inverse
        onClick={() => {
          setIsHolidayCreateModalOpen(true);
        }}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <div>
      <MiPageHeader
        title="Holidays"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
      />
      <MiModal
        open={isHolidayCreateModalOpen}
        title="Add A New Holiday"
        onClose={closeHolidayCreateModal}
        maxWidth="md"
        modalTitleStyle={{
          textAlign: "center",
          color: theme.palette.primary.main,
          fontWeight: 700,
          fontSize: theme.fontSize.xxlarge,
        }}
        actions={[
          {
            onClick: closeHolidayCreateModal,
            color: theme.palette.primary.grey,
            name: "Cancel",
          },
          {
            onClick: addHoliday,
            color: theme.palette.primary.main,
            name: "Save",
            inverse: true,
            disabled: !holidayName || !startDate || !endDate,
          },
        ]}
      >
        <div style={{ margin: 16 }}>
          <MiInputTextbox
            id="holidayName"
            labelText="Holiday Name"
            handleChange={handleHolidayNameChange}
            value={holidayName}
            required
          />
        </div>
        <div style={{ margin: 16 }}>
          <MiDatePickerInput
            type="date"
            value={getSelectedDate(startDate) || ""}
            handleChange={handleStartDateChange}
            labelText="Start Date"
            labelTextColor={theme.palette.primary.grey}
            inputFormat="MM/DD/YYYY"
            dateFormat={dateFormat}
            allowSameDateSelection
            required
          />
        </div>
        <div style={{ margin: 16 }}>
          <MiDatePickerInput
            type="date"
            value={getSelectedDate(endDate) || ""}
            handleChange={handleEndDateChange}
            labelText="End Date"
            labelTextColor={theme.palette.primary.grey}
            inputFormat="MM/DD/YYYY"
            dateFormat={dateFormat}
            allowSameDateSelection
            required
          />
        </div>
      </MiModal>

      <MiModal
        title="Edit Holiday"
        open={isHolidayEditModalOpen}
        onClose={closeHolidayEditModal}
        maxWidth="md"
        modalTitleStyle={{
          textAlign: "center",
          color: theme.palette.primary.main,
          fontWeight: 700,
          fontSize: theme.fontSize.xxlarge,
        }}
        actions={[
          {
            onClick: closeHolidayEditModal,
            color: theme.palette.primary.grey,
            name: "Cancel",
          },
          {
            onClick: updateHoliday,
            color: theme.palette.primary.main,
            name: "Save",
            inverse: true,
            disabled: !holidayName || !startDate || !endDate,
          },
        ]}
      >
        <div style={{ margin: 16 }}>
          <MiInputTextbox
            id="holidayName"
            labelText="Holiday Name"
            handleChange={handleHolidayNameChange}
            value={holidayName}
            required
          />
        </div>
        <div style={{ margin: 16 }}>
          <MiDatePickerInput
            type="date"
            value={getSelectedDate(startDate) || ""}
            handleChange={handleStartDateChange}
            labelText="Start Date"
            labelTextColor={theme.palette.primary.grey}
            inputFormat="MM/DD/YYYY"
            dateFormat={dateFormat}
            allowSameDateSelection
            required
          />
        </div>

        <div style={{ margin: 16 }}>
          <MiDatePickerInput
            type="date"
            value={getSelectedDate(endDate) || ""}
            handleChange={handleEndDateChange}
            labelText="End Date"
            labelTextColor={theme.palette.primary.grey}
            inputFormat="MM/DD/YYYY"
            dateFormat={dateFormat}
            allowSameDateSelection
            required
          />
        </div>
      </MiModal>
      <MiModalConfirm
        title={"Confirm Delete"}
        description={`Delete Holiday: ${editSelection.holidayName}?`}
        open={isHolidayDeleteModalOpen}
        handlePositive={deleteHoliday}
        handleNegative={closeHolidayDeleteModal}
      />
      <MiList
        data={holidays}
        setData={setHolidays}
        dataProvider={holidayService.getAll}
        columns={columns}
        defaultPageSize={25}
        paginationMode={PAGINATION.CLIENT}
        searchColumns={["holidayName"]}
        getRowActions={getRowActions}
        refresh={refresh}
      />
    </div>
  );
};
