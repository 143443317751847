import { STAGE_TYPES } from "../views/HomeSpecs/constants";

export const boundaryMismatch = (cadBoundaries, formData) => {
  const noMatch = (id) => {
    if (!cadBoundaries) {
      return;
    }
    if (id) {
      const boundary = cadBoundaries.find(
        (b) => b.measurementTypeId === Number(id)
      );

      if (boundary) {
        return false;
      }

      return true;
    }

    return false;
  };

  if (
    noMatch(formData.measurementBackTypeId) ||
    noMatch(formData.measurementSideTypeId)
  ) {
    return true;
  }

  return false;
};

const requiredFields = [
  "itemName",
  "walkTypeId",
  "measurementBackValue",
  "measurementSideValue",
  "measurementBackTypeId",
  "measurementSideTypeId",
  "stackSizeId",
];

export const isStackUnresolved = (details, cadBoundaries) => {
  const checkDetails = requiredFields.some((f) => !details[f]);
  if (checkDetails) {
    return true;
  }

  const measurementBackTypeId = details.measurementBackTypeId;
  const measurementSideTypeId = details.measurementSideTypeId;

  return boundaryMismatch(cadBoundaries, {
    measurementBackTypeId,
    measurementSideTypeId,
  });
};

export const itemIsIncomplete = (item, stageId) => {
  if (item.isTemplateItem) {
    return false;
  }
  switch (stageId) {
    case STAGE_TYPES.roughIn:
    case STAGE_TYPES.boxAndWrap:
      return item.stackSizeId && !item.cadImageX && !item.isDeleted;
    case STAGE_TYPES.topOut:
      return !item.topImageName && !item.topImageAdded;
    case STAGE_TYPES.trim:
    default:
      return false;
  }
};

export const countOpenItems = (planAreasPreview, stageId) => {
  if (!planAreasPreview.length) {
    return 0;
  }
  return planAreasPreview.reduce(
    (count, x) =>
      count +
      x.items?.reduce(
        (c, item) =>
          c +
          (item.stageId === stageId && itemIsIncomplete(item, stageId) ? 1 : 0),
        0
      ),
    0
  );
};

export const getMatchingItems = (planAreasPreview, stack, matchFields) => {
  if (!planAreasPreview.length || !matchFields?.length || stack === undefined) {
    return [];
  }

  return planAreasPreview.reduce(
    (matches, area) =>
      matches.concat(
        area.items?.filter(
          (i) =>
            stack.itemId !== i.itemId &&
            stack.propertySectionId === i.propertySectionId &&
            stack.stageId !== i.stageId &&
            matchFields.reduce((c, f) => c && stack[f] === i[f], true)
        )
      ),
    []
  );
};

export const getQueueItems = (planAreasPreview, stageId) => {
  if (!planAreasPreview.length || !stageId) {
    return [];
  }
  const queue = [];

  planAreasPreview.forEach((x, index) => {
    if (x.stageTypeId === stageId) {
      x.items.forEach((item) => {
        if (itemIsIncomplete(item, stageId)) {
          queue.push({ ...item, areaIndex: index });
        }
      });
    }
  });
  return queue;
};
