import moment from "moment";
import {
  TASK,
  WALK,
  ITEM,
  OPEN,
  CLOSED,
  ISSUE_STATUSES,
  ISSUE_STATUSES_CLOSED,
  ISSUE_STATUSES_OPEN,
  SIDE,
  BACK,
  ON_OPERATOR,
  IS_OPERATOR,
  OPERATOR_LIST,
} from "@miview/constants";
import {
  getGridDateOperators,
  GridFilterInputDate,
} from "@mui/x-data-grid-pro";
import { getCurrencyString } from "../currency/index";

import { bugsnag } from "../index.ts";
import { decode } from "base-64";
import { matchPath } from "react-router-dom";

export const groupBy = (array, criteria) => {
  return array.reduce(function (obj, item) {
    let key = typeof criteria === "function" ? criteria(item) : item[criteria];

    // eslint-disable-next-line
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }

    obj[key].push(item);
    return obj;
  }, {});
};

export const sectionMap = (array, field) => {
  let newArr = groupBy(array, (item) => item[field]);
  let sections = Object.entries(newArr).map(([title, data]) => ({
    title,
    data,
  }));
  return sections;
};

export const sortData = (data, field, sortDirection) => {
  let reverse = sortDirection !== "asc";
  data.sort(sortBy(field, reverse));
  return data;
};

export const sectionMapWithSortedSectionData = (
  array,
  field,
  sectionSortField,
  sortDirection
) => {
  let newArr = groupBy(array, (item) => item[field]);
  let sectionMapData = Object.entries(newArr).map(([title, data]) => ({
    title,
    data,
  }));

  sectionMapData = sortData(sectionMapData, "title", "asc");
  return sectionMapData.map((section) => {
    const sortedData = sortData(section.data, sectionSortField, sortDirection);
    return {
      title: section.title,
      data: sortedData,
    };
  });
};

export const simpleSort = (a, b) => (a > b) - (b > a);

export const getFieldValueFromList = (
  currentField,
  targetField,
  currentFieldValue,
  list,
  defaultValue
) => {
  const targetObj = list.find((val) => val[currentField] === currentFieldValue);
  if (!targetObj) {
    return defaultValue;
  }
  return targetObj[targetField] ? targetObj[targetField] : defaultValue;
};

export const getNextBusinessDay = (date) => {
  //pass any moment object or date string to date
  const selectedDay = moment(date).day();
  if (selectedDay === 5) {
    return moment(date).add(3, "d").format();
  } else if (selectedDay === 6) {
    return moment(date).add(2, "d").format();
  } else return moment(date).add(1, "d").format();
};

export const sortBy = (field, reverse, primer) => {
  let key = primer
    ? function (x) {
        return primer(x[field]);
      }
    : function (x) {
        return x[field];
      };
  reverse = !reverse ? 1 : -1;
  return function (a, b) {
    return (
      (a = key(a) ? key(a) : ""),
      (b = key(b) ? key(b) : ""),
      reverse * ((a > b) - (b > a))
    );
  };
};

export const sortPrimarySecondary = (arr, primary, secondary) => {
  const first = sortData(arr, primary, "asc");
  return sortData(first, secondary, "asc");
};

export const replaceAllUrl = (url, mapObj) => {
  let re = new RegExp(Object.keys(mapObj).join("|"), "gi");
  return url.replace(re, function (matched) {
    return mapObj[matched];
  });
};

export const isEmpty = (obj) => {
  if (typeof obj === "object") {
    for (let prop in obj) {
      // eslint-disable-next-line
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }
  return Array.isArray(obj) && obj.length === 0;
};

export const assert = (value, message, expected) => {
  if (expected === value) {
    return true;
  } else {
    throw message;
  }
};

export const getUniqueArray = (fieldA, fieldB, array) => {
  let newArray = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item[fieldA])) {
      map.set(item[fieldA], true); // set any value to Map
      newArray.push({
        value: item[fieldA],
        label: item[fieldB],
      });
    }
  }
  return newArray;
};

export const handleSearch = (data, search) => {
  if (search === null || data === undefined || search.trim() === "") {
    return data;
  }
  const filterByValue = (array, string) =>
    array.filter((values) =>
      Object.keys(values).some((item) =>
        values[item] !== undefined && typeof values[item] === "object"
          ? Object.values(values[item])
              .toString()
              .toLowerCase()
              .includes(string.toLowerCase())
          : values[item] !== undefined
          ? values[item].toString().toLowerCase().includes(string.toLowerCase())
          : null
      )
    );

  return filterByValue(data, search);
};

export const checkDependent = (arr, field, dependentValue) => {
  return arr.some((item) => item[field] === dependentValue);
};

export const checkFileImage = (file) => {
  return file.match(/.(jpg|jpeg|png|gif)$/i);
};

export const checkImageFileSize = (file) => {
  let base64str = file.substr(22);
  let decoded = atob(base64str);
  return decoded.length;
};

export const checkBase64 = (str) => {
  if (typeof str !== "string") {
    return null;
  }

  const splittedStr = str.split(",");
  if (splittedStr.length <= 1) {
    return false;
  }
  let decoded = atob(splittedStr[1]);
  return btoa(decoded) !== str;
};

export const toggleItem = (item) => {
  return !item;
};

export const setSelectedItem = (data, field, val) => {
  let sel = data.find((item) => item[field] === val);
  return sel;
};

export const equalTo = (input, val) => {
  return input === val;
};

export const createUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const sumField = (array, field) => {
  let sum = 0;
  if (array) {
    sum = array.reduce(function (prev, cur) {
      return prev + cur[field];
    }, 0);
  }

  return sum;
};

export const getFieldCounts = (objArray, field) => {
  const fieldCountArray = objArray.reduce(function (prev, curr) {
    if (!prev[curr[field]]) {
      prev[curr[field]] = 0;
    }
    prev[curr[field]]++;
    return prev;
  }, {});
  return fieldCountArray;
};

export const getArrayFieldCounts = (objArray, field, initial = {}) => {
  const fieldCountArray = objArray.reduce(function (prev, curr) {
    if (curr[field]) {
      curr[field].forEach((val) => {
        if (!prev[val]) {
          prev[val] = 0;
        }
        prev[val]++;
      });
    }
    return prev;
  }, initial);
  return fieldCountArray;
};

export const getStorageKey = (name, field) => {
  return name + "-" + field;
};

export const getValues = (image) => {
  try {
    bugsnag.leaveBreadcrumb("helpers::getValues");
    if (image.row?.hold) {
      return { ...image?.row };
    } else if (
      image?.row?.type === TASK ||
      image.row?.type === WALK ||
      image.punchType
    ) {
      return {
        failReason: "",
        issueCategoryId: "",
        assigneeId: "",
        propertyPlanAreaId: image?.row?.propertyPlanAreaId
          ? image.row.propertyPlanAreaId
          : "",
        abortWalk: false,
        scopeName: image?.row?.type === WALK ? WALK : ITEM,
        walkItemId: image.row?.itemId ? image?.row.id : null,
        walkId: image.row?.walkId,
        purchaseOrderAmount: "0",
      };
    } else {
      return { ...image?.row };
    }
  } catch (e) {
    bugsnag.setContext("helpers::getValues: Handle get values annotate photo");
    bugsnag.notify(e);
  }
};

export const getMinutes = (begin) => {
  let now = new Date().getTime(); //moment(new Date()); //todays date
  let startTime = new Date(begin).getTime(); // another date
  let totalTime = now - startTime;
  const duration = moment.duration(totalTime);
  return duration.minutes() + duration.hours() * 60;
};

export const getImageUrl = (item, cdn) => {
  if (item?.uri) {
    return item.uri;
  }

  if (item?.imageName) {
    return item.imageName.includes("http")
      ? item.imageName
      : cdn + item.imageName;
  }

  if (typeof item === "string") {
    return item.includes("http") ||
      item.includes("base64") ||
      item.includes("file://") ||
      item.startsWith("/")
      ? item
      : cdn + item;
  }

  return item;
};

export const getImageName = (item) => {
  if (item?.uri) {
    return item.uri;
  }

  if (item?.imageName) {
    return item.imageName.includes("http")
      ? item.imageName.split("/").pop()
      : item.imageName;
  }

  return item;
};

export const getDirectionURL = (property, platform, position) => {
  let end = property.propertyLatitude + "+" + property.propertyLongitude;
  let current = position.coords.latitude + "+" + position.coords.longitude;
  let url;
  if (platform === "ios") {
    url = `maps://app?saddr=${current}&daddr=${end}`;
  } else {
    end = end.replace("+", ",");
    url = `google.navigation:q=${end}`;
  }
  return url;
};

export const sortImagesByDate = (imgs) => {
  let sortedImgs = imgs;
  let dateField = "createTime";
  if (sortedImgs.length > 1) {
    // eslint-disable-next-line
    if (sortedImgs[0].hasOwnProperty("dateTaken")) {
      dateField = "dateTaken";
    }
    sortedImgs = sortedImgs
      .sort((a, b) => {
        return simpleSort(a[dateField], b[dateField]);
      })
      .reverse();
  }

  return sortedImgs;
};

export const chainOptions = (optionA, optionB) => {
  if (optionB) {
    return { ...optionA, chaining: true, child: { ...optionB } };
  }
  return optionA;
};

export const mapWalkItem = (items) => {
  return items.map((item) => {
    return {
      description: item.failReason,
      propertyPlanItemId: item.propertyPlanItemId,
    };
  });
};

export const getIssueStatusIds = (type) => {
  const statusIds = Object.values(ISSUE_STATUSES);
  const openStatuses = statusIds.filter((value) =>
    ISSUE_STATUSES_OPEN.includes(value)
  );

  if (type === OPEN) {
    return openStatuses;
  } else if (type === CLOSED) {
    return ISSUE_STATUSES_CLOSED;
  }
  return statusIds;
};

export const prefixBase64Type = (base64, type) => {
  return `data:${type || "image/png"};base64,` + base64;
};

export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

export const deepMergeObjects = (target, ...sources) => {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        deepMergeObjects(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepMergeObjects(target, ...sources);
};

export const removeSpaces = (string) => {
  return string.split(" ").join("");
};

export const getValueFormat = (val, fieldType) => {
  if (fieldType === "Date") {
    return moment(val).format("MM/DD/YY");
  }
  if (fieldType === "Currency") {
    return getCurrencyString(val);
  }
  return val;
};
export const keyByProp = (data, keyProp) => {
  let result = {};

  if (!Array.isArray(data)) {
    return result;
  }

  const key = `${keyProp}`;
  data.forEach((item) => {
    if (item[key]) {
      result[`${item[key]}`] = item;
    }
  });

  return result;
};

export const isUserValid = async () => {
  return null;
};

export const isTokenValid = async (token) => {
  try {
    const parts = token.split(".");
    let valid = false;
    if (parts[1]) {
      const tokenData = JSON.parse(decode(parts[1]));
      if (tokenData.exp) {
        valid = moment.unix(tokenData.exp).isSameOrAfter(moment(), "day");
      }
    }
    return valid;
  } catch (e) {
    return false;
  }
};

export const getManualMeasureImages = (item) => {
  let images = [];
  if (item?.images?.length > 0) {
    images.push({ ...item.images[0], context: TASK });
  }
  if (item?.measurementBackImageUri || item?.measurementBackImageUrl) {
    images.push({
      uri: item?.measurementBackImageUri || item?.measurementBackImageUrl,
      context: BACK,
    });
  }
  if (item?.measurementSideImageUri || item?.measurementSideImageUrl) {
    images.push({
      uri: item?.measurementSideImageUri || item?.measurementSideImageUrl,
      context: SIDE,
    });
  }

  if (images.includes(SIDE) && images.includes(BACK)) {
    images = images.filter((x) => x.context !== BACK);
  }

  return images;
};

export const generateBreadCrumbsData = (pathName, allRoutes) => {
  try {
    if (!Array.isArray(allRoutes) || !pathName) {
      return [];
    }

    const getMatchedRouteParams = (path, routes) => {
      let routeProps = null;
      routes.forEach((route) => {
        const match = matchPath(path, {
          path: route.path,
          exact: true,
          strict: false,
        });
        if (match) {
          routeProps = match;
        }
      });

      return routeProps;
    };

    const currentRouteParams = getMatchedRouteParams(pathName, allRoutes);
    return allRoutes
      .filter(
        ({ path }) => currentRouteParams?.path.includes(path) && path !== "/"
      )
      .map(({ path, ...rest }) => ({
        path: Object.keys(currentRouteParams.params).length
          ? Object.keys(currentRouteParams.params).reduce(
              (path, param) =>
                path.replace(`:${param}`, currentRouteParams.params[param]),
              path
            )
          : path,
        name: rest.name,
      }));
  } catch (e) {
    console.warn(e);
    return [];
  }
};

export const base64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const openInNewTab = (uri) => {
  const newWindow = window.open(uri, "_blank", "noopener,noreferrer");
  if (newWindow) {
    newWindow.opener = null;
  }
};

export * from "./filterHelper";

export const DATE_FILTER_OPERATORS = [
  {
    label: ON_OPERATOR,
    value: ON_OPERATOR,
    InputComponent: GridFilterInputDate,
    InputComponentProps: { type: "date" },
  },
  ...getGridDateOperators().filter(
    (e) => e.value !== IS_OPERATOR && OPERATOR_LIST.some((f) => e.value === f)
  ),
];
