import ViewArrayIcon from "@mui/icons-material/ViewArray";
import DescriptionIcon from "@mui/icons-material/Description";
import BallotIcon from "@mui/icons-material/Ballot";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { COLORS } from "./colors";

export const STEPS = [
  {
    id: 0,
    icon: LibraryBooksIcon,
    color: COLORS[0],
  },
  {
    id: 1,
    icon: BallotIcon,
    color: COLORS[1],
  },
  {
    id: 2,
    icon: DashboardIcon,
    color: COLORS[2],
  },
  {
    id: 3,
    icon: ViewArrayIcon,
    color: COLORS[0],
  },
  {
    id: 4,
    icon: DescriptionIcon,
    color: COLORS[1],
  },
];

// export const REVIEW_STEP = {
//   id: "review",
//   title: "Review and Complete",
//   route: "/review",
//   icon: MobileSVG,
//   color: COLORS.review
// };
