import React from "react";
import {
  ManageUsers,
  UserDetails,
  ManageRoles,
  RoleDetails,
  ManageAreas,
  Communities,
  Community,
  Cities,
  City,
  Properties,
  Property,
  StageWalkDetails,
  Titles,
  StageManagement,
  PunchListDetails,
  Support,
  LoginScreen,
  FailureReasons,
  StageDetails,
  Stages,
  AreaDetails,
  AreaCategories,
  AreaCategory,
  Materials,
  MaterialDetails,
  InspectionPreps,
  Builders,
  Builder,
  WalkTemplates,
  WalkTemplateDetails,
  Plans,
  PlanDetails,
  StageManagementDetails,
  HomeSpecs,
  MasterSpecs,
  SearchResults,
  MaterialCategories,
  StageWalkTypeDetails,
  AuditHistory,
  Manufacturers,
  BonusSettings,
  BonusSettingsEdit,
  AccountPayable,
  PaySchedules,
  PayPeriods,
  HolidaysPage,
  Walks,
  Trades,
} from "../views";
import PayActivity from "../views/PayActivities/PayActivity";
import { PERMISSIONS } from "@miview/constants";
export const routes = [
  {
    path: "login",
    name: "Login",
    element: <LoginScreen />,
    permissionName: PERMISSIONS.CAN_LOGIN_MITRADE_WEB,
  },
  { path: "support", name: "Support", element: <Support /> },
  {
    path: "users",
    exact: true,
    name: "Users",
    element: <ManageUsers />,
    permissionName: PERMISSIONS.CAN_VIEW_MANAGE_USERS,
  },
  {
    path: "users/:id",
    name: "User Details",
    element: <UserDetails />,
    permissionName: PERMISSIONS.CAN_VIEW_USERS,
  },
  {
    path: "profile",
    name: "User Details",
    element: <UserDetails />,
  },
  {
    path: "roles",
    exact: true,
    name: "Roles",
    element: <ManageRoles />,
    permissionName: PERMISSIONS.CAN_VIEW_MANAGE_ROLES,
  },
  {
    path: "roles/:id",
    name: "Role",
    element: <RoleDetails />,
    permissionName: PERMISSIONS.CAN_VIEW_ROLES,
  },
  {
    path: "",
    element: <Properties />,
    name: "Properties",
  },
  {
    path: "homes/:id",
    name: "Property Details",
    element: <Property />,
  },
  {
    path: "homes",
    exact: true,
    name: "Properties",
    element: <Properties />,
  },
  {
    path: "homes/:id/:activeTab",
    name: "Property Details",
    element: <Property />,
  },
  {
    path: "homes/:propertyId/specs/:stepIndex",
    name: "Home Specifications",
    element: <HomeSpecs />,
  },
  {
    path: "homes/:propertyId/specs",
    name: "Home Specifications",
    element: <HomeSpecs />,
  },
  {
    path: "communities",
    exact: true,
    name: "Communities",
    element: <Communities />,
    permissionName: PERMISSIONS.CAN_VIEW_COMMUNITIES,
  },
  {
    path: "communities/:id",
    name: "Community",
    element: <Community />,
    permissionName: PERMISSIONS.CAN_VIEW_COMMUNITIES,
  },
  {
    path: "cities",
    name: "Cities",
    element: <Cities />,
    permissionName: PERMISSIONS.CAN_VIEW_CITIES,
  },
  {
    path: "cities/:id",
    name: "City",
    element: <City />,
    permissionName: PERMISSIONS.CAN_VIEW_CITIES,
  },
  { path: "stages", exact: true, name: "Stages", element: <Stages /> },
  {
    path: "stages/:id",
    exact: true,
    name: "Stage Details",
    element: <StageDetails />,
  },
  { path: "walks/:id", name: "Walk Details", element: <StageWalkDetails /> },
  {
    path: "stagetypes",
    exact: true,
    name: "Stage Management",
    element: <StageManagement />,
  },
  {
    path: "stagetypes/:stageTypeId/walktypes/:id",
    exact: true,
    name: "Walk Type Management",
    element: <StageWalkTypeDetails />,
  },
  {
    path: "stagetypes/:id",
    name: "Stage Management",
    element: <StageManagementDetails />,
  },
  { path: "issues/:id", name: "Issue Details", element: <PunchListDetails /> },
  {
    path: "titles",
    name: "Titles",
    element: <Titles />,
    permissionName: PERMISSIONS.CAN_MANAGE_TITLES,
  },
  { path: "areas", exact: true, name: "Sections", element: <ManageAreas /> },
  {
    path: "areas/:id",
    exact: true,
    name: "Area Details",
    element: <AreaDetails />,
  },
  {
    path: "failurereasons",
    exact: true,
    name: "Failure Reasons",
    element: <FailureReasons />,
  },
  {
    path: "areacategories",
    exact: true,
    name: "Area Categories",
    element: <AreaCategories />,
  },
  {
    path: "areacategories/:id",
    exact: true,
    name: "Area Category Details",
    element: <AreaCategory />,
  },
  {
    path: "materialcategories",
    exact: true,
    name: "Material Categories",
    element: <MaterialCategories />,
    permissionName: PERMISSIONS.CAN_VIEW_MATERIALS,
  },
  {
    path: "materials",
    exact: true,
    name: "Materials",
    element: <Materials />,
    permissionName: PERMISSIONS.CAN_VIEW_MATERIALS,
  },
  {
    path: "materials/:id",
    exact: true,
    name: "Material Details",
    element: <MaterialDetails />,
    permissionName: PERMISSIONS.CAN_VIEW_MATERIALS,
  },
  {
    path: "builders",
    exact: true,
    name: "Builders",
    element: <Builders />,
    permissionName: PERMISSIONS.CAN_VIEW_BUILDERS,
  },
  {
    path: "builder/:id",
    name: "Builder",
    element: <Builder />,
    permissionName: PERMISSIONS.CAN_VIEW_BUILDERS,
  },
  {
    path: "plans/:planId/specs/:stepIndex",
    name: "Master Spec Builder",
    element: <MasterSpecs />,
  },
  {
    path: "plans/:planId/specs",
    name: "Master Spec Builder",
    element: <MasterSpecs />,
  },
  { path: "plans/:id", name: "Plan Details", element: <PlanDetails /> },
  { path: "plans", exact: true, name: "Plans", element: <Plans /> },
  {
    path: "templates",
    exact: true,
    name: "Templates",
    element: <WalkTemplates />,
    permissionName: PERMISSIONS.CAN_CREATE_LIST_TEMPLATES,
  },
  {
    path: "templates/:id",
    name: "Template Details",
    element: <WalkTemplateDetails />,
    permissionName: PERMISSIONS.CAN_CREATE_LIST_TEMPLATES,
  },
  {
    path: "search",
    exact: true,
    name: "Search Results",
    element: <SearchResults />,
  },
  {
    path: "trades",
    exact: true,
    name: "Trades",
    element: <Trades />,
  },
  {
    path: "audit",
    exact: true,
    name: "Audit History",
    element: <AuditHistory />,
    permissionName: PERMISSIONS.CAN_VIEW_AUDIT_HISTORY,
  },
  {
    path: "manufacturers",
    exact: true,
    name: "Manufacturers",
    element: <Manufacturers />,
    permissionName: PERMISSIONS.CAN_VIEW_MANUFACTURERS,
  },
  {
    path: "paysettings",
    exact: true,
    name: "Pay Settings",
    element: <BonusSettings />,
    permissionName: PERMISSIONS.CAN_MANAGE_PAY_CONFIGURATION,
  },
  {
    path: "paysettings/:id",
    name: "Pay Settings Edit",
    element: <BonusSettingsEdit />,
    permissionName: PERMISSIONS.CAN_MANAGE_PAY_CONFIGURATION,
  },
  {
    path: "accountpayable",
    exact: true,
    name: "Account Payable",
    element: <AccountPayable />,
    permissionName: PERMISSIONS.CAN_VIEW_ACCOUNTING,
  },
  {
    path: "payschedules",
    exact: true,
    name: "Pay Schedules",
    element: <PaySchedules />,
    permissionName: PERMISSIONS.CAN_MANAGE_PAY_SCHEDULES,
  },
  {
    path: "payschedules/:payScheduleId/payperiods",
    name: "PayPeriods",
    element: <PayPeriods />,
  },
  {
    path: "holidays",
    name: "Holidays",
    element: <HolidaysPage />,
    permissionName: PERMISSIONS.CAN_MANAGE_HOLIDAYS,
  },
  { path: "payactivities", name: "Pay Activities", element: <PayActivity /> },
  { path: "walks", exact: true, name: "Walks", element: <Walks /> },
  {
    path: "inspectionpreps",
    name: "Assignments",
    element: <InspectionPreps />,
  },
];
