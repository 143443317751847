import { makeStyles } from "tss-react/mui";

export const areaItemStyles = {
  areaItemBox: {
    boxShadow: "0px 0px 5px 2px rgba(235, 235, 235, 1)",
    width: "100%",
    borderRadius: "5px",
    display: "flex",
    minHeight: "50px",
    padding: "5px",
  },
  areaItemLabel: {
    "& p": {
      color: "#5c5c5c",
      marginTop: "0px",
      marginBottom: "0px",
      paddingLeft: "0.8rem",
    },
  },
  areaItemDetail: { flexGrow: 1 },
  areaItemEditIcon: {
    paddingLeft: "12px",
    cursor: "pointer",
    color: "gray",
    minWidth: "35px",
    "&: i": { fontSize: "16px" },
  },
  areaItemImageLabel: {
    fontSize: "0.8rem",
    color: "rgb(150, 148, 146)",
    marginBottom: "4px",
    paddingTop: "4px",
    textAlign: "center",
  },
  areaItemImagesContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  areaItemDescription: {
    "& p": {
      color: "#999999",
      paddingLeft: "0.8rem",
      marginBottom: "0",
    },
  },
  areaItemImage: {
    backgroundColor: "rgb(214, 214, 214)",
    height: "60px",
    width: "60px",
    borderRadius: "5px",
    border: "1px solid gray",
    margin: "5px",
    "& img": {
      height: "60px",
      width: "60px",
      borderRadius: "5px",
      border: "1px solid gray",
    },
    "& p": {
      color: "#999999",
      paddingLeft: "0.8rem",
      marginBottom: "0",
    },
  },
  areaItemMaterialSection: { padding: "5px" },
  createDrawingInput: {
    width: "0.1",
    height: "0.1",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },
  createDrawingLabel: { margin: "0", height: "40px", flex: 0 },
  areaItemAddMaterialButton: { marginTop: "8px" },
  areaItemMaterialsEdit: { display: "flex" },
  areaItemMaterialInput: { marginBottom: "0px" },
  areaLabelIconInner: { height: "20px", width: "20px" },
  areaItemRestoreIcon: { color: "green", opacity: 1 },
  wrapper: { padding: "5px 10px" },
};

export const useAreaItemStyles = makeStyles()(areaItemStyles);
