import React, { useEffect, useState } from "react";
import { MiPath, MiDetailsSideBar } from "../../components/Standard/MiView";
import {
  MiLink,
  MiSnackbar,
  MiTab,
  MiDetailFields,
  MiButton,
  MiCard,
  MiBox,
  MiPageHeader,
  MiIcon,
  MiFieldSet,
} from "@miview/components";
import { useTheme } from "@mui/material";
import { useRouter, useEdit, useComponentState } from "@miview/hooks";
import {
  accountServiceLegacy as accountService,
  planService,
  statusService,
  communityService,
} from "@miview/api";
import {
  STATUS_TYPES,
  DETAILS,
  ACCOUNT_TYPES,
  HTTP_STATUSES,
} from "@miview/constants";
import { toList } from "@miview/utils";
import { mdiArrowLeft, mdiHome } from "@mdi/js";

const PlanDetails = () => {
  const [plan, setPlan] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [builders, setBuilders] = useState([]);
  const [activeTab, setActiveTab] = useState(DETAILS);
  const [properties, setProperties] = useState([]);
  const [planStatuses, setPlanStatuses] = useState([]);
  const [communities, setCommunities] = useState([]);

  const router = useRouter();
  const edit = useEdit(plan);
  const stateManager = useComponentState();
  const theme = useTheme();

  const { id: planId } = router.params;

  useEffect(() => {
    document.title = plan ? `Plan - ${plan.name}` : "MiView - Plans";
    refresh();
    getAccounts();
    getStatuses();
    getCommunities();
    getProperties();
  }, []);

  const getCommunities = () => {
    stateManager.run(async () => {
      const response = await communityService.getAll({
        params: { pageSize: 9999 },
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "id", "name");
        setCommunities(mapped);
      }
    });
  };

  const getAccounts = () => {
    stateManager.run(async () => {
      const response = await accountService.getAll({
        params: { pageSize: 9999 },
        filters: { type: ACCOUNT_TYPES.BUILDER },
        sort: [{ field: "name", sort: "asc" }],
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "id", "name");
        setBuilders(mapped);
      }
    });
  };

  const getStatuses = () => {
    stateManager.run(async () => {
      const response = await statusService.getAll({
        category: STATUS_TYPES.PLANS,
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "id", "name");
        setPlanStatuses(mapped);
      }
    });
  };

  const refresh = () => {
    stateManager.run(async () => {
      const response = await planService.get(planId);
      if (response.status === HTTP_STATUSES.OK) {
        setPlan(response.data);
        edit.reset();
      }
    });
  };

  const getProperties = () => {
    stateManager.run(async () => {
      const response = await planService.getProperties(planId);
      if (response.status === HTTP_STATUSES.OK) {
        setProperties(response.data);
      }
    });
  };

  const cancelEdit = () => {
    edit.reset();
    setEditMode(false);
  };

  const saveEdits = () => {
    stateManager.run(async () => {
      const response = await planService.update(planId, edit.edits);
      if (response.status === HTTP_STATUSES.OK) {
        setEditMode(false);
        refresh();
      }
    });
  };

  const handleMasterSpecClick = () => router.push(`/plans/${planId}/specs/0`);
  const toggleEdit = () => setEditMode(!editMode);
  const handleNegative = (e) => (e < 0 ? 0 : e);

  const detailFields = [
    {
      label: "Name",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Community",
      fieldType: "select",
      value: edit.getValue("communityId"),
      options: communities,
      setValue: (e) => edit.update({ communityId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Builder",
      fieldType: "select",
      value: edit.getValue("builderId"),
      options: builders,
      setValue: (e) => edit.update({ builderId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Status",
      fieldType: "select",
      value: edit.getValue("statusId"),
      options: planStatuses,
      setValue: (e) => edit.update({ statusId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Number of Downstairs Full Baths",
      fieldType: "number",
      value: edit.getValue("numberOfFullBathsDown"),
      setValue: (e) =>
        edit.update({ numberOfFullBathsDown: handleNegative(e) }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
      minValue: 0,
    },
    {
      label: "Number of Upstairs Full Baths",
      fieldType: "number",
      value: edit.getValue("numberOfFullBathsUp"),
      setValue: (e) => edit.update({ numberOfFullBathsUp: handleNegative(e) }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
      minValue: 0,
    },
    {
      label: "Number of Downstairs Half Baths",
      fieldType: "number",
      value: edit.getValue("numberOfHalfBathsDown"),
      setValue: (e) =>
        edit.update({ numberOfHalfBathsDown: handleNegative(e) }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
      minValue: 0,
    },
    {
      label: "Number of Upstairs Half Baths",
      fieldType: "number",
      value: edit.getValue("numberOfHalfBathsUp"),
      setValue: (e) => edit.update({ numberOfHalfBathsUp: handleNegative(e) }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
      minValue: 0,
    },
  ];

  return (
    <>
      {stateManager.statusTag("planDetailStatus")}
      <MiPageHeader
        color={theme.palette.primary.main}
        title={edit.getValue("name")}
        loading={stateManager.isBusy()}
        leftIcon={
          <MiIcon
            style={{ cursor: "pointer" }}
            path={mdiArrowLeft}
            size={1.5}
            color={theme.palette.primary.main}
            onClick={router.goBack}
          />
        }
        details={
          <MiFieldSet
            hasDivider
            orientation="Horizontal"
            dividerOrientation="vertical"
            fieldSetArray={[
              { label: "Builder", value: edit.getValue("builderName") },
              { label: "Community", value: edit.getValue("community") },
            ]}
          />
        }
        action={
          <MiButton
            title="Configure Spec"
            variant="outlined"
            color={theme.palette.primary.purple}
            onClick={handleMasterSpecClick}
          />
        }
      />
      <MiSnackbar
        visible={editMode}
        handleCancelClick={cancelEdit}
        handleSaveClick={saveEdits}
        disableSave={!edit.getValue("name")}
      />
      <MiBox>
        <MiPath
          picklist={planStatuses.map((item) => {
            return { value: item.text };
          })}
          currentStatus={edit.getValue("status")}
          barWidth={300}
        />
      </MiBox>
      <div style={boxLvl1}>
        <MiBox>
          <div style={boxLvl2}>
            <MiTab
              tabs={[{ label: "Details", value: "Details" }]}
              onTabChange={setActiveTab}
              currenttab={activeTab}
            />
          </div>
          <div style={flexColumn}>
            {activeTab === DETAILS && (
              <>
                {builders && (
                  <MiDetailFields
                    detailFields={detailFields}
                    headerProps={{
                      icon: "list",
                      title: "Detail Fields",
                    }}
                  />
                )}
              </>
            )}
          </div>
        </MiBox>
        <MiDetailsSideBar titleWidth={200} sidebarTitle="Homes">
          {plan &&
            properties.length > 0 &&
            properties.map((home) => {
              return (
                <MiCard
                  key={home.propertyId}
                  style={{ paddingBottom: 7 }}
                  color={theme.palette.primary.main}
                  icon={mdiHome}
                  title={
                    <MiLink
                      title={home.addressLine1}
                      to={"/homes/" + home.propertyId}
                      style={{
                        paddingLeft: 0,
                        marginLeft: 0,
                        whiteSpace: "unset",
                        overflowWrap: "break-word",
                      }}
                    />
                  }
                  fieldset={[
                    { label: "Stage:", value: home.propertyStageName },
                  ]}
                />
              );
            })}
        </MiDetailsSideBar>
      </div>
    </>
  );
};

const flexColumn = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

const flexRow = {
  display: "flex",
  flexDirection: "row",
};

const boxLvl1 = {
  ...flexRow,
  flex: 1,
  width: "100%",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  paddingTop: "1rem",
};

const boxLvl2 = {
  marginBottom: 20,
  paddingTop: 5,
};

export default PlanDetails;
