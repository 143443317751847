import React, { useState } from "react";
import { MiLink, MiPageHeader, MiList, Chip, MiIcon } from "@miview/components";
import { walkService } from "@miview/api";
import { DATE_FILTER_OPERATORS } from "@miview/utils";
import {
  WALK_STATUS_OPTIONS,
  WALK_RECORD_TYPES_OPTIONS,
  ICON_STATUSES,
} from "@miview/constants";
import { useComponentState } from "@miview/hooks";
import { formatDate } from "../../helpers/dateTools";
import { useTheme, alpha } from "@mui/material";
import { mdiClipboardTextSearchOutline } from "@mdi/js";

const Walks = () => {
  const [walks, setWalks] = useState([]);
  const stateManager = useComponentState();
  const theme = useTheme();

  const getColor = (val) => {
    return ICON_STATUSES[val]?.color || theme.palette.primary.blue;
  };

  const columns = [
    {
      field: "walkTypeName",
      headerName: "Walks",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 0.6,
      renderCell: (p) => {
        return <MiLink to={`/walks/${p.row.id}`} title={p.value} />;
      },
    },
    {
      field: "addressLine1",
      headerName: "Address",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1.2,
      renderCell: (p) => {
        return (
          <MiLink to={`/homes/${p.row.propertyId}/Details`} title={p.value} />
        );
      },
    },
    {
      field: "cityName",
      headerName: "City",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 0.7,
    },
    {
      field: "communityName",
      headerName: "Community",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "builderName",
      headerName: "Builder",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "assignedUserName",
      headerName: "Assigned To",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "scheduledStartDate",
      headerName: "Schedule Date",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      type: "date",
      valueFormatter: (params) => formatDate(params.value),
      filterOperators: DATE_FILTER_OPERATORS,
    },
    {
      field: "walkRecordType",
      headerName: "Record Type",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      type: "singleSelect",
      valueOptions: WALK_RECORD_TYPES_OPTIONS.map((item) => item.text),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      align: "left",
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <Chip
            text={value}
            style={{
              display: "flex",
              borderRadius: "30px",
              height: "30px",
              minWidth: "80px",
              justifyContent: "center",
              padding: "0 7px 0 7px",
            }}
            color={alpha(getColor(value), 0.2)}
            textColor={getColor(value)}
          />
        );
      },
      type: "singleSelect",
      valueOptions: WALK_STATUS_OPTIONS.map((item) => item.text),
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiClipboardTextSearchOutline}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const getWalks = async ({ sort, ...rest }) => {
    return stateManager.run(async () => {
      return walkService.getAll({
        sort:
          sort && sort.length
            ? sort
            : [{ field: "scheduledStartDate", sort: "desc" }],
        ...rest,
      });
    });
  };

  return (
    <>
      <MiPageHeader
        title="Walks"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        loading={stateManager.isBusy()}
      />
      {stateManager.statusTag("walkListStatus")}
      <MiList
        data={walks}
        setData={setWalks}
        dataProvider={getWalks}
        defaultPageSize={50}
        columns={columns}
        columnVisibilityModel={{ walkRecordType: false }}
        hideFilters
        customFooter
      />
    </>
  );
};

export default Walks;
