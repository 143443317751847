import React, { useEffect } from "react";
import { useTheme, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiModal, MiInputTextbox, MiInputSelectBox } from "@miview/components";
import en from "../../../locale/en.json";
import { useEdit } from "@miview/hooks";

const useStyles = makeStyles()(() => ({
  formWidth: {
    width: 524,
  },
}));

const CreateNewTemplateModal = ({
  closeCreateTemplateModal,
  createTemplate,
  showModal,
  folders,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const edit = useEdit({});

  const requiredFields = ["name"];

  const getHelperText = (field, message) => {
    const val = edit.getValue(field);
    if (!val) {
      return {
        error: true,
        helperText: message,
      };
    }
    return {
      error: false,
      helperText: "",
    };
  };

  useEffect(() => {
    if (!showModal) {
      edit.reset();
    }
  }, [showModal]);

  const handleSubmitLocation = () => {
    if (edit.allFilled(...requiredFields)) {
      const payload = { ...edit.edits };
      createTemplate(payload);
    }
  };

  const actions = [
    {
      onClick: closeCreateTemplateModal,
      color: theme.palette.primary.grey,
      name: "Close",
      inverse: false,
    },
    {
      onClick: handleSubmitLocation,
      color: theme.palette.primary.green,
      name: "Submit",
      inverse: true,
    },
  ];

  return (
    <MiModal title={"Create New Template"} open={showModal} actions={actions}>
      <Grid container className={classes.formWidth}>
        <Grid item xs={6} style={{ margin: "auto" }}>
          <MiInputTextbox
            {...getHelperText("name", en.notEnteredName)}
            labelText={en.name}
            value={edit.getValue("name")}
            handleChange={(e) => edit.update({ name: e.target.value })}
            minWidth={228}
            required
          />
          <MiInputTextbox
            labelText={en.Description}
            value={edit.getValue("description")}
            handleChange={(e) => edit.update({ description: e.target.value })}
          />
          <MiInputSelectBox
            labelText={en.folderLocation}
            name="templateFolderId"
            variant="outlined"
            menuItems={folders}
            labelField={"name"}
            valueField={"id"}
            value={edit.getValue("templateFolderId") || ""}
            handleChange={(e) => {
              return edit.update({ templateFolderId: e.target.value });
            }}
          />
          <MiInputTextbox
            labelText={en.tag}
            value={edit.getValue("tag")}
            handleChange={(e) => edit.update({ tag: e.target.value })}
          />
        </Grid>
      </Grid>
    </MiModal>
  );
};

export default React.memo(CreateNewTemplateModal);
