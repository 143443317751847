import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, { props }) => ({
  selected: {
    color: props.selectedFontColor || theme.palette.primary.blue,
    backgroundColor: props.selectedBackgroundColor || theme.palette.light.blue,
    textTransform: "none",
    transition: "background-color 0.01s ease,color 0.01s ease",
    "&:hover": {
      backgroundColor:
        props.selectedBackgroundColor || theme.palette.light.blue,
    },
  },

  UnSelected: {
    borderColor: theme.palette.secondary.grey || props?.UnSelectedBorderColor,
    color: props.UnSelectedFontColor || theme.palette.primary.grey,
    backgroundColor:
      props.UnSelectedBackgroundColor || theme.palette.primary.white,
    textTransform: "none",
    transition: "background-color 0.01s ease,color 0.01s ease",
  },
}));

export const MiButtonGroup = ({
  data,
  selectedBackgroundColor,
  selectedFontColor,
  UnSelectedBackgroundColor,
  UnSelectedFontColor,
  orientation,
  handleClick,
  selected,
  ...rest
}) => {
  const { classes } = useStyles({
    props: {
      selectedBackgroundColor,
      selectedFontColor,
      UnSelectedBackgroundColor,
      UnSelectedFontColor,
    },
  });
  return (
    <ButtonGroup
      data-testid={"button-group"}
      className={classes.buttonGroup}
      aria-label="outlined primary button group"
      orientation={orientation}
    >
      {data.map((item, index) => {
        return (
          <Button
            data-testid={".mui-button"}
            key={index}
            onClick={() => handleClick(item)}
            className={
              item.value === selected ? classes.selected : classes.UnSelected
            }
            value={item.value}
            {...rest}
          >
            {item.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

MiButtonGroup.propTypes = {
  /**
   * Array of objects.Should have key named value.
   * ex:  data={[{ value: 1, label: "One" }, { value: 2, label:"Two" }, { value: 3, label: "Three" }]}
   */
  data: PropTypes.array,
  /**
   * selected button background color.
   */
  selectedBackgroundColor: PropTypes.string,
  /**
   * selected button font color.
   */
  selectedFontColor: PropTypes.string,
  /**
   * Not selected button background color.
   */
  UnSelectedBackgroundColor: PropTypes.string,
  /**
   * Not selected button font color.
   */
  UnSelectedFontColor: PropTypes.string,
  /**
   * orientation of button group. Ex 'horizontal' or 'vertical'
   */
  orientation: PropTypes.string,
  /**
   * Function to handle change of selected value
   */
  handleClick: PropTypes.func,
  /**
   * selected value when the button is clicked
   */
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MiButtonGroup.defaultProps = {
  data: [],
};
