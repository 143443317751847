import React, { useState } from "react";
import { Typography, useTheme, alpha } from "@mui/material";
import {
  MiPageHeader,
  MiButton,
  MiMoreActionItem,
  MiModalConfirm,
  MiLink,
  MiChip,
  MiList,
} from "@miview/components";
import { useComponentState } from "@miview/hooks";
import en from "../../../locale/en.json";
import { StatusChip } from "../../../components";
import { ACCOUNT_TYPE } from "./../../../constants";
import {
  ROW_ACTIONS,
  HTTP_STATUSES,
  ACCOUNT_STATUS,
  ACCOUNT_STATUS_COLOR,
} from "@miview/constants";
import AddNewSupplierModal from "./AddNewSupplierModal";
import SupplierBranchesDrawer from "./SupplierBranchesDrawer";
import { accountService } from "@miview/api";
import { createFilter } from "@miview/utils";
import { mdiTrashCanOutline, mdiUpdate } from "@mdi/js";

export const Suppliers = () => {
  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const theme = useTheme();
  const stateManager = useComponentState();

  const getBranchesCountColor = (status) => {
    const color = ACCOUNT_STATUS_COLOR[status];
    return color ? color : theme.palette.primary.grey;
  };

  const columns = [
    {
      field: "number",
      headerName: en.accountNumber,
      flex: 1,
      renderCell: (param) => {
        return (
          <MiLink
            title={param.value}
            to={`suppliers/${param.row.id}/general`}
          />
        );
      },
    },
    {
      field: "name",
      headerName: en.name,
      flex: 2,
      renderCell: (params) => {
        return <Typography>{params.value}</Typography>;
      },
    },
    {
      field: "code",
      headerName: en.shortName,
      flex: 1,
    },
    {
      field: "branchCount",
      headerName: en.branches,
      flex: 1,
      filterable: false,

      renderCell: (params) => {
        return (
          <MiChip
            label={params.value.toString()}
            style={{
              backgroundColor: alpha(
                getBranchesCountColor(params.row.status),
                0.18
              ),
            }}
            onClick={() => handleDrawer(params.row)}
          />
        );
      },
    },
    {
      field: "status",
      headerName: en.status,
      flex: 1,
      renderCell: (params) => <StatusChip status={params.value} />,
      type: "singleSelect",
      valueOptions: [
        ACCOUNT_STATUS.active,
        ACCOUNT_STATUS.inactive,
        ACCOUNT_STATUS.incomplete,
      ],
    },
  ];

  const handleDrawer = (row) => {
    setIsDrawerOpen(true);
    setSelectedSupplier(row);
  };

  const handleClose = () => {
    setIsCreateModalOpen(false);
    setRefresh(!refresh);
  };

  const handleNewSupplierModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleUpdate = async () => {
    const updatedStatus =
      selectedRow.status === ACCOUNT_STATUS.active
        ? ACCOUNT_STATUS.inactive
        : ACCOUNT_STATUS.active;
    stateManager.run(async () => {
      const response = await accountService.accounts.update({
        id: selectedRow.id,
        data: {
          status: updatedStatus,
        },
        toastMessageConfig: {
          successMessage: `${selectedRow.name} updated successfully to ${updatedStatus}`,
        },
      });
      if (response.status === HTTP_STATUSES.OK) {
        const newSuppliers = [...supplierList];
        newSuppliers.splice(
          supplierList.indexOf(selectedRow),
          1,
          response.data
        );
        setSupplierList(newSuppliers);
        closeUpdateModal();
      }
    });
  };

  const handleDelete = async () => {
    stateManager.run(async () => {
      const response = await accountService.accounts.delete({
        id: selectedRow.id,
        successMessage: `Successfully Deleted ${selectedRow.name}`,
      });
      if (response.status === HTTP_STATUSES.NO_CONTENT) {
        setSupplierList(supplierList.filter((e) => e.id !== selectedRow.id));
        closeDeleteModal();
      }
    });
  };

  const handleShowConfirmDelete = (row) => {
    setSelectedRow(row);
    setIsDeleteModalOpen(true);
  };

  const handleShowUpdate = (row) => {
    setSelectedRow(row);
    setIsUpdateModalOpen(true);
  };

  const getRowActions = () => {
    return [
      {
        renderIcon: (row) => {
          return (
            <MiMoreActionItem
              type={
                row.status === ACCOUNT_STATUS.active
                  ? ROW_ACTIONS.inActive
                  : ROW_ACTIONS.active
              }
            />
          );
        },
        onClick: handleShowUpdate,
      },
      {
        renderIcon: () => <MiMoreActionItem type={ROW_ACTIONS.delete} />,
        onClick: handleShowConfirmDelete,
      },
    ];
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedRow(null);
  };

  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
    setSelectedRow(null);
  };

  const actionButton = () => {
    return (
      <MiButton
        color={theme.palette.primary.main}
        inverse={true}
        title={en.addNewSupplier}
        onClick={handleNewSupplierModal}
        style={{
          fontSize: theme.fontSize.medium,
          fontWeight: theme.fontWeight.medium,
        }}
      />
    );
  };

  const getSuppliers = async ({ ...params }) => {
    return stateManager.run(async () => {
      const filters = createFilter().equals({
        parentId: null,
        accountType: ACCOUNT_TYPE.SUPPLIER,
      });
      if (params.filters?.items.length > 0) {
        filters.items = filters.items.concat(params.filters.items);
      }
      params.filters = filters;
      return accountService.accounts.getAll(params);
    });
  };

  const renderDeleteModal = () => {
    return (
      <MiModalConfirm
        open={isDeleteModalOpen}
        title={"Delete Account"}
        description={"Are you sure you want to delete this account?"}
        icon={mdiTrashCanOutline}
        color={theme.palette.primary.red}
        handlePositive={handleDelete}
        handleNegative={closeDeleteModal}
      />
    );
  };

  const renderUpdateModal = () => {
    const status =
      selectedRow?.status === ACCOUNT_STATUS.active
        ? ACCOUNT_STATUS.inactive
        : ACCOUNT_STATUS.active;
    return (
      <MiModalConfirm
        open={isUpdateModalOpen}
        title={`Make Account ${status}?`}
        description={`Are you sure you would like to make this account ${status}?`}
        icon={mdiUpdate}
        color={theme.palette.primary.main}
        handlePositive={handleUpdate}
        handleNegative={closeUpdateModal}
      />
    );
  };

  return (
    <>
      {stateManager.statusTag("suppliersList")}
      {isDrawerOpen && (
        <SupplierBranchesDrawer
          onCancel={() => setIsDrawerOpen(false)}
          data={selectedSupplier}
        />
      )}
      <MiPageHeader
        title={en.suppliers}
        color={theme.palette.primary.main}
        hasDivider={true}
        action={actionButton()}
        loading={stateManager.isBusy()}
      />
      <MiList
        data={supplierList}
        setData={setSupplierList}
        dataProvider={getSuppliers}
        columns={columns}
        getRowActions={getRowActions}
        getRowClassName={(params) =>
          params.row.status === ACCOUNT_STATUS.inactive ? "inActiveRow" : ""
        }
        refresh={refresh}
      />
      {renderDeleteModal()}
      {renderUpdateModal()}
      <AddNewSupplierModal handleClose={handleClose} open={isCreateModalOpen} />
    </>
  );
};
