import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import { MiListFilterHeader } from "@miview/components";
import { BOOL_VALUE } from "../../../constants";

const useStyles = makeStyles()(() => ({
  marginBottom: {
    width: "100%",
    marginBottom: 10,
  },
}));

const NewHomesFilter = ({
  handleFilterChange,
  onSearchChange,
  handleRefreshClick,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const [chipId, setChipId] = useState([
    {
      id: "all",
      status: "All Reqs",
    },
  ]);
  const [filterIcon, setFilterIcon] = useState(!BOOL_VALUE);

  const handleChipChange = (ID) => {
    handleFilterChange(ID[0].id, "status");
    setChipId(ID);
  };
  const handleFilterIconClick = () => {
    setFilterIcon(!filterIcon);
  };
  const handleRefresh = () => {
    setChipId([
      {
        id: "all",
        status: "All Reqs",
      },
    ]);
    handleFilterIconClick();
    handleRefreshClick();
  };

  return (
    <div className={classes.marginBottom}>
      <MiListFilterHeader
        featureFilterStyles={{
          display: "none",
        }}
        filterArray={[
          {
            type: "filterAction",
            activeColor: theme.palette.primary.main,
            color: theme.palette.accent.grey,
            handleChange: handleChipChange,
            values: [
              {
                id: "all",
                status: "All Reqs",
              },
              {
                id: "draft",
                status: "Draft",
              },
              {
                id: "open",
                status: "Open",
              },
              {
                id: "on order",
                status: "On Order",
              },
              {
                id: "voided",
                status: "Voided",
              },
              {
                id: "complete",
                status: "Complete",
              },
            ],
            selectedItems: chipId,
            size: "medium",
            chipType: "radio",
            valueField: "status",
          },
        ]}
        handleRefreshClick={handleRefresh}
        handleSearch={onSearchChange}
        handleChange={handleFilterChange}
        handleFilterIconClick={handleFilterIconClick}
        filterIcon={filterIcon}
      />
    </div>
  );
};

export default NewHomesFilter;
