import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ExpandMore, AccountTree, FileCopy } from "@mui/icons-material";
import { THEME, MiModal } from "../../../components/Standard/MiView";
import { useRouter } from "@miview/hooks";
import { MiButton } from "@miview/components";
import { planService, seriesService } from "@miview/api";
import { createFilter } from "@miview/utils";
import { AND_FILTER, HTTP_STATUSES } from "@miview/constants";
import { MiInputTextbox, MiInputSelectBox } from "@miview/components";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    boxShadow: "none",
    "&::before": {
      backgroundColor: "transparent",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const NewSeriesModal = ({ onCancel, onSave, onToggle }) => {
  const [formData, setFormData] = useState({ name: "" });

  const updateData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <MiModal
      title="Create New Series"
      cancelAction={onCancel}
      saveAction={() => onSave(formData)}
      saveTitle="SAVE"
      color={THEME.BLUE_PRIMARY}
      toggle={onToggle}
    >
      <div>
        <MiInputTextbox
          labelText="Series Name"
          styles={{ width: "100%" }}
          value={formData.name}
          required
          name="name"
          handleChange={updateData}
        />
      </div>
    </MiModal>
  );
};

const NewPlanModal = ({ builderId, onCancel, onSave, onToggle }) => {
  const [formData, setFormData] = useState({ name: "", templatePlanId: "" });
  const [templatePlans, setTemplatePlans] = useState([]);

  const handleGetTemplatePlans = async () => {
    const filter = createFilter(AND_FILTER);
    filter.equals({ builderId });
    const results = await planService.getPlans({ filters: filter.getFilter() });
    const templateList = results.data.map((t) => ({
      text: t.planName,
      value: t.planId,
    }));

    setTemplatePlans(templateList);
  };

  useEffect(() => {
    handleGetTemplatePlans();
  }, []);

  const updateData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <MiModal
      title="Create New Series Plan"
      cancelAction={onCancel}
      saveAction={() => onSave(formData)}
      saveTitle="SAVE"
      color={THEME.BLUE_PRIMARY}
      toggle={onToggle}
    >
      <div>
        <MiInputSelectBox
          labelText="Select a Template"
          required
          labelField="text"
          name="templatePlanId"
          defaultOption="--"
          options={templatePlans}
          value={formData.templatePlanId}
          handleChange={(e) => {
            const text = templatePlans.find(
              (t) => t.value === Number(e.target.value)
            )?.text;
            setFormData({
              ...formData,
              [e.target.name]: e.target.value,
              name: text || "",
            });
          }}
          styles={{ width: "100%" }}
        />
        <MiInputTextbox
          labelText="Plan Name"
          styles={{ width: "100%" }}
          value={formData.name}
          required
          name="name"
          handleChange={updateData}
        />
      </div>
    </MiModal>
  );
};

const BuilderSeries = ({ builderId }) => {
  const { classes } = useStyles();
  const { push } = useRouter();
  const [series, setSeries] = useState([]);
  const [newSeriesModal, setNewSeriesModal] = useState(false);
  const [newPlanSeriesId, setNewPlanSeriesId] = useState(null);

  useEffect(() => {
    handleGetSeries();
  }, []);

  const handleGetSeries = async () => {
    const result = await seriesService.getAll({ accountId: builderId });
    if (result.status === HTTP_STATUSES.OK) {
      setSeries(result.data);
    }
  };

  const handleSaveSeries = async (formData) => {
    const requestPayload = {
      name: formData.name,
      accountId: builderId,
    };
    await seriesService.create(requestPayload);
    setNewSeriesModal(false);
    handleGetSeries();
  };

  const handleSaveSeriesPlan = async (formData) => {
    const requestPayload = {
      name: formData.name,
      seriesId: newPlanSeriesId,
      planId: formData.templatePlanId,
    };
    await seriesService.createPlan(newPlanSeriesId, requestPayload);
    setNewPlanSeriesId(null);
    handleGetSeries();
  };

  const renderSeriesPlanList = (plans) => {
    return plans.map((plan) => {
      return (
        <div key={plan.id} style={{ height: 50 }}>
          <div
            onClick={() => push(`/plans/${plan.id}`)}
            style={{
              border: `1px solid ${THEME.GREY_LIGHT}`,
              color: THEME.PURPLE_SECONDARY,
              borderRadius: 5,
              width: "100%",
              margin: 10,
              height: 50,
              display: "flex",
              alignItems: "center",
              paddingLeft: 18,
              cursor: "pointer",
            }}
          >
            <div>{plan.name} (V1.0)</div>
            <div style={{ flex: 1 }} />
            <div style={{ color: THEME.BLUE_PRIMARY, marginRight: 10 }}>
              <FileCopy />
            </div>
            <div style={{ color: THEME.ORANGE_PRIMARY, marginRight: 26 }}>
              <AccountTree />
            </div>
          </div>
          <br />
        </div>
      );
    });
  };

  const renderSeriesList = () => {
    return series.map((s, i) => {
      const handleClickNewPlan = (e) => {
        e.stopPropagation();
        setNewPlanSeriesId(s.id);
      };

      return (
        <Accordion key={i} className={classes.root}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
              backgroundColor: THEME.GREY_LIGHT_BACKGROUND,
              marginTop: 10,
              marginBottom: 10,
              borderRadius: 6,
              borderTop: "0px solid white",
            }}
          >
            <div
              style={{
                paddingTop: 8,
                color: THEME.BLUE_PRIMARY,
              }}
            >
              {s.name}
            </div>
            <div style={{ flex: 1 }}></div>
            <MiButton
              style={{
                backgroundColor: THEME.BLUE_PRIMARY,
                borderRadius: 5,
                border: "0px solid white",
                color: "white",
                width: 120,
              }}
              title="New Plan"
              onClick={handleClickNewPlan}
              disabled={false}
            />
          </AccordionSummary>
          <AccordionDetails style={{ display: "block", boxShadow: "none" }}>
            <Fragment>
              {s.plans.length
                ? renderSeriesPlanList(s.plans)
                : "No plans added yet"}
            </Fragment>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }} />
        <MiButton
          style={{
            backgroundColor: THEME.GREEN_PRIMARY,
            borderRadius: 5,
            border: "0px solid white",
            color: "white",
            width: 120,
            marginBottom: 20,
          }}
          title="New Series"
          onClick={() => setNewSeriesModal(true)}
          disabled={false}
        />
      </div>
      {renderSeriesList()}
      {newSeriesModal && (
        <NewSeriesModal
          onSave={handleSaveSeries}
          onToggle={() => setNewSeriesModal(false)}
          onCancel={() => setNewSeriesModal(false)}
        />
      )}
      {newPlanSeriesId ? (
        <NewPlanModal
          builderId={builderId}
          onSave={handleSaveSeriesPlan}
          onToggle={() => setNewPlanSeriesId(null)}
          onCancel={() => setNewPlanSeriesId(null)}
        />
      ) : null}
    </div>
  );
};

export default BuilderSeries;
