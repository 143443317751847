import React, { useState, useEffect } from "react";
import { MiDragDropZone } from "../../../components/Standard/MiView";
import { createToast } from "@miview/toast";
import {
  propertyService,
  systemTypeService,
  drawingService,
} from "@miview/api";
import {
  SYSTEM_TYPE_CATEGORIES,
  TOAST_TYPES,
  VALID_FILE_TYPES_FOR_DOCUMENT_UPLOAD,
  HTTP_STATUSES,
} from "@miview/constants";
import {
  MiModal,
  MiModalConfirm,
  MiButton,
  MiCard,
  MiIcon,
  MiDetailFields,
} from "@miview/components";
import {
  getImageUrl,
  getImageBase64FromFile,
  openInNewTab,
} from "@miview/utils";
import moment from "moment";
import { useTheme } from "@mui/material";
import { mdiClose, mdiFileDocument, mdiPlus } from "@mdi/js";
import { useEdit, useCdn } from "@miview/hooks";

export const DrawingsCollapse = (props) => {
  const { propertyId } = props;
  const [attachModalOpen, setAttachModalOpen] = useState(false);
  const [drawings, setDrawings] = useState([]);
  const [drawingCategoryTypes, setDrawingCategoryTypes] = useState([]);
  const [drawingTypes, setDrawingTypes] = useState([]);
  const [selectedDrawingCategoryType, setSelectedDrawingCategoryType] =
    useState();
  const [drawingCategoryTypeOptions, setDrawingCategoryTypeOptions] = useState(
    []
  );
  const [disableAdd, setDisableAdd] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState();
  const [showDeleteAttachmentModal, setShowDeleteAttachmentModal] =
    useState(false);
  const [attachmentToDeleteId, setAttachmentToDeleteId] = useState();
  const [status, setStatus] = useState("Loading...");
  const UPLOAD_TYPE_GENERAL = 207;
  const theme = useTheme();
  const edit = useEdit({
    type: "",
    category: "",
    attachment: "",
    notes: "",
  });
  const cdn = useCdn();

  useEffect(() => {
    getDrawings();
    getDrawingTypes();
    getDrawingCategoryTypes();
  }, []);

  useEffect(() => {
    if (drawings) {
      checkOkayToUpload();
    }
  }, [edit.getValue("type")]);

  const getDrawings = async () => {
    setStatus("Loading...");
    const response = await propertyService.getDrawings(propertyId);
    if (response.status === HTTP_STATUSES.OK) {
      setDrawings(response.data);
      setStatus("");
    }
  };

  const getDrawingTypes = async () => {
    const response = await systemTypeService.getSystemTypesByName({
      name: SYSTEM_TYPE_CATEGORIES.DRAWING_TYPE,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setDrawingTypes(response.data);
    }
  };

  const getDrawingCategoryTypes = async () => {
    const response = await systemTypeService.getSystemTypesByName({
      name: SYSTEM_TYPE_CATEGORIES.DRAWING_CATEGORY_TYPE,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setDrawingCategoryTypes(response.data);
    }
  };

  const checkForDocTypeDuplicate = (drawingType, category) => {
    if (drawingType === UPLOAD_TYPE_GENERAL) {
      return false;
    }

    const duplicatedType = drawings.filter(
      (x) => x.drawingTypeId === drawingType && x.categoryId === category
    );
    return duplicatedType.length > 0;
  };

  const checkOkayToUpload = () => {
    const okayToUpload = !checkForDocTypeDuplicate(
      edit.getValue("type"),
      selectedDrawingCategoryType
    );
    if (okayToUpload) {
      setValidationErrorMessage(undefined);
      setDisableAdd(false);
    } else {
      setValidationErrorMessage(
        "There is already a document of that type and category uploaded."
      );
      setDisableAdd(true);
    }
  };

  const checkValidFiles = () => {
    return VALID_FILE_TYPES_FOR_DOCUMENT_UPLOAD.includes(
      edit.getValue("fileType")
    );
  };

  const uploadAttachment = () => {
    if (!edit.allFilled("type", "attachment", "category") || disableAdd) {
      return;
    }
    if (!checkValidFiles()) {
      createToast(
        `${edit.getValue(
          "fileType"
        )} is not valid File Type. Please select a different file`,
        TOAST_TYPES.ERROR
      );
      return;
    }

    setStatus("Uploading...");
    setDisableAdd(true);

    const uploadObj = {
      categoryId: edit.getValue("category"),
      drawingTypeId: edit.getValue("type"),
      Note: edit.getValue("notes"),
      propertyId: propertyId,
      file: edit.getValue("attachment"),
      fileType: edit.getValue("fileType"),
      fileName: edit.getValue("fileName"),
    };
    uploadDrawing(uploadObj);
    toggleAttachModal();
    setSelectedDrawingCategoryType("");
    edit.reset();
  };

  const uploadDocument = (documents) => {
    if (documents.length > 1) {
      createToast(
        "Only one document may be uploaded at a time.",
        TOAST_TYPES.ERROR
      );
      return;
    }

    getImageBase64FromFile(documents[0], (document) => {
      setStatus("Uploading...");
      const uploadObj = {
        drawingTypeId: UPLOAD_TYPE_GENERAL,
        propertyId: propertyId,
        file: document,
        fileType: documents[0].type,
        fileName: removeFileExtension(documents[0].name),
      };
      uploadDrawing(uploadObj);
    });
  };

  const uploadDrawing = async (request) => {
    const response = await drawingService.uploadDrawing({ request });
    if (response.status === HTTP_STATUSES.OK) {
      getDrawings();
    }
  };

  const removeFileExtension = (filename) => {
    return filename.substr(0, filename.lastIndexOf(".")) || filename;
  };

  const toggleAttachModal = () => {
    setAttachModalOpen(!attachModalOpen);
    if (!attachModalOpen) {
      edit.reset();
      setDisableAdd(false);
    }
    setSelectedDrawingCategoryType();
  };

  const confirmDeleteAttachment = (attachmentId) => {
    if (showDeleteAttachmentModal) {
      return;
    }

    setShowDeleteAttachmentModal(true);
    setAttachmentToDeleteId(attachmentId);
  };

  const deleteAttachment = async () => {
    const response = await drawingService.deleteDrawing({
      id: attachmentToDeleteId,
    });
    if (response.status === HTTP_STATUSES.OK) {
      getDrawings();
      setShowDeleteAttachmentModal(false);
      setAttachmentToDeleteId();
    }
  };

  const renderDeleteAttachmentModal = () => {
    return (
      <MiModalConfirm
        color={theme.palette.primary.red}
        title={"Confirm Delete"}
        handlePositive={deleteAttachment}
        handleNegative={() => setShowDeleteAttachmentModal(false)}
        description={"Are you sure you want to delete this document?"}
        open={showDeleteAttachmentModal}
      />
    );
  };

  const handleSetDrawingType = (value) => {
    edit.update({ type: value });
    const categoryOptions = drawingCategoryTypes.map((e, k) => {
      const isDuplicate = checkForDocTypeDuplicate(value, e.systemTypeId);
      return {
        value: e.systemTypeId,
        key: k,
        text: `${e.mainValue}${
          isDuplicate ? " - A document of this type already exists" : ""
        }`,
        disabled: isDuplicate,
      };
    });
    setDrawingCategoryTypeOptions(categoryOptions);
  };

  const detailFields = [
    {
      label: "Type",
      id: "drawing-type-select",
      value: edit.getValue("type"),
      required: true,
      setValue: handleSetDrawingType,
      defaultOption: true,
      options: drawingTypes?.map((i, k) => {
        return {
          value: i.systemTypeId,
          key: k,
          text: i.mainValue,
          disabled: i.disabled,
        };
      }),
      fieldType: "select",
      width: "100%",
    },
    {
      label: "Category",
      id: "category-select",
      value: edit.getValue("category"),
      defaultOption: true,
      options: drawingCategoryTypeOptions,
      setValue: (e) => edit.update({ category: e }),
      disabled: !edit.getValue("type"),
      fieldType: "select",
      required: true,
      width: "100%",
    },
    {
      label: "Attachment",
      fieldType: "file",
      value: edit.getValue("attachment"),
      fileType: edit.getValue("fileType"),
      setValue: (e) => {
        edit.update({
          attachment: e.base64,
          fileName: e.fileName,
          fileType: e.fileType,
        });
      },
      required: true,
      width: "100%",
    },
    {
      label: "Notes",
      id: "attachment-note",
      value: edit.getValue("notes"),
      setValue: (e) => edit.update({ notes: e }),
      width: "100%",
    },
  ];

  const actions = [
    {
      onClick: toggleAttachModal,
      color: theme.palette.primary.grey,
      name: "Close",
      inverse: false,
    },
    {
      onClick: uploadAttachment,
      color: theme.palette.primary.green,
      name: "Confirm",
      inverse: true,
      disabled: !edit.allFilled("type", "attachment", "category") || disableAdd,
    },
  ];

  const renderAttachModal = () => {
    return (
      <MiModal
        title="Add an attachment"
        open={attachModalOpen}
        maxWidth={600}
        onClose={toggleAttachModal}
        actions={actions}
      >
        <MiDetailFields detailFields={detailFields} />
        <div
          style={{
            fontSize: theme.fontSize.medium,
            color: theme.palette.primary.red,
            marginLeft: 7,
          }}
        >
          {validationErrorMessage}
        </div>
      </MiModal>
    );
  };

  if (status) {
    return <>{status}</>;
  }

  return (
    <MiDragDropZone onDrop={(documents) => uploadDocument(documents)}>
      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        {renderAttachModal()}
        {renderDeleteAttachmentModal()}
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          {drawings.length > 0 && (
            <div
              style={{
                color: theme.palette.mediumDark.grey,
                fontSize: theme.fontSize.medium,
                fontWeight: theme.fontWeight.regular,
              }}
            >
              Total Documents: {drawings.length}
            </div>
          )}
          <MiButton
            icon={mdiPlus}
            onClick={toggleAttachModal}
            title="Document"
            color={theme.palette.primary.green}
          />
        </div>
        {drawings.length > 0 ? (
          drawings.map((item) => {
            return (
              <div
                key={item.id}
                style={{
                  marginBottom: 10,
                  border: "1px solid",
                  borderColor: theme.palette.accent.grey,
                  borderRadius: 4,
                }}
              >
                <MiCard
                  title={
                    item.documentName ??
                    `${item.drawingTypeName} (${item.categoryName})`
                  }
                  onClick={() => openInNewTab(getImageUrl(item.fileName, cdn))}
                  icon={mdiFileDocument}
                  color={theme.palette.medium.purple}
                  fieldset={[
                    { label: "Stage: ", value: item.categoryName },
                    { label: "Type: ", value: item.drawingTypeName },
                    { label: "Uploaded By: ", value: item.updatedByName },
                    {
                      label: "Uploaded Date:",
                      value: moment(item.updateTime).format("L"),
                    },
                  ]}
                  rightArea={
                    <div
                      style={{
                        position: "absolute",
                        top: 15,
                        right: 10,
                        padding: 8,
                        color: theme.palette.primary.red,
                        zIndex: 10,
                      }}
                      onClick={(e) => {
                        e.stopPropagation(e);
                        confirmDeleteAttachment(item.id);
                      }}
                    >
                      <MiIcon
                        style={{ color: theme.palette.primary.red }}
                        size={0.7}
                        path={mdiClose}
                      />
                    </div>
                  }
                />
              </div>
            );
          })
        ) : (
          <div
            style={{
              paddingTop: 10,
              display: "flex",
              flex: 1,
              backgroundColor: theme.palette.lightShade.grey,
              padding: 8,
              color: theme.palette.medium.grey,
            }}
          >
            There are currently no documents
          </div>
        )}
      </div>
    </MiDragDropZone>
  );
};
