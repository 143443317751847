import axios from "axios";
import { toJS } from "mobx";
import { handleError } from "./";
import { createToast } from "@miview/toast";
import { TOAST_TYPES } from "@miview/constants";

const axiosInstance = axios.create({
  timeout: 30000,
});

const reportSuccess = (result, url, toastMessage) => {
  const obj = {
    endpoint: url,
    message: "Success",
    error: false,
    data: result?.data,
    statusCode: result?.status,
    response: result,
  };

  if (toastMessage?.message) {
    createToast(
      toastMessage.message,
      toastMessage?.type ? toastMessage.type : TOAST_TYPES.SUCCESS
    );
  }
  if (process.env.NODE_ENV !== "production") {
    console.log("Network Response: ", obj);
  }
};

const reportCancel = (error, url) => {
  const obj = {
    endpoint: url,
    message: "Cancelled",
    error: false,
    data: null,
    statusCode: null,
    response: error,
  };

  if (process.env.NODE_ENV !== "production") {
    console.log("Request Canceled: ", obj);
  }
};

/**
 *
 * @param request
 * @param toastMessageConfig Ex: {
 *     success: {
 *         message: "Action performed successfully"
 *     },
 *     failure: {
 *         message: "Failed to preform the action"
 *         hideMessage: true <<Optional param to not show toast on failure>>
 *     }
 * }
 */
export const apiRequest = async (request, toastMessageConfig = null) => {
  if (process.env.NODE_ENV === "development") {
    const loggedRequest = { ...request, data: toJS(request.data) };
    console.log("Requesting...", loggedRequest);
  }

  try {
    const result = await axiosInstance(request);

    if (result?.status < 400) {
      if (typeof toastMessageConfig === "string") {
        toastMessageConfig = {
          success: {
            message: toastMessageConfig,
          },
        };
      }
      reportSuccess(result, request.url, toastMessageConfig?.success);
      return result;
    }

    throw result;
  } catch (error) {
    if (axios.isCancel(error)) {
      reportCancel(error, request.url);
      return {
        canceled: true,
        request,
        message: "This request was cancelled",
      };
    }

    return handleError(error, request, toastMessageConfig?.failure);
  }
};
