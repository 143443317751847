import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const roleService = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/roles`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });
    return apiRequest(options);
  },

  get: async ({ id, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/roles/${id}`,
      method: "get",
      signal,
    });
    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/roles`,
      method: "post",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving role",
      },
      success: {
        message: "Role saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/roles/${id}`,
      method: "post",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving role",
      },
      success: {
        message: "Role saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
};
