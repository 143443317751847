import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { mdiStarCircleOutline } from "@mdi/js";
import { MiInputTextbox, MiIcon } from "@miview/components";
import { en } from "@miview/constants";

const getBorderColor = (theme) => (props) =>
  (props.isFilled && theme.palette.primary.main) ||
  (props.isActiveBorder
    ? theme.palette.medium.grey
    : theme.palette.accent.grey);

const useStyles = makeStyles()((theme, { props }) => {
  return {
    costCard: {
      borderWidth: props.disabled ? 0.5 : 1,
      borderColor: getBorderColor(props),
      borderStyle: "solid",
      borderRadius: "0px 4px 4px 0px",
      width: 120,
      height: 49,
      padding: "0px 0px 4px 0px",
      overflow: "hidden",
    },
    subContainer: {
      padding: "0px 10px 0px 10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      marginRight: 5,
    },
    subItem: {
      color:
        props.disabled || props.isEnabled
          ? theme.palette.medium.grey
          : theme.palette.secondary.bluegrey,
      fontWeight: theme.fontWeight.medium,
      fontSize: theme.fontSize.medium,
      lineHeight: "100%",
      textTransform: "uppercase",
      margin: "0px 8px 8px 8px",
    },
    title: {
      color:
        props.disabled || props.isEnabled
          ? theme.palette.medium.grey
          : theme.palette.dark.bluegrey,
      fontSize: theme.fontSize.medium,
      margin:
        props.disabled || props.isEnabled
          ? "8px 0px 0px 8px"
          : "8px 4px 4px 8px",
    },
    margin: {
      margin: theme.spacing(1),
    },
    percentage: {
      color: props.isNegative
        ? theme.palette.medium.red
        : theme.palette.medium.teal,
    },
  };
});
const useStylesForTextField = makeStyles()((theme, { props }) => {
  return {
    root: {
      borderColor: getBorderColor(props),
      borderWidth: props.disabled ? ".5px 0px .5px .5px" : "1px 0px 1px 1px",
      backgroundColor:
        (props.isEnabled && theme.palette.light.grey) ||
        (props.isFilled && theme.palette.light.teal),
      borderStyle: "solid",
      overflow: "hidden",
      borderRadius: "4px 0px 0px 4px",
      width: 40,
      height: 49,
      paddingLeft: 4,
      color: theme.palette.primary.bluegrey,
      fontSize: 13,
    },
    input: {
      textAlign: "center",
    },
    focused: {
      backgroundColor: theme.palette.light.main,
    },
    inputMarginDense: {
      marginTop: 3,
    },
  };
});

const SupplierCard = ({
  title,
  orderedqty,
  currentcost,
  change,
  isPreferredVendor,
  disabled,
  onChange,
  uid,
}) => {
  const theme = useTheme();
  const _isDisabled = disabled || !currentcost;
  const isActiveBorder = !_isDisabled;
  const isNegative = change < 0;
  const { classes } = useStyles({
    props: {
      isNegative,
      disabled,
      isActiveBorder,
      isFilled: orderedqty !== 0,
      isEnabled: _isDisabled,
    },
  });
  const { classes: textCSSClasses } = useStylesForTextField({
    props: {
      disabled,
      isActiveBorder,
      isFilled: orderedqty !== 0,
      isEnabled: _isDisabled,
    },
  });
  return (
    <Box display={"flex"}>
      <MiInputTextbox
        id={uid ?? ""}
        variant={"standard"}
        disabled={_isDisabled}
        value={orderedqty === 0 ? "" : orderedqty}
        handleChange={onChange}
        InputProps={{
          classes: textCSSClasses,
          disableUnderline: true,
        }}
        minWidth={40}
        fullWidth={false}
      />
      <Grid className={classes.costCard}>
        <Grid className={classes.subContainer}>
          <Typography className={classes.title}>
            {isPreferredVendor && title !== en.notApplicable && (
              <MiIcon
                path={mdiStarCircleOutline}
                color={theme.palette.medium.yellow}
                size={0.6}
                className={classes.icon}
              />
            )}
            {title}
          </Typography>
        </Grid>
        <Grid className={classes.subContainer}>
          <Typography className={[classes.subItem]}>
            {(currentcost !== 0 && "$" + currentcost) || "--"}
          </Typography>

          <Typography className={[classes.subItem, classes.percentage]}>
            {(currentcost !== 0 && change?.toFixed(2) + "%") || ""}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
SupplierCard.propTypes = {
  title: PropTypes.string | PropTypes.undefined,
  orderedqty: PropTypes.number,
  currentcost: PropTypes.string,
  change: PropTypes.string,
  isPositive: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
export default memo(SupplierCard);
