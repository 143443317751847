import React, { useState, useEffect } from "react";
import { MiInputTextbox, MiInputSelectBox, MiModal } from "@miview/components";
import { payActivityTypeService, expenseTypeService } from "@miview/api";
import { createToast } from "@miview/toast";
import { TOAST_TYPES, HTTP_STATUSES } from "@miview/constants";
import { useEdit, useComponentState } from "@miview/hooks";
import { useTheme } from "@mui/material/styles";

export const CreateEditPayActivityTypeModal = (props) => {
  const { open, onClose, payActivityType } = props;

  const [expenseTypeDataForSelect, setExpenseTypeDataForSelect] = useState([]);

  const edit = useEdit(payActivityType);
  const stateManager = useComponentState();
  const theme = useTheme();

  const isEdit = !!payActivityType;

  useEffect(() => {
    edit.reset();
  }, [open]);

  useEffect(() => {
    getExpenseTypes();
  }, []);

  const getExpenseTypes = async () => {
    stateManager.run(async () => {
      const response = await expenseTypeService.getAll({
        params: { pageSize: 9999 },
      });
      if (response.status === HTTP_STATUSES.OK) {
        const types = response.data.map((t) => ({
          value: t.id,
          label: t.name,
        }));
        setExpenseTypeDataForSelect(types);
      }
    });
  };

  const addPayActivityType = async () => {
    stateManager.run(async () => {
      const response = await payActivityTypeService.create(edit.edits);
      if (response.status === HTTP_STATUSES.OK) {
        createToast("Pay Activity Added Successfully", TOAST_TYPES.SUCCESS);
        onClose();
      }
    });
  };

  const editPayActivityType = async () => {
    stateManager.run(async () => {
      const response = await payActivityTypeService.update(payActivityType.id, {
        ...payActivityType,
        ...edit.edits,
      });
      if (response.status === HTTP_STATUSES.OK) {
        createToast("Edited Successfully", TOAST_TYPES.SUCCESS);
        onClose();
      }
    });
  };

  const actions = [
    {
      onClick: onClose,
      color: theme.palette.primary.grey,
      name: "Cancel",
      inverse: false,
    },
    {
      onClick: isEdit ? editPayActivityType : addPayActivityType,
      color: theme.palette.primary.green,
      name: "Submit",
      inverse: true,
      disabled:
        stateManager.isBusy() || !edit.allFilled("name", "expenseTypeId"),
    },
  ];

  return (
    <MiModal
      title={isEdit ? "Edit Pay Activity Type" : "Add Pay Activity Type"}
      open={open}
      onClose={onClose}
      actions={actions}
    >
      <MiInputTextbox
        labelText="Name"
        styles={{ margin: 16 }}
        labelTextColor={theme.palette.secondary.grey}
        handleChange={(e) => edit.update({ name: e.target.value })}
        value={edit.getValue("name")}
        required
      />
      <MiInputSelectBox
        labelText="Expense Type"
        styles={{ margin: 16 }}
        labelTextColor={theme.palette.secondary.grey}
        menuItems={expenseTypeDataForSelect}
        value={edit.getValue("expenseTypeId")}
        handleChange={(e) => edit.update({ expenseTypeId: e.target.value })}
        disableNone
        required
      />
      <MiInputTextbox
        labelText="Display Order"
        type="Number"
        styles={{ margin: 16 }}
        labelTextColor={theme.palette.secondary.grey}
        handleChange={(e) => edit.update({ displayOrder: e.target.value })}
        value={edit.getValue("displayOrder")}
        required
      />
    </MiModal>
  );
};
