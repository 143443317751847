import React from "react";
import { THEME } from "@miview/theme";
import PropTypes from "prop-types";
import { makeStyles } from 'tss-react/mui';

export const DueCounter = (props) => {
  const { days, hours, daysColor, hoursColor } = props;
  return (
    <span style={{ ...THEME.ROW, marginLeft: 4 }}>
      {days ? (
        <span style={{ ...styles.timeColor, color: daysColor }}>
          {`${days.toString()}D `}
        </span>
      ) : null}
      {hours ? (
        <span style={{ ...styles.timeColor, color: hoursColor }}>
          {`${hours.toString()}H`}
        </span>
      ) : null}
    </span>
  );
};

const styles = makeStyles()({
  timeColor: {
    ...THEME.TEXT_BODY_REGULAR,
    textAlign: "center",
  },
});

DueCounter.propTypes = {
  days: PropTypes.number,
  hours: PropTypes.number,
  daysColor: PropTypes.string,
  hoursColor: PropTypes.string,
};
