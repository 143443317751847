import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { LogoMiViewFooter } from "@miview/assets";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const useFooterStyles = makeStyles()((theme) => ({
  logo: {
    width: 100,
  },
  container: {
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
    backgroundColor: theme.palette.lightAccent.grey,
  },
}));

const DefaultFooter = () => {
  const { classes: footerStyles } = useFooterStyles();
  return (
    <div className={footerStyles.container}>
      <span>
        <a href="https://www.miviewis.com">MiView Integrated Solutions</a>{" "}
        &copy; {new Date().getFullYear()} MiViewIS
      </span>
      {window.location.href.indexOf("cathedral.miviewis") === -1 && (
        <span style={{ margin: "auto" }}>
          Git Revision: {process.env.REACT_APP_GIT_SHA}
        </span>
      )}
      <span className="ml-auto">
        <img alt="" src={LogoMiViewFooter} className={footerStyles.logo} />
      </span>
    </div>
  );
};

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
