export const supplierData = (() => {
  const d = [
    {
      accountNumber: "DF4233DF1",
      name: "American Tool & Fastener",
      shortName: "ATF",
      branches: 4,
      status: 1,
    },
    {
      accountNumber: "434SDF261",
      name: "BWI",
      shortName: "BWI",
      branches: 2,
      status: 0,
    },
    {
      accountNumber: "3454TGGS5",
      name: "C & C Wholesale Distributors",
      shortName: "CNC",
      branches: 1,
      status: 0,
    },
    {
      accountNumber: "GSDF55437",
      name: "CAT Commercial Account",
      shortName: "CCW",
      branches: 10,
      status: 1,
    },
  ];

  const list = Array(20)
    .fill({})
    .map((_, idx) => {
      const n = d[idx % d.length] || d[0];
      idx = idx + 1;
      return {
        id: idx,
        accountNumber: n.accountNumber,
        name: n.name,
        shortName: n.shortName,
        branches: n.branches,
        status: n.status,
      };
    });

  const general = {
    accountName: "Ferguson Supply Company",
    shortName: "FRG",
    alternateIdNumber: "5245525245234",
    phone: "(469)293-7927",
    mobile: "(469)293-7927",
    email: "emails@ferguson.com",
    fax: "(757)989-2501",
    website: "www.ferguson.com",
    corporateAddress: "12500 Jefferson Ave",
    corporateAddress2: "Site200",
    corporateCity: "Dallas",
    corporateState: "Texas",
    corporateZipCode: "75225",
    remittanceAddress: "12500 Jefferson Ave",
    remittanceAddress2: "Site200",
    remittanceCity: "Dallas",
    remittanceState: "Texas",
    remittanceZipCode: "75225",
  };

  const customerPaymentInfo = {
    customerNumber: "323243453",
    legalName: "Billy Joel",
    paymentTerms: "Net 30",
    billingRate: "3%",
  };

  const contactData = [
    {
      branchName: "Dallas",
      name: "Marisa Hall",
      role: "Accounting",
      email: "marisha.hall2@ferguson.com",
      phone: "(214) 241-3432",
      status: 1,
    },
    {
      branchName: "Lewisville",
      name: "Gary Anderson",
      role: "Purchasing",
      email: "gary.anderson@ferguson.com",
      phone: "(850) 154-4523",
      status: 1,
    },
    {
      branchName: "Plano",
      name: "John Smith",
      role: "Purchasing",
      email: "john.smith@ferguson.com",
      phone: "(972) 174-5811",
      status: 1,
    },
  ];

  const contacts = Array(10)
    .fill({})
    .map((_, idx) => {
      const n = contactData[idx % contactData.length] || contactData[0];
      idx = idx + 1;
      return {
        id: idx,
        branchName: n.branchName,
        name: n.name,
        role: n.role,
        email: n.email,
        phone: n.phone,
        status: n.status,
      };
    });

  const branches = [
    {
      id: 1,
      branchName: "Test 1",
      storeNumber: "1234567-768",
      internalNumber: "123",
      address: "2723 Dublin SanFrancisco CA",
      phoneNumber: "9591939290",
      branchEmail: "selva@technoduce.com",
      status: 1,
      contacts: [
        {
          name: "Miksa Amadei",
          role: "Purchasing",
          email: "miksa.amdei@ferguson.com",
          phoneNumber: "(817)555-0178",
        },
        {
          name: "Gunther Wilton",
          role: "Accounting",
          email: "gunther.wilton@ferguson.com",
          phoneNumber: "(817)421-8522",
        },
        {
          name: "Marshel Gafffney",
          role: "Purchasing",
          email: "marshel.gafffney@ferguson.com",
          phoneNumber: "(817)789-3486",
        },
      ],
    },
    {
      id: 2,
      branchName: "Branch 2",
      storeNumber: "1234567-768",
      internalNumber: "123",
      address: "2723 Dublin SanFrancisco CA",
      phoneNumber: "9591939290",
      branchEmail: "selva@technoduce.com",
      status: 1,
      contacts: [
        {
          name: "Miksa Amadei",
          role: "Purchasing",
          email: "miksa.amdei@ferguson.com",
          phoneNumber: "(817)555-0178",
        },
        {
          name: "Gunther Wilton",
          role: "Accounting",
          email: "gunther.wilton@ferguson.com",
          phoneNumber: "(817)421-8522",
        },
        {
          name: "Marshel Gafffney",
          role: "Purchasing",
          email: "marshel.gafffney@ferguson.com",
          phoneNumber: "(817)789-3486",
        },
      ],
    },
  ];

  return {
    list,
    general,
    customerPaymentInfo,
    contacts,
    branches,
  };
})();

export const StatesCities = [
  {
    State: "Texas",
    Cities: [
      "Addison",
      "Aledo",
      "Allen",
      "Alma",
      "Alvarado",
      "Alvord",
      "Anna",
      "Annetta North",
      "Annetta South",
      "Annetta",
      "Argyle",
      "Arlington",
      "Aubrey",
      "Aurora",
      "Azle",
      "Balch Springs",
      "Balsora",
      "Bardwell",
      "Bartonville",
      "Bedford",
      "Benbrook",
      "Blue Mound",
      "Blue Ridge",
      "Boonsvile",
      "Boyd",
      "Briar",
      "Briaroaks",
      "Bridgeport",
      "Brock",
      "Burleson",
      "Caddo Mills",
      "Campbell",
      "Carrollton",
      "Cedar Hill",
      "Celeste",
      "Celina",
      "Chico",
      "Cleburne",
      "Cockrell Hill",
      "Colleyville",
      "Combine",
      "Commerce",
      "Cool",
      "Coppell",
      "Copper Canyon",
      "Corinth",
      "Corral City",
      "Cottondale",
      "Crandall",
      "Cresson",
      "Cross Roads",
      "Cross Timber",
      "Crowley",
      "Dallas",
      "Dalworthington Gardens",
      "Decatur",
      "Dennis",
      "Denton",
      "DeSoto",
      "Double Oak",
      "Duncanville",
      "Eagle Mountain",
      "Edgecliff Village",
      "Ennis",
      "Euless",
      "Everman",
      "Fairview",
      "Farmers Branch",
      "Farmersville",
      "Fate",
      "Ferris",
      "Flower Mound",
      "Forest Hill",
      "Forney",
      "Fort Worth",
      "Frisco",
      "Garland",
      "Garner",
      "Garrett",
      "Glen Rose",
      "Glenn Heights",
      "Godley",
      "Goshen",
      "Granbury",
      "Grand Prairie",
      "Grandview",
      "Grapevine",
      "Grays Prairie",
      "Greenville",
      "Greenwood",
      "Hackberry",
      "Haltom City",
      "Haslet",
      "Heartland",
      "Heath",
      "Hebron",
      "Hickory Creek",
      "Highland Park",
      "Highland Village",
      "Hudson Oaks",
      "Hurst",
      "Hutchins",
      "Irving",
      "Italy",
      "Josephine",
      "Joshua",
      "Justin",
      "Kaufman",
      "Keene",
      "Keller",
      "Kemp",
      "Kennedale",
      "Krugerville",
      "Krum",
      "La Junta Sanctuary",
      "Lake Dallas",
      "Lake Worth",
      "Lakeside",
      "Lakewood Village",
      "Lancaster",
      "Lavon",
      "Lawrence",
      "Lewisville",
      "Lincoln Park",
      "Lipan",
      "Little Elm",
      "Lone Oak",
      "Lowry Crossing",
      "Lucas",
      "Mabank",
      "Mansfield",
      "Marshall Creek",
      "Maypearl",
      "McKinney",
      "McLendon-Chisholm",
      "Melissa",
      "Mesquite",
      "Midlothian",
      "Milford",
      "Millsap",
      "Mobile City",
      "Murphy",
      "Nevada",
      "New Hope",
      "Newark",
      "North Richland Hills",
      "Northlake",
      "Oak Grove",
      "Oak Leaf",
      "Oak Point",
      "Oak Ridge",
      "Oak Trail Shores",
      "Ovilla",
      "Palmer",
      "Pantego",
      "Paradise",
      "Parker",
      "Peaster",
      "Pecan Acres",
      "Pecan Hill",
      "Pecan Plantation",
      "Pelican Bay",
      "Pilot Point",
      "Plano",
      "Ponder",
      "Poolville",
      "Post Oak Bend",
      "Princeton",
      "Prosper",
      "Providence Village",
      "Quinlan",
      "Rainbow",
      "Red Oak",
      "Rendon",
      "Reno",
      "Rhome",
      "Richardson",
      "Richland Hills",
      "Rio Vista",
      "River Oaks",
      "Roanoke",
      "Rockwall",
      "Rosser",
      "Rowlett",
      "Royse City",
      "Runaway Bay",
      "Sachse",
      "Saginaw",
      "Sanctuary",
      "Sanger",
      "Sansom Park",
      "Scurry",
      "Seagoville",
      "Shady Shores",
      "Slidell",
      "Southlake",
      "Springtown",
      "St. Paul",
      "Sunnyvale",
      "Talty",
      "Telico",
      "Terrell",
      "The Colony",
      "Tolar",
      "Trophy Club",
      "University Park",
      "Van Alstyne",
      "Venus",
      "Watauga",
      "Waxahachie",
      "Weatherford",
      "West Tawakoni",
      "Westlake",
      "Westminster",
      "Weston",
      "Westover Hills",
      "Westworth Village",
      "White Settlement",
      "Whitt",
      "Willow Park",
      "Wilmer",
      "Wolfe City",
      "Wylie",
    ],
  },
];
