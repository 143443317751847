import { apiOptions, apiRequest } from "../utils";
import { API_V3 } from "../constants";

export const auditService = {
  getAll: async (params) => {
    const options = await apiOptions({
      url: `${API_V3}/audit`,
      method: "get",
      params,
    });

    return apiRequest(options);
  },

  getTypes: async () => {
    const options = await apiOptions({
      url: `${API_V3}/audit/types`,
      method: "get",
    });

    return apiRequest(options);
  },

  revert: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/audit/${id}/revert`,
      method: "post",
    });

    const toastMessageConfig = {
      success: {
        message: "Reverted successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  redo: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/audit/${id}/redo`,
      method: "post",
    });

    const toastMessageConfig = {
      success: {
        message: "Redone successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  revertTransaction: async (transactionId) => {
    const options = await apiOptions({
      url: `${API_V3}/audit/revert`,
      method: "post",
      params: { transactionId },
    });

    const toastMessageConfig = {
      success: {
        message: "Transaction reverted successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  redoTransaction: async (transactionId) => {
    const options = await apiOptions({
      url: `${API_V3}/audit/redo`,
      method: "post",
      params: { transactionId },
    });

    const toastMessageConfig = {
      success: {
        message: "Transaction redone successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
};
