import { appStorage } from "../appStorage";
import {
  OFFLINE_WALKS,
  OFFLINE_WALKS_NOT_TODAY,
  OFFLINE_ETAG_PUNCH,
  OFFLINE_ETAG_JOB,
  OFFLINE_PUNCH_TAB_ETAG,
  OFFLINE_WALKS_ETAG,
  OFFLINE_WALKS_GENERAL_CACHE,
  OFFLINE_STAGE_SUMMARIES,
  OFFLINE_WALK_TYPES,
} from "@miview/constants";
import { checklistHelpers } from "../checklistHelpers";
import { bugsnag } from "../bugsnag";
import { isDateToday } from "../getTimeDiff";

export const getOfflineWalk = async (walkId) => {
  let walks = await getOfflineWalksKeyedById();

  let walk = walks[`${walkId}`];
  if (walk) {
    return walk;
  }

  walks = await getOfflineWalksKeyedByIdFromGeneralCache();
  return walks[`${walkId}`];
};

export const setOfflineWalk = async (walkId, walk) => {
  if (!walkId) {
    return;
  }

  const walks = await getOfflineWalksKeyedById();
  walks[`${walkId}`] = walk;
  await setOfflineWalks(walks);
};

export const setWalkDetailsInGeneralCache = async (walkId, walk) => {
  if (!walkId) {
    return;
  }

  let walks = await getOfflineWalksKeyedById();
  if (walks[`${walkId}`]) {
    walks[`${walkId}`] = walk;
    await setOfflineWalks(walks);
  } else {
    walks = await getOfflineWalksKeyedByIdFromGeneralCache();
    walks[`${walkId}`] = walk;
    await setOfflineWalksInGeneralCache(walks);
  }
};

export const updateOfflineWalk = async (walkId, updates) => {
  if (!walkId) {
    return;
  }

  const walks = await getOfflineWalksKeyedById();
  let walk = walks[`${walkId}`];

  if (walk) {
    walk = { ...walk, ...updates };
  } else {
    walk = updates;
  }

  walks[`${walkId}`] = walk;
  await setOfflineWalks(walks);
};

export const setOfflineWalkItem = async (walkId, walkItemId, item) => {
  const walk = await getOfflineWalk(walkId);
  if (walk?.items) {
    walk.items = checklistHelpers.setChecklistItem(
      walk.items,
      walkItemId,
      item
    );
    await setOfflineWalk(walkId, walk);
  }
};

export const setOfflineWalkItems = async (walkId, items) => {
  const walk = await getOfflineWalk(walkId);
  if (walk?.items) {
    walk.items = checklistHelpers.setChecklistItems(walk.items, items);
    await setOfflineWalk(walkId, walk);
  }
};

export const getOfflineWalkItem = async (walkId, walkItemId) => {
  const walk = await getOfflineWalk(walkId);
  if (walk?.items) {
    return checklistHelpers.getChecklistItem(walk.items, walkItemId);
  }
  return null;
};

export const setOfflineWalks = async (walks) => {
  try {
    await appStorage.setItem(OFFLINE_WALKS, JSON.stringify(walks));
  } catch (e) {
    bugsnag.setContext("walkStorage::setOfflineWalks");
    bugsnag.notify(e);
  }
};

export const setOfflineWalksInGeneralCache = async (walks) => {
  try {
    await appStorage.setItem(
      OFFLINE_WALKS_GENERAL_CACHE,
      JSON.stringify(walks)
    );
  } catch (e) {
    bugsnag.setContext("walkStorage::setOfflineWalks");
    bugsnag.notify(e);
  }
};

export const getOfflineWalksKeyedById = async () => {
  try {
    const walks = await appStorage.getItem(OFFLINE_WALKS);

    if (walks) {
      return JSON.parse(walks);
    }

    return {};
  } catch (e) {
    bugsnag.setContext("walkStorage::getOfflineWalksKeyedById");
    bugsnag.notify(e);
  }
};

export const getOfflineWalksKeyedByIdFromGeneralCache = async () => {
  try {
    const walks = await appStorage.getItem(OFFLINE_WALKS_GENERAL_CACHE);

    if (walks) {
      return JSON.parse(walks);
    }

    return {};
  } catch (e) {
    bugsnag.setContext("walkStorage::getOfflineWalksKeyedById");
    bugsnag.notify(e);
  }
};

export const getOfflineWalks = async () => {
  const walks = await getOfflineWalksKeyedById();
  return Object.values(walks);
};

export const clearOfflineWalks = async () => {
  try {
    await appStorage.removeItem(OFFLINE_WALKS);
    await appStorage.removeItem(OFFLINE_WALKS_NOT_TODAY);
    await appStorage.removeItem(OFFLINE_ETAG_PUNCH);
    await appStorage.removeItem(OFFLINE_ETAG_JOB);
    await appStorage.removeItem(OFFLINE_PUNCH_TAB_ETAG);
    await appStorage.removeItem(OFFLINE_WALKS_ETAG);
    await appStorage.removeItem(OFFLINE_WALK_TYPES);
    await appStorage.removeItem(OFFLINE_WALKS_GENERAL_CACHE);
    await appStorage.removeItem(OFFLINE_STAGE_SUMMARIES);
  } catch (e) {
    bugsnag.setContext("walkStorage::clearOfflineWalks");
    bugsnag.notify(e);
  }
};

export const removeOfflineWalkFromADay = async (id, scheduleDate) => {
  try {
    if (isDateToday(scheduleDate)) {
      return;
    }

    let allWalks = await appStorage.getItem(OFFLINE_WALKS_NOT_TODAY);
    allWalks = JSON.parse(allWalks);

    let walks = allWalks ? allWalks[scheduleDate] : null;
    if (!walks?.length) {
      return;
    }
    walks = walks.filter((walk) => walk.id !== id);

    allWalks[scheduleDate] = walks;
    allWalks = JSON.stringify(allWalks);

    await appStorage.setItem(OFFLINE_WALKS_NOT_TODAY, allWalks);
  } catch (e) {
    bugsnag.setContext("walkStorage::removeOfflineWalkFromADay");
    bugsnag.notify(e);
  }
};
