import React, { useState, useEffect } from "react";
import { useRouter } from "@miview/hooks";
import { Grid } from "@mui/material";
import {
  MiButton,
  MiPageHeader,
  MiBox,
  MiChip,
  MiCard,
  MiSectionHeader,
  MiLoader,
  MiIcon,
} from "@miview/components";
import { useTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { integrationService } from "@miview/api";
import { INTEGRATION_NAMES } from "@miview/constants";
import { mdiCheck, mdiMinusCircleOffOutline, mdiPlus, mdiCog } from "@mdi/js";

const useStyles = makeStyles()(() => {
  return {
    grid: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
      flex: 1,
    },
    row: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    main: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    actionColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    marginTop: {
      marginTop: 15,
    },
  };
});

export const Integrations = () => {
  const [integrations, setIntegrations] = useState([]);
  const [integrationTypes, setIntegrationTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { classes } = useStyles();
  useEffect(() => {
    fetchData();
  }, []);

  const { push } = useRouter();
  const theme = useTheme();

  const filterIntegrationTypes = (keys, typesArray) => {
    if (typesArray?.length) {
      const filtered = typesArray.filter((type) =>
        keys.every((key) => key !== type.name)
      );
      setIntegrationTypes(filtered);
    }
  };

  const addIntegration = async (row) => {
    try {
      setLoading(true);
      const data = {
        integrationTypeName: row.name,
      };
      const response = await integrationService.integrations.create(data);
      if (response.status === 200) {
        await fetchData();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await integrationService.integrations.getAll();
      let keys = [];
      if (response.data) {
        setIntegrations(response.data);
        keys = response.data.map((setting) => setting.integrationTypeName);
      }
      const typesResponse = await integrationService.integrationTypes.getAll();
      if (typesResponse.data) {
        filterIntegrationTypes(keys, typesResponse.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleClick = (item) => {
    const screen = INTEGRATION_NAMES[item.integrationTypeName];
    push(`/settings/integrations/${screen.toLowerCase()}/connectapi`);
  };

  const renderImage = (url) => {
    if (!url) {
      return null;
    }
    return (
      <div style={{ padding: 10, maxWidth: 300 }}>
        <img src={url} width={250} />
      </div>
    );
  };

  const styles = {
    micard: {
      border: `1px solid #DDD`,
      borderRadius: 6,
      margin: 10,
      padding: 10,
    },
  };

  const renderBody = (item, type) => {
    if (!item) {
      return null;
    }

    const getIconProps = (val) => {
      if (val) {
        return {
          label: "Enabled",
          icon: (
            <MiIcon
              path={mdiCheck}
              size={1}
              color={theme.palette.secondary.green}
            />
          ),
          style: {
            backgroundColor: alpha(theme.palette.secondary.green, 0.2),
            color: theme.palette.secondary.green,
          },
        };
      }
      return {
        label: "Disabled",
        icon: (
          <MiIcon
            path={mdiMinusCircleOffOutline}
            size={1}
            color={alpha(theme.palette.secondary.grey, 0.5)}
          />
        ),
        style: {
          backgroundColor: alpha(theme.palette.secondary.grey, 0.2),
          color: theme.palette.secondary.grey,
        },
      };
    };

    const renderChip = () => {
      if (type) {
        return <div></div>;
      }
      return <MiChip {...getIconProps(item.isEnabled)} />;
    };

    const renderButton = () => {
      if (type) {
        return (
          <MiButton
            title={"Add"}
            icon={mdiPlus}
            inverse={true}
            color={theme.palette.primary.green}
            onClick={(e) => {
              e.stopPropagation();
              addIntegration(item);
            }}
          />
        );
      }
      return (
        <MiButton
          title={"Configure"}
          icon={mdiCog}
          color={theme.palette.primary.main}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(item);
          }}
        />
      );
    };

    const getUrlValue = () => {
      if (type) {
        return item.imageUrl;
      }
      return item.integrationTypeImageUrl;
    };

    return (
      <div className={classes.row}>
        {renderImage(getUrlValue())}
        <div className={classes.actionColumn}>
          {renderChip()}
          <div className={classes.marginTop}>{renderButton()}</div>
        </div>
      </div>
    );
  };

  const renderType = (item, i) => {
    if (!item) {
      return null;
    }
    return (
      <Grid key={i} item xs={6}>
        <MiCard
          containerStyles={styles.micard}
          renderBody={() => renderBody(item, "type")}
        />
      </Grid>
    );
  };

  const renderIntegrationTypes = () => {
    if (!integrationTypes?.length) {
      return null;
    }

    return (
      <Grid>
        <Grid style={{ paddingTop: 20 }}>
          <MiSectionHeader
            title="Available Integrations"
            color={theme.palette.secondary.grey}
            hasDivider={true}
          />
        </Grid>
        <Grid className={classes.grid} container spacing={2}>
          {integrationTypes.map((item, i) => renderType(item, i))}
        </Grid>
      </Grid>
    );
  };

  const renderIntegrations = () => {
    if (!integrations?.length) {
      return null;
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MiSectionHeader title="Active Integrations" hasDivider={true} />
        </Grid>
        <Grid className={classes.grid} container spacing={2}>
          {integrations.map((item, i) => (
            <Grid key={i} item xs={6}>
              <MiCard
                onItemSelected={() => handleClick(item)}
                containerStyles={styles.micard}
                renderBody={() => renderBody(item)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  const renderPageHeader = () => {
    return (
      <MiPageHeader
        title="Integration Settings"
        color={theme.palette.primary.main}
      />
    );
  };

  const renderLoader = () => {
    if (!loading) {
      return null;
    }
    return <MiLoader type="linear" />;
  };

  return (
    <Grid className={classes.main}>
      {renderPageHeader()}
      {renderLoader()}
      <MiBox>
        {renderIntegrations()}
        {renderIntegrationTypes()}
      </MiBox>
    </Grid>
  );
};
