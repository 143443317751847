import React, { useState, useEffect } from "react";
import {
  walkTypeService,
  walkTypeTemplateService,
  accountServiceLegacy,
  systemTypeService,
} from "@miview/api";
import { useRouter, useEdit, useComponentState } from "@miview/hooks";
import { MiModal, MiDetailFields } from "@miview/components";
import { HTTP_STATUSES } from "@miview/constants";
import { toList } from "@miview/utils";
import { useTheme } from "@mui/material";

const INITIAL_VALUES = {
  scheduleValue: 0,
  firstFailureGracePeriod: 0,
  timeFrameTypeId: 50,
  walkTypeOrder: undefined,
  displayOrder: 0,
  priorityOrder: 0,
  rewalkOnFail: true,
  ripple: true,
  isFinalWalk: false,
  isFirstWalk: false,
  canPreview: false,
  useCurfew: 0,
  allowManualMeasurement: false,
  targetTypeIds: [],
};
const StageWalkTypeCreateModal = (props) => {
  const { onClose, open, stageTypeId } = props;
  const router = useRouter();
  const theme = useTheme();
  const stateManager = useComponentState();

  //dropdown data
  const [titles, setTitles] = useState([]);
  const [walkTypeTemplates, setWalkTypeTemplates] = useState([]);
  const [recordTypes, setRecordTypes] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getRecordTypes();
    getTitles();
    getWalkTypeTemplates();
  };

  const getRecordTypes = async () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: "RecordType",
      });
      if (response.status === HTTP_STATUSES.OK) {
        const recordTypes = toList(response.data, "systemTypeId", "mainValue");
        setRecordTypes(recordTypes);
      }
    });
  };
  const getTitles = async () => {
    stateManager.run(async () => {
      const response = await accountServiceLegacy.getTitles({
        id: localStorage.accountId,
        params: { orderBy: "name" },
      });
      if (response.status === HTTP_STATUSES.OK) {
        const titles = toList(response.data, "id", "name");
        setTitles(titles);
      }
    });
  };

  const getWalkTypeTemplates = async () => {
    stateManager.run(async () => {
      const response = await walkTypeTemplateService.getAll();
      if (response.status === HTTP_STATUSES.OK) {
        const walkTypeTemplates = toList(response.data, "id", "name");
        setWalkTypeTemplates(walkTypeTemplates);
      }
    });
  };
  const edit = useEdit({ ...INITIAL_VALUES, stageTypeId: stageTypeId });

  const detailFields = [
    {
      label: "Name",
      value: edit.getValue("walkTypeName"),
      setValue: (e) => edit.update({ walkTypeName: e }),
      width: "50%",
      readOnly: false,
      required: true,
    },
    {
      label: "Record Type",
      value: edit.getValue("recordType"),
      fieldType: "select",
      setValue: (e) => edit.update({ recordType: e }),
      width: "50%",
      options: recordTypes,
      required: true,
    },
    {
      label: "Title",
      value: edit.getValue("titleId"),
      setValue: (e) => edit.update({ titleId: e }),
      width: "50%",
      fieldType: "select",
      options: titles,
      required: true,
    },
    {
      label: "Walk Type Template",
      value: edit.getValue("walkTypeTemplateId"),
      setValue: (e) => edit.update({ walkTypeTemplateId: e }),
      width: "50%",
      readOnly: false,
      clickButton: () => null,
      fieldType: "select",
      options: walkTypeTemplates,
    },
    {
      label: "Walk Type Order",
      value: edit.getValue("walkTypeOrder"),
      setValue: (e) => edit.update({ walkTypeOrder: e }),
      width: "50%",
      fieldType: "number",
      readOnly: false,
    },
  ];

  const isFormValid = () => {
    return edit.allFilled("walkTypeName", "titleId", "recordType");
  };

  const saveEdits = () => {
    walkTypeService
      .create({ ...INITIAL_VALUES, ...edit.edits, stageTypeId: stageTypeId })
      .then((response) => {
        if (response.status === HTTP_STATUSES.OK) {
          onClose();
          router.push(`/stagetypes/${stageTypeId}/walktypes/${response.data}`);
        }
      });
  };

  const actions = [
    {
      onClick: onClose,
      name: "Cancel",
      color: theme.palette.primary.grey,
    },
    {
      onClick: saveEdits,
      name: "Save",
      disabled: !isFormValid(),
      color: theme.palette.primary.main,
      inverse: true,
    },
  ];

  return (
    <MiModal
      key={0}
      open={open}
      onClose={onClose}
      maxWidth={"60%"}
      title={"Create A New Walk Type"}
      actions={actions}
    >
      <MiDetailFields detailFields={detailFields} />
    </MiModal>
  );
};

export default StageWalkTypeCreateModal;
