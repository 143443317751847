import React, { useState } from "react";
import { MiTHEME } from "@miview/theme";
import { roleService } from "@miview/api";
import { PERMISSIONS, TOAST_TYPES, HTTP_STATUSES, en } from "@miview/constants";
import { createToast } from "@miview/toast";
import { usePermissions } from "@miview/hooks";
import {
  MiPageHeader,
  MiButton,
  MiList,
  MiLink,
  MiInputTextbox,
  MiIcon,
  MiModal,
} from "@miview/components";
import { mdiShieldAccountOutline, mdiPlus } from "@mdi/js";
import { useEdit } from "@miview/hooks";

const ManageRoles = () => {
  const [showModal, setShowModal] = useState(false);
  const [roles, setRoles] = useState();
  const edit = useEdit({
    Name: "",
    Description: "",
  });
  const [refresh, setRefresh] = useState(false);

  const permissions = usePermissions();

  const createRole = async () => {
    const response = await roleService.create(edit.edits);
    if (response.status === HTTP_STATUSES.OK) {
      closeAddModal();
      edit.reset();
      setRefresh(!refresh);
    }
  };

  const openAddModal = () => {
    if (!permissions.hasPermission(PERMISSIONS.CAN_CREATE_ROLE)) {
      createToast(
        "You do not have permission to create roles",
        TOAST_TYPES.ERROR
      );
      return;
    }
    setShowModal(true);
  };

  const closeAddModal = () => {
    setShowModal(false);
    edit.reset();
  };

  const actions = [
    {
      onClick: closeAddModal,
      color: MiTHEME.palette.primary.grey,
      name: en.cancel,
      inverse: false,
    },
    {
      onClick: createRole,
      color: MiTHEME.palette.primary.green,
      name: en.save,
      inverse: true,
      disabled: !edit.getValue("Name") || !edit.getValue("Description"),
    },
  ];

  const columns = [
    {
      field: "roleName",
      headerName: "Role Name",
      align: "left",
      renderCell: (e) => <MiLink title={e.value} to={`/roles/${e.id}`} />,
      flex: 1,
    },
    {
      field: "active",
      headerName: "Status",
      align: "left",
      renderCell: (e) => (e.value ? "Active" : "Inactive"),
      flex: 1,
    },
    {
      field: "count",
      headerName: "Used By",
      align: "left",
      renderCell: (e) => e.count,
      width: 180,
    },
    {
      field: "description",
      headerName: "Description",
      align: "left",
      renderCell: (e) => e.description,
      flex: 1,
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiShieldAccountOutline}
        size={1}
        color={MiTHEME.palette.primary.main}
      />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Role"
        icon={mdiPlus}
        inverse={true}
        onClick={openAddModal}
        color={MiTHEME.palette.primary.main}
      />
    );
  };

  return (
    <>
      <MiPageHeader
        title="Manage Roles"
        leftIcon={renderHeaderIcon()}
        color={MiTHEME.palette.primary.main}
        action={actionButton()}
      />
      <MiModal
        id="create-role-form"
        title="Add a new Role"
        actions={actions}
        open={showModal}
        onClose={closeAddModal}
      >
        <MiInputTextbox
          id="roleName"
          value={edit.getValue("Name")}
          handleChange={(e) => edit.update({ Name: e.target.value })}
          labelText="Enter the Role name: "
          required
        />
        <MiInputTextbox
          id="description"
          value={edit.getValue("Description")}
          handleChange={(e) => edit.update({ Description: e.target.value })}
          labelText="Enter the Description: "
          required
        />
      </MiModal>
      <MiList
        data={roles}
        setData={setRoles}
        dataProvider={roleService.getAll}
        hideFilters
        defaultPageSize={20}
        columns={columns}
        refresh={refresh}
      />
    </>
  );
};

export default ManageRoles;
