import React, { useEffect, useState } from "react";
import { useTheme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { StatusChip } from "../../../../components";
import en from "../../../../locale/en.json";
import {
  HTTP_STATUSES,
  AND_FILTER,
  PAGINATION,
  TABLE_STYLES,
} from "@miview/constants";
import { mdiPhone, mdiDelete, mdiPencil } from "@mdi/js";
import { accountService } from "@miview/api";
import { createFilter } from "@miview/utils";
import { useComponentState, useRouter } from "@miview/hooks";
import { MiModalConfirm, MiIcon, MiButton, MiList } from "@miview/components";
import { AddContactsModal } from "../AddContactsModal";
import { CONTACT_STATUS } from "../../../../constants";

const useStyles = makeStyles()(() => ({
  iconstyle: {
    marginRight: 5,
  },
}));

const SupplierContact = () => {
  const [contactTypes, setContactTypes] = useState([]);
  const [branches, setBranches] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const router = useRouter();
  const theme = useTheme();
  const { classes } = useStyles();
  const stateManager = useComponentState();

  const { id } = router.query;

  const openDeleteModal = (obj) => {
    setIsDeleteModalOpen(true);
    setSelectedRow(obj);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedRow(null);
  };

  const deleteContact = async () => {
    stateManager.run(async () => {
      const response = await accountService.contacts.delete({
        id: selectedRow.id,
        successMessage: en.contactDeletedSuccessfully,
      });
      if (response.status === HTTP_STATUSES.NO_CONTENT) {
        setContacts(contacts.filter((e) => e.id !== selectedRow.id));
        closeDeleteModal();
      }
    });
  };

  const openCreateModal = () => {
    setIsEditModalOpen(true);
    setSelectedRow(null);
  };

  const openEditModal = (obj) => {
    setIsEditModalOpen(true);
    setSelectedRow(obj);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedRow(null);
  };

  const createUpdateContact = async (obj) => {
    if (selectedRow) {
      return updateContact(obj);
    }
    return createContact(obj);
  };

  const createContact = async (obj) => {
    stateManager.run(async () => {
      const response = await accountService.accounts.createContact({
        accountId: obj.accountId || id,
        data: obj,
      });
      if (response.status === HTTP_STATUSES.CREATED) {
        setContacts([response.data, ...contacts]);
        closeEditModal();
      }
    });
  };

  const updateContact = async (obj) => {
    stateManager.run(async () => {
      const response = await accountService.contacts.update({
        id: selectedRow.id,
        data: obj,
        successMessage: en.contactEditedSuccessfully,
      });
      if (response.status === HTTP_STATUSES.OK) {
        const newContacts = [...contacts];
        newContacts.splice(contacts.indexOf(selectedRow), 1, response.data);
        setContacts(newContacts);
        setIsEditModalOpen(false);
        setSelectedRow(null);
      }
    });
  };

  const getRowActions = (row) => {
    return [
      {
        text: "Delete",
        onClick: () => openDeleteModal(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={"20px"} />,
      },
      {
        text: "Edit",
        onClick: () => openEditModal(row),
        renderIcon: () => <MiIcon path={mdiPencil} size={"20px"} />,
      },
    ];
  };

  const columns = [
    {
      field: "accountName",
      headerName: en.branchName,
      flex: 2,
    },
    {
      field: "displayName",
      headerName: en.name,
      flex: 1,
      renderCell: (params) => {
        const value = params.value || "";
        return <Typography>{value}</Typography>;
      },
    },
    {
      field: "contactTypeName",
      headerName: en.role,
      flex: 1,
    },
    {
      field: "email",
      headerName: en.email,
      flex: 2,
    },
    {
      field: "phone",
      headerName: en.phone,
      flex: 1,
      renderCell: (params) => {
        const value = params.value || "";
        return (
          <Typography>
            <MiIcon
              path={mdiPhone}
              size={"15px"}
              className={classes.iconstyle}
              color={theme.palette.primary.blue}
            />
            {value}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: en.status,
      flex: 1,
      renderCell: (params) => <StatusChip status={params.value} />,
    },
  ];

  const getContacts = async (config) => {
    return stateManager.run(async () => {
      const f = createFilter(AND_FILTER);
      f.equals({ accountId: [...branches.map((e) => e.id), id] });
      const filters = { ...config.filters, ...f.getFilter() };
      return accountService.contacts.getAll({ ...config, filters });
    });
  };

  const getContactTypes = async () => {
    stateManager.run(async () => {
      const response = await accountService.contactTypes.getAll();
      if (response.status === HTTP_STATUSES.OK) {
        setContactTypes(response.data);
      }
    });
  };

  const getBranches = async () => {
    stateManager.run(async () => {
      const f = createFilter(AND_FILTER);
      f.equals({ parentId: id });
      const response = await accountService.accounts.getAll({
        filters: f.getFilter(),
      });
      if (response.status === HTTP_STATUSES.OK) {
        setBranches(response.data);
      }
    });
  };

  const renderHeaderButtons = () => {
    return (
      <MiButton
        title={en.addNewContact}
        color={theme.palette.primary.white}
        backgroundColor={theme.palette.primary.main}
        onClick={openCreateModal}
        disabled={false}
      />
    );
  };

  useEffect(() => {
    getContactTypes();
    getBranches();
  }, []);

  return (
    <>
      {stateManager.statusTag("supplierContacts")}
      <MiModalConfirm
        open={isDeleteModalOpen}
        title={en.deleteContacts}
        description={en.deleteContact}
        handlePositive={deleteContact}
        handleNegative={closeDeleteModal}
      />
      <AddContactsModal
        isVisible={isEditModalOpen}
        accountId={id}
        row={selectedRow}
        onCancel={closeEditModal}
        onSave={createUpdateContact}
        branches={branches}
        contactTypes={contactTypes}
      />
      <MiList
        data={contacts}
        setData={setContacts}
        dataProvider={getContacts}
        columns={columns}
        paginationMode={PAGINATION.SERVER}
        refresh={branches}
        getRowActions={getRowActions}
        renderHeaderButtons={renderHeaderButtons}
        getRowClassName={(params) =>
          params.row.status === CONTACT_STATUS.inactive
            ? TABLE_STYLES.INACTIVE
            : null
        }
      />
    </>
  );
};

export default React.memo(SupplierContact);
