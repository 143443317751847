export const PURCHASING_NAV = {
  items: [
    {
      name: "Accounts",
      icon: "AssignmentIndSharp",
      children: [
        {
          name: "Suppliers",
          url: "/purchasing/suppliers",
        },
        {
          name: "Manufacturers",
          url: "/purchasing/manufacturers",
        },
      ],
    },
    {
      name: "Quotes",
      icon: "AccountBalanceWalletOutlined",
      url: "/purchasing/quotes",
    },
    {
      name: "Preferred Vendor",
      icon: "AccountBalanceWalletOutlined",
      url: "/purchasing/preferredvendor",
    },
    {
      name: "Requisitions",
      icon: "AccountBalanceWalletOutlined",
      children: [
        {
          name: "Templates",
          url: "/purchasing/requisitions/templates",
        },
      ],
    },
    {
      name: "Purchase Orders",
      icon: "AccountBalanceWalletOutlined",
      children: [
        {
          name: "New Homes",
          url: "/purchasing/purchaseorders/newhomes",
        },
      ],
    },
  ],
};
