import React, { useCallback } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from "prop-types";

const MiSpecsTitle = ({ icon, children, setRightColumnOn, rightColumnOn }) => {
  const activateRightColumn = useCallback(() => {
    setRightColumnOn(true);
  }, [setRightColumnOn]);

  const deactivateRightColumn = useCallback(() => {
    setRightColumnOn(false);
  }, [setRightColumnOn]);

  const renderToggle = () => {
    if (rightColumnOn) {
      return (
        <KeyboardArrowRightIcon
          onClick={deactivateRightColumn}
          style={styles.icon}
        />
      );
    }
    return (
      <KeyboardArrowLeftIcon
        onClick={activateRightColumn}
        style={styles.icon}
      />
    );
  };

  return (
    <div style={styles.titleContainer}>
      <div style={styles.titleIcon}>{icon}</div>
      <div style={styles.titleName}>{children}</div>
      <div style={styles.spacer} />
      <div>{setRightColumnOn && renderToggle()}</div>
    </div>
  );
};

MiSpecsTitle.propTypes = {
  icon: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  rightColumnOn: PropTypes.bool,
  setRightColumnOn: PropTypes.func,
};

const styles = {
  titleContainer: {
    border: "1px solid #dddbda",
    display: "flex",
    alignItems: "center",
    borderRadius: 5,
    height: 42,
  },
  titleIcon: {
    flexGrow: 0,
    padding: "4px 4px 0",
  },
  titleName: {
    flexGrow: 1,
    borderLeft: "1px solid #dddbda",
    paddingLeft: 10,
  },
  icon: {
    cursor: "pointer",
  },
  spacer: {
    flex: 1,
  },
};

export default MiSpecsTitle;
