import React from "react";
import { MiButton, MiCard } from "@miview/components";
import { mdiTag } from "@mdi/js";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  topCardRightArea: {
    display: "flex",
    flexDirection: "row",
  },
  miCard: {
    display: "flex",
    width: "unset",
    marginTop: "1rem",
    marginLeft: "1rem",
    marginRight: "1.5rem",
  },
}));

const RedTagCard = ({ canSubmit, handleRedTagMode, handleRedTagSubmit }) => {
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <MiCard
      icon={mdiTag}
      color={theme.palette.primary.red}
      title={"Red Tag"}
      titleStyle={{ color: "red", marginLeft: "5px" }}
      fieldset={[{ value: "Document Red Tag Details and Issues" }]}
      muiCardContainer={classes.miCard}
      rightArea={
        <div className={classes.topCardRightArea}>
          <MiButton
            title="Cancel"
            color={theme.palette.primary.red}
            onClick={() => handleRedTagMode(false)}
          />
          <MiButton
            title="Submit"
            color={theme.palette.primary.green}
            inverse
            disabled={!canSubmit}
            onClick={handleRedTagSubmit}
          />
        </div>
      }
    />
  );
};

export default RedTagCard;
