export const PLANAREA_FORM_INITIAL_STATE = {
  categoryId: 0,
  planAreaId: 0,
  propertyPlanAreaId: 0,
  propertySectionId: 0,
  planAreaName: "",
  floorId: "",
  isGroundFloor: false,
  imageUrl: "",
  adminEdit: false,
  isRequired: false,
};

export const PLANITEM_FORM_INITIAL_STATE = {
  itemId: "",
  itemName: "",
  description: "",
  topImageName: "",
  frontImageName: "",
  materialId: "",
  materialCategoryId: "",
  materialName: "",
  materialBrand: "",
  materialImageName: "",
  quantity: "",
  categoryId: "",
  walkTypeId: "",
  ruleId: "",
  isDeleted: "",
  type: undefined,
  measurementBackTypeId: "",
  measurementBackValue: "",
  measurementSideTypeId: "",
  measurementSideValue: "",
  stackSizeId: "",
  validateBack: true,
  validateSide: true,
  propertyPlanAreaId: 0,
};

export const ACTION_FORM_INITIAL_STATE = {
  actionStep: 1,
  planAreaName: "",
  propertySectionId: "",
  floorId: "",
  isGroundFloor: "",
  planAreaId: "",
};

export const MISC_ITEM_FORM_INITIAL_STATE = {
  itemName: "",
  description: "",
  walkTypeId: "",
  topImageUrl: "",
  frontImageUrl: "",
  materialId: "",
  id: "",
};

export const OPTIONS_FORM_INITIAL_STATE = {
  optionId: null,
  optionName: "",
  optionDescription: "",
  optionTypeId: "",
  optionAction: "",
  isDraft: true,
  actions: [],
  actionForm: ACTION_FORM_INITIAL_STATE,
  miscItemForm: MISC_ITEM_FORM_INITIAL_STATE,
};

export const PLANOPTION_FORM_INITIAL_STATE = {
  optionId: null,
  optionName: "",
  optionDescription: "",
  optionTypeId: "",
  optionAction: "",
  isDraft: true,
  actions: [],
  actionForm: ACTION_FORM_INITIAL_STATE,
  miscItemForm: MISC_ITEM_FORM_INITIAL_STATE,
};
