import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
  return {
    container: { width: "100%", cellpadding: "0", cellspacing: "0" },
    cell: {
      padding: `10px`,
      borderTop: `1px solid ${theme.palette.lightAccent.grey}`,
      textOverflow: "ellipsis",
    },
    headerCell: {
      padding: "10px",
      color: theme.palette.primary.main,
      fontSize: theme.fontSize.medium,
      fontWeight: theme.fontWeight.regular,
    },
  };
});

export const MiTableCustom = (props) => {
  const {
    columns,
    rows,
    headerRowStyles = {},
    headerStyles = {},
    cellStyles = {},
    tableContainerStyles = {},
  } = props;

  const { classes } = useStyles();

  const renderHeaderItem = (col, index) => {
    if (col.renderHeader) {
      return col.renderHeader({ value: col.headerName });
    }
    return (
      <th key={index} className={classes.headerCell} style={headerStyles}>
        {col.headerName}
      </th>
    );
  };

  const renderHeaderRow = () => {
    return <tr>{columns.map((col, index) => renderHeaderItem(col, index))}</tr>;
  };

  const renderCellItem = (row, col, index) => {
    return (
      <td
        key={index}
        className={classes.cell}
        style={{ ...cellStyles, width: col.width }}
      >
        {col.renderCell
          ? col.renderCell({ row: row, value: row[col.field] })
          : row[col.field]}
      </td>
    );
  };

  const renderRows = () => {
    if (!rows?.length) {
      return;
    }
    return rows.map((row, index) => (
      <tr key={index}>
        {columns.map((col, index) => renderCellItem(row, col, index))}
      </tr>
    ));
  };

  return (
    <table className={classes.container} style={tableContainerStyles}>
      <thead style={headerRowStyles}>{renderHeaderRow()}</thead>
      <tbody>{renderRows()}</tbody>
    </table>
  );
};
