import React from "react";
import { Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiAccordion, MiTable } from "@miview/components";

const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    width: "100%",
    alignSelf: "stretch",
    ...theme?.overrides?.MuiDataGrid,
  },
  gridContainer: {
    width: "100%",
  },
}));

const PoTable = (props) => {
  const { columns, rows, title } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const getAccordionDetails = () => {
    return (
      <Grid className={classes.gridContainer}>
        <MiTable
          pinnedColumns={{
            left: columns.slice(0, 5).map((item) => item.field),
          }}
          columns={columns}
          rows={rows}
          className={classes.tableContainer}
          rowHeight={70}
          autoPageSize={true}
          autoHeight={true}
          hideFooter={true}
          sx={{
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              color: theme.palette.primary.main,
            },
            "& .MuiDataGrid-pinnedColumnHeaders": {
              "box-shadow": "none",
            },
            "& .MuiDataGrid-pinnedColumns": {
              "box-shadow": "none",
            },
            "& .MuiDataGrid-cell": {
              padding: "0px 0px 0px 0px !important",
            },
          }}
        />
      </Grid>
    );
  };
  return (
    <div className={classes.container}>
      <MiAccordion
        renderDetail={getAccordionDetails}
        subTitle={`${rows.length} Results`}
        isEnableSubTitle={true}
        config={[
          {
            title,
            id: "testIf",
            activeItemId: "activeEll",
            iconPosition: "left",
          },
        ]}
      />
    </div>
  );
};
export default PoTable;
