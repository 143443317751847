import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const bonusSettingsService = {
  getPayActivityTypes: async () => {
    const options = await apiOptions({
      url: `${API_V3}/systemtypes`,
      params: {
        name: "PayActivityType",
      },
      method: "get",
    });
    return apiRequest(options);
  },
  getWalkTypes: async () => {
    const options = await apiOptions({
      url: `${API_V3}/walktypes`,
      method: "get",
    });
    return apiRequest(options);
  },
  getWalkStatus: async () => {
    const options = await apiOptions({
      url: `${API_V3}/statuses`,
      params: {
        category: "Walk",
      },
      method: "get",
    });
    return apiRequest(options);
  },
  getBuilderSettings: async () => {
    const options = await apiOptions({
      url: `${API_V3}/bonuses/settings`,
      method: "get",
    });
    return apiRequest(options);
  },
  putBuilderSettings: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/bonuses/settings`,
      method: "put",
      data: request,
    });
    return apiRequest(options);
  },
};
