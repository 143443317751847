import { makeStyles } from "tss-react/mui";

const styles = {
  miInput: {
    "& .icon-button": { opacity: 0 },
    "&:hover .icon-button": { opacity: 1 },
    "& .react-datepicker-wrapper": { width: "100%" },
    "& .react-datepicker__input-container": {
      width: "100%",
    },
  },
  opaqueOnHover: { "&:hover": { opacity: "1 !important" } },
  miButton: {
    "&:hover": { filter: "opacity(90%)" },
    "&:active": { transform: "translateY(1px)" },
    "&:focus": { boxShadow: "0 0 7px #88aaff" },
  },
  miFilterTray: { transition: "min-width .1s ease-in-out", minWidth: "0" },
  trayOpen: { minWidth: "300px", marginRight: "1rem" },
  buttonMicallout: {
    "&:hover": { filter: "opacity(90%)" },
    "&:active": { transform: "translateY(1px)" },
  },

  miCard: {
    border: "1px solid #DDD",
    transition: "border .3s ease",
    cursor: "pointer",
    "&:hover": { border: "1px solid #CCC", transition: "border .3s ease" },
  },
  miLink: {
    textDecoration: "none",
    "&:hover": { textDecoration: "underline" },
  },
  miModalBackdrop: { opacity: 0.6, animation: "$fadeIn .3s" },
  miModal: { animation: "$scaleIn .2s ease-in-out" },
  scaleIn: { animation: "$scaleIn .2s ease-in-out" },
  scaleOut: { animation: "$scaleOut .3s ease-in-out" },
  scaleIn2: { animation: "$scaleIn2 .3s ease-in-out" },
  scaleOut2: { animation: "$scaleOut2 .3s ease-in-out" },
  fadeIn: { animation: "$fadeIn .3s ease-in-out" },
  fadeOut: { animation: "$fadeOut .3s ease-out" },
  miModalOut: { animation: "$scaleOut .2s ease-out" },
  slideUp: { animation: "$slideUp .3s ease-in-out" },
  slideIn: { animation: "$slidein .3s ease-in-out" },
  slideOut: { animation: "$slideOut .3s ease-in-out" },
  slideInLeft: { animation: "$slidein 1s linear" },
  slideOutLeft: { animation: "$slideOut 1s linear" },
  slideInRight: { animation: "$slidein2  1s linear" },
  slideOutRight: { animation: "$slideOut2  1s linear" },
  "@keyframes slidein": {
    "0%": { transform: "translateX(calc(100%))" },
    "25%": { transform: "translateX(calc(75%))" },
    "50%": { transform: "translateX(calc(50%))" },
    "75%": { transform: "translateX(calc(25%))" },
    "100%": { transform: "translateX(calc(0%))" },
  },
  "@keyframes slideOut": {
    "0%": { transform: "translateX(calc(0%))" },
    "25%": { transform: "translateX(calc(-25%))" },
    "50%": { transform: "translateX(calc(-50%))" },
    "75%": { transform: "translateX(calc(-75%))" },
    "100%": { transform: "translateX(calc(-100%))" },
  },
  "@keyframes slidein2": {
    "0%": { transform: "translateX(calc(-100%))" },
    "100%": { transform: "translateX(0px)" },
  },
  "@keyframes slideOut2": {
    "0%": { transform: "translateX(0px)" },
    "100%": { transform: "translateX(calc(100%))" },
  },
  "@keyframes scaleIn": {
    "0%": { transform: "scale(0)" },
    "100%": { transform: "scale(1)" },
  },
  "@keyframes scaleOut": {
    "0%": { transform: "scale(1)" },
    "100%": { transform: "scale(0)" },
  },
  "@keyframes scaleIn2": {
    "0%": { transform: "scale(0.5)" },
    "100%": { transform: "scale(1)" },
  },
  "@keyframes scaleOut2": {
    "0%": { transform: "scale(1)" },
    "100%": { transform: "scale(0.5)" },
  },
  "@keyframes fadeIn": { "0%": { opacity: 0 }, "100%": { opacity: 0.6 } },
  "@keyframes fadeOut": { "0%": { opacity: 0.6 }, "100%": { opacity: 0 } },
  "@keyframes slideUp": {
    "0%": { transform: "translateY(100px)" },
    "100%": { transform: "translateY(0px)" },
  },
  bonusSettingEditOuter: { display: "flex", flexDirection: "column" },
  bonusSettingEditInputDirectionRow: {
    display: "flex",
    marginBottom: "1rem",
    alignItems: "flex-end",
  },
  bonusSettingEditInputMargin: { marginBottom: "1rem" },
};

export const miStyles = makeStyles()(styles);
