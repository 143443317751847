import React from "react";
import { MiIcon, MiCard } from "../index.ts";
import { Grid, useTheme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { mdiEmailOutline, mdiPhone } from "@mdi/js";

const useStyles = makeStyles()((theme) => ({
  contactsCell: {
    padding: "0px 0px 0px 10px",
    fontWeight: theme.fontWeight.regular,
  },
  contactsIcon: {
    marginRight: 10,
  },
}));

const RenderBody = ({ contact }) => {
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={4}>
        <Grid>
          <Typography>
            {contact?.firstName} {contact?.lastName}
          </Typography>
        </Grid>
        <Grid>{contact.contactTypeName}</Grid>
      </Grid>
      <Grid item xs={4}>
        <MiIcon
          className={classes.contactsIcon}
          path={mdiEmailOutline}
          color={theme.palette.secondary.bluegrey}
          size={"15px"}
        />
        {contact?.email}
      </Grid>
      <Grid item xs={4}>
        <MiIcon
          className={classes.contactsIcon}
          path={mdiPhone}
          color={theme.palette.secondary.bluegrey}
          size={"15px"}
        />
        {contact?.phone}
      </Grid>
    </Grid>
  );
};

export const MiContactCard = ({ contact }) => {
  const theme = useTheme();
  const styles = {
    contactsRow: {
      backgroundColor: theme.palette.light.grey,
      borderRadius: 5,
      marginBottom: 10,
    },
  };
  return (
    <MiCard
      containerStyles={styles.contactsRow}
      renderBody={() => <RenderBody contact={contact} />}
    />
  );
};
