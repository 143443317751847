import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { InputBase } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

const useStyles = makeStyles()((theme) => ({
  root: {
    margin: "auto",
  },
  list: {
    width: 380,
    height: 330,
    marginTop: 15,
    overflow: "auto",
  },
  button: {
    margin: 5,
    color: theme.palette.primary.main,
  },
  heading: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    backgroundColor: theme.palette.lightAccent.grey,
    height: 35,
    fontWeight: "300",
  },
  search: {
    position: "relative",
    borderTop: `1px solid ${theme.palette.accent.grey}`,
    borderBottom: `1px solid ${theme.palette.accent.grey}`,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: `${theme.spacing(0, 3)}rem`,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1rem + ${theme.spacing(8)}rem)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    color: "black",
    fontSize: 14,
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a?.filter((value) => b.indexOf(value) !== -1);
}

function moveUp(listItemsRight, rightchecked) {
  let elementIndex = listItemsRight.indexOf(rightchecked[0]);
  listItemsRight.splice(elementIndex, 1);
  listItemsRight.splice(elementIndex - 1, 0, rightchecked[0]);
  return [...listItemsRight];
}

function moveDown(listItemsRight, rightchecked) {
  let elementIndex = listItemsRight.indexOf(rightchecked[0]);
  listItemsRight.splice(elementIndex, 1);
  listItemsRight.splice(elementIndex + 1, 0, rightchecked[0]);
  return [...listItemsRight];
}

export const MiTransferList = ({
  headerLeft,
  headerRight,
  listItemsLeft,
  listItemsRight,
  headingStyles,
  listStyles,
  buttonStyles,
  rightHandler,
  leftHandler,
  readOnly,
  labelField = "name",
  secondLabelField,
  onClick,
  showUpDown,
}) => {
  const { classes } = useStyles();
  const [checked, setChecked] = useState([]);
  const [leftSearchText, setLeftSearchText] = useState("");
  const [rightSearchText, setRightSearchText] = useState("");
  const leftChecked = intersection(checked, listItemsLeft);
  const rightChecked = intersection(checked, listItemsRight);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);

    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    rightHandler(listItemsRight.concat(listItemsLeft));
    leftHandler([]);
  };

  const handleCheckedRight = () => {
    rightHandler(listItemsRight.concat(leftChecked));
    leftHandler(not(listItemsLeft, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    leftHandler(listItemsLeft.concat(rightChecked));
    rightHandler(not(listItemsRight, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    leftHandler(listItemsLeft.concat(listItemsRight));
    rightHandler([]);
  };
  const handleMoveUp = () => {
    if (listItemsRight.indexOf(rightChecked[0]) !== 0) {
      rightHandler(moveUp(listItemsRight, rightChecked));
    }
  };
  const handleMoveDown = () => {
    if (listItemsRight.indexOf(rightChecked[0]) !== listItemsRight.length - 1) {
      rightHandler(moveDown(listItemsRight, rightChecked));
    }
  };

  const handleSearch = (searchText, heading) => {
    heading === headerLeft
      ? setLeftSearchText(searchText)
      : setRightSearchText(searchText);
  };

  const handleFilter = (arr, heading) => {
    const searchText =
      heading === headerLeft ? leftSearchText : rightSearchText;

    return arr.filter((item) => {
      return item[labelField]?.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  const customList = (items, heading) => (
    <Paper className={classes.list} style={{ ...listStyles }}>
      <div className={classes.heading} style={{ ...headingStyles }}>
        {heading ? heading : "LIST ITEMS"}
      </div>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>

        <InputBase
          id={heading}
          placeholder="Search"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onChange={(e) => handleSearch(e.target.value, heading)}
          inputProps={{ "aria-label": "search" }}
        />
      </div>

      <List dense component="div" role="list">
        {items.map((value, index) => {
          const labelId = `transfer-list-item-${value[labelField]}${
            index + heading
          }-label`;

          return (
            <ListItem
              key={labelId}
              role="listitem"
              button
              disabled={value?.disable || readOnly}
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1 && !readOnly}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>

              <ListItemText
                id={labelId}
                primary={value.text || value[labelField]}
              />
              {secondLabelField && (
                <ListItemText
                  sx={{ textAlign: "right" }}
                  id={labelId}
                  primary={value[secondLabelField]}
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
      onClick={onClick}
      className={classes.root}
    >
      <Grid item>
        {customList(handleFilter(listItemsLeft, headerLeft), headerLeft)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={listItemsLeft.length === 0}
            aria-label="move all listItemsRight"
            style={{ ...buttonStyles }}
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked?.length === 0}
            aria-label="move selected listItemsRight"
            style={{ ...buttonStyles }}
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked?.length === 0}
            aria-label="move selected listItemsLeft"
            style={{ ...buttonStyles }}
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={listItemsRight.length === 0}
            aria-label="move all listItemsLeft"
            style={{ ...buttonStyles }}
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {customList(handleFilter(listItemsRight, headerRight), headerRight)}
      </Grid>
      {showUpDown && (
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleMoveUp}
              disabled={rightChecked?.length !== 1}
              aria-label="move selected up"
              style={{ ...buttonStyles }}
            >
              <KeyboardArrowUpIcon />
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleMoveDown}
              disabled={rightChecked?.length !== 1}
              aria-label="move selected down"
              style={{ ...buttonStyles }}
            >
              <KeyboardArrowDownIcon />
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

MiTransferList.defaultProps = {
  showUpDown: false,
};

MiTransferList.propTypes = {
  headingStyles: PropTypes.object,
  listStyles: PropTypes.object,
  buttonStyles: PropTypes.object,
  showUpDown: PropTypes.bool,
};
