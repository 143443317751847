import React from "react";
import { Typography, useTheme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((_, { props }) => ({
  root: {
    fontSize: props.fontSize,
    fontWeight: props.fontWeight,
    marginBottom: props.marginBottom,
    color: props.color,
    maxWidth: props.maxWidth,
  },
}));

const TypographyCustom = ({ text, component }) => {
  const theme = useTheme();
  const types = {
    h1: {
      fontSize: theme.fontSize.xlarge,
      fontWeight: theme.fontWeight.medium,
      color: theme.palette.secondary.bluegrey,
      marginBottom: 10,
    },
    p1: {
      fontSize: theme.fontSize.medium,
      color: theme.palettte.main.accent,
      marginBottom: 10,
      maxWidth: 464,
    },
  };
  const { classes } = useStyles({ props: types[component] });
  return (
    <Typography component="div" className={classes.root}>
      {text}
    </Typography>
  );
};

export default TypographyCustom;
