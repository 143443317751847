import React from "react";
import { MiCheckbox } from "@miview/components";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getImageUrl } from "@miview/utils";
import { useCdn } from "@miview/hooks";

export const MiMaterialField = (props) => {
  const { materials = [], materialId, updateMaterial } = props;

  const getDefaultLabel = () => {
    const selectedItem = materials.find(
      (material) => material.id === materialId
    );
    if (selectedItem) {
      return `${selectedItem.name} - ${selectedItem.itemNumber}`;
    }
    return "Material";
  };

  const cdn = useCdn();
  return (
    <Autocomplete
      ListboxProps={{ style: styles.listbox }}
      multiple={false}
      id="material-selector"
      options={materials}
      getOptionLabel={(option) => `${option.name} - ${option.itemNumber}`}
      onChange={(event, value, reason) => {
        if (reason === "clear") {
          updateMaterial("");
        }
      }}
      renderOption={(_, option) => {
        return (
          <div
            style={{ display: "flex" }}
            key={option.id}
            onClick={() => updateMaterial(option.id)}
          >
            <a
              href={getImageUrl(option.imageName, cdn)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={styles.imgContainer}
                src={getImageUrl(option.imageName, cdn)}
              />
            </a>
            <div style={{ display: "inline" }}>
              <div>
                <MiCheckbox
                  label={`${option.name} - ${option.itemNumber}`}
                  checked={option.id === Number(materialId)}
                  color="rgb(75, 202, 129)"
                  onChange={() => updateMaterial(option.id)}
                />
              </div>
              <div style={styles.description}>{option.description}</div>
            </div>
          </div>
        );
      }}
      renderInput={(params) => (
        <TextField
          style={{ width: "95%" }}
          {...params}
          variant="outlined"
          label={getDefaultLabel()}
        />
      )}
    />
  );
};

const styles = {
  imgContainer: {
    height: 40,
    width: 40,
    border: "1px solid gray",
    borderRadius: 5,
    marginRight: 16,
  },
  listbox: {
    maxHeight: 300,
  },
  description: {
    fontSize: 11,
    marginLeft: 4,
  },
};
