import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const seriesService = {
  getAll: async (params) => {
    const options = await apiOptions({
      url: `${API_V3}/series`,
      method: "get",
      params,
    });
    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/series/${id}`,
      method: "get",
    });
    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/series/${id}`,
      method: "patch",
      data: request,
    });

    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/series`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  createPlan: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/series/${id}/plans`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },
};
