import React, { useEffect, useState } from "react";
import { InputAdornment, IconButton, ClickAwayListener } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiDatePicker } from "@miview/components";
import EventIcon from "@mui/icons-material/Event";
import CloseIcon from "@mui/icons-material/Close";
import TdInputBox from "./TdInputBox";
import moment from "moment";

const useStyles = makeStyles()((theme) => ({
  componentCustom: {
    position: "relative",
    width: "100%",
    "& .Mui-disabled": {
      color: "inherit",
      backgroundColor: theme.palette.primary.white,
    },
  },
  IconButtonEvent: {
    padding: 0,
  },
  datePickerContainer: {
    position: "absolute",
    left: 0,
    top: "101%",
    backgroundColor: theme.palette.primary.white,
    zIndex: 1,
    boxShadow: "0px 8px 10px 0px rgb(0 0 0 / 23%)",
  },
}));

export const TdDatepicker = (props) => {
  const { classes } = useStyles();
  const [showDatepicker, setShowDatepicker] = useState(false);
  const { value, onChange, disabled } = props;
  const [date, setDate] = useState(value);
  const handleClickAway = () => {
    setShowDatepicker(false);
  };
  const TextBoxHandleChange = () => {
    setDate(moment(date).format("DD/MM/YYYY"));
    setShowDatepicker(true);
  };
  const handleDateChange = (dateValue) => {
    setDate(dateValue);
    onChange(dateValue);
  };

  const renderInput = () => {
    return (
      <TdInputBox
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                disabled={disabled}
                className={classes.IconButtonEvent}
                onClick={() => setShowDatepicker(!showDatepicker)}
                size="large"
              >
                {showDatepicker ? <CloseIcon /> : <EventIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        handleChange={TextBoxHandleChange}
        fullWidth={props?.fullWidth}
        labelText={props.labelText}
        variant={props.variant}
        disabled={true}
        {...props}
      />
    );
  };
  useEffect(() => {
    renderInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <div className={classes.componentCustom}>
      {renderInput()}
      {showDatepicker && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={classes.datePickerContainer}>
            <MiDatePicker
              defaultDate={date}
              setDefaultDate={(val) => handleDateChange(val)}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default TdDatepicker;
