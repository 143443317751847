import React, { useEffect, useState } from "react";
import {
  MiTab,
  MiPageHeader,
  MiLoader,
  MiTable,
  MiBox,
  MiIcon,
} from "@miview/components";
import { mdiArrowLeft } from "@mdi/js";
import {
  mdiFileDocumentOutline,
  mdiCheckCircle,
  mdiCloseCircle,
} from "@mdi/js";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Grid } from "@mui/material";
import { MiTHEME } from "@miview/theme";
import { useRouter } from "@miview/hooks";
import { integrationService } from "@miview/api";
import { INTEGRATIONS } from "@miview/constants";
import { JsonModal } from "../components";
import moment from "moment";

const useStyles = makeStyles()(() => {
  return {
    button: {
      marginRight: 10,
      backgroundColor: "inherit",
      border: 0,
      cursor: "pointer",
    },
  };
});

const tabs = [
  {
    label: "Details",
    value: "details",
  },
];

export const HistoryDetails = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const router = useRouter();
  const [currentTab, setCurrentTab] = useState(tabs[0].value);
  const [isLoading, setIsLoading] = useState(false);
  const [run, setRun] = useState({});
  const [runDetails, setRunDetails] = useState([]);
  const [selected, setSelected] = useState();
  const [showModal, setShowModal] = useState(false);

  const { id: runId } = router.query;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchRunData = async () => {
    const response = await integrationService.integrations.getRun(
      INTEGRATIONS.DRHORTON,
      runId
    );
    if (response.status === 200) {
      setRun(response.data);
    }
  };

  const fetchRunDetailData = async () => {
    const response = await integrationService.integrations.getRunDetails({
      name: INTEGRATIONS.DRHORTON,
      id: runId,
      params: { pageSize: 9999 },
    });
    if (response.status === 200) {
      setRunDetails(response.data);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      fetchRunData();
      fetchRunDetailData();

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  const handleBack = () => {
    router.goBack();
  };

  const handleCloseModal = () => {
    setSelected(null);
    setShowModal(false);
  };

  const actions = [
    {
      onClick: handleCloseModal,
      color: theme.palette.primary.grey,
      name: "Close",
      inverse: true,
    },
  ];

  const handleViewJson = (row) => {
    setSelected(row);
    setShowModal(true);
  };

  const CloseCircleIcon = () => {
    return (
      <MiIcon
        path={mdiCloseCircle}
        size={1}
        color={theme.palette.primary.red}
      />
    );
  };

  const CompleteCircleIcon = () => {
    return (
      <MiIcon
        path={mdiCheckCircle}
        size={1}
        color={theme.palette.primary.green}
      />
    );
  };

  const renderIconDocument = ({ row }) => {
    return (
      <div onClick={() => handleViewJson(row)}>
        <MiIcon
          path={mdiFileDocumentOutline}
          size={1}
          color={theme.palette.primary.grey}
        />
      </div>
    );
  };

  const renderErrorCell = ({ value }) => {
    if (!value) {
      return <CompleteCircleIcon />;
    }
    return (
      <div>
        <CloseCircleIcon />
        <span style={{ color: theme.palette.primary.red, marginLeft: 5 }}>
          {value}
        </span>
      </div>
    );
  };

  const renderActionCell = ({ value }) => {
    if (value === true) {
      return <div>{`New`}</div>;
    }
    return <div>{`Updated`}</div>;
  };

  const renderTabContent = () => {
    return (
      <MiBox>
        <Grid>
          <MiTable
            columns={columns}
            rows={runDetails}
            disableColumnMenu={true}
          />
        </Grid>
      </MiBox>
    );
  };

  const renderBackArrow = () => {
    return (
      <button className={classes.button} onClick={handleBack}>
        <MiIcon
          path={mdiArrowLeft}
          size={1}
          color={theme.palette.primary.main}
        />
      </button>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "parsedEntityType",
      headerName: "Type",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "isNew",
      headerName: "Action",
      width: 100,
      renderCell: (params) => renderActionCell(params),
    },
    {
      field: "log",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => renderErrorCell(params),
    },
    {
      field: "resultRaw",
      headerName: "",
      disableColumnMenu: true,
      width: 80,
      renderCell: (params) => renderIconDocument(params),
    },
  ];

  return (
    <div>
      <MiPageHeader
        title={`Run ${runId} - ${moment(run.startTime).format("lll")}`}
        color={theme.palette.primary.main}
        leftIcon={renderBackArrow()}
      />
      {isLoading && <MiLoader type="linear" />}
      <MiTab
        tabs={tabs}
        currenttab={currentTab}
        tabIndicatorColor={MiTHEME.palette.secondary.purple}
        onTabChange={handleTabChange}
        renderTabContent={renderTabContent}
      />
      <JsonModal
        title="Run - D.R. Horton - JSON response"
        actions={actions}
        open={showModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        selected={selected}
      />
    </div>
  );
};
