import React, { useState, useEffect } from "react";
import { toList } from "../../../utils";
import { TabulatedPage } from "../TabulatedPage";
import {
  propertySectionService,
  propertySectionCategoryService,
} from "@miview/api";
import { useEdit, useRouter } from "@miview/hooks";
import { HTTP_STATUSES } from "@miview/constants";
import { MiDetailFields, MiTab } from "@miview/components";

const AreaDetails = () => {
  const [toggleEdit, setToggleEdit] = useState(false);
  const [activeTab] = useState(global.ACTIVE_TAB.DETAILS);
  const [propertySectionCategories, setPropertySectionCategories] = useState(
    []
  );
  const [area, setArea] = useState({});
  const router = useRouter();
  const edit = useEdit(area);

  const { id: areaId } = router.params;

  const getArea = async () => {
    const response = await propertySectionService.get(areaId);
    if (response.status === HTTP_STATUSES.OK) {
      setArea(response.data);
    }
  };

  const getPropertySectionCategories = async () => {
    const response = await propertySectionCategoryService.getAll();
    setPropertySectionCategories(toList(response.data, "id", "name"));
  };

  useEffect(() => {
    getArea();
    getPropertySectionCategories();
  }, []);

  const detailFields = [
    {
      label: "Area Name",
      value: edit.getValue("name"),
      fieldType: "text",
      readOnly: !toggleEdit,
      required: true,
      clickButton: () => setToggleEdit(!toggleEdit),
      setValue: (value) => edit.update({ name: value }),
      width: "33%",
    },
    {
      label: "Weight",
      value: edit.getValue("weight"),
      fieldType: "text",
      readOnly: !toggleEdit,
      clickButton: () => setToggleEdit(!toggleEdit),
      setValue: (value) => edit.update({ weight: value }),
      width: "33%",
    },
    {
      label: "Description",
      value: edit.getValue("description"),
      fieldType: "text",
      readOnly: !toggleEdit,
      required: true,
      clickButton: () => setToggleEdit(!toggleEdit),
      setValue: (value) => edit.update({ description: value }),
      width: "33%",
    },
    {
      label: "Category",
      options: propertySectionCategories,
      fieldType: "select",
      value: edit.getValue("propertySectionCategoryId"),
      defaultOption: "--",
      readOnly: !toggleEdit,
      clickButton: () => setToggleEdit(!toggleEdit),
      setValue: (value) =>
        edit.update({
          propertySectionCategoryId: value,
        }),
      width: "33%",
    },
    {
      label: "Default",
      fieldType: "checkbox",
      value: edit.getValue("isDefault"),
      readOnly: !toggleEdit,
      required: true,
      setValue: (e) => edit.update({ isDefault: e }),
      clickButton: () => setToggleEdit(!toggleEdit),
      width: "33%",
    },
  ];

  const cancelEditArea = () => {
    edit.reset();
    setToggleEdit(false);
  };

  const updateData = async () => {
    await propertySectionService.update(areaId, edit.edits);
    cancelEditArea();
    await getArea();
  };

  const isSaveDisabled = () => {
    return !edit.allFilled("name", "description");
  };

  return (
    <TabulatedPage
      toggleEdit={toggleEdit}
      cancelEdit={cancelEditArea}
      onSave={updateData}
      pageTitle="Area Details"
      fieldSet={[]}
      activeTab={activeTab}
      saveDisabled={isSaveDisabled()}
      renderPage={() =>
        activeTab == global.ACTIVE_TAB.DETAILS && (
          <div>
            <MiDetailFields detailFields={detailFields} />
          </div>
        )
      }
      renderTabs={() => (
        <MiTab
          tabs={[{ label: "Details", value: global.ACTIVE_TAB.DETAILS }]}
          currenttab={activeTab}
        />
      )}
    />
  );
};

export default AreaDetails;
