import React, { useState, useEffect, useRef } from "react";
import { MiDatePicker, MiButton } from "../index.ts";
import { THEME } from "@miview/theme";
import moment from "moment";
import { mdiCalendarBlank } from "@mdi/js";

const styles = {
  dropDown: {
    borderRadius: 6,
    backgroundColor: "white",
    padding: 10,
    minWidth: 250,
    position: "absolute",
    zIndex: 100,
  },
  selector: {
    borderRadius: 8,
    margin: "0px 0px 5px",
    border: "none",
    fontSize: 20,
    fontWeight: 400,
    padding: "20px 18px",
    backgroundColor: THEME.GREY_LIGHT_BACKGROUND,
  },
  option: {
    borderRadius: 8,
    marginBottom: 10,
    fontSize: 20,
    fontWeight: 400,
    padding: "20px 0",
    borderColor: THEME.GREY_LIGHT,
    backgroundColor: THEME.GREY_LIGHT_BACKGROUND,
  },
  optionSelected: {
    borderRadius: 8,
    fontSize: 20,
    fontWeight: 400,
    padding: "20px 0",
    backgroundColor: THEME.BLUE_PRIMARY,
    color: "white",
    borderColor: THEME.BLUE_PRIMARY,
    marginBottom: 10,
  },
  calendars: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  calendar: {
    borderRadius: 8,
    marginBottom: 10,
    fontSize: 20,
    fontWeight: 300,
    padding: "20px 20px",
    minWidth: 100,
    borderColor: THEME.GREY_LIGHT,
    backgroundColor: THEME.GREY_LIGHT_BACKGROUND,
  },
  calendarCol: {
    display: "flex",
    flexDirection: "column",
  },
  calendarLabel: {
    margin: "2px 5px",
  },
  submit: {
    flexGrow: 0,
    borderRadius: 8,
    marginBottom: 10,
    fontSize: 20,
    fontWeight: 400,
    padding: "20px 20px",
    borderColor: THEME.GREY_LIGHT,
  },
  button: {
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 400,
    outline: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 18px",
    border: "none",
    borderRadius: 8,
    height: 32,
    minWidth: 32,
    margin: "0px 0px 5px",
    userSelect: "none",
    backgroundColor: THEME.GREY_LIGHT_BACKGROUND,
  },
};

const todayStart = moment().startOf("day").format();
const todayEnd = moment().endOf("day").format();

const DATE_RANGE_OPTIONS_DEFAULT = [
  {
    name: "Today",
    start: todayStart,
    end: todayEnd,
  },
  {
    name: "Tomorrow",
    start: moment().add(1, "days").startOf("day").format(),
    end: moment().add(1, "days").endOf("day").format(),
  },
];

export const DateRangePicker = (props) => {
  const {
    options = DATE_RANGE_OPTIONS_DEFAULT,
    defaultStartDate = todayStart,
    defaultEndDate = todayEnd,
    getSelectedRange = () => null,
    rootStyles = {},
  } = props;

  const dropDownRef = useRef();

  const [isDatePickerActive, setIsDatePickerActive] = useState(false);
  const [isDropDownActive, setIsDropDownActive] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState("start");
  const [dropDownDate, setDropDownDate] = useState({
    start: defaultStartDate,
    end: defaultEndDate,
  });
  const [rangeDate, setRangeDate] = useState({
    start: dropDownDate.start,
    end: dropDownDate.end,
  });

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e) => {
    if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
      setIsDropDownActive(false);
      setIsDatePickerActive(false);
    }
  };

  const handleOptionClick = (option) => {
    setDropDownDate({
      start: option.start,
      end: option.end,
    });
    getSelectedRange({
      start: moment(option).format("YYYY-MM-DD"),
      end: moment(option).format("YYYY-MM-DD"),
    });
    setIsDropDownActive(false);
  };

  const handleCalendarClick = (timePeriod) => {
    setSelectedCalendar(timePeriod);
    setIsDatePickerActive(true);
  };

  const dropDownBody = () => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <i className="fa fa-calendar" style={{ fontSize: 15 }} />
        </div>
        <div style={{ display: "flex", padding: "0 20px 0 10px" }}>
          {moment(dropDownDate.start).format("M.DD.YY") +
            " - " +
            moment(dropDownDate.end).format("M.DD.YY")}
        </div>
        <div style={{ display: "flex" }}>
          <i className="fa fa-chevron-down" style={{ fontSize: 12 }} />
        </div>
      </>
    );
  };

  return (
    <div ref={dropDownRef} style={rootStyles}>
      <button
        style={styles.button}
        onClick={() => {
          setRangeDate({
            start: dropDownDate.start,
            end: dropDownDate.end,
          });
          setIsDropDownActive(!isDropDownActive);
        }}
      >
        {dropDownBody()}
      </button>

      {/* Render the dropdown */}
      {isDropDownActive && (
        <div style={styles.dropDown}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={styles.calendars}>
              <div style={styles.calendarCol}>
                <div style={styles.calendarLabel}>From</div>
                <MiButton
                  icon={mdiCalendarBlank}
                  fontSize={18}
                  title={moment(rangeDate.start).format("M.DD.YY")}
                  style={styles.calendar}
                  onClick={() => handleCalendarClick("start")}
                />
              </div>
              <div style={styles.calendarCol}>
                <div style={styles.calendarLabel}>To</div>
                <MiButton
                  icon={mdiCalendarBlank}
                  fontSize={18}
                  title={moment(rangeDate.end).format("M.DD.YY")}
                  style={styles.calendar}
                  onClick={() => handleCalendarClick("end")}
                />
              </div>
            </div>
            {options.map((option) => {
              return (
                <MiButton
                  key={option.name}
                  title={option.name}
                  style={
                    rangeDate.start + rangeDate.end ===
                    option.start + option.end
                      ? styles.optionSelected
                      : styles.option
                  }
                  onClick={() => handleOptionClick(option)}
                />
              );
            })}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <MiButton
                title="Submit"
                style={styles.submit}
                onClick={() => {
                  setDropDownDate({
                    start: rangeDate.start,
                    end: rangeDate.end,
                  });
                  getSelectedRange(rangeDate);
                  setIsDropDownActive(false);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {/* Render the DatePicker */}
      {isDatePickerActive && (
        <div style={styles.dropDown}>
          <MiDatePicker
            defaultDate={
              selectedCalendar === "start" ? rangeDate.start : rangeDate.end
            }
            setDefaultDate={(date) => {
              selectedCalendar === "start"
                ? setRangeDate({ ...rangeDate, start: date })
                : setRangeDate({ ...rangeDate, end: date });
              setIsDatePickerActive(false);
            }}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <MiButton
              title="Back"
              style={styles.submit}
              onClick={() => setIsDatePickerActive(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
