import { useState } from "react";
import { systemTypeService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

const handleGetSystemTypesByName = async ({ name, setState }) => {
  const response = await systemTypeService.getSystemTypesByName({ name: name });
  if (response.status === HTTP_STATUSES.OK) {
    const mappedList = response.data.map((b) => {
      return {
        ...b,
        value: b.systemTypeId,
        text: b.mainValue,
      };
    });
    setState(mappedList);
  }
};

export const useStacks = () => {
  const [stackSizes, setStackSizes] = useState([]);
  const [measurementTypesBack, setMeasurementTypesBack] = useState([]);
  const [measurementTypesSide, setMeasurementTypesSide] = useState([]);

  const handleGetStackSizes = async () => {
    await handleGetSystemTypesByName({
      name: "StackSize",
      setState: setStackSizes,
    });
  };

  const handleGetStackMeasurementTypes = async () => {
    await handleGetSystemTypesByName({
      name: "MeasureReferenceBack",
      setState: setMeasurementTypesBack,
    });
    await handleGetSystemTypesByName({
      name: "MeasureReferenceSide",
      setState: setMeasurementTypesSide,
    });
  };

  return {
    stackSizes,
    setStackSizes,
    measurementTypesBack,
    setMeasurementTypesBack,
    measurementTypesSide,
    setMeasurementTypesSide,
    handleGetStackSizes,
    handleGetStackMeasurementTypes,
  };
};
