import React, { useEffect, useState } from "react";
import { MiTab, MiPageHeader, MiLoader, MiIcon } from "@miview/components";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
  integrationService,
  accountServiceLegacy as accountService,
} from "@miview/api";
import {
  INTEGRATIONS,
  MAPPING_TYPES,
  INTEGRATION_NAMES,
} from "@miview/constants";
import { ConnectApi } from "./ConnectApi";
import { Schedules } from "./Schedules";
import { Divisions } from "./Divisions";
import { History } from "./History";
import { mdiArrowLeft } from "@mdi/js";
import { useRouter } from "@miview/hooks";
import { createFilter } from "@miview/utils";

const useStyles = makeStyles()(() => {
  return {
    button: {
      marginRight: 10,
      backgroundColor: "inherit",
      border: 0,
      cursor: "pointer",
    },
  };
});

const tabs = [
  {
    label: "Connect API",
    value: "connectapi",
  },
  {
    label: "Divisions",
    value: "divisions",
  },
  {
    label: "Schedules",
    value: "schedules",
  },
  {
    label: "History",
    value: "history",
  },
];

export const DRHorton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(tabs[0].value);
  const [servicesData, setServicesData] = useState([]);
  const [connectionData, setConnectionData] = useState({});
  const [divisionData, setDivisionData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const theme = useTheme();
  const {
    replace,
    goBack,
    query: { activeTab },
  } = useRouter();
  const { classes } = useStyles();
  const filterHelper = createFilter();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setCurrentTab(activeTab || tabs[0].value);
  }, [activeTab]);

  const handleTabChange = (value) => {
    replace({ pathname: `/settings/integrations/drhorton/${value}` });
    setCurrentTab(value);
  };

  const fetchServicesData = async () => {
    const services = await integrationService.integrations.getAllServices(
      INTEGRATION_NAMES.DrHorton
    );
    if (services.data) {
      setServicesData(services.data);
    }
  };

  const fetchConnectionTabData = async () => {
    const apiConnectionInfo = await integrationService.integrations.get(
      INTEGRATIONS.DRHORTON
    );
    if (apiConnectionInfo.data) {
      setConnectionData(apiConnectionInfo.data);
    }
  };

  const handleDeleteDivision = (id) => {
    setIsLoading(true);
    const divs = divisionData.filter((item) => item.id !== id);
    setDivisionData(divs);
    fetchDivisionTabData();
    setIsLoading(false);
  };

  const fetchDivisionTabData = async () => {
    const filters = filterHelper.equals({
      mappingTypeId: MAPPING_TYPES.DIVISIONS,
    });

    const response = await integrationService.integrations.getAllMappings({
      id: INTEGRATIONS.DRHORTON,
      params: { pageSize: 9999 },
      filters,
    });
    if (response.data) {
      setDivisionData(response.data);
    }
  };

  const fetchAccountsData = async () => {
    const response = await accountService.getAll({
      params: { pageSize: 9999 },
      sort: [{ field: "name", sort: "asc" }],
    });

    if (response.data) {
      const accountsArr = response.data.map((account) => {
        return { ...account, value: account.id, label: account.name };
      });
      setAccountData(accountsArr);
    }
  };

  const fetchHistoryTabData = async () => {
    const response = await integrationService.integrations.getAllRuns({
      name: INTEGRATIONS.DRHORTON,
      params: { pageSize: 100, orderBy: "startTime", descending: true },
    });
    if (response.status === 200) {
      setHistoryData(response.data);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const actions = [
        fetchConnectionTabData(),
        fetchServicesData(),
        fetchDivisionTabData(),
        fetchAccountsData(),
        fetchHistoryTabData(),
      ];
      await Promise.all(actions);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchDivisionData = () => {
    try {
      setIsLoading(true);
      fetchDivisionTabData();
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchServices = () => {
    try {
      setIsLoading(true);
      fetchServicesData();
      fetchHistoryTabData();
    } finally {
      setIsLoading(false);
    }
  };

  const renderTabContent = (value) => {
    switch (value) {
      case "connectapi": {
        return (
          <ConnectApi
            data={connectionData}
            fetchData={fetchConnectionTabData}
          />
        );
      }
      case "schedules": {
        return (
          <Schedules data={servicesData} fetchData={handleFetchServices} />
        );
      }
      case "divisions": {
        return (
          <Divisions
            data={divisionData}
            accounts={accountData}
            fetchData={handleFetchDivisionData}
            handleDeleteDivision={handleDeleteDivision}
          />
        );
      }
      case "history": {
        return <History data={historyData} fetchData={fetchHistoryTabData} />;
      }
      default: {
        return <div>Under construction</div>;
      }
    }
  };

  const handleBack = () => {
    goBack();
  };

  const renderBackArrow = () => {
    return (
      <button className={classes.button} onClick={handleBack}>
        <MiIcon
          path={mdiArrowLeft}
          size={1}
          color={theme.palette.primary.main}
        />
      </button>
    );
  };

  return (
    <>
      <MiPageHeader
        title="Integration Settings - D.R. Horton"
        color={theme.palette.primary.main}
        leftIcon={renderBackArrow()}
      />
      {isLoading && <MiLoader type="linear" />}
      <MiTab
        tabs={tabs}
        currenttab={currentTab}
        tabIndicatorColor={theme.palette.primary.main}
        onTabChange={handleTabChange}
        renderTabContent={renderTabContent}
      />
    </>
  );
};
