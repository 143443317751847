import React from "react";
import { miStyles, THEME } from "@miview/theme";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export const MiLink = (props) => {
  const { title, color, disabled, style, onClick, ...rest } = props;
  const classes = miStyles();

  let link;

  const handleClick = (e) => {
    if (disabled) {
      return;
    }
    onClick && onClick(e);
  };

  if (props.to) {
    link = (
      <Link
        {...rest}
        className={classes.miLink}
        style={{
          opacity: disabled ? 0.3 : 1,
          color: color || THEME.BLUE_PRIMARY,
          cursor: disabled ? "default" : "pointer",
          whiteSpace: "nowrap",
          ...styles.link,
          ...style,
        }}
        onClick={handleClick}
      >
        {title}
      </Link>
    );
  } else {
    link = (
      <div
        {...rest}
        className={classes.miLink}
        style={{
          opacity: props.disabled ? 0.3 : 1,
          color: color || THEME.BLUE_PRIMARY,
          cursor: props.disabled ? "default" : "pointer",
          whiteSpace: "nowrap",
          ...styles.link,
          ...style,
        }}
        onClick={handleClick}
      >
        {title}
      </div>
    );
  }

  return link;
};

const styles = {
  text: {
    textAlign: "center",
    flex: 0,
  },
  disabled: {
    opacity: 0.3,
  },
};

MiLink.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  to: PropTypes.string,
  onClick: PropTypes.func,
};
