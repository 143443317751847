import React, { useEffect, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import { TextField } from "@mui/material";
import { useDebounce } from "@miview/hooks";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: 16,
    display: "flex",
    alignItems: "center",
  },
  selectionContainer: {
    textTransform: "capitalize",
  },
  searchFieldContainer: {
    flex: 1,
    margin: "0 22px 14px",
  },
  searchField: {
    color: theme.palette.blue.main,
  },
  config: {
    margin: 14,
  },
}));

export const TableToolbar = ({ searchAction, renderSelector }) => {
  const { classes } = useStyles();
  const [searchInput, setSearchInput] = useState("");
  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(() => {
    searchAction(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div className={classes.root}>
      <div className={classes.selectionContainer}>{renderSelector()}</div>
      <div className={classes.searchFieldContainer}>
        <TextField
          id="table-search"
          label="Search"
          fullWidth
          className={classes.searchField}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      <div className={classes.config}></div>
    </div>
  );
};

TableToolbar.defaultProps = {
  searchAction: () => null,
  renderSelector: () => null,
};
