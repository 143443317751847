import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  ButtonGroup,
  Grid,
  Typography,
  useTheme,
  Popover,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  MiButton,
  MiDrawer,
  MiInputSelectBox,
  MiInputTextbox,
  MiStepperCustom,
  MiRichTextEditor,
  MiViewEditor,
  MiIconButton,
  MiIcon,
} from "@miview/components";
import {
  mdiChevronDown,
  mdiTrayArrowDown,
  mdiFileSearch,
  mdiChevronLeft,
} from "@mdi/js";
import { en } from "@miview/constants";
import { useEdit } from "@miview/hooks";
import { purchaseOrderService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

const useStyles = makeStyles()((theme, { props }) => ({
  dialogPaper: {
    width: "100%",
    maxWidth: "920px",
    background: "rgba(0, 0, 0, 0.2)",
    boxShadow: "none",
    borderRadius: "20px",
    alignSelf: "center",
  },
  root: {
    backdropFilter: "blur(10px)",
    background: "rgba(0, 0, 0, 0.1)",
    width: "100%",
  },
  customSpacing: {
    padding: 10,
    paddingTop: 20,
    paddingRight: 20,
  },

  editorHeader: {
    fontSize: theme.fontSize.large,
    color: theme.palette.primary.white,
    fontWeight: theme.fontWeight.medium,
    marginBottom: 30,
  },
  editorFooterStyle: {
    fontSize: theme.fontSize.mediun,
    color: theme.palette.light.blue,
    fontWeight: theme.fontWeight.medium,
  },
  prevContainer: {
    padding: 20,
    borderRadius: 20,
    background: theme.palette.dark.grey,
    minHeight: 600,
    marginBottom: 10,
  },
  greyContainer: {
    background: theme.palette.lightAccent.grey,
    padding: "20px 20px 70px",
  },
  whiteContainer: {
    padding: 16,
    paddingTop: 20,
    background: theme.palette.primary.white,
    borderRadius: "8px 8px 0px 0px",
  },
  titleBlue: {
    background: props.titleBackground || theme.palette.mediumLight.grey,
    borderRadius: "3px",
    border: props.titleBorder || "1px solid",
    borderColor: theme.palette.mediumDark.grey,
    color: props.titleColor || theme.palette.darkAccent.red,
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.medium,
    textAlign: "center",
    padding: 10,
    marginBottom: 25,
    display: "flex",
    justifyContent: "center",
  },
  imageCheck: {
    marginBottom: 10,
    "& img": {
      maxWidth: "116px",
      height: "auto",
      width: "auto",
    },
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.primary.blue}`,
    maxWidth: 120,
    marginLeft: 0,
    marginBottom: 20,
  },
  editorContent: {
    color: theme.palette.dark.bluegrey,
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.regular,
    lineHeight: 1.5,
    margin: "40px 0px",
  },
  editorContentRight: {
    color: theme.palette.dark.bluegrey,
    fontSize: theme.fontSize.large,
    fontWeight: theme.fontWeight.regular,
    lineHeight: 1.5,
  },

  editorFooter: {
    background: theme.palette.primary.bluegrey,
    padding: "20px 16px 15px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.white,
    fontWeight: theme.fontWeight.regular,
    borderTop: `3px solid ${theme.palette.primary.blue}`,
    borderRadius: "0px 0px 8px 8px",
    marginBottom: 5,
    fontSize: theme.fontSize.medium,
    "& img": {
      marginLeft: 20,
    },
  },

  editorFooterContent: {
    color: theme.palette.light.blue,
    fontSize: theme.fontSize.medium,
  },

  copyRightStyle: {
    textAlign: "center",
    color: theme.palette.secondary.grey,
    fontSize: theme.fontSize.xsmall,
    fontWeight: theme.fontWeight.regular,
    fontStyle: "italic",
    paddingTop: "20px",
  },

  darkInput: {
    marginBottom: 20,
    marginTop: 30,
    "& > div": {
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%",
    },

    "& > div > label": {
      position: "relative",
      left: "0",
      top: "0",
      marginBottom: 5,
      color: theme.palette.primary.white,
      fontSize: theme.fontSize.medium,
    },
    "& fieldset": {
      border: "none",
    },

    "& label + div": {
      background: theme.palette.darkAccent.grey,
      color: theme.palette.primary.white,
      borderRadius: "4px",
    },

    "& label + div > div": {
      color: theme.palette.primary.white,
      fontSize: theme.fontSize.medium,
    },
  },

  darkSelectBox: {
    marginBottom: 20,
    "& > div > div > label": {
      position: "relative",
      left: "0",
      top: "0",
      marginBottom: 5,
      color: theme.palette.primary.white,
      fontSize: theme.fontSize.medium,
    },

    "& > div > div": {
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%",
    },

    "& > div > div > label + div": {
      background: theme.palette.darkAccent.grey,
      color: theme.palette.primary.white,
    },

    "& > div > div > label + div svg": {
      color: theme.palette.primary.white,
    },

    "& fieldset": {
      border: "none",
    },
  },

  popover: {
    margin: 20,
    paddingRight: 20,
    cursor: "pointer",
    color: theme.palette.secondary.bluegrey,
    fontWeight: theme.fontWeight.regular,
  },
  popOverDownload: {
    paddingRight: 35,
    borderRadius: 5,
    cursor: "pointer",
    color: theme.palette.secondary.bluegrey,
    fontWeight: theme.fontWeight.regular,
  },
  buttonStyle: {
    background: theme.palette.primary.teal,
    color: theme.palette.primary.white,
    borderRadius: "30px",
    height: 24,
    minWidth: "fit-content",
    fontSize: 10,
    justifyContent: "space-around",
    marginLeft: -10,
    "&:hover": {
      backgroundColor: theme.palette.primary.teal,
    },
  },
  headerGrid: {
    position: "sticky",
    zIndex: theme.zIndex.drawer,
    top: -20,
  },
  iconStyle: {
    paddingRight: 15,
  },
  menu: {
    marginTop: 50,
  },
  iconAlign: {
    padding: 0,
    backgroundColor: theme.palette.darkAccent.teal,
    left: "12px",
    "&:hover": {
      backgroundColor: theme.palette.darkAccent.teal,
    },
  },
  link: {
    fontSize: theme.fontSize.xsmall,
    fontWeight: theme.fontWeight.regular,
    margin: 20,
  },
}));

const SendEmail = ({
  title,
  handleOnSave,
  handleOnSaveManually,
  handleOnBack,
  titleBackground,
  titleColor,
  titleBorder,
  stepLabelData = [],
  isEmailMessage = true,
  isBackButtonText = false,
  isEmailSubject,
}) => {
  const { classes } = useStyles({
    props: { titleBackground, titleColor, titleBorder },
  });
  const theme = useTheme();

  const quillValue = ` 
  <img src="https://mivprodblob.blob.core.windows.net/images/cathedral-logo.png" alt="title" width="140" height="32"/>
  <br /> <br /><hr />
  To Whom It May Concern, <br />  <br />
  Please review the linked Purchase Order.
  Feel free to contact us if you have any questions. <br /> <br /> <br />
  <a href="#">Purchase-Order </a><br />  <br />  <br /> 
  Thank you for your service. <br/> <br/> <br /> 
  Sincerely,
  Johnny Walker <br /> 
  Purchasing Manager <br /> 
  Cathedral Plumbing of Texas, LLC, <br />
  cathedralplumbingtx.com <br /> 
  972 446 2500 <br/> <br /> <br /> <br /> <br />`;

  const footer = `<p>Powered By MiViews</p>
  <br/>
  <p> Visit MiViewIS.com to learn more.</p><br/><br/>`;
  const link = `<p>© Copyright MiView IS 2021  |  This message is not an advertisement</p>`;

  const [quillEditor, setQuillEditor] = useState(quillValue);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const defaultValues = {
    Name: "",
    To: "",
    Cc: "",
    Bcc: "",
    Subject: isEmailSubject
      ? `Purchase Order from Cathedral ${new Date().toLocaleDateString()}`
      : `Purchase Order Cancellation from Cathedral ${new Date().toLocaleDateString()}`,
  };
  const edit = useEdit(defaultValues);

  const stepLabelChange = (currentIndex) => {
    if (currentIndex === activeIndex) {
      return "Active";
    }
    if (currentIndex > activeIndex) {
      return "Review";
    }
    if (currentIndex < activeIndex) {
      return "Sent";
    }
  };

  const header = stepLabelData[activeIndex]?.PreviewTitle ?? `${title}`;

  const stepData = React.useMemo(
    () =>
      stepLabelData.length &&
      stepLabelData?.map((item, index) => ({
        label: item.acctitle || item.accountName,
        subLabel: stepLabelChange(index),
        id: item.accid || item.id,
      })),
    [stepLabelData, activeIndex]
  );

  const handleStepChange = (newIndex) => {
    setActiveIndex(newIndex);
  };

  const handleOnSendEmail = async () => {
    if (edit.allFilled("To")) {
      const emailData = {
        ...defaultValues,
        ...edit.edits,
        message: quillValue,
      };
      const response = await purchaseOrderService.purchaseOrder.sendEmail({
        data: emailData,
        successMessage:
          isEmailMessage &&
          activeIndex === stepData.length - 1 &&
          (stepData.length > 1
            ? en.purchaseOrdersWereSentSuccessfully
            : en.purchaseOrderWasSentSuccessfully),
      });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (activeIndex === stepData.length - 1) {
          handleOnSave();
        }
        if (activeIndex !== stepData.length - 1) {
          handleStepChange(activeIndex + 1);
        }
        edit.reset();
        setQuillEditor(quillValue);
      }
    }
  };

  const handleOnSendEmailManually = () => {
    if (activeIndex === 0 || activeIndex === stepData.length - 1) {
      handleOnSaveManually();
    } else {
      handleOnSendEmail();
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickAttachment = (e) => {
    setAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const renderSendButton = () => {
    return (
      <ButtonGroup variant="contained">
        <MiButton
          title={en.sendEmail}
          onClick={handleOnSendEmail}
          backgroundColor={theme.palette.secondary.green}
          color={theme.palette.primary.white}
          style={{
            fontWeight: theme.fontWeight.medium,
            width: 100,
            height: 45,
            margin: 0,
            borderRadius: "3px 0px 0px 3px ",
          }}
        />
        <MiButton
          onClick={handlePopoverOpen}
          backgroundColor={theme.palette.primary.green}
          color={theme.palette.primary.white}
          icon={mdiChevronDown}
          variant="contained"
          style={{
            fontWeight: theme.fontWeight.medium,
            width: 30,
            height: 45,
            margin: 0,
            borderRadius: "0px 3px 3px 0px",
          }}
        />
        {
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handlePopoverClose}
          >
            <Typography
              variant="subtitle1"
              onClick={handleOnSendEmailManually}
              className={classes.popover}
            >
              {en.sendManually}
            </Typography>
          </Popover>
        }
      </ButtonGroup>
    );
  };

  const renderButton = () => {
    return (
      <MiButton
        title={isBackButtonText ? en.backToReceiving : en.backToPricing}
        onClick={handleOnBack}
        backgroundColor={theme.palette.primary.white}
        color={theme.palette.dark.bluegrey}
        style={{ fontWeight: theme.fontWeight.medium, width: 153, height: 45 }}
        icon={mdiChevronLeft}
      />
    );
  };

  return (
    <MiDrawer
      open={true}
      anchor="right"
      classes={{ paper: classes.root }}
      width="100%"
    >
      <Grid container spacing={10} alignItems="center" justifyContent="center">
        <Grid item xs={12} className={classes.headerGrid}>
          <Grid>
            <MiStepperCustom
              items={stepData}
              activeIndex={activeIndex}
              renderPrevButton={renderButton}
              renderNextButton={renderSendButton}
              activeColor={theme.palette.primary.teal}
              stepperWidth={"fit-content"}
              boxHeight={56}
              containerHeight={70}
              indexCount={false}
              handleChange={(newIndex) => {
                if (newIndex < activeIndex) {
                  handleStepChange(newIndex);
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.dialogPaper}>
          <Grid container>
            <Grid item md={6} className={classes.customSpacing}>
              <Typography className={classes.editorHeader}>
                {en.editor}
              </Typography>
              <Grid className={classes.darkSelectBox}>
                <MiInputSelectBox
                  labelText={en.to}
                  menuItems={[
                    {
                      label: "chris.cornell@ferguson.com",
                      value: 0,
                    },
                    {
                      label: "cornell@ferguson.com",
                      value: 1,
                    },
                    {
                      label: "rajeshkumar.c@technoduce.com",
                      value: "rajeshkumar.c@technoduce.com",
                    },
                    {
                      label: "sankeetha.k@technoduce.com",
                      value: "sankeetha.k@technoduce.com",
                    },
                  ]}
                  required
                  requiredColor={theme.palette.medium.yellow}
                  value={edit.getValue("To") || ""}
                  handleChange={(e) => edit.update({ To: e.target.value })}
                />
              </Grid>
              <Grid className={classes.darkInput}>
                <MiInputTextbox
                  labelText={en.cc}
                  required
                  requiredColor={theme.palette.medium.yellow}
                  value={edit.getValue("Cc") || ""}
                  handleChange={(e) => edit.update({ Cc: e.target.value })}
                />
              </Grid>
              <Grid className={classes.darkInput}>
                <MiInputTextbox
                  labelText={en.bcc}
                  required
                  value={edit.getValue("Bcc") || ""}
                  handleChange={(e) => edit.update({ Bcc: e.target.value })}
                  requiredColor={theme.palette.medium.yellow}
                />
              </Grid>
              <Grid className={classes.darkInput}>
                <MiInputTextbox
                  labelText={en.subject}
                  required
                  value={edit.getValue("Subject") || ""}
                  handleChange={(e) => edit.update({ Subject: e.target.value })}
                  requiredColor={theme.palette.medium.yellow}
                />
              </Grid>

              <MiRichTextEditor
                title={en.message}
                value={quillEditor}
                onChange={(value) => setQuillEditor(value)}
              />
              <Grid className={classes.darkInput}>
                <MiInputTextbox
                  labelText={en.attachments}
                  labelTextColor={theme.palette.primary.white}
                  InputProps={{
                    startAdornment: (
                      <>
                        <Button
                          className={classes.buttonStyle}
                          onClick={handleOnClickAttachment}
                          disableRipple
                          disableElevation
                          disableFocusRipple
                          variant="contained"
                        >
                          {isEmailSubject
                            ? en.purchaseOrderPdf
                            : en.cancelPOPdf}

                          <MiIconButton
                            size="small"
                            classes={{ root: classes.iconAlign }}
                          >
                            <MiIcon
                              path={mdiChevronDown}
                              size={0.8}
                              color={theme.palette.primary.white}
                            />
                          </MiIconButton>
                        </Button>
                        <Menu
                          open={Boolean(anchor)}
                          anchorEl={anchor}
                          onClose={handleClose}
                          PopoverClasses={{
                            root: classes.menu,
                          }}
                        >
                          <MenuItem>
                            <Typography
                              variant="subtitle1"
                              className={classes.popOverDownload}
                            >
                              <MiIcon
                                path={mdiFileSearch}
                                size={1.5}
                                color={theme.palette.primary.grey}
                                className={classes.iconStyle}
                              />
                              {en.printPreview}
                            </Typography>
                          </MenuItem>
                          <MenuItem>
                            <Typography
                              variant="subtitle1"
                              className={classes.popOverDownload}
                            >
                              <MiIcon
                                path={mdiTrayArrowDown}
                                size={1.5}
                                color={theme.palette.primary.grey}
                                className={classes.iconStyle}
                              />
                              {en.download}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Grid className={classes.prevContainer}>
                <Typography
                  className={classes.editorHeader}
                  style={{ fontWeight: theme.fontWeight.regular }}
                >
                  {en.preview}
                </Typography>
                <Grid className={classes.greyContainer}>
                  <Grid className={classes.whiteContainer}>
                    <Typography className={classes.titleBlue}>
                      <MiViewEditor value={header} />
                    </Typography>
                    <Grid className={classes.editorContentRight}>
                      <MiViewEditor value={quillEditor} />
                    </Grid>
                  </Grid>
                  <Grid className={classes.editorFooter}>
                    <MiViewEditor value={footer} />
                  </Grid>
                  <Grid className={classes.link}>
                    <MiViewEditor value={link} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MiDrawer>
  );
};

SendEmail.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleOnBack: PropTypes.func.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  handleOnSaveManually: PropTypes.func.isRequired,
  stepLabelData: PropTypes.array.isRequired,
  isEmailMessage: PropTypes.bool.isRequired,
};

export default SendEmail;
