import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const communityService = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/communities`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  getCommunity: async ({ communityId, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/communities/${communityId}`,
      method: "get",
      signal,
    });

    return apiRequest(options);
  },

  getRegions: async ({ signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/communities/regions`,
      method: "get",
      signal,
    });

    return apiRequest(options);
  },

  createCommunity: async ({ request, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/communities`,
      method: "post",
      data: request,
      signal,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving community",
      },
      success: {
        message: "Community saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  updateCommunity: async ({ id, request, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/communities/${id}`,
      method: "patch",
      data: request,
      signal,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving community",
      },
      success: {
        message: "Community saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  updateCommunityContact: async ({ communityId, personId, fields, signal }) => {
    const options = await apiOptions({
      method: "patch",
      url: `${API_V3}/communities/${communityId}/persons/${personId}`,
      data: fields,
      signal,
    });
    return apiRequest(options);
  },

  deleteCommunityContact: async ({ communityId, personId, signal }) => {
    const options = await apiOptions({
      method: "delete",
      url: `${API_V3}/communities/${communityId}/persons/${personId}`,
      signal,
    });
    return apiRequest(options);
  },

  getContacts: async ({ id, signal, params }) => {
    const options = await apiOptions({
      url: `${API_V3}/communities/${id}/contacts`,
      method: "get",
      params,
      signal,
    });

    return apiRequest(options);
  },

  getBuilders: async ({ id, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/communities/${id}/builders`,
      method: "get",
      signal,
    });

    return apiRequest(options);
  },

  updateAssignees: async ({ id, request, params, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/communities/${id}/persons`,
      method: "patch",
      data: request,
      params,
      signal,
    });
    const toastMessageConfig = {
      success: {
        message: "Contacts updated successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  addPlan: async ({ id, planId, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/communities/${id}/plans`,
      method: "post",
      data: { planId },
      signal,
    });
    const toastMessageConfig = {
      success: {
        message: "Plan added Successfully",
      },
      failure: {
        message: "Failure saving Plan",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  removePlan: async ({ id, planId, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/communities/${id}/plans/${planId}`,
      method: "delete",
      signal,
    });
    const toastMessageConfig = {
      success: {
        message: "Plan removed Successfully",
      },
      failure: {
        message: "Failure removing Plan",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
};
