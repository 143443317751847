import React, { useState, useEffect } from "react";
import {
  accountServiceLegacy as accountService,
  walkTypeService,
  paySchedulesService,
} from "@miview/api";
import { useEdit } from "@miview/hooks";
import {
  MiButton,
  MiPageHeader,
  MiDetailFields,
  MiModal,
  MiList,
  MiIcon,
  MiModalConfirm,
} from "@miview/components";
import { useTheme } from "@mui/material/styles";
import { HTTP_STATUSES, NOTIFICATION_SUBSCRIPTIONS } from "@miview/constants";
import {
  mdiBriefcaseAccountOutline,
  mdiDelete,
  mdiPencil,
  mdiPlus,
} from "@mdi/js";

const Titles = () => {
  const [isTitleEditModalOpen, setIsTitleEditModalOpen] = useState(false);
  const [isTitleDeleteModalOpen, setIsTitleDeleteModalOpen] = useState(false);
  const [title, setTitle] = useState({});
  const [titles, setTitles] = useState([]);
  const [walkTypes, setWalkTypes] = useState([]);
  const [isNewItem, setIsNewItem] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const accountId = localStorage.getItem("accountId");
  const theme = useTheme();
  const edit = useEdit(title);

  useEffect(() => {
    getWalkTypes();
    getSchedules();
  }, []);

  const getWalkTypes = async () => {
    const response = await walkTypeService.getWalkTypes({ pageSize: 9999 });
    if (response.status === HTTP_STATUSES.OK) {
      const mapped = response.data.map(({ walkTypeId, walkTypeName }) => {
        return {
          value: walkTypeId,
          label: walkTypeName,
        };
      });
      setWalkTypes(mapped);
    }
  };

  const getSchedules = async () => {
    const response = await paySchedulesService.getAll({});
    if (response.status === HTTP_STATUSES.OK) {
      const mapped = response.data.map((item) => {
        return {
          value: item["id"],
          label: item["name"],
        };
      });
      setSchedules(mapped);
    }
  };
  const closeEditModal = () => {
    edit.reset();
    setIsTitleEditModalOpen(false);
    setRefresh(!refresh);
  };

  const update = async () => {
    const responseWalkTypes = edit.getValue("viewWalkTypeIds");
    const responseWalkTypesObj = responseWalkTypes.map((editedWalkType) => {
      const originalObj = title.viewWalkTypes.find(
        (val) => val.walkTypeId === editedWalkType
      );
      return {
        walkTypeId: editedWalkType,
        isAssignable: originalObj?.isAssignable || false,
      };
    });
    const response = await accountService.updateTitle(
      accountId,
      edit.getValue("id"),
      {
        ...edit.edits,
        viewWalkTypes: responseWalkTypesObj,
      }
    );
    if (response.status === HTTP_STATUSES.OK) {
      closeEditModal();
    }
  };

  const createTitle = async () => {
    const responseWalkTypes = edit.getValue("viewWalkTypeIds");
    const responseWalkTypesObj = responseWalkTypes.map((editedWalkType) => {
      return {
        walkTypeId: editedWalkType,
        isAssignable: false,
      };
    });
    const response = await accountService.createTitle(accountId, {
      ...edit.edits,
      viewWalkTypes: responseWalkTypesObj,
    });
    if (response.status === HTTP_STATUSES.OK) {
      closeEditModal();
    }
  };

  const deleteTitleObj = async () => {
    const response = await accountService.deleteTitle(
      accountId,
      edit.getValue("id")
    );
    if (response.status === HTTP_STATUSES.OK) {
      setIsTitleDeleteModalOpen(false);
      setRefresh(!refresh);
    }
  };

  const openEdit = (row) => {
    setTitle(row);
    setIsNewItem(false);
    setIsTitleEditModalOpen(true);
  };

  const openCreate = () => {
    setTitle({});
    setIsNewItem(true);
    setIsTitleEditModalOpen(true);
  };

  const closeCreateEdit = () => {
    edit.reset();
    setIsTitleEditModalOpen(false);
  };

  const openDelete = (row) => {
    setTitle(row);
    setIsTitleDeleteModalOpen(true);
  };

  const closeDelete = () => {
    edit.reset();
    setTitle({});
    setIsTitleDeleteModalOpen(false);
  };

  const getScheduleName = (id) => {
    const result = schedules.filter((obj) => obj.value === id);
    return result[0]?.label;
  };

  const columns = [
    {
      field: "name",
      headerName: "Job Title",
      flex: 1,
    },
    {
      field: "payScheduleId",
      headerName: "Pay Schedule",
      flex: 1,
      renderCell: (p) => <div>{getScheduleName(p.value)}</div>,
    },
  ];

  const getTitles = async (params) => {
    return await accountService.getTitles({ id: accountId, ...params });
  };

  const setTitlesData = (response) => {
    const modifiedData = response.map((data) => {
      return {
        ...data,
        viewWalkTypeIds: data.viewWalkTypes.map(
          (walkType) => walkType.walkTypeId
        ),
      };
    });

    setTitles(modifiedData);
  };

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => openDelete(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
      {
        text: "EDIT",
        onClick: () => openEdit(row),
        renderIcon: () => <MiIcon path={mdiPencil} size={1} />,
      },
    ];
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiBriefcaseAccountOutline}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Title"
        icon={mdiPlus}
        inverse={true}
        onClick={openCreate}
        color={theme.palette.primary.main}
      />
    );
  };

  const createMaterialInput = [
    {
      label: "Title Name",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "100%",
    },
    {
      label: "Viewable Walk Types",
      fieldType: "multiSelect",
      options: walkTypes,
      value: edit.getValue("viewWalkTypeIds"),
      setValue: (e) => edit.update({ viewWalkTypeIds: e.target.value }),
      width: "100%",
    },
    {
      label: "Pay Schedules",
      value: edit.getValue("payScheduleId"),
      options: schedules,
      fieldType: "select",
      setValue: (e) => edit.update({ payScheduleId: e }),
      width: "100%",
      selectNone: true,
    },
    {
      label: "Default Notifications",
      value: edit.getValue("enabledEmails"),
      fieldType: "multiSelect",
      options: NOTIFICATION_SUBSCRIPTIONS,
      setValue: (e) => edit.update({ enabledEmails: e.target.value }),
      width: "100%",
    },
  ];

  return (
    <div className="animated fadeIn">
      <MiModal
        title={isNewItem ? "Create Title" : "Edit Title"}
        open={isTitleEditModalOpen}
        onClose={closeCreateEdit}
        actions={[
          {
            name: "Cancel",
            onClick: closeCreateEdit,
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: isNewItem ? createTitle : update,
            color: theme.palette.primary.green,
            inverse: true,
            disabled: !edit.allFilled("name"),
          },
        ]}
      >
        <MiDetailFields detailFields={createMaterialInput} />
      </MiModal>
      <MiModalConfirm
        title={"Confirm Delete"}
        description={`Delete Title: ${title.name}?`}
        open={isTitleDeleteModalOpen}
        handlePositive={deleteTitleObj}
        handleNegative={closeDelete}
      />
      <div>
        <MiPageHeader
          title="Titles"
          leftIcon={renderHeaderIcon()}
          color={theme.palette.primary.main}
          action={actionButton()}
        />
        <MiList
          data={titles}
          setData={setTitlesData}
          dataProvider={getTitles}
          defaultPageSize={20}
          columns={columns}
          disableColumnMenu
          actionIconStyle={{ color: theme.palette.secondary.grey }}
          getRowActions={getRowActions}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default Titles;
