import React, { useState } from "react";
import { accountService } from "@miview/api";
import { en, HTTP_STATUSES } from "@miview/constants";
import { formatDate } from "../../../../helpers/dateTools";
import {
  MiPageHeader,
  MiModal,
  MiInputTextbox,
  MiIcon,
  MiButton,
  MiModalConfirm,
  MiList,
} from "@miview/components";
import { useTheme } from "@mui/material/styles";
import { useEdit, useComponentState } from "@miview/hooks";
import { mdiHomeCity, mdiPencil, mdiDelete, mdiPlus } from "@mdi/js";

export const ContactManagement = () => {
  const [rows, setRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const theme = useTheme();
  const edit = useEdit(selectedRow);
  const stateManager = useComponentState();

  const columns = [
    {
      field: "name",
      headerName: en.name,
      flex: 1,
    },
    {
      field: "description",
      headerName: en.description,
      flex: 1,
    },
    {
      field: "idPattern",
      headerName: en.idPattern,
      flex: 1,
    },
    {
      field: "lastModified",
      headerName: en.lastModified,
      flex: 1,
      valueGetter: ({ value }) => formatDate(value) || "--",
    },
  ];

  const fetchData = (params) => {
    return stateManager.run(async () => {
      return accountService.contactTypes.getAll(params);
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    edit.reset();
  };

  const handleContactType = () => {
    const payload = { ...edit.edits };
    selectedRow.id ? handleUpdate(payload) : handleCreate(payload);
    setShowModal(false);
  };

  const handleUpdate = async (value) => {
    const response = await accountService.contactTypes.update({
      id: selectedRow.id,
      data: value,
      successMessage: en.contactTypeAddedCreatedSuccessMessage,
      failureMessage: en.theActionWasNotSuccessful,
    });
    if (response.status === HTTP_STATUSES.OK) {
      edit.reset();
      setRefresh(!refresh);
    }
  };

  const handleCreate = async (value) => {
    const response = await accountService.contactTypes.create({
      data: value,
      successMessage: en.contactTypeAddedCreatedSuccessMessage,
      failureMessage: en.theActionWasNotSuccessful,
    });
    if (response.status === HTTP_STATUSES.CREATED) {
      edit.reset();
      setRefresh(!refresh);
    }
  };

  const handleDelete = async () => {
    const response = await accountService.contactTypes.delete({
      id: selectedRow.id,
      successMessage: en.contactTypeDeleted,
      failureMessage: en.theActionWasNotSuccessful,
    });
    if (response.status === HTTP_STATUSES.NO_CONTENT) {
      edit.reset();
      setRefresh(!refresh);
    }
    closeDeleteModal();
  };

  const requiredFields = ["name", "description", "idPattern"];

  const canSubmit = () => {
    return edit.allFilled(...requiredFields);
  };

  const actions = [
    {
      onClick: handleCloseModal,
      color: theme.palette.primary.grey,
      name: "Close",
      inverse: false,
    },
    {
      onClick: handleContactType,
      color: theme.palette.primary.main,
      name: "Submit",
      inverse: true,
      disabled: !canSubmit(),
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon path={mdiHomeCity} size={1} color={theme.palette.primary.main} />
    );
  };

  const openEditModal = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const getRowActions = (row) => {
    return [
      {
        text: "Delete",
        onClick: () => openDeleteModal(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={"20px"} />,
      },
      {
        text: "EDIT",
        onClick: () => openEditModal(row),
        renderIcon: () => <MiIcon path={mdiPencil} size={"20px"} />,
      },
    ];
  };

  const handleOpenModal = (row = {}) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const openDeleteModal = (row) => {
    setIsDeleteModalOpen(true);
    setSelectedRow(row);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedRow(null);
  };

  const pageHeaderActionButton = () => {
    return (
      <MiButton
        title="Add Contact Types"
        icon={mdiPlus}
        inverse
        onClick={handleOpenModal}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <>
      <MiPageHeader
        title="Contact Types"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        loading={stateManager.isBusy()}
        action={pageHeaderActionButton()}
      />
      <MiList
        data={rows}
        dataProvider={fetchData}
        setData={setRows}
        columns={columns}
        hideFilters
        disableColumnMenu
        refresh={refresh}
        getRowActions={getRowActions}
      />
      <MiModal
        title={"Edit Contact Type"}
        onClose={handleCloseModal}
        open={showModal}
        actions={actions}
      >
        <MiInputTextbox
          labelText="Contact Type"
          value={edit.getValue("name")}
          handleChange={(e) => edit.update({ name: e.target.value })}
          required
        />
        <MiInputTextbox
          labelText="Description"
          value={edit.getValue("description")}
          handleChange={(e) => edit.update({ description: e.target.value })}
          required
        />
        <MiInputTextbox
          labelText="Contact Type Id Pattern"
          value={edit.getValue("idPattern")}
          handleChange={(e) => edit.update({ idPattern: e.target.value })}
          required
        />
      </MiModal>
      <MiModalConfirm
        open={isDeleteModalOpen}
        title={en.deleteContactType}
        description={en.deleteContactType}
        handlePositive={handleDelete}
        handleNegative={closeDeleteModal}
      />
    </>
  );
};
