import React, { memo, Fragment } from "react";
import PropTypes from "prop-types";
import UndoIcon from "@mui/icons-material/Undo";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { THEME, MiMenuSystem } from "./MiView";
import { useAreaItemStyles } from "./MiAreaItemStyles";
import { PaddedDiv } from "./StyledComponents/PaddedDiv";
import { useCdn } from "@miview/hooks";

const boxColor = (itemDetails) => {
  if (itemDetails?.isDeleted) {
    return THEME.RED_PRIMARY;
  }

  if (itemDetails?.isTemplateItem) {
    return THEME.GREY_LIGHT;
  }

  if (itemDetails?.isNew) {
    return THEME.ORANGE_SECONDARY;
  }

  if (itemDetails?.isChanged) {
    return THEME.PURPLE_LIGHT;
  }

  return "gray";
};

const MiAreaItem = ({
  itemDetails,
  areaId,
  walkTypes,
  deleteOrRestoreItem,
  setImagePreviewed,
  canEdit,
  canDelete,
  index,
  canReorder,
  setItemDragging,
  isMasterSpecs,
  setEditingItem,
  editing,
}) => {
  const { classes } = useAreaItemStyles();
  const cdn = useCdn();

  const handleClickEdit = () => {
    setEditingItem(itemDetails);
  };

  const handleDeleteItem = () => {
    deleteOrRestoreItem({ itemDetails, isDeleted: true });
  };

  const handleRevertItem = () => {
    deleteOrRestoreItem({ itemDetails, isReverted: true });
  };

  const handleRestoreItem = () => {
    deleteOrRestoreItem({ itemDetails, isRestored: true });
  };

  const onDragStart = () => {
    setItemDragging({ ...itemDetails, areaId, index });
  };

  const renderImage = (position) => {
    let imageSrc = itemDetails[`${position}ImageName`];

    const handleSetImagePreviewed = () => {
      setImagePreviewed(imageSrc);
    };

    if (itemDetails.materialId) {
      return <img src={`${cdn}${itemDetails.materialImageName}`} />;
    }

    if (imageSrc) {
      return (
        <img
          data-testid={`${position}-image`}
          src={`${cdn}${imageSrc}`}
          onClick={handleSetImagePreviewed}
        />
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 18,
          }}
        >
          <CameraAltIcon className={classes.areaImageIcon} />
        </div>
      );
    }
  };

  const generateMenuOptions = () => {
    let options = [];

    if (canEdit) {
      options.push({
        name: "Edit",
        action: handleClickEdit,
      });
    }

    if (canDelete) {
      options.push({
        name: "Delete",
        action: handleDeleteItem,
      });
    }

    if (
      !isMasterSpecs &&
      canDelete &&
      itemDetails.isChanged &&
      !itemDetails.isNew
    ) {
      options.push({
        name: "Revert to Original",
        action: handleRevertItem,
      });
    }

    return options;
  };

  const renderJob = () => {
    if (walkTypes) {
      return (
        <p>
          {
            walkTypes.find((t) => t.walkTypeId === itemDetails.walkTypeId)
              ?.walkTypeName
          }
        </p>
      );
    }

    return null;
  };

  const renderIcons = () => {
    if (canEdit) {
      if (itemDetails.isDeleted) {
        return (
          <UndoIcon
            className={classes.areaItemRestoreIcon}
            onClick={handleRestoreItem}
          />
        );
      } else {
        return (
          <Fragment>
            <MiMenuSystem options={generateMenuOptions()} />
          </Fragment>
        );
      }
    }

    return null;
  };

  const draggable = canReorder && !editing;

  return (
    <PaddedDiv>
      <div
        id={`DraggablePlanItem-${itemDetails?.itemId}`}
        className={classes.areaItemBox}
        style={{
          borderLeft: `12px solid ${boxColor(itemDetails)}`,
          minHeight: 100,
          cursor: canReorder ? "grab" : "auto",
        }}
        onDragStart={onDragStart}
        draggable={draggable}
        onDoubleClick={handleClickEdit}
      >
        <div className={classes.areaItemDetail}>
          <div className={classes.areaItemLabel}>
            <p
              style={
                itemDetails.isDeleted ? { textDecoration: "line-through" } : {}
              }
            >
              {itemDetails.itemName}
            </p>
          </div>
          <div className={classes.areaItemDescription}>
            <p>{itemDetails.description}</p>
          </div>
          <div className={classes.areaItemDescription}>{renderJob()}</div>
          {itemDetails.materialName ? (
            <div className={classes.areaItemDescription}>
              <p>
                Material:{" "}
                {`${itemDetails.materialName} - ${
                  itemDetails.materialItemNumber || ""
                }`}
              </p>
            </div>
          ) : null}
          {itemDetails.quantity && !itemDetails.isTemplateItem ? (
            <div className={classes.areaItemDescription}>
              <p>Quantity: {itemDetails.quantity}</p>
            </div>
          ) : null}
          {itemDetails.canBeNotApplicable ? (
            <div className={classes.areaItemDescription}>
              <p> Can Be Not Applicable</p>
            </div>
          ) : null}
        </div>
        <label
          htmlFor={`drawing-${itemDetails?.itemId || 0}`}
          className={classes.createDrawingLabel}
        >
          {!itemDetails.isTemplateItem && (
            <div className={classes.areaItemImagesContainer}>
              {itemDetails.materialId ? (
                <div className={classes.areaItemImage}>
                  {renderImage("top")}
                </div>
              ) : (
                <Fragment>
                  <div>
                    <span className={classes.areaItemImageLabel}>Top</span>
                    <div className={classes.areaItemImage}>
                      {renderImage("top")}
                    </div>
                  </div>
                  <div>
                    <span className={classes.areaItemImageLabel}>Front</span>
                    <div className={classes.areaItemImage}>
                      {renderImage("front")}
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          )}
        </label>
        <div id="areaItemMenu" className={classes.areaItemEditIcon}>
          {!itemDetails.isTemplateItem && renderIcons()}
        </div>
      </div>
    </PaddedDiv>
  );
};

MiAreaItem.propTypes = {
  areaId: PropTypes.number,
  itemDetails: PropTypes.object,
  walkTypes: PropTypes.array,
  deleteOrRestoreItem: PropTypes.func,
  setImagePreviewed: PropTypes.func,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
};

MiAreaItem.defaultProps = {
  canDelete: true,
};

export default memo(MiAreaItem);
