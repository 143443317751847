import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { styled } from "@mui/system";

const StyledIconButton = styled(IconButton)(() => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&:focus": {
    outline: 0,
  },
}));

export const MiIconButton = React.memo(({ children, ...rest }) => (
  <StyledIconButton disableFocusRipple disableRipple {...rest}>
    {children}
  </StyledIconButton>
));

MiIconButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};
