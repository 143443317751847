import React from "react";
import {
  Timeline as MaterialTimeline,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Card, Typography } from "@mui/material";
import { THEME } from "@miview/theme";
import moment from "moment";

const sortData = (data, order, field) => {
  const _data = [...data];
  if (order === "asc") {
    _data.sort((a, b) => {
      return moment(b[field]).isBefore(a[field]) ? 1 : -1;
    });
  } else if (order === "desc") {
    _data.sort((a, b) => {
      return moment(b[field]).isAfter(a[field]) ? 1 : -1;
    });
  }
  return _data;
};

const renderDate = (data, i, dateField) => {
  if (
    i === 0 ||
    !moment(data[i][dateField]).isSame(data[i - 1][dateField], "day")
  ) {
    return moment(data[i][dateField]).format("MMM DD");
  }
  return null;
};

export const Timeline = (props) => {
  const { renderCard, data, dateField, sortOrder } = props;
  const sortedData = sortData(data, sortOrder, dateField);
  return (
    <div style={{ ...styles.container, ...styles.borderBottom }}>
      <MaterialTimeline align="left">
        {sortedData.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent style={{ minWidth: 100, flex: 0 }}>
              <Typography variant="body2" color="textSecondary">
                {renderDate(sortedData, index, dateField)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Card variant="outlined" style={{ padding: 8 }}>
                {renderCard(item)}
              </Card>
            </TimelineContent>
          </TimelineItem>
        ))}
      </MaterialTimeline>
    </div>
  );
};

const styles = {
  container: {
    color: "white",
    flexDirection: "column",
    position: "relative",
    backgroundColor: "#FFF",
    margin: 10,
    padding: 10,
    width: "100%",

    marginHorizontal: 10,
    paddingBottom: 5,
  },
  borderBottom: {
    borderBottomColor: THEME.GREY_LIGHT_BACKGROUND,
    borderBottomWidth: 1,
  },
  frontStyle: {
    display: "flex",
    flex: 2,
    flexDirection: "row",
    alignItems: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
};
