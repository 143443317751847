import React from "react";
import { MiChip } from "@miview/components";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiTHEME } from "@miview/theme";
import { QUOTE_STATUS } from "@miview/constants";
import en from "../locale/en.json";

const styles = {
  chip: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 14px",
    position: "static",
    minWidth: "63px",
    height: "24px",
    left: "0px",
    top: "0px",
    borderRadius: "500px",
    margin: "0px 0px 0px 0px",
  },
};

const useStyles = makeStyles()((theme) => ({
  pageHeaderDetails: {
    display: "flex",
    borderRadius: "500px",
    height: 24,
    minWidth: 90,
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.xsmall,
    color: theme.palette.secondary.bluegrey,
  },
}));

export const PurchasingOrderStatus = ({ value }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  switch (value) {
    case en.backorder:
      return (
        <MiChip
          label={en.backorder}
          style={{ backgroundColor: theme.palette.light.bluegrey }}
          className={classes.pageHeaderDetails}
        />
      );
    case en.issued:
      return (
        <MiChip
          label={en.issued}
          className={classes.pageHeaderDetails}
          style={{ backgroundColor: theme.palette.light.amber }}
        />
      );
    case en.received:
      return (
        <MiChip
          label={en.received}
          className={classes.pageHeaderDetails}
          style={{ backgroundColor: theme.palette.light.indigo }}
        />
      );
    case en.cancelled:
      return (
        <MiChip
          label={en.cancelled}
          className={classes.pageHeaderDetails}
          style={{ backgroundColor: theme.palette.light.red }}
        />
      );
    case en.paid:
      return (
        <MiChip
          label={en.paid}
          className={classes.pageHeaderDetails}
          style={{ backgroundColor: theme.palette.light.teal }}
        />
      );
    default:
      return (
        <MiChip
          label={en.open}
          className={classes.pageHeaderDetails}
          style={{ backgroundColor: theme.palette.light.blue }}
        />
      );
  }
};

export const QuoteStatus = ({ value, pageHeaderDetails }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  let pageHeaderDetailsStyle = pageHeaderDetails || classes.pageHeaderDetails;
  switch (value) {
    case QUOTE_STATUS.DRAFT:
      return (
        <MiChip
          label={en.statusDraft}
          style={{ backgroundColor: theme.palette.lightAccent.grey }}
          className={pageHeaderDetailsStyle}
        />
      );
    case QUOTE_STATUS.REQUESTED:
      return (
        <MiChip
          label={en.statusRequested}
          className={pageHeaderDetailsStyle}
          style={{ backgroundColor: theme.palette.light.indigo }}
        />
      );
    case QUOTE_STATUS.ACCEPTED:
      return (
        <MiChip
          label={en.statusAccepted}
          className={pageHeaderDetailsStyle}
          style={{ backgroundColor: theme.palette.light.amber }}
        />
      );
    case QUOTE_STATUS.CANCELLED:
      return (
        <MiChip
          label={en.statusCancelled}
          className={pageHeaderDetailsStyle}
          style={{ backgroundColor: theme.palette.light.red }}
        />
      );
    default:
      return (
        <MiChip
          label={""}
          className={pageHeaderDetailsStyle}
          style={{ backgroundColor: theme.palette.light.grey }}
        />
      );
  }
};

export const CostBookStatus = ({ value }) => {
  switch (value) {
    case "Active":
      return (
        <MiChip
          text={"Costed"}
          textColor={MiTHEME.palette.secondary.bluegrey}
          color={MiTHEME.palette.light.indigo}
          style={styles["chip"]}
        />
      );
    case "InActive":
      return (
        <MiChip
          text={"Uncosted"}
          textColor={MiTHEME.palette.secondary.bluegrey}
          color={MiTHEME.palette.light.teal}
          style={styles["chip"]}
        />
      );
    case "SearchUncosted":
      return (
        <MiChip
          text={"Uncosted"}
          textColor={MiTHEME.palette.secondary.bluegrey}
          color={MiTHEME.palette.lightAccent.grey}
          style={styles["chip"]}
        />
      );
    default:
      return (
        <MiChip
          text={"InActive"}
          textColor={MiTHEME.palette.secondary.bluegrey}
          color={MiTHEME.palette.lightAccent.grey}
          style={styles["chip"]}
        />
      );
  }
};

export const TemplateStatus = ({ value }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  switch (value) {
    case "Complete":
      return (
        <MiChip
          label={"Complete"}
          className={classes.pageHeaderDetails}
          style={{ backgroundColor: theme.palette.light.indigo }}
        />
      );
    case "Open":
      return (
        <MiChip
          label={"Open"}
          className={classes.pageHeaderDetails}
          style={{ backgroundColor: theme.palette.light.teal }}
        />
      );
    case "On Order":
      return (
        <MiChip
          label={"On Order"}
          className={classes.pageHeaderDetails}
          style={{ backgroundColor: theme.palette.light.amber }}
        />
      );
    case "Voided":
      return (
        <MiChip
          label={"Voided"}
          className={classes.pageHeaderDetails}
          style={{ backgroundColor: theme.palette.light.red }}
        />
      );
    case "Review":
      return (
        <MiChip
          label={"Review"}
          className={classes.pageHeaderDetails}
          style={{ backgroundColor: theme.palette.light.indigo }}
        />
      );
    default:
      return (
        <MiChip
          label={"Draft"}
          className={classes.pageHeaderDetails}
          style={{ backgroundColor: theme.palette.lightAccent.grey }}
        />
      );
  }
};
