import { useEffect, useState } from "react";
import { propertyService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const usePropertyActivities = (
  propertyId,
  filterBy,
  relatedEntityId
) => {
  const [activities, setActivities] = useState([]);
  const [status, setStatus] = useState("Loading...");

  const getActivities = async () => {
    if (filterBy === "Property") {
      return propertyService.getActivities(propertyId);
    }

    return propertyService.getActivities(propertyId, {
      filterBy,
      relatedEntityId,
    });
  };

  const handleGetActivities = async () => {
    const response = await getActivities();
    if (response.status === HTTP_STATUSES.OK) {
      setActivities(response.data);
      setStatus(null);
    }
  };

  useEffect(() => {
    handleGetActivities();
  }, []);

  return { activities, status, handleGetActivities };
};
