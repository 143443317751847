import React, { useState, useEffect } from "react";
import { peopleService } from "@miview/api";
import { useComponentState } from "@miview/hooks";
import {
  MiCard,
  MiTransferList,
  MiModal,
  MiModalConfirm,
} from "@miview/components";
import { HTTP_STATUSES, AND_FILTER } from "@miview/constants";
import { createFilter } from "@miview/utils";
import { mdiAccount, mdiWalk } from "@mdi/js";
import { useTheme } from "@mui/styles";

const AssigneesCollapse = (props) => {
  const {
    contacts,
    communityName,
    setEditAssigneesOpen,
    editAssigneesOpen,
    updateAssignees,
  } = props;

  const [people, setPeople] = useState([]);
  const [unassignedPeople, setUnassignedPeople] = useState([]);
  const [assignedPeople, setAssignedPeople] = useState([]);
  const [filteredPeople, setFilteredPeople] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const stateManager = useComponentState();
  const filterHelper = createFilter(AND_FILTER);
  const theme = useTheme();

  useEffect(() => {
    getPeople();
  }, []);

  const getPeople = () => {
    stateManager.run(async () => {
      const response = await peopleService.getAll({
        params: {
          pageSize: 9999,
        },
        filters: filterHelper.equals({
          accountId: localStorage.getItem("accountId"),
        }),
      });
      if (response.status === HTTP_STATUSES.OK) {
        setPeople(response.data);
      }
    });
  };

  useEffect(() => {
    sortPeople();
  }, [people, contacts]);

  const sortPeople = () => {
    const assigned = people.filter((e) =>
      contacts.some((f) => f.personId === e.personId)
    );
    const unassigned = people.filter(
      (e) => !contacts.some((f) => f.personId === e.personId)
    );
    setAssignedPeople(assigned);
    setUnassignedPeople(unassigned);
  };

  const saveEdits = (reassignWalks) => {
    let accountId = localStorage.getItem("accountId");
    const mappedAssignedPeople = assignedPeople.map((items) => ({
      personId: items.personId,
      titleId: items.titleId,
    }));
    updateAssignees(
      mappedAssignedPeople,
      accountId,
      "InternalContact",
      reassignWalks
    );
    setEditAssigneesOpen(false);
    setShowConfirmModal(!showConfirmModal);
  };

  const sortPeopleByNameAndTitle = (a, b) => {
    if (a.titleName === b.titleName) {
      if (a.fullName < b.fullName) {
        return -1;
      } else if (a.fullName > b.fullName) {
        return 1;
      }
    } else {
      if (a.titleName < b.titleName) {
        return -1;
      } else if (a.titleName > b.titleName) {
        return 1;
      }
    }
    return 0;
  };

  const cancelEdit = () => {
    setEditAssigneesOpen(false);
    sortPeople();
  };

  useEffect(() => {
    setFilteredPeople(unassignedPeople);
  }, [unassignedPeople]);

  const actions = [
    {
      onClick: () => {
        cancelEdit();
      },
      color: theme.palette.primary.grey,
      name: "Cancel",
      inverse: false,
    },
    {
      onClick: () => setShowConfirmModal(true),
      color: theme.palette.primary.green,
      name: "Save",
      inverse: true,
    },
  ];

  return (
    <div>
      {stateManager.statusTag("assigneeListStatus")}
      <div style={{ display: "flex" }}>
        <MiModal
          title={"Add Assignees to " + communityName}
          maxWidth={1000}
          width={"100%"}
          open={editAssigneesOpen}
          onClose={() => {
            setEditAssigneesOpen(false);
          }}
          actions={actions}
        >
          {
            <div style={{ marginBottom: "30px" }}>
              <MiTransferList
                id="assigned-contacts"
                headerLeft="Available"
                headerRight="Assigned"
                rightHandler={setAssignedPeople}
                leftHandler={setUnassignedPeople}
                listItemsLeft={filteredPeople}
                listItemsRight={assignedPeople}
                labelField="fullName"
                secondLabelField="titleName"
              />
            </div>
          }
        </MiModal>
      </div>
      <MiModalConfirm
        title={"Reassign Existing Walks"}
        icon={mdiWalk}
        color={theme.palette.primary.orange}
        maxWidth="90%"
        open={showConfirmModal}
        description={
          "Would you like to update the assignment of all current walks?"
        }
        handlePositive={() => saveEdits(true)}
        secondaryPositive={() => saveEdits(false)}
        handleNegative={() => {
          setShowConfirmModal(false);
        }}
        textPositive="Save and Reassign"
        secondaryPositiveText="Save without Reassigned"
      ></MiModalConfirm>

      {contacts?.sort(sortPeopleByNameAndTitle).map((user, key) => {
        return (
          <div key={"user" + key} style={{ paddingBottom: 7 }}>
            <MiCard
              color={theme.palette.secondary.teal}
              icon={mdiAccount}
              title={user.title + " - " + user.fullName}
              fieldset={[
                { icon: "email", value: user.email },
                { icon: "phone", value: user.cell ? user.cell : user.work },
              ]}
            />
          </div>
        );
      })}
    </div>
  );
};

export default AssigneesCollapse;
