import { apiOptions, apiRequest } from "../../utils";
import { API_ACCOUNT_SERVICE, ADDITIONAL_HEADERS } from "../../constants";

export const accountStatuses = {
  getAll: async () => {
    const options = await apiOptions({
      url: `${API_ACCOUNT_SERVICE}/statuses`,
      headers: ADDITIONAL_HEADERS,
      method: "get",
    });
    return apiRequest(options);
  },
};
