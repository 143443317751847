import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MiIcon } from "@miview/components";
import { mdiDotsVertical } from "@mdi/js";

export const MiMenuSystem = ({ options, icon, iconStyles }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = (option) => {
    if (option?.action) {
      option.action();
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <MiIcon
        path={icon ? icon : mdiDotsVertical}
        size={1}
        onClick={handleClick}
        style={{ ...styles.menuIcon, ...iconStyles }}
      />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "26ch",
          },
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem
              onClick={() => handleClose(option)}
              disabled={option.disabled}
              key={option.name}
            >
              {option.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

MiMenuSystem.propTypes = {
  options: PropTypes.array,
  iconStyles: PropTypes.object,
};

MiMenuSystem.defaultProps = {
  iconStyles: {},
};

const styles = {
  menuIcon: { height: 24, width: 24, cursor: "pointer" },
};
