import React from "react";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";

/**
 * - This is MiChip
 */
export const MiChip = (props) => {
  const {
    style,
    label,
    size,
    color,
    avatar,
    variant,
    onClick,
    deleteIcon,
    onDelete,
  } = props;

  return (
    <Chip
      {...props}
      data-testid="MiChip"
      color={color}
      size={size}
      label={label}
      variant={variant}
      style={style}
      avatar={avatar}
      onClick={onClick}
      deleteIcon={deleteIcon}
      onDelete={onDelete}
      clickable
    />
  );
};

MiChip.propTypes = {
  /**
   * overriding styles
   */
  style: PropTypes.object,
  /**
   * pill text
   */
  label: PropTypes.string,
  /**
   * Component rendered in the left part of the chip
   */
  avatar: PropTypes.element,
  /**
   * Component rendered in the right part of the chip
   */
  deleteIcon: PropTypes.element,
  /**
   * handle click function
   */
  onClick: PropTypes.func,
  /**
   *  handle right icon click function
   */
  onDelete: PropTypes.func,
  /**
   * size of the chip
   */
  size: PropTypes.oneOf(["small", "medium"]),
};
