import React, { useEffect } from "react";
import { useTheme, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiModal, MiInputTextbox } from "@miview/components";
import en from "../../../locale/en.json";
import { useEdit } from "@miview/hooks";

const CreateNewFolderModal = ({
  closeCreateFolderModal,
  createFolder,
  showModal,
}) => {
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    formAlignment: {
      marginTop: 20,
    },
    formWidth: {
      width: 524,
    },
  }));

  const { classes } = useStyles();

  const requiredFields = ["name"];

  const edit = useEdit({});

  const getHelperText = (field, message) => {
    const val = edit.getValue(field);
    if (!val) {
      return {
        error: true,
        helperText: message,
      };
    }
    return {
      error: false,
      helperText: "",
    };
  };

  useEffect(() => {
    if (!showModal) {
      edit.reset();
    }
  }, [showModal]);

  const handleSubmitLocation = () => {
    if (edit.allFilled(...requiredFields)) {
      const payload = { ...edit.edits };
      createFolder(payload);
    }
  };

  const actions = [
    {
      onClick: closeCreateFolderModal,
      color: theme.palette.primary.grey,
      name: "Close",
      inverse: false,
    },
    {
      onClick: handleSubmitLocation,
      color: theme.palette.primary.green,
      name: "Submit",
      inverse: true,
    },
  ];

  return (
    <MiModal title={"Create New Folder"} open={showModal} actions={actions}>
      <Grid container className={classes.formWidth}>
        <Grid item xs={6} style={{ margin: "auto" }}>
          <MiInputTextbox
            {...getHelperText("name", en.notEnteredName)}
            labelText={en.name}
            value={edit.getValue("name")}
            handleChange={(e) => edit.update({ name: e.target.value })}
            minWidth={228}
            required
          />
        </Grid>
      </Grid>
    </MiModal>
  );
};

export default React.memo(CreateNewFolderModal);
