import React, { useEffect } from "react";
import { MiModal, MiDetailFields } from "@miview/components";
import { expenseTypeService } from "@miview/api";
import { createToast } from "@miview/toast";
import {
  TOAST_TYPES,
  LEDGER_ACCOUNTS,
  EXPENSE_TYPE_CATEGORIES,
  HTTP_STATUSES,
} from "@miview/constants";
import { useTheme } from "@mui/material/styles";
import { useEdit, useComponentState } from "@miview/hooks";

export const CreateEditExpenseTypeModal = (props) => {
  const { open, onClose, expenseType } = props;

  const edit = useEdit(expenseType);
  const stateManager = useComponentState();
  const theme = useTheme();

  const ledgerAccountOptions = Object.entries(LEDGER_ACCOUNTS).map((e) => ({
    value: e[1],
    label: e[1],
  }));

  const categoryOptions = Object.entries(EXPENSE_TYPE_CATEGORIES).map((e) => ({
    value: e[1],
    label: e[1],
  }));
  const isEdit = !!expenseType;

  useEffect(() => {
    edit.reset();
  }, [open]);

  const addExpenseType = async () => {
    stateManager.run(async () => {
      const response = await expenseTypeService.create(edit.edits);
      if (response.status === HTTP_STATUSES.OK) {
        createToast("Expense Type Added Successfully", TOAST_TYPES.SUCCESS);
        onClose();
      }
    });
  };

  const editExpenseType = async () => {
    stateManager.run(async () => {
      const response = await expenseTypeService.update(expenseType.id, {
        ...expenseType,
        ...edit.edits,
      });
      if (response.status === HTTP_STATUSES.OK) {
        createToast("Edited Successfully", TOAST_TYPES.SUCCESS);
        onClose();
      }
    });
  };

  const actions = [
    {
      onClick: onClose,
      color: theme.palette.primary.grey,
      name: "Cancel",
      inverse: false,
    },
    {
      onClick: isEdit ? editExpenseType : addExpenseType,
      color: theme.palette.primary.green,
      name: "Submit",
      inverse: true,
      disabled:
        stateManager.isBusy() || !edit.allFilled("name", "externalAccountId"),
    },
  ];

  const detailFields = [
    {
      label: "Name",
      value: edit.getValue("name"),
      setValue: (e) => edit.update({ name: e }),
      width: "100%",
      required: true,
    },
    {
      label: "Category",
      fieldType: "select",
      value: edit.getValue("category"),
      options: categoryOptions,
      setValue: (e) => edit.update({ category: e }),
      width: "100%",
      required: true,
      selectNone: false,
    },
    {
      label: "Ledger Account",
      fieldType: "select",
      value: edit.getValue("externalAccountId"),
      options: ledgerAccountOptions,
      setValue: (e) => edit.update({ externalAccountId: e }),
      width: "100%",
      required: true,
    },
    {
      label: "Requires PIN to View",
      fieldType: "checkbox",
      value: edit.getValue("requiresPin"),
      setValue: () =>
        edit.update({ requiresPin: !edit.getValue("requiresPin") }),
      width: "100%",
    },
    {
      label: "Display Order",
      fieldType: "number",
      value: edit.getValue("displayOrder"),
      setValue: (e) => edit.update({ displayOrder: e }),
      width: "100%",
      required: true,
    },
  ];

  return (
    <MiModal
      title={isEdit ? "Edit Expense Type" : "Add Expense Type"}
      open={open}
      onClose={onClose}
      actions={actions}
    >
      <MiDetailFields detailFields={detailFields} />
    </MiModal>
  );
};
