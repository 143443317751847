import { propertyService } from "@miview/api";

export const revertPropertyItems = async ({
  propertyId,
  planId,
  stageIds,
  refreshData,
}) => {
  await propertyService.revert(propertyId, { planId, stageIds });

  await refreshData();
};
