import React, { useEffect, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import { Grid } from "@mui/material";
import { NotificationsNone as NotificationsIcon } from "@mui/icons-material";
import moment from "moment";
import { ToolIcon } from "./ToolIcon";

const useNotificationsStyles = makeStyles()((theme) => ({
  container: {
    padding: 8,
    borderBottom: "1px solid black",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey["300"],
    },
  },
  emptyContainer: {
    padding: 8,
  },
  empty: {
    width: 320,
    textAlign: "center",
  },
}));

const useNotifications = () => {
  const [notifications] = useState([]);

  const getNotifications = async () => {
    return null;
  };

  const unreadCount = notifications.reduce((acc, current) => {
    if (current.readDate) {
      return acc;
    }

    return acc + 1;
  }, 0);

  const markAsRead = async () => {
    return null;
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== "test") {
      getNotifications();
    }
  }, []);

  return {
    notifications,
    markAsRead,
    unreadCount,
  };
};

export const Notifications = () => {
  const { classes } = useNotificationsStyles();
  const { notifications, markAsRead, unreadCount } = useNotifications();

  const renderMenu = () => {
    if (!notifications.length) {
      return (
        <Grid item className={classes.emtpyContainer}>
          <div className={classes.empty}>No notifications</div>
        </Grid>
      );
    }

    return notifications.map((item) => {
      return (
        <Grid className={classes.container} item key={item.id}>
          <Grid container justifyContent="space-between">
            <Grid item>Mitrade</Grid>
            <Grid item>{moment(item.sentDate).format("ddd h:mm A")}</Grid>
          </Grid>
          <Grid item>{item.text}</Grid>
        </Grid>
      );
    });
  };

  return (
    <ToolIcon
      icon={NotificationsIcon}
      menu={renderMenu}
      count={unreadCount}
      onClick={markAsRead}
    />
  );
};
