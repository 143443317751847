import React from "react";
import { getImageBase64FromFile } from "@miview/utils";
import { useTheme } from "@mui/system";
import {
  MiInputTextbox,
  MiInputSelectBox,
  MiCheckbox,
  MiDatePickerInput,
  MiImageFilePicker,
  MiFormSectionHeader,
  MiInputMultiSelectBox,
  MiAutocomplete,
} from "../index.ts";
import {
  DEFAULT_IMAGE_FILE_TYPE,
  VALID_FILE_TYPES_FOR_DOCUMENT_UPLOAD,
} from "@miview/constants";
import { styled } from "@mui/material/styles";

const FieldContainer = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    fontWeight: "bold",
    flexWrap: "wrap",
    flex: 1,
  };
});

const FieldWrapper = styled("div", {
  shouldForwardProp: (props) => props !== "style",
})(({ style }) => ({
  marginRight: style.marginRight,
  marginBottom: style.marginBottom,
  minWidth: style.minWidth,
  width: style.width,
}));

export const MiDetailFields = (props) => {
  const theme = useTheme();
  const { detailFields, headerProps, customWrapperStyle } = props;

  const setStateOfPropertyCheckBox = (setFunction, value) => {
    setFunction(!value);
  };

  const setStateOfProperty = (setFunction, event, verificationFunction) => {
    if (event && event.target) {
      const value = event.target.value;
      if (!verificationFunction || verificationFunction(value)) {
        setFunction(value);
      }
    } else {
      setFunction(event);
    }
  };

  const setStateOfImageProperty = (
    setFunction,
    setBlob,
    event,
    customHandleFunction
  ) => {
    if (customHandleFunction) {
      return customHandleFunction(event);
    }
    if (event && event.target) {
      const file = event.target.files[0];
      getImageBase64FromFile(file, (base64) => {
        setFunction({
          base64,
          fileName: file.name,
          fileType: file.type,
        });
        if (setBlob) {
          setBlob(file);
        }
      });
    } else {
      setFunction(event);
    }
  };

  const setStateofFile = (setFunction, event) => {
    const file = event.target.files[0];
    if (file) {
      getImageBase64FromFile(file, (base64) => {
        setFunction({
          base64,
          fileName: file.name,
          fileType: file.type,
        });
      });
    }
  };

  const getInput = (item, idx) => {
    switch (item.fieldType) {
      case "select":
        return (
          <MiInputSelectBox
            labelText={item.label}
            value={item.value}
            labelField="text"
            minWidth={item.minWidth}
            marginBottom={item.marginBottom}
            marginRight={item.marginRight}
            menuItems={item.options || []}
            variant="standard"
            handleChange={(e) => {
              setStateOfProperty(item.setValue, e);
            }}
            disableNone={!item.selectNone}
            disabled={item.readOnly && !item.clickButton}
            required={item.required}
          />
        );
      case "autoComplete":
        return (
          <MiAutocomplete
            getOptionLabel={(item) => item.name}
            labelText={item.label}
            minWidth={item.minWidth}
            inputValue={item.inputValue}
            onInputChange={item.inputChange}
            onSelectionChange={item.setValue}
            variant={item.variant || "standard"}
            options={item.options}
            required={item.required}
            disabled={item.disabled}
            clearOnBlur={true}
            freeSolo={false}
            focusOnMount={item.focusOnMount}
          />
        );
      case "checkbox":
        return (
          <MiCheckbox
            label={item.label}
            clickButton={item.clickButton}
            checked={item.value}
            disabled={item.readOnly}
            onChange={() =>
              setStateOfPropertyCheckBox(item.setValue, item.value)
            }
          />
        );
      case "date":
        return (
          <MiDatePickerInput
            labelText={item.label}
            handleChange={(val) => {
              item.setValue(val);
            }}
            type={item.dateType || "date"}
            variant="standard"
            disabled={item.readOnly && !item.clickButton}
            editable={item.readOnly && item.clickButton}
            required={item.required}
            inputFormat={item.inputFormat}
            dateFormat={item.dateFormat}
            value={item.value}
            minInputWidth={item.minInputWidth}
          />
        );
      case "image":
        return (
          <MiImageFilePicker
            idx={idx}
            value={item.value}
            fileType={item.fileType || DEFAULT_IMAGE_FILE_TYPE}
            readOnly={item.readOnly}
            labelText={item.label}
            variant="standard"
            disabled={item.readOnly && !item.clickButton}
            style={{ height: "100%", width: "100%" }}
            handleChange={(e) =>
              setStateOfImageProperty(item.setValue, item.setBlob, e)
            }
            clipBoardItem={item.clipBoardItem}
            onClick={item.clickImage}
            required={item.required}
            showInput={item.showInput}
          />
        );
      case "file":
        return (
          <MiImageFilePicker
            value={item.value}
            fileType={item.fileType}
            readOnly={item.readOnly}
            labelText={item.label}
            variant="standard"
            disabled={item.readOnly && !item.clickButton}
            style={{ height: "100%", width: "100%" }}
            handleChange={(e) => setStateofFile(item.setValue, e)}
            inputProps={{
              accept: VALID_FILE_TYPES_FOR_DOCUMENT_UPLOAD,
            }}
            required={item.required}
          />
        );
      case "multiSelect":
        return (
          <MiInputMultiSelectBox
            value={item.value}
            labelText={item.label}
            menuItems={item.options}
            handleChange={item.setValue}
            disableNone={!item.selectNone}
            readOnly={item.readOnly}
            disabled={item.readOnly && !item.clickButton}
            required={item.required}
            multiline={item.multiline}
            error={!!item.error}
            minWidth={item.minWidth}
            marginBottom={item.marginBottom}
            marginRight={item.marginRight}
            helperText={item.helperText}
            placeholder={item.placeholder}
            rows={item.rows}
            type={item.fieldType}
            clickButton={item.clickButton}
            InputLabelProps={{ style: { color: theme.palette.medium.grey } }}
            InputProps={item.InputProps}
          />
        );
      case "emptyField":
        return <div></div>;
      default:
        return (
          <MiInputTextbox
            labelText={item.label}
            value={item.value}
            multiline={item.multiline}
            focusOnMount={item.focusOnMount}
            error={!!item.error}
            minWidth={item.minWidth}
            marginBottom={item.marginBottom}
            helperText={item.helperText}
            placeholder={item.placeholder}
            rows={item.rows}
            type={item.fieldType}
            variant={item.multiline ? "outlined" : "standard"}
            disabled={item.readOnly && !item.clickButton}
            readOnly={item.readOnly && item.clickButton}
            handleChange={(e) => {
              setStateOfProperty(item.setValue, e, item.verificationFunction);
            }}
            InputLabelProps={{ style: { color: theme.palette.medium.grey } }}
            InputProps={item.InputProps}
            required={item.required}
            minValue={item.minValue}
          />
        );
    }
  };

  return (
    <div style={customWrapperStyle}>
      {headerProps && (
        <MiFormSectionHeader
          icon={headerProps.icon}
          title={headerProps.title}
          button={headerProps.button}
          style={headerProps.style}
        />
      )}
      <FieldContainer>
        {detailFields &&
          detailFields
            .filter((e) => !e.hide)
            .map((item, index) => (
              <FieldWrapper
                style={{
                  marginBottom: item.marginBottom || 0,
                  marginRight: item.marginRight || 0,
                  minWidth: item.minWidth || 220,
                  width: item.width || "33%",
                }}
                key={"key_" + index}
              >
                <div
                  onClick={() => {
                    if (item.readOnly && item.clickButton) {
                      item.clickButton();
                    }
                  }}
                  style={{
                    margin: "0 10px 12px 0",
                  }}
                >
                  {getInput(item, index)}
                </div>
              </FieldWrapper>
            ))}
      </FieldContainer>
    </div>
  );
};
