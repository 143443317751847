import React from "react";
import { MiCard, MiButton, Image } from "@miview/components";
import { makeStyles } from "tss-react/mui";
import {
  mdiTag,
  mdiHammerWrench,
  mdiAxisArrow,
  mdiAccount,
  mdiTrashCan,
  mdiPencil,
  mdiImageMultiple,
} from "@mdi/js";
import { useTheme } from "@mui/styles";

const useStyles = makeStyles()(() => ({
  button: { border: "none" },
}));

const IssueCard = ({
  item,
  handleOpenImageUrls,
  editIssues,
  deleteIssues,
  isInspection,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <MiCard
      title={item.failReason || item.description}
      icon={isInspection ? mdiTag : mdiImageMultiple}
      border={"none"}
      color={
        isInspection ? theme.palette.primary.red : theme.palette.primary.orange
      }
      iconProps={{ width: "1.8rem", height: "1.8rem" }}
      rightArea={
        isInspection && (
          <>
            {item.propertyPlanItemFrontImageUrl ? (
              <Image
                imgSrc={item.propertyPlanItemFrontImageUrl}
                style={{ maxWidth: 30, maxHeight: 30 }}
                onClick={() =>
                  handleOpenImageUrls(item.propertyPlanItemFrontImageUrl)
                }
              />
            ) : null}
            <MiButton
              color={theme.palette.secondary.blue}
              onClick={() => editIssues(item)}
              icon={mdiPencil}
              className={classes.button}
              iconSize={0.8}
            />
            <MiButton
              color={theme.palette.primary.red}
              onClick={() => deleteIssues(item)}
              icon={mdiTrashCan}
              className={classes.button}
              iconSize={0.8}
            />
          </>
        )
      }
      orientation={"horizontal"}
      fieldset={[
        {
          icon: mdiHammerWrench,
          iconSize: 0.6,
          value: item.fixTradeName,
        },
        {
          icon: mdiAxisArrow,
          iconSize: 0.6,
          value: item.issueCategoryName,
        },
        {
          icon: mdiAccount,
          iconSize: 0.6,
          value: item.assigneeName,
        },
      ]}
    />
  );
};

export default IssueCard;
