import React from "react";
import { MiIcon } from "../MiIcon/MiIcon";
import { mdiAccount, mdiCamera } from "@mdi/js";

export const ProfilePicture = ({ src, editFunction }) => {
  const displayPicture = () => {
    if (src) {
      return <img src={src} alt="Profile" style={imgStyle} />;
    }
    return <MiIcon path={mdiAccount} size="100%" />;
  };

  return (
    <div style={imgContainer}>
      {editFunction && (
        <div style={imgPosition} onClick={editFunction}>
          <MiIcon path={mdiCamera} size={0.75} />
        </div>
      )}
      {displayPicture()}
    </div>
  );
};

const imgStyle = { height: "100%", width: "100%", borderRadius: "50%" };

const imgPosition = {
  position: "absolute",
  top: 0,
  left: 0,
  padding: 5,
  borderRadius: ".3rem",
  backgroundColor: "rgba(0,0,0,0.2)",
  color: "white",
  cursor: "pointer",
};

const imgContainer = {
  height: "200px",
  width: "200px",
  border: "2px solid #73818f",
  borderRadius: "50%",
  position: "relative",
  backgroundColor: "#FFF",
};
