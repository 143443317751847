import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "tss-react/mui";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MiTHEME } from "@miview/theme";

const StyledMenu = withStyles(
  (props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...props}
    />
  ),
  {
    paper: {
      border: `1px solid ${MiTHEME.colors.grey.lightAccent}`,
    },
  }
);

const StyledMenuItem = withStyles(MenuItem, (theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.blue,
      },
    },
  },
}));

export const MiMenu = ({
  data,
  selectedItem,
  selectedColor,
  handleChange,
  hasDivider,
  menuItemStyles,
  menuStyles,
  iconStyles,
  OptionalElement,
  disabled,
  ...menuProps
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (disabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderIcon = (icn) => {
    if (!icn) {
      return null;
    }
    return (
      <ListItemIcon>
        <div className={icn} style={{ ...iconStyles }}></div>
      </ListItemIcon>
    );
  };

  const onHandleChange = (ele) => {
    handleChange(ele.value, ele?.currentRow);
    handleClose();
  };

  const getColor = (val) => {
    if (val === selectedItem) {
      return selectedColor || MiTHEME.colors.main.primary;
    }
    return "transparent";
  };

  const getTextColor = (val) => {
    if (val === selectedItem) {
      return "white";
    }
  };

  const renderMenuItems = () => {
    if (!data) {
      return null;
    }
    return (
      <div>
        {data.map((ele) => (
          <div key={ele.value}>
            <StyledMenuItem
              onClick={() => onHandleChange(ele)}
              style={{
                backgroundColor: getColor(ele.value),
                ...menuItemStyles,
              }}
            >
              {renderIcon(ele.icon)}
              <ListItemText
                primary={ele.value}
                style={{ color: getTextColor(ele.value) }}
              />
            </StyledMenuItem>
            {hasDivider && <Divider />}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div onClick={handleClick}>{OptionalElement || <MoreVertIcon />}</div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ ...menuStyles }}
        {...menuProps}
      >
        {renderMenuItems()}
      </StyledMenu>
    </>
  );
};

MiMenu.propTypes = {
  data: PropTypes.array,
  selectedItem: PropTypes.string,
  selectedColor: PropTypes.string,
  menuItemStyles: PropTypes.object,
  menuStyles: PropTypes.object,
  iconStyles: PropTypes.object,

  /* This is used to display any html element for opening the menu. 
    If not present then the component will default to <MoreVertIcon /> */
  OptionalElement: PropTypes.element,
};

MiMenu.defaultProps = {
  data: [],
};
