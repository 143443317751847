import { ISSUE_STATUSES, TYPE_EXTERNAL } from "@miview/constants";
import moment from "moment";
import { simpleSort } from "../index.ts";

const getLatestDate = (newDate, dueDate) => {
  if (!newDate) {
    return dueDate;
  }
  return moment(newDate).isAfter(dueDate) ? dueDate : newDate;
};

const lowerCase = (val) => {
  if (val) {
    return val.toLowerCase();
  }

  return val;
};

const sortByAddress = (data) => {
  return data.sort((a, b) => {
    const val = simpleSort(lowerCase(a.streetName), lowerCase(b.streetName));
    if (val === 0) {
      return simpleSort(lowerCase(a.streetNumber), lowerCase(b.streetNumber));
    }
    return val;
  });
};

const getWorkComplete = (id) => {
  return id === ISSUE_STATUSES.WORK_COMPLETED;
};

const getPropertyInitialValues = (item) => {
  return {
    address: item.address,
    assigneeId: item.assigneeId,
    assigneeName: item.assigneeName,
    propertyId: item.propertyId,
    internalCount: 0,
    externalCount: 0,
    communityId: item.communityId,
    communityName: item.communityName,
    hold: item.hold,
    crewHold: item.crewHold,
    stageSummary: item.stageSummary,
    stageSummaryNotes: item.stageSummaryNotes,
    streetName: item.streetName,
    streetNumber: item.streetNumber,
    internalIssues: [],
    externalIssues: [],
    internalCompleteAmount: 0,
    externalCompleteAmount: 0,
    internalComplete: false,
    externalComplete: false,
  };
};

const setPropertyValues = (property, item) => {
  if (item.hold) {
    property.hold = true;
  }
  if (item.crewHold) {
    property.crewHold = true;
  }
  const complete = getWorkComplete(item.statusId);

  if (item.issueCategoryType === TYPE_EXTERNAL) {
    property.externalCount = property.externalCount + 1;
    property.externalIssues.push(item);
    property.externalDueDate = getLatestDate(
      property.externalDueDate,
      item.dueDate
    );
    if (complete) {
      property.externalCompleteAmount = property.externalCompleteAmount + 1;
    }
    property.externalComplete =
      property.externalCompleteAmount === property.externalCount;
    if (item.hold) {
      property.externalHold = true;
    }
  } else {
    property.internalDueDate = getLatestDate(
      property.internalDueDate,
      item.dueDate
    );
    property.internalCount = property.internalCount + 1;
    property.internalIssues.push(item);
    if (complete) {
      property.internalCompleteAmount = property.internalCompleteAmount + 1;
    }
    property.internalComplete =
      property.internalCompleteAmount === property.internalCount;
    if (item.hold) {
      property.internalHold = true;
    }
  }
};

export const formatPunchListData = (data, { key: groupByKey, titleKey }) => {
  const result = [];
  let groupById = "";
  let propertyId = "";
  let section = {};
  let property = {};
  let propertyIds = new Set();

  const onNewGroup = (item) => {
    if (groupById) {
      section.data.push(property);
      section.data = sortByAddress(section.data);
      result.push(section);
      section = {};
    }
    groupById = item[groupByKey];
    propertyId = "";
    property = {};
    section.title = item[titleKey];
    section.data = [];
  };

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (groupById !== item[groupByKey]) {
      onNewGroup(item);
    }

    if (propertyId !== item.propertyId) {
      if (propertyId) {
        section.data.push(property);
      }
      propertyId = item.propertyId;
      propertyIds.add(item.propertyId);
      property = getPropertyInitialValues(item);
    }

    setPropertyValues(property, item);

    if (i === data.length - 1) {
      section.data.push(property);
      section.data = sortByAddress(section.data);
      result.push(section);
    }
  }
  return {
    list: result,
    propertyIds: Array.from(propertyIds),
  };
};

export const mergePunchListItems = (
  item1,
  item2,
  { key: groupByKey, titleKey },
  firstItemOnNewChunk
) => {
  if (item1.data[0][groupByKey] !== item2.data[0][groupByKey]) {
    return [item1, item2];
  }

  const property1Index = item1.data.findIndex(
    (property) => property.propertyId === firstItemOnNewChunk.propertyId
  );
  let property2Index = -1;
  if (property1Index !== -1) {
    property2Index = item2.data.findIndex(
      (property) => property.propertyId === firstItemOnNewChunk.propertyId
    );
  }

  const property1 = item1.data[property1Index];
  const property2 = item2.data[property2Index];

  if (property2 && property1.propertyId === property2.propertyId) {
    const property = {
      address: property1.address,
      assigneeId: property1.assigneeId,
      assigneeName: property1.assigneeName,
      propertyId: property1.propertyId,
      internalCount: property1.internalCount + property2.internalCount,
      externalCount: property1.externalCount + property2.externalCount,
      communityId: property1.communityId,
      communityName: property1.communityName,
      hold: property1.hold || property2.hold,
      crewHold: property1.crewHold || property2.crewHold,
      stageSummary: property1.stageSummary,
      stageSummaryNotes: property1.stageSummaryNotes,
      streetName: property1.streetName,
      streetNumber: property1.streetNumber,
      internalIssues: [
        ...property1.internalIssues,
        ...property2.internalIssues,
      ],
      externalIssues: [
        ...property1.externalIssues,
        ...property2.externalIssues,
      ],
    };

    if (moment(property1.internalDueDate).isBefore(property2.internalDueDate)) {
      property.internalDueDate = property2.internalDueDate;
    } else {
      property.internalDueDate = property1.internalDueDate;
    }

    if (moment(property1.externalDueDate).isBefore(property2.externalDueDate)) {
      property.externalDueDate = property2.externalDueDate;
    } else {
      property.externalDueDate = property1.externalDueDate;
    }
    item1.data.splice(property1Index, 1);
    item2.data.splice(property2Index, 1);
    item1.data.push(property);
  }

  const data = sortByAddress(item1.data.concat(item2.data));

  return [
    {
      title: item1.data[0][titleKey],
      data,
    },
  ];
};

export const appendPunchListData = (
  list1,
  list2,
  groupByInfo,
  firstItemOnNewChunk
) => {
  if (!Array.isArray(list1) || !list1.length) {
    return list2;
  }
  const oldList = [...list1];
  const newList = [...list2];
  const mergedItem = mergePunchListItems(
    oldList.pop(),
    newList.shift(),
    groupByInfo,
    firstItemOnNewChunk
  );
  let result = oldList.concat(mergedItem);
  return result.concat(newList);
};
