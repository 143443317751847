import React, { useState, useEffect } from "react";
import MessageCollapse from "../../../components/Standard/MessageCollapse/MessageCollapse.js";
import UpdatePasswordModal from "../ManageUsers/UpdatePasswordModal";
import UploadProfilePictureModal from "../ManageUsers/UploadProfilePictureModal";
import UpdatePinModal from "../ManageUsers/UpdatePinModal";
import {
  userService,
  accountServiceLegacy as accountService,
  uiService,
  roleService,
  timezoneService,
  payActivityTypeService,
} from "@miview/api";
import { getImageUrl, toList, createUUID, isValidEmail } from "@miview/utils";
import { useComponentState, useEdit, useCdn, useRouter } from "@miview/hooks";
import {
  ProfilePicture,
  MiSnackbar,
  MiTab,
  MiDetailFields,
  MiButton,
  MiBox,
  MiPageHeader,
  MiIcon,
} from "@miview/components";
import {
  HTTP_STATUSES,
  TOAST_TYPES,
  PAY_TYPE_OPTIONS,
} from "@miview/constants";
import { createToast } from "@miview/toast";
import { mdiArrowLeft, mdiEmail } from "@mdi/js";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";

const uuid = createUUID();

const UserDetails = () => {
  const [user, setUser] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [titles, setTitles] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [payActivityTypes, setPayActivityTypes] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [mainActiveTab, setMainActiveTab] = useState(global.ACTIVE_TAB.DETAILS);
  const [sideActiveTab, setSideActiveTab] = useState(
    global.ACTIVE_TAB.MESSAGES
  );

  const router = useRouter();
  const userId = router.params.id || localStorage.getItem("userId");
  const isProfilePage = router.pathname === "/profile";
  const cdn = useCdn();
  const stateManager = useComponentState();
  const edit = useEdit(user);
  const theme = useTheme();
  const { classes } = useStyles();

  useEffect(() => {
    getUser();
    getTitles();
    getAccounts();
    getLanguageOptions();
    getRoles();
    getPayActivityTypes();
    getTimezones();
  }, []);

  const getTitles = () => {
    stateManager.run(async () => {
      const accountId = localStorage.getItem("accountId");
      const response = await accountService.getTitles({
        id: accountId,
        params: { pageSize: 9999 },
        sort: [{ field: "name", sort: "asc" }],
      });
      const mapped = toList(response.data, "id", "name");
      setTitles(mapped);
    });
  };

  const getAccounts = async () => {
    const response = await accountService.getAll();
    if (response.status === HTTP_STATUSES.OK) {
      const accounts = toList(response.data, "id", "name");
      setAccounts(accounts);
    }
  };

  const getLanguageOptions = () => {
    stateManager.run(async () => {
      const response = await uiService.getLanguages();
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "code", "name");
        setLanguageOptions(mapped);
      }
    });
  };

  const getRoles = () => {
    stateManager.abort(uuid);
    stateManager.run(async (signal) => {
      const response = await roleService.getAll({
        params: { pageSize: 9999 },
        signal,
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = response.data.map(({ id, roleName }) => {
          return { value: id, label: roleName };
        });
        setRoles(mapped);
      }
    }, uuid);
  };

  const getPayActivityTypes = () => {
    stateManager.run(async () => {
      const response = await payActivityTypeService.getAll({
        params: { pageSize: 9999 },
        sort: [{ field: "name", sort: "asc" }],
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "id", "name");
        setPayActivityTypes(mapped);
      }
    });
  };

  const getTimezones = () => {
    stateManager.run(async () => {
      const response = await timezoneService.getAll();
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = response.data.map((e) => {
          return { value: e, text: e };
        });
        setTimezones(mapped);
      }
    });
  };

  const enableEditMode = () => {
    setEditMode(true);
  };

  const cancelEditUser = () => {
    setEditMode(false);
    edit.reset();
  };

  const initiateUpdateUser = async () => {
    updateUser();
    setEditMode(false);
  };

  const callUpdateUserFunction = () =>
    isProfilePage
      ? userService.updateMyUser(edit.edits)
      : userService.update(userId, edit.edits);

  const updateUser = () => {
    stateManager.run(async () => {
      const response = await callUpdateUserFunction();
      if (response.status === HTTP_STATUSES.OK) {
        createToast("Changes Saved Successfully", TOAST_TYPES.SUCCESS);
        getUser();
      }
    });
  };

  const callGetUserFunction = () =>
    isProfilePage ? userService.getMyUser() : userService.get(userId);

  const getUser = () => {
    stateManager.run(async () => {
      const response = await callGetUserFunction();
      if (response.status === HTTP_STATUSES.OK) {
        setUser(response.data);
      }
    });
  };

  const resetPassword = async () => {
    await userService.initiatePasswordReset(user.email);
  };

  const saveModal = () => {
    setActiveModal(false);
    getUser();
  };

  const closeModal = () => {
    setActiveModal(false);
  };

  const emailErrorMsg = (email) => {
    if (!isValidEmail(email)) {
      return "You must enter an email address.";
    }
    return null;
  };

  const handleError = (field) => {
    const value = edit.getValue(field);
    if (["email", "personEmail"].includes(field) && value) {
      return emailErrorMsg(value);
    }
  };

  const isValid = () => {
    return edit.allFilled("firstName", "lastName", "email", "personEmail");
  };

  const renderButtons = () => {
    if (isProfilePage) {
      return (
        <>
          <MiButton
            title="Update Password"
            onClick={() => setActiveModal("Password")}
            color={theme.palette.medium.grey}
            className={classes.miButton}
          />
          <MiButton
            title="Update PIN"
            onClick={() => setActiveModal("Pin")}
            color={theme.palette.medium.grey}
            className={classes.miButton}
          />
        </>
      );
    }
    return (
      <>
        <MiButton
          title="Reset Password"
          onClick={resetPassword}
          color={theme.palette.medium.grey}
          className={classes.miButton}
        />
        <MiButton
          title="Update Password"
          onClick={() => setActiveModal("AdminPassword")}
          color={theme.palette.medium.grey}
          className={classes.miButton}
        />
      </>
    );
  };

  const hideFields = isProfilePage
    ? [
        "title",
        "home",
        "billingAccount",
        "active",
        "addtionalPayAmount",
        "additionalPayDescription",
        "additionalPayActivityTypeId",
        "roleIds",
        "payType",
      ]
    : [];

  const detailFields = [
    {
      label: "User Name",
      value: edit.getValue("email"),
      readOnly: !editMode,
      width: "50%",
      clickButton: isProfilePage ? null : enableEditMode,
      setValue: (e) => edit.update({ email: e }),
      required: true,
      error: handleError("email"),
      helperText: handleError("email"),
    },
    {
      label: "Email Address",
      value: edit.getValue("personEmail"),
      readOnly: !editMode,
      width: "50%",
      clickButton: isProfilePage ? null : enableEditMode,
      setValue: (e) => edit.update({ personEmail: e }),
      required: true,
      error: handleError("personEmail"),
      helperText: handleError("personEmail"),
    },
    {
      label: "First Name",
      value: edit.getValue("firstName"),
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ firstName: e }),
      required: true,
    },
    {
      label: "Last Name",
      value: edit.getValue("lastName"),
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ lastName: e }),
      required: true,
    },
    {
      label: "Home",
      field: "home",
      value: edit.getValue("home"),
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ home: e }),
    },
    {
      label: "Cell",
      fieldType: "number",
      value: edit.getValue("cell"),
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ cell: e }),
    },
    {
      label: "Work",
      fieldType: "number",
      value: edit.getValue("work"),
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ work: e }),
    },
    {
      label: "Language",
      fieldType: "select",
      options: languageOptions,
      value: edit.getValue("language"),
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ language: e }),
    },
    {
      label: "Pay Type",
      fieldType: "select",
      field: "payType",
      value: edit.getValue("payType"),
      options: PAY_TYPE_OPTIONS,
      selectNone: true,
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ payType: e }),
    },
    {
      label: "Billing Account",
      field: "billingAccount",
      value: edit.getValue("billingAccount"),
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ billingAccount: e }),
    },
    {
      label: "Title",
      fieldType: "select",
      field: "title",
      value: edit.getValue("titleId"),
      options: titles,
      readOnly: isProfilePage || !editMode,
      width: "50%",
      clickButton: isProfilePage ? null : enableEditMode,
      setValue: (e) => edit.update({ titleId: e }),
    },
    {
      label: "Additional Pay",
      field: "addtionalPayAmount",
      value: edit.getValue("addtionalPayAmount"),
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ addtionalPayAmount: e }),
    },
    {
      label: "Additional Pay Description",
      field: "additionalPayDescription",
      value: edit.getValue("additionalPayDescription"),
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ additionalPayDescription: e }),
    },
    {
      label: "Additional Pay Activity Type",
      fieldType: "select",
      field: "additionalPayActivityTypeId",
      options: payActivityTypes,
      value: edit.getValue("additionalPayActivityTypeId"),
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ additionalPayActivityTypeId: e }),
    },
    {
      label: "Timezone",
      fieldType: "select",
      options: timezones,
      value: edit.getValue("timezone"),
      readOnly: !editMode,
      width: "50%",
      clickButton: enableEditMode,
      setValue: (e) => edit.update({ timezone: e }),
    },
    {
      label: "Assigned Roles",
      field: "roleIds",
      fieldType: "multiSelect",
      options: roles.length ? roles : [{}],
      value: edit.getValue("roleIds"),
      disabled: !editMode,
      readOnly: !editMode,
      clickButton: enableEditMode,
      width: "50%",
      setValue: (e) => edit.update({ roleIds: e.target.value }),
    },
    {
      label: "Account ID",
      fieldType: "select",
      value: edit.getValue("accountId"),
      clickButton: enableEditMode,
      readOnly: !editMode,
      options: accounts,
      setValue: (e) => edit.update({ accountId: e }),
      width: "50%",
    },
    {
      label: `Active`,
      fieldType: "checkbox",
      value: edit.getValue("active"),
      setValue: (e) => edit.update({ active: e }),
      width: "50%",
      clickButton: enableEditMode,
      readOnly: !editMode,
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        onClick={router.goBack}
        path={mdiArrowLeft}
        size={1}
        color={theme.palette.primary.main}
        style={{ cursor: "pointer" }}
      />
    );
  };

  return (
    <>
      {stateManager.statusTag("userDetailsStatus")}
      <MiPageHeader
        title="Profile"
        leftIcon={renderHeaderIcon()}
        hasDivider
        color={theme.palette.primary.main}
      />
      <MiBox styles={{ display: "flex", flexWrap: "wrap" }}>
        <div className={classes.userContainer}>
          <ProfilePicture
            src={getImageUrl(edit.getValue("imageName"), cdn)}
            editFunction={() => setActiveModal("Profile")}
          />
          <div className={classes.userRoleContainer}>
            <div className={classes.userRoleBackground}>
              <div className={classes.userName}>
                {user.firstName} {user.lastName}
              </div>
              <div className={classes.userRole}>{user.titleName}</div>
            </div>
            <div className={classes.emailIconContainer}>
              <MiIcon path={mdiEmail} size={1} />
              {edit.getValue("userEmail")}
            </div>
          </div>
          {renderButtons()}
        </div>
        <div className={classes.detailsContainer}>
          <div className={classes.miTabContainer}>
            <MiTab
              tabs={[{ label: "Details", value: global.ACTIVE_TAB.DETAILS }]}
              onTabChange={setMainActiveTab}
              currenttab={mainActiveTab}
            />
          </div>
          {mainActiveTab === global.ACTIVE_TAB.DETAILS && (
            <MiDetailFields
              detailFields={detailFields.filter(
                (arr) => !hideFields.includes(arr.field)
              )}
            />
          )}
        </div>
        <div className={classes.messagesContainer}>
          <div className={classes.miTabContainer}>
            <MiTab
              tabs={[{ label: "Messages", value: global.ACTIVE_TAB.MESSAGES }]}
              onTabChange={setSideActiveTab}
              currenttab={sideActiveTab}
            />
          </div>
          {sideActiveTab === global.ACTIVE_TAB.MESSAGES && (
            <MessageCollapse
              messageContext={global.MESSAGE_CONTEXT.USER}
              id={userId}
            />
          )}
        </div>
      </MiBox>
      <UploadProfilePictureModal
        userId={userId}
        onSave={saveModal}
        onClose={closeModal}
        open={activeModal === "Profile"}
      />
      <UpdatePinModal
        userId={userId}
        onSave={saveModal}
        onClose={closeModal}
        open={activeModal === "Pin"}
      />
      <UpdatePasswordModal
        userId={userId}
        isAdmin={false}
        onClose={closeModal}
        open={activeModal === "Password"}
      />
      <UpdatePasswordModal
        userId={userId}
        isAdmin={true}
        onClose={closeModal}
        open={activeModal === "AdminPassword"}
      />
      <MiSnackbar
        visible={editMode}
        handleCancelClick={cancelEditUser}
        handleSaveClick={initiateUpdateUser}
        disableSave={!isValid()}
      />
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  userContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "1rem",
    flex: "1 0 220px",
    borderRadius: "0.25rem",
    border: "1px solid rgb(221,221,221)",
    padding: "3rem",
  },
  userRoleContainer: {
    marginTop: "-1rem",
    width: "100%",
    minWidth: "200px",
    textAlign: "center",
    border: `1px solid ${theme.palette.medium.grey}`,
  },
  userRoleBackground: {
    backgroundColor: theme.palette.accent.grey,
    paddingBottom: "0.5rem",
    paddingTop: "1rem",
  },
  userName: { color: theme.palette.primary.main, fontSize: "1.5em" },
  userRole: { color: theme.palette.primary.grey },
  emailIconContainer: { color: theme.palette.medium.grey },
  miTabContainer: { marginBottom: 20, paddingTop: 5 },
  detailsContainer: {
    border: "1px solid rgb(221,221,221)",
    borderRadius: "0.25rem",
    padding: "1rem",
    margin: "1rem",
    flex: "3 0 300px",
  },
  messagesContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "1rem",
    padding: "1rem",
    flex: "2 0 250px",
    border: "1px solid rgb(221,221,221)",
    borderRadius: "0.25rem",
  },
  miButton: { minWidth: "200px", marginTop: "1rem" },
}));

export default UserDetails;
