import React from "react";
import { MiInputTextbox, MiTable } from "@miview/components";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { en } from "@miview/constants";

const useStyles = makeStyles()((theme) => ({
  tableHeaderStyle: {
    backgroundColor: theme.palette.light.grey,
  },
  tableCellStyle: {
    border: "1px solid",
    borderColor: theme.palette.light.grey,
  },
  typography: {
    fontWeight: theme.fontWeight.small,
    width: "100%",
    textAlign: "right",
  },
  itemText: {
    fontWeight: theme.fontWeight.medium,
    padding: 0,
    color: theme.palette.primary.blue,
  },
  input: {
    backgroundColor: "transparent",
  },
}));

const PurchaseOrderTable = ({
  data,
  handleInputNotesChange,
  isReadOnlyNotes = true,
  orderDetails,
}) => {
  const { classes } = useStyles();

  const columns = [
    {
      field: "itemName",
      sortable: false,
      headerName: en.item,
      align: "left",
      flex: 1,
      renderCell: ({ value = "" }) => {
        return <Typography className={classes.itemText}>{value}</Typography>;
      },
    },
    {
      field: "description",
      sortable: false,
      headerName: en.description,
      flex: 4,
      valueFormatter: ({ value }) => value,
    },
    {
      field: "notes",
      sortable: false,
      headerName: en.notes,
      flex: 2,
      renderCell: ({ row, value }) => {
        return (
          <MiInputTextbox
            variant="standard"
            value={value}
            placeholder={"--"}
            InputProps={{
              disableUnderline: true,
              readOnly: isReadOnlyNotes,
            }}
            classes={{
              root: classes.input,
            }}
            handleChange={(e) => handleInputNotesChange(row, e.target.value)}
          />
        );
      },
    },
    {
      field: "sku",
      sortable: false,
      headerName: en.sku,
      flex: 1,
    },
    {
      field: "quantityOrdered",
      sortable: false,
      headerName: en.quantity,
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: ({ row, value }) => {
        const qtyReceived = row.quantityReceived;
        return orderDetails?.status === "Paid" ||
          orderDetails?.status === "Received"
          ? qtyReceived
          : value;
      },
    },
    {
      field: "unit",
      sortable: false,
      headerName: en.unit,
      flex: 1,
    },
    {
      field: "unitPrice",
      sortable: false,
      headerName: en.unitPrice,
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: ({ row }) => {
        return (
          <Typography className={classes.typography}>
            ${row.unitPrice}
          </Typography>
        );
      },
    },

    {
      field: "total",
      sortable: false,
      headerName: en.total,
      flex: 1,
      headerAlign: "right",
      renderCell: ({ row }) => {
        const qtyReceived =
          (orderDetails?.status === "Paid" ||
            orderDetails?.status === "Received") &&
          row.quantityReceived;
        const total =
          (qtyReceived || row.quantityOrdered) * row.unitPrice || "-";
        return <Typography className={classes.typography}>${total}</Typography>;
      },
    },
  ];

  return (
    <MiTable
      columns={columns}
      rows={data}
      disableColumnMenu
      hideFooter={true}
      hideFooterRowCount={true}
      disableColumnReorder={true}
      autoPageSize={true}
      autoHeight={true}
      sx={{
        "& .MuiDataGrid-columnHeaderTitle": {
          padding: "0px !important",
        },
        "& .MuiDataGrid-cell": {
          padding: "0px 10px 0px 10px !important",
        },
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        minHeight: "100%",
      }}
      classes={{
        columnHeader: classes.tableHeaderStyle,
        cell: classes.tableCellStyle,
      }}
    />
  );
};
export default PurchaseOrderTable;
