import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const cityService = {
  getCities: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/cities`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/cities/${id}`,
      method: "get",
    });

    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/cities/${id}`,
      method: "patch",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving city",
      },
      success: {
        message: "City saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  getAdders: async ({ id, sort, params, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/cities/${id}/adders`,
      method: "get",
      signal,
      params,
      sort,
    });

    return apiRequest(options);
  },

  createAdder: async (cityId, request) => {
    const options = await apiOptions({
      url: `${API_V3}/cities/${cityId}/adders`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  replaceAdder: async (cityId, adderId, request) => {
    const options = await apiOptions({
      url: `${API_V3}/cities/${cityId}/adders/${adderId}`,
      method: "put",
      data: request,
    });

    return apiRequest(options);
  },

  deleteAdder: async (cityId, adderId) => {
    const options = await apiOptions({
      url: `${API_V3}/cities/${cityId}/adders/${adderId}`,
      method: "delete",
    });

    return apiRequest(options);
  },
};
