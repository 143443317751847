import React from "react";
import { makeStyles } from "tss-react/mui";
import { MiInputTextbox } from "@miview/components";

const useStyles = makeStyles()((theme) => ({
  componentCustom: {
    width: "100%",
    "& > div": {
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%",
    },
    "& > div > label": {
      position: "relative",
      left: "0",
      top: "0",
      marginBottom: 5,
      color: theme.palette.primary.teal,
    },
    "& > div > label + div": {
      backgroundColor: theme.palette.light.grey,
    },
    "& > div > label + div input": {
      padding: "18.5px 14px",
    },
    "& fieldset": {
      borderColor: theme.palette.lightAccent.grey,
    },
    "& .Mui-focused fieldset": {
      borderColor: `${theme.palette.primary.blue}!important`,
    },
  },
}));

export const TdSearchInput = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.componentCustom}>
      <MiInputTextbox {...props} />
    </div>
  );
};

export default TdSearchInput;
