import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Grid, alpha } from "@mui/material";
import {
  MiPageHeader,
  MiTab,
  MiLoader,
  MiDrawer,
  MiIcon,
} from "@miview/components";
import { mdiClose } from "@mdi/js";
import { integrationService } from "@miview/api";
import { ImportedWorkTable } from "./ImportedWorkTable";
import { MappingsEdit } from "./MappingsEdit";
import { DetailHeader } from "./DetailHeader";
import {
  MAPPING_TYPES,
  STATES,
  HTTP_STATUSES,
  IMPORT_STATUSES,
  IMPORT_WORK_KEYS,
} from "@miview/constants";
import { createFilter } from "@miview/utils";
import { AcceptedWork } from "./AcceptedWork";
import { useDebounce } from "@miview/hooks";

const useStyles = makeStyles()((theme) => {
  return {
    button: {
      marginRight: 10,
      backgroundColor: "inherit",
      border: 0,
      cursor: "pointer",
    },
    header: {
      width: "100%",
    },
    row: {
      backgroundColor: theme.palette.primary.main,
      padding: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerText: {
      color: "white",
      fontSize: theme.fontSize.large || 20,
    },
    buttonHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: alpha(theme.palette.primary.main, 0.6),
      },
    },
  };
});

const tabs = [
  {
    label: "Homes",
    value: IMPORT_WORK_KEYS.HOMES,
  },
  {
    label: "Jobs",
    value: IMPORT_WORK_KEYS.JOBS,
  },
];

const API_UPDATE_ENTITY = {
  homes: "properties",
  jobs: "stages",
};

export const ImportedWork = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(tabs[0].value);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showAccepted, setShowAccepted] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [homeOptions, setHomeOptions] = useState();
  const [jobOptions, setJobOptions] = useState();
  const [homes, setHomes] = useState([]);
  const [jobs, setJobs] = useState([]);
  const theme = useTheme();
  const { classes } = useStyles();
  const filterHelper = createFilter();
  const [homesQueryFilter, setHomesQueryFilter] = useState({});
  const [jobsQueryFilter, setJobsQueryFilter] = useState({});
  const debouncedHomesQueryFilters = useDebounce(homesQueryFilter, 500);
  const debouncedJobsQueryFilters = useDebounce(jobsQueryFilter, 500);

  useEffect(() => {
    document.title = "Imported Work";
    fetchHomesFilteredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedHomesQueryFilters]);

  useEffect(() => {
    fetchJobsFilteredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedJobsQueryFilters]);

  const handleHomesFilterChange = (filterModel) => {
    setHomesQueryFilter({ ...filterModel });
  };

  const handleJobsFilterChange = (filterModel) => {
    setJobsQueryFilter({ ...filterModel });
  };

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  const getFilters = (tab) => {
    let filters = filterHelper.equals({
      acceptStatus: IMPORT_STATUSES.PENDING,
    });

    if (tab === IMPORT_WORK_KEYS.HOMES) {
      if (debouncedHomesQueryFilters.items) {
        filters = {
          ...filters,
          items: filters.items.concat(debouncedHomesQueryFilters.items),
        };
      }
      return filters;
    }

    if (debouncedJobsQueryFilters.items) {
      filters = {
        ...filters,
        items: filters.items.concat(debouncedJobsQueryFilters.items),
      };
    }
    return filters;
  };

  const fetchHomesFilteredData = async () => {
    try {
      setIsLoading(true);
      const filters = getFilters(IMPORT_WORK_KEYS.HOMES);

      const params = {
        orderBy: "canAccept",
        pageSize: 9999,
      };

      await fetchHomes({ params, filters });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchJobsFilteredData = async () => {
    try {
      setIsLoading(true);
      const filters = getFilters(IMPORT_WORK_KEYS.JOBS);

      const params = {
        orderBy: "canAccept",
        pageSize: 9999,
      };

      await fetchJobs({ params, filters });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchHomes = async ({ params, filters }) => {
    const response = await integrationService.properties.getAll(
      params,
      filters
    );
    if (response.data) {
      setHomes(response.data);
      fetchMappingOptions(response.data[0], IMPORT_WORK_KEYS.HOMES);
    }
  };

  const mappingPromises = async (item) => {
    if (item.mappingTypeId === MAPPING_TYPES.STATES) {
      return { id: item.mappingTypeId, data: STATES };
    } else {
      const response = await integrationService.mappingTypes.getOptions(
        item.mappingTypeId
      );
      return { id: item.mappingTypeId, data: response.data };
    }
  };

  const fetchMappingOptions = async (data, type) => {
    if (!data) {
      return;
    }
    let opts = {};
    const mappedPromises = data.mappings.map((item) => mappingPromises(item));
    const result = await Promise.allSettled(mappedPromises);
    result.forEach((item) => {
      opts[item.value.id] = item.value?.data;
    });
    if (type === IMPORT_WORK_KEYS.HOMES) {
      setHomeOptions(opts);
    } else {
      setJobOptions(opts);
    }
  };

  const fetchJobs = async ({ params, filters }) => {
    const response = await integrationService.stages.getAll(params, filters);
    if (response.data) {
      setJobs(response.data);
      fetchMappingOptions(response.data[0]);
    }
  };

  const handleSave = (name) => {
    setShowDrawer(false);
    setSelectedRow(null);
    applyMappings(name);
  };

  const applyMappings = async (name) => {
    setIsLoading(true);
    const response = await integrationService.integrations.applyMappings(name);
    setIsLoading(false);
    if (response.status < HTTP_STATUSES.BAD_REQUEST) {
      fetchHomesFilteredData();
      fetchJobsFilteredData();
    }
  };

  const handleShowAccepted = () => {
    setShowAccepted(true);
    setShowDrawer(true);
  };

  const renderTabContent = (tab) => {
    switch (tab) {
      case IMPORT_WORK_KEYS.HOMES:
        return (
          <ImportedWorkTable
            data={homes}
            fetchData={fetchHomesFilteredData}
            mode={IMPORT_WORK_KEYS.HOMES}
            onAccept={integrationService.properties.accept}
            handleSelectedMapping={handleSelectedMapping}
            handleShowAccepted={handleShowAccepted}
            filterMode="server"
            onFilterModelChange={handleHomesFilterChange}
          />
        );
      case IMPORT_WORK_KEYS.JOBS:
        return (
          <ImportedWorkTable
            data={jobs}
            fetchData={fetchJobsFilteredData}
            mode={IMPORT_WORK_KEYS.JOBS}
            onAccept={integrationService.stages.accept}
            handleSelectedMapping={handleSelectedMapping}
            handleShowAccepted={handleShowAccepted}
            filterMode="server"
            onFilterModelChange={handleJobsFilterChange}
          />
        );
      default:
        return "";
    }
  };

  const handleSelectedMapping = (row) => {
    if (row?.mappings?.length) {
      row.mappings.forEach((item, index) => {
        if (!item.id) {
          item.id = `id_${index}`;
        }
      });
    }
    setSelectedRow(row);
    setShowDrawer(true);
  };

  const handleDrawerClose = () => {
    setShowDrawer(false);
    setShowAccepted(false);
  };

  const renderHeader = (title) => {
    return (
      <Grid className={classes.header}>
        <div className={classes.row}>
          <div className={classes.headerText}>{title}</div>
          <MiIcon
            path={mdiClose}
            size={1}
            color={theme.palette.primary.white}
            onClick={handleDrawerClose}
            className={classes.buttonHover}
          />
        </div>

        {!showAccepted && renderDetailHeader()}
      </Grid>
    );
  };

  const renderDetailHeader = () => {
    if (!selectedRow) {
      return null;
    }
    return <DetailHeader selected={selectedRow} />;
  };

  const getOptions = () => {
    if (currentTab === IMPORT_WORK_KEYS.HOMES) {
      return homeOptions;
    }
    return jobOptions;
  };

  const renderModalBody = () => {
    if (showAccepted) {
      return (
        <AcceptedWork
          apiEntity={API_UPDATE_ENTITY[currentTab]}
          show={showAccepted}
        />
      );
    }
    return (
      <MappingsEdit
        apiEntity={API_UPDATE_ENTITY[currentTab]}
        handleSave={handleSave}
        selected={selectedRow}
        options={getOptions()}
      />
    );
  };

  const getTitle = () => {
    return showAccepted ? "Accepted Work" : "Manage Mappings";
  };

  return (
    <>
      <MiPageHeader title="Imported Work" color={theme.palette.primary.main} />
      {isLoading && <MiLoader type="linear" />}
      <MiTab
        tabs={tabs}
        currenttab={currentTab}
        tabIndicatorColor={theme.palette.primary.main}
        onTabChange={handleTabChange}
        renderTabContent={renderTabContent}
      />
      <MiDrawer
        header={renderHeader(getTitle())}
        onClose={handleDrawerClose}
        open={showDrawer}
        anchor={"right"}
      >
        {renderModalBody()}
      </MiDrawer>
    </>
  );
};
