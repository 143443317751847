import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const systemTypeService = {
  getSystemTypesByName: async ({ name, signal }) => {
    const params = { name };
    const options = await apiOptions({
      url: `${API_V3}/systemTypes`,
      method: "get",
      params,
      signal,
    });

    return apiRequest(options);
  },
};
