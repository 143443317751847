global.EventStatuses = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  ACKNOWLEDGED: "ACKNOWLEDGED",
};

global.ACTIVE_TAB = {
  DETAILS: "details",
  DISPATCH: "DISPATCH",
  PUNCH_ITEMS: "PUNCHLISTS",
  EXTENSIONS: "extensions",
  SUBCONTRACTORS: "SUBCONTRACTORS",
  WARRANTY: "WARRANTY",
  DOCUMENTS: "DOCUMENTS",
  STAGES: "stages",
  WALKS: "walks",
  SPECS: "SPECS",
  CONTACTS: "CONTACTS",
  OWNER_HISTORY: "OWNERHISTORY",
  PROPERTIES: "PROPERTIES",
  PLANS: "PLANS",
  CITY_ADDERS: "CITY ADDERS",
  CHECKLIST_ITEMS: "CHECKLIST ITEMS",
  HISTORY: "HISTORY",
  BUILDERS: "BUILDERS",
  INTERNAL: "INTERNAL",
  ACTIVITIES: "ACTIVITIES",
  ISSUES: "issues",
  JOBS: "jobs",
  OPTIONS: "options",
  SERIES: "Series",
  assigneesOpen: "ASSIGNEES",
  BuilderContactsOpen: "BUILDERCONTACTS",
  activeUserButton: "ASSIGNEES",
  stageInfoOpen: "STAGEINFO",
  RELATEDEXTENSIONS: "RELATEDEXTENSIONS",
  ACTIVITY: "ACTIVITY",
  BUILDERPLANS: "BUILDERPLANS",
  COMMUNITIES: "COMUNITIES",
  MESSAGES: "MESSAGES",
};

global.MESSAGE_CONTEXT = {
  HOME: "Property",
  USER: "User",
  JOB: "Job",
};

global.FILTER = {
  PROPERTY: "Property",
  JOB: "Job",
  WALK: "Walk",
};
