import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_IMPERSONATE = `${API_V3}/impersonate`;

export const impersonateService = {
  post: async (data) => {
    const options = await apiOptions({
      url: `${API_IMPERSONATE}`,
      method: "post",
      data,
    });
    return apiRequest(options)
  },
};
