import React from "react";
import { MiTHEME } from "@miview/theme";
import { styled } from "@mui/system";

const MuiCheckslistGroup = styled("div")(() => ({
  msFlex: "2 0 auto",
  flex: "2 0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "&:first-of-type": {
    alignItems: "flex-start",
    textAlign: "left",
    msFlexPositive: "1",
    flexGrow: 1,
  },
  "&:last-child": {
    alignItems: "flex-end",
    textAlign: "right",
    msFlexPositive: "1",
    flexGrow: 1,
  },
}));

const MuiCheckboxIcon = styled("div")(({ item }) => ({
  borderRadius: 50,
  width: 24,
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderWidth: 3,
  borderStyle: "solid",
  backgroundColor: item.value
    ? MiTHEME.palette.secondary.green
    : MiTHEME.palette.primary.white,
  borderColor: item.value
    ? MiTHEME.palette.secondary.green
    : MiTHEME.palette.accent.grey,
}));

const MuiCheckslistText = styled("div")(() => ({
  display: "block",
  marginTop: "0.5rem",
  fontSize: "0.685rem",
  lineHeight: "100%",
  fontWeight: 300,
  color: "#54698d",
  textTransform: "uppercase",
  letterSpacing: "0.0625rem",
  maxWidth: "100%",
  overflow: "hidden",
  whiteSpace: "normal",
}));

const MuiCheckslist = styled("div")(() => ({
  maxHeight: "200px",
  marginLeft: "0px",
  marginRight: "0px",
  marginBottom: "0",
  padding: "0.5rem",
  position: "relative",
}));

const MuiCheckslistBar = styled("div")(() => ({
  position: "absolute",
  left: "0.6rem",
  right: "0.5rem",
  top: "1.13rem",
  height: "0.3rem",
  display: "block",
  width: "calc(100% - 1.1rem)",
  background: "#efede8",
}));

const MuiCheckslistList = styled("div")(() => ({
  display: "flex",
  msFlexPack: "justify",
  justifyContent: "space-between",
  position: "relative",
  "& i": {
    zIndex: 100,
    color: "white",
    fontSize: "0.6rem",
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "1.5rem",
    cursor: "pointer",
    width: "inherit",
    height: "inherit",
  },
}));

export const MiMultiSelect = (props) => {
  const { action, picklist } = props;
  const renderItem = (item, index) => {
    return (
      <MuiCheckslistGroup key={index}>
        <MuiCheckboxIcon
          item={item}
          onClick={action ? () => action(item.id) : null}
        >
          <i
            className="material-icons"
            aria-hidden="true"
            style={{ fontSize: 18, color: "#FFF" }}
          >
            {item.value ? "check" : null}
          </i>
        </MuiCheckboxIcon>
        <MuiCheckslistText>{item.label}</MuiCheckslistText>
      </MuiCheckslistGroup>
    );
  };

  return (
    <MuiCheckslist>
      <MuiCheckslistBar></MuiCheckslistBar>
      <MuiCheckslistList>
        {picklist && picklist.map((item, index) => renderItem(item, index))}
      </MuiCheckslistList>
    </MuiCheckslist>
  );
};
