import { usePlanOptions, usePlanAreas } from ".";

export const useResetPage = () => {
  const {
    clearToggles,
    optionsToggledOn,
    optionConfiguring,
    setOptionConfiguring,
  } = usePlanOptions();
  const { refreshData, setPlanAreasLoading } = usePlanAreas();

  const resetPage = async () => {
    try {
      if (optionConfiguring || optionsToggledOn.length > 0) {
        clearToggles();
        setOptionConfiguring(0);
        setPlanAreasLoading("Loading...");

        await refreshData();
        setPlanAreasLoading("");
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return resetPage;
};
