import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { MiButton } from "../index.ts";
import { mdiPencil } from "@mdi/js";

const useStyles = makeStyles()((theme, { color }) => ({
  floatingAction: {
    position: "absolute",
    bottom: 35,
    right: 20,
    backgroundColor: color || theme.palette.primary.main,
    borderRadius: 100,
    boxShadow: theme.boxShadows.floating,
  },
}));

export const MiFloatingAction = ({
  title,
  handleClick,
  icon,
  color,
  styles,
  ...rest
}) => {
  const { classes } = useStyles({ color });

  return (
    <div className={classes.floatingAction}>
      <MiButton
        title={title}
        color={"transparent"}
        inverse={true}
        icon={icon ? icon : mdiPencil}
        style={{
          padding: 20,
          minWidth: 80,
          ...styles,
        }}
        onClick={handleClick}
        {...rest}
      />
    </div>
  );
};

MiFloatingAction.propTypes = {
  title: PropTypes.string,
  handleClick: PropTypes.func,
  icon: PropTypes.string,
  color: PropTypes.string,
  styles: PropTypes.object,
};
