import { AccountPayable, BillDetails } from "../../views";
import React from "react";
import { PERMISSIONS } from "@miview/constants";

export const accountingRoutes = [
  {
    path: "/accounting/accountpayable",
    exact: true,
    name: "Accounts Payable",
    element: <AccountPayable />,
    permissionName: PERMISSIONS.CAN_VIEW_ACCOUNTING,
  },
  {
    path: "/accounting/accountpayable/bills/:id",
    exact: true,
    name: "Bill Details",
    element: <BillDetails />,
    permissionName: PERMISSIONS.CAN_VIEW_ACCOUNTING,
  },
];
