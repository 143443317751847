import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { Grid, useTheme } from "@mui/material";
import { MiModal } from "@miview/components";
import BranchesInfoAddress from "../../../../../components/BranchesInfoAddress";
import BranchesInfoNumber from "../../../../../components/BranchesInfoNumber";
import en from "../../../../../locale/en.json";
import { useEdit } from "@miview/hooks";
import { isPhoneNumber, isValidEmail } from "@miview/utils";
import { ACCOUNT_STATUS } from "@miview/constants";

const useStyles = makeStyles()(() => ({
  content: {
    paddingRight: 20,
    paddingLeft: 20,
  },
}));

const AddNewBranchModal = ({
  accountId,
  accountTypeId,
  onCancel,
  createUpdateBranch,
  row,
  open,
  ...rest
}) => {
  const [showValidation, setShowValidation] = useState(false);

  const { classes } = useStyles();
  const theme = useTheme();

  const defaultValues = {
    code: "",
    name: "",
    fax: "",
    phone: "",
    addressStreetNumber: "",
    addressHouseNumber: "",
    storeNumber: "",
    internalNumber: "",
    addressCity: "",
    addressState: "",
    addressZipCode: "",
    email: "",
    status: ACCOUNT_STATUS.active,
    parentId: Number(accountId),
    addressAdditionalInfo: "",
    billingHouseNumber: "",
    billingStreetNumber: "",
    billingAdditionalInfo: "",
    billingCity: "",
    billingState: "",
    billingZipCode: "",
    externalId: "",
    legalName: "",
    paymentTermId: 1,
    billingRate: 0,
    accountTypeId: accountTypeId,
    website: "",
    mobile: "",
    ...row,
  };

  const edit = useEdit(defaultValues);

  useEffect(() => {
    if (!open) {
      setShowValidation(false);
      edit.reset();
    }
  }, [open]);

  const isValid = () => {
    return (
      edit.getValue("name") &&
      (!edit.getValue("email") || isValidEmail(edit.getValue("email"))) &&
      (!edit.getValue("phone") || isPhoneNumber(edit.getValue("phone"))) &&
      (!edit.getValue("fax") || isPhoneNumber(edit.getValue("fax")))
    );
  };

  const handleCreateUpdateBranch = () => {
    if (!isValid()) {
      setShowValidation(true);
      return;
    }
    createUpdateBranch({ ...defaultValues, ...edit.edits });
  };

  return (
    <MiModal
      open={open}
      maxWidth="md"
      title={en.branchModalTitle}
      modalTitleStyle={{
        padding: "40px 40px 0px 40px",
        color: theme.palette.secondary.bluegrey,
      }}
      actions={[
        {
          name: en.cancel,
          onClick: onCancel,
          disableRipple: true,
          style: {
            width: 120,
            height: 36,
            padding: "4px 10px",
            backgroundColor: theme.palette.primary.white,
            fontSize: theme.fontSize.small,
            fontWeight: theme.fontWeight.medium,
            color: theme.palette.dark.bluegrey,
          },
        },
        {
          name: en.save,
          disableRipple: true,
          onClick: handleCreateUpdateBranch,
          inverse: false,
          style: {
            width: 120,
            height: 36,
            padding: "4px 10px",
            marginRight: 15,
            fontSize: theme.fontSize.small,
            fontWeight: theme.fontWeight.medium,
            color: theme.palette.primary.white,
            backgroundColor: theme.palette.primary.main,
          },
        },
      ]}
      {...rest}
    >
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={6}>
          <BranchesInfoAddress edit={edit} enableValidate={showValidation} />
        </Grid>
        <Grid item xs={6}>
          <BranchesInfoNumber edit={edit} />
        </Grid>
      </Grid>
    </MiModal>
  );
};
AddNewBranchModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
export default React.memo(AddNewBranchModal);
