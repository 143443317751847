import { integrations } from "./integrations";
import { integrationTypes } from "./integrationTypes";
import { properties } from "./properties";
import { stages } from "./stages";
import { mappingTypes } from "./mappingTypes";
import { services } from "./services";

export const integrationService = {
  integrations,
  integrationTypes,
  properties,
  stages,
  mappingTypes,
  services,
};
