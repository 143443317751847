import React from "react";
import { THEME } from "./theme";
import DatePicker from "react-datepicker";
import { SketchPicker } from "react-color";
import CircularProgress from "@mui/material/CircularProgress";
import { miStyles } from "@miview/theme";
import "../../scss/datepicker.css";
import { getImageUrl } from "@miview/utils";
import { useCdn } from "@miview/hooks";

const MiInput = (props) => {
  const {
    label,
    labelStyle,
    required,
    isSearch,
    readOnly,
    inputStyle,
    disabled,
    errorMessage,
    options,
    optionElements,
    value,
    clickButton,
    defaultOption,
    type,
    height,
    selected,
    children,
    style,
    forwardRef,
    inputLoader,
    hide,
    ...rest
  } = props;

  const { classes } = miStyles();
  const cdn = useCdn();

  if (hide) {
    return null;
  }
  const elementLabel = label || props.placeholder;
  const elementId = elementLabel
    ? `${elementLabel.replace(/[^A-z]+/g, "")}Input`
    : "";
  let inputStyles = { ...styles.inputEditing, ...inputStyle };
  if (readOnly) {
    inputStyles = { ...styles.inputReadOnly, ...inputStyle };
  } else if (disabled) {
    inputStyles = styles.inputDisabled;
  } else if (isSearch) {
    inputStyles = { ...styles.inputSearch, ...inputStyle };
  }

  if (errorMessage) {
    inputStyles = { ...styles.inputEditing, ...styles.inputError };
  }

  let input = "";

  if (type === "date") {
    input = (
      <DatePicker
        id={elementId}
        {...rest}
        dateFormat={props.dateFormat || "ddd, MMM DD, YYYY"}
        customInputRef={forwardRef}
        customInput={
          <input
            disabled={props.disabled}
            style={{ ...styles.input, ...inputStyles }}
            ref={forwardRef}
          />
        }
        popperPlacement={props.placement || "bottom"}
        selected={selected}
        style={{ ...styles.input, ...inputStyles, width: "100%" }}
        autoComplete="off"
      />
    );
  } else if (type === "color") {
    input = <SketchPicker width={"100%"} color={value} {...rest} />;
  } else if (options) {
    input = (
      <select
        id={elementId}
        disabled={props.disabled}
        {...rest}
        value={value}
        style={{ ...styles.input, ...inputStyles }}
        ref={forwardRef}
      >
        {defaultOption && (
          <option id="default-option" key={-1} value={""}>
            {defaultOption}
          </option>
        )}
        {options.map((i, k) => (
          <option
            id={`option-${i.text}`}
            key={k}
            value={i.value}
            disabled={i.disabled}
          >
            {i.text}
          </option>
        ))}
      </select>
    );
  } else if (optionElements) {
    input = (
      <select
        id={elementId}
        disabled={props.disabled}
        {...rest}
        value={value}
        style={{ ...styles.input, ...inputStyles }}
        ref={forwardRef}
      >
        {optionElements}
      </select>
    );
  } else if (type === "textarea") {
    input = (
      <textarea
        id={elementId}
        disabled={props.disabled}
        {...rest}
        value={value || ""}
        style={{ ...styles.input, ...inputStyles, height: height || 30 }}
      ></textarea>
    );
  } else if (type === "file") {
    input = (
      <input
        id={elementId}
        disabled={props.disabled}
        type="file"
        {...rest}
        value={value}
        style={{ ...styles.input, ...inputStyles, height: height || 40 }}
      ></input>
    );
  } else if (type === "image") {
    input = (
      <div>
        {value && (
          <img
            src={getImageUrl(value, cdn)}
            style={{ width: style.width, height: style.height }}
          />
        )}
        {!readOnly && (
          <input
            id={elementId}
            disabled={props.disabled}
            {...rest}
            type="file"
            style={{ ...styles.input, ...inputStyles, height: height || 40 }}
          />
        )}
      </div>
    );
  } else {
    input = (
      <input
        id={elementId}
        type={type}
        disabled={props.disabled}
        {...rest}
        value={value || value === 0 ? value : ""}
        style={{ ...styles.input, ...inputStyles }}
        onKeyPress={() => {
          if (readOnly && clickButton) {
            clickButton();
          }
        }}
        ref={forwardRef}
        readOnly={readOnly}
      />
    );
  }

  return (
    <div
      className={classes.miInput}
      onClick={() => {
        if (readOnly && clickButton) {
          clickButton();
        }
      }}
      style={{ ...styles.container, ...style }}
    >
      {label && (
        <div style={{ ...styles.label, ...labelStyle }}>
          <span
            style={{
              color: THEME.RED_PRIMARY,
              fontWeight: "bold",
              opacity: required ? 1 : 0,
            }}
          >
            *{" "}
          </span>
          {label + " "}
          <span> {inputLoader ? <CircularProgress size={14} /> : null}</span>
        </div>
      )}
      <div style={{ width: "100%", position: "relative" }}>
        {input}
        {isSearch && (
          <i className="material-icons" style={{ ...styles.searchIcon }}>
            search
          </i>
        )}
        {isSearch && value && (
          <i
            id="SearchClear"
            className="material-icons"
            onClick={clickButton}
            style={{ ...styles.iconButton }}
          >
            close
          </i>
        )}
        {readOnly && clickButton && (
          <i
            className={`icon-button material-icons`}
            onClick={clickButton}
            style={{ ...styles.iconButton }}
          >
            edit
          </i>
        )}
        {children}
      </div>

      {errorMessage && (
        <div style={{ ...styles.errorMessage }}>{errorMessage}</div>
      )}
    </div>
  );
};

export default MiInput;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "0 10px 12px 0",
  },
  input: {
    padding: "4px 12px",
    borderRadius: 3,
    width: "100%",
    minWidth: 50,
  },
  inputEditing: {
    borderColor: THEME.GREY_LIGHT,
    borderStyle: "solid",
    borderWidth: 1,
  },
  inputReadOnly: {
    borderRadius: 0,
    background: "none",
    borderTop: "1px solid rgba(0,0,0,0)",
    borderRight: "1px solid rgba(0,0,0,0)",
    borderLeft: "1px solid rgba(0,0,0,0)",
    borderBottomWidth: 1,
    borderBottomColor: THEME.GREY_LIGHT,
    outline: "none",
    MozAppearance: "none",
    WebkitAppearance: "none",
    MsAppearance: "none",
  },
  inputSearch: {
    padding: "4px 30px",
    borderColor: THEME.GREY_LIGHT,
    borderStyle: "solid",
    borderWidth: 1,
  },
  inputDisabled: {
    borderRadius: ".25rem",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: THEME.GREY_LIGHT,
  },
  inputError: {
    borderColor: THEME.RED_PRIMARY,
    borderWidth: 1,
  },
  label: {
    fontSize: ".8rem",
    color: THEME.GREY_MEDIUM,
    marginBottom: 2,
  },
  errorMessage: {
    fontSize: ".8rem",
    color: THEME.RED_PRIMARY,
    marginLeft: 10,
  },
  iconButton: {
    color: THEME.GREY_MEDIUM,
    position: "absolute",
    right: 8,
    bottom: 6,
    fontSize: 18,
    cursor: "pointer",
    userSelect: "none",
  },
  searchIcon: {
    color: THEME.GREY_MEDIUM,
    position: "absolute",
    left: 8,
    bottom: 6,
    fontSize: 18,
    cursor: "default",
    userSelect: "none",
  },
};
