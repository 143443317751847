export * from "./constants";
export * from "./routes";
export * from "./offline";
export * from "./icons";
export * from "./rootUrls";
export * from "./urls";
export * from "./columns/columns";
export * from "./applist";
export * from "./filters";
export * from "./locale";
export * from "./integrations";
export * from "./states";
export * from "./statuses";
export * from "./userTypes";
export * from "./cycleTypes/cycleTypes";

export const CDN_STORAGE_PARAM = "cdn";
export const CDN_DEFAULT = "https://mivprodblob.azureedge.net/images/";
export const CDN_RESIZE =
  "https://miv-func-image-d01.azurewebsites.net/api/thumbnail/";
