export const permissionCheck = (user, iconConfigs) => {
  const result = [];

  iconConfigs.forEach((config) => {
    if (config.permission && user?.permissions?.includes(config.permission)) {
      result.push(config);
    }

    if (config.role && user?.roles?.includes(config.role)) {
      result.push(config);
    }

    if (config.permission === undefined && config.role === undefined) {
      result.push(config);
    }
  });

  return result;
};

export const buildNavBasedOnPermissions = (nav, permissions) => {
  const titleObjectIndex = {};
  const newNav = {
    items: [],
  };
  permissions = permissions || [];
  nav.items.forEach((item) => {
    if (item.title) {
      titleObjectIndex[item.name] = newNav.items.length;
    }
    if (item.children) {
      const childNav = buildNavBasedOnPermissions(
        { items: item.children },
        permissions
      );
      item.children = childNav.items ? childNav.items : [];
    }

    if (
      (item.permissionName && permissions.indexOf(item.permissionName) > -1) ||
      !item.permissionName
    ) {
      newNav.items.push(item);
    }
  });
  return newNav;
};
