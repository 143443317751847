import React, { useEffect, useState } from "react";
import { useTheme, Typography, Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import enPurchasing from "../../../locale/en.json";
import { HTTP_STATUSES, REQUISITION_ORDER_TYPE, en } from "@miview/constants";
import { RequisitionService } from "@miview/api";
import { useComponentState, useRouter } from "@miview/hooks";
import {
  MiButton,
  MiModalConfirm,
  MiModal,
  MiIcon,
  MiInputTextbox,
  MiLink,
  MiInputSelectBox,
  MiList,
} from "@miview/components";
import { createFilter, combineFilters } from "@miview/utils";
import CreateNewFolderModal from "./CreateNewFolderModal";
import CreateNewTemplateModal from "./CreateNewTemplateModal";
import {
  mdiFolderEdit,
  mdiFolderRemove,
  mdiFileEditOutline,
  mdiFileRemoveOutline,
} from "@mdi/js";

const useStyles = makeStyles()(() => ({
  actionFlex: {
    display: "flex",
    alignItems: "center",
  },
  filterFlex: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  widthStyle: {
    width: "100%",
  },
}));

const RequisitionTemplate = () => {
  const [templates, setTemplates] = useState([]);
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [isCreateTemplateModalOpen, setIsCreateTemplateModalOpen] =
    useState(false);
  const [isRenameFolderModalOpen, setIsRenameFolderModalOpen] = useState(false);
  const [isRenameTemplateModalOpen, setIsRenameTemplateModalOpen] =
    useState(false);
  const [isDeleteTemplateModalOpen, setIsDeleteTemplateModalOpen] =
    useState(false);
  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [rowGroupingModel, setRowGroupingModel] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [folders, setFolders] = useState([]);
  const [templateFolderId, setTemplateFolderId] = useState("");

  const router = useRouter();
  const theme = useTheme();
  const stateManager = useComponentState();

  const { classes } = useStyles();

  const getRequisitionTemplates = async (params) => {
    return stateManager.run(async () => {
      const filter = createFilter();
      filter.equals({
        type: REQUISITION_ORDER_TYPE.TEMPLATE,
      });
      return RequisitionService.requisitions.getAll({
        ...params,
        filters: combineFilters(params.filters, filter.getFilter()),
      });
    });
  };

  const getFolders = async () => {
    const response = await RequisitionService.templateFolders.getAll();
    if (response.status === HTTP_STATUSES.OK) {
      setFolders(response.data);
    }
  };

  useEffect(() => {
    getFolders();
  }, []);

  const renameFolder = async (newName) => {
    const response = await RequisitionService.templateFolders.update(
      selectedItem.groupingKey,
      { name: newName },
      "Folder Name Updated"
    );
    if (response.status === HTTP_STATUSES.OK) {
      setRefresh(!refresh);
      setSelectedItem();
      setIsRenameFolderModalOpen(false);
    }
  };

  const renameTemplate = async (newName, templateFolderId) => {
    const response = await RequisitionService.requisitions.update(
      selectedItem.id,
      { name: newName, templateFolderId },
      "Template Name Updated"
    );
    if (response.status === HTTP_STATUSES.OK) {
      const newTemplates = [...templates];
      newTemplates.splice(templates.indexOf(selectedItem), 1, response.data);
      setTemplates(newTemplates);
      setSelectedItem();
      setIsRenameTemplateModalOpen(false);
    }
  };

  const openRenameFolderModal = (rowNode) => {
    setIsRenameFolderModalOpen(true);
    setSelectedItem(rowNode);
  };

  const closeRenameFolderModal = () => {
    setIsRenameFolderModalOpen(false);
    setSelectedItem();
  };

  const openDeleteFolderModal = (rowNode) => {
    setIsDeleteFolderModalOpen(true);
    setSelectedItem(rowNode);
  };

  const closeDeleteFolderModal = () => {
    setIsDeleteFolderModalOpen(false);
    setSelectedItem();
  };

  const openRenameTemplateModal = (row) => {
    setName(row.name);
    setTemplateFolderId(row.templateFolderId);
    setIsRenameTemplateModalOpen(true);
    setSelectedItem(row);
  };

  const openDeleteTemplateModal = (row) => {
    setIsDeleteTemplateModalOpen(true);
    setSelectedItem(row);
  };

  const columns = [
    {
      field: "name",
      headerName: en.name,
      flex: 2,
      renderCell: (params) => {
        return (
          <MiLink
            to={`/purchasing/requisitions/templates/${params.row.id}`}
            title={params.value}
          />
        );
      },
    },
    {
      field: "description",
      headerName: en.description,
      flex: 1,
    },
    {
      field: "templateFolderId",
      headerName: en.folder,
      flex: 1,
      renderCell: (params) => {
        return <div>{params.row.templateFolderName}</div>;
      },
    },
  ];

  const openCreateFolderModal = () => {
    setIsCreateFolderModalOpen(true);
  };

  const closeCreateFolderModal = () => {
    setIsCreateFolderModalOpen(false);
  };

  const openCreateTemplateModal = () => {
    setIsCreateTemplateModalOpen(true);
  };

  const closeCreateTemplateModal = () => {
    setIsCreateTemplateModalOpen(false);
  };

  const createFolder = async (value) => {
    const folderData = {
      ...value,
      displaySequence: 1,
      status: "Active",
    };
    stateManager.run(async () => {
      const response = await RequisitionService.templateFolders.create(
        folderData,
        "Folder Created Successfully",
        "Error occured while creating"
      );
      if (response.status === HTTP_STATUSES.CREATED) {
        closeCreateFolderModal();
      }
    });
  };

  const createTemplate = async (value) => {
    const response = await RequisitionService.requisitions.create({
      ...value,
      type: REQUISITION_ORDER_TYPE.TEMPLATE,
    });
    if (response.status === HTTP_STATUSES.CREATED) {
      router.push(`/purchasing/requisitions/templates/${response.data.id}`);
    }
  };

  const getRowActions = (row) => {
    if (!row.isAutoGenerated) {
      return [
        {
          text: "Edit",
          onClick: () => openRenameTemplateModal(row),
          renderIcon: () => <MiIcon path={mdiFileEditOutline} size={"20px"} />,
        },
        {
          text: "Delete",
          onClick: () => openDeleteTemplateModal(row),
          renderIcon: () => (
            <MiIcon path={mdiFileRemoveOutline} size={"20px"} />
          ),
        },
      ];
    }

    if (row.groupingField === "templateFolderId") {
      return [
        {
          text: "Rename",
          onClick: () => openRenameFolderModal(row),
          renderIcon: () => <MiIcon path={mdiFolderEdit} size={"20px"} />,
        },
        {
          text: "Delete",
          onClick: () => openDeleteFolderModal(row),
          renderIcon: () => <MiIcon path={mdiFolderRemove} size={"20px"} />,
        },
      ];
    }

    return [];
  };

  const deleteFolder = async () => {
    const response = await RequisitionService.templateFolders.delete(
      selectedItem.groupingKey,
      "Folder Deleted Successfully"
    );
    if (response.status === HTTP_STATUSES.NO_CONTENT) {
      setRefresh(!refresh);
      setSelectedItem();
      setIsDeleteFolderModalOpen(false);
    }
  };

  const renderDeleteFolderModal = () => {
    return (
      <MiModalConfirm
        open={isDeleteFolderModalOpen}
        title={"Delete Folder"}
        description={`Confirm you'd like to delete folder ${selectedItem?.name}`}
        textPositive={en.delete}
        severity={"error"}
        icon={mdiFolderRemove}
        handlePositive={deleteFolder}
        handleNegative={closeDeleteFolderModal}
      />
    );
  };

  const deleteTemplate = async () => {
    const response = await RequisitionService.requisitions.delete(
      selectedItem.id,
      "Template Deleted Successfully"
    );
    if (response.status === HTTP_STATUSES.NO_CONTENT) {
      setTemplates(templates.filter((e) => e.id !== selectedItem.id));
      closeDeleteTemplateModal();
    }
  };

  const closeDeleteTemplateModal = () => {
    setSelectedItem();
    setIsDeleteTemplateModalOpen(false);
  };

  const renderDeleteTemplateModal = () => {
    return (
      <MiModalConfirm
        open={isDeleteTemplateModalOpen}
        title={"Delete Template"}
        description={`Confirm you'd like to delete template ${selectedItem?.name}`}
        textPositive={en.delete}
        severity={"error"}
        icon={mdiFileRemoveOutline}
        handlePositive={deleteTemplate}
        handleNegative={closeDeleteTemplateModal}
      />
    );
  };

  const closeRenameTemplateModal = () => {
    setIsRenameTemplateModalOpen(false);
    setSelectedItem();
  };

  const renameTemplateActions = [
    {
      onClick: () => {
        closeRenameTemplateModal();
        setName("");
        setTemplateFolderId("");
      },
      color: theme.palette.primary.grey,
      name: en.close,
      inverse: false,
    },
    {
      onClick: () => {
        renameTemplate(name, templateFolderId);
        setName("");
        setTemplateFolderId("");
      },
      color: theme.palette.primary.green,
      name: en.confirm,
      inverse: true,
      disabled: !name,
    },
  ];

  const renderRenameTemplateModal = () => {
    return (
      <MiModal open={isRenameTemplateModalOpen} actions={renameTemplateActions}>
        <Box display="flex" justifyContent="center" maxWidth="sx">
          <div className={classes.iconBackground}>
            <MiIcon path={mdiFileEditOutline} size={1} />
          </div>
        </Box>
        <Typography align="center" component="div" className={classes.title}>
          {"Edit Template"}
        </Typography>
        <MiInputTextbox
          labelText={enPurchasing.name}
          value={name}
          handleChange={(e) => setName(e.target.value)}
        />
        <MiInputSelectBox
          labelText={enPurchasing.folderLocation}
          name="templateFolderId"
          variant="outlined"
          menuItems={folders}
          labelField={"name"}
          valueField={"id"}
          value={templateFolderId || ""}
          handleChange={(e) => setTemplateFolderId(e.target.value)}
        />
      </MiModal>
    );
  };

  const renameFolderActions = [
    {
      onClick: () => {
        closeRenameFolderModal();
        setName("");
      },
      color: theme.palette.primary.grey,
      name: en.close,
      inverse: false,
    },
    {
      onClick: () => {
        renameFolder(name);
        setName("");
      },
      color: theme.palette.primary.green,
      name: en.confirm,
      inverse: true,
      disabled: !name,
    },
  ];

  const renderRenameFolderModal = () => {
    return (
      <MiModal open={isRenameFolderModalOpen} actions={renameFolderActions}>
        <Box display="flex" justifyContent="center" maxWidth="sx">
          <div className={classes.iconBackground}>
            <MiIcon path={mdiFolderEdit} size={1} />
          </div>
        </Box>
        <Typography align="center" component="div" className={classes.title}>
          {"Rename Folder"}
        </Typography>
        <MiInputTextbox
          labelText={enPurchasing.name}
          value={name}
          handleChange={(e) => setName(e.target.value)}
        />
      </MiModal>
    );
  };

  const renderHeaderButtons = () => {
    return (
      <div className={classes.actionFlex}>
        <MiButton
          color={theme.palette.primary.main}
          inverse={true}
          title={enPurchasing.createFolder}
          onClick={openCreateFolderModal}
          style={{
            fontSize: theme.fontSize.medium,
            fontWeight: theme.fontWeight.medium,
            minWidth: 130,
            height: 40,
          }}
        />
        <MiButton
          color={theme.palette.primary.main}
          inverse={true}
          title={enPurchasing.createTemplate}
          onClick={openCreateTemplateModal}
          style={{
            fontSize: theme.fontSize.medium,
            fontWeight: theme.fontWeight.medium,
            minWidth: 130,
            height: 40,
          }}
        />
      </div>
    );
  };

  return (
    <>
      {stateManager.statusTag("requisitionTemplates")}
      {renderDeleteTemplateModal()}
      {renderRenameTemplateModal()}
      {renderDeleteFolderModal()}
      {renderRenameFolderModal()}
      <CreateNewFolderModal
        showModal={isCreateFolderModalOpen}
        closeCreateFolderModal={closeCreateFolderModal}
        createFolder={createFolder}
      />
      <CreateNewTemplateModal
        showModal={isCreateTemplateModalOpen}
        closeCreateTemplateModal={closeCreateTemplateModal}
        createTemplate={createTemplate}
        folders={folders}
      />
      <MiList
        data={templates}
        setData={setTemplates}
        dataProvider={getRequisitionTemplates}
        columns={columns}
        refresh={refresh}
        getRowActions={getRowActions}
        renderHeaderButtons={renderHeaderButtons}
        disableColumnPinning={true}
        defaultGroupingExpansionDepth={-1}
        rowGroupingColumnMode="single"
        experimentalFeatures={{
          rowGrouping: true,
        }}
        rowGroupingModel={rowGroupingModel}
        onRowGroupingModelChange={(model) => {
          setRowGroupingModel(model);
        }}
      />
    </>
  );
};

export default RequisitionTemplate;
