import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Actions from "../../helpers/ActionHelper";
import { MiMaterialField } from "./MiMaterialField";
import { materialService, manufacturerService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { MiModal, MiDetailFields } from "@miview/components";
import { useTheme } from "@mui/material/styles";

const useMaterials = ({ categoryId, brand }) => {
  const [materials, setMaterials] = useState([]);
  const [materialBrands, setMaterialBrands] = useState([]);

  const handleGetBrands = async () => {
    if (!categoryId) {
      setMaterialBrands([]);
      return;
    }

    const response = await manufacturerService.getAll({
      params: { categoryId },
    });

    if (response.status === HTTP_STATUSES.OK) {
      setMaterialBrands(response.data);
    }
  };

  const handleGetMaterials = async () => {
    const response = await materialService.getAll({
      params: { pageSize: 9999 },
      filters: {
        categoryId,
        manufacturerId: brand,
      },
    });
    if (response.status === HTTP_STATUSES.OK) {
      setMaterials(response.data);
    }
  };

  useEffect(() => {
    handleGetBrands();
  }, [categoryId]);

  useEffect(() => {
    if (categoryId && brand) {
      handleGetMaterials();
    } else {
      setMaterials([]);
    }
  }, [categoryId, brand]);

  return {
    materials,
    materialBrands,
  };
};

export const MiMaterialsModal = ({
  edit,
  materialCategories,
  setMaterialsModalOpen,
}) => {
  const theme = useTheme();

  const { materials, materialBrands } = useMaterials({
    categoryId: edit.getValue("materialCategoryId"),
    brand: edit.getValue("brandName"),
  });

  const updateMaterial = (e) => {
    edit.update({
      materialId: e,
    });
  };

  const updateData = (name, value) => {
    edit.update({
      [name]: value,
      materialId: "",
    });
  };

  const handleCancelModal = () => {
    setMaterialsModalOpen(false);
    edit.reset();
  };

  const filteredMaterials = materials.filter(
    (m) => m.categoryId === Number(edit.getValue("materialCategoryId"))
  );
  const handleSave = () => {
    const selectedMaterial = materials.find(
      (m) => m.id === Number(edit.getValue("materialId"))
    );
    edit.update({
      materialName: selectedMaterial?.name,
      materialItemNumber: selectedMaterial?.itemNumber,
      materialImageUrl: selectedMaterial?.imageURL,
      materialImageName: selectedMaterial?.imageName,
    });
    setMaterialsModalOpen(false);
  };

  const actions = [
    {
      onClick: handleCancelModal,
      color: theme.palette.primary.grey,
      name: "Cancel",
      inverse: false,
    },
    {
      onClick: handleSave,
      color: theme.palette.primary.green,
      name: "Save",
      inverse: true,
      disabled: !edit.getValue("materialId"),
    },
  ];

  const modalFields = [
    {
      label: "Category",
      value: edit.getValue("materialCategoryId"),
      fieldType: "autoComplete",
      setValue: (_e, value) => updateData("materialCategoryId", value?.id),
      width: "100%",
      options: materialCategories?.sort(
        (a, b) =>
          Actions.simpleSort(a.displayOrder, b.displayOrder) ||
          Actions.simpleSort(a.name, b.name)
      ),
      focusOnMount: true,
    },
    {
      label: "Brand",
      value: edit.getValue("brandName"),
      fieldType: "autoComplete",
      setValue: (_e, value) => updateData("brandName", value?.id),
      width: "100%",
      options: materialBrands,
    },
  ];
  return (
    <MiModal
      actions={actions}
      maxWidth={800}
      title="Input Material Details"
      onClose={handleCancelModal}
      open
    >
      <MiDetailFields detailFields={modalFields} />
      <MiMaterialField
        materials={filteredMaterials}
        updateMaterial={updateMaterial}
        materialId={edit.getValue("materialId")}
      />
    </MiModal>
  );
};

MiMaterialsModal.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  materialCategories: PropTypes.array,
  setMaterialsModalOpen: PropTypes.func,
  materialBrands: PropTypes.array,
};
