import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import {
  PlanItemImageLabel,
  PlanItemImageFocus,
  MiImagePreview,
} from "../index.ts";
import { getImageBase64FromFile } from "@miview/utils";
import { VALID_FILE_TYPES_FOR_IMAGES_UPLOAD } from "@miview/constants";

class ImageCollector extends Component {
  componentDidMount() {
    this.nv.addEventListener("paste", this.onPasteImageHandler, true);
  }

  componentWillUnmount() {
    this.nv.removeEventListener("paste", this.onPasteImageHandler, true);
  }

  onPasteImageHandler = (pasteEvent) => {
    const items = pasteEvent.clipboardData.items;

    for (const ele of items) {
      if (!ele.type.includes("image")) {
        continue;
      }
      const file = ele.getAsFile();

      this.submitFileAndImage(file);
    }
  };

  submitFileAndImage = (file) => {
    const { submitImage, submitFile } = this.props;
    getImageBase64FromFile(file, (base64) => {
      if (submitImage) {
        submitImage(base64);
      }
      if (submitFile) {
        submitFile(file);
      }
    });
  };

  onImageSelect = (event) => {
    const file = event.target.files[0];

    this.submitFileAndImage(file);
  };

  render() {
    const { imageUrl, uniqueKey } = this.props;

    const renderImage = () => {
      if (imageUrl) {
        return <MiImagePreview src={imageUrl} />;
      }
      return null;
    };

    return (
      <>
        <PlanItemImageFocus ref={(elem) => (this.nv = elem)}>
          <PlanItemImageLabel
            style={{ pointerEvents: "none", textAlign: "left" }}
          >
            Image
          </PlanItemImageLabel>
          <input
            className="CreateDrawingInput"
            type="file"
            id={`areaDrawing-${uniqueKey}`}
            name={`areaDrawing-${uniqueKey}`}
            accept={VALID_FILE_TYPES_FOR_IMAGES_UPLOAD}
            onChange={this.onImageSelect}
          />
          <label
            htmlFor={`areaDrawing-${uniqueKey}`}
            className="CreateDrawingLabel"
          />
        </PlanItemImageFocus>
        <div className="AreaItemFormImage">{renderImage()}</div>
      </>
    );
  }
}

ImageCollector.propTypes = {
  uniqueKey: PropTypes.string,
  imageUrl: PropTypes.string,
  submitImage: PropTypes.func,
  submitFile: PropTypes.func,
};

export const MiImageCollector = memo(ImageCollector);
