import React, { useState, useEffect } from "react";
import { MiHeader } from "../../../components/Standard/MiView";
import { THEME } from "../../../components/Standard/theme";
import Actions from "../../../helpers/ActionHelper";
import { ToolIcon } from "@miview/assets";
import {
  materialService,
  materialCategoryService,
  manufacturerService,
} from "@miview/api";
import { useEdit, useComponentState, useRouter } from "@miview/hooks";
import { toList } from "@miview/utils";
import {
  ImageViewer,
  Image,
  MiSnackbar,
  MiTab,
  MiDetailFields,
  MiImageCollector,
  MiModal,
} from "@miview/components";
import { HTTP_STATUSES } from "@miview/constants";
import { useTheme } from "@mui/material";

const MaterialDetails = () => {
  const [materialDetails, setMaterialDetails] = useState({});
  const [imageData, setImageData] = useState("");
  const [editMode, setEditMode] = useState();
  const [materialImgEdit, setMaterialImgEdit] = useState(false);
  const [categories, setcategories] = useState([]);
  const [fullScreenStatus, setFullScreenStatus] = useState(false);
  const [activeTab, setActiveTab] = useState("Details");
  const [manufacturers, setManufacturers] = useState([]);

  const edit = useEdit(materialDetails);
  const stateManager = useComponentState();
  const router = useRouter();
  const theme = useTheme();

  const { id: materialId } = router.params;

  const getManufacturers = () => {
    stateManager.run(async () => {
      const response = await manufacturerService.getAll();
      const mapped = toList(
        response.data.sort((a, b) => Actions.simpleSort(a.name, b.name)),
        "id",
        "name"
      );
      setManufacturers(mapped);
    });
  };

  useEffect(() => {
    getManufacturers();
    materialCategoryService.getAll().then((response) => {
      const mapped = response.data
        .sort(
          (a, b) =>
            Actions.simpleSort(a.displayOrder, b.displayOrder) ||
            Actions.simpleSort(a.name, b.name)
        ) //order by displayOrder then by name
        .map((i) => ({ value: i.id, text: i.name }));
      setcategories(mapped);
    });
  }, []);

  useEffect(() => {
    refreshMaterial();
  }, [materialId]);

  const refreshMaterial = async () => {
    const response = await materialService.get(materialId);
    if (response.status === HTTP_STATUSES.OK) {
      setMaterialDetails(response.data);
    }
  };

  const saveMaterialImage = async () => {
    await materialService.update(materialId, { image: imageData });
    await refreshMaterial();
    edit.reset();
    handleModalClose();
  };

  const cancelEdit = () => {
    setEditMode(!editMode);
    edit.reset();
  };

  const saveEdits = async () => {
    await materialService.update(materialId, edit.edits);
    await refreshMaterial();
    setEditMode(false);
    edit.reset();
  };

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const detailFields = [
    {
      label: "Item Number",
      value: edit.getValue("itemNumber"),
      required: true,
      setValue: (e) => edit.update({ itemNumber: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Name",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Description",
      value: edit.getValue("description"),
      required: true,
      setValue: (e) => edit.update({ description: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Price",
      value: edit.getValue("price"),
      setValue: (e) => edit.update({ price: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Brand",
      fieldType: "select",
      value: edit.getValue("manufacturerId"),
      required: true,
      options: manufacturers,
      setValue: (e) => edit.update({ manufacturerId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Category",
      fieldType: "select",
      options: categories,
      value: edit.getValue("categoryId"),
      setValue: (e) => edit.update({ categoryId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
  ];

  const handleModalClose = () => {
    setMaterialImgEdit(false);
    setImageData("");
  };

  return (
    <div className="animated fadeIn">
      <MiModal
        open={materialImgEdit}
        title="Edit Material Image"
        onClose={handleModalClose}
        actions={[
          {
            name: "Cancel",
            onClick: handleModalClose,
            color: theme.palette.primary.grey,
          },
          {
            name: "Save",
            onClick: saveMaterialImage,
            color: theme.palette.primary.main,
            inverse: true,
            disabled: !imageData,
          },
        ]}
      >
        <MiImageCollector
          uniqueKey="material-image-new"
          imageUrl={imageData}
          submitImage={setImageData}
        />
      </MiModal>
      <MiSnackbar
        visible={editMode}
        handleCancelClick={cancelEdit}
        handleSaveClick={saveEdits}
        disableSave={
          !edit.getValue("name") ||
          !edit.getValue("description") ||
          !edit.getValue("itemNumber") ||
          !edit.getValue("manufacturerId")
        }
      />

      <MiHeader
        color={THEME.BLUE_PRIMARY}
        backAction={router.goBack}
        inverse={false}
        icon="arrow_back"
        title="Material Details"
      />

      <div style={THEME.BOX_MAIN}>
        <Image
          label="Material Image"
          style={{ position: "relative" }}
          imgSrc={materialDetails.imageName || ToolIcon}
          onClick={() => setFullScreenStatus(!!materialDetails.imageName)}
          toggle={() => setMaterialImgEdit(true)}
        />

        <div
          style={{
            width: 120,
            display: "flex",
            flexDirection: "row",
            marginTop: 10,
          }}
        >
          <MiTab
            tabs={[{ label: "Details", value: "Details" }]}
            onTabChange={setActiveTab}
            currenttab={activeTab}
          />
        </div>

        <MiDetailFields
          detailFields={detailFields}
          headerProps={{
            icon: "list",
            title: "Detail Fields",
          }}
        />
      </div>
      <ImageViewer
        fullScreenStatus={fullScreenStatus}
        images={[materialDetails.imageName || ToolIcon]}
        onUnfullscreen={() => setFullScreenStatus(false)}
      />
    </div>
  );
};

export default MaterialDetails;
