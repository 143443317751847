import React, { useState, useEffect } from "react";
import { createToast } from "@miview/toast";
import { WalkItems } from "./WalkItems";
import { walkService, issueService, propertyItemService } from "@miview/api";
import {
  COMPLETE,
  CANCELLED,
  UNASSIGNED,
  NOT_SCHEDULED,
  SCHEDULED,
  PENDING,
  IN_PROGRESS,
  PERMISSIONS,
  HTTP_STATUSES,
  WALK_RECORD_TYPES,
  WALK_STATUS_OPTIONS_KEYED,
  WALK_STATUS_INSPECTION_LABELS,
  HOLD,
  INCOMPLETE,
  ISSUE_CATEGORY_IDS,
  TOAST_TYPES,
} from "@miview/constants";
import {
  usePermissions,
  useRouter,
  useEdit,
  useComponentState,
  useTrades,
  useIssues,
  useRedTagAssignees,
} from "@miview/hooks";
import {
  MiModalConfirm,
  MiBox,
  MiTab,
  MiButton,
  MiPageHeader,
  MiIcon,
  MiFieldSet,
} from "@miview/components";
import { makeStyles } from "tss-react/mui";
import {
  getFieldValueFromList,
  getNextBusinessDay,
  checkBase64,
} from "@miview/utils";
import { AssignWorkModal } from "./AssignWorkModal";
import { HoldWorkModal } from "./HoldWorkModal";
import { CancelWorkModal } from "./CancelWorkModal";
import { WorkDetailSidebar } from "./WorkDetailSidebar";
import { WorkDetailsTab } from "./WorkDetailsTab";
import { useTheme } from "@mui/material";
import {
  mdiCalendar,
  mdiCalendarRemove,
  mdiHandBackRight,
  mdiCalendarSync,
  mdiTag,
  mdiArrowLeft,
} from "@mdi/js";
import RedTagCard from "./RedTagCard";
import ScheduleModal from "./ScheduleModal";
import RescheduleModal from "./RescheduleModal";
import GreenTagModal from "./GreenTagModal";
import ExitRedTagModal from "./ExitRedTagModal";
import moment from "moment";

const INITIAL_RED_TAG_VALUES = {
  failReason: "",
  fixTradeId: "",
  issueCategoryId: ISSUE_CATEGORY_IDS.UNKNOWN,
  assigneeId: "",
};
const defaultWalkStatuses = Object.values(WALK_STATUS_OPTIONS_KEYED);

const handleAssigneeResponse = (response, setAssignees) => {
  const mappedAssignees = response.map((assignee) => {
    return {
      ...assignee,
      value: assignee.userId,
      label: `${assignee.name} - ${assignee.titleName}`,
    };
  });
  setAssignees(mappedAssignees);
};

const currentTime = moment().format();
const nextBusinessDay = getNextBusinessDay(moment());

const StageWalkDetails = () => {
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isHoldModalOpen, setIsHoldModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [isRedTagMode, setIsRedTagMode] = useState(false);
  const [activeTab, setActiveTab] = useState(global.ACTIVE_TAB.DETAILS);
  const [walkDetails, setWalkDetails] = useState({});
  const [walkStatuses, setWalkStatuses] = useState(defaultWalkStatuses);
  const [isGreenTagModalOpen, setIsGreenTagModalOpen] = useState(false);
  const [isPlumbing, setIsPlumbing] = useState(true);
  const [checklist, setChecklist] = useState([]);
  const [hasIssues, setHasIssues] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isExitRedTagModalOpen, setIsExitRedTagModalOpen] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState({});

  const permissions = usePermissions();
  const router = useRouter();
  const stateManager = useComponentState();
  const { id: walkId } = router.params;
  const { redTagMode = "" } = router.query;
  const { classes } = useStyles();
  const theme = useTheme();
  const { trades, plumbingId, getTrades } = useTrades();
  const { issues, getIssues, updateIssue, deleteIssue } = useIssues(
    walkId,
    setHasIssues
  );
  const { assignees, getAssignableUsers } = useRedTagAssignees();
  const edit = useEdit(walkDetails);
  const redTagEdit = useEdit(INITIAL_RED_TAG_VALUES);
  const hasMadeEdits =
    edit.getValue("reviewedOnDate") !== currentTime ||
    edit.getValue("walkEndDate") !== walkDetails?.scheduledStartDate ||
    edit.getValue("nextAttemptScheduledStartDate") !== nextBusinessDay;

  const isInspection =
    walkDetails?.recordTypeId === WALK_RECORD_TYPES.INSPECTION;
  const isPunch = walkDetails?.recordTypeId === WALK_RECORD_TYPES.PUNCH;
  const isScheduled = [IN_PROGRESS, SCHEDULED].includes(walkDetails?.status);
  const disabledStatuses = [HOLD, CANCELLED];
  const scheduleDisabledStatuses = [UNASSIGNED, NOT_SCHEDULED, PENDING];
  const rescheduleDisabledStatuses = [SCHEDULED, IN_PROGRESS];
  const greenTagRedtagCancelDisabledStatuses = [
    PENDING,
    UNASSIGNED,
    NOT_SCHEDULED,
    SCHEDULED,
    IN_PROGRESS,
  ];

  useEffect(() => {
    handleGetWalk();
    getChecklist();
    getTrades();
    getIssues();
    getAssignableUsers(walkId, handleAssigneeResponse);
  }, [walkId]);

  useEffect(() => {
    if (redTagMode === "true") {
      handleRedTagClick();
    }
  }, [trades, walkDetails]);

  const getChecklist = () => {
    stateManager.run(async () => {
      const response = await walkService.getItems(walkId);
      if (response.status === HTTP_STATUSES.OK) {
        const data = response.data.map((item) =>
          item?.name ? item : { ...item, name: "Red Tag Issues" }
        );
        setChecklist(data);
      }
    });
  };

  const tabs = !isInspection
    ? [
        { value: global.ACTIVE_TAB.DETAILS, label: "Details" },
        {
          value: global.ACTIVE_TAB.CHECKLIST_ITEMS,
          label: "Checklist Items",
        },
      ]
    : [{ value: global.ACTIVE_TAB.DETAILS, label: "Details" }];

  const handleMapStatuses = (walk) => {
    setWalkStatuses(
      defaultWalkStatuses.map((w) => {
        return {
          ...w,
          text:
            walk.recordTypeId === WALK_RECORD_TYPES.INSPECTION
              ? WALK_STATUS_INSPECTION_LABELS[w.value]
              : w.text,
          disabled: disabledStatuses.includes(w.text),
        };
      })
    );
  };

  const handleGetWalk = () => {
    stateManager.run(async () => {
      const response = await walkService.getWalkExpand(walkId, "Crews");
      if (response.status === HTTP_STATUSES.OK) {
        setWalkDetails(response.data);
        handleMapStatuses(response.data);
        edit.reset();
      }
    });
  };

  const setRedTagDefaults = () => {
    redTagEdit.reset();
    redTagEdit.update({
      failReason: "",
      fixTradeId: plumbingId,
      issueCategoryId: ISSUE_CATEGORY_IDS.UNKNOWN,
      assigneeId: assignees?.length === 1 ? assignees[0].userId : "",
      image: "",
    });
  };

  const handleAssign = () => {
    if (checklist?.length > 0) {
      openAssignModal();
    } else {
      createToast(
        "There are no walk items for the job. Please try to recreate them again.",
        TOAST_TYPES.WARNING,
        { autoClose: 5000, pauseOnHover: true }
      );
    }
  };

  const handleResetWalk = async () => {
    const response = await walkService.reset(walkId);
    if (response.status === HTTP_STATUSES.OK) {
      router.navigate(`/walks/${response.data}`);
    }
    setIsResetModalOpen(false);
  };

  const renderChecklist = () => {
    const renderList = () => {
      return (
        <WalkItems
          checklistItems={checklist}
          stage={walkDetails.stageTypeName}
          recordTypeId={walkDetails.recordTypeId}
        />
      );
    };

    return renderList();
  };

  const openAssignModal = () => {
    setIsAssignModalOpen(true);
  };

  const closeAssignModal = () => {
    setIsAssignModalOpen(false);
    handleGetWalk();
  };

  const openHoldModal = () => {
    setIsHoldModalOpen(true);
  };

  const openGreenTagModal = () => {
    setIsGreenTagModalOpen(true);
  };

  const closeHoldModal = () => {
    setIsHoldModalOpen(false);
    handleGetWalk();
  };

  const openScheduleModal = () => {
    setIsScheduleModalOpen(true);
  };

  const openRescheduleModal = () => {
    setIsRescheduleModalOpen(true);
  };

  const closeCancelModal = () => {
    setIsCancelModalOpen(false);
    handleGetWalk();
  };

  const closeScheduleModal = () => {
    setIsScheduleModalOpen(false);
  };

  const closeRescheduleModal = () => {
    setIsRescheduleModalOpen(false);
  };

  const closeGreenTagModal = () => {
    setIsGreenTagModalOpen(false);
  };

  const handleSaveRedTag = () => {
    stateManager.run(async () => {
      const response = await walkService.completeWalk(walkId, {
        ...edit.edits,
        walkStatus: INCOMPLETE,
      });
      if (response.status === HTTP_STATUSES.OK) {
        edit.reset();
        redTagEdit.reset();
        handleGetWalk();
        setIsRedTagMode(false);
        router.navigate(`/homes/${walkDetails.propertyId}/Inspections`);
      }
    });
  };

  const handleRedTagMode = () => {
    if (hasMadeEdits) {
      setIsExitRedTagModalOpen(true);
    } else {
      handleExitRedTagModal();
    }
  };

  const handleRedTagSubmit = () => {
    handleSaveRedTag();
  };

  const handleHide = (value, trades) => {
    if (
      !getFieldValueFromList("id", "name", value, trades, "")
        .toLowerCase()
        .includes("plumb")
    ) {
      setIsPlumbing(false);
      redTagEdit.update({
        fixTradeId: value,
        issueCategoryId: ISSUE_CATEGORY_IDS.UNKNOWN,
        assigneeId: null,
      });
      return;
    }
    setIsPlumbing(true);
  };

  const closeExitRedTagModal = () => {
    setIsExitRedTagModalOpen(false);
  };

  const handleExitRedTagModal = () => {
    setIsRedTagMode(false);
    edit.reset();
    redTagEdit.reset();
    setIsExitRedTagModalOpen(false);
  };

  const handleUpdateIssues = () => {
    const finalRedTagValues = {
      ...INITIAL_RED_TAG_VALUES,
      ...redTagEdit.edits,
    };
    updateIssue(finalRedTagValues);
    setIsPlumbing(true);
    setRedTagDefaults();
  };

  const handleDeleteIssues = (selectedIssue) => {
    deleteIssue(selectedIssue.id);
  };

  const editIssues = (item) => {
    setSelectedEditItem(item);
    redTagEdit.update({
      failReason: item.failReason,
      fixTradeId: item.fixTradeId,
      issueCategoryId: item.issueCategoryId,
      assigneeId: item.assigneeId,
      image: item.propertyPlanItemFrontImageUrl,
    });
    setIsPlumbing(item.fixTradeName.toLowerCase().includes("plumb"));
  };

  const handleSaveSelectedItem = () => {
    stateManager.run(async () => {
      const redTagImage = redTagEdit.getValue("image");
      if (redTagImage === null || checkBase64(redTagImage)) {
        await propertyItemService.update(selectedEditItem.propertyPlanItemId, {
          frontImage: redTagImage,
        });
      }

      const response = await issueService.update(
        selectedEditItem.id,
        redTagEdit.edits
      );
      if (response.status === HTTP_STATUSES.OK) {
        setSelectedEditItem({});
        setIsPlumbing(true);
        setRedTagDefaults();
        getIssues();
      }
    });
  };

  const handleCancelSelectedItem = () => {
    setSelectedEditItem({});
    setIsPlumbing(true);
    setRedTagDefaults();
  };

  const handleRedTagClick = () => {
    redTagEdit.update({
      fixTradeId: plumbingId,
      assigneeId: assignees?.length === 1 ? assignees[0].userId : "",
    });
    setIsRedTagMode(true);
    edit.update({
      reviewedOnDate: currentTime,
      walkEndDate: walkDetails?.scheduledStartDate,
      nextAttemptScheduledStartDate: nextBusinessDay,
    });

    setEditMode(false);
  };

  const isActionButtonDisabled =
    stateManager.isBusy() ||
    walkDetails?.status === COMPLETE ||
    walkDetails?.status === CANCELLED ||
    walkDetails?.status === INCOMPLETE ||
    editMode;

  const canSubmit =
    hasIssues && edit.allFilled("walkEndDate", "reviewedOnDate");

  return (
    <div className="animated fadeIn" style={{ padding: 0 }}>
      {stateManager.statusTag("walkDetailStatus")}
      <AssignWorkModal
        open={isAssignModalOpen}
        onClose={closeAssignModal}
        walkId={walkId}
        walkDetails={walkDetails}
      />
      <ScheduleModal
        open={isScheduleModalOpen}
        onClose={closeScheduleModal}
        onSubmit={handleGetWalk}
        walkId={walkId}
      />
      <RescheduleModal
        open={isRescheduleModalOpen}
        onClose={closeRescheduleModal}
        walkId={walkId}
      />
      <HoldWorkModal
        open={isHoldModalOpen}
        onClose={closeHoldModal}
        walkId={walkId}
        walkDetails={walkDetails}
      />
      <GreenTagModal
        open={isGreenTagModalOpen}
        onClose={closeGreenTagModal}
        walkId={walkId}
        handleGetWalk={handleGetWalk}
      />
      <CancelWorkModal
        open={isCancelModalOpen}
        onClose={closeCancelModal}
        walkId={walkId}
        isInspection={isInspection}
      />
      <MiModalConfirm
        title="Reset Work"
        handlePositive={handleResetWalk}
        open={isResetModalOpen}
        handleNegative={() => setIsResetModalOpen(false)}
        description="Are you sure you want to reset this work?"
        color={theme.palette.primary.red}
      />
      <ExitRedTagModal
        open={isExitRedTagModalOpen}
        onClose={closeExitRedTagModal}
        handleExitRedTagModal={handleExitRedTagModal}
      />
      <MiPageHeader
        color={theme.palette.primary.blue}
        leftIcon={
          <MiIcon
            path={mdiArrowLeft}
            color={theme.palette.primary.blue}
            size={1.5}
            onClick={router.goBack}
          />
        }
        title={walkDetails?.name}
        className={classes.header}
        loading={stateManager.isBusy()}
        rightStyles={{
          flex: 1,
          flexWrap: "wrap",
          alignContent: "center",
          padding: "0 10px",
        }}
        details={
          <MiFieldSet
            hasDivider={true}
            orientation={"Horizontal"}
            dividerOrientation={"vertical"}
            fieldSetArray={[
              {
                label: "Address",
                to: `/homes/${walkDetails?.propertyId}/Details`,
                value: walkDetails?.address,
              },
              {
                label: "Stage",
                to: `/stages/${walkDetails?.propertyStageId}`,
                value: walkDetails?.stageTypeName,
              },
              { label: "Type", value: walkDetails?.walkTypeName },
              { label: "Attempt", value: walkDetails?.attemptNumber },
              {
                label: "Community",
                to: `/communities/${walkDetails?.communityId}`,
                value: walkDetails?.communityName,
              },
              {
                label: "Builder",
                to: `/builder/${walkDetails?.builderId}`,
                value: walkDetails?.builderName,
              },
            ]}
          />
        }
        rightArea={
          <div className={classes.redTagButtons}>
            {!isRedTagMode && isScheduled && isInspection && (
              <MiButton
                title="Reschedule"
                icon={mdiCalendarSync}
                color={theme.palette.secondary.blue}
                disabled={
                  isActionButtonDisabled ||
                  !rescheduleDisabledStatuses.includes(walkDetails.status)
                }
                onClick={openRescheduleModal}
              />
            )}
            {!isRedTagMode && !isScheduled && isInspection && (
              <MiButton
                title="Schedule"
                icon={mdiCalendarSync}
                color={theme.palette.secondary.blue}
                disabled={
                  isActionButtonDisabled ||
                  !scheduleDisabledStatuses.includes(walkDetails.status)
                }
                onClick={openScheduleModal}
              />
            )}
            {!isRedTagMode && isInspection && (
              <>
                <MiButton
                  title="Green Tag"
                  icon={mdiTag}
                  color={theme.palette.primary.green}
                  disabled={
                    isActionButtonDisabled ||
                    !greenTagRedtagCancelDisabledStatuses.includes(
                      walkDetails.status
                    )
                  }
                  onClick={openGreenTagModal}
                />
                <MiButton
                  title="Red Tag"
                  icon={mdiTag}
                  color={theme.palette.primary.red}
                  onClick={handleRedTagClick}
                  disabled={
                    isActionButtonDisabled ||
                    !greenTagRedtagCancelDisabledStatuses.includes(
                      walkDetails.status
                    )
                  }
                />
                <MiButton
                  title="Cancel"
                  inverse={false}
                  color={theme.palette.secondary.red}
                  icon={mdiCalendarRemove}
                  onClick={() => setIsCancelModalOpen(!isCancelModalOpen)}
                  disabled={
                    isActionButtonDisabled ||
                    !greenTagRedtagCancelDisabledStatuses.includes(
                      walkDetails.status
                    )
                  }
                />
              </>
            )}
            {!isInspection &&
              permissions.hasPermission(PERMISSIONS.CAN_RESET_WALK) &&
              !isPunch && (
                <MiButton
                  title={"Reset"}
                  disabled={isActionButtonDisabled}
                  color={theme.palette.primary.orange}
                  onClick={() => setIsResetModalOpen(true)}
                  width={150}
                  className={classes.inspectionButtons}
                />
              )}
            {!isInspection && (
              <>
                <MiButton
                  title={"Hold"}
                  disabled={isActionButtonDisabled}
                  icon={mdiHandBackRight}
                  color={theme.palette.primary.purple}
                  onClick={openHoldModal}
                  width={150}
                  className={classes.inspectionButtons}
                />
                <MiButton
                  title={"Assign"}
                  disabled={isActionButtonDisabled}
                  inverse={false}
                  color={theme.palette.primary.orange}
                  icon={mdiCalendar}
                  onClick={handleAssign}
                  width={150}
                  className={classes.inspectionButtons}
                />
                <MiButton
                  title="Cancel"
                  disabled={isActionButtonDisabled}
                  inverse={false}
                  color={theme.palette.primary.red}
                  icon={mdiCalendarRemove}
                  onClick={() => setIsCancelModalOpen(!isCancelModalOpen)}
                  className={classes.inspectionButtons}
                />
              </>
            )}
          </div>
        }
      />
      {isRedTagMode && (
        <RedTagCard
          canSubmit={canSubmit}
          handleRedTagMode={handleRedTagMode}
          handleRedTagSubmit={handleRedTagSubmit}
        />
      )}
      <div className={classes.boxContainer}>
        <MiBox styles={{ padding: "1rem", flex: "7 1 0%", marginLeft: "1rem" }}>
          <div className={classes.tabContainer}>
            <MiTab
              tabs={tabs}
              currenttab={activeTab}
              onTabChange={setActiveTab}
            />
          </div>
          {activeTab === global.ACTIVE_TAB.DETAILS && (
            <WorkDetailsTab
              walkId={walkId}
              walkDetails={walkDetails}
              refreshWalk={handleGetWalk}
              walkStatuses={walkStatuses}
              isRedTagMode={isRedTagMode}
              stageTypeName={walkDetails.stageTypeName}
              edit={edit}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          )}
          {activeTab === global.ACTIVE_TAB.CHECKLIST_ITEMS && renderChecklist()}
        </MiBox>
        <WorkDetailSidebar
          trades={trades}
          issues={issues}
          assignees={assignees}
          plumbingId={plumbingId}
          propertyId={walkDetails.propertyId}
          redTagEdit={redTagEdit}
          isRedTagMode={isRedTagMode}
          isInspection={isInspection}
          isPlumbing={isPlumbing}
          handleHide={handleHide}
          selectedEditItem={selectedEditItem}
          handleUpdateIssues={handleUpdateIssues}
          handleDeleteIssues={handleDeleteIssues}
          editIssues={editIssues}
          handleSaveSelectedItem={handleSaveSelectedItem}
          handleCancelSelectedItem={handleCancelSelectedItem}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles()(() => ({
  inspectionButtons: { margin: "5px 5px" },
  header: { flexWrap: "nowrap" },
  redTagButtons: { display: "flex", flexDirection: "row" },
  boxContainer: {
    display: "flex",
    flexDirection: "row",
    paddingRight: "1rem",
    paddingTop: "1rem",
  },
  tabContainer: {
    width: "100%",
    marginTop: -15,
    position: "relative",
  },
}));

export default StageWalkDetails;
