import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Grid,
  useTheme,
  alpha,
} from "@mui/material";
import { MiButton } from "../index.ts";
import Icon from "@mdi/react";
import { mdiDelete } from "@mdi/js";
import { styled } from "@mui/system";

const Title = styled(Typography)(({ theme, color }) => ({
  color: color || theme.palette.secondary.bluegrey,
  fontSize: 14,
  fontWeight: "bold",
  alignItems: "center",
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.dark.bluegrey,
  fontSize: 12,
  align: "center",
}));
const IconBackground = styled("div")(({ theme, color }) => ({
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  marginBottom: "20px",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
  backgroundColor: alpha(color || theme.palette.primary.red, 0.2),
}));
const ButtonAlignment = styled(DialogActions)(() => ({
  margin: 15,
}));

export const MiModalConfirm = ({
  open,
  title,
  textNegative,
  handleNegative,
  textPositive,
  handlePositive,
  description,
  color,
  icon,
  secondaryPositive,
  secondaryPositiveText,
}) => {
  const theme = useTheme();

  const renderIcon = () => {
    return (
      <Box display="flex" justifyContent="center" maxWidth="sx">
        <IconBackground color={color}>
          <Icon
            path={icon || mdiDelete}
            size={1.4}
            color={color || theme.palette.primary.red}
            data-testid={"SvgIcon"}
          />
        </IconBackground>
      </Box>
    );
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {renderIcon()}
        <Title align="center" color={color}>
          {title}
        </Title>
      </DialogTitle>
      <DialogContent>
        <Description align="center">{description}</Description>
      </DialogContent>
      <ButtonAlignment>
        <Grid container direction="row" justifyContent="space-evenly">
          <Grid item>
            <MiButton
              title={textNegative || "Cancel"}
              onClick={handleNegative}
              color={theme.palette.primary.grey}
            />
          </Grid>
          {secondaryPositive && (
            <Grid item>
              <MiButton
                title={secondaryPositiveText || "Confirm"}
                onClick={secondaryPositive}
                inverse
                color={color || theme.palette.primary.red}
              />
            </Grid>
          )}
          <Grid item>
            <MiButton
              title={textPositive || "Confirm"}
              onClick={handlePositive}
              inverse
              color={color || theme.palette.primary.red}
            />
          </Grid>
        </Grid>
      </ButtonAlignment>
    </Dialog>
  );
};

MiModalConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  textPositive: PropTypes.string,
  textNegative: PropTypes.string,
  handlePositive: PropTypes.func,
  handleNegative: PropTypes.func,
  color: PropTypes.string,
  icon: PropTypes.string,
  buttonLeftStyles: PropTypes.object,
  buttonRightStyles: PropTypes.object,
};
