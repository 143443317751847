import React from "react";
import { MiPageHeader, MiBox, MiIcon } from "@miview/components";
import { MiTHEME } from "@miview/theme";
import { mdiHelpCircleOutline, mdiAccount, mdiEmail, mdiPhone } from "@mdi/js";

const Support = () => {
  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiHelpCircleOutline}
        size={1}
        color={MiTHEME.palette.primary.main}
      />
    );
  };

  return (
    <>
      <MiPageHeader
        title={`Support`}
        color={MiTHEME.palette.primary.main}
        leftIcon={renderHeaderIcon()}
      />
      <MiBox>
        <div style={{ display: "flex", paddingRight: 50 }}>
          <div style={personIconContainer}>
            <MiIcon
              path={mdiAccount}
              color={MiTHEME.palette.secondary.teal}
              size={4}
            />
          </div>
          <div style={contactContainer}>
            <h3>How do I contact Support? </h3>
            <div style={contactIconContainer}>
              <MiIcon path={mdiPhone} color={MiTHEME.palette.secondary.teal} />
              <span>Phone (Text or Call): (817) 835-8761</span>
            </div>
            <div style={contactIconContainer}>
              <MiIcon path={mdiEmail} color={MiTHEME.palette.secondary.teal} />
              <span>Email: support@miviewis.com</span>
            </div>
          </div>
        </div>
      </MiBox>
    </>
  );
};

const personIconContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const contactContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingLeft: "50px",
};

const contactIconContainer = {
  padding: "5px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
};

export default Support;
