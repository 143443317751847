import React, { forwardRef, useMemo } from "react";
import { CathedralLogo, LogoMiView } from "@miview/assets";
import { Grid, Typography, useTheme, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiLink, MiPageHeader, MiTable } from "@miview/components";
import { en, CHECK_INVENTORY_PAGE_ROW_COUNT } from "@miview/constants";

const useStyles = makeStyles()((theme) => ({
  tableHeaderStyle: {
    backgroundColor: theme.palette.light.blue,
    padding: 6,
  },
  tableCellStyle: {
    border: "1px solid",
    borderColor: theme.palette.lightAccent.grey,
  },
  gridPower: {
    paddingBottom: 5,
    justifyContent: "flex-end",
  },
  gridEmp: {
    padding: 25,
    margin: 0,
    color: theme.palette.mediumAccent.blue,
  },
  signDate: {
    padding: 25,
    color: theme.palette.mediumAccent.blue,
  },
  grid: {
    margin: 30,
    paddingRight: 0,
  },
  grid1: {
    paddingRight: 0,
    marginTop: 80,
    alignSelf: "flex-end",
  },
  gridItem: {
    marginBottom: 600,
  },
  logo: {
    marginTop: 30,
    height: 34,
    width: 129,
  },
  header: {
    marginTop: 90,
  },
  requisition: {
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: theme.fontSize.medium,
    marginLeft: 10,
    color: theme.palette.secondary.bluegrey,
  },
  requisitionStyle: {
    paddingTop: 12,
  },
  headerStyle: {
    paddingTop: 12,
  },
  link: {
    fontWeight: theme.fontWeight.medium,
    textDecoration: "none",
    marginLeft: 0,
    padding: 0,
  },
  foot: {
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.small,
  },
  page: {
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.small,
    border: "1px",
    backgroundColor: theme.palette.lightAccent.grey,
    width: 94,
    height: 20,
    textAlign: "center",
    marginLeft: 135,
    borderRadius: 4,
  },
  footer: {
    marginBottom: 0,
    paddingRight: 50,
  },
  power: {
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.small,
    color: theme.palette.mediumDark.grey,
    paddingLeft: 50,
  },
}));

const CheckInventoryPrint = forwardRef(({ requisitionData, reqId }, ref) => {
  const theme = useTheme();
  const { classes } = useStyles();

  const rows = requisitionData.reduce((acc, group) => {
    return acc.concat(
      group.data.map((item, index) => {
        return {
          id: item.id,
          item: item.itemname,
          description: item.itemdescription,
          quantity: item.quantity,
          onHand: item.onhand,
          productLine: index === 0 ? group.categoryname : "",
        };
      })
    );
  }, []);

  const tables = useMemo(() => {
    let tableindex = 0;
    let count = 0;
    const tableRows = [];
    for (let row of rows) {
      count++;
      if (count === CHECK_INVENTORY_PAGE_ROW_COUNT) {
        count = 0;
        tableindex++;
      }
      if (!tableRows[tableindex]) {
        tableRows[tableindex] = [];
      }
      tableRows[tableindex].push(row);
    }
    return tableRows;
  }, [rows]);

  const columns = [
    {
      field: "productLine",
      sortable: false,
      headerName: en.productLine,
      align: "left",
      width: 255,
    },
    {
      field: "item",
      sortable: false,
      headerName: en.item,
      width: 255,
      renderCell: (param) => {
        const value = param.row.item || "";
        return (
          <MiLink
            title={value}
            style={{
              fontWeight: theme.fontWeight.medium,
              fontSize: theme.fontSize.small,
              textDecoration: "none",
              marginLeft: 0,
              padding: 0,
            }}
          />
        );
      },
    },
    {
      field: "description",
      sortable: false,
      headerName: en.description,
      width: 300,
    },

    {
      field: "quantity",
      sortable: false,
      headerName: en.quantity,
      width: 120,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "onHand",
      sortable: false,
      headerName: en.onHand,
      align: "right",
      headerAlign: "right",
      width: 120,
    },
  ];
  const renderTable = (rowData) => {
    return (
      <MiTable
        columns={columns}
        rows={rowData}
        disableColumnMenu
        hideFooter={true}
        disableColumnReorder={true}
        autoPageSize={true}
        autoHeight={true}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            padding: "0px !important",
            color: theme.palette.mediumAccent.blue,
          },
          "& .MuiDataGrid-cell": {
            padding: "0px 10px 0px 10px !important",
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {
            borderColor: `${theme.palette.lightAccent.grey} !important`,
          },
        }}
        classes={{
          columnHeader: classes.tableHeaderStyle,
          cell: classes.tableCellStyle,
        }}
      />
    );
  };
  const renderFooter = (pageNumber, totalPage) => {
    return (
      <Grid container className={classes.footer}>
        <Grid item xs={3} className={classes.gridPower}>
          <Typography className={classes.power}>{en.poweredBy}</Typography>
          <img src={LogoMiView} alt="title" width="180px" height="80px" />
        </Grid>
        <Grid item xs={3} className={classes.gridEmp}>
          <Divider />
          <Typography className={classes.foot}>{en.employee}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.signDate}>
          <Divider />
          <Typography className={classes.foot}>{en.signature}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.signDate}>
          <Divider />
          <Typography className={classes.foot}>{en.date} </Typography>
          <Typography className={classes.page}>
            Page {pageNumber} of {totalPage}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid
      container
      direction="column"
      style={{ height: "auto", width: "100%" }}
      ref={ref}
      className={classes.grid}
    >
      {tables.map((items, index) => {
        return (
          <Grid
            key={index}
            item
            container
            justifyContent="space-between"
            style={{ height: 1580, width: "100%", padding: 30 }}
          >
            <Grid item xs={12}>
              <Grid container item xs={12} className={classes.headerStyle}>
                <Grid item xs={12} className={classes.logo}>
                  <img src={CathedralLogo} alt="title" />
                </Grid>
                <Grid item xs={12} className={classes.header}>
                  <MiPageHeader
                    title={en.inventoryCheck}
                    color={theme.palette.primary.blue}
                    hasDivider={true}
                    backgroundColor={theme.palette.light.blue}
                    containerStyles={{
                      marginRight: 40,
                      borderRadius: 4,
                    }}
                    titleVariant={"h4"}
                  />
                </Grid>
                <Grid container direction="row" xs={12}>
                  <Grid item xs={3}>
                    <Typography className={classes.requisition}>
                      {en.requisitionOrder} {reqId}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.requisition}>
                      {en.date} {new Date().toLocaleDateString()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.requisitionStyle}>
                {renderTable(items)}
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.grid1}>
              {renderFooter(index + 1, tables.length)}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
});

export default CheckInventoryPrint;
