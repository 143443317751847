import React from "react";
import { makeStyles } from 'tss-react/mui';
import MuiCircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles()(() => ({
  root: {},
}));

export const CircularProgress = (props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <MuiCircularProgress
        variant="indeterminate"
        className={classes.bottom}
        color="primary"
        size={16}
        thickness={6}
        {...props}
      />
    </div>
  );
};
