import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  requiredRed: {
    color: theme.palette.primary.red,
  },
}));

export default React.memo((props) => {
  const { classes } = useStyles();
  return (
    <>
      {props.text}
      <span className={classes.requiredRed}>{"\u00a0\u00a0*"}</span>
    </>
  );
});
