import React from "react";
import { Grid, useTheme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { StatesCities } from "../dummy";
import en from "../locale/en.json";
import { MiInputSelectBox, MiInputTextbox } from "@miview/components";
import { isValidEmail } from "@miview/utils";

const states = StatesCities.map((item) => {
  return { label: item.State, value: item.State };
});

const cities = StatesCities[0].Cities.map((item) => {
  return { label: item, value: item };
});

const useStyles = makeStyles()(() => ({
  mainContainer: {
    marginBottom: 30,
  },
  formLabel: {
    paddingRight: 5,
  },

  Grid: {
    paddingRight: 5,
  },
  formGroup: {
    paddingLeft: 10,
  },
  formAlign: {
    marginTop: 20,
  },
}));

const BranchInfoNumber = ({ isDisable, edit }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Grid container className={classes.formGroup}>
      <Grid item xs={6} className={`${classes.Grid} ${classes.formAlign}`}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.storeNumber}
          minWidth={150}
          disabled={isDisable}
          value={edit.getValue("storeNumber")}
          handleChange={(e) => edit.update({ storeNumber: e.target.value })}
          type={"number"}
        />
      </Grid>
      <Grid item xs={6} className={`${classes.formLabel} ${classes.formAlign}`}>
        <MiInputSelectBox
          labelTextColor={theme.palette.primary.main}
          labelText={en.city}
          menuItems={cities}
          disableNone={true}
          minWidth={150}
          disabled={isDisable}
          value={edit.getValue("addressCity") || ""}
          displayEmpty={true}
          isPlaceholder={true}
          handleChange={(e) => edit.update({ addressCity: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} className={classes.formAlign}>
        <MiInputSelectBox
          labelTextColor={theme.palette.primary.main}
          labelText={en.state}
          menuItems={states}
          disableNone={true}
          minWidth={150}
          disabled={isDisable}
          value={edit.getValue("addressState") || ""}
          displayEmpty={true}
          isPlaceholder={true}
          handleChange={(e) => edit.update({ addressState: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.zipCode}
          minWidth={150}
          disabled={isDisable}
          value={edit.getValue("addressZipCode")}
          type={"number"}
          handleChange={(e) => edit.update({ addressZipCode: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.branchEmail}
          disabled={isDisable}
          value={edit.getValue("email")}
          handleChange={(e) => edit.update({ email: e.target.value })}
          helperText={
            edit.allFilled("email") && !isValidEmail(edit.getValue("email"))
              ? "Email is not valid"
              : ""
          }
        />
      </Grid>
    </Grid>
  );
};

export default BranchInfoNumber;
