import { apiOptions, apiRequest } from "../../index.js";
import { API_HEADERS_TEMP, API_INTEGRATION_SERVICE_URL } from "../../constants";

const API_INTEGRATIONS = `/integrations`;
const API_RUNS = `runs`;

export const integrations = {
  getAll: async (params, filters) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  get: async (name) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}`,
      method: "get",
      headers: API_HEADERS_TEMP,
    });
    return apiRequest(options);
  },
  update: async (name, data) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}`,
      method: "patch",
      data,
      headers: API_HEADERS_TEMP,
    });
    const toastMessageConfig = {
      success: {
        message: "This API was successfully saved",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
  create: async (data) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}`,
      method: "post",
      data,
      headers: API_HEADERS_TEMP,
    });
    const toastMessageConfig = {
      success: {
        message: "Added integration successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
  testConnection: async (name) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}/connectionStatus`,
      method: "get",
      headers: API_HEADERS_TEMP,
    });
    return apiRequest(options);
  },

  //MAPPINGS
  getAllMappings: async ({id,  params, filters }) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${id}/mappings`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  createMapping: async (name, data) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}/mappings`,
      method: "post",
      data,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  updateMapping: async (name, mappingId, data) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}/mappings/${mappingId}`,
      method: "patch",
      data,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  deleteMapping: async (name, id) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}/mappings/${id}`,
      method: "delete",
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  applyMappings: async (name) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}/mappings/apply`,
      method: "post",
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },

  //SERVICES
  getAllServices: async (name) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}/services`,
      method: "get",
      headers: API_HEADERS_TEMP,
    });
    return apiRequest(options);
  },
  updateService: async (name, serviceId, data) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}/services/${serviceId}`,
      method: "patch",
      data,
      headers: API_HEADERS_TEMP,
    });
    const toastMessageConfig = {
      success: {
        message: "This API was successfully saved",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
  runService: async (name, serviceId) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}/services/${serviceId}/runs`,
      method: "post",
      headers: API_HEADERS_TEMP,
    });
    const toastMessageConfig = {
      success: {
        message: "Schedule run initiated successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  //RUNS
  getAllRuns: async ({name,  params, filters }) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}/${API_RUNS}`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  getRun: async (name, id) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}/${API_RUNS}/${id}`,
      method: "get",
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  getRunDetails: async ({ name, id, params, filters }) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_INTEGRATIONS}/${name}/${API_RUNS}/${id}/rundetails`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
};
