import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";
export const GET_WALK_FAILURE_TYPES = `${API_V3}/walkfailuretypes`;

export const failureTypeService = {
  getFailureTypes: async () => {
    const options = await apiOptions({
      url: GET_WALK_FAILURE_TYPES,
    });
    return apiRequest(options);
  },
};
