import React, { useState, useEffect } from "react";
import { MiHeader } from "../../../components/Standard/MiView";
import { MiModal, MiDetailFields } from "@miview/components";
import "react-datepicker/dist/react-datepicker.css";
import { createToast } from "@miview/toast";
import ValidationRadioList from "./ValidationRadioList";
import {
  systemTypeService,
  accountServiceLegacy as accountService,
  communityService,
  propertyService,
  planService,
} from "@miview/api";
import {
  SYSTEM_TYPE_CATEGORIES,
  ACCOUNT_TYPES,
  HTTP_STATUSES,
  TOAST_TYPES,
  AND_FILTER,
} from "@miview/constants";
import { createFilter, checkIsNumber } from "@miview/utils";
import {
  useEdit,
  useComponentState,
  useRouter,
  useDebounce,
} from "@miview/hooks";
import { useTheme } from "@mui/material/styles";

const CreateNewPropertyModal = (props) => {
  const { toggle, newPropertyInProgress } = props;
  const [isValidateModalOpen, setIsValidateModalOpen] = useState(false);
  const [stateShort, setStateShort] = useState();
  const [propertyPlans, setPropertyPlans] = useState([]);
  const [useVerifiedAddress, setUseVerifiedAddress] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [garageSwingTypes, setGarageSwingTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [plansSearchTerm, setPlansSearchTerm] = useState("");
  const edit = useEdit();
  const validated = useEdit();
  const stateManager = useComponentState();
  const router = useRouter();
  const debouncedPlansSearchTerm = useDebounce(plansSearchTerm, 300);
  const theme = useTheme();

  useEffect(() => {
    getPlans(
      edit.getValue("builderId"),
      edit.getValue("communityId"),
      plansSearchTerm
    );
  }, [debouncedPlansSearchTerm]);

  const getBuilders = () => {
    stateManager.run(async () => {
      const response = await accountService.getAll({
        params: { pageSize: 9999 },
        filters: { type: ACCOUNT_TYPES.BUILDER },
        sort: [{ field: "name", sort: "asc" }],
      });
      if (response.data) {
        setBuilders(response.data);
      }
    });
  };

  const getCommunities = async () => {
    stateManager.run(async () => {
      const response = await communityService.getAll({
        params: { pageSize: 9999 },
      });
      if (response.status === HTTP_STATUSES.OK) {
        setCommunities(response.data);
      }
    });
  };

  const getPlans = (builderId, communityId, search = null) => {
    stateManager.run(async () => {
      const filter = createFilter(AND_FILTER);
      filter.equals({
        communityId: [null, communityId],
        builderId: [null, builderId],
      });
      const response = await planService.getPlans({
        filters: filter.getFilter(),
        sort: [{ field: "name", sort: "asc" }],
        params: { pageSize: 20, search: search },
      });
      setPropertyPlans(Array.isArray(response.data) ? response.data : []);
    });
  };

  const getGarageSwingTypes = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: SYSTEM_TYPE_CATEGORIES.GARAGE_SWING,
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = response.data.map((item) => {
          return { value: item["systemTypeId"], label: item["mainValue"] };
        });
        setGarageSwingTypes(mapped);
      }
    });
  };

  useEffect(() => {
    getBuilders();
    getCommunities();
    getGarageSwingTypes();
  }, []);

  const updateCommunityId = (communityId) => {
    const stateId = getStateFromSelectedCommunity(communityId);
    edit.update({ communityId, stateId });
    if (!communityId) {
      setPlansSearchTerm("");
    }
    const builderId = edit.getValue("builderId");
    if (builderId) {
      getPlans(builderId, communityId);
    }
  };

  const getStateFromSelectedCommunity = (communityId) => {
    if (communityId) {
      const id = Number(communityId);
      const selectedCommunity = communities.find(
        (community) => community.id === id
      );
      setStateShort(selectedCommunity?.stateNameShort);
      return selectedCommunity?.stateId;
    }

    setStateShort("");
    return undefined;
  };

  const updateBuilderId = (builderId) => {
    edit.update({ builderId });
    if (!builderId) {
      setPlansSearchTerm("");
    }
    const communityId = edit.getValue("communityId");
    if (communityId) {
      getPlans(builderId, communityId);
    }
  };

  const validateAddress = () => {
    setIsLoading(true);
    stateManager.run(async () => {
      const addressObj = {
        address1: edit.getValue("addressLine1"),
        address2: edit.getValue("addressLine2"),
        state: edit.getValue("state"),
        zipCode: edit.getValue("zipCode"),
      };

      const response = await propertyService.validateAddress(addressObj);
      if (response.data.addressFound !== true) {
        completeSave(edit.edits);
        return;
      }
      if (
        edit.getValue("addressLine1").toLowerCase() !=
          response.data.address1.toLowerCase() ||
        edit.getValue("stateId") != response.data.stateId ||
        edit.getValue("zipCode") != response.data.zipCode ||
        (response.data.address2 &&
          edit.getValue("addressLine2").toLowerCase() !=
            response.data.address2.toLowerCase())
      ) {
        validated.update({
          addressLine1: response.data.address1,
          addressLine2: response.data.address2,
          city: response.data.city,
          state: response.data.state,
          stateId: response.data.stateId,
          zipCode: response.data.zipCode,
        });
        setIsLoading(false);
        setIsValidateModalOpen(true);
      } else {
        completeSave(edit.edits);
      }
    });
  };

  const completeSave = (values) => {
    stateManager.run(async () => {
      const request = {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        cityId: values.cityId,
        stateId: values.stateId,
        zipCode: values.zipCode,
        communityId: values.communityId,
      };

      const isAddressDuplicate = await propertyService.checkDuplicateAddress(
        request
      );
      if (isAddressDuplicate.data) {
        createToast("The property already exists.", TOAST_TYPES.WARNING);
        setIsLoading(false);
        return;
      }
      const response = await propertyService.createNewProperty(values);
      if (response.status !== HTTP_STATUSES.OK) {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      router.navigate(`/homes/${response.data}`);
    });
  };

  const getFormattedAddress = (useValidated) => {
    if (useValidated) {
      return (
        `${validated.getValue("addressLine1")} ${
          validated.getValue("addressLine2") || ""
        },` +
        `${validated.getValue("city")}, ${validated.getValue(
          "state"
        )} ${validated.getValue("zipCode")}`
      );
    }
    return (
      `${edit.getValue("addressLine1")} ${
        edit.getValue("addressLine2") || ""
      },` + ` ${stateShort} ${edit.getValue("zipCode")}`
    );
  };

  const resetValues = () => {
    edit.reset();
    validated.reset();
    toggle();
  };

  const saveValidatedAddress = () => {
    if (useVerifiedAddress) {
      setIsLoading(true);
      const request = { ...edit.edits, ...validated.edits };
      completeSave(request);
      return;
    }
    setIsLoading(true);
    completeSave(edit.edits);
  };

  const addressNormalizationData = [
    {
      key: "newHomeModalEntered",
      id: "entered",
      label: "You Entered",
      value: getFormattedAddress(false),
      action: () => setUseVerifiedAddress(false),
      isChecked: !useVerifiedAddress,
    },
    {
      key: "newHomeModalValidated",
      id: "validated",
      label: "We Found",
      value: getFormattedAddress(true),
      action: () => setUseVerifiedAddress(true),
      isChecked: useVerifiedAddress,
    },
  ];

  const renderAddressNormalizationContent = () => {
    return (
      <div>
        <MiHeader
          color={theme.palette.primary.blue}
          title="Select address you would like to use:"
          inverse={false}
          icon="arrow_back"
          backAction={() => {
            validated.reset();
            setIsValidateModalOpen(false);
          }}
          style={{ fontSize: "15px", marginLeft: "-5px" }}
          outerContainerStyles={{ top: 0 }}
        />
        <ValidationRadioList data={addressNormalizationData} />
      </div>
    );
  };

  const onPlanSelectionChange = (value) => {
    edit.update({ planId: value?.id });
    setPlansSearchTerm(value?.name || "");
  };

  const setZip = (value) => {
    if (!checkIsNumber(value) && value.length <= 5) {
      edit.update({ zipCode: value });
    }
  };

  const modalFields = [
    {
      label: "Builder",
      value: edit.getValue("builderId"),
      options: builders,
      fieldType: "autoComplete",
      setValue: (_e, value) => updateBuilderId(value?.id),
      width: "100%",
      required: true,
    },
    {
      label: "Community",
      value: edit.getValue("communityId"),
      options: communities,
      fieldType: "autoComplete",
      setValue: (_e, value) => updateCommunityId(value?.id),
      width: "100%",
      required: true,
    },
    {
      label: "Plan",
      options: propertyPlans,
      fieldType: "autoComplete",
      value: edit.getValue("planId"),
      setValue: (_e, value) => onPlanSelectionChange(value),
      inputValue: plansSearchTerm,
      inputChange: (_e, value) => setPlansSearchTerm(value || ""),
      disabled: !edit.getValue("builderId"),
      width: "100%",
      required: true,
    },
    {
      label: "Address Line 1",
      value: edit.getValue("addressLine1"),
      required: true,
      setValue: (e) => edit.update({ addressLine1: e }),
      width: "50%",
    },
    {
      label: "Address Line 2",
      value: edit.getValue("addressLine2"),
      setValue: (e) => edit.update({ addressLine2: e }),
      width: "50%",
    },
    {
      label: "Zip",
      value: edit.getValue("zipCode"),
      required: true,
      setValue: setZip,
      width: "50%",
    },
    {
      label: "Elevation",
      value: edit.getValue("elevation"),
      setValue: (e) => edit.update({ elevation: e }),
      width: "50%",
    },
    {
      label: "Swing",
      value: edit.getValue("garageSwingTypeId"),
      options: garageSwingTypes,
      fieldType: "select",
      setValue: (e) => edit.update({ garageSwingTypeId: e }),
      width: "50%",
    },
  ];

  const isValid = () => {
    return (
      edit.allFilled(
        "builderId",
        "communityId",
        "planId",
        "stateId",
        "zipCode",
        "addressLine1"
      ) && edit.getValue("zipCode")?.length >= 5
    );
  };

  return (
    <MiModal
      width="90%"
      maxWidth={600}
      open={newPropertyInProgress}
      onClose={toggle}
      title={"Input Home Details"}
      loading={stateManager.isBusy()}
      actions={[
        {
          name: "Cancel",
          onClick: resetValues,
          style: { minWidth: 90 },
          color: theme.palette.primary.grey,
          inverse: false,
        },
        {
          name: "Save",
          onClick: isValidateModalOpen ? saveValidatedAddress : validateAddress,
          disabled: !isValid() || isLoading,
          style: { minWidth: 90 },
          color: theme.palette.primary.green,
          inverse: true,
        },
      ]}
    >
      {stateManager.statusTag("createPropertyStatus")}
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "stretch",
          height: "100%",
        }}
      >
        <div>
          {isValidateModalOpen ? (
            renderAddressNormalizationContent()
          ) : (
            <MiDetailFields detailFields={modalFields} />
          )}
        </div>
      </div>
    </MiModal>
  );
};

export default CreateNewPropertyModal;
