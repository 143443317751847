import React from "react";
import { useMiTimelineStyles } from "./MiTimelineStyles";
import { THEME } from "./theme";

const Chevron = (props) => {
  const { classes } = useMiTimelineStyles();

  let pickIndex = -1;
  let barWidth = null;

  if (props.picklist) {
    if (props.currentStatus) {
      pickIndex = props.picklist.findIndex(
        (item) => item.value == props.currentStatus
      );
    }
    barWidth = Math.floor((1 / props.picklist.length) * 100).toString();
    barWidth = barWidth + "%";
  }
  return (
    <div className={classes.path}>
      <div
        className={classes.pathTrack}
        style={{ display: "flex", flex: 1, padding: ".2rem .5rem" }}
      >
        <div
          style={{
            color: THEME.GREY_MEDIUM_ALT,
            fontSize: ".65rem",
            marginBottom: ".2rem",
          }}
        >
          {props.field}
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {props.picklist &&
            props.picklist.map((item, i) => (
              <div
                key={"key_" + i}
                className={
                  pickIndex >= i
                    ? `${classes.chevron} is-complete`
                    : `${classes.chevron} is-incomplete`
                }
                style={{
                  zIndex: i == 0 ? 100 : 100 - i * i,
                  width: barWidth ? barWidth : "20%",
                  backgroundColor: THEME.GREEN_PRIMARY,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: ".2rem",
                  borderTopLeftRadius: i == 0 ? 100 : null,
                  borderBottomLeftRadius: i == 0 ? 100 : null,
                  borderTopRightRadius:
                    i == props.picklist.length - 1 ? 100 : null,
                  borderBottomRightRadius:
                    i == props.picklist.length - 1 ? 100 : null,
                }}
              >
                {item.text || item.value}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Chevron;
