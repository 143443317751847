import { useState } from "react";
import { stageTypeService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const useStages = () => {
  const [stages, setStages] = useState([]);

  const getStages = async () => {
    const stages = await stageTypeService.getAll({
      sort: [{ field: "order", sort: "asc" }],
    });
    if (stages.status === HTTP_STATUSES.OK) {
      setStages(stages.data);
    }
  };

  return {
    stages,
    setStages,
    getStages,
  };
};
