import React from "react";
import { THEME, MiHeader } from "../../../components/Standard/MiView";
import { useRouter } from "@miview/hooks";
import { usePlan } from "../hooks";

const createFieldset = (plan = {}) => {
  let fieldSet = [];

  if (plan?.communityId) {
    fieldSet.push({
      label: "Community",
      value: plan?.community,
      to: `/communities/${plan?.communityId}`,
    });
  }

  if (plan?.builderId) {
    fieldSet.push({
      label: "Builder",
      value: plan?.builderName,
      to: `/builder/${plan?.builderId}`,
    });
  }

  return fieldSet;
};

export const MasterSpecsHeader = () => {
  const { goBack } = useRouter();
  const { plan } = usePlan();

  return (
    <MiHeader
      color={THEME.BLUE_PRIMARY}
      title={plan?.planName || "Master Specs Builder"}
      inverse={false}
      icon="arrow_back"
      backAction={goBack}
      fieldSet={createFieldset(plan)}
    />
  );
};
