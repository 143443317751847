import React, { useState } from "react";
import { accountService } from "@miview/api";
import { en, HTTP_STATUSES } from "@miview/constants";
import { formatDate } from "../../../../helpers/dateTools";
import {
  MiPageHeader,
  MiModal,
  MiInputTextbox,
  MiIcon,
  MiList,
} from "@miview/components";
import { useTheme } from "@mui/material/styles";
import { useEdit, useComponentState } from "@miview/hooks";
import { mdiHomeCity, mdiPencil } from "@mdi/js";

export const AccountManagement = () => {
  const [rows, setRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [refresh, setRefresh] = useState(false);
  const theme = useTheme();
  const edit = useEdit(selectedRow);
  const stateManager = useComponentState();

  const columns = [
    {
      field: "name",
      headerName: en.name,
      flex: 1,
    },
    {
      field: "description",
      headerName: en.description,
      flex: 1,
    },
    {
      field: "idPattern",
      headerName: en.idPattern,
      flex: 1,
    },
    {
      field: "lastModified",
      headerName: en.lastModified,
      flex: 1,
      valueGetter: ({ value }) => formatDate(value) || "--",
    },
  ];

  const fetchData = ({ ...params }) => {
    return stateManager.run(async () => {
      return accountService.accountTypes.getAll({ ...params });
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    edit.reset();
  };

  const handleAccountType = () => {
    const payload = { ...edit.edits };
    handleAdd(payload);
    setShowModal(false);
  };

  const handleAdd = async (value) => {
    const response = await accountService.accountTypes.update({
      id: selectedRow.id,
      data: value,
      successMessage: en.accountTypeEditedSuccessfully,
      failureMessage: en.theActionWasNotSuccessful,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setRefresh(!refresh);
      edit.reset();
    }
  };

  const actions = [
    {
      onClick: handleCloseModal,
      color: theme.palette.primary.grey,
      name: "Close",
      inverse: false,
    },
    {
      onClick: handleAccountType,
      color: theme.palette.primary.main,
      name: "Submit",
      inverse: true,
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon path={mdiHomeCity} size={1} color={theme.palette.primary.main} />
    );
  };

  const openEditModal = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const getRowActions = (row) => {
    return [
      {
        text: "EDIT",
        onClick: () => openEditModal(row),
        renderIcon: () => <MiIcon path={mdiPencil} size={"20px"} />,
      },
    ];
  };

  return (
    <>
      <MiPageHeader
        title="Account Types"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        loading={stateManager.isBusy()}
      />
      <MiList
        data={rows}
        dataProvider={fetchData}
        setData={setRows}
        columns={columns}
        hideFilters
        disableColumnMenu
        refresh={refresh}
        getRowActions={getRowActions}
      />
      <MiModal
        title={"Edit Account Type"}
        onClose={handleCloseModal}
        open={showModal}
        actions={actions}
      >
        <MiInputTextbox
          labelText="Account Type"
          value={edit.getValue("name")}
          handleChange={(e) => edit.update({ name: e.target.value })}
          required
          disabled
        />
        <MiInputTextbox
          labelText="Description"
          value={edit.getValue("description")}
          handleChange={(e) => edit.update({ description: e.target.value })}
          required
        />
        <MiInputTextbox
          labelText="Account Type Id Pattern"
          value={edit.getValue("idPattern")}
          handleChange={(e) => edit.update({ idPattern: e.target.value })}
          required
        />
      </MiModal>
    </>
  );
};
