import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
  return {
    detailContainer: {
      padding: "10px 20px",
      marginBottom: 10,
      borderBottomStyle: "solid",
      borderBottomSize: 1,
      borderBottomColor: theme.palette.primary.main,
    },
    headerColor: {
      fontSize: theme.fontSize.large,
      color: theme.palette.primary.main,
    },
    textColor: {
      fontSize: theme.fontSize.medium,
      color: theme.palette.primary.grey,
    },
  };
});

const getDetails = (selected) => {
  let details = "";
  if (selected.builderName) {
    details += selected.builderName;
  }
  if (selected.communityName) {
    details += ` - ${selected.communityName}`;
  }
  return details;
};

export const DetailHeader = (props) => {
  const { classes } = useStyles();
  const { selected } = props;
  if (!selected) {
    return null;
  }
  return (
    <Grid className={classes.detailContainer}>
      <Typography className={classes.headerColor}>
        {selected.addressLine1 || "Address not yet created or mapped"}
      </Typography>
      <Typography className={classes.textColor}>
        {getDetails(selected)}
      </Typography>
    </Grid>
  );
};
