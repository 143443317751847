import { useRouter } from "@miview/hooks";
import { usePlanOptions, useResetPage } from "./";
import { optionService, planService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const usePlanOptionUpdate = ({
  planOptionForm,
  setPlanOptionForm,
  setPlanOptions,
}) => {
  const {
    query: { planId },
  } = useRouter();
  const { resetForm } = usePlanOptions();
  const resetPage = useResetPage();
  const CUSTOM_OPTION_TYPE = 186;
  const saveDraft = async () => {
    try {
      const requestPayload = {
        name: planOptionForm.optionName,
        description: planOptionForm.optionDescription,
        planId,
        optionType: planOptionForm.optionTypeId || CUSTOM_OPTION_TYPE,
      };

      const response = await optionService.create(requestPayload);

      if (response.status === HTTP_STATUSES.OK) {
        setPlanOptionForm({
          ...planOptionForm,
          optionId: response.data,
        });

        return response.data;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const saveAreaActionAdd = async (id, areaEdits) => {
    try {
      const requestPayload = {
        optionId: id,
        action: "Add",
        floorId: areaEdits.floorId || "",
        name: areaEdits.planAreaName,
        propertySectionId: areaEdits.propertySectionId,
      };

      await optionService.createAreaRule(id, requestPayload);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const saveAreaActionRemove = async () => {
    const requestPayload = {
      optionId: planOptionForm.optionId,
      action: "Remove",
      planAreaId: planOptionForm.actionForm.planAreaId,
    };

    await optionService.createAreaRule(planOptionForm.optionId, requestPayload);

    updateOptionsAfterSubmission();
  };

  const saveAreaActionUpdate = async () => {
    const requestPayload = {
      optionId: planOptionForm.optionId,
      action: "Update",
      planAreaId: planOptionForm.actionForm.planAreaId,
    };

    await optionService.createAreaRule(planOptionForm.optionId, requestPayload);

    updateOptionsAfterSubmission();
  };

  const saveOption = async () => {
    try {
      const requestPayload = {
        id: planOptionForm.optionId,
        name: planOptionForm.optionName,
        description: planOptionForm.optionDescription,
      };

      await optionService.submit(requestPayload);

      const planOptions = await planService.getOptions(planId);
      if (planOptions.status === HTTP_STATUSES.OK) {
        setPlanOptions(planOptions.data);
      }

      resetPage();
      resetForm();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const updateOptionsAfterSubmission = async () => {
    const planOptions = await planService.getOptions(planId);

    setPlanOptions(planOptions.data);
  };

  return {
    saveOption,
    saveDraft,
    saveAreaActionAdd,
    saveAreaActionRemove,
    saveAreaActionUpdate,
    updateOptionsAfterSubmission,
  };
};
