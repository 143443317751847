import React, { useState, useEffect } from "react";
import { createFilter } from "@miview/utils";
import { pricingService } from "@miview/api";
import { HTTP_STATUSES, en } from "@miview/constants";
import { MiDrawer, MiTable, MiAlert, MiBox } from "@miview/components";

const columns = [
  {
    field: "itemName",
    headerName: en.itemNumber,
    flex: 1,
  },
  {
    field: "description",
    headerName: en.description,
    flex: 1,
  },
  {
    field: "currentQuotePrice",
    headerName: "Price",
    flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <div>{params.row.accountName}</div>
          <div>${params.value}</div>
          <div
            style={{
              color: params.row.percentDifference < 0 ? "red" : "green",
            }}
          >
            {isNaN(params.row.percentDifference)
              ? "--"
              : Math.abs(params.row.percentDifference).toFixed(2)}
            %
          </div>
        </div>
      );
    },
  },
];

export const PriceCompareDrawer = (props) => {
  const { open, itemId, itemNumber, comparePrice, onClose, ...rest } = props;

  const [rows, setRows] = useState([]);

  const getComparisons = async () => {
    if (!itemId) {
      return;
    }

    const filterBuilder = createFilter();
    filterBuilder.equals({ itemId });
    filterBuilder.greaterThanOrEqual({ currentQuotePrice: 0 });
    const response = await pricingService.quotes.getAccountItems({
      filters: filterBuilder.getFilter(),
      sort: [{ sort: "asc", field: "currentQuotePrice" }],
    });

    if (response.status === HTTP_STATUSES.OK) {
      for (const itemPrice of response.data) {
        itemPrice.percentDifference =
          100 *
          (1 - Number(itemPrice.currentQuotePrice) / Number(comparePrice));
      }
      setRows(response.data);
    }
  };

  useEffect(() => {
    getComparisons();
  }, [itemId]);

  return (
    <MiDrawer variant={"persistent"} anchor="right" open={open} {...rest}>
      <MiBox height={"95%"} padding={0}>
        <MiAlert
          open={true}
          icon={false}
          message={`Price Compare for ${itemNumber}`}
          onClose={onClose}
        />
        <MiTable columns={columns} rows={rows} />
      </MiBox>
    </MiDrawer>
  );
};
