import React, { useState } from "react";
import { MiTab } from "@miview/components";
import { FAILURE_REASONS_TABS } from "@miview/constants";
import { WalkFailuresTab } from "./WalkFailuresTab";
import { ItemFailuresTab } from "./ItemFailuresTab";

export const FailureReasons = () => {
  const [activeTab, setActiveTab] = useState(
    FAILURE_REASONS_TABS.WALK_FAILURES
  );

  const tabs = [
    {
      label: "Walks",
      value: FAILURE_REASONS_TABS.WALK_FAILURES,
    },
    {
      label: "Items",
      value: FAILURE_REASONS_TABS.ITEM_FAILURES,
    },
  ];

  const Tabs = () => {
    return (
      <div style={cardMain}>
        <MiTab tabs={tabs} currenttab={activeTab} onTabChange={setActiveTab} />
      </div>
    );
  };

  return (
    <div>
      {activeTab === FAILURE_REASONS_TABS.WALK_FAILURES && (
        <WalkFailuresTab tabs={Tabs} />
      )}
      {activeTab === FAILURE_REASONS_TABS.ITEM_FAILURES && (
        <ItemFailuresTab tabs={Tabs} />
      )}
    </div>
  );
};

const cardMain = {
  width: "100%",
  marginBottom: 20,
};
