import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const API_STATUSES = `${API_V3}/statuses`;

export const statusService = {
  getAll: async (params) => {
    const options = await apiOptions({ url: `${API_STATUSES}`, params });
    return apiRequest(options);
  },
};
