import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_ISSUES = `issues`;

export const issueService = {
  get: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/${API_ISSUES}/${id}`,
      method: "get",
    });
    return apiRequest(options);
  },

  getAll: async (params, filters, signal) => {
    const options = await apiOptions({
      url: `${API_V3}/${API_ISSUES}`,
      method: "get",
      params,
      filters,
      signal,
    });
    return apiRequest(options);
  },

  replace: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/${API_ISSUES}/${id}`,
      method: "post",
      data: request,
    });
    const result = await apiRequest(options);
    return result;
  },

  updateOptions: (id, data) => {
    return apiOptions({
      url: `${API_V3}/${API_ISSUES}/${id}`,
      method: "patch",
      data,
    });
  },

  update: async (id, data) => {
    const options = await issueService.updateOptions(id, data);
    return apiRequest(options);
  },

  delete: async (id) => {
    const options = await apiOptions({
      method: "DELETE",
      url: `${API_V3}/${API_ISSUES}/${id}`,
    });
    return apiRequest(options);
  },

  updateIssueByAltIdOptions: async (data) => {
    return apiOptions({
      method: "put",
      url: `${API_V3}/${API_ISSUES}/altId/${data.altId}`,
      data,
    });
  },

  deleteByAltIdOptions: (altId) => {
    return apiOptions({
      method: "DELETE",
      url: `${API_V3}/${API_ISSUES}/altId/${altId}`,
    });
  },

  deleteImageByAltIdOptions: (altId, fileName) => {
    return apiOptions({
      method: "DELETE",
      url: `${API_V3}/${API_ISSUES}/altId/${altId}/attachments/${fileName}`,
    });
  },

  getAssignablePersons: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/${API_ISSUES}/${id}/persons`,
    });
    return apiRequest(options);
  },
};
