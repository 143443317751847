import { apiOptions, apiRequest } from "../../utils";
import { API_HEADER, REQUISITON_SDERVICE_BASE_URL } from "../../constants";

const baseUrl = "";

export const requisitions = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions`,
      method: "get",
      headers: API_HEADER,
      params,
      filters,
      sort,
      signal,
    });
    return apiRequest(options);
  },

  get: async (requisitionId) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions/${requisitionId}`,
      method: "get",
      headers: API_HEADER,
    });
    return apiRequest(options);
  },

  update: async (requisitionId, data, successMessage, failureMessage) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions/${requisitionId}`,
      method: "patch",
      headers: API_HEADER,
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  create: async (data, successMessage, failureMessage) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions`,
      method: "post",
      headers: API_HEADER,
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  replace: async (reqId, dataReq, reqSuccess, reqFailure) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions/${reqId}`,
      method: "patch",
      headers: API_HEADER,
      data: dataReq,
    });
    const toastMessageConfig = {
      success: {
        message: reqSuccess,
      },
      failure: {
        message: reqFailure,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  delete: async (requisitionId, successMessage, failureMessage) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions/${requisitionId}`,
      headers: API_HEADER,
      method: "delete",
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  // REQUISITION ITEMS
  createItem: async (requisitionId, data, successMessage, failureMessage) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions/${requisitionId}/items`,
      method: "post",
      headers: API_HEADER,
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  updateItem: async (requisitionId, itemId, data) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions/${requisitionId}/items/${itemId}`,
      method: "patch",
      headers: API_HEADER,
      data: data,
    });
    return apiRequest(options);
  },

  replaceItem: async (requisitionId, itemId, data) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions/${requisitionId}/items/${itemId}`,
      method: "put",
      headers: API_HEADER,
      data: data,
    });
    return apiRequest(options);
  },

  deleteItem: async (requisitionId, itemId, successMessage, failureMessage) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions/${requisitionId}/items/${itemId}`,
      method: "delete",
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };

    return apiRequest(options, toastMessageConfig);
  },

  getAllItems: async (requisitionId) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions/${requisitionId}/items`,
      method: "get",
      headers: API_HEADER,
    });
    return apiRequest(options);
  },

  getItem: async (requisitionId, itemId) => {
    const options = await apiOptions({
      url: `${REQUISITON_SDERVICE_BASE_URL}${baseUrl}/requisitions/${requisitionId}/items/${itemId}`,
      method: "get",
      headers: API_HEADER,
    });
    return apiRequest(options);
  },
};
