import { apiOptions, apiRequest } from "../../utils";
import { API_HEADER, PRICINGSERVICE_BASE_URL, API } from "../../constants";

export const quoteAttachments = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quoteattachments`,
      method: "get",
      headers: API_HEADER,
      params,
      filters,
      sort,
      signal,
    });
    return apiRequest(options);
  },

  update: async ({ id, request, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quoteattachments/${id}`,
      method: "patch",
      data: request,
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  delete: async ({ id, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quoteattachments/${id}`,
      method: "delete",
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
};
