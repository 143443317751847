import React from "react";
import { tradeService } from "@miview/api";
import { MiModal, MiDetailFields } from "@miview/components";
import { useTheme } from "@mui/material";
import { useEdit, useComponentState } from "@miview/hooks";
import { HTTP_STATUSES } from "@miview/constants";

export const CreateEditTradeModal = ({ onClose, open, selection }) => {
  const theme = useTheme();
  const edit = useEdit(selection);
  const stateManager = useComponentState();

  const createTrade = async () => {
    stateManager.run(async () => {
      const response = await tradeService.create({
        request: edit.edits,
      });
      if (response.status === HTTP_STATUSES.OK) {
        onModalClose();
      }
    });
  };

  const editTrade = async () => {
    stateManager.run(async () => {
      const response = await tradeService.update({
        id: selection.id,
        request: edit.edits,
      });
      if (response.status === HTTP_STATUSES.OK) {
        onModalClose();
      }
    });
  };

  const onModalClose = () => {
    edit.reset();
    onClose();
  };

  const tradeFields = [
    {
      label: "Trade Name",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "100%",
    },
  ];

  return (
    <MiModal
      title={selection.id ? "Edit Trade" : "Add Trade"}
      open={open}
      onClose={onModalClose}
      actions={[
        {
          name: "Cancel",
          style: { minWidth: 90 },
          onClick: onModalClose,
          color: theme.palette.medium.grey,
          inverse: false,
        },
        {
          name: "Save",
          onClick: selection.id ? editTrade : createTrade,
          style: { minWidth: 90 },
          color: theme.palette.primary.green,
          inverse: true,
          disabled: !edit.allFilled("name"),
        },
      ]}
    >
      <MiDetailFields detailFields={tradeFields} />
    </MiModal>
  );
};
