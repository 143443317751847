import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useRouter } from "@miview/hooks";
import { planService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

const ACTION_FORM_INITIAL_STATE = {
  actionStep: 1,
  planAreaName: "",
  propertySectionId: "",
  floorId: "",
  isGroundFloor: "",
  planAreaId: "",
};

const MISC_ITEM_FORM_INITIAL_STATE = {
  itemName: "",
  description: "",
  walkTypeId: "",
  topImageUrl: "",
  frontImageUrl: "",
  materialId: "",
  id: "",
};

const OPTIONS_FORM_INITIAL_STATE = {
  optionId: null,
  optionName: "",
  optionDescription: "",
  optionTypeId: "",
  optionAction: "",
  isDraft: true,
  actions: [],
  actionForm: ACTION_FORM_INITIAL_STATE,
  miscItemForm: MISC_ITEM_FORM_INITIAL_STATE,
};

const INITIAL_STATE = {
  planOptions: [],
  setPlanOptions: () => null,
  walkTypes: [],
  setWalkTypes: () => null,
  optionTypes: [],
  setOptionTypes: () => null,
  optionsToggledOn: [],
  updateTogglesOn: () => null,
  clearToggles: () => null,
  optionConfiguring: 0,
  setOptionConfiguring: () => null,
  planOptionForm: OPTIONS_FORM_INITIAL_STATE,
  setPlanOptionForm: () => null,
  resetForm: () => null,
  updateForm: () => null,
  activateNewForm: () => null,
  editOption: () => null,
  saveDisabled: () => null,
  refreshPlanOptions: () => null,
};

export const PlanOptionsContext = React.createContext({
  ...INITIAL_STATE,
});

export const PlanOptionsProvider = ({ children }) => {
  const {
    query: { planId },
  } = useRouter();
  const [planOptions, setPlanOptions] = useState(INITIAL_STATE.planOptions);
  const [walkTypes, setWalkTypes] = useState(INITIAL_STATE.walkTypes);
  const [optionTypes, setOptionTypes] = useState(INITIAL_STATE.optionTypes);
  const [optionsToggledOn, setOptionTogglesOn] = useState(
    INITIAL_STATE.optionsToggledOn
  );
  const [optionConfiguring, setOptionConfiguring] = useState(
    INITIAL_STATE.optionConfiguring
  );
  const [planOptionForm, setPlanOptionForm] = useState(
    OPTIONS_FORM_INITIAL_STATE
  );

  const clearToggles = useCallback(() => {
    setOptionTogglesOn([]);
  }, []);

  const updateTogglesOn = (optionId, active) => {
    let newOptions = [...optionsToggledOn];

    if (active) {
      newOptions = newOptions.concat([optionId]);

      setOptionTogglesOn(newOptions);
    } else {
      newOptions = newOptions.filter((option) => option !== optionId);

      setOptionTogglesOn(newOptions);
    }

    return newOptions;
  };

  const resetForm = useCallback(() => {
    setPlanOptionForm(OPTIONS_FORM_INITIAL_STATE);
  }, []);

  const updateForm = useCallback(
    (e) => {
      setPlanOptionForm({
        ...planOptionForm,
        [e.target.name]: e.target.value,
      });
    },
    [planOptionForm]
  );

  const updateMiscItem = (e) => {
    if (e.target) {
      return setPlanOptionForm({
        ...planOptionForm,
        miscItemForm: {
          ...planOptionForm.miscItemForm,
          [e.target.name]: e.target.value,
        },
      });
    }

    setPlanOptionForm({
      ...planOptionForm,
      miscItemForm: {
        ...planOptionForm.miscItemForm,
        ...e,
      },
    });
  };

  const activateNewForm = useCallback(() => {
    setPlanOptionForm({
      ...OPTIONS_FORM_INITIAL_STATE,
      optionId: 0,
    });
  }, []);

  const editOption = (option) => {
    const editForm = {
      ...OPTIONS_FORM_INITIAL_STATE,
      optionId: option.optionId,
      optionName: option.optionName,
      optionDescription: option.description,
      optionTypeId: option.optionTypeId,
      isDraft: option.isDraft,
      actions: option.actions || [],
      optionAction: option.actions[0]?.type || "",
      actionForm: {
        ...ACTION_FORM_INITIAL_STATE,
        actionStep: option.actions.length + 1,
      },
      miscItemForm: MISC_ITEM_FORM_INITIAL_STATE,
    };

    switch (option.actions?.[0]?.type) {
      case "Add":
        editForm.actionForm = {
          ...editForm.actionForm,
          planAreaName: option.actions[0].name,
          propertySectionId: option.actions[0].propertySectionId,
          floorId: option.actions[0].floorId,
        };
        break;
      case "Remove":
        editForm.actionForm = {
          ...editForm.actionForm,
          planAreaId: option.actions[0].planAreaId,
          planAreaName: option.actions[1]?.name || "",
          propertySectionId: option.actions[1]?.propertySectionId || "",
          floorId: option.actions[1]?.floorId || "",
        };
        break;
      case "Update":
        editForm.actionForm = {
          ...editForm.actionForm,
          planAreaId: option.actions[0].planAreaId,
        };

        editForm.miscItemForm = {
          ...editForm.miscItemForm,
          ...option.items[0],
          itemName: option.items[0]?.itemText || option.optionName,
          description: option.items[0]?.description || option.description,
          topImageUrl: option.items[0]?.topImageOverride,
          frontImageUrl: option.items[0]?.frontImageOverride,
        };
        break;
      default:
    }

    setPlanOptionForm(editForm);
  };

  const saveDisabled = (miscForm) => {
    if (
      !planOptionForm.optionName ||
      !planOptionForm.optionDescription ||
      !planOptionForm.optionTypeId ||
      !planOptionForm.actions.every((action) => action.isComplete)
    ) {
      return true;
    }

    if (miscForm) {
      if (planOptionForm.miscItemForm.itemName) {
        if (
          !planOptionForm.miscItemForm.description ||
          !planOptionForm.miscItemForm.walkTypeId
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const refreshPlanOptions = async () => {
    const planOptions = await planService.getOptions(planId);
    if (planOptions.status === HTTP_STATUSES.OK) {
      setPlanOptions(planOptions.data);
    }

    if (planOptionForm.optionId) {
      const option = planOptions.data.find(
        (option) => option.optionId === planOptionForm.optionId
      );

      if (option) {
        editOption(option);
      }
    }
  };

  const contextValue = {
    planOptions,
    setPlanOptions,
    walkTypes,
    setWalkTypes,
    optionTypes,
    setOptionTypes,
    optionsToggledOn,
    updateTogglesOn,
    clearToggles,
    optionConfiguring,
    setOptionConfiguring,
    planOptionForm,
    setPlanOptionForm,
    resetForm,
    updateForm,
    activateNewForm,
    editOption,
    saveDisabled,
    // handleSetOptionOn,
    refreshPlanOptions,
    updateMiscItem,
  };

  return (
    <PlanOptionsContext.Provider value={contextValue}>
      {children}
    </PlanOptionsContext.Provider>
  );
};

PlanOptionsProvider.propTypes = {
  children: PropTypes.object,
};
