import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const accountServiceLegacy = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });
    return apiRequest(options);
  },

  getAccount: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${id}`,
      method: "get",
    });
    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${id}`,
      method: "patch",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving account",
      },
      success: {
        message: "Account saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  getPricing: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${id}/pricing`,
      method: "get",
    });
    return apiRequest(options);
  },

  createPricing: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${id}/pricing`,
      method: "post",
      data: request,
    });
    return apiRequest(options);
  },

  addCommunity: async (id, communityId) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${id}/communities`,
      data: { communityId },
      method: "post",
    });
    return apiRequest(options);
  },

  getCommunities: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${id}/communities`,
      method: "get",
    });
    return apiRequest(options);
  },

  getContacts: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${id}/contacts`,
      method: "get",
    });
    return apiRequest(options);
  },

  getDefaults: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${id}/defaults`,
      method: "get",
    });
    return apiRequest(options);
  },

  addDefault: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${id}/defaults`,
      data: request,
      method: "post",
    });
    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts`,
      data: request,
      method: "post",
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving account",
      },
      success: {
        message: "Account saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  getTitles: async ({ id, filters, sort, params, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${id}/titles`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });
    return apiRequest(options);
  },

  createTitle: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${id}/titles`,
      method: "post",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving title",
      },
      success: {
        message: "Title saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  replaceTitle: async (accountId, titleId, request) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${accountId}/titles/${titleId}`,
      method: "post",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving title",
      },
      success: {
        message: "Title saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  updateTitle: async (accountId, titleId, request) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${accountId}/titles/${titleId}`,
      method: "patch",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving title",
      },
      success: {
        message: "Title saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  deleteTitle: async (accountId, titleId) => {
    const options = await apiOptions({
      url: `${API_V3}/accounts/${accountId}/titles/${titleId}`,
      method: "delete",
    });

    return apiRequest(options);
  },
};
