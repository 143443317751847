import { navigate, bugsnag } from "@miview/utils";
import { createToast } from "@miview/toast";
import { TOAST_TYPES } from "@miview/constants";
import { SCREENS, ROUTES } from "@miview/constants";

const reportIssue = (error, request, toastMessage) => {
  const errorBody =
    (error?.response?.data?.errors &&
      Object.values(error.response.data.errors).join(" | ")) ||
    error?.response?.data?.errorBody ||
    "An error occured while updating or retrieving data";
  const errorObj = {
    request: error?.response?.request,
    message: error?.response?.data?.errorBody,
    endpoint: request?.url,
    error: true,
    code: error?.code,
    status: error?.response?.status,
    statusCode: error?.response?.status,
    errorBody,
  };

  if (process.env.NODE_ENV === "development") {
    console.log("Network Error: ", errorObj);
  }

  if (!toastMessage?.hideMessage) {
    createToast(
      (toastMessage?.message ? toastMessage.message + ", " : "") +
        errorObj.errorBody,
      TOAST_TYPES.ERROR
    );
  }

  bugsnag.notify(
    new Error("Error Request:  " + JSON.stringify(errorObj, null, 2))
  );
  return errorObj;
};

const handle401 = () => {
  //TODO: wire up toast messages
  //createToast("Session expired. Please log in.", "");
  navigate(ROUTES.LOGIN, { screen: SCREENS.LOGIN });
};

export const handleError = (error, request, toastMessage = null) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    const { status } = error.response;

    if (status === 401) {
      handle401();
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
  }

  return reportIssue(error, request, toastMessage);
};
