import { accounts } from "./accounts";
import { contactTypes } from "./contactTypes";
import { accountTypes } from "./accountTypes";
import { accountStatuses } from "./accountStatuses";
import { paymentTerms } from "./paymentTerms";
import { deliveryLocations } from "./deliveryLocations";
import { contacts } from "./contacts";
import { itemCost } from "./ItemCost";
export * from "./accountServiceLegacy";

export const accountService = {
  accounts,
  contactTypes,
  accountStatuses,
  paymentTerms,
  accountTypes,
  deliveryLocations,
  contacts,
  itemCost,
};
