import React from "react";
import { makeStyles } from 'tss-react/mui';

const areaItemAddStyles = {
  areaItemBoxAdd: {
    width: "100%",
    borderRadius: 5,
    display: "flex",
  },
};
const useAreaItemAddStyles = makeStyles()(areaItemAddStyles);

export const AreaItemAdd = (props) => {
  const { classes } = useAreaItemAddStyles();
  return <div className={classes.areaItemBoxAdd}>{props.children}</div>;
};
