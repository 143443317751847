import React, { useEffect } from "react";
import moment from "moment";
import { MiCard } from "@miview/components";
import { THEME } from "../../../components/Standard/theme";
import { usePropertyActivities } from "./usePropertyActivities";
import { useRouter } from "@miview/hooks";
import { mdiHome, mdiWrench, mdiClock, mdiClipboardClock } from "@mdi/js";

const PropertyActivity = (props) => {
  const { propertyId, filterBy, relatedEntityId } = props;

  const { activities, status, handleGetActivities } = usePropertyActivities(
    propertyId,
    filterBy,
    relatedEntityId
  );
  const { navigate } = useRouter();

  useEffect(() => {
    handleGetActivities();
  }, []);

  const getIcon = (type) => {
    let icon = "";
    if (type == "Property") {
      icon = mdiHome;
    } else if (type == "Stage") {
      icon = mdiWrench;
    } else if (type == "Extension") {
      icon = mdiClock;
    } else if (type == "Walk" || type == "Job") {
      icon = mdiClipboardClock;
    }
    return icon;
  };

  const handleNavigate = (type, referenceId) => {
    let redirectUrl = "";

    if (type == "Walk" || type == "Job") {
      redirectUrl = "/walks/" + referenceId;
    } else if (type == "Stage" || type == "StageStatusUpdate") {
      redirectUrl = "/stages/" + referenceId;
    } else if (type == "Extension") {
      return;
    } else if (type == "Property") {
      return;
    }
    navigate(redirectUrl);
  };

  if (status) {
    return status;
  }

  return (
    <div>
      {activities.length > 0 ? (
        activities.map((item, index) => {
          return (
            <div key={"key_" + index} style={{ marginBottom: 10 }}>
              <MiCard
                title={item.details}
                icon={getIcon(
                  item.relatedEntityTypeValue || item.activityTypeValue
                )}
                color={THEME.PURPLE_SECONDARY}
                onClick={() => {
                  const itemType =
                    item.relatedEntityTypeValue || item.activityTypeValue;
                  handleNavigate(itemType, item.referenceId);
                }}
                fieldset={[
                  { label: "", value: item.activityUserName },
                  { label: "", value: moment(item.activityDate).calendar() },
                ]}
              />
            </div>
          );
        })
      ) : (
        <div style={{ paddingTop: 10 }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              backgroundColor: THEME.GREY_LIGHT_BACKGROUND,
              padding: 8,
              borderRadius: 9,
              color: THEME.GREY_MEDIUM,
            }}
          >
            No activity history available
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyActivity;
