import React, { useState, useEffect } from "react";
import { MiHeader } from "../../../components/Standard/MiView";
import { MiTransferList, MiDetailFields } from "@miview/components";
import { THEME } from "../../../components/Standard/theme";
import { useRoleDetailsStyles } from "./RoleDetailsStyles";
import { roleService, permissionService } from "@miview/api";
import { useRouter, useEdit, useComponentState } from "@miview/hooks";
import { createUUID } from "@miview/utils";
import { MiSnackbar } from "@miview/components";

const roleDetailsUuid = createUUID();

const RoleDetails = () => {
  const [role, setRole] = useState({});
  const [assignedPermissions, setAssignedPermissions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const classes = useRoleDetailsStyles();
  const router = useRouter();
  const edit = useEdit(role);
  const stateManager = useComponentState();
  const { id: roleId } = router.params;

  useEffect(() => {
    getUserRoleAndAllPermissions();
  }, []);

  const getUserRoleAndAllPermissions = async () => {
    stateManager.abort(roleDetailsUuid);
    stateManager.run(async (signal) => {
      const roleResponse = await roleService.get({ id: roleId, signal });
      const permissionsResponse = await permissionService.getAll({ signal });
      const responseOrder = permissionsResponse.data
        .filter(
          (e) => !roleResponse.data?.permissions.map((e) => e.id).includes(e.id)
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      setPermissions(responseOrder);
      setRole({ ...roleResponse.data, availablePermissions: responseOrder });
      setAssignedPermissions(roleResponse.data.permissions);
    }, roleDetailsUuid);
  };

  const saveRole = () => {
    stateManager.run(async () => {
      let roleObj = {
        Name: edit.getValue("roleName"),
        Description: edit.getValue("description"),
        Active: edit.getValue("active"),
        PermissionIds: assignedPermissions.map((e) => e.id),
      };

      await roleService.update(roleId, roleObj);
      await getUserRoleAndAllPermissions();
      setEditMode(false);
    });
  };

  const resetRole = () => {
    edit.reset();
    setAssignedPermissions(role.permissions);
    setPermissions(edit.getValue("availablePermissions"));
    setEditMode(false);
  };

  const toggleEdit = () => {
    setEditMode(!editMode);
  };
  const detailFields = [
    {
      label: "Role Name",
      value: edit.getValue("roleName"),
      readOnly: !editMode,
      required: true,
      clickButton: toggleEdit,
      setValue: (e) => edit.update({ roleName: e }),
      width: "50%",
    },
    {
      label: "Active",
      fieldType: "checkbox",
      value: edit.getValue("active"),
      readOnly: !editMode,
      clickButton: toggleEdit,
      setValue: (e) => edit.update({ active: e }),
      width: "50%",
    },

    {
      label: "Description",
      value: edit.getValue("description"),
      readOnly: !editMode,
      required: true,
      clickButton: toggleEdit,
      setValue: (e) => edit.update({ description: e }),
      width: "100%",
    },
    {
      label: "Updated By",
      value: edit.getValue("updatedBy"),
      readOnly: true,
      width: "50%",
    },
    {
      label: "Updated On",
      value: edit.getValue("updatedTime"),
      readOnly: true,
      width: "50%",
    },
  ];

  return (
    <div className="animated fadeIn">
      {stateManager.statusTag("roleDetailStatus")}
      <MiHeader
        color={THEME.BLUE_PRIMARY}
        backAction={router.goBack}
        inverse={false}
        icon="arrow_back"
        title="Role Details"
      />
      <div className={classes.frame}>
        <div style={THEME.BOX_MAIN}>
          <div>
            <MiDetailFields
              detailFields={detailFields}
              headerProps={{
                icon: "list",
                title: "Detail Fields",
              }}
            />
            <div className={classes.contentSection}>
              <MiTransferList
                id="assigned-permissions"
                headerLeft="Available Permissions"
                headerRight="Assigned Permissions"
                rightHandler={setAssignedPermissions}
                leftHandler={setPermissions}
                listItemsLeft={permissions}
                listItemsRight={assignedPermissions}
                readOnly={!editMode}
                onClick={() => setEditMode(true)}
              />
            </div>

            <MiSnackbar
              visible={editMode}
              handleCancelClick={resetRole}
              handleSaveClick={saveRole}
              disableSave={
                !edit.getValue("roleName") || !edit.getValue("description")
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleDetails;
