import React, { useState, useEffect, useRef } from "react";
import { Divider, Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  MiChip,
  MiPageHeader,
  MiDrawer,
  MiLoader,
  MiSnackbar,
  MiModalConfirm,
  MiIcon,
} from "@miview/components";
import { mdiCardTextOutline, mdiNoteTextOutline } from "@mdi/js";
import { purchaseOrderService, accountService } from "@miview/api";
import { createToast } from "@miview/toast";
import { en, TOAST_TYPES, ORDER_STATUS } from "@miview/constants";
import { useEdit, useRouter } from "@miview/hooks";
import ReceivingPOForm from "./ReceivingPOForm";
import ReceivingPOFormTable from "./ReceivingPOFormTable";
import CreatePurchaseOrder from "./CreatePurchaseOrder";
import SendEmail from "./SentEmail";

const useStyles = makeStyles()((theme) => ({
  iconHeading: {
    display: "flex",
    alignItems: "center",
  },
  chipDetails: {
    fontSize: theme.fontSize.xsmall,
    color: theme.palette.secondary.bluegrey,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 3,
    height: 20,
    fontWeight: theme.fontWeight.medium,
  },
  iconStyle: {
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: 10,
    background: theme.palette.primary.main,
  },
  headerDetails: {
    marginBottom: 10,
  },
  floatingAction: {
    position: "fixed",
  },
  form: {
    marginTop: 10,
  },
  tableStyle: {
    marginTop: 10,
  },
  root: {
    marginRight: 50,
    padding: "10px 20px 0px 20px",
  },
  drawerWidthStyle: {
    width: "100%",
  },
  loadingStyle: {
    top: "50%",
    left: "50%",
    width: "100%",
    position: "relative",
  },
}));

const requiredFields = ["receiveNotes"];

const Receiving = ({
  handleCancel,
  handleDrawerCancel,
  id,
  accountId,
  refreshData,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [confirmModal, setConfirmModal] = useState({ open: false });
  const [newPoModal, SetNewPoModal] = useState({ open: false });
  const [tableData, setTableData] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [showEmailEditor, setShowEmailEditor] = useState({ open: false });
  const [loading, setLoading] = useState(true);
  const [isMissingRequiredField, setIsMissingRequiredField] = useState(false);
  const actionRef = useRef(null);
  const itemActionsRef = useRef(null);
  const purchaseOrder = useEdit({});
  const [poFinalData, setPoFinalData] = useState([]);
  const router = useRouter();

  const handleReceiveOrderChange = (row, value = 0) => {
    setTableData(() => {
      return tableData.map((td) => {
        if (row.id === td.id) {
          const quantityReceived = parseInt(value) || 0;
          const action = actionRef.current || "";
          const diff = td.quantityOrdered - quantityReceived;
          return {
            ...td,
            quantityReceived,
            action: diff ? action : "",
            diff: diff,
          };
        }
        return td;
      });
    });
  };

  const createBackOrderOrCancel = async (
    purchaseOrderData,
    purchaseOrderItems,
    status
  ) => {
    const CancelOrderNumber = Math.floor(
      crypto.getRandomValues(new Uint32Array(1))[0]
    );
    const newOrder = await purchaseOrderService.purchaseOrder.create({
      data: {
        ...purchaseOrderData,
        status,
        number:
          status === ORDER_STATUS.BACKORDER
            ? purchaseOrderData.number
            : CancelOrderNumber,
      },
      failureMessage: en.errorBody,
    });

    const poItemsPromises = purchaseOrderItems.map((item) => {
      const diff =
        status === ORDER_STATUS.BACKORDER
          ? item.quantityOrdered - item.quantityReceived
          : item.quantityOrdered;
      return purchaseOrderService.purchaseOrder.createPurchaseOrderItem({
        id: newOrder.data.id,
        data: {
          ...item,
          quantityOrdered: diff,
          purhaseOrderId: newOrder.data.id,
        },
        failureMessage: en.errorBody,
      });
    });
    if (status === ORDER_STATUS.CANCEL) {
      await deletePoItem(purchaseOrderData, purchaseOrderItems);
    }
    await Promise.all([...poItemsPromises]);
  };

  const deletePoItem = async (purchaseOrderData, purchaseOrderItems) => {
    const poItemsPromises = purchaseOrderItems.map((item) => {
      return purchaseOrderService.purchaseOrder.deletePurchaseOrderItem({
        id: purchaseOrderData.id,
        poItemId: item.id,
        failureMessage: en.theActionWasNotSuccessful,
      });
    });
    await Promise.all(poItemsPromises);
  };

  const updatePoReceived = async (purchaseOrderData, purchaseOrderItems) => {
    const poItemsPromises = purchaseOrderItems.map((item) => {
      return purchaseOrderService.purchaseOrder.replacePurchaseOrderItem({
        id: purchaseOrderData.id,
        poItemId: item.id,
        data: item,
        failureMessage: en.theActionWasNotSuccessful,
      });
    });
    const poUpdatePromise = purchaseOrderService.purchaseOrder.update({
      id,
      data: { status: "Received" },
      successMessage: en.purchaseOrderReceivedSuccessfully,
      failureMessage: en.theActionWasNotSuccessful,
    });

    await Promise.all([...poItemsPromises, poUpdatePromise]);
  };

  const handleFormDataChange = (field, value) => {
    purchaseOrder.update({ [field]: value });
  };
  const handleActionChange = (row, e) => {
    e.stopPropagation();
    setTableData(() => {
      return tableData.map((td) => {
        if (row.id === td.id) {
          return { ...td, action: e.target.value };
        }
        return td;
      });
    });
  };

  const handleReceiveAll = () => {
    actionRef.current = "";
    setTableData(() => {
      return tableData.map((td) => {
        return {
          ...td,
          quantityReceived: td.quantityOrdered,
          action: "",
          diff: 0,
        };
      });
    });
  };
  const fetchContacts = async (params = {}, filterParams = {}) => {
    const list = await accountService.accounts.getContacts(
      accountId,
      params,
      filterParams
    );
    const receivedData = [];
    if (list.data) {
      list.data.reduce((res, item) => {
        res.push({
          label: item.displayName || "",
          value: item.id || 0,
        });
        return res;
      }, receivedData);
      setContactsList(receivedData);
    }
  };

  const fetchLocations = async () => {
    const list = await accountService.deliveryLocations.getAll();
    const locationData = [];
    if (list.data) {
      list.data.reduce((res, item) => {
        res.push({
          label: item.name || "",
          value: item.id || 0,
        });
        return res;
      }, locationData);
      setLocationList(locationData);
      setLoading(false);
    }
  };

  const groupByCategoryId = (requisitions, categoryID) => {
    return requisitions.reduce((acc, obj) => {
      const key = obj[categoryID];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  function uniqByAccountId(arr, callback) {
    const mapObj = {};
    const uniques = [];

    for (const ele of arr) {
      const result = callback(ele);
      if (mapObj[result] !== true) {
        mapObj[result] = true;
        uniques.push(ele);
      }
    }
    return uniques;
  }

  const fetchData = async (params = {}, filterParams = {}) => {
    Promise.all([
      await purchaseOrderService.purchaseOrder.get(id),
      await purchaseOrderService.purchaseOrder.getAllPurchaseOrderItems(
        params,
        filterParams,
        id
      ),
    ]).then((results) => {
      const order = results[0];
      const orderItems = results[1];
      if (order?.data) {
        purchaseOrder.update({ data: order.data });
      }

      if (orderItems?.data) {
        const mapData = orderItems.data.map((d) => ({
          ...d,
          action: "",
          categoryId: "",
          categoryName: "categoryName ",
          diff: 0,
        }));

        setTableData(mapData);
      }
    });
    fetchContacts();
    fetchLocations();
  };

  const getPoData = (categoryWiseMapping, itemCost, accounts, poAccId) => {
    const finalPoObj = [];
    categoryWiseMapping.forEach((category) => {
      const catId = category.CatId;
      const catName = category.CatName;
      const reqAggsByItem = groupByCategoryId(category.ItemsInfo, "itemId");
      const keysItem = Object.keys(reqAggsByItem);
      const itemArray = [];
      keysItem.forEach((key) => {
        let difference = reqAggsByItem[key].reduce((previous, current) => {
          return previous + current.diff;
        }, 0);
        let onHand = "0";
        itemArray.push({
          id: key,
          itemid: key,
          itemname: reqAggsByItem[key][0].itemName,
          itemdescription: reqAggsByItem[key][0].description,
          itemNotes: reqAggsByItem[key][0].notes,
          itemUnit: reqAggsByItem[key][0].unit,
          itemSKU: reqAggsByItem[key][0].sku,
          quantity: difference || reqAggsByItem[key][0].quantityOrdered,
          onhand: onHand,
          incoming: "0",
          accounts: accounts.map((myacc) => {
            let accWiseItemCost = itemCost.find(
              (ic) => ic.accountId === myacc.id && ic.itemId === parseInt(key)
            );
            const currentcost = accWiseItemCost?.currentCost;
            const orderedQuantity = myacc.id === poAccId ? difference : 0;
            return {
              uid: `${category.CatId}_${key}_${myacc.id}`,
              title: myacc.name,
              accid: myacc.id,
              currentcost: currentcost,
              change: accWiseItemCost?.percentChange,
              orderedqty: orderedQuantity,
              totalsum: orderedQuantity * currentcost,
              isPreferredVendor: myacc.id === poAccId ? true : false,
            };
          }),
        });
      });

      finalPoObj.push({
        categoryid: catId,
        categoryname: catName,
        data: itemArray,
      });
    });
    return finalPoObj;
  };

  const newPoUpdates = (poAccountId, newPoData = []) => {
    const params = {};
    Promise.all([
      accountService.itemCost.getAll({ pageSize: 250 }, {}, 1),
      accountService.accounts.getAll(params, { parentId: null, statusId: 1 }),
    ]).then((results) => {
      const itemCost = results[0].data;
      const top5Accounts = results[1].data.slice(0, 5).map((a) => a.id);
      const firstaccount = results[1].data.filter((a) => a.id === poAccountId);
      const accounts = uniqByAccountId(
        firstaccount.concat(
          results[1].data.filter((a) => top5Accounts.includes(a.id))
        ),
        (account) => account.id
      );
      const reqAggsByCategory = groupByCategoryId(newPoData, "categoryId");
      const keys = Object.keys(reqAggsByCategory);
      const categoryWiseMapping = [];
      keys.forEach((key) => {
        categoryWiseMapping.push({
          CatId: key,
          CatName: reqAggsByCategory[key][0].categoryName,
          ItemsInfo: [...reqAggsByCategory[key]],
        });
      });
      const finalPoData = getPoData(
        categoryWiseMapping,
        itemCost,
        accounts,
        poAccountId
      );
      setPoFinalData(finalPoData);
      SetNewPoModal({ open: true });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderLeftIcon = () => {
    return (
      <Grid className={classes.iconStyle}>
        <MiIcon
          path={mdiCardTextOutline}
          size={1.5}
          color={theme.palette.primary.white}
        />
      </Grid>
    );
  };

  const poDateFormat = (podate) => {
    const date = new Date(podate);
    return date.toDateString();
  };

  const handleSentEmail = (poData, title, itemActions) => {
    const handleOnBack = () => {
      setShowEmailEditor({ open: false });
    };
    const handleOnSave = () => {
      setShowEmailEditor({ open: false });
      refreshData();
      handleCancel();
      handleDrawerCancel();
      if (itemActions.Cancel.length) {
        createToast(en.purchaseOrderCancelled, TOAST_TYPES.ERROR);
      }
    };
    if (itemActions.Cancel.length) {
      setShowEmailEditor({
        open: true,
        handleOnBack,
        handleOnSave,
        title: title,
        stepLabelData: title.map((PreviewTitle) => {
          return {
            ...poData,
            PreviewTitle: PreviewTitle,
          };
        }),
        isEmailMessage: false,
        handleOnSaveManually: handleOnSave,
      });
    } else if (itemActions.Backorder.length) {
      refreshData();
      handleCancel();
      handleDrawerCancel();
      createToast(en.purchaseOrderSetToBackOrder, TOAST_TYPES.WARNING);
    }
  };

  const handleNewPoSave = (callbackUrl) => {
    try {
      const itemActions = itemActionsRef.current;
      const cancelledBackOrders = [];

      if (itemActions.Backorder.length) {
        createBackOrderOrCancel(
          purchaseOrder.edits,
          itemActions.Backorder,
          "Backorder"
        );
      }

      if (itemActions.Cancel.length) {
        const cancelTitle = `${en.cancelPo}| ${
          purchaseOrder.edits.number
        } | ${poDateFormat(purchaseOrder.edits.issueDate)}`;
        createBackOrderOrCancel(
          purchaseOrder.edits,
          itemActions.Cancel,
          "Cancel"
        );
        cancelledBackOrders.push(cancelTitle);
      }

      const poItems = itemActions.Backorder.concat(itemActions.Cancel)
        .concat(itemActions.received)
        .concat(itemActions.NewPO);

      if (poItems.length) {
        updatePoReceived(purchaseOrder.edits, poItems);
      }

      if (cancelledBackOrders.length || itemActions.Backorder.length) {
        handleSentEmail(purchaseOrder.edits, cancelledBackOrders, itemActions);
      }

      if (
        itemActions.Backorder.length === 0 &&
        itemActions.Cancel.length === 0
      ) {
        refreshData();
        handleCancel();
        handleDrawerCancel();
      }
      if (callbackUrl) {
        router.push(callbackUrl);
      }
    } catch (e) {
      createToast(en.errorBody, TOAST_TYPES.ERROR);
    }
  };

  const closeNewPoDrawer = () => {
    SetNewPoModal({ open: false });
  };

  const tableActionItems = () => {
    const itemActions = {
      Backorder: [],
      Cancel: [],
      NewPO: [],
      received: [],
    };
    tableData.reduce((prevAction, td) => {
      // eslint-disable-next-line
      if (prevAction.hasOwnProperty(td.action)) {
        if (td.quantityReceived !== 0) {
          prevAction.received.push({
            ...td,
            status: td.action || ORDER_STATUS.RECEIVED,
          });
        }
        prevAction[td.action].push({
          ...td,
          status: td.action || ORDER_STATUS.RECEIVED,
        });
      } else {
        prevAction.received.push({
          ...td,
          status: td.action || ORDER_STATUS.RECEIVED,
        });
      }
      return prevAction;
    }, itemActions);

    itemActionsRef.current = itemActions;
    return itemActions;
  };

  const handleSave = () => {
    if (purchaseOrder.allFilled(...requiredFields)) {
      setIsMissingRequiredField(false);
      const itemActions = tableActionItems();
      const filterDifferItems = tableData.filter(
        (item) => item.quantityReceived !== item.quantityOrdered
      );
      const selectActions = filterDifferItems.map((item) => item.action);
      const isActionNotSelected = selectActions.some((item) => item === "");

      if (itemActions.NewPO.length && !isActionNotSelected) {
        newPoUpdates(purchaseOrder.edits.accountId, itemActions.NewPO);
      } else if (!isActionNotSelected) {
        handleNewPoSave();
      } else {
        createToast(en.pleaseSelectAnActions, TOAST_TYPES.ERROR);
      }
    } else {
      setIsMissingRequiredField(true);
    }
  };

  const renderHeader = () => {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.headerDetails}
      >
        <Grid item className={classes.iconHeading}>
          <MiPageHeader
            title={en.receiving}
            color={theme.palette.secondary.bluegrey}
            leftIcon={renderLeftIcon()}
            titleVariant={"h4"}
          />
          <MiChip
            label={purchaseOrder.getValue("number")}
            className={classes.chipDetails}
          />
        </Grid>
      </Grid>
    );
  };

  const handleNotesChange = (row, notes = "") => {
    const onCancel = () => setConfirmModal({ open: false });
    const onApprove = (txt) => {
      setTableData(() => {
        return tableData.map((td) => {
          if (row.id === td.id) {
            return { ...td, notes: txt };
          }
          return td;
        });
      });
      setConfirmModal({ open: false });
    };
    const onDelete = () => {
      setTableData(() => {
        return tableData.map((td) => {
          if (row.id === td.id) {
            return { ...td, notes: "" };
          }
          return td;
        });
      });
      setConfirmModal({ open: false });
    };

    let mProps = {
      title: en.newNote,
      textPositive: en.create,
      textAreaValue: notes,
      buttonLeftStyles: { height: 36, width: 130 },
      buttonRightStyles: { height: 36, width: 130 },
      isTextAreaModified: true,
    };

    if (notes) {
      mProps = {
        title: en.editNote,
        isNeutralButton: true,
        textNeutral: en.delete,
        textPositive: en.save,
        textAreaValue: notes,
        buttonRightStyles: { height: 36, width: 80 },
        buttonLeftStyles: { height: 36, width: 80 },
        neutralButtonStyle: { height: 36, width: 80 },
        isTextAreaModified: true,
      };
    }

    setConfirmModal({
      ...mProps,
      open: true,
      handleNegative: onCancel,
      handlePositive: onApprove,
      handleNeutral: onDelete,
    });
  };

  return (
    <MiDrawer
      open={true}
      anchor="right"
      classes={{ paper: classes.drawerWidthStyle }}
      width="100%"
    >
      {loading ? (
        <Grid className={classes.loadingStyle}>
          <MiLoader color={theme.palette.primary.white} />
        </Grid>
      ) : (
        <Grid container spacing={5} className={classes.root}>
          <Grid item xs={12}>
            {renderHeader()}
            <Divider />
          </Grid>
          <Grid item xs={12} className={classes.form}>
            <ReceivingPOForm
              order={purchaseOrder}
              handleOrderChange={handleFormDataChange}
              contactsList={contactsList}
              locationList={locationList}
              isMissingRequiredField={isMissingRequiredField}
              setIsMissingRequiredField={setIsMissingRequiredField}
            />
          </Grid>
          <Grid item xs={12} className={classes.tableStyle}>
            <ReceivingPOFormTable
              data={tableData}
              handleNotesChange={handleNotesChange}
              handleReceiveOrderChange={handleReceiveOrderChange}
              handleActionChange={handleActionChange}
              handleReceiveAll={handleReceiveAll}
            />
          </Grid>
          <Grid item xs={12} className={classes.floatingAction}>
            <MiSnackbar
              visible={true}
              cancelTitle={en.cancel}
              handleCancelClick={handleCancel}
              buttonLeftStyles={{
                width: 150,
                height: 45,
                fontWeight: 500,
              }}
              justifyCenter={false}
              buttonOneStyles={{ width: 150, height: 45 }}
              saveTitle={en.complete}
              handleSaveClick={handleSave}
              buttonTwoColor={theme.palette.primary.blue}
              buttonTwoStyles={{
                width: 150,
                height: 45,
                fontWeight: 500,
              }}
            />
          </Grid>
        </Grid>
      )}
      {confirmModal.open && (
        <MiModalConfirm
          {...confirmModal}
          dialogActionStyle={{
            padding: "25px 30px",
          }}
          isTextArea={true}
          icon={mdiNoteTextOutline}
          color={theme.palette.primary.blue}
          approveBgColor={theme.palette.lightAccent.grey}
          txtEmptyColor={theme.palette.lightAccent.bluegrey}
          textAreaPlaceholder={en.enterNoteHere}
        />
      )}
      {newPoModal.open && (
        <CreatePurchaseOrder
          closePurchaseOrderDrawer={closeNewPoDrawer}
          reqData={poFinalData}
          selectedItems={() => {
            return [];
          }}
          onSave={handleNewPoSave}
          isOriginalSupplierAlert={true}
          supplierName={purchaseOrder.edits.accountName}
        />
      )}
      {showEmailEditor.open && (
        <SendEmail {...showEmailEditor} isBackButtonText={true} />
      )}
    </MiDrawer>
  );
};

export default Receiving;
