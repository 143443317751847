import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Session } from "@miview/components";
import { purchasingRoutes, PURCHASING_NAV } from "./routes/purchasing";
import { MiTHEME } from "@miview/theme";
import { RoofLogo_MiPurchasing } from "@miview/assets";
import { APPLIST } from "@miview/constants";
import DefaultHeader from "./containers/DefaultLayout/DefaultHeader";
import DefaultFooter from "./containers/DefaultLayout/DefaultFooter";

const createMui5Theme = createTheme({
  ...MiTHEME,
  palette: {
    ...MiTHEME.palette,
    primary: {
      ...MiTHEME.palette.primary,
      main: MiTHEME.colors.teal.primary,
      secondary: MiTHEME.colors.teal.secondary,
      accent: MiTHEME.colors.grey.primary,
      light: MiTHEME.colors.teal.light,
    },
  },
  spacing: (factor) => `${0.25 * factor}`,
  colors: undefined,
});

export const AppProvider = ({ children }) => {
  return <ThemeProvider theme={createMui5Theme}>{children}</ThemeProvider>;
};

const MAIN_APP = {
  logo: RoofLogo_MiPurchasing,
  name: "MiPurchasing",
  url: "/purchasing/suppliers",
};
const renderFooter = () => {
  return <DefaultFooter />;
};

const renderRightIcons = () => {
  return <DefaultHeader />;
};

export const PurchasingRoutes = () => {
  return (
    <AppProvider>
      <CssBaseline />
      <Session
        nav={PURCHASING_NAV}
        routesInfo={purchasingRoutes}
        mainApp={MAIN_APP}
        apps={APPLIST}
        renderRightIcons={renderRightIcons}
        renderFooter={renderFooter}
      />
    </AppProvider>
  );
};
