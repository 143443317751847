import { makeStyles } from "tss-react/mui";

export const useMiStepsBarStyles = makeStyles()((theme) => ({
  stepsContainer: {
    height: "65px",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "0",
    position: "relative",
    "& .activated .step-circle": { background: "#4bca81" },
    "& .activated .step-circle-canceled": {
      width: "1.2rem",
      height: "1.2rem",
      borderRadius: "50%",
      background: "#e70f0f",
      display: "inline-block",
      verticalAlign: "middle",
      zIndex: 1,
      position: "relative",
    },
  },
  stepsInnerContainer: {
    maxHeight: "200px",
    overflow: "visible",
    transition: "all 0.5s ease-in-out",
    padding: "0.5rem",
  },
  stepsInnerContainerHide: {
    display: "none",
    opacity: 0,
    padding: "0",
    maxHeight: "0",
    boxShadow: "0px 0px 0px",
    overflow: "hidden",
    transition: "all 0.5s ease-in-out",
  },
  stepsList: {
    display: "flex",
    msFlexPack: "justify",
    justifyContent: "space-between",
    position: "relative",
    "& i": {
      zIndex: 100,
      color: "white",
      fontSize: "2rem",
      textAlign: "center",
      verticalAlign: "middle",
      lineHeight: "1.5rem",
    },
  },
  stepCircle: {
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: "#efede8",
    display: "inline-block",
    verticalAlign: "middle",
    zIndex: 10,
    position: "relative",
    top: "-60px",
    left: "-10px",
  },
  stepsBar: {
    position: "absolute",
    left: "0.6rem",
    right: "0.5rem",
    top: "2rem",
    height: "0.3rem",
    display: "block",
    width: "calc(100% - 1.1rem)",
    background: "#efede8",
  },
  stepsBarActivated0: { width: "5%" },
  stepsBarActivated1: { width: "0%" },
  stepsBarActivated2: { width: "16.5%" },
  stepsBarActivated3: { width: "33%" },
  stepsBarActivated4: { width: "50%" },
  stepsBarActivated5: { width: "66.5%" },
  stepsBarActivated6: { width: "83%" },
  stepsBarActivated7: { width: "100%" },
  timelineText: { display: "block", marginTop: "0.5rem" },
  titleIconContainer: {
    [theme.breakpoints.down("lg")]: { width: "50px" },
    [theme.breakpoints.up("lg")]: {
      width: "140px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "12px",
    },
    height: "50px",
    border: "1px solid #dddbda",
    padding: "12px 10px 10px 8px",
    color: "white",
    zIndex: 0,
  },
  titleIconContainerSvg: {
    [theme.breakpoints.down("lg")]: { width: "50px" },
    [theme.breakpoints.up("lg")]: {
      width: "140px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "12px",
    },
    height: "50px",
    border: "1px solid #dddbda",
    padding: "8px 10px 10px 8px",
    color: "white",
    zIndex: 0,
  },
  titleIcon: {
    display: "inline",
    [theme.breakpoints.up("lg")]: { paddingRight: "10px" },
  },
  stepInfoContainer: { cursor: "pointer" },
  stepTitleContainer: {
    [theme.breakpoints.down("lg")]: { display: "none" },
    [theme.breakpoints.up("lg")]: { display: "inline" },
  },
  stepTitle: { marginLeft: "5px", paddingLeft: "5px", display: "inline" },
}));
