import React, { useState } from "react";
import { MiTable, MiBox, MiIcon } from "@miview/components";
import { useTheme } from "@mui/material/styles";
import {
  mdiCloseCircle,
  mdiCheckCircle,
  mdiFileDocumentOutline,
} from "@mdi/js";
import moment from "moment";
import { useRouter } from "@miview/hooks";
import { RUN_STATUSES } from "@miview/constants";
import { JsonModal } from "../components";

export const History = (props) => {
  const { data } = props;
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();
  const router = useRouter();

  const handleCloseModal = () => {
    setSelected(null);
    setShowModal(false);
  };

  const handleViewJson = (row) => {
    setSelected(row);
    setShowModal(true);
  };

  const actions = [
    {
      onClick: handleCloseModal,
      color: theme.palette.primary.grey,
      name: "Close",
      inverse: true,
    },
  ];

  const renderIconStatus = ({ value }) => {
    let icon = mdiCloseCircle;
    let color = theme.palette.primary.red;
    if (value === RUN_STATUSES.SUCCEEDED) {
      icon = mdiCheckCircle;
      color = theme.palette.primary.green;
    }
    return <MiIcon path={icon} size={1} color={color} />;
  };

  const renderIconJson = ({ row }) => {
    return (
      <div
        onClick={(e) => {
          handleViewJson(row);
          e.stopPropagation();
        }}
      >
        <MiIcon
          path={mdiFileDocumentOutline}
          size={1}
          color={theme.palette.primary.grey}
        />
      </div>
    );
  };

  const renderDate = ({ value }) => {
    if (!value) {
      return null;
    }
    return moment(value).format("lll");
  };

  const handleViewDetails = ({ row }) => {
    router.push(`/integrations/drhorton/history/${row?.id}`);
  };

  const renderTotal = ({ row }) => {
    return row.newCount + row.updateCount;
  };

  const columns = [
    {
      field: "status",
      headerName: "",
      disableColumnMenu: true,
      width: 35,
      renderCell: (params) => renderIconStatus(params),
    },
    {
      field: "id",
      headerName: "Run Id",
      width: 80,
    },
    {
      field: "startTime",
      headerName: "Date",
      width: 200,
      renderCell: (params) => renderDate(params),
    },
    {
      field: "serviceTypeName",
      headerName: "Job",
      flex: 1,
    },
    {
      field: "divisionCode",
      headerName: "Division",
    },
    {
      field: "serviceTypeDescription",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "newCount",
      headerName: "New",
      width: 100,
    },
    {
      field: "updateCount",
      headerName: "Updated",
      width: 100,
    },
    {
      field: "runId",
      headerName: "Total",
      disableColumnMenu: true,
      width: 80,
      renderCell: (params) => renderTotal(params),
    },
    {
      field: "resultRaw",
      headerName: "",
      disableColumnMenu: true,
      width: 100,
      renderCell: (params) => renderIconJson(params),
    },
  ];

  return (
    <>
      <MiBox height={"100%"}>
        <MiTable
          columns={columns}
          rows={data}
          disableColumnMenu
          onRowClick={handleViewDetails}
        />
      </MiBox>
      <JsonModal
        title="Run - D.R. Horton - JSON response"
        actions={actions}
        open={showModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        selected={selected}
      />
    </>
  );
};
