import { useState } from "react";
import { walkService, issueService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { useComponentState } from "../useComponentState/useComponentState";

export const useIssues = (walkId, setHasIssues) => {
  const [issues, setIssues] = useState([]);
  const stateManager = useComponentState();

  const getIssues = () => {
    stateManager.run(async () => {
      const response = await walkService.getIssues(walkId);
      if (response.status === HTTP_STATUSES.OK) {
        setHasIssues(response.data.length);
        setIssues(response.data);
      }
    });
  };

  const updateIssue = (data) => {
    stateManager.run(async () => {
      const response = await walkService.createIssue(walkId, data);
      if (response.status === HTTP_STATUSES.OK) {
        getIssues();
      }
    });
  };

  const deleteIssue = (selectedIssueId) => {
    stateManager.run(async () => {
      const response = await issueService.delete(selectedIssueId);
      if (response.status === HTTP_STATUSES.OK) {
        getIssues();
      }
    });
  };

  return { issues, getIssues, updateIssue, deleteIssue };
};
