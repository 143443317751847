import { useRouter } from "@miview/hooks";
import { usePlanOptions, usePlanAreas, usePlanItemForm } from "./";
import { planService } from "@miview/api";

export const useSetOptionOn = () => {
  const {
    query: { planId },
  } = useRouter();
  const { setOptionConfiguring, clearToggles, updateTogglesOn } =
    usePlanOptions();
  const { setPlanAreasLoading, setPlanAreasPreview } = usePlanAreas();
  const { resetPlanItemForm } = usePlanItemForm();

  const setOptionOn = async (optionId, config) => {
    try {
      let newOptions = [];

      if (config?.configuring) {
        setOptionConfiguring(optionId);
        clearToggles();
      } else {
        newOptions = updateTogglesOn(optionId, config?.active);
        setOptionConfiguring(0);
      }

      setPlanAreasLoading("Loading...");

      let planAreas = [];

      if (optionId) {
        if (config?.configuring) {
          const response = await planService.applyOptions(planId, [optionId]);
          planAreas = response.data;
        } else {
          if (newOptions.length) {
            const response = await planService.applyOptions(planId, newOptions);
            planAreas = response.data;
          } else {
            const response = await planService.applyOptions(planId, []);
            planAreas = response.data;
          }
        }
      } else {
        const response = await planService.applyOptions(planId, []);
        planAreas = response.data;
      }

      resetPlanItemForm();
      setPlanAreasPreview(planAreas);
      setPlanAreasLoading("");
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return setOptionOn;
};
