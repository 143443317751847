import React, { useState } from "react";
import { Grid, Container } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import SupplierEdit from "./SupplierEdit";
import {
  BasicInfoForm,
  GeneralContactForm,
  RemittanceAddressForm,
  CorporateAddressForm,
} from "../../../../components";
import en from "../../../../locale/en.json";
import { accountService } from "@miview/api";
import { useEdit, useRouter } from "@miview/hooks";
import { HTTP_STATUSES } from "@miview/constants";
import { isPhoneNumber, isValidEmail } from "@miview/utils";
import { MiBox } from "@miview/components";

const useStyles = makeStyles()(() => ({
  maxWidthContainer: {
    maxWidth: "95%",
    marginTop: "25px",
  },
}));

const REQUIRED_REMITTANCE_FIELDS = [
  "billingHouseNumber",
  "billingCity",
  "billingState",
  "billingZipCode",
];

const REQUIRED_FIELDS = [
  "name",
  "status",
  "alternateIdNumber",
  "phone",
  "addressHouseNumber",
  "addressCity",
  "addressState",
  "addressZipCode",
  "legalName",
  "paymentTermId",
  "code",
];

const SupplierGeneral = (props) => {
  const { data, setData } = props;
  const [editMode, setEditMode] = useState(false);
  const [isMissingRequiredField, setIsMissingRequiredField] = useState(false);
  const [duplicateAccounts, setDuplicateAccounts] = useState(false);
  const router = useRouter();
  const { classes } = useStyles();
  const edit = useEdit(data);

  const { id } = router.query;

  const editModeOnChange = (mode) => {
    edit.reset();
    setEditMode(mode);
  };

  const validateNumericInputs = () => {
    return (
      (edit.getValue("email") && !isValidEmail(edit.getValue("email"))) ||
      (edit.getValue("phone") && !isPhoneNumber(edit.getValue("phone"))) ||
      (edit.getValue("fax") && !isPhoneNumber(edit.getValue("fax")))
    );
  };

  const handleSave = async () => {
    if (
      !edit.allFilled(...REQUIRED_FIELDS) ||
      (!edit.getValue("useCorporateAddress") &&
        !edit.allFilled(...REQUIRED_REMITTANCE_FIELDS)) ||
      validateNumericInputs() ||
      duplicateAccounts
    ) {
      setIsMissingRequiredField(true);
      return;
    }
    const request = { ...edit.edits };
    if (edit.getValue("useCorporateAddress")) {
      request.billingHouseNumber = edit.getValue("addressHouseNumber");
      request.billingStreetNumber = edit.getValue("addressStreetNumber");
      request.billingCity = edit.getValue("addressCity");
      request.billingState = edit.getValue("addressState");
      request.billingZipCode = edit.getValue("addressZipCode");
    }
    setIsMissingRequiredField(false);
    const response = await accountService.accounts.update({
      id,
      data: request,
      successMessage: en.changesSaved,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setData(response.data);
      setEditMode(false);
    }
  };

  return (
    <MiBox>
      <Container className={classes.maxWidthContainer}>
        <Grid container direction="row" spacing={10}>
          <Grid item xs={6}>
            <BasicInfoForm
              editMode={editMode}
              edit={edit}
              isMissingRequiredField={isMissingRequiredField}
              setDuplicateAccounts={setDuplicateAccounts}
            />
          </Grid>
          <Grid item xs={6}>
            <GeneralContactForm
              editMode={editMode}
              edit={edit}
              isMissingRequiredField={isMissingRequiredField}
            />
          </Grid>
          <Grid item xs={6}>
            <CorporateAddressForm
              editMode={editMode}
              edit={edit}
              isMissingRequiredField={isMissingRequiredField}
            />
          </Grid>
          <Grid item xs={6}>
            <RemittanceAddressForm
              editMode={editMode}
              edit={edit}
              isMissingRequiredField={isMissingRequiredField}
            />
          </Grid>
        </Grid>
        <SupplierEdit
          mode={editMode}
          onChange={editModeOnChange}
          onSave={handleSave}
          type={en.submit}
        />
      </Container>
    </MiBox>
  );
};

export default SupplierGeneral;
