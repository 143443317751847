import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { MiModal, MiInputSelectBox } from "@miview/components";
import {
  paySchedulesService,
  payPeriodService,
  accountingTransactionService,
} from "@miview/api";
import { HTTP_STATUSES, en, EXPENSE_TYPE_CATEGORIES } from "@miview/constants";
import moment from "moment";
import { createFilter, getFilename, openFileSaveDialog } from "@miview/utils";
import { useComponentState } from "@miview/hooks";
import { MiTHEME } from "@miview/theme";

export const ExportModal = (props) => {
  const { open, onClose, selected } = props;

  const [payPeriods, setPayPeriods] = useState([]);
  const [selectedPayPeriod, setSelectedPayPeriod] = useState("");

  const theme = useTheme();
  const stateManager = useComponentState();

  useEffect(() => {
    fetchPayPeriods();
  }, []);

  const fetchPayPeriods = async () => {
    stateManager.run(async () => {
      const response = await paySchedulesService.getAll();

      if (response.status === HTTP_STATUSES.OK) {
        const [paySchedule] = response.data;
        await fetchPayPeriodsBySchedule(paySchedule.id);
      }
    });
  };

  const fetchPayPeriodsBySchedule = async (payScheduleId) => {
    const fromDate = moment().subtract(31, "days").format("YYYY-MM-DD");
    const toDate = moment().add(31, "days").format("YYYY-MM-DD");

    const filters = createFilter().inRange({
      payDate: [fromDate, toDate],
    });
    const response = await payPeriodService.getAll({
      payScheduleId,
      sort: [{ field: "payDate", sort: "desc" }],
      filters,
    });

    if (response.status === HTTP_STATUSES.OK) {
      const formattedPayPeriods = response.data.map((payPeriod) => {
        return {
          ...payPeriod,
          value: payPeriod.id,
          label: `Pay Date: ${renderDate(
            payPeriod.payDate,
            "MM/DD/YYYY"
          )} (Pay Period: ${renderDate(
            payPeriod.startDate,
            "MM/DD/YYYY"
          )} - ${renderDate(payPeriod.endDate, "MM/DD/YYYY")}) \n`,
        };
      });
      setPayPeriods(formattedPayPeriods);
      const nextImmediatePayPeriod = formattedPayPeriods.findLast((x) =>
        moment(x.payDate).isSameOrAfter(moment(), "day")
      );
      setSelectedPayPeriod(nextImmediatePayPeriod?.value);
    }
  };

  const renderDate = (val, dateFormat) => {
    return val ? moment(val).utc().format(dateFormat) : "";
  };

  const exportBonus = async () => {
    const response =
      await accountingTransactionService.exportQuickBooksBonusIif(
        selectedPayPeriod
      );
    if (response.status === HTTP_STATUSES.OK) {
      const filename = getFilename(response.headers["content-disposition"]);
      openFileSaveDialog(response.data, filename);
      onClose();
    }
  };

  const createPayExport = async () => {
    const response = await accountingTransactionService.exportQuickBooksPayIif(
      selectedPayPeriod
    );
    if (response.status === HTTP_STATUSES.OK) {
      const filename = getFilename(response.headers["content-disposition"]);
      openFileSaveDialog(response.data, filename);
      onClose();
    }
  };

  const actions = [
    {
      onClick: onClose,
      color: theme.palette.primary.grey,
      name: en.close,
      inverse: false,
      style: {
        borderWidth: 2,
        borderRadius: 50,
        minWidth: 140,
        padding: 10,
        color: MiTHEME.colors.grey.primary,
        borderColor: MiTHEME.colors.grey.accent,
      },
    },
    {
      onClick:
        selected === EXPENSE_TYPE_CATEGORIES.BONUS
          ? exportBonus
          : createPayExport,
      color: theme.palette.primary.green,
      name:
        selected === EXPENSE_TYPE_CATEGORIES.BONUS
          ? "Generate Bonus Export"
          : "Generate Pay Export",
      inverse: true,
      disabled: !selectedPayPeriod,
      style: {
        borderRadius: 50,
        minWidth: 140,
        padding: 10,
        backgroundColor: MiTHEME.colors.teal.medium,
      },
    },
  ];

  return (
    <MiModal
      title={"Create Quickbooks Export"}
      open={open}
      onClose={onClose}
      actions={actions}
    >
      <div>
        A Quickbooks .iif file will be created and can be imported into
        quickbooks. All transactions included in this export will be flagged as
        exported and will not be included in future exports.
      </div>
      <MiInputSelectBox
        menuItems={payPeriods}
        styles={{ marginTop: 12, width: "100%" }}
        labelText="Pay Period"
        value={selectedPayPeriod}
        handleChange={(e) => setSelectedPayPeriod(e.target.value)}
        required
        disableNone
      />
    </MiModal>
  );
};
