import {
  mdiAlert,
  mdiTriangle,
  mdiCloseCircleOutline,
  mdiCheckCircleOutline,
  mdiCheckCircle,
  mdiBlockHelper,
  mdiHandBackRight,
} from "@mdi/js";
import { MiTHEME } from "@miview/theme";
import { STAGEWALKITEM_STATUSES, WALK_STATUSES_LIST } from "@miview/constants";

export const ItemIconHelper = {
  [STAGEWALKITEM_STATUSES.COMPLETE]: {
    itemIconPath: mdiCheckCircle,
    itemIconColor: MiTHEME.palette.primary.green,
  },
  [STAGEWALKITEM_STATUSES.NA]: {
    itemIconPath: mdiBlockHelper,
    itemIconColor: MiTHEME.palette.primary.red,
  },
  [STAGEWALKITEM_STATUSES.INCOMPLETE]: {
    itemIconPath: mdiCloseCircleOutline,
    itemIconColor: MiTHEME.palette.primary.red,
  },
  [STAGEWALKITEM_STATUSES.PENDING]: {
    itemIconPath: mdiCheckCircleOutline,
    itemIconColor: MiTHEME.palette.primary.green,
  },
  [STAGEWALKITEM_STATUSES.HOLD]: {
    itemIconPath: mdiHandBackRight,
    itemIconColor: MiTHEME.palette.primary.purple,
  },
  [STAGEWALKITEM_STATUSES.VERIFIED]: {
    itemIconPath: mdiCheckCircleOutline,
    itemIconColor: MiTHEME.palette.primary.grey,
  },
  [STAGEWALKITEM_STATUSES.NOTREADY]: {
    itemIconPath: mdiCheckCircleOutline,
    itemIconColor: MiTHEME.palette.primary.grey,
  },
  [STAGEWALKITEM_STATUSES.ABORTED]: {
    itemIconPath: mdiCheckCircleOutline,
    itemIconColor: MiTHEME.palette.primary.grey,
  },
};

export const TableIconHelper = {
  [WALK_STATUSES_LIST.UNASSIGNED]: {
    tableIconPath: mdiTriangle,
    tableIconColor: MiTHEME.palette.light.grey,
  },
  [WALK_STATUSES_LIST.SCHEDULED]: {
    tableIconPath: mdiTriangle,
    tableIconColor: MiTHEME.palette.light.grey,
  },
  [WALK_STATUSES_LIST.INPROGRESS]: {
    tableIconPath: mdiTriangle,
    tableIconColor: MiTHEME.palette.light.grey,
  },
  [WALK_STATUSES_LIST.INCOMPLETE]: {
    tableIconPath: mdiAlert,
    tableIconColor: MiTHEME.palette.primary.red,
  },
  [WALK_STATUSES_LIST.NOT_STARTED]: {
    tableIconPath: mdiTriangle,
    tableIconColor: MiTHEME.palette.light.grey,
  },
  [WALK_STATUSES_LIST.COMPLETE]: {
    tableIconPath: mdiTriangle,
    tableIconColor: MiTHEME.palette.primary.green,
  },
  [WALK_STATUSES_LIST.CANCELLED]: {
    tableIconPath: mdiTriangle,
    tableIconColor: MiTHEME.palette.light.grey,
  },
  [WALK_STATUSES_LIST.HOLD]: {
    tableIconPath: mdiTriangle,
    tableIconColor: MiTHEME.palette.light.grey,
  },
  [WALK_STATUSES_LIST.PENDING]: {
    tableIconPath: mdiTriangle,
    tableIconColor: MiTHEME.palette.light.grey,
  },
  [WALK_STATUSES_LIST.VERIFIED]: {
    tableIconPath: mdiTriangle,
    tableIconColor: MiTHEME.palette.light.grey,
  },
};
