import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import PurchaseOrderForm from "../../components/PurchaseOrderForm";
import PurchaseOrderTable from "../../components/PurchaseOrderTable";
import { Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  MiChip,
  MiPageHeader,
  MiLoader,
  MiStepperCustom,
  MiDrawer,
  MiModalConfirm,
  MiIcon,
} from "@miview/components";
import {
  mdiCardTextOutline,
  mdiChevronRight,
  mdiChevronLeft,
  mdiFileRemoveOutline,
} from "@mdi/js";
import SendEmail from "./SentEmail";
import { useEdit, useRouter } from "@miview/hooks";
import { en, TAX_RATE, TOAST_TYPES } from "@miview/constants";
import {
  purchaseOrderService,
  accountService,
  RequisitionService,
} from "@miview/api";
import { createToast } from "@miview/toast";
import moment from "moment";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: 20,
  },

  iconCircle: {
    width: 50,
    height: 50,
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: 10,
  },
  iconTitle: {
    display: "flex",
    alignItems: "center",
  },
  pageHeaderDetails: {
    borderRadius: 3,
    height: 20,
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.xsmall,
    color: theme.palette.secondary.bluegrey,
    backgroundColor: theme.palette.primary.light,
  },
  bottomGrid: {
    padding: 5,
  },
  stepper: {
    marginLeft: 20,
  },
  headerStyle: {
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  table: {
    marginTop: 20,
  },
  footerStyle: {
    paddingLeft: 20,
    padding: 3,
    width: "100%",
    textAlign: "right",
    fontWeight: theme.fontWeight.medium,
    color: theme.palette.secondary.bluegrey,
    fontSize: theme.fontSize.medium,
  },
  amountGrid: {
    paddingLeft: 20,
    padding: 3,
    width: "100%",
    textAlign: "right",
    color: theme.palette.dark.bluegrey,
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.medium,
  },
  totalStyle: {
    paddingLeft: 20,
    padding: 3,
    width: "100%",
    textAlign: "right",
    color: theme.palette.secondary.bluegrey,
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.xlarge,
  },
  totalAmount: {
    paddingLeft: 20,
    padding: 3,
    width: "100%",
    textAlign: "right",
    color: theme.palette.dark.bluegrey,
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.xlarge,
  },
  button: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: theme.palette.primary.white,
    fontSize: theme.fontSize.medium,
    alignItems: "initial",
    fontWeight: theme.fontWeight.medium,
  },
  topGrid: {
    fontSize: theme.fontSize.xlarge,
    fontWeight: theme.fontWeight.regular,
  },
  drawerWidth: {
    width: "100%",
  },
  loading: {
    width: "100%",
    position: "relative",
    top: "50%",
    left: "50%",
  },
  gridStyle: {
    margin: 0,
  },
  dialogPaper: {
    width: 351,
  },
}));
const mungeContact = (item) => {
  return {
    label: item.displayName || "",
    value: item.id || 0,
  };
};
const mungeLocation = (item) => {
  return {
    label: item.name || "",
    value: item.id || 0,
  };
};
const PurchaseOrder = ({
  closeReviewPoDrawer,
  data,
  selectedItems,
  onSave,
}) => {
  const defaultFormValues = {
    contact: "",
    tag: "",
    memo: "",
    specialInstructions: "",
    receivedDate: new Date(),
  };

  const [confirmModal, setConfirmModal] = useState({ open: false });
  const [showEmailEditor, setShowEmailEditor] = useState({ open: false });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const { classes } = useStyles();
  const theme = useTheme();
  const [initialValues, setInitial] = useState(defaultFormValues);
  const currentFormEdits = useRef({});
  const edit = useEdit(initialValues);
  const [contacts, setContacts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [account, setAccount] = useState({});
  const [isMissingRequiredField, setIsMissingRequiredField] = useState(false);

  const steps = useMemo(
    () =>
      data.map((item) => ({
        label: item.acctitle,
        id: item.accid,
      })),
    [data]
  );

  const router = useRouter();

  const fetchData = useCallback(
    async (id) => {
      try {
        const poAccount = data.find((item) => item.accid === id);
        const [{ data: contact }, { data: location }] = await Promise.all([
          accountService.accounts.getContacts(id),
          accountService.deliveryLocations.getAll(),
        ]);
        const poContact = contact?.map(mungeContact) ?? [];
        const poLocation = location?.map(mungeLocation) ?? [];
        setContacts(poContact);
        setLocations(poLocation);
        setAccount({ label: poAccount.acctitle, value: poAccount.accid });
        setList(poAccount?.orderItems ?? []);
        const existingFormValues = currentFormEdits.current[id] ?? {};
        setInitial({
          ...defaultFormValues,
          ...existingFormValues,
          accountId: id,
        });
        setLoading(false);
      } catch (error) {
        createToast(en.errorBody, TOAST_TYPES.ERROR);
      }
    },
    [data]
  );

  useEffect(() => {
    setLoading(true);
    fetchData(steps[activeIndex].id);
  }, [activeIndex, steps, fetchData]);

  const hideConfirmationModal = () => setConfirmModal({ open: false });
  const onConfirmationApprove = () => {
    hideConfirmationModal();
    closeReviewPoDrawer();
  };

  const handleOnDiscardPo = () => {
    let mProps = {
      color: theme.palette.secondary.red,
      icon: mdiFileRemoveOutline,
      title: en.backToPricing,
      description: en.backToPricingDescription,
      textPositive: en.discard,
      buttonLeftStyles: { width: 130, height: 36 },
      buttonRightStyles: { width: 130, height: 36 },
    };
    setConfirmModal({ ...mProps, open: true });
  };

  const handleStepChange = (newIndex) => {
    const currentStepId = steps[activeIndex].id;
    setActiveIndex(newIndex);
    currentFormEdits.current[currentStepId] = {
      ...initialValues,
      ...edit.edits,
    };
    edit.reset();
    setInitial({ ...defaultFormValues });
  };
  const total = list.reduce(function (previousValue, currentValue) {
    return (
      previousValue + currentValue.quantityOrdered * currentValue.unitPrice
    );
  }, 0);
  const invoiceSubtotal = Number(total).toFixed(2);
  const taxes = TAX_RATE * total;
  const invoiceTaxes = Number(taxes).toFixed(2);
  const invoiceTotal = taxes + total;
  const invoiceTotals = Number(invoiceTotal).toFixed(2);

  const handleSendEmail = () => {
    setShowEmailEditor({ open: false });
    onSave("/purchasing/purchase-order/new-homes");
  };
  const handleSendEmailManually = () => {
    router.push("/purchasing/purchase-order/new-homes");
    createToast(en.purchaseOrderIssuedManually, TOAST_TYPES.SUCCESS);
  };
  const handleOnCloseEmail = () => {
    setShowEmailEditor({ open: false });
  };

  const createPurchaseOrders = async () => {
    const showError = () =>
      createToast(en.theActionWasNotSuccessful, TOAST_TYPES.ERROR);
    try {
      const createPOPromises = Object.entries(currentFormEdits.current).map(
        async ([accountId, formData]) => {
          const purchaseOrder = await purchaseOrderService.purchaseOrder.create(
            {
              data: {
                number: Math.floor(
                  crypto.getRandomValues(new Uint32Array(1))[0]
                ),
                accountId: accountId,
                contactId: formData.contactName,
                description: "",
                classification: "",
                deliveryLocationId: formData.deliveryLocation,
                billingDate: moment(formData.billingDate).toISOString(),
                issueDate: moment(formData.issueDate).toISOString(),
                deliveryDate: moment(formData.deliveryDate).toISOString(),
                receivedDate: moment(formData.receivedDate).toISOString(),
                requestedBillingDate: moment(
                  formData.requestedBillingDate
                ).toISOString(),
                tag: formData.tag ?? "",
                status: "Issued",
                memo: formData.memo ?? "",
                receiveNotes: "",
                specialInstructions: formData.specialInstructions ?? "",
              },
            }
          );
          const purchaseOrderId = purchaseOrder.data.id;
          const poAccount = data.find(
            (item) => item.accid === formData.accountId
          );
          const orderItems = poAccount?.orderItems ?? [];
          return orderItems.map((item, index) => {
            const orderItem = {
              accountItemId: item.id,
              notes: item.notes,
              itemName: item.itemName,
              description: item.description,
              sku: item.sku,
              unit: item.unit,
              unitPrice: item.unitPrice,
              quantityOrdered: item.quantityOrdered,
              quantityReceived: 0,
              displaySequence: index,
              status: "Issued",
              purchaseOrderId: purchaseOrderId,
            };
            return purchaseOrderService.purchaseOrder.createPurchaseOrderItem({
              id: purchaseOrderId,
              data: orderItem,
            });
          });
        }
      );
      const createPOPromisesResult = await Promise.allSettled(createPOPromises);
      const hasRejectedPO = createPOPromisesResult.find(
        (item) => item.status === "rejected"
      );
      if (hasRejectedPO) {
        showError();
      }
    } catch (error) {
      showError();
    }
  };

  const updateRequisitionStatus = async () => {
    const showError = () =>
      createToast(en.theActionWasNotSuccessful, TOAST_TYPES.ERROR);
    try {
      const statusData = { status: "On Order" };
      const statusUpdatePromises = selectedItems.map((item) => {
        return RequisitionService.requisitions.update(item.id, statusData);
      });
      const statusUpdatePromiseResult = await Promise.allSettled(
        statusUpdatePromises
      );
      const hasRejectedStatusUpdate = statusUpdatePromiseResult.find(
        (item) => item.status === "rejected"
      );
      if (hasRejectedStatusUpdate) {
        showError();
      }
    } catch (error) {
      showError();
    }
  };

  const handleOnNext = async () => {
    if (edit.allFilled("specialInstructions")) {
      setIsMissingRequiredField(false);
      try {
        if (activeIndex === steps.length - 1) {
          const currentStepId = steps[activeIndex].id;
          currentFormEdits.current[currentStepId] = {
            ...initialValues,
            ...edit.edits,
          };
          await createPurchaseOrders();
          if (selectedItems.length) {
            await updateRequisitionStatus();
          }
          setShowEmailEditor({
            open: true,
            stepLabelData: data,
          });
        } else {
          handleStepChange(activeIndex + 1);
        }
      } catch (error) {
        createToast(en.errorBody, TOAST_TYPES.ERROR);
      }
    } else {
      setIsMissingRequiredField(true);
    }
  };

  const renderLeftIcon = () => {
    return (
      <Grid className={classes.iconCircle}>
        <MiIcon
          path={mdiCardTextOutline}
          size={1.5}
          color={theme.palette.primary.white}
        />
      </Grid>
    );
  };

  const renderButton = () => {
    return (
      <Button
        className={classes.button}
        disableRipple
        onClick={handleOnDiscardPo}
      >
        <MiIcon path={mdiChevronLeft} size={1} />
        {en.backToPricing}
      </Button>
    );
  };

  const renderSaveButton = () => {
    return (
      <Button className={classes.button} disableRipple onClick={handleOnNext}>
        {steps.length === activeIndex + 1 ? en.saveAndSend : en.saveAndNext}
        <MiIcon path={mdiChevronRight} size={1} />
      </Button>
    );
  };

  const renderHeader = () => {
    return (
      <Grid container className={classes.headerStyle}>
        <Grid item className={classes.iconTitle}>
          <MiPageHeader
            titleVariant={"h4"}
            title={en.purchaseOrder}
            color={theme.palette.secondary.bluegrey}
            leftIcon={renderLeftIcon()}
          />
          <MiChip label="Review" className={classes.pageHeaderDetails} />
        </Grid>
        <Grid item>
          <MiStepperCustom
            items={steps}
            activeIndex={activeIndex}
            renderPrevButton={renderButton}
            renderNextButton={renderSaveButton}
            activeColor={theme.palette.primary.main}
            stepperWidth={"fit-content"}
            boxHeight={56}
            containerHeight={70}
            indexCount={false}
            previousStepsSelectable
            handleChange={(newIndex) => {
              if (newIndex < activeIndex) {
                handleStepChange(newIndex);
              }
            }}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.topGrid}>
            <b>{en.total}</b> ${invoiceTotals}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const handleInputNotesChange = (row, value = "") => {
    setList((tableData) => {
      const rowIndex = tableData.findIndex((td) => row.id === td.id);
      if (rowIndex !== -1) {
        tableData[rowIndex].notes = value;
        return [...tableData];
      }
      return tableData;
    });
  };

  return (
    <MiDrawer
      open={true}
      anchor="right"
      classes={{ paper: classes.drawerWidth }}
      width="100%"
    >
      {loading ? (
        <Grid className={classes.loading}>
          <MiLoader color={theme.palette.primary.white} />
        </Grid>
      ) : (
        <Grid container spacing={5} className={classes.root}>
          <Grid item xs={12}>
            {renderHeader()}
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <PurchaseOrderForm
              disabled={false}
              edit={edit}
              contacts={contacts}
              locations={locations}
              account={account}
              isMissingRequiredField={isMissingRequiredField}
              setIsMissingRequiredField={setIsMissingRequiredField}
            />
          </Grid>
          <Grid item xs={12} className={classes.table}>
            <PurchaseOrderTable
              data={list}
              handleInputNotesChange={handleInputNotesChange}
              isReadOnlyNotes={false}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={10}
            className={classes.gridStyle}
            justifyContent="flex-end"
          >
            <Grid container>
              <Grid item xs={10}></Grid>
              <Grid item xs={1}>
                <Grid>
                  <Typography className={classes.footerStyle}>
                    {en.subtotal}
                  </Typography>
                  <Divider />
                </Grid>
                <Grid>
                  <Typography className={classes.footerStyle}>
                    {en.tax}
                  </Typography>
                  <Divider />
                </Grid>
                <Grid>
                  <Typography className={classes.totalStyle}>
                    {en.total}
                  </Typography>
                  <Divider />
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <Grid>
                  <Typography className={classes.amountGrid}>
                    ${invoiceSubtotal}
                  </Typography>
                  <Divider />
                </Grid>
                <Grid>
                  <Typography className={classes.amountGrid}>
                    ${invoiceTaxes}
                  </Typography>
                  <Divider />
                </Grid>
                <Grid>
                  <Typography className={classes.totalAmount}>
                    ${invoiceTotals}
                  </Typography>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {confirmModal.open && (
        <MiModalConfirm
          {...confirmModal}
          handleNegative={hideConfirmationModal}
          handlePositive={onConfirmationApprove}
          dialogClasses={{ paper: classes.dialogPaper }}
        />
      )}
      {showEmailEditor.open && (
        <SendEmail
          {...showEmailEditor}
          title={en.wareHouseDelivery}
          titleColor={theme.palette.primaryShade.blue}
          titleBackground={theme.palette.secondary.lightblue}
          titleBorder={"none"}
          handleOnBack={handleOnCloseEmail}
          handleOnSave={handleSendEmail}
          handleOnSaveManually={handleSendEmailManually}
          isEmailSubject={true}
        />
      )}
    </MiDrawer>
  );
};

export default PurchaseOrder;
