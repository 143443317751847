import React from "react";
import { IconButton, Badge } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const useStyles = makeStyles()((theme) => ({
  toggleButtonStyle: {
    width: 75,
    height: 60,
    position: "fixed",
    right: 0,
    bottom: 50,
    background: theme.palette.primary.teal,
    borderRadius: "35px 0px 0px 35px",
    color: "#fff",
    "&:hover": {
      background: theme.palette.secondary.teal,
    },
    zIndex: 2,
  },
  toggleButtonStyleArrow: {
    fontSize: theme.fontSize.medium,
  },
  toggleButtonStyleBadge: {
    alignItems: "center",
  },
  badgeRed: {
    background: theme.palette.secondary.red,
  },
  toggleButtonStyleWallet: {
    fontSize: theme.fontSize.header,
  },
}));

const SideToggleButton = ({ onClick, count }) => {
  const { classes } = useStyles();
  return (
    <IconButton
      onClick={onClick}
      className={classes.toggleButtonStyle}
      size="large"
    >
      <Badge
        className={classes.toggleButtonStyleBadge}
        classes={{ badge: classes.badgeRed }}
        badgeContent={count}
        color="primary"
      >
        <ArrowBackIosIcon className={classes.toggleButtonStyleArrow} />
        <AccountBalanceWalletOutlinedIcon
          className={classes.toggleButtonStyleWallet}
        />
      </Badge>
    </IconButton>
  );
};
export default SideToggleButton;
