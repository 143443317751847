import { apiOptions, apiRequest } from "../../index.js";
import { API_HEADERS_TEMP, API_INTEGRATION_SERVICE_URL } from "../../constants";

const API_SERVICES = `/services`;

export const services = {
  getAll: async (params, filters) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_SERVICES}`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  get: async (id, params, filters) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_SERVICES}/${id}`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  execute: async (id, params, filters) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_SERVICES}/${id}/execute`,
      method: "post",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
};
