import React, { useState } from "react";
import { MiIcon, MiModal, MiInputTextbox } from "@miview/components";
import { useTheme, Typography, Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { en } from "@miview/constants";
import { mdiCloseBox } from "@mdi/js";

const useStyles = makeStyles()((theme) => ({
  title: {
    color: theme.palette.primary.red,
    fontSize: 20,
    fontWeight: "bold",
    alignItems: "center",
  },
  description: {
    color: theme.palette.dark.bluegrey,
    fontSize: 12,
    align: "center",
    padding: 20,
  },
  iconBackground: {
    width: "50px",
    height: "50px",
    borderRadius: "50px",
    marginBottom: "20px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.light.grey,
  },
}));

const CancelQuoteModal = ({
  isCancelModalOpen,
  closeCancelModal,
  cancelQuote,
}) => {
  const [reason, setReason] = useState("");
  const theme = useTheme();
  const { classes } = useStyles();
  const actions = [
    {
      onClick: () => {
        closeCancelModal();
        setReason("");
      },
      color: theme.palette.primary.grey,
      name: en.close,
      inverse: false,
    },
    {
      onClick: () => {
        cancelQuote(reason);
        setReason("");
      },
      color: theme.palette.primary.red,
      name: en.confirm,
      inverse: true,
      disabled: !reason,
    },
  ];

  return (
    <MiModal
      open={isCancelModalOpen}
      actions={actions}
      color={theme.palette.primary.red}
    >
      <Box display="flex" justifyContent="center" maxWidth="sx">
        <div className={classes.iconBackground}>
          <MiIcon
            path={mdiCloseBox}
            size={1}
            color={theme.palette.primary.red}
          />
        </div>
      </Box>
      <Typography align="center" component="div" className={classes.title}>
        {en.cancelQuoteRequest}
      </Typography>
      <Typography align="center" className={classes.description}>
        {en.cancelQuoteDescription}
      </Typography>
      <MiInputTextbox
        labelText={en.cancelReason}
        labelTextColor={theme.palette.primary.red}
        value={reason}
        handleChange={(e) => setReason(e.target.value)}
      />
    </MiModal>
  );
};

export default React.memo(CancelQuoteModal);
