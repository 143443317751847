import React, { useState } from "react";
import { MiModal } from "../../../components/Standard/MiView";
import PropTypes from "prop-types";
import { propertyService } from "@miview/api";
import { HTTP_STATUSES, TOAST_TYPES } from "@miview/constants";
import { MiRadioGroup, MiButton, MiInputTextbox } from "@miview/components";
import { createFilter } from "@miview/utils";
import { createToast } from "@miview/toast";

export const ImportRoughModal = ({
  propertyId,
  setImportRoughModal,
  invokeRefresh,
}) => {
  const [importAddress, setImportAddress] = useState("");
  const [importOptions, setImportOptions] = useState([]);
  const [importFromPropertyId, setimportFromPropertyId] = useState(-1);

  const getPropertiesOnEnter = (e) => {
    if (e.key !== "Enter") {
      return;
    }

    getProperties();
  };

  const setImportFromProperty = (value) => {
    const selectedItem = importOptions.find((item) => item.value === value);
    if (selectedItem?.id) {
      setimportFromPropertyId(selectedItem.id);

      const newImportOptions = importOptions.map((option) => ({
        ...option,
        isChecked: selectedItem.id === option.id,
      }));
      setImportOptions(newImportOptions);
    }
  };

  const getProperties = async () => {
    const filterHelper = createFilter();
    const filters = filterHelper.contains({
      addressLine1: importAddress,
    });
    const response = await propertyService.getAll({ filters });
    if (response.status === HTTP_STATUSES.OK) {
      const result = response.data;
      const mapped = result.map((item) => {
        return {
          id: item.propertyId,
          value: item.addressLine1,
          label: item.addressLine1,
          isChecked: false,
        };
      });
      setImportOptions(mapped);
    }
  };

  const importRough = async () => {
    const response = await propertyService.importRough(propertyId, {
      importFromPropertyId: importFromPropertyId,
    });

    if (response.status === HTTP_STATUSES.OK) {
      invokeRefresh();
    } else if (response.status === HTTP_STATUSES.UNAUTHORIZED) {
      createToast(
        "You do not have permission to import rough plan",
        TOAST_TYPES.ERROR
      );
    } else {
      createToast(response.errorBody, TOAST_TYPES.ERROR);
    }
  };

  return (
    <MiModal
      title="Import Rough Details"
      saveAction={() => importRough()}
      toggle={() => setImportRoughModal(false)}
      saveTitle="Copy"
      saveDisabled={importFromPropertyId === -1}
    >
      <div style={styles.searchBox}>
        <MiInputTextbox
          labelText="Address"
          value={importAddress}
          styles={{ flex: 1 }}
          handleChange={(e) => setImportAddress(e.target.value)}
          onKeyDown={getPropertiesOnEnter}
        />
        <MiButton title="Search" onClick={() => getProperties()} />
      </div>
      {importOptions && (
        <MiRadioGroup
          data={importOptions}
          handleChange={setImportFromProperty}
        />
      )}
    </MiModal>
  );
};

const styles = {
  searchBox: {
    display: "flex",
    alignItems: "center",
  },
};

ImportRoughModal.propTypes = {
  propertyId: PropTypes.string,
  setImportRoughModal: PropTypes.func,
  invokeRefresh: PropTypes.func,
};
