import React, { useState, useEffect } from "react";
import { Typography, Grid, Drawer, useTheme, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiIcon, MiAccordion, MiLoader } from "@miview/components";
import PropTypes from "prop-types";
import { StatusChip } from "../../../../components";
import { HTTP_STATUSES } from "@miview/constants";
import { mdiClose } from "@mdi/js";
import { accountService } from "@miview/api";
import en from "../../../../locale/en.json";
import { createFilter } from "@miview/utils";
import SupplierBranchDrawerInfo from "./SupplierBranchDrawerInfo";
import { useComponentState } from "@miview/hooks";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 10,
    width: 531,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 10px",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  detailsContainer: {
    paddingRight: "50px",
    paddingLeft: "50px",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerAlignment: {
    marginRight: 30,
    fontWeight: theme.fontWeight.medium,
    fontSize: 21,
    color: theme.palette.secondary.bluegrey,
  },
  closeIcon: {
    color: theme.palette.dark.bluegrey,
    cursor: "pointer",
  },
  accountText: {
    color: theme.palette.primary.blue,
    padding: 10,
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.medium,
  },
  boxColor: {
    backgroundColor: theme.palette.light.grey,
    padding: 10,
    marginBottom: 15,
  },
  text: {
    textAlign: "center",
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.xlarge,
    color: theme.palette.primary.bluegrey,
  },
  secondaryText: {
    textAlign: "center",
    color: theme.palette.medium.bluegrey,
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.medium,
  },
  loader: {
    marginTop: 20,
  },
}));
const SupplierBranchesDrawer = ({ onCancel, data }) => {
  const [branches, setBranches] = useState([]);
  const { classes } = useStyles();
  const theme = useTheme();
  const stateManager = useComponentState();

  useEffect(() => {
    getBranches();
  }, []);

  const getBranches = async () => {
    stateManager.run(async () => {
      let response = await accountService.accounts.getAll({
        filters: createFilter().equals({
          parentId: data.id,
        }),
      });
      if (response.status === HTTP_STATUSES.OK) {
        setBranches(response.data);
      }
    });
  };

  const accordionConfig = branches.map((item) => {
    return {
      title: (
        <div className={classes.titleContainer}>
          <Typography>{item.name}</Typography>
          <StatusChip status={item.status} />
        </div>
      ),
      detail: (
        <div className={classes.detailsContainer}>
          <SupplierBranchDrawerInfo account={item} />
        </div>
      ),
    };
  });
  const renderAccordionDetail = (obj) => <div>{obj.detail}</div>;

  const drawerContainer = () => {
    return (
      <Grid className={classes.container}>
        <Grid className={classes.headerContainer}>
          <Grid className={classes.header}>
            <Typography className={classes.headerAlignment}>
              {data.name}
            </Typography>
            <StatusChip status={data.status} />
          </Grid>
          <MiIcon
            path={mdiClose}
            color={theme.palette.dark.bluegrey}
            onClick={onCancel}
            className={classes.closeIcon}
          />
        </Grid>
        <Typography
          className={classes.accountText}
        >{`#${data.number}`}</Typography>
        {stateManager.isBusy() && <MiLoader type="linear" />}
        <Grid className={classes.boxColor}>
          <Typography className={classes.text}>{en.branches}</Typography>
          <Typography
            className={classes.secondaryText}
          >{`${branches?.length} ${en.total}`}</Typography>
        </Grid>
        <Divider />
        <MiAccordion
          config={accordionConfig}
          renderDetail={renderAccordionDetail}
        />
      </Grid>
    );
  };

  return (
    <>
      {stateManager.statusTag("branchesDrawerStatusTag")}
      <Drawer anchor="right" open={true}>
        {drawerContainer()}
      </Drawer>
    </>
  );
};

SupplierBranchesDrawer.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default React.memo(SupplierBranchesDrawer);
