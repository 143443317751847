import React, { useEffect, useState } from "react";
import {
  walkTypeService,
  failureTypeService,
  accountServiceLegacy as accountService,
  systemTypeService,
  walkTypeTemplateService,
  issueDescriptionService,
} from "@miview/api";
import { useRouter, useEdit, useComponentState } from "@miview/hooks";
import {
  UI_TAB_LABELS,
  PAGINATION,
  HTTP_STATUSES,
  STAGE_STATUS_OPTIONS,
  WALK_STATUS_OPTIONS,
} from "@miview/constants";
import { toList, checkIsNumber } from "@miview/utils";
import { MiHeader } from "../../../components/Standard/MiView";
import {
  MiButton,
  MiSectionHeader,
  MiIcon,
  MiList,
  MiSnackbar,
  MiTab,
  MiDetailFields,
  MiModal,
  MiBox,
  MiModalConfirm,
} from "@miview/components";
import { useTheme } from "@mui/material/styles";
import StageWalkFailureReasons from "./StageWalkFailureReasons";
import { StageWalkItemFailureReasons } from "./StageWalkItemFailureReasons";
import { CreateWalkTypePredecessorModal } from "./CreateWalkTypePredecessorModal";
import { DeleteWalkTypePredecessorModal } from "./DeleteWalkTypePredecessorModal";
import { PayCalculationCollapse } from "./PayCalculationCollapse";
import { mdiCheck, mdiDelete, mdiPlus } from "@mdi/js";

const PROPERTY_STAGE_STATUSES = [
  { label: "Not Ready", value: 22 },
  { label: "Partially Ready", value: 6 },
  { label: "Ready", value: 5 },
  { label: "Not Scheduled", value: 1 },
  { label: "Scheduled", value: 2 },
  { label: "In Progress", value: 3 },
  { label: "Ready for Inspection", value: 73 },
  { label: "Complete", value: 4 },
  { label: "Cancelled", value: 52 },
];

const INITIAL_REASON = {
  failureTypeName: "",
  causeName: "",
  failureTypeId: "",
  stageStatusId: "",
  walkStatusId: "",
  reviewedWorkStatusId: "",
  description: "",
};

const StageWalkTypeDetails = () => {
  const { params, goBack } = useRouter();
  const { id: walkTypeId } = params;
  const stateManager = useComponentState();
  const theme = useTheme();

  //main data
  const [walkType, setWalkType] = useState({});
  const [targetTypeIds, setTargetTypeIds] = useState([]);
  const [tandemTypeIds, setTandemTypeIds] = useState([]);
  const [assignableTitleIds, setAssignableTitleIds] = useState([]);
  const [walkFailureReasons, setWalkFailureReasons] = useState([]);
  const [failureTypes, setFailureTypes] = useState([]);
  const [itemFailureTypes, setItemFailureTypes] = useState([]);

  //ux state management
  const [activeTab, setActiveTab] = useState(UI_TAB_LABELS.DETAILS);
  const [editMode, setEditMode] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isCreatePredecessorModalOpen, setIsCreatePredecessorModalOpen] =
    useState(false);
  const [isDeletePredecessorModalOpen, setIsDeletePredecessorModalOpen] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [addFailReason, setAddFailReason] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteReason, setDeleteReason] = useState(0);
  const [selectedFailReason, setSelectedFailReason] = useState(INITIAL_REASON);
  const [canHaveTandemTypes, setCanHaveTandemTypes] = useState(false);

  //dropdowns
  const [titles, setTitles] = useState([]);
  const [walkTypeTemplates, setWalkTypeTemplates] = useState([]);
  const [recordTypes, setRecordTypes] = useState([]);
  const [timeFrames, setTimeFrames] = useState([]);
  const [jobWalkTypes, setJobWalkTypes] = useState([]);

  const edit = useEdit(walkType);
  const editFailReasons = useEdit(selectedFailReason);
  const tabs = [
    {
      value: UI_TAB_LABELS.DETAILS,
      label: "Details",
    },
    {
      value: UI_TAB_LABELS.WALK_FAILURE_REASONS,
      label: "Walk Failure Reasons",
    },
    {
      value: UI_TAB_LABELS.ITEM_FAILURE_REASONS,
      label: "Item Failure Reasons",
    },
    {
      value: UI_TAB_LABELS.WALK_TYPE_PREDECESSORS,
      label: "Prerequisites",
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getWalkType();
    getWalkFailureReasons();
    getFailureTypes();
    getItemFailureTypes();
    getJobWalkTypes();
    getRecordTypes();
    getTimeFrames();
    getTitles();
    getWalkTypeTemplates();
  };

  const getJobWalkTypes = () => {
    stateManager.run(async () => {
      const response = await walkTypeService.getWalkTypes({});
      if (response.status === HTTP_STATUSES.OK) {
        const jobWalkTypes = response.data
          .filter((walk) => walk.walkTypeId !== Number(walkTypeId))
          .map(({ walkTypeId, walkTypeName, tandemTypeIds }) => {
            return {
              value: walkTypeId,
              label: walkTypeName,
              tandemTypeIds,
            };
          });

        setJobWalkTypes(jobWalkTypes);

        const canHaveTandemTypes = !jobWalkTypes.some((type) =>
          type.tandemTypeIds.includes(Number(walkTypeId))
        );
        setCanHaveTandemTypes(canHaveTandemTypes);
      }
    });
  };
  const getTimeFrames = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: "TimeframeType",
      });
      if (response.status === HTTP_STATUSES.OK) {
        const timeFrames = toList(
          response?.data.filter((x) => x.systemTypeId < 52),
          "systemTypeId",
          "mainValue"
        );
        setTimeFrames(timeFrames);
      }
    });
  };
  const getRecordTypes = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: "RecordType",
      });
      if (response.status === HTTP_STATUSES.OK) {
        const recordTypes = toList(response.data, "systemTypeId", "mainValue");
        setRecordTypes(recordTypes);
      }
    });
  };
  const getTitles = async () => {
    stateManager.run(async () => {
      const response = await accountService.getTitles({
        id: localStorage.accountId,
        params: { orderBy: "name" },
      });
      if (response.status === HTTP_STATUSES.OK) {
        const titles = response?.data.map(({ id, name }) => {
          return {
            value: id,
            label: name,
          };
        });
        setTitles(titles);
      }
    });
  };

  const getWalkTypeTemplates = () => {
    stateManager.run(async () => {
      const response = await walkTypeTemplateService.getAll();
      if (response.status === HTTP_STATUSES.OK) {
        const walkTypeTemplates = toList(response.data, "id", "name");
        setWalkTypeTemplates(walkTypeTemplates);
      }
    });
  };

  const getWalkType = () => {
    stateManager.run(async () => {
      const response = await walkTypeService.getWalkType(walkTypeId);
      if (response.status === HTTP_STATUSES.OK) {
        setWalkType(response.data);
        setTargetTypeIds(response.data.walkTypeTargetIds);
        setTandemTypeIds(response.data.tandemTypeIds);
        setAssignableTitleIds(response.data.assignableTitleIds);
      }
    });
  };

  const getWalkFailureReasons = () => {
    stateManager.run(async () => {
      const response = await walkTypeService.getFailureReasonsByWalkType(
        walkTypeId
      );
      if (response.status === HTTP_STATUSES.OK) {
        setWalkFailureReasons(response.data);
      }
    });
  };

  const getFailureTypes = () => {
    stateManager.run(async () => {
      const response = await failureTypeService.getFailureTypes();
      if (response.status === HTTP_STATUSES.OK) {
        setFailureTypes(response.data);
      }
    });
  };

  const getItemFailureTypes = () => {
    stateManager.run(async () => {
      const response = await issueDescriptionService.getAll({});
      if (response.status === HTTP_STATUSES.OK) {
        setItemFailureTypes(response.data);
      }
    });
  };

  const isValid = () => {
    return edit.getValue("walkTypeName") && edit.getValue("recordType");
  };

  const formattedFailureReasonsRequestBody = () => {
    return Object.values(walkFailureReasons).map((reason) => {
      return {
        failureTypeId: reason.failureTypeId,
        stageStatusId: reason.stageStatusId,
        walkStatusId: reason.walkStatusId,
        reviewedWorkStatusId: reason.reviewedWorkStatusId,
        description: reason.description,
      };
    });
  };

  const saveDetails = () => {
    stateManager.run(async () => {
      await Promise.all([
        walkTypeService.updateTargetTypes(walkTypeId, targetTypeIds),
        walkTypeService.updateTandemTypes(walkTypeId, tandemTypeIds),
        walkTypeService.updateAssignableTitles(walkTypeId, assignableTitleIds),
        walkTypeService.update(walkTypeId, edit.edits),
      ]);
      getWalkType();
    });
  };

  const saveFailureReasons = () => {
    stateManager.run(async () => {
      await walkTypeService.saveFailureReasonsByWalkType(
        walkTypeId,
        formattedFailureReasonsRequestBody()
      );
      getWalkFailureReasons();
    });
  };

  const saveEdits = () => {
    if (activeTab === UI_TAB_LABELS.DETAILS) {
      saveDetails();
    } else if (activeTab === UI_TAB_LABELS.WALK_FAILURE_REASONS) {
      saveFailureReasons();
    }
    setEditMode(!editMode);
    edit.reset();
  };

  const getRecordTypeValue = (recordName) => {
    const recordTypeObject = recordTypes?.find(
      (recordType) => recordType.text === recordName
    );
    return recordTypeObject?.value;
  };

  if (
    edit.getValue("recordType") &&
    edit.getValue("recordType") === getRecordTypeValue("Job")
  ) {
    tabs.push({
      value: UI_TAB_LABELS.PAY_CALCULATION,
      label: "Pay Calculation",
    });
  }

  const cancelEdit = () => {
    setEditMode(!editMode);
    edit.reset();
    setTargetTypeIds(walkType?.walkTypeTargetIds);
    setTandemTypeIds(walkType?.tandemTypeIds);
    setAssignableTitleIds(walkType?.assignableTitleIds);
  };

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const detailFields = [
    {
      label: "Name",
      value: edit.getValue("walkTypeName"),
      setValue: (e) => edit.update({ walkTypeName: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Short Name",
      value: edit.getValue("shortName"),
      setValue: (e) => edit.update({ shortName: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Walk Type Template",
      fieldType: "select",
      value: edit.getValue("walkTypeTemplateId"),
      options: walkTypeTemplates,
      setValue: (e) => edit.update({ walkTypeTemplateId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Auto Assign Title",
      fieldType: "select",
      value: edit.getValue("titleId"),
      options: titles,
      setValue: (e) => edit.update({ titleId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
      selectNone: true,
    },
    {
      label: "Record Type",
      fieldType: "select",
      value: edit.getValue("recordType"),
      options: recordTypes,
      setValue: (e) => edit.update({ recordType: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Auto Assign From WorkType",
      value: edit.getValue("autoAssignFromWalkTypeId"),
      fieldType: "select",
      setValue: (e) => edit.update({ autoAssignFromWalkTypeId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
      options: jobWalkTypes,
      selectNone: true,
    },
    {
      label: "Inspecting Job Type",
      value: targetTypeIds,
      fieldType: "multiSelect",
      options: jobWalkTypes.length ? jobWalkTypes : [{}],
      setValue: (e) => setTargetTypeIds(e.target.value),
      disabled: !editMode,
      readOnly: !editMode,
      clickButton: toggleEdit,
      width: "50%",
    },
    {
      label: "Assignable Titles",
      fieldType: "multiSelect",
      value: assignableTitleIds,
      options: titles.length ? titles : [{}],
      setValue: (e) => setAssignableTitleIds(e.target.value),
      width: "50%",
      disabled: !editMode,
      readOnly: !editMode,
      clickButton: toggleEdit,
    },
    {
      label: "Tandem Work Types",
      value: tandemTypeIds,
      fieldType: "multiSelect",
      options: jobWalkTypes.length ? jobWalkTypes : [{}],
      setValue: (e) => setTandemTypeIds(e.target.value),
      width: "50%",
      disabled: !canHaveTandemTypes || !editMode,
      readOnly: !canHaveTandemTypes || !editMode,
      clickButton: canHaveTandemTypes && toggleEdit,
    },
    {
      fieldType: "emptyField",
      width: "50%",
    },
    {
      label: "Allow Manual Measurement",
      fieldType: "checkbox",
      value: !!edit.getValue("allowManualMeasurement"),
      setValue: () =>
        edit.update({
          allowManualMeasurement: !edit.getValue("allowManualMeasurement"),
        }),
      readOnly: !editMode,
      clickButton: toggleEdit,
      width: "50%",
    },
    {
      label: "Priority Order",
      value: edit.getValue("priorityOrder"),
      fieldType: "number",
      id: "priorityOrder",
      setValue: (e) => {
        if (!checkIsNumber(e)) {
          edit.update({ priorityOrder: e });
        }
      },
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Display Order",
      value: edit.getValue("displayOrder"),
      fieldType: "number",
      id: "priorityOrder",
      setValue: (e) => edit.update({ displayOrder: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Timeframe",
      fieldType: "select",
      value: edit.getValue("timeFrameTypeId"),
      options: timeFrames,
      setValue: (e) => edit.update({ timeFrameTypeId: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Attempt Interval",
      value: edit.getValue("attemptInterval"),
      fieldType: "number",
      id: "attemptInterval",
      setValue: (e) => {
        if (!checkIsNumber(e)) {
          edit.update({ attemptInterval: e });
        }
      },
      width: "50%",
      options: jobWalkTypes,
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Due Offset Days",
      value: edit.getValue("stageScheduleOffset"),
      fieldType: "number",
      setValue: (e) => {
        if (!checkIsNumber(e)) {
          edit.update({ stageScheduleOffset: e });
        }
      },
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Extra Floors Due Offset Days",
      value: edit.getValue("extraFloorDueOffsetDays"),
      fieldType: "number",
      id: "extraFloorDueOffsetDays",
      setValue: (e) => {
        if (!checkIsNumber(e)) {
          edit.update({ stageScheduleOffset: e });
        }
      },
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Due Time",
      value: edit.getValue("dueTime"),
      setValue: (e) => edit.update({ dueTime: e }),
      clickButton: toggleEdit,
      width: "50%",
      readOnly: !editMode,
    },
    {
      label: "Scheduled Offset Days",
      value: edit.getValue("scheduledOffsetDays"),
      fieldType: "number",
      setValue: (e) => edit.update({ scheduledOffsetDays: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "On Complete Move Stage To",
      fieldType: "select",
      value: edit.getValue("onSequenceCompleteStageStatus") || "",
      options: PROPERTY_STAGE_STATUSES,
      setValue: (e) => edit.update({ onSequenceCompleteStageStatus: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
      selectNone: true,
    },
    {
      label: "First Rewalk Grace Period",
      value: edit.getValue("firstFailureGracePeriod"),
      fieldType: "number",
      id: "firstFailureGracePeriod",
      setValue: (e) => {
        if (!checkIsNumber(e)) {
          edit.update({ firstFailureGracePeriod: e });
        }
      },
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Base Pay Amount",
      value: edit.getValue("basePayAmount"),
      fieldType: "number",
      id: "basePayAmount",
      setValue: (e) => {
        if (!checkIsNumber(e)) {
          edit.update({ basePayAmount: e });
        }
      },
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Geofence Distance",
      value: edit.getValue("geofenceDistance"),
      fieldType: "number",
      id: "geofenceDistance",
      setValue: (e) => {
        if (!checkIsNumber(e)) {
          edit.update({ geofenceDistance: e });
        }
      },
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Reschedule Until Signoff",
      fieldType: "checkbox",
      value: !!edit.getValue("rescheduleUntilSignoff"),
      setValue: () =>
        edit.update({
          rescheduleUntilSignoff: !edit.getValue("rescheduleUntilSignoff"),
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Signoff Text",
      value: edit.getValue("signoffText"),
      setValue: (e) => edit.update({ signoffText: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Auto Assign",
      fieldType: "checkbox",
      value: !!edit.getValue("autoAssign"),
      setValue: () =>
        edit.update({
          autoAssign: !edit.getValue("autoAssign"),
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "16%",
    },
    {
      label: "Rewalk on Fail",
      fieldType: "checkbox",
      value: !!edit.getValue("rewalkOnFail"),
      setValue: () =>
        edit.update({
          rewalkOnFail: !edit.getValue("rewalkOnFail"),
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "16%",
    },
    {
      label: "Rewalk on Cancel",
      fieldType: "checkbox",
      value: !!edit.getValue("rewalkOnCancel"),
      setValue: () =>
        edit.update({
          rewalkOnCancel: !edit.getValue("rewalkOnCancel"),
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "16%",
    },
    {
      label: "Can Preview",
      fieldType: "checkbox",
      value: !!edit.getValue("canPreview"),
      setValue: () =>
        edit.update({
          canPreview: !edit.getValue("canPreview"),
        }),
      clickButton: toggleEdit,
      width: "16%",
      readOnly: !editMode,
    },
    {
      label: "Is Immediately Scheduled",
      fieldType: "checkbox",
      value: !!edit.getValue("isImmediatelyScheduled"),
      setValue: () =>
        edit.update({
          isImmediatelyScheduled: !edit.getValue("isImmediatelyScheduled"),
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "16%",
    },
    {
      label: "Update Property Location on Start",
      fieldType: "checkbox",
      value: !!edit.getValue("updatePropertyLocation"),
      setValue: () =>
        edit.update({
          updatePropertyLocation: !edit.getValue("updatePropertyLocation"),
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "16%",
    },
    {
      label: "Hide Previously Completed Items",
      fieldType: "checkbox",
      value: !!edit.getValue("hideCompletedItems"),
      setValue: () =>
        edit.update({
          hideCompletedItems: !edit.getValue("hideCompletedItems"),
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "16%",
    },
  ];

  const onTabClick = (tab) => {
    if (!tab) {
      return;
    }
    if (editMode) {
      toggleConfirmModal();
    } else {
      setActiveTab(tab);
    }
  };

  const toggleConfirmModal = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const columns = [
    {
      field: "predecessorName",
      headerName: "Name",
      align: "left",
      flex: 1,
    },
    {
      field: "isCompletionRequired",
      headerName: "Required",
      align: "left",
      renderCell: (params) => {
        return (
          params.value && (
            <MiIcon path={mdiCheck} color={theme.palette.secondary.grey} />
          )
        );
      },
    },
  ];

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => handleDeletePredecessor(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={"20px"} />,
      },
    ];
  };

  const handleDeletePredecessor = (row) => {
    setSelectedRow(row);
    setIsDeletePredecessorModalOpen(true);
  };

  const openCreatePredecessorModal = () => {
    setIsCreatePredecessorModalOpen(true);
  };

  const closeCreatePredecessorModal = () => {
    setIsCreatePredecessorModalOpen(false);
    getWalkType();
  };

  const closeDeletePredecessorModal = (success) => {
    if (success) {
      getWalkType();
    }
    setIsDeletePredecessorModalOpen(false);
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Prerequisite"
        icon={mdiPlus}
        inverse={true}
        onClick={openCreatePredecessorModal}
        color={theme.palette.primary.main}
      />
    );
  };

  const addFailReasonActionButton = (type) => {
    return (
      <MiButton
        color={theme.palette.primary.main}
        title={`${type} Failure Reason`}
        icon={mdiPlus}
        inverse
        onClick={openModal}
      />
    );
  };

  const handleOnchange = () => {
    stateManager.run(async () => {
      const response = await walkTypeService.saveFailureReasonsByWalkType(
        walkType.walkTypeId,
        editFailReasons.getValue("failureTypeId"),
        editFailReasons.edits
      );
      if (response.status === HTTP_STATUSES.OK) {
        getWalkFailureReasons();
        setAddFailReason(false);
        editFailReasons.reset();
      }
    });
  };

  const handleSaveItemFailure = () => {
    const toastMessage = "Item Failure added succesfully";
    stateManager.run(async () => {
      const response = await walkTypeService.update(
        walkType.walkTypeId,
        {
          issueDescriptionIds: [
            ...walkType.issueDescriptions.map((elem) => elem.id),
            editFailReasons.getValue("failureTypeId"),
          ],
        },
        toastMessage
      );
      if (response.status === HTTP_STATUSES.OK) {
        getWalkType();
        setAddFailReason(false);
        editFailReasons.reset();
      }
    });
  };

  const closeModal = () => {
    setSelectedFailReason(INITIAL_REASON);
    editFailReasons.reset();
    setAddFailReason(false);
  };

  const openModal = () => {
    editFailReasons.reset();
    setAddFailReason(true);
  };

  const addWalkFailReasonColumn = [
    {
      label: "Fail Type",
      value: editFailReasons.getValue("failureTypeId"),
      fieldType: selectedFailReason.id ? null : "select",
      options: failureTypes
        .filter(
          (x) =>
            !walkFailureReasons.map((item) => item.failureTypeId).includes(x.id)
        )
        .map((e) => ({ value: e.id, text: e.name })),
      setValue: (e) => editFailReasons.update({ failureTypeId: e }),
      readOnly: selectedFailReason.id,
      width: "50%",
    },
    {
      label: "Stage Status",
      value: editFailReasons.getValue("stageStatusId"),
      fieldType: "select",
      options: STAGE_STATUS_OPTIONS,
      setValue: (e) => editFailReasons.update({ stageStatusId: e }),
      clickButton: () => setEditMode(!editMode),
      width: "50%",
      selectNone: true,
    },
    {
      label: "Work Status",
      value: editFailReasons.getValue("walkStatusId"),
      fieldType: "select",
      options: WALK_STATUS_OPTIONS,
      setValue: (e) => editFailReasons.update({ walkStatusId: e }),
      clickButton: () => setEditMode(!editMode),
      width: "50%",
      selectNone: true,
    },
    {
      label: "Reviewed Work Status",
      value: editFailReasons.getValue("reviewedWorkStatusId"),
      fieldType: "select",
      options: WALK_STATUS_OPTIONS,
      setValue: (e) => editFailReasons.update({ reviewedWorkStatusId: e }),
      clickButton: () => setEditMode(!editMode),
      width: "50%",
      selectNone: true,
    },
    {
      label: "Description",
      value: editFailReasons.getValue("description"),
      setValue: (e) => editFailReasons.update({ description: e }),
      clickButton: () => setEditMode(!editMode),
      width: "50%",
    },
  ];

  const addItemFailReasonColumn = [
    {
      label: "Fail Type",
      value: editFailReasons.getValue("failureTypeId"),
      fieldType: "select",
      options: itemFailureTypes
        .filter(
          (elem) =>
            elem.id !==
            walkType.issueDescriptions?.find((item) => item.id === elem.id)?.id
        )
        .map((e) => ({
          value: e.id,
          text: e.description,
        })),
      setValue: (e) => editFailReasons.update({ failureTypeId: e }),
      readOnly: selectedFailReason.id,
      width: "100%",
    },
  ];

  const handleEditModal = (row) => {
    editFailReasons.update(row);
    setSelectedFailReason(row);
    setAddFailReason(true);
  };

  const closeDeleteReasonModal = () => {
    setConfirmModal(false);
  };

  const handleDeleteFailReason = () => {
    stateManager.run(async () => {
      const response = await walkTypeService.deleteWalkTypeFailure(
        walkType.walkTypeId,
        deleteReason
      );
      if (response.status === HTTP_STATUSES.OK) {
        getWalkFailureReasons();
      }
    });
    setConfirmModal(false);
  };

  const handleDeleteItemFailReason = () => {
    const toastMessage = "Item Failure removed succesfully";
    stateManager.run(async () => {
      const response = await walkTypeService.update(
        walkType.walkTypeId,
        {
          issueDescriptionIds: walkType.issueDescriptions
            .map((e) => e.id)
            .filter((item) => item !== deleteReason),
        },
        toastMessage
      );
      if (response.status === HTTP_STATUSES.OK) {
        getWalkType();
      }
    });
    setConfirmModal(false);
  };

  const openDeleteReasonModal = (row) => {
    setDeleteReason(row);
    setConfirmModal(true);
  };

  return (
    <>
      {stateManager.statusTag("walkTypeDetailStatus")}
      <MiHeader
        color={theme.palette.primary.main}
        title={walkType.walkTypeName}
        inverse={false}
        icon="arrow_back"
        backAction={goBack}
        fieldSet={[{ label: "Description", value: "Stage walk type" }]}
      >
        {activeTab === UI_TAB_LABELS.WALK_TYPE_PREDECESSORS && actionButton()}
        {activeTab === UI_TAB_LABELS.WALK_FAILURE_REASONS &&
          addFailReasonActionButton("Walk")}
        {activeTab === UI_TAB_LABELS.ITEM_FAILURE_REASONS &&
          addFailReasonActionButton("Item")}
      </MiHeader>
      <MiSnackbar
        visible={editMode}
        handleCancelClick={cancelEdit}
        disableSave={!isValid()}
        handleSaveClick={saveEdits}
      />
      <div style={cardMain}>
        <MiTab tabs={tabs} currenttab={activeTab} onTabChange={onTabClick} />
      </div>
      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        {activeTab === UI_TAB_LABELS.DETAILS && (
          <MiBox styles={{ marginBottom: 40 }}>
            <MiDetailFields detailFields={detailFields} />
          </MiBox>
        )}
        {activeTab === UI_TAB_LABELS.WALK_FAILURE_REASONS && (
          <MiBox>
            <StageWalkFailureReasons
              failureReasons={walkFailureReasons}
              walkType={walkType}
              openDeleteReasonModal={openDeleteReasonModal}
              handleEditModal={handleEditModal}
            />
            <MiModalConfirm
              open={confirmModal}
              title={"Delete Walk Failure Reason?"}
              handlePositive={handleDeleteFailReason}
              handleNegative={closeDeleteReasonModal}
            />
            <MiModal
              title={
                selectedFailReason.id
                  ? "Edit Walk Failure Reason"
                  : "Add Walk Failure Reason"
              }
              open={addFailReason}
              onClose={closeModal}
              actions={[
                {
                  name: "Cancel",
                  onClick: closeModal,
                  color: theme.palette.medium.grey,
                  inverse: false,
                },
                {
                  name: "Save",
                  onClick: handleOnchange,
                  color: theme.palette.primary.main,
                  inverse: true,
                },
              ]}
            >
              <MiDetailFields detailFields={addWalkFailReasonColumn} />
            </MiModal>
          </MiBox>
        )}

        {activeTab === UI_TAB_LABELS.ITEM_FAILURE_REASONS && (
          <MiBox>
            <StageWalkItemFailureReasons
              failureReasons={walkType.issueDescriptions || []}
              walkType={walkType}
              openDeleteReasonModal={openDeleteReasonModal}
            />
            <MiModalConfirm
              open={confirmModal}
              title={"Delete Item Failure Reason?"}
              handlePositive={handleDeleteItemFailReason}
              handleNegative={closeDeleteReasonModal}
            />
            <MiModal
              title={"Add Item Failure Reason"}
              open={addFailReason}
              onClose={closeModal}
              actions={[
                {
                  name: "Cancel",
                  onClick: closeModal,
                  color: theme.palette.medium.grey,
                  inverse: false,
                },
                {
                  name: "Save",
                  onClick: handleSaveItemFailure,
                  color: theme.palette.primary.main,
                  inverse: true,
                },
              ]}
            >
              <MiDetailFields detailFields={addItemFailReasonColumn} />
            </MiModal>
          </MiBox>
        )}

        {activeTab === UI_TAB_LABELS.WALK_TYPE_PREDECESSORS && (
          <div>
            <CreateWalkTypePredecessorModal
              isVisible={isCreatePredecessorModalOpen}
              close={closeCreatePredecessorModal}
              walkTypeId={Number(walkTypeId)}
            />
            <DeleteWalkTypePredecessorModal
              isVisible={isDeletePredecessorModalOpen}
              close={closeDeletePredecessorModal}
              walkTypeId={walkTypeId}
              selection={selectedRow}
            />
            <MiList
              data={walkType.predecessors}
              columns={columns}
              paginationMode={PAGINATION.CLIENT}
              getRowActions={getRowActions}
              searchColumns={["predecessorName"]}
            />
          </div>
        )}
        {activeTab === UI_TAB_LABELS.PAY_CALCULATION && (
          <MiBox>
            <MiSectionHeader icon="list" title="Pay Calculation" />
            <PayCalculationCollapse walkTypeId={walkTypeId} />
          </MiBox>
        )}
      </div>
    </>
  );
};

const cardMain = {
  marginBottom: 10,
  paddingTop: 5,
};

export default StageWalkTypeDetails;
