import React from "react";
import { Breadcrumbs, Link } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import PropTypes from "prop-types";
import { THEME } from "@miview/theme";

/**
 *
##Definition
_Breadcrumbs_ are a navigation aid that shows the route the users have traveled within the website or application so that they can easily return to the home (parent) location.

*Commonly Referred To As:* Navigation

##Example
![image.png](/.attachments/image-0c67e091-74f7-43d8-b519-bf017ad415ac.png)

##Structure & Placement

###Structure
*Breadcrumbs* are generally separated with a slash ( / ), a pipe ( | ), or a right arrow ( > ) depending on the information. We use the slash ( / ) as seen in the Web example above. They should appear on every page of an application with a few exceptions **<<DO WE HAVE ANY EXCEPTIONS>>**. The page hierarchy should show the starting location/page down through the current location/page. Breadcrumbs should never replace the main or side bar navigation.

- Level 1 = Main Route Name
- Level 2 = Child Route Name
- Level 3 = Detail Name

**<<need example>>**

###Placement
Breadcrumb navigation should always appear at the upper left corner of an application (highlighted yellow below). 

![image.png](/.attachments/image-3761f25b-8c80-42b1-928c-fa407c437e47.png)

###Icons Used
| **Icon**         | **Icon Name**   | **Notes**       |
|------------------|-----------------|-----------------|
| None | None | None |

##Guidelines
###Do's & Don'ts

| **Do's**        | **Don'ts**      |
|-----------------|-----------------|
| Default breadcrumbs to one line only | Break breadcrumbs on separate lines |
| Appear on every page unless exceptions noted | **<<need wording here>>** |
| Use a slash ( / ) as the breadcrumb separator | **<<need wording here>>** |

###Variants
####Collapsed Breadcrumb
![image.png](/.attachments/image-dab74eb6-fdff-41c8-99b3-13e46723b3a3.png)

####(Alternate Variation) - Breadcrumb with Icons
![image.png](/.attachments/image-3b9a621b-dde1-42f8-a0e0-991d23bac8cb.png)

###Sizes
**<<insert text>>**

###Color
Follow standard MiView color themes.

###Behavior (States)
Not applicable

###Content
**<<insert text>>**

##Accessibility
- Use the `aria-label="Breadcrumbs"` markup to describe this navigation.
 * Using material-ui/core/Breadcrumbs under the hood.
 * All the props passed to this components will be passed down to material-ui Breadcrumbs
 */

const useStyles = makeStyles()((theme) => {
  return {
    breadcrumbs: {
      padding: "5px",
      paddingLeft: 10,
      fontSize: 14,
      [theme.breakpoints.down("sm")]: { display: "none" },
    },
  };
});

export const MiBreadcrumbs = (props) => {
  const { config, breadcrumbcolor } = props;
  const { classes } = useStyles();

  return (
    <Breadcrumbs
      data-testid="MiBreadcrumbs"
      {...props}
      className={classes.breadcrumbs}
    >
      {Array.isArray(config) &&
        config.map((crumb, index) => {
          if (index === config.length - 1) {
            return (
              <div
                key={index}
                style={{ color: breadcrumbcolor || THEME.GREY_MEDIUM }}
              >
                {crumb.name}
              </div>
            );
          } else {
            return (
              <Link href={crumb.path} key={index}>
                <span style={{ color: breadcrumbcolor || THEME.GREY_MEDIUM }}>
                  {crumb.name}
                </span>
              </Link>
            );
          }
        })}
    </Breadcrumbs>
  );
};

MiBreadcrumbs.propTypes = {
  /**
   * color of the breadcrumb text
   */
  breadcrumbcolor: PropTypes.string,
  /**
   * json config to render breadcrumbs
   */
  config: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),
};
