import React, { useState } from "react";
import { Chip, MiPageHeader, MiLink, MiList, MiIcon } from "@miview/components";
import { propertyStageService } from "@miview/api";
import { PROPERTY_STATUSES, DETAILS } from "@miview/constants";
import { DATE_FILTER_OPERATORS } from "@miview/utils";
import { useTheme, alpha } from "@mui/material/styles";
import { useComponentState } from "@miview/hooks";
import { mdiLayersTripleOutline } from "@mdi/js";
import { formatDate } from "../../helpers/dateTools";

const Stages = () => {
  const [stages, setStages] = useState([]);
  const theme = useTheme();

  const stateManager = useComponentState();

  const STATUS_COLORS = {
    [PROPERTY_STATUSES.STAGECOMPLETE]: theme.palette.primary.green,
    [PROPERTY_STATUSES.SCHEDULED]: theme.palette.primary.blue,
    [PROPERTY_STATUSES.NOTSCHEDULED]: theme.palette.primary.bluegrey,
    [PROPERTY_STATUSES.NOTREADY]: theme.palette.primary.bluegrey,
  };

  const getColor = (val) => {
    return STATUS_COLORS[val] || theme.palette.primary.blue;
  };

  const columns = [
    {
      field: "stageTypeName",
      headerName: "Stage",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 0.6,
      sortable: true,
      renderCell: (p) => {
        return <MiLink to={`/stages/${p.row.id}`} title={p.value} />;
      },
    },
    {
      field: "addressLine1",
      headerName: "Address",
      width: 150,
      headerAlign: "left",
      align: "left",
      sortable: true,
      flex: 1.2,
      renderCell: (p) => {
        return (
          <MiLink
            to={`/homes/${p.row.propertyId}/${DETAILS}`}
            title={p.value}
          />
        );
      },
    },
    {
      field: "cityName",
      headerName: "City",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 0.7,
      sortable: true,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 0.6,
      sortable: true,
    },
    {
      field: "communityName",
      headerName: "Community",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
    },
    {
      field: "builderName",
      headerName: "Builder",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
    },
    {
      field: "internalCycleTime",
      headerName: "Internal Cycle Time",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
    },
    {
      field: "totalCycleTime",
      headerName: "Total Cycle Time",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
    },
    {
      field: "scheduledStartDate",
      headerName: "Schedule Date",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      valueFormatter: (params) => formatDate(params.value),
      filterOperators: DATE_FILTER_OPERATORS,
      sortable: true,
    },
    {
      field: "readyForInspectionDate",
      headerName: "Ready for Inspection",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
      valueFormatter: (params) => formatDate(params.value),
      filterOperators: DATE_FILTER_OPERATORS,
    },
    {
      field: "completedDate",
      headerName: "Complete Date",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
      valueFormatter: (params) => formatDate(params.value),
      filterOperators: DATE_FILTER_OPERATORS,
    },
    {
      field: "statusName",
      headerName: "Status",
      width: 150,
      headerAlign: "left",
      align: "left",
      sortable: true,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <Chip
            text={value}
            style={{
              display: "flex",
              borderRadius: "30px",
              height: "30px",
              minWidth: "80px",
              justifyContent: "center",
              padding: "0 7px 0 7px",
            }}
            color={alpha(getColor(value), 0.2)}
            textColor={getColor(value)}
          />
        );
      },
      type: "singleSelect",
      valueOptions: Object.values(PROPERTY_STATUSES),
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiLayersTripleOutline}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const getStages = async (params) => {
    return stateManager.run(async () => {
      return propertyStageService.getAll({
        sort: [{ field: "scheduledStartDate", sort: "desc" }],
        ...params,
      });
    });
  };

  return (
    <>
      <MiPageHeader
        title="Stages"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        loading={stateManager.isBusy()}
      />
      {stateManager.statusTag("stageListStatus")}
      <MiList
        data={stages}
        setData={setStages}
        dataProvider={getStages}
        defaultPageSize={50}
        getRowId={(row) => row.id}
        columns={columns}
        hideFilters
      />
    </>
  );
};

export default Stages;
