import React from "react";
import PropTypes from "prop-types";
import { Typography as MuiTypography, Divider } from "@mui/material";
import { MiLoader } from "../index.ts";
import { styled } from "@mui/system";
import { MiTHEME } from "@miview/theme";
import { URLS } from "@miview/constants";

const Container = styled("div", {
  shouldForwardProp: (props) =>
    !["containerStyles", "hasDivider", "backgroundColor"].includes(props),
})(({ backgroundColor, hasDivider, containerStyles }) => ({
  alignItems: "left",
  justifyContent: "space-between",
  borderBottom: hasDivider
    ? `1px solid ${MiTHEME.palette.lightAccent.grey || "#DDD"}`
    : "none",
  backgroundColor: backgroundColor || "#FFF",
  padding: 15,
  position: "sticky",
  zIndex: 1200,
  top: Object.values(URLS).includes(global.baseUrl) ? "85px" : "61px",
  ...containerStyles,
}));

const Typography = styled(MuiTypography, {
  shouldForwardProp: (props) => !["leftIcon", "color"].includes(props),
})(
  {
    fontWeight: "bold",
  },
  ({ color, leftIcon }) => {
    return {
      color: color,
      paddingLeft: leftIcon ? "0px" : "none",
    };
  }
);

const DetailContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const LeftContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const MiPageHeader = ({
  title,
  color,
  leftIcon,
  details,
  rightArea,
  action,
  backgroundColor,
  hasDivider,
  containerStyles,
  titleVariant = "h5",
  loading = false,
}) => {
  return (
    <div id="mipageheader" data-testid="MiPageHeader">
      <Container
        backgroundColor={backgroundColor}
        containerStyles={containerStyles}
      >
        <DetailContainer>
          <LeftContainer>
            <div style={{ cursor: "pointer" }}>{leftIcon}</div>
            <div>
              <Typography variant={titleVariant} color={color}>
                <span>&nbsp;</span>
                {title}
              </Typography>
              <DetailContainer>{details}</DetailContainer>
            </div>
          </LeftContainer>
          {rightArea}
          {action}
        </DetailContainer>
      </Container>
      {hasDivider && <Divider data-testid="MiPageHeader-Divider" />}
      {loading ? (
        <MiLoader type="linear" />
      ) : (
        <div style={{ height: 5, background: "#FFF" }}></div>
      )}
    </div>
  );
};

MiPageHeader.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  leftIcon: PropTypes.element,
  details: PropTypes.element,
  action: PropTypes.element,
  hasDivider: PropTypes.bool,
  containerStyles: PropTypes.object,
  titleVariant: PropTypes.string,
};
