import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_PROPERTY_STAGES = `${API_V3}/propertystages`;

export const propertyStageService = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: API_PROPERTY_STAGES,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_PROPERTY_STAGES}/${id}`,
    });
    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_PROPERTY_STAGES}/${id}`,
      method: "patch",
      data: request,
    });

    const toastMessageConfig = {
      success: {
        message: "Property stage saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  getStatusIntervals: async (id) => {
    const options = await apiOptions({
      url: `${API_PROPERTY_STAGES}/${id}/statusintervals`,
      method: "get",
    });
    return apiRequest(options);
  },

  getHistory: async (id) => {
    const options = await apiOptions({
      url: `${API_PROPERTY_STAGES}/${id}/history`,
    });
    return apiRequest(options);
  },

  getIssues: async (id) => {
    const options = await apiOptions({
      url: `${API_PROPERTY_STAGES}/${id}/issues`,
    });
    return apiRequest(options);
  },
};
