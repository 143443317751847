import React, { useEffect } from "react";
import { MiJournalEntries } from "./MiAccounting/MiJournalEntries";

export const AccountPayable = () => {
  useEffect(() => {
    document.title = "Accounts Payable";
  }, []);

  return <MiJournalEntries />;
};
