import React from "react";
import { MiModal, MiDetailFields } from "@miview/components";
import { useTheme } from "@mui/material";
import { useEdit, useComponentState } from "@miview/hooks";
import { walkService } from "@miview/api";
import { COMPLETE, HTTP_STATUSES } from "@miview/constants";

const GreenTagModal = ({ onClose, open, walkId, handleGetWalk }) => {
  const theme = useTheme();
  const date = new Date();
  const stateManager = useComponentState();
  const edit = useEdit();

  const onModalClose = () => {
    edit.reset();
    onClose();
  };

  const handleGreenTagSubmit = () => {
    stateManager.run(async () => {
      const response = await walkService.completeWalk(walkId, {
        ...edit.edits,
        walkStatus: COMPLETE,
      });
      if (response.status === HTTP_STATUSES.OK) {
        edit.reset();
        handleGetWalk();
        onClose();
      }
    });
  };

  const greenTagFields = [
    {
      label: "Date of Inspection",
      value: edit.getValue("walkEndDate") || date,
      required: true,
      setValue: (e) => edit.update({ walkEndDate: e }),
      width: "50%",
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
    },
    {
      label: "Reported On",
      value: edit.getValue("reviewedOnDate") || date,
      required: true,
      setValue: (e) => edit.update({ reviewedOnDate: e }),
      width: "50%",
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
    },
    {
      label: "Notes",
      value: edit.getValue("stageSummary"),
      setValue: (e) => edit.update({ stageSummary: e }),
      width: "100%",
    },
    {
      value: edit.getValue("image") || edit.getValue("imageName"),
      setValue: (e) => {
        edit.update({ image: e });
      },
      required: true,
      width: "20%",
      fieldType: "image",
      clipBoardItem: true,
    },
  ];

  return (
    <MiModal
      title="Green Tag"
      open={open}
      maxWidth={600}
      onClose={onModalClose}
      actions={[
        {
          name: "Cancel",
          style: { minWidth: 90 },
          onClick: onModalClose,
          color: theme.palette.primary.black,
          inverse: false,
        },
        {
          name: "Submit",
          onClick: handleGreenTagSubmit,
          style: { minWidth: 90 },
          color: theme.palette.secondary.green,
          inverse: true,
        },
      ]}
      loading={stateManager.isBusy()}
    >
      <MiDetailFields detailFields={greenTagFields} />
    </MiModal>
  );
};

export default GreenTagModal;
