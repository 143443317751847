import React from "react";
import PropTypes from "prop-types";
import { MiButton } from "@miview/components";
import { THEME } from "../../../components/Standard/theme";

export const SaveAndNextSectionButton = (props) => {
  return (
    <div style={styles.saveButtonContainer}>
      <MiButton
        style={styles.saveButton}
        title="Save and Next Section"
        onClick={props.onClick}
        disabled={props.disabled}
      />
    </div>
  );
};

SaveAndNextSectionButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SaveAndNextSectionButton.defaultProps = {
  onClick: () => null,
  disabled: false,
};

const styles = {
  saveButtonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: 20,
  },
  saveButton: {
    backgroundColor: THEME.GREEN_PRIMARY,
    color: "white",
    fontSize: 14,
    height: 50,
    width: 200,
  },
};
