import { useEffect, useState } from "react";
import { useRouter } from "@miview/hooks";
import {
  usePlanOptions,
  usePlan,
  useStages,
  usePropertySections,
  useMaterials,
  useWalkTypes,
} from "./";
import {
  communityService,
  accountServiceLegacy as accountService,
} from "@miview/api";
import {
  HTTP_STATUSES,
  ACCOUNT_TYPES,
  WALK_RECORD_TYPES,
} from "@miview/constants";

const handleGetCommunities = async ({ setCommunities }) => {
  const response = await communityService.getAll({
    params: { pageSize: 9999 },
  });

  if (response.status === HTTP_STATUSES.OK) {
    let communitiesList = response.data.map((c) => {
      return { value: c.id, text: c.name };
    });

    communitiesList.sort(function (a, b) {
      var nameA = a.text.toUpperCase();
      var nameB = b.text.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return setCommunities(communitiesList);
  }
};

const handleGetBuilders = async ({ setBuilders }) => {
  const response = await accountService.getAll({
    params: { pageSize: 9999, orderBy: "name" },
    filters: { type: ACCOUNT_TYPES.BUILDER },
    sort: [{ field: "name", sort: "asc" }],
  });
  if (response.data) {
    const buildersList = response.data.map((b) => {
      return { value: b.id, text: b.name };
    });
    return setBuilders(buildersList);
  }
};

export const useMasterSpecsLoader = () => {
  const router = useRouter();
  const { planId } = router.params;
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const {
    planEdit,
    builders,
    setBuilders,
    communities,
    setCommunities,
    floors,
    setPlan,
    measurementTypesBack,
    measurementTypesSide,
    setMeasurementTypesBack,
    setMeasurementTypesSide,
    stackSizes,
    setStackSizes,
    handleGetStackSizes,
    handleGetPlan,
    handleOnChangePlan,
    handleSavePlan,
    handleGetStackMeasurementTypes,
  } = usePlan();

  const { stages, setStages, getStages } = useStages();
  const { walkTypes, handleGetWalkTypes } = useWalkTypes();
  const {
    planOptions,
    setPlanOptions,
    optionTypes,
    handleGetOptionsByPlan,
    handleGetOptionTypes,
  } = usePlanOptions(planId);
  const { propertySections, handleGetPropertySections } = usePropertySections();
  const {
    materialBrands,
    setMaterialBrands,
    materialCategories,
    setMaterialCategories,
    handleGetMaterialBrands,
    handleGetMaterialCategories,
  } = useMaterials();

  const loadMasterSpecs = async () => {
    try {
      await Promise.all([
        handleGetPlan({ planId, edit: planEdit, setPlan, setLoadingMessage }),
        handleGetBuilders({ setBuilders }),
        handleGetCommunities({ setCommunities }),
        handleGetWalkTypes({
          params: { recordId: WALK_RECORD_TYPES.JOB },
        }),
        getStages({ setStages }),
        handleGetOptionsByPlan(),
      ]);

      setLoadingMessage("");

      handleGetPropertySections();
      handleGetOptionTypes();
      handleGetStackMeasurementTypes({
        setMeasurementTypesBack,
        setMeasurementTypesSide,
      });
      handleGetStackSizes({ setStackSizes });
      handleGetMaterialBrands();
      handleGetMaterialCategories();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      setLoadingMessage(
        "There was a system error.  Please contact an administrator."
      );
    }
  };

  useEffect(() => {
    loadMasterSpecs();
  }, []);

  return {
    planEdit,
    loadMasterSpecs,
    loadingMessage,
    setLoadingMessage,
    measurementTypesSide,
    measurementTypesBack,
    setMeasurementTypesBack,
    setMeasurementTypesSide,
    stackSizes,
    setStackSizes,
    materialBrands,
    setMaterialBrands,
    materialCategories,
    setMaterialCategories,
    propertySections,
    stages,
    floors,
    builders,
    communities,
    handleOnChangePlan,
    handleSavePlan,
    walkTypes,
    planOptions,
    setPlanOptions,
    optionTypes,
    handleGetOptionsByPlan,
    handleGetOptionTypes,
  };
};
