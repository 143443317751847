import * as React from "react";
import PropTypes from "prop-types";
import { MiAccordionItem } from "./MiAccordionItem";
import { useState } from "react";

/**
 * 
##Definition
An _accordion_ is a content section that the user can toggle (expand/collapse) the content to display and group information on a page or to shorten page content for better readability.
*Commonly Referred To As:* None
##Structure & Placement
###Structure
*Accordions* consist of a header, an icon with current state reflected as expanded or collapsed, and a body. An accordion can be nested within other accordions, if needed.
|**Item**|**Description**|
|--|--|
|1. Icon|Refer to [Icons Used](#Icons-Used)|
|2. Header|Should have concise, meaningful content about the action|
|3. Body|Content that is relevant to the header and that is generally displayed below the header using a specified spacing, font size, and color|
- Icon appears on the left
- Content should be positioned to the left of the icon
##Guidelines
###Do's & Don'ts
| **Do's**        | **Don'ts**      |
|-----------------|-----------------|
| Use icons to reflect the expand/collapse states | Display without a header and icon or use a button to trigger the expand/collapse state|
| Use a right/down chevron or plus/minus icon to indicate the expand/collapse state | Use an icon that does not graphically show the expand/collapse states|
| Provide multiple ways to trigger the expand/collapse state when clicking on the header or icon||
###Color
Follow standard MiView color themes.
###Behavior (States)
See [Icons Used](#Icons-Used)
- Hover state
- Focus
- Pressed
###Content
Choice of accordion is dependent on the information being represented.
##Accessibility
- Focus should remain on the accordion component so that the user can use the keyboard to expand/collapse it more easily.
Consult [WAI-ARIA Authoring Practices](https://www.w3.org/TR/wai-aria-practices-1.1) for more information.
 */

export const MiAccordion = (props) => {
  const {
    config,
    activeColor,
    iconPosition,
    renderExpandIcons,
    renderTitle,
    renderDetail,
    ...Rest
  } = props;
  const [activeItemIds, setActiveItemIds] = useState([]);

  const onClose = (id) => {
    const newActiveIds = activeItemIds.filter((activeId) => activeId !== id);
    setActiveItemIds(newActiveIds);
  };

  const onOpen = (id) => {
    if (activeItemIds.includes(id)) {
      return;
    }
    setActiveItemIds([...activeItemIds, id]);
  };

  const handleRenderTitle = (item) => {
    if (renderTitle) {
      return renderTitle(item);
    }
    return item?.title;
  };

  const handleRenderDetail = (item) => {
    if (renderDetail) {
      return renderDetail(item);
    }
    return item?.data;
  };

  return (
    <div>
      {config.map((item, index) => {
        return (
          <div key={index}>
            <MiAccordionItem
              title={handleRenderTitle(item)}
              detail={handleRenderDetail(item)}
              onOpen={onOpen}
              onClose={onClose}
              id={`${index}`}
              activeItemIds={activeItemIds}
              activeColor={activeColor}
              iconPosition={iconPosition}
              renderExpandIcons={renderExpandIcons}
              {...Rest}
            />
          </div>
        );
      })}
    </div>
  );
};

MiAccordion.defaultProps = {
  iconPosition: "left",
};

MiAccordion.propTypes = {
  /**
   * background color when the accordion is expanded
   */
  activeColor: PropTypes.string,
  /**
   * json config for the accordion
   */
  config: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      data: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.array,
      ]),
    })
  ),
  /**
   * expand icon position
   */
  iconPosition: PropTypes.oneOf(["right", "left"]),
  /**
   * gets accordionItem active state as callback value and should return appropriate expand icon
   *
   * Ex: ``` (isActive) => isActive?  <ArrowUpwardOutlined/> : <ArrowDownwardOutlined /> ```
   */
  renderExpandIcons: PropTypes.func,
  /**
   * This function will return elements to be dispalyed in the Accordion summary
   */
  renderTitle: PropTypes.func,
  /**
   * This function will return elements to be dispalyed in the Accordion details
   */
  renderDetail: PropTypes.func,
};
