import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  useTheme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { HTTP_STATUSES } from "@miview/constants";
import { mdiEmailOutline, mdiPhone } from "@mdi/js";
import { accountService } from "@miview/api";
import { MiIcon } from "@miview/components";
import en from "../../../../locale/en.json";
import { createFilter } from "@miview/utils";

const useStyles = makeStyles()((theme) => ({
  row: {
    borderBottom: "5px solid white",
    backgroundColor: theme.palette.light.grey,
    borderRadius: 5,
    height: 63,
  },
  name: {
    color: theme.palette.secondary.bluegrey,
    fontSize: theme.fontSize.small,
    fontWeight: theme.fontWeight.medium,
    margin: "5px 0px",
  },
  details: {
    color: theme.palette.secondary.bluegrey,
    fontSize: theme.fontSize.small,
    fontWeight: theme.fontWeight.regular,
    margin: "5px 0px",
  },
  tabCell: {
    marginRight: 10,
  },
  textColor: {
    color: theme.palette.primary.main,
    fontSize: theme.fontSize.small,
    fontWeight: theme.fontWeight.medium,
  },
  container: {
    padding: "20px 0px",
  },
  text: {
    marginTop: 20,
  },
  header: {
    fontSize: theme.fontSize.small,
    fontWeight: theme.fontWeight.medium,
    color: theme.palette.dark.teal,
    borderBottom: `1px solid ${theme.palette.accent.grey}`,
  },
  subText: {
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.regular,
    color: theme.palette.secondary.bluegrey,
  },
  contactCell: {
    width: 180,
    padding: 5,
    paddingLeft: 10,
  },
  contactCells: {
    padding: 5,
    paddingLeft: 10,
  },
  tBody: {
    marginTop: 10,
    marginBottom: 30,
  },
}));

const SupplierBranchDrawerInfo = ({ account }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    getContacts();
  }, []);

  const getContacts = async () => {
    const response = await accountService.contacts.getAll({
      filters: createFilter().equals({
        accountId: account.id,
      }),
    });
    if (response.status === HTTP_STATUSES.OK) {
      setContacts(response.data);
    }
  };

  const generalInfo = () => {
    return (
      <>
        <Typography className={classes.header}>
          {en.generalInformation}
        </Typography>
        <Grid container className={classes.container} key={account.id}>
          <Grid item xs={6}>
            <Typography className={classes.textColor}>
              {en.internalNumber}
            </Typography>
            <Typography className={classes.subText}>
              {account?.internalNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.textColor}>
              {en.storeNumber}
            </Typography>
            <Typography className={classes.subText}>
              {account?.storeNumber}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.text}>
            <Typography className={classes.textColor}>
              {en.branchEmail}
            </Typography>
            <Typography className={classes.subText}>
              {account?.email}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.text}>
            <Typography className={classes.textColor}>
              {en.branchPhoneNumber}
            </Typography>
            <Typography className={classes.subText}>
              {account?.phone}
            </Typography>
          </Grid>
        </Grid>
        <Typography className={classes.header}>{en.contacts}</Typography>
      </>
    );
  };
  const contactsInfo = () => {
    return (
      <Table className={classes.tBody}>
        <TableBody>
          {contacts.map((contact, i) => {
            return (
              <TableRow className={classes.row} key={i}>
                <TableCell className={classes.contactCell}>
                  <Typography className={classes.name}>
                    {contact.firstName} {contact.lastName}
                  </Typography>
                  <Typography className={classes.details}>
                    {contact.contactTypeName}
                  </Typography>
                </TableCell>
                <TableCell className={classes.contactCells}>
                  <Typography className={classes.details}>
                    <MiIcon
                      path={mdiEmailOutline}
                      size={0.8}
                      color={theme.palette.secondary.bluegrey}
                      className={classes.tabCell}
                      fontSize="small"
                    />
                    {contact.email}
                  </Typography>
                  <Typography className={classes.details}>
                    <MiIcon
                      path={mdiPhone}
                      size={1}
                      color={theme.palette.secondary.bluegrey}
                      className={classes.tabCell}
                      fontSize="small"
                    />
                    {contact.phone}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      {generalInfo()}
      {contactsInfo()}
    </>
  );
};
export default React.memo(SupplierBranchDrawerInfo);
