import React from "react";

import { useRouter } from "@miview/hooks";
import QuotesComponent from "../../../../components/QuotesComponent";

const Quotes = () => {
  const router = useRouter();
  const accountId = router.params.id;

  return (
    <>
      <QuotesComponent accountId={accountId}></QuotesComponent>
    </>
  );
};

export default Quotes;
