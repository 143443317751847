import React, { useEffect, useState } from "react";
import { MiPageHeader, MiBox, MiLoader, MiButton } from "@miview/components";
import { Grid, useTheme } from "@mui/material";
import NewHomesFilter from "./NewHomesFilter";
import NewHomesSelectModal from "./NewHomesSelectModal";
import { REQUISITION_FILTERS, BOOL_VALUE } from "../../../constants";
import { accountService, RequisitionService } from "@miview/api";
import moment from "moment";
import NewHomesTable from "./NewHomesTable";
import RequisitionsSelected from "./RequisitionSelected";
import RequsistionOrderReview from "./RequisitionOrderReview";
import { HTTP_STATUSES } from "@miview/constants";
import en from "../../../locale/en.json";
import CreatePurchaseOrder from "../../PurchasingOrder/CreatePurchaseOrder";
import { useRouter } from "@miview/hooks";
import NewHomesTemplatesModal from "./NewHomesTemplatesModal";

export const RequisitionsNewHomes = () => {
  const theme = useTheme();
  const [selectModal, setSelectModal] = useState({ open: false });
  const [showTemplateModal, setShowTemplateModal] = useState({
    open: false,
  });
  const [loading, setLoading] = useState(false);
  const [requisitions, setRequisitions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showSelectedCart, setShowSelectedCart] = useState(false);
  const [manualCreation, setManualCreation] = useState(false);
  const [showDrawer, setShowDrawer] = useState({ open: false });
  const [requisitionItems, setRequisitionItems] = useState([]);
  const [requisitionRow, setRequisitionRow] = useState("");
  const [requestedContacts, setRequestedContacts] = useState([]);
  const [purchaseOrderDrawer, setPurchaseOrderDrawer] = useState({
    open: false,
  });
  const router = useRouter();
  const [filterData, setFilterData] = useState({ type: "Instance" });
  const [totalcount, setTotalcount] = useState(0);
  const [itemTemplates, setItemTemplates] = useState([]);
  const [fromTemplate, setFromTemplate] = useState(false);

  let templatedId = "";
  const getRequisitonItems = async (RowData) => {
    if (RowData?.id !== undefined) {
      try {
        const resp = await RequisitionService.requisitions.get(RowData?.id);
        const itemsResp = await RequisitionService.requisitions.getAllItems(
          RowData?.id
        );
        if (resp?.status < HTTP_STATUSES.BAD_REQUEST) {
          setRequisitionRow(resp?.data);
          setRequisitionItems(itemsResp?.data);
        }
      } catch (e) {
        alert(e.errorMessage);
      } finally {
        const onClose = () => setShowDrawer({ open: false });
        setShowDrawer({
          open: true,
          onClose,
        });
      }
    } else {
      const onClose = () => setShowDrawer({ open: false });
      setShowDrawer({
        open: true,
        onClose,
        RequisitionRow: requisitionRow,
        RequisitionItems: requisitionItems,
      });
    }
  };

  const itemFormat = async (temp) => {
    const contactsArray = [];
    await Promise.all(
      temp.map(async (item, index) => {
        const resp = await RequisitionService.requisitions.getAll("", {
          type: "Template",
          templateFolderId: item?.id,
        });
        contactsArray.push({
          ...item,
          indexId: index,
          children: resp?.data,
        });
        contactsArray.sort((a, b) => (a.indexId > b.indexId ? 1 : -1));
      })
    );
    return contactsArray;
  };

  const getFolderItems = async () => {
    try {
      setLoading(true);
      const resp = await RequisitionService.templateFolders.getAll();
      if (resp?.data?.length > 0) {
        setItemTemplates([]);
        const folder = resp?.data;
        const temp = await itemFormat(
          folder?.filter((item) => item?.parentId === null)
        );
        setItemTemplates(temp);
      }
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  const onShowDrawerFunction = (RowData) => {
    getRequisitonItems(RowData);
  };

  const handleSelectModal = () => {
    const onCancel = () =>
      setSelectModal({
        open: false,
      });
    const onRequisitionBlank = () => {
      setSelectModal({
        open: false,
      });
      setManualCreation(true);
      onShowDrawerFunction({});
    };

    const onTemplateExisting = () => {
      const onCancelTemplateModal = () => {
        setShowTemplateModal({
          open: false,
        });
        setFromTemplate(false);
      };
      const onApprove = () => {
        getRequisitonItems(templatedId);
        setFromTemplate(true);
      };
      const getTemplate = (ROW) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        templatedId = ROW;
        setShowTemplateModal({
          open: true,
          templateSelected: true,
          onCancelTemplateModal,
          onApprove,
          getTemplate,
        });
      };
      setShowTemplateModal({
        open: true,
        onCancelTemplateModal,
        onApprove,
        getTemplate,
        templateSelected: false,
      });
    };

    setSelectModal({
      open: true,
      onCancel,
      onRequisitionBlank,
      onTemplateExisting: onTemplateExisting,
    });
  };

  const handleSearchChange = () => {
    return null;
  };
  const getRequisitions = async () => {
    try {
      setLoading(BOOL_VALUE);
      const requisitonsResp = await RequisitionService.requisitions.getAll(
        {
          IncludeTotal: true,
          pageSize: 10000,
          orderBy: "scheduledDate",
          descending: true,
        },
        filterData
      );
      const temp = requisitonsResp?.data;
      temp.forEach((item) => {
        item.scheduledDate = moment(item?.scheduledDate)?.format("MM/DD/YYYY");
        item.requestedDeliveryDate = moment(
          item?.requestedDeliveryDate
        )?.format("MM/DD/YYYY");
      });
      setTotalcount(requisitonsResp.headers?.totalcount);
      setRequisitions(temp);
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getRequestedContacts = async () => {
    const resp = await accountService.accounts.getContacts(1, "", {});
    const temp = resp?.data;
    temp.forEach((item) => {
      item.value = item?.id;
      item.label = item?.displayName;
    });
    setRequestedContacts(temp);
  };
  const closePurchaseOrderDrawer = () => {
    setPurchaseOrderDrawer({ open: false });
  };

  const onShowSupplierFunction = () => {
    setPurchaseOrderDrawer({ open: true });
  };

  const handlePurchaseOrder = (callbackUrl) => {
    router.push(callbackUrl);
  };

  const getSingleItem = (RowData) => {
    return RequisitionService.requisitions.getItem(
      RowData?.requisitionOrderId,
      RowData?.id
    );
  };

  useEffect(() => {
    if (selectedItems && selectedItems?.length > 0) {
      setShowSelectedCart(true);
    }
  }, [selectedItems]);

  const clearAllFunction = () => {
    setSelectedItems([]);
  };
  const removeItem = (ID) => {
    const Filterd = selectedItems?.filter((item) => item?.id !== ID);
    setSelectedItems(Filterd);
  };

  const closeClick = () => {
    setShowSelectedCart(!showSelectedCart);
  };

  const filterItems = async () => {
    let temp = {
      pageSize: 10000,
      IncludeTotal: true,
      orderBy: "scheduledDate",
      descending: true,
    };
    const resp = await RequisitionService.requisitions.getAll(temp, filterData);
    resp?.data.forEach((item) => {
      item.requestedDeliveryDate = moment(item?.requestedDeliveryDate)?.format(
        "MM/DD/YYYY"
      );
      item.scheduledDate = moment(item?.scheduledDate)?.format("MM/DD/YYYY");
    });
    setRequisitions(resp?.data);
  };

  const handleFilterChange = (value, label) => {
    if (value === "all") {
      setFilterData((prevState) => ({
        ...prevState,
        type: "Instance",
        [REQUISITION_FILTERS[label.replace(/\s/g, "")]]: "",
      }));
    } else {
      setFilterData((prevState) => ({
        ...prevState,
        type: "Instance",
        [REQUISITION_FILTERS[label.replace(/\s/g, "")]]: value,
      }));
    }
  };

  const handleRefresh = () => {
    setFilterData({});
    getRequisitions();
  };

  useEffect(() => {
    if (Object.keys(filterData)?.length > 0) {
      filterItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const actionTopButton = () => {
    return (
      <MiButton
        color={theme.palette.primary.main}
        inverse={true}
        title={en.createRequisition}
        onClick={handleSelectModal}
        style={{
          fontSize: theme.fontSize.medium,
          fontWeight: theme.fontWeight.medium,
        }}
      />
    );
  };

  useEffect(() => {
    getFolderItems();
    getRequisitions();
    getRequestedContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MiPageHeader
        title={`${en.reqOrders} - ${en.reqNewHomes}`}
        color={theme.palette.primary.teal}
        hasDivider={true}
        action={actionTopButton()}
      />
      <Grid container>
        <Grid
          item
          md={showSelectedCart ? 9 : 12}
          lg={showSelectedCart ? 9 : 12}
        >
          <MiBox>
            <NewHomesFilter
              onSearchChange={handleSearchChange}
              handleFilterChange={handleFilterChange}
              handleRefreshClick={handleRefresh}
              requestedContacts={requestedContacts}
            />
            {loading && (
              <MiLoader
                style={{ margin: "50px auto 0px", background: "none" }}
              />
            )}
            {!loading && (
              <NewHomesTable
                requisitions={requisitions}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                handleItemAddCount={closeClick}
                onShowDrawerFunction={onShowDrawerFunction}
                totalcount={totalcount}
                handleSelectModal={handleSelectModal}
              />
            )}
          </MiBox>
        </Grid>
        {showSelectedCart && (
          <Grid item md={3} lg={3}>
            <RequisitionsSelected
              closeClick={closeClick}
              selectedItems={selectedItems}
              ClearAllFunction={clearAllFunction}
              onShowSupplierFunction={onShowSupplierFunction}
              clearAllFunction={clearAllFunction}
              removeItem={removeItem}
            />
          </Grid>
        )}
      </Grid>
      <NewHomesSelectModal {...selectModal} />
      <NewHomesTemplatesModal
        {...showTemplateModal}
        itemTemplates={itemTemplates}
      />
      <RequsistionOrderReview
        {...showDrawer}
        requisitionRow={requisitionRow}
        requisitionItems={requisitionItems}
        setRequisitions={setRequisitions}
        closeRequisitionModel={() => {
          setShowDrawer({
            open: false,
          });
          setRequisitionRow("");
          setRequisitionItems([]);
          setManualCreation(false);
        }}
        getRequisitonItems={getRequisitonItems}
        getRequisitionOrders={getRequisitions}
        manualCreation={manualCreation}
        setRequisitionItems={setRequisitionItems}
        getSingleItem={getSingleItem}
        requestedContacts={requestedContacts}
        fromTemplate={fromTemplate}
      />
      {purchaseOrderDrawer.open && (
        <CreatePurchaseOrder
          {...purchaseOrderDrawer}
          closePurchaseOrderDrawer={closePurchaseOrderDrawer}
          selectedItems={selectedItems}
          reqId={selectedItems.map((si) => si.id)}
          onSave={handlePurchaseOrder}
        />
      )}
    </>
  );
};
