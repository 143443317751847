export const es = {
  failureTypeNameField: "failureTypeNameSpanish",
  requiredUpdateHeader: "¡Hora de actualizar tu app!",
  requiredUpdateText:
    "Esta versión de la aplicación ya no es compatible, por favor actualice a la versión mas reciente o contacte a su administrador para soporte.",
  issueDescriptionField: "descriptionSpanish",
  jobStatus: "Estado",
  jobType: "Tipo de Trabajo",
  jobName: "Nombre del Trabajo",
  address: "Dirección",
  builder: "Constructora",
  community: "Comunidad",
  sqFootage: "Metros Cuadrados",
  floors: "Pisos",
  bathrooms: "Baños",
  zipcode: "Código postal",
  duedate: "Fecha de Vencimiento",
  scheduledate: "Fecha Programada",
  stage: "Etapa",
  amount: "Cantidad",
  jobs: "Trabajos",
  midia: "Mi Día",
  past: "Pasado",
  today: "Hoy",
  future: "Futuro",
  acceptJob: "Aceptar Trabajo",
  declineJob: "Rechazar Trabajo",
  startJob: "Comenzar Trabajo",
  previewJob: "Vista Previa del Trabajo",
  startRemote: "Iniciar Remoto",
  takePictureToStart: "Por favor tome una foto para iniciar.",
  submit: "Enviar",
  preview: "Vista Previa",
  message: "Mensaje",
  extras: "Extras",
  unableToStart: "No se pudo iniciar trabajo",
  reasonUnableToStart: "Razón por la que no se pudo iniciar el trabajos",
  selectReason: "Selecciona una Razón",
  selectLanguage: "Selecciona Lenguaje",
  checklist: "Tareas",
  areas: "Areas",
  noTasks: "No hay tareas.",
  na: "N/A",
  addNote: "Agregar Nota",
  addPhoto: "Agregar Photo",
  viewDocument: "Ver Documento",
  cancel: "Cancelar",
  save: "Guardar",
  addANote: "Agrega una nota",
  confirm: "Confirmar",
  details: "Detalles",
  profile: "Perfil",
  changePassword: "Cambiar Contraseña",
  changepin: "Cambiar PIN",
  changeLanguage: "Cambiar Lenguaje",
  username: "Nombre de Usuario",
  oldPassword: "Contraseña Vieja",
  newPassword: "Contraseña Nueva",
  confirmPassword: "Confirmar Contraseña Nueva",
  logout: "Cerrar Sesión",
  advanced: "Avanzadas",
  forgotPassword: "Olvidó Contraseña",
  signIn: "Iniciar Sesión",
  password: "Contraseña",
  passwordReset: "Resetear Contraseña",
  resetPassword: "Resetear Contraseña",
  sync: "Sincronizar",
  pendingSync: "Sincronizar Pendiente",
  imageSync: "Sincronizar de Imágenes",
  dataSync: "Data Sync",
  errors: "Errores",
  dumpLocalStorage: "Deshacer Almacenamiento Local",
  dumpCurrentSyncs: "Deshacer Sincs Actuales",
  messages: "Mensajes",
  addMessage: "Agregar Mensaje",
  tagContact: "Etiquetar Contacto",
  close: "Cerrar",
  tag: "Etiquetar",
  title: "Título",
  noMessage: "No hay Mensajes.",
  addExtra: "Agregar Extra",
  saveExtra: "Guardar Extra",
  reasonRequestingExtra: "Agregar la razón por la cual se solicita el Extra",
  selectArea: "Selecciona el Area",
  noSyncData: "No hay información para sincronizar por ahora.",
  finishJob: "Terminar Trabajo",
  finalReview: "Revisión Final",
  completed: "Completados",
  duration: "Duración (mins)",
  incomplete: "Incompleta",
  naItem: "N/A",
  complete: "Completa",
  checklistItem: "Tareas",
  reviewSpecSheet: "Revisar Hoja de Especificaciones",
  status: "Estado",
  description: "Descripción",
  noDetails: "Sin detalles.",
  imageRequired: "Se requiere una imagen para esta tarea.",
  Phone: "Telefono",
  Email: "Correo",
  support: "Soporte",
  techSupport: "Soporte Técnico",
  notifications: "Notificaciones",
  noNotifications: "No hay notificaciones.",
  confirmLogout: "Cerrar Sessión",
  noJobsForPeriod: "No hay trabajos para este período.",
  pullRefresh: "Deslice hacia abajo para Refrescar.",
  noAnswer_message: "No has terminado todas las tareas.",
  annotateImage_title: "Marque el problema en la Foto",
  noExtras_message: "No hay Extras para este trabajo.",
  deleteExtraAlert: "Estas seguro que quieres eliminar el Extra?",
  jobSignatureRequired: "Requerido: Firma para verificar el Trabajo.",
  submitWithSignature: "Enviar con Firma",
  logoutAlert: "Alerta de Cierre de Sesión",
  logoutAlert_title: "Estás seguro que quieres finalizar tu sesión?",
  logoutAlert_message:
    "Actualmente tienes sincs activas en progreso. Si cierras tu sesión perderás la información pendiente por sincronizar.",
  loginFailed_toast: "No se pudo iniciar sesión.",
  sessionExpired_toast: "Sesión expirada. Favor de Iniciar sesión nuevamente.",
  checkEmail_toast: "Revisa tu correo para ver el código de reseteo.",
  networkError_toast: "Error de red. Por favor checa tu conexión de Internet.",
  workingOffline_toast: "Estas trabajando sin Internet.",
  syncFailed_toast: "Falla al sincronizar datos.",
  syncInProgress_toast: "Sincronización en progreso.",
  errorUpdating_toast: "Error actualizando información.",
  successAdded_toast: "Información agregada exitosamente.",
  successCancelled_toast: "Solicitud cancelada exitosamente.",
  locationTooFar_toast:
    "Tu locación esta fuera del rango permitido para operar.",
  noLocation_toast: "No se pudo obtener tu ubicación.",
  walks: "Andar",
  stages: "Trabajos",
  punch: "Perforar",
  search_address: "Buscar Dirección",
  search: "Buscar",
  bonus: "Bonos",
  pay: "Pagos",
  total_pay: "Pagos Totales",
  pending: "Pendiente",
  total: "Totales",
  deduction: "Deducciones",
  deducted: "Deducidos",
  earned: "Percibidos",
  paid: "Pagados",
  pay_period: "Periodo de Pago",
  current: "Actual",
  monday: "Lu",
  tuesday: "Ma",
  wednesday: "Mi",
  thursday: "Ju",
  friday: "Vi",
  saturday: "Sá",
  sunday: "Do",
  forgot_PIN: "Olvidó PIN",
  reset_PIN: "Reiniciar PIN",
  enter_PIN: "Ingresar PIN",
  enter_new_pin: "Ingrese nuevo PIN",
  enter_password: "Introduzca su Contraseña",
  incorrect_password: "Contraseña Incorrecta",
  incorrect_PIN: "PIN Incorrecto",
  enter_complete_PIN: "Ingresar Completa PIN",
  num_only_warning: "Ingrese únicamente caracteres numéricos",
  inprogress: "En Progreso",
  hold: "Sostener",
  notstarted: "No Empezada",
  scheduled: "Programada",
  late: "Tarde",
  work: "Trabajo",
  no_matching_records_message: "No se han encontrado registros que coincidan.",
  mi_team: "MiEquipo",
  all_punchmen: "Todos los golpeadores",
  crews: "Tripulaciones",
  langUpatedSuccess: "Preferencia de idioma actualizada con éxito",
  relaunchApp:
    "Reinicie su aplicación o cierre la sesión y vuelva a iniciarla para que estos cambios surtan efecto.",
  storageError:
    "Error de almacenamiento: póngase en contacto con el administrador",
  enterPassword: "Por favor, introduzca su contraseña",
  networkErr: "¡Error de red!",
  passwordVerified: "¡Contraseña verificada con éxito!",
  messaging: "Mensajes",
  menu: "Menú",
  manageWork: "Administrar trabajo",
  homes: "Casas",
  review: "Revisar",
  logInAs: "Iniciar sesión como",
  inspections: "Inspeccion",
  tractor: "Tractor",
};
