import React, { useState } from "react";
import { propertySectionCategoryService } from "@miview/api";
import {
  MiPageHeader,
  MiButton,
  MiModal,
  MiList,
  MiLink,
  MiDetailFields,
  MiIcon,
} from "@miview/components";
import { useEdit } from "@miview/hooks";
import { useTheme } from "@mui/material";
import AreaCategoryDeleteModal from "./AreaCategoryDeleteModal";
import { mdiGroup, mdiPlus, mdiDelete } from "@mdi/js";

const AreaCategories = () => {
  const [newPropertySectionInProgress, setNewPropertySectionInProgress] =
    useState(false);
  const [propertySectionCategories, setPropertySectionCategories] = useState();
  const [isAreaCategoryDeleteModalOpen, setIsAreaCategoryDeleteModalOpen] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const theme = useTheme();
  const edit = useEdit({
    name: "",
  });

  const saveNewPropertySection = async () => {
    await propertySectionCategoryService.create(edit.edits);

    toggleModal();
    setRefresh(!refresh);
  };

  const toggleModal = () => {
    edit.reset();
    setNewPropertySectionInProgress(!newPropertySectionInProgress);
  };

  const openAreaCategoryDeleteModal = (row) => {
    setSelectedRow(row);
    setIsAreaCategoryDeleteModalOpen(true);
  };

  const closeAreaCategoryDeleteModal = () => {
    setIsAreaCategoryDeleteModalOpen(false);
    setSelectedRow({});
    setRefresh(!refresh);
  };

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => {
          openAreaCategoryDeleteModal(row);
        },
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
    ];
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (p) => {
        return <MiLink to={`/areacategories/${p.id}`} title={p.value} />;
      },
    },
  ];

  const areaCatagories = [
    {
      label: "Name",
      value: edit.getValue("name"),
      setValue: (e) => edit.update({ name: e }),
      width: "100%",
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon path={mdiGroup} size={1} color={theme.palette.primary.main} />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Area Category"
        icon={mdiPlus}
        inverse={true}
        onClick={toggleModal}
        color={theme.palette?.primary.main}
      />
    );
  };

  return (
    <div>
      <MiPageHeader
        title="Area Categories"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
      />
      <MiList
        data={propertySectionCategories}
        setData={setPropertySectionCategories}
        dataProvider={propertySectionCategoryService.getAll}
        columns={columns}
        getRowActions={getRowActions}
        disableColumnMenu
        refresh={refresh}
        selection={selectedRow}
      />
      <MiModal
        key={0}
        width={800}
        title="Create Area Category"
        open={newPropertySectionInProgress}
        onClose={toggleModal}
        actions={[
          {
            name: "Cancel",
            style: { minWidth: 90 },
            onClick: toggleModal,
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: saveNewPropertySection,
            style: { minWidth: 90 },
            color: theme.palette.primary.green,
            inverse: true,
            disabled: !edit.allFilled("name"),
          },
        ]}
      >
        <MiDetailFields detailFields={areaCatagories} />
      </MiModal>
      <AreaCategoryDeleteModal
        open={isAreaCategoryDeleteModalOpen}
        onClose={closeAreaCategoryDeleteModal}
        selection={selectedRow}
      />
    </div>
  );
};

export default AreaCategories;
