import React from "react";
import { makeStyles } from "tss-react/mui";
import { MiInputTextbox } from "@miview/components";

const useStyles = makeStyles()((theme) => ({
  componentCustom: {
    width: "100%",
    "& > div": {
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%",
    },
    "& > div > label": {
      position: "relative",
      left: "0",
      top: "0",
      marginBottom: 5,
      color: theme.palette.primary.teal,
    },
    "& fieldset": {
      border: "none",
    },
  },
}));

export const TdInputBox = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.componentCustom}>
      <MiInputTextbox {...props} />
    </div>
  );
};

export default TdInputBox;
