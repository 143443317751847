import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Session } from "@miview/components";
import { SCHEDULING_NAV } from "./routes/scheduling/";
import { MiTHEME } from "@miview/theme";
import { roofLogoAdmin } from "@miview/assets";
import { APPLIST } from "@miview/constants";
import DefaultHeader from "./containers/DefaultLayout/DefaultHeader";
import DefaultFooter from "./containers/DefaultLayout/DefaultFooter";

const createMui5Theme = createTheme({
  ...MiTHEME,
  palette: {
    ...MiTHEME.palette,
    primary: {
      ...MiTHEME.palette.primary,
      main: MiTHEME.colors.purple.secondary,
      secondary: MiTHEME.colors.purple.secondary,
      accent: MiTHEME.colors.grey.primary,
      light: MiTHEME.colors.purple.light,
    },
  },
  spacing: (factor) => `${0.25 * factor}`,
  colors: undefined,
});

export const AppProvider = ({ children }) => {
  return <ThemeProvider theme={createMui5Theme}>{children}</ThemeProvider>;
};

const MAIN_APP = {
  logo: roofLogoAdmin,
  name: "MiScheduling",
  url: "/scheduling/importedwork",
};

export const SchedulingRoutes = () => {
  return (
    <AppProvider>
      <CssBaseline />
      <Session
        nav={SCHEDULING_NAV}
        mainApp={MAIN_APP}
        apps={APPLIST}
        admin={true}
        renderRightIcons={DefaultHeader}
        renderFooter={DefaultFooter}
      />
    </AppProvider>
  );
};
