import React from "react";
import { Grid, Container } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import {
  BasicInfoForm,
  GeneralContactForm,
  RemittanceAddressForm,
  CorporateAddressForm,
} from "../../../../components";

const useStyles = makeStyles()(() => ({
  maxWidthContainer: {
    marginTop: "25px",
    padding: "0px 20px 70px 40px",
  },
}));

const CreateGeneral = ({ isValid, edit, setDuplicateAccounts }) => {
  const { classes } = useStyles();

  return (
    <>
      <Container className={classes.maxWidthContainer}>
        <Grid container direction="row" spacing={10}>
          <Grid item xs={6}>
            <BasicInfoForm
              editMode={true}
              edit={edit}
              isMissingRequiredField={!isValid}
              setDuplicateAccounts={setDuplicateAccounts}
            />
          </Grid>
          <Grid item xs={6}>
            <GeneralContactForm
              editMode={true}
              edit={edit}
              isMissingRequiredField={!isValid}
            />
          </Grid>
          <Grid item xs={6}>
            <CorporateAddressForm
              editMode={true}
              edit={edit}
              isMissingRequiredField={!isValid}
            />
          </Grid>
          <Grid item xs={6}>
            <RemittanceAddressForm
              editMode={true}
              edit={edit}
              isMissingRequiredField={!isValid}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default React.memo(CreateGeneral);
