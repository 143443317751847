import { useState } from "react";
import { propertySectionService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const usePropertySections = () => {
  const [propertySections, setPropertySections] = useState([]);

  const handleGetPropertySections = async () => {
    const response = await propertySectionService.getAll({
      sort: [{ field: "name", sort: "asc" }],
    });

    if (response.status === HTTP_STATUSES.OK) {
      const propertySectionsList = response.data.map((p) => {
        return {
          value: p.id,
          text: p.name,
          propertySectionCategoryId: p.propertySectionCategoryId,
          isGroundFloor: p.isGroundFloor,
        };
      });
      setPropertySections(propertySectionsList);
    }
  };
  return {
    propertySections,
    setPropertySections,
    handleGetPropertySections,
  };
};
