import React, { useEffect, useState } from "react";
import { formatDate } from "../../../helpers/dateTools";
import { propertyService } from "@miview/api";
import { MiTable, MiLink, MiSectionHeader } from "@miview/components";
import { HTTP_STATUSES } from "@miview/constants";
import { combineFields } from "@miview/utils";

export const PunchListsCollapse = (props) => {
  const { propertyId } = props;

  const [punchLists, setPunchLists] = useState([]);

  useEffect(() => {
    getPunchlists();
  }, []);

  const getPunchlists = async () => {
    const response = await propertyService.getIssues({
      id: propertyId,
      pageSize: 200,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setPunchLists(response.data);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Punch Item",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row, value }) => {
        return (
          <MiLink
            to={"/issues/" + row.id}
            style={{
              marginLeft: 0,
              paddingLeft: 0,
              whiteSpace: "normal",
            }}
            title={value}
          />
        );
      },
    },
    {
      field: "stageName",
      headerName: "Stage",
      headerAlign: "left",
      align: "left",
      flex: 0.8,
    },
    {
      field: "areaName",
      headerName: "Area",
      headerAlign: "left",
      align: "left",
      flex: 0.8,
    },
    {
      field: "propertyPlanItemName",
      headerName: "Description",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "failReason",
      headerName: "Fail Reason",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {combineFields(row.description, row.failReason)}
          </div>
        );
      },
    },
    {
      field: "issueCategoryName",
      headerName: "Type",
      headerAlign: "left",
      align: "left",
      flex: 0.8,
    },
    {
      field: "assigneeName",
      headerName: "Assigned To",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ value }) => formatDate(value),
    },
    {
      field: "statusName",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      flex: 0.8,
    },
    {
      field: "hold",
      headerName: "Hold",
      headerAlign: "left",
      align: "left",
      flex: 0.8,
      valueGetter: ({ value }) => (value ? "Yes" : "No"),
    },
  ];

  return (
    <>
      {punchLists.length ? (
        <MiTable
          columns={columns}
          rows={punchLists}
          toolbarProps={{ visible: false }}
          headerHeight={40}
          autoHeight={true}
          hideFooter={false}
          pagination
          disableColumnMenu
        />
      ) : (
        <div style={{ paddingTop: 10 }}>
          <MiSectionHeader title="Punch items have not been created for this Home." />
        </div>
      )}
    </>
  );
};
