import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const planItemService = {
  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/planitems`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  replace: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/planitems/${id}`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/planitems/${id}`,
      method: "patch",
      data: request,
    });

    return apiRequest(options);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/planitems/${id}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  reorder: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/planitems/reorder`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },
};
