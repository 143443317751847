/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IconButton, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { STATUS_ENUM } from "../../../constants";
import { BolderText } from "../../../components/TableTdComponents";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {
  MiLoader,
  MiTable,
  MiPlaceHolderList,
  MiIcon,
} from "@miview/components";
import { mdiFolderOpenOutline } from "@mdi/js";

const useStyles = makeStyles()((theme) => ({
  searchTableStyle: {
    marginTop: 0,
    "& .MuiDataGrid-main": {
      borderRadius: "4px",
      overflow: "hidden",
      border: `1px solid ${theme.palette.accent.grey}`,
    },
    "& .MuiDataGrid-autoHeight": {
      minHeight: "inherit",
    },
  },
  addButtonStyle: {
    padding: 5,
    marginLeft: "10px",
    color: theme.palette.medium.grey,
  },
  circleStyle: {
    width: 175,
    height: 175,
    backgroundColor: theme.palette.lightAccent.grey,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
    marginTop: 40,
  },
}));

const RequisitionQuoteSearch = (props) => {
  const { masterItems = [], AddRequisitionItems, searchLoading } = props;
  const { classes } = useStyles();
  const theme = useTheme();

  const columns = [
    {
      field: "AddCart",
      headerName: "",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <IconButton
            className={classes.addButtonStyle}
            onClick={() => AddRequisitionItems(params?.row)}
            size="large"
          >
            <PlaylistAddIcon />
          </IconButton>
        );
      },
    },
    {
      field: "number",
      headerName: "Item #",
      minWidth: 190,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <BolderText value={params?.value?.toString()} />;
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "categoryName",
      headerName: "Product Line",
      minWidth: 300,
    },
  ];

  const renderListTable = () => {
    if (!masterItems?.length && !searchLoading) {
      return (
        <MiPlaceHolderList
          renderIcon={
            <div className={classes.circleStyle}>
              <MiIcon
                path={mdiFolderOpenOutline}
                size={4}
                color={theme.palette.lightShade.bluegrey}
              />
            </div>
          }
          text={"No Matching Items Found"}
        />
      );
    }
    return (
      <div className={classes.searchTableStyle}>
        {searchLoading && (
          <MiLoader style={{ margin: "50px auto", background: "none" }} />
        )}
        {!searchLoading && (
          <MiTable
            columns={columns}
            rows={masterItems}
            disableColumnMenu={true}
            getRowClassName={(params) => {
              return `purchasing-row--${
                STATUS_ENUM[params.getValue(params.id, "status")]
              }`;
            }}
            hideFooter={true}
            autoHeight={true}
            sx={{
              minHeight: "inherit",
              border: "inherit",
              "& .MuiDataGrid-columnHeader": {
                background: theme.palette.light.teal,
                minHeight: 60,
              },
              "& .MuiDataGrid-footerContainer p": {
                marginBottom: "0px",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-cell": {
                "&:focus, &:focus-within": {
                  outline: "inherit",
                },
              },
            }}
          />
        )}
      </div>
    );
  };
  return <>{renderListTable()}</>;
};

export default RequisitionQuoteSearch;
