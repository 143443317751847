import React from "react";
import { Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import en from "../locale/en.json";
import { MiInputTextbox, MiInputPhone } from "@miview/components";
import { isPhoneNumber } from "@miview/utils";

const useStyles = makeStyles()(() => ({
  mainContainer: {
    marginBottom: 30,
    paddingRight: 10,
  },
  Grid: {
    paddingRight: 5,
  },
  formAlign: {
    marginTop: 20,
  },
}));

const BranchesInfoAddress = ({ isDisable, edit, enableValidate }) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const invalidName = enableValidate && !edit.getValue("name");

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.branchName}
          disabled={isDisable}
          error={invalidName}
          helperText={invalidName ? "Name is required" : ""}
          required
          value={edit.getValue("name")}
          handleChange={(e) => edit.update({ name: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.address}
          disabled={isDisable}
          value={edit.getValue("addressHouseNumber")}
          handleChange={(e) =>
            edit.update({ addressHouseNumber: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.address2}
          disabled={isDisable}
          value={edit.getValue("addressStreetNumber")}
          handleChange={(e) =>
            edit.update({ addressStreetNumber: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={6} className={`${classes.Grid} ${classes.formAlign}`}>
        <MiInputPhone
          labelTextColor={theme.palette.primary.main}
          labelText={en.phone}
          minWidth={150}
          disabled={isDisable}
          value={edit.getValue("phone")}
          handleChange={(e) => edit.update({ phone: e.target.value })}
          helperText={
            edit.allFilled("phone") && !isPhoneNumber(edit.getValue("phone"))
              ? "Phone number is not valid"
              : ""
          }
        />
      </Grid>
      <Grid item xs={6} className={classes.formAlign}>
        <MiInputPhone
          labelTextColor={theme.palette.primary.main}
          labelText={en.fax}
          minWidth={150}
          disabled={isDisable}
          value={edit.getValue("fax")}
          handleChange={(e) => edit.update({ fax: e.target.value })}
          helperText={
            edit.allFilled("fax") && !isPhoneNumber(edit.getValue("fax"))
              ? "Fax number is not valid"
              : ""
          }
        />
      </Grid>
    </Grid>
  );
};

export default BranchesInfoAddress;
