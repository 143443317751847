import { INTEGRATION_NAMES } from "./integrationNames";

export const MAPPINGS = {
  [INTEGRATION_NAMES.DrHorton]: {
    builderId: {
      mappingTypeId: 1,
      optionsService: "accountService",
      optionsEntity: "accounts",
    },
    communityId: {
      mappingTypeId: 2,
      optionsService: "communityService",
    },
    stageTypeId: {
      mappingTypeId: 3,
      optionsService: "stageTypeService",
    },
  },
};
