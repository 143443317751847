import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { MiInputTextbox } from "../index.ts";
import { makeStyles } from "tss-react/mui";
import PropTypes from "prop-types";

const useStyles = makeStyles()((_theme, { width, inputStyles }) => ({
  inputRoot: {
    width: width,
  },
  input: {
    ...inputStyles,
  },
}));
export const MiAutocomplete = ({
  handleChange,
  options,
  onSelectionChange,
  loading,
  labelText,
  helperText,
  variant,
  autoSelect = true,
  value,
  InputProps,
  width,
  inputStyles,
  required,
  getOptionLabel,
  labelTextColor,
  minWidth,
  name,
  onKeyDown,
  focusOnMount,
  ...rest
}) => {
  const { classes } = useStyles({ width, inputStyles });
  const [open, setOpen] = useState(false);
  loading = open && loading;

  const handleSearchChange = (e) => {
    handleChange && handleChange(e.target.value);
  };

  return (
    <Autocomplete
      onKeyDown={onKeyDown}
      autoHighlight
      autoSelect={autoSelect}
      classes={{ input: classes.input }}
      clearOnBlur={false}
      freeSolo
      name={name}
      {...rest}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      onChange={onSelectionChange}
      renderInput={(params) => (
        <MiInputTextbox
          {...params}
          value={value}
          labelTextColor={labelTextColor}
          classes={{ root: classes.inputRoot }}
          required={required}
          labelText={labelText}
          focusOnMount={focusOnMount}
          helperText={helperText}
          minWidth={minWidth}
          variant={variant || "outlined"}
          handleChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

MiAutocomplete.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  onSelectionChange: PropTypes.func,
  loading: PropTypes.bool,
  labelText: PropTypes.string,
  helperText: PropTypes.string,
  width: PropTypes.string,
  inputStyles: PropTypes.object,
  getOptionLabel: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
};
