import React, { useEffect, useRef, useState } from "react";
import { MiTable, MiListFilterHeader, MiBox, MiLoader } from "../index.ts";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { usePagination } from "@miview/hooks";
import { PAGINATION } from "@miview/constants";

const useStyles = makeStyles()(() => {
  return {
    row: {
      display: "flex",
      flexDirection: "row",
      flexFlow: "wrap",
      marginBottom: 10,
    },
    emptyPadding: {
      padding: 40,
    },
    emptyList: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    overlay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      opacity: 0.8,
      top: 30,
      zIndex: 4,
      left: "50%",
      flex: 1,
      backgroundColor: "rgba(255,255,255,0.2)",
    },
  };
});

const rowsPerPageOptions = [5, 10, 20, 25, 50, 100];

export const MiList = (props) => {
  const {
    data,
    setData,
    dataProvider,
    callouts,
    filterBar,
    columns,
    loading,
    tabs,
    paginationMode,
    defaultPageSize,
    handleSearch,
    searchColumns,
    refresh,
    renderHeaderButtons,
    filterArray,
    onFilterHeaderChange,
    customFooter,
    ...rest
  } = props;

  const {
    page,
    pageSize,
    rowCount,
    pageCount,
    search,
    filters,
    getPageData,
    onPageSizeChange,
    onPageChange,
    onSortChange,
    onSearchChange,
    onFilterChange,
  } = usePagination({
    setData: setData,
    getPageData: dataProvider,
    mode: paginationMode,
    defaultPageSize: defaultPageSize,
    refresh: refresh,
    searchColumns: searchColumns,
  });

  const theme = useTheme();
  const initialRender = useRef(true);
  const { classes } = useStyles();
  const [boxHeight, setBoxHeight] = useState(0);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      getPageData();
    }
    handleBoxSize();
    window.addEventListener("resize", debounce(handleBoxSize, 500));

    return () => {
      window.removeEventListener("resize", debounce(handleBoxSize, 500));
    };
  }, [refresh]);

  const renderCallouts = () => {
    return callouts && <div className={classes.row}>{callouts}</div>;
  };

  const renderTabs = () => {
    if (!tabs) {
      return;
    }
    return tabs();
  };

  const renderTable = () => {
    if (data && columns && paginationMode === PAGINATION.SERVER) {
      return (
        <MiTable
          columns={columns}
          rows={data}
          pageSize={pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          pagination
          paginationMode={paginationMode}
          sortingMode={paginationMode}
          filterMode={paginationMode}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          onSortModelChange={onSortChange}
          onFilterModelChange={onFilterChange}
          rowCount={rowCount}
          pages={pageCount}
          fetchData={getPageData}
          customFooter={customFooter}
          actionIconStyle={{ color: theme.palette.secondary.grey }}
          {...rest}
        />
      );
    }
    if (data && columns && paginationMode === PAGINATION.CLIENT) {
      return (
        <MiTable
          columns={columns}
          rows={data}
          pageSize={pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          fetchData={getPageData}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          actionIconStyle={{ color: theme.palette.secondary.grey }}
          onFilterModelChange={onFilterChange}
          filterModel={filters}
          {...rest}
        />
      );
    }
  };

  const onHandleSearch = (searchTerm) => {
    handleSearch && handleSearch(searchTerm);
    onSearchChange(searchTerm);
  };

  const renderSearch = () => {
    if (searchColumns || paginationMode === PAGINATION.SERVER) {
      return (
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <MiListFilterHeader
              filterIconStyles={{ color: theme.palette.primary.main }}
              handleSearch={onHandleSearch}
              clearSearch={() => onHandleSearch("")}
              searchTerm={search}
              filterArray={filterArray}
              filterIcon={true}
              handleChange={onFilterHeaderChange}
            />
          </div>
          <div style={{ alignSelf: "center" }}>
            {renderHeaderButtons && renderHeaderButtons()}
          </div>
        </div>
      );
    }
  };

  const debounce = (func, delay) => {
    let timeout;
    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func();
      }, delay);
    };
  };

  const handleBoxSize = () => {
    const miPageHeader = document.getElementById("mipageheader");
    const appBar = document.getElementById("appbar");
    const footer = document.getElementById("footer");
    const heightOfBox =
      window.innerHeight -
      (miPageHeader?.offsetHeight +
        appBar?.offsetHeight +
        footer?.offsetHeight);
    setBoxHeight(!tabs ? heightOfBox : heightOfBox - 55);
  };

  const renderLoader = () => {
    if (!loading) {
      return null;
    }
    return (
      <div className={classes.overlay}>
        <div>
          <MiLoader type="linear" size={10} />
          {"Loading... "}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderCallouts()}
      {renderTabs()}
      <MiBox height={boxHeight - 20}>
        <div
          style={{
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {renderLoader()}
          {renderSearch()}
          {filterBar && filterBar()}
          {renderTable()}
        </div>
      </MiBox>
    </div>
  );
};

MiList.propTypes = {
  setSorted: PropTypes.func,
  loading: PropTypes.bool,
  paginationMode: PropTypes.string,
};

MiList.defaultProps = {
  setSorted: () => null,
  loading: false,
  paginationMode: PAGINATION.SERVER,
};
