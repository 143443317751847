import { propertyItemService } from "@miview/api";

export const deleteOrRestorePlanItem =
  ({ refreshData }) =>
  async ({ itemDetails, isDeleted, isReverted, isRestored }) => {
    try {
      if (isRestored) {
        await propertyItemService.restore(itemDetails.itemId);
      }

      if (isDeleted) {
        await propertyItemService.delete(itemDetails.itemId);
      }

      if (isReverted) {
        await propertyItemService.revert(itemDetails.itemId);
      }

      refreshData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
