import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_USERS = `${API_V3}/users`;
const API_WALKS = `/walks`;
const API_BONUS = `/bonus`;
const API_PAYROLL = `/payroll`;
const API_CURRENT = `/current`;

export const userService = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_USERS}`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });
    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_USERS}/${id}`,
      method: "get",
    });
    return apiRequest(options);
  },

  getMyUser: async () => {
    const options = await apiOptions({
      url: `${API_USERS}/me`,
      method: "get",
    });
    return apiRequest(options);
  },

  create: async (request, signal) => {
    const options = await apiOptions({
      url: `${API_USERS}`,
      method: "post",
      data: request,
      signal,
    });
    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_USERS}/${id}`,
      method: "patch",
      data: request,
    });
    return apiRequest(options);
  },

  updateMyUser: async (request) => {
    const options = await apiOptions({
      url: `${API_USERS}/me`,
      method: "patch",
      data: request,
    });
    return apiRequest(options);
  },

  updatePassword: async (request) => {
    const options = await apiOptions({
      url: `${API_USERS}/me/updatepassword`,
      method: "post",
      data: request,
    });
    return apiRequest(options, "Password updated successfully");
  },

  updatePasswordAdmin: async (id, request) => {
    const options = await apiOptions({
      url: `${API_USERS}/${id}/updatepassword`,
      method: "post",
      data: request,
    });
    return apiRequest(options);
  },

  updatePin: async (request) => {
    const options = await apiOptions({
      url: `${API_USERS}/me/pin`,
      method: "put",
      data: request,
    });
    return apiRequest(options);
  },

  getBonusCurrent: async (userId) => {
    const options = await apiOptions({
      url: `${API_USERS}/${userId}${API_BONUS}${API_CURRENT}`,
      method: "get",
    });
    return apiRequest(options);
  },

  getPayrollCurrent: async (userId) => {
    const options = await apiOptions({
      url: `${API_USERS}/${userId}${API_PAYROLL}${API_CURRENT}`,
      method: "get",
    });
    return apiRequest(options);
  },

  getWalks: async (date, statuses, etag) => {
    const params = {
      url: `${API_USERS}/me${API_WALKS}`,
      method: "get",
      params: {
        date,
        statuses,
      },
    };
    if (etag) {
      params.etag = etag;
    }
    const options = await apiOptions(params);
    return apiRequest(options);
  },

  getRoles: async (id) => {
    const options = await apiOptions({
      url: `${API_USERS}/${id}/roles`,
      method: "get",
    });
    return apiRequest(options);
  },

  initiatePasswordReset: async (email) => {
    const options = await apiOptions({
      url: `${API_USERS}/${email}/initiatepasswordreset`,
      method: "post",
    });
    const toastMessageConfig = {
      failure: {
        message: "Error sending pasword reset email",
      },
      success: {
        message: "Password reset email sent",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  resetPassword: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/users/${id}/resetPassword`,
      method: "post",
      data: request,
    });
    const toastMessageConfig = {
      failure: {
        message: "Error Resetting Password",
      },
      success: {
        message: "Password Reset Successfull",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  verifyPassword: async (data) => {
    const options = await apiOptions({
      url: `${API_V3}/session`,
      method: "post",
      data,
    });
    return apiRequest(options);
  },

  resetPin: async (data) => {
    const options = await apiOptions({
      url: `${API_USERS}/me/pin`,
      method: "put",
      data,
    });
    return apiRequest(options);
  },

  checkLoggedIn: async () => {
    const options = await apiOptions({
      url: `${API_V3}/session`,
      method: "get",
    });
    return apiRequest(options);
  },

  verifyPin: async (params) => {
    const options = await apiOptions({
      url: `${API_USERS}/me/verifypin`,
      method: "post",
      params,
    });
    return apiRequest(options);
  },
};
