import { apiOptions, apiRequest } from "../../utils";
import {
  API_PO_SERVICE,
  ADDITIONAL_HEADERS,
  QUOTE_ATTACCHMENT_PATH,
} from "../../constants";

export const purchaseOrder = {
  create: async ({ data, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders`,
      headers: ADDITIONAL_HEADERS,
      method: "post",
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  getAll: async (params, queryParams) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders`,
      headers: ADDITIONAL_HEADERS,
      method: "get",
      filters: queryParams,
      params: params,
    });

    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${id}`,
      headers: ADDITIONAL_HEADERS,
      method: "get",
    });

    return apiRequest(options);
  },

  update: async ({ id, data, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${id}`,
      headers: ADDITIONAL_HEADERS,
      method: "patch",
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  replace: async ({ id, data, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${id}`,
      headers: ADDITIONAL_HEADERS,
      method: "put",
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  delete: async ({ id, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${id}`,
      headers: ADDITIONAL_HEADERS,
      method: "delete",
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
  sendEmail: async ({ data, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/email/send`,
      headers: ADDITIONAL_HEADERS,
      method: "post",
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  createPurchaseOrderItem: async ({
    id,
    data,
    successMessage,
    failureMessage,
  }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${id}/PurchaseOrderItems`,
      headers: ADDITIONAL_HEADERS,
      method: "post",
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  getAllPurchaseOrderItems: async (params, queryParams, id) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${id}/PurchaseOrderItems`,
      headers: ADDITIONAL_HEADERS,
      method: "get",
      filters: queryParams,
      params: params,
    });

    return apiRequest(options);
  },

  getPurchaseOrderItem: async (id, poItemId) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${id}/PurchaseOrderItems/${poItemId}`,
      headers: ADDITIONAL_HEADERS,
      method: "get",
    });

    return apiRequest(options);
  },

  updatePurchaseOrderItem: async ({
    id,
    poItemId,
    data,
    successMessage,
    failureMessage,
  }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${id}/PurchaseOrderItems/${poItemId}`,
      headers: ADDITIONAL_HEADERS,
      method: "patch",
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  replacePurchaseOrderItem: async ({
    id,
    poItemId,
    data,
    successMessage,
    failureMessage,
  }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${id}/PurchaseOrderItems/${poItemId}`,
      headers: ADDITIONAL_HEADERS,
      method: "put",
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  deletePurchaseOrderItem: async ({
    id,
    poItemId,
    successMessage,
    failureMessage,
  }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${id}/PurchaseOrderItems/${poItemId}`,
      headers: ADDITIONAL_HEADERS,
      method: "delete",
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
  createPurchaseOrderAttachment: async ({
    purchaseOrderId,
    data,
    successMessage,
    failureMessage,
  }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${purchaseOrderId}/PurchaseOrderAttachments/${data.fileName}`,
      headers: {
        ...ADDITIONAL_HEADERS,
        ...{ "Content-Type": "application/pdf" },
      },
      method: "post",
      data: data.file,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  getAllPurchaseOrderAttachments: async (
    purchaseOrderId,
    params,
    queryParams
  ) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${purchaseOrderId}/attachments`,
      headers: ADDITIONAL_HEADERS,
      method: "get",
      filters: queryParams,
      params: params,
    });

    return apiRequest(options);
  },

  getPurchaseOrderAttachment: async (purchaseOrderId, id) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${purchaseOrderId}/attachments/${id}`,
      headers: ADDITIONAL_HEADERS,
      method: "get",
    });

    return apiRequest(options);
  },

  updatePurchaseOrderAttachment: async ({
    purchaseOrderId,
    id,
    data,
    successMessage,
    failureMessage,
  }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${purchaseOrderId}/attachments/${id}`,
      headers: ADDITIONAL_HEADERS,
      method: "patch",
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  replacePurchaseOrderAttachment: async ({
    purchaseOrderId,
    id,
    data,
    successMessage,
    failureMessage,
  }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${purchaseOrderId}/attachments/${id}`,
      headers: ADDITIONAL_HEADERS,
      method: "put",
      data: {
        ...data,
        uri: QUOTE_ATTACCHMENT_PATH + data.selectedFileName,
      },
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  deletePurchaseOrderAttachment: async ({
    purchaseOrderId,
    FileName,
    Format,
    successMessage,
    failureMessage,
  }) => {
    const options = await apiOptions({
      url: `${API_PO_SERVICE}/purchaseOrders/${purchaseOrderId}/attachments/${
        FileName & Format
      }`,
      headers: ADDITIONAL_HEADERS,
      method: "delete",
      data: { FileName, Format },
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
};
