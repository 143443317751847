import { apiOptions, apiRequest } from "../../utils";
import { API_HEADER, PRICINGSERVICE_BASE_URL, API } from "../../constants";

const toastMessageOptions = {
  closeButton: false,
  closeOnClick: true,
  className: "toast",
};
export const quotes = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes`,
      method: "get",
      headers: API_HEADER,
      params,
      filters,
      sort,
      signal,
    });
    return apiRequest(options);
  },

  get: async (quoteId) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes/${quoteId}`,
      method: "get",
      headers: API_HEADER,
    });
    return apiRequest(options);
  },

  getAttachment: async (quoteId) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes/${quoteId}/quoteattachments`,
      method: "get",
      headers: API_HEADER,
    });
    return apiRequest(options);
  },

  createAttachment: async (quoteId, request) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes/${quoteId}/quoteattachments`,
      method: "post",
      data: request,
      headers: API_HEADER,
    });
    return apiRequest(options);
  },

  getPriceCompare: async (itemId) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quoteitems/pricecompare/item/${itemId}`,
      method: "get",
      headers: API_HEADER,
    });
    return apiRequest(options);
  },

  create: async ({ data, successMessage } = {}) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes`,
      method: "post",
      data,
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      ...toastMessageOptions,
    };
    return apiRequest(options, toastMessageConfig);
  },

  update: async ({ id, request, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes/${id}`,
      method: "patch",
      data: request,
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
      ...toastMessageOptions,
    };
    return apiRequest(options, toastMessageConfig);
  },

  replace: async ({ quoteId, quote, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes/${quoteId}`,
      method: "put",
      data: quote,
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
      ...toastMessageOptions,
    };
    return apiRequest(options, toastMessageConfig);
  },

  delete: async ({ id, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes/${id}`,
      method: "delete",
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
      ...toastMessageOptions,
    };
    return apiRequest(options, toastMessageConfig);
  },

  copy: async ({ id, successMessage }) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes/${id}/copy`,
      method: "post",
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      ...toastMessageOptions,
    };
    return apiRequest(options, toastMessageConfig);
  },

  //Account Items
  /**NEED TO BE MOVED TO ACCOUNT SERVICE*/

  getAccountItems: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/accountitems`,
      method: "get",
      headers: API_HEADER,
      filters,
      sort,
      params,
      signal,
    });
    return apiRequest(options);
  },

  createAccountItems: async (data, successMessage, failureMessage) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/accountitems`,
      method: "post",
      data: data,
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
      ...toastMessageOptions,
    };
    return apiRequest(options, toastMessageConfig);
  },

  //Quote Items

  getItems: async (quoteId) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes/${quoteId}/quoteitems`,
      method: "get",
      headers: API_HEADER,
    });
    return apiRequest(options);
  },

  createItem: async ({ quoteId, data, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes/${quoteId}/quoteitems`,
      method: "post",
      data: data,
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  removeItem: async ({ quoteItemId, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quoteitems/${quoteItemId}`,
      method: "delete",
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  updateItem: async ({ quoteItemId, data, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quoteitems/${quoteItemId}`,
      method: "patch",
      data: data,
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  //Attachment

  getQuoteExcel: async ({ quoteId, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotes/${quoteId}/xlsx`,
      method: "get",
      headers: { ...API_HEADER, "Content-Type": "blob" },
      responseType: "arraybuffer",
    });

    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
      ...toastMessageOptions,
    };
    return apiRequest(options, toastMessageConfig);
  },

  // send email request

  sendEmail: async (data, successMessage, failureMessage) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/quotemailrequest`,
      method: "post",
      data: data,
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
      ...toastMessageOptions,
    };
    return apiRequest(options, toastMessageConfig);
  },
};
