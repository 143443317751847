import React, { useState, useRef } from "react";
import { useMiDragDropZoneStyles } from "./MiDragDropZoneStyles";

const MiDragDropZone = (props) => {
  const { onDrop } = props;
  const { classes } = useMiDragDropZoneStyles();

  const [isDragging, setIsDragging] = useState(false);
  const dragInCounter = useRef(0);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragInCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragInCounter.current--;
    if (dragInCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      dragInCounter.current = 0;
    }
  };

  return (
    <div
      className={
        (isDragging && classes.dragDropZoneDragging) ||
        classes.dragDropZoneDefault
      }
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDrag(e)}
      onDragEnter={(e) => handleDragIn(e)}
      onDragLeave={(e) => handleDragOut(e)}
    >
      {props.children}
    </div>
  );
};

export default MiDragDropZone;
