import React, { useState } from "react";
import { propertyService } from "@miview/api";
import { MiLink, MiList } from "@miview/components";
import { useComponentState } from "@miview/hooks";
import { createFilter } from "@miview/utils";

const BuilderHomes = ({ builderId }) => {
  const [homes, setHomes] = useState([]);

  const stateManager = useComponentState();

  const getData = (params) => {
    const filters = createFilter().equals({ accountId: builderId });
    return stateManager.run(async () => {
      return await propertyService.getAll({ ...params, filters });
    });
  };

  const columns = [
    {
      field: "addressLine1",
      headerName: "Address",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (p) => {
        return <MiLink to={"/homes/" + p.row.propertyId} title={p.value} />;
      },
    },
    {
      field: "currentStage",
      headerName: "Current Stage",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "cityName",
      headerName: "City",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "state",
      headerName: "State",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
  ];

  return (
    <MiList
      columns={columns}
      data={homes}
      setData={setHomes}
      dataProvider={getData}
      getRowId={(item) => item.propertyId}
    />
  );
};

export default BuilderHomes;
