import React, { useState } from "react";
import { MiModal } from "../../../components/Standard/MiView";
import PropTypes from "prop-types";
import { propertyService } from "@miview/api";
import { getImageBase64FromFile } from "@miview/utils";
import { MiInputFile } from "@miview/components";

export const ImportStacksModal = ({
  propertyId,
  setImportStacksModal,
  invokeRefresh,
}) => {
  const [stacksFile, setStacksFile] = useState(null);

  const importStacks = async () => {
    await propertyService.importStacks(propertyId, stacksFile.base64);
    invokeRefresh();
  };

  const handleImportClick = (event) => {
    var file = event.target.files[0];
    getImageBase64FromFile(file, (base64, displayName) =>
      setStacksFile({
        base64,
        displayName,
      })
    );
  };

  return (
    <MiModal
      title="Import Stacks"
      saveAction={() => importStacks()}
      toggle={() => setImportStacksModal(false)}
      saveTitle="Import"
      saveDisabled={!stacksFile}
    >
      <MiInputFile
        label="Import Stacks"
        handleChange={handleImportClick}
        style={{ width: "100%" }}
        filename={stacksFile?.displayName}
        onClear={() => setStacksFile({})}
        accept=".csv"
      />
    </MiModal>
  );
};

ImportStacksModal.propTypes = {
  propertyId: PropTypes.string,
  setImportStacksModal: PropTypes.func,
  invokeRefresh: PropTypes.func,
};
