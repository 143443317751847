import React, { useState, useEffect } from "react";
import Actions from "../../../helpers/ActionHelper";
import {
  MiPageHeader,
  MiButton,
  MiModal,
  MiList,
  MiLink,
  MiDetailFields,
  MiIcon,
} from "@miview/components";
import { useTheme } from "@mui/material/styles";
import { ToolIcon } from "@miview/assets";
import {
  materialService,
  manufacturerService,
  materialCategoryService,
} from "@miview/api";
import { useRouter, useCdn, useComponentState, useEdit } from "@miview/hooks";
import { toList, getImageUrl, getCurrencyString } from "@miview/utils";
import { HTTP_STATUSES } from "@miview/constants";
import { mdiHexagonMultipleOutline, mdiPlus } from "@mdi/js";

const Materials = () => {
  const [materials, setMaterials] = useState([]);
  const [addingMaterial, setaddingMaterial] = useState(false);
  const [disableButton, setdisableButton] = useState(false);
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);

  const router = useRouter();
  const cdn = useCdn();
  const stateManager = useComponentState();
  const theme = useTheme();
  const edit = useEdit({
    name: "",
    description: "",
    itemNumber: "",
    price: "",
    categoryId: "",
    manufacturerId: "",
    image: "",
  });

  useEffect(() => {
    document.title = "Materials";
    getManufacturers();
    getMaterialCategories();
  }, []);

  const getManufacturers = () => {
    stateManager.run(async () => {
      const response = await manufacturerService.getAll({
        params: { pageSize: 9999 },
      });
      setManufacturers(response.data);
    });
  };

  const getMaterialCategories = () => {
    stateManager.run(async () => {
      const response = await materialCategoryService.getAll({
        params: { pageSize: 9999 },
      });
      const mapped = response.data
        .sort(
          (a, b) =>
            Actions.simpleSort(a.displayOrder, b.displayOrder) ||
            Actions.simpleSort(a.name, b.name)
        ) //order by displayOrder then by name
        .map((i) => ({ value: i.id, text: i.name }));
      setCategories(mapped);
    });
  };

  const closeModal = () => {
    edit.reset();
    setaddingMaterial(false);
  };

  const openModal = () => {
    setaddingMaterial(true);
  };

  const saveNewMaterial = () => {
    if (disableButton) {
      return;
    }

    setdisableButton(true);

    let materialObj = edit.edits;

    materialService.create(materialObj).then((response) => {
      if (response.status === HTTP_STATUSES.OK) {
        closeModal();
        router.navigate(`/materials/${response.data}`);
      } else {
        setdisableButton(false);
      }
    });
  };

  const columns = [
    {
      field: "itemNumber",
      headerName: "Item Number",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (p) => {
        return (
          <MiLink
            to={"/materials/" + p.id}
            title={p.value}
            style={{ marginLeft: -2 }}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      flex: 1.2,
    },
    {
      field: "categoryName",
      headerName: "Category",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      align: "left",
      flex: 2,
    },
    {
      field: "imageName",
      headerName: "Image",
      headerAlign: "left",
      align: "left",
      flex: 0.8,
      renderCell: (p) => {
        return p.value ? (
          <img src={getImageUrl(p.value, cdn)} width={50} height={50} />
        ) : (
          <img src={ToolIcon} width={50} height={50} />
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      headerAlign: "left",
      align: "left",
      flex: 0.7,
      renderCell: ({ value }) => getCurrencyString(value),
    },
    {
      field: "brand",
      headerName: "Brand",
      headerAlign: "left",
      align: "left",
      flex: 1.3,
    },
  ];

  const materialInput = [
    {
      label: "Item Number",
      id: "itemNumber",
      value: edit.getValue("itemNumber"),
      required: true,
      setValue: (e) => edit.update({ itemNumber: e }),
      width: "50%",
    },
    {
      label: "Material Name",
      id: "materialName",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "50%",
    },
    {
      label: "Material Description",
      id: "materialDescription",
      value: edit.getValue("description"),
      required: true,
      setValue: (e) => edit.update({ description: e }),
      width: "50%",
    },
    {
      label: "Price",
      fieldType: "number",
      value: edit.getValue("price"),
      setValue: (e) => edit.update({ price: e }),
      width: "50%",
    },
    {
      label: "Category",
      id: "category",
      value: edit.getValue("categoryId"),
      fieldType: "select",
      options: categories,
      required: true,
      setValue: (e) => edit.update({ categoryId: e }),
      width: "50%",
    },
    {
      label: "Manufacturer",
      id: "manufacturer",
      value: edit.getValue("manufacturerId"),
      fieldType: "select",
      options: toList(
        manufacturers.sort((a, b) => Actions.simpleSort(a.name, b.name)),
        "id",
        "name"
      ),
      required: true,
      setValue: (e) => edit.update({ manufacturerId: e }),
      width: "50%",
    },
    {
      label: "Image",
      value: edit.getValue("image"),
      fieldType: "image",
      setValue: (e) => edit.update({ image: e.base64 }),
      width: "50%",
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiHexagonMultipleOutline}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Material"
        icon={mdiPlus}
        inverse={true}
        onClick={openModal}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <React.Fragment>
      <MiPageHeader
        title="Materials"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
        loading={stateManager.isBusy()}
      />
      {stateManager.statusTag("materialListStatus")}
      <MiList
        data={materials}
        setData={setMaterials}
        dataProvider={materialService.getAll}
        columns={columns}
        disableColumnMenu
      />
      <MiModal
        key={0}
        title="Create A New Material"
        open={addingMaterial}
        onClose={closeModal}
        actions={[
          {
            name: "Cancel",
            style: { minWidth: 90 },
            onClick: closeModal,
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: saveNewMaterial,
            style: { minWidth: 90 },
            color: theme.palette.primary.green,
            inverse: true,
            disabled:
              !edit.allFilled(
                "name",
                "description",
                "itemNumber",
                "categoryId",
                "manufacturerId"
              ) || disableButton,
          },
        ]}
      >
        <MiDetailFields detailFields={materialInput} />
      </MiModal>
    </React.Fragment>
  );
};

export default Materials;
