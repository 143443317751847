import React from "react";
import { MiStepsBar } from "../../../components/Standard/MiView";
import { STEPS } from "../constants";

export const MasterSpecsStepsBar = ({ activeTab, setActiveTab }) => {
  return (
    <MiStepsBar
      steps={STEPS}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};
