import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MiModal } from "../../../components/Standard/MiView";
import { getImageBase64FromFile, checkIsNumber, toList } from "@miview/utils";
import { THEME } from "../../../components/Standard/theme";
import {
  propertyService,
  systemTypeService,
  cityService,
  communityService,
  accountServiceLegacy as accountService,
} from "@miview/api";
import {
  SYSTEM_TYPE_CATEGORIES,
  ACCOUNT_TYPES,
  HTTP_STATUSES,
} from "@miview/constants";
import { useEdit, useComponentState } from "@miview/hooks";
import {
  Image,
  MiDetailFields,
  MiSnackbar,
  MiImagePreview,
  MapContainer,
  MiImageFilePicker,
} from "@miview/components";

export const NewPropertyInfoCollapse = (props) => {
  const {
    editMode,
    toggleEdit,
    setResetCoords,
    property,
    refresh,
    propertyId,
  } = props;

  const [pipingMaterialTypes, setPipingMaterialTypes] = useState([]);
  const [garageSwingTypes, setGarageSwingTypes] = useState([]);
  const [garageSizeTypes, setGarageSizeTypes] = useState([]);
  const [garageTypes, setGarageTypes] = useState([]);
  const [powerTypes, setPowerTypes] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [cities, setCities] = useState([]);
  const [PexBrands, setPexBrands] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [propertyImgEdit, setPropertyImgEdit] = useState(false);
  const [imgSrc, setImgSrc] = useState();
  const [pic, setPic] = useState();

  const [showCommunityConfirmModal, setShowCommunityConfirmModal] =
    useState(false);

  const edit = useEdit({
    ...property,
    selectedSand: property.sandProvidedByBuilder ? "sandBuilder" : "internal",
  });
  const stateManager = useComponentState();

  const getAccounts = () => {
    stateManager.run(async () => {
      const response = await accountService.getAll({
        params: { pageSize: 9999 },
        sort: [{ field: "name", sort: "asc" }],
        filters: { type: ACCOUNT_TYPES.BUILDER },
      });
      if (response.data) {
        setBuilders(toList(response.data, "id", "name"));
      }
    });
  };

  const getPipingMaterialTypes = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: "PipingMaterial",
      });
      if (response.status === HTTP_STATUSES.OK) {
        setPipingMaterialTypes(
          toList(response.data, "systemTypeId", "mainValue")
        );
      }
    });
  };

  const getGarageSwingTypes = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: SYSTEM_TYPE_CATEGORIES.GARAGE_SWING,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setGarageSwingTypes(toList(response.data, "systemTypeId", "mainValue"));
      }
    });
  };

  const getGarageSizeTypes = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: SYSTEM_TYPE_CATEGORIES.GARAGE_SIZE_TYPE,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setGarageSizeTypes(toList(response.data, "systemTypeId", "mainValue"));
      }
    });
  };

  const getGarageTypes = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: SYSTEM_TYPE_CATEGORIES.GARAGE_TYPE,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setGarageTypes(toList(response.data, "systemTypeId", "mainValue"));
      }
    });
  };

  const getSpecialties = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: SYSTEM_TYPE_CATEGORIES.SPECIALIZATION,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setSpecialties(toList(response.data, "systemTypeId", "mainValue"));
      }
    });
  };

  const getPexBrands = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: SYSTEM_TYPE_CATEGORIES.PEX_BRAND,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setPexBrands(toList(response.data, "systemTypeId", "mainValue"));
      }
    });
  };

  const getPowerTypes = () => {
    stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: SYSTEM_TYPE_CATEGORIES.POWER_TYPE,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setPowerTypes(toList(response.data, "systemTypeId", "mainValue"));
      }
    });
  };

  const getCities = () => {
    stateManager.run(async () => {
      const response = await cityService.getCities({
        params: { pageSize: 9999 },
        sort: [{ field: "cityName", sort: "asc" }],
        filters: { stateShortName: "tx" },
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = response.data.map((i, k) => {
          return { value: i.cityId, key: k, text: i.cityName };
        });
        setCities(mapped);
      }
    });
  };

  const getCommunities = () => {
    stateManager.run(async () => {
      const response = await communityService.getAll({
        params: { pageSize: 9999 },
      });
      if (response.status !== HTTP_STATUSES.OK) {
        return;
      }
      const mapped = response.data.map((i, k) => {
        return { value: i.id, key: k, text: i.name };
      });
      setCommunities(mapped);
    });
  };

  useEffect(() => {
    getAccounts();
    getPipingMaterialTypes();
    getGarageSwingTypes();
    getGarageSizeTypes();
    getGarageTypes();
    getSpecialties();
    getPexBrands();
    getPowerTypes();
    getCities();
    getCommunities();
  }, []);

  useEffect(() => {
    setImgSrc(property.imageName);
  }, [property.imageName]);

  const savePropertyDetails = async (reassignWalks) => {
    toggleEdit(false);
    await propertyService.updateProperty(propertyId, edit.edits, reassignWalks);
    edit.reset();
    refresh();
  };

  const checkSavePropertyDetails = () => {
    if (edit.getValue("communityId") != property.communityId) {
      setShowCommunityConfirmModal(!showCommunityConfirmModal);
    } else {
      savePropertyDetails(false);
    }
  };

  const cancelPropertyDetails = (event) => {
    event.preventDefault();
    edit.reset();
    toggleEdit();
    setResetCoords(true);
  };

  const getLatLongErrorMessage = (value) => {
    if (!isLatLongValid(value)) {
      return "Please enter a valid number";
    }
    return null;
  };

  const isLatLongValid = (value) => {
    return value && !isNaN(value);
  };

  const generalFields = [
    {
      label: "Address Line 1",
      id: "addressLine1",
      value: edit.getValue("addressLine1"),
      required: true,
      setValue: (e) => edit.update({ addressLine1: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Address Line 2",
      id: "addressLine2",
      value: edit.getValue("addressLine2"),
      setValue: (e) => edit.update({ addressLine2: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "City",
      id: "cityId",
      value: edit.getValue("cityId"),
      options: cities?.length > 0 && cities,
      fieldType: "select",
      required: true,
      setValue: (e) => edit.update({ cityId: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Zipcode",
      id: "zipCode",
      value: edit.getValue("zipCode"),
      required: true,
      setValue: (e) => !checkIsNumber(e) && edit.update({ zipCode: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Latitude",
      id: "latitude",
      value: edit.getValue("latitude"),
      errorMessage: getLatLongErrorMessage(edit.getValue("latitude")),
      required: true,
      setValue: (e) => edit.update({ latitude: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Longitude",
      id: "longitude",
      value: edit.getValue("longitude"),
      errorMessage: getLatLongErrorMessage(edit.getValue("longitude")),
      required: true,
      setValue: (e) => edit.update({ longitude: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Community Latitude",
      id: "communityLatitude",
      value: edit.getValue("communityLatitude"),
      errorMessage: getLatLongErrorMessage(edit.getValue("communityLatitude")),
      readOnly: true,
      width: "50%",
    },
    {
      label: "Community Longitude",
      id: "communityLongitude",
      value: edit.getValue("communityLongitude"),
      errorMessage: getLatLongErrorMessage(edit.getValue("communityLongitude")),
      readOnly: true,
      width: "50%",
    },
  ];

  const otherFields = [
    {
      label: "Community",
      id: "communityId",
      text: edit.getValue("communityName"),
      value: edit.getValue("communityId"),
      fieldType: "select",
      options: communities?.length > 0 ? communities : [],
      required: true,
      setValue: (e) => edit.update({ communityId: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Close Date",
      id: "closeDate",
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
      clickButton: toggleEdit,
      readOnly: !editMode,
      value: edit.getValue("closeDate"),
      setValue: (e) => {
        edit.update({ closeDate: e });
      },
      width: "50%",
    },
    {
      label: "Plan",
      id: "planName",
      value: edit.getValue("planName"),
      readOnly: true,
      width: "50%",
    },
    {
      label: "Builder",
      id: "builderId",
      fieldType: "select",
      value: edit.getValue("builderId"),
      options: builders,
      required: true,
      setValue: (e) => edit.update({ builderId: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Customer",
      id: "customerNumber",
      value: edit.getValue("customerNumber"),
      fieldType: "number",
      setValue: (e) => edit.update({ customerNumber: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
      minValue: 0,
    },
    {
      label: "Year Built",
      id: "yearBuilt",
      value: edit.getValue("yearBuilt"),
      setValue: (e) => !checkIsNumber(e) && edit.update({ yearBuilt: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Sand Provider",
      id: "sandProvidedByBuilder",
      value: edit.getValue("selectedSand"),
      options: [
        { value: "internal", key: "internal", text: "Internal" },
        {
          value: "sandBuilder",
          key: "sandBuilder",
          text: edit.getValue("builderName"),
        },
      ],
      fieldType: "select",
      setValue: (e) =>
        edit.update({
          selectedSand: e,
          sandProvidedByBuilder: e === "sandBuilder",
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Specialty",
      id: "specialtyTypeId",
      value: edit.getValue("specialtyTypeId"),
      options: specialties,
      fieldType: "select",
      setValue: (e) => edit.update({ specialtyTypeId: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "PEX Brand",
      id: "pexBrandTypeId",
      value: edit.getValue("pexBrandTypeId"),
      options: PexBrands,
      fieldType: "select",
      setValue: (e) => edit.update({ pexBrandTypeId: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
  ];

  const detailFields = [
    {
      label: "Square Footage",
      id: "squareFootage",
      value: edit.getValue("squareFootage"),
      fieldType: "number",
      setValue: (e) => edit.update({ squareFootage: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
      minValue: 0,
    },
    {
      label: "Floors",
      id: "floors",
      value: edit.getValue("floors"),
      fieldType: "number",
      setValue: (e) => edit.update({ floors: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
      minValue: 0,
    },
    {
      label: "Bedrooms",
      id: "bedrooms",
      value: edit.getValue("bedrooms"),
      fieldType: "number",
      setValue: (e) => edit.update({ bedrooms: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
      minValue: 0,
    },
    {
      label: "Power Type",
      id: "powerTypeId",
      value: edit.getValue("powerTypeId"),
      options: powerTypes,
      fieldType: "select",
      readOnly: true,
      disabled: true,
      width: "50%",
    },
    {
      label: "Garage Type",
      id: "garageTypeId",
      value: edit.getValue("garageTypeId"),
      options: garageTypes,
      fieldType: "select",
      setValue: (e) => edit.update({ garageTypeId: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Garage Size",
      id: "garageSizeId",
      value: edit.getValue("garageSizeId"),
      options: garageSizeTypes,
      fieldType: "select",
      setValue: (e) => edit.update({ garageSizeId: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Swing Type",
      id: "garageSwingTypeId",
      value: edit.getValue("garageSwingTypeId"),
      options: garageSwingTypes,
      fieldType: "select",
      setValue: (e) => edit.update({ garageSwingTypeId: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Piping Material",
      id: "pipingMaterialTypeId",
      value: edit.getValue("pipingMaterialTypeId"),
      options: pipingMaterialTypes,
      fieldType: "select",
      setValue: (e) => edit.update({ pipingMaterialTypeId: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Elevation",
      id: "elevation",
      value: edit.getValue("elevation"),
      setValue: (e) => edit.update({ elevation: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Basement",
      id: "basement",
      fieldType: "checkbox",
      value: edit.getValue("basement"),
      setValue: (e) => edit.update({ basement: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Series",
      id: "series",
      value: edit.getValue("series"),
      setValue: (e) => edit.update({ series: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Plan Name",
      id: "propertyPlanName",
      value: edit.getValue("propertyPlanName"),
      setValue: (e) => edit.update({ propertyPlanName: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
  ];

  const payCalcFields = [
    {
      label: "Full Baths Downstairs",
      id: "numberOfFullBathsDown",
      value: edit.getValue("numberOfFullBathsDown") || null,
      fieldType: "number",
      setValue: (e) => edit.update({ numberOfFullBathsDown: e || 0 }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
      minValue: 0,
    },
    {
      label: "Full Baths Upstairs",
      id: "numberOfFullBathsUp",
      value: edit.getValue("numberOfFullBathsUp") || null,
      fieldType: "number",
      setValue: (e) => edit.update({ numberOfFullBathsUp: e || 0 }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
      minValue: 0,
    },
    {
      label: "Half Baths Downstairs",
      id: "numberOfHalfBathsDown",
      value: edit.getValue("numberOfHalfBathsDown") || null,
      fieldType: "number",
      setValue: (e) => edit.update({ numberOfHalfBathsDown: e || 0 }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
      minValue: 0,
    },
    {
      label: "Half Baths Upstairs",
      id: "numberOfHalfBathsUp",
      value: edit.getValue("numberOfHalfBathsUp") || null,
      fieldType: "number",
      setValue: (e) => edit.update({ numberOfHalfBathsUp: e || 0 }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
      minValue: 0,
    },
    {
      label: "2nd Kitchen",
      id: "numberOfKitchenettes",
      fieldType: "checkbox",
      value: edit.getValue("numberOfKitchenettes") == 1,
      setValue: (e) =>
        edit.update({
          numberOfKitchenettes: e ? 1 : 0,
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Fosta Pex",
      id: "isFostaPex",
      fieldType: "checkbox",
      value: edit.getValue("isFostaPex"),
      setValue: (e) => edit.update({ isFostaPex: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Bar and Utility Sinks",
      id: "numberOfSinks",
      value: edit.getValue("numberOfSinks") || null,
      fieldType: "number",
      setValue: (e) => edit.update({ numberOfSinks: e || 0 }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
      minValue: 0,
    },
    {
      label: "Pre-plumbs",
      id: "numberOfPrePlumbs",
      value: edit.getValue("numberOfPrePlumbs") || null,
      fieldType: "number",
      setValue: (e) => edit.update({ numberOfPrePlumbs: e || 0 }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
      minValue: 0,
    },
    {
      label: "Disposal Button",
      id: "numberOfDisposalSwitches",
      fieldType: "checkbox",
      value: edit.getValue("numberOfDisposalSwitches") == 1,
      setValue: (e) =>
        edit.update({
          numberOfDisposalSwitches: e ? 1 : 0,
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Washing Machine Pan",
      id: "numberOfWashingMachinePans",
      fieldType: "checkbox",
      value: edit.getValue("numberOfWashingMachinePans") == 1,
      setValue: (e) =>
        edit.update({
          numberOfWashingMachinePans: e ? 1 : 0,
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Tankless Water Heater",
      id: "isTankless",
      fieldType: "checkbox",
      value: edit.getValue("isTankless"),
      setValue: (e) => edit.update({ isTankless: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Water Heater in Attic",
      id: "numberOfAtticWaterHeaters",
      fieldType: "checkbox",
      value: edit.getValue("numberOfAtticWaterHeaters") == 1,
      setValue: (e) =>
        edit.update({
          numberOfAtticWaterHeaters: e ? 1 : 0,
        }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
    },
    {
      label: "Gas Drops",
      id: "numberOfGasDrops",
      value: edit.getValue("numberOfGasDrops") || null,
      fieldType: "number",
      setValue: (e) => edit.update({ numberOfGasDrops: e || 0 }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "50%",
      minValue: 0,
    },
  ];

  const notesFields = [
    {
      label: "City Cleanout Note",
      id: "cityCleanoutNote",
      value: edit.getValue("cityCleanoutNote"),
      multiline: true,
      rows: 4,
      maxLength: 255,
      setValue: (e) => edit.update({ cityCleanoutNote: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "100%",
    },
    {
      label: "Water Shut Off Valve Note",
      id: "waterShutOffValveNote",
      value: edit.getValue("waterShutOffValveNote"),
      multiline: true,
      rows: 4,
      maxLength: 255,
      setValue: (e) => edit.update({ waterShutOffValveNote: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "100%",
    },
    {
      label: "Slab Leak Note",
      id: "slabLeakNote",
      value: edit.getValue("slabLeakNote"),
      multiline: true,
      rows: 4,
      maxLength: 255,
      setValue: (e) => edit.update({ slabLeakNote: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "100%",
    },
    {
      label: "Gas Note",
      id: "gasNote",
      value: edit.getValue("gasNote"),
      multiline: true,
      rows: 4,
      maxLength: 255,
      setValue: (e) => edit.update({ gasNote: e }),
      clickButton: toggleEdit,
      readOnly: !editMode,
      width: "100%",
    },
  ];

  const isValid = () => {
    return (
      !edit.getValue("addressLine1") ||
      !edit.getValue("cityId") ||
      !edit.getValue("zipCode") ||
      !isLatLongValid(edit.getValue("latitude")) ||
      !isLatLongValid(edit.getValue("longitude")) ||
      !edit.getValue("communityId") ||
      !edit.getValue("builderId") ||
      !edit.getValue("planId")
    );
  };

  const coordinatesDidUpdate = (lat, lng) => {
    edit.update({
      latitude: lat,
      longitude: lng,
    });
  };

  const savePropertyImage = () => {
    stateManager.run(async () => {
      setImgSrc(pic);
      await propertyService.updateProperty(propertyId, { image: pic });
      closeImageEditModal();
    });
  };

  const closeImageEditModal = () => {
    setPropertyImgEdit(false);
    setPic(null);
  };

  return (
    <div>
      {propertyImgEdit && (
        <MiModal
          title="Edit Property Image"
          toggle={closeImageEditModal}
          saveAction={savePropertyImage}
          saveDisabled={!pic}
        >
          <MiImageFilePicker
            label="Image"
            value={property.BlobStoreId}
            handleChange={(event) => {
              const file = event.target.files[0];
              getImageBase64FromFile(file, setPic);
            }}
            style={{ width: "100%" }}
          />
          <MiImagePreview src={pic} />
        </MiModal>
      )}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            width: "50%",
            paddingRight: "1rem",
            flex: 1,
            margin: "0 .5rem 1rem .5rem",
            minWidth: 300,
            maxHeight: 220,
            position: "relative",
          }}
        >
          <Image imgSrc={imgSrc} toggle={() => setPropertyImgEdit(true)} />
        </div>
        <div
          style={{
            width: "50%",
            paddingRight: "1rem",
            flex: 1,
            margin: "0 .5rem 1rem .5rem",
            minWidth: 300,
            maxHeight: 220,
            position: "relative",
          }}
        >
          {!editMode && (
            <div
              onDoubleClick={() => toggleEdit()}
              style={{
                position: "absolute",
                borderRadius: ".25rem",
                top: 0,
                left: 0,
                zIndex: 10,
                background: "rgba(0, 0, 0, 0.1)",
                height: "100%",
                width: "100%",
              }}
            >
              {" "}
              &nbsp;
            </div>
          )}
          <MapContainer
            lat={
              property?.latitude
                ? property.latitude
                : property.communityLatitude
            }
            lng={
              property?.longitude
                ? property.longitude
                : property.communityLongitude
            }
            recenter={true}
            onCoordUpdate={coordinatesDidUpdate}
          />
        </div>
      </div>
      <div className="animated fadeIn">
        {stateManager.statusTag("propertyInfoStatus")}
        {showCommunityConfirmModal && (
          <MiModal
            title={"Reassign Existing Walks"}
            color={THEME.TANGERINE_ACCENT}
            maxWidth="90%"
            toggle={() =>
              setShowCommunityConfirmModal(!showCommunityConfirmModal)
            }
            saveAction={() => {
              savePropertyDetails(true);
              setShowCommunityConfirmModal(!showCommunityConfirmModal);
            }}
            otherAction={() => {
              savePropertyDetails(false);
              setShowCommunityConfirmModal(!showCommunityConfirmModal);
            }}
            cancelAction={() =>
              setShowCommunityConfirmModal(!showCommunityConfirmModal)
            }
            saveTitle="Save and Reassign"
            otherTitle="Save without Reassigning"
            cancelTitle="Cancel"
            stayOpenOnSave
          >
            <p>
              New walks for this home will be assigned according to the selected
              community assignees.
            </p>
            <p>Would you like to reassign existing walks for this home?</p>
          </MiModal>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            flex: 1,
          }}
        >
          <MiSnackbar
            visible={editMode}
            handleCancelClick={(e) => cancelPropertyDetails(e)}
            handleSaveClick={checkSavePropertyDetails}
            disableSave={isValid()}
          />
          <div style={{ flex: 3 }}>
            {generalFields && (
              <MiDetailFields
                detailFields={generalFields}
                headerProps={{
                  style: { marginRight: "2%" },
                  icon: "my_location",
                  title: "Address",
                }}
              />
            )}
            {detailFields && (
              <MiDetailFields
                detailFields={detailFields}
                headerProps={{
                  style: { marginRight: "2%" },
                  icon: "home",
                  title: "Details",
                }}
              />
            )}
            {payCalcFields && (
              <MiDetailFields
                detailFields={payCalcFields}
                headerProps={{
                  style: { marginRight: "2%" },
                  icon: "attach_money",
                  title: "Job Pay Calculation",
                }}
              />
            )}
            {otherFields && (
              <MiDetailFields
                detailFields={otherFields}
                headerProps={{
                  style: { marginRight: "2%" },
                  icon: "list",
                  title: "Other",
                }}
              />
            )}
          </div>
          <div style={{ flex: 1, minWidth: 220 }}>
            {detailFields && (
              <MiDetailFields
                detailFields={notesFields}
                headerProps={{
                  style: { marginRight: "2%" },
                  icon: "description",
                  title: "Notes",
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

NewPropertyInfoCollapse.propTypes = {
  editMode: PropTypes.bool,
  toggleEdit: PropTypes.func,
  updateProperty: PropTypes.func,
  property: PropTypes.object,
  setResetCoords: PropTypes.func,
  refresh: PropTypes.func,
};
