import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { SaveAndNextSectionButton } from "./SaveAndNextSectionButton";
import {
  MiSpecsTitle,
  MiSpecsSubTitle,
} from "../../../components/Standard/MiView";
import { MiDetailFields } from "@miview/components";
import { THEME } from "@miview/theme";

export const DetailsPage = ({
  rightColumnOn,
  setRightColumnOn,
  edit,
  builders,
  communities,
  handleSaveDetails,
}) => {
  const detailFields = [
    {
      label: "Name",
      value: edit.getValue("name"),
      setValue: (e) => edit.update({ name: e }),
      required: true,
      width: "50%",
    },
    {
      label: `Number of Floors`,
      options: [
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
      ],
      fieldType: "select",
      value: edit.getValue("numberOfFloors"),
      setValue: (e) => edit.update({ numberOfFloors: e }),
      width: "50%",
      minWidth: 120,
    },
    {
      label: "Builder",
      value: edit.getValue("builderId"),
      fieldType: "select",
      options: builders,
      required: true,
      setValue: (e) => edit.update({ builderId: e }),
      width: "50%",
    },
    {
      label: "Community",
      fieldType: "select",
      value: edit.getValue("communityId"),
      options: communities,
      required: true,
      setValue: (e) => edit.update({ communityId: e }),
      width: "50%",
    },
  ];

  return (
    <Fragment>
      <MiSpecsTitle
        setRightColumnOn={setRightColumnOn}
        rightColumnOn={rightColumnOn}
        icon={<LibraryBooksIcon style={styles.icon} />}
      >
        Create Master Details
      </MiSpecsTitle>
      <MiSpecsSubTitle>Add master template plan details</MiSpecsSubTitle>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <MiDetailFields detailFields={detailFields} />
      </Grid>
      <SaveAndNextSectionButton
        onClick={handleSaveDetails}
        disabled={!edit.allFilled("communityId", "builderId", "name")}
      />
    </Fragment>
  );
};

DetailsPage.propTypes = {};

const styles = {
  customLabel: {
    fontSize: "0.8rem",
    color: "rgb(150, 148, 146)",
    marginBottom: "2px",
  },
  icon: { color: THEME.STEPS_BAR[0] },
};
