import { useState } from "react";
import { tradeService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { useComponentState } from "../useComponentState/useComponentState";

const mapTrades = (trades) => {
  return trades.map((trade) => {
    return { ...trade, value: trade.id, label: trade.name };
  });
};

export const useTrades = () => {
  const [trades, setTrades] = useState([]);
  const stateManager = useComponentState();

  const getTrades = () => {
    stateManager.run(async () => {
      const response = await tradeService.getAll({});
      if (response.status === HTTP_STATUSES.OK) {
        setTrades(mapTrades(response.data));
      }
    });
  };

  const plumbingId = trades.find((p) =>
    p.name.toLowerCase().includes("plumb")
  )?.id;

  return { trades, plumbingId, getTrades };
};
