import React, { useState } from "react";
import PropTypes from "prop-types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Collapse from "@mui/material/Collapse";
import { THEME } from "@miview/theme";

export const MiDragToZone = ({
  index,
  areaId,
  itemDragging,
  reorderItems,
  setItemDragging,
  filteredItems,
}) => {
  const [active, setActive] = useState(false);

  const checkBeforeActivating = () => {
    if (itemDragging) {
      if (itemDragging.areaId === areaId) {
        if (itemDragging.index !== index && itemDragging.index + 1 !== index) {
          return true;
        }
      }
    }

    return false;
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActive(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    reorderItems({ itemDragging, index, areaId, filteredItems });
    setItemDragging(false);
    setActive(false);
  };

  const onDragOverSensor = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActive(true);
  };

  return (
    <Collapse in={checkBeforeActivating()}>
      <div
        style={{
          height: 30,
          textAlign: "center",
          border: active
            ? `1px solid ${THEME.GREEN_PRIMARY}`
            : `1px dashed ${THEME.GREEN_PRIMARY}`,
          width: "100%",
          opacity: 0.8,
          cursor: "grab",
          margin: "0 10px",
          backgroundColor: active ? THEME.GREEN_PRIMARY : "white",
        }}
        onDragOver={onDragOverSensor}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        id={`DroppableSpot-${index}`}
      >
        <MoreHorizIcon
          style={{ color: active ? "white" : "black", margin: "0 auto" }}
        />
      </div>
    </Collapse>
  );
};

MiDragToZone.propTypes = {
  index: PropTypes.number,
  areaId: PropTypes.number,
  itemDragging: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  reorderItems: PropTypes.func,
  setItemDragging: PropTypes.func,
  filteredItems: PropTypes.array,
};
