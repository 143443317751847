import React, { useState } from "react";
import {
  MiModalConfirm,
  MiList,
  MiButton,
  MiIcon,
  MiPageHeader,
} from "@miview/components";
import { expenseTypeService } from "@miview/api";
import { createToast } from "@miview/toast";
import { TOAST_TYPES, HTTP_STATUSES, PAGINATION } from "@miview/constants";
import { CreateEditExpenseTypeModal } from "./CreateEditExpenseTypeModal";
import { useComponentState } from "@miview/hooks";
import { mdiCheck, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import { useTheme } from "@mui/material";
import { mdiCashMultiple } from "@mdi/js";

export const ExpenseTypeTab = (props) => {
  const { tabs } = props;

  const [expenseTypeSelectData, setExpenseTypeSelectData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const stateManager = useComponentState();
  const theme = useTheme();

  const getExpenseTypes = (params) => {
    return stateManager.run(async () => {
      return await expenseTypeService.getAll(params);
    });
  };

  const deleteExpenseType = () => {
    stateManager.run(async () => {
      const response = await expenseTypeService.delete(selectedRow.id);
      if (response.status === HTTP_STATUSES.OK) {
        createToast("Deleted Successfully", TOAST_TYPES.SUCCESS);
        closeDeleteModal();
        setRefresh(!refresh);
      }
    });
  };

  const openCreateUpdateModal = (obj) => {
    setSelectedRow(obj);
    setIsCreateUpdateModalOpen(true);
  };

  const closeCreateUpdateModal = () => {
    setSelectedRow();
    setIsCreateUpdateModalOpen(false);
    setRefresh(!refresh);
  };

  const openDeleteModal = (row) => {
    setIsDeleteModalOpen(true);
    setSelectedRow(row);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedRow();
  };

  const expenseTypeColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "externalAccountId",
      headerName: "Ledger Account",
      flex: 1,
    },
    {
      field: "requiresPin",
      headerName: "Requires PIN to View",
      flex: 1,
      renderCell: (c) => c.value && <MiIcon path={mdiCheck} />,
    },
    {
      field: "displayOrder",
      headerName: "Display Order",
      flex: 1,
    },
  ];

  const getRowActions = () => {
    return [
      {
        text: "DELETE",
        onClick: (e) => openDeleteModal(e),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
      {
        text: "EDIT",
        onClick: (e) => openCreateUpdateModal(e),
        renderIcon: () => <MiIcon path={mdiPencil} size={1} />,
      },
    ];
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiCashMultiple}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const headerContent = () => (
    <MiButton
      title="Expense Type"
      icon={mdiPlus}
      inverse
      onClick={() => openCreateUpdateModal()}
      color={theme.palette.primary.main}
    />
  );

  return (
    <>
      <MiPageHeader
        title="Pay Settings"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={headerContent()}
        loading={stateManager.isBusy()}
      />
      <MiList
        data={expenseTypeSelectData}
        columns={expenseTypeColumns}
        dataProvider={getExpenseTypes}
        setData={setExpenseTypeSelectData}
        tabs={tabs}
        defaultPageSize={20}
        refresh={refresh}
        getRowActions={getRowActions}
        searchColumns={[
          "name",
          "category",
          "externalAccountId",
          "displayOrder",
        ]}
        paginationMode={PAGINATION.CLIENT}
      />
      <CreateEditExpenseTypeModal
        open={isCreateUpdateModalOpen}
        onClose={closeCreateUpdateModal}
        expenseType={selectedRow}
      />
      <MiModalConfirm
        title="Confirm Delete"
        open={isDeleteModalOpen}
        description={`Delete the following Pay Activity: ${selectedRow?.name}?`}
        handlePositive={deleteExpenseType}
        handleNegative={closeDeleteModal}
      />
    </>
  );
};
