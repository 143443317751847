export const STATES = [
  { shortName: "AL", name: "Alabama", id: 1 },
  { shortName: "AK", name: "Alaska", id: 2 },
  { shortName: "AZ", name: "Arizona", id: 3 },
  { shortName: "AR", name: "Arkansas", id: 4 },
  { shortName: "CA", name: "California", id: 5 },
  { shortName: "CO", name: "Colorado", id: 6 },
  { shortName: "CT", name: "Connecticut", id: 7 },
  { shortName: "DE", name: "Delaware", id: 8 },
  { shortName: "DC", name: "District Of Columbia", id: 9 },
  { shortName: "FL", name: "Florida", id: 10 },
  { shortName: "GA", name: "Georgia", id: 11 },
  { shortName: "HI", name: "Hawaii", id: 12 },
  { shortName: "ID", name: "Idaho", id: 13 },
  { shortName: "IL", name: "Illinois", id: 14 },
  { shortName: "IN", name: "Indiana", id: 15 },
  { shortName: "IA", name: "Iowa", id: 16 },
  { shortName: "KS", name: "Kansas", id: 17 },
  { shortName: "KY", name: "Kentucky", id: 18 },
  { shortName: "LA", name: "Louisiana", id: 19 },
  { shortName: "ME", name: "Maine", id: 20 },
  { shortName: "MD", name: "Maryland", id: 21 },
  { shortName: "MA", name: "Massachusetts", id: 22 },
  { shortName: "MI", name: "Michigan", id: 23 },
  { shortName: "MN", name: "Minnesota", id: 24 },
  { shortName: "MS", name: "Mississippi", id: 25 },
  { shortName: "MO", name: "Missouri", id: 26 },
  { shortName: "MT", name: "Montana", id: 27 },
  { shortName: "NE", name: "Nebraska", id: 28 },
  { shortName: "NV", name: "Nevada", id: 29 },
  { shortName: "NH", name: "New Hampshire", id: 30 },
  { shortName: "NJ", name: "New Jersey", id: 31 },
  { shortName: "NM", name: "New Mexico", id: 32 },
  { shortName: "NY", name: "New York", id: 33 },
  { shortName: "NC", name: "North Carolina", id: 34 },
  { shortName: "ND", name: "North Dakota", id: 35 },
  { shortName: "OH", name: "Ohio", id: 36 },
  { shortName: "OK", name: "Oklahoma", id: 37 },
  { shortName: "OR", name: "Oregon", id: 38 },
  { shortName: "PA", name: "Pennsylvania", id: 39 },
  { shortName: "RI", name: "Rhode Island", id: 40 },
  { shortName: "SC", name: "South Carolina", id: 41 },
  { shortName: "SD", name: "South Dakota", id: 42 },
  { shortName: "TN", name: "Tennessee", id: 43 },
  { shortName: "TX", name: "Texas", id: 44 },
  { shortName: "UT", name: "Utah", id: 45 },
  { shortName: "VT", name: "Vermont", id: 46 },
  { shortName: "VA", name: "Virginia", id: 47 },
  { shortName: "WA", name: "Washington", id: 48 },
  { shortName: "WV", name: "West Virginia", id: 49 },
  { shortName: "WI", name: "Wisconsin", id: 50 },
  { shortName: "WY", name: "Wyoming", id: 51 },
];
