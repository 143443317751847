import React from "react";
import { decorate, observable, action } from "mobx";
import { Provider } from "mobx-react";
import { createUUID } from "@miview/utils";

class Toastore {
  show = false;
  message = "";
  type = null;

  create = (message, type = null, navigate = "", options = null) => {
    this.messageId = createUUID();
    this.show = true;
    this.message = message;
    this.type = type;
    this.link = navigate;
    this.options = options;
  };

  destroy = () => {
    this.show = false;
    this.message = "";
    this.type = null;
    this.link = "";
  };
}

decorate(Toastore, {
  show: observable,
  message: observable,
  messageId: observable,
  type: observable,
  create: action,
  destroy: action,
});

const toastore = new Toastore();

const Toaster = ({ children }) => {
  return <Provider globalToastStore={toastore}>{children}</Provider>;
};

const createToast = (message, type = null, navigate = "", options = null) => {
  toastore.create(message, type, navigate, options);
};

const destroyToast = () => {
  toastore.destroy();
};

export { createToast, destroyToast, Toaster };
