import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import {
  MiBox,
  MiListFilterHeader,
  MiTable,
  MiButton,
  MiIcon,
} from "@miview/components";
import { useTheme } from "@mui/material/styles";
import { handleSearch } from "@miview/utils";
import {
  mdiCheckCircleOutline,
  mdiCloseCircle,
  mdiCheckCircle,
  mdiFileLink,
  mdiChevronLeft,
} from "@mdi/js";
import { makeStyles } from "tss-react/mui";
import { IMPORT_WORK_KEYS, TOAST_TYPES } from "@miview/constants";
import { createToast } from "@miview/toast";

const useStyles = makeStyles()(() => {
  return {
    tableContainter: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    acceptButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      position: "relative",
    },
  };
});

export const ImportedWorkTable = (props) => {
  const {
    data,
    fetchData,
    mode,
    onAccept,
    handleSelectedMapping,
    handleShowAccepted,
    filterMode,
    onFilterModelChange,
  } = props;
  const [rows, setRows] = useState(data);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const theme = useTheme();
  const { classes } = useStyles();

  useEffect(() => {
    setRows(data);
  }, [data]);

  const FULFILLED = "fulfilled";

  const HOMES_COLUMNS = [
    {
      hideable: false,
      groupable: false,
      field: "addressLine1",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "countyName",
      groupable: false,
      headerName: "County",
      flex: 1,
      renderCell: (params) => renderCounty(params),
    },
    {
      hideable: false,
      field: "builderName",
      headerName: "Builder",
      flex: 1,
    },
    {
      field: "communityName",
      headerName: "Community",
      flex: 1,
    },
    {
      field: "acceptStatus",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "isNew",
      headerName: "Action",
      flex: 1,
      valueGetter: ({ value }) => (value ? "New" : "Updated"),
    },
    {
      field: "canAccept",
      headerName: "",
      width: 80,
      renderCell: (params) => renderIconStatus(params),
    },
    {
      field: "runId",
      headerName: "",
      width: 100,
      renderCell: (params) => renderManageButton(params),
    },
  ];

  const renderDocument = ({ value }) => {
    if (!value) {
      return null;
    }
    return (
      <a href={value} target="_blank" rel="noreferrer noopener">
        <MiIcon
          path={mdiFileLink}
          size={1}
          color={theme.palette.primary.grey}
        />
      </a>
    );
  };

  const JOBS_COLUMNS = [
    {
      hideable: false,
      field: "addressLine1",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "builderName",
      headerName: "Builder",
      flex: 1,
    },
    {
      field: "communityName",
      headerName: "Community",
      flex: 1,
    },
    {
      field: "acceptStatus",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "isNew",
      headerName: "Action",
      flex: 1,
      valueGetter: ({ value }) => (value ? "New" : "Updated"),
    },
    {
      field: "documentUrl",
      headerName: "Document",
      width: 120,
      renderCell: (params) => renderDocument(params),
    },
    {
      field: "canAccept",
      headerName: "",
      width: 80,
      renderCell: (params) => renderIconStatus(params),
    },
    {
      field: "runId",
      headerName: "",
      width: 100,
      renderCell: (params) => renderManageButton(params),
    },
  ];

  const updatedColumns =
    mode === IMPORT_WORK_KEYS.HOMES ? HOMES_COLUMNS : JOBS_COLUMNS;

  const renderIconStatus = ({ value }) => {
    const iconSize = 0.9;
    if (value) {
      return (
        <MiIcon
          path={mdiCheckCircle}
          size={iconSize}
          color={theme.palette.primary.green}
        />
      );
    }
    return (
      <MiIcon
        path={mdiCloseCircle}
        size={iconSize}
        color={theme.palette.primary.red}
      />
    );
  };

  const renderCounty = ({ row }) => {
    return row.countyName || row.countyNameOriginal;
  };

  const checkFulfilled = (settled) => {
    const filterErrors = settled.filter((p) => p.status !== FULFILLED);
    if (filterErrors.length > 0) {
      createToast(
        `Unable to update (${filterErrors?.length}) ${mode}`,
        TOAST_TYPES.ERROR
      );
    }
    if (filterErrors !== selectedIds?.length) {
      createToast(
        `Successfully updated (${selectedIds?.length}) ${mode}`,
        TOAST_TYPES.SUCCESS
      );
    }
  };

  const handleAccept = async () => {
    try {
      const promiseArr = selectedIds.map((id) => onAccept(id));
      const settled = await Promise.allSettled(promiseArr);
      checkFulfilled(settled);
    } finally {
      fetchData();
    }
  };

  const quickSearch = (search) => {
    setSearchTerm(search);
    setRows(handleSearch(data, search));
  };

  const clearSearch = () => {
    setSearchTerm("");
    setRows(handleSearch(data, ""));
  };

  const handleRefresh = () => {
    setSearchTerm("");
    fetchData();
  };

  const handleSelectionChange = (ids) => {
    setSelectedIds(ids);
  };

  const renderManageButton = ({ row }) => {
    if (!row.id) {
      return null;
    }
    return (
      <MiButton
        title="Manage"
        color={theme.palette.primary.main}
        onClick={() => handleSelectedMapping(row)}
      />
    );
  };

  return (
    <MiBox height="100%">
      <Grid className={classes.tableContainter}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <MiListFilterHeader
              searchTerm={searchTerm}
              filterIconStyles={{ color: theme.palette.primary.main }}
              handleSearch={quickSearch}
              handleRefreshClick={handleRefresh}
              clearSearch={clearSearch}
            />
          </div>
          <MiButton
            style={{ margin: "auto" }}
            title="Accept"
            icon={mdiCheckCircleOutline}
            color={theme.palette.primary.white}
            backgroundColor={theme.palette.primary.main}
            onClick={handleAccept}
          />
        </div>
        <div className={classes.acceptButton}>
          <MiButton
            title="Show Accepted"
            icon={mdiChevronLeft}
            onClick={handleShowAccepted}
            color={theme.palette.primary.main}
            backgroundColor={"transparent"}
            style={{ position: "absolute", bottom: -43, right: 0, zIndex: 100 }}
          />
        </div>

        <MiTable
          columns={updatedColumns}
          rows={rows}
          disableColumnPinning={true}
          checkboxSelection={true}
          selectedRowIds={selectedIds}
          isRowSelectable={(params) => params.row.canAccept}
          rowGroupingColumnMode="single"
          experimentalFeatures={{
            rowGrouping: true,
          }}
          onSelectionModelChange={handleSelectionChange}
          filterMode={filterMode}
          onFilterModelChange={onFilterModelChange}
        />
      </Grid>
    </MiBox>
  );
};
