import { apiOptions, apiRequest } from "../utils";
import { API_V3 } from "../constants";

export const walkTypeTemplateService = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/walkTypeTemplates`,
      method: "get",
      params,
      filters,
      sort,
      signal,
    });
    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/walkTypeTemplates/${id}`,
      method: "get",
    });

    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/walkTypeTemplates`,
      method: "post",
      data: request,
    });
    const toastMessageConfig = {
      success: {
        message: "Template Created Successfully",
      },
    };

    return apiRequest(options, toastMessageConfig);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/walkTypeTemplates/${id}`,
      method: "post",
      data: request,
    });
    const toastMessageConfig = {
      success: {
        message: "Template Updated Successfully",
      },
    };

    return apiRequest(options, toastMessageConfig);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/walkTypeTemplates/${id}`,
      method: "delete",
    });
    const toastMessageConfig = {
      success: {
        message: "Template Deleted Successfully",
      },
    };

    return apiRequest(options, toastMessageConfig);
  },

  getWalkTypes: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/walkTypeTemplates/${id}/walkTypes`,
      method: "get",
    });
    return apiRequest(options);
  },

  getItems: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/walkTypeTemplates/${id}/walkTypeTemplateItems`,
      method: "get",
    });
    return apiRequest(options);
  },

  createItem: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/walkTypeTemplates/${id}/walkTypeTemplateItems`,
      method: "post",
      data: request,
    });
    const toastMessageConfig = {
      success: {
        message: "Template Item Created Successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  publishTemplateChanges: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/walkTypeTemplates/${id}/updateProperties`,
      method: "post",
    });
    const toastMessageConfig = {
      success: {
        message: "Property Templates Updated Successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
};
