import React, { useState } from "react";
import CreateNewUserModal from "./CreateNewUserModal";
import { useRouter, useComponentState } from "@miview/hooks";
import { userService } from "@miview/api";
import { MANAGE_USERS_ACTIVE_STATUS } from "@miview/constants";
import { useTheme, alpha } from "@mui/material";
import {
  MiPageHeader,
  MiButton,
  MiList,
  MiMultiselectChip,
  MiLink,
  MiIcon,
  Chip,
} from "@miview/components";
import { mdiAccountCogOutline, mdiPlus } from "@mdi/js";
import { createFilter, combineFilters, createUUID } from "@miview/utils";

const fetchUserUuid = createUUID();
const INITIAL_FILTER = createFilter().getFilter();

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [filterModelState, setFilterModelState] = useState(INITIAL_FILTER);
  const [selectedChip, setSelectedChip] = useState([
    MANAGE_USERS_ACTIVE_STATUS[0],
  ]);

  const theme = useTheme();
  const router = useRouter();
  const stateManager = useComponentState();

  const fetchUserData = (params) => {
    stateManager.abort(fetchUserUuid);
    const filters = getCombinedFilters();
    return stateManager.run(async (signal) => {
      return userService.getAll({ ...params, filters, signal });
    }, fetchUserUuid);
  };

  const handleFilterModelChange = (filters) => {
    setFilterModelState(filters);
    getCombinedFilters();
    setRefresh(!refresh);
  };

  const handleChipSelectionChange = (item) => {
    setSelectedChip(item);
    setRefresh(!refresh);
  };

  const getCombinedFilters = () => {
    if (!selectedChip.length || selectedChip.length > 1) {
      return filterModelState;
    }
    return combineFilters(
      filterModelState,
      createFilter().equals({ active: selectedChip[0].value })
    );
  };

  const gotoEdit = (id) => {
    router.push(`/users/${id}`);
  };

  const getColor = (val) => {
    return val ? theme.palette.primary.green : theme.palette.primary.red;
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (p) => (
        <MiLink
          title={p.value}
          style={{ margin: 0, padding: 0 }}
          to={"/users/" + p.row.userId}
        />
      ),
    },
    {
      field: "email",
      headerName: "Username",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "personEmail",
      headerName: "Email",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "titleName",
      headerName: "Title",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "roleNames",
      headerName: "Role(s)",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
    },
    {
      field: "active",
      headerName: "Status",
      type: "singleSelect",
      headerAlign: "left",
      align: "left",
      valueOptions: [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
      ],
      flex: 0.8,
      renderCell: ({ value }) => {
        return (
          <Chip
            text={value ? "Active" : "Inactive"}
            style={{
              display: "flex",
              borderRadius: "30px",
              height: "30px",
              minWidth: "80px",
              justifyContent: "center",
              padding: "0 7px 0 7px",
            }}
            color={alpha(getColor(value), 0.2)}
            textColor={getColor(value)}
          />
        );
      },
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiAccountCogOutline}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="User"
        icon={mdiPlus}
        inverse={true}
        onClick={() => setAddUserModal(true)}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <>
      {stateManager.statusTag("userListStatus")}
      <MiPageHeader
        title="Manage Users"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
        loading={stateManager.isBusy()}
      />
      <CreateNewUserModal
        openModal={addUserModal}
        closeModal={() => setAddUserModal(false)}
        redirectToEdit={gotoEdit}
      />
      <MiList
        data={users}
        setData={setUsers}
        dataProvider={fetchUserData}
        getRowId={(row) => row.userId}
        refresh={refresh}
        columns={columns}
        filterModel={filterModelState}
        onFilterModelChange={handleFilterModelChange}
        rightToolbarComponent={
          <MiMultiselectChip
            items={MANAGE_USERS_ACTIVE_STATUS}
            selectedItems={selectedChip}
            size="small"
            activeColor={theme.palette.primary.main}
            handleChange={handleChipSelectionChange}
          />
        }
      />
    </>
  );
};

export default ManageUsers;
