import { apiOptions, apiRequest } from "../../utils";
import { API_HEADER, PRICINGSERVICE_BASE_URL, API } from "../../constants";

export const vendors = {
  getAll: async () => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/preferredvendors`,
      method: "get",
      headers: API_HEADER,
    });
    return apiRequest(options);
  },

  create: async (categoryId, data, successMessage, failureMessage) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/categories/${categoryId}/preferredvendors`,
      method: "post",
      data: data,
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
  update: async (data, vendorID, successMessage, failureMessage) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/preferredvendors/${vendorID}`,
      method: "patch",
      data: data,
      headers: API_HEADER,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
};
