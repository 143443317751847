import { useState } from "react";
import { planService, planAreaService, propertyService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const savePlanArea =
  ({ planId, planAreaForm, resetForm, refreshData }) =>
  async () => {
    try {
      const requestPayload = {
        planId,
        planAreaId: planAreaForm.planAreaId || 0,
        name: planAreaForm.planAreaName,
        propertySectionId: planAreaForm.propertySectionId,
        displayOrder: 1,
        floorId: planAreaForm.floorId,
        image: planAreaForm.image,
      };

      if (planAreaForm.planAreaId === 0) {
        await planAreaService.create(requestPayload);
      } else {
        await planAreaService.update(planAreaForm.planAreaId, requestPayload);
      }

      refreshData();
      resetForm();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

const PLANAREA_FORM_INITIAL_STATE = {
  categoryId: 0,
  planAreaId: 0,
  propertyPlanAreaId: 0,
  propertySectionId: 0,
  planAreaName: "",
  floorId: "",
  isGroundFloor: false,
  imageUrl: "",
  adminEdit: false,
  isRequired: false,
};

const INITIAL_STATE = {
  planAreas: [],
  setPlanAreas: () => null,
  planAreasPreview: [],
  setPlanAreasPreview: () => null,
  planAreasLoading: "",
  planAreaForm: PLANAREA_FORM_INITIAL_STATE,
  setPlanAreaForm: () => null,
  setPlanAreasLoading: "",
  isConfirmingDelete: 0,
  setIsConfirmingDelete: () => null,
  handleSavePlanArea: () => null,
  handleDeletePlanArea: () => null,
  toggleFormOn: () => null,
  resetForm: () => null,
  updateForm: () => null,
  addNewArea: () => null,
  saveAreaBasic: () => null,
  saveFullArea: () => null,
  refreshData: () => null,
  planAreaEdting: "",
};

const BLANK_AREA = {
  categoryId: "",
  displayOrder: null,
  floorId: null,
  isGroundFloor: false,
  name: "",
  plan: "",
  planArea: "",
  planAreaId: 0,
  planId: null,
  planItems: [],
  propertySection: "",
  propertySectionId: null,
  isDraft: true,
  isRequired: false,
};

export const usePlanAreas = (planId, propertyId) => {
  const [planAreaForm, setPlanAreaForm] = useState(INITIAL_STATE.planAreaForm);
  const [planAreas, setPlanAreas] = useState([]);
  const [planAreasBase, setPlanAreasBase] = useState([]);
  const [planAreasPreview, setPlanAreasPreview] = useState([]);
  const [planAreasLoading, setPlanAreasLoading] = useState(
    INITIAL_STATE.planAreasLoading
  );
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(
    INITIAL_STATE.isConfirmingDelete
  );

  const handleGetPlanAreas = async ({ setState }) => {
    if (propertyId) {
      const response = await propertyService.getAreas(propertyId);
      if (response.status === HTTP_STATUSES.OK) {
        setState(response.data);
      }
    } else {
      const response = await planService.getAreas(planId);
      if (response.status === HTTP_STATUSES.OK) {
        setState(response.data);
      }
    }
  };

  const handleGetPlanAreasPreview = async () => {
    const response = await planService.applyOptions(planId);
    if (response.status === HTTP_STATUSES.OK) {
      setPlanAreasPreview(response.data);
    }
  };

  const handleGetPropertyPreview = async () => {
    const response = await propertyService.getPreview(propertyId);
    if (response.status === HTTP_STATUSES.OK) {
      setPlanAreasPreview(response.data);
    }
  };

  const refreshData = () => {
    if (propertyId) {
      handleGetPropertyPreview();
      handleGetPlanAreas({ setState: setPlanAreas });
      handleGetPlanAreas({ setState: setPlanAreasBase });
    } else {
      handleGetPlanAreasPreview();
      handleGetPlanAreas({ setState: setPlanAreas });
    }
  };

  const handleSavePlanArea = async (planId, request) => {
    try {
      const requestPayload = {
        planId,
        planAreaId: request.planAreaId || 0,
        name: request.planAreaName,
        propertySectionId: request.propertySectionId,
        displayOrder: 1,
        floorId: request.floorId,
        image: request.image,
      };

      if (request.planAreaId === 0) {
        await planAreaService.create(requestPayload);
      } else {
        await planAreaService.update(request.planAreaId, requestPayload);
      }

      refreshData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleSavePropertyPlanArea = async (request) => {
    const requestPayload = {
      planId,
      planAreaId: request.planAreaId || 0,
      name: request.planAreaName,
      propertySectionId: request.propertySectionId,
      displayOrder: 1,
      floorId: request.floorId,
      image: request.image,
    };
    if (request.planAreaId === 0) {
      await propertyService.createArea(propertyId, requestPayload);
    } else {
      await propertyService.updateArea(
        propertyId,
        request.planAreaId,
        requestPayload
      );
    }
    refreshData();
  };

  const handleDeletePlanArea = async ({ areaId }) => {
    await planAreaService.delete(areaId);
    refreshData();
  };

  const addNewArea = async () => {
    setPlanAreas(planAreas.concat([BLANK_AREA]));
  };

  const removeNewArea = async () => {
    setPlanAreas(planAreas.slice(0, planAreas.length - 1));
  };

  const saveAreaBasic = async (request) => {
    const requestPayload = {
      propertySectionId: request.propertySectionId,
      floorId: request.floorId,
      isRequired: request.isRequired,
      name: request.name,
      planId,
    };

    const result = await planAreaService.create(requestPayload);

    if (result.status === HTTP_STATUSES.OK) {
      refreshData();
    }
  };

  const saveFullArea = async (request) => {
    await planAreaService.submit(request.id, {
      areaName: request.name,
      floorId: request.floorId,
      isRequired: request.isRequired,
    });
    refreshData();
  };

  return {
    planAreas,
    planAreasPreview,
    setPlanAreas,
    setPlanAreasPreview,
    setPlanAreasBase,
    planAreasBase,
    planAreasLoading,
    planAreaForm,
    setPlanAreaForm,
    setPlanAreasLoading,
    isConfirmingDelete,
    handleGetPropertyPreview,
    handleGetPlanAreas,
    setIsConfirmingDelete,
    handleSavePlanArea,
    handleSavePropertyPlanArea,
    handleDeletePlanArea,
    addNewArea,
    removeNewArea,
    saveAreaBasic,
    saveFullArea,
    refreshData,
  };
};
