import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const planAreaService = {
  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/planareas/`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/planareas/${id}`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/planareas/${id}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  submit: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/planareas/${id}/submit`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },
};
