import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const drawingService = {
  deleteDrawing: async ({ id, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/drawings/${id}`,
      method: "delete",
      signal,
    });

    return apiRequest(options);
  },

  uploadDrawing: async ({ request, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/drawings`,
      method: "post",
      data: request,
      signal,
    });

    return apiRequest(options);
  },
};
