import React from "react";
import { Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { SubHeader } from "./";
import en from "../locale/en.json";
import { StatesCities } from "../dummy";
import {
  MiCheckbox,
  MiInputSelectBox,
  MiInputTextbox,
} from "@miview/components";

const useStyles = makeStyles()((theme) => ({
  formAlign: {
    marginTop: 15,
    paddingRight: 10,
  },
  span: {
    color: theme.palette.accent.bluegrey,
  },
}));

const states = StatesCities.map((item) => {
  return { label: item.State, value: item.State };
});

const cities = StatesCities[0].Cities.map((item) => {
  return { label: item, value: item };
});

const RemittanceAddressForm = ({ editMode, edit, isMissingRequiredField }) => {
  const { classes } = useStyles();

  const theme = useTheme();
  const billingHouseNumberError =
    isMissingRequiredField && !edit.getValue("billingHouseNumber");
  const billingCityError =
    isMissingRequiredField && !edit.getValue("billingCity");
  const billingStateError =
    isMissingRequiredField && !edit.getValue("billingState");
  const billingZipCodeError =
    isMissingRequiredField && !edit.getValue("billingZipCode");

  return (
    <Grid container style={{ margin: "20px 0" }}>
      <Grid item xs={12}>
        <SubHeader text={en.remittanceAddress} />
      </Grid>
      <Grid item xs={12}>
        <MiCheckbox
          checked={!!edit.getValue("useCorporateAddress")}
          label={en.useCorporateAddress}
          labelPlacement={"end"}
          onChange={(e) =>
            edit.update({ useCorporateAddress: e.target.checked })
          }
          disabled={!editMode}
        />
      </Grid>
      {!edit.getValue("useCorporateAddress") && (
        <>
          <Grid item xs={12} style={{ paddingRight: 10 }}>
            <MiInputTextbox
              labelTextColor={
                billingHouseNumberError
                  ? theme.palette.secondary.red
                  : theme.palette.primary.main
              }
              labelText={en.address}
              required
              error={billingHouseNumberError}
              helperText={
                billingHouseNumberError ? "Required a valid address" : ""
              }
              disabled={!editMode}
              value={edit.getValue("billingHouseNumber")}
              handleChange={(e) =>
                edit.update({ billingHouseNumber: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.formAlign}>
            <MiInputTextbox
              labelTextColor={theme.palette.primary.main}
              labelText={en.address2}
              disabled={!editMode}
              value={edit.getValue("billingStreetNumber")}
              handleChange={(e) =>
                edit.update({ billingStreetNumber: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.formAlign}>
            <MiInputSelectBox
              labelTextColor={
                billingCityError
                  ? theme.palette.secondary.red
                  : theme.palette.primary.main
              }
              labelText={en.city}
              menuItems={cities}
              disableNone={true}
              displayEmpty={true}
              isPlaceholder={true}
              disabled={!editMode}
              required
              error={billingCityError}
              minWidth={150}
              value={edit.getValue("billingCity")}
              handleChange={(e) => edit.update({ billingCity: e.target.value })}
            />
          </Grid>
          <Grid item xs={6} className={classes.formAlign}>
            <MiInputSelectBox
              labelTextColor={
                billingStateError
                  ? theme.palette.secondary.red
                  : theme.palette.primary.main
              }
              labelText={en.state}
              menuItems={states}
              disableNone={true}
              displayEmpty={true}
              isPlaceholder={true}
              required
              error={billingStateError}
              disabled={!editMode}
              minWidth={150}
              value={edit.getValue("billingState")}
              handleChange={(e) =>
                edit.update({ billingState: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6} className={classes.formAlign}>
            <MiInputTextbox
              labelTextColor={
                billingZipCodeError
                  ? theme.palette.secondary.red
                  : theme.palette.primary.main
              }
              labelText={en.zipCode}
              required
              error={billingZipCodeError}
              helperText={billingZipCodeError ? "Enter valid Zip Code" : ""}
              disabled={!editMode}
              minWidth={150}
              type={"number"}
              value={edit.getValue("billingZipCode")}
              handleChange={(e) =>
                edit.update({ billingZipCode: e.target.value })
              }
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default React.memo(RemittanceAddressForm);
