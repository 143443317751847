export const API_V2 = "/api/v2";
export const API_V3 = "/api/v3";

export const API = "/api";

export const USER_TOKEN_NATIVE = "userToken";

export const USER_TOKEN = "userToken";

export const POST = "POST";
export const PATCH = "PATCH";
export const PUT = "PUT";
export const GET = "GET";
export const DELETE = "DELETE";

export const API_CONTENT_JSON = "application/json";

export const API_ACCOUNT_SERVICE =
  "https://miv-func-accountservice-dev-centralus-001.azurewebsites.net";

export const API_HEADER = {
  "Miview-TenantId": 1,
  "Miview-UserId": "c2eb3b1e-1a3c-4d9e-b1f2-f9a7d7b6559b",
  "Miview-Permissions": "CanViewAccounts",
};

export const API_PO_SERVICE =
  "https://miv-func-purchaseorderservice-dev-centralus-001.azurewebsites.net";

export const ADDITIONAL_HEADERS = {
  "Miview-TenantId": 1,
  "Miview-UserId": "c2eb3b1e-1a3c-4d9e-b1f2-f9a7d7b6559b",
  "Miview-Permissions": "CanViewAccounts",
};
export const PRICINGSERVICE_BASE_URL =
  "https://miv-func-pricingservice-dev-centralus-001.azurewebsites.net";

export const ITEM_MASTER_BASE_URL =
  "https://miv-dev-technodeuce-api.azurewebsites.net";

export const API_HEADERS_TEMP = {
  "Miview-TenantId": 1,
  "Miview-UserId": "c2eb3b1e-1a3c-4d9e-b1f2-f9a7d7b6559b",
  "Miview-Permissions": "1",
};

export const API_INTEGRATION_SERVICE_URL =
  "https://miv-func-integrationservice-dev-southcentralus-001.azurewebsites.net/api";

export const QUOTE_ATTACCHMENT_PATH =
  "https://mivstpricingservicedev.blob.core.windows.net/quoteattachments/";

export const REQUISITON_SDERVICE_BASE_URL =
  "https://miv-func-requisitionservice-dev-centralus-001.azurewebsites.net";
