import { useState } from "react";
import { propertyService } from "@miview/api";

export const useProperty = (propertyId) => {
  const [property, setProperty] = useState({});

  const handleGetProperty = async () => {
    const propertyResponse = await propertyService.get(propertyId);
    setProperty(propertyResponse.data);
  };

  return {
    property,
    setProperty,
    handleGetProperty,
  };
};
