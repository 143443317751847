import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiCheckbox, MiButton, MiIcon } from "@miview/components";
import FilterMenu from "./FilterMenu";
import { mdiRefresh, mdiFilter } from "@mdi/js";
import {
  Select,
  InputLabel,
  InputBase,
  FormControl,
  ListItemText,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useDebounce } from "@miview/hooks";

const useStyles = makeStyles()((theme) => ({
  filterList: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  search_Outer: {
    display: "flex",
    alignItems: "center",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid",
    padding: 5,
    borderColor: theme.palette.lightAccent.grey,
    marginRight: 8,
    marginLeft: 0,
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
  },
  searchIcon: {
    alignSelf: "center",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.secondary.bluegrey,
  },
  filterIcons: {
    border: "1px solid",
    borderColor: theme.palette.lightAccent.grey,
    padding: 10,
    borderRadius: "4px",
    color: theme.palette.primary.blue,
    marginRight: 8,
    "&:hover": {
      backgroundColor: theme.palette.light.main,
      cursor: "pointer",
    },
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    paddingLeft: 12,
    transition: theme.transitions.create("width"),
    color: theme.palette.secondary.bluegrey,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  filter: {
    marginLeft: 0,
    marginTop: 4,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "100%",
    },
    display: "flex",
    padding: 10,
    backgroundColor: theme.palette.light.grey,
  },
  checkbox: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-end",
  },
  checkboxContainer: {
    marginLeft: 10,
  },
  dropdown: {
    width: "50%",
  },
  formControl: {
    marginLeft: 10,
    minWidth: 120,
    maxWidth: 300,
  },
  label: {
    position: "relative",
    top: 15,
  },
  Box: {
    marginRight: 40,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ListFilterHeader = (props) => {
  const {
    filterArray,
    handleRefreshClick,
    handleSearch,
    handleChange,
    filterIconStyles,
    actionText,
    actionOnClick,
  } = props;
  const theme = useTheme();
  const { classes } = useStyles();
  const [filterIcon, setFilterIcon] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  useEffect(() => {
    handleSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handlefilterIconClick = () => {
    setFilterIcon(!filterIcon);
  };

  const onHandleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const onHandleRefresh = () => {
    setSearchTerm("");
    handleRefreshClick && handleRefreshClick();
  };

  const renderAction = () => {
    if (actionOnClick) {
      return (
        <MiButton
          color={theme.palette.primary.main}
          inverse={true}
          title={actionText}
          onClick={actionOnClick}
          style={{
            fontSize: theme.fontSize.medium,
            fontWeight: theme.fontWeight.medium,
          }}
        />
      );
    }
    return null;
  };

  const renderFilterToggle = () => {
    if (!filterArray) {
      return null;
    }
    return (
      <div
        className={classes.filterIcons}
        onClick={handlefilterIconClick}
        style={{
          ...filterIconStyles,
        }}
      >
        <MiIcon
          path={mdiFilter}
          size={0.9}
          color={theme.palette.primary.main}
        />
      </div>
    );
  };

  const renderToggles = () => {
    const checkboxes = filterArray.filter((item) => item.type === "checkbox");
    if (!checkboxes?.length > 0) {
      return null;
    }

    return (
      <div className={classes.checkbox}>
        {checkboxes.map((el) => (
          <div className={classes.checkboxContainer} key={el.label}>
            <MiCheckbox
              key={el.label}
              checked={el.value}
              onChange={() => handleChange(!el.value, el.label)}
              color="primary"
              label={el.label}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderFilterItems = (ele) => {
    return (
      <FormControl className={classes.formControl} key={ele.label}>
        {!ele?.value && (
          <InputLabel id="demo-simple-select-helper-label">
            {ele.label}
          </InputLabel>
        )}
        <Select
          labelId={`"demo-mutiple-checkbox-label-"${ele.label}`}
          id={`"demo-mutiple-checkbox-"${ele.label}`}
          value={ele.value}
          onChange={(e) => handleChange(e.target.value, ele.label)}
          MenuProps={MenuProps}
          label={ele.label}
          disableUnderline={true}
        >
          {ele.options.map((item) => (
            <MenuItem key={item.label} value={item.value}>
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderMultiSelectDropdowns = () => {
    const dropdowns = filterArray.filter((item) => item.type === "multiSelect");
    if (!dropdowns?.length > 0) {
      return null;
    }
    return (
      <Box display="flex">
        {dropdowns.map((ele, index) => (
          <Box key={index} className={classes.Box}>
            <FilterMenu {...ele} />
          </Box>
        ))}
      </Box>
    );
  };

  const renderSelectDropdowns = () => {
    const dropdowns = filterArray.filter((item) => item.type === "select");
    if (!dropdowns?.length > 0) {
      return null;
    }
    return (
      <div className={classes.dropdown}>
        {dropdowns.map((ele) => renderFilterItems(ele))}
      </div>
    );
  };

  const renderFilters = () => {
    if (!filterArray || !filterIcon) {
      return null;
    }
    return (
      <div className={classes.filter}>
        {renderMultiSelectDropdowns()}
        {renderSelectDropdowns()}
        {renderToggles()}
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search"
          value={searchTerm}
          onChange={onHandleSearch}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
        />
      </div>
    );
  };

  return (
    <div className={classes.filterList}>
      <div className={classes.search_Outer}>
        {renderSearch()}
        <div
          className={classes.filterIcons}
          onClick={onHandleRefresh}
          style={{ ...filterIconStyles }}
        >
          <MiIcon
            path={mdiRefresh}
            size={0.9}
            color={theme.palette.primary.main}
          />
        </div>
        {renderFilterToggle()}
        {renderAction()}
      </div>
      {renderFilters()}
    </div>
  );
};

ListFilterHeader.propTypes = {
  filterArray: PropTypes.array,
  toggles: PropTypes.array,
  handleRefreshClick: PropTypes.func,
  refreshIconStyles: PropTypes.object,
  filterIconStyles: PropTypes.object,
  actionText: PropTypes.string,
  actionOnClick: PropTypes.func,
};

ListFilterHeader.defaultProps = {
  filterArray: [],
  toggles: [],
};

export default React.memo(ListFilterHeader);
