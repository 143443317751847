import { useState } from "react";
import { walkTypeService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

const INITIAL_STATE = [];

export const useWalkTypes = () => {
  const [walkTypes, setWalkTypes] = useState(INITIAL_STATE);

  const handleGetWalkTypes = async ({ params = {} } = {}) => {
    const jobTypes = await walkTypeService.getWalkTypes(params);
    if (jobTypes.status === HTTP_STATUSES.OK) {
      jobTypes.data.sort((x, y) => (x.walkTypeName > y.walkTypeName ? 1 : -1));
      setWalkTypes(jobTypes.data);
    }
  };
  return {
    walkTypes,
    setWalkTypes,
    handleGetWalkTypes,
  };
};
