export const OFFLINE_TOKEN = "userToken";
export const OFFLINE_ROOTURL = "url";
export const OFFLINE_USER = "user";
export const OFFLINE_DEVICE = "device";
export const OFFLINE_EMAIL = "email";
export const OFFLINE_ADMIN = "adminUser";
export const OFFLINE_NAME = "name";
export const OFFLINE_WALKS = "walks";
export const OFFLINE_WALKS_GENERAL_CACHE = "walksGeneralCache";
export const OFFLINE_CATEGORIES = "categories";
export const OFFLINE_FAILREASONS = "walksFail";
export const OFFLINE_ITEM_FAILURE_REASONS = "itemFailures";
export const OFFLINE_BLOBSTORAGE = "blobStoreEndpoint";
export const OFFLINE_COMPACT_VIEW = "compactView";
export const OFFLINE_ETAG = "etag";
export const OFFLINE_ETAG_PUNCH = "etagPunch";
export const OFFLINE_ETAG_JOB = "etagJob";
export const OFFLINE_PUNCH_TAB_ETAG = "punchEtag";
export const OFFLINE_WALKS_NOT_TODAY = "walksNotToday";
export const OFFLINE_WALKS_ETAG = "walksETag";
export const OFFLINE_IMAGE_RESPONSE = "imageResponses";
export const OFFLINE_STAGE_SUMMARIES = "stageSummaries";

export const SYNC = {
  DATA: "syncData",
  ERRORS: "syncErrors",
  IMAGES: "syncImages",
};
