export default {
  items: [
    {
      name: "Homes",
      url: "/homes",
      icon: "mdiHomeOutline",
    },
    { name: "Stages", url: "/stages", icon: "mdiLayersTripleOutline" },
    {
      name: "Communities",
      url: "/communities",
      icon: "mdiHomeGroup",
    },
    {
      permissionName: "CanViewPlan",
      name: "Plans",
      url: "/plans",
      icon: "mdiFloorPlan",
    },
    {
      permissionName: "CanViewMaterials",
      name: "Materials",
      url: "/materials",
      icon: "mdiHexagonMultipleOutline",
    },
    {
      permissionName: "CanManageAssignments",
      name: "Assignments",
      url: "/inspectionpreps",
      icon: "mdiCalendarAccountOutline",
    },
    {
      permissionName: "CanViewManufacturers",
      name: "Manufacturers",
      url: "/manufacturers",
      icon: "mdiFactory",
    },
    {
      name: "Builders",
      url: "/builders",
      icon: "mdiAccountHardHatOutline",
    },
    {
      permissionName: "CanViewAdminSettings",
      name: "Admin",
      icon: "mdiCogOutline",
      children: [
        {
          name: "Areas",
          url: "/areas",
          icon: "mdiVectorSquareClose",
        },
        {
          name: "Area Categories",
          url: "/areacategories",
          icon: "mdiGroup",
        },
        {
          permissionName: "CanManagePaySchedules",
          name: "Pay Schedules",
          url: "/payschedules",
          icon: "mdiCashClock",
        },
        {
          id: "payActivities",
          name: "Pay Activities",
          url: "/payactivities",
          icon: "mdiCashSync",
        },
        {
          name: "Pay Settings",
          url: "/paysettings",
          icon: "mdiCashMultiple",
        },
        {
          name: "Failure Reasons",
          url: "/failurereasons",
          icon: "mdiThumbDownOutline",
        },
        {
          permissionName: "CanManageHolidays",
          name: "Holidays",
          url: "/holidays",
          icon: "mdiCalendarStarOutline",
        },
        {
          name: "Cities",
          url: "/cities",
          icon: "mdiCityVariantOutline",
        },
        {
          name: "Users",
          permissionName: "CanViewManageUsers",
          url: "/users",
          icon: "mdiAccountCogOutline",
        },
        {
          name: "Material Categories",
          url: "/materialcategories",
          icon: "mdiSelectGroup",
        },
        {
          name: "Roles",
          url: "/roles",
          icon: "mdiShieldAccountOutline",
        },
        {
          name: "Titles",
          url: "/titles",
          icon: "mdiBriefcaseAccountOutline",
        },
        {
          name: "Stage Mgmt",
          url: "/stagetypes",
          icon: "mdiPageNextOutline",
        },
        {
          name: "Trades",
          url: "/trades",
          icon: "mdiHammerWrench",
        },
        {
          name: "Templates",
          url: "/templates",
          icon: "mdiFormatTextbox",
        },
        {
          permissionName: "CanViewAuditHistory",
          name: "Audit History",
          url: "/audit",
          icon: "mdiHistory",
        },
        {
          id: "walks",
          name: "Walks",
          url: "/walks",
          icon: "mdiClipboardTextSearchOutline",
        },
      ],
    },
    {
      name: "Help",
      url: "/support",
      icon: "mdiHelpCircleOutline",
    },
  ],
};
