import { BUILDER, TRADE } from "../constants";
import { MiTHEME } from "@miview/theme";

export const CYCLE_TYPES = {
  Cycle_Builder: {
    label: BUILDER,
    value: "Cycle_Builder",
    color: MiTHEME.palette.primary.orange,
    issueCategoryFilter: 6,
  },
  Cycle_Trade: {
    label: TRADE,
    value: "Cycle_Trade",
    color: MiTHEME.palette.primary.purple,
    issueCategoryFilter: 2,
  },
};
