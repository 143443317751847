import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid } from "@mui/material";
import { MiSideNav, MiAppBar } from "../index.ts";
import { APP_BAR_HEIGHT, DRAWER_WIDTH, URLS } from "@miview/constants";
import { MiTHEME } from "@miview/theme";
import { Outlet } from "react-router-dom";

export const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: "flex",
      paddingTop: Object.values(URLS).includes(global.baseUrl)
        ? 85
        : APP_BAR_HEIGHT,
    },
    rightMainContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    shadowRight: {
      boxShadow: "2px 0 8px 0 rgba(0,0,0,0.2)",
      zIndex: theme.zIndex.modal,
    },
    footer: {
      width: "100%",
      position: "fixed",
      zIndex: 1200,
      bottom: 0,
      left: 0,
    },
  };
});

export const Session = ({
  nav,
  renderFooter,
  renderRightIcons,
  user,
  mainApp,
  apps,
  appSettings,
  settingApp,
  admin,
}) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (window.innerWidth < MiTHEME.breakpoint.sm) {
      handleDrawer(false);
    }
  }, []);

  const handleDrawer = (val) => {
    setOpen(val);
  };

  const renderLeftContainer = () => {
    return <Grid />;
  };

  const buildAppsBasedOnPermissions = (nav, permissions) => {
    let newApps = [];
    permissions = permissions || [];
    nav.forEach((item) => {
      if (
        (item.permissionName &&
          permissions.indexOf(item.permissionName) > -1) ||
        !item.permissionName
      ) {
        newApps.push(item);
      }
    });
    return newApps;
  };
  return (
    <Grid>
      <MiAppBar
        open={open}
        leftContent={renderLeftContainer()}
        rightContent={renderRightIcons}
        handleDrawer={handleDrawer}
        user={user}
        mainApp={mainApp}
        settingApp={settingApp}
        addOnDrawerWidth={0}
      />
      <Grid className={classes.root}>
        <Grid>
          <MiSideNav
            navItems={nav?.items}
            handleDrawer={handleDrawer}
            drawerWidth={DRAWER_WIDTH}
            open={open}
            apps={buildAppsBasedOnPermissions(
              apps,
              localStorage.getItem("permissions")
            )}
            mainApp={mainApp}
            admin={admin}
            appSettings={appSettings}
          />
        </Grid>
        <Grid className={classes.rightMainContainer}>
          <Grid sx={{ flexGrow: 1, paddingBottom: 50 }}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.footer} id="footer">
        {renderFooter && renderFooter()}
      </Grid>
    </Grid>
  );
};
