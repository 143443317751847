import React, { useEffect, useState } from "react";
import { formatDateTime } from "../../../helpers/dateTools";
import { walkService } from "@miview/api";
import {
  MiTable,
  MiLoader,
  MiLink,
  MiSectionHeader,
  Chip,
  MiIcon,
} from "@miview/components";
import { alpha } from "@mui/material";
import {
  HTTP_STATUSES,
  WALK_RECORD_TYPES,
  WALK_STATUS_INSPECTION_LABELS,
  WALK_STATUS_OPTIONS_KEYED,
  IN_PROGRESS,
  UNASSIGNED,
  NOT_SCHEDULED,
  SCHEDULED,
  COMPLETE,
  CANCELLED,
  NOT_STARTED,
  PENDING,
  INCOMPLETE,
} from "@miview/constants";
import { useComponentState, useRouter } from "@miview/hooks";
import { createFilter } from "@miview/utils";
import moment from "moment";
import { mdiTag, mdiCalendarRemove, mdiCalendarSync } from "@mdi/js";
import { MiTHEME } from "@miview/theme";
import GreenTagModal from "../../StageWalks/StageWalkDetails/GreenTagModal";
import { CancelWorkModal } from "../../StageWalks/StageWalkDetails/CancelWorkModal";
import ScheduleModal from "../../StageWalks/StageWalkDetails/ScheduleModal";
import RescheduleModal from "../../StageWalks/StageWalkDetails/RescheduleModal";

export const InspectionsCollapse = (props) => {
  const { PropertyId } = props;
  const [inspections, setInspections] = useState([]);
  const [isGreenTagModalOpen, setIsGreenTagModalOpen] = useState(false);
  const [isCancelWorkModalOpen, setIsCancelWorkModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [selectedInspectionId, setSelectedInspectionId] = useState();
  const stateManager = useComponentState();
  const router = useRouter();

  const disabledIconStatuses = [COMPLETE, CANCELLED, NOT_STARTED, INCOMPLETE];
  const scheduleDisabledStatuses = [UNASSIGNED, NOT_SCHEDULED, PENDING];
  const rescheduleDisabledStatuses = [SCHEDULED, IN_PROGRESS];
  const greenTagRedtagCancelDisabledStatuses = [
    UNASSIGNED,
    NOT_SCHEDULED,
    SCHEDULED,
    IN_PROGRESS,
    PENDING,
  ];

  const sortInspections = (inspections) => {
    return inspections.sort((x, y) => {
      if (x.scheduledStartDate < y.scheduledStartDate) {
        return -1;
      }
      if (x.scheduledStartDate > y.scheduledStartDate) {
        return 1;
      }
      return 0;
    });
  };

  const filters = createFilter().equals({
    propertyId: PropertyId,
    recordTypeId: [WALK_RECORD_TYPES.INSPECTION],
  });

  const getInspections = () => {
    stateManager.run(async () => {
      const response = await walkService.getAll({
        params: { pageSize: 200 },
        filters,
      });
      if (response.status === HTTP_STATUSES.OK) {
        const ordered = sortInspections(response.data);
        const updatedOrdered = ordered.map((item) => {
          return {
            ...item,
            disabledMoreVert: disabledIconStatuses.includes(item.status),
          };
        });
        setInspections(updatedOrdered);
      }
    });
  };

  const getRowActions = (row) => {
    return [
      {
        text: "Schedule",
        renderIcon: () => (
          <MiIcon
            path={mdiCalendarSync}
            color={MiTHEME.palette.secondary.blue}
          />
        ),
        onClick: () => {
          setSelectedInspectionId(row.id);
          setIsScheduleModalOpen(true);
        },
        hide: !scheduleDisabledStatuses.includes(row.status),
      },
      {
        text: "Reschedule",
        renderIcon: () => (
          <MiIcon
            path={mdiCalendarSync}
            color={MiTHEME.palette.secondary.blue}
          />
        ),
        onClick: () => {
          setSelectedInspectionId(row.id);
          setIsRescheduleModalOpen(true);
        },
        hide: !rescheduleDisabledStatuses.includes(row.status),
      },
      {
        text: "Green Tag",
        renderIcon: () => (
          <MiIcon path={mdiTag} color={MiTHEME.palette.secondary.green} />
        ),
        hide: !greenTagRedtagCancelDisabledStatuses.includes(row.status),
        onClick: () => {
          setSelectedInspectionId(row.id);
          setIsGreenTagModalOpen(true);
        },
      },
      {
        text: "Red Tag",
        renderIcon: () => (
          <MiIcon path={mdiTag} color={MiTHEME.palette.primary.red} />
        ),
        hide: !greenTagRedtagCancelDisabledStatuses.includes(row.status),
        onClick: () => {
          router.navigate({
            pathname: `/walks/${row.id}`,
            search: "?redTagMode=true",
          });
        },
      },
      {
        text: "Cancel",
        renderIcon: () => (
          <MiIcon
            path={mdiCalendarRemove}
            color={MiTHEME.palette.secondary.red}
          />
        ),
        hide: !greenTagRedtagCancelDisabledStatuses.includes(row.status),
        onClick: () => {
          setSelectedInspectionId(row.id);
          setIsCancelWorkModalOpen(true);
        },
      },
    ];
  };

  const closeGreenTagModal = () => {
    setIsGreenTagModalOpen(false);
    setSelectedInspectionId();
  };

  const closeCancelWorkModal = () => {
    setIsCancelWorkModalOpen(false);
    setSelectedInspectionId();
  };
  const closeScheduleModal = () => {
    setIsScheduleModalOpen(false);
    setSelectedInspectionId();
  };

  const closeRescheduleModal = () => {
    setIsRescheduleModalOpen(false);
    setSelectedInspectionId();
  };

  const renderStatusChip = (item) => {
    const statusProperties = WALK_STATUS_OPTIONS_KEYED[item];
    if (!statusProperties) {
      return null;
    }
    return (
      <Chip
        text={WALK_STATUS_INSPECTION_LABELS[statusProperties.value]}
        style={{
          display: "flex",
          width: "fit-content",
          minWidth: "90px",
          justifyContent: "center",
        }}
        color={alpha(statusProperties.color, 0.1)}
        textColor={statusProperties.color}
      />
    );
  };

  useEffect(() => {
    getInspections();
  }, []);

  const getSelectedDate = (date) => {
    if (!date) {
      return null;
    }
    return moment.parseZone(date);
  };

  const columns = [
    {
      field: "name",
      headerName: "Inspections",
      headerAlign: "left",
      align: "left",
      flex: 0.9,
      renderCell: ({ row, value }) => {
        return (
          <MiLink
            to={`/walks/${row.id}`}
            style={{
              marginLeft: 0,
              paddingLeft: 0,
              whiteSpace: "normal",
            }}
            title={value}
          />
        );
      },
    },
    {
      field: "stageTypeName",
      headerName: "Stage",
      headerAlign: "left",
      align: "left",
      flex: 0.7,
    },
    {
      field: "walkTypeName",
      headerName: "Inspection Type",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ value, row }) => {
        return (
          <div>
            {row.attemptNumber > 1
              ? value + " (#" + row.attemptNumber + ")"
              : value}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (item) => renderStatusChip(item.value),
    },
    {
      field: "readyForInspectionDate",
      headerName: "Ready for Inspection Date",
      headerAlign: "left",
      align: "left",
      flex: 0.95,
      valueFormatter: ({ value }) => formatDateTime(getSelectedDate(value)),
    },
    {
      field: "scheduledStartDate",
      headerName: "Scheduled Date",
      headerAlign: "left",
      align: "left",
      flex: 0.95,
      valueFormatter: ({ value }) => formatDateTime(getSelectedDate(value)),
    },
    {
      field: "stageInternalCycleTime",
      headerName: "Cycle Time",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "assignedUserName",
      headerName: "Assigned User",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
  ];

  const renderTable = () => {
    if (stateManager.isBusy()) {
      return <MiLoader />;
    }
    if (!inspections?.length) {
      return (
        <div style={{ paddingTop: 10 }}>
          <MiSectionHeader
            title={"Inspections have not been created for this Home."}
          />
        </div>
      );
    }
    return (
      <>
        <GreenTagModal
          open={isGreenTagModalOpen}
          onClose={closeGreenTagModal}
          walkId={selectedInspectionId}
          handleGetWalk={getInspections}
        />
        <CancelWorkModal
          open={isCancelWorkModalOpen}
          onClose={closeCancelWorkModal}
          walkId={selectedInspectionId}
          handleGetWalk={getInspections}
        />
        <ScheduleModal
          open={isScheduleModalOpen}
          onClose={closeScheduleModal}
          walkId={selectedInspectionId}
          onSubmit={getInspections}
        />
        <RescheduleModal
          open={isRescheduleModalOpen}
          onClose={closeRescheduleModal}
          walkId={selectedInspectionId}
        />
        <MiTable
          tableContainerStyle={{
            width: "100%",
            minHeight: "100px",
          }}
          columns={columns}
          rows={inspections}
          toolbarProps={{ visible: false }}
          style={{ minHeight: "100px" }}
          headerHeight={40}
          autoHeight={true}
          hideFooter={false}
          pagination
          disableColumnMenu
          getRowActions={getRowActions}
        />
      </>
    );
  };

  return (
    <React.Fragment>
      {stateManager.statusTag("propertyWorkListStatus")}
      {renderTable()}
    </React.Fragment>
  );
};
