import { MiTHEME, THEME } from "@miview/theme";
import { ICONS_NATIVE_STATUS, ICONS_WEB_STATUS } from "../icons";

export const HASPHOTO = "Has Photo";

// STATUS
export const PUNCH_TYPE = {
  REWALK_BUILDER: "Rewalk Builder",
  REWALK_INTERNAL: "Rewalk Internal",
  NEW: "New",
  INTERNAL: "Internal",
  BUILDER: "Builder",
  BOTH: "Both",
};

export const WALK_TYPE = {
  BOX_AND_WRAP: "Box and wrap",
};

export const STAGE_TYPE_ID = {
  BOX_AND_WRAP: 5,
};

export const WALK_PROPERTY = {
  PUNCH_TYPE: "punchType",
  ASSIGNED_USER_NAME: "assignedUserName",
  COMMUNITY_NAME: "communityName",
  WALK_RECORD_TYPE: "walkRecordType",
};

export const ORIENTATION = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
};

export const PAGINATION = {
  SERVER: "server",
  CLIENT: "client",
};

export const CURRENCY = "Currency";
export const METHOD_GET = "GET";
export const METHOD_POST = "POST";
export const METHOD_PATCH = "PATCH";
export const METHOD_PUT = "PUT";
export const METHOD_DELETE = "DELETE";

export const TODAY = "Today";
export const SCHEDULED = "Scheduled";
export const COMPLETE = "Complete";
export const INCOMPLETE = "Incomplete";
export const CANCELLED = "Cancelled";
export const ABORTED = "Aborted";
export const NA = "NotApplicable";
export const IN_PROGRESS = "Inprogress";
export const IN_PROGRESS_LABEL = "In Progress";
export const NOT_SCHEDULED = "Not Scheduled";
export const READY_FOR_INSPECTION = "Ready for Inspection";
export const STAGECOMPLETE = "Stage Complete";
export const PARTIALLY_READY = "Partially Ready";
export const READY = "Ready";
export const HOLD = "Hold";
export const ABORT = "Abort";
export const VERIFIED = "Verified";
export const NOTREADY = "NotReady";
export const RELEASE = "Release";
export const APPROVED = "Approved";
export const REJECTED = "Rejected";
export const DECLINED = "Declined";
export const DENIED = "Denied";
export const OPEN = "Open";
export const CLOSED = "Closed";
export const PENDING = "Pending";
export const NOT_READY = "Not Ready";
export const PUNCHMAN = "PunchMan";
export const CREW = "Crew";
export const UPLOAD = "upload";
export const CREATE_PUNCH = "create";
export const UPDATE_PUNCH = "update";
export const UPDATE_WALKITEM = "updateWalkItem";

export const PAYABLE = "Payable";
export const POTENTIAL = "Potential";
export const PAID = "Paid";
export const DEDUCTION = "Deduction";
export const DEDUCTED = "Deducted";
export const UNVERIFIED = "Unverified";
export const NEGATIVE = "Negative";
export const WITHHOLDING = "Withholding";
export const PROCESSING = "Processing";
export const TOTAL = "Total";
export const CURRENT = "Current";

export const WORK_VERIFIED = "WorkVerified";
export const NOT_ACCEPTED = "NotAccepted";
export const REOPENED = "Re-opened";
export const WORK_COMPLETED = "Work Completed";
export const NOT_STARTED = "NotStarted";
export const NOT_STARTED_LABEL = "Not Started";
export const UNASSIGNED = "Unassigned";

export const FAIL_REASON = "failReason";
export const ISSUE_CATEGORYID = "issueCategoryId";

// TABS
export const EPO = "EPO";
export const EXTRAS = "Extra";
export const PUNCH_ITEMS = "Punch";
export const STAGES = "Stages";
export const CHECKLIST = "Checklist";
export const EXTENSIONS = "extensions";
export const DETAILS = "Details";
export const SYNC_IMAGES = "syncImages";
export const SYNC_DATA = "syncData";
export const SYNC_ERRORS = "syncErrors";
export const POURED = "Poured";
export const FORM = "Form";
export const MENU = "Menu";
export const INSPECTIONS = "Inspections";
export const INSPECTIONJOBS = "Insp. Jobs";
export const POSTINSPECTION_SHORTNAME = "Post Insp.";
export const TRACTOR = "Tractor";
export const ISSUES = "Issues";

// ITEM TYPES
export const SIDE = "Side";
export const BACK = "Back";
export const TASK = "task";
export const ISSUE = "issue";
export const INFO = "info";
export const SECTION = "PropertySection";
export const PROPERTY_PLAN_AREA = "PropertyPlanArea";
export const PROPERTY = "Property";
export const ITEM = "WalkItem";
export const CHECKLIST_ITEM = "checklistItem";
export const PUNCH = "Punch";
export const INSPECTIONPREP = "InspectionPrep";
export const RED_TAG = "Red Tag";
export const TOP_OUT = "Top Out";
export const TOPOFF = "TopOff";
export const TRIM = "Trim";
export const GAS = "Gas";
export const SPEC = "Spec";
export const MATERIAL = "Material";
export const PUNCHITEM = "PunchItem";
export const INTERNAL_PUNCHITEM = "Punch Item";
export const BACK_OFFICE = "Back Office";
export const WALK = "Walk";
export const JOB = "Job";
export const INSPECTION = "Inspection";
export const HOME = "Home";
export const SIGNATURE = "Signature";
export const MESSAGE = "Message";
export const MESSAGES = "Messages";
export const WALKS = "Walks";
export const JOBS = "Jobs";
export const DISPUTE = "Dispute";
export const PAY_ACTIVITIES = "Pay Activities";
export const START_JOB = "Start Job";
export const START_WALK = "Start Walk";
export const AREA_WALKS = "All Walks";

// TYPES EXTENSION
export const TYPE_INTERNAL = "Internal";
export const TYPE_EXTERNAL = "External";

//COLORS REMOVED ---

//FILTERS
export const COMMUNITY = "Community";
export const BUILDER = "Builder";
export const CITY = "City";
export const STAGE = "Stage";
export const TRADE = "Trade";

// WEATHER
export const API_WEATHER =
  "http://api.openweathermap.org/data/2.5/weather?lat=latitude&lon=longitude&units=imperial&appid=bd3d02f003d36d6a86f5d288d6d99283";
export const WEATHER_APPID = "bd3d02f003d36d6a86f5d288d6d99283";

// IMAGE OPTIONS
export const IMAGE_OPTIONS = {
  quality: 0.5,
  maxWidth: 1000,
  maxHeight: 1000,
  noData: true,
};

export const EDIT_TYPES = {
  RELEASE: "Pending",
  HOLD: "Hold",
  EMAIL: "Email",
  COMPLETE: "Complete",
};

export const LAT_LNG_DEFAULT = {
  latitude: "32.988038",
  longitude: "-96.923839",
};

export const MOBILE_APPS = {
  MIDIA: "MiDia",
  MITRADE: "MiTrade",
  MIPUNCH: "MiPunch",
};

export const REQUIRED_APP_VERSIONS = {
  [MOBILE_APPS.MIDIA]: "5.1.2",
  [MOBILE_APPS.MITRADE]: "5.1.2",
  [MOBILE_APPS.MIPUNCH]: "5.1.5",
};

//DAYS OF THE WEEK
export const DAYS_OF_THE_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// URL ENPOINTS
export const QA_URL = "";
export const PROD_URL = "";
export const DEV_URL = "";

export const rootImageUri = "https://mivprodblob.blob.core.windows.net/images/";
export const V2 = "/api/v2";
export const V3 = "/api/v3";

//USER ENDPOINTS

export const API_LOGIN = `${V3}/session`;
export const API_PASSWORD_RESET = `${V3}/users/email/initiatePasswordReset`;
export const API_PASSWORD_UPDATE = `${V3}/users/UpdatePassword`;
export const API_IMPERSONATE = "/api/v3/impersonate";

//WALKS
//OFFLINE CONSTANTS DATA NAME
export const OFFLINE_GET_WALKS = {
  name: "walks",
  api: `${V3}/users/userId/walks?statuses=Complete,Incomplete,Inprogress,Scheduled`,
};

export const OFFLINE_GET_CATEGORIES = {
  name: "categories",
  api: `${V3}/issues/categories?expand=true`,
};

export const OFFLINE_WALK_TYPES = "walksTypes";

export const API_GET_WALKS = `${V3}/users/userId/walks`;
export const API_UPDATE_WALK = `${V3}/walks/walkId`;
export const API_START_WALK = `${V3}/walks/walkId/start`;
export const API_COMPLETE_WALK = `${V3}/walks/walkId/complete`;
export const API_UPDATE_WALK_STATUS = `${V3}/walks/walkId/status`;
export const API_GETJOBS_BYCOMMUNITY = `${V3}/communities/jobs?startDate=startParam&endDate=endParam`;
export const API_UPDATE_PROGRESS = `${V3}/walks/walkId/progress`;

export const API_GETWALKS_BYCOMMUNITY = `${V3}/communities/walks?startDate=startParam&endDate=endParam`;

//GET WALK ITEM DETAILS ISSUES FOR EACH BY WALK
export const API_GET_WALK_EXPAND = `${V3}/walks/walkId?expand=Property,Items,Crews`;

//IMAGE ENDPOINTS
export const API_DELETE_SECTION_IMAGE = `${V3}/properties/propertyId/sections/sectionId/images/filename/fileNameParam`;
export const API_ADD_PROPERTY_SECTION_IMAGE = `${V3}/properties/propertyId/sections/sectionId/images`;
export const API_DELETE_WALKITEM_IMAGE = `${V3}/walks/walkId/items/itemId/attachments/imageId`;
export const API_ADD_WALKITEM_IMAGE = `${V3}/walks/walkId/items/itemId/attachments`;

export const API_UPLOAD_IMAGE = `${V3}/files`;
export const API_UPDATE_IMAGE_ANNOTATE = `${V3}/files/annotate`;
export const API_UPDATE_IMAGE = `${V3}/files/fileName?walkId=walkIdParam&propertyPlanAreaId=propertyPlanAreaIdParam`;

//ISSUE API FOR WALKS
export const API_UPDATE_ISSUE = `${V3}/issues/altId/altIdParam`;
export const API_UPDATE_ISSUE_PATCH = `${V3}/walks/walkId/issues`;
export const API_DELETE_ISSUE = `${V3}/walks/walkId/issues/altId/altIdParam`;
export const API_ADD_ISSUE = `${V3}/walks/walkId/issues`;
export const API_UPDATE_WALKITEMISSUE = `${V3}/walks/walkId/issues/issueId`;

export const API_UPDATE_TASKS = `${V3}/walks/walkId/items/itemId`;

//BONUS SCREENS
export const API_GET_USER_BONUSES =
  "/api/V3/bonuses?userId=userIdParam&period=periodParam";
export const API_DISPUTE_BONUS = "/api/V3/bonuses/bonusId/dispute";
export const API_MARK_BONUS_REVIEWED = "/api/V3/bonuses/bonusId/review";
export const API_GET_STAGE_BONUS_SUMMARY =
  "/api/V3/stages/stageId/bonus?userId=userIdParam";

//GOOGLE API_KEY
export const GOOGLE_API_KEY = "AIzaSyBbmnBQN9_tONY35MVvLRGeFS6GJQWgY0A";

//EXTENSIONS SCREEN LUIS
export const API_GET_EXTENSIONS_RESOLVED = `${V3}/extensions?numberOfDays=daysParam`;
export const API_UPDATED_EXTENSION = "/api/V3/extensions/extensionId";
export const API_GET_EMAIL_TEMPLATE = `${V3}/extensions/extensionId/EmailTemplate`;
export const API_UPDATE_PUNCHITEM_DECISION = `${V3}/extensions/punchItems/decision`;

export const API_EXTRAPAYREQUESTS = `${V3}/extrapayrequests`;
export const API_UPDATE_EXTRAPAYREQUEST = `${V3}/extrapayrequests/requestId`;
export const API_ADD_EXTRA_PAY_REQUEST = `${V3}/walks/walkId/extrapayrequests`;

export const API_ADD_TASK = `${V3}/walks`;

//SCHEDULING SCREEN JOB ENDPOINTS
export const API_CREWS = `${V3}/crews/smartSearch`;
export const API_UPDATE_JOBS = "/api/V3/stages/assign";
export const API_GET_COMMUNITY_CONTACTS =
  "/api/V3/communities/communityId/Contacts";
export const API_STAGES = "/stages";
export const API_ASSIGN = `${V3}${API_STAGES}/assign`;
export const API_CONFIRM_JOBS = `${V3}${API_STAGES}/confirm`;

//HOME AND DETAILS
export const API_HOMES = `${V3}/homes`;
export const API_PROPERTIES = `${V3}/properties`;
export const API_EXTENSIONS = `${V3}/extensions`;
export const API_DOCUMENTS = `/drawings`;
export const API_WALKS = `/walks`;
export const API_ISSUES = `/punchItems`;
export const API_STAGE_WORK = `/stageworkhistory`;
export const API_JOBS = `/jobs`;
export const API_COMMUNITIES = `${V3}/communities`;
export const API_CONTACTS = "/Contacts";

//MESSAGINGS
export const API_GET_MESSAGES_PROPERTY = `${V3}/properties/propertyId/messages`;
export const API_ADD_MESSAGES = `${V3}/messages`;
export const API_READ_MESSAGES = `${V3}/messages/read`;
export const API_GET_CONVERSATIONS = `${V3}/conversations`;

//NOTIFICATIONS
export const API_GET_RECENT_NOTIFICATIONS = `${V3}/notifications/recent/amount`;
export const API_MARK_NOTIFICATIONS_AS_READ = `${V3}/notifications/recent`;

//TASKS
export const API_GET_ASSIGNEE_TYPES_BYWALK = `${V3}/walks/assigneeTypes`;
export const API_SYSTEM_TYPES = `${V3}/systemTypes`;
export const API_WALK_TYPES_BY_RECORD = `${V3}${API_WALKS}/types/recordId`;

//CONTACTS
export const API_GET_PROPERTY_CONTACTS = `${V3}/properties/propertyId/contacts`;

export const NOTIFICATION_TYPES = {
  SUCCESS: "success",
  WARNING: "warning",
  WARNING_YELLOW: "warningYellow",
  LINK: "link",
  ERROR: "error",
  INFO: "info",
  DEFAULT: "default",
};

export const NOTIFICATION_COMMANDS = {
  ALERT: "alert:",
  CONSOLE_LOG: "console:",
  RELOAD_MESSAGES: "reloadMessages",
  RELOAD_NOTIFICATIONS: "reloadNotifications",
  STALE_DATA: "StaleData",
};

export const NOTIFICATION_SUBSCRIPTIONS = [
  { value: "NotStarted", label: "Unable to Start Job" },
  { value: "InProgress", label: "Incomplete Job" },
  { value: "ReadyForInspection", label: "Ready for Inspection" },
];

export const TOAST_TYPES = {
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "success",
  INFO: "info",
  LINK: "link",
};

export const SYSTEM_TYPE_CATEGORIES = {
  GARAGE_SWING: "GarageSwingType",
  DRAWING_TYPE: "DrawingType",
  GARAGE_TYPE: "GarageType",
  GARAGE_SIZE_TYPE: "GarageSizeType",
  DRAWING_CATEGORY_TYPE: "DrawingCategoryType",
  SPECIALIZATION: "Specialization",
  PEX_BRAND: "PEXBrand",
  POWER_TYPE: "PowerType",
};

export const DATA_STATUSES = {
  LOADING: "Loading",
  LOADING_MORE: "Loading More",
  NO_RESULTS: "No Results",
  ERROR: "Error",
  PROCESSING: "Processing",
  DONE: "",
};

export const UI_TAB_LABELS = {
  DETAILS: "DETAILS",
  ITEMS: "ITEMS",
  WALK_FAILURE_REASONS: "Walk Failure Reasons",
  ITEM_FAILURE_REASONS: "Item Failure Reasons",
  WALK_TYPE_PREDECESSORS: "WALKTYPEPREDECESSORS",
  PAY_CALCULATION: "PAYCALCULATION",
  STAGE_SUMMARY_OPTIONS: "STAGESUMMARYOPTIONS",
};

export const ACCOUNT_TYPES = {
  BUILDER: "Builder",
  SUPPLIER: "Supplier",
};

export const TRADE_TYPES = {
  PLUMBING: "Plumbing",
  CONCRETE: "Concrete",
  ELECTRICAL: "Electrical",
  FRAMING: "Framing",
  COUNTERTOPS: "Countertops",
  CABINETRY: "Cabinetry",
  HVAC: "HVAC",
  BUILDER: "Builder",
  PAINTING: "Painting",
};

export const STATUS_TYPES = {
  PLANS: "Plan",
};

export const MESSAGING = "Messaging";

export const WALK_STATUSES_LIST = {
  UNASSIGNED: UNASSIGNED,
  SCHEDULED: SCHEDULED,
  INPROGRESS: IN_PROGRESS,
  INCOMPLETE: INCOMPLETE,
  NOT_STARTED: NOT_STARTED,
  COMPLETE: COMPLETE,
  CANCELLED: CANCELLED,
  HOLD: HOLD,
  PENDING: PENDING,
  VERIFIED: VERIFIED,
};

//INSPECTIONS
const PASSED = "Passed";
const FAILED = "Failed";
const RESCHEDULED = "Rescheduled";
const NOTSCHEDULED = "Not Scheduled";

export const DOCUMENT_CATEGORY_NAME_MAPPING = {
  Rough: "Rough In",
  "Top Out": "Top Out",
  Trim: "Trim",
};

export const WALK_STATUS_INSPECTION_LABELS = {
  [UNASSIGNED]: NOTSCHEDULED,
  [SCHEDULED]: SCHEDULED,
  [IN_PROGRESS]: IN_PROGRESS_LABEL,
  [INCOMPLETE]: FAILED,
  [NOT_STARTED]: RESCHEDULED,
  [COMPLETE]: PASSED,
  [CANCELLED]: CANCELLED,
  [HOLD]: HOLD,
  [PENDING]: PENDING,
};

export const WALK_STATUS_OPTIONS_KEYED = {
  [PENDING]: {
    id: 32,
    text: PENDING,
    value: PENDING,
    displayOrder: -1,
    color: MiTHEME.palette.primary.grey,
  },
  [UNASSIGNED]: {
    id: 12,
    text: UNASSIGNED,
    value: UNASSIGNED,
    displayOrder: -1,
    color: MiTHEME.palette.primary.grey,
  },
  [SCHEDULED]: {
    id: 7,
    value: SCHEDULED,
    text: SCHEDULED,
    displayOrder: 0,
    color: MiTHEME.palette.primary.blue,
  },
  [NOT_STARTED]: {
    id: 72,
    value: NOT_STARTED,
    text: NOT_STARTED_LABEL,
    displayOrder: 0,
    color: MiTHEME.palette.secondary.purple,
  },
  [IN_PROGRESS]: {
    value: IN_PROGRESS,
    text: IN_PROGRESS_LABEL,
    id: 29,
    displayOrder: 1,
    color: MiTHEME.palette.primary.orange,
  },
  [HOLD]: {
    value: HOLD,
    text: HOLD,
    displayOrder: -1,
    disabled: true,
    color: MiTHEME.palette.secondary.purple,
  },
  [COMPLETE]: {
    text: COMPLETE,
    value: COMPLETE,
    id: 11,
    color: MiTHEME.palette.primary.green,
    displayOrder: 2,
  },
  [INCOMPLETE]: {
    id: 9,
    value: INCOMPLETE,
    text: INCOMPLETE,
    displayOrder: -1,
    color: MiTHEME.palette.secondary.red,
  },
  [CANCELLED]: {
    id: 30,
    value: CANCELLED,
    text: CANCELLED,
    displayOrder: -1,
    disabled: true,
    color: MiTHEME.palette.secondary.grey,
  },
};

export const INSPECTIONPREP_STATUS_COLORS = {
  [UNASSIGNED]: {
    color: MiTHEME.palette.primary.grey,
    backgroundColor: MiTHEME.palette.lightShade.grey,
  },
  [SCHEDULED]: {
    color: MiTHEME.palette.primary.blue,
    backgroundColor: MiTHEME.palette.light.blue,
  },
  [IN_PROGRESS]: {
    color: MiTHEME.palette.primary.orange,
    backgroundColor: MiTHEME.palette.light.orange,
  },
  [INCOMPLETE]: {
    color: MiTHEME.palette.secondary.red,
    backgroundColor: MiTHEME.palette.light.red,
  },
  [NOT_STARTED]: {
    color: MiTHEME.palette.secondary.bluegrey,
    backgroundColor: MiTHEME.palette.lightShade.bluegrey,
  },
  [HOLD]: {
    color: MiTHEME.palette.secondary.purple,
    backgroundColor: MiTHEME.palette.light.purple,
  },
  [PENDING]: {
    color: MiTHEME.palette.primary.grey,
    backgroundColor: MiTHEME.palette.lightShade.grey,
  },
  [VERIFIED]: {
    color: MiTHEME.palette.primary.grey,
    backgroundColor: MiTHEME.palette.lightShade.grey,
  },
  [COMPLETE]: {
    color: MiTHEME.palette.primary.green,
    backgroundColor: MiTHEME.palette.light.green,
  },
  [CANCELLED]: {
    color: MiTHEME.palette.secondary.red,
    backgroundColor: MiTHEME.palette.lightShade.grey,
  },
};

export const STAGE_STATUSES = {
  NOT_SCHEDULED: 1,
  SCHEDULED: 2,
  IN_PROGRESS: 3,
  STAGE_COMPLETE: 4,
  READY: 5,
  PARTIALLY_READY: 6,
  NOT_READY: 22,
  CANCELLED: 52,
  READY_FOR_INSPECTION: 73,
};

export const STAGE_STATUS_OPTIONS = [
  { text: "NotScheduled", value: 1 },
  { text: "Scheduled", value: 2 },
  { text: "InProgress", value: 3 },
  { text: "StageComplete", value: 4 },
  { text: "Ready", value: 5 },
  { text: "PartiallyReady", value: 6 },
  { text: "NotReady", value: 22 },
  { text: "Cancelled", value: 52 },
  { text: "ReadyForInspection", value: 73 },
];

export const WALK_STATUS_OPTIONS = [
  { text: "Scheduled", value: 7 },
  { text: "Incomplete", value: 9 },
  { text: "Complete", value: 11 },
  { text: "Unassigned", value: 12 },
  { text: "Inprogress", value: 29 },
  { text: "Cancelled", value: 30 },
  { text: "Pending", value: 32 },
  { text: "Hold", value: 51 },
  { text: "Not Started", value: 72 },
];

export const WALK_RECORD_TYPES_OPTIONS = [
  { text: "Walk", value: 170 },
  { text: "Job", value: 171 },
  { text: "Punch", value: 176 },
  { text: "Task", value: 203 },
  { text: "Inspection", value: 282 },
  { text: "Inspection Prep", value: 285 },
  { text: "Top Off", value: 286 },
  { text: "Post Inspection", value: 287 },
];

export const OPEN_WALK_STATUSES = {
  SCHEDULED: 7,
  IN_PROGRESS: 29,
};

export const WALK_STATUSES = {
  SCHEDULED: 7,
  IN_PROGRESS: 29,
  HOLD: 51,
  COMPLETE: 11,
  INCOMPLETE: 9,
  NOT_STARTED: 72,
};

export const WALK_STATUS_IDS = {
  SCHEDULED: 7,
  IN_PROGRESS: 29,
  HOLD: 51,
  COMPLETE: 11,
  INCOMPLETE: 9,
  NOT_STARTED: 72,
  UNASSIGNED: 12,
  CANCELLED: 14,
};

export const WALK_STATUSES_FINAL = [
  WALK_STATUS_OPTIONS_KEYED[COMPLETE].value,
  WALK_STATUS_OPTIONS_KEYED[INCOMPLETE].value,
  WALK_STATUS_OPTIONS_KEYED[NOT_STARTED].value,
  WALK_STATUS_OPTIONS_KEYED[CANCELLED].value,
];

export const HTTP_STATUSES = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  NOT_MODIFIED: 304,
  BAD_REQUEST: 400,
  SERVER_ERROR: 500,
  UNAUTHORIZED: 403,
};

export const ISSUE_CATEGORY_IDS = {
  BUILDER: 2,
  CREW: 6,
  INSPECTION: 9,
  UNKNOWN: 10,
};

export const ISSUE_CATEGORY_IDS_TO_NAME = {
  2: BUILDER,
  6: CREW,
  9: INSPECTION,
};

export const ISSUE_CATEGORIES = [
  { label: "Cathedral", value: 6, typeId: 280 },
  { label: "Other", value: 2, typeId: 254 },
  { label: "Unknown", value: 10, typeId: 283 },
];

export const ISSUE_CATEGORIES_MOBILE = [
  { label: "Cathedral", value: 6, typeId: 280 },
  { label: "Builder", value: 2, typeId: 254 },
];

export const RED_TAG_CATEGORIES = [
  {
    label: INSPECTION,
    value: ISSUE_CATEGORY_IDS.INSPECTION,
  },
];

export const PERMISSIONS = {
  CAN_RESET_WALK: "CanResetWalk",
  CAN_DELETE_MANUFACTURER: "CanDeleteManufacturers",
  CAN_CREATE_MANUFACTURER: "CanCreateManufacturers",
  CAN_CREATE_USER: "CanCreateUsers",
  CAN_CREATE_ROLE: "CanCreateRoles",
  CAN_CREATE_CITY: "CanCreateCities",
  CAN_VIEW_OWN_PAY: "CanViewOwnPay",
  CAN_VIEW_WORK_PAY_AMOUNT: "CanViewWorkPayAmount",
  CAN_MANAGE_WORK_PAY_AMOUNT: "CanManageWorkPayAmount",
  CAN_ACCESS_MIPURCHASING: "CanAccessMiPurchasing",
  CAN_VIEW_ACCOUNTING: "CanViewAccounting",
  CAN_VIEW_ADMIN_SETTINGS: "CanViewAdminSettings",
  CAN_ACCESS_MISCHEDULING: "CanAccessMiScheduling",
  CAN_MANAGE_ACCOUNTS: "CanManageAccounts",
  CAN_MANAGE_PAY_SCHEDULES: "CanManagePaySchedules",
  CAN_VIEW_COMMUNITIES: "CanViewCommunities",
  CAN_VIEW_PROPERTIES: "CanViewProperties",
  CAN_CREATE_COMMUNITIES: "CanCreateCommunities",
  CAN_VIEW_MATERIALS: "CanViewMaterials",
  CAN_CREATE_BUILDERS: "CanCreateBuilders",
  CAN_VIEW_BUILDERS: "CanViewBuilders",
  CAN_VIEW_PLANS: "CanViewPlan",
  CAN_VIEW_ALL_WORK: "CanViewAllWork",
  CAN_VIEW_ALL_WALKS: "CanViewAllWalks",
  CAN_VIEW_AUDIT_HISTORY: "CanViewAuditHistory",
  CAN_MANAGE_HOLIDAYS: "CanManageHolidays",
  CAN_VIEW_MANAGE_USERS: "CanViewManageUsers",
  CAN_LOGIN_MITRADE_WEB: "CanLoginMiTradeWeb",
  CAN_VIEW_USERS: "CanViewUsers",
  CAN_VIEW_MANAGE_ROLES: "CanViewManageRoles",
  CAN_VIEW_ROLES: "CanViewRoles",
  CAN_VIEW_CITIES: "CanViewCities",
  CAN_MANAGE_TITLES: "CanManageTitles",
  CAN_CREATE_LIST_TEMPLATES: "CanCreateListTemplates",
  CAN_VIEW_MANUFACTURERS: "CanViewManufacturers",
  CAN_MANAGE_PAY_CONFIGURATION: "CanManagePayConfiguration",
  CAN_VIEW_INSPECTIONS: "CanViewInspections",
  CAN_VIEW_INSPECTIONPREPS: "CanViewInspectionPreps",
  CAN_VIEW_WORKSCHEDULETAB_MYWORK: "CanViewScheduleTabMyWork",
  CAN_VIEW_WORKSCHEDULETAB_WALKS: "CanViewScheduleTabWalks",
  CAN_VIEW_WORKSCHEDULETAB_STAGES: "CanViewScheduleTabStages",
  CAN_VIEW_WORKSCHEDULETAB_TRACTOR: "CanViewScheduleTabTractor",
};

export const WALK_RECORD_TYPES = {
  WALK: 170,
  JOB: 171,
  PUNCH: 176,
  TASK: 203,
  INSPECTION: 282,
  INSPECTIONPREP: 285,
  TOPOFF: 286,
  POSTINSPECTION: 287,
};

export const FILE_TYPES = {
  PDF: "application/pdf",
  PNG: "image/png",
  XLS: "application/vnd.ms-excel",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const DRAWER_WIDTH = 235;
export const ADDON_DRAWER_WIDTH = 276;

export const APP_BAR_HEIGHT = 60;

export const ISSUE_STATUSES = {
  PENDING: 2,
  WORK_COMPLETED: 10,
  REOPENED: 13,
  NOT_READY: 15,
  NOT_FINALIZED: 16,
  WORK_VERIFIED: 12,
  CANCELLED: 14,
};

export const ISSUE_STATUSES_COLORS = {
  [PENDING]: {
    color: MiTHEME.palette.primary.blue,
    icon: ICONS_NATIVE_STATUS.PENDING,
  },
  [WORK_COMPLETED]: {
    color: MiTHEME.palette.primary.teal,
    icon: ICONS_NATIVE_STATUS.COMPLETE,
  },
  [WORK_VERIFIED]: {
    color: MiTHEME.palette.primary.greem,
    icon: ICONS_NATIVE_STATUS.COMPLETE,
  },
  [undefined]: {
    color: MiTHEME.palette.primary.grey,
    icon: ICONS_NATIVE_STATUS.PENDING,
  },
};

export const ISSUE_CLASSIFICATIONS_IDS = {
  PUNCH: 288,
  REDTAG: 289,
};

export const ICON_TYPE = {
  MATERIAL_COMMUNITY: "materialCommunity",
  MATERIAL: "material",
};

export const WALK_STATUSES_OPEN = [
  OPEN_WALK_STATUSES.SCHEDULED,
  OPEN_WALK_STATUSES.IN_PROGRESS,
];

export const ISSUE_STATUSES_CLOSED = [
  ISSUE_STATUSES.WORK_VERIFIED,
  ISSUE_STATUSES.CANCELLED,
  ISSUE_STATUSES.NOT_FINALIZED,
];

export const ISSUE_STATUSES_OPEN = [
  ISSUE_STATUSES.PENDING,
  ISSUE_STATUSES.REOPENED,
  ISSUE_STATUSES.WORK_COMPLETED,
];

export const STAGEWALKITEM_STATUSES = {
  PENDING: PENDING,
  COMPLETE: COMPLETE,
  HOLD: HOLD,
  INCOMPLETE: INCOMPLETE,
  ABORTED: ABORTED,
  VERIFIED: VERIFIED,
  NOTREADY: NOTREADY,
  NA: NA,
};

export const STAGEWALKITEMS_ICONS = {
  [STAGEWALKITEM_STATUSES.COMPLETE]: {
    color: THEME.GREEN_PRIMARY,
    icon: ICONS_NATIVE_STATUS.COMPLETE,
    iconWeb: ICONS_WEB_STATUS.COMPLETE,
  },
  [STAGEWALKITEM_STATUSES.INCOMPLETE]: {
    color: THEME.RED_PRIMARY,
    icon: ICONS_NATIVE_STATUS.INCOMPLETE,
    iconWeb: ICONS_WEB_STATUS.INCOMPLETE,
  },
  [STAGEWALKITEM_STATUSES.HOLD]: {
    color: THEME.PURPLE_PRIMARY,
    icon: ICONS_NATIVE_STATUS.HOLD,
    iconWeb: ICONS_WEB_STATUS.HOLD,
  },
  [STAGEWALKITEM_STATUSES.PENDING]: {
    color: THEME.GREEN_PRIMARY,
    icon: ICONS_NATIVE_STATUS.COMPLETE,
    iconWeb: ICONS_WEB_STATUS.COMPLETE,
  },
  [STAGEWALKITEM_STATUSES.ABORTED]: {
    color: THEME.GREEN_PRIMARY,
    icon: ICONS_NATIVE_STATUS.COMPLETE,
    iconWeb: ICONS_WEB_STATUS.COMPLETE,
  },
  [STAGEWALKITEM_STATUSES.NA]: {
    color: THEME.GREY_MEDIUM,
    icon: ICONS_NATIVE_STATUS.NA,
    iconWeb: ICONS_WEB_STATUS.NA,
  },
};

export const STAGEWALKITEM_STATUSES_OPEN = [PENDING, ABORTED];
export const STAGEWALKITEM_STATUSES_UNCHECK = [INCOMPLETE, COMPLETE, NA, HOLD];

export const MISC_CONSTANTS = {
  NO_OPTIONS: "No Options",
  NO_DATA: "No Data",
};

export const ROLES = {
  EXECUTIVE: "Executive",
};

export const onStatus = [
  { label: "Create", value: "Create" },
  { label: "Complete", value: "Complete" },
  { label: "Verified", value: "Verified" },
  { label: "On Demand", value: "OnDemand" },
];

export const condition = [
  { label: "Late", value: "Late" },
  { label: "On Time", value: "OnTime" },
];

export const includesIssueType = [
  { label: "Punch", value: 253 },
  { label: "External", value: 254 },
  { label: "Internal", value: 280 },
];

export const statusChangeOnStageStatus = [
  { label: "Created", value: 1 },
  { label: "Scheduled", value: 2 },
  { label: "Completed", value: 4 },
];

export const InitialStatus = [
  { label: "Potential", value: 60 },
  { label: "Pending", value: 57 },
  { label: "Payable", value: 59 },
];
export const BACKCHARGES = "Back Charges";
export const DISPUTES = "Disputes";

export const DEFAULT_TIMEZONE = "America/Chicago";

export const MUI_DATAGRID_KEY =
  "6103ea17901dffe0e71dee2a157bc785Tz01MjYxNixFPTE2OTc2Mzg1MzcwODEsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";

export const PAY_ACTIVITY_TYPES = {
  1: { type: "PunchBonus", name: "Punch Bonus" },
  2: { type: "WalkBonus", name: "Walk Bonus" },
  3: { type: "JobBonus", name: "Job Bonus" },
  4: { type: "JobPay", name: "Job Pay" },
  5: { type: "DeferredPay", name: "Deferred Pay" },
  6: { type: "StageBonus", name: "Stage Bonus" },
};

export const ACCOUNTINGTRANSACTIONSTATUSES = {
  PENDING: "Pending", //63
  WITHHELD: "Withheld", //64
  PROCESSING: "Processing", //65
  PAYABLE: "Payable", //70
  PAID: "Paid", //66
  CANCELLED: "Cancelled", //71
};

export const PAY_SETTINGS_TABS = {
  RULES: "Rules",
  PAY_ACTIVITY_TYPES: "Pay Activity Types",
  EXPENSE_TYPES: "Expense Types",
};

export const FAILURE_REASONS_TABS = {
  WALK_FAILURES: "Walks",
  ITEM_FAILURES: "Items",
};

export const LEDGER_ACCOUNTS = {
  ACCOUNTS_PAYABLE: "Accounts Payable",
  WAGES_PAYABLE: "Wages Payable",
  OTHER: "Other",
};

export const EXPENSE_TYPE_CATEGORIES = {
  PAY: "Pay",
  BONUS: "Bonus",
};

export const MANAGE_USERS_ACTIVE_STATUS = [
  { label: "Active", id: 1, value: true },
  { label: "Inactive", id: 2, value: false },
];

export const ACCOUNT_PAYABLE_EXPORTED_STATUS = [
  { label: "Exported", id: 1, value: "Exported" },
  { label: "Not Exported", id: 2, value: "Not Exported" },
];

export const EXPORT_CHIP_OPTIONS = {
  EXPORTED: "Exported",
  NOT_EXPORTED: "Not Exported",
};

export const ACCOUNTPAYABLE_CHIP_ITEMS_OPENBILLSTAB = [
  { label: "Processing", id: 1, value: "Processing" },
  { label: "Payable", id: 2, value: "Payable" },
  { label: "Pending", id: 3, value: "Pending" },
  { label: "Withheld", id: 4, value: "Withheld" },
];

export const ACCOUNTPAYABLE_CHIP_ITEMS_HISTORYTAB = [
  { label: "Processing", id: 1, value: "Processing" },
  { label: "Payable", id: 2, value: "Payable" },
  { label: "Pending", id: 3, value: "Pending" },
  { label: "Paid", id: 4, value: "Paid" },
  { label: "Cancelled", id: 5, value: "Cancelled" },
  { label: "Withheld", id: 6, value: "Withheld" },
];

export const ACCOUNTPAYABLE_CHIP_STATUS_OPENBILLSTAB = [
  "Payable",
  "Pending",
  "Processing",
  "Withheld",
];
export const ACCOUNTPAYABLE_CHIP_STATUS_HISTORYTAB = [
  "Payable",
  "Pending",
  "Processing",
  "Withheld",
  "Paid",
  "Cancelled",
];
export const ACCOUNTPAYABLE_ACTIVETAB_VALUE = {
  OPENBILLS: "openbills",
  HISTORY: "history",
};

export const ACCOUNTPAYABLE_TAB_DATA = [
  { label: "Open Bills", value: "openbills" },
  { label: "History", value: "history" },
];

export const ACCOUNTPAYABLE_BUTTONGROUP_VALUES = {
  JOB_PAY: "Job Pay",
  BONUS_PAY: "Bonus Pay",
};
export const ACCOUNTPAYABLE_MENU_ITEMS = [
  { value: "Paid" },
  { value: "Processing" },
  { value: "Pending" },
  { value: "Withheld" },
  { value: "Payable" },
  { value: "Cancelled" },
];

export const ACCOUNT_PAYABLE_EXPORT_BUTTON_GROUP_VALUES = {
  EXPORTED: "Exported",
  NOT_EXPORTED: "Not Exported",
};

export const INSPECTIONPREP_STATUS_CHIPS = [
  { label: "Unassigned", id: 1, value: ["Unassigned"] },
  {
    label: "Open",
    id: 2,
    value: ["Scheduled", "Inprogress", "Incomplete", "NotStarted"],
  },
  { label: "Complete", id: 3, value: ["Complete"] },
];

export const HOME_ICON_INDEX = {
  phone: 3,
  tablet: 5,
};

export const INTEGRATIONS = {
  DRHORTON: "drhorton",
};

export const MAPPING_TYPES = {
  DIVISIONS: 1,
  COMMUNITIES: 2,
  PLANS: 3,
  STATES: 4,
  CITIES: 5,
  COUNTIES: 6,
  GARAGESWINGS: 7,
  STAGES: 8,
};

export const STATE = "State";

export const MAPPING_TYPE_ENTITIES = {
  1: "Division",
  2: "Community",
  3: "Plan",
  4: "State",
  5: "City",
  6: "County",
  7: "GarageSwing",
  8: "Stage",
};

export const ICON_STATUSES = {
  [IN_PROGRESS]: {
    color: MiTHEME.colors.orange.primary,
    icon: ICONS_NATIVE_STATUS.INPROGRESS,
  },
  [COMPLETE]: {
    color: MiTHEME.colors.green.primary,
    icon: ICONS_NATIVE_STATUS.COMPLETE,
  },
  [INCOMPLETE]: {
    color: MiTHEME.colors.pink.primary,
    icon: ICONS_NATIVE_STATUS.APPROVED,
  },
  [NOT_STARTED]: {
    color: MiTHEME.colors.purple.primary,
    icon: ICONS_NATIVE_STATUS.SCHEDULED,
  },
  [HOLD]: {
    color: MiTHEME.colors.purple.secondary,
    icon: ICONS_NATIVE_STATUS.HOLD,
  },
  [UNASSIGNED]: {
    color: MiTHEME.colors.grey.primary,
    icon: ICONS_NATIVE_STATUS.PENDING,
  },
  [CANCELLED]: {
    color: MiTHEME.colors.red.primary,
    icon: ICONS_NATIVE_STATUS.DECLINED,
  },
  [SCHEDULED]: {
    color: MiTHEME.colors.blue.primary,
    icon: ICONS_NATIVE_STATUS.SCHEDULED,
  },
};

export const ROW_ACTIONS = {
  openInNewTab: "newTab",
  active: "active",
  inActive: "inActive",
  archive: "archive",
  edit: "edit",
  delete: "delete",
  active2: "active2",
  inActive2: "inActive2",
};

export const CHECKLIST_SECTION_STATUSES = {
  [INCOMPLETE]: { color: MiTHEME.colors.red.primary, icon: "close" },
  [COMPLETE]: { color: MiTHEME.colors.green.primary, icon: "check" },
  [PENDING]: { color: MiTHEME.colors.grey.primary, icon: "" },
  [HOLD]: { color: MiTHEME.colors.purple.primary, icon: "hand-back-right" },
  [ABORTED]: { color: MiTHEME.colors.purple.secondary, icon: "alert" },
};

export const WALK_STATUS_COLORS = {
  [IN_PROGRESS]: {
    color: MiTHEME.colors.orange.primary,
    icon: "clock-outline",
  },
  [COMPLETE]: {
    color: MiTHEME.colors.green.primary,
    icon: "check",
  },
  [INCOMPLETE]: {
    color: MiTHEME.colors.pink.light,
    icon: "check",
  },
  [NOT_STARTED]: {
    color: MiTHEME.colors.purple.primary,
    icon: "close-circle-outline",
  },
  [SCHEDULED]: {
    color: MiTHEME.colors.blue.primary,
    icon: "clock-outline",
  },
  [HOLD]: {
    color: MiTHEME.colors.purple.secondary,
    icon: "hand-back-right",
  },
  [UNASSIGNED]: {
    color: MiTHEME.colors.grey.primary,
    icon: "clock-outline",
  },
  [CANCELLED]: {
    color: MiTHEME.palette.primary.grey,
    icon: "cancel",
  },
  [PENDING]: {
    color: MiTHEME.colors.purple.primary,
    icon: "",
  },
};

export const INSPECTION_STATUS_COLORS = {
  ...WALK_STATUS_COLORS,
  [INCOMPLETE]: {
    color: MiTHEME.palette.primary.red,
    icon: "close",
  },
};

export const START_STATUSES = [HOLD, SCHEDULED];
export const LATE_STATUSES = [SCHEDULED, NOT_STARTED];
export const RUN_STATUSES = {
  SUCCEEDED: "Succeeded",
  FAILED: "Failed",
  RUNNING: "Running",
};

export const IMPORT_WORK_KEYS = {
  HOMES: "homes",
  JOBS: "jobs",
};

export const FILTER_LABELS = {
  SUPPLIER: "Supplier",
  DELIVERY: "Delivery Location",
};

export const TAX_RATE = 0.08;

export const ORDER_STATUS = {
  BACKORDER: "Backorder",
  CANCEL: "Cancel",
  RECEIVED: "Received",
  ISSUED: "Issued",
  PAID: "Paid",
};

export const PROPERTY_STATUSES = {
  NOTSCHEDULED: "Not Scheduled",
  CANCELLED: "Cancelled",
  IN_PROGRESS: "In Progress",
  NOTREADY: "Not Ready",
  PARTIALLY_READY: "Partially Ready",
  READY: "Ready",
  SCHEDULED: "Scheduled",
  READY_FOR_INSPECTION: "Ready for Inspection",
  STAGECOMPLETE: "Stage Complete",
};

export const PROPERTY_STATUSES_OPTIONS_KEYED = {
  [NOTSCHEDULED]: {
    value: STAGE_STATUSES.NOT_SCHEDULED,
    text: NOTSCHEDULED,
    displayOrder: -1,
  },
  [CANCELLED]: {
    value: STAGE_STATUSES.CANCELLED,
    text: CANCELLED,
    displayOrder: -1,
  },
  [IN_PROGRESS]: {
    value: STAGE_STATUSES.IN_PROGRESS,
    text: IN_PROGRESS_LABEL,
    displayOrder: -1,
  },
  [NOTREADY]: {
    value: STAGE_STATUSES.NOT_READY,
    text: NOT_READY,
    displayOrder: 0,
  },
  [PARTIALLY_READY]: {
    value: STAGE_STATUSES.PARTIALLY_READY,
    text: PARTIALLY_READY,
    displayOrder: 1,
  },
  [READY]: {
    value: STAGE_STATUSES.READY,
    text: READY,
    displayOrder: 2,
  },
  [SCHEDULED]: {
    value: STAGE_STATUSES.SCHEDULED,
    text: SCHEDULED,
    displayOrder: 3,
  },
  [READY_FOR_INSPECTION]: {
    value: STAGE_STATUSES.READY_FOR_INSPECTION,
    text: READY_FOR_INSPECTION,
    displayOrder: 3,
  },
  [STAGECOMPLETE]: {
    value: STAGE_STATUSES.STAGE_COMPLETE,
    text: STAGECOMPLETE,
    displayOrder: 4,
  },
};

export const STAGE_STATUS_COLORS = {
  [PROPERTY_STATUSES.NOTSCHEDULED]: {
    color: MiTHEME.colors.grey.primary,
  },
  [PROPERTY_STATUSES.IN_PROGRESS]: {
    color: MiTHEME.colors.orange.primary,
    icon: "clock-outline",
  },
  [PROPERTY_STATUSES.CANCELLED]: {
    color: MiTHEME.colors.red.primary,
    icon: "",
  },
  [PROPERTY_STATUSES.NOTREADY]: {
    color: MiTHEME.colors.black.primary,
  },
  [PROPERTY_STATUSES.PARTIALLY_READY]: {
    color: MiTHEME.colors.bluegrey.primary,
  },
  [PROPERTY_STATUSES.READY]: {
    color: MiTHEME.colors.yellow.primary,
  },
  [PROPERTY_STATUSES.SCHEDULED]: {
    color: MiTHEME.colors.blue.primary,
    icon: "clock-outline",
  },
  [PROPERTY_STATUSES.READY_FOR_INSPECTION]: {
    color: MiTHEME.colors.orange.primary,
  },
  [PROPERTY_STATUSES.STAGECOMPLETE]: {
    color: MiTHEME.colors.green.primary,
  },
};

export const PROPERTY_STATUSES_INTERVALS = [
  PROPERTY_STATUSES.IN_PROGRESS,
  PROPERTY_STATUSES.SCHEDULED,
  PROPERTY_STATUSES.READY_FOR_INSPECTION,
  PROPERTY_STATUSES.STAGECOMPLETE,
];

export const STAGE_TYPES = {
  ROUGH: 1,
  TOP_OUT: 2,
  TRIM: 3,
  GAS: 4,
  BOX_AND_WRAP: 5,
};

export const CHECK_INVENTORY_PAGE_ROW_COUNT = 20;

export const BONUS_FILTERS = [PENDING, APPROVED, DECLINED];

export const GridOperators = {
  AND: "and",
  OR: "or",
};

export const VALID_FILE_TYPES_FOR_DOCUMENT_UPLOAD = [
  "image/png",
  "image/bmp",
  "image/jpeg",
  "application/vnd.rar",
  "application/zip",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
  "text/csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/gif",
  "text/html",
  "image/vnd.microsoft.icon",
  "audio/mpeg",
  "video/mpeg",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/plain",
];

export const VALID_FILE_TYPES_FOR_IMAGES_UPLOAD = [
  "image/png",
  "image/bmp",
  "image/jpeg",
  "image/gif",
];

export const DEFAULT_IMAGE_FILE_TYPE = "image/png";

export const PLANS_MODAL_INVALID_FILE_TYPE_ERROR =
  "Invalid File Type. Only .xls and .xlsx file types supported.";

export const TABLE_STYLES = {
  INACTIVE: "inActiveRow",
};

export const ACCOUNT_STATUS = {
  active: "Active",
  inactive: "Inactive",
  archived: "Archived",
  incomplete: "Incomplete",
};

export const ACCOUNT_STATUS_COLOR = {
  [ACCOUNT_STATUS.active]: MiTHEME.colors.blue.primary,
  [ACCOUNT_STATUS.inactive]: MiTHEME.colors.grey.primary,
  [ACCOUNT_STATUS.incomplete]: MiTHEME.colors.grey.primary,
  [ACCOUNT_STATUS.archived]: MiTHEME.colors.grey.primary,
};

export const QUOTE_STATUS = {
  DRAFT: "Draft",
  REQUESTED: "Requested",
  ACCEPTED: "Accepted",
  CANCELLED: "Cancelled",
};

export const QUOTE_ATTACHMENT_DISCRIMINATOR = {
  QUOTE_ATTACHMENT: "quoteAttachment",
};

export const REQUISITION_ORDER_TYPE = {
  ORDER: "Order",
  TEMPLATE: "Template",
};

export const GEOFENCE_DEFAULT = 5000;

export const STATUS_COLORS = {
  Open: MiTHEME.colors.purple.primary,
  Paid: MiTHEME.colors.green.primary,
  Processing: MiTHEME.colors.orange.primary,
  Pending: MiTHEME.colors.purple.primary,
  Payable: MiTHEME.colors.blue.primary,
  Cancelled: MiTHEME.colors.grey.primary,
  Withheld: MiTHEME.colors.blue.primary,
};

export const PAY_ACTIVITY_STATUSES = {
  PENDING: 57,
  CANCELLED: 58,
  PAYABLE: 59,
  POTENTIAL: 60,
  PAID: 61,
};
export const PAY_ACTIVITY_STATUS_ID = {
  57: "PENDING",
  58: "CANCELLED",
  59: "PAYABLE",
  60: "POTENTIAL",
  61: "PAID",
};

export const PAY_ACTIVITY_STATUS_SPECS = {
  [PAY_ACTIVITY_STATUSES.PENDING]: {
    color: MiTHEME.colors.purple.primary,
    icon: ICONS_NATIVE_STATUS.INPROGRESS,
  },
  [PAY_ACTIVITY_STATUSES.CANCELLED]: {
    color: MiTHEME.colors.grey.primary,
    icon: ICONS_NATIVE_STATUS.INPROGRESS,
  },
  [PAY_ACTIVITY_STATUSES.PAYABLE]: {
    color: MiTHEME.colors.blue.primary,
    icon: ICONS_NATIVE_STATUS.INPROGRESS,
  },
  [PAY_ACTIVITY_STATUSES.POTENTIAL]: {
    color: MiTHEME.colors.orange.primary,
    icon: ICONS_NATIVE_STATUS.INPROGRESS,
  },
  [PAY_ACTIVITY_STATUSES.PAID]: {
    color: MiTHEME.colors.green.primary,
    icon: ICONS_NATIVE_STATUS.INPROGRESS,
  },
};

export const IMG_SIZE = {
  PIXEL_40x40: "/?size=40",
  PIXEL_50x50: "/?size=50",
};

export const UNAUTHORIZED_ERROR =
  "This user does not have access to login to this app. Please contact an administrator to resolve this issue.";
