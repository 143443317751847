import React, { useState, useEffect } from "react";
import { walkService, stageSummaryService } from "@miview/api";
import { HTTP_STATUSES, HOLD } from "@miview/constants";
import { createFilter } from "@miview/utils";
import { useEdit, useComponentState } from "@miview/hooks";
import { MiModal, MiMultipleSelect } from "@miview/components";
import { useTheme } from "@mui/material/styles";

export const HoldWorkModal = (props) => {
  const { open, onClose, walkId, walkDetails } = props;

  const [stageSummaries, setStageSummaries] = useState([]);

  const theme = useTheme();
  const edit = useEdit(walkDetails);
  const stateManager = useComponentState();
  const filterHelper = createFilter();

  useEffect(() => {
    getStageSummaries();
  }, []);

  useEffect(() => {
    if (!open) {
      edit.reset();
    }
  }, [open]);

  const getStageSummaries = async (stageTypeId) => {
    stateManager.run(async () => {
      const filters = filterHelper.equals({
        stageTypeId: stageTypeId,
      });
      const response = await stageSummaryService.getAll({
        filters,
        params: { orderBy: "StageSummaryDescription" },
      });
      if (response.status === HTTP_STATUSES.OK) {
        const dropdownOptions = response.data.map((v) => ({
          text: v.stageSummaryDescription,
          value: v.stageSummaryId,
        }));
        setStageSummaries(dropdownOptions);
      }
    });
  };

  const saveChanges = () => {
    stateManager.run(async () => {
      const request = { ...edit.edits, status: HOLD };
      const response = await walkService.update(walkId, request);
      if (response.status === HTTP_STATUSES.OK) {
        onClose();
      }
    });
  };

  return (
    <MiModal
      title="Hold"
      isCloseButton
      open={open}
      onClose={onClose}
      actions={[
        {
          onClick: onClose,
          color: theme.palette.primary.grey,
          name: "Cancel",
        },
        {
          onClick: saveChanges,
          color: theme.palette.primary.main,
          name: "Save",
          inverse: true,
          disabled: !edit.getValue("stageSummaryIds") || stateManager.isBusy(),
        },
      ]}
    >
      <MiMultipleSelect
        label="Stage Summary"
        options={stageSummaries}
        value={edit.getValue("stageSummaryIds") || []}
        onChange={(e) => edit.update({ stageSummaryIds: e })}
      />
    </MiModal>
  );
};
