import { useContext } from "react";
import { PlanItemFormContext } from "../../../context";

export const usePlanItemForm = () => {
  return {
    ...useContext(PlanItemFormContext),
  };
};

//NEW
export * from "./usePlan";
export * from "./useMaterials";
export * from "./useStages";
export * from "./usePropertySections";
export * from "./usePlanAreas";
export * from "./useWalkTypes";
export * from "./usePlanOptions";
export * from "./useStacks";
export * from "./useProperty";

//OLD
export * from "./useResetPage";
export * from "./usePlanItemUpdate";
export * from "./usePlanOptionUpdate";
export * from "./useGenerateMaster";
export * from "./useMasterSpecsLoader";
export * from "./useSetOptionOn";
