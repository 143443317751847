import React from "react";
import PropTypes from "prop-types";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BallotIcon from "@mui/icons-material/Ballot";
import { Chip } from "@miview/components";
import { useMiStepsBarStyles } from "./MiStepsBarStyles";
import { THEME } from "./theme";

const STEPS = [
  {
    id: 0,
    value: "Details",
    icon: LibraryBooksIcon,
    color: THEME.STEPS_BAR[0],
  },
  {
    id: 1,
    value: "Areas",
    icon: BallotIcon,
    color: THEME.STEPS_BAR[1],
  },
  {
    id: 2,
    value: "Options",
    icon: DashboardIcon,
    color: THEME.STEPS_BAR[2],
  },
  {
    id: 3,
    value: "Custom",
    icon: LibraryBooksIcon,
    color: THEME.STEPS_BAR[3],
  },
];

const StepInfo = ({ step, index, selected }) => {
  const stepDisplay = STEPS?.[index];

  const { classes } = useMiStepsBarStyles();

  if (stepDisplay?.icon) {
    const IconSVG = stepDisplay.icon;

    return (
      <div
        style={{
          position: "relative",
          backgroundColor: stepDisplay.color,
          boxShadow: selected ? `0 0 10px ${stepDisplay.color}` : "none",
        }}
        className={classes.titleIconContainerSvg}
        id={`step-bar-item-${index}`}
      >
        {renderBadge(step.incompleteItems)}
        <IconSVG style={styles.icon} />
        <div className={classes.stepTitleContainer}>
          <div className={classes.stepTitle}>{step.name || step.label}</div>
        </div>
      </div>
    );
  }

  return null;
};

StepInfo.propTypes = {
  step: PropTypes.object,
  selected: PropTypes.bool,
};

StepInfo.defaultProps = {
  step: {},
};

const renderBadge = (count) => {
  if (!count) {
    return null;
  }
  return (
    <div style={{ position: "absolute", right: 0, top: 0 }}>
      <Chip
        text={count.toString()}
        color={THEME.RED_PRIMARY}
        textColor="white"
        style={{ paddingLeft: 8, paddingRight: 9 }}
      />
    </div>
  );
};

const Step = ({ step = {}, index, isReview, activeTab, setActiveTab }) => {
  const { classes } = useMiStepsBarStyles();

  return (
    <div
      className={classes.stepInfoContainer}
      id={`step-info-container-${step.value}`}
      onClick={() => setActiveTab(step.value)}
    >
      <StepInfo
        index={index}
        step={step}
        isReview={isReview}
        selected={activeTab === step?.value}
      />
    </div>
  );
};

Step.propTypes = {
  step: PropTypes.object.isRequired,
  isReview: PropTypes.bool,
  setActiveTab: PropTypes.func,
  activeTab: PropTypes.string,
  selected: PropTypes.bool,
};

const StepsBar = (props) => {
  const { selectedStepNumber, steps, activeTab, setActiveTab } = props;

  const { classes } = useMiStepsBarStyles();

  return (
    <div className={classes.stepsContainer}>
      <div className={classes.stepsInnerContainer}>
        <span className={classes.stepsBar}>
          <span style={{ width: "100%" }} id="timeline"></span>
        </span>
        <div className={classes.stepsList}>
          {steps.map((item, index) => (
            <div key={index}>
              <Step
                step={item}
                index={index}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                selected={selectedStepNumber === index}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

StepsBar.propTypes = {
  steps: PropTypes.array,
  setActiveTab: PropTypes.func,
};

StepsBar.defaultProps = {
  setActiveTab: () => null,
};

const styles = {
  current: {
    backgroundColor: "#FFF",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: THEME.GREEN_PRIMARY,
  },
  past: {
    backgroundColor: THEME.GREEN_PRIMARY,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: THEME.GREEN_PRIMARY,
  },
  iconCurrent: {
    fontSize: "1.3rem",
    textAlign: "center",
    marginTop: "-4px",
    marginLeft: "-1.5px",
    color: "white",
  },
  icon: { height: 32, width: 32 },
};

export default StepsBar;
