import React, { useState } from "react";
import moment from "moment";
import { useComponentState, usePermissions } from "@miview/hooks";
import { manufacturerService } from "@miview/api";
import { useTheme } from "@mui/material/styles";
import { PERMISSIONS, TOAST_TYPES } from "@miview/constants";
import { createToast } from "@miview/toast";
import {
  MiPageHeader,
  MiButton,
  MiModal,
  MiList,
  MiDetailFields,
  MiIcon,
  MiModalConfirm,
} from "@miview/components";
import { mdiDelete, mdiFactory, mdiPlus } from "@mdi/js";

export const Manufacturers = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [deleteName, setDeleteName] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [newInProgress, setNewInProgress] = useState(false);
  const [newManufacturerData, setNewManufacturerData] = useState({});
  const [refresh, setRefresh] = useState(false);

  const stateManager = useComponentState();
  const permissions = usePermissions();
  const theme = useTheme();

  const closeDeleteModal = () => {
    setDeleteId();
    setDeleteName("");
    setIsDeleting(false);
  };

  const columns = [
    {
      field: "name",
      align: "left",
      headerName: "Manufacturer",
      flex: 1,
    },
    {
      field: "createTime",
      align: "left",
      headerName: "Created",
      flex: 1,
      renderCell: (p) => moment(p.value).format("ddd, MMM DD, YYYY"),
    },
  ];

  const manufacturersInput = [
    {
      label: "Manufacturer Name",
      id: "manufacturersName",
      value: newManufacturerData.name,
      required: true,
      setValue: (e) =>
        setNewManufacturerData({
          ...newManufacturerData,
          name: e,
        }),
      width: "100%",
    },
  ];

  const openDeleteModal = (row) => {
    if (!permissions.hasPermission(PERMISSIONS.CAN_DELETE_MANUFACTURER)) {
      createToast(
        "You do not have permission to delete manufacturers",
        TOAST_TYPES.ERROR
      );
      return;
    }
    setDeleteId(row.id);
    setDeleteName(row.name);
    setIsDeleting(true);
  };

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => openDeleteModal(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
    ];
  };

  const deleteManufacturer = async () => {
    await manufacturerService.delete(deleteId);
    closeDeleteModal();
    setRefresh(!refresh);
  };

  const closeNewManufacturerModal = () => {
    setNewInProgress(false);
    setNewManufacturerData({});
  };

  const createManufacturer = async () => {
    if (!permissions.hasPermission(PERMISSIONS.CAN_CREATE_MANUFACTURER)) {
      createToast(
        "You do not have permission to create manufacturers",
        TOAST_TYPES.ERROR
      );
      return;
    }
    await manufacturerService.create(newManufacturerData);
    closeNewManufacturerModal();
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon path={mdiFactory} size={1} color={theme.palette.primary.main} />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Manufacturer"
        icon={mdiPlus}
        inverse={true}
        onClick={() => setNewInProgress(true)}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <>
      <MiPageHeader
        title="Manufacturer"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
      />
      {stateManager.statusTag("manufacturerListStatus")}
      <MiList
        data={manufacturers}
        setData={setManufacturers}
        dataProvider={manufacturerService.getAll}
        columns={columns}
        defaultPageSize={20}
        actionIconStyle={{ color: theme.palette.secondary.grey }}
        getRowActions={getRowActions}
        refresh={refresh}
      />
      <MiModal
        key={0}
        title={"Create Manufacturer"}
        open={newInProgress}
        onClose={closeNewManufacturerModal}
        actions={[
          {
            name: "Cancel",
            style: { minWidth: 90 },
            onClick: closeNewManufacturerModal,
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: createManufacturer,
            style: { minWidth: 90 },
            color: theme.palette.primary.green,
            inverse: true,
            disabled: !newManufacturerData.name,
          },
        ]}
      >
        <div style={{ width: "100%", padding: "5px" }}>
          <MiDetailFields detailFields={manufacturersInput} />
        </div>
      </MiModal>
      <MiModalConfirm
        title={"Confirm Delete"}
        description={`Delete Manufacturer: ${deleteName}?`}
        open={isDeleting}
        handlePositive={deleteManufacturer}
        handleNegative={closeDeleteModal}
      />
    </>
  );
};
