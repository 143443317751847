import moment from "moment";
import { THEME } from "@miview/theme";

export const getTimeDiffByDays = (startDate, endDate) => {
  const totalHours = getTimeDiffByHours(startDate, endDate);
  const days = parseInt(totalHours / 24);
  const hours = totalHours % 24;
  return { days: days, hours: hours };
};

export const getTimeDiffByHours = (startDate, endDate) => {
  let end = moment(endDate);
  let start = moment(startDate);
  return end.clone().diff(start, "hours");
};

export const getCounterInfo = (date, endDate) => {
  var response = {
    days: 0,
    hours: 0,
    hoursColor: THEME.GREEN_PRIMARY,
    daysColor: THEME.GREEN_PRIMARY,
  };
  if (!date) {
    return response;
  }

  endDate = endDate ? moment(endDate) : moment();

  let diff = moment(date).diff(endDate);
  if (diff < 0) {
    response.daysColor = THEME.RED_PRIMARY;
    response.hoursColor = THEME.RED_PRIMARY;
  }

  const duration = moment.duration(diff);
  response.days = Math.abs(duration.days());
  response.hours = Math.abs(duration.hours());
  return response;
};

export const getIsLateFromNow = (date) => {
  const endDate = moment();
  let due = moment(date);
  let diff = due.diff(endDate);
  return diff < 0;
};

export const isDateToday = (day) => {
  return moment().format("YYYY-MM-DD") === moment(day).format("YYYY-MM-DD");
};
