import React, { useState } from "react";
import {
  MiLink,
  MiPageHeader,
  MiModal,
  MiModalConfirm,
  MiInputTextbox,
  MiButton,
  MiList,
  MiIcon,
} from "@miview/components";
import { walkTypeTemplateService } from "@miview/api";
import { useEdit, useComponentState } from "@miview/hooks";
import { mdiDelete, mdiFormatTextbox, mdiPlus } from "@mdi/js";
import { MiTHEME } from "@miview/theme";
import { createUUID } from "@miview/utils";

const uuid = createUUID();

const WalkTemplates = () => {
  const [walkTemplates, setWalkTemplates] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const create = useEdit();
  const remove = useEdit();
  const stateManager = useComponentState();

  const getTemplates = (config) => {
    stateManager.abort(uuid);
    return stateManager.run(async (signal) => {
      return walkTypeTemplateService.getAll({ ...config, signal });
    }, uuid);
  };

  const openCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    create.reset();
    setIsCreateModalOpen(false);
  };

  const createTemplate = () => {
    stateManager.run(async () => {
      await walkTypeTemplateService.create(create.edits);
      setRefresh(!refresh);
      closeCreateModal();
    });
  };

  const openDeleteModal = (row) => {
    remove.update({ id: row.id, name: row.name });
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    remove.reset();
    setIsDeleteModalOpen(false);
  };

  const deleteTemplate = () => {
    stateManager.run(async () => {
      await walkTypeTemplateService.delete(remove.getValue("id"));
      setRefresh(!refresh);
      closeDeleteModal();
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Template",
      renderCell: (e) => (
        <MiLink to={`/templates/${e.row.id}`} title={e.value} />
      ),
      flex: 1,
    },
  ];

  const getRowActions = () => {
    return [
      {
        text: "DELETE",
        onClick: (e) => openDeleteModal(e),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
    ];
  };

  const actions = [
    {
      onClick: closeCreateModal,
      color: MiTHEME.palette.primary.grey,
      name: "Cancel",
      inverse: false,
    },
    {
      onClick: createTemplate,
      color: MiTHEME.palette.primary.green,
      name: "Submit",
      inverse: true,
      disabled: !create.getValue("name"),
    },
  ];

  const renderCreateModal = () => (
    <MiModal
      title={"Create New Template"}
      open={isCreateModalOpen}
      actions={actions}
      onClose={closeCreateModal}
    >
      <MiInputTextbox
        labelText="Template Name"
        value={create.getValue("name")}
        handleChange={(e) => create.update({ name: e.target.value })}
        required
      />
    </MiModal>
  );

  const renderDeleteModal = () => (
    <MiModalConfirm
      open={isDeleteModalOpen}
      title={"Confirm Delete"}
      description={`Delete ${remove.getValue("name")}?`}
      handlePositive={deleteTemplate}
      handleNegative={closeDeleteModal}
    />
  );

  const renderHeaderIcon = () => (
    <MiIcon
      path={mdiFormatTextbox}
      size={1}
      color={MiTHEME.palette.primary.main}
    />
  );

  const headerContent = () => (
    <MiButton
      title="Template"
      icon={mdiPlus}
      inverse={true}
      onClick={openCreateModal}
      color={MiTHEME.palette.primary.main}
    />
  );

  return (
    <>
      {stateManager.statusTag("walkTemplateListStatus")}
      {renderCreateModal()}
      {renderDeleteModal()}
      <MiPageHeader
        title={"Templates"}
        leftIcon={renderHeaderIcon()}
        color={MiTHEME.palette.primary.main}
        action={headerContent()}
      />
      <MiList
        data={walkTemplates}
        columns={columns}
        refresh={refresh}
        dataProvider={getTemplates}
        setData={setWalkTemplates}
        getRowActions={getRowActions}
      />
    </>
  );
};

export default WalkTemplates;
