import React from "react";
import { makeStyles } from "tss-react/mui";
import { Avatar as MuiAvatar } from "@mui/material";
import PropTypes from "prop-types";

const useStyles = makeStyles()((_, { props }) => ({
  avatar: {
    backgroundColor: props.backgroundColor,
    color: "white",
    fontSize: 12,
    height: 30,
    width: 30,
  },
}));

/**
 * 
 * 

##Definition
An _avatar_ is a pictorial representation (image, text, icon) of a person, group, or project.

*Commonly Referred As:* None

##Example

![image.png](/.attachments/image-66cc4af6-1a3d-4774-aa43-3785a4adf8ae.png)

##Structure & Placement

###Structure
An _avatar_ may be just an image, text, or icon in a circle or rounded square. It may also have an accompanying text label that is optional. 

###Placement
See [Example](#Example) for placement.

##Guidelines
###Do's & Don'ts

| **Do's**        | **Don'ts**      |
|-----------------|-----------------|
| Use to represent a person, group, or project where the visual provides context to the content to which it is near. | Use **only** to represent a person, group, or project if it is not near the content being referenced. Consider using an icon instead. |

###Variants
- **Circle:** Use for a person
- **Rounded Square:** Use for a group or project

###Sizes
- Depends on the context.
- Recommended sizes (in pixels): 16, 24, 32, 48, 64, 96.

###Color
Follow standard MiView color themes.

###Behavior (States)
- Avatars without an accompanying label should make use of a tooltip to identify what is being represented.

###Content
- Avatars that default to the use of initials should follow these rules:
  - For names: Use the First and Last initials of the person's name.
  - For a single word name: Use the first two letters of the word with the first being a Capital and the second being lower case.
  - Consider using an icon instead of initials as an alternative

##Accessibility
- Use an `alt` tag for a single avatar that matches its tooltip.
- Use an empty `alt` tag if the avatar image has a label so a screen reader can ignore it.

Consult [WAI-ARIA Authoring Practices](https://www.w3.org/TR/wai-aria-practices-1.1) for more information.


 */
export const MiAvatar = (props) => {
  const { styles = {} } = props;
  const { classes } = useStyles({
    props: {
      styles,
    },
  });

  return (
    <MuiAvatar onClick={props?.handleClick} className={classes.avatar}>
      {props.children}
    </MuiAvatar>
  );
};

MiAvatar.propTypes = {
  /**
   * function to be called during the component's onClick event
   */
  handleClick: PropTypes.func,

  /**
   * backgroundColor:  updates the background color for non image versions of the avatar
   */
  backgroundColor: PropTypes.string,

  /**
   * the icon element to be rendered, between the opening
   * and closing tag of this component. Short strings also
   * work.
   * Ex: <MiAvatar>
   *       <Icon />
   *     </MiAvatar>
   */
  children: PropTypes.node,

  /**
   * an object containing the styles you want to change for the component
   * Ex: {
   *       backgroundColor: "blue"
   *     }
   */
  styles: PropTypes.object,
};
