import React, { useState, useEffect } from "react";
import {
  MiModalConfirm,
  MiList,
  MiButton,
  MiIcon,
  MiPageHeader,
} from "@miview/components";
import { payActivityTypeService, expenseTypeService } from "@miview/api";
import { createToast } from "@miview/toast";
import { TOAST_TYPES, HTTP_STATUSES, PAGINATION } from "@miview/constants";
import { CreateEditPayActivityTypeModal } from "./CreateEditPayActivityTypeModal";
import { useComponentState } from "@miview/hooks";
import { mdiDelete, mdiPencil, mdiPlus, mdiCashMultiple } from "@mdi/js";
import { useTheme } from "@mui/material";

export const PayActivityTab = (props) => {
  const { tabs } = props;

  const [payActivityTypes, setPayActivityTypes] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [isRulesDeleteModalOpen, setIsRulesDeleteModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const stateManager = useComponentState();
  const theme = useTheme();

  useEffect(() => {
    getExpenseTypes();
  }, []);

  const getPayActivityTypes = (params) => {
    return stateManager.run(async () => {
      return await payActivityTypeService.getAll(params);
    });
  };

  const getExpenseTypes = (params) => {
    stateManager.run(async () => {
      const response = await expenseTypeService.getAll(params);
      if (response.status === HTTP_STATUSES.OK) {
        setExpenseTypes(response.data);
      }
    });
  };

  const deletePayActivity = () => {
    stateManager.run(async () => {
      const response = await payActivityTypeService.delete(selectedRow.id);
      if (response.status === HTTP_STATUSES.OK) {
        createToast("Deleted Successfully", TOAST_TYPES.SUCCESS);
        closeDeleteModal();
        setRefresh(!refresh);
      }
    });
  };

  const openPayActivityEditModal = (obj) => {
    setSelectedRow(obj);
    setIsCreateUpdateModalOpen(true);
  };

  const closePayActivityEditModal = () => {
    setIsCreateUpdateModalOpen(false);
    setSelectedRow();
    setRefresh(!refresh);
  };

  const openDeleteModal = (obj) => {
    setSelectedRow(obj);
    setIsRulesDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedRow();
    setIsRulesDeleteModalOpen(false);
  };

  const payActivityColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "expenseTypeId",
      headerName: "Expense Type",
      flex: 1,
      renderCell: (e) => {
        const result = expenseTypes.find((ele) => ele.id === e.value);
        return result?.name ? result.name : "";
      },
    },
    {
      field: "displayOrder",
      headerName: "Display Order",
      flex: 1,
    },
  ];

  const getRowActions = () => {
    return [
      {
        text: "DELETE",
        onClick: (e) => openDeleteModal(e),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
      {
        text: "EDIT",
        onClick: (e) => openPayActivityEditModal(e),
        renderIcon: () => <MiIcon path={mdiPencil} size={1} />,
      },
    ];
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiCashMultiple}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const headerContent = () => (
    <MiButton
      title="Pay Activity Type"
      icon={mdiPlus}
      inverse
      onClick={openPayActivityEditModal}
      color={theme.palette.primary.main}
    />
  );

  return (
    <>
      <MiPageHeader
        title="Pay Settings"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={headerContent()}
        loading={stateManager.isBusy()}
      />
      <CreateEditPayActivityTypeModal
        open={isCreateUpdateModalOpen}
        onClose={closePayActivityEditModal}
        payActivityType={selectedRow}
      />
      <MiModalConfirm
        open={isRulesDeleteModalOpen}
        title="Confirm Delete"
        description={`Delete the following Pay Activity: ${selectedRow?.name}?`}
        handlePositive={deletePayActivity}
        handleNegative={closeDeleteModal}
      />
      <MiList
        data={payActivityTypes}
        columns={payActivityColumns}
        dataProvider={getPayActivityTypes}
        setData={setPayActivityTypes}
        tabs={tabs}
        defaultPageSize={20}
        refresh={refresh}
        getRowActions={getRowActions}
        searchColumns={["name", "expenseTypeId", "displayOrder"]}
        paginationMode={PAGINATION.CLIENT}
      />
    </>
  );
};
