import moment from "moment";

/**
 * formatDate -
 * Converts ISO or any valid date to a readable format.
 * It will not include hours/minutes/seconds.
 * It will return an empty string for falsy values and invalid old dates.
 *
 * @param {string} date
 * @param {boolean} allowErrors //allow method to break loudly if date is invalid
 * @returns {string}
 */
export const formatDate = (date, allowErrors = false) => {
  if (!allowErrors) {
    if (!date) {
      return "";
    }

    if (!moment(date).isAfter("1900-01-01")) {
      return "";
    }
  }

  return moment(date).format("ddd, MMM DD, YYYY");
};

export const formatDateTime = (date, allowErrors = false) => {
  if (!allowErrors) {
    if (!date) {
      return "";
    }

    if (!moment(date).isAfter("1900-01-01")) {
      return "";
    }
  }

  return moment(date).format("ddd, MMM DD, YYYY h:mm A");
};
