import React from "react";
import { MiButton } from "@miview/components";
import { Typography, Avatar, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";

const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: theme.fontSize.xlarge,
    fontWeight: theme.fontWeight.medium,
    color: theme.palette.secondary.bluegrey,
    marginBottom: 10,
  },
  showAllStyles: {
    textAlign: "center",
    padding: "100px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  showAllStylesIcon: {
    width: 175,
    height: 175,
    background: theme.palette.lightAccent.grey,
    marginBottom: 15,
    color: theme.palette.lightAccent.bluegrey,
    "& svg": {
      fontSize: 100,
    },
  },

  showAllStylesIconSmall: {
    width: 100,
    height: 100,
    background: theme.palette.light.teal,
    color: theme.palette.primary.teal,
    "& svg": {
      fontSize: 60,
    },
  },

  paddingSmall: {
    padding: "50px 20px",
  },

  sub: {
    fontSize: theme.fontSize.medium,
    color: theme.palette.accent.bluegrey,
    marginBottom: 10,
    maxWidth: 464,
    fontWeight: theme.fontWeight.regular,
  },
}));

const AccountNoItems = ({
  title,
  onClick,
  buttonText,
  description,
  small,
  icon,
}) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  return (
    <div className={cx(classes.showAllStyles, small && classes.paddingSmall)}>
      <Avatar
        className={cx(
          classes.showAllStylesIcon,
          small && classes.showAllStylesIconSmall
        )}
      >
        {!icon ? <FolderOpenOutlinedIcon /> : icon}
      </Avatar>

      <Typography component="h2" className={classes.title}>
        {title}
      </Typography>

      {description && (
        <Typography component="p" className={classes.sub}>
          {description}
        </Typography>
      )}

      {buttonText && (
        <MiButton
          style={{
            fontSize: theme.fontSize.medium,
            color: theme.palette.primary.white,
            backgroundColor: theme.palette.primary.teal,
            height: "36px",
            lineHeight: "36px",
            padding: "6px 14px",
            minWidth: "130px",
            fontWeight: theme.fontWeight.medium,
          }}
          onClick={onClick}
          title={buttonText}
        />
      )}
    </div>
  );
};

export default AccountNoItems;
