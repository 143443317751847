import React from "react";
import Alert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { TOAST_TYPES } from "@miview/constants";

export const MiAlert = (props) => {
  const { open, onClose, severity, message, ...rest } = props;
  const backgroundColor = severity === TOAST_TYPES.INFO ? "black" : "";

  return (
    <Collapse in={open}>
      <Alert
        variant="filled"
        severity={severity}
        style={{
          backgroundColor,
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        {...rest}
      >
        {message}
      </Alert>
    </Collapse>
  );
};
