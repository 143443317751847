import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import BuilderHomes from "./BuilderHomes";
import BuilderCommunities from "./BuilderCommunities";
import BuilderSeries from "./BuilderSeries";
import { accountServiceLegacy as accountService } from "@miview/api";
import { getImageBase64FromFile } from "@miview/utils";
import { useEdit, useRouter, useComponentState } from "@miview/hooks";
import {
  Image,
  MiSnackbar,
  MiTab,
  MiDetailFields,
  MiImagePreview,
  MiImageFilePicker,
  MiModal,
  MiBox,
  MiPageHeader,
  MiIcon,
} from "@miview/components";
import { mdiArrowLeft } from "@mdi/js";
import { HTTP_STATUSES } from "@miview/constants";

const tabs = [
  {
    label: "Details",
    value: global.ACTIVE_TAB.DETAILS,
  },
  {
    label: "Communities",
    value: global.ACTIVE_TAB.COMMUNITIES,
  },
  {
    label: "Homes",
    value: global.ACTIVE_TAB.PROPERTIES,
  },
];

const Builder = () => {
  const [activeTab, setActiveTab] = useState(global.ACTIVE_TAB.DETAILS);
  const [builderDetails, setBuilderDetails] = useState({});
  const [imgSrc, setImgSrc] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [builderImgEdit, setBuilderImgEdit] = useState(false);
  const [propertyImage, setPropertyImage] = useState();

  const edit = useEdit(builderDetails);
  const router = useRouter();
  const stateManager = useComponentState();
  const theme = useTheme();

  const { id: builderId } = router.params;

  useEffect(() => {
    getBuilder(builderId);
  }, [builderId]);

  const getBuilder = (id) => {
    stateManager.run(async () => {
      const response = await accountService.getAccount(id);
      if (response.status === HTTP_STATUSES.OK) {
        setBuilderDetails(response.data);
        setImgSrc(response.data.logoWebSmall);
      }
    });
  };

  const saveEdits = () => {
    stateManager.run(async () => {
      const response = await accountService.update(builderId, edit.edits);
      if (response.status === HTTP_STATUSES.OK) {
        setBuilderImgEdit(false);
        setEditMode(false);
      }
    });
  };

  const saveBuilderImage = () => {
    stateManager.run(async () => {
      const response = await accountService.update(builderId, {
        logoWebSmall: propertyImage,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setImgSrc(propertyImage);
        toggleImageEditModal();
        setPropertyImage(null);
      }
    });
  };

  const cancelEdit = () => {
    setEditMode(false);
    edit.reset();
  };

  const toggleImageEditModal = () => setBuilderImgEdit(!builderImgEdit);
  const toggleEdit = () => setEditMode(!editMode);

  const detailFields = [
    {
      label: "Builder Name",
      value: edit.getValue("name"),
      setValue: (e) => edit.update({ name: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
    {
      label: "Abbreviation",
      value: edit.getValue("code"),
      setValue: (e) => edit.update({ code: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
  ];

  const actions = [
    {
      onClick: toggleImageEditModal,
      color: theme.palette.primary.grey,
      name: "Close",
      inverse: false,
    },
    {
      onClick: saveBuilderImage,
      color: theme.palette.primary.main,
      name: "Confirm",
      inverse: true,
      disabled: !propertyImage,
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiArrowLeft}
        size={1}
        color={theme.palette.primary.main}
        onClick={router.goBack}
        style={{ cursor: "pointer" }}
      />
    );
  };

  return (
    <>
      {stateManager.statusTag("builderDetailsStatus")}
      <MiPageHeader
        color={theme.palette.primary.main}
        leftIcon={renderHeaderIcon()}
        loading={stateManager.isBusy()}
        title={builderDetails.name}
      />
      <MiBox styles={{ width: "100%" }}>
        <div style={{ marginBottom: 20, paddingTop: 5 }}>
          <MiTab
            tabs={tabs}
            currenttab={activeTab}
            onTabChange={setActiveTab}
          />
        </div>
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          {activeTab === global.ACTIVE_TAB.DETAILS && (
            <div style={detailsTabContainer}>
              <Image imgSrc={imgSrc} toggle={toggleImageEditModal} />
              <MiDetailFields
                detailFields={detailFields}
                headerProps={{
                  icon: "list",
                  title: "Detail Fields",
                }}
              />
              <MiSnackbar
                visible={editMode}
                handleCancelClick={cancelEdit}
                handleSaveClick={saveEdits}
                disableSave={!edit.allFilled("name", "code")}
              />
            </div>
          )}
          {activeTab === global.ACTIVE_TAB.BUILDERSERIES && (
            <BuilderSeries builderId={builderId} />
          )}
          {activeTab == global.ACTIVE_TAB.COMMUNITIES && (
            <BuilderCommunities builderId={builderId} />
          )}
          {activeTab === global.ACTIVE_TAB.PROPERTIES && (
            <BuilderHomes builderId={builderId} />
          )}
        </div>
      </MiBox>
      <MiModal
        title="Edit Builder Image"
        open={builderImgEdit}
        onClose={toggleImageEditModal}
        actions={actions}
      >
        <MiImageFilePicker
          labelText="Image"
          handleChange={(event) => {
            const file = event.target.files[0];
            getImageBase64FromFile(file, setPropertyImage);
          }}
          style={{ height: "100%", width: "100%" }}
        />
        <MiImagePreview src={propertyImage} />
      </MiModal>
    </>
  );
};

const detailsTabContainer = {
  display: "flex",
  flex: 1,
  flexDirection: " column",
  gap: 30,
  height: "100%",
  position: "relative",
};

export default Builder;
