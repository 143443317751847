import React from "react";
import { useTheme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Close } from "@mui/icons-material";
import {
  MiCollapse,
  MiBox,
  MiButton,
  MiAlert,
  MiIconButton,
  MiAccordion,
} from "@miview/components";
import { AccountNoItems } from "../../../../components";
import en from "../../../../locale/en.json";
import { useRouter } from "@miview/hooks";
import { QUOTE_STATUS, HTTP_STATUSES } from "@miview/constants";
import { pricingService } from "@miview/api";

const useStyles = makeStyles()((theme) => ({
  cartItemHeaderStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 10px 15px 10px",
    background: theme.palette.light.grey,
    "& h2": {
      margin: 0,
      fontSize: theme.fontSize.medium,
    },
  },
  cartItemList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px 5px 10px",
    "&:hover": {
      background: theme.palette.lightAccent.grey,
    },
  },
  id: {
    color: theme.palette.accent.bluegrey,
    fontSize: theme.fontSize.small,
    overflowWrap: "break-word",
    whiteSpace: "normal",
    lineHeight: "0.9",
    width: "40%",
    marginRight: "3px",
  },
  desc: {
    fontSize: theme.fontSize.small,
    fontWeight: theme.fontWeight.medium,
    width: "100%",
  },
  removeCartStyle: {
    width: 30,
    height: 30,
    padding: 0,
    borderRadius: "0px",
    color: theme.palette.secondary.red,
    background: "inherit!important",
    "& svg": {
      fontSize: theme.fontSize.large,
    },
  },
  buttonContainer: {
    display: "flex",
    padding: 5,
    backgroundColor: theme.palette.light.teal,
  },
}));

export const CostDrawer = ({
  open,
  onClear,
  onClose,
  selectedItems,
  remove,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const router = useRouter();

  const groupBy = (arr, key) => {
    return arr.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const getAccordionConfig = () => {
    const itemsByCategoryName = groupBy(selectedItems, "categoryName");
    return Object.entries(itemsByCategoryName).map(([categoryName, items]) => {
      return {
        title: (
          <div className={classes.cartItemHeaderStyle}>
            <Typography>{categoryName}</Typography>
            <Typography>{`(${items.length})`}</Typography>
          </div>
        ),
        data: items,
      };
    });
  };

  const renderAccordionDetails = (arr) =>
    arr.map((item) => (
      <div key={item.itemId} className={classes.cartItemList}>
        <div className={classes.id}>{item.itemName}</div>
        <div className={classes.desc}>{item.itemDescription}</div>
        <MiIconButton onClick={() => remove(item)}>
          <Close />
        </MiIconButton>
      </div>
    ));

  const renderDetails = (config) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {renderAccordionDetails(config.data)}
      </div>
    );
  };

  const renderDrawerContents = () => {
    if (selectedItems.length) {
      return (
        <MiAccordion
          config={getAccordionConfig()}
          renderDetail={renderDetails}
          backgroundColor={theme.palette.light.grey}
        />
      );
    }
    return (
      <AccountNoItems
        title={en.sideCartEmptyTitle}
        description={en.sideCartEmptyDescription}
        small={true}
      />
    );
  };

  const createQuote = async (status) => {
    const request = {
      accountId: router.query.id,
      quoteItems: selectedItems,
      status,
    };
    const response = await pricingService.quotes.create({ data: request });
    if (response.status === HTTP_STATUSES.CREATED) {
      router.push(`/purchasing/quotes/${response.data.id}`);
    }
  };

  return (
    <MiCollapse in={open} orientation={"horizontal"}>
      <MiBox height={"95%"} padding={0}>
        <div style={{ width: "450px" }}>
          <MiAlert
            open={true}
            icon={false}
            message={`Item Count (${selectedItems.length})`}
            onClose={onClose}
            style={{ backgroundColor: theme.palette.primary.teal }}
          />
          <div className={classes.buttonContainer}>
            <MiButton
              title={"Request Quote"}
              color={theme.palette.primary.white}
              backgroundColor={theme.palette.primary.teal}
              style={{ flex: 1 }}
              disabled={!selectedItems.length}
              onClick={() => createQuote(QUOTE_STATUS.DRAFT)}
            />
            <MiButton
              title={"Enter Quote"}
              color={theme.palette.primary.white}
              backgroundColor={theme.palette.primary.teal}
              style={{ flex: 1 }}
              disabled={!selectedItems.length}
              onClick={() => createQuote(QUOTE_STATUS.REQUESTED)}
            />
            <MiButton
              title={"Clear All"}
              color={theme.palette.primary.white}
              backgroundColor={theme.palette.primary.teal}
              style={{ flex: 1 }}
              disabled={!selectedItems.length}
              onClick={onClear}
            />
          </div>
          {renderDrawerContents()}
        </div>
      </MiBox>
    </MiCollapse>
  );
};
