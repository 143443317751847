import React from "react";
import { CathedralLogo, LogoMiView } from "@miview/assets";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { MiTHEME } from "@miview/theme";

export const PrintPage = React.memo(({ rows, formatCurrency }) => {
  const theme = useTheme();
  const sumOfSelectedEntries = () => {
    return rows.reduce(
      (previousVal, currentVal) => previousVal + currentVal.amount,
      0
    );
  };

  return (
    <div id="printcls" style={{ display: "none" }}>
      <div
        style={{
          width: "100%",
          backgroundColor: theme.palette.light.teal,
          borderRadius: "4px",
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            margin: "8px",
            color: theme.palette.primary.main,
            fontWeight: MiTHEME.fontWeight.bold,
          }}
        >
          Subcontractor Accounts Payable
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img
          src={CathedralLogo}
          style={{
            marginBottom: "20px",
            zIndex: 1,
            width: 200,
            height: "50px",
            objectFit: "contain",
          }}
          alt="CathedralLogo"
        />
        <div>
          <span style={{ position: "relative", bottom: "18px" }}>
            Powered By:
          </span>
          <img
            src={LogoMiView}
            style={{
              zIndex: 1,
              objectFit: "contain",
            }}
            alt="MiView Logo"
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        {printTableHeading.map((itm, idx) => (
          <div
            key={idx}
            style={{
              color: theme.palette.primary.main,
              width: itm.width,
              backgroundColor: theme.palette.light.teal,
              border: "1px solid lightteal",
              textAlign: "center",
            }}
          >
            {itm.heading}
          </div>
        ))}
      </div>

      {rows.map((itm, idx) => {
        return (
          <div
            key={idx}
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              breakInside: "avoid",
            }}
          >
            {printTableKeysOfObject.map((key, index) => {
              if (typeof itm[key.field] === "number") {
                return (
                  <div
                    key={index}
                    style={{
                      border: "1px solid lightgrey",
                      width: key.width,
                      overflowWrap: "break-word",
                    }}
                  >
                    {formatCurrency(itm[key.field])}
                  </div>
                );
              }
              if (key.field === "recordedDate" || key.field === "payDate") {
                return (
                  <div
                    key={idx}
                    style={{
                      border: "1px solid lightgrey",
                      width: key.width,
                    }}
                  >
                    {itm[key.field]
                      ? moment(itm[key.field]).utc().format("MM/DD/YY")
                      : ""}
                  </div>
                );
              }
              return (
                <div
                  key={idx}
                  style={{
                    border: "1px solid lightgrey",
                    width: key.width,
                    overflowWrap: "break-word",
                  }}
                >
                  {itm[key.field]}
                </div>
              );
            })}
          </div>
        );
      })}

      <div
        style={{
          margin: 10,
          display: "flex",
          justifyContent: "flex-end",
          breakInside: "avoid",
        }}
      >
        <span
          style={{
            fontWeight: MiTHEME.fontWeight.bold,
            padding: "5px 10px 5px 10px",
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.light.teal,
            borderRadius: "4px",
          }}
        >
          Total:
        </span>{" "}
        <span style={{ padding: "5px 10px 5px 10px" }}>
          {formatCurrency(sumOfSelectedEntries())}
        </span>
      </div>
    </div>
  );
});

const printTableHeading = [
  { heading: "Subcontractor", width: "13.5%" },
  { heading: "Home", width: "24%" },
  { heading: "Pay Type", width: "10%" },
  { heading: "Job Type", width: "10%" },
  { heading: "Stage", width: "10%" },
  { heading: "Created", width: "10%" },
  { heading: "Pay Date", width: "10%" },
  { heading: "Amount", width: "12.5%" },
];

const printTableKeysOfObject = [
  { field: "payableToUserName", width: "13.5%" },
  { field: "address", width: "24%" },
  { field: "expenseType", width: "10%" },
  { field: "walkTypeName", width: "10%" },
  { field: "stageStatus", width: "10%" },
  { field: "recordedDate", width: "10%" },
  { field: "payDate", width: "10%" },
  { field: "amount", width: "12.5%" },
];
