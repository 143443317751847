import React from "react";
import {
  MiModal,
  MiInputTextbox,
  MiInputSelectBox,
  MiDatePickerInput,
  MiSwitch,
} from "@miview/components";
import { useEdit } from "@miview/hooks";
import { integrationService } from "@miview/api";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { RunDays } from "./RunDays";
import { INTEGRATION_NAMES, HTTP_STATUSES } from "@miview/constants";
import moment from "moment";

const useStyles = makeStyles()(() => ({
  fieldRows: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    width: "100%",
    justifyContent: "space-between",
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 10,
  },
  inputFieldContainer: {
    flex: 1,
    minWidth: 250,
    padding: 10,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const frequencyOptions = [
  { label: "1 Hour", value: 1 },
  { label: "2 Hours", value: 2 },
  { label: "3 Hours", value: 3 },
  { label: "4 Hours", value: 4 },
  { label: "5 Hours", value: 5 },
  { label: "6 Hours", value: 6 },
  { label: "7 Hours", value: 7 },
  { label: "8 Hours", value: 8 },
  { label: "9 Hours", value: 9 },
  { label: "10 Hours", value: 10 },
  { label: "11 Hours", value: 11 },
  { label: "12 Hours", value: 12 },
  { label: "24 Hours", value: 0 },
];

export const ScheduleModal = (props) => {
  const { data, handleClose, fetchData } = props;
  const theme = useTheme();
  const { classes } = useStyles();
  const edit = useEdit(data);

  const handleChange = (field, value) => {
    edit.update({ [field]: value });
  };

  const handleSave = async () => {
    const response = await integrationService.integrations.updateService(
      INTEGRATION_NAMES.DrHorton,
      data.id,
      edit.edits
    );
    if (response.status === HTTP_STATUSES.OK) {
      fetchData();
      handleClose();
    }
  };

  const getDisabled = () => {
    const disable = !edit.allFilled("runDays", "interval");
    //TODO:  update logic for these field validations --- need to handle the bad values onChange to make allFilled work properly
    const disableStartTime = !edit.getValue("startTime");
    const disableDate =
      edit.getValue("startDate") === "Invalid date" ||
      !edit.getValue("startDate");
    return disable || disableDate || disableStartTime;
  };

  const actions = [
    {
      onClick: handleClose,
      color: theme.palette.primary.grey,
      name: "Cancel",
    },
    {
      disabled: getDisabled(),
      onClick: handleSave,
      color: theme.palette.primary.main,
      name: "Save",
      inverse: true,
    },
  ];

  const handleDayChange = (val) => {
    edit.update({ runDays: val });
  };

  const getFrequencyValue = () => {
    const value = edit.getValue("interval");
    if (value) {
      return moment(value, "h:mm:ss").hour();
    }
    return "";
  };

  const handleFrequencyChange = (e) => {
    let value = e.target.value;
    if (value || value === 0) {
      value = `${e.target.value}:00:00`;
    }
    handleChange("interval", value);
  };

  const RepeatsOnLabel = () => {
    return (
      <div className={classes.rowContainer}>
        <div
          style={{
            ...styles.label,
            color: theme.palette.primary.main,
            fontSize: theme.fontSize.medium,
          }}
        >
          Repeats On
        </div>
        <div
          style={{
            ...styles.label,
            color: theme.palette.primary.red,
          }}
        >
          *
        </div>
      </div>
    );
  };

  return (
    <>
      {!!data && (
        <MiModal
          open={!!data}
          onClose={handleClose}
          title={"Edit Schedule"}
          actions={actions}
        >
          <div>
            <div className={classes.fieldRows}>
              <div
                className={classes.inputFieldContainer}
                style={{ width: "auto" }}
              >
                <MiInputTextbox
                  style={{ width: "100%" }}
                  labelText={"API"}
                  disabled={true}
                  value={edit.getValue("serviceTypeDisplayName")}
                  labelTextColor={theme.palette.primary.main}
                />
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.inputFieldContainer}>
                <MiDatePickerInput
                  type="time"
                  labelText={"Start Time"}
                  labelTextColor={theme.palette.primary.main}
                  value={edit.getValue("startTime") || ""}
                  handleChange={(val) => {
                    handleChange("startTime", val);
                  }}
                  inputFormat="hh:mm A"
                  dateFormat="HH:mm:ss"
                  views={["hours"]}
                  required={true}
                />
              </div>
              <div className={classes.inputFieldContainer}>
                <MiDatePickerInput
                  type={"date"}
                  labelText={"Start Date"}
                  labelTextColor={theme.palette.primary.main}
                  value={edit.getValue("startDate") || ""}
                  handleChange={(val) => handleChange("startDate", val)}
                  inputFormat="MM/DD/YYYY"
                  dateFormat="YYYY-MM-DDThh:mm:ss"
                  required={true}
                />
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.inputFieldContainer}>
                <MiInputSelectBox
                  required={true}
                  labelText={"Frequency"}
                  value={getFrequencyValue()}
                  menuItems={frequencyOptions}
                  handleChange={handleFrequencyChange}
                  labelTextColor={theme.palette.primary.main}
                />
              </div>
              <div className={classes.inputFieldContainer}>
                <RepeatsOnLabel />
                <RunDays
                  onClick={handleDayChange}
                  days={edit.getValue("runDays")}
                  size={30}
                  style={{ marginTop: 20 }}
                />
              </div>
            </div>
            <div className={classes.fieldRows}>
              <div className={classes.inputFieldContainer}>
                <MiSwitch
                  label={"Enable"}
                  labelPlacement={"end"}
                  value={edit.getValue("isEnabled")}
                  handleChange={(e) =>
                    handleChange("isEnabled", e.target.checked)
                  }
                  color={theme.palette.primary.main}
                />
              </div>
            </div>
          </div>
        </MiModal>
      )}
    </>
  );
};

const styles = {
  dayChips: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    borderRadius: 40,
    height: 30,
    overflow: "hidden",
    textOverflow: "",
    margin: 1,
  },
  label: {
    marginTop: -10,
    marginBottom: -20,
  },
};
