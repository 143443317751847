import React, { memo, useState, useEffect } from "react";
import { THEME } from "./theme";
import { MiIcon, MiModal } from "@miview/components";
import MiMessageReplyOptions from "./MiMessageReplyOptions";
import { messageService, propertyService } from "@miview/api";
import {
  HTTP_STATUSES,
  VALID_FILE_TYPES_FOR_IMAGES_UPLOAD,
} from "@miview/constants";
import {
  mdiAccountMultiplePlus,
  mdiCheck,
  mdiAccountCircle,
  mdiDotsHorizontal,
  mdiPlus,
} from "@mdi/js";

const MiNewMessageDialog = (props) => {
  const {
    parentMessage,
    relatedType,
    refreshAction,
    hideDialog,
    relatedEntityId,
  } = props;

  const [tagMenu, setTagMenu] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState(undefined);
  const [fileType, setFileType] = useState(undefined);
  const [showOptions, setShowOptions] = useState(false);
  const [isImageFile, setIsImageFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const isReply = !(parentMessage === null || parentMessage === undefined);
  const placeholderMessage = isReply
    ? `Reply to ${parentMessage.fromName}`
    : "Add new message";
  const id = isReply
    ? `MessageInput${parentMessage.messageId}`
    : "TopLevelMessageInput";

  useEffect(() => {
    if (relatedType === global.MESSAGE_CONTEXT.HOME){
      fetchContacts();
    }
    
  }, []);

  const fetchContacts = async () => {
    const response = await propertyService.getContacts(relatedEntityId);
    if (response.status === HTTP_STATUSES.OK) {
      setContacts(response.data);
    }
  };

  const addUser = (selectedUser) => {
    if (selectedContacts.some((s) => s.personId === selectedUser.personId)) {
      return;
    }
    setContacts(
      contacts.map((item) => {
        if (item.personId === selectedUser.personId) {
          return { ...item, isSelected: true };
        }
        return item;
      })
    );
    setSelectedContacts([...selectedContacts, selectedUser]);
  };

  const removeUser = (selectedUser) => {
    setContacts(
      contacts.map((item) => {
        if (item.personId === selectedUser.personId) {
          return { ...item, isSelected: false };
        }
        return item;
      })
    );
    setSelectedContacts(
      selectedContacts.filter((usr) => usr.personId !== selectedUser.personId)
    );
  };

  const reset = () => {
    setInputValue("");
    setFileType(undefined);
    setAttachmentUrl(undefined);
    if (refreshAction) {
      refreshAction();
    }
    if (hideDialog) {
      hideDialog();
    }
  };

  const postMessage = async () => {
    if (!inputValue) {
      return;
    }
    const messageData = {
      body: inputValue,
      parentMessageId: isReply ? parentMessage.messageId : undefined,
      attachment: attachmentUrl,
      fileType: fileType,
      relatedEntityId: relatedEntityId,
      entityType: relatedType,
      taggedUsers: tags,
    };
    await messageService.post(messageData);
    reset();
    setTags([]);
  };

  const checkIsValidImageFile = (file) => {
    if (VALID_FILE_TYPES_FOR_IMAGES_UPLOAD.includes(file.type)) {
      setIsImageFile(true);
      return;
    }
    setIsImageFile(false);
    setFileName(file.name);
  };

  return (
    <div className="Message" style={offset}>
      <div style={boxStyle}>
        <div onClick={() => setTagMenu(true)}>
          {relatedType === global.MESSAGE_CONTEXT.HOME && (
            <MiIcon
              path={mdiAccountMultiplePlus}
              size={1.5}
              color={THEME.BLUE_PRIMARY}
            />
          )}
        </div>
        <div style={{ flex: 1 }}>
          {tags.map((tag) => {
            return <span key={tag.userId}>@{tag.fullName} </span>;
          })}
          <textarea
            id={id}
            style={textareaStyle}
            value={inputValue}
            placeholder={placeholderMessage}
            onChange={(e) => setInputValue(e.target.value)}
          />
          {attachmentUrl &&
            (isImageFile ? (
              <img
                src={attachmentUrl}
                height="100"
                width="150"
                alt="Image preview"
              />
            ) : (
              <span>{fileName}</span>
            ))}
        </div>
        <div onClick={postMessage}>
          <MiIcon path={mdiPlus} size={1.5} color={THEME.BLUE_PRIMARY} />
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {showOptions ? (
          <MiMessageReplyOptions
            hideDialog={() => setShowOptions(false)}
            setFileURL={setAttachmentUrl}
            checkIsValidImageFile={checkIsValidImageFile}
          />
        ) : (
          <MiIcon
            path={mdiDotsHorizontal}
            color={THEME.GREY_DARK}
            onClick={() => setShowOptions(true)}
          />
        )}
      </div>
      <MiModal
        title="Tag User"
        onClose={() => setTagMenu(false)}
        open={tagMenu}
        actions={[
          {
            onClick: () => setTagMenu(false),
            color: THEME.GREY_LIGHT,
            name: "Close",
            inverse: true,
          },
          {
            onClick: () => {
              setTags(selectedContacts);
              setTagMenu(false);
            },
            color: THEME.BLUE_PRIMARY,
            name: "Confirm",
            inverse: true,
          },
        ]}
      >
        <>
          {contacts.map((contact) => {
            return (
              <div
                key={contact.userId}
                style={contactBox}
                onClick={() =>
                  contact.isSelected ? removeUser(contact) : addUser(contact)
                }
              >
                <div style={contactAvatar}>
                  <MiIcon
                    path={mdiAccountCircle}
                    size={1.5}
                    color={THEME.PURPLE_SECONDARY}
                  />
                </div>
                <div style={contactNameContainer}>
                  <div style={contactNameStyle}>{contact.fullName}</div>
                  <>{contact.title}</>
                </div>
                <>
                  {contact.isSelected ? (
                    <MiIcon
                      path={mdiCheck}
                      size={1.5}
                      color={THEME.GREEN_PRIMARY}
                    />
                  ) : (
                    <MiIcon
                      path={mdiPlus}
                      size={1.5}
                      color={THEME.BLUE_PRIMARY}
                    />
                  )}
                </>
              </div>
            );
          })}
        </>
      </MiModal>
    </div>
  );
};

const offset = {
  paddingLeft: 50,
};

const boxStyle = {
  border: "1px solid rgb(221,221,211)",
  padding: ".6rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: "nowrap",
};

const textareaStyle = {
  border: "0px",
  resize: "none",
  overflowY: "hidden",
  maxHeight: "100%",
  width: "100%",
};

const contactBox = {
  border: `1px solid ${THEME.GREY_LIGHT_ALT}`,
  display: "flex",
  padding: 10,
  marginBottom: 10,
  borderRadius: 5,
  cursor: "pointer",
  alignItems: "center",
};

const contactNameContainer = {
  flex: 1,
};

const contactAvatar = {
  marginRight: 10,
};

const contactNameStyle = {
  fontSize: 18,
};

export default memo(MiNewMessageDialog);
