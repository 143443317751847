import React, { useEffect, useState } from "react";
import {
  MiPageHeader,
  MiList,
  MiLink,
  MiButtonGroup,
  Chip,
  ImageViewer,
  MiMultiselectChip,
  MiIcon,
} from "@miview/components";
import { useComponentState } from "@miview/hooks";
import {
  WALK_RECORD_TYPES,
  HTTP_STATUSES,
  INSPECTIONPREP_STATUS_COLORS,
  INSPECTIONPREP_STATUS_CHIPS,
  UNASSIGNED,
  SCHEDULED,
} from "@miview/constants";
import { walkService, walkTypeService } from "@miview/api";
import { createFilter, combineFilters, createUUID } from "@miview/utils";
import { InspectionPopover } from "./InspectionPopover";
import { InspectionDatePicker } from "./InspectionDatePicker";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { InspectionPrepAssignees } from "./inspectionPrepAssignees";

import {
  mdiCalendarAccountOutline,
  mdiFormatListBulleted,
  mdiTag,
} from "@mdi/js";

const FilterBarWrapper = styled("div")(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    padding: "10px",
    backgroundColor: theme.palette.light.grey,
    marginBottom: 10,
  };
});

const EMPTY_FILTER = createFilter().getFilter();

export const InspectionPreps = () => {
  const [inspections, setInspections] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedStatusChip, setSelectedStatusChip] = useState([]);
  const [selectedPrepJobType, setSelectedPrepJobType] = useState("");
  const [walkTypes, setWalkTypes] = useState([]);
  const [date, setDate] = useState(moment());
  const stateManager = useComponentState();
  const uuid = createUUID();
  const theme = useTheme();

  useEffect(() => {
    getWalkTypes();
  }, []);

  const columns = [
    {
      field: "propertyStageId",
      headerName: "",
      headerAlign: "left",
      align: "left",
      width: 50,
      renderCell: (params) => renderPlanItemsIcon(params),
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => renderStatusChip(params.value),
    },
    {
      field: "addressLine1",
      headerName: "Address",
      headerAlign: "left",
      align: "left",
      flex: 1,
      valueFormatter: (params) => params.api.getRow(params.id).address,
      renderCell: (params) => {
        return (
          <MiLink
            to={`/homes/${params.row.propertyId}`}
            title={params.row.address}
          />
        );
      },
    },
    {
      field: "failedInspectionsCount",
      headerAlign: "center",
      width: 60,
      align: "center",
      renderCell: (params) => {
        if (params.value === 0) {
          return "";
        }

        return (
          <div style={{ color: theme.palette.primary.red, fontWeight: "bold" }}>
            {params.value}
          </div>
        );
      },
      renderHeader: () => (
        <MiIcon path={mdiTag} color={theme.palette.primary.red} />
      ),
    },
    {
      field: "communityName",
      headerName: "Community",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "cityName",
      headerName: "City",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "walkTypeName",
      headerName: "Type",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <MiLink
            to={`/walks/${params.id}`}
            title={`${params.value}${
              params.row.attemptNumber > 1
                ? ` ( #${params.row.attemptNumber} )`
                : ""
            }`}
          />
        );
      },
    },
    {
      field: "assignedUserFirstName",
      headerName: "Assignee",
      headerAlign: "left",
      align: "left",
      flex: 2,
      minWidth: 250,
      valueFormatter: (params) => params.api.getRow(params.id).assignedUserName,
      renderCell: ({ id, row }) => {
        return (
          <InspectionPrepAssignees
            id={id}
            render
            walk={row}
            handleSelectList={handleSelectList}
          />
        );
      },
    },
    {
      field: "builderName",
      headerName: "Builder",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "walkDueDate",
      headerName: "Date",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => moment(params.value).format("l"),
    },
  ];

  const updateWalk = (id, body) => {
    return stateManager.run(async () => {
      const response = await walkService.update(id, body, { success: null });
      if (response.status === HTTP_STATUSES.OK) {
        setRefresh(!refresh);
      }
    });
  };

  const getInspectionPreps = ({ params = {}, sort }) => {
    const startOfDayStr = moment(date).startOf("day").format();
    const endOfDayStr = moment(date).endOf("day").format();
    stateManager.abort(uuid);
    return stateManager.run(async (signal) => {
      const updatedParams = {
        ...params,
        expand: "Property",
      };

      const filterHelper = createFilter();
      filterHelper.equals({
        recordTypeId: WALK_RECORD_TYPES.INSPECTIONPREP,
        walkTypeId: selectedPrepJobType === "" ? [] : selectedPrepJobType,
        status: selectedStatusChip.reduce(
          (accu, curr) => [...accu, ...curr.value],
          []
        ),
      });
      filterHelper.inRange({
        walkDueDate: [startOfDayStr, endOfDayStr],
      });
      const filters = combineFilters(filterState, filterHelper.getFilter());
      return await walkService.getAll({
        params: updatedParams,
        filters,
        sort: sort,
        signal,
      });
    }, uuid);
  };

  const getWalkTypes = () => {
    return stateManager.run(async () => {
      const walkTypeFilter = createFilter();
      walkTypeFilter.equals({
        recordType: WALK_RECORD_TYPES.INSPECTIONPREP,
      });
      const response = await walkTypeService.getAll({
        filters: walkTypeFilter.getFilter(),
        sort: [
          { field: "stageOrder", sort: "asc" },
          { field: "displayOrder", sort: "asc" },
        ],
      });

      if (response.status === HTTP_STATUSES.OK) {
        const combinedWalkTypes = [
          { label: "All", value: "" },
          ...response.data.map((data) => {
            return {
              label: data.shortName,
              value: data.walkTypeId,
              pay: data.basePayAmount,
            };
          }),
        ];
        setWalkTypes(combinedWalkTypes);
      }
    });
  };

  const handleStatusChipSelectionChange = (item) => {
    setSelectedStatusChip(item);
    setRefresh((refreshVal) => !refreshVal);
  };

  const handleFilterChange = (filterCondition) => {
    setFilterState(filterCondition);
    setRefresh((refreshVal) => !refreshVal);
  };

  const handlePrepJobTypeChange = (item) => {
    item.value === selectedPrepJobType
      ? setSelectedPrepJobType("")
      : setSelectedPrepJobType(item.value);
    setRefresh((refreshVal) => !refreshVal);
  };

  const handleDatePickerChange = (item) => {
    setDate(item);
    setRefresh((refreshVal) => !refreshVal);
  };

  const handleSelectList = async (assignee, id) => {
    const prepJob = inspections.find((job) => job.id === id);
    stateManager.run(async () => {
      const response = await walkService.assignUserToWalk(
        id,
        {
          scheduledStartDate: prepJob.scheduledStartDate,
          assignedUserId: assignee ? assignee.userId : "",
        },
        {
          success: null,
        }
      );
      if (response.status === HTTP_STATUSES.OK) {
        if (prepJob.status === UNASSIGNED && assignee?.userId) {
          return updateWalk(id, { status: SCHEDULED });
        }
        if (!assignee?.userId) {
          return updateWalk(id, { status: UNASSIGNED });
        }
      }
    });
  };

  const renderPlanItemsIcon = (item) => {
    return (
      <InspectionPopover
        data={item}
        statusChip={renderStatusChip}
        setImages={(item) => setImages(item)}
        setSelectedImage={(item) => setSelectedImage(item)}
      />
    );
  };

  const renderStatusChip = (item) => {
    return (
      <Chip
        text={item}
        style={{
          display: "flex",
          width: "fit-content",
          minWidth: "90px",
          justifyContent: "center",
        }}
        color={INSPECTIONPREP_STATUS_COLORS[item]?.backgroundColor}
        textColor={INSPECTIONPREP_STATUS_COLORS[item]?.color}
      />
    );
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiCalendarAccountOutline}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const renderFilterBar = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <FilterBarWrapper>
          <InspectionDatePicker
            date={date}
            handleDateChange={handleDatePickerChange}
          />
          <MiMultiselectChip
            items={INSPECTIONPREP_STATUS_CHIPS}
            selectedItems={selectedStatusChip}
            size="small"
            type="CHECKBOX"
            activeColor={theme.palette.primary.main}
            handleChange={handleStatusChipSelectionChange}
          />
        </FilterBarWrapper>
        <MiButtonGroup
          data={walkTypes}
          selected={selectedPrepJobType}
          selectedBackgroundColor={theme.palette.primary.main}
          selectedFontColor={theme.palette.primary.white}
          UnSelectedBackgroundColor={theme.palette.primary.white}
          UnSelectedFontColor={theme.palette.primary.grey}
          style={{ padding: "0px 10px" }}
          handleClick={handlePrepJobTypeChange}
        />
      </div>
    );
  };

  return (
    <>
      <MiPageHeader
        title="Inspections"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        loading={stateManager.isBusy()}
      />
      <MiList
        data={inspections}
        setData={setInspections}
        dataProvider={getInspectionPreps}
        columns={columns}
        refresh={refresh}
        filterModel={filterState}
        filterBar={renderFilterBar}
        onFilterModelChange={handleFilterChange}
        disableRowGrouping={true}
        emptyListProps={{
          icon: mdiFormatListBulleted,
          text: "There are no records to display",
        }}
      />
      <ImageViewer
        images={images}
        selectedImage={selectedImage}
        onUnfullscreen={() => setSelectedImage("")}
        fullScreenStatus={selectedImage.length > 0}
      />
    </>
  );
};
