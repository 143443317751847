export const ROUTES = {
  AUTH: "Auth",
  LOGIN: "Login",
  WORK: "Work",
  WALKS: "Walks",
  EXTENSIONS: "Extensions",
  BONUS: "Bonus",
  DISPUTE: "Dispute",
  SCHEDULING: "Scheduling",
  EXTRAS: "Extras",
  DASHBOARDS: "Dashboards",
  MESSAGING: "Messaging",
  SUPPORT: "Support",
  HOMES: "Homes",
  SYNC: "Sync",
  PROFILE: "Profile",
  LOGOUT: "Logout",
  CONTACTS: "Contacts",
  JOBS: "Jobs",
  REVIEW: "Reviews",
  PAY: "Pay",
  MAIN: "Main",
  MENU: "Menu",
  DEFAULT: "Default",
  WORKMANAGE: "Manage Work",
  PERMISSIONS: "AppPermissions",
};

export const SCREENS = {
  EXTENSIONHISTORY: "ExtensionHistory",
  EXTENSIONREVIEW: "ExtensionReview",
  EXTENSIONREQUEST: "ExtensionRequest",
  SCHEDULE: "Schedule",
  SCHEDULEJOB: "Schedule Job",
  SCHEDULEJOBSUMMARY: "Schedule Summary",
  PROFILE: "Profile",
  EXTRAS: "Extras",
  REVIEW: "Review",
  EXTRASREVIEW: "Extra Review",
  WORK: "Work",
  WALKS: "Walks",
  BONUS: "Bonus",
  BONUSDETAILS: "Bonus Details",
  PAYDETAILS: "Pay Details",
  PAYSUMMARY: "Pay",
  BONUS_DISPUTES: "BonusDisputes",
  BONUS_DISPUTE_REVIEW: "BonusDisputeReview",
  HOMES: "Homes",
  HOMEDETAIL: "HomeDetail",
  LOGOUT: "Logout",
  SYNC: "Sync",
  METRICS: "Metrics",
  SUPPORT: "Support",
  CONVERSATIONS: "Conversations",
  MESSAGES: "Messages",
  WALKLIST: "WalkList",
  JOBLIST: "Job List",
  WALKDETAILS: "WalkDetails",
  JOBDETAILS: "Job",
  STARTJOB: "StartJob",
  TASK: "Task",
  EXTRADETAILS: "ExtraDetails",
  STACKS: "Stacks",
  EDITPUNCH: "EditPunch",
  BLUETOOTH: "Bluetooth",
  SUBMITREVIEW: "SubmitReview",
  SUBMITFINAL: "SubmitFinal",
  AUTH: "Auth",
  SIGNIN: "SignIn",
  PASSWORDRESET: "PasswordReset",
  IMAGEPICKER: "ImagePicker",
  CONTACT_LOGIN: "ContactLogin",
  CONFIRMPIN: "ConfirmPin",
  RESETPIN: "Reset PIN",
  PAYREVIEW: "PayReview",
  LOGIN: "Login",
  WORKMANAGE: "Manage Work",
  PERMISSIONS: "AppPermissions",
  UPDATEAPP: "UpdateApp",
};

export const NAVMAP = [
  { keyword: "work", drawer: ROUTES.WORK, initialScreen: SCREENS.WORK },
  {
    keyword: "walk",
    drawer: ROUTES.WALKS,
    initialScreen: SCREENS.WORK,
    detailScreen: SCREENS.WALKDETAILS,
  },
  {
    keyword: "message",
    drawer: ROUTES.CONVERSATIONS,
    initialScreen: SCREENS.CONVERSATIONS,
    detailScreen: SCREENS.MESSAGING,
  },
  {
    keyword: "home",
    drawer: ROUTES.HOMES,
    initialScreen: SCREENS.HOMES,
    detailScreen: SCREENS.HOMEDETAIL,
  },
  {
    keyword: "job",
    drawer: ROUTES.JOBS,
    initialScreen: SCREENS.WORK,
    detailScreen: SCREENS.JOBDETAILS,
  },
  {
    keyword: "message",
    drawer: ROUTES.MESSAGING,
    initialScreen: SCREENS.CONVERSATIONS,
    detailScreen: SCREENS.MESSAGES,
  },
];
