import React, { memo, Fragment } from "react";
import PropTypes from "prop-types";
import UndoIcon from "@mui/icons-material/Undo";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { THEME, MiMenuSystem } from "./MiView";
import { useAreaItemStyles } from "./MiAreaItemStyles";
import { useCdn } from "@miview/hooks";

const fields = [
  "measurementBackTypeId",
  "measurementBackValue",
  "measurementSideTypeId",
  "measurementSideValue",
  "toleranceSide",
  "toleranceBack",
  "cadImageX",
  "cadImageY",
];

const resolvedCheck = (details) => {
  if (fields.some((f) => !details[f])) {
    return THEME.ORANGE_PRIMARY;
  }
  return THEME.GREEN_PRIMARY;
};

const getBoxColor = (itemDetails) => {
  if (itemDetails?.isDeleted) {
    return THEME.RED_PRIMARY;
  }
  return resolvedCheck(itemDetails);
};

const MiStack = ({
  itemDetails = {},
  setEditingItem,
  areaId,
  walkTypes,
  deleteOrRestoreItem,
  setImagePreviewed,
  canEdit,
  canDelete,
  index,
  canReorder,
  setItemDragging,
  measurementTypesBack,
  measurementTypesSide,
  stackSizes,
}) => {
  const { classes } = useAreaItemStyles();
  const cdn = useCdn();

  const handleClickEdit = () => {
    if (canEdit && !itemDetails.isDeleted) {
      setEditingItem(itemDetails);
    }
  };

  const handleDeleteItem = () => {
    deleteOrRestoreItem({ itemDetails, isDeleted: true });
  };

  const handleRestoreItem = () => {
    deleteOrRestoreItem({ itemDetails, isRestored: true });
  };

  const onDragStart = () => {
    setItemDragging({ ...itemDetails, areaId, index });
  };

  const renderImage = (position) => {
    let imageSrc = itemDetails[`${position}ImageName`];

    const handleSetImagePreviewed = () => {
      setImagePreviewed(imageSrc);
    };

    if (itemDetails.materialId) {
      return <img src={`${cdn}${itemDetails.materialImageName}`} />;
    }

    if (imageSrc) {
      return (
        <img src={`${cdn}${imageSrc}`} onClick={handleSetImagePreviewed} />
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 18,
          }}
        >
          <CameraAltIcon className={classes.areaImageIcon} />
        </div>
      );
    }
  };

  const generateMenuOptions = () => {
    let options = [];

    if (canEdit) {
      options.push({
        name: "Edit",
        action: handleClickEdit,
      });
    }

    if (canDelete) {
      options.push({
        name: "Delete",
        action: handleDeleteItem,
      });
    }

    return options;
  };

  const renderJob = () => {
    if (walkTypes) {
      return (
        <p>
          {
            walkTypes.find((t) => t.walkTypeId === itemDetails.walkTypeId)
              ?.walkTypeName
          }
        </p>
      );
    }

    return null;
  };

  const renderIcons = () => {
    if (canEdit) {
      if (itemDetails.isDeleted) {
        return (
          <UndoIcon
            className={classes.areaItemRestoreIcon}
            onClick={handleRestoreItem}
          />
        );
      } else {
        return (
          <Fragment>
            <MiMenuSystem options={generateMenuOptions()} />
          </Fragment>
        );
      }
    }

    return null;
  };

  const getMeasurementTypeBack = (typeId) => {
    if (measurementTypesBack) {
      return measurementTypesBack.find((t) => t.value === typeId)?.text;
    }

    return null;
  };

  const getMeasurementTypeSide = (typeId) => {
    if (measurementTypesSide) {
      return measurementTypesSide.find((t) => t.value === typeId)?.text;
    }

    return null;
  };

  const getStackSize = (typeId) => {
    if (stackSizes) {
      return <p>Size: {stackSizes.find((t) => t.value === typeId)?.text}</p>;
    }

    return null;
  };

  const draggable = canReorder && itemDetails.itemId === "";

  return (
    <div className={classes.wrapper}>
      <div
        id={`DraggablePlanItem-${itemDetails?.itemId}`}
        className={classes.areaItemBox}
        style={{
          borderLeft: `12px solid ${getBoxColor(itemDetails)}`,
          minHeight: 100,
          cursor: canReorder ? "grab" : "auto",
        }}
        onDragStart={onDragStart}
        draggable={draggable}
        onDoubleClick={handleClickEdit}
      >
        <div className={classes.areaItemDetail}>
          <div className={classes.areaItemLabel}>
            <p
              style={
                itemDetails.isDeleted ? { textDecoration: "line-through" } : {}
              }
            >
              {itemDetails.itemName}
            </p>
          </div>
          <div className={classes.areaItemDescription}>
            <p>{itemDetails.description}</p>
          </div>
          <div className={classes.areaItemDescription}>{renderJob()}</div>
          <div className={classes.areaItemDescription}>
            <p>
              {getMeasurementTypeBack(itemDetails.measurementBackTypeId)}:{" "}
              {itemDetails.measurementBackValue}
            </p>
          </div>
          <div className={classes.areaItemDescription}>
            <p>
              {getMeasurementTypeSide(itemDetails.measurementSideTypeId)}:{" "}
              {itemDetails.measurementSideValue}
            </p>
          </div>
          <div className={classes.areaItemDescription}>
            {getStackSize(itemDetails.stackSizeId)}
          </div>
          {itemDetails.canBeNotApplicable ? (
            <div className={classes.areaItemDescription}>
              <p> Can Be Not Applicable</p>
            </div>
          ) : null}
        </div>
        <label
          htmlFor={`drawing-${itemDetails?.itemId || 0}`}
          className={classes.createDrawingLabel}
        >
          <div className={classes.areaItemImagesContainer}>
            {itemDetails.materialId ? (
              <div className={classes.areaItemImage}>{renderImage("top")}</div>
            ) : (
              <Fragment>
                <div>
                  <span className={classes.areaItemImageLabel}>Top</span>
                  <div className={classes.areaItemImage}>
                    {renderImage("top")}
                  </div>
                </div>
                <div>
                  <span className={classes.areaItemImageLabel}>Front</span>
                  <div className={classes.areaItemImage}>
                    {renderImage("front")}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </label>
        <div className={classes.areaItemEditIcon}>{renderIcons()}</div>
      </div>
    </div>
  );
};

MiStack.propTypes = {
  areaId: PropTypes.number,
  itemDetails: PropTypes.object,
  walkTypes: PropTypes.array,
  setEditingItem: PropTypes.func,
  deleteOrRestoreItem: PropTypes.func,
  setImagePreviewed: PropTypes.func,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
};

MiStack.defaultProps = {
  canDelete: true,
};

export default memo(MiStack);
