export const URLS = {
  DEV: "https://dev-api.miviewis.com",
  QA: "https://qa-api.miviewis.com",
  TRAINING: "https://beta-demo-api.miviewis.com",
};

export const URLS_KEY = {
  [URLS.DEV]: "DEV Environment",
  [URLS.QA]: "QA Environment",
  [URLS.TRAINING]: "Training",
};
