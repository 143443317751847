import { useState } from "react";
import { manufacturerService, materialCategoryService } from "@miview/api";

export const useMaterials = () => {
  const [materialBrands, setMaterialBrands] = useState([]);
  const [materialCategories, setMaterialCategories] = useState([]);
  const [materialsModalOpen, setMaterialsModalOpen] = useState(false);

  const handleGetMaterialBrands = async () => {
    const materialBrands = await manufacturerService.getAll();
    setMaterialBrands(materialBrands.data);
  };

  const handleGetMaterialCategories = async () => {
    const materialCategories = await materialCategoryService.getAll();
    setMaterialCategories(materialCategories.data);
  };

  return {
    materialBrands,
    setMaterialBrands,
    materialCategories,
    setMaterialCategories,
    materialsModalOpen,
    setMaterialsModalOpen,
    handleGetMaterialBrands,
    handleGetMaterialCategories,
  };
};
