import { apiOptions, apiRequest } from "../utils";
import { API_V3 } from "../constants";

export const permissionService = {
  getAll: async ({ signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/permissions`,
      method: "get",
      signal,
    });

    return apiRequest(options);
  },
};
