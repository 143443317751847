export const STATUS = {
  active: 1,
  inactive: 2,
  archived: 3,
  incomplete: 4,
  active2: 5,
  inActive2: 6,
  delete: 7,
  Active: 8,
  InActive: 9,
  cancel: 10,
  text: 11,
  cancel2: 12,
};

export const CONTACT_STATUS = {
  active: "Active",
  inactive: "Inactive",
  archived: "Archived",
  incomplete: "Incomplete",
};

export const ACCOUNT_TYPE = {
  SUPPLIER: "Supplier",
  MANUFACTURER: "Manufacturer",
};

export const HANDLE_SUBMIT = {
  createBranches: "createBranches",
  editBranches: "editBranches",
  createContacts: "createContacts",
  editContacts: "editContacts",
};

export const FILTER_CHIPS = [
  { status: "All POs" },
  { status: "Backorder" },
  { status: "Issued" },
  { status: "Received" },
  { status: "Cancelled" },
  { status: "Paid" },
  { status: "Open" },
];

export const PO_STATUS = {
  backorder: "Backorder",
  received: "Received",
  issued: "Issued",
  paid: "Paid",
  cancelled: "Cancelled",
};

export const STATUS_ENUM = {
  inactive: "inactive",
  active: "active",
  archive: "archive",
  incomplete: "incomplete",
  inactiveRow: "inactive-row",
};
export const STATUS_FILTER = ["Active", "Inactive", "Incomplete"];

export const CONTACT_FILTERS = {
  Status: "statusId",
  BranchName: "branchId",
  ContactType: "contactTypeId",
};
export const COSTBOOK_FILTERS = {
  Brand: "brandId",
  Expiring: "currentExpiryDate",
  categoryId: "categoryId",
};

export const API = {
  v1: "",
};

export const VENDOR_STATUS = {
  1: "active",
  2: "inactive",
};

export const REQUISITION_FILTERS = {
  SortbyDate: "sortByDate",
  Requestedby: "requestedBy",
  status: "status",
};

export const BOOL_VALUE = true;
