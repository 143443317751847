import { useState } from "react";
import { checklistHelpers } from "@miview/utils";
import {
  COMPLETE,
  HOLD,
  PENDING,
  INCOMPLETE,
  ABORTED,
} from "@miview/constants";

const ISSUE_STATUS_CHECK = [HOLD, INCOMPLETE];

export const useChecklist = () => {
  const [checklist, setChecklist] = useState([]);

  const formatChecklistDataForSectionList = (propertyPlanAreas) => {
    return propertyPlanAreas.map((area) => handleSetArea(area));
  };

  const getSectionStatus = (items) => {
    let status = COMPLETE;
    for (let item of items) {
      if (
        item.status === PENDING ||
        (!item.isAnswered && item.status !== COMPLETE)
      ) {
        status = PENDING;
        break;
      }
      if (ISSUE_STATUS_CHECK.includes(item.status)) {
        status = item.status;
      }
    }
    if (status !== COMPLETE) {
      const aborted = items.some(checklistHelpers.checkAbortOnly);
      if (aborted && status !== HOLD) {
        status = ABORTED;
      }
    }
    return status;
  };

  const handleSetChecklist = (arr) => {
    if (arr) {
      const newList = formatChecklistDataForSectionList(arr);
      setChecklist(newList);
    }
  };

  const updateChecklistArea = (area) => {
    const newList = [...checklist];
    const index = newList.findIndex(
      (arr) => arr.propertyPlanAreaId === area.propertyPlanAreaId
    );
    newList[index] = handleSetArea(area);
    setChecklist(newList);
  };

  const handleSetArea = (area) => {
    return {
      ...area,
      title: area.name,
      data: area.items,
      sectionStatus: getSectionStatus(area.items),
    };
  };

  const onStatusChange = (item) => {
    return checklistHelpers.setChecklistItem(checklist, item.id, item);
  };

  return {
    checklist,
    updateChecklistArea,
    handleSetChecklist,
    onStatusChange,
    getSectionStatus,
  };
};
