import React from "react";
import { makeStyles } from "tss-react/mui";
import Icon from "@mdi/react";
import { useTheme } from "@mui/material";

const useHeaderLogoStyles = makeStyles()((theme, { props }) => ({
  toplogo: {
    height: 40,
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: { width: 45 },
  },
  logo: {
    width: 45,
    [theme.breakpoints.down("sm")]: { width: 45 },
  },
  thumbnail: {
    backgroundColor: "white",
    padding: (props) => (props.open ? "0 3px" : "0"),
    marginRight: 5,
  },
  appTitle: {
    fontSize: props.fontSize || theme.fontSize.large,
    color: props.color || theme.palette.primary.main,
    fontWeight: props.fontWeight || theme.fontWeight.bold,
    fontFamily: props.fontFamily || "helvetica",
    [theme.breakpoints.down("sm")]: {
      display: props.isTopNav ? "none" : "block",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer",
  },
}));

export const HeaderLogo = ({ open, app, largeLogo, onClick, isTopNav }) => {
  const theme = useTheme();
  const { classes } = useHeaderLogoStyles({
    props: {
      open,
      isTopNav,
      color: app?.color,
      fontSize: app?.fontSize,
      fontWeight: app?.fontWeight,
      fontFamily: app?.fontFamily,
    },
  });

  return (
    <div onClick={onClick} className={classes.row}>
      <div className={classes.thumbnail}>
        {app?.logo && (
          <img
            alt=""
            src={app.logo}
            className={largeLogo ? classes.toplogo : classes.logo}
          />
        )}
        {app?.icon && (
          <Icon
            path={app.icon}
            size={1}
            color={app?.iconColor || theme.palette.primary}
          />
        )}
      </div>
      {open && !largeLogo && (
        <div className={classes.appTitle}>{app?.name}</div>
      )}
    </div>
  );
};
