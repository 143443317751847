import React, { useState } from "react";
import { userService } from "@miview/api";
import {
  MiModal,
  MiIcon,
  MiIconButton,
  MiDetailFields,
} from "@miview/components";
import { InputAdornment, useTheme } from "@mui/material";
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import { useEdit } from "@miview/hooks";
import { isPasswordLength } from "@miview/utils";

const UpdatePasswordModal = (props) => {
  const { onClose, open, isAdmin, userId } = props;

  const [showPassword, setShowPassword] = useState(false);

  const theme = useTheme();
  const edit = useEdit({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    notes: "",
  });

  const sendUpdatePassword = async () => {
    if (isAdmin) {
      await userService.updatePasswordAdmin(userId, {
        newPassword: edit.getValue("newPassword"),
        notes: edit.getValue("notes"),
      });
    } else {
      await userService.updatePassword({
        oldPassword: edit.getValue("oldPassword"),
        newPassword: edit.getValue("newPassword"),
      });
    }
    onClose();
  };

  const passwordErrorMsg = (password) => {
    if (!isPasswordLength(password)) {
      return "Password must have at least 6 characters.";
    }
    return null;
  };

  const handleError = (field) => {
    const value = edit.getValue(field);
    if (field === "newPassword" && value) {
      return passwordErrorMsg(value);
    }
  };

  const confirmPasswordErrorMessage =
    edit.getValue("newPassword") !== edit.getValue("confirmNewPassword")
      ? "Passwords don't match"
      : "";

  const EndAdornment = () => (
    <InputAdornment position="end">
      <MiIconButton
        onClick={() => setShowPassword((prev) => !prev)}
        onMouseDown={(e) => e.preventDefault()}
      >
        <MiIcon
          path={showPassword ? mdiEyeOutline : mdiEyeOffOutline}
          size={0.8}
        />
      </MiIconButton>
    </InputAdornment>
  );

  const detailFields = isAdmin
    ? [
        {
          label: "New Password",
          value: edit.getValue("newPassword"),
          fieldType: isAdmin && showPassword ? "text" : "password",
          required: true,
          placeholder: "New Password",
          setValue: (e) => edit.update({ newPassword: e }),
          width: "50%",
          error: handleError("newPassword"),
          helperText: handleError("newPassword"),
          InputProps: isAdmin && { endAdornment: <EndAdornment /> },
        },
        {
          label: "Notes",
          value: edit.getValue("notes"),
          fieldType: "text",
          required: false,
          placeholder: "Notes",
          setValue: (e) => edit.update({ notes: e }),
          width: "50%",
        },
      ]
    : [
        {
          label: "Old Password",
          value: edit.getValue("oldPassword"),
          fieldType: showPassword ? "text" : "password",
          required: true,
          placeholder: "Old Password",
          setValue: (e) => edit.update({ oldPassword: e }),
          width: "100%",
          InputProps: { endAdornment: <EndAdornment /> },
        },
        {
          label: "New Password",
          value: edit.getValue("newPassword"),
          fieldType: isAdmin && showPassword ? "text" : "password",
          required: true,
          placeholder: "New Password",
          setValue: (e) => edit.update({ newPassword: e }),
          width: "50%",
          error: handleError("newPassword"),
          helperText: handleError("newPassword"),
          InputProps: isAdmin && { endAdornment: <EndAdornment /> },
        },
        {
          label: "Confirm New Password",
          value: edit.getValue("confirmNewPassword"),
          fieldType: "password",
          required: true,
          placeholder: "Confirm New Password",
          setValue: (e) => edit.update({ confirmNewPassword: e }),
          width: "50%",
          error: confirmPasswordErrorMessage,
          helperText: confirmPasswordErrorMessage,
        },
      ];

  const actions = [
    {
      onClick: onClose,
      color: theme.palette.primary.grey,
      name: "Close",
      inverse: false,
    },
    {
      onClick: sendUpdatePassword,
      color: theme.palette.primary.main,
      name: "Submit",
      inverse: true,
      disabled:
        !edit.allFilled("oldPassword", "confirmNewPassword") && !isAdmin,
    },
  ];

  return (
    <MiModal
      title="Update Password"
      open={open}
      isCloseButton={false}
      actions={actions}
    >
      <MiDetailFields detailFields={detailFields} />
    </MiModal>
  );
};

export default UpdatePasswordModal;
