import React from "react";
import { MiButton } from "@miview/components";
import { Typography, IconButton, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { Close as CloseIcon } from "@mui/icons-material";
import { TemplateStatus } from "../../../../components/Status";
import { mdiBlockHelper } from "@mdi/js";

const useStyles = makeStyles()((theme) => ({
  root: {
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.medium,
  },
  reqHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
  },
  reqHeaderFlex: {
    display: "flex",
    alignItems: "center",
  },
  closeDrawerIcon: {
    width: 40,
    height: 40,
    padding: 0,
    fontSize: 25,
    margin: 0,
    color: theme.palette.darkLight.grey,
  },
  iconBox: {
    width: 50,
    height: 50,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 20,
    color: theme.palette.primary.white,
  },
  reqId: {
    background: theme.palette.lightAccent.grey,
    fontSize: theme.fontSize.xsmall,
    padding: "4px 10px",
    borderRadius: "3px",
    color: theme.palette.secondary.bluegrey,
    marginLeft: 20,
  },

  footerAction: {
    width: "100%",
    position: "fixed",
    left: 0,
    bottom: 0,
    zIndex: 1,
    background: "rgba(0, 0, 0, 0.6)",
    minHeight: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
  },

  footerFlex: {
    display: "flex",
    alignItems: "center",
  },

  filterBoxStyle: {
    display: "inline-block",
    width: "100%",
    padding: "20px",
    background: theme.palette.lightShade.grey,
    margin: "5px 0",
    borderRadius: "4px",
  },
  headingFour: {
    fontSize: theme.fontSize.headerSmall,
    color: theme.palette.secondary.bluegrey,
    margin: 0,
    fontWeight: theme.fontWeight.medium,
  },
}));

export const HeadingTwentyFour = ({ text }) => {
  const { classes } = useStyles();
  return (
    <Typography component="h2" className={classes.headingFour}>
      {text}
    </Typography>
  );
};

const RequisitionComponents = ({ active, value }) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.root, !active && classes.inactive)}>{value}</div>
  );
};

export const RequisitionHeader = ({ CloseDrawer, Title, text, Id, Status }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.reqHeader}>
      <div className={classes.reqHeaderFlex}>
        <div className={classes.iconBox}></div>
        <HeadingTwentyFour text={Title} />
        <Typography component="h2" className={classes.headingFour}>
          {text}
        </Typography>
        <div className={classes.reqId}>{Id}</div>
      </div>
      <TemplateStatus value={Status} />
      <IconButton
        onClick={CloseDrawer}
        className={classes.closeDrawerIcon}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export const TemplateHeader = ({ closeDrawer, title }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.reqHeader}>
      <div className={classes.reqHeaderFlex}>
        <div className={classes.iconBox}>
          <ListAltOutlinedIcon />
        </div>
        <HeadingTwentyFour text={title} />
      </div>
      <IconButton
        onClick={closeDrawer}
        className={classes.closeDrawerIcon}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export const RequisitionFooter = ({
  templateData,
  handleDiscard,
  handleSave,
  handleDelete,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.footerAction}>
      <div className={classes.footerFlex}>
        <MiButton
          style={{
            width: 150,
            height: 45,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: theme.palette.primary.red,
            color: theme.palette.primary.white,
            fontWeight: theme.fontWeight.medium,
            borderRadius: "4px",
          }}
          title="Discard"
          onClick={handleDiscard}
        />
        {templateData?.id && (
          <MiButton
            style={{
              width: 150,
              height: 45,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: theme.palette.primary.red,
              color: theme.palette.primary.white,
              fontWeight: theme.fontWeight.medium,
              borderRadius: "4px",
            }}
            title="Delete"
            onClick={handleDelete}
          />
        )}
      </div>
      <div className={classes.footerFlex}>
        <MiButton
          style={{
            width: 150,
            height: 45,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: theme.palette.secondary.green,
            color: theme.palette.primary.white,
            fontWeight: theme.fontWeight.medium,
            borderRadius: "4px",
          }}
          title="Save Template"
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export const SelectedFilterBoxLayout = ({ children }) => {
  const { classes } = useStyles();
  return <div className={classes.filterBoxStyle}>{children}</div>;
};

export const FloatingVoid = ({ onClick }) => {
  const theme = useTheme();
  return (
    <MiButton
      title="Void"
      inverse="true"
      icon={mdiBlockHelper}
      color={theme.palette.secondary.red}
      style={{
        height: 45,
        minWidth: 140,
        fontSize: theme.fontSize.medium,
        fontWeight: theme.fontWeight.medium,
        borderRadius: 35,
        position: "fixed",
        right: 20,
        bottom: 20,
        zIndex: 8,
      }}
      onClick={onClick}
    />
  );
};

export default RequisitionComponents;
