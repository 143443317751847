class ActionHelper {
  groupBy = (array, field) => {
    return array.reduce(function (obj, item) {
      var key = typeof field === "function" ? field(item) : item[field];

      // eslint-disable-next-line
      if (!obj.hasOwnProperty(key)) {
        obj[key] = [];
      }

      obj[key].push(item);
      return obj;
    }, {});
  };

  sectionMap = (array, field) => {
    let newArr = this.groupBy(array, (item) => item[field]);
    let sections = Object.entries(newArr).map(([title, data]) => ({
      title,
      data,
    }));
    return sections;
  };

  sortData = (data, field, sortDirection) => {
    var reverse = sortDirection !== "asc";
    data.sort(this.sortBy(field, reverse));
    return data;
  };

  simpleSort = (a, b) => (a > b) - (b > a);

  sortBy = (field, reverse, primer) => {
    var key = primer
      ? function (x) {
          return primer(x[field]);
        }
      : function (x) {
          return x[field];
        };
    reverse = !reverse ? 1 : -1;
    return function (a, b) {
      return (
        (a = key(a) ? key(a) : ""),
        (b = key(b) ? key(b) : ""),
        reverse * ((a > b) - (b > a))
      );
    };
  };

  replaceAllUrl = (url, mapObj) => {
    var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
    // constants.ROOT_URL +
    return url.replace(re, function (matched) {
      return mapObj[matched];
    });
  };

  isEmpty = (obj) => {
    for (var prop in obj) {
      // eslint-disable-next-line
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  };

  assert = (value, message) => {
    if (!value) {
      throw message;
    }
  };

  convertDate = (date) => {
    if (date) {
      return date.toISOString();
    }
  };

  converToBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  handleSearch = (data, search) => {
    if (search === "" || search == null) {
      return data;
    }
    const filterByValue = (array, string) =>
      array.filter((values) =>
        Object.keys(values).some((item) =>
          values[item]?.toString().toLowerCase().includes(string.toLowerCase())
        )
      );
    let filtered = filterByValue(data, search);
    return filtered;
  };

  isObjectEmpty = (obj) =>
    Object.values(obj).every((x) => x === null || x === "");

  getUniqueOptions = (data, fieldName, includeEmpty, emptyText) => {
    return data
      ? [...new Set(data.map((w) => w[fieldName]))]
          .sort((a, b) => {
            if (a < b) {
              return -1;
            } else if (a > b) {
              return 1;
            }
            return 0;
          })
          //.map(v => {return {value:v, text:v};})
          .filter((v) => v?.length || includeEmpty)
          .map((v) => {
            return { value: v || emptyText, text: v || emptyText };
          })
      : [];
  };
}

const actions = new ActionHelper();
export default actions;
