import {
  RED,
  PINK,
  BLUE,
  TEAL,
  GREEN,
  GREY,
  ORANGE,
  BLUEGREY,
  LIGHTBLUE,
  WHITE,
  PURPLE,
  INDIGO,
  AMBER,
  YELLOW,
  BLACK,
  DEEPPURPLE,
} from "../defaultColors";

export const palette = {
  primary: {
    main: BLUE[500],
    bluegrey: BLUEGREY[800],
    blue: BLUE[500],
    grey: GREY[700],
    orange: ORANGE[700],
    green: GREEN[800],
    teal: TEAL[600],
    red: RED[900],
    pink: PINK[500],
    lightblue: LIGHTBLUE[800],
    white: WHITE,
    black: BLACK,
    purple: PURPLE[900],
    indigo: INDIGO["a200"],
    overlay: "rgba(0, 0, 0, 0.5)",
    amber: AMBER[500],
    light: BLUE[50],
  },
  secondary: {
    main: BLUEGREY[800],
    bluegrey: BLUEGREY[900],
    blue: BLUE[700],
    grey: GREY[500],
    green: GREEN[500],
    teal: TEAL[300],
    red: RED[500],
    lightblue: LIGHTBLUE["a50"],
    purple: PURPLE[600],
    overlay: "rgba(0, 0, 0, 0.2)",
  },
  accent: {
    main: GREY[500],
    bluegrey: BLUEGREY[500],
    blue: BLUE["a700"],
    grey: GREY[300],
    green: GREEN["a700"],
    red: RED["a700"],
    purple: PURPLE["a400"],
  },
  light: {
    main: BLUE[50],
    bluegrey: BLUEGREY[50],
    blue: BLUE[50],
    grey: GREY[50],
    green: GREEN[50],
    teal: TEAL[50],
    red: RED[50],
    orange: ORANGE[50],
    pink: PINK[200],
    purple: PURPLE[50],
    indigo: INDIGO[50],
    overlay: "rgba(255, 255, 255, 0.2)",
    amber: AMBER[50],
  },
  dark: {
    bluegrey: BLUEGREY[700],
    blue: BLUE[900],
    grey: GREY[900],
    teal: TEAL[900],
  },
  medium: {
    bluegrey: BLUEGREY[600],
    blue: BLUE[600],
    grey: GREY[400],
    green: GREEN[600],
    teal: TEAL[700],
    red: RED[700],
    purple: DEEPPURPLE[300],
    yellow: YELLOW[500],
  },
  mediumAccent: {
    bluegrey: BLUEGREY["a500"],
    blue: BLUE[800],
  },
  lightAccent: {
    bluegrey: BLUEGREY[300],
    grey: GREY[200],
  },
  mediumLight: {
    bluegrey: BLUEGREY[200],
    grey: GREY[100],
  },
  lightShade: {
    bluegrey: BLUEGREY[100],
    grey: GREY[100],
  },
  mediumShade: {
    bluegrey: BLUEGREY[400],
  },
  darkAccent: {
    blue: BLUE["a900"],
    grey: GREY[800],
    teal: TEAL[800],
    red: RED["a900"],
  },
  primaryShade: {
    blue: BLUE["a500"],
  },
  mediumDark: {
    grey: GREY[600],
  },
};
