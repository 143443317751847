import React from "react";
import { MiButton } from "@miview/components";
import { Typography, Box, Chip, useTheme, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { mdiClose } from "@mdi/js";

const useStyles = makeStyles()((theme) => ({
  selectedRqPanel: {
    minHeight: "calc(100% - 18px)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    margin: 8,
    background: theme.palette.primary.white,
    borderRadius: "0px 0px 5px 4px",
  },
  selectedRqHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.primary.teal,
    height: 44,
    color: theme.palette.primary.white,
    padding: "0px 20px",
    "& h3": {
      fontSize: theme.fontSize.large,
      fontWeight: theme.fontWeight.medium,
    },
  },

  selectedRqHeaderFilter: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.light.teal,
    textAlign: "center",
    padding: "5px",
    minHeight: 52,
  },

  chipStyle: {
    margin: "0 5px",
    borderRadius: "4px",
    width: "100%",
    background: theme.palette.lightAccent.grey,
    color: theme.palette.lightAccent.bluegrey,
    fontSize: theme.fontSize.small,
    "&[role='button']": {
      background: theme.palette.primary.teal,
      color: theme.palette.primary.white,
    },
  },

  cartItemList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px 5px 20px",
    borderTop: `1px solid ${theme.palette.accent.grey}`,
  },
  id: {
    color: theme.palette.accent.bluegrey,
    fontSize: theme.fontSize.medium,
    width: 70,
    overflow: "hidden",
  },
  desc: {
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.medium,
    whiteSpace: "normal",
    width: "100%",
    maxWidth: "150px",
    paddingLeft: 15,
    overflow: "hidden",
  },
}));

const CartComponents = ({ children }) => {
  const { classes } = useStyles();
  return <Box className={classes.selectedRqPanel}>{children}</Box>;
};

export const CartHeader = ({ title, count, closeClick }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Box className={classes.selectedRqHeader}>
      <Typography component="h3">
        {title} <span>{"(" + count + ")"}</span>
      </Typography>
      <MiButton
        color={theme.palette.primary.white}
        icon={mdiClose}
        onClick={closeClick}
        style={{
          margin: 0,
          minWidth: "auto",
          padding: 0,
        }}
      />
    </Box>
  );
};

export const CartHeaderFilter = ({ filterArray = [] }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.selectedRqHeaderFilter}>
      {filterArray?.map((item, index) => (
        <Chip
          key={index}
          clickable={item.clickable}
          className={classes.chipStyle}
          label={item.title}
          onClick={item.click}
        />
      ))}
    </Box>
  );
};

export const CartHeaderButtons = ({ FilterArray }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Grid className={classes.selectedRqHeaderFilter}>
      {FilterArray?.map((item) => (
        <MiButton
          color={item.color || theme.palette.primary.white}
          backgroundColor={item.backgroundColor || theme.palette.secondary.teal}
          key={item.name}
          title={item.title}
          onClick={item.click}
          style={item.style}
          disabled={!item.clickable}
        />
      ))}
    </Grid>
  );
};

export const CartItemList = ({ id, removeItem, description }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Box className={classes.cartItemList}>
      <Typography component="div" className={classes.id}>
        {id}
      </Typography>
      <Typography component="div" className={classes.desc}>
        {description}
      </Typography>
      <MiButton
        color={theme.palette.secondary.red}
        icon={mdiClose}
        onClick={removeItem}
        style={{
          margin: 0,
          width: 30,
          height: 30,
        }}
      />
    </Box>
  );
};

export default CartComponents;
