import React, { useState } from "react";
import { useRouter, useEdit } from "@miview/hooks";
import { planService, systemTypeService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

const DETAILS_FORM_INITIAL_STATE = {
  name: "",
  builderId: "",
  communtyId: "",
  numberOfFloors: "",
  isTemplate: false,
};

const INITIAL_STATE = {
  communities: [],
  setCommunities: () => null,
  builders: [],
  setBuilders: () => null,
  detailsForm: DETAILS_FORM_INITIAL_STATE,
  plan: {},
  setDetailsForm: () => null,
  handleSaveDetails: () => null,
  handleOnChangeDetails: () => null,
  floors: [
    { text: 1, value: 204 },
    { text: 2, value: 205 },
    { text: 3, value: 206 },
  ],
  measurementTypesBack: [],
  measurementTypesSide: [],
  stackSizes: [],
};

export const PlanContext = React.createContext({
  ...INITIAL_STATE,
});

const handleGetPlan = async ({ planId, setPlan, edit }) => {
  const plan = await planService.get(planId);

  if (plan.status === HTTP_STATUSES.OK) {
    setPlan(plan.data);
    edit.update({ ...plan.data });
  }
};

const handleGetSystemTypesByName = async ({ name, setState }) => {
  const response = await systemTypeService.getSystemTypesByName({ name: name });
  if (response.status === HTTP_STATUSES.OK) {
    const mappedList = response.data.map((b) => {
      return { value: b.systemTypeId, text: b.mainValue };
    });
    setState(mappedList);
  }
};

const handleGetStackSizes = ({ setStackSizes }) => {
  handleGetSystemTypesByName({ name: "StackSize", setState: setStackSizes });
};

const handleGetStackMeasurementTypes = async ({
  setMeasurementTypesBack,
  setMeasurementTypesSide,
}) => {
  await handleGetSystemTypesByName({
    name: "MeasureReferenceBack",
    setState: setMeasurementTypesBack,
  });
  await handleGetSystemTypesByName({
    name: "MeasureReferenceSide",
    setState: setMeasurementTypesSide,
  });
};

export const usePlan = () => {
  const router = useRouter();
  const { planId } = router.params;
  const [communities, setCommunities] = useState(INITIAL_STATE.communities);
  const [builders, setBuilders] = useState(INITIAL_STATE.builders);
  const [plan, setPlan] = useState(INITIAL_STATE.plan);
  const [measurementTypesBack, setMeasurementTypesBack] = useState(
    INITIAL_STATE.measurementTypesBack
  );
  const [measurementTypesSide, setMeasurementTypesSide] = useState(
    INITIAL_STATE.measurementTypesSide
  );
  const [stackSizes, setStackSizes] = useState(INITIAL_STATE.stackSizes);

  const floors = INITIAL_STATE.floors;

  const edit = useEdit(DETAILS_FORM_INITIAL_STATE);

  const handleSavePlan = async () => {
    return planService.update(planId, { ...edit.edits });
  };

  const handleOnChangePlan = (e) => {
    edit.update({ [e.target.name]: e.target.value });
  };

  return {
    communities,
    setCommunities,
    builders,
    setBuilders,
    floors,
    plan,
    setPlan,
    measurementTypesBack,
    setMeasurementTypesBack,
    measurementTypesSide,
    setMeasurementTypesSide,
    stackSizes,
    setStackSizes,
    planEdit: edit,
    handleGetPlan,
    handleGetStackSizes,
    handleGetStackMeasurementTypes,
    handleOnChangePlan,
    handleSavePlan,
  };
};
