import React from "react";
import { MiTab, MiBox } from "@miview/components";

const MiDetailsSideBar = (props) => {
  const { sidebarTitle } = props;

  return (
    <MiBox styles={{ width: "30%" }}>
      <div
        style={{
          width: "100%",
          marginBottom: 20,
          paddingTop: 5,
        }}
      >
        <MiTab
          tabs={[{ value: sidebarTitle, label: sidebarTitle }]}
          currentTab={sidebarTitle}
        />
      </div>
      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        {props.children}
      </div>
    </MiBox>
  );
};

export default MiDetailsSideBar;
