import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const payPeriodService = {
  getAll: async ({ payScheduleId, params, sort, filters, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/schedules/${payScheduleId}/payperiods`,
      method: "get",
      params: { ...params, pageSize: 9999 },
      sort,
      filters,
      signal,
    });
    return await apiRequest(options);
  },
  create: async (request, payScheduleId) => {
    const options = await apiOptions({
      url: `${API_V3}/schedules/${payScheduleId}/payperiods`,
      method: "post",
      data: request,
    });
    return await apiRequest(options);
  },
  update: async (id, request, payScheduleId) => {
    const options = await apiOptions({
      url: `${API_V3}/schedules/${payScheduleId}/payperiods/${id}`,
      method: "patch",
      data: request,
    });
    return await apiRequest(options);
  },
  delete: async (id, payScheduleId) => {
    const options = await apiOptions({
      url: `${API_V3}/schedules/${payScheduleId}/payperiods/${id}`,
      method: "delete",
    });
    return await apiRequest(options);
  },
  getNextPayPeriod: async (payScheduleId) => {
    const options = await apiOptions({
      url: `${API_V3}/schedules/${payScheduleId}/payperiods/next`,
      method: "get",
    });
    return await apiRequest(options);
  },
};
