import React from "react";
import { MiTHEME } from "@miview/theme";
import { styled, Typography } from "@mui/material";
import { StyledColumn } from "../StyledComponents/StyledColumn";
import { StyledRow } from "../StyledComponents/StyledRow";
import { MiIcon } from "../MiIcon/MiIcon";

const MuiCard = styled("div")(({ style = {}, onClick, border }) => ({
  width: "100%",
  display: "flex",
  flex: 1,
  position: "relative",
  padding: 8,
  backgroundColor: "#FFF",
  flexDirection: "row",
  alignItems: "center",
  borderRadius: ".25rem",
  cursor: onClick ? "pointer" : "default",
  border: border || `1px solid ${MiTHEME.palette.accent.grey}`,
  ...style,
}));

const ListText = styled("div")(() => ({
  display: "flex",
  color: MiTHEME.palette.darkAccent.grey,
  fontSize: ".75rem",
  overflowWrap: "anywhere",
  paddingRight: "1rem",
}));

const IconLabel = ({ item }) => {
  if (!item.value) {
    return null;
  }
  if (item.renderIcon) {
    return item.renderIcon(item);
  }
  return (
    <div
      style={{
        marginRight: ".3rem",
      }}
    >
      {item.icon && (
        <MiIcon
          color={MiTHEME.palette.medium.grey}
          size={item.iconSize || 0.3}
          path={item.icon}
        />
      )}
      {item.label}
    </div>
  );
};

export const MiCard = (props) => {
  const {
    onClick,
    color,
    icon,
    title,
    fieldset,
    border,
    rightArea,
    titleStyle,
    iconProps,
    renderIcon,
    orientation = "vertical",
    muiCardContainer,
    ...rest
  } = props;

  const getIcon = () => {
    if (renderIcon) {
      return renderIcon();
    }
    if (icon) {
      return (
        <div style={{ marginRight: ".5rem", flex: 0, width: "4rem" }}>
          <div
            style={{
              display: "flex",
              width: "2.5rem",
              height: "2.5rem",
              borderRadius: "50%",
              backgroundColor: color,
              alignItems: "center",
              justifyContent: "center",
              ...iconProps,
            }}
          >
            <MiIcon
              path={icon}
              size={0.9}
              color={color ? "white" : MiTHEME.palette.medium.grey}
              {...iconProps}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div data-testid="MiCard">
      <MuiCard
        {...rest}
        onClick={onClick}
        className={muiCardContainer}
        border={border}
      >
        {getIcon()}
        <StyledColumn>
          <Typography
            style={{
              color: color,
              fontSize: "0.875rem",
              fontWeight: 300,
              width: "90%",
              ...titleStyle,
            }}
          >
            {title}
          </Typography>
          {fieldset && (
            <StyledRow
              onClick={onClick}
              orientation={orientation}
              style={{ marginBottom: 5 }}
            >
              {fieldset.map((item, index) => {
                const elementId = item.label
                  ? item.label.replace(/[^A-z]+/g, "") + "ItemValue"
                  : "";
                return (
                  <ListText
                    key={"key_" + index}
                    id={elementId + index}
                    className={elementId}
                    onClick={item.onClick}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        color: item.color,
                      }}
                    >
                      <IconLabel item={item} />
                      {item.value}
                    </div>
                  </ListText>
                );
              })}
            </StyledRow>
          )}
          <div>{props.footer}</div>
        </StyledColumn>
        {props.children}
        {rightArea}
      </MuiCard>
    </div>
  );
};
