import React from "react";
import { Drawer } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  drawerPaper: {
    width: "100%",
    maxWidth: "100%",
    paddingBottom: 70,
  },
}));

const DrawerLayout = ({ open, onCancel, children }) => {
  const { classes } = useStyles();
  return (
      <Drawer
        anchor="right"
        open={open}
        onClose={onCancel}
        classes={{ paper: classes.drawerPaper }}
      >
      {children}
    </Drawer>
  );
};

export default React.memo(DrawerLayout);
