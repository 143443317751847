import React, { useEffect, useState } from "react";
import { MiHeader } from "../../../components/Standard/MiView";
import { StageWalkTypeCreateModal } from "..";
import { useTheme } from "@mui/material";
import { createToast } from "@miview/toast";
import { createFilter, combineFilters } from "@miview/utils";
import {
  useRouter,
  useEdit,
  usePagination,
  useFilter,
  useDebounce,
} from "@miview/hooks";
import {
  MiLink,
  MiSnackbar,
  MiTab,
  MiTable,
  MiModal,
  MiDetailFields,
  MiButton,
  MiBox,
  MiList,
  MiIcon,
  MiModalConfirm,
} from "@miview/components";
import {
  UI_TAB_LABELS,
  TOAST_TYPES,
  HTTP_STATUSES,
  PAGINATION,
} from "@miview/constants";
import {
  stageTypeService,
  walkTypeService,
  stageSummaryService,
} from "@miview/api";
import { mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";

const EMPTY_FILTER = createFilter().getFilter();

const tabs = [
  { value: UI_TAB_LABELS.DETAILS, label: "Details" },
  { value: UI_TAB_LABELS.ITEMS, label: "Walk Types" },
  { value: UI_TAB_LABELS.STAGE_SUMMARY_OPTIONS, label: "Summary Options" },
];

const INITIAL_VALUE = {
  stageSummaryOrder: "",
  stageSummaryDescription: "",
  stageSummaryName: "",
};

const STAGETYPE_INITIAL_VALUE = {
  name: "",
  order: "",
  description: "",
  abbreviation: "",
  targetCycle1F: "",
  targetCycle2F: "",
  scheduleNextStage: "",
};

const StageManagementDetails = () => {
  const {
    push,
    query: { id: stageId },
    goBack,
  } = useRouter();

  //main data
  const [stageType, setStageType] = useState({});
  const [walkTypes, setWalkTypes] = useState([]);
  const [stageTypeEdits, setStageTypeEdits] = useState(STAGETYPE_INITIAL_VALUE);
  const [stageSummaries, setStageSummaries] = useState([]);
  const [editSelection, setEditSelection] = useState(INITIAL_VALUE);
  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  //new item fields
  const [itemId, setItemId] = useState();
  const [itemName, setItemName] = useState();
  //ux state management
  const [activeTab, setActiveTab] = useState(UI_TAB_LABELS.DETAILS);
  const [editMode, setEditMode] = useState(false);
  const [addWalkTypeModal, setAddWalkTypeModal] = useState(false);
  const [isDeleteWalkTypeModalopen, setIsDeleteWalkTypeModalOpen] =
    useState(false);
  const [isAddSummaryOptionModalOpen, setIsSummaryOptionModalOpen] =
    useState(false);
  const [isEditSummaryOptionModalOpen, setIsEditSummaryOptionModalOpen] =
    useState(false);
  const [isDeleteSummaryOptionModalOpen, setIsDeleteSummaryOptionModalOpen] =
    useState(false);

  const theme = useTheme();
  const edit = useEdit(editSelection);
  const editStageTypes = useEdit(stageTypeEdits);
  const debounceFilters = useDebounce(filterState, 400);
  const exportFilter = useFilter({
    initialFilter: createFilter().equals({
      stageTypeId: stageId,
    }),
  });

  const getWalkTypesTab = async () => {
    const params = {
      includeTotal: true,
      pageOffset: page,
      pageSize: pageSize,
    };
    const combinedFilters = combineFilters(
      filterState,
      exportFilter.getFilter()
    );
    return await walkTypeService.getWalkTypes(params, combinedFilters, sort);
  };

  const handleSetWalkTypes = (data) => {
    setWalkTypes(
      data.map((item) => {
        return { ...item, id: item.walkTypeId };
      })
    );
  };

  const {
    page,
    pageSize,
    rowCount,
    pageCount,
    getPageData,
    onPageSizeChange,
    onPageChange,
    onSortChange,
    sort,
  } = usePagination({
    setData: handleSetWalkTypes,
    getPageData: getWalkTypesTab,
    mode: PAGINATION.SERVER,
  });

  useEffect(() => {
    if (activeTab === UI_TAB_LABELS.DETAILS) {
      getStageTypesTab();
    }
    if (activeTab === UI_TAB_LABELS.STAGE_SUMMARY_OPTIONS) {
      getSummaryOptionsTab();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === UI_TAB_LABELS.ITEMS) {
      getPageData();
    }
  }, [activeTab, exportFilter.debounce, debounceFilters, sort]);

  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  const getStageTypesTab = async () => {
    const response = await stageTypeService.get({ id: stageId });
    if (response.status === HTTP_STATUSES.OK) {
      setStageType(response.data);
    }
  };

  const getSummaryOptionsTab = async () => {
    const response = await stageSummaryService.getAll({
      params: { orderBy: "StageSummaryDescription" },
      filters: exportFilter.getFilter(),
    });
    if (response.status === HTTP_STATUSES.OK) {
      setStageSummaries(
        response.data.map((item) => {
          return { ...item, id: item.stageSummaryId };
        })
      );
    }
  };

  const toggleSummaryOptionAddModal = () => {
    setIsSummaryOptionModalOpen(!isAddSummaryOptionModalOpen);
    edit.reset();
    setEditSelection(INITIAL_VALUE);
  };

  const closeSummaryOptionEditModal = () => {
    setIsEditSummaryOptionModalOpen(false);
    setEditSelection({});
    edit.reset();
  };

  const openSummaryOptionEditModal = (obj) => {
    setIsEditSummaryOptionModalOpen(true);
    setEditSelection(obj);
  };

  const closeSummaryOptionDeleteModal = () => {
    setIsDeleteSummaryOptionModalOpen(false);
    setEditSelection({});
  };

  const openSummaryOptionDeleteModal = (obj) => {
    setIsDeleteSummaryOptionModalOpen(true);
    setEditSelection(obj);
  };

  const addSummaryOption = async () => {
    const data = { ...edit.edits, stageTypeId: stageType.id };
    const response = await stageSummaryService.create(data);
    if (response.status === HTTP_STATUSES.OK) {
      createToast("Successful", TOAST_TYPES.SUCCESS);
      getSummaryOptionsTab();
      toggleSummaryOptionAddModal();
    }
  };

  const editSummaryOption = async () => {
    const data = { ...edit.edits, stageTypeId: stageType.id };
    const response = await stageSummaryService.update(
      editSelection.stageSummaryId,
      data
    );
    if (response.status === HTTP_STATUSES.OK) {
      createToast("Successful", TOAST_TYPES.SUCCESS);
      getSummaryOptionsTab();
      closeSummaryOptionEditModal();
    }
  };

  const deleteSummaryOption = async () => {
    const response = await stageSummaryService.delete(
      editSelection.stageSummaryId
    );
    if (response.status === HTTP_STATUSES.OK) {
      createToast("Summary Option Deleted Successfully", TOAST_TYPES.SUCCESS);
      getSummaryOptionsTab();
      closeSummaryOptionDeleteModal();
    }
  };

  const toggleDelete = (row) => {
    if (row) {
      setItemId(row.walkTypeId);
      setItemName(row.walkTypeName);
    } else {
      setItemId({});
      setItemName({});
    }
    setIsDeleteWalkTypeModalOpen(!isDeleteWalkTypeModalopen);
  };

  const deleteWalkTypeItem = async () => {
    const response = await walkTypeService.deleteWalkTypes(itemId);
    if (response.status !== HTTP_STATUSES.OK) {
      return;
    }
    toggleDelete();
    getPageData();
  };

  const openWalkTypeDetail = (cell) => {
    if (cell === "new") {
      setAddWalkTypeModal(true);
    } else {
      push(`/stagetypes/${stageType.id}/walktypes/${cell.row.walkTypeId}`);
    }
  };

  const cellFunction = (params) => {
    return (
      <MiLink onClick={() => openWalkTypeDetail(params)} title={params.value} />
    );
  };

  const renderCheckIcon = (params) => {
    return params.value && <i className="material-icons">check</i>;
  };

  const columns = [
    {
      field: "walkTypeName",
      headerName: "Walk Type",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
      renderCell: cellFunction,
    },
    {
      field: "recordTypeValue",
      headerName: "Record Type",
      headerAlign: "left",
      align: "left",
      flex: 0.8,
      sortable: true,
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
    },
    {
      field: "scheduledOffsetDays",
      headerName: "Sched Offset",
      headerAlign: "left",
      align: "center",
      flex: 0.6,
      sortable: true,
    },
    {
      field: "attemptInterval",
      headerName: "Attempt Interval",
      headerAlign: "left",
      align: "center",
      flex: 0.7,
      sortable: true,
    },
    {
      field: "autoAssign",
      headerName: "Auto Assign",
      headerAlign: "left",
      align: "center",
      flex: 0.6,
      sortable: true,
      renderCell: renderCheckIcon,
    },
    {
      field: "rewalkOnFail",
      headerName: "Rewalk On Fail",
      headerAlign: "left",
      align: "center",
      flex: 0.7,
      sortable: true,
      renderCell: renderCheckIcon,
    },
    {
      field: "walkTypeTemplate",
      headerName: "Template",
      headerAlign: "left",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <MiLink
            to={"/templates/" + params.row.walkTypeTemplateId}
            title={params.value}
          />
        );
      },
    },
  ];

  const getWalkTypesRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => toggleDelete(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
    ];
  };

  const stageSummaryColumns = [
    {
      field: "stageSummaryDescription",
      headerName: "Description",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
    },
    {
      field: "stageSummaryOrder",
      headerName: "Order",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
    },
  ];

  const getSummaryOptionsRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => openSummaryOptionDeleteModal(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
      {
        text: "EDIT",
        onClick: () => openSummaryOptionEditModal(row),
        renderIcon: () => <MiIcon path={mdiPencil} size={1} />,
      },
    ];
  };

  const saveDetailFieldEdit = async () => {
    const response = await stageTypeService.update(
      stageId,
      editStageTypes.edits
    );
    if (response.status === HTTP_STATUSES.OK) {
      createToast("Save Success", TOAST_TYPES.SUCCESS);
      setEditMode(!editMode);
    }
  };

  const cancelDetailFieldEdit = () => {
    setEditMode(!editMode);
  };

  const toggleEditDetailField = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    setStageTypeEdits({ ...stageType });
  }, [stageType]);

  const regex = /^[0-9]{0,1}$|^[1-9]{0,1}[0-9]{0,1}$|^(100)$/;

  const summaryDetailFields = [
    {
      label: "Description",
      value: edit.getValue("stageSummaryDescription"),
      setValue: (e) => edit.update({ stageSummaryDescription: e }),
      width: "100%",
      required: true,
    },
    {
      label: "Order",
      value: edit.getValue("stageSummaryOrder"),
      setValue: (e) => edit.update({ stageSummaryOrder: e }),
      width: "100%",
    },
  ];

  const detailFields = [
    {
      label: "Name",
      value: editStageTypes.getValue("name"),
      setValue: (e) => editStageTypes.update({ name: e }),
      width: "100%",
      readOnly: !editMode,
      clickButton: toggleEditDetailField,
    },
    {
      label: "Order",
      value: editStageTypes.getValue("order"),
      setValue: (e) => editStageTypes.update({ order: e }),
      width: "100%",
      readOnly: !editMode,
      clickButton: toggleEditDetailField,
    },
    {
      label: "Description",
      value: editStageTypes.getValue("description"),
      setValue: (e) => editStageTypes.update({ description: e }),
      width: "100%",
      readOnly: !editMode,
      clickButton: toggleEditDetailField,
    },
    {
      label: "Abbreviation",
      value: editStageTypes.getValue("abbreviation"),
      setValue: (e) =>
        editStageTypes.update({ abbreviation: e.toString().substring(0, 5) }),
      width: "100%",
      readOnly: !editMode,
      clickButton: toggleEditDetailField,
    },
    {
      label: "Target Cycle Single Story",
      value: editStageTypes.getValue("targetCycle1F"),
      setValue: (e) => {
        if (regex.test(e)) {
          editStageTypes.update({ targetCycle1F: e });
        }
      },
      width: "100%",
      readOnly: !editMode,
      clickButton: toggleEditDetailField,
      required: true,
    },
    {
      label: "Target Cycle Multi-Story",
      value: editStageTypes.getValue("targetCycle2F"),
      setValue: (e) => {
        if (regex.test(e)) {
          editStageTypes.update({ targetCycle2F: e });
        }
      },
      width: "100%",
      readOnly: !editMode,
      clickButton: toggleEditDetailField,
      required: true,
    },
    {
      label: "Schedule Next Stage",
      value: !!editStageTypes.getValue("scheduleNextStage"),
      fieldType: "checkbox",
      setValue: (e) => editStageTypes.update({ scheduleNextStage: e }),
      width: "100%",
      readOnly: !editMode,
      clickButton: toggleEditDetailField,
      required: true,
    },
  ];

  return (
    <>
      {stageTypeEdits && (
        <MiHeader
          color={theme.palette.primary.blue}
          title={stageTypeEdits.name}
          inverse={false}
          icon="arrow_back"
          backAction={goBack}
          fieldSet={
            activeTab === UI_TAB_LABELS.DETAILS && [
              { label: "Description", value: stageTypeEdits.description },
              { label: "Order", value: stageTypeEdits.order },
              { label: "Abbreviation", value: stageTypeEdits.abbreviation },
            ]
          }
        >
          {activeTab === UI_TAB_LABELS.ITEMS && (
            <MiButton
              title="Walk Type"
              icon={mdiPlus}
              inverse={true}
              onClick={() => openWalkTypeDetail("new")}
              color={theme.palette.primary.main}
            />
          )}
          {activeTab === UI_TAB_LABELS.STAGE_SUMMARY_OPTIONS && (
            <MiButton
              title="Summary Option"
              icon={mdiPlus}
              inverse
              onClick={toggleSummaryOptionAddModal}
              color={theme.palette.primary.main}
            />
          )}
        </MiHeader>
      )}
      <div style={cardMain}>
        <MiTab
          tabs={tabs}
          onTabChange={handleTabChange}
          currenttab={activeTab}
        />
      </div>
      {activeTab === UI_TAB_LABELS.DETAILS && (
        <MiBox styles={{ minHeight: "100vh" }}>
          <MiDetailFields
            detailFields={detailFields}
            additionalItemDependencies={editMode}
            headerProps={{
              icon: "list",
              title: "Detail Fields",
            }}
          />
          <MiSnackbar
            visible={editMode}
            handleCancelClick={cancelDetailFieldEdit}
            handleSaveClick={saveDetailFieldEdit}
          />
        </MiBox>
      )}
      {activeTab === UI_TAB_LABELS.ITEMS && (
        <MiBox>
          <StageWalkTypeCreateModal
            open={addWalkTypeModal}
            stageTypeId={stageId}
            onClose={() => setAddWalkTypeModal(false)}
          />
          <MiModalConfirm
            title={"Confirm Delete"}
            description={`Delete Walk Type: ${itemName}?`}
            open={isDeleteWalkTypeModalopen}
            handlePositive={deleteWalkTypeItem}
            handleNegative={() => setIsDeleteWalkTypeModalOpen(false)}
          />
          <MiTable
            style={{ minHeight: "100vh" }}
            columns={columns}
            rows={walkTypes}
            getRowId={(row) => row.walkTypeId}
            page={page}
            pages={pageCount}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            rowCount={rowCount}
            onSortModelChange={onSortChange}
            onFilterModelChange={setFilterState}
            pagination
            paginationMode={PAGINATION.SERVER}
            sortingMode={PAGINATION.SERVER}
            filterMode={PAGINATION.SERVER}
            disableColumnMenu
            getRowActions={getWalkTypesRowActions}
          />
        </MiBox>
      )}
      {activeTab === UI_TAB_LABELS.STAGE_SUMMARY_OPTIONS && (
        <>
          <MiModal
            title={"Add Summary Option"}
            open={isAddSummaryOptionModalOpen}
            onClose={toggleSummaryOptionAddModal}
            actions={[
              {
                name: "Cancel",
                onClick: toggleSummaryOptionAddModal,
                color: theme.palette.medium.grey,
                inverse: false,
              },
              {
                name: "Add",
                onClick: addSummaryOption,
                color: theme.palette.primary.main,
                inverse: true,
                disabled: !edit.getValue("stageSummaryDescription"),
              },
            ]}
          >
            <MiDetailFields detailFields={summaryDetailFields} />
          </MiModal>
          <MiModal
            title="Edit Summary Option"
            open={isEditSummaryOptionModalOpen}
            onClose={closeSummaryOptionEditModal}
            actions={[
              {
                name: "Cancel",
                onClick: closeSummaryOptionEditModal,
                color: theme.palette.medium.grey,
                inverse: false,
              },
              {
                name: "Save",
                onClick: editSummaryOption,
                color: theme.palette.primary.main,
                inverse: true,
                disabled: !edit.getValue("stageSummaryDescription"),
              },
            ]}
          >
            <MiDetailFields detailFields={summaryDetailFields} />
          </MiModal>
          <MiModalConfirm
            open={isDeleteSummaryOptionModalOpen}
            title="Confirm Delete"
            description={`Delete the following Summary Option?: ${editSelection.stageSummaryDescription}`}
            handlePositive={deleteSummaryOption}
            handleNegative={closeSummaryOptionDeleteModal}
          />
          <MiList
            style={{ minHeight: "100vh" }}
            data={stageSummaries}
            columns={stageSummaryColumns}
            searchColumns={["stageSummaryDescription"]}
            getRowId={(row) => row.stageSummaryId}
            paginationMode={PAGINATION.CLIENT}
            getRowActions={getSummaryOptionsRowActions}
            disableColumnMenu
          />
        </>
      )}
    </>
  );
};

const cardMain = {
  width: "100%",
  marginBottom: 20,
  paddingTop: 5,
};

export default StageManagementDetails;
