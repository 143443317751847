import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const contactMapService = {
  delete: async ({ contactMapId, signal }) => {
    const options = await apiOptions({
      method: "delete",
      url: `${API_V3}/contactmaps/${contactMapId}`,
      signal,
    });
    return apiRequest(options);
  },

  update: async ({ contactMapId, data, signal }) => {
    const options = await apiOptions({
      method: "patch",
      url: `${API_V3}/contactmaps/${contactMapId}`,
      data,
      signal,
    });

    return apiRequest(options);
  },
};
