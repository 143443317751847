import * as React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import PropTypes from "prop-types";
import { THEME } from "@miview/theme";
import { makeStyles } from "tss-react/mui";

const useAccordionStyles = makeStyles()(
  (theme, { backgroundColor, backgroundColorHover }) => ({
    summary: {
      position: "relative",
      margin: 0,
      backgroundColor: backgroundColor || THEME.NONE,
      "&:hover": {
        backgroundColor: backgroundColorHover || theme.palette.light.grey,
      },
      display: "flex",
      padding: "0 10px",
      "&.Mui-expanded": {
        minHeight: "45px",
      },
      "& .Mui-expanded": {
        margin: "20px 0 10px 0",
      },
    },
    details: {
      alignItems: "block",
    },
    iconStyle: {
      marginRight: 15,
      fontSize: theme.fontSize.headerSmall,
    },
    widthStyle: {
      width: "100%",
      fontWeight: theme.fontWeight.medium,
      fontSize: theme.fontSize.medium,
    },
    subTitle: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);

export const MiAccordionItem = (props) => {
  const {
    title,
    detail,
    disabled,
    onOpen,
    onClose,
    id,
    activeItemIds,
    activeColor,
    iconPosition,
    renderExpandIcons,
    isEnableSubTitle,
    subTitle,
    backgroundColor,
    ...Rest
  } = props;
  const isActive = activeItemIds.includes(id);
  const { classes: styleClasses } = useAccordionStyles({
    backgroundColor,
    backgroundColorHover: activeColor,
  });

  const handleChange = (event, isExpanded) => {
    if (isExpanded) {
      onOpen(id);
      return;
    }
    onClose(id);
  };

  const renderDefaultExpandIcons = () => {
    if (renderExpandIcons) {
      return renderExpandIcons(isActive);
    } else if (isActive) {
      return <ExpandLess />;
    } else {
      return <ExpandMore />;
    }
  };

  const getAccordionSummaryStyle = () => {
    return iconPosition === "right"
      ? styles.expandIconRight
      : { display: "flex", width: "100%", alignItems: "center" };
  };

  return (
    <Accordion
      onChange={handleChange}
      expanded={isActive}
      disabled={disabled}
      style={styles.accordion}
      {...Rest}
    >
      <AccordionSummary className={styleClasses.summary} IconButtonProps={null}>
        <div style={getAccordionSummaryStyle()}>
          <div className={styleClasses.iconStyle}>
            {renderDefaultExpandIcons()}
          </div>
          <div className={styleClasses.widthStyle}>{title}</div>
          {isEnableSubTitle && (
            <div className={styleClasses.subTitle}>{subTitle}</div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className={styleClasses.details}>
        {isActive && detail}
      </AccordionDetails>
    </Accordion>
  );
};

const styles = {
  expandIconRight: {
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
  accordion: { marginBottom: "5px", boxShadow: "none" },
};

MiAccordionItem.defaultProps = {
  iconPosition: "left",
  isEnableSubTitle: false,
};

MiAccordionItem.propTypes = {
  /**
   * title on the Accordion
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * id of the current accordion item
   */
  id: PropTypes.string.isRequired,
  /**
   * id of the selected accordion. If activeId === id then accordion header highlights and expands
   */
  activeItemIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  /**
   * this function gets called with id of the selected accordion
   */
  onOpen: PropTypes.func.isRequired,
  /**
   * this function gets called with id of the selected accordion
   */
  onClose: PropTypes.func.isRequired,
  /**
   * can be a text or a component which will be displayed as accordion body
   */
  detail: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * background color when the accordion is expanded
   */
  activeColor: PropTypes.string,
  /**
   * expand icon position
   */
  iconPosition: PropTypes.oneOf(["right", "left"]),
  /**
   * subtitle on the Accordion
   */
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Enable subtitle on the Accordion
   */
  isEnableSubTitle: PropTypes.bool,
};
