import React from "react";
import { IconButton, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRouter } from "@miview/hooks";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles()((theme) => ({
  pageHeader: {
    padding: 15,
    backgroundColor: theme.palette.primary.white,
  },
  pageHeaderFlex: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  phTitle: {
    color: theme.palette.dark.teal,
    fontSize: theme.fontSize.xlarge,
    fontWeight: theme.fontWeight.medium,
    marginLeft: 10,
    marginRight: 20,
  },
  phId: {
    background: theme.palette.lightAccent.grey,
    fontSize: theme.fontSize.xsmall,
    padding: "5px 10px",
    borderRadius: "3px",
    color: theme.palette.bluegrey.secondary,
    fontWeight: theme.fontWeight.medium,
    textTransform: "uppercase",
  },
  buttonStyle: {
    padding: 10,
    color: theme.palette.primary.main,
  },
}));

const TdPageHeader = (props) => {
  const { classes } = useStyles();
  const { goBack } = useRouter();
  const { account, loading } = props;
  return (
    <div className={classes.pageHeader}>
      <div className={classes.pageHeaderFlex}>
        <IconButton
          onClick={goBack}
          className={classes.buttonStyle}
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography component="h3" className={classes.phTitle}>
          {loading && <Skeleton height={20} width={150} />}
          {account && !loading && account?.name}
        </Typography>

        {loading ? (
          <Skeleton height={20} width={50} />
        ) : (
          <Typography component="p" className={classes.phId}>
            {account && !loading && account?.code}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default TdPageHeader;
