import React from "react";
import { MiHeader } from "../../components/Standard/MiView";
import { useRouter } from "@miview/hooks";
import { makeStyles } from "tss-react/mui";
import { THEME } from "../../components/Standard/theme";
import { MiSnackbar } from "@miview/components";

const useStyles = makeStyles()({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    padding: "1rem",
  },
  container: { ...THEME.BOX_MAIN, width: "70%" },
  tabsContainer: {
    width: "100%",
    marginBottom: 20,
    paddingTop: 5,
  },
  flexContainer: { display: "flex", flexDirection: "row" },
  tab: { width: 120 },
  pageContent: { flex: 1 },
});

export const TabulatedPage = ({
  toggleEdit,
  cancelEdit,
  onSave,
  pageTitle,
  fieldSet,
  renderPage,
  renderTabs,
  saveDisabled,
}) => {
  const { goBack } = useRouter();
  const { classes } = useStyles();

  return (
    <div className="animated fadeIn" style={{ padding: 0 }}>
      <MiSnackbar
        visible={toggleEdit}
        handleCancelClick={cancelEdit}
        handleSaveClick={onSave}
        disableSave={saveDisabled}
      />
      <MiHeader
        color={THEME.BLUE_PRIMARY}
        backAction={goBack}
        inverse={false}
        icon="arrow_back"
        title={pageTitle}
        fieldSet={fieldSet}
      />
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.tabsContainer}>
            <div>{renderTabs()}</div>
          </div>
          <div className={classes.pageContent}>{renderPage()}</div>
        </div>
      </div>
    </div>
  );
};
