import React, { useState } from "react";
import { useTheme } from "@mui/material";
import { paySchedulesService } from "@miview/api";
import {
  MiButton,
  MiLink,
  MiModalConfirm,
  MiPageHeader,
  MiList,
  MiIcon,
} from "@miview/components";
import { createToast } from "@miview/toast";
import { TOAST_TYPES, HTTP_STATUSES, PAGINATION } from "@miview/constants";
import { useComponentState } from "@miview/hooks";
import { mdiDelete, mdiCashClock, mdiPencil, mdiPlus } from "@mdi/js";
import { CreateEditPayScheduleModal } from "./CreateEditPayScheduleModal";

const PaySchedules = () => {
  const [paySchedules, setPaySchedules] = useState([]);
  const [editSelection, setEditSelection] = useState();
  const [refresh, setRefresh] = useState(false);
  const [isPayScheduleCreateModalOpen, setIsPayScheduleCreateModalOpen] =
    useState(false);
  const [isPayScheduleDeleteModalOpen, setIsPayScheduleDeleteModalOpen] =
    useState(false);

  const stateManager = useComponentState();
  const theme = useTheme();

  const getPaySchedules = async () => {
    return stateManager.run(async (config) => {
      return paySchedulesService.getAll(config);
    });
  };

  const deletePaySchedule = async () => {
    const response = await paySchedulesService.delete({ id: editSelection.id });
    if (response.status === HTTP_STATUSES.OK) {
      createToast("Pay Schedule Deleted Successfully", TOAST_TYPES.SUCCESS);
      setRefresh(!refresh);
      closePayScheduleDeleteModal();
    }
  };

  const openPayScheduleCreateEditModal = (obj) => {
    setEditSelection(obj);
    setIsPayScheduleCreateModalOpen(true);
  };

  const closePayScheduleCreateEditModal = () => {
    setEditSelection();
    setIsPayScheduleCreateModalOpen(false);
    setRefresh(!refresh);
  };

  const openPayScheduleDeleteModal = (obj) => {
    setEditSelection(obj);
    setIsPayScheduleDeleteModalOpen(true);
  };

  const closePayScheduleDeleteModal = () => {
    setEditSelection();
    setIsPayScheduleDeleteModalOpen(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (p) => {
        return (
          <MiLink to={`/payschedules/${p.row.id}/payperiods`} title={p.value} />
        );
      },
    },
    {
      field: "isDefault",
      headerName: "Default",
      renderCell: (c) => c.value && <i className="material-icons">check</i>,
    },
  ];

  const getRowActions = () => {
    return [
      {
        text: "DELETE",
        onClick: (e) => openPayScheduleDeleteModal(e),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
      {
        text: "EDIT",
        onClick: (e) => openPayScheduleCreateEditModal(e),
        renderIcon: () => <MiIcon path={mdiPencil} size={1} />,
      },
    ];
  };

  const renderDeleteModal = () => (
    <MiModalConfirm
      open={isPayScheduleDeleteModalOpen}
      title={"Delete Pay Schedule"}
      description={`Delete the following Pay Schedule: ${editSelection?.name}`}
      handlePositive={deletePaySchedule}
      handleNegative={closePayScheduleDeleteModal}
    />
  );

  const renderHeaderIcon = () => (
    <MiIcon path={mdiCashClock} size={1} color={theme.palette.primary.main} />
  );

  const headerContent = () => (
    <MiButton
      title="Pay Schedules"
      icon={mdiPlus}
      inverse
      onClick={() => openPayScheduleCreateEditModal()}
      color={theme.palette.primary.main}
    />
  );

  return (
    <div>
      {renderDeleteModal()}
      <CreateEditPayScheduleModal
        open={isPayScheduleCreateModalOpen}
        schedule={editSelection}
        onClose={closePayScheduleCreateEditModal}
      />
      <MiPageHeader
        title={"Pay Schedules"}
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={headerContent()}
      />
      <MiList
        data={paySchedules}
        columns={columns}
        refresh={refresh}
        dataProvider={getPaySchedules}
        setData={setPaySchedules}
        getRowActions={getRowActions}
        searchColumns={["name"]}
        paginationMode={PAGINATION.CLIENT}
      />
    </div>
  );
};

export default PaySchedules;
