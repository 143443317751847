import React, { useState } from "react";
import { MiModal } from "../../../components/Standard/MiView";
import { communityService } from "@miview/api";
import { getImageBase64FromFile } from "@miview/utils";
import { MiInputFile } from "@miview/components";

const UploadPlatMapModal = (props) => {
  const { communityId, toggle, refresh } = props;
  const [platImage, setPlatImage] = useState();

  const uploadPlatImage = async () => {
    await communityService.update({ image: platImage.base64 }, communityId);
    refresh();
    toggle();
  };

  return (
    <MiModal
      title="Upload Plat Image"
      toggle={toggle}
      width={750}
      saveAction={() => uploadPlatImage()}
      saveDisabled={!platImage}
    >
      <MiInputFile
        label="Plat Image"
        id="platImage"
        accept=".png,.jpg"
        filename={platImage?.displayName}
        handleChange={(e) => {
          const file = e.target.files[0];
          getImageBase64FromFile(file, (base64, displayName) => {
            setPlatImage({
              base64,
              displayName,
            });
          });
        }}
        onClear={() => setPlatImage({})}
      />
    </MiModal>
  );
};

export default UploadPlatMapModal;
