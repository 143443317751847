import React from "react";
import { MiModal, THEME } from "../../../components/Standard/MiView";
import { usePlanAreas } from "../hooks";

export const ConfirmDeleteModal = () => {
  const {
    planAreas,
    isConfirmingDelete,
    setIsConfirmingDelete,
    handleDeletePlanArea,
  } = usePlanAreas();

  const areaName = planAreas.find(
    (area) => area.planAreaId === isConfirmingDelete
  )?.name;

  if (isConfirmingDelete) {
    return (
      <MiModal
        title="Warning"
        cancelAction={() => setIsConfirmingDelete(0)}
        saveAction={() => handleDeletePlanArea({ areaId: isConfirmingDelete })}
        saveIcon="delete"
        saveTitle="DELETE"
        color={THEME.RED_PRIMARY}
        toggle={() => setIsConfirmingDelete(0)}
      >
        <div style={{ fontSize: 18, margin: "0 16px" }}>
          This action will remove the {areaName || "Area"} and any associated
          items and options. Are you sure you want to proceed?
        </div>
      </MiModal>
    );
  }

  return null;
};

ConfirmDeleteModal.propTypes = {};
