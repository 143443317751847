import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  MiSpecsTitle,
  MiSpecsSubTitle,
  MiPlanOptions,
} from "../../../components/Standard/MiView";
import { usePlanOptions, usePlanOptionUpdate, useSetOptionOn } from "../hooks";
import { optionService } from "@miview/api";
import { THEME } from "../../../components/Standard/theme";
import { useEdit } from "@miview/hooks";

const INITIAL_OPTIONS = {
  description: "",
  name: "",
};

export const OptionsPage = ({
  setPreviewTab,
  rightColumnOn,
  setRightColumnOn,
  floors,
  planEdit,
  materialCategories,
  materialBrands,
  propertySections,
  refreshData,
  planAreas,
  planOptions,
  setPlanOptions,
  optionTypes,
}) => {
  const {
    setPlanOptionForm,
    planOptionForm,
    updateForm,
    saveDisabled,
    resetForm,
    optionConfiguring,
    activateNewForm,
  } = usePlanOptions();

  const editOption = useEdit(INITIAL_OPTIONS);
  const {
    saveOption,
    saveDraft,
    saveAreaActionAdd,
    saveAreaActionRemove,
    saveAreaActionUpdate,
    updateOptionsAfterSubmission,
  } = usePlanOptionUpdate({
    planOptionForm: editOption.edits,
    setPlanOptionForm: editOption.update,
    setPlanOptions,
  });
  const setOptionOn = useSetOptionOn();
  const [optionsToggledOn] = useState(false);

  const handleDeleteOption = async (option) => {
    await optionService.delete(option.optionId);
    updateOptionsAfterSubmission();
  };

  const saveOptionRequest = async (request) => {
    await optionService.submit(request);
    updateOptionsAfterSubmission();
  };

  const planAreaSelections = planAreas.map((p) => ({
    value: p.planAreaId,
    text: p.name,
  }));

  return (
    <Fragment>
      <MiSpecsTitle
        icon={<DashboardIcon style={styles.titleIcon} />}
        setRightColumnOn={setRightColumnOn}
        rightColumnOn={rightColumnOn}
      >
        Add Available Options
      </MiSpecsTitle>
      <MiSpecsSubTitle>
        Add custom home options, update custom options, or add new custom option
      </MiSpecsSubTitle>
      <MiPlanOptions
        optionName="Available"
        refreshData={refreshData}
        deleteOption={handleDeleteOption}
        activateNewForm={activateNewForm}
        planOptionForm={planOptionForm}
        planOptions={planOptions}
        optionsToggledOn={optionsToggledOn}
        handleSetOptionOn={setOptionOn}
        edit={editOption}
        optionConfiguring={optionConfiguring}
        updateForm={updateForm}
        resetForm={resetForm}
        planAreas={planAreas}
        optionTypes={optionTypes}
        saveOptionRequest={saveOptionRequest}
        saveDraft={saveDraft}
        setPlanOptionForm={setPlanOptionForm}
        floors={floors}
        numberOfFloors={planEdit.getValue("numberOfFloors")}
        propertySections={propertySections}
        saveAreaActionAdd={saveAreaActionAdd}
        updateOptionsAfterSubmission={updateOptionsAfterSubmission}
        planAreaSelections={planAreaSelections}
        saveAreaActionRemove={saveAreaActionRemove}
        saveAreaActionUpdate={saveAreaActionUpdate}
        saveOption={saveOption}
        saveDisabled={saveDisabled}
        materialBrands={materialBrands}
        materialCategories={materialCategories}
        setPreviewTab={setPreviewTab}
      />
    </Fragment>
  );
};

OptionsPage.propTypes = {
  planOptionForm: PropTypes.object,
  setPlanOptionForm: PropTypes.func,
  propertySections: PropTypes.array,
  optionTypes: PropTypes.array,
  walkTypes: PropTypes.array,
  handleSaveOption: PropTypes.func,
  planOptions: PropTypes.array,
  optionsToggledOn: PropTypes.array,
  handleSetOptionOn: PropTypes.func,
  optionConfiguring: PropTypes.number,
  floors: PropTypes.array,
};

const styles = {
  titleIcon: {
    color: THEME.STEPS_BAR[2],
  },
};
