import moment from "moment";
import { useEffect, useState } from "react";
import {
  getDatesForMonth,
  nextMonth,
  prevMonth,
} from "../utils/calendarMethods";

export const useMonthCal = (selectedDate) => {
  const [calendarDate, setCalendarDate] = useState(
    moment(selectedDate).format()
  );
  const [calendarMonthDates, setCalendarMonthDates] = useState([]);

  useEffect(() => {
    setCalendarDate(moment(selectedDate).format());
    setCalendarMonthDates(getDatesForMonth(moment(selectedDate).format()));
  }, [selectedDate]);

  const goToNextMonth = (date) => {
    let month = { ...nextMonth(date) };
    setCalendarDate(month.nextDate);
    setCalendarMonthDates(month.nextDates);
  };

  const goToPrevMonth = (date) => {
    let month = { ...prevMonth(date) };
    setCalendarDate(month.prevDate);
    setCalendarMonthDates(month.prevDates);
  };

  return [calendarDate, calendarMonthDates, goToNextMonth, goToPrevMonth];
};
