import React, { useEffect, useState } from "react";
import {
  MiInputSelectBox,
  MiButton,
  MiLoader,
  MiTableCustom,
} from "@miview/components";
import { Grid } from "@mui/material";
import { TOAST_TYPES, HTTP_STATUSES } from "@miview/constants";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { createToast } from "@miview/toast";
import { integrationService } from "@miview/api";

const useStyles = makeStyles()(() => {
  return {
    buttonContainer: {
      position: "fixed",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      bottom: 0,
      padding: 10,
      width: "100%",
      maxWidth: 500,
    },
  };
});

export const MappingsEdit = ({
  id,
  selected,
  handleSave,
  options,
  columns,
  apiEntity,
}) => {
  const theme = useTheme();
  const [mappings, setMappings] = useState([]);
  const [loading, setLoading] = useState(false);
  const { classes } = useStyles();

  useEffect(() => {
    if (selected) {
      setMappings(selected.mappings);
    }
  }, [id]);

  const onHandleSave = async () => {
    try {
      setLoading(true);
      const updateMappingsIds = mappings
        .map((item) => {
          if (typeof item.id === "string" && item.id.includes("id")) {
            item.id = null;
          }
          return item;
        })
        .filter((x) => x && x.internalId);

      const data = {
        ...selected,
        mappings: updateMappingsIds,
      };

      const response = await integrationService[apiEntity].update(
        data.id,
        data
      );
      if (response.status === HTTP_STATUSES.OK) {
        handleSave(data.integrationTypeName);
      }
    } catch (e) {
      createToast(`Error ${e}`, TOAST_TYPES.WARNING);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e, id) => {
    const maps = [...mappings];
    const index = maps.findIndex((x) => x.id === id);
    maps[index] = {
      ...maps[index],
      internalId: e.target.value.toString(),
    };
    setMappings(maps);
  };

  const renderDropdown = (params) => {
    if (!options) {
      return null;
    }

    const { row } = params;
    return (
      <MiInputSelectBox
        menuItems={options[row.mappingTypeId]}
        value={row.internalId || ""}
        handleChange={(e) => handleChange(e, row.id)}
        error={!row.internalId}
        labelField={"name"}
        valueField={"id"}
      />
    );
  };

  const checkInternalId = (arr) => {
    return !!arr.internalId;
  };

  const getDisabled = () => {
    return !mappings.every(checkInternalId);
  };

  const getColumns = () => {
    if (!columns) {
      return [
        {
          field: "entityType",
          headerName: "Name",
          width: 170,
        },
        {
          field: "externalId",
          headerName: "Code",
          width: 75,
        },
        {
          field: "mappings",
          headerName: "Mapping",
          type: "singleSelect",
          editable: true,
          renderCell: (params) => renderDropdown(params),
          flex: 1,
        },
      ];
    }
    return columns;
  };

  const renderTable = () => {
    if (!mappings?.length > 0) {
      return null;
    }
    return (
      <Grid style={{ paddingLeft: 10 }}>
        {
          //TODO: Fix MiTable bug for mui select error
          /* <MiTable
          columns={getColumns()}
          rows={mappings}
          disableColumnMenu={true}
          editRowsModel={editRow}
          onEditRowModelChange={handleEditRow}
        /> */
        }
        <MiTableCustom columns={getColumns()} rows={mappings} />
      </Grid>
    );
  };

  return (
    <Grid
      container
      style={{ flex: 1 }}
      direction="column"
      justifyContent="space-between"
    >
      {loading && <MiLoader type="linear" />}
      {renderTable()}
      <div className={classes.buttonContainer}>
        <MiButton
          color={theme.palette.primary.main}
          inverse={true}
          onClick={onHandleSave}
          title="Save and Apply"
          disabled={getDisabled()}
          style={{ padding: 15, alignSelf: "stretch" }}
        />
      </div>
    </Grid>
  );
};
