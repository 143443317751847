import React, { useState, useEffect } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { MiPath } from "../../../components/Standard/MiView";
import { formatDate } from "../../../helpers/dateTools";
import { useTheme } from "@mui/material";
import Lightbox from "react-images";
import { propertyStageService } from "@miview/api";
import { createToast } from "@miview/toast";
import {
  MiDetailFields,
  MiTab,
  MiLink,
  MiSnackbar,
  MiSectionHeader,
  MiCard,
  MiBox,
  MiPageHeader,
  MiIcon,
  MiModalConfirm,
  MiModal,
  MiFieldSet,
} from "@miview/components";
import {
  TOAST_TYPES,
  HTTP_STATUSES,
  STAGE_STATUSES,
  PROPERTY_STATUSES_OPTIONS_KEYED,
} from "@miview/constants";
import { useRouter, useEdit, useComponentState } from "@miview/hooks";
import { mdiArrowLeft, mdiClock } from "@mdi/js";

const ALLOWED_STATUSES = [
  STAGE_STATUSES.NOT_SCHEDULED,
  STAGE_STATUSES.SCHEDULED,
  STAGE_STATUSES.STAGE_COMPLETE,
  STAGE_STATUSES.CANCELLED,
];
const DATE_FORMAT = "YYYY-MM-DD";

const StageDetails = () => {
  const [stageDetails, setStageDetails] = useState([]);
  const [propertyStatusOptions, setPropertyStatusOptions] = useState([]);
  const [history, setHistory] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(global.ACTIVE_TAB.DETAILS);
  const [activeTabSidebar, setActiveTabSidebar] = useState(
    global.ACTIVE_TAB.HISTORY
  );
  const [
    showReadyForInspectionStageConfirm,
    setShowReadyForInspectionStageConfirm,
  ] = useState(false);
  const [showCompleteStageConfirm, setShowCompleteStageConfirm] =
    useState(false);
  const [showCancelStageConfirm, setShowCancelStageConfirm] = useState(false);
  const [showUnscheduleStageConfirm, setShowUnscheduleStageConfirm] =
    useState(false);
  const [showRescheduleStageConfirm, setShowRescheduleStageConfirm] =
    useState(false);
  const [openImageIndex, setOpenImageIndex] = useState(0);
  const [openImageUrls, setOpenImageUrls] = useState("");

  const router = useRouter();
  const edit = useEdit(stageDetails);
  const stateManager = useComponentState();
  const theme = useTheme();

  const propertyStatuses = Object.values(PROPERTY_STATUSES_OPTIONS_KEYED);

  const { id: stageId } = router.params;

  useEffect(() => {
    getPropertyStage();
    getPropertyStatuses();
    getStageHistory();
  }, []);

  const getPropertyStage = () => {
    stateManager.run(async () => {
      const response = await propertyStageService.get(stageId);
      if (response.status === HTTP_STATUSES.OK) {
        setStageDetails(response.data);
      }
    });
  };

  const getPropertyStatuses = () => {
    setPropertyStatusOptions(
      propertyStatuses.map((status) => ({
        ...status,
        disabled: !ALLOWED_STATUSES.includes(status.value),
      }))
    );
  };

  const getStageHistory = () => {
    stateManager.run(async () => {
      const response = await propertyStageService.getHistory(stageId);
      if (response.status === HTTP_STATUSES.OK) {
        setHistory(response.data);
      }
    });
  };

  const resetStageDetailsEdits = () => edit.reset();

  const cancelEditStageDetails = () => {
    resetStageDetailsEdits();
    setIsEditing(false);
  };

  const getStatusLabel = (value) => {
    return propertyStatusOptions?.find((o) => o.value === value).text;
  };

  const checkValidDates = (dateParams1, dateParams2) => {
    if (dateParams1 && dateParams2) {
      if (moment(dateParams1) < moment(dateParams2)) {
        return true;
      }
    }
    return false;
  };

  const checkSaveChanges = () => {
    const newStageStatus = getStatusLabel(edit.getValue("propertyStatusId"));
    const oldStageStatus = getStatusLabel(stageDetails.propertyStatusId);

    if (
      checkValidDates(
        edit.getValue("readyForInspectionDate"),
        edit.getValue("scheduledStartDate")
      )
    ) {
      createToast(
        "Ready For Inspection Date cannot be less then Scheduled Start Date",
        TOAST_TYPES.ERROR
      );
      return;
    }

    if (
      checkValidDates(
        edit.getValue("completedDate"),
        edit.getValue("scheduledStartDate")
      )
    ) {
      createToast(
        'Completed Date cannot be less then "Scheduled Start Date"',
        TOAST_TYPES.ERROR
      );
      return;
    }

    if (
      newStageStatus === "Scheduled" &&
      !edit.getValue("scheduledStartDate")
    ) {
      createToast(
        'Scheduled Start Date must be set when status is set to "Scheduled"',
        TOAST_TYPES.ERROR
      );
    } else if (
      newStageStatus === "Stage Complete" &&
      !edit.getValue("readyForInspectionDate")
    ) {
      createToast(
        'Ready For Inspection Date must be set when status is set to "Stage Complete"',
        TOAST_TYPES.ERROR
      );
    } else if (newStageStatus === "Stage Complete") {
      setShowCompleteStageConfirm(true);
    } else if (newStageStatus === "Ready for Inspection") {
      setShowReadyForInspectionStageConfirm(true);
    } else if (newStageStatus === "Cancelled") {
      setShowCancelStageConfirm(true);
    } else if (
      newStageStatus === "Scheduled" &&
      oldStageStatus === "Scheduled" &&
      edit.getValue("scheduledStartDate") != stageDetails.scheduledStartDate
    ) {
      setShowRescheduleStageConfirm(true);
    } else if (
      oldStageStatus === "Scheduled" &&
      newStageStatus !== "Scheduled"
    ) {
      setShowUnscheduleStageConfirm(true);
    } else {
      saveChanges();
    }
  };

  const saveChanges = () => {
    stateManager.run(async () => {
      const response = await propertyStageService.update(stageId, edit.edits);
      if (response.status !== HTTP_STATUSES.OK) {
        return;
      }
      setIsEditing(false);
      setStageDetails({ ...stageDetails, ...edit.edits });
      resetStageDetailsEdits();
      getPropertyStage();
    });
  };

  const handleCancelStageConfirm = () => {
    setShowCancelStageConfirm(false);
    saveChanges();
  };

  const handleCompleteStageConfirm = () => {
    setShowCompleteStageConfirm(false);
    saveChanges();
  };

  const handleUnscheduleStageConfirm = () => {
    setShowUnscheduleStageConfirm(false);
    saveChanges();
  };

  const handleRescheduleStageConfirm = () => {
    setShowRescheduleStageConfirm(false);
    saveChanges();
  };

  const handleShowReadyForInspectionStageConfirm = () => {
    setShowReadyForInspectionStageConfirm(false);
    saveChanges();
  };

  const onSetActiveTab = (tab) => {
    switch (tab) {
      case global.ACTIVE_TAB.DETAILS:
        setActiveTab(global.ACTIVE_TAB.DETAILS);
        break;
      case global.ACTIVE_TAB.HISTORY:
        setActiveTabSidebar(global.ACTIVE_TAB.HISTORY);
        break;
      default:
        break;
    }
  };

  const getSelectedDate = (date) => {
    if (!date) {
      return null;
    }
    return moment(date).local();
  };

  const handleTargetCycleTimeChange = (e) => {
    const regex = /^[0-9]{0,1}$|^[1-9]{0,1}[0-9]{0,1}$|^(100)$/;
    if (regex.test(e)) {
      edit.update({ targetCycleTime: e });
    } else {
      return;
    }
  };

  const toggleEdit = () => setIsEditing(!isEditing);

  const detailFields = [
    {
      label: "Status",
      value: edit.getValue("propertyStatusId"),
      fieldType: "select",
      readOnly: !isEditing,
      options: propertyStatusOptions,
      clickButton: toggleEdit,
      setValue: (e) => edit.update({ propertyStatusId: e }),
      required: true,
    },
    {
      label: "Scheduled Start Date",
      value: getSelectedDate(edit.getValue("scheduledStartDate")),
      readOnly: !isEditing,
      fieldType: "date",
      inputFormat: "MM/DD/YYYY",
      clickButton: toggleEdit,
      setValue: (e) => edit.update({ scheduledStartDate: e || null }),
      dateFormat: DATE_FORMAT,
    },
    {
      label: "Ready For Inspection",
      value: getSelectedDate(edit.getValue("readyForInspectionDate")),
      readOnly: !isEditing,
      fieldType: "date",
      inputFormat: "MM/DD/YYYY",
      clickButton: toggleEdit,
      setValue: (e) => edit.update({ readyForInspectionDate: e }),
      dateFormat: DATE_FORMAT,
    },
    {
      label: "Builder Date",
      value: getSelectedDate(edit.getValue("cycleStartDate")),
      readOnly: !isEditing,
      fieldType: "date",
      inputFormat: "MM/DD/YYYY",
      clickButton: toggleEdit,
      setValue: (e) => edit.update({ cycleStartDate: e }),
      dateFormat: DATE_FORMAT,
    },
    {
      label: "QC1 Date",
      value: getSelectedDate(edit.getValue("qc1")),
      fieldType: "date",
      readOnly: !isEditing,
      inputFormat: "MM/DD/YYYY",
      clickButton: toggleEdit,
      setValue: (e) => edit.update({ qc1: e }),
      dateFormat: DATE_FORMAT,
    },
    {
      label: "QC2 Date",
      fieldType: "date",
      readOnly: !isEditing,
      value: getSelectedDate(edit.getValue("qc2")),
      clickButton: toggleEdit,
      setValue: (e) => edit.update({ qc2: e }),
      inputFormat: "MM/DD/YYYY",
      dateFormat: DATE_FORMAT,
    },
    {
      label: "Target Completion Date",
      fieldType: "date",
      value: getSelectedDate(edit.getValue("targetCompleteDate")),
      readOnly: true,
      inputFormat: "MM/DD/YYYY",
      dateFormat: DATE_FORMAT,
    },
    {
      label: "Target Cycle Time",
      fieldType: "number",
      value: edit.getValue("targetCycleTime"),
      clickButton: toggleEdit,
      setValue: handleTargetCycleTimeChange,
      required: true,
      readOnly: !isEditing,
    },
    {
      label: "Hold Reason",
      value: edit.getValue("summary"),
      clickButton: toggleEdit,
      setValue: (e) => edit.update({ summary: e }),
      readOnly: !isEditing,
    },
    {
      fieldType: "date",
      label: "Completed Date",
      value: getSelectedDate(edit.getValue("completedDate")),
      clickButton: toggleEdit,
      dateFormat: DATE_FORMAT,
      inputFormat: "MM/DD/YYYY",
      setValue: (e) => edit.update({ completedDate: e }),
    },
    {
      label: "Internal Cycle Time",
      value: edit.getValue("internalCycleTime"),
      readOnly: true,
    },
    {
      label: "Notes",
      value: edit.getValue("notes"),
      clickButton: toggleEdit,
      setValue: (e) => edit.update({ notes: e }),
    },
  ];

  const getCurrentStatusValue = () => {
    const currentStatus = propertyStatusOptions?.find(
      (e) => Number(e.value) === Number(edit.getValue("propertyStatusId"))
    );
    return currentStatus?.text;
  };

  return (
    <>
      {stateManager.statusTag("propertyStageDetailStatus")}
      <MiPageHeader
        color={theme.palette.primary.main}
        loading={stateManager.isBusy()}
        title={stageDetails?.stageTypeName}
        leftIcon={
          <MiIcon
            style={{ cursor: "pointer" }}
            path={mdiArrowLeft}
            size={1}
            color={theme.palette.primary.main}
            onClick={router.goBack}
          />
        }
        details={
          <MiFieldSet
            hasDivider={true}
            orientation={"Horizontal"}
            dividerOrientation={"vertical"}
            fieldSetArray={[
              {
                label: "Address",
                value: (
                  <MiLink
                    to={"/homes/" + stageDetails?.propertyId}
                    title={stageDetails?.address}
                    style={{ padding: 0, margin: 0 }}
                  />
                ),
              },
              {
                label: "Community",
                value: (
                  <MiLink
                    to={"/communities/" + stageDetails?.communityId}
                    title={stageDetails?.communityName}
                    style={{ padding: 0, margin: 0 }}
                  />
                ),
              },
              {
                label: "Builder",
                value: (
                  <MiLink
                    to={"/builder/" + stageDetails?.builderId}
                    title={stageDetails?.builderName}
                    style={{ padding: 0, margin: 0 }}
                  />
                ),
              },
            ]}
          />
        }
      />
      <MiBox styles={{ width: "100%" }}>
        <MiPath
          picklist={propertyStatusOptions
            ?.filter((r) => r.displayOrder >= 0)
            .map((e) => ({
              value: e.text,
            }))}
          currentStatus={getCurrentStatusValue()}
        />
      </MiBox>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <MiBox styles={{ width: "70%" }}>
          <div style={{ marginBottom: 20, paddingTop: 5 }}>
            <MiTab
              tabs={[{ label: "Details", value: global.ACTIVE_TAB.DETAILS }]}
              onTabChange={onSetActiveTab}
              currenttab={global.ACTIVE_TAB.DETAILS}
            />
          </div>
          {activeTab === global.ACTIVE_TAB.DETAILS && (
            <MiDetailFields
              detailFields={detailFields}
              headerProps={{
                style: { marginRight: "2%" },
                icon: "list",
                title: "Detail Fields",
              }}
            />
          )}
        </MiBox>
        <MiBox styles={{ width: "30%" }}>
          <div style={{ width: "100%", marginBottom: 20, paddingTop: 5 }}>
            <MiTab
              tabs={[{ label: "History", value: global.ACTIVE_TAB.HISTORY }]}
              currenttab={global.ACTIVE_TAB.HISTORY}
            />
          </div>
          {activeTabSidebar === global.ACTIVE_TAB.HISTORY && (
            <>
              {history?.length > 0 ? (
                history.map((item) => (
                  <MiCard
                    key={item.stageWorkHistoryId}
                    style={{ marginBottom: 10 }}
                    title={item.workStatusName}
                    icon={mdiClock}
                    color={theme.palette.medium.purple}
                    fieldset={[
                      { value: item.note },
                      {
                        value: `${item.createdByName} - 
                        ${formatDate(item.createTime, "daydatestring")}`,
                      },
                    ]}
                  />
                ))
              ) : (
                <MiSectionHeader
                  style={{ paddingTop: 10 }}
                  title="There is no history"
                />
              )}
            </>
          )}
        </MiBox>
      </div>
      <MiModalConfirm
        title="Cancel Stage"
        description="All related walks and jobs in this stage will be cancelled. Do you want to proceed?"
        open={showCancelStageConfirm}
        handlePositive={handleCancelStageConfirm}
        handleNegative={() => setShowCancelStageConfirm(false)}
      />
      <MiModalConfirm
        title="Unschedule Stage"
        description='All walks and jobs in this stage will be set to
        "Unassigned". Do you want to proceed?'
        open={showUnscheduleStageConfirm}
        handlePositive={handleUnscheduleStageConfirm}
        handleNegative={() => setShowUnscheduleStageConfirm(false)}
      />
      <MiModalConfirm
        title="Reschedule Stage"
        description="Any open walks and jobs in this stage will be rescheduled. Do you want to proceed?"
        open={showRescheduleStageConfirm}
        handlePositive={handleRescheduleStageConfirm}
        handleNegative={() => setShowRescheduleStageConfirm(false)}
      />
      <MiModal
        title="Ready For Inspection"
        open={showReadyForInspectionStageConfirm}
        onClose={() => setShowReadyForInspectionStageConfirm(false)}
        actions={[
          {
            onClick: () => setShowReadyForInspectionStageConfirm(false),
            color: theme.palette.primary.grey,
            name: "Close",
            inverse: false,
          },
          {
            onClick: handleShowReadyForInspectionStageConfirm,
            color: theme.palette.primary.green,
            name: "Confirm",
            inverse: true,
          },
        ]}
      >
        <p>Any open work in this stage will be marked &quot;Complete&quot;.</p>
        <p>Do you want to proceed?</p>
      </MiModal>
      <MiModal
        title="Complete Stage"
        open={showCompleteStageConfirm}
        onClose={() => setShowCompleteStageConfirm(false)}
        actions={[
          {
            onClick: () => setShowCompleteStageConfirm(false),
            color: theme.palette.primary.grey,
            name: "Close",
            inverse: false,
          },
          {
            onClick: handleCompleteStageConfirm,
            color: theme.palette.primary.green,
            name: "Confirm",
            inverse: true,
          },
        ]}
      >
        <p>Any open work in this stage will be marked &quot;Complete&quot;.</p>
        <p>Do you want to proceed?</p>
      </MiModal>
      {openImageUrls && (
        <Lightbox
          backdropClosesModal={true}
          currentImage={openImageIndex}
          images={openImageUrls}
          isOpen={openImageUrls.length}
          onClickNext={() => setOpenImageIndex(openImageIndex + 1)}
          onClickPrev={() => setOpenImageIndex(openImageIndex - 1)}
          onClose={() => {
            setOpenImageUrls("");
            setOpenImageIndex(0);
          }}
        />
      )}
      <MiSnackbar
        visible={isEditing}
        handleCancelClick={cancelEditStageDetails}
        handleSaveClick={checkSaveChanges}
        disableSave={
          !edit.getValue("targetCycleTime") ||
          !edit.getValue("propertyStatusId")
        }
      />
    </>
  );
};

export default StageDetails;
