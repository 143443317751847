import React from "react";
import { THEME } from "@miview/theme";
import { calloutColor } from "./calloutColor";

export const CallOut = (props) => {
  const { label, active, onClick } = props;

  return (
    <div
      style={[
        active ? styles.countActive : styles.countItem,
        {
          width: "33%",
          borderColor: calloutColor(true, label),
          backgroundColor: calloutColor(active, label),
        },
      ]}
      onClick={onClick}
    >
      <div
        style={[
          styles.countText,
          {
            color: calloutColor(active, label, true),
          },
        ]}
      >
        {label}
      </div>
      <div
        style={[
          styles.countText,
          {
            fontWeight: "300",
            fontSize: THEME.TEXT_HEADER.fontSize,
            color: calloutColor(active, label, true),
          },
        ]}
      >
        {props.value}
      </div>
    </div>
  );
};

const styles = {
  countItem: {
    borderWidth: 1,
    minWidth: 100,
    marginHorizontal: 5,
    borderRadius: 6,
    padding: 12,
    alignItems: "stretch",
  },
  countActive: {
    borderWidth: 1,
    minWidth: 100,
    marginHorizontal: 5,
    borderRadius: 6,
    padding: 12,
    alignItems: "stretch",
  },
  countText: {
    fontSize: THEME.TEXT_BODY_SMALL.fontSize,
    textAlign: "center",
  },
  borderRed: {
    borderColor: THEME.RED_PRIMARY,
  },
  borderOrange: {
    borderColor: THEME.ORANGE_PRIMARY,
  },
  borderGreen: {
    borderColor: THEME.GREEN_PRIMARY,
  },
};
