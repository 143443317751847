import React, { memo, Fragment, useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Virtuoso } from "react-virtuoso";
import { useAreaStyles } from "../../../components/Standard/MiAreaStyles";
import { RadioButtonChecked, Cancel } from "@mui/icons-material";
import {
  MiArea,
  MiAreaForm,
  MiAreaItem,
  MiStack,
  MiStackForm,
  MiAreaItemForm,
} from "../../../components/Standard/MiView";
import { THEME } from "@miview/theme";
import {
  PLANAREA_FORM_INITIAL_STATE,
  PLANITEM_FORM_INITIAL_STATE,
} from "../constants";
import { MiSwitch, MiDragToZone } from "@miview/components";
import { useEdit } from "@miview/hooks";
import { PaddedDiv } from "../../../components/Standard/StyledComponents/PaddedDiv";
import { AreaItemAdd } from "../../../components/Standard/StyledComponents/AreaItemAdd";

export const filterAndSortItems = (items, walkTypes, showTemplateItems) => {
  const filteredItems = items.filter((i) => {
    return walkTypes.find(
      (t) =>
        t.walkTypeId === i.walkTypeId &&
        (showTemplateItems || !i.isTemplateItem)
    );
  });

  return filteredItems || [];
};

export const HomeSpecPreview = memo((props) => {
  const { classes } = useAreaStyles();
  const {
    propertySections,
    filteredFloors,
    submitPlanItem,
    deleteOrRestorePlanItem,
    walkTypes,
    materialsModalOpen,
    setMaterialsModalOpen,
    setImagePreviewed,
    handleSavePlanArea,
    planAreas,
    planAreasLoading,
    isMobile,
    itemDragging,
    setItemDragging,
    reorderItems,
    rightColumnHeight,
    stackSizes,
    measurementTypesBack,
    measurementTypesSide,
    handleDeletePlanArea,
    handleRestorePlanArea,
    isStacksContent,
    cadBoundaries,
    quickMode,
    handleClickQuick,
    virtuosoRef,
    stageId,
    selectedPlanItem,
    planItemEdit,
    setSelectedPlanItem,
    refreshData = () => null,
  } = props;

  const [selectedPlanArea, setSelectedPlanArea] = useState(
    PLANAREA_FORM_INITIAL_STATE
  );
  const [showTemplateItems, setShowTemplateItems] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const planAreaEdit = useEdit(selectedPlanArea);

  const savePlanArea = () => {
    handleSavePlanArea({
      ...selectedPlanArea,
      ...planAreaEdit.edits,
    });
    setSelectedPlanArea(PLANAREA_FORM_INITIAL_STATE);
    refreshData();
  };

  const handleSubmitPlanItem = (edits) => {
    submitPlanItem({ formData: { ...selectedPlanItem, ...edits } });
  };

  const onCancelAreaEdit = () => {
    planAreaEdit.reset();
    setSelectedPlanArea(PLANAREA_FORM_INITIAL_STATE);
    setAddingNew(false);
  };

  const onCancelPlanItemEdit = () => {
    planItemEdit.reset();
    setSelectedPlanItem(PLANITEM_FORM_INITIAL_STATE);
    setAddingNew(false);
  };

  const handleSelectedPlanItem = (item) => {
    if (item.isTemplateItem) {
      return;
    }
    planItemEdit.reset();
    setSelectedPlanItem(item);
  };

  const formatMeasurementTypes = (types) => {
    return types.map((b) => {
      return { value: b.systemTypeId, text: b.mainValue };
    });
  };

  const renderAddNewItem = (handleAddClick, handleAddStackClick, section) => {
    if (
      !planItemEdit.getValue("itemId") &&
      !planItemEdit.getValue("ruleId") &&
      planItemEdit.getValue("propertyPlanAreaId") === section.propertyPlanAreaId
    ) {
      if (addingNew === "Stack") {
        return renderStackForm("new");
      }

      return renderItemForm("new", planItemEdit.getValue("propertyPlanAreaId"));
    }

    return (
      <PaddedDiv>
        <AreaItemAdd>
          <Button
            aria-label="add-planItem"
            className={classes.areaIconButton}
            style={{ marginRight: isStacksContent ? 10 : 0 }}
            onClick={handleAddClick}
          >
            <AddIcon />
          </Button>
          {isStacksContent && (
            <Button
              aria-label="add-planItemStack"
              className={classes.areaIconStackButton}
              onClick={handleAddStackClick}
              style={{
                backgroundColor: THEME.STEPS_BAR[0],
                paddingRight: 14,
                color: "white",
              }}
            >
              <AddIcon />
              <span>Stack</span>
            </Button>
          )}
        </AreaItemAdd>
      </PaddedDiv>
    );
  };

  const handleAddItem = (planAreaId, propertyPlanAreaId, isStack) => {
    planItemEdit.reset();
    setSelectedPlanItem({
      ...PLANITEM_FORM_INITIAL_STATE,
      planAreaId: planAreaId,
      propertyPlanAreaId: propertyPlanAreaId,
    });
    setAddingNew(isStack || "item");
  };

  const renderItem = (item, index, areaId, canReorder) => {
    const getEditing = () => {
      return (
        (planItemEdit.getValue("planAreaId") === areaId &&
          !planItemEdit.getValue("itemId")) ||
        planItemEdit.getValue("itemId") === item.itemId
      );
    };

    if (
      item.stackSizeId ||
      item.measurementBackTypeId ||
      item.measurementSideTypeId
    ) {
      return (
        <MiStack
          areaId={areaId}
          index={index}
          itemDetails={item}
          key={item.itemId}
          walkTypes={walkTypes}
          deleteOrRestoreItem={deleteOrRestorePlanItem}
          setImagePreviewed={setImagePreviewed}
          canEdit={true}
          setEditingItem={handleSelectedPlanItem}
          canReorder={canReorder}
          setItemDragging={setItemDragging}
          stackSizes={stackSizes}
          measurementTypesBack={formatMeasurementTypes(measurementTypesBack)}
          measurementTypesSide={formatMeasurementTypes(measurementTypesSide)}
        />
      );
    }

    return (
      <MiAreaItem
        areaId={areaId}
        index={index}
        itemDetails={item}
        key={item.itemId}
        edit={planItemEdit}
        editMode={item.itemId === selectedPlanItem.itemId}
        walkTypes={walkTypes}
        setEditingItem={handleSelectedPlanItem}
        editing={getEditing()}
        deleteOrRestoreItem={deleteOrRestorePlanItem}
        setImagePreviewed={setImagePreviewed}
        canEdit={true}
        canReorder={canReorder}
        setItemDragging={setItemDragging}
      />
    );
  };

  const renderItemForm = (edit, areaId) => {
    return (
      <MiAreaItemForm
        key={edit}
        edit={planItemEdit}
        handleCancel={onCancelPlanItemEdit}
        handleUpdate={handleSubmitPlanItem}
        walkTypes={walkTypes}
        materialsModalOpen={materialsModalOpen}
        setMaterialsModalOpen={setMaterialsModalOpen}
        quickMode={quickMode}
        propertyPlanAreaId={areaId}
        isCanNaCheckboxVisible={true}
      />
    );
  };

  const renderStackForm = (edit) => {
    return (
      <MiStackForm
        itemDetails={selectedPlanItem}
        key={edit}
        edit={planItemEdit}
        handleCancel={onCancelPlanItemEdit}
        handleUpdate={handleSubmitPlanItem}
        walkTypes={walkTypes}
        setMaterialsModalOpen={setMaterialsModalOpen}
        stackSizes={stackSizes}
        measurementTypesBack={formatMeasurementTypes(measurementTypesBack)}
        measurementTypesSide={formatMeasurementTypes(measurementTypesSide)}
        cadBoundaries={cadBoundaries}
      />
    );
  };

  const renderMiscItems = (items, propertyPlanAreaId) => {
    const resolvedItems = filterAndSortItems(
      items,
      walkTypes,
      showTemplateItems
    );

    return (
      <Fragment>
        <MiDragToZone
          index={0}
          areaId={propertyPlanAreaId}
          itemDragging={itemDragging}
          reorderItems={reorderItems}
          setItemDragging={setItemDragging}
          filteredItems={resolvedItems}
        />
        {renderItems(resolvedItems, propertyPlanAreaId, true)}
      </Fragment>
    );
  };

  const renderItems = (items, propertyPlanAreaId, canReorder) => {
    if (items) {
      return (
        <div id="items">
          {items.map((item, index) => {
            if (selectedPlanItem.itemId === item.itemId) {
              if (item.measurementBackTypeId || item.measurementSideTypeId) {
                return renderStackForm("edit");
              }
              return renderItemForm("edit", propertyPlanAreaId);
            }

            return (
              <Fragment key={item.itemId}>
                {renderItem(item, index, propertyPlanAreaId, canReorder)}
                {canReorder ? (
                  <MiDragToZone
                    index={index + 1}
                    itemDragging={itemDragging}
                    areaId={propertyPlanAreaId}
                    reorderItems={reorderItems}
                    setItemDragging={setItemDragging}
                    filteredItems={items}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const renderArea = (section) => {
    if (
      section.propertyPlanAreaId === selectedPlanArea.propertyPlanAreaId &&
      planAreaEdit.getValue("adminEdit")
    ) {
      return (
        <MiAreaForm
          edit={planAreaEdit}
          savePlanArea={savePlanArea}
          floors={filteredFloors}
          propertySections={propertySections}
          isDeleted={section.isDeleted}
          isRule={section.isChanged}
          onCancelAreaEdit={onCancelAreaEdit}
        />
      );
    }

    const handleClickEdit = () => {
      setSelectedPlanArea({
        ...section,
        type: "edit",
        adminEdit: true,
        imageUrl: section.imageName,
        planAreaName: section.name,
        planAreaId: section.propertyPlanAreaId,
      });
    };

    const handleClickDelete = () =>
      handleDeletePlanArea({ areaId: section.propertyPlanAreaId });

    const handleClickRestore = () =>
      handleRestorePlanArea({ areaId: section.propertyPlanAreaId });

    const menuOptions = !section.isDeleted
      ? [
          {
            name: "Edit",
            action: handleClickEdit,
          },
          {
            name: "Delete",
            action: handleClickDelete,
          },
        ]
      : [
          {
            name: "Revert to Original",
            action: handleClickRestore,
          },
        ];

    return (
      <MiArea
        name={section.name}
        menuOptions={menuOptions}
        onDoubleClick={handleClickEdit}
        imageUrl={section.imageName}
        canEdit={true}
        isDeleted={section.isDeleted}
        isRule={section.isChanged}
        setImagePreviewed={setImagePreviewed}
        handleRestore={handleClickRestore}
      />
    );
  };

  const renderAreas = (index) => {
    const section = planAreas[index];

    if (Number(stageId) !== Number(section.stageTypeId)) {
      //virtuoso needs a visible DOM node to render or a glitch occurs.
      return <div style={{ height: 1, marginTop: -1 }}></div>;
    }

    const handleAddClick = () => {
      handleAddItem(section.planAreaId, section.propertyPlanAreaId);
    };

    const handleAddStackClick = () => {
      handleAddItem(section.planAreaId, section.propertyPlanAreaId, "Stack");
    };

    return (
      section && (
        <div key={section.propertyPlanAreaId} className="AreaContainer">
          {renderArea(section)}
          <div id="items">
            {renderMiscItems(section.items, section.propertyPlanAreaId)}
            {!section.isDeleted &&
              renderAddNewItem(handleAddClick, handleAddStackClick, section)}
          </div>
        </div>
      )
    );
  };

  const handleRenderAreas = () => {
    return planAreas.map((areas, i) => {
      return renderAreas(i);
    });
  };

  const renderPreviewData = () => {
    if (isMobile) {
      return handleRenderAreas();
    }

    return (
      <Virtuoso
        style={{ height: rightColumnHeight - 110 }}
        totalCount={planAreas.length}
        item={(index) => renderAreas(index)}
        ref={virtuosoRef}
      />
    );
  };

  const renderTemplateItemToggle = () => {
    return (
      <MiSwitch
        label={"Show Template Items"}
        value={showTemplateItems}
        handleChange={() => setShowTemplateItems(!showTemplateItems)}
      ></MiSwitch>
    );
  };

  const renderQuickModeTips = () => {
    return isStacksContent ? (
      <div>Click the CAD image to place the stack</div>
    ) : (
      <div>ctrl+v = paste, &#8594; = skip</div>
    );
  };

  const renderQuickModeButton = () => {
    const renderIcon = () => {
      if (quickMode) {
        return <Cancel style={{ color: THEME.RED_PRIMARY }} />;
      }

      return <RadioButtonChecked />;
    };

    return (
      <div onClick={handleClickQuick}>
        <span style={{ color: THEME.GREY_MEDIUM_LIGHT, fontWeight: "normal" }}>
          {quickMode && renderQuickModeTips()}
        </span>
        <span
          style={{
            color: quickMode ? THEME.GREEN_PRIMARY : "black",
            cursor: "pointer",
            marginLeft: 10,
          }}
        >
          Quick Mode {renderIcon()}
        </span>
      </div>
    );
  };

  return (
    <div onDragEnd={() => setItemDragging(false)}>
      <div style={styles.previewTitle}>
        <>
          <div style={{ width: "25%" }}>Spec Preview</div>
          <span style={styles.loader}>{planAreasLoading}</span>
          <div style={{ width: "50%" }}>{renderTemplateItemToggle()}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "50%",
            }}
          >
            {renderQuickModeButton()}
          </div>
        </>
      </div>
      {planAreasLoading !== "Loading..." ? renderPreviewData() : null}
    </div>
  );
});

const styles = {
  previewTitle: {
    color: "#32C5FF",
    fontWeight: "bold",
    fontSize: 16,
    padding: 4,
    borderBottom: "1px solid #dddbda",
    margin: 6,
    display: "flex",
    alignItems: "center",
  },
  loader: {
    color: "black",
    fontSize: 14,
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    textAlign: "left",
  },
  raised: {
    zIndex: 1000,
  },
  stageSelect: {
    width: 120,
  },
};
