import React, { useRef } from "react";
import { MiButton, MiIcon, MiIconButton, MiInputTextbox } from "../index.ts";
import { mdiEye, mdiClose } from "@mdi/js";

export const MiInputFile = (props) => {
  const {
    disabled,
    label,
    filename,
    handleChange,
    onView,
    onClear,
    required,
    accept,
    ...rest
  } = props;
  const inputRef = useRef();

  const handleClear = () => {
    inputRef.current.value = null;
    onClear();
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "row", position: "relative" }}
    >
      <MiButton
        disabled={disabled}
        component="label"
        title={" "}
        titleStyle={{
          flex: 1,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        style={{
          opacity: 0,
          position: "absolute",
          top: 0,
          left: -3,
          width: "100%",
          height: 50,
          zIndex: 200,
        }}
      >
        <input
          hidden
          ref={inputRef}
          type="file"
          onChange={handleChange}
          accept={accept}
          {...rest}
        />
      </MiButton>
      <MiInputTextbox
        labelText={label}
        value={filename}
        required={required}
        minWidth="100%"
        placeholder={"Add Attachment"}
        editable={false}
        sx={{
          "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        disabled={disabled}
      />
      {!disabled && filename && onClear && (
        <MiIconButton>
          <MiIcon path={mdiClose} onClick={handleClear} size={0.8} />
        </MiIconButton>
      )}
      {filename && onView && (
        <MiIconButton>
          <MiIcon path={mdiEye} onClick={onView} />
        </MiIconButton>
      )}
    </div>
  );
};
