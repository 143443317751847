import React from "react";
import { useValidationRadioListStyles } from "./ValidationRadioListStyles";

const ValidationRadioList = (props) => {
  const { data } = props;
  const classes = useValidationRadioListStyles();

  return (
    <div className={classes.radioAddContainer}>
      {data &&
        data.map((item, index) => (
          <div
            className={classes.customRadioButton}
            key={item.key ? item.key : `radioList${index}`}
          >
            <div className={classes.radioAdd}>
              <input
                type="radio"
                name={index}
                id={item.id}
                checked={item.isChecked}
                onChange={item.action}
              />
              <label className={classes.label} htmlFor={item.id}>
                <span className={classes.ohIconAdd}></span>
                <span className={classes.addText}>
                  <span className={classes.serviceTitle}>{item.label}</span>
                  <br />
                  <span className={classes.valueText}>{item.value}</span>
                </span>
              </label>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ValidationRadioList;
