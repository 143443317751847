import React, { useEffect, useState } from "react";
import {
  MiHeader,
  MiDetailsSideBar,
} from "../../../components/Standard/MiView";
import {
  cityService,
  communityService,
  countyService,
  timezoneService,
} from "@miview/api";
import { useRouter, useEdit, useComponentState } from "@miview/hooks";
import { HTTP_STATUSES, STATES } from "@miview/constants";
import { useTheme } from "@mui/material/styles";
import {
  MiLink,
  MiSnackbar,
  MiDetailFields,
  MiCard,
  MiBox,
} from "@miview/components";
import { mdiAccountGroup } from "@mdi/js";
import { createFilter } from "@miview/utils";

const City = () => {
  const [city, setCity] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [counties, setCounties] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const router = useRouter();
  const edit = useEdit(city);
  const stateManager = useComponentState();
  const theme = useTheme();
  const filterHelper = createFilter();

  const { id: cityId } = router.params;

  useEffect(() => {
    reset();
    getCommunities();
    getTimezones();
  }, []);

  const reset = () => {
    stateManager.run(async () => {
      const response = await cityService.get(cityId);
      if (response.status === HTTP_STATUSES.OK) {
        setCity(response.data);
        getCounties(response.data.stateId);
      }
    });
  };

  const getCommunities = () => {
    const filters = filterHelper.equals({
      cityId: cityId,
    });
    stateManager.run(async () => {
      const response = await communityService.getAll({
        filters,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setCommunities(response.data);
      }
    });
  };

  const getCounties = (stateId) => {
    const filters = filterHelper.equals({
      stateId,
    });
    stateManager.run(async () => {
      const response = await countyService.getAll({
        params: { pageSize: 9999 },
        filters,
        sort: [{ sort: "asc" }],
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = response.data.map((c) => {
          return { ...c, value: c.countyId, label: c.countyName };
        });
        setCounties(mapped); //filter counties by state at dropdown level.
      }
    });
  };

  const getTimezones = () => {
    stateManager.run(async () => {
      const response = await timezoneService.getAll();
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = response.data.map((e) => {
          return { value: e, text: e };
        });
        setTimezones(mapped);
      }
    });
  };

  const saveEdits = () => {
    stateManager.run(async () => {
      const response = await cityService.update(cityId, edit.edits);
      if (response.status === HTTP_STATUSES.OK) {
        edit.reset();
        reset();
        setEditMode(!editMode);
      }
    });
  };

  const cancelEdit = () => {
    edit.reset();
    setEditMode(!editMode);
    reset();
  };

  const renderCommunities = () => {
    return communities.map((community, key) => {
      return (
        <div key={key} style={{ paddingBottom: 7 }}>
          <MiCard
            color={theme.palette.accent.green}
            icon={mdiAccountGroup}
            title={
              <MiLink
                title={community.name}
                to={`/communities/${community.id}`}
              />
            }
          />
        </div>
      );
    });
  };

  const toggleDetailFields = () => {
    setEditMode(!editMode);
  };

  const detailFields = [
    {
      label: "Name",
      value: edit.getValue("cityName"),
      readOnly: !editMode,
      width: "50%",
      clickButton: toggleDetailFields,
      setValue: (e) => edit.update({ cityName: e }),
      required: true,
    },
    {
      label: "State",
      fieldType: "select",
      options: STATES.map((i) => {
        return { value: i.id, text: i.name };
      }),
      value: edit.getValue("stateId"),
      width: "50%",
      readOnly: !editMode,
      clickButton: toggleDetailFields,
      required: true,
      setValue: (e) => {
        if (e) {
          getCounties(e);
        }
        edit.update({
          stateId: e,
          countyIds: [],
        });
      },
    },
    {
      label: "Timezone",
      fieldType: "select",
      options: timezones,
      value: edit.getValue("timezone"),
      width: "50%",
      required: true,
      readOnly: !editMode,
      clickButton: toggleDetailFields,
      setValue: (e) => edit.update({ timezone: e }),
    },
    {
      label: "License Required",
      fieldType: "checkbox",
      value: edit.getValue("licenseRequired"),
      width: "50%",
      disabled: !editMode,
      readOnly: !editMode,
      clickButton: toggleDetailFields,
      setValue: () =>
        edit.update({
          licenseRequired: !edit.getValue("licenseRequired"),
        }),
    },
    {
      label: "Counties",
      fieldType: "multiSelect",
      options: counties,
      value: edit.getValue("countyIds"),
      width: "50%",
      disabled: !editMode,
      readOnly: !editMode,
      clickButton: toggleDetailFields,
      setValue: (e) => edit.update({ countyIds: e.target.value }),
    },
  ];

  return (
    <div>
      {stateManager.statusTag("cityDetailsStatus")}
      <MiHeader
        color={theme.palette.primary.main}
        title={edit.getValue("cityName")}
        inverse={false}
        icon="arrow_back"
        backAction={router.goBack}
      />

      <MiSnackbar
        visible={editMode}
        handleCancelClick={cancelEdit}
        handleSaveClick={saveEdits}
        disableSave={!edit.allFilled("cityName", "stateId", "timezone")}
      />

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          width: "100%",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: "1rem",
        }}
      >
        <MiBox styles={{ width: "70%" }}>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            {city.cityId && (
              <div>
                <MiDetailFields
                  detailFields={detailFields}
                  headerProps={{
                    icon: "list",
                    title: "Detail Fields",
                  }}
                />
              </div>
            )}
          </div>
        </MiBox>
        <MiDetailsSideBar
          titleWidth={200}
          sidebarTitle="Communities in this City"
        >
          <div style={{ height: "55vh", overflow: "auto" }}>
            {renderCommunities()}
          </div>
        </MiDetailsSideBar>
      </div>
    </div>
  );
};

export default City;
