import React, { Fragment, useState } from "react";
import { Grid } from "@mui/material";
import BallotIcon from "@mui/icons-material/Ballot";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Collapse from "@mui/material/Collapse";
import {
  MiSpecsTitle,
  MiSpecsSubTitle,
  MiAddArea,
} from "../../../components/Standard/MiView";
import { CATEGORIES } from "../constants";
import { MiButton } from "@miview/components";
import { THEME } from "@miview/theme";
import { useEdit } from "@miview/hooks";
import { PLANAREA_FORM_INITIAL_STATE } from "../../HomeSpecs/constants";

export const AreasPage = ({
  rightColumnOn,
  setRightColumnOn,
  planEdit,
  floors,
  propertySections,
  planAreaForm,
  planAreas,
  addNewArea,
  removeNewArea,
  saveFullArea,
  saveAreaBasic,
  planAreasLoading,
}) => {
  const [selectedArea, setSelectedArea] = useState(PLANAREA_FORM_INITIAL_STATE);
  const edit = useEdit(selectedArea);

  const renderTitle = (area) => {
    return area.name || "New Area";
  };

  const toggleFormOn = (area) => {
    setSelectedArea(area);
  };

  const resetIntialState = () => {
    setSelectedArea({});
    edit.reset();
  };

  const handleCreateArea = async () => {
    await saveAreaBasic({
      ...edit.edits,
    });
    resetIntialState();
  };

  const handleUpdateArea = async () => {
    await saveFullArea({
      ...selectedArea,
      ...edit.edits,
    });
    resetIntialState();
  };

  const renderAddArea = (addAreaProps) => {
    return (
      <Fragment>
        <MiAddArea
          {...addAreaProps}
          floors={floors}
          numberOfFloors={planEdit.getValue("numberOfFloors")}
          isTemplate={planEdit.getValue("isTemplate")}
          propertySections={propertySections}
          category={CATEGORIES[0]}
          edit={edit}
          saveArea={handleCreateArea}
          sectionDisabled={Boolean(addAreaProps.area?.propertySectionId)}
        />
      </Fragment>
    );
  };

  const clearArea = (id) => {
    if (!id) {
      removeNewArea();
    }
    resetIntialState();
  };

  const handleAreaSave = (area) => {
    if (area.id) {
      handleUpdateArea();
    } else {
      handleCreateArea();
    }
  };

  const renderArea = (area = {}) => {
    const id = area.id;
    const isEditing = selectedArea?.id === id && !planAreaForm.adminEdit;
    const isComplete = !area.isDraft && area.isComplete;

    return (
      <Grid item key={area.id} style={{ width: "100%" }}>
        <div
          style={{
            ...styles.planAreaHeader,
            backgroundColor: isComplete
              ? THEME.GREEN_PRIMARY
              : THEME.BLUE_DARK_ALT_2,
          }}
          onClick={selectedArea.id ? clearArea : () => toggleFormOn(area)}
        >
          {isComplete ? (
            <DoneIcon style={styles.addIcon} />
          ) : (
            <ErrorOutlineIcon style={styles.addIcon} />
          )}
          <div style={styles.areaNameTitle}>{renderTitle(area)}</div>
        </div>
        <Collapse in={isEditing}>
          <Fragment>
            {renderAddArea({ area })}
            <div style={styles.saveAreaButtonContainer}>
              <MiButton
                style={styles.saveButton}
                title="Submit"
                onClick={() => {
                  handleAreaSave(area);
                }}
                disabled={!edit.allFilled("name", "propertySectionId")}
              />
              <MiButton
                style={styles.cancelButton}
                title="Cancel"
                onClick={() => {
                  clearArea(id);
                }}
              />
            </div>
          </Fragment>
        </Collapse>
      </Grid>
    );
  };

  const renderAreas = () => {
    return planAreas.map((area) => {
      return renderArea(area);
    });
  };

  const newDraftEnabled =
    !planAreas.length || planAreas[planAreas.length - 1].id;

  return (
    <Fragment>
      <MiSpecsTitle
        icon={<BallotIcon style={styles.titleIcon} />}
        setRightColumnOn={setRightColumnOn}
        rightColumnOn={rightColumnOn}
      >
        Add Areas
      </MiSpecsTitle>
      <MiSpecsSubTitle>Add areas associated with standard plan</MiSpecsSubTitle>
      <Grid container spacing={2}>
        {!planAreasLoading && renderAreas()}
        {newDraftEnabled ? (
          <Grid item style={{ width: "100%" }}>
            <div
              style={{
                ...styles.planAreaHeader,
                border: `1px dashed ${THEME.BLUE_SECONDARY}`,
              }}
              onClick={addNewArea}
            >
              <AddIcon style={styles.addIconBlue} />
              <div
                style={{ ...styles.areaNameTitle, color: THEME.BLUE_SECONDARY }}
              >
                Add New Area
              </div>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};

AreasPage.propTypes = {};

const styles = {
  titleIcon: { color: THEME.STEPS_BAR[1] },
  planAreaHeader: {
    display: "flex",
    height: 48,
    width: "100%",
    borderRadius: 2,
    marginTop: 10,
    alignItems: "center",
    cursor: "pointer",
  },
  addIcon: {
    color: "white",
    height: 32,
    width: 32,
    marginLeft: 6,
    cursor: "pointer",
  },
  addIconBlue: {
    color: THEME.BLUE_SECONDARY,
    height: 32,
    width: 32,
    marginLeft: 6,
    cursor: "pointer",
  },
  categoryIcon: {
    color: "white",
    height: 30,
    width: 30,
    marginRight: 12,
  },
  areaNameTitle: {
    flex: 1,
    color: "white",
    fontSize: 16,
    paddingLeft: 12,
  },
  saveAreaButtonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: 20,
  },
  saveButton: {
    backgroundColor: THEME.GREEN_PRIMARY,
    color: "white",
    fontSize: 14,
    height: 30,
    width: 100,
    border: "none",
  },
  cancelButton: {
    fontSize: 14,
    height: 30,
    width: 100,
  },
  addAreaButton: {
    backgroundColor: THEME.BLUE_SECONDARY,
    border: "none",
    color: "white",
    width: 200,
    height: 40,
    margin: "30px auto",
  },
};
