const flexStyles = {
  flex_one: {
    flex: 1,
  },
  flex_grow_one: {
    flexGrow: 1,
  },
  flex_wrap: {
    flexWrap: "wrap",
  },
  width_100: {
    width: "100%",
  },
  textAlign_right: {
    textAlign: "right",
  },
  alignItems_end: {
    alignItems: "flex-end",
  },
  direction_row: {
    content_start_items_center: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    content_center_items_center: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    items_end: {
      flexDirection: "row",
      alignItems: "flex-end",
    },
    items_center: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  direction_column: {
    content_start_items_stretch: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
    },
    content_start_items_start: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
};
export default flexStyles;
