import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useRouter } from "@miview/hooks";
import { planService, planAreaService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const savePlanArea =
  ({ planId, planAreaForm, resetForm, refreshData }) =>
  async () => {
    try {
      const requestPayload = {
        planId,
        planAreaId: planAreaForm.planAreaId || 0,
        name: planAreaForm.planAreaName,
        propertySectionId: planAreaForm.propertySectionId,
        displayOrder: 1,
        floorId: planAreaForm.floorId,
        image: planAreaForm.image,
      };

      if (planAreaForm.planAreaId === 0) {
        await planAreaService.create(requestPayload);
      } else {
        await planAreaService.update(planAreaForm.planAreaId, requestPayload);
      }

      refreshData();
      resetForm();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

const PLANAREA_FORM_INITIAL_STATE = {
  categoryId: 0,
  planAreaId: 0,
  propertyPlanAreaId: 0,
  propertySectionId: 0,
  planAreaName: "",
  floorId: "",
  isGroundFloor: false,
  imageUrl: "",
  adminEdit: false,
  isRequired: false,
};

const INITIAL_STATE = {
  planAreas: [],
  setPlanAreas: () => null,
  planAreasPreview: [],
  setPlanAreasPreview: () => null,
  planAreasLoading: "",
  planAreaForm: PLANAREA_FORM_INITIAL_STATE,
  setPlanAreaForm: () => null,
  setPlanAreasLoading: "",
  isConfirmingDelete: 0,
  setIsConfirmingDelete: () => null,
  handleSavePlanArea: () => null,
  handleDeletePlanArea: () => null,
  toggleFormOn: () => null,
  resetForm: () => null,
  updateForm: () => null,
  addNewArea: () => null,
  saveAreaBasic: () => null,
  saveFullArea: () => null,
  refreshData: () => null,
  planAreaEdting: "",
};

const BLANK_AREA = {
  categoryId: "",
  displayOrder: null,
  floorId: null,
  isGroundFloor: false,
  name: "",
  plan: "",
  planArea: "",
  planAreaId: 0,
  planId: null,
  planItems: [],
  propertySection: "",
  propertySectionId: null,
  isDraft: true,
  isRequired: false,
};

const handleGetPlanAreas = async ({ planId, setPlanAreas }) => {
  const planAreas = await planService.getAreas(planId);
  if (planAreas.status === HTTP_STATUSES.OK) {
    setPlanAreas(planAreas.data);
  }
};

const handleGetPlanAreasPreview = async ({ planId, setPlanAreasPreview }) => {
  const planAreasPreview = await planService.applyOptions(planId);
  if (planAreasPreview.status === HTTP_STATUSES.OK) {
    setPlanAreasPreview(planAreasPreview.data);
  }
};

export const PlanAreasContext = React.createContext({
  ...INITIAL_STATE,
});

export const PlanAreasProvider = ({ children }) => {
  const {
    query: { planId, stepIndex },
  } = useRouter();
  const [planAreaForm, setPlanAreaForm] = useState(INITIAL_STATE.planAreaForm);
  const [planAreas, setPlanAreas] = useState(INITIAL_STATE.planAreas);
  const [planAreasPreview, setPlanAreasPreview] = useState(
    INITIAL_STATE.planAreasPreview
  );
  const [planAreasLoading, setPlanAreasLoading] = useState(
    INITIAL_STATE.planAreasLoading
  );
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(
    INITIAL_STATE.isConfirmingDelete
  );

  const resetForm = () => {
    setPlanAreaForm({ ...PLANAREA_FORM_INITIAL_STATE });
  };

  const refreshData = () => {
    handleGetPlanAreas({ planId, setPlanAreas });
    handleGetPlanAreasPreview({ planId, setPlanAreasPreview });
  };

  const handleSavePlanArea = savePlanArea({
    planId,
    planAreaForm,
    resetForm,
    refreshData,
  });

  const handleDeletePlanArea = async ({ areaId }) => {
    if (stepIndex === "1") {
      await planAreaService.delete(areaId);
      refreshData();
    }

    return false;
  };

  const toggleFormOn = async (area) => {
    if (area?.planAreaId) {
      return setPlanAreaForm({
        ...PLANAREA_FORM_INITIAL_STATE,
        planAreaId: area.planAreaId,
        planAreaName: area.name,
        isGroundFloor: area.isGroundFloor,
        propertySectionId: area.propertySectionId,
        floorId: area.floorId,
        isRequired: area.isRequired,
      });
    }

    setPlanAreaForm({
      ...PLANAREA_FORM_INITIAL_STATE,
      planAreaId: 0,
    });
  };

  const updateForm = (e) => {
    setPlanAreaForm({
      ...planAreaForm,
      [e.target.name]: e.target.value,
    });
  };

  const addNewArea = async () => {
    setPlanAreas(planAreas.concat([BLANK_AREA]));
  };

  const saveAreaBasic = async () => {
    const requestPayload = {
      propertySectionId: planAreaForm.propertySectionId,
      areaName: planAreaForm.planAreaName,
      floorId: planAreaForm.floorId,
      isRequired: planAreaForm.isRequired,
    };

    const result = await planService.createArea(planId, requestPayload);
    if (result?.data) {
      refreshData();
      setPlanAreaForm({
        ...planAreaForm,
        planAreaId: result.data.planAreaId,
      });
    }
  };

  const saveFullArea = async () => {
    await planAreaService.submit(planAreaForm.planAreaId, {
      areaName: planAreaForm.planAreaName,
      floorId: planAreaForm.floorId,
      isRequired: planAreaForm.isRequired,
    });
    refreshData();
    resetForm();
  };

  const planAreaEditing = planAreaForm.planAreaId;

  useEffect(() => {
    if (planId) {
      refreshData();
    }
  }, [planId]);

  const contextValue = {
    planAreas,
    planAreasPreview,
    setPlanAreas,
    setPlanAreasPreview,
    planAreasLoading,
    planAreaForm,
    setPlanAreaForm,
    setPlanAreasLoading,
    isConfirmingDelete,
    setIsConfirmingDelete,
    handleSavePlanArea,
    handleDeletePlanArea,
    toggleFormOn,
    resetForm,
    updateForm,
    addNewArea,
    saveAreaBasic,
    saveFullArea,
    refreshData,
    planAreaEditing,
  };

  return (
    <PlanAreasContext.Provider value={contextValue}>
      {children}
    </PlanAreasContext.Provider>
  );
};

PlanAreasProvider.propTypes = {
  children: PropTypes.object,
};
