import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { THEME } from "../../../components/Standard/theme";
import { useTheme } from "@mui/material";
import { propertyService, planService } from "@miview/api";
import { STAGE_STATUSES, HTTP_STATUSES, AND_FILTER } from "@miview/constants";
import { toList } from "../../../utils";
import { createFilter } from "@miview/utils";
import {
  MiAutocomplete,
  MiModal,
  MiButton as Button,
} from "@miview/components";
import { useDebounce } from "@miview/hooks";

const REVERT_TEXT_WARNING =
  "The plan for the selected stage(s) will be reverted.";

export const RefreshModal = ({
  invokeRefresh,
  refreshModal,
  setRefreshModal,
  property,
  propertyId,
}) => {
  const [planId, setPlanId] = useState(property.planId);
  const [propertyPlans, setPropertyPlans] = useState([]);
  const [propertyStages, setPropertyStages] = useState([]);
  const [selectedStages, setSelectedStages] = useState([]);
  const [plansSearchTerm, setPlansSearchTerm] = useState("");
  const debouncedPlansSearchTerm = useDebounce(plansSearchTerm, 300);

  const theme = useTheme();

  useEffect(() => {
    getPlans(plansSearchTerm);
  }, [debouncedPlansSearchTerm]);

  const getPlans = async (search) => {
    const filter = createFilter(AND_FILTER);
    filter.equals({
      builderId: [null, property.builderId],
      communityId: [null, property.communityId],
    });
    const response = await planService.getPlans({
      filters: filter.getFilter(),
      sort: [{ field: "name", sort: "asc" }],
      params: { pageSize: 20, search },
    });
    if (response.status !== HTTP_STATUSES.OK) {
      setPropertyPlans([]);
      return;
    }
    const mapped = toList(response.data, "id", "name");
    setPropertyPlans(mapped);
  };

  const getStages = async () => {
    const response = await propertyService.getStages({ id: propertyId });
    if (response.status !== HTTP_STATUSES.OK) {
      return;
    }
    setPropertyStages(response.data);
  };

  useEffect(() => {
    getStages();
  }, []);

  useEffect(() => {
    getPlans(plansSearchTerm);
  }, [debouncedPlansSearchTerm]);

  const handlePropertyStageClick = (id) => {
    const isSelected = selectedStages.indexOf(id);

    if (isSelected !== -1) {
      setSelectedStages(selectedStages.filter((stageId) => stageId !== id));
    } else {
      setSelectedStages([...selectedStages, id]);
    }
  };

  const renderStageButton = (stage) => {
    const isSelected = selectedStages.indexOf(stage.id);
    const isStageUnselectable = [
      STAGE_STATUSES.SCHEDULED,
      STAGE_STATUSES.IN_PROGRESS,
      STAGE_STATUSES.STAGE_COMPLETE,
    ].includes(stage.propertyStatusId);

    let buttonStyle = styles.stageButton;

    if (isSelected !== -1) {
      buttonStyle = styles.stageButtonSelected;
    }

    if (isStageUnselectable) {
      buttonStyle = styles.stageButtonDisabled;
    }

    return (
      <Button
        key={stage.id}
        style={buttonStyle}
        onClick={() => {
          handlePropertyStageClick(stage.id);
        }}
        disabled={isStageUnselectable}
      >
        <div>
          <div style={styles.stageType}>{stage.stageTypeName}</div>
          <div style={styles.stageStatus}>{stage.propertyStatus}</div>
        </div>
      </Button>
    );
  };

  const onPlanSelectionChange = (value) => {
    setPlanId(value?.value);
    setPlansSearchTerm(value?.text || "");
  };

  const renderStageRow = (stages) => {
    return (
      <div style={styles.buttonRow}>
        {stages.map((stage) => renderStageButton(stage))}
      </div>
    );
  };

  const handleRevert = () => {
    invokeRefresh(planId, selectedStages);
    setRefreshModal(false);
  };

  return (
    <MiModal
      title="Are you sure you want to revert?"
      open={refreshModal}
      onClose={() => setRefreshModal(false)}
      actions={[
        {
          name: "Cancel",
          onClick: () => setRefreshModal(false),
          color: theme.palette.medium.grey,
          inverse: false,
        },
        {
          name: "Revert",
          onClick: handleRevert,
          color: theme.palette.primary.red,
          inverse: true,
          disabled: !selectedStages.length,
        },
      ]}
    >
      <div style={textStyle}>{REVERT_TEXT_WARNING}</div>
      <div style={formContainer}>
        <MiAutocomplete
          getOptionLabel={(item) => item.text}
          labelText="Plan"
          inputValue={plansSearchTerm}
          onInputChange={(_, value) => setPlansSearchTerm(value || "")}
          onSelectionChange={(_, value) => onPlanSelectionChange(value)}
          options={propertyPlans}
          required
          clearOnBlur={true}
          freeSolo={false}
          autoHighlight
          disabled={!propertyStages.length}
          labelTextColor={theme.palette.medium.grey}
        />
      </div>
      <div style={textStyle}>Select the stages you want to revert.</div>
      <div style={formContainer}>{renderStageRow(propertyStages)}</div>
    </MiModal>
  );
};

const textStyle = {
  textAlign: "center",
};

const formContainer = {
  margin: "20px 100px 0 100px",
};

RefreshModal.propTypes = {
  invokeRefresh: PropTypes.func,
  setRefreshModal: PropTypes.func,
  property: PropTypes.object,
};

const styles = {
  stageButton: {
    display: "flex",
    flexDirection: "column",
    color: THEME.GREY_MEDIUM_ALT,
    border: "2px solid #DDDDDD",
    borderRadius: 5,
    minWidth: 100,
  },
  stageButtonSelected: {
    display: "flex",
    flexDirection: "column",
    color: "#FFF",
    border: "2px hidden",
    borderRadius: 5,
    backgroundColor: "#3EA9E2",
    minWidth: 100,
  },
  stageButtonDisabled: {
    display: "flex",
    flexDirection: "column",
    color: "#3e3e3c",
    border: "2px solid #DDDDDD",
    borderRadius: 5,
    backgroundColor: "#DDDDDD",
    opacity: 0.5,
    minWidth: 100,
  },
  stageType: {
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    fontSize: 14,
    lineHeight: 1.2,
    marginBottom: 5,
  },
  stageStatus: {
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    fontSize: 9,
    lineHeight: 1.2,
  },
  buttonRow: {
    display: "flex",
    justifyContent: "space-between",
  },
};
