import { useRouter } from "@miview/hooks";
import { usePlanOptions, useResetPage } from "./";
import { planService, optionService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const usePlanOptionUpdate = (config = {}) => {
  const {
    query: { propertyId, planId },
  } = useRouter();
  const { planOptionForm, setPlanOptions, resetForm } = usePlanOptions(
    planId,
    propertyId
  );
  const resetPage = useResetPage();
  const CUSTOM_OPTION_TYPE = 186;
  const saveDraft = async () => {
    try {
      const requestPayload = {
        propertyId,
        planId,
        name: config.planOptionEdit.optionName,
        description: config.planOptionEdit.optionDescription,
        optionType: config.planOptionEdit.optionTypeId || CUSTOM_OPTION_TYPE,
      };

      const response = await optionService.create(requestPayload);

      if (response.status === HTTP_STATUSES.OK) {
        config.setPlanOptionEdit({
          optionId: response.data,
        });
        return response.data;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const saveAreaActionAdd = async (id, areaEdits) => {
    try {
      const requestPayload = {
        optionId: id,
        action: "Add",
        floorId: areaEdits.floorId || "",
        name: areaEdits.name,
        propertySectionId: areaEdits.propertySectionId,
      };

      await optionService.createAreaRule(id, requestPayload);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const saveAreaActionRemove = async () => {
    const requestPayload = {
      optionId: planOptionForm.optionId,
      action: "Remove",
      planAreaId: planOptionForm.actionForm.planAreaId,
    };

    await optionService.createAreaRule(planOptionForm.optionId, requestPayload);

    updateOptionsAfterSubmission();
  };

  const saveAreaActionUpdate = async (id, areaEdits) => {
    const requestPayload = {
      optionId: id,
      action: "Update",
      name: areaEdits.planAreaName,
      propertySectionId: areaEdits.propertySectionId,
      planAreaOptionRuleId: areaEdits.planAreaOptionRuleId,
    };

    await optionService.createAreaRule(id, requestPayload);

    updateOptionsAfterSubmission();
  };

  const saveOption = async () => {
    try {
      const requestPayload = {
        id: config.planOptionEdit.optionId,
        name: config.planOptionEdit.optionName,
        description: config.planOptionEdit.optionDescription,
      };

      await optionService.submit(requestPayload);

      if (planOptionForm.miscItemForm.itemName) {
        const { miscItemForm } = planOptionForm;
        const ruleRequestPayload = {
          optionId: planOptionForm.optionId,
          itemName: miscItemForm.itemName,
          description: miscItemForm.description,
          topImageUrl: miscItemForm.topImageUrl,
          frontImageUrl: miscItemForm.frontImageUrl,
          walkTypeId: miscItemForm.walkTypeId,
          ruleId: miscItemForm.id || undefined,
          planAreaId: planOptionForm.actions[0].planAreaId,
          itemId: miscItemForm.itemId || 0,
          areaRuleId: miscItemForm.ruleId
            ? undefined
            : planOptionForm.actions[0].id,
          materialId: miscItemForm.materialId,
        };

        await optionService.createOrUpdateRule(
          planOptionForm.optionId,
          ruleRequestPayload
        );
      }

      if (config.fetchOptions) {
        const result = await config.fetchOptions(propertyId, config.planId);
        setPlanOptions(result.customOptions);
      } else {
        const planOptions = await planService.getOptions(planId);
        if (planOptions.status === HTTP_STATUSES.OK) {
          setPlanOptions(planOptions.data);
        }
      }

      resetPage(propertyId);
      resetForm();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const updateOptionsAfterSubmission = async () => {
    const result = await config.fetchOptions(propertyId, config.planId);
    if (result.status === HTTP_STATUSES.OK) {
      const planOption = result.data.customOptions.find(
        (option) => option.optionId === planOptionForm.optionId
      );

      setPlanOptions(result.customOptions);
      config.setPlanOptionEdit({ ...planOption });
    }
  };

  return {
    saveOption,
    saveDraft,
    saveAreaActionAdd,
    saveAreaActionRemove,
    saveAreaActionUpdate,
    updateOptionsAfterSubmission,
  };
};
