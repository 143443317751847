export const TOAST_TYPES = {
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "success",
  INFO: "info",
  LINK: "link",
};

export const STATUS = {
  active: 1,
  inactive: 2,
  active2: 5,
  inActive2: 6,
  delete: 7,
};

export const DELIVERY_LOCATION_STATUS = {
  active: "Active",
  inactive: "Inactive",
};

export const STATUS_DROPDOWN = ["Active", "Inactive"];

export const APPLICATION_ACCOUNT_MANAGEMENT = {
  ACCOUNT: "Account",
  SUPPLIER: "Supplier",
  BUILDER: "Builder",
  MANUFACTURER: "Manufacturer",
  SUBCONTRACTOR: "Subcontractor",
};
export const APPLICATION_CONTACT_MANAGEMENT = {
  PURCHASING: "Purchasing",
  SALES: "Sales",
  ACCOUNTING: "Accounting",
};
