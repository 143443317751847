import React, { useState } from "react";
import { Tabs, Tab, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import PropTypes from "prop-types";
import { ORIENTATION } from "@miview/constants";
import { MiTHEME } from "@miview/theme";
import { MiCard, MiToolTip } from "../index.ts";

const useStyles = makeStyles()((theme, { props }) => {
  return {
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
    },
    indicator: {
      display: "flex",
      height: 3,
      backgroundColor: props.indicatorColor || theme.palette?.primary.main,
      alignSelf: "start",
      left: 0,
    },
    root: {
      fontSize: 16,
      textTransform: "none",
      minWidth: "100px",
    },
  };
});

export const MiTabSidebar = (props) => {
  const {
    tabs = [],
    onTabChange,
    currenttab,
    renderTabContent,
    renderTabHeader,
    contentWidth,
  } = props;

  const [value, setValue] = useState(currenttab || tabs[0].value || 0);
  const activeTab = tabs.find((tab) => tab.value === value);
  const { classes } = useStyles({
    props: {
      indicatorColor: activeTab.indicatorColor,
    },
  });

  const handleChange = (e, newValue) => {
    setValue(newValue);
    onTabChange && onTabChange(newValue);
  };

  const renderIcon = (tab) => {
    if (!tab.renderIcon) {
      return null;
    }
    return <MiToolTip title={tab.label}>{tab.renderIcon()}</MiToolTip>;
  };

  const getTabStyles = (tab) => {
    return {
      backgroundColor:
        value === tab.value
          ? tab.activeBackgroundColor || MiTHEME.palette.light.blue
          : MiTHEME.palette?.primary.white,
    };
  };

  return (
    <div className={classes.wrapper}>
      <div data-testid="MiTabSidebar">
        <Tabs
          onChange={handleChange}
          value={value}
          orientation={ORIENTATION.VERTICAL}
          indicatorcolor={activeTab.indicatorColor}
          classes={{
            indicator: classes.indicator,
          }}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={index}
                value={tab.value || index}
                icon={renderIcon(tab)}
                disabled={tab.disabled}
                classes={{ root: classes.root }}
                style={getTabStyles(tab)}
              />
            );
          })}
        </Tabs>
        <Divider />
      </div>
      <div style={{ width: contentWidth }}>
        <MiCard
          renderHeader={() => renderTabHeader && renderTabHeader(activeTab)}
          renderBody={() => renderTabContent && renderTabContent(activeTab)}
        />
      </div>
    </div>
  );
};

MiTabSidebar.propTypes = {
  /**
   * array of tab config with label and value
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      iconPosition: PropTypes.oneOf(["start", "end", ""]),
      renderIcon: PropTypes.func,
    })
  ),
  /**
   * value of the current active tab
   */
  currenttab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   *  callback on tab change with the tab value
   */
  onTabChange: PropTypes.func,
  /**
   * overriding styles
   */
  style: PropTypes.object,
  /**
   * callback with tab value as input and return current tab content jsx
   */
  renderTabContent: PropTypes.func,
  /**
   * callback with tab value as input and return current tab header jsx
   */
  renderTabHeader: PropTypes.func,
  /**
   * color of the active tab indicator
   */
  tabIndicatorColor: PropTypes.string,
  /**
   * indicates the width of the side bar tab content
   */
  contentWidth: PropTypes.string,
};
