import { APPROVED, PENDING, DECLINED } from "../constants";
import { MiTHEME } from "@miview/theme";

export const DISPUTE_STATUS_COLORS = {
  [APPROVED]: MiTHEME.colors.green.primary,
  [PENDING]: MiTHEME.colors.orange.primary,
  [DECLINED]: MiTHEME.colors.red.primary,
};

export const PAY_DISPUTE_STATUSES = {
  PENDING: 53,
  APPROVED: 54,
  DECLINED: 55,
  CANCELLED: 69,
};
