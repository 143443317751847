import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

const INITIAL_STATE = {
  itemId: "",
  areaId: "",
  itemName: "",
  description: "",
  topImageName: "",
  frontImageName: "",
  materialId: "",
  materialCategoryId: "",
  materialName: "",
  materialBrand: "",
  materialImageName: "",
  quantity: "",
  categoryId: "",
  walkTypeId: "",
  ruleId: "",
  isDeleted: "",
  type: undefined,
  measurementBackTypeId: "",
  measurementBackValue: "",
  measurementSideTypeId: "",
  measurementSideValue: "",
  stackSizeId: "",
  validateBack: true,
  validateSide: true,
};

export const PlanItemFormContext = React.createContext({
  ...INITIAL_STATE,
});

export const PlanItemFormProvider = ({ children }) => {
  const [planItemForm, setPlanItemForm] = useState({});

  const updatePlanItemForm = useCallback(
    (params) => {
      setPlanItemForm({
        ...planItemForm,
        ...params,
      });
    },
    [planItemForm]
  );

  const activateNewItemForm = useCallback((areaId, type) => {
    setPlanItemForm({ planAreaId: areaId, type });
  }, []);

  const resetPlanItemForm = useCallback(() => {
    setPlanItemForm({});
  }, []);

  const editItemForm = (areaId, itemDetails) => {
    setPlanItemForm({ planAreaId: areaId, itemId: itemDetails.itemId });
  };

  const contextValue = {
    planItemForm,
    updatePlanItemForm,
    activateNewItemForm,
    resetPlanItemForm,
    editItemForm,
  };

  return (
    <PlanItemFormContext.Provider value={contextValue}>
      {children}
    </PlanItemFormContext.Provider>
  );
};

PlanItemFormProvider.propTypes = {
  children: PropTypes.object,
};
