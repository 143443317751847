import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";

/**
 * This is component description
 */

const MiCheckbox = ({
  checked,
  onChange,
  label,
  labelPlacement,
  disabled,
  clickButton,
  color,
  ...rest
}) => {
  const onIcon = rest.onIcon || "check_box";
  const offIcon = rest.offIcon || "check_box_outline_blank";
  const icon = checked ? onIcon : offIcon;
  const renderIcon = () => {
    return <i className="material-icons">{icon}</i>;
  };

  return (
    <FormControlLabel
      onClick={() => {
        if (disabled && clickButton) {
          clickButton();
        }
      }}
      control={
        <Checkbox
          data-testid="MiCheckbox"
          checked={checked}
          onChange={onChange}
          icon={renderIcon()}
          checkedIcon={renderIcon()}
          disabled={disabled}
          inputProps={{ readOnly: true }}
          {...rest}
        />
      }
      label={label}
      labelPlacement={labelPlacement || "end"}
    ></FormControlLabel>
  );
};

MiCheckbox.defaultProps = {
  checked: false,
};

MiCheckbox.propTypes = {
  /**
   * checked value (true | false)
   */
  checked: PropTypes.bool,
  /**
   * handle onChange.
   */
  onChange: PropTypes.func,
  /**
   * Label for checkbox
   */
  label: PropTypes.string,
  /**
   * 'start'||'end' ||'bottom' ||'start'
   */
  labelPlacement: PropTypes.string,
  /**
   * (true|false)
   */
  disabled: PropTypes.bool,
  /**
   * For toggling between edit and non-edit mode
   */
  clickButton: PropTypes.func,
  /**Font size of the Label*/
  labelFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export { MiCheckbox };
