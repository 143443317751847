/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { MiBox, MiTable, MiDrawer, MiInputSelectBox } from "@miview/components";
import { makeStyles } from "tss-react/mui";
import { Grid, IconButton, InputAdornment, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import MiInputBoxCustom, {
  MiInputBoxIcon,
  MiSearch,
} from "../../../components/MiInputBoxCustom";
import {
  ConfirmationModal,
  TdDatepicker,
  AccountNumber,
} from "../../../components";
import { getRandomNumber } from "../../../utils";
import { NewHomesRequistionHeader, NewHomesRequistionFooter } from "./Drawer";
import { FloatingRightAction } from "./Drawer/NewHomesRequistionFooter";
import { STATUS_ENUM } from "../../../constants";
import RequisitionQuoteSearch from "./RequisitionSearchResult";
import { itemService, RequisitionService } from "@miview/api";
import moment from "moment";
import { createToast } from "@miview/toast";
import { HTTP_STATUSES, TOAST_TYPES } from "@miview/constants";
import { useEdit } from "@miview/hooks";
import {
  mdiFolderRemove,
  mdiFolderInformation,
  mdiCheckBold,
  mdiTrashCanOutline,
} from "@mdi/js";

const useStyles = makeStyles()((theme) => ({
  drawerPaper: {
    width: "100%",
    maxWidth: "100%",
    paddingBottom: 60,
    "& > div": {
      width: "100%",
    },
  },
  boxesContainer: {
    width: "100%",
    paddingBottom: 70,
  },
  rqBox: {
    display: "inline-block",
    width: "100%",
    background: theme.palette.lightShade.grey,
    margin: "25px 0",
    borderRadius: "4px",
  },
  spacing: {
    padding: "15px",
  },
  flexNoWrap: {
    flexWrap: "nowrap",
  },
  width100: {
    width: "100%",
  },
  removeIcon: {
    background: "none!important",
    color: theme.palette.primary.red,
    "& svg": {
      fontSize: 18,
    },
  },
  addRow: {
    marginTop: -2,
    "& #AddRowButton i.fa": {
      marginRight: 15,
    },
  },
  menuItemStyle: {
    fontSize: theme.fontSize.medium,
  },
  fileStylesAttach: {
    padding: 5,
    color: theme.palette.lightShade.bluegrey,
    "& svg": {
      fontSize: 20,
    },
  },
  tableBorder: {
    border: `1px solid ${theme.palette.accent.grey}`,
  },
  tableheight: {
    position: "relative",
    overflow: "hidden",
  },
  voidedStyle: {
    position: "absolute",
    left: "35%",
    top: "35%",
    fontWeight: theme.fontWeight.medium,
    fontSize: 200,
    opacity: 0.3,
    transform: "rotate(-15deg)",
    color: theme.palette.primary.red,
    zIndex: 1,
  },
}));
const RequsistionOrderReview = ({
  open,
  fromTemplate,
  requisitionRow = {},
  requisitionItems = [],
  closeRequisitionModel,
  getRequisitionOrders,
  getRequisitonItems,
  manualCreation,
  setRequisitionItems,
  getSingleItem,
  requestedContacts = [],
}) => {
  const theme = useTheme();
  const { classes, cx } = useStyles();

  const initialValue = {
    number: getRandomNumber("REQ"),
    type: "Instance",
    name: getRandomNumber("REQ"),
    templateFolderId: 1,
    scheduledDate: moment(new Date()).format("MM/DD/YYYY"),
    requestedBy: "",
    description: "",
    stage: "7",
    deliveryLocationId: 4,
    issueDate: moment(new Date())?.format("MM/DD/YYYY"),
    requestedDeliveryDate: moment(new Date())?.format("MM/DD/YYYY"),
    status: "",
    requestedBillingDate: moment(new Date())?.format("MM/DD/YYYY"),
    reason: "",
  };
  const edit = useEdit(initialValue);
  const [confirmModal, setConfirmModal] = useState({ open: false });
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [masterItems, setMasterItems] = useState([]);
  const [isMissingRequiredField, setIsMissingRequiredField] = useState(false);
  const requiredFields = ["description", "requestedBy", "scheduledDate"];
  const [searchLoading, setSearchLoading] = useState(false);
  const [type, setType] = useState("");
  useEffect(() => {
    if (Object.keys(requisitionRow)?.length > 0) {
      edit.update(requisitionRow);
    }
  }, [requisitionRow]);

  useEffect(() => {
    if (!manualCreation && !fromTemplate) {
      let mapped = requisitionItems.map((item) => ({
        [item.id]: item.quantity,
      }));
      let newObj = Object.assign({}, ...mapped);
      edit.update(newObj);
    }
  }, [requisitionItems]);

  const handleClickShowSearch = () => {
    setSearchText("");
    setShowSearch(!showSearch);
    setMasterItems([]);
    setSearchLoading(false);
  };

  const handleSearchListShow = useCallback(async (e) => {
    try {
      setSearchLoading(true);
      setSearchText(e.target.value);
      const resp = await itemService.itemMaster.getAll(
        {
          search: e.target.value,
        },
        {}
      );
      if (resp?.status < HTTP_STATUSES.BAD_REQUEST) {
        setMasterItems(resp.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setSearchLoading(false);
    }
  }, []);

  const onShowDrawerFunction = useCallback((Row) => {
    getSingleItem(Row);
  }, []);
  const additem = (data) => {
    setRequisitionItems((prevState) =>
      [...prevState, data].filter(
        (v, i, a) =>
          a.findIndex((v2) => ["id"].every((k) => v2[k] === v[k])) === i
      )
    );
  };
  const addRequisitionItems = async (selectedRow) => {
    if (selectedRow && Object.keys(selectedRow)?.length > 0) {
      const data = {
        categoryId: selectedRow?.collectionId,
        categoryName: selectedRow?.collectionName,
        displaySequence: 2,
        id: selectedRow?.id,
        itemDescription: selectedRow?.description,
        itemId: selectedRow?.id,
        itemName: selectedRow?.number,
        notes: "Item 2 Notes Order 2 Tenant 1",
        quantity: 0,
        requisitionOrderId: requisitionRow?.id,
        status: "Active",
      };
      if (manualCreation || fromTemplate) {
        additem(data);
      } else {
        const resp = await createItems(requisitionRow?.id, data);
        if (resp?.status < HTTP_STATUSES.BAD_REQUEST) {
          additem(data);
          getRequisitonItems(requisitionRow);
        }
      }
    }
  };
  const removeRequisitionItems = async (removedItem) => {
    if (removedItem) {
      if (manualCreation || fromTemplate) {
        const temp = requisitionItems?.filter(
          (item) => item?.id !== removedItem?.id
        );
        edit.update({ [removedItem?.id]: 0 });
        setRequisitionItems(temp);
      } else {
        const resp = await RequisitionService.requisitions.deleteItem(
          requisitionRow?.id,
          removedItem?.id,
          "Item Removed Successfully",
          "Error Occurred While Removing Item"
        );
        if (resp?.status < 400) {
          getRequisitonItems(requisitionRow);
        }
      }
    }
  };

  const createItems = (requisitionId, data) => {
    return RequisitionService.requisitions.createItem(requisitionId, data);
  };

  const editItems = (requisitionId, itemId, data) => {
    return RequisitionService.requisitions.updateItem(
      requisitionId,
      itemId,
      data
    );
  };

  const createRequisitionItem = (item) => {
    return {
      itemId: item?.itemId,
      notes: item?.notes,
      status: item?.status,
      quantity: edit.getValue(item?.id),
      displaySequence: item?.displaySequence,
      id: item?.id,
      requisitionOrderId: item?.requisitionOrderId,
      itemName: item?.itemName,
      itemDescription: item?.itemDescription,
      categoryId: item?.categoryId,
      categoryName: item?.categoryName,
    };
  };

  const draftSubmit = async (Type) => {
    if (edit.allFilled(...requiredFields)) {
      setIsMissingRequiredField(false);
      const FormData = {
        number: fromTemplate ? getRandomNumber("REQ") : edit.getValue("number"),
        type: "Instance",
        name: edit.getValue("name"),
        templateFolderId: edit.getValue("templateFolderId"),
        scheduledDate: moment(edit.getValue("scheduledDate")).format(
          "MM/DD/YYYY"
        ),
        requestedBy: edit.getValue("requestedBy"),
        description: edit.getValue("description"),
        stage: edit.getValue("stage"),
        deliveryLocationId: edit.getValue("deliveryLocationId"),
        issueDate: moment(edit.getValue("scheduledDate"))?.format("MM/DD/YYYY"),
        requestedDeliveryDate: moment(edit.getValue("scheduledDate"))?.format(
          "MM/DD/YYYY"
        ),
        status: Type,
        requestedBillingDate: moment(edit.getValue("scheduledDate"))?.format(
          "MM/DD/YYYY"
        ),
        reason: edit.getValue("reason"),
      };

      try {
        if (manualCreation || fromTemplate) {
          const resp = await RequisitionService.requisitions.create(
            FormData,
            "Requisition Created Successfully",
            "Error Occurred While Creating"
          );
          if (resp.status < HTTP_STATUSES.BAD_REQUEST) {
            await Promise.all(
              requisitionItems?.forEach(async (item) => {
                let ItemFormat = createRequisitionItem(item);
                createItems(resp?.data?.id, ItemFormat);
              })
            );
          }
        } else {
          const resp = await RequisitionService.requisitions.update(
            requisitionRow?.id,
            FormData,
            "Requisition Updtaed Successfully",
            "Error Occurred While Upadting"
          );
          resp?.data &&
            (await Promise.all(
              requisitionItems?.map((item) => {
                let ItemFormat = createRequisitionItem(item);
                return editItems(resp?.data?.id, item?.id, ItemFormat);
              })
            ));
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        getRequisitionOrders();
        closeRequisitionModel();
        edit.reset();
      }
    } else {
      setIsMissingRequiredField(true);
    }
  };

  const actionDisable = !(manualCreation || requisitionRow?.status === "Draft");
  const columns = [
    {
      field: "itemName",
      headerName: "Item #",
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
      disableColumnResize: true,
      renderCell: (params) => {
        return (
          <AccountNumber
            onClick={() => onShowDrawerFunction(params?.row)}
            value={params?.value?.toString()}
            active={params?.row?.removeItem}
          />
        );
      },
    },
    {
      field: "itemDescription",
      headerName: "Description",
      minWidth: 200,
      disableColumnMenu: true,
      disableColumnResize: true,
      flex: 1,
    },
    {
      field: "categoryName",
      flex: 1,
      headerName: "Product Line",
      minWidth: 180,
      disableColumnMenu: true,
      disableColumnResize: true,
    },

    {
      field: "",
      headerName: "Quantity",
      flex: 1,
      disableColumnMenu: true,
      disableColumnResize: true,
      minWidth: 190,
      renderCell: (params) => {
        return (
          <MiInputBoxIcon
            value={edit.getValue(params?.row?.id) || ""}
            disabled={actionDisable}
            handleChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                params.api.updateRows([
                  { ...params.row, quantity: e.target.value },
                ]);
                edit.update({ [params?.row?.id]: e.target.value });
              }
            }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: " ",
      sortable: false,
      disableColumnMenu: true,
      disableColumnResize: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <IconButton
            className={classes.removeIcon}
            onClick={() => removeRequisitionItems(params?.row)}
            disabled={actionDisable}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        );
      },
    },
  ];
  const renderFilterEdit = () => {
    return (
      <div className={classes.rqBox}>
        <Grid container className={classes.flexNoWrap}>
          <Grid item className={cx(classes.spacing, classes.width100)}>
            <MiInputBoxCustom
              labelText="Description"
              required
              variant="outlined"
              fullWidth
              disabled={actionDisable}
              error={isMissingRequiredField && !edit.getValue("description")}
              helperText={
                isMissingRequiredField &&
                !edit.getValue("description") &&
                "This is a required field"
              }
              value={edit.getValue("description")}
              labelTextColor={
                isMissingRequiredField && !edit.getValue("description")
                  ? theme.palette.secondary.red
                  : theme.palette.primary.main
              }
              handleChange={(e) => edit.update({ description: e.target.value })}
            />
          </Grid>
          <Grid item className={cx(classes.spacing, classes.width100)}>
            <MiInputSelectBox
              labelText="Requested By"
              name="requestedBy"
              disabled={actionDisable}
              labelTextColor={
                isMissingRequiredField && !edit.getValue("requestedBy")
                  ? theme.palette.secondary.red
                  : theme.palette.primary.main
              }
              menuItems={requestedContacts}
              required
              error={isMissingRequiredField && !edit.getValue("requestedBy")}
              helperText={
                isMissingRequiredField &&
                !edit.getValue("requestedBy") &&
                "This is a required field"
              }
              minWidth={150}
              value={edit.getValue("requestedBy")}
              handleChange={(e) => edit.update({ requestedBy: e.target.value })}
              disableNone={true}
              displayEmpty={true}
              isPlaceholder={true}
            />
          </Grid>
          <Grid item className={cx(classes.spacing, classes.width100)}>
            <MiInputBoxCustom
              labelText="Tag"
              name="reason"
              disabled={actionDisable}
              variant="outlined"
              fullWidth
              value={edit.getValue("reason")}
              handleChange={(e) => edit.update({ reason: e.target.value })}
            />
          </Grid>
          <Grid item className={cx(classes.spacing, classes.width100)}>
            <TdDatepicker
              labelText="Scheduled Date"
              required
              disabled={actionDisable}
              value={moment(
                edit.getValue("scheduledDate") || new Date()
              ).format("MM/DD/YYYY")}
              onChange={(e) => edit.update({ scheduledDate: e })}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const onCancel = () => setConfirmModal({ open: false });
  const onApprove = () => {
    draftSubmit(type);
    onCancel();
    if (type === "Discard") {
      closeRequisitionModel();
      edit.reset();
      createToast("Requisition Discarded", TOAST_TYPES.ERROR);
    }
  };
  const onVoidRequestModal = useCallback(() => {
    setType("Voided");
    let statusProps = {
      open: true,
      title: "Void Requisition Order",
      severity: "error",
      description1: "Confirm you'd like to void this Requisition Order",
      approveTxt: "Void Requisition",
      icon: mdiTrashCanOutline,
    };
    setConfirmModal({ ...statusProps });
  }, [edit]);

  const onCompleteRequestModal = useCallback(() => {
    setType("Complete");
    let statusProps = {
      open: true,
      title: "Complete Requisition",
      severity: "info",
      description1: "You'd like to complete the requisition",
      approveTxt: "Complete",
      icon: mdiCheckBold,
    };
    setConfirmModal({ ...statusProps });
  }, [edit]);

  const onDiscardRequestModal = useCallback(() => {
    setType("Discard");
    let statusProps = {
      open: true,
      title: "Discard Requisition",
      severity: "error",
      description: "Confirm you'd like to discard the requisition",
      approveTxt: "Discard Requisition",
      icon: mdiFolderRemove,
    };
    setConfirmModal({ ...statusProps });
  }, []);

  const onSaveDiscardRequestModal = () => {
    setType("Draft");
    let statusProps = {
      open: true,
      title: "Save Requisition as Draft?",
      severity: "warning",
      description:
        "Are you sure you would like to save this Requisition as a draft? You can resume this draft Requisition at a later time.",
      type: 1,
      approveTxt: "Save For Later",
      icon: mdiFolderInformation,
      warning: true,
    };
    setConfirmModal({ ...statusProps });
  };
  const renderAddRow = () => {
    return (
      <div className={classes.addRow}>
        <MiSearch
          value={searchText}
          handleChange={handleSearchListShow}
          placeholder="Search for Item"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {searchText.length ? (
                  <IconButton onClick={handleClickShowSearch} size="large">
                    <CloseIcon />
                  </IconButton>
                ) : (
                  ""
                )}
              </InputAdornment>
            ),
          }}
        />
        {searchText.length > 0 && (
          <RequisitionQuoteSearch
            masterItems={masterItems}
            AddRequisitionItems={addRequisitionItems}
            searchLoading={searchLoading}
          />
        )}
      </div>
    );
  };
  const handleClose = () => {
    edit.reset();
    setIsMissingRequiredField(false);
    closeRequisitionModel();
  };

  return (
    <MiDrawer
      anchor="right"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <MiBox>
        <NewHomesRequistionHeader
          ReqNumber={
            requisitionRow ? requisitionRow?.number : getRandomNumber("REQ")
          }
          Status={requisitionRow ? requisitionRow?.status : 0}
          CloseonClick={handleClose}
        />

        {renderFilterEdit()}
        <div className={classes.tableheight}>
          <MiTable
            columns={columns}
            rows={requisitionItems}
            disableColumnMenu={true}
            getRowClassName={(params) => {
              return `purchasing-row--${
                STATUS_ENUM[params.getValue(params.id, "status")]
              }`;
            }}
            hideFooter={true}
            autoHeight={true}
            sx={{
              minHeight: "inherit",
              border: "inherit",
              "& .MuiDataGrid-columnHeader": {
                background: theme.palette.lightShade.grey,
                minHeight: 60,
              },
              "& .MuiDataGrid-footerContainer p": {
                marginBottom: "0px",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
            }}
          />
          {requisitionRow?.status === "Voided" && (
            <div className={classes.voidedStyle}>Voided</div>
          )}
        </div>
        {manualCreation || requisitionRow?.status === "Draft"
          ? renderAddRow()
          : ""}
      </MiBox>
      {requisitionRow?.status === "Draft" || manualCreation ? (
        <NewHomesRequistionFooter
          onDiscardRequestModal={onDiscardRequestModal}
          SaveForLaterClick={onSaveDiscardRequestModal}
          SubmitOnClick={() => draftSubmit("Open")}
        />
      ) : (
        ""
      )}
      {requisitionRow?.status === "Open" && (
        <FloatingRightAction
          title="Void"
          icon="fa-ban"
          onClick={onVoidRequestModal}
          background={theme.palette.primary.red}
        />
      )}
      {requisitionRow?.status === "On Order" && (
        <FloatingRightAction
          title="Complete"
          icon="fa-check"
          onClick={onCompleteRequestModal}
          background={theme.palette.primary.blue}
        />
      )}
      <ConfirmationModal
        {...confirmModal}
        onApprove={onApprove}
        onCancel={onCancel}
      />
    </MiDrawer>
  );
};

RequsistionOrderReview.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RequsistionOrderReview;
