import React, { useState, useEffect } from "react";
import { toList } from "@miview/utils";
import { cityService, communityService } from "@miview/api";
import { useEdit, useComponentState } from "@miview/hooks";
import { HTTP_STATUSES, STATES } from "@miview/constants";
import { MiModal, MiDetailFields, MapContainer } from "@miview/components";
import { useTheme } from "@mui/material";

export const CreateCommunityModal = (props) => {
  const { isVisible, close } = props;
  const [cities, setCities] = useState([]);
  const [recenter, setRecenter] = useState(false);
  const [regions, setRegions] = useState();

  const theme = useTheme();
  const edit = useEdit();
  const stateManager = useComponentState();

  const getCities = (stateId) => {
    stateManager.run(async () => {
      const response = await cityService.getCities({
        params: { pageSize: 9999 },
        filters: { stateId },
        sort: [{ field: "cityName", sort: "asc" }],
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "cityId", "cityName");
        setCities(mapped);
      }
    });
  };

  const getRegions = () => {
    stateManager.run(async () => {
      const response = await communityService.getRegions({});
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(response.data, "regionId", "fullName");
        setRegions(mapped);
      }
    });
  };

  useEffect(() => {
    getRegions();
  }, []);

  const isValid = () => {
    return (
      edit.getValue("name") &&
      edit.getValue("stateId") &&
      edit.getValue("cityId") &&
      edit.getValue("regionId") &&
      edit.getValue("latitude") &&
      edit.getValue("longitude")
    );
  };

  const saveNewCommunity = () => {
    stateManager.run(async () => {
      const response = await communityService.createCommunity({
        request: edit.edits,
      });
      if (response.status === HTTP_STATUSES.OK) {
        close(true, response.data);
      }
    });
  };

  const handleCloseModal = () => {
    close(false);
    edit.reset();
  };

  const coordinatesDidUpdate = (latitude, longitude) => {
    edit.update({ latitude, longitude });
  };

  if (!isVisible) {
    return null;
  }

  const createCommunityInputs = [
    {
      label: "Community Name",
      id: "communityName",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "50%",
    },
    {
      label: "State",
      id: "state",
      value: edit.getValue("stateId"),
      options: toList(STATES, "id", "name"),
      fieldType: "select",
      required: true,
      setValue: (e) => {
        getCities(e);
        edit.update({ stateId: e, cityId: null });
      },
      width: "50%",
    },
    {
      label: "City",
      id: "cityId",
      value: edit.getValue("cityId"),
      options: cities,
      readOnly: edit.getValue("stateId") ? false : true,
      fieldType: "select",
      required: true,
      setValue: (e) => edit.update({ cityId: e }),
      width: "50%",
    },
    {
      label: "Region",
      id: "region",
      options: regions,
      fieldType: "select",
      value: edit.getValue("regionId"),
      required: true,
      setValue: (e) => edit.update({ regionId: e }),
      width: "50%",
    },
  ];

  const LongLatInput = [
    {
      label: "Latitude",
      id: "latitude",
      value: edit.getValue("latitude"),
      required: true,
      setValue: (e) => {
        edit.update({ latitude: e });
        setRecenter(!recenter);
      },
      width: "50%",
    },
    {
      label: "Longitude",
      id: "longitude",
      value: edit.getValue("longitude"),
      required: true,
      setValue: (e) => {
        edit.update({ longitude: e });
        setRecenter(!recenter);
      },
      width: "50%",
    },
  ];

  return (
    <>
      {stateManager.statusTag("createCommunityModalStatus")}
      <MiModal
        key={0}
        title="Create A New Community"
        open={isVisible}
        onClose={handleCloseModal}
        actions={[
          {
            name: "Cancel",
            style: { minWidth: 90 },
            onClick: handleCloseModal,
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: saveNewCommunity,
            style: { minWidth: 90 },
            color: theme.palette.primary.main,
            inverse: true,
            disabled: !isValid(),
          },
        ]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <MiDetailFields detailFields={createCommunityInputs} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              key={4}
              style={{
                width: "100%",
                paddingRight: "1rem",
                maxHeight: 300,
                position: "relative",
              }}
            >
              <MapContainer
                lat={edit.getValue("latitude")}
                lng={edit.getValue("longitude")}
                recenter={recenter}
                onCoordUpdate={coordinatesDidUpdate}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <MiDetailFields detailFields={LongLatInput} />
            </div>
          </div>
        </div>
      </MiModal>
    </>
  );
};
