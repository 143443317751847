import React from "react";
import {
  MiListFilterHeader,
  MiMultiselectChip,
  MiButtonGroup,
  MiButton,
  MiInputMultiSelectBox,
} from "@miview/components";
import { useTheme } from "@mui/material/styles";
import {
  ACCOUNTPAYABLE_CHIP_ITEMS_OPENBILLSTAB,
  ACCOUNTPAYABLE_CHIP_ITEMS_HISTORYTAB,
  ACCOUNTPAYABLE_BUTTONGROUP_VALUES,
  ACCOUNTPAYABLE_ACTIVETAB_VALUE,
  EXPENSE_TYPE_CATEGORIES,
  ACCOUNT_PAYABLE_EXPORTED_STATUS,
} from "@miview/constants";
import { makeStyles } from "tss-react/mui";

const multiSelectStatusItems = {
  openbills: ACCOUNTPAYABLE_CHIP_ITEMS_OPENBILLSTAB,
  history: ACCOUNTPAYABLE_CHIP_ITEMS_HISTORYTAB,
};

const useStyles = makeStyles()((theme) => ({
  btnStyles: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: "10px",
    background: theme.palette.lightAccent.grey,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

export const AccountingFilterComponent = ({
  handlePayButtonGroupClick,
  handleExportButtonClick,
  selectedStatus,
  setSelectedStatus,
  handleExportChipSelectionChange,
  activeTab,
  paySelection,
  filterArray,
  selectedExportChip,
  handleFilterIconClick,
  searchTerm,
  handleSearch,
  handleClearSearch,
  payPeriods,
  selectedMultiPayPeriod,
  setSelectedMultiPayPeriod,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <>
      <MiListFilterHeader
        clearSearch={handleClearSearch}
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        filterArray={filterArray}
        handleFilterIconClick={handleFilterIconClick}
      >
        <MiButton
          key={1}
          title="Export Bonus"
          color={theme.palette.primary.teal}
          inverse
          style={{ borderRadius: "4px", width: "150px" }}
          onClick={() => handleExportButtonClick(EXPENSE_TYPE_CATEGORIES.BONUS)}
        />
        <MiButton
          key={2}
          title="Export Pay"
          color={theme.palette.primary.teal}
          inverse
          style={{ borderRadius: "4px", width: "150px" }}
          onClick={() => handleExportButtonClick(EXPENSE_TYPE_CATEGORIES.PAY)}
        />
      </MiListFilterHeader>
      <div className={classes.btnStyles}>
        <div
          style={{
            marginLeft: "5px",
            display: "flex",
            alignItems: "flex-end",
            gap: "1rem",
          }}
        >
          <MiButtonGroup
            data={[
              {
                label: ACCOUNTPAYABLE_BUTTONGROUP_VALUES.JOB_PAY,
                value: ACCOUNTPAYABLE_BUTTONGROUP_VALUES.JOB_PAY,
              },
              {
                label: ACCOUNTPAYABLE_BUTTONGROUP_VALUES.BONUS_PAY,
                value: ACCOUNTPAYABLE_BUTTONGROUP_VALUES.BONUS_PAY,
              },
            ]}
            selected={paySelection}
            selectedBackgroundColor={theme.palette.primary.main}
            selectedFontColor={theme.palette.primary.white}
            UnSelectedBackgroundColor={theme.palette.primary.white}
            UnSelectedFontColor={theme.palette.primary.grey}
            style={{ padding: "0px 10px" }}
            handleClick={handlePayButtonGroupClick}
          ></MiButtonGroup>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              gap: "0.5rem",
            }}
          >
            {activeTab === ACCOUNTPAYABLE_ACTIVETAB_VALUE.HISTORY ? (
              <MiInputMultiSelectBox
                styles={{ width: "200px", fontSize: "13px" }}
                menuItems={payPeriods}
                value={selectedMultiPayPeriod}
                labelText="Pay Period"
                handleChange={(e) => {
                  setSelectedMultiPayPeriod(e.target.value);
                }}
                disableNone
              />
            ) : null}

            <MiInputMultiSelectBox
              labelText="Status"
              styles={{ width: "200px", fontSize: "13px" }}
              menuItems={multiSelectStatusItems[activeTab] || []}
              value={selectedStatus}
              handleChange={(e) => {
                setSelectedStatus(e.target.value);
              }}
              disableNone
            />

            <MiMultiselectChip
              items={ACCOUNT_PAYABLE_EXPORTED_STATUS}
              selectedItems={selectedExportChip}
              size="small"
              type="CHECKBOX"
              activeColor={theme.palette.primary.main}
              handleChange={handleExportChipSelectionChange}
            ></MiMultiselectChip>
          </div>
        </div>
      </div>
    </>
  );
};
