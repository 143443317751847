import React from "react";
import { Typography } from "@mui/material";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  subHeader: {
    color: theme.palette.dark.teal,
    borderBottom: `1px solid ${theme.palette.accent.grey}`,
  },
}));

export default React.memo((props) => {
  const { classes } = useStyles();
  return (
    <Typography className={classes.subHeader} variant="subtitle1" gutterBottom>
      {props.text}
    </Typography>
  );
});
