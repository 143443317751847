import React from "react";
import { IconButton, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const useStyles = makeStyles()((theme) => ({
  fileNameStyle: {
    display: "flex",
    alignItems: "center",
  },
  paddingLeftStyle: {
    paddingLeft: 40,
  },
  iconSpacing: {
    marginRight: 10,
  },
  collapseButtonstyle: {
    marginRight: 20,
    padding: 5,
    transition: "0.5s all",
    transform: "rotate(-90deg)",
  },
  collapseButtonstyleDown: {
    marginRight: 20,
    padding: 5,
    transition: "0.5s all",
    transform: "rotate(-180deg)",
  },
  emptyButton: {
    marginRight: 20,
    width: 34,
  },
  Greentext: {
    color: theme.palette.primary.teal,
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.medium,
    textTransform: "inherit",
    "&:hover": {
      background: "inherit",
    },
  },
}));

const FolderCollapse = ({
  value,
  fileMode,
  parent,
  handleCollapseButton,
  handleCollapseArrow,
  ID,
}) => {
  const { classes, cx } = useStyles();
  return (
    <div
      className={cx(
        classes.fileNameStyle,
        fileMode && classes.paddingLeftStyle
      )}
    >
      {parent ? (
        <IconButton
          className={cx(
            handleCollapseArrow && classes.collapseButtonstyleDown,
            classes.collapseButtonstyle
          )}
          onClick={() => handleCollapseButton(ID, handleCollapseArrow)}
          size="large"
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      ) : (
        <div className={classes.emptyButton} />
      )}

      <div className={classes.iconSpacing}>
        {fileMode ? <InsertDriveFileOutlinedIcon /> : <FolderIcon />}
      </div>

      <Button className={classes.Greentext}>{value}</Button>
    </div>
  );
};

export default FolderCollapse;
