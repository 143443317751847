import React, { Fragment } from "react";
import { THEME } from "./theme";
import { MiMenuSystem, MiAddArea } from "./MiView";
import { ACTION_FORM_INITIAL_STATE } from "../../views/HomeSpecs/constants";
import {
  MiCheckbox,
  MiButton,
  MiDetailFields,
  MiModal,
} from "@miview/components";
import { useEdit } from "@miview/hooks";

export const MiPlanOptions = ({
  optionName,
  deleteOption,
  planOptions,
  handleSetOptionOn,
  optionTypes,
  saveDraft,
  floors,
  propertySections,
  saveAreaActionAdd,
  saveOptionRequest,
  propertyOptionsLoading,
  handleUpdatePropertyOptions,
  edit,
}) => {
  const areaEdit = useEdit(ACTION_FORM_INITIAL_STATE);
  const hasActions = !!(
    edit.getValue("optionAction") === "Add" ||
    edit.getValue("optionAction") === "Update"
  );

  const handleSaveOptionandArea = async () => {
    if (edit.getValue("optionAction") === "Update") {
      const requestPayload = {
        id: edit.getValue("optionId"),
        name: edit.getValue("optionName"),
        description: edit.getValue("optionDescription"),
      };

      await saveOptionRequest(requestPayload);
      edit.reset();
      areaEdit.reset();

      return;
    }
    const optionId = await saveDraft();
    await saveAreaActionAdd(optionId, areaEdit.edits);
    const requestPayload = {
      id: optionId,
      name: edit.getValue("optionName"),
      description: edit.getValue("optionDescription"),
    };
    edit.reset();
    areaEdit.reset();
    await saveOptionRequest(requestPayload);
  };

  const renderPlanOptions = () => {
    return planOptions.map((p, i) => {
      const generateMenuOptions = () => {
        let options = [
          {
            name: "Edit",
            action: async () => {
              edit.update({
                ...p,
                optionDescription: p.description,
                optionAction: "Update",
              });
              areaEdit.update({
                ...p.actions[0],
                planAreaName: p.actions[0].name,
              });
            },
          },
          {
            name: "Delete",
            action: () => {
              deleteOption(p);
            },
          },
        ];

        return options;
      };

      const isCustom = optionName === "Custom";
      const isEditing = edit.getValue("optionId") === p.optionId;

      const renderCheckbox = () => {
        if (isCustom) {
          if (p.isDraft) {
            return <div style={styles.optionCheckboxVoid} />;
          }

          return (
            <div style={styles.optionCheckbox}>
              <MiCheckbox
                color="orange"
                checked={p.isSelected}
                disabled={propertyOptionsLoading || isEditing}
                onChange={() => handleUpdatePropertyOptions(p, p.isSelected)}
              />
            </div>
          );
        }

        return null;
      };

      return (
        <div key={p.optionId + i} style={{ ...styles.planOptionContainer }}>
          <div style={styles.planOptionFlexContainer}>
            {renderCheckbox()}
            <div style={styles.planOptionDetails}>
              <div style={styles.planOptionName}>
                {p.optionName}
                <span style={styles.draftTag}>
                  {p.isDraft ? " (Draft)" : ""}
                </span>
              </div>
              <div style={styles.planOptionDescription}>{p.description}</div>
            </div>
            <div>
              {!p.isSelected && !isEditing && (
                <MiMenuSystem options={generateMenuOptions()} />
              )}
            </div>
            {edit.getValue("optionId") === p.optionId && (
              <div>
                <MiButton
                  title="Done"
                  color="green"
                  onClick={() => handleSetOptionOn(0)}
                />
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  const renderAreaModificationOptions = () => {
    if (edit.getValue("optionAction") === "Add") {
      return (
        <Fragment>
          <div style={styles.addAreaTitle}>Area Info</div>
          <MiAddArea
            floors={floors}
            numberOfFloors={0}
            edit={areaEdit}
            propertySections={propertySections}
            isEditing
            saveDraft={saveDraft}
            saveArea={saveAreaActionAdd}
            sectionDisabled={!hasActions}
          />
        </Fragment>
      );
    }
    return null;
  };

  const renderModal = () => {
    const modalTitle =
      edit.getValue("optionAction") === "Add"
        ? "Create a Custom Option"
        : "Edit Custom Option";
    return (
      <MiModal
        title={modalTitle}
        maxWidth={600}
        onClose={() => {
          edit.reset();
          areaEdit.reset();
        }}
        open={hasActions}
      >
        {renderPlanOptionForm()}
      </MiModal>
    );
  };

  const renderPlanOptionForm = () => {
    const detailFields = [
      {
        label: "Name",
        value: edit.getValue("optionName"),
        setValue: (e) => edit.update({ optionName: e }),
        width: "50%",
      },
      {
        label: "Description",
        value: edit.getValue("optionDescription"),
        setValue: (e) => edit.update({ optionDescription: e }),
        width: "50%",
      },
      {
        label: "Type",
        fieldType: "select",
        value: edit.getValue("optionTypeId"),
        options: optionTypes,
        required: true,
        hide: true,
        setValue: (e) => edit.update({ optionTypeId: e }),
        width: "50%",
      },
    ];

    return (
      <Fragment>
        <div style={styles.addAreaTitle}>Option Info</div>
        <div style={styles.createOptionContainer}>
          <MiDetailFields detailFields={detailFields} />
        </div>
        {renderAreaModificationOptions()}
        {
          <div style={styles.saveOptionButtonContainer}>
            <MiButton
              style={styles.saveButton}
              title="Save Option"
              onClick={() => {
                handleSaveOptionandArea();
              }}
            />
            <MiButton
              style={styles.cancelButton}
              title="Close"
              onClick={() => {
                edit.reset();
              }}
            />
          </div>
        }
      </Fragment>
    );
  };

  return (
    <Fragment>
      {planOptions && renderPlanOptions()}
      <div style={styles.createOptionButton}>
        <div
          id={"add-option-button"}
          style={styles.createOptionIcon}
          onClick={() => {
            edit.update({
              optionId: 0,
              optionAction: "Add",
              actionForm: areaEdit.edits,
            });
          }}
        >
          +
        </div>
        {renderModal()}
        <div style={styles.createOptionLabel}>Add {optionName} Option</div>
      </div>
    </Fragment>
  );
};

const styles = {
  createOptionButton: {
    margin: "20px 8px 30px",
    display: "flex",
  },
  createOptionIcon: {
    border: "1px solid #DDDBDA",
    borderRadius: 5,
    height: 30,
    width: 30,
    textAlign: "center",
    cursor: "pointer",
    fontSize: 30,
    lineHeight: 0.75,
    color: "#0070D2",
    backgroundColor: "white",
  },
  createOptionLabel: {
    margin: "0 0 0 10px",
    lineHeight: 2,
  },
  stageMultiSelect: {
    width: "100%",
    height: 80,
    marginBottom: 16,
  },
  planOptionContainer: {
    borderBottom: "1px solid #DDDBDA",
    padding: "10px 0",
    backgroundColor: "white",
  },
  raised: {
    padding: 8,
    borderRadius: 5,
    zIndex: 1000,
    position: "relative",
  },
  planOptionFlexContainer: {
    display: "flex",
  },
  planOptionDetails: {
    flex: 1,
  },
  editIcon: {
    color: "#DDDBDA",
    height: 20,
    width: 20,
    cursor: "pointer",
  },
  editForm: {
    margin: "14px",
  },
  toggle: {
    marginRight: 14,
  },
  saveOptionButtonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: 20,
  },
  saveButton: {
    backgroundColor: THEME.BLUE_PRIMARY,
    color: "white",
    fontSize: 14,
    height: 30,
    width: 100,
    border: `1px solid ${THEME.BLUE_PRIMARY}`,
  },
  cancelButton: {
    fontSize: 14,
    height: 30,
    width: 100,
  },
  optionActionContainer: {
    marginTop: 10,
    padding: "20px 10px",
    borderTop: `1px solid ${THEME.GREY_LIGHT}`,
  },
  optionActionTitle: {
    marginBottom: 10,
  },
  optionActionButton: {
    padding: 20,
    color: THEME.BLUE_PRIMARY,
    border: `1px solid ${THEME.GREY_DARK}`,
    cursor: "pointer",
    marginRight: 10,
    borderRadius: 6,
  },
  optionActionButtonActive: {
    padding: 20,
    color: "white",
    backgroundColor: THEME.GREEN_PRIMARY,
    border: `1px solid ${THEME.GREEN_PRIMARY}`,
    marginRight: 10,
    borderRadius: 6,
    cursor: "pointer",
  },
  selectedAreasContainer: {
    padding: "20px 10px 0",
  },
  addAreaActionContainer: {
    display: "flex",
    alignItems: "center",
  },
  addAreaActionIcon: {
    color: THEME.GREEN_PRIMARY,
  },
  addAreaTitle: {
    color: THEME.BLUE_PRIMARY,
    fontSize: 16,
    paddingLeft: 10,
    borderBottom: `1px solid ${THEME.GREY_LIGHT}`,
    paddingBottom: 8,
  },
  nextButton: {
    margin: "0 0 0 auto",
    width: 80,
    backgroundColor: THEME.BLUE_SECONDARY,
    border: "none",
    color: "white",
  },
  optionCheckbox: {
    margin: "0 8px 0 8px",
  },
  optionCheckboxVoid: {
    width: 40,
  },
  draftTag: {
    color: THEME.BLUE_PRIMARY,
  },
  createOptionContainer: {
    padding: "14px 0 14px 12px",
  },
  createDrawingIcon: {
    border: "1px solid #DDDBDA",
    borderRadius: 5,
    height: 30,
    width: 30,
    textAlign: "center",
    cursor: "pointer",
    fontSize: 30,
    lineHeight: 0.75,
    color: "#0070D2",
    backgroundColor: "white",
  },
  createDrawingInput: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },
  customLabel: {
    fontSize: "0.8rem",
    color: "rgb(150, 148, 146)",
    marginBottom: "2px",
  },
  imageContainer: {
    maxWidth: 200,
  },
  image: {
    maxWidth: "100%",
  },
};
