/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { accountService } from "@miview/api";
import { SideToggleButton } from "../../../../components";
import { ACCOUNT_STATUS } from "@miview/constants";
import { CostDrawer } from "./CostDrawer";
import { mdiPlaylistPlus } from "@mdi/js";
import { MiIconButton, MiIcon, MiList } from "@miview/components";
import { createFilter, combineFilters } from "@miview/utils";
import { useRouter } from "@miview/hooks";
import en from "../../../../locale/en.json";
import moment from "moment";

const SHOW_ALL_LABEL = "Show All Items";
const EXPIRES_LABEL = "Expiring";

const EXPIRED_OPTIONS = [
  { label: en.expireOptionsExpired, value: 0 },
  { label: en.expireOptions7, value: 7 },
  { label: en.expireOptions15, value: 15 },
  { label: en.expireOptions30, value: 30 },
];

const ItemCostVendorCostBook = () => {
  const [showCart, setShowCart] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [accountItems, setAccountItems] = useState([]);
  const [rowGroupingModel, setRowGroupingModel] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [expire, setExpire] = useState("");

  const router = useRouter();

  const accountId = router.match.params.id;

  const getItems = (config) => {
    if (showAll) {
      return accountService.itemCost.getAll({ accountId, config });
    }

    const f = createFilter();
    f.equals({ accountId });
    if (expire !== "") {
      const futureDate = moment().add(expire, "days").format();
      if (expire === 0) {
        f.lessThanOrEqual({ expiryDate: futureDate });
      } else {
        f.inRange({ expiryDate: [moment().format(), futureDate] });
      }
    }
    return accountService.itemCost.getAll({
      id: accountId,
      ...config,
      filters: combineFilters(config.filters, f.getFilter()),
    });
  };

  const addToDrawer = (obj) => {
    setSelectedItems([...new Set([...selectedItems, obj])]);
    setShowCart(true);
  };

  const addAllToDrawer = (obj) => {
    setSelectedItems([
      ...new Set([
        ...selectedItems,
        ...accountItems.filter((e) => e[obj.groupingField] === obj.groupingKey),
      ]),
    ]);
    setShowCart(true);
  };

  const columns = [
    {
      field: "accountId",
      headerName: "",
      renderCell: (param) => (
        <MiIconButton disabled={!param.row.canAddToQuote}>
          <MiIcon
            path={mdiPlaylistPlus}
            onClick={() =>
              Object.keys(param.row).length
                ? addToDrawer(param.row)
                : addAllToDrawer(param.rowNode)
            }
          />
        </MiIconButton>
      ),
      flex: 1,
    },
    {
      field: "categoryName",
      headerName: "Category",
      hide: rowGroupingModel.includes("categoryName"),
      flex: 1,
    },
    {
      field: "itemName",
      headerName: "Item #",
      flex: 1,
    },
    {
      field: "itemDescription",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
    },
    {
      field: "currentPrice",
      headerName: "Current Cost",
      flex: 1,
    },
    {
      field: "expiryDate",
      headerName: "Exp Date",
      renderCell: (param) =>
        param.value ? moment(param.value).format("LL") : "--",
      flex: 1,
    },
    {
      field: "futureCost",
      headerName: "Future Cost",
      flex: 1,
    },
    {
      field: "effectiveDate",
      headerName: "Eff Date",
      flex: 1,
    },
    {
      field: "percentChange",
      headerName: "% Change",
      flex: 1,
    },
  ];

  const clearSelected = () => {
    setSelectedItems([]);
  };

  const closeCart = () => {
    setShowCart(false);
  };

  const removeSelectedItem = (item) => {
    setSelectedItems(selectedItems.filter((e) => e !== item));
  };

  const filterArray = [
    {
      type: "select",
      label: EXPIRES_LABEL,
      value: expire,
      options: EXPIRED_OPTIONS,
    },
    {
      type: "checkbox",
      label: SHOW_ALL_LABEL,
      value: showAll,
    },
  ];

  const filterActionUpdate = (value, type) => {
    switch (type) {
      case SHOW_ALL_LABEL:
        setShowAll(value);
        break;
      case EXPIRES_LABEL:
        setExpire(value);
        break;
    }
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <MiList
            data={accountItems}
            setData={setAccountItems}
            dataProvider={getItems}
            columns={columns}
            filterArray={filterArray}
            onFilterHeaderChange={filterActionUpdate}
            getRowClassName={(params) =>
              params.row.status === ACCOUNT_STATUS.inactive ? "inActiveRow" : ""
            }
            getRowId={(row) => row.itemId}
            disableColumnPinning={true}
            defaultGroupingExpansionDepth={-1}
            rowGroupingColumnMode="single"
            experimentalFeatures={{
              rowGrouping: true,
            }}
            rowGroupingModel={rowGroupingModel}
            onRowGroupingModelChange={(model) => {
              setRowGroupingModel(model);
            }}
            refresh={showAll ^ (expire || expire !== "")}
          />
        </div>
        <CostDrawer
          onClear={clearSelected}
          onClose={closeCart}
          open={showCart}
          selectedItems={selectedItems}
          remove={removeSelectedItem}
        />
      </div>
      {!showCart && <SideToggleButton onClick={() => setShowCart(true)} />}
    </>
  );
};

export default ItemCostVendorCostBook;
