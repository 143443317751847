export const THEME = {
  TRANSPARENT_OVERLAY: "rgba(0,0,0,0.6)",
  TRANSPARENT_OVERLAY_LIGHT: "rgba(0,0,0,0.2)",
  TRANSPARENT_OVERLAY_WHITE: "rgba(255,255,255,0.2)",
  TRANSPARENT_OVERLAY_GREY: "rgba(200,200,200,0.2)",

  GREY_DARK: "#3e3e3c",
  GREY_MEDIUM: "#969492",
  GREY_MEDIUM_ALT: "#5C5C5C",
  GREY_LIGHT: "#DDDDDD",
  GREY_LIGHT_E: "#EEEEEE",
  GREY_LIGHT_BACKGROUND: "#F7F7F7",
  GREY_MEDIUM_LIGHT: "rgb(167,167,167)",
  GREY_LIGHT_ALT: "#999999",

  PURPLE_PRIMARY: "#B620E0",
  PURPLE_SECONDARY: "#A88BFF",
  PURPLE_BACKGROUND: "#8B6FDD",
  PURPLE_BACKGROUND_LIGHT: "#a28be3",
  PURPLE_LIGHT: "#BFA7E1",
  PURPLE_DARK: "#3A048A",

  BLUE_PRIMARY: "#3EA9E2",
  BLUE_SECONDARY: "#087DE4",
  BLUE_ACCENT: "#63BAEB",
  BLUE_LIGHT: "#BDE4FF",
  BLUE_DARK: "#163D85",
  BLUE_DARK_ALT: "#2A303E",

  BLUE: ["#087DE4", "#3EA9E2", "#63BAEB", "#83C4E8", "#163D85"],

  GREEN_PRIMARY: "#3CBA6A",
  GREEN_ACCENT: "#53CC7F",
  GREEN_LIGHT: "#C5FECE",
  GREEN_ACCENT_ALT: "#30C6B4",
  GREEN_ACCENT_ALT_DARK: "#00746b",
  GREEN_ACCENT_ALT_MEDIUM: "#2a8e86",
  GREEN_DARK: "#027E46",

  BLUE_GREY_LIGHT: "#eceff1",

  ORANGE_PRIMARY: "#F69100",
  ORANGE_LIGHT: "#FDE9CC",
  ORANGE_SECONDARY: "#FFA500",
  ORANGE_DARK: "#c37300",
  ORANGE_ACCENT: "#FCC05B",
  YELLOW_PRIMARY: "#FFF03F",
  YELLOW_SECONDARY: "#f3c301",
  BLACK_PRIMARY: "#000",

  TANGERINE: "#F06147",
  TANGERINE_ACCENT: "#FF735A",

  RED_PRIMARY: "#B51B1B",
  RED_DARK: "#870500",
  RED_ACCENT: "#D4504C",
  RED_LIGHT: "#F2CAC9",

  PINK_PRIMARY: "#F49AC1",

  ACTION_PRIMARY: "#3CBA6A", //green
  ACTION_SECONDARY: "#3EA9E2", //blue
  ACTION_NEGATIVE: "#B51B1B", //red
  ACTION_SUCCESS: "#3CBA6A",
  ACTION_ORANGE: "#F6A600",
  ACTION_PURPLE: "#B620E0",

  HEADER_PRIMARY: "#3EA9E2",
  HEADER_SCONDARY: "#DDDDDD",

  TEXT_BODY_REGULAR: {
    fontSize: 13,
    lineHeight: 12 * 1.2,
    fontWeight: "400",
  },
  TEXT_BODY_SMALL: {
    fontSize: 11.5,
    lineHeight: 12 * 1.2,
    fontWeight: "400",
  },

  TEXT_SECTION_TITLE_SMALL: {
    fontSize: 14,
    fontWeight: "700",
  },

  TEXT_SECTION_TITLE_MEDIUM: {
    fontSize: 17,
    lineHeight: 17 * 1.1,
    fontWeight: "800",
  },

  TEXT_SECTION_TITLE_LARGE: {
    fontSize: 20,
    lineHeight: 20 * 1.1,
    fontWeight: "800",
  },

  TEXT_SECTION_TITLE_THIN: {
    fontSize: 16,
    lineHeight: 16 * 1.2,
    fontWeight: "300",
  },

  TEXT_HEADER: {
    fontSize: 24,
    lineHeight: 24 * 1.1,
    fontWeight: "800",
  },

  TEXT_HEADER_MEDIUM: {
    fontSize: 26,
    lineHeight: 26 * 1.1,
    fontWeight: "300",
  },

  TEXT_HEADER_LARGE: {
    fontSize: 32,
    lineHeight: 32 * 1.1,
    fontWeight: "300",
  },

  BORDER_BOTTOM: {
    borderBottomWidth: 1,
    borderBottomColor: "#DDD",
    paddingBottom: 10,
  },
  BORDER_TOP: {
    borderTopWidth: 1,
    borderTopColor: "#DDD",
    paddingTop: 10,
  },

  CARD: {
    width: "100%",
    borderRadius: 8,
    borderColor: "#DDD",
    borderWidth: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 1,
    padding: 8,
    backgroundColor: "#FFF",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  btnActionMenu: {
    position: "absolute",
    zIndex: 10,
    right: 0,
    bottom: 0,
    padding: 5,
    paddingBottom: 18,
    paddingHorizontal: 20,
    flexDirection: "row",
    flex: 1,
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: "#DDD",
    backgroundColor: "rgba(255,255,255,0.8)",
  },
  DOT: {
    zIndex: 10,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#CCC",
    top: -3,
    right: -3,
    width: 12,
    height: 12,
    position: "absolute",
  },
  BOX: {
    padding: 5,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#DDD",
    backgroundColor: "#FFF",
  },

  ROW: {
    flexDirection: "row",
    alignItems: "center",
  },
  COL: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
  SECTION_HEADER: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    borderWidth: 1,
    marginBottom: 5,
    borderColor: "#DDD",
    backgroundColor: "#F7F7F7",
    color: "#3EA9E2",
  },
  WINDOW_HEIGHT_LIMIT: 900,

  BLUE_DARK_ALT_2: "#1589EE",
  BLUE_TURQUOISE: "#00AFB9",

  TEXT_SECTION_TITLE: {
    fontSize: 15,
    lineHeight: 1.1,
    // paddingTop: 15 - (15 * 1.1),
    fontWeight: "800",
  },

  TEXT_SECTION_TITLE_MED: {
    fontSize: 15,
    lineHeight: 1.1,
    // paddingTop: 15 - (15 * 1.1),
    fontWeight: "500",
  },

  MODAL_HEADER: {
    fontSize: 24,
    lineHeight: 1.1,
    fontWeight: "500",
  },

  BUTTON_TEXT: {
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: "300",
  },
  BOX_MAIN: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    borderColor: "#DDD",
    borderRadius: "0.25rem",
    borderWidth: 1,
    backgroundColor: "white",
    borderStyle: "solid",
    padding: "1rem",
  },

  PADDING_SMALL: {
    padding: ".5rem",
  },

  PADDING_MEDIUM: {
    padding: "1rem",
  },

  PADDING_LARGE: {
    padding: "2rem",
  },

  STEPS_BAR: {
    0: "#061C3F",
    1: "#1589EE",
    2: "#FFB75D",
    review: "#4BCA81",
  },
};
