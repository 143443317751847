import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_CONVERSATIONS = `${API_V3}/conversations`;

export const conversationService = {
  getAll: async () => {
    const options = await apiOptions({
      url: `${API_CONVERSATIONS}`,
    });
    return apiRequest(options);
  },
};
