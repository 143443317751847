import React from "react";
import { Grid, useTheme } from "@mui/material";
import { SubHeader } from ".";
import { StatesCities } from "../dummy";
import en from "../locale/en.json";
import { MiInputSelectBox, MiInputTextbox } from "@miview/components";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  formAlign: {
    marginTop: 15,
    paddingRight: 10,
  },
  corporateAddress: {
    marginBottom: 50,
  },
}));

const states = StatesCities.map((item) => {
  return { label: item.State, value: item.State };
});

const cities = StatesCities[0].Cities.map((item) => {
  return { label: item, value: item };
});

const CorporateAddressForm = ({ editMode, edit, isMissingRequiredField }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const addressHouseNumberError =
    isMissingRequiredField && !edit.allFilled("addressHouseNumber");
  const addressCityError =
    isMissingRequiredField && !edit.allFilled("addressCity");
  const addressStateError =
    isMissingRequiredField && !edit.allFilled("addressState");
  const addressZipCodeError =
    isMissingRequiredField && !edit.allFilled("addressZipCode");
  return (
    <Grid container style={{ margin: "20px 0" }}>
      <Grid item xs={12} className={classes.corporateAddress}>
        <SubHeader text={en.corporateAddress} />
      </Grid>
      <Grid item xs={12} style={{ paddingRight: 10 }}>
        <MiInputTextbox
          labelTextColor={
            addressHouseNumberError
              ? theme.palette.secondary.red
              : theme.palette.primary.main
          }
          labelText={en.address}
          required
          error={addressHouseNumberError}
          helperText={addressHouseNumberError ? "Required a valid address" : ""}
          disabled={!editMode}
          value={edit.getValue("addressHouseNumber")}
          handleChange={(e) =>
            edit.update({ addressHouseNumber: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.address2}
          disabled={!editMode}
          value={edit.getValue("addressStreetNumber")}
          handleChange={(e) =>
            edit.update({ addressStreetNumber: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputSelectBox
          labelTextColor={
            addressCityError
              ? theme.palette.secondary.red
              : theme.palette.primary.main
          }
          labelText={en.city}
          menuItems={cities}
          disableNone={true}
          required
          error={addressCityError}
          disabled={!editMode}
          displayEmpty={true}
          isPlaceholder={true}
          minWidth={150}
          value={edit.getValue("addressCity") || ""}
          handleChange={(e) => edit.update({ addressCity: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} className={classes.formAlign}>
        <MiInputSelectBox
          labelTextColor={
            addressStateError
              ? theme.palette.secondary.red
              : theme.palette.primary.main
          }
          labelText={en.state}
          menuItems={states}
          disableNone={true}
          displayEmpty={true}
          isPlaceholder={true}
          required
          error={addressStateError}
          disabled={!editMode}
          minWidth={150}
          value={edit.getValue("addressState") || ""}
          handleChange={(e) => edit.update({ addressState: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={
            addressZipCodeError
              ? theme.palette.secondary.red
              : theme.palette.primary.main
          }
          labelText={en.zipCode}
          required
          error={addressZipCodeError}
          helperText={addressZipCodeError ? "Enter valid Zip Code" : ""}
          disabled={!editMode}
          minWidth={150}
          value={edit.getValue("addressZipCode")}
          type={"number"}
          handleChange={(e) => edit.update({ addressZipCode: e.target.value })}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(CorporateAddressForm);
