//native using materialCommunityIcons
//view available icons at https://oblador.github.io/react-native-vector-icons/

export const ICONS_NATIVE_MISC = {
  CHECK: "check",
  CLOCK: "clock",
  HAND: "hand-back-right",
  DELETE: "delete",
  REFRESH: "refresh",
  CLOSE: "close",
  CAMERA: "camera",
  BLUETOOTH: "bluetooth",
  ADD: "add",
  CANCEL: "cancel",
  CALENDAR: "calendar",
  CHEVRON_RIGHT: "chevron-right",
  CHEVRON_LEFT: "chevron-left",
  CHEVRON_UP: "chevron-up",
  CHEVRON_DOWN: "chevron-down",
  PDF: "file-cad",
  UPLOAD: "cloud-upload",
  DOWNLOAD: "cloud-download",
  CASH: "cash",
  SEARCH: "magnify",
  SAVE: "content-save",
  DIRECTIONS: "directions",
  PERSON: "account",
  PERSON_ADD: "account-plus",
  MORE_VERTICAL: "dots-vertical",
  MORE_HORIZONTAL: "dots-horizontal",
  ADD_NOTE: "note-add",
  EMAIL: "email",
  EVENT: "calendar",
  CLOCK_REFRESH: "history",
  PASSWORD: "security",
  USER: "account",
  USD: "currency-usd",
  HOMESEARCH: "home-search",
  DOLLAR: "currency-usd",
  CALENDAROUTLINE: "calendar-month-outline",
  ARROWBACKWARD: "arrow-back-ios",
  ARROWFORWARD: "arrow-forward-ios",
  PAPERCLIP: "paperclip",
  TRASHOUTLINE: "trash-can-outline",
  BUILDER: "account-hard-hat",
  COMMUNITY: "home-group",
  WALK: "walk",
  ALERT: "alert",
  WALKTYPE: "tag-search",
  LOCATIONSEARCH: "location-searching",
  FILTER: "filter",
  CLOCKOUTLINE: "clock-outline",
  PENCIL: "pencil",
  PREVIEW: "eye",
  BLOCK: "block-helper",
  CIRCLE: "circle",
  CIRCLE_OUTLINE: "circle-outline",
  REDTAG: "tag",
};

export const ICONS_NATIVE_MESSAGING = {
  COMMENT: "comment",
  SEND: "send",
  REPLY: "reply",
};

export const ICONS_NATIVE_STATUS = {
  PENDING: "clock",
  COMPLETE: "check",
  INCOMPLETE: "close",
  HOLD: "hand-back-right",
  SCHEDULED: "calendar",
  INPROGRESS: "clock-outline",
  APPROVED: "check",
  DECLINED: "close",
  CREWHOLD: "account-cancel",
  NA: "cancel",
  ABORTED: "stop",
};

export const ICONS_WEB_STATUS = {
  PENDING: "mdiClock",
  COMPLETE: "mdiCheck",
  INCOMPLETE: "mdiClose",
  HOLD: "mdiHandBackRight",
  SCHEDULED: "mdiCalendar",
  INPROGRESS: "mdiClockOutline",
  APPROVED: "mdiCheck",
  DECLINED: "mdiClose",
  NA: "mdiCancel",
  ABORTED: "mdiStop",
};
