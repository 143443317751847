import React from "react";
import { MiBox } from "@miview/components";
import { useTheme, alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, { props }) => ({
  header: {
    backgroundColor: alpha(props.color, 0.2),
    color: props.color,
    borderRadius: props.borderRadius || "6px 6px 0px 0px",
    padding: "3px 0px 3px 6px",
    fontSize: props?.fontSize || theme.fontSize.small,
    fontWeight: theme.fontWeight.large,
  },
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 4,
  },
}));

const IssueCardContainer = ({
  title,
  titleColor,
  children,
  borderRadius,
  boxShadow,
  titleFontSize,
}) => {
  const theme = useTheme();
  const { classes } = useStyles({
    props: {
      color: titleColor || theme.palette.primary.grey,
      fontSize: titleFontSize,
      borderRadius,
    },
  });

  return (
    <MiBox padding={0} boxShadow={boxShadow}>
      <div className={classes.header}>{title}</div>

      <div className={classes.container}>{children}</div>
    </MiBox>
  );
};

export default IssueCardContainer;
