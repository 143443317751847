import React, { useState } from "react";
import { userService } from "@miview/api";
import { MiTHEME } from "@miview/theme";
import { MiDetailFields, MiModal } from "@miview/components";
import { HTTP_STATUSES, TOAST_TYPES } from "@miview/constants";
import { createToast } from "@miview/toast";

const UpdatePinModal = (props) => {
  const { onSave, onClose, open } = props;

  const [password, setPassword] = useState();
  const [newPin, setNewPin] = useState();
  const [pinValid, setPinValid] = useState();
  const [pinError, setPinError] = useState();

  const handleSetPin = (pin) => {
    const regex = /^[0-9]{4}$/;
    const isValid = pin && pin.match(regex);
    setPinValid(isValid);
    if (isValid) {
      setPinError(null);
    } else {
      setPinError("PIN must be 4 digits long");
    }
    setNewPin(pin);
  };

  const sendUpdatePin = async () => {
    const response = await userService.updatePin({ password, newPin });
    if (response.status === HTTP_STATUSES.OK) {
      onSave();
      createToast("Successful", TOAST_TYPES.SUCCESS);
    }
  };

  const actions = [
    {
      onClick: onClose,
      color: MiTHEME.palette.primary.grey,
      name: "Close",
      inverse: false,
    },
    {
      onClick: sendUpdatePin,
      color: MiTHEME.palette.primary.main,
      name: "Submit",
      inverse: true,
      disabled: !password || !pinValid,
    },
  ];

  const detailFields = [
    {
      label: "Password",
      value: password,
      fieldType: "password",
      required: true,
      setValue: (e) => setPassword(e),
      width: "50%",
    },
    {
      label: "New PIN",
      value: newPin,
      fieldType: "password",
      required: true,
      setValue: (e) => handleSetPin(e),
      width: "50%",
      error: pinError,
    },
  ];

  return (
    <MiModal
      title={"Update PIN"}
      open={open}
      isCloseButton={false}
      actions={actions}
    >
      <MiDetailFields detailFields={detailFields} />
    </MiModal>
  );
};

export default UpdatePinModal;
