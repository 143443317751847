import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const extraRequestService = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/extrapayrequests`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/extrapayrequests/${id}`,
      method: "get",
    });

    return apiRequest(options);
  },

  getCallouts: async (params) => {
    const options = await apiOptions({
      url: `${API_V3}/extrapayrequests/callouts`,
      method: "get",
      params,
    });

    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/extrapayrequests/${id}`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  approve: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/extrapayrequests/${id}/approve`,
      method: "post",
    });

    return apiRequest(options);
  },

  decline: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/extrapayrequests/${id}/decline`,
      method: "post",
    });

    return apiRequest(options);
  },
};
