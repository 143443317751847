import React, { useState, Fragment } from "react";
import { makeStyles } from "tss-react/mui";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { MiInputTextbox } from "@miview/components";

const offsetInputStyles = makeStyles()(() => ({
  iconStyle: {
    color: "black",
    float: "right",
    fontSize: "1rem",
    cursor: "pointer",
    marginRight: "5px",
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "5px",
  },
}));

const CadBoundaryOffsetField = (props) => {
  const { value: initialValue = "0", data = {}, handleSave } = props;
  const [value, setValue] = useState(initialValue);
  const [editMode, setEditMode] = useState(false);
  const { iconStyle, rowStyle } = offsetInputStyles();
  const onEditClick = () => {
    setEditMode(true);
  };
  const onCancelClick = () => {
    setEditMode(false);
    setValue(initialValue);
  };
  const onSaveClick = async () => {
    await handleSave({ ...data, offset: value });
    setEditMode(false);
  };
  const handleInputChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <div className={rowStyle}>
      <MiInputTextbox
        labelText="Offset"
        value={value}
        handleChange={handleInputChange}
        disabled={!editMode}
      />
      {!editMode && <EditIcon className={iconStyle} onClick={onEditClick} />}
      {editMode && (
        <Fragment>
          <SaveIcon
            className={iconStyle}
            style={{ marginRight: "5px" }}
            onClick={onSaveClick}
          />
          <ClearIcon className={iconStyle} onClick={onCancelClick} />
        </Fragment>
      )}
    </div>
  );
};

export default CadBoundaryOffsetField;
