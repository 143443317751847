import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const materialCategoryService = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/materialcategories`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/materialcategories`,
      method: "post",
      data: request,
    });
    const toastMessageConfig = {
      failure: {
        message: "Failure adding Material Category",
      },
      success: {
        message: "Material Category added successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  replace: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/materialcategories/${id}`,
      method: "put",
      data: request,
    });
    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/materialcategories/${id}`,
      method: "patch",
      data: request,
    });
    const toastMessageConfig = {
      failure: {
        message: "Failure editing Material Category",
      },
      success: {
        message: "Material Category edited successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/materialcategories/${id}`,
      method: "delete",
    });
    const toastMessageConfig = {
      failure: {
        message: "Failure deleting Material Category",
      },
      success: {
        message: "Material Category deleted successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
};
