import React from "react";
import { MiButton } from "@miview/components";
import { useTheme } from "@mui/material";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  footer: {
    width: "100%",
    position: "fixed",
    left: 0,
    bottom: 0,
    zIndex: 1,
    background: "rgba(0, 0, 0, 0.6)",
    minHeight: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    "& #faangledownButton i.fa, & #faangleupButton i.fa": {
      color: "inherit!important",
      fontSize: "23px",
    },
  },
  footerFlex: {
    display: "flex",
    alignItems: "center",
  },
  floatButton: {
    position: "fixed",
    right: 20,
    bottom: 10,
    zIndex: 1,
    "& i.fa": {
      color: `${theme.palette.primary.white}!important`,
      marginRight: 10,
    },
  },
}));

const NewHomesRequistionFooter = ({
  onDiscardRequestModal,
  SaveForLaterClick,
  SubmitOnClick,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.footer}>
      <MiButton
        style={{
          width: 150,
          height: 45,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: theme.palette.primary.red,
          color: theme.palette.primary.white,
          fontWeight: theme.fontWeight.medium,
          borderRadius: "4px",
        }}
        title="Discard"
        onClick={onDiscardRequestModal}
      />
      <div className={classes.footerFlex}>
        <MiButton
          style={{
            width: 150,
            height: 45,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: theme.palette.primary.white,
            color: theme.palette.primary.bluegrey,
            fontWeight: theme.fontWeight.medium,
            borderRadius: "4px",
          }}
          title="Save For Later"
          onClick={SaveForLaterClick}
        />
        <MiButton
          style={{
            width: 150,
            height: 45,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: theme.palette.primary.green,
            color: theme.palette.primary.white,
            fontWeight: theme.fontWeight.medium,
            borderRadius: "4px",
          }}
          title="Submit"
          onClick={SubmitOnClick}
        />
      </div>
    </div>
  );
};

export const FloatingRightAction = ({ onClick, title, icon, background }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.floatButton}>
      <MiButton
        style={{
          width: 150,
          height: 45,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: background,
          color: theme.palette.primary.white,
          fontWeight: theme.fontWeight.medium,
          borderRadius: "35px",
        }}
        title={title}
        icon={icon}
        onClick={onClick}
      />
    </div>
  );
};

export default NewHomesRequistionFooter;
