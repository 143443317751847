import { makeStyles } from "tss-react/mui";

export const areaStyles = {
  areaContainer: {
    marginBottom: 16,
    overflow: "hidden",
  },
  areaHeader: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    padding: "5px 10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    minHeight: 88,
  },
  areaLabel: {
    boxShadow: "2px 0px 5px 2px rgba(235, 235, 235, 1)",
    paddingLeft: "0.8rem",
    width: "100%",
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5,
    zIndex: 1,
    fontSize: "1rem",
    display: "flex",
    overflow: "hidden",
  },
  areaLabelForm: {
    boxShadow: "2px 0px 5px 2px rgba(235, 235, 235, 1)",
    paddingLeft: "0.8rem",
    width: "100%",
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5,
    zIndex: 1,
    fontSize: "1rem",
  },
  areaLabelText: {
    color: "#969492",
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 5,
    paddingTop: 8,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    flex: 1,
  },
  areaLabelIcon: {
    margin: "0 5px 0 0",
    cursor: "pointer",
    color: "gray",
  },
  areaLabelImage: {
    margin: "8px 15px",
    backgroundColor: "rgb(214, 214, 214)",
    height: 60,
    width: 60,
    borderRadius: 5,
    border: "1px solid gray",
    cursor: "pointer",
    float: "right",
  },
  areaImageIcon: {
    color: "gray",
  },
  "areaLabelImage img": {
    height: 60,
    width: 60,
    borderRadius: 5,
    border: "1px solid gray",
  },
  iconWrapper: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    width: "1.8rem",
    minHeight: "1.8rem",
    minWidth: "1.8rem",
    zIndex: 2,
  },
  areaIconButton: {
    boxShadow: "0px 0px 5px 2px rgba(235, 235, 235, 1)",
    margin: "0 10px 0 0",
    flex: 1,
  },
  areaIconStackButton: {
    boxShadow: "0px 0px 5px 2px rgba(235, 235, 235, 1)",
  },
  dragToZone: {
    width: "100%",
    opacity: 0.8,
    cursor: "grab",
    margin: "0 10px",
  },
  moreHorizIcon: { margin: "0 auto" },
  areaItemFormImage: {
    backgroundColor: "rgb(214, 214, 214)",
    height: 60,
    width: 60,
    borderRadius: 5,
    border: "1px solid gray",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "lightblue",
    },
    "& img": {
      height: 60,
      width: 60,
      borderRadius: 5,
      border: "1px solid gray",
      "&:hover": {
        opacity: "0.5",
      },
    },
  },
  createDrawingLabel: {
    margin: 0,
    height: 40,
  },
  createDrawingInput: {
    width: "0.1",
    height: "0.1",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },
  areaItemFormFormButtons: { display: "flex", marginTop: "20px" },
  areaItemFormRemoveImage: {
    margin: "0px 0px 0px 17px",
    textDecoration: "underline",
    fontSize: "12px",
    cursor: "pointer",
  },
};

export const useAreaStyles = makeStyles()(areaStyles);
