import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const adminService = {
  initiatePinReset: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/admin/${id}/resetPin`,
      method: "post",
    });
    const toastMessageConfig = {
      failure: {
        message: "Error sending pin reset email",
      },
      success: {
        message: "Reset pin email sent",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },
};
