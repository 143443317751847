import { apiOptions, apiRequest } from "../utils";
import { API_V3 } from "../constants";

export const walkTypeTemplateItemService = {
  replace: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/walkTypeTemplateItems/${id}`,
      method: "put",
      data: request,
    });

    return apiRequest(options);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/walkTypeTemplateItems/${id}`,
      method: "delete",
    });

    return apiRequest(options);
  },
};
