import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const materialService = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/materials`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/materials/${id}`,
      method: "get",
    });

    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/materials`,
      method: "post",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving material",
      },
      success: {
        message: "Material saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/materials/${id}`,
      method: "patch",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving material",
      },
      success: {
        message: "Material saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  updatePrice: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/materials/${id}/pricing`,
      method: "put",
      data: request,
    });

    return apiRequest(options);
  },

  getSuppliers: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/materials/${id}/suppliers`,
      method: "get",
    });

    return apiRequest(options);
  },

  createSupplier: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/materials/${id}/suppliers`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  updateSupplier: async (materialId, supplierId, request) => {
    const options = await apiOptions({
      url: `${API_V3}/materials/${materialId}/suppliers/${supplierId}`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  deleteSupplier: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/materials/suppliers/${id}`,
      method: "delete",
    });

    return apiRequest(options);
  },
};
