import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiSnackbar, MiFloatingAction } from "@miview/components";
import en from "../../../../locale/en.json";
const useStyles = makeStyles()(() => ({
  floatingAction: {
    position: "fixed",
    bottom: 10,
    right: 20,
  },
}));

const SupplierEdit = ({ mode, onSave, onChange, type }) => {
  const onDiscard = () => {
    onChange(false);
  };
  const { classes } = useStyles();
  return (
    <Grid className={classes.floatingAction}>
      {mode ? (
        <MiSnackbar
          visible={mode}
          handleSaveClick={onSave}
          justifyCenter={false}
          handleCancelClick={onDiscard}
          saveTitle={en.save}
          cancelTitle={en.discard}
          buttonTwoType={type}
        />
      ) : (
        <MiFloatingAction
          title={en.editInfo}
          handleClick={() => onChange(true)}
          spaceAfterIcon={10}
        />
      )}
    </Grid>
  );
};

SupplierEdit.propTypes = {
  mode: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default React.memo(SupplierEdit);
