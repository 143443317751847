import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_BONUS = `/bonuses`;
const API_DISPUTE = `/dispute`;
const API_CURRENT = `/current`;
const API_BONUS_ACTIVITY = "bonusactivities";

export const bonusService = {
  getBonusCurrent: async () => {
    const options = await apiOptions({
      url: `${API_V3}${API_BONUS}${API_CURRENT}`,
    });
    const result = await apiRequest(options);
    return result;
  },

  getBonusActivity: async (userId, propertyStageId) => {
    const options = await apiOptions({
      url: `${API_V3}/${API_BONUS_ACTIVITY}`,
      params: {
        userId: userId,
        propertyStageId: propertyStageId,
      },
    });
    const result = await apiRequest(options);
    return result;
  },

  createDispute: async (data) => {
    const options = await apiOptions({
      url: `${API_BONUS}${API_DISPUTE}`,
      method: "post",
      data,
    });
    const result = await apiRequest(options);
    return result;
  },

  getConfig: async () => {
    const options = await apiOptions({
      url: `${API_V3}/bonuses/config`,
      method: "get",
    });
    return apiRequest(options);
  },

  replaceConfig: async (data) => {
    const options = await apiOptions({
      url: `${API_V3}/bonuses/config`,
      method: "put",
      data,
    });
    return apiRequest(options);
  },
};
