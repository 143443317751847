import React, { useState, useEffect } from "react";
import {
  MiPageHeader,
  MiButton,
  MiLink,
  MiList,
  MiIcon,
} from "@miview/components";
import { useTheme } from "@mui/material/styles";
import { useRouter, useComponentState, usePermissions } from "@miview/hooks";
import { CreateCommunityModal } from "./CreateCommunityModal";
import { PERMISSIONS, TOAST_TYPES } from "@miview/constants";
import { createToast } from "@miview/toast";
import { communityService } from "@miview/api";
import { mdiHomeGroup, mdiPlus } from "@mdi/js";

const Communities = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [communities, setCommunities] = useState([]);

  const router = useRouter();
  const stateManager = useComponentState();
  const permissions = usePermissions();
  const theme = useTheme();

  useEffect(() => {
    document.title = "Communities";
  }, []);

  const openAddModal = () => {
    if (!permissions.hasPermission(PERMISSIONS.CAN_CREATE_COMMUNITIES)) {
      createToast(
        "You do not have permission to create communities",
        TOAST_TYPES.ERROR
      );
      return;
    }
    setIsCreateModalOpen(true);
  };

  const openCommunity = (communityId) => {
    router.push(`/communities/${communityId}`);
  };

  const fetchData = ({ ...params }) => {
    return stateManager.run(async () => {
      return communityService.getAll({ ...params });
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Community",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <MiLink
            onClick={(p) => {
              p.preventDefault();
              openCommunity(params.id);
            }}
            title={params.value}
          />
        );
      },
    },
    {
      field: "cityName",
      headerName: "City",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "stateNameShort",
      headerName: "State",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "numberOfProperties",
      headerName: "Homes",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
  ];

  const createClosed = (success, newCommunityId) => {
    if (success) {
      router.navigate(`/communities/${newCommunityId}`);
    } else {
      setIsCreateModalOpen(false);
    }
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon path={mdiHomeGroup} size={1} color={theme.palette.primary.main} />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Community"
        icon={mdiPlus}
        inverse={true}
        onClick={openAddModal}
        color={theme.palette.primary.main}
      />
    );
  };
  return (
    <React.Fragment>
      {stateManager.statusTag("communityListStatus")}
      <CreateCommunityModal
        isVisible={isCreateModalOpen}
        close={createClosed}
      />
      <MiPageHeader
        title="Communities"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
        loading={stateManager.isBusy()}
      />
      <MiList
        data={communities}
        setData={setCommunities}
        dataProvider={fetchData}
        columns={columns}
        disableColumnMenu
      />
    </React.Fragment>
  );
};

export default Communities;
