import React from "react";
import { Menu, MenuItem } from "@mui/material";

export const MiActionMenu = (props) => {
  const { open, selectedActionRow, rowActions, handleClose, anchorEl } = props;
  return (
    <div>
      <Menu
        data-testid="MiAction-Menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
      >
        {rowActions.map((action, index) => {
          return (
            !action.hide && (
              <MenuItem
                disabled={action.disabled}
                data-testid={`MiAction-MenuItem-${index}`}
                onClick={() => action.onClick(selectedActionRow)}
                key={index}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {action.renderIcon && (
                    <div style={{ marginRight: 10 }}>
                      {action.renderIcon(selectedActionRow)}
                    </div>
                  )}
                  {action.text}
                </div>
              </MenuItem>
            )
          );
        })}
      </Menu>
    </div>
  );
};
