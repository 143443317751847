import _nav from "../_nav";
import { HTTP_STATUSES } from "@miview/constants";
import { userService } from "@miview/api";
import { buildNavBasedOnPermissions } from "@miview/utils";

export const login = async (email, password) => {
  try {
    const loginObj = {
      Email: email,
      Password: password,
    };

    const response = await userService.verifyPassword(loginObj);

    if (response.status !== HTTP_STATUSES.OK) {
      return false;
    }

    const { data } = response;
    const permissions = data.permissions;

    Object.keys(data).forEach((k) => {
      localStorage.setItem(k, data[k]);
    });

    const navConfig = buildNavBasedOnPermissions(_nav, permissions);
    localStorage.setItem("navConfig", JSON.stringify(navConfig));

    return data;
  } catch {
    return false;
  }
};

export const checkIfLoggedIn = async () => {
  try {
    await userService.checkIfLoggedIn();
    let serializedPermissions = localStorage.getItem("permissions");
    let permissions = await JSON.parse(serializedPermissions);
    const navConfig = buildNavBasedOnPermissions(_nav, permissions);
    localStorage.setItem("navConfig", JSON.stringify(navConfig));
  } catch (error) {
    localStorage.removeItem("token");
  }
};

export const logout = async () => {
  const returnUrl = localStorage.getItem("lastVisitedRoute");
  localStorage.clear();
  localStorage.setItem("lastVisitedRoute", returnUrl);
};
