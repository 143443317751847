import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Divider } from "@mui/material";
import {
  MiTab,
  MiTable,
  MiInputTextbox,
  MiPageHeader,
} from "@miview/components";
import { accountingTransactionService, payActivityService } from "@miview/api";
import { createFilter } from "@miview/utils";
import { HTTP_STATUSES } from "@miview/constants";
import { useComponentState, useRouter } from "@miview/hooks";
import { mdiFormatListBulleted } from "@mdi/js";
import moment from "moment";

const useStyles = makeStyles()(() => ({
  payActivitiesTableStyles: {
    marginTop: "5px",
    marginBottom: "10px",
    "&.MuiDataGrid-root": {
      border: 0,
      borderTop: "1px solid rgba(224, 224, 224, 1)",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
  },
  detailsContainer: {
    padding: "0px 5px",
    margin: "0px 5px",
  },
}));

export const BillDetails = () => {
  const tabs = [
    {
      label: "Pay Details",
      value: "paydetails",
    },
    {
      label: "Pay Activities",
      value: "payactivities",
    },
  ];

  const [tabData, setTabData] = useState();
  const [payActivities, setPayActivities] = useState([]);
  const [currentTab, setCurrentTab] = useState(tabs[0].value);
  const filterHelper = createFilter();
  const theme = useTheme();
  const { classes } = useStyles();
  const stateManager = useComponentState();
  const router = useRouter();
  const { id } = router.params;

  useEffect(() => {
    fetchTabData();
    setCurrentTab(tabs[0].value);
  }, [id]);

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  const renderTabContent = (value) => {
    switch (value) {
      case "paydetails": {
        return (
          <>
            <div style={{ display: "flex", margin: "20px 0 20px 12px" }}>
              <div style={{ marginLeft: 25 }}>
                <MiInputTextbox
                  labelText="Payee"
                  value={tabData.payableToUserName}
                  disabled={true}
                />
              </div>
              <div style={{ marginLeft: 25 }}>
                <MiInputTextbox
                  labelText="Billing Account"
                  value={tabData.payableToUserBillingAccount}
                  disabled={true}
                />
              </div>
              <div style={{ marginLeft: 25 }}>
                <MiInputTextbox
                  labelText="Amount"
                  value={formatCurrency(tabData.amount)}
                  disabled={true}
                />
              </div>
              <div style={{ padding: "0 20px 0 0", marginLeft: "25px" }}>
                <MiInputTextbox
                  labelText="Stage Scheduled Start Date"
                  value={moment(tabData.stageScheduledStartDate).format(
                    "MM/DD/YYYY"
                  )}
                  inputProps={{ disabled: true }}
                  disabled
                />
              </div>
            </div>
            <div style={{ display: "flex", margin: "20px 0 20px 12px" }}>
              <div style={{ marginLeft: 25 }}>
                <MiInputTextbox
                  labelText="Status"
                  value={tabData.status}
                  disabled={true}
                />
              </div>
              <div style={{ marginLeft: 25 }}>
                <MiInputTextbox
                  labelText="Expense Type"
                  value={tabData.expenseType}
                  disabled={true}
                />
              </div>
            </div>
          </>
        );
      }
      case "payactivities": {
        return (
          <MiTable
            key={0}
            tableContainerStyle={tableContainerStyle}
            columns={payActivityColumns}
            rows={payActivities}
            style={tableStyle}
            headerHeight={40}
            rowHeight={75}
            autoHeight={true}
            className={classes.payActivitiesTableStyles}
            hideFooter
            disableColumnMenu
            emptyListProps={{
              icon: mdiFormatListBulleted,
              text: "There are no records to display",
            }}
          />
        );
      }
      default: {
        return <div>Under construction</div>;
      }
    }
  };

  const getAccountingTransaction = async () => {
    stateManager.run(async () => {
      const response = await accountingTransactionService.get(id);
      if (response.status === HTTP_STATUSES.OK) {
        setTabData(response.data);
      }
    });
  };
  const getPayActivities = async () => {
    const filters = filterHelper.equals({
      accountingTransactionId: id,
    });
    const params = { expand: "Walk" };
    stateManager.run(async () => {
      const response = await payActivityService.getAll({
        params,
        filters,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setPayActivities(response.data);
      }
    });
  };
  const formatCurrency = (value) => {
    if (!value) {
      return null;
    }
    if (value < 0) {
      return `-$${Math.abs(value).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    }
    return `$${value?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };
  const fetchTabData = () => {
    getAccountingTransaction();
    getPayActivities();
  };
  const payActivityColumns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "left",
      align: "left",
      flex: 0.6,
      sortable: false,
      renderCell: ({ value }) => {
        return <div style={{ fontWeight: theme.fontWeight.bold }}>{value}</div>;
      },
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {value}
          </div>
        );
      },
    },
    {
      field: "typeName",
      headerName: "Pay Type",
      headerAlign: "left",
      align: "left",
      flex: 0.7,
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {value}
          </div>
        );
      },
    },
    {
      field: "WalkTypeName",
      headerName: "Job Type",
      headerAlign: "left",
      align: "left",
      flex: 0.8,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <div>
            {row.stageWalk?.walkTypeName}{" "}
            {row.stageWalk?.progress ? `${row.stageWalk.progress * 100}%` : ""}
          </div>
        );
      },
    },
    {
      field: "assignedUserName",
      headerName: "Assigned User",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              fontWeight: theme.fontWeight.bold,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.stageWalk?.assignedUserName}
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      headerAlign: "left",
      align: "left",
      flex: 0.8,
      sortable: false,
      renderCell: ({ value }) => {
        if (!value) {
          return null;
        }
        return (
          <div
            style={{
              fontWeight: theme.fontWeight.bold,
            }}
          >
            {formatCurrency(value)}
          </div>
        );
      },
    },
  ];

  const renderTab = () => {
    if (!tabData) {
      return null;
    }
    return (
      <div
        style={{
          backgroundColor: theme.palette.primary.white,
          margin: "5px 0 0 0",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 20px 0",
          }}
        >
          <div style={{ width: "85%", display: "flex", alignItems: "left" }}>
            <MiPageHeader
              color={theme.palette.primary.main}
              title={`Bill-${id} Details`}
              loading={stateManager.isBusy()}
              details={[
                `${tabData.address}, ${tabData.city} | Stage: ${tabData.stageTypeName} | Community: ${tabData.community} | Builder: ${tabData.builder} `,
              ]}
            />
          </div>
        </div>
        <Divider
          style={{ backgroundColor: "black" }}
          orientation="vertical"
          flexItem
        />
        <div className={classes.detailsContainer}></div>
        <Divider
          style={{ backgroundColor: "black" }}
          orientation="vertical"
          flexItem
        />
        <div className={classes.detailsContainer}></div>
        <div>
          <MiTab
            tabs={tabs}
            currenttab={currentTab}
            onTabChange={handleTabChange}
            renderTabContent={renderTabContent}
          />
        </div>
      </div>
    );
  };
  return renderTab();
};

const tableContainerStyle = {
  width: "100%",
  minHeight: "100px",
  padding: "0px 10px",
};
const tableStyle = { minHeight: "100px" };
