import React from "react";
import PropTypes from "prop-types";
import { InputAdornment } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiInputTextbox, MiInputSelectBox } from "@miview/components";
import SearchIcon from "@mui/icons-material/Search";
import EventIcon from "@mui/icons-material/Event";

const useStyles = makeStyles()((theme) => ({
  componentCustom: {
    width: "100%",
    "& > div": {
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%",
    },
    "& > div > label": {
      position: "relative",
      left: "0",
      top: "0",
      marginBottom: 5,
      color: theme.palette.primary.teal,
    },
    "& fieldset": {
      border: "none",
    },
  },
  componentCustomSelect: {
    width: "100%",

    "& > div > div": {
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%",
    },

    "& > div > div > label": {
      position: "relative",
      left: "0",
      top: "0",
      marginBottom: 5,
    },
    "& fieldset": {
      borderColor: "transparent",
    },
    "& > div > div > label + div.Mui-error": {
      background: theme.palette.light.red,
    },
    "& > div > div > label + div.Mui-error fieldset": {
      border: `1px solid ${theme.palette.primary.red}`,
    },
  },

  componentIconwithText: {
    width: "100%",
    maxWidth: 100,
    "& > div": {
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%",
    },
    "& input": {
      minHeight: 36,
    },
    "& label + div": {
      background: theme.palette.light.grey,
      borderRadius: "4px",
      border: `1px solid ${theme.palette.lightAccent.grey}`,
    },
    "& fieldset": {
      border: "none",
    },
  },

  componentSearch: {
    "& fieldset": {
      border: "none",
    },

    "& > div": {
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%",
    },

    "& input": {
      minHeight: 30,
    },

    "& label + div > div": {
      background: theme.palette.light.grey,
      borderRadius: "4px",
      border: `1px solid ${theme.palette.lightAccent.grey}`,
      paddingRight: 0,
    },

    "& label + div > .Mui-focused": {
      borderColor: theme.palette.primary.blue,
      background: theme.palette.primary.white,
    },
  },

  darkInput: {
    marginBottom: 20,

    "& > div": {
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%",
    },

    "& > div > label": {
      position: "relative",
      left: "0",
      top: "0",
      marginBottom: 5,
      color: theme.palette.primary.white,
      fontSize: theme.fontSize.medium,
    },
    "& > div > label>span": {
      color: theme.palette.primary.amber,
    },

    "& fieldset": {
      border: "none",
    },

    "& label + div": {
      background: theme.palette.primary.grey,
      color: theme.palette.primary.white,
      borderRadius: "4px",
    },

    "& label + div > div": {
      color: theme.palette.primary.white,
      fontSize: theme.fontSize.medium,
    },
  },

  darkSelectBox: {
    marginBottom: 20,
    "& > div > div > label": {
      position: "relative",
      left: "0",
      top: "0",
      marginBottom: 5,
      color: theme.palette.primary.white,
      fontSize: theme.fontSize.medium,
    },

    "& > div > div": {
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%",
    },

    "& > div > div > label + div": {
      background: theme.palette.primary.grey,
      color: theme.palette.primary.white,
    },

    "& > div > div > label + div svg": {
      color: theme.palette.primary.white,
    },

    "& fieldset": {
      border: "none",
    },
  },

  datePickerStyle: {
    width: "100%",
    "& > div > label": {
      position: "relative",
      left: "0",
      top: "0",
      marginBottom: 5,
      color: theme.palette.primary.teal,
    },

    "& fieldset": {
      border: "none",
    },
  },

  editorRoot: {
    "& .ql-snow .ql-stroke": {
      stroke: theme.palette.light.grey,
    },

    "& .ql-snow .ql-fill, & .ql-snow .ql-stroke.ql-fill": {
      fill: theme.palette.light.grey,
    },

    "& .ql-snow.ql-toolbar button.ql-active .ql-stroke, & .ql-picker-label:hover .ql-stroke, & .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke":
      {
        stroke: theme.palette.primary.white,
      },

    "& .ql-snow .ql-picker": {
      color: theme.palette.primary.white,
    },

    "& .ql-snow.ql-toolbar button:hover .ql-stroke": {
      color: theme.palette.primary.white,
      stroke: `${theme.palette.primary.white}!important`,
    },

    "& .quill ": {
      display: "flex",
      flexDirection: "column-reverse",
      width: "100%",
    },

    "& .ql-toolbar.ql-snow": {
      border: "none",
      backgroundColor: theme.palette.dark.grey,
      fontFamily: "inherit",
    },

    "& .ql-container.ql-snow": {
      border: "none",
      background: theme.palette.primary.grey,
      minHeight: 150,
      color: theme.palette.primary.white,
      fontFamily: "inherit",
      fontSize: theme.fontSize.medium,
    },

    "& .ql-container *": {
      color: theme.palette.primary.white,
    },

    "& .ql-snow .ql-picker-options": {
      backgroundColor: theme.palette.dark.grey,
      border: `1px solid ${theme.palette.dark.greyLight} !important`,
      padding: 15,
      borderRadius: 10,
      top: "auto!important",
      bottom: 25,
    },

    "& .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label": {
      borderColor: "transparent",
    },

    "& .ql-tooltip": {
      backgroundColor: theme.palette.dark.grey,
      boxShadow: "none",
    },

    "& .ql-snow .ql-tooltip input[type=text]": {
      color: theme.palette.primary.grey,
    },
    "& .ql-custom": {
      color: theme.palette.primary.grey,
      "&:hover": {
        color: `${theme.palette.primary.white}!important`,
      },
    },
  },
  editorLabel: {
    color: theme.palette.primary.white,
    "& span": {
      color: theme.palette.primary.red,
    },
  },

  editorRootView: {
    "& .ql-disabled.ql-container.ql-snow": {
      border: "none",
      fontFamily: "inherit",
      fontSize: "inherit",
    },
    "& .ql-editor": {
      padding: 0,
    },
  },
}));

export const MiInputBoxCustom = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.componentCustom}>
      <MiInputTextbox {...props} />
    </div>
  );
};

export const MiSelectBox = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.componentCustomSelect}>
      <MiInputSelectBox {...props} />
    </div>
  );
};

export const MiInputBoxIcon = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.componentIconwithText}>
      <MiInputTextbox {...props} />
    </div>
  );
};

export const MiSearch = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.componentSearch}>
      <MiInputTextbox
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </div>
  );
};

export const MiDatePicker = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.datePickerStyle}>
      <MiInputTextbox
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <EventIcon />
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </div>
  );
};

export const DarkInput = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.darkInput}>
      <MiInputTextbox {...props} />
    </div>
  );
};

export const DarkSelect = (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.darkSelectBox}>
      <MiInputSelectBox {...props} />
    </div>
  );
};

MiInputBoxCustom.propTypes = {
  defaultText: PropTypes.bool.isRequired,
  labelText: PropTypes.string.isRequired,
  required: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  minWidth: PropTypes.number,
  isFrom: PropTypes.bool,
};

export default MiInputBoxCustom;
