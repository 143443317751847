import React from "react";
import { IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { TemplateStatus } from "../../../../components/Status";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles()((theme) => ({
  rqHeaderStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconTitle: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    color: theme.palette.primary.white,
  },
  iconCircle: {
    width: 50,
    height: 50,
    background: theme.palette.primary.teal,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: 30,
  },
  iconHeading: {
    color: theme.palette.secondary.bluegrey,
    fontSize: theme.fontSize.headerSmall,
    marginRight: 30,
  },
  iconBadge: {
    background: theme.palette.lightAccent.grey,
    fontSize: theme.fontSize.xsmall,
    padding: "4px 10px",
    borderRadius: "3px",
    color: theme.palette.secondary.bluegrey,
    fontWeight: theme.fontWeight.medium,
  },
  chFlex: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },

  buttonContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-end",
  },

  iconButtonStyle: {
    color: theme.palette.dark.bluegrey,
    marginLeft: 10,
  },
  centerHeader: {
    minWidth: 250,
    display: "flex",
    justifyContent: "center",
  },
}));

const NewHomesRequistionHeader = ({ ReqNumber, Status, CloseonClick }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.rqHeaderStyle}>
      <div className={classes.iconTitle}>
        <div className={classes.iconCircle}>
          <ListAltOutlinedIcon />
        </div>
        <div className={classes.iconHeading}>New Home Requisition</div>
        <div className={classes.iconBadge}>{ReqNumber}</div>
      </div>
      <div className={classes.centerHeader}>
        <div className={classes.chFlex}>
          <TemplateStatus value={Status} />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <IconButton
          className={classes.iconButtonStyle}
          onClick={CloseonClick}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default NewHomesRequistionHeader;
