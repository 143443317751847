import React, { useState } from "react";
import { accountService } from "@miview/api";
import { en, HTTP_STATUSES } from "@miview/constants";
import { DELIVERY_LOCATION_STATUS } from "../../constants";
import {
  MiPageHeader,
  MiModal,
  MiInputTextbox,
  MiInputSelectBox,
  MiIcon,
  MiButton,
  MiModalConfirm,
  MiChip,
  MiList,
} from "@miview/components";
import { useTheme, Grid, alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useEdit, useComponentState } from "@miview/hooks";
import {
  mdiHomeCity,
  mdiPencil,
  mdiDelete,
  mdiProgressCheck,
  mdiMinusCircleOffOutline,
  mdiMapMarker,
  mdiPlus,
} from "@mdi/js";
import { StatesCities } from "../../../Purchasing/dummy";
import { STATUS_DROPDOWN } from "../../constants";

const states = StatesCities.map((item) => {
  return { label: item.State, value: item.State };
});

const Status = STATUS_DROPDOWN.map((item) => {
  return { label: item, value: item };
});

const useStyles = makeStyles()(() => ({
  formAlignment: {
    marginTop: 20,
  },
  formWidth: {
    width: 524,
  },
}));

export const WarehouseLocationManagement = () => {
  const [rows, setRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const theme = useTheme();
  const edit = useEdit(selectedRow);
  const stateManager = useComponentState();
  const { classes } = useStyles();
  const [isValid, setIsValid] = useState(true);

  const types = {
    [DELIVERY_LOCATION_STATUS.active]: theme.palette.primary.blue,
    [DELIVERY_LOCATION_STATUS.inactive]: theme.palette.primary.grey,
  };

  const columns = [
    {
      field: "name",
      headerName: en.name,
      flex: 1,
    },
    {
      field: "address",
      headerName: en.address,
      flex: 1,
    },
    {
      field: "city",
      headerName: en.city,
      flex: 1,
    },
    {
      field: "state",
      headerName: en.state,
      flex: 1,
    },
    {
      field: "status",
      headerName: en.status,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <MiChip
            size="small"
            label={value}
            style={{
              backgroundColor: alpha(types[value], 0.18),
              color: types[value] || theme.palette.primary.main,
            }}
            className={classes.statusStyle}
          />
        );
      },
    },
  ];

  const fetchData = (params) => {
    return stateManager.run(async () => {
      return accountService.deliveryLocations.getAll(params);
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    edit.reset();
    setIsValid(true);
  };

  const handleSubmitLocation = () => {
    if (!canSubmit()) {
      setIsValid(false);
    } else {
      const payload = { ...edit.edits };
      selectedRow.id ? handleUpdate(payload) : handleCreate(payload);
      setIsValid(true);
    }
  };

  const handleUpdate = async (value) => {
    const response = await accountService.deliveryLocations.update({
      id: selectedRow.id,
      data: value,
      successMessage: en.warehouseLocationWasEditedSuccessfully,
    });
    if (response.status === HTTP_STATUSES.OK) {
      edit.reset();
      setRefresh(!refresh);
      setShowModal(false);
    }
  };

  const handleCreate = async (value) => {
    const response = await accountService.deliveryLocations.create({
      data: value,
      successMessage: en.warehouseLocationWasAddedSuccessfully,
    });
    if (response.status === HTTP_STATUSES.CREATED) {
      edit.reset();
      setRefresh(!refresh);
      setShowModal(false);
    }
  };

  const handleDelete = async () => {
    const response = await accountService.deliveryLocations.delete({
      id: selectedRow.id,
      successMessage: en.warehouseLocationWasDeletedSuccessfully,
    });
    if (response.status === HTTP_STATUSES.NO_CONTENT) {
      edit.reset();
      setRefresh(!refresh);
    }
    closeDeleteModal();
  };

  const requiredFields = ["name", "city", "address", "state", "status"];

  const canSubmit = () => {
    return edit.allFilled(...requiredFields);
  };

  const actions = [
    {
      onClick: handleCloseModal,
      color: theme.palette.primary.grey,
      name: "Close",
      inverse: false,
    },
    {
      onClick: handleSubmitLocation,
      color: theme.palette.primary.main,
      name: "Submit",
      inverse: true,
      disabled: !canSubmit(),
    },
  ];

  const isActive = (status) => {
    return status === DELIVERY_LOCATION_STATUS.active;
  };

  const handleActivation = async (row) => {
    const payload = isActive(row.status)
      ? DELIVERY_LOCATION_STATUS.inactive
      : DELIVERY_LOCATION_STATUS.active;
    const response = await accountService.deliveryLocations.update({
      id: row.id,
      data: { status: payload },
      successMessage: isActive(row.status)
        ? en.warehouseLocationWasInactivatedSuccessfully
        : en.warehouseLocationWasActivatedSuccessfully,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setRefresh(!refresh);
    }
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon path={mdiHomeCity} size={1} color={theme.palette.primary.main} />
    );
  };

  const openEditModal = (row) => {
    edit.reset();
    setSelectedRow(row);
    setShowModal(true);
  };

  const getRowActions = (row) => {
    return [
      {
        text: "Edit",
        onClick: () => openEditModal(row),
        renderIcon: () => <MiIcon path={mdiPencil} size={"20px"} />,
      },
      {
        text: isActive(row.status) ? en.makeInActive : en.makeActive,
        onClick: () => handleActivation(row),
        renderIcon: () => (
          <MiIcon
            path={
              isActive(row.status) ? mdiMinusCircleOffOutline : mdiProgressCheck
            }
            size={"20px"}
          />
        ),
      },
      {
        text: "Delete",
        onClick: () => openDeleteModal(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={"20px"} />,
      },
    ];
  };

  const handleOpenModal = () => {
    setSelectedRow({});
    edit.reset();
    setShowModal(true);
  };

  const openDeleteModal = (row) => {
    setIsDeleteModalOpen(true);
    setSelectedRow(row);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedRow(null);
  };

  const pageHeaderActionButton = () => {
    return (
      <MiButton
        title="Add Location"
        icon={mdiPlus}
        inverse
        onClick={handleOpenModal}
        color={theme.palette.primary.main}
      />
    );
  };

  const getHelperText = (field, message) => {
    const val = edit.getValue(field);
    if (!val && !isValid) {
      return {
        error: true,
        helperText: message,
      };
    }
    return {
      error: false,
      helperText: "",
    };
  };

  const getEmptyListProps = () => {
    return {
      icon: mdiMapMarker,
      text: en.emptyWarehouseLocationListText,
      helperText: en.emptyWarehouseLocationListHelperText,
      actions: [
        {
          title: "Add Location",
          icon: "add",
          inverse: true,
          onClick: handleOpenModal,
          color: theme.palette.primary.main,
        },
      ],
    };
  };

  return (
    <>
      <MiPageHeader
        title="Warehouse Locations"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        loading={stateManager.isBusy()}
        action={pageHeaderActionButton()}
      />
      <MiList
        data={rows}
        dataProvider={fetchData}
        setData={setRows}
        columns={columns}
        hideFilters
        disableColumnMenu
        refresh={refresh}
        getRowActions={getRowActions}
        emptyListProps={getEmptyListProps()}
      />
      <MiModal
        title={"Warehouse Location"}
        onClose={handleCloseModal}
        open={showModal}
        actions={actions}
      >
        <Grid container className={classes.formWidth}>
          <Grid item xs={6} style={{ paddingRight: 10 }}>
            <Grid item xs={12}>
              <MiInputTextbox
                {...getHelperText("name", en.notEnteredName)}
                labelText={en.name}
                value={edit.getValue("name")}
                handleChange={(e) => edit.update({ name: e.target.value })}
                minWidth={228}
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.formAlignment}>
              <MiInputTextbox
                {...getHelperText("city", en.notEnteredCity)}
                labelText={en.city}
                value={edit.getValue("city")}
                handleChange={(e) => edit.update({ city: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.formAlignment}>
              <MiInputSelectBox
                {...getHelperText("status", en.notSelectedStatus)}
                labelText={en.status}
                menuItems={Status}
                value={edit.getValue("status") || ""}
                handleChange={(e) => edit.update({ status: e.target.value })}
                disableNone={true}
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 10 }}>
            <Grid item xs={12}>
              <MiInputTextbox
                {...getHelperText("address", en.notEnteredAddress)}
                labelText={en.address}
                value={edit.getValue("address")}
                handleChange={(e) => edit.update({ address: e.target.value })}
                minWidth={228}
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.formAlignment}>
              <MiInputSelectBox
                labelText={en.state}
                menuItems={states}
                {...getHelperText("state", en.notSelectedState)}
                value={edit.getValue("state") || ""}
                handleChange={(e) => edit.update({ state: e.target.value })}
                disableNone={true}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </MiModal>
      <MiModalConfirm
        open={isDeleteModalOpen}
        icon={mdiDelete}
        title={"Delete Warehouse Location"}
        description={"Are you sure you want to delete this warehouse location?"}
        color={theme.palette.primary.red}
        handlePositive={handleDelete}
        handleNegative={closeDeleteModal}
      />
    </>
  );
};
