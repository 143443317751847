import React from "react";
import { useTheme } from "@mui/material";
import { MiLink } from "@miview/components";
import PropTypes from "prop-types";

const AccountNumber = ({ value, url, OnClick }) => {
  const theme = useTheme();
  return (
    <MiLink
      title={value}
      color={theme.palette.primary.blue}
      to={url}
      style={{
        margin: 0,
        fontWeight: theme.fontWeight.medium,
        fontSize: theme.fontSize.medium,
        padding: 0,
        textDecoration: "none",
      }}
      onClick={OnClick}
    />
  );
};

AccountNumber.propTypes = {
  value: PropTypes?.string,
  url: PropTypes?.string,
};

export default AccountNumber;
