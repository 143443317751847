import React, { useEffect, useState } from "react";
import { MiHeader } from "../../components/Standard/MiView";
import { searchService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { THEME } from "../../components/Standard/theme";
import { MiLink, MiTable } from "@miview/components";
import { useRouter } from "@miview/hooks";

const SearchResults = () => {
  const router = useRouter();
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    const { q } = router.query;
    if (q) {
      search(q);
    }
  }, [router.query]);

  const search = async (query) => {
    const response = await searchService.globalSearch(query);
    if (response.status === HTTP_STATUSES.OK) {
      setSearchResults(response.data);
    }
  };

  const homeColumns = [
    {
      field: "address",
      headerName: "Address",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (x) => <MiLink to={`/homes/${x.row.id}`} title={x.value} />,
    },
    {
      field: "city",
      headerName: "City",
      headerAlign: "left",
      flex: 1,
      align: "left",
    },
    {
      field: "state",
      headerName: "State",
      headerAlign: "left",
      flex: 1,
      align: "left",
    },
    {
      field: "community",
      headerName: "Community",
      headerAlign: "left",
      flex: 1,
      align: "left",
      renderCell: (x) => (
        <MiLink to={`/communities/${x.row.communityId}`} title={x.value} />
      ),
    },
    {
      field: "builder",
      headerName: "Builder",
      headerAlign: "left",
      flex: 1,
      align: "left",
    },
  ];

  const builderColumns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      flex: 1,
      align: "left",
      renderCell: (x) => <MiLink to={`/builder/${x.row.id}`} title={x.value} />,
    },
  ];

  const communityColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (x) => (
        <MiLink to={`/communities/${x.row.id}`} title={x.value} />
      ),
    },
    {
      field: "city",
      flex: 1,
      headerName: "City(s)",
      headerAlign: "left",
      align: "left",
    },
  ];

  const userColumns = [
    {
      field: "firstName",
      headerName: "Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (x) => (
        <MiLink
          to={`/users/${x.row.id}`}
          title={x.row.firstName + " " + x.row.lastName}
        />
      ),
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "left",
      flex: 1,
      align: "left",
    },
  ];

  return (
    <div>
      {searchResults?.homes?.length > 0 && (
        <div>
          <MiHeader icon={"home"} title="Homes" color={THEME.BLUE_PRIMARY} />
          <div style={styles.searchResultWrapper}>
            <MiTable
              columns={homeColumns}
              rows={searchResults.homes}
              pageSize={searchResults.homes.length}
              disableColumnMenu
            />
          </div>
        </div>
      )}
      {searchResults?.builders?.length > 0 && (
        <div>
          <MiHeader
            icon={"fa-clipboard"}
            title="Builders"
            color={THEME.BLUE_PRIMARY}
          />
          <div style={styles.searchResultWrapper}>
            <MiTable
              columns={builderColumns}
              rows={searchResults.builders}
              pageSize={searchResults.builders.length}
              disableColumnMenu
            />
          </div>
        </div>
      )}
      {searchResults?.communities?.length > 0 && (
        <div>
          <MiHeader
            icon={"fa-users"}
            title="Communities"
            color={THEME.BLUE_PRIMARY}
          />
          <div style={styles.searchResultWrapper}>
            <MiTable
              columns={communityColumns}
              rows={searchResults.communities}
              pageSize={searchResults.communities.length}
              disableColumnMenu
            />
          </div>
        </div>
      )}
      {searchResults?.users?.length > 0 && (
        <div>
          <MiHeader icon={"fa-user"} title="Users" color={THEME.BLUE_PRIMARY} />
          <div style={styles.searchResultWrapper}>
            <MiTable
              columns={userColumns}
              rows={searchResults.users}
              pageSize={searchResults.users.length}
              disableColumnMenu
            />
          </div>
        </div>
      )}

      {searchResults &&
        Object.keys(searchResults)?.filter(
          (key) => searchResults[key].length > 0
        ).length === 0 && (
          <div
            style={{ padding: "10px", marginTop: "10px", ...THEME.BOX_MAIN }}
          >
            <p
              style={{
                ...THEME.TEXT_HEADER_LARGE,
                color: THEME.GREY_DARK,
                textAlign: "center",
              }}
            >
              No results found.
            </p>
          </div>
        )}
    </div>
  );
};

const styles = {
  searchResultWrapper: {
    borderColor: THEME.GREY_LIGHT,
    borderRadius: "0.25rem",
    borderWidth: 1,
    backgroundColor: "white",
    borderStyle: "solid",
    padding: "1rem",
  },
};

export default SearchResults;
