export const API_ROOT_URLS = [
  { label: "Prod", value: "https://api.miviewis.com" },
  {
    label: "Staging",
    value: "https://miv-prod-api-staging.azurewebsites.net",
  },
  { label: "QA", value: "https://qa-api.miviewis.com" },
  { label: "DEV", value: "https://dev-api.miviewis.com" },
  {
    label: "Beta",
    value: "https://beta-demo-api.miviewis.com",
  },
  {
    label: "Demo",
    value: "https://demo-api.miviewis.com",
  },
  { label: "Local", value: "http://127.0.0.1" },
  { label: "MM", value: "http://192.168.0.254" },
  { label: "Andy", value: "http://192.168.1.100" },
  { label: "Serhii", value: "http://192.168.2.202" },
  { label: "Josh", value: "http://192.168.2.200" },
  { label: "Israel", value: "http://192.168.0.9" },
  { label: "Jimmy", value: "http://192.168.0.214" },
  { label: "Humberto", value: "http://192.168.1.85" },
  { label: "BrowserStackLocal", value: "http://bs-local.com" },
];
