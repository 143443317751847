import React, { useState, Fragment } from "react";
import { Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  ImageViewer,
  MiCard,
  MiIcon,
  MiSectionHeader,
} from "@miview/components";
import {
  TRIM,
  PUNCH,
  INCOMPLETE,
  STAGEWALKITEMS_ICONS,
  COMPLETE,
} from "@miview/constants";
import { getImageUrl, convertMToFt } from "@miview/utils";
import { useCdn } from "@miview/hooks";
import { THEME } from "@miview/theme";
import * as Icons from "@mdi/js";

const useWalkItemStyles = makeStyles()({
  container: {
    border: `1px solid ${THEME.GREY_LIGHT}`,
    borderRadius: 6,
    margin: 4,
    padding: 10,
  },
  gridContainer: {
    minHeight: 80,
  },
  statusContainer: {},
  iconContainer: {
    borderRadius: "50%",
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: ".3rem",
  },
  detailsGridItem: {
    flex: 1,
  },
  areaNotReady: {
    flex: 1,
    color: THEME.ORANGE_PRIMARY,
  },
  statusIcon: {
    height: 30,
    width: 30,
  },
  itemFailReason: {
    color: THEME.RED_PRIMARY,
    fontSize: 10,
  },

  itemName: {
    color: THEME.BLUE_SECONDARY,
    fontSize: 14,
  },
  itemDescription: {
    fontSize: 10,
  },
  itemMeasurements: {
    fontSize: 11,
    display: "flex",
    flexDirection: "row",
  },
  measurementTypeContainer: {
    width: 100,
  },
  measurementActual: {
    height: 20,
    borderRadius: 2,
    margin: "4px 6px 4px 0",
  },
});

const useImageThumbnailStyles = makeStyles()((theme) => ({
  imageContainer: {
    height: 40,
    width: 40,
    cursor: "pointer",
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%",
    border: "1px solid black",
    borderRadius: 6,
  },
  badgeStyles: {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    fontSize: 12,
    right: -5,
    top: -5,
    display: "flex",
    color: theme.palette.primary.white,
    width: "18px",
    height: "18px",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const renderMeasurements = (classes, item) => {
  if (!item.stackSize) {
    return null;
  }
  return (
    <div className={classes.itemMeasurements}>
      <div
        className={classes.measurementTypeContainer}
        style={{ color: item.measurementBackColor }}
      >
        <div
          className={classes.measurementActual}
          style={{ border: `1px solid ${item.measurementBackColor}` }}
        >
          {convertMToFt(item.measurementBackActual) || ""}
        </div>
        <div className={classes.measurementPre}>
          back: {convertMToFt(item.measurementBack) || `0"`}
        </div>
      </div>
      <div
        className={classes.measurementTypeContainer}
        style={{ color: item.measurementSideColor }}
      >
        <div
          className={classes.measurementActual}
          style={{ border: `1px solid ${item.measurementSideColor}` }}
        >
          {convertMToFt(item.measurementSideActual) || ""}
        </div>
        <div className={classes.measurementPre}>
          side: {convertMToFt(item.measurementSide) || `0"`}
        </div>
      </div>
    </div>
  );
};

const ImageThumbnail = ({ image, setSelectedImage, setImages, imageCount }) => {
  const { classes } = useImageThumbnailStyles();
  const cdn = useCdn();

  return (
    <Grid item key={image}>
      <div
        className={classes.imageContainer}
        onClick={() => {
          if (setImages) {
            setImages([image]);
          }
          setSelectedImage(image);
        }}
      >
        {imageCount > 1 && (
          <div className={classes.badgeStyles}>{imageCount}</div>
        )}
        <img className={classes.image} src={getImageUrl(image, cdn)} />
      </div>
    </Grid>
  );
};

const WalkItem = ({ item, setSelectedImage, setImages }) => {
  const { classes } = useWalkItemStyles();
  const cadImages = [
    item?.frontImageName,
    item?.topImageName,
    item?.material.imageName,
  ];
  const itemImage = item?.images?.map((x) => x.imageName);
  const combinedImages = [...itemImage, ...cadImages].filter((e) => e);

  const renderImage = () => {
    if (!combinedImages?.length) {
      return null;
    }
    return (
      <ImageThumbnail
        imageCount={combinedImages.length}
        image={combinedImages[0]}
        setSelectedImage={() => {
          setSelectedImage(combinedImages[0]);
          setImages(combinedImages);
        }}
      />
    );
  };

  const renderIcon = (type) => {
    const inverse = item.isAnswered || item.status === COMPLETE;
    if (type === PUNCH && inverse) {
      return <div style={{ width: 32, marginRight: ".3rem" }}></div>;
    }
    const IconHelper =
      type === PUNCH
        ? STAGEWALKITEMS_ICONS[INCOMPLETE]
        : STAGEWALKITEMS_ICONS[item.status];
    const IconName =
      item.stackSize && type !== PUNCH
        ? "mdiRadioboxMarked"
        : IconHelper.iconWeb;
    return (
      <div
        style={{
          backgroundColor: inverse ? IconHelper.color : "white",
          border: `2px solid ${IconHelper.color}`,
        }}
        className={classes.iconContainer}
      >
        <MiIcon
          path={Icons[IconName]}
          size={0.7}
          color={inverse ? "white" : IconHelper.color}
        />
      </div>
    );
  };

  const renderRightArea = () => {
    return (
      <div style={{ display: "flex", ...THEME.ROW }}>
        <div style={{ paddingRight: ".5rem" }}>{renderImage()}</div>
      </div>
    );
  };

  const theme = useTheme();

  return (
    <Grid key={item.id} item xs={12} style={{ padding: 5 }}>
      <MiCard
        title={item.stackSize ? `${item.name} (${item.stackSize}")` : item.name}
        titleStyle={{
          fontWeight: 500,
          color: theme.palette.primary.main,
        }}
        renderIcon={renderIcon}
        fieldset={[
          { label: "Details:", value: item.description },
          {
            label: "Fail Reason:",
            value: item.failReason,
            color: THEME.RED_PRIMARY,
          },
          {
            label: "Area: Not Ready",
            value: item.abortWalk,
            color: THEME.ORANGE_PRIMARY,
          },
        ]}
        rightArea={renderRightArea()}
        footer={renderMeasurements(classes, item)}
      ></MiCard>
    </Grid>
  );
};

// eslint-disable-next-line react/display-name
const WalkItemsList = ({
  checklistItems,
  stage,
  setSelectedImage,
  setImages,
  setSelectedItem,
  setShowAddIssue,
  recordTypeId,
}) => {
  const renderAreaImage = (area) => {
    if (!area.topImageName || stage === TRIM) {
      return null;
    }
    return (
      <Grid item>
        <ImageThumbnail
          image={area.topImageName}
          setSelectedImage={setSelectedImage}
          setImages={setImages}
        />
      </Grid>
    );
  };

  return (
    <Grid container>
      {checklistItems.map((area) => {
        return (
          <Grid
            item
            key={area.propertyPlanAreaId}
            xs={12}
            style={{ paddingTop: 12 }}
          >
            <MiSectionHeader
              title={area.name}
              rightArea={renderAreaImage(area)}
            />
            {area.items.map((item) => {
              return (
                <WalkItem
                  key={item.itemId}
                  item={item}
                  setSelectedItem={setSelectedItem}
                  setShowAddIssue={setShowAddIssue}
                  setSelectedImage={setSelectedImage}
                  setImages={setImages}
                  recordTypeId={recordTypeId}
                />
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const WalkItems = (props) => {
  const {
    checklistItems,
    stageTypeName,
    setSelectedItem,
    setShowAddIssue,
    recordTypeId,
  } = props;
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");

  return (
    <Fragment>
      <WalkItemsList
        checklistItems={checklistItems}
        stage={stageTypeName}
        setSelectedImage={setSelectedImage}
        setImages={setImages}
        setSelectedItem={setSelectedItem}
        setShowAddIssue={setShowAddIssue}
        recordTypeId={recordTypeId}
      />
      <ImageViewer
        images={images}
        selectedImage={selectedImage}
        onUnfullscreen={() => setSelectedImage([])}
        fullScreenStatus={selectedImage.length > 0}
      />
    </Fragment>
  );
};
