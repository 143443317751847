import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const API_WALK_TYPES = `${API_V3}/walktypes`;

export const walkTypeService = {
  getWalkType: async (id) => {
    const options = await apiOptions({
      url: `${API_WALK_TYPES}/${id}`,
    });
    return apiRequest(options);
  },

  getAll: async ({ params, filters, sort, signal }) => {
    const options = await apiOptions({
      url: `${API_WALK_TYPES}`,
      params,
      filters,
      sort,
      signal,
    });
    return apiRequest(options);
  },

  getWalkTypes: async (params, filters, sort) => {
    return walkTypeService.getAll({ params, filters, sort });
  },

  getFailureReasonsByWalkType: async (walkTypeId) => {
    const options = await apiOptions({
      url: `${API_WALK_TYPES}/${walkTypeId}/walkFailureTypes`,
    });
    return apiRequest(options);
  },

  saveFailureReasonsByWalkType: async (walkTypeId, failureTypeId, obj) => {
    const options = await apiOptions({
      method: "put",
      url: `${API_WALK_TYPES}/${walkTypeId}/walkFailureTypes/${failureTypeId}`,
      data: obj,
    });
    const toastMessageConfig = {
      success: {
        message: "Failure reasons saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  deleteWalkTypes: async (walkTypeId) => {
    const options = await apiOptions({
      method: "delete",
      url: `${API_WALK_TYPES}/${walkTypeId}`,
    });
    return apiRequest(options, "Walk type deleted successfully");
  },

  deleteWalkTypeFailure: async (walkTypeId, failureTypeId) => {
    const options = await apiOptions({
      method: "delete",
      url: `${API_WALK_TYPES}/${walkTypeId}/walkFailureTypes/${failureTypeId}`,
    });
    return apiRequest(options, "Walk type failure deleted successfully");
  },

  create: async (request) => {
    const options = await apiOptions({
      method: "post",
      url: `${API_WALK_TYPES}`,
      data: request,
    });
    return apiRequest(options);
  },

  replace: async (id, request) => {
    const options = await apiOptions({
      method: "put",
      url: `${API_WALK_TYPES}/${id}`,
      data: request,
    });
    return apiRequest(options);
  },

  update: async (id, request, customMsg) => {
    const options = await apiOptions({
      method: "patch",
      url: `${API_WALK_TYPES}/${id}`,
      data: request,
    });

    const toastMessageConfig = {
      success: {
        message: customMsg || "Walk type saved successfully",
      },
    };

    return apiRequest(options, toastMessageConfig);
  },

  updateTargetTypes: async (id, request) => {
    const options = await apiOptions({
      method: "put",
      url: `${API_WALK_TYPES}/${id}/targettypes`,
      data: request,
    });
    return apiRequest(options);
  },

  updateTandemTypes: async (id, request) => {
    const options = await apiOptions({
      method: "put",
      url: `${API_WALK_TYPES}/${id}/tandemtypes`,
      data: request,
    });
    return apiRequest(options);
  },

  updateAssignableTitles: async (id, request) => {
    const options = await apiOptions({
      method: "put",
      url: `${API_WALK_TYPES}/${id}/assignableTitles`,
      data: request,
    });
    return apiRequest(options);
  },

  createPredecessor: async (id, request) => {
    const options = await apiOptions({
      method: "post",
      url: `${API_WALK_TYPES}/${id}/predecessors`,
      data: request,
    });
    return apiRequest(options);
  },

  deletePredecessor: async (id, predecessorId) => {
    const options = await apiOptions({
      method: "delete",
      url: `${API_WALK_TYPES}/${id}/predecessors/${predecessorId}`,
    });
    return apiRequest(options);
  },

  getPayCalculation: async (id, signal) => {
    const options = await apiOptions({
      url: `${API_WALK_TYPES}/${id}/payCalculation`,
      signal,
    });
    return apiRequest(options);
  },

  updatePayCalculation: async (id, request, signal) => {
    const options = await apiOptions({
      method: "put",
      url: `${API_WALK_TYPES}/${id}/payCalculation`,
      data: request,
      signal,
    });
    return apiRequest(options);
  },
};
