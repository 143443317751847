import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_PAYSCHEDULES = `${API_V3}/schedules`;

export const paySchedulesService = {
  getAll: async () => {
    const options = await apiOptions({
      url: API_PAYSCHEDULES,
      method: "get",
    });
    return await apiRequest(options);
  },
  create: async (request) => {
    const options = await apiOptions({
      url: API_PAYSCHEDULES,
      method: "post",
      data: request,
    });
    return await apiRequest(options);
  },
  update: async ({ id, request }) => {
    const options = await apiOptions({
      url: `${API_PAYSCHEDULES}/${id}`,
      method: "patch",
      data: request,
    });
    return await apiRequest(options);
  },
  delete: async ({ id }) => {
    const options = await apiOptions({
      url: `${API_PAYSCHEDULES}/${id}`,
      method: "delete",
    });
    return await apiRequest(options);
  },
};
