import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

const Container = styled(FormControl)(() => ({
  width: "100%",
}));

const LabelStyle = styled(FormLabel)(
  ({ theme, labelColor, labelColorFocused }) => ({
    color: labelColor || theme.palette.primary.blue,
    "&.MuiFormLabel-root.Mui-focused": {
      color: labelColorFocused || theme.palette.primary.blue,
    },
  })
);

const RadioButton = styled(FormControlLabel)(({ checked }) => ({
  ".MuiFormControlLabel-label": {
    fontSize: "0.875rem",
  },
  backgroundColor: checked ? "#EAF6FC" : "",
  gap: ".2em",
}));

export const MiRadioGroup = ({
  data,
  orientation,
  disabled,
  radioGroupLabel,
  radioGroupName,
  labelStyle,
  formControlLabelStyle,
  value,
  handleChange,
}) => {
  return (
    <Container component="fieldset" disabled={disabled}>
      <LabelStyle component="legend" style={labelStyle}>
        {radioGroupLabel}
      </LabelStyle>
      <RadioGroup
        row={orientation === "row"}
        name={radioGroupName}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      >
        {data.map((obj) => {
          return (
            <RadioButton
              key={obj.value}
              value={obj.value}
              control={<Radio />}
              label={obj.label}
              disabled={obj.disabled}
              style={formControlLabelStyle}
            />
          );
        })}
      </RadioGroup>
    </Container>
  );
};

MiRadioGroup.defaultProps = {
  data: PropTypes.array,
  radioColor: PropTypes.string,
  labelColor: PropTypes.string,
  labelColorFocused: PropTypes.string,
  orientation: PropTypes.string,
  disabled: PropTypes.bool,
  radioGroupLabel: PropTypes.string,
  radioGroupName: PropTypes.string,
  labelStyle: PropTypes.object,
  formControlLabelStyle: PropTypes.object,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

MiRadioGroup.defaultProps = {
  data: [],
  radioGroupName: "Radio_Group",
};
