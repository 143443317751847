import React from "react";
import { makeStyles } from 'tss-react/mui';

const planItemImageFocusStyles = {
  planItemImageFocus: {
    border: "none",
    backgroundColor: "white",
    position: "relative",
    height: "88px",
    padding: "0 6px 6px",
    "&:focus": {
      zIndex: 10,
      boxShadow: "0 0 0 5px rgba(21, 156, 228, 0.4)",
    },
  },
};
const usePlanItemImageFocusStyles = makeStyles()(planItemImageFocusStyles);

export const PlanItemImageFocus = ({ forwardedRef, children, ...rest }) => {
  const { classes } = usePlanItemImageFocusStyles();
  return (
    <div className={classes.planItemImageFocus} ref={forwardedRef} {...rest}>
      {children}
    </div>
  );
};
