/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { MiBox } from "@miview/components";
import { TemplateHeader } from "./Components/RequisitionComponents";
import DrawerItems from "./DrawerItems";
import { itemService, RequisitionService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const RequisitionTemplateDetails = () => {
  const [masterItems, setMasterItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [templateData, setTemplateData] = useState({});
  const [templateItems, setTemplateItems] = useState([]);

  const handleSearchListShow = useCallback(async (e) => {
    setSearchText(e.target.value);
    const resp = await itemService.itemMaster.getAll(
      {
        search: e.target.value,
        pageSize: 10000,
      },
      {}
    );
    if (resp?.status < HTTP_STATUSES.BAD_REQUEST) {
      setMasterItems(resp.data);
    }
  }, []);

  const removeTemplateItems = async (ID) => {
    const resp = await RequisitionService.requisitions.deleteItem(
      templateData?.id,
      ID,
      "Item Removed Successfully",
      "Error Occurred While Removing"
    );
    if (resp?.status < 400) {
      setTemplateItems(templateItems);
    }
  };

  const addItem = (data) => {
    setTemplateItems((prevState) =>
      [...prevState, data].filter(
        (v, i, a) =>
          a.findIndex((v2) => ["id"].every((k) => v2[k] === v[k])) === i
      )
    );
  };

  const addTemplateItems = async (selectedRow) => {
    if (selectedRow && Object.keys(selectedRow)?.length > 0) {
      selectedRow["itemName"] = selectedRow?.number;
      selectedRow["itemDescription"] = selectedRow?.description;
      selectedRow["categoryName"] = selectedRow?.collectionName;
      selectedRow["quantity"] = 0;
      const data = {
        categoryId: selectedRow?.collectionId,
        categoryName: selectedRow?.categoryName,
        displaySequence: 2,
        id: selectedRow?.id,
        itemDescription: selectedRow?.itemDescription,
        itemId: selectedRow?.id,
        itemName: selectedRow?.itemName,
        notes: "Item 2 Notes Order 2 Tenant 1",
        quantity: 0,
        requisitionOrderId: "",
        status: "Active",
      };
      addItem(data);
    }
  };

  const closeAll = () => {
    setMasterItems([]);
    setTemplateItems([]);
    setTemplateData({});
  };

  return (
    <MiBox>
      <div style={{ minHeight: "calc(100vh - 115px)" }}>
        <TemplateHeader
          closeDrawer={closeAll}
          title="New Home Requisition Template"
        />
        <DrawerItems
          handleSearchListShow={handleSearchListShow}
          searchText={searchText}
          setSearchText={setSearchText}
          masterItems={masterItems}
          addTemplateItems={addTemplateItems}
          setMasterItems={setMasterItems}
          removeTemplateItems={removeTemplateItems}
          CloseAll={closeAll}
        />
      </div>
    </MiBox>
  );
};
