import React, { useState } from "react";
import { payPeriodService } from "@miview/api";
import { createToast } from "@miview/toast";
import { TOAST_TYPES, HTTP_STATUSES, PAGINATION } from "@miview/constants";
import { useRouter } from "@miview/hooks";
import moment from "moment";
import {
  MiDatePickerInput,
  MiModal,
  MiList,
  MiPageHeader,
  MiButton,
  MiIcon,
} from "@miview/components";
import { useTheme } from "@mui/material/styles";
import { mdiArrowLeft, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";

const DATE_FORMAT = "MM/DD/YYYY";

export const PayPeriods = () => {
  const [payPeriods, setPayPeriods] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [payDate, setPayDate] = useState(null);
  const [editSelection, setEditSelection] = useState({});
  const [isPayPeriodCreateModalOpen, setIsPayPeriodCreateModalOpen] =
    useState(false);
  const [isPayPeriodEditModalOpen, setIsPayPeriodEditModalOpen] =
    useState(false);
  const [isPayPeriodDeleteModalOpen, setIsPayPeriodDeleteModalOpen] =
    useState(false);
  const [refresh, setRefresh] = useState(false);

  const theme = useTheme();
  const router = useRouter();
  const { payScheduleId } = router.params;

  const checkVaildDates = () => {
    if (moment(startDate) > moment(endDate)) {
      createToast(
        "Start Date Cannot be greater than End Date",
        TOAST_TYPES.ERROR
      );
      return false;
    }
    if (moment(startDate) > moment(payDate)) {
      createToast(
        "Start Date Cannot be greater than Pay Date",
        TOAST_TYPES.ERROR
      );
      return false;
    }
    return true;
  };

  const addPayPeriod = async () => {
    if (!checkVaildDates()) {
      return;
    }
    const response = await payPeriodService.create(
      {
        startDate: moment(startDate).utc().startOf("day").format(),
        endDate: moment(endDate).utc().endOf("day").format(),
        payDate: payDate,
      },
      payScheduleId
    );
    if (response.status === HTTP_STATUSES.OK) {
      createToast("Pay Period Created Successfully", TOAST_TYPES.SUCCESS);
      setRefresh(!refresh);
      setIsPayPeriodCreateModalOpen(false);
    }
  };

  const updatePayPeriod = async () => {
    if (!checkVaildDates()) {
      return;
    }
    const response = await payPeriodService.update(
      editSelection.id,
      {
        startDate: moment(startDate).utc().startOf("day").format(),
        endDate: moment(endDate).utc().endOf("day").format(),
        payDate: payDate,
      },
      payScheduleId
    );
    if (response.status === HTTP_STATUSES.OK) {
      createToast("Pay Period Edited Successfully", TOAST_TYPES.SUCCESS);
      setRefresh(!refresh);
      setIsPayPeriodEditModalOpen(false);
    }
  };

  const deletePayPeriod = async () => {
    const response = await payPeriodService.delete(
      editSelection.id,
      payScheduleId
    );
    if (response.status === HTTP_STATUSES.OK) {
      createToast("Pay Period Deleted Successfully", TOAST_TYPES.SUCCESS);
      setRefresh(!refresh);
      setIsPayPeriodDeleteModalOpen(false);
    }
  };

  const closePayPeriodCreateModal = () => {
    setIsPayPeriodCreateModalOpen(false);
    setStartDate(null);
    setEndDate(null);
    setPayDate(null);
  };

  const openPayPeriodEditModal = (obj) => {
    setEditSelection(obj);
    setStartDate(obj.startDate);
    setEndDate(obj.endDate);
    setPayDate(obj.payDate);
    setIsPayPeriodEditModalOpen(true);
  };

  const closePayPeriodEditModal = () => {
    setIsPayPeriodEditModalOpen(false);
    setStartDate(null);
    setEndDate(null);
    setPayDate(null);
    setEditSelection({});
  };

  const openPayPeriodDeleteModal = (obj) => {
    setEditSelection(obj);
    setIsPayPeriodDeleteModalOpen(true);
  };

  const closePayPeriodDeleteModal = () => {
    setEditSelection({});
    setIsPayPeriodDeleteModalOpen(false);
  };

  const handleAddPayPeriodClick = async () => {
    const response = await payPeriodService.getNextPayPeriod(payScheduleId);
    if (response.status === HTTP_STATUSES.OK) {
      setStartDate(
        moment(response.data.startDate)
          .add(1, "day")
          .utc()
          .startOf("day")
          .format()
      );
      setEndDate(response.data.endDate);
      setPayDate(response.data.payDate);
    }
    setIsPayPeriodCreateModalOpen(true);
  };

  const columns = [
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      renderCell: (p) => moment(p.value).utc().format(DATE_FORMAT),
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      renderCell: (p) => moment(p.value).utc().format(DATE_FORMAT),
    },
    {
      field: "payDate",
      headerName: "Pay Date",
      flex: 1,
      renderCell: (p) => moment(p.value).utc().format(DATE_FORMAT),
    },
  ];

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => openPayPeriodDeleteModal(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
      {
        text: "EDIT",
        onClick: () => openPayPeriodEditModal(row),
        renderIcon: () => <MiIcon path={mdiPencil} size={1} />,
      },
    ];
  };

  const validCheck = (val) => val === "Invalid date";

  const handleStartDateChange = (val) => {
    if (validCheck(val)) {
      setStartDate(null);
      return;
    }
    setStartDate(val);
  };

  const handleEndDateChange = (val) => {
    if (validCheck(val)) {
      setEndDate(null);
      return;
    }
    setEndDate(val);
  };

  const handlePayDateChange = (val) => {
    if (validCheck(val)) {
      setPayDate(null);
      return;
    }
    setPayDate(val);
  };

  const getSelectedDate = (date) => {
    if (!date) {
      return "";
    }
    return moment(date).utc().format(DATE_FORMAT);
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiArrowLeft}
        size={1}
        color={theme.palette.primary.main}
        style={{ cursor: "pointer" }}
        onClick={router.goBack}
      />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Pay Periods"
        inverse={true}
        icon={mdiPlus}
        onClick={handleAddPayPeriodClick}
        color={theme.palette.primary.main}
      />
    );
  };

  const getPayPeriods = async () => {
    return await payPeriodService.getAll({ payScheduleId });
  };

  return (
    <div>
      <MiPageHeader
        title="Pay Periods"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
      />
      <MiModal
        open={isPayPeriodCreateModalOpen}
        title="Add A New Pay Period"
        onClose={closePayPeriodCreateModal}
        maxWidth="md"
        actions={[
          {
            onClick: closePayPeriodCreateModal,
            color: theme.palette.primary.grey,
            name: "Cancel",
          },
          {
            onClick: addPayPeriod,
            color: theme.palette.primary.main,
            name: "Save",
            inverse: true,
            disabled: !startDate || !endDate || !payDate,
          },
        ]}
      >
        <div style={{ margin: 12 }}>
          <MiDatePickerInput
            type="date"
            value={getSelectedDate(startDate)}
            handleChange={handleStartDateChange}
            labelText="Select Start Date"
            labelTextColor={theme.palette.primary.grey}
            inputFormat={DATE_FORMAT}
            dateFormat={DATE_FORMAT}
            allowSameDateSelection
            required
          />
        </div>
        <div style={{ margin: 12 }}>
          <MiDatePickerInput
            type="date"
            value={getSelectedDate(endDate)}
            handleChange={handleEndDateChange}
            labelText="Select End Date"
            labelTextColor={theme.palette.primary.grey}
            inputFormat={DATE_FORMAT}
            dateFormat={DATE_FORMAT}
            allowSameDateSelection
            required
          />
        </div>
        <div style={{ margin: 12 }}>
          <MiDatePickerInput
            type="date"
            value={getSelectedDate(payDate)}
            handleChange={handlePayDateChange}
            labelText="Select Pay Date"
            labelTextColor={theme.palette.primary.grey}
            inputFormat={DATE_FORMAT}
            dateFormat={DATE_FORMAT}
            allowSameDateSelection
            required
          />
        </div>
      </MiModal>
      <MiModal
        open={isPayPeriodEditModalOpen}
        title="Edit Pay Period"
        onClose={closePayPeriodEditModal}
        modalTitleStyle={{
          textAlign: "center",
          color: theme.palette.primary.main,
          fontWeight: 700,
          fontSize: theme.fontSize.xxlarge,
        }}
        actions={[
          {
            onClick: closePayPeriodEditModal,
            color: theme.palette.primary.grey,
            name: "Cancel",
          },
          {
            onClick: updatePayPeriod,
            color: theme.palette.primary.main,
            name: "Save",
            inverse: true,
            disabled: !startDate || !endDate || !payDate,
          },
        ]}
      >
        <div style={{ margin: 16 }}>
          <MiDatePickerInput
            type="date"
            value={getSelectedDate(startDate)}
            handleChange={handleStartDateChange}
            labelText="Select Start Date"
            labelTextColor={theme.palette.primary.grey}
            inputFormat={DATE_FORMAT}
            dateFormat={DATE_FORMAT}
            allowSameDateSelection
            required
          />
        </div>
        <div style={{ margin: 16 }}>
          <MiDatePickerInput
            type="date"
            value={getSelectedDate(endDate)}
            handleChange={handleEndDateChange}
            labelText="Select End Date"
            labelTextColor={theme.palette.primary.grey}
            inputFormat={DATE_FORMAT}
            dateFormat={DATE_FORMAT}
            allowSameDateSelection
            required
          />
        </div>
        <div style={{ margin: 16 }}>
          <MiDatePickerInput
            type="date"
            value={getSelectedDate(payDate)}
            handleChange={handlePayDateChange}
            labelText="Select Pay Date"
            labelTextColor={theme.palette.primary.grey}
            inputFormat={DATE_FORMAT}
            dateFormat={DATE_FORMAT}
            allowSameDateSelection
            required
          />
        </div>
      </MiModal>
      <MiModal
        open={isPayPeriodDeleteModalOpen}
        titleColor={theme.palette.primary.red}
        title="Delete Pay Period"
        onClose={closePayPeriodDeleteModal}
        actions={[
          {
            onClick: closePayPeriodDeleteModal,
            color: theme.palette.primary.grey,
            name: "Cancel",
          },
          {
            onClick: deletePayPeriod,
            color: theme.palette.primary.red,
            name: "Confirm",
            inverse: true,
          },
        ]}
      >
        <div style={{ margin: 8 }}>
          <p>Delete the following Pay Period:</p>
          <p>{`Start Date: ${getSelectedDate(editSelection.startDate)}`}</p>
          <p>{`End Date: ${getSelectedDate(editSelection.endDate)}`}</p>
          <p>{`Pay Date: ${getSelectedDate(editSelection.payDate)}`}</p>
        </div>
      </MiModal>
      <MiList
        columns={columns}
        loadingMessage="No Pay Period Found! Please Create a new Pay Period."
        paginationMode={PAGINATION.CLIENT}
        getRowActions={getRowActions}
        data={payPeriods}
        setData={setPayPeriods}
        dataProvider={getPayPeriods}
        refresh={refresh}
      />
    </div>
  );
};
