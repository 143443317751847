import { apiOptions, apiRequest } from "../../utils";
import { API_ACCOUNT_SERVICE, ADDITIONAL_HEADERS } from "../../constants";

export const contacts = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_ACCOUNT_SERVICE}/contacts`,
      headers: ADDITIONAL_HEADERS,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_ACCOUNT_SERVICE}/contacts/${id}`,
      headers: ADDITIONAL_HEADERS,
      method: "get",
    });
    return apiRequest(options);
  },

  update: async ({ id, data, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${API_ACCOUNT_SERVICE}/contacts/${id}`,
      headers: ADDITIONAL_HEADERS,
      method: "patch",
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  replace: async ({ id, data, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${API_ACCOUNT_SERVICE}/contacts/${id}`,
      headers: ADDITIONAL_HEADERS,
      method: "put",
      data: data,
    });
    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  delete: async ({ id, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${API_ACCOUNT_SERVICE}/contacts/${id}`,
      headers: ADDITIONAL_HEADERS,
      method: "delete",
    });

    const toastMessageConfig = {
      success: {
        message: successMessage,
      },
      failure: {
        message: failureMessage,
      },
    };

    return apiRequest(options, toastMessageConfig);
  },
};
