import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  MiCard,
  MiActionMenu,
  MiIcon,
  MiSectionHeader,
  MiButton,
} from "@miview/components";
import { propertyService, contactMapService } from "@miview/api";
import { mdiAccount, mdiDelete, mdiPencil, mdiHome, mdiDomain } from "@mdi/js";
import { MoreVert } from "@mui/icons-material";
import { HTTP_STATUSES } from "@miview/constants";
import { useTheme } from "@mui/styles";
import EditContactModal from "./EditContactModal";
import DeleteContactModal from "./DeleteContactModal";
import AssigneesTransferModal from "./AssigneesTransferModal";

export const AssigneesCollapse = (props) => {
  const { propertyId, isButtonOpen } = props;
  const [propertyPeople, setPropertyPeople] = useState([]);
  const [peopleLoaded, setPeopleLoaded] = useState(false);
  const [assigneesTransferModal, setAssigneesTransferModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const open = Boolean(anchorEl);
  const theme = useTheme();

  useEffect(() => {
    getContacts();
  }, [propertyId]);

  const getContacts = async () => {
    const response = await propertyService.getContacts(propertyId);
    if (response.status === HTTP_STATUSES.OK) {
      setPropertyPeople(response?.data);
      setPeopleLoaded(true);
    }
  };

  const handleDeletePropertyContact = async () => {
    const response = await contactMapService.delete({
      contactMapId: selectedContact.id,
    });
    if (response.status === HTTP_STATUSES.OK) {
      getContacts();
      closeDeleteContactModal();
    }
  };

  const handleUpdatePropertyContact = async (edits) => {
    const response = await contactMapService.update({
      contactMapId: selectedContact.id,
      data: edits,
    });
    if (response.status === HTTP_STATUSES.OK) {
      closeEditContactModal();
      getContacts();
    }
  };

  useEffect(() => {
    if (showEditContactModal || showDeleteContactModal) {
      handleClose();
    }
  }, [showEditContactModal, showDeleteContactModal]);

  const openDeleteContactModal = () => {
    setShowDeleteContactModal(true);
  };

  const closeDeleteContactModal = () => {
    setSelectedContact({});
    setShowDeleteContactModal(false);
  };

  const openEditContactModal = () => {
    setShowEditContactModal(true);
  };

  const closeEditContactModal = () => {
    setSelectedContact({});
    setShowEditContactModal(false);
  };

  if (!isButtonOpen) {
    return null;
  }

  if (!peopleLoaded) {
    return <div>Loading...</div>;
  }

  const handleActionsIconSelect = (e, row) => {
    if (row.id === selectedContact?.id) {
      setSelectedContact({});
      setAnchorEl(null);
    } else {
      setSelectedContact(row);
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCommunityRowActions = () => {
    return [
      {
        text: "NOTIFICATIONS",
        onClick: () => openEditContactModal(),
        renderIcon: () => <MiIcon path={mdiPencil} size={"20px"} />,
      },
    ];
  };

  const getHomeRowActions = () => {
    return [
      {
        text: "REMOVE",
        onClick: () => openDeleteContactModal(),
        renderIcon: () => <MiIcon path={mdiDelete} size={"20px"} />,
      },
      {
        text: "NOTIFICATIONS",
        onClick: () => openEditContactModal(),
        renderIcon: () => <MiIcon path={mdiPencil} size={"20px"} />,
      },
    ];
  };

  const renderActions = () => {
    const rowActions = selectedContact.isPropertyContact
      ? getHomeRowActions()
      : getCommunityRowActions();
    if (!rowActions.length) {
      return;
    }

    return (
      <MiActionMenu
        open={open}
        selectedActionRow={selectedContact}
        rowActions={rowActions}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    );
  };

  const renderContacts = (contacts) =>
    contacts.map((user, key) => {
      return (
        <div key={"user" + key} style={{ paddingBottom: 7 }}>
          <MiCard
            color={theme.palette.secondary.teal}
            icon={mdiAccount}
            title={`${user.fullName} - ${user.title}`}
            fieldset={[
              { icon: "email", value: user.email },
              { icon: "phone", value: user.cell ? user.cell : user.work },
            ]}
            rightArea={
              <div
                onClick={(e) => handleActionsIconSelect(e, user)}
                style={{ cursor: "pointer" }}
              >
                <MoreVert style={{ color: theme.palette.secondary.grey }} />
              </div>
            }
          />
          {user.id === selectedContact?.id && renderActions()}
        </div>
      );
    });

  const Home = propertyPeople.filter((person) => person.isPropertyContact);
  const Community = propertyPeople.filter(
    (person) => !person.isPropertyContact
  );
  return (
    <>
      <div>
        <EditContactModal
          showEditContactModal={showEditContactModal}
          selectedContact={selectedContact}
          closeEditContactModal={closeEditContactModal}
          handleUpdatePropertyContact={handleUpdatePropertyContact}
        />
        <DeleteContactModal
          showDeleteContactModal={showDeleteContactModal}
          selectedContact={selectedContact}
          handleDeletePropertyContact={handleDeletePropertyContact}
          closeDeleteContactModal={closeDeleteContactModal}
        />
      </div>
      <div>
        <MiSectionHeader icon={mdiDomain} title="Community" />
        {renderContacts(Community)}
        <MiSectionHeader
          icon={mdiHome}
          title="Home"
          button={
            <MiButton
              title="Manage Contacts"
              inverse={false}
              onClick={() => setAssigneesTransferModal(true)}
              color={theme.palette.primary.blue}
              style={{
                backgroundColor: "#fff",
                borderRadius: 20,
                fontSize: "12px",
              }}
              variant="outlined"
            />
          }
        />
        {renderContacts(Home)}
        <AssigneesTransferModal
          setModal={setAssigneesTransferModal}
          propertyId={propertyId}
          open={assigneesTransferModal}
          communityContacts={Community}
          homeContacts={Home}
          setData={setPropertyPeople}
        />
      </div>
    </>
  );
};

AssigneesCollapse.propTypes = {
  isButtonOpen: PropTypes.bool,
  propertyId: PropTypes.string,
};
