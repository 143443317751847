import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const propertyItemService = {
  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/propertyitems/${id}`,
      method: "patch",
      data: request,
    });

    return apiRequest(options);
  },

  replace: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/propertyitems/${id}`,
      method: "put",
      data: request,
    });

    return apiRequest(options);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/propertyitems/${id}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  revert: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/propertyitems/${id}/revert`,
      method: "post",
    });

    return apiRequest(options);
  },

  restore: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/propertyitems/${id}/restore`,
      method: "post",
    });

    return apiRequest(options);
  },
};
