import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const propertySectionService = {
  getAll: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/propertysections`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/propertysections/${id}`,
      method: "get",
    });

    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/propertysections`,
      method: "post",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving area",
      },
      success: {
        message: "Area saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  replace: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/propertysections/${id}`,
      method: "put",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving area",
      },
      success: {
        message: "Area saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/propertysections/${id}`,
      method: "patch",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving area",
      },
      success: {
        message: "Area saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  delete: async ({ id }) => {
    const options = await apiOptions({
      url: `${API_V3}/propertysections/${id}`,
      method: "delete",
    });

    const toastMessageConfig = {
      success: {
        message: "Area deleted successfully",
      },
    };

    return apiRequest(options, toastMessageConfig);
  },
};
