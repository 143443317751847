import React from "react";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { DaysOptions } from "../../constants";

const fontSizes = {
  [20]: "xsmall",
  [30]: "small",
  [40]: "medium",
};

const useStyles = makeStyles()((theme, { props }) => ({
  dayChips: {
    display: "flex",
    marginTop: props.style?.marginTop || 5,
    alignItems: "center",
    justifyContent: "center",
    width: props.size || 20,
    borderRadius: 100,
    height: props.size || 20,
    overflow: "hidden",
    textOverflow: "",
    margin: 2,
    cursor: "pointer",
    fontSize: props.size
      ? theme.fontSize[fontSizes[props.size]]
      : theme.fontSize.xsmall,
    fontWeight: theme.fontWeight.bold,
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
}));

export const RunDays = ({
  days = "",
  size,
  style = {},
  onClick = () => null,
}) => {
  const { classes } = useStyles({ props: { size, style } });
  const theme = useTheme();
  const options = days.split(",");

  const getChipStyles = (day) => {
    if (!options.includes(day.value)) {
      return {
        ...style,
        color: theme.palette.secondary.grey,
        backgroundColor: theme.palette.accent.grey,
      };
    }
  };

  const handleClick = (val) => {
    let newOptions = [...options];
    if (newOptions.includes(val)) {
      newOptions = newOptions.filter((opt) => opt !== val);
    } else {
      newOptions.push(val);
    }
    onClick(newOptions.join(","));
  };

  return (
    <div style={{ display: "flex", alignSelf: "center", justifySelf: "start" }}>
      {DaysOptions.map((day) => {
        return (
          <div
            onClick={() => handleClick(day.value)}
            key={day.value}
            className={classes.dayChips}
            style={getChipStyles(day)}
          >
            {day.label}
          </div>
        );
      })}
    </div>
  );
};
