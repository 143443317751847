import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_USER_PAY_ACTIVITIES = `${API_V3}/users/me/payactivities`;
const API_PAY_ACTIVITIES = `${API_V3}/payactivities`;
const API_PAY_PERIODS = `${API_V3}/users/me/payperiods`;

export const payActivityService = {
  get: async ({
    userId,
    propertyStageId,
    pageSize,
    pageOffset,
    orderBy,
    descending,
    includeTotal,
  }) => {
    const params = {
      userId,
      propertyStageId,
      pageSize,
      pageOffset,
      descending,
      orderBy,
      includeTotal,
    };
    const options = await apiOptions({
      url: `${API_USER_PAY_ACTIVITIES}`,
      params,
    });
    return apiRequest(options);
  },

  getUserPayActivities: async ({ params, filters, signal }) => {
    const options = await apiOptions({
      url: `${API_USER_PAY_ACTIVITIES}`,
      method: "get",
      params,
      filters,
      signal,
    });
    return apiRequest(options);
  },

  sum: async ({ params, filters, signal }) => {
    const options = await apiOptions({
      url: `${API_USER_PAY_ACTIVITIES}/sum`,
      method: "get",
      params,
      filters,
      signal,
    });
    return apiRequest(options);
  },

  createDispute: async (id, data) => {
    const options = await apiOptions({
      url: `${API_PAY_ACTIVITIES}/${id}/paydisputes`,
      method: "post",
      data,
    });
    return apiRequest(options);
  },

  getDispute: async (id) => {
    const options = await apiOptions({
      url: `${API_PAY_ACTIVITIES}/${id}/paydisputes`,
      method: "get",
    });
    return apiRequest(options);
  },

  getPayPeriods: async ({ params, filters, signal }) => {
    const options = await apiOptions({
      url: `${API_PAY_PERIODS}`,
      method: "get",
      params,
      filters,
      signal,
    });

    return apiRequest(options);
  },
  getAll: async ({ params, filters, signal, sort }) => {
    const options = await apiOptions({
      url: `${API_PAY_ACTIVITIES}`,
      method: "get",
      params,
      filters,
      signal,
      sort,
    });
    return apiRequest(options);
  },
};
