export const ADMIN_NAV = {
  items: [
    {
      name: "Account Management",
      url: "/settings/accountmanagement",
      icon: "People",
    },
    {
      name: "Contact Management",
      url: "/settings/contactmanagement",
      icon: "Contacts",
    },
    {
      name: "Warehouse Locations",
      url: "/settings/warehouselocationmanagement",
      icon: "Warehouse",
    },
    // {
    //   name: "Integrations",
    //   url: "/settings/integrations",
    // },
  ],
};
