import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_CONFIGURATION = `${API_V3}/configurations`;

export const configurationService = {
  getConnectionString: async ({ params } = {}) => {
    const options = await apiOptions({
      url: `${API_CONFIGURATION}/appinsightsConnectionString`,
      method: "get",
      params,
    });
    return apiRequest(options, { failure: { hideMessage: true } });
  },
};
