import React from "react";
import { MiModalConfirm } from "@miview/components";
import { useTheme } from "@mui/material";

const ExitRedTagModal = (props) => {
  const { open, onClose, handleExitRedTagModal } = props;

  const theme = useTheme();

  return (
    <MiModalConfirm
      titleColor={theme.palette.primary.red}
      title={"Warning!"}
      open={open}
      description={
        "Are you sure you want to lose your unsaved data you have entered for this Red Tag?"
      }
      handlePositive={handleExitRedTagModal}
      handleNegative={onClose}
    ></MiModalConfirm>
  );
};

export default ExitRedTagModal;
