import { useEffect, useState } from "react";
import { appStorage } from "@miview/utils";
import { getAndStoreCDN } from "@miview/api";
import { CDN_DEFAULT, CDN_STORAGE_PARAM } from "@miview/constants";

export const useCdn = () => {
  const [cdn, setCdn] = useState(CDN_DEFAULT);

  useEffect(() => {
    const initialize = async () => {
      await getAndStoreCDN();
      getCdnFromStorage();
    };
    initialize();
  }, []);

  const getCdnFromStorage = async () => {
    const result = await appStorage.getItem(CDN_STORAGE_PARAM);
    if (result) {
      setCdn(result);
    }
  };
  return cdn;
};
