import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  MiPageHeader,
  MiButton,
  MiList,
  MiLink,
  MiModal,
  MiDetailFields,
  MiIcon,
} from "@miview/components";
import { accountServiceLegacy as accountService } from "@miview/api";
import {
  useRouter,
  useComponentState,
  usePermissions,
  useEdit,
} from "@miview/hooks";
import { ACCOUNT_TYPES, PERMISSIONS, TOAST_TYPES } from "@miview/constants";
import { createToast } from "@miview/toast";
import { createFilter, combineFilters } from "@miview/utils";
import { mdiAccountHardHatOutline, mdiPlus } from "@mdi/js";

const Builders = () => {
  const [builders, setBuilders] = useState([]);
  const [isCreateBuilderModalOpen, setIsCreateBuilderModalOpen] =
    useState(false);
  const router = useRouter();
  const stateManager = useComponentState();
  const permissions = usePermissions();
  const edit = useEdit({
    name: "",
    code: "",
  });

  const theme = useTheme();

  useEffect(() => {
    document.title = "Builders";
  }, []);

  const getBuilders = async ({ ...params }) => {
    const filters = createFilter();
    filters.equals({
      type: ACCOUNT_TYPES.BUILDER,
    });
    return accountService.getAll({
      ...params,
      filters: combineFilters(params.filters, filters.getFilter()),
    });
  };

  const addBuilder = async () => {
    let builderObj = edit.edits;

    const response = await accountService.create(builderObj);
    if (response?.data) {
      router.push(`/builder/${response.data}`);
    }
  };

  const openAddModal = () => {
    if (!permissions.hasPermission(PERMISSIONS.CAN_CREATE_BUILDERS)) {
      createToast(
        "You do not have permission to create builders",
        TOAST_TYPES.ERROR
      );
      return;
    }
    setIsCreateBuilderModalOpen(true);
  };

  const closeAddModal = () => {
    setIsCreateBuilderModalOpen(false);
    edit.reset();
  };

  const columns = [
    {
      field: "name",
      headerName: "Builder",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (p) => {
        return <MiLink to={`/builder/${p.id}`} title={p.value} />;
      },
    },
    {
      field: "code",
      headerName: "Account Code",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
  ];

  const builderFields = [
    {
      label: "Builder",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "100%",
    },
    {
      label: "Account Code",
      value: edit.getValue("code"),
      setValue: (e) => edit.update({ code: e }),
      required: true,
      width: "100%",
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiAccountHardHatOutline}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Builder"
        icon={mdiPlus}
        inverse
        onClick={openAddModal}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <React.Fragment>
      <MiPageHeader
        title="Builders"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
      />
      {stateManager.statusTag("builderListStatus")}
      <MiList
        data={builders}
        dataProvider={getBuilders}
        setData={setBuilders}
        columns={columns}
        defaultPageSize={50}
        hideFilters
      />
      <MiModal
        key={0}
        title="Add A New Builder"
        open={isCreateBuilderModalOpen}
        onClose={closeAddModal}
        actions={[
          {
            name: "Cancel",
            color: theme.palette.medium.grey,
            onClick: closeAddModal,
            inverse: false,
          },
          {
            name: "Save",
            onClick: addBuilder,
            color: theme.palette.primary.green,
            inverse: true,
            disabled: !edit.allFilled("name", "code"),
          },
        ]}
      >
        <MiDetailFields detailFields={builderFields} />
      </MiModal>
    </React.Fragment>
  );
};

export default Builders;
