import React, { useState } from "react";
import { THEME } from "./theme";
import { miStyles } from "@miview/theme";
import { MiButton } from "@miview/components";
import { mdiCancel, mdiCheckBold } from "@mdi/js";

const MiModal = (props) => {
  const {
    title,
    saveAction,
    saveTitle,
    saveDisabled,
    otherAction,
    otherTitle,
    otherDisabled,
    otherColor,
    cancelAction,
    cancelTitle,
    children,
    slidesOut,
    stayOpenOnSave,
    disableHeaderFooter,
    disableOverlayClick,
  } = props;

  const { classes } = miStyles();

  const [anim, setAnim] = useState(classes.miModal);
  const [animFade, setAnimFade] = useState(classes.miModalBackdrop);
  const width = props.width || 600;
  const maxWidth = props.maxWidth || "unset";
  const minWidth = props.minWidth || "unset";
  const color = props.color || THEME.BLUE_PRIMARY;
  const overflowY = props.overflowY || "auto";

  const save = () => {
    if (slidesOut) {
      slideOut();
      setTimeout(() => saveAction(), 200);
    } else {
      saveAction();
      if (!stayOpenOnSave) {
        animOut();
        setTimeout(() => props.toggle(), 180);
      }
    }
  };

  const close = () => {
    animOut();
    setTimeout(() => props.toggle(), 180);
  };

  const slideOut = () => {
    setAnim(classes.slideOut);
    setAnimFade(classes.fadeOut);
  };

  const cancel = () => {
    animOut();
    setTimeout(cancelAction ? () => cancelAction() : () => props.toggle(), 180);
  };

  const animOut = () => {
    setAnim(classes.miModalOut);
  };

  return (
    <div
      className={classes.miModalContainer}
      onClick={(e) => e.stopPropagation()}
      style={styles.container}
    >
      <div className={animFade} style={styles.backdrop} />

      <div
        className={anim}
        onMouseDown={!disableOverlayClick && close}
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            width: width,
            maxWidth: maxWidth,
            minWidth: minWidth,
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          <div style={{ textAlign: "right", width: "100%" }}>
            <i
              className={`${classes.miModalClose} material-icons`}
              onClick={close}
              style={styles.closeButton}
            >
              close
            </i>
          </div>

          <div
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            style={{
              ...styles.modal,
              maxHeight: "85%",
              padding: !disableHeaderFooter ? "12px" : 0,
            }}
          >
            {!disableHeaderFooter && (
              <div
                className={classes.miModalHeader}
                style={{
                  ...styles.header,
                  ...THEME.MODAL_HEADER,
                  color: color,
                }}
              >
                {title}
                {title && <hr style={styles.divider} />}
              </div>
            )}
            <MiModalBody
              overflowY={overflowY}
              disableHeaderFooter={disableHeaderFooter}
            >
              {children}
            </MiModalBody>
            {(saveAction || otherAction || cancelAction) &&
              !disableHeaderFooter && (
                <MiModalFooter>
                  <MiButton
                    icon={mdiCancel}
                    title={cancelTitle || "Cancel"}
                    style={{ minWidth: 90 }}
                    inverse={false}
                    onClick={cancel}
                    color={THEME.GREY_MEDIUM}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {otherAction && (
                      <MiButton
                        icon={otherTitle ? "" : mdiCheckBold}
                        title={otherTitle || "Save, Add Another"}
                        inverse={true}
                        style={{ minWidth: 90 }}
                        onClick={otherAction}
                        disabled={otherDisabled}
                        color={otherColor || color}
                      />
                    )}
                    {saveAction && (
                      <MiButton
                        id="modalSaveButton"
                        icon={mdiCheckBold}
                        title={saveTitle || "Save"}
                        inverse={true}
                        style={{ minWidth: 90 }}
                        onClick={save}
                        disabled={saveDisabled}
                        color={color}
                      />
                    )}
                  </div>
                </MiModalFooter>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MiModalBody = (props) => {
  const { overflowY, children, disableHeaderFooter, ...rest } = props;
  const { classes } = miStyles();
  return (
    <div
      className={classes.miModalBody}
      style={{
        ...styles.body,
        overflowY,
        padding: !disableHeaderFooter ? "0 24px" : 0,
        margin: !disableHeaderFooter ? "24px 0" : 0,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

const MiModalFooter = (props) => {
  const { style, children, ...rest } = props;
  const { classes } = miStyles();
  return (
    <div
      className={classes.miModalFooter}
      {...rest}
      style={{ ...styles.footer, ...style }}
    >
      {children}
    </div>
  );
};

export default MiModal;
export { MiModalBody, MiModalFooter };

const styles = {
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1205,
  },
  backdrop: {
    background: "black",
    position: "fixed",
    height: "100%",
    width: "100%",
  },
  modal: {
    background: "white",
    flexBasis: "auto",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: "0 12px",
    marginTop: 5,
    textAlign: "center",
  },
  divider: {
    marginTop: 6,
    borderColor: THEME.GREY_LIGHT,
    width: "100%",
  },
  body: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  footer: {
    margin: "12px 12px 4px 12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  closeButton: {
    marginTop: "9vh",
    fontSize: 30,
    color: "white",
    cursor: "pointer",
    userSelect: "none",
  },
};
