import React from "react";
import {
  MiInputTextbox,
  MiInputSelectBox,
  MiDatePickerInput,
  MiInputFile,
} from "../index.ts";
import { Grid } from "@mui/material";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, { backgroundColor }) => ({
  fieldRows: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    backgroundColor: backgroundColor || theme.palette.primary.white,
  },
}));

const FieldType = ({ column, disabled }) => {
  switch (column?.type) {
    case "select":
      return (
        <MiInputSelectBox
          value={column.value}
          menuItems={column.options}
          disabled={disabled}
          labelText={column.label}
          required={column.required}
          valueField={column.valueField}
          labelField={column.labelField}
          handleChange={column.onChange}
        />
      );
    case "date":
      return (
        <MiDatePickerInput
          type={"date"}
          required={column.required}
          labelText={column.label}
          labelTextColor={column.textColor}
          value={column.value}
          handleChange={column.onChange}
          inputFormat="MM/DD/YYYY"
          dateFormat="YYYY-MM-DDThh:mm:ss"
          disabled={disabled}
        />
      );
    case "file":
      return (
        <MiInputFile
          disabled={disabled}
          labelText={column.label}
          required={column.required}
          label={column.label}
          filename={column.filename}
          handleChange={column.onChange}
          onClear={column.onClear}
          onView={column.onView}
        />
      );
  }

  return (
    <MiInputTextbox
      required={column.required}
      disabled={disabled}
      labelText={column.label}
      value={column.value}
      handleChange={column.onChange}
    />
  );
};

export const MiFields = (props) => {
  const { columns = [], disabled, edit, backgroundColor = "#FFF" } = props;
  const { classes } = useStyles({ backgroundColor });

  if (!columns.length) {
    return null;
  }

  return (
    <Grid container className={classes.fieldRows}>
      {columns.map((col) => (
        <Grid item xs={col.xs || 1.8} key={col.id} style={{ padding: 5 }}>
          <FieldType edit={edit} column={col} disabled={disabled} />
        </Grid>
      ))}
    </Grid>
  );
};
