import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  link: {
    cursor: "pointer",
    textDecoration: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export const MiOpenLinkInNewTab = React.memo(({ to, children }) => {
  const { classes } = useStyles();

  return (
    <Link to={to} target="_blank" rel="noopener" className={classes.link}>
      {children}
    </Link>
  );
});
