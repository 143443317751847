import React from "react";
import PropTypes from "prop-types";
import {
  Switch as MuiSwitch,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material";
import { styled } from "@mui/system";

const Switch = styled(MuiSwitch)(({ theme, iconColor, trackColor }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: iconColor || theme.palette.primary.main,
  },
  "& .MuiSwitch-switchBase": {
    transitionDuration: "400ms",

    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: trackColor || theme.palette.lightAccent.bluegrey,
      },
    },
  },
}));

const FormControlLabel = styled(MuiFormControlLabel)(
  ({ theme, fontSize, textColor }) => ({
    ".MuiFormControlLabel-label": {
      fontSize: fontSize || "0.8rem",
      color: textColor || theme.palette.secondary.grey,
      fontWeight: "800",
    },
    margin: "auto",
  })
);

export const MiSwitch = ({
  label,
  labelPlacement,
  disabled,
  value,
  handleChange,
  inputProps,
  ...rest
}) => {
  return (
    <FormControlLabel
      value={labelPlacement}
      control={
        <Switch
          data-testid="MiSwitch"
          checked={Boolean(value)}
          onChange={handleChange}
          name="checkedA"
          disabled={disabled}
          inputProps={{ "aria-label": "secondary checkbox", ...inputProps }}
          {...rest}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

MiSwitch.propTypes = {
  /**
   * Label text for the component
   */
  label: PropTypes.string,
  /**
   * Label palcement. Value can be top, start, bottom or end
   */
  labelPlacement: PropTypes.string,
  /**
   * color for the switch component
   */
  color: PropTypes.string,
  /**
   * will decide if the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Attributes applied to the input element.
   */
  inputProps: PropTypes.object,
};
