/*eslint-disable*/
import React from "react";
import { ErrorIllustraion, Angle } from "@miview/assets";
import { THEME } from "@miview/theme";

export const ErrorView = () => {
  return (
    <div style={{ display: "flex", flexGrow: 1, height: "100vh" }}>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "60%" }}>
          <h1
            style={{
              fontSize: 120,
              fontWeight: 800,
              borderBottom: `2px solid ${THEME.BLUE_PRIMARY}`,
            }}
          >
            Uh Oh!
          </h1>
          <p
            style={{
              fontSize: 32,
            }}
          >
            It seems there is a problem accessing the page you are looking for.
          </p>
          <p style={{ color: THEME.GREY_MEDIUM }}>
            Contact our Support team at support@miviewis.com for help.
          </p>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          backgroundImage: `url(${Angle})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={ErrorIllustraion}
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </div>
    </div>
  );
};

export default ErrorView;
