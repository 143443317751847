import React, { memo } from "react";
import { MiMessage, MiNewMessageDialog } from "../MiView";
import { useMessages } from "./useMessages";

const MessageCollapse = (props) => {
  const { messageContext, id } = props;
  const { messages, status, handleGetMessages } = useMessages(
    messageContext,
    id
  );

  if (status) {
    return status;
  }

  return (
    <div
      style={{
        height: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {messages.length > 0 &&
        messages
          .reverse()
          .map((item) => (
            <MiMessage
              key={item.messageId}
              messageInfo={item}
              relatedType={messageContext}
              relatedEntityId={id}
              refreshAction={handleGetMessages}
              depth={1}
              childMessages={item.childMessages}
              isChildMessage={false}
            />
          ))}

      <MiNewMessageDialog
        relatedEntityId={id}
        refreshAction={handleGetMessages}
        relatedType={messageContext}
      />
    </div>
  );
};

export default memo(MessageCollapse);
