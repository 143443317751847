import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { useRouter } from "@miview/hooks";
import { sessionService } from "@miview/api";
import { getInitials } from "@miview/utils";

const useStyles = makeStyles()((theme) => ({
  popup: {
    marginTop: 8,
    marginLeft: 8,
  },
  avatar: {
    backgroundColor: theme.palette.medium.grey,
    color: "white",
    padding: 6,
    marginLeft: 10,
    height: 30,
    width: 30,
    fontSize: 12,
    cursor: "pointer",
  },
}));

export const UserAvatar = ({ user }) => {
  const { classes } = useStyles();
  const { push } = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGetInitials = () => {
    if (!user) {
      return null;
    }
    return getInitials(user.fullName);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionService.logout();
    push("/login");
  };

  return (
    <>
      <Avatar onClick={handleClick} className={classes.avatar}>
        {handleGetInitials()}
      </Avatar>
      <Menu
        className={classes.popup}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            push("/profile");
            setAnchorEl(null);
          }}
        >
          Edit Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
