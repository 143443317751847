export const getFilename = (headerDisposition) => {
  const r = /filename="([^"]+)"/;
  const matches = headerDisposition.match(r);
  if (!matches) {
    return;
  }
  return matches[1];
};

export const openFileSaveDialog = (blob, filename) => {
  const URL = window.URL || window.webkitURL;
  const downloadUrl = URL.createObjectURL(blob);

  if (filename) {
    // use HTML5 a[download] attribute to specify filename
    const a = document.createElement("a");
    // safari doesn't support this yet
    if (typeof a.download === "undefined") {
      window.location.href = downloadUrl;
    } else {
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }
  } else {
    window.location.href = downloadUrl;
  }
};
