/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { MiPageHeader } from "@miview/components";
import { Grid } from "@mui/material";
import { MiTHEME } from "@miview/theme";
import en from "../../../locale/en.json";
import RequisitionTemplate from "./RequisitionTemplate";

export const RequisitionsTemplates = () => {
  return (
    <Grid>
      <MiPageHeader
        title={en.templates}
        color={MiTHEME.colors.teal.primary}
        hasDivider={true}
      />
      <RequisitionTemplate />
    </Grid>
  );
};
