import React, { useState } from "react";
import { MiModal } from "../../../components/Standard/MiView";
import PropTypes from "prop-types";
import { propertyService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import {
  MiRadioGroup,
  MiButton,
  MiInputSelectBox,
  MiInputTextbox,
} from "@miview/components";
import { createFilter } from "@miview/utils";
export const ImportCadModal = ({
  propertyId,
  setImportCadModal,
  invokeRefresh,
  stages,
}) => {
  const topOutStageId = stages?.find((s) => s.name === "Top Out")?.id;

  const [copyAddress, setCopyAddress] = useState("");
  const [copyOptions, setCopyOptions] = useState([]);
  const [copyTargetId, setCopyTargetId] = useState(-1);
  const [stageTypeId, setStageTypeId] = useState(topOutStageId);

  const getPropertiesOnEnter = (e) => {
    if (e.key !== "Enter") {
      return;
    }

    getProperties();
  };

  const setCopyTarget = (selectedPropertyName) => {
    const selectedPropertyId = copyOptions.find(
      (option) => option.value === selectedPropertyName
    )?.id;
    setCopyTargetId(selectedPropertyId);

    const newCopyOptions = copyOptions.map((option) => ({
      ...option,
      isChecked: selectedPropertyId === option.id,
    }));
    setCopyOptions(newCopyOptions);
  };

  const getProperties = async () => {
    const filterHelper = createFilter();
    const filters = filterHelper.contains({
      addressLine1: copyAddress,
    });
    const response = await propertyService.getAll({ filters });
    if (response.status !== HTTP_STATUSES.OK) {
      return;
    }
    const result = response.data;
    const mapped = result.map((item) => {
      return {
        id: item.propertyId,
        value: item.addressLine1,
        label: item.addressLine1,
        isChecked: false,
      };
    });
    setCopyOptions(mapped);
  };

  const importCad = async () => {
    await propertyService.importCad(propertyId, {
      importId: copyTargetId,
      stageTypeId: stageTypeId,
    });
    invokeRefresh();
  };

  const getStageOptions = (stages) => {
    return stages.map((s) => ({ value: s.id, text: s.name }));
  };

  return (
    <MiModal
      title="Import CAD images"
      saveAction={() => importCad()}
      toggle={() => setImportCadModal(false)}
      saveTitle="Copy"
      saveDisabled={copyTargetId === -1}
    >
      <MiInputSelectBox
        labelText="Stage"
        labelField="text"
        value={stageTypeId}
        menuItems={getStageOptions(stages)}
        handleChange={(e) => setStageTypeId(e.target.value)}
      />

      <div style={styles.searchBox}>
        <MiInputTextbox
          labelText="Address"
          value={copyAddress}
          styles={{ flex: "1" }}
          handleChange={(e) => setCopyAddress(e.target.value)}
          onKeyDown={getPropertiesOnEnter}
        />
        <MiButton title="Search" onClick={() => getProperties()} />
      </div>
      {copyOptions && (
        <MiRadioGroup data={copyOptions} handleChange={setCopyTarget} />
      )}
    </MiModal>
  );
};

const styles = {
  searchBox: {
    display: "flex",
    alignItems: "center",
  },
};

ImportCadModal.propTypes = {
  setImportCadModal: PropTypes.func,
};
