import { makeStyles } from "tss-react/mui";

const MiImageClipboardStyles = {
  ImageFocus: {
    border: "none",
    backgroundColor: "white",
    position: "relative",
    top: "10px",
    width: "50px",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 5px",
    bottom: "20px",
    "&:focus": {
      zIndex: 10,
      boxShadow: "0 0 0 5px rgba(21, 156, 228, 0.4)",
    },
  },
  altIcon: {
    color: "gray",
  },
  selectionStyles: {
    width: 0.1,
    height: "0.1",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },
  selectedStyles: {
    width: "85%",
    height: "70%",
    padding: "30px 30px",
    overflow: "hidden",
    position: "absolute",
    color: "transparent",
    zIndex: 9999,
    cursor: "pointer",
    "&:focus:": {
      border: "5px solid blue",
    },
    "&::file-selector-button": {
      display: "none",
    },
  },
  selectionLabel: {
    margin: "0",
    textTransform: "capitalize",
    textAlign: "center",
    flex: 0,
  },
  selectedImageStyles: {
    backgroundColor: "rgb(214, 214, 214)",
    height: "40px",
    width: "40px",
    borderRadius: "5px",
    border: "1px solid gray",
    cursor: "pointer",
    "& img": {
      height: "40px",
      width: "40px",
      borderRadius: "5px",
      border: "1px solid gray",
    },
    "&:hover": { backgroundColor: "lightblue" },
    "& img:hover": { opacity: 0.5 },
  },
  removalLabel: {
    fontSize: "0.75rem",
    color: "rgb(150, 148, 146)",
    textAlign: "center",
    textTransform: "capitalize",
    cursor: "pointer",
    "&:hover": { color: "blue", textDecoration: "underline" },
  },
  clipboardLabel: {
    fontSize: "0.75rem",
    color: "rgb(150, 148, 146)",
    paddingTop: "4px",
    textAlign: "center",
    textTransform: "capitalize",
    cursor: "pointer",
    "&:hover": { color: "blue", textDecoration: "underline" },
  },
};

export const clipboardImageStyles = makeStyles()(MiImageClipboardStyles);
