import React from "react";
import PropTypes from "prop-types";

const MiSpecsSubTitle = (props) => {
  return <div style={styles.subTitle}>{props.children}</div>;
};

MiSpecsSubTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

const styles = {
  subTitle: {
    padding: "8px",
    fontSize: 12,
  },
};

export default MiSpecsSubTitle;
