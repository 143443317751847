import React from "react";
import { makeStyles } from "tss-react/mui";

const planItemImageLabelStyles = {
  planItemImageLabel: {
    fontSize: "0.8rem",
    color: "rgb(150, 148, 146)",
    marginBottom: "4px",
    paddingTop: "4px",
    textAlign: "center",
    textTransform: "capitalize",
    cursor: "pointer",
    "&:hover": { color: "blue", textDecoration: "underline" },
  },
};

export const usePlanItemImageLabelStyles = makeStyles()(
  planItemImageLabelStyles
);

export const PlanItemImageLabel = (props) => {
  const { classes } = usePlanItemImageLabelStyles();
  return <div className={classes.planItemImageLabel}>{props.children}</div>;
};
