import { useCallback } from "react";
import { useRouter } from "@miview/hooks";
import { usePlanOptions, usePlanItemForm } from "./";
import { planItemService, planService, optionService } from "@miview/api";

const handleDeleteOrRestore = async ({
  optionConfiguring,
  itemDetails,
  planId,
  setPlanAreasPreview,
}) => {
  try {
    let planAreas = [];

    if (optionConfiguring) {
      const deletedValue = !itemDetails.ruleId;

      const requestPayload = {
        itemId: itemDetails.itemId,
        itemName: itemDetails.itemName || undefined,
        description: itemDetails.description || undefined,
        planAreaId: itemDetails.planAreaId,
        walkTypeId: itemDetails.walkTypeId || undefined,
        topImageUrl: itemDetails.topImageUrl || undefined,
        frontImageUrl: itemDetails.frontImageUrl || undefined,
        ruleId: itemDetails.ruleId,
        isDeleted: deletedValue,
        optionId: optionConfiguring,
        materialId: itemDetails.materialId || undefined,
        quantity: itemDetails.quantity || undefined,
      };

      await optionService.createOrUpdateRule(optionConfiguring, requestPayload);

      const response = await planService.applyOptions(planId, [
        optionConfiguring,
      ]);
      planAreas = response.data;
    } else {
      await planItemService.delete(itemDetails.itemId);

      const response = await planService.applyOptions(planId);
      planAreas = response.data;
    }

    setPlanAreasPreview(planAreas);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const usePlanItemUpdate = (setPlanAreasPreview) => {
  const {
    query: { planId },
  } = useRouter();
  const { optionConfiguring } = usePlanOptions();
  const { planItemForm, resetPlanItemForm } = usePlanItemForm();

  const submitPlanItem = async () => {
    try {
      let planAreas = [];

      if (optionConfiguring) {
        const optionPayload = {
          itemId: planItemForm.itemId,
          itemName: planItemForm.itemName || undefined,
          description: planItemForm.description || undefined,
          planAreaId: planItemForm.areaId,
          walkTypeId: planItemForm.walkTypeId || undefined,
          topImageName: planItemForm.topImageName || undefined,
          frontImageName: planItemForm.frontImageName || undefined,
          ruleId: planItemForm.ruleId || undefined,
          isDeleted: planItemForm.isDeleted || undefined,
          optionId: optionConfiguring,
          materialId: planItemForm.materialId || undefined,
          quantity: planItemForm.quantity || undefined,
        };

        await optionService.createOrUpdateRule(optionPayload);

        const response = await planService.applyOptions(planId, [
          optionConfiguring,
        ]);
        planAreas = response.data;
      } else {
        const requestPayload = { ...planItemForm, planId };

        if (!planItemForm.itemId) {
          await planItemService.create(requestPayload);
        } else {
          await planItemService.update(planItemForm.itemId, requestPayload);
        }

        const response = await planService.applyOptions(planId);
        planAreas = response.data;
      }

      resetPlanItemForm();
      setPlanAreasPreview(planAreas);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const deleteOrRestorePlanItem = useCallback(
    ({ itemDetails }) => {
      handleDeleteOrRestore({
        optionConfiguring,
        itemDetails,
        planId,
        setPlanAreasPreview,
      });
    },
    [optionConfiguring, planId, setPlanAreasPreview]
  );

  return {
    submitPlanItem,
    deleteOrRestorePlanItem,
  };
};
