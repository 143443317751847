import React from "react";
import { MiMaterialsModal } from "../../../components/Standard/MiView";

export const MaterialModalContainer = (props) => {
  const {
    materialsModalOpen,
    setMaterialsModalOpen,
    materialCategories,
    materialBrands,
    edit,
  } = props;

  if (materialsModalOpen) {
    return (
      <MiMaterialsModal
        edit={edit}
        materialCategories={materialCategories}
        materialBrands={materialBrands}
        setMaterialsModalOpen={setMaterialsModalOpen}
      />
    );
  }

  return null;
};
