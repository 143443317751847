import React from "react";
import { styled } from "@mui/material/styles";

const Label = styled("label")(({ theme, labelTextColor }) => {
  return {
    color: labelTextColor || theme.palette.primary.main,
    position: "absolute",
    top: -22,
    left: 5,
    fontSize: theme.fontSize.medium,
    display: "contents",
  };
});

const RequiredSpan = styled("span")(({ theme }) => {
  return {
    display: "inherit",
    color: theme.palette.primary.red,
    fontWeight: "bold",
  };
});

export const MiLabel = (props) => {
  const { children, required, labelTextColor } = props;

  return (
    <Label labelTextColor={labelTextColor}>
      {children}
      {required && <RequiredSpan>&nbsp;*</RequiredSpan>}
    </Label>
  );
};
