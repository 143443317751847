import React, { useState, useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import { SubHeader } from "../components";
import en from "../locale/en.json";
import { MiInputTextbox } from "@miview/components";
import { makeStyles } from 'tss-react/mui';
import { accountService } from "@miview/api";
import { createFilter } from "@miview/utils";
import { HTTP_STATUSES } from "@miview/constants";
import { useDebounce } from "@miview/hooks";
const useStyles = makeStyles()(() => ({
  formAlign: {
    marginTop: 20,
    paddingRight: 10,
  },
}));

const CustomerInfoForm = ({
  editMode,
  edit,
  isMissingRequiredField,
  setDuplicateAccounts,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [legalNameHelperText, setLegalNameHelperText] = useState("");
  const debouncedLegalName = useDebounce(edit.getValue("legalName"), 300);

  useEffect(() => {
    validateLegalName(edit.getValue("legalName"));
  }, [debouncedLegalName]);

  const verifyDuplicateAccountsByLegalName = async (accountName) => {
    const filters = createFilter().equals({
      legalName: accountName,
    });
    const response = await accountService.accounts.getAll({ filters });
    if (response.status === HTTP_STATUSES.OK) {
      const accounts = response.data;
      const accountNames = accounts
        .filter((x) => x.id != edit.getValue("id"))
        .map((x) => x.name);

      if (accountNames.length) {
        setLegalNameHelperText(`Enter ${en.legalName}`);
        setDuplicateAccounts(true);
      } else {
        setLegalNameHelperText("");
        setDuplicateAccounts(false);
      }
    }
  };

  const validateLegalName = async (accountName) => {
    if (isMissingRequiredField && !accountName) {
      setLegalNameHelperText("Enter a valid Legal Name");
      return;
    }
    verifyDuplicateAccountsByLegalName(accountName);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <SubHeader text={en.customerInfo} />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.customerNumber}
          disabled={!editMode}
          value={edit.getValue("number")}
          handleChange={(e) => edit.update({ number: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={
            editMode && legalNameHelperText !== ""
              ? theme.palette.secondary.red
              : theme.palette.primary.main
          }
          labelText={en.legalName}
          required
          error={editMode && legalNameHelperText !== ""}
          helperText={editMode && legalNameHelperText}
          disabled={!editMode}
          value={edit.getValue("legalName")}
          handleChange={(e) => {
            edit.update({ legalName: e.target.value });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(CustomerInfoForm);
