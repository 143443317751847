import React, { useState, useEffect, memo } from "react";
import { useTheme } from "@mui/material/styles";
import CreateNewPropertyModal from "./CreateNewPropertyModal";
import {
  Chip,
  MiPageHeader,
  MiButton,
  MiList,
  MiLink,
  MiIcon,
} from "@miview/components";
import "./StyleTable.css";
import { formatDate } from "../../../helpers/dateTools";
import { propertyService } from "@miview/api";
import { PROPERTY_STATUSES, DETAILS } from "@miview/constants";
import { alpha } from "@mui/material";
import { useComponentState } from "@miview/hooks";
import { mdiHomeOutline, mdiPlus } from "@mdi/js";
import { DATE_FILTER_OPERATORS } from "@miview/utils";

const Properties = memo(() => {
  const [newPropertyInProgress, setNewPropertyInProgress] = useState();
  const [properties, setProperties] = useState([]);
  const [search, setSearch] = useState(false);

  const stateManager = useComponentState();
  const theme = useTheme();
  const STATUS_COLORS = {
    [PROPERTY_STATUSES.STAGECOMPLETE]: theme.palette.primary.green,
    [PROPERTY_STATUSES.SCHEDULED]: theme.palette.primary.main,
    [PROPERTY_STATUSES.NOTSCHEDULED]: theme.palette.primary.bluegrey,
    [PROPERTY_STATUSES.NOTREADY]: theme.palette.primary.bluegrey,
    [PROPERTY_STATUSES.IN_PROGRESS]: theme.palette.primary.orange,
  };

  const getColor = (val) => {
    if (!val) {
      return theme.palette.primary.white;
    }
    return STATUS_COLORS[val] || theme.palette.primary.main;
  };

  useEffect(() => {
    document.title = "Homes";
  }, []);

  const toggleModal = () => {
    setNewPropertyInProgress(!newPropertyInProgress);
  };

  const columns = [
    {
      field: "addressLine1",
      headerName: "Address",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1.2,
      renderCell: (p) => {
        return (
          <MiLink
            to={`/homes/${p.row.propertyId}/${DETAILS}`}
            title={p.value}
          />
        );
      },
    },
    {
      field: "cityName",
      headerName: "City",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 0.7,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 0.6,
    },
    {
      field: "community",
      headerName: "Community",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "builder",
      headerName: "Builder",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "currentStage",
      headerName: "Stage",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 0.6,
      renderCell: (p) => {
        return (
          <MiLink to={"/stages/" + p.row.currentStageId} title={p.value} />
        );
      },
    },
    {
      field: "currentStageScheduledDate",
      headerName: "Schedule Date",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      type: "date",
      filterOperators: DATE_FILTER_OPERATORS,
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
    },
    {
      field: "propertyStatus",
      headerName: "Status",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <Chip
            text={value}
            style={{
              display: "flex",
              borderRadius: "30px",
              height: "30px",
              minWidth: "80px",
              justifyContent: "center",
              padding: "0 7px 0 7px",
            }}
            color={alpha(getColor(value), 0.2)}
            textColor={getColor(value)}
          />
        );
      },
      type: "singleSelect",
      valueOptions: Object.values(PROPERTY_STATUSES),
    },
  ];

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiHomeOutline}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Home"
        icon={mdiPlus}
        inverse={true}
        onClick={toggleModal}
        color={theme.palette.primary.main}
      />
    );
  };

  const getProperties = async (params) => {
    if (search) {
      return propertyService.getAll(params);
    }
    return propertyService.getRecent(params);
  };

  return (
    <>
      <MiPageHeader
        title={`Homes - ${search ? "All" : "Recent"}`}
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
        loading={stateManager.isBusy()}
      />
      {stateManager.statusTag("propertyListStatus")}
      {newPropertyInProgress && (
        <CreateNewPropertyModal
          key={0}
          toggle={toggleModal}
          newPropertyInProgress={newPropertyInProgress}
        />
      )}
      <MiList
        data={properties}
        setData={setProperties}
        dataProvider={getProperties}
        defaultPageSize={50}
        handleSearch={setSearch}
        getRowId={(row) => row.propertyId}
        columns={columns}
        hideFilters
      />
    </>
  );
});

export default Properties;
