import React from "react";
import en from "../../../locale/en.json";
import { AccountNoItems } from "../../../components";
import CartComponents, {
  CartHeader,
  CartHeaderButtons,
  CartItemList,
} from "../../../components/VendorItemCost/CartComponents";
import { BOOL_VALUE } from "../../../constants";
import { useTheme } from "@mui/material";

const RequisitionsSelected = ({
  closeClick,
  selectedItems = [],
  ClearAllFunction,
  removeItem,
  onShowSupplierFunction,
}) => {
  const theme = useTheme();
  const CreateOrderFunction = () => {
    onShowSupplierFunction();
  };

  return (
    <CartComponents>
      <CartHeader
        title={en.requisitions}
        count={selectedItems?.length}
        closeClick={closeClick}
      />
      <CartHeaderButtons
        FilterArray={[
          {
            title: "Create Purchase Order",
            click: () => CreateOrderFunction(),
            color:
              selectedItems?.length > 0
                ? theme.palette.primary.white
                : theme.palette.lightAccent.bluegrey,
            backgroundColor:
              selectedItems?.length > 0
                ? theme.palette.primary.secondary
                : theme.palette.lightAccent.grey,
            style: {
              width: 170,
              height: 30,
              fontWeight: theme.fontWeight.medium,
              fontSize: theme.fontSize.small,
            },
            clickable: selectedItems.length > 0,
          },

          {
            title: "Clear All",
            click: () => ClearAllFunction(),
            color:
              selectedItems?.length > 0
                ? theme.palette.primary.white
                : theme.palette.lightAccent.bluegrey,
            backgroundColor:
              selectedItems?.length > 0
                ? theme.palette.primary.secondary
                : theme.palette.lightAccent.grey,
            style: {
              width: 160,
              height: 30,
              fontWeight: theme.fontWeight.medium,
              fontSize: theme.fontSize.small,
            },
            clickable: selectedItems.length > 0,
          },
        ]}
      />
      {!selectedItems.length && (
        <AccountNoItems
          title={en.noRequisitionsSelected}
          description={en.inOrderToCreateAPurchaseOrderYouMustAddRequisition}
          small={BOOL_VALUE}
        />
      )}
      {selectedItems &&
        selectedItems?.map((el, index) => (
          <CartItemList
            key={index}
            id={el?.number}
            description={el?.description}
            removeItem={() => removeItem(el?.id)}
          />
        ))}
    </CartComponents>
  );
};

export default RequisitionsSelected;
