import {
  ABORT,
  ABORTED,
  COMPLETE,
  INCOMPLETE,
  HOLD,
  PENDING,
  TASK,
} from "@miview/constants";
import { convertMToFt } from "../index.ts";

const checkAbortOnly = (arr) => {
  return arr.abortWalk && arr.status !== COMPLETE;
};

const checkIsComplete = (arr) => {
  return arr.status !== PENDING;
};

const getAbortAreasByStatus = (areas) => {
  return areas
    .filter((area) => area.sectionStatus === ABORT)
    .map((section) => section.propertyPlanAreaId);
};

const checkAreaPunchNotAnswered = (areas) =>
  areas.find(
    (elem) =>
      (elem.status === INCOMPLETE || elem.status === HOLD) &&
      !elem.isAnswered &&
      (elem.issueDescriptionId || elem.failReason)
  );

const getAllAbortAreas = (areas) => {
  return areas
    .filter((area) => area.items.some(checkAbortOnly))
    .map((section) => section.propertyPlanAreaId);
};

const checkPending = (walkItem) => {
  return walkItem.status === PENDING;
};

const checkIsAnswered = (walkItem) => {
  return !walkItem.isAnswered;
};

const checkComplete = (walkItem) => {
  return (
    (walkItem.status === COMPLETE || walkItem.isAnswered) &&
    walkItem.status !== PENDING
  );
};

const checkWalkComplete = (walkItem) => {
  return (
    (walkItem.status === COMPLETE ||
      walkItem.isAnswered ||
      walkItem.status === ABORTED) &&
    walkItem.status !== PENDING
  );
};

const checkNotPending = (walkItem) => {
  return walkItem.status !== PENDING;
};

const getFirstChecklistItem = (checklist) => {
  return checklist.find(
    (x) => x.sectionStatus !== ABORT && x.items.some(checkPending)
  );
};

const getChecklistItem = (checklist, id) => {
  let item = null;
  if (!checklist) {
    return item;
  }
  checklist.forEach((area, index) => {
    const itemIndex = area.items.findIndex(
      (sectionItem) => sectionItem.id === id
    );
    if (itemIndex !== -1) {
      item = checklist[index].items[itemIndex];
    }
  });
  return item;
};

const setChecklistItem = (checklist, id, newItem) => {
  let itemIndex = -1;
  const sectionIndex = checklist.findIndex((area) => {
    itemIndex = area.items.findIndex((sectionItem) => sectionItem.id === id);
    return itemIndex !== -1;
  });
  if (sectionIndex > -1 && itemIndex > -1) {
    const item = { ...checklist[sectionIndex].items[itemIndex], ...newItem };
    checklist[sectionIndex].items[itemIndex] = item;
  }
  return checklist;
};

const getAbortStatus = (item = {}) => {
  if (item.removeAborts) {
    return PENDING;
  }
  if (item.abortWalk && item.status !== COMPLETE) {
    return ABORTED;
  }
  return null;
};

const setChecklistItems = (checklist, items) => {
  const itemsKeyedById = {};
  items.forEach((item, index) => {
    itemsKeyedById[items[index].id] = items[index];
  });
  const itemIds = Object.keys(itemsKeyedById);
  checklist.forEach((area, sectionIndex) => {
    area.items.forEach((item, itemIndex) => {
      if (itemIds.includes(`${item.id}`)) {
        checklist[sectionIndex].items[itemIndex] = {
          ...item,
          ...itemsKeyedById[item.id],
        };
      }
    });
  });
  return checklist;
};

const checkPercentComplete = (checklist) => {
  let weight = 0;
  let completed = 0;
  checklist.forEach((item) => {
    const currentWeight = item?.weight || 20;
    weight = weight + currentWeight;
    let itemsComplete = item?.items.every(checkIsComplete);
    if (itemsComplete) {
      completed = completed + currentWeight;
    }
  });
  return (completed / weight).toFixed(2);
};

const allChecklistAnswered = (checklist) => {
  const index = checklist.findIndex((item) => item.items.some(checkIsAnswered));
  return index === -1;
};

const checkZeroAndConvert = (expected) => {
  if (expected === 0) {
    return 0;
  }
  return convertMToFt(expected);
};

const setMeasurements = (checklist, propertyStage) => {
  const updatedChecklist = [...checklist];
  updatedChecklist.forEach((area) =>
    area.items.forEach((item) => {
      if (
        (propertyStage.stageTypeId === 1 || propertyStage.stageTypeId === 5) &&
        item.stackSize
      ) {
        item.isStack = true;
        item.type = TASK;
        item.measurementBackConverted = checkZeroAndConvert(
          item.measurementBack
        );
        item.measurementSideConverted = checkZeroAndConvert(
          item.measurementSide
        );
        const measureCalc = 0.1397;
        const boundaries = ["Side", "Back"];
        boundaries.forEach((field) => {
          if (item[`measurement${field}Type`]) {
            const boundary = propertyStage?.cadBoundaries?.find(
              (b) => b.name === item[`measurement${field}Type`]
            );
            item[`hasBrickLedge${field}`] = false;
            item[`measurement${field}BrickConverted`] =
              item[`measurement${field}Converted`];
            if (boundary?.hasBrickLedge && item[`measurement${field}`] > 0) {
              item[`hasBrickLedge${field}`] = true;
              item[`measurement${field}Brick`] =
                item[`measurement${field}`] - measureCalc;
              item[`measurement${field}BrickConverted`] = convertMToFt(
                item[`measurement${field}`] - measureCalc
              );
            }
          }
        });
      }
    })
  );
  return updatedChecklist;
};

const getAssignees = (crews = []) => {
  return crews.map((crew) => {
    return {
      ...crew,
      body: crew.titleName,
      label: crew.name,
      value: crew.userId,
    };
  });
};
const getPunchmen = (punchmen = []) => {
  return punchmen.map((item) => {
    return {
      ...item,
      body: item.titleName,
      label: item.name,
      value: item.userId,
    };
  });
};

export const checklistHelpers = {
  checkIsComplete,
  checkAbortOnly,
  getAllAbortAreas,
  getAbortAreasByStatus,
  checkPending,
  checkComplete,
  checkWalkComplete,
  getFirstChecklistItem,
  checkNotPending,
  getChecklistItem,
  setChecklistItem,
  setChecklistItems,
  setMeasurements,
  checkPercentComplete,
  checkAreaPunchNotAnswered,
  allChecklistAnswered,
  getAssignees,
  getPunchmen,
  getAbortStatus,
};
