import {
  OR_SEPARATOR,
  AND_SEPARATOR,
  ASSIGNMENT_SEPARATOR,
  EQUALS_OPERATOR,
  RANGE_OPERATOR,
  STARTS_WITH_OPERATOR,
  ENDS_WITH_OPERATOR,
  CONTAINS_OPERATOR,
  AND_FILTER,
  OR_FILTER,
  IS_ANY_OF,
  IS_OPERATOR,
  ON_OR_AFTER_OPERATOR,
  ON_OR_BEFORE_OPERATOR,
  IS_EMPTY_OPERATOR,
  ON_OPERATOR,
} from "@miview/constants";
import moment from "moment";

export const createOredFields = (fieldValuePairs) => {
  return fieldValuePairs
    .map((e) => `${e.columnField}${ASSIGNMENT_SEPARATOR}${e.value}`)
    .join(OR_SEPARATOR);
};

export const createAndedFields = (fieldValuesPair) => {
  return fieldValuesPair
    .map((e) => {
      if (Array.isArray(e.value)) {
        return `${e.columnField}${ASSIGNMENT_SEPARATOR}${e.value.join(
          OR_SEPARATOR
        )}`;
      }
      return `${e.columnField}${ASSIGNMENT_SEPARATOR}${e.value}`;
    })
    .join(AND_SEPARATOR);
};

export const createQueryString = (filters) => {
  const separator = selectSeparator(filters.linkOperator);
  if (!separator) {
    return null;
  }
  const fieldStrings = filters.items
    .map((e) => {
      const valueString = wrapValues(e);
      if (!valueString) {
        return null;
      }
      return `${e.columnField}${ASSIGNMENT_SEPARATOR}${valueString}`;
    })
    .filter((e) => !!e && e.length);
  return fieldStrings.join(separator);
};

const wrapValues = (fieldObj) => {
  if (
    fieldObj.value === undefined &&
    fieldObj.operatorValue !== IS_EMPTY_OPERATOR
  ) {
    return null;
  }
  const value = Array.isArray(fieldObj.value)
    ? fieldObj.value
    : [fieldObj.value];

  switch (fieldObj.operatorValue) {
    case EQUALS_OPERATOR:
    case IS_OPERATOR:
    case IS_ANY_OF:
      return `${value
        .map((e) => (e === null ? "null" : e))
        .join(OR_SEPARATOR)}`;
    case RANGE_OPERATOR:
      return value.map((e) => `${e[0]}~${e[1]}`).join(OR_SEPARATOR);
    case STARTS_WITH_OPERATOR:
      return value.map((e) => `${e}*`).join(OR_SEPARATOR);
    case ENDS_WITH_OPERATOR:
      return value.map((e) => `*${e}`).join(OR_SEPARATOR);
    case CONTAINS_OPERATOR:
      return value.map((e) => `*${e}*`).join(OR_SEPARATOR);
    case ON_OR_AFTER_OPERATOR:
      return value
        .map((e) => {
          const date = moment(e);
          if (!date.isValid()) {
            return null;
          }
          return `${date.startOf("day").format()}~`;
        })
        .filter((e) => !!e)
        .join(OR_SEPARATOR);
    case ON_OR_BEFORE_OPERATOR:
      return value
        .map((e) => {
          const date = moment(e);
          if (!date.isValid()) {
            return null;
          }
          return `~${date.endOf("day").format()}`;
        })
        .filter((e) => !!e)
        .join(OR_SEPARATOR);
    case ON_OPERATOR:
      return value
        .map((e) => {
          const date = moment(e);
          if (!date.isValid()) {
            return null;
          }
          return `${date.startOf("day").format()}~${date
            .endOf("day")
            .format()}`;
        })
        .filter((e) => !!e)
        .join(OR_SEPARATOR);
    case IS_EMPTY_OPERATOR:
      return ["null"];
    default:
      return null;
  }
};

const selectSeparator = (selection) => {
  switch (selection) {
    case AND_FILTER:
      return AND_SEPARATOR;
    case OR_FILTER:
      return OR_SEPARATOR;
    default:
      return null;
  }
};

export const createAndFilter = () => {
  return { linkOperator: AND_FILTER, items: [] };
};

export const createOrFilter = () => {
  return { linkOperator: OR_FILTER, items: [] };
};

export const setRangeOperation = (filters, fieldValuePairs, id) => {
  const entries = Object.entries(fieldValuePairs)
    .filter(([, value]) => Array.isArray(value))
    .map(([field, value]) => {
      if (Array.isArray(value[0])) {
        return {
          id,
          operatorValue: RANGE_OPERATOR,
          columnField: field,
          value: value,
        };
      }
      return {
        id,
        operatorValue: RANGE_OPERATOR,
        columnField: field,
        value: [value],
      };
    });
  const fieldNames = entries.map((e) => e.columnField);
  filters.items = [
    ...filters.items.filter(
      (e) => !fieldNames.some((f) => f === e.columnField)
    ),
    ...entries,
  ];
  return filters;
};

export const addRangeOperation = (filters, fieldValuePairs, id) => {
  const entries = Object.entries(fieldValuePairs)
    .filter(([, value]) => Array.isArray(value))
    .map(([field, value]) => {
      if (Array.isArray(value[0])) {
        return {
          id,
          operatorValue: RANGE_OPERATOR,
          columnField: field,
          value: value,
        };
      }
      return {
        id,
        operatorValue: RANGE_OPERATOR,
        columnField: field,
        value: [value],
      };
    });
  filters.items = [...filters.items, ...entries];
  return filters;
};

export const setLessThanEqualOperation = (filters, fieldValuePairs, id) => {
  const mappedEntries = Object.entries(fieldValuePairs).map(
    ([field, value]) => {
      return { [field]: [null, value] };
    }
  );
  const args = Object.assign({}, ...mappedEntries);
  return setRangeOperation(filters, args, id);
};

export const addLessThanEqualOperation = (filters, fieldValuePairs, id) => {
  const mappedEntries = Object.entries(fieldValuePairs).map(
    ([field, value]) => {
      return { [field]: [null, value] };
    }
  );
  const args = Object.assign({}, ...mappedEntries);
  return addRangeOperation(filters, args, id);
};

export const setGreaterThanEqualOperation = (filters, fieldValuePairs, id) => {
  const mappedEntries = Object.entries(fieldValuePairs).map(
    ([field, value]) => {
      return { [field]: [value, null] };
    }
  );
  const args = Object.assign({}, ...mappedEntries);
  return setRangeOperation(filters, args, id);
};

export const addGreaterThanEqualOperation = (filters, fieldValuePairs, id) => {
  const mappedEntries = Object.entries(fieldValuePairs).map(
    ([field, value]) => {
      return { [field]: [value, null] };
    }
  );
  const args = Object.assign({}, ...mappedEntries);
  return addRangeOperation(filters, args, id);
};

export const setEqualsOperation = (filters, fieldValuePairs, id) => {
  return setOperation(filters, fieldValuePairs, EQUALS_OPERATOR, id);
};

export const addEqualsOperation = (filters, fieldValuePairs, id) => {
  return addOperation(filters, fieldValuePairs, EQUALS_OPERATOR, id);
};

export const setStartsWithOperation = (filters, fieldValuePairs, id) => {
  return setOperation(filters, fieldValuePairs, STARTS_WITH_OPERATOR, id);
};

export const addStartsWithOperation = (filters, fieldValuePairs, id) => {
  return addOperation(filters, fieldValuePairs, STARTS_WITH_OPERATOR, id);
};

export const setEndsWithOperation = (filters, fieldValuePairs, id) => {
  return setOperation(filters, fieldValuePairs, ENDS_WITH_OPERATOR, id);
};

export const addEndsWithOperation = (filters, fieldValuePairs, id) => {
  return addOperation(filters, fieldValuePairs, ENDS_WITH_OPERATOR, id);
};

export const setContainsOperation = (filters, fieldValuePairs, id) => {
  return setOperation(filters, fieldValuePairs, CONTAINS_OPERATOR, id);
};

export const addContainsOperation = (filters, fieldValuePairs, id) => {
  return addOperation(filters, fieldValuePairs, CONTAINS_OPERATOR, id);
};

const setOperation = (filters, fieldValuePairs, operatorValue, id) => {
  const entries = Object.entries(fieldValuePairs)
    .filter(([, value]) => value !== undefined)
    .map(([field, value]) => {
      if (Array.isArray(value)) {
        return { id, operatorValue, columnField: field, value: value };
      }
      return { id, operatorValue, columnField: field, value: [value] };
    });
  const fieldNames = entries.map((e) => e.columnField);
  if (filters.items) {
    filters.items = [
      ...filters.items.filter(
        (e) => !fieldNames.some((f) => f === e.columnField)
      ),
      ...entries,
    ];
  } else {
    filters.items = [...entries];
  }
  return filters;
};

const addOperation = (filters, fieldValuePairs, operatorValue, id) => {
  const entries = Object.entries(fieldValuePairs)
    .filter(([, value]) => value !== undefined)
    .map(([field, value]) => {
      if (Array.isArray(value)) {
        return { id, operatorValue, columnField: field, value: value };
      }
      return { id, operatorValue, columnField: field, value: [value] };
    });
  filters.items = [...filters.items, ...entries];
  return filters;
};

export const removeOperationByField = (filters, field) => {
  filters.items = filters.items.filter((e) => e.columnField !== field);
  return filters;
};

export const removeOperationById = (filters, id) => {
  filters.items = filters.items.filter((e) => e.id !== id);
  return filters;
};

export const combineFilters = (filter1, filter2) => {
  if (!filter1) {
    return filter2;
  }
  if (!filter2) {
    return filter1;
  }

  const items = [...filter1.items, ...filter2.items];
  return { ...filter1, ...filter2, items };
};
