import React, { useState } from "react";
import { makeStyles } from 'tss-react/mui';
import { InputBase } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useRouter } from "@miview/hooks";

const useSearchBarStyles = makeStyles()((theme) => {
  return {
    search: {
      position: "relative",
      borderRadius: 8,
      backgroundColor: "white",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: `${theme.spacing(0, 3)}rem`,
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "black",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1rem + ${theme.spacing(8)}rem)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
      color: "black",
      fontSize: 14,
    },
  };
});

export const MiSearch = () => {
  const { classes } = useSearchBarStyles();
  const { push } = useRouter();
  const [searchText, setSearchText] = useState("");

  const submit = (e) => {
    e.preventDefault();
    const trimmedSearchText = searchText.trim();

    if (trimmedSearchText.length) {
      push({
        pathname: "/search",
        search: searchText,
      });
    }

    if (trimmedSearchText !== searchText) {
      setSearchText(trimmedSearchText);
    }
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <form onSubmit={submit}>
        <InputBase
          placeholder="Search"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onChange={(e) => setSearchText(e.target.value)}
          inputProps={{ "aria-label": "search" }}
          value={searchText}
        />
      </form>
    </div>
  );
};
