import { apiOptions, apiRequest } from "../utils";
import { API_V3 } from "../constants";

export const walkFailureTypeService = {
  getAll: async () => {
    const options = await apiOptions({
      url: `${API_V3}/walkfailuretypes`,
      method: "get",
    });

    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/walkfailuretypes`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/walkfailuretypes/${id}`,
      method: "patch",
      data: request,
    });

    return apiRequest(options);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/walkfailuretypes/${id}`,
      method: "delete",
    });

    return apiRequest(options);
  },
};
