import React from "react";
import moment from "moment";
import { MiButton } from "../index.ts";
import { THEME } from "@miview/theme";
import { daysArray } from "./constants";
import { useMonthCal } from "./hooks/useMonthCal";
import { mdiCalendarBlank, mdiMenuLeft, mdiMenuRight } from "@mdi/js";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  close: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: 350,
  },
  calendar: {
    padding: 5,
    backgroundColor: "white",
    borderRadius: 6,
    justifyContent: "flex-start",
    width: 300,
    height: 390,
    alignContent: "center",
    margin: 10,
  },
  monthBar: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  calendarRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 5,
    minHeight: 45,
    display: "flex",
  },
  cell: {
    backgroundColor: "#FFF",
    borderRadius: 50,
    width: 30,
    padding: 3,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  weekHeaderRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  weekHeaderCell: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "center",
  },
  today: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
};

export const MiDatePicker = (props) => {
  const { defaultDate, setDefaultDate } = props;
  const [calendarDate, calendarMonthDates, goToNextMonth, goToPrevMonth] =
    useMonthCal(defaultDate);

  const passDateToParent = (num) => {
    const now = moment(calendarDate).date(num);
    setDefaultDate(now.format());
  };

  const isToday = (date) => {
    const todaysDate = moment().format("MM DD YY");
    const givenDate = moment(calendarDate).date(date).format("MM DD YY");
    return todaysDate === givenDate;
  };

  const buildCalendarCell = (num, clickable) => {
    const date = moment(calendarDate).date(num).format("MM DD YY");
    const today = isToday(num) && clickable;

    let bg = {
      color: THEME.GREY_PRIMARY,
    };

    if (today) {
      bg.backgroundColor = THEME.GREY_LIGHT;
    }

    if (date === moment(defaultDate).format("MM DD YY") && clickable) {
      bg.backgroundColor = THEME.BLUE_PRIMARY;
      bg.color = "white";
    }

    if (!clickable) {
      bg.color = THEME.GREY_LIGHT;
    }

    return (
      <div
        key={"c" + num}
        style={{ ...styles.cell, backgroundColor: bg.backgroundColor }}
      >
        <option
          onClick={() => passDateToParent(num)}
          disabled={!clickable}
          style={{
            fontSize: 12,
            lineHeight: 1.2,
            fontWeight: "500",
            color: bg.color,
          }}
        >
          {num}
        </option>
      </div>
    );
  };

  const buildCalendarRow = (cells, idx) => {
    return (
      <div key={"r" + idx} style={styles.calendarRow}>
        {cells}
      </div>
    );
  };

  const renderCalendar = () => {
    const newCal = calendarMonthDates;
    let cells = [];
    const rows = [];

    const now = moment(calendarDate);
    const daysInMonth = now.daysInMonth();
    const day = now.startOf("month").day();

    // Calendar header
    for (let i = 0; i < daysArray.length; i++) {
      cells.push(
        <div key={"d" + i} style={styles.weekHeaderCell}>
          {daysArray[i]}
        </div>
      );
    }
    rows.push(
      <div key="dr0" style={styles.weekHeaderRow}>
        {cells}
      </div>
    );
    cells = [];

    // Calendar dates
    for (let i = 0; i < newCal.length; i++) {
      if (cells.length % 7 === 0 && i !== 0) {
        rows.push(buildCalendarRow(cells, i));
        cells = [];
      }
      // If the calendar cell is for a day in the current month, it should be clickable so that we can set the selected date
      const isClickable = i >= day && i < day + daysInMonth;
      cells.push(buildCalendarCell(newCal[i], isClickable));
    }
    rows.push(buildCalendarRow(cells, 8));

    return rows;
  };

  return (
    <div style={styles.calendar}>
      <div style={styles.monthBar}>
        <MiButton
          title=""
          icon={mdiMenuLeft}
          onClick={() => goToPrevMonth(calendarDate)}
          fontSize={20}
          style={{ borderWidth: 0 }}
        />
        <div>{moment(calendarDate).format("MMMM").toUpperCase()}</div>
        <MiButton
          title=""
          icon={mdiMenuRight}
          onClick={() => goToNextMonth(calendarDate)}
          fontSize={20}
          style={{ borderWidth: 0 }}
        />
      </div>
      {renderCalendar()}
      <div style={styles.today}>
        <MiButton
          title="Today"
          icon={mdiCalendarBlank}
          onClick={() => setDefaultDate(moment().format())}
          fontSize={15}
          style={{ borderWidth: 0 }}
        />
      </div>
    </div>
  );
};
