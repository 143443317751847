/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useDebounce } from "../useDebounce";
import {
  PAGINATION,
  OR_FILTER,
  CONTAINS_OPERATOR,
  HTTP_STATUSES,
} from "@miview/constants";

export const usePagination = (config) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(config.defaultPageSize || 50);
  const [pageCount, setPageCount] = useState(0);
  const [counts, setCounts] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState(null);
  const [filters, setFilters] = useState({ items: [] });
  const [sort, setSort] = useState(null);
  const debouncedSearch = useDebounce(search, 300);
  const debouncedFilters = useDebounce(filters, 300);
  const initialRender = useRef(true);
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    if (initialRender.current && config.mode === PAGINATION.CLIENT) {
      // Fetch data only on initial render for client-side pagination
      getPageData();
      initialRender.current = false;
    } else if (config.mode === PAGINATION.SERVER) {
      // Fetch data every time pagination params change
      getPageData();
    }

    return () => {
      abortControllerRef.current.abort();
    };
  }, [page, pageSize, sort, debouncedSearch, debouncedFilters]);

  const getPageData = async (shouldSetExtraData = true) => {
    if (!config.getPageData) {
      return;
    }

    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    const params = getParams();
    const response = await config.getPageData({
      params: params,
      filters: filters,
      sort: sort,
      signal: abortControllerRef.current.signal,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setPagination(response, shouldSetExtraData);
      config.setData(response.data);
    }
  };

  const getParams = () => {
    switch (config.mode) {
      case PAGINATION.SERVER:
        return {
          includeTotal: true,
          pageOffset: page,
          pageSize: pageSize,
          search: search,
        };
      case PAGINATION.CLIENT:
        return { pageSize: 9999 };
    }
  };

  const setPagination = (response, shouldSetExtraData) => {
    if (config.mode === PAGINATION.SERVER) {
      setPageCount(response.headers.totalpages);
      setRowCount(Number(response.headers.totalcount));
      if (shouldSetExtraData && response.headers.counts) {
        setCounts(JSON.parse(response.headers.counts));
      }
    }
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const onSortChange = (sortModel) => {
    setSort(sortModel);
    setPage(0);
  };

  const onSearchChange = (searchParam) => {
    if (config.mode === PAGINATION.CLIENT) {
      const newFilters = { items: [], linkOperator: OR_FILTER };
      if (searchParam) {
        config.searchColumns?.forEach((column) => {
          newFilters.items.push({
            columnField: column,
            operatorValue: CONTAINS_OPERATOR,
            value: searchParam,
          });
        });
      }
      setFilters(newFilters);
    }
    setSearch(searchParam);
    setPage(0);
  };

  const onFilterChange = (filter) => {
    setFilters(filter);
    setPage(0);
  };

  return {
    page,
    pageSize,
    rowCount,
    pageCount,
    counts,
    search,
    filters,
    sort,
    getPageData,
    onPageSizeChange,
    onPageChange,
    onSortChange,
    onSearchChange,
    onFilterChange,
  };
};
