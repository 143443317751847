import React, { useMemo, useCallback } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { en } from "@miview/constants";
import PropTypes from "prop-types";
import { MiAccordion } from "@miview/components";
import CartPreviewLayout, {
  CartHeader,
  CartHeaderButtons,
} from "../../components/VendorItemCost/CartComponents";

const useStyles = makeStyles()((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  orderItemsContainer: {
    width: "100%",
  },
  rowItemContainer: {
    padding: "10px 12px",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.accent.grey}`,
  },
  sectionTitle: {
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.medium,
    color: theme.palette.secondary.bluegrey,
  },
  rowDescription: {
    fontSize: theme.fontSize.xsmall,
    fontWeight: theme.fontWeight.regular,
    color: theme.palette.medium.bluegrey,
  },
  quantity: {
    fontSize: theme.fontSize.small,
    fontWeight: theme.fontWeight.regular,
    color: theme.palette.secondary.bluegrey,
    textAlign: "center",
  },
  itemPrice: {
    fontSize: theme.fontSize.small,
    fontWeight: theme.fontWeight.regular,
    color: theme.palette.secondary.bluegrey,
    textAlign: "right",
  },
  itemTotal: {
    fontSize: theme.fontSize.small,
    fontWeight: theme.fontWeight.medium,
    color: theme.palette.primary.main,
    textAlign: "right",
  },
  sectionTotalPrice: {
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.medium,
    color: theme.palette.dark.bluegrey,
  },
}));

const PurchaseOrderPreview = ({
  closeClick,
  discardClick,
  reviewPOClick,
  buttonTwoStyle,
  buttonTwoBg,
  buttonTwoColor,
  buttonOneStyle,
  buttonOneBg,
  buttonOneColor,
  data,
}) => {
  const poAggsData = data;
  const flattedPoData = [];
  for (let catIndex in poAggsData) {
    for (let itemsIndex in poAggsData[catIndex].data) {
      for (let accountsIndex in poAggsData[catIndex].data[itemsIndex]
        .accounts) {
        flattedPoData.push({
          categoryid: poAggsData[catIndex].categoryid,
          categoryname: poAggsData[catIndex].categoryname,
          itemid: poAggsData[catIndex].data[itemsIndex].itemid,
          itemname: poAggsData[catIndex].data[itemsIndex].itemname,
          itemdescription:
            poAggsData[catIndex].data[itemsIndex].itemdescription,
          itemNotes: poAggsData[catIndex].data[itemsIndex].itemNotes,
          itemUnit: poAggsData[catIndex].data[itemsIndex].itemUnit,
          itemSKU: poAggsData[catIndex].data[itemsIndex].itemSKU,
          quantity: poAggsData[catIndex].data[itemsIndex].quantity,
          onhand: poAggsData[catIndex].data[itemsIndex].onhand,
          incoming: poAggsData[catIndex].data[itemsIndex].incoming,
          acctitle:
            poAggsData[catIndex].data[itemsIndex].accounts[accountsIndex].title,
          accid:
            poAggsData[catIndex].data[itemsIndex].accounts[accountsIndex].accid,
          currentcost:
            poAggsData[catIndex].data[itemsIndex].accounts[accountsIndex]
              .currentcost,
          change:
            poAggsData[catIndex].data[itemsIndex].accounts[accountsIndex]
              .change,
          totalsum:
            poAggsData[catIndex].data[itemsIndex].accounts[accountsIndex]
              .totalsum,
          orderedqty:
            poAggsData[catIndex].data[itemsIndex].accounts[accountsIndex]
              .orderedqty,
          isPreferredVendor:
            poAggsData[catIndex].data[itemsIndex].accounts[accountsIndex]
              .isPreferredVendor,
        });
      }
    }
  }

  const groupByAnItem = (requisitions, accountTitle) => {
    return requisitions.reduce((acc, obj) => {
      const key = obj[accountTitle];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  const sumByAnItem = (requisitions, itemTitle, key) => {
    return requisitions[key].reduce((previous, current) => {
      return previous + current[itemTitle];
    }, 0);
  };

  const orderItems = (accounts, itemTitle) => {
    const groupedAccounts = groupByAnItem(accounts, itemTitle);
    const itemArray = [];
    const keysOfAccounts = Object.keys(groupedAccounts);
    keysOfAccounts.forEach((accountKey) => {
      itemArray.push({
        itemName: groupedAccounts[accountKey][0].itemname,
        description: groupedAccounts[accountKey][0].itemdescription,
        quantityOrdered: sumByAnItem(groupedAccounts, "orderedqty", accountKey),
        unitPrice: groupedAccounts[accountKey][0].currentcost,
        notes: groupedAccounts[accountKey][0].itemNotes,
        sku: groupedAccounts[accountKey][0].itemSKU,
        id: groupedAccounts[accountKey][0].itemid,
        unit: groupedAccounts[accountKey][0].itemUnit,
      });
    });
    return itemArray.filter((f) => f.quantityOrdered !== 0);
  };

  const poDataByAccountList = (poByAccData) => {
    const keysOfPOByAcc = Object.keys(poByAccData);
    const accountArray = [];
    keysOfPOByAcc.forEach((accountKey) => {
      accountArray.push({
        accid: poByAccData[accountKey][0].accid,
        acctitle: poByAccData[accountKey][0].acctitle,
        title: poByAccData[accountKey][0].acctitle,
        orderQty: sumByAnItem(poByAccData, "orderedqty", accountKey),
        orderTotal: sumByAnItem(poByAccData, "totalsum", accountKey),
        orderItems: orderItems(poByAccData[accountKey], "itemname"),
      });
    });
    return accountArray.filter((p) => p.orderQty !== 0);
  };

  const poByAcc = groupByAnItem(flattedPoData, "acctitle");
  const poDataByAccount = poDataByAccountList(poByAcc);
  const { classes } = useStyles();
  const theme = useTheme();
  const filterOptions = useMemo(() => {
    return [
      {
        title: en.reviewPo,
        clickable: true,
        click: () => reviewPOClick(poDataByAccount),
        style: buttonOneStyle,
        backgroundColor: buttonOneBg,
        color: buttonOneColor,
      },
      {
        title: en.discard,
        click: discardClick,
        clickable: true,
        backgroundColor: buttonTwoBg,
        color: buttonTwoColor,
        style: buttonTwoStyle,
      },
    ];
  }, [
    discardClick,
    reviewPOClick,
    poDataByAccount,
    buttonOneColor,
    buttonOneStyle,
    buttonTwoBg,
    buttonTwoColor,
    buttonTwoStyle,
    buttonOneBg,
  ]);

  const renderDetail = useCallback(
    (items) => {
      return (
        <Grid className={classes.orderItemsContainer}>
          {items.orderItems.map((row) => {
            return (
              <Grid
                container
                className={classes.rowItemContainer}
                key={row.itemName}
              >
                <Grid item xs={6}>
                  <Typography className={classes.sectionTitle}>
                    {row.itemName}
                  </Typography>
                  <Typography className={classes.rowDescription}>
                    {row.description}
                  </Typography>
                </Grid>
                <Grid className={classes.quantity} item xs={1}>
                  {row.quantityOrdered}
                </Grid>
                <Grid className={classes.itemPrice} item xs={2}>
                  {`$${row.unitPrice.toFixed(2)}`}
                </Grid>
                <Grid className={classes.itemTotal} item xs={3}>
                  {`$${(row.quantityOrdered * row.unitPrice).toFixed(2)}`}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      );
    },
    [classes]
  );

  const renderTitle = useCallback(
    (item) => {
      const orderTotal = item.orderItems.reduce(
        (previousValue, currentValue) => {
          return (
            previousValue +
            currentValue.quantityOrdered * currentValue.unitPrice
          );
        },
        0
      );
      return (
        <Grid className={classes.headerContainer}>
          <span className={classes.sectionTitle}>{item.title}</span>
          <span className={classes.sectionTotalPrice}>{`$${orderTotal}`}</span>
        </Grid>
      );
    },
    [classes]
  );

  return (
    <CartPreviewLayout>
      <CartHeader
        title={en.purchaseOrders}
        count={poDataByAccount?.length}
        closeClick={closeClick}
      />
      <CartHeaderButtons FilterArray={filterOptions} />
      <MiAccordion
        activeColor={theme.palette.light.grey}
        config={poDataByAccount}
        setActiveItem={() => undefined}
        renderDetail={renderDetail}
        renderTitle={renderTitle}
      />
    </CartPreviewLayout>
  );
};

PurchaseOrderPreview.propTypes = {
  closeClick: PropTypes.func,
  discardClick: PropTypes.func,
  reviewPOClick: PropTypes.func,
};

export default React.memo(PurchaseOrderPreview);
