import React from "react";
import {
  Suppliers,
  SupplierDetails,
  RequisitionsNewHomes,
  PreferredVendor,
  RequisitionsTemplates,
  RequisitionTemplateDetails,
  PurchasingOrder,
  QuoteDetails,
} from "../../views/Purchasing";
import Quotes from "../../views/Purchasing/screens/Accounts/Suppliers/Quotes";

export const purchasingRoutes = [
  {
    path: "/purchasing/suppliers",
    exact: true,
    name: "Suppliers",
    element: <Suppliers />,
  },
  {
    path: "/purchasing/suppliers/:id/:activeTab",
    name: "Supplier Details",
    element: <SupplierDetails />,
  },
  {
    path: "/purchasing/manufacturers",
    exact: true,
    name: "Manufacturers",
    element: <Suppliers />,
  },
  {
    path: "/purchasing/manufacturers/:id",
    name: "Manufacturer Details",
    element: <SupplierDetails />,
  },
  {
    path: "/purchasing/preferredvendor",
    exact: true,
    name: "Preferred Vendor",
    element: <PreferredVendor />,
  },
  {
    path: "/purchasing/requisitions/templates",
    exact: true,
    name: "Templates",
    element: <RequisitionsTemplates />,
  },
  {
    path: "purchasing/requisitions/templates/:id",
    name: "Template Details",
    element: <RequisitionTemplateDetails />,
  },
  {
    path: "/purchasing/requisitions/newhomes",
    exact: true,
    name: "New Homes",
    element: <RequisitionsNewHomes />,
  },
  {
    path: "/purchasing/purchaseorders/newhomes",
    exact: true,
    name: "New Homes",
    element: <PurchasingOrder />,
  },
  {
    path: "/purchasing/quotes",
    exact: true,
    name: "Quotes",
    element: <Quotes />,
  },
  {
    path: "/purchasing/quotes/:id",
    name: "Quote Details",
    element: <QuoteDetails />,
  },
];
