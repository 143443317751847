import React, { useState, useEffect } from "react";
import {
  MiModal,
  MiDetailFields,
  MiIcon,
  MiIconButton,
} from "@miview/components";
import {
  DEFAULT_TIMEZONE,
  HTTP_STATUSES,
  PAY_TYPE_OPTIONS,
  TOAST_TYPES,
  PERMISSIONS,
} from "@miview/constants";
import {
  userService,
  accountServiceLegacy as accountService,
  timezoneService,
  roleService,
  uiService,
} from "@miview/api";
import {
  toList,
  createUUID,
  isValidEmail,
  isPasswordLength,
} from "@miview/utils";
import { useTheme, InputAdornment } from "@mui/material";
import { useEdit, useComponentState, usePermissions } from "@miview/hooks";
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import { createToast } from "@miview/toast";

const getRolesUuid = createUUID();
const uuid = createUUID();
const MIN_CHARACTERS = 6;
const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  personalEmail: "",
  password: "",
  titleId: "",
  billingAccount: "",
  cell: "",
  home: "",
  work: "",
  roleIds: [],
  timezone: "",
  language: "",
};

const CreateNewUserModal = (props) => {
  const { redirectToEdit, closeModal, openModal } = props;

  const [validPassword, setValidPassword] = useState(false);
  const [titles, setTitles] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [roles, setRoles] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const theme = useTheme();
  const edit = useEdit(INITIAL_STATE);
  const stateManager = useComponentState();
  const permissions = usePermissions();

  useEffect(() => {
    const accountId = localStorage.getItem("accountId");
    getTitles(accountId);
    getAccounts();
    getTimezones();
    getRoles();
    getLanguageOptions();
  }, []);

  const getTitles = async (accountId) => {
    const response = await accountService.getTitles({
      id: accountId,
      params: { pageSize: 9999 },
      sort: [{ field: "name", sort: "asc" }],
    });

    if (response.status === HTTP_STATUSES.OK) {
      const titles = toList(response.data, "id", "name");
      setTitles(titles);
    }
  };

  const getAccounts = async () => {
    const response = await accountService.getAll();
    if (response.status === HTTP_STATUSES.OK) {
      const accounts = toList(response.data, "id", "name");
      setAccounts(accounts);
    }
  };

  const getRoles = () => {
    stateManager.abort(getRolesUuid);
    stateManager.run(async (signal) => {
      const response = await roleService.getAll({
        params: { pageSize: 9999 },
        signal,
      });
      if (response.status === HTTP_STATUSES.OK) {
        const mappedRoles = response.data.map(({ id, roleName }) => {
          return {
            value: id,
            label: roleName,
          };
        });
        setRoles(mappedRoles);
      }
    }, getRolesUuid);
  };

  const getLanguageOptions = async () => {
    if (
      !permissions.hasPermission(PERMISSIONS.CAN_CREATE_USER) &&
      roles.length
    ) {
      createToast(
        "You do not have permission to create users.",
        TOAST_TYPES.ERROR
      );
      return;
    }
    const response = await uiService.getLanguages();
    if (response.status === HTTP_STATUSES.OK) {
      const mapped = toList(response.data, "code", "name");
      setLanguageOptions(mapped);
      edit.update({
        timezone: DEFAULT_TIMEZONE,
        language: mapped[0].value,
      });
    }
  };

  const createUser = () => {
    const user = { ...edit.edits };
    stateManager.abort(uuid);
    stateManager.run(async (signal) => {
      const response = await userService.create(user, signal);
      if (response.status === HTTP_STATUSES.OK) {
        redirectToEdit(response.data);
        edit.reset();
      }
    }, uuid);
  };

  const handleChange = (value) => {
    edit.update({ password: value });
    setValidPassword(value.length >= MIN_CHARACTERS);
  };

  const getTimezones = async () => {
    const response = await timezoneService.getAll();
    if (response.status === HTTP_STATUSES.OK) {
      const mapped = response.data.map((e) => {
        return { value: e, text: e };
      });
      setTimezones(mapped);
    }
  };

  const isValid = () => {
    return (
      edit.allFilled("firstName", "lastName", "titleId", "accountId") &&
      validPassword &&
      isValidEmail(edit.getValue("email")) &&
      isValidEmail(edit.getValue("personalEmail"))
    );
  };

  const emailErrorMsg = (email) => {
    if (!isValidEmail(email)) {
      return "You must enter an email address.";
    }
    return null;
  };

  const passwordErrorMsg = (password) => {
    if (!isPasswordLength(password)) {
      return "Password must have at least 6 characters.";
    }
    return null;
  };

  const handleError = (field) => {
    const value = edit.getValue(field);
    if (["email", "personalEmail"].includes(field) && value.length) {
      return emailErrorMsg(value);
    }
    if (field === "password" && value.length) {
      return passwordErrorMsg(value);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleCloseModal = () => {
    closeModal();
    edit.reset();
  };

  const userModalFields = [
    {
      label: "User Name",
      placeholder: "User Email",
      required: true,
      value: edit.getValue("email"),
      setValue: (e) => edit.update({ email: e.trim() }),
      error: handleError("email"),
      helperText: handleError("email"),
      width: "50%",
    },
    {
      label: "Email Address",
      type: "email",
      placeholder: "Personal Email",
      required: true,
      value: edit.getValue("personalEmail"),
      setValue: (e) => edit.update({ personalEmail: e.trim() }),
      error: handleError("personalEmail"),
      helperText: handleError("personalEmail"),
      width: "50%",
    },
    {
      label: "Password",
      value: edit.getValue("password"),
      fieldType: showPassword ? "text" : "password",
      name: "password",
      required: true,
      setValue: (e) => handleChange(e),
      error: handleError("password"),
      helperText: handleError("password"),
      width: "50%",
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <MiIconButton
              onClick={toggleShowPassword}
              onMouseDown={(e) => e.preventDefault()}
              style={{ padding: 0 }}
            >
              <MiIcon
                path={showPassword ? mdiEyeOutline : mdiEyeOffOutline}
                size={0.8}
              />
            </MiIconButton>
          </InputAdornment>
        ),
      },
    },
    {
      label: "First Name",
      required: true,
      value: edit.getValue("firstName"),
      setValue: (e) => edit.update({ firstName: e }),
      width: "50%",
    },
    {
      label: "Last Name",
      required: true,
      value: edit.getValue("lastName"),
      setValue: (e) => edit.update({ lastName: e }),
      width: "50%",
    },
    {
      label: "Cell",
      value: edit.getValue("cell"),
      setValue: (e) => edit.update({ cell: e }),
      width: "50%",
    },
    {
      label: "Work",
      value: edit.getValue("work"),
      setValue: (e) => edit.update({ work: e }),
      width: "50%",
    },
    {
      label: "Title",
      required: true,
      fieldType: "select",
      value: edit.getValue("titleId"),
      options: titles,
      setValue: (e) => edit.update({ titleId: e }),
      width: "50%",
    },
    {
      label: "Home",
      value: edit.getValue("home"),
      setValue: (e) => edit.update({ home: e }),
      width: "50%",
    },
    {
      label: "Billing Account",
      value: edit.getValue("billingAccount"),
      setValue: (e) => edit.update({ billingAccount: e }),
      width: "50%",
    },
    {
      label: "Pay Type",
      fieldType: "select",
      field: "payType",
      value: edit.getValue("payType"),
      options: PAY_TYPE_OPTIONS,
      selectNone: true,
      width: "50%",
      setValue: (e) => edit.update({ payType: e }),
    },
    {
      label: "Timezone",
      fieldType: "select",
      value: edit.getValue("timezone"),
      options: timezones,
      setValue: (e) => edit.update({ timezone: e }),
      width: "50%",
    },
    {
      label: "Language",
      fieldType: "select",
      value: edit.getValue("language"),
      options: languageOptions,
      setValue: (e) => edit.update({ language: e }),
      width: "50%",
      required: true,
    },
    {
      label: "Assigned Roles",
      fieldType: "multiSelect",
      value: edit.getValue("roleIds"),
      options: roles,
      setValue: (e) => edit.update({ roleIds: e.target.value }),
      width: "50%",
    },
    {
      label: "Account ID",
      fieldType: "select",
      required: true,
      value: edit.getValue("accountId"),
      options: accounts,
      setValue: (e) => edit.update({ accountId: e }),
      width: "50%",
    },
  ];

  return (
    <MiModal
      title="Add User"
      open={openModal}
      onClose={handleCloseModal}
      actions={[
        {
          name: "Cancel",
          onClick: handleCloseModal,
          color: theme.palette.medium.grey,
          inverse: false,
        },
        {
          name: "Save",
          onClick: createUser,
          color: theme.palette.primary.main,
          inverse: true,
          disabled: !isValid(),
        },
      ]}
    >
      <input
        type="password"
        name="dummy-chrome-autofill-taker"
        defaultValue={edit.getValue("password")}
        style={{ scale: 0, position: "absolute" }}
      />
      <MiDetailFields detailFields={userModalFields} />
    </MiModal>
  );
};

export default CreateNewUserModal;
