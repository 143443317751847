import moment from "moment";

export const getDatesForMonth = (date) => {
  const cal = [];
  const now = moment(date);
  const numDays = now.daysInMonth();
  const day = now.startOf("month").day();

  const daysInPrevMonth = now.subtract(1, "M").daysInMonth();
  for (let i = daysInPrevMonth - day + 1; i <= daysInPrevMonth; i++) {
    cal.push(i);
  }

  for (let i = 1; i <= numDays; i++) {
    cal.push(i);
  }

  for (let i = 1; i <= 42 - numDays - day; i++) {
    cal.push(i);
  }

  return cal;
};

export const nextMonth = (date) => {
  const month = moment(date).add(1, "M");

  return {
    nextDate: month.format(),
    nextDates: getDatesForMonth(month.format()),
  };
};

export const prevMonth = (date) => {
  const month = moment(date).subtract(1, "M");

  return {
    prevDate: month.format(),
    prevDates: getDatesForMonth(month.format()),
  };
};
