import React from "react";
import { MiModal, MiDetailFields } from "@miview/components";
import { useTheme } from "@mui/material";
import { useComponentState, useEdit } from "@miview/hooks";
import { walkService } from "@miview/api";
import { SCHEDULED, HTTP_STATUSES } from "@miview/constants";
import { getNextBusinessDay } from "@miview/utils";
import moment from "moment";

const nextBusinessDay = getNextBusinessDay(moment());

const ScheduleModal = ({ onClose, open, walkId, onSubmit = () => null }) => {
  const theme = useTheme();
  const stateManager = useComponentState();
  const initialState = { scheduledStartDate: nextBusinessDay };
  const edit = useEdit(initialState);

  const onModalClose = () => {
    edit.reset();
    onClose();
  };

  const scheduleInspection = () => {
    stateManager.run(async () => {
      const response = await walkService.update(walkId, {
        ...initialState,
        ...edit.edits,
        status: SCHEDULED,
      });
      if (response.status === HTTP_STATUSES.OK) {
        onSubmit();
        onModalClose();
      }
    });
  };

  const scheduleFields = [
    {
      label: "Scheduled Date",
      value: edit.getValue("scheduledStartDate"),
      required: true,
      setValue: (e) => edit.update({ scheduledStartDate: e }),
      width: "100%",
      fieldType: "date",
      dateType: "date",
      inputFormat: "MM/DD/YYYY",
      dateFormat: "YYYY-MM-DDThh:mm:ss",
    },
  ];

  return (
    <MiModal
      title="Schedule"
      open={open}
      onClose={onModalClose}
      actions={[
        {
          name: "Cancel",
          style: { minWidth: 90 },
          onClick: onModalClose,
          color: theme.palette.primary.black,
          inverse: false,
        },
        {
          name: "Submit",
          onClick: scheduleInspection,
          style: { minWidth: 90 },
          color: theme.palette.secondary.green,
          disabled:
            !edit.allFilled("scheduledStartDate") || stateManager.isBusy(),
          inverse: true,
        },
      ]}
      loading={stateManager.isBusy()}
    >
      <MiDetailFields detailFields={scheduleFields} />
    </MiModal>
  );
};

export default ScheduleModal;
