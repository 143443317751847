import React from "react";
import { LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, { progressColor, backgroundColor }) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: "94%",
  },
  colorPrimary: {
    backgroundColor: backgroundColor || theme.palette.lightAccent.grey,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: progressColor,
  },
  progressText: {
    fontSize: theme.fontSize.small,
    fontWeight: theme.fontWeight.medium,
    marginRight: 5,
    marginLeft: 5,
  },
}));

export const MiProgressBar = (props) => {
  const {
    value,
    width,
    progressColor,
    backgroundColor,
    showProgressValue,
    ...rest
  } = props;
  const { progressText, ...progressBarClasses } = useStyles({
    progressColor,
    backgroundColor,
  });
  return (
    <div
      style={{
        width: width || "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {showProgressValue && (
        <Typography className={progressText}>0%</Typography>
      )}
      <LinearProgress
        variant="determinate"
        value={value}
        classes={progressBarClasses}
        {...rest}
      />
      {showProgressValue && (
        <Typography className={progressText}>{value}%</Typography>
      )}
    </div>
  );
};
