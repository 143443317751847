export const parseFloatString = (str) => {
  var float = 0,
    sign,
    mantissa,
    exp,
    int = 0,
    multi = 1;
  if (/^0x/.exec(str)) {
    int = parseInt(str, 16);
  } else {
    for (var i = str.length - 1; i >= 0; i -= 1) {
      if (str.charCodeAt(i) > 255) {
        console.log("Wrong string parameter");
        return false;
      }
      int += str.charCodeAt(i) * multi;
      multi *= 256;
    }
  }
  sign = int >>> 31 ? -1 : 1;
  exp = ((int >>> 23) & 0xff) - 127;
  mantissa = ((int & 0x7fffff) + 0x800000).toString(2);
  for (i = 0; i < mantissa.length; i += 1) {
    float += parseInt(mantissa[i]) ? Math.pow(2, exp) : 0;
    exp--;
  }
  return float * sign;
};

export const convertMToFt = (m) => {
  //inche to mm 25.4 to m 0.0254
  if (m && m !== 0) {
    //get feet by inches
    let ft = convertToFt(m);
    const converted = convertFtDecimalToFtInches(ft);
    let feet = converted.feet > 0 ? converted.feet + `' ` : "";
    let inches = converted.inches > 0 ? converted.inches : "";
    let fraction =
      converted.fraction === 0 && converted.inches === 0
        ? ``
        : converted.fraction === 0
        ? `"`
        : " " + converted.fraction + `"`;
    let results = feet + inches + fraction;
    return results;
  } else {
    return null;
  }
};

export const convertFtToMM = (ft) => {
  return ft * 304.8;
};

export const convertFtToM = (ft) => {
  return ft / 3.2808;
};

export const convertInchesToFt = (inch) => {
  const inches = inch || 0;
  return inches / 12;
};

export const convertMToMM = (m) => {
  return m * 1000;
};

export const convertToFt = (m) => {
  return m / 0.0254 / 12;
};

export const convertFtDecimalToFtInches = (val) => {
  let denominator = 4;
  let feet = Math.floor(val);
  let inchesDecimal = (val - feet) * 12;
  let inches = Math.floor(inchesDecimal);
  let remainder = inchesDecimal - inches;
  let numerator = Math.round(remainder * 4);

  switch (numerator) {
    case 0:
      numerator = 0;
      break;
    case 2:
      numerator = 1;
      denominator = 2;
      break;
    case 4:
      numerator = 0;
      inches = inches + 1;
      break;
    default:
      break;
  }
  if (inches === 12) {
    feet++;
    inches = 0;
  }
  let fraction = 0;
  if (numerator > 0) {
    fraction = numerator + "/" + denominator;
  }
  return { feet: feet, inches: inches, fraction: fraction };
};

export const convertByteArrToHex = (arr) => {
  let hex =
    "0x" +
    arr
      .reverse()
      .map((item) => ("00" + item.toString(16)).slice(-2))
      .join("");
  return hex;
};

export const convertDate = (date) => {
  if (date) {
    return date.toISOString();
  }
};

export const convertRadianToDeg = (rad) => {
  return rad * (180 / Math.PI);
};

export const convertMeasurementFromRad = (length, rad) => {
  const a = length * Math.sin(rad);
  return Math.sqrt(length ** 2 - a ** 2);
};

export const converToBlob = (b64Data, contentType, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
