import React from "react";
import { MiCheckbox } from "../index.ts";

export const MiTableCheckBox = (props) => {
  const { row, getRowId, isEnabled, selectedRowIds = [], handleChange } = props;
  let { id } = row;
  if (getRowId) {
    id = getRowId(row);
  }

  const isChecked = () => {
    return selectedRowIds.includes(id);
  };

  const onChange = (e) => {
    if (!handleChange) {
      return;
    }
    const ids = new Set(selectedRowIds);
    const checked = e.target.checked;
    if (checked) {
      ids.add(id);
    } else {
      ids.delete(id);
    }
    handleChange(Array.from(ids));
  };

  const renderCheckbox = () => {
    let enabled = true;

    if (isEnabled) {
      enabled = isEnabled(row);
    }

    if (!enabled) {
      return;
    }

    return <MiCheckbox checked={isChecked(row)} onChange={onChange} />;
  };

  return <>{renderCheckbox()}</>;
};
