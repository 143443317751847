import React, { useEffect, useState } from "react";
import { MiAutocomplete, MiIcon } from "@miview/components";
import { useComponentState } from "@miview/hooks";
import { walkService } from "@miview/api";
import { HTTP_STATUSES, WALK_STATUSES_FINAL } from "@miview/constants";
import { useTheme, styled } from "@mui/material/styles";
import { mdiCircleMedium } from "@mdi/js";

const StyleDiv = styled("div")(() => {
  return {
    fontSize: "10px",
    fontStyle: "italic",
  };
});

export const InspectionPrepAssignees = ({ id, walk, handleSelectList }) => {
  const theme = useTheme();
  const [assignees, setAssignees] = useState([]);
  const [originalCrews, setOriginalCrew] = useState([]);
  const stateManager = useComponentState();

  useEffect(() => {
    const originals = walk.property?.jobs
      ?.filter(
        (x) => x.crew !== "" && x.walkTypeId === walk.autoAssignFromWalkTypeId
      )
      .map((item) => item.assignedUserId);

    if (originals) {
      setOriginalCrew(originals);
    }
  }, [walk]);

  const getAssignees = () => {
    stateManager.run(async () => {
      const allCrewResponse = await walkService.getAssignableUsers(id);
      if (allCrewResponse.status === HTTP_STATUSES.OK) {
        const assignees = allCrewResponse.data.sort(
          (a, b) =>
            b.isOriginalCrew - a.isOriginalCrew ||
            b.isCommunityContact - a.isCommunityContact ||
            a.name.localeCompare(b.name)
        );

        setAssignees(assignees);
      }
    });
  };

  const handleSelect = (e) => {
    return handleSelectList(e, id);
  };

  const renderOption = (props, option) => {
    const containerStyles = {
      display: "flex",
      fontSize: "12px",
    };
    if (option) {
      return (
        <div style={containerStyles} {...props}>
          {renderIcon(option.isOriginalCrew)}
          <div>
            <div>
              <div>{option.name}</div>
            </div>
            <></>
            <StyleDiv>
              {option.isCommunityContact ? (
                <span style={{ fontWeight: "bold" }}>Community </span>
              ) : null}
              {option.titleName || ""}
            </StyleDiv>
          </div>
        </div>
      );
    }
    if (!option) {
      return <div>{option}</div>;
    }
  };

  const renderIcon = (iconId) => {
    return (
      <MiIcon path={mdiCircleMedium} size={1} color={renderIconColor(iconId)} />
    );
  };

  const renderIconColor = (isOriginal) => {
    if (isOriginal) {
      return theme.palette.secondary.grey;
    }
    return theme.palette.primary.orange;
  };

  const renderColorIntial = (userId) => {
    if (!userId) {
      return;
    }

    const isOriginal = originalCrews.includes(userId);
    return renderIcon(isOriginal);
  };

  const handleChange = () => {
    getAssignees();
  };

  return (
    <>
      <MiAutocomplete
        onKeyDown={(e) => e.stopPropagation()}
        style={{ width: "100%" }}
        defaultValue={walk.assignedUserName || ""}
        getOptionLabel={(user) => {
          return typeof user === "string" ? user : user.name;
        }}
        onFocus={handleChange}
        getOptionSelected={(option, value) => option.name === value.name}
        onSelectionChange={(_, value) => handleSelect(value)}
        options={assignees}
        autoSelect={false}
        variant="standard"
        minWidth="0"
        disabled={WALK_STATUSES_FINAL.includes(walk.status)}
        InputProps={{
          style: { fontSize: 14 },
          startAdornment: renderColorIntial(walk.assignedUserId),
          disableUnderline: true,
        }}
        freeSolo={false}
        renderOption={renderOption}
      />
    </>
  );
};
