export const JSON_DEFAULT = {
  menu: {
    header: "SVG Viewer",
    items: [
      { id: "Open" },
      { id: "OpenNew", label: "Open New" },
      null,
      { id: "ZoomIn", label: "Zoom In" },
      { id: "ZoomOut", label: "Zoom Out" },
      { id: "OriginalView", label: "Original View" },
      null,
      { id: "Quality" },
      { id: "Pause" },
      { id: "Mute" },
      null,
      { id: "Find", label: "Find..." },
      { id: "FindAgain", label: "Find Again" },
      { id: "Copy" },
      { id: "CopyAgain", label: "Copy Again" },
      { id: "CopySVG", label: "Copy SVG" },
      { id: "ViewSVG", label: "View SVG" },
      { id: "ViewSource", label: "View Source" },
      { id: "SaveAs", label: "Save As" },
      null,
      { id: "Help" },
      { id: "About", label: "About Adobe CVG Viewer..." },
    ],
  },
};

export const DaysOptions = [
  { value: "sunday", label: "S" },
  { value: "monday", label: "M" },
  { value: "tuesday", label: "T" },
  { value: "wednesday", label: "W" },
  { value: "thursday", label: "T" },
  { value: "friday", label: "F" },
  { value: "saturday", label: "S" },
];
