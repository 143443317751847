import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const API_PROPERTIES_V3 = `${API_V3}/properties`;
export const API_MESSAGES = `/messages`;
export const API_CONTACTS = `/contacts`;

export const propertyService = {
  getAll: async ({ filters, sort, params, signal }) => {
    const options = await apiOptions({
      url: API_PROPERTIES_V3,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (id, signal) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}`,
      signal,
    });
    return apiRequest(options);
  },

  getRecent: async ({ filters, sort, params, signal }) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/recent`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  getContacts: async (propertyId, signal) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${propertyId}${API_CONTACTS}`,
      signal,
    });
    return apiRequest(options);
  },

  deletePropertyContact: async ({ propertyId, personId, signal }) => {
    const options = await apiOptions({
      method: "delete",
      url: `${API_PROPERTIES_V3}/${propertyId}${API_CONTACTS}/${personId}`,
      signal,
    });
    return apiRequest(options);
  },

  updatePropertyContact: async ({ propertyId, personId, data, signal }) => {
    const options = await apiOptions({
      method: "patch",
      url: `${API_PROPERTIES_V3}/${propertyId}${API_CONTACTS}/${personId}`,
      data,
      signal,
    });

    return apiRequest(options);
  },

  getSetupStatuses: async () => {
    const options = await apiOptions({
      url: `${API_V3}/propertySetupStatuses`,
    });
    return apiRequest(options);
  },

  validateAddress: async (addressObj) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/address/validateaddress`,
      method: "post",
      data: addressObj,
    });
    return apiRequest(options);
  },

  checkDuplicateAddress: async (addressObj) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/checkDuplicate`,
      method: "post",
      data: addressObj,
    });
    return apiRequest(options);
  },

  createNewProperty: async (address) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}`,
      method: "post",
      data: address,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving property",
      },
      success: {
        message: "Property saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  getDrawings: async (id, signal) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/drawings`,
      signal,
    });
    return apiRequest(options);
  },

  getStages: async ({ id, params, filter, signal }) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/stages`,
      params,
      filter,
      signal,
    });
    return apiRequest(options);
  },

  updatePropertySetupStatus: async (propertyId, value) => {
    const options = await apiOptions({
      method: "post",
      url: `${API_PROPERTIES_V3}/${propertyId}/setupstatus`,
      data: value,
    });
    return apiRequest(options);
  },

  updateProperty: async (propertyId, fields, reassignWalks = false) => {
    const options = await apiOptions({
      method: "patch",
      url: `${API_PROPERTIES_V3}/${propertyId}`,
      data: fields,
      params: { reassignWalks },
    });
    const toastMessageConfig = {
      success: {
        message: "Property saved successfully",
      },
    };

    return apiRequest(options, toastMessageConfig);
  },

  getIssues: async ({ id, params, filters, signal }) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/issues`,
      params,
      filters,
      signal,
    });
    return apiRequest(options);
  },

  importCad: async (id, request) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/importcad`,
      method: "post",
      data: request,
    });
    const toastMessageConfig = {
      success: {
        message: "Cad images imported successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  importStacks: async (id, stacksFile) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/stacks`,
      method: "post",
      data: { file: stacksFile },
    });
    return apiRequest(options);
  },

  importRough: async (id, request) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/copyStacks`,
      method: "post",
      data: request,
    });
    const toastMessageConfig = {
      failure: {
        hideMessage: true,
      },
      success: {
        message: "Rough details imported successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  getPreview: async (id) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/preview`,
      method: "get",
    });
    return apiRequest(options);
  },

  createPdf: async (id, params) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/homespecpdf`,
      method: "post",
      params,
    });
    return apiRequest(options);
  },

  getOptions: async (id) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/options`,
      method: "get",
    });
    return apiRequest(options);
  },

  publish: async (id, request) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/publish`,
      method: "post",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure publishing property",
      },
      success: {
        message: "Property published successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  deleteArea: async (id, areaId) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/areas/${areaId}`,
      method: "delete",
    });
    return apiRequest(options);
  },

  restoreArea: async (id, areaId) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/areas/${areaId}/restore`,
      method: "post",
    });
    return apiRequest(options);
  },

  revert: async (id, request) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/revert`,
      method: "post",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure reverting property",
      },
      success: {
        message: "Property reverted successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  updateArea: async (id, areaId, request) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/areas/${areaId}`,
      method: "post",
      data: request,
    });
    return apiRequest(options);
  },

  getAreas: async (id) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/areas`,
      method: "get",
    });
    return apiRequest(options);
  },

  getActivities: async (id, params) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/activities`,
      method: "get",
      params,
    });
    return apiRequest(options);
  },

  recalculateWorkPay: async (id) => {
    const options = await apiOptions({
      url: `${API_PROPERTIES_V3}/${id}/recalculatePay`,
      method: "post",
    });
    return apiRequest(options);
  },
};
