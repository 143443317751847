import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import en from "../../../../../locale/en.json";
import { HTTP_STATUSES } from "@miview/constants";
import { createFilter } from "@miview/utils";
import { accountService } from "@miview/api";
import { MiContactCard } from "@miview/components";

const useStyles = makeStyles()((theme) => ({
  contactsHeader: {
    padding: "15px 0px 15px 60px",
    color: theme.palette.dark.teal,
    fontWeight: theme.fontWeight.bold,
  },
  contactsDetails: {
    marginLeft: 60,
  },
}));

const SupplierBranchContactTable = ({ accountId, handleSetHeights }) => {
  const [contacts, setContacts] = useState([]);
  const { classes } = useStyles();

  useEffect(() => {
    getContacts();
  }, []);

  const getContacts = async () => {
    const response = await accountService.contacts.getAll({
      filters: createFilter().equals({
        accountId: accountId,
      }),
    });
    if (response.status === HTTP_STATUSES.OK) {
      setContacts(response.data);
      handleSetHeights(accountId, response.data.length * 50 + 50);
    }
  };

  return (
    <>
      <Typography className={classes.contactsHeader}>
        {en.branchContacts}
      </Typography>
      <div className={classes.contactsDetails}>
        {contacts.map((contact, i) => {
          return <MiContactCard contact={contact} key={i} />;
        })}
      </div>
    </>
  );
};

export default React.memo(SupplierBranchContactTable);
