import React, { useState, Fragment, createElement, useEffect } from "react";
import { useRouter } from "@miview/hooks";
import { makeStyles } from "tss-react/mui";
import {
  Collapse,
  Grid,
  Drawer,
  List,
  CssBaseline,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import * as Icons from "@mdi/js";
import { MiButton, MiIcon } from "../index.ts";
import { HeaderLogo } from "../Navigation/HeaderLogo";
import { MiTHEME } from "@miview/theme";
import { APP_BAR_HEIGHT, URLS } from "@miview/constants";
import { Link } from "react-router-dom";
import { buildNavBasedOnPermissions } from "@miview/utils";
import { mdiCogOutline, mdiChevronRight, mdiChevronDown } from "@mdi/js";

const useNavItemStyles = makeStyles()((theme, { props }) => ({
  indent: {
    marginLeft: 30,
  },
  listItem: {
    marginLeft: props.open ? 5 : 0,
    width: 220,
    borderRadius: 8,
    backgroundColor:
      props.focused || (!props.open && props.hasAChildFocused)
        ? theme.palette.primary.light
        : "#FFF",
    color:
      props.focused || props.hasAChildFocused
        ? theme.palette.primary.main
        : "grey",

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
  },
  listItemText: {
    fontSize: theme.fontSize.medium,
  },
  arrowIcon: {
    minWidth: 0,
    color: theme.palette.accent.main,
  },
  linkStyles: {
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const getNavChildItemStyles = makeStyles()((theme, { props }) => ({
  listItemChild: {
    marginLeft: 5,
    borderLeft: "2px solid #DDD",
    width: 220,
    borderRadius: "0 8px 8px 0",
    borderLeftColor:
      props.focused && !props.open
        ? theme.palette.primary.main
        : theme.palette.lightAccent.grey,
    backgroundColor:
      props.focused && !props.open ? theme.palette.primary.light : "inherit",
    color: props.focused && !props.open ? theme.palette.primary.main : "grey",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main,
      },
    },
  },
  listItemText: {
    fontSize: theme.fontSize.medium,
  },
}));

const useStyles = makeStyles()((theme, { props }) => ({
  root: {
    height: "100%",
  },
  hide: {
    visibility: "hidden",
  },
  drawer: {
    width: "5%",
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "100%",
    marginTop: Object.values(URLS).includes(global.baseUrl)
      ? 85
      : APP_BAR_HEIGHT,
    boxShadow: "0 3px 0 0 rgba(0,0,0,0.2)",
  },
  drawerOpen: {
    marginTop: Object.values(URLS).includes(global.baseUrl)
      ? 85
      : APP_BAR_HEIGHT,
    width: props.drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      zIndex: 1300,
    },
    left: "auto",
    overflowX: "hidden",
  },
  drawerClose: {
    marginTop: Object.values(URLS).includes(global.baseUrl)
      ? 85
      : APP_BAR_HEIGHT,
    width: "55px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "0px",
    },
    overflowX: "hidden",
  },
  menuButton: {
    marginLeft: "-5px",
  },
  logoSmall: {
    position: "fixed",
    left: 0,
    top: 0,
    backgroundColor: "white",
  },
  bottomApps: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    paddingBottom: props.apps.length * 20,
    marginBottom: 30,
  },
}));

const isPathMatched = (path, url) => {
  return path && path.startsWith(url);
};

const NavItem = ({ item, open, handleDrawer, router, isNavIcon }) => {
  const [collapsed, setCollapsed] = useState(true);
  const hasAChildFocused = item.children?.some((child) =>
    isPathMatched(router.pathname, child.url)
  );

  useEffect(() => {
    if (!open) {
      setCollapsed(true);
    } else if (open && hasAChildFocused) {
      setCollapsed(false);
    }
  }, [open, hasAChildFocused]);

  let focused = isPathMatched(router.pathname, item.url);

  const { classes: classesNav } = useNavItemStyles({
    props: {
      focused: focused,
      hasAChildFocused: hasAChildFocused,
      open,
    },
  });

  const handleItemClick = (row) => {
    if (window.innerWidth <= MiTHEME.breakpoint.sm) {
      if (row.children) {
        setCollapsed(!collapsed);
        handleDrawer(true);
        return;
      }
      setCollapsed(false);
      handleDrawer(false);
      return;
    }
    if (row.children) {
      setCollapsed(!collapsed);
      handleDrawer(true);
    }
  };

  const renderChildItem = (child) => {
    let isChildItemFocused = isPathMatched(router.pathname, child.url);
    const { classes: childClassesNav } = getNavChildItemStyles({
      props: {
        focused: isChildItemFocused,
      },
    });
    return (
      <Link to={child.url ? child.url : ""} className={classesNav.linkStyles}>
        <MenuItem
          className={childClassesNav.listItemChild}
          key={child.name}
          onClick={() => handleItemClick(child)}
        >
          {child.icon && (
            <ListItemIcon style={{ marginRight: -10, color: "inherit" }}>
              <MiIcon path={Icons[child.icon]} size={0.7} />
            </ListItemIcon>
          )}

          <ListItemText
            classes={{ primary: childClassesNav.listItemText }}
            primary={child.name}
          />
        </MenuItem>
      </Link>
    );
  };

  const renderChildItems = () => {
    return (
      <Collapse in={!collapsed}>
        <div className={classesNav.indent}>
          {item?.children &&
            item.children.map((childItem) => {
              return (
                <div key={childItem.name}>{renderChildItem(childItem)}</div>
              );
            })}
        </div>
      </Collapse>
    );
  };

  return (
    <Fragment key={item.name}>
      <Link
        to={item.url ? item.url : window.location.pathname}
        className={classesNav.linkStyles}
      >
        <MenuItem
          onClick={() => handleItemClick(item)}
          className={classesNav.listItem}
        >
          {item.icon && (
            <MiIcon
              path={Icons[item.icon]}
              size={1}
              style={{ marginRight: 20, color: "inherit" }}
            />
          )}
          <ListItemText
            primary={item.name}
            classes={{ primary: classesNav.listItemText }}
          />

          {isNavIcon && (
            <ListItemIcon classes={{ root: classesNav.arrowIcon }}>
              <MiIcon path={collapsed ? mdiChevronRight : mdiChevronDown} />
            </ListItemIcon>
          )}
        </MenuItem>
      </Link>
      {item.children && renderChildItems()}
    </Fragment>
  );
};

export const MiSideNav = ({
  navItems,
  open,
  handleDrawer,
  drawerWidth,
  apps,
  mainApp,
  admin,
  appSettings,
  hideIcon,
}) => {
  const permissions = localStorage.getItem("permissions");
  const { classes, cx } = useStyles({ props: { drawerWidth, open, apps } });
  const router = useRouter();

  const renderNavItems = () => {
    if (!navItems?.length) {
      return null;
    }
    const newNav =
      buildNavBasedOnPermissions({ items: navItems }, permissions)?.items || [];
    return (
      <List>
        {newNav.map((item, index) => (
          <NavItem
            item={item}
            open={open}
            router={router}
            key={index}
            handleDrawer={handleDrawer}
            isNavIcon={item.children}
          />
        ))}
      </List>
    );
  };

  const renderAppSettings = () => {
    const handleAppNav = (nav) => {
      router.navigate(nav);
    };

    if (!appSettings) {
      return null;
    }
    return (
      <div style={{ padding: 10 }}>
        <HeaderLogo
          open={open}
          app={appSettings}
          onClick={() => handleAppNav(appSettings.url)}
        />
      </div>
    );
  };

  const renderApps = () => {
    const handleAppNav = (nav) => {
      router.navigate(nav);
    };

    if (!apps) {
      return null;
    }
    return (
      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
        {apps
          .filter((app) => app.name !== mainApp.name)
          .map((app, index) => (
            <HeaderLogo
              key={index}
              open={open}
              app={app}
              onClick={() => handleAppNav(app.url)}
            />
          ))}
        {renderAdmin()}
      </div>
    );
  };

  const handleNavAdmin = () => {
    router.push("/settings/accountmanagement");
  };

  const renderAdmin = () => {
    if (!admin) {
      return null;
    }
    return (
      <Grid style={{ marginTop: 20 }}>
        <MiButton
          color={MiTHEME.palette.secondary.grey}
          icon={mdiCogOutline}
          onClick={handleNavAdmin}
          fontSize={15}
          title={open ? "Admin Settings" : ""}
        />
      </Grid>
    );
  };

  return (
    <Grid className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={cx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: cx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Box display="flex" flexDirection="column" style={{ height: "100%" }}>
          <Box flexGrow={1} display="flex" style={{ minHeight: "60" }}>
            {renderNavItems()}
            <Divider />
          </Box>
          {!hideIcon && (
            <Box className={classes.bottomApps}>
              <Divider />
              {renderApps()}
              <Divider />
              {renderAppSettings()}
            </Box>
          )}
        </Box>
      </Drawer>
    </Grid>
  );
};
