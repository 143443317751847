import React, { useContext } from "react";
import { useNotification as useNotificationHook } from "../useNotification";
import { observer, inject } from "mobx-react";

export const NotificationHubContext = React.createContext(0);
export const useNotification = () => useContext(NotificationHubContext);

const NotificationHubProviderInternal = ({ children, notificationStore }) => {
  const notificationCounter = useNotificationHook({ notificationStore });

  return (
    <NotificationHubContext.Provider value={notificationCounter}>
      {children}
    </NotificationHubContext.Provider>
  );
};

export const NotificationHubProvider = inject(({ notificationStore }) => ({
  notificationStore,
}))(observer(NotificationHubProviderInternal));
