import React from "react";
import { Grid, useTheme } from "@mui/material";
import { SubHeader } from "../components";
import en from "../locale/en.json";
import { MiInputSelectBox, MiInputTextbox } from "@miview/components";
import { makeStyles } from 'tss-react/mui';
import { InputAdornment } from "@mui/material";

const useStyles = makeStyles()(() => ({
  formAlign: {
    marginTop: 20,
    paddingRight: 10,
  },
}));

const TermsAndRatesSection = ({
  editMode,
  paymentTerms,
  edit,
  isMissingRequiredField,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const paymentTermIdError =
    isMissingRequiredField && !edit.allFilled("paymentTermId");
  const paymentDropdownOptions = paymentTerms.map((e) => {
    return {
      value: e.id,
      label: e.value,
    };
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <SubHeader text={en.termsAndRates} />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputSelectBox
          labelTextColor={
            paymentTermIdError
              ? theme.palette.secondary.red
              : theme.palette.primary.main
          }
          labelText={en.paymentTerms}
          disabled={!editMode}
          menuItems={paymentDropdownOptions}
          required
          error={paymentTermIdError}
          disableNone={true}
          displayEmpty={true}
          isPlaceholder={true}
          value={edit.getValue("paymentTermId") || ""}
          handleChange={(e) => edit.update({ paymentTermId: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.paymentDiscount}
          disabled={!editMode}
          type={"number"}
          value={edit.getValue("billingRate")}
          handleChange={(e) => edit.update({ billingRate: e.target.value })}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(TermsAndRatesSection);
