export const getFieldSet = (loadingMessage, property) => {
  if (loadingMessage) {
    return [{ label: "", value: "..." }];
  }

  return [
    {
      label: "Community",
      value: property?.communityName,
      to: `/communities/${property?.communityId}`,
    },
    {
      label: "Builder",
      value: property?.builderName,
      to: `/builder/${property?.builderId}`,
    },
    {
      label: "ZipCode",
      value: property?.zipCode,
    },
    {
      label: "Plan",
      value: property?.planName,
      to: `/plans/${property?.planId}`,
    },
  ];
};
