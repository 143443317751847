import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const planService = {
  getPlans: async ({ filters, sort, params, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/plans`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });

    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/${id}`,
      method: "get",
    });

    return apiRequest(options);
  },

  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/plans`,
      method: "post",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving plan",
      },
      success: {
        message: "Plan saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  replace: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/${id}`,
      method: "put",
      data: request,
    });

    return apiRequest(options);
  },

  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/${id}`,
      method: "patch",
      data: request,
    });

    const toastMessageConfig = {
      failure: {
        message: "Failure saving plan",
      },
      success: {
        message: "Plan saved successfully",
      },
    };
    return apiRequest(options, toastMessageConfig);
  },

  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/${id}`,
      method: "delete",
    });

    return apiRequest(options);
  },

  clone: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/${id}/copy`,
      method: "post",
    });

    return apiRequest(options);
  },

  createCommunityPlan: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/community`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  getProperties: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/${id}/properties`,
      method: "get",
    });

    return apiRequest(options);
  },

  createPdf: async (id) => {
    var accountId = localStorage.getItem("accountId");
    const options = await apiOptions({
      url: `${API_V3}/plans/${id}/pdf/`,
      method: "post",
      data: { accountId },
    });

    return apiRequest(options);
  },

  getOptions: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/${id}/options`,
      method: "get",
    });

    return apiRequest(options);
  },

  applyOptions: async (id, appliedOptions = []) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/${id}/options`,
      method: "post",
      data: appliedOptions,
    });

    return apiRequest(options);
  },

  getAreas: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/${id}/areas`,
      method: "get",
    });

    return apiRequest(options);
  },

  getAvailablePropertyPlans: async (communityId, builderId) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/communities/${communityId}/builders/${builderId}`,
      method: "get",
    });

    return apiRequest(options);
  },

  reorderPropertyPlanItems: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/propertyitems/reorder`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  createPropertyPlanItem: async (planId, propertyId, request) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/${planId}/properties/${propertyId}/items`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },

  createArea: async (planId, request) => {
    const options = await apiOptions({
      url: `${API_V3}/plans/${planId}/areas`,
      method: "post",
      data: request,
    });

    return apiRequest(options);
  },
};
