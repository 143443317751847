import React, { useEffect, useState } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  MiLink,
  MiButton,
  MiInputTextbox,
  MiInputSelectBox,
  MiPageHeader,
  MiBox,
  MiIcon,
  MiDetailFields,
} from "@miview/components";
import { Card, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useComponentState, useEdit } from "@miview/hooks";
import { auditService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { makeStyles } from "tss-react/mui";
import { mdiArrowRight, mdiHistory } from "@mdi/js";
import { toText } from "@miview/utils";

const DATE_FIELD = "createTime";
const BY_ITEM = "Item";
const BY_TRANSACTION = "Transaction";

export const AuditHistory = () => {
  const [searchMode, setSearchMode] = useState(BY_ITEM);
  const [transactionId, setTransactionId] = useState(null);
  const [lastTransactionId, setLastTransactionId] = useState(null);
  const [history, setHistory] = useState([]);
  const [objectTypes, setObjectTypes] = useState([]);

  const stateManager = useComponentState();
  const { classes } = useAuditStyles();
  const theme = useTheme();
  const edit = useEdit({
    objectName: "",
    objectId: null,
  });

  useEffect(() => {
    getAuditTypes();
  }, []);

  const getAuditTypes = () => {
    stateManager.run(async () => {
      const response = await auditService.getTypes();
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = response.data.map((e) => {
          return { value: e, label: e };
        });
        setObjectTypes(mapped);
      }
    });
  };

  const getHistoryRequest = () => {
    switch (searchMode) {
      case BY_ITEM:
        return {
          objectName: edit.getValue("objectName"),
          objectId: edit.getValue("objectId"),
        };
      case BY_TRANSACTION:
        return { transactionId };
    }
  };

  const getAuditHistory = () => {
    stateManager.run(async () => {
      const request = getHistoryRequest();
      const response = await auditService.getAll(request);
      if (response.status === HTTP_STATUSES.OK) {
        setHistory(response.data);
        setLastTransactionId(transactionId);
      }
    });
  };

  const renderDate = (e, i) => {
    if (i == 0 || moment(e[DATE_FIELD]).isBefore(history[i - 1][DATE_FIELD])) {
      return moment(e[DATE_FIELD]).format("lll");
    }
    return null;
  };

  const clickTransactionId = (id) => {
    setTransactionId(id);
    setSearchMode(BY_TRANSACTION);
  };

  const renderTopRow = (e) => {
    return (
      <div className={classes.frontStyle} style={{ marginBottom: 8 }}>
        <div className={classes.auditTitleContainer}>
          {`Audit Id: ${e.id} - Transaction Id:\u00A0`}
          <MiLink
            onClick={() => clickTransactionId(e.transactionId)}
            title={e.transactionId}
            style={{ padding: 0, margin: 0 }}
          />
          {`\u00A0- ${e.objectName} Id: ${e.objectId} - Performed By:\u00A0`}
          <MiLink
            to={`/users/${e.userId}`}
            title={e.userName}
            style={{ padding: 0, margin: 0 }}
          />
        </div>
        <div className={classes.auditTitleContainer}>
          <MiButton
            title="Revert"
            variant="contained"
            disabled={e.isRolledBack}
            onClick={() => revertAudit(e.id)}
          />
          <MiButton
            title="Redo"
            variant="contained"
            disabled={!e.isRolledBack}
            onClick={() => redoAudit(e.id)}
          />
        </div>
      </div>
    );
  };

  const revertAudit = async (id) => {
    const response = await auditService.revert(id);
    if (response.status === HTTP_STATUSES.OK) {
      const audit = history.find((e) => e.id === id);
      audit.isRolledBack = true;
      setHistory([...history]);
    }
  };

  const redoAudit = async (id) => {
    const response = await auditService.redo(id);
    if (response.status === HTTP_STATUSES.OK) {
      const audit = history.find((e) => e.id === id);
      audit.isRolledBack = false;
      setHistory([...history]);
    }
  };

  const revertTransaction = async () => {
    const response = await auditService.revertTransaction(transactionId);
    if (response.status === HTTP_STATUSES.OK) {
      getAuditHistory();
    }
  };

  const redoTransaction = async () => {
    const response = await auditService.redoTransaction(transactionId);
    if (response.status === HTTP_STATUSES.OK) {
      getAuditHistory();
    }
  };

  const renderEntry = (e) => {
    const diff = JSON.parse(e.difference);
    if (e.isCreation) {
      return renderCreate(e.id, diff);
    }
    return renderDiffs(e.id, diff);
  };

  const renderCreate = (id, diff) => {
    return Object.entries(diff).map(([field, value]) => (
      <div
        key={`${field}-${id}`}
        className={classes.row}
        style={{ justifyContent: "space-between" }}
      >
        <p className={classes.values}>{field}</p>
        <p style={{ width: "10%" }}>=</p>
        <p className={classes.values}>{String(value)}</p>
      </div>
    ));
  };

  const renderDiffs = (id, diff) => {
    return Object.entries(diff).map(([field, value]) => (
      <div key={`${field}-${id}`}>{renderDifference(field, value)}</div>
    ));
  };

  const renderDifference = (field, value) => {
    return (
      <div className={classes.borderTop}>
        <p className={classes.labels}>{toText(field)}:</p>
        <div className={classes.frontStyle}>
          <p className={classes.values}>{value.Old}</p>
          <MiIcon path={mdiArrowRight} size={1} />
          <p className={classes.values}>{value.New}</p>
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div
        className={classes.filterContainer}
        style={{ display: "flex", gap: "1rem" }}
      >
        <MiInputSelectBox
          labelText="Modes"
          value={searchMode}
          handleChange={(e) => setSearchMode(e.target.value)}
          menuItems={[
            { value: BY_ITEM, label: BY_ITEM },
            { value: BY_TRANSACTION, label: BY_TRANSACTION },
          ]}
          required
          disableNone
        />
        {renderFilterOptions()}
      </div>
    );
  };

  const renderFilterOptions = () => {
    switch (searchMode) {
      case BY_ITEM:
        return renderFiltersItem();
      case BY_TRANSACTION:
        return renderFiltersTransaction();
    }
  };

  const filtersItemField = [
    {
      label: "Object",
      fieldType: "select",
      required: true,
      width: "50%",
      selectNone: true,
      options: objectTypes,
      value: edit.getValue("objectName"),
      setValue: (e) => edit.update({ objectName: e }),
    },
    {
      label: "ID",
      required: true,
      width: "50%",
      value: edit.getValue("objectId"),
      setValue: (e) => edit.update({ objectId: e }),
    },
  ];

  const renderFiltersItem = () => {
    return (
      <div className={classes.filterContainer}>
        <MiDetailFields
          detailFields={filtersItemField}
          customWrapperStyle={{ paddingTop: 8 }}
        />
        <MiButton
          title="Search"
          variant="outlined"
          onClick={getAuditHistory}
          color={theme.palette.primary.blue}
          disabled={!edit.getValue("objectName") || !objectIdIsValid()}
        />
      </div>
    );
  };

  const renderFiltersTransaction = () => {
    return (
      <>
        <div className={classes.filterContainer}>
          <MiInputTextbox
            labelText="Transaction ID"
            value={transactionId}
            handleChange={(e) => setTransactionId(e.target.value)}
            required
          />
          <MiButton
            title="Search"
            variant="outlined"
            onClick={getAuditHistory}
            color={theme.palette.primary.blue}
            disabled={!transactionId}
          />
        </div>
        <div className={classes.filterContainer}>
          <MiButton
            title="Revert Transaction"
            variant="contained"
            onClick={revertTransaction}
            disabled={!transactionId || transactionId !== lastTransactionId}
          />
          <MiButton
            title="Redo Transaction"
            variant="contained"
            onClick={redoTransaction}
            disabled={!transactionId || transactionId !== lastTransactionId}
          />
        </div>
      </>
    );
  };

  const objectIdIsValid = () => {
    return (
      edit.getValue("objectId") &&
      !isNaN(+edit.getValue("objectId")) &&
      !edit.getValue("objectId").includes(".")
    );
  };

  const renderList = () => {
    return (
      <div className={classes.listDiv}>
        <Timeline align="left">
          {history.map((e, index) => {
            return (
              <TimelineItem key={e.id}>
                <TimelineOppositeContent style={{ minWidth: 100, flex: 0 }}>
                  <Typography variant="body2" color="textSecondary">
                    {renderDate(e, index)}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Card variant="outlined" style={{ padding: 8 }}>
                    {renderTopRow(e)}
                    {renderEntry(e)}
                  </Card>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </div>
    );
  };

  return (
    <>
      <MiPageHeader
        title="Audit History"
        color={theme.palette.primary.main}
        loading={stateManager.isBusy()}
        leftIcon={
          <MiIcon
            path={mdiHistory}
            size={1}
            color={theme.palette.primary.main}
          />
        }
      />
      <MiBox style={{ backgroundColor: "#FFF" }}>
        {stateManager.statusTag("auditHistoryStatus")}
        {renderFilters()}
        <hr />
        {renderList()}
      </MiBox>
    </>
  );
};

const useAuditStyles = makeStyles()((theme) => ({
  listDiv: {
    backgroundColor: "#FFF",
    "& >div": {
      display: "flex",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  frontStyle: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  labels: {
    fontSize: theme.fontSize.medium,
    color: theme.palette.primary.grey,
  },
  values: {
    fontSize: theme.fontSize.small,
    width: "45%",
    color: "#000",
  },
  borderTop: {
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: theme.palette.accent.grey,
    backgroundColor: theme.palette.lightAccent.grey,
    padding: ".5rem",
  },
  filterContainer: {
    display: "flex",
    padding: 10,
    alignItems: "center",
  },
  auditTitleContainer: {
    marginRight: 5,
    display: "flex",
    flexDirection: "row",
  },
}));
