export const updateById = (list, data) => {
  const newList = [...list];
  const updateIndex = newList.findIndex((item) => item.id === data.id);
  newList[updateIndex] = { ...newList[updateIndex], ...data };
  return newList;
};

export const deleteById = (list, id) => list.filter((item) => item.id !== id);

export const isValidEmail = (email) => {
  const regex = /^[\w.-]+@[\w.-]+\.[\w.]+$/;
  return email && email.match(regex);
};

export const isPhoneNumber = (number) => {
  const numberExp = /^\d{10}$/;
  return number?.match(numberExp);
};

export const isPasswordLength = (password) => {
  return password.length >= 6;
};
