import React, { useState, useEffect } from "react";
import { MiPageHeader, MiTab, MiChip, MiIcon } from "@miview/components";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { HTTP_STATUSES } from "@miview/constants";
import SupplierBranches from "./SupplierBranches";
import SupplierContact from "./SupplierContact";
import SupplierPaymentInfo from "./SupplierPaymentInfo";
import SupplierGeneral from "./SupplierGeneral";
import Quotes from "../Quotes";
import ItemCostVendorCostBook from "../CostBook";
import { accountService } from "@miview/api";
import { mdiArrowLeft } from "@mdi/js";
import { useRouter, useComponentState } from "@miview/hooks";

export const SUPPLIER_TABS = {
  general: "general",
  paymentinfo: "paymentinfo",
  branches: "branches",
  contacts: "contacts",
  items: "items",
  quotes: "quotes",
};

const tabs = [
  {
    label: "General",
    value: SUPPLIER_TABS.general,
  },
  {
    label: "Payment Info",
    value: SUPPLIER_TABS.paymentinfo,
  },
  {
    label: "Branches",
    value: SUPPLIER_TABS.branches,
  },
  {
    label: "Contacts",
    value: SUPPLIER_TABS.contacts,
  },
  { label: "Items", value: SUPPLIER_TABS.items },
  { label: "Quotes", value: SUPPLIER_TABS.quotes },
];

const useStyles = makeStyles()((theme) => ({
  pageHeaderDetails: {
    display: "flex",
    borderRadius: 3,
    height: 20,
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.xsmall,
    color: theme.palette.secondary.bluegrey,
    backgroundColor: theme.palette.lightAccent.grey,
    marginLeft: 10,
  },
}));

export const SupplierDetails = () => {
  const [supplierData, setSupplierData] = useState({});
  const [paymentTermsData, setPaymentTermsData] = useState([]);
  const theme = useTheme();
  const { classes } = useStyles();
  const stateManager = useComponentState();

  const {
    replace,
    goBack,
    query: { activeTab, id },
  } = useRouter();

  useEffect(() => {
    fetchSupplierData();
    fetchPaymentTermsData();
  }, []);

  const fetchSupplierData = async () => {
    stateManager.run(async () => {
      const response = await accountService.accounts.get(id);
      if (response.status === HTTP_STATUSES.OK) {
        setSupplierData(response.data);
      }
    });
  };

  const fetchPaymentTermsData = async () => {
    stateManager.run(async () => {
      const response = await accountService.paymentTerms.getAll();
      if (response.status === HTTP_STATUSES.OK) {
        setPaymentTermsData(response.data);
      }
    });
  };

  const renderTabContent = (value) => {
    switch (value) {
      case SUPPLIER_TABS.general:
        return (
          <SupplierGeneral data={supplierData} setData={setSupplierData} />
        );
      case SUPPLIER_TABS.paymentinfo:
        return (
          <SupplierPaymentInfo
            data={supplierData}
            fetchData={fetchSupplierData}
            paymentTermsData={paymentTermsData}
          />
        );
      case SUPPLIER_TABS.branches:
        return <SupplierBranches />;
      case SUPPLIER_TABS.contacts:
        return <SupplierContact />;
      case SUPPLIER_TABS.items:
        return <ItemCostVendorCostBook />;
      case SUPPLIER_TABS.quotes:
        return <Quotes />;
    }
  };

  const renderBackArrow = () => {
    return (
      <MiIcon
        path={mdiArrowLeft}
        size={1}
        color={theme.palette.primary.main}
        style={{ cursor: "pointer" }}
        onClick={goBack}
      />
    );
  };

  const handleTabChange = (value) => {
    replace({ pathname: `/purchasing/suppliers/${id}/${value}` });
  };

  const pageHeaderDetails = () => {
    return (
      <MiChip
        label={supplierData?.number}
        className={classes.pageHeaderDetails}
      />
    );
  };

  return (
    <>
      {stateManager.statusTag("supplierDetailsStatusTag")}
      <MiPageHeader
        title={supplierData?.name}
        color={theme.palette.primary.main}
        leftIcon={renderBackArrow()}
        details={pageHeaderDetails()}
        loading={stateManager.isBusy()}
      />
      <MiTab
        tabs={tabs}
        currenttab={activeTab}
        tabIndicatorColor={theme.palette.primary.main}
        onTabChange={handleTabChange}
        renderTabContent={renderTabContent}
      />
    </>
  );
};
