import React, { useEffect, useState } from "react";
import { useTheme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { StatusChip } from "../../../../../components";
import en from "../../../../../locale/en.json";
import {
  TABLE_STYLES,
  ACCOUNT_STATUS,
  HTTP_STATUSES,
  ACCOUNT_TYPES,
} from "@miview/constants";
import { accountService } from "@miview/api";
import { createFilter } from "@miview/utils";
import { useComponentState, useRouter } from "@miview/hooks";
import {
  mdiMinusCircleOffOutline,
  mdiDelete,
  mdiPencil,
  mdiProgressCheck,
} from "@mdi/js";
import { MiIcon, MiButton, MiModalConfirm, MiList } from "@miview/components";
import SupplierBranchContactTable from "./SupplierBranchContactTable";
import AddNewBranchModal from "./AddBranchModal";

const useStyles = makeStyles()((theme) => ({
  adrressContainer: {
    display: "flex",
    flexDirection: "column",
    fontSize: theme.fontSize.small,
  },
  addressFirstLine: {
    fontWeight: theme.fontWeight.bold,
  },
}));

const SupplierBranches = (account) => {
  const [branches, setBranches] = useState([]);
  const [openedBranches, setOpenedBranches] = useState([]);
  const [heights, setHeights] = useState({});
  const [selectedRow, setSelectedRow] = useState();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({ open: false });
  const [refresh, setRefresh] = useState(false);
  const [supplierType, setSupplierType] = useState({});

  const router = useRouter();
  const theme = useTheme();
  const { classes } = useStyles();
  const stateManager = useComponentState();
  const { id } = router.query;
  const dataGridHeightRenderTrigger = [0];

  const getBranches = async ({ ...params }) => {
    return stateManager.run(async () => {
      const filters = createFilter().equals({
        parentId: id,
      });
      if (params.filters?.items.length > 0) {
        filters.items = filters.items.concat(params.filters.items);
      }
      params.filters = filters;
      return accountService.accounts.getAll(params);
    });
  };

  const getSupplierAccountType = async () => {
    stateManager.run(async () => {
      const f = createFilter();
      f.equals({ name: ACCOUNT_TYPES.SUPPLIER });
      const response = await accountService.accountTypes.getAll({
        filters: f.getFilter(),
      });
      if (response.status === HTTP_STATUSES.OK && response.data.length) {
        setSupplierType(response.data[0]);
      }
    });
  };

  useEffect(() => {
    getSupplierAccountType();
  }, []);

  const columns = [
    {
      field: "name",
      headerName: en.branchName,
      flex: 2,
      renderCell: (params) => {
        return <Typography>{params.value}</Typography>;
      },
    },
    {
      field: "storeNumber",
      headerName: en.branchNumber,
      flex: 1,
    },
    {
      field: "id",
      headerName: en.internalNumber,
      flex: 1,
    },
    {
      field: "addressStreetNumber",
      headerName: en.address,
      flex: 1,
      renderCell: (params) => (
        <div className={classes.adrressContainer}>
          <div className={classes.addressFirstLine}>
            {params.row.addressHouseNumber} {params.row.addressStreetNumber}
          </div>
          <div>
            {params.row.addressCity} {params.row.addressState}{" "}
            {params.row.addressZipCode}
          </div>
        </div>
      ),
    },
    {
      field: "phone",
      headerName: en.phone,
      flex: 1,
    },
    {
      field: "email",
      headerName: en.email,
      flex: 1,
    },
    {
      field: "status",
      headerName: en.status,
      flex: 1,
      renderCell: (params) => <StatusChip status={params.value} />,
      type: "singleSelect",
      valueOptions: [
        ACCOUNT_STATUS.active,
        ACCOUNT_STATUS.inactive,
        ACCOUNT_STATUS.incomplete,
      ],
    },
  ];

  const closeConfirmationModal = () => {
    setConfirmationModal({ open: false });
    setSelectedRow(null);
  };

  const openCreateModal = () => {
    setIsEditModalOpen(true);
    setSelectedRow(null);
  };

  const openEditModal = (obj) => {
    setIsEditModalOpen(true);
    setSelectedRow(obj);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedRow(null);
  };

  const createUpdateBranch = async (obj) => {
    if (selectedRow) {
      return updateBranch(obj);
    }
    return createBranch(obj);
  };

  const createBranch = async (obj) => {
    stateManager.run(async () => {
      const response = await accountService.accounts.create({
        data: { ...obj, accountTypeId: supplierType.id },
        successMessage: en.branchCreatedSuccessfully,
      });
      if (response.status === HTTP_STATUSES.CREATED) {
        setRefresh(!refresh);
        closeEditModal();
      }
    });
  };

  const updateBranch = async (obj) => {
    stateManager.run(async () => {
      const response = await accountService.accounts.update({
        id: selectedRow.id,
        data: obj,
        successMessage: en.branchEditedSuccessfully,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setRefresh(!refresh);
        closeEditModal();
      }
    });
  };

  const handleBranchStatusUpdate = (row) => {
    if (row.status === ACCOUNT_STATUS.active) {
      setConfirmationModal({
        open: true,
        title: en.makeInActive,
        description: `Make ${row.name} Inactive?`,
        positive: () =>
          updateBranchStatus(
            row.id,
            ACCOUNT_STATUS.inactive,
            en.branchInactivatedSuccessfully
          ),
      });
    } else {
      setConfirmationModal({
        open: true,
        title: en.makeActive,
        description: `Make ${row.name} Active?`,
        positive: () =>
          updateBranchStatus(
            row.id,
            ACCOUNT_STATUS.active,
            en.branchActivatedSuccessfully
          ),
      });
    }
  };

  const updateBranchStatus = (branchId, status, message) => {
    stateManager.run(async () => {
      const response = await accountService.accounts.update({
        id: branchId,
        data: { status: status },
        successMessage: message,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setRefresh(!refresh);
        setConfirmationModal({ open: false });
      }
    });
  };

  const handleDeleteBranch = (row) => {
    setSelectedRow(row);
    setConfirmationModal({
      open: true,
      title: en.deleteBranch,
      description: `Delete ${row.name}?`,
      positive: () => deleteBranch(row.id),
    });
  };

  const deleteBranch = async (branchId) => {
    stateManager.run(async () => {
      const response = await accountService.accounts.delete({
        id: branchId,
        successMessage: en.branchDeletedSuccessfully,
      });
      if (response.status === HTTP_STATUSES.NO_CONTENT) {
        setRefresh(!refresh);
        setConfirmationModal({ open: false });
      }
    });
  };

  const getRowActions = (row) => {
    return [
      {
        text: en.edit,
        onClick: () => openEditModal(row),
        renderIcon: () => <MiIcon path={mdiPencil} size={"20px"} />,
      },
      {
        text:
          row.status === ACCOUNT_STATUS.inactive
            ? en.makeActive
            : en.makeInActive,
        onClick: () => handleBranchStatusUpdate(row),
        renderIcon: () => (
          <MiIcon
            path={
              row.status === ACCOUNT_STATUS.inactive
                ? mdiProgressCheck
                : mdiMinusCircleOffOutline
            }
            size={"20px"}
          />
        ),
      },
      {
        text: en.delete,
        onClick: () => handleDeleteBranch(row),
        renderIcon: () => <MiIcon path={mdiDelete} size={"20px"} />,
      },
    ];
  };

  const getRowDetails = (row) => {
    if (openedBranches.includes(row.id)) {
      return (
        <SupplierBranchContactTable
          accountId={row.id}
          handleSetHeights={handleSetHeights}
        />
      );
    }
    return <></>;
  };

  const renderHeaderButtons = () => {
    return (
      <MiButton
        title={en.addNewBranch}
        color={theme.palette.primary.white}
        backgroundColor={theme.palette.primary.main}
        onClick={openCreateModal}
        disabled={false}
      />
    );
  };

  const handleSetHeights = (branchId, count) => {
    const newHeights = { ...heights, [branchId]: count };
    setHeights(newHeights);
    setOpenedBranches(openedBranches.concat(dataGridHeightRenderTrigger));
  };

  return (
    <>
      {stateManager.statusTag("supplierBranches")}
      <MiModalConfirm
        open={confirmationModal.open}
        title={confirmationModal.title}
        description={confirmationModal.description}
        handlePositive={confirmationModal.positive}
        handleNegative={closeConfirmationModal}
      />
      <AddNewBranchModal
        open={isEditModalOpen}
        accountId={id}
        accountTypeId={account.accountTypeId}
        row={selectedRow}
        onCancel={closeEditModal}
        createUpdateBranch={createUpdateBranch}
      />
      <MiList
        data={branches}
        setData={setBranches}
        dataProvider={getBranches}
        columns={columns}
        refresh={refresh}
        getRowActions={getRowActions}
        renderHeaderButtons={renderHeaderButtons}
        getDetailPanelContent={(row) => getRowDetails(row)}
        getDetailPanelHeight={(row) => heights[row.id]}
        onDetailPanelExpandedRowIdsChange={setOpenedBranches}
        detailPanelExpandedRowIds={openedBranches}
        getRowClassName={(params) =>
          params.row.status === ACCOUNT_STATUS.inactive
            ? TABLE_STYLES.INACTIVE
            : null
        }
      />
    </>
  );
};

export default SupplierBranches;
