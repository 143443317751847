import { STATUS } from "../constants";
import { pricingService } from "@miview/api";

export const makeFirstLetterAsCapital = (word = "") => {
  return word.length ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : "";
};

export const constructPageTitle = (pathname = "") => {
  const paths = pathname.split("/");
  const path = paths.length ? paths[paths.length - 1] : "";
  return makeFirstLetterAsCapital(path);
};

export const mapStatusEnum = (filters) => {
  return filters.map((fi) => STATUS[fi.toLowerCase()]);
};

export const updateById = (list, data) =>
  list.map((item) => (item.id === data.id ? data : item));
export const deleteById = (list, id) => list.filter((item) => item.id !== id);

export const makeActiveOrInactiveConfirmModalObj = (type, statusId) => {
  let obj = {
    type: STATUS.active,
    title: `Make ${makeFirstLetterAsCapital(type)} Active?`,
    description1: `Are you sure you would like to make this ${type} active?`,
    description2: `You can make a ${type} inactive any time.`,
    approveTxt: "Make Active",
  };
  if (statusId === STATUS.active) {
    obj = {
      type: STATUS.inactive,
      title: `Make ${makeFirstLetterAsCapital(type)} Inactive?`,
      description1: `Are you sure you would like to make this ${type} inactive?`,
      description2: `You can reactivate a ${type} any time.`,
      approveTxt: "Make Inactive",
    };
  }
  return obj;
};
export const formatArray = (arr, key) => {
  return arr.reduce((obj, item) => {
    obj[item[key]] = item?.id;
    return obj;
  }, {});
};

export const capitalizeFirstLetter = (string) => {
  const arr = string.split("-");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
};

export const getUniqueItems = (arr) => {
  let unique = [];
  arr.forEach((element) => {
    if (!unique.includes(element)) {
      unique.push(element);
    }
  });
  return unique;
};

export const filterArray = (array, filters) => {
  const getValue = (value) =>
    typeof value === "string" ? value.toUpperCase() : value;
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    return filterKeys.every((key) => {
      if (!filters[key].length) {
        return "";
      }
      return filters[key].find(
        (filter) => getValue(filter) === getValue(item[key])
      );
    });
  });
};

export const filterByProperty = (data, key) => {
  return [...new Map(data.map((x) => [key(x), x])).values()];
};

export const getRandomNumber = (name) => {
  let buf = new Uint8Array(1);
  window.crypto.getRandomValues(buf);
  return name + buf[0];
};

const constructQuoteItems = (quoteList) => {
  let items = [];
  quoteList.forEach((item, idx) => {
    items.push({
      itemId: item.itemId,
      displaySequence: idx + 1,
    });
  });
  return items;
};

export const constructQuote = ({
  preText,
  accountId,
  contactId,
  selectedItems,
  status,
  description,
  submissionDate,
  effectiveDate,
  expirationDate,
}) => {
  let localItems = [];
  if (selectedItems.length > 0) {
    selectedItems.map((item) => {
      // eslint-disable-next-line
      if (item.hasOwnProperty("itemsInfo")) {
        localItems.push(...item.itemsInfo);
      } else {
        localItems.push(item);
      }
    });
  }
  return {
    quoteNumber: preText.includes("REQ") ? getRandomNumber(preText) : preText,
    accountId,
    contactId,
    status,
    description,
    quoteItems: constructQuoteItems(localItems),
    submissionDate,
    effectiveDate,
    expirationDate,
  };
};

const createNewQuoteItem = (quoteId, newItemId, newQuoteItems) => {
  pricingService.quotes.createItems(quoteId, {
    accountItemId: newItemId,
    displaySequence: newQuoteItems.displaySequence,
    quotePrice: 0.0,
  });
};

const removeQuoteItem = (quoteId, deletedItemList) => {
  let removeItems = [...new Set(deletedItemList)];
  if (removeItems?.length > 0) {
    Promise.all(
      removeItems?.map(async (item) => {
        await pricingService.quotes.removeItems(quoteId, item);
      })
    );
  }
};

export const processAddedRemovedItems = async (
  id,
  accountId,
  deletedItems,
  newItems,
  originalItems
) => {
  removeQuoteItem(id, deletedItems);
  let addedItems = [...new Set(newItems)];
  if (addedItems?.length > 0) {
    await Promise.all(
      addedItems?.map(async (item) => {
        const addNewItem = await pricingService.quotes.getAccountItems(
          accountId,
          item.itemId || item.accountItemId
        );
        if (addNewItem?.data?.id) {
          createNewQuoteItem(id, addNewItem?.data?.id, item);
        } else {
          let accountItem = {};
          originalItems?.forEach((quote) => {
            if (quote.itemId == item.accountItemId) {
              accountItem = {
                accountId,
                itemId: item.accountItemId,
                sku: quote.sku,
                currentQuoteItemId: quote.currentQuoteItemId,
                currentQuotePrice: quote.currentQuotePrice,
                description: quote.description,
              };
            }
          });
          const accountItemResponse =
            await pricingService.quotes.createAccountItems(
              accountItem,
              "Account Item created successfuly",
              "Account Item created failed"
            );
          if (accountItemResponse?.data?.id) {
            createNewQuoteItem(id, accountItemResponse?.data?.id, item);
          }
        }
      })
    );
  }
};

export const downloadCSV = function (data) {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([data], { type: "text/csv" });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement("a");

  // Passing the blob downloading url
  a.setAttribute("href", url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute("download", "download.csv");

  // Performing a download with click
  a.click();
};
