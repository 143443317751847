import { makeStyles } from "tss-react/mui";

export const useValidationRadioListStyles = makeStyles()(() => ({
  customRadioButton: {
    "& [type=radio]": {
      width: "1px",
      height: "1px",
      border: "0",
      clip: "rect(0 0 0 0)",
      margin: "-1px",
      overflow: "hidden",
      padding: "0",
      position: "absolute",
    },
  },
  label: {
    position: "relative",
    cursor: "pointer",
    padding: "0 .2rem",
    display: "inline-flex",
    width: "100%",
    WebkitFontSmoothing: "antialiased",
    textAlign: "left",
    "& .add-text": {
      display: "inline-block",
      width: "80%",
      textAlign: "left",
      padding: "1rem .5rem 1rem .5rem",
    },
    "& .addText .serviceTitle": { fontSize: "1rem", color: "#0070D2" },
    "& .serviceTitle": { fontWeight: "bold" },
  },
  radioAdd: {
    '& [type="radio"] + label:before': {
      marginLeft: "1rem",
      fontFamily: "FontAwesome",
      display: "inline-block",
      height: "2.5rem",
      width: "2.5rem",
      color: "#FFF",
      fontSize: "1.25rem",
      textAlign: "center",
      verticalAlign: "middle",
      lineHeight: "2.5rem",
      borderRadius: "50%",
      backgroundColor: "#54698D",
      fontWeight: 300,
      content: '"\\f067"',
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    },
    '& [type="radio"] ~ label:before': {
      marginLeft: "1rem",
      fontFamily: "FontAwesome",
      display: "inline-block",
      height: "2.5rem",
      width: "2.5rem",
      color: "#FFF",
      fontSize: "1.25rem",
      textAlign: "center",
      verticalAlign: "middle",
      lineHeight: "2.5rem",
      borderRadius: "50%",
      backgroundColor: "#54698D",
      fontWeight: 300,
      content: '"\\f067"',
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    },
    '& [type="radio"]:checked + label': {
      backgroundColor: "#EAF6FC",
    },
    '& [type="radio"]:checked ~ label': {
      backgroundColor: "#EAF6FC",
    },
    '& [type="radio"] + label:hover': {
      backgroundColor: "#EAF6FC",
    },
    '& [type="radio"] ~ label:hover': {
      backgroundColor: "#EAF6FC",
    },
    '& [type="radio"]:checked + label:before': {
      backgroundColor: "#4BCA81",
      fontWeight: 300,
      content: '"\\f00c"',
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    },
    '& [type="radio"]:checked ~ label:before': {
      backgroundColor: "#4BCA81",
      fontWeight: 300,
      content: '"\\f00c"',
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    },
  },
  ohIconAdd: { marginRight: "2rem" },
  hr: { border: "0", backgroundColor: "#D8DDE6", margin: "0" },
}));
