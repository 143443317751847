import React, { memo, useState } from "react";
import { THEME } from "./theme";
import { ImageViewer } from "@miview/components";
import MiNewMessageDialogue from "./MiNewMessageDialog";
import moment from "moment";
import MiMessageOptions from "./MiMessageOptions";
import { getImageUrl } from "@miview/utils";
import { useCdn } from "@miview/hooks";

const MiMessage = (props) => {
  const {
    messageInfo,
    childMessages,
    relatedEntityId,
    relatedType,
    depth,
    refreshAction,
    isChildMessage,
  } = props;
  const [showReplyDialog, setShowReplyDialog] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [activeImage, setActiveImage] = useState("");
  const [fullScreenStatus, setFullScreenStatus] = useState(false);
  const cdn = useCdn();
  const maxDepth = 2;
  const hasParent = messageInfo.parentMessage?.messageId > 0; // Have to check if id > 0 since it will always be there
  const messageBGColor = messageInfo.isFromCurrentUser
    ? THEME.PURPLE_SECONDARY
    : THEME.GREEN_ACCENT;
  const parentMessageBGColor = messageInfo.isFromCurrentUser
    ? "#67529B"
    : THEME.GREEN_ACCENT_ALT;

  const rootcontainterStyle = {
    padding: "5px",
    paddingLeft: isChildMessage ? 45 : 5,
  };

  const labelStyle = {
    color: THEME.BLUE_PRIMARY,
    paddingLeft: 50,
    marginBottom: 0,
    fontSize: ".65rem",
  };

  const flexRow = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 15,
  };

  const iconStyle = {
    color: "white",
    textAlign: "center",
    fontSize: "1.4rem",
    backgroundColor: messageBGColor,
    padding: 5,
    paddingRight: 5,
    borderRadius: "50%",
  };

  const messageBlurb = {
    backgroundColor: messageBGColor,
    minWidth: "50%",
    maxWidth: "80%",
    borderRadius: 10,
    marginLeft: 15,
    marginBottom: 0,
    lineHeight: 1,
  };

  const messagebody = {
    color: "white",
    padding: 10,
    paddingTop: hasParent ? 0 : 10,
    paddingBottom: 0,
    marginBottom: 0,
  };

  const optionsContainer = {
    position: "relative",
    left: "80%",
    border: "0px",
    maxWidth: "1rem",
  };

  const options = {
    color: "white",
    cursor: "pointer",
  };

  const triangle = {
    width: "22px",
    height: "22px",
    background: `linear-gradient(225deg,  ${messageBGColor} 50%, transparent 0)`,
    backgroundColor: "#FFF",
    marginRight: -22,
  };

  const messageParent = {
    container: {
      backgroundColor: parentMessageBGColor,
      borderRadius: 6,
      margin: 6,
      padding: 5,
    },

    label: {
      color: "white",
      marginBottom: 0,
      fontSize: ".65rem",
      fontWeight: 1.2,
    },
    body: {
      color: "white",
      marginTop: 2,
      marginBottom: 0,
    },
  };

  const taggedContainer = {
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    fontSize: "0.75rem",
  };

  const isImage = () => {
    return (
      messageInfo.fileType.toLowerCase() == "png" ||
      messageInfo.fileType.toLowerCase() == "jpg"
    );
  };

  const imageStyle = {
    borderRadius: 5,
    border: "1px solid rgb(221,221,221)",
  };

  const fullscreen = (e) => {
    setActiveImage(e.target.src);
    setFullScreenStatus(true);
  };

  const unFullscreen = () => {
    setFullScreenStatus(false);
  };

  return (
    <div style={rootcontainterStyle}>
      <p style={labelStyle}>
        {messageInfo.fromName} -{" "}
        {moment(messageInfo.createTime).format("L h:mmA")}
      </p>
      <div style={flexRow}>
        <div>
          <i style={iconStyle} className="material-icons">
            person
          </i>
        </div>
        <div style={triangle}></div>
        <div style={messageBlurb}>
          {hasParent && (
            <div style={messageParent.container}>
              <p style={messageParent.label}>
                {messageInfo.parentMessage.fromName}
              </p>
              <p style={messageParent.body}>{messageInfo.parentMessage.body}</p>
            </div>
          )}
          <p style={messagebody}>{messageInfo.body}</p>
          {messageInfo.taggedUsers && (
            <div style={taggedContainer}>
              {messageInfo.taggedUsers.map((user, i) => (
                <p key={i} style={{ fontWeight: "bold", marginBottom: 0 }}>
                  @{user.fullName}
                </p>
              ))}
            </div>
          )}
          {messageInfo.attachmentUrl && messageInfo.fileType && (
            <div style={{ paddingBottom: 10, paddingLeft: 10 }}>
              {isImage() && (
                <img
                  style={imageStyle}
                  src={getImageUrl(messageInfo.attachmentUrl, cdn)}
                  width={50}
                  height={50}
                  onClick={(e) => fullscreen(e)}
                />
              )}
              {!isImage() && (
                <a href={getImageUrl(messageInfo.attachmentUrl, cdn)}>
                  <i
                    style={{ fontSize: "1.3rem" }}
                    className="material-icons"
                    onClick={() => null}
                  >
                    attach_file
                  </i>
                </a>
              )}
            </div>
          )}
          <div style={optionsContainer}>
            {depth < maxDepth && (
              <i
                className="material-icons"
                onClick={() => setShowOptions(true)}
                onBlur={() => setShowOptions(false)}
                style={options}
              >
                more_horiz
              </i>
            )}
            {showOptions && (
              <MiMessageOptions
                hideDialog={() => setShowOptions(false)}
                replyButtonClick={() => setShowReplyDialog(true)}
              />
            )}
          </div>
        </div>
      </div>
      {showReplyDialog && depth < maxDepth && (
        <MiNewMessageDialogue
          hideDialog={() => setShowReplyDialog(false)}
          relatedEntityId={relatedEntityId}
          relatedType={relatedType}
          refreshAction={refreshAction}
          parentMessage={messageInfo}
        />
      )}
      {childMessages &&
        childMessages.length > 0 &&
        childMessages.map((item) => (
          <MiMessage
            key={item.messageId}
            messageInfo={item}
            depth={depth + 1}
            relatedEntityId={relatedEntityId}
            refreshAction={refreshAction}
            relatedType={relatedType}
            childMessages={item.childMessages}
            isChildMessage={true}
          />
        ))}
      <ImageViewer
        fullScreenStatus={fullScreenStatus}
        images={[activeImage]}
        onUnfullscreen={unFullscreen}
      />
    </div>
  );
};
export default memo(MiMessage);
