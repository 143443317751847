import React from "react";
import { miStyles } from "@miview/theme";
import { MiIcon } from "../index.ts";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { styled } from "@mui/material/styles";
import { Button as MuiButton } from "@mui/material";

const useStyles = makeStyles()((_, { props }) => ({
  iconStyles: {
    fontSize: props.iconSize || props.fontSize || undefined,
    color: props.inverse ? "white" : props.color,
  },
  iconMargin: {
    marginRight: !props.title ? 0 : props?.spaceAfterIcon || 3,
  },

  iconElementStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Button = styled(MuiButton)(
  {
    outline: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    borderWidth: 1,
    borderRadius: 3,
    borderStyle: "solid",
    height: 32,
    minWidth: 32,
    margin: "2px 5px",
    userSelect: "none",
    whiteSpace: "nowrap",
    lineHeight: "1.1",
  },
  ({ styles, ...props }) => {
    return {
      opacity: props.disabled ? 0.3 : 1,
      borderColor:
        styles.backgroundColor || styles.inverse ? "transparent" : styles.color,
      backgroundColor: styles.inverse ? styles.color : styles.backgroundColor,
      paddingLeft: styles.padding,
      paddingRight: styles.padding,
      fontSize: styles.fontSize,
      ":hover": {
        backgroundColor: styles.inverse ? styles.color : styles.backgroundColor,
      },
    };
  }
);

/**
 * A customizable button component
 */
export const MiButton = (props) => {
  let buttonTitle = null;

  const {
    icon,
    title,
    color,
    inverse,
    disabled,
    style,
    fontSize,
    iconSize,
    spaceAfterIcon,
    backgroundColor,
    children,
    titleStyle,
    ...rest
  } = props;

  const { classes: miClasses } = miStyles();

  const { classes } = useStyles({
    props: {
      inverse,
      color,
      iconSize,
      fontSize,
      title,
      spaceAfterIcon,
      disabled,
      backgroundColor,
    },
  });

  const elementId = title
    ? title.replace(/[^A-z]+/g, "") + "Button"
    : icon
    ? icon.replace(/[^A-z]+/g, "") + "Button"
    : "";

  const styles = {
    text: {
      textAlign: "center",
      flex: 0,
      ...titleStyle,
    },
  };

  if (title) {
    buttonTitle = (
      <div
        style={{
          ...styles.text,
          color: inverse ? "white" : color,
          textTransform: "capitalize",
        }}
      >
        {title}
      </div>
    );
  }

  return (
    <Button
      id={elementId}
      data-testid={elementId}
      {...rest}
      className={`${miClasses.miButton} ${props.className}`}
      styles={{
        inverse,
        color,
        iconSize,
        fontSize,
        padding: title ? 8 : 4,
        spaceAfterIcon,
        backgroundColor,
      }}
      style={style}
      disabled={props.disabled}
    >
      {icon && (
        <div className={classes.iconMargin}>
          <MiIcon
            data-testid={"MiButtonIcon"}
            path={icon}
            className={classes.iconStyles}
            size={iconSize}
          />
        </div>
      )}
      {buttonTitle}
      {children}
    </Button>
  );
};

MiButton.propTypes = {
  /**
   * the name of the icon to be displayed in the button.
   */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /**
   * a string value to display on the button.
   */
  title: PropTypes.string,

  /**
   * the color of the button text.
   */
  color: PropTypes.string,

  /**
   * the color of the button background.
   */
  backgroundColor: PropTypes.string,

  /**
   * a boolean value used to determine if the background color
   * is transparent or the value provided in the color prop.
   */
  inverse: PropTypes.bool,

  /**
   * a boolean value used to determine if the onClick event
   * will fire or not. If true, the color provided to the
   * color prop will be faded.
   */
  disabled: PropTypes.bool,

  /**
   * an object of css properties that will override
   * the button's default styles.
   */
  style: PropTypes.object,

  /**
   * used to determine the size of the icon
   */
  fontSize: PropTypes.number,

  /**
   * used to provide space between the icon and the title
   */
  spaceAfterIcon: PropTypes.number,

  /**
   * an array of any remaining props provided to this component
   * that are not explicitly handeled. They are passed down to
   * the underlying button.
   */
  rest: PropTypes.array,
};
