import React from "react";
import { MiDatePickerInput, MiIconButton, MiIcon } from "@miview/components";
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";
import moment from "moment";

export const InspectionDatePicker = ({ date, handleDateChange }) => {
  const renderDateFormat = (val) => {
    return moment(val).calendar({
      sameDay: "[Today], MMM DD, YYYY",
      nextDay: "[Tomorrow], MMM DD, YYYY",
      lastDay: "[Yesterday], MMM DD, YYYY",
      nextWeek: "ddd, MMM DD, YYYY",
      lastWeek: "ddd, MMM DD, YYYY",
      sameElse: "ddd, MMM DD, YYYY",
    });
  };
  return (
    <div style={{ display: "flex" }}>
      <MiIconButton
        id="sub"
        onClick={() => handleDateChange(moment(date).add(-1, "days"))}
        style={{ padding: 0, marginRight: "5px" }}
      >
        <MiIcon path={mdiChevronLeft} size={1} />
      </MiIconButton>
      <MiDatePickerInput
        value={date}
        handleChange={handleDateChange}
        type="date"
        inputFormat="MM/DD/YYYY"
        dateFormat="MM/DD/YYYY"
        renderDateFormat={renderDateFormat}
      />
      <MiIconButton
        id="add"
        onClick={() => handleDateChange(moment(date).add(1, "days"))}
        style={{ padding: 0, marginLeft: "5px" }}
      >
        <MiIcon path={mdiChevronRight} size={1} />
      </MiIconButton>
    </div>
  );
};
