import React from "react";
import { useTheme } from "@mui/material/styles";
import { MiModalConfirm } from "@miview/components";
import PropTypes from "prop-types";

const ConfirmationModal = ({
  open,
  icon,
  title,
  description,
  cancelTxt = "Cancel",
  approveTxt,
  onCancel,
  onApprove,
  severity,
}) => {
  const theme = useTheme();
  const HandleColors = (color) => {
    switch (color) {
      case "error":
        return theme.palette.secondary.red;
      case "warning":
        return theme.palette.primary.amber;
      case "info":
        return theme.palette.primary.blue;
      default:
        return theme.palette.primary.teal;
    }
  };
  return (
    <MiModalConfirm
      open={open}
      title={title}
      description={description}
      icon={icon}
      color={HandleColors(severity)}
      buttonRightStyles={{
        height: "36px",
        lineHeight: "36px",
        padding: "6px 14px",
        minWidth: "132px",
        fontSize: theme.fontSize.medium,
        fontWeight: theme.fontWeight.medium,
      }}
      buttonLeftStyles={{
        height: "36px",
        lineHeight: "36px",
        padding: "6px 14px",
        minWidth: "132px",
        fontSize: theme.fontSize.medium,
        fontWeight: theme.fontWeight.medium,
      }}
      textNegative={cancelTxt}
      textPositive={approveTxt}
      handlePositive={onApprove}
      handleNegative={onCancel}
    />
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default React.memo(ConfirmationModal);
