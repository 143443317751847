import React, { useState, useEffect, Fragment, useRef } from "react";
import { createToast } from "@miview/toast";
import { TOAST_TYPES, HTTP_STATUSES } from "@miview/constants";
import PropTypes from "prop-types";
import { THEME } from "@miview/theme";
import {
  MiHeader,
  MiStepsBar,
  MiBuilderLayout,
  MiSpecsTitle,
  MiSpecsSubTitle,
  MiMaterialsModal,
  MiPlanOptions,
  MiPreviewTabs,
  MiImagePreviewModal,
  MiMenuSystem,
} from "../../components/Standard/MiView";
import {
  STEPS,
  STAGE_TYPES,
  KEY_CODES,
  KEY_MODIFIERS,
  PLANITEM_FORM_INITIAL_STATE,
  PLANOPTION_FORM_INITIAL_STATE,
} from "./constants";
import {
  updatePropertyOptions,
  updateWalkItem,
  getFieldSet,
  deleteOrRestorePlanItem,
  revertPropertyItems,
} from "./utils";
import {
  reorderItems,
  countOpenItems,
  getMatchingItems,
  getQueueItems,
  itemIsIncomplete,
} from "../../utils";
import { getImageBase64FromFile } from "@miview/utils";
import { AvailableOptionsList, StageIcon, HomeSpecPreview } from "./components";
import { usePlanOptionUpdate } from "./hooks";
import {
  usePropertySections,
  useStages,
  useWalkTypes,
  useMaterials,
  usePlanAreas,
  usePlanOptions,
  useStacks,
  useProperty,
} from "../MasterSpecs/hooks";
import { useRouter, useComponentState, useEdit } from "@miview/hooks";
import {
  RefreshModal,
  ImportCadModal,
  ImportRoughModal,
  RoughInCanvas,
  ImportStacksModal,
  PublishModal,
} from "./components";
import {
  propertyService,
  planService,
  optionService,
  cadBoundaryService,
} from "@miview/api";
import { mdiCog } from "@mdi/js";

const STACK_STAGES = [STAGE_TYPES.roughIn, STAGE_TYPES.boxAndWrap];

const HomeSpecsInternal = () => {
  const [initialLoading, setInitialLoading] = useState(false);
  const [previewTab, setPreviewTab] = React.useState(0);
  const [cadBoundaries, setCadBoundaries] = useState(null);
  const [refreshCadImage, setRefreshCadImage] = useState(false);
  const [activeTab, setActiveTab] = useState("Rough In");
  //modals
  const [imagePreviewed, setImagePreviewed] = useState("");
  const [refreshModal, setRefreshModal] = useState("");
  const [importCadModal, setImportCadModal] = useState("");
  const [importStacksModal, setImportStacksModal] = useState(false);
  const [importRoughModal, setImportRoughModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  //materials
  const [materialsModalOpen, setMaterialsModalOpen] = useState(false);
  //dragging
  const [itemDragging, setItemDragging] = useState(false);
  //quick mode
  const virtuosoRef = useRef(null);
  const [quickMode, setQuickMode] = useState(false);
  const [quickModeIndex, setQuickModeIndex] = useState(null);
  const [quickModeQueue, setQuickModeQueue] = useState([]);
  const [selectedPlanItem, setSelectedPlanItem] = useState(
    PLANITEM_FORM_INITIAL_STATE
  );

  const router = useRouter();
  const stateManager = useComponentState();

  //property detail
  const { propertyId } = router.params;
  const { property, handleGetProperty } = useProperty(propertyId);

  const {
    handleSavePropertyPlanArea,
    planAreas,
    planAreasBase,
    planAreasPreview,
    setPlanAreas,
    setPlanAreasBase,
    handleGetPropertyPreview,
    handleGetPlanAreas,
    refreshData,
  } = usePlanAreas(property.planId, propertyId);

  //hooks
  const { stages, getStages } = useStages();
  const { walkTypes, handleGetWalkTypes } = useWalkTypes();
  const { propertySections, handleGetPropertySections } = usePropertySections();
  const { materialCategories, handleGetMaterialCategories } = useMaterials();
  const {
    stackSizes,
    measurementTypesBack,
    measurementTypesSide,
    handleGetStackSizes,
    handleGetStackMeasurementTypes,
  } = useStacks();

  const handleUpdateWalkItem = updateWalkItem({
    property,
    propertyId,
    refreshData,
    planAreas,
  });

  const handleDeleteOrRestorePlanItem = deleteOrRestorePlanItem({
    refreshData,
  });

  const handleDeletePlanArea = async ({ areaId }) => {
    await propertyService.deleteArea(propertyId, areaId);
    refreshData();
  };
  const handleRestorePlanArea = async ({ areaId }) => {
    await propertyService.restoreArea(propertyId, areaId);
    refreshData();
  };

  const {
    planOptions,
    setPlanOptions,
    propertyOptions,
    optionTypes,
    handleGetOptionsByProperty,
    handleGetOptionTypes,
  } = usePlanOptions(property.planId, propertyId);

  const planItemEdit = useEdit(selectedPlanItem);
  const planOptionEdit = useEdit(PLANOPTION_FORM_INITIAL_STATE);

  // NEED TO REPLACE
  const {
    saveDraft,
    saveAreaActionAdd,
    saveAreaActionRemove,
    saveAreaActionUpdate,
    updateOptionsAfterSubmission,
  } = usePlanOptionUpdate({
    fetchOptions: propertyService.getOptions,
    planId: property.planId,
    property,
    setPlanOptions,
    planOptions,
    planOptionEdit: planOptionEdit.edits,
    setPlanOptionEdit: planOptionEdit.update,
  });

  const saveOptionRequest = async (request) => {
    await optionService.submit(request);
    await handleRefreshOptionsData();
  };

  const handleUpdatePropertyOptions = async (option, isChecked) => {
    await updatePropertyOptions({
      propertyId,
      option,
      isChecked,
    });
    await handleGetOptionsByProperty();
    refreshData();
  };

  const quickModeRef = useRef();

  const stage = stages?.find((e) => e.name === activeTab);
  const stageId = stage?.id;
  const stageName = stage?.name;

  const areasInternal = planAreasBase || planAreas;
  const planAreaSelections = areasInternal.map((p) => ({
    value: p.planAreaId,
    text: p.name,
  }));

  const walkTypeIds = walkTypes
    .filter((w) => w.stageTypeId === stageId)
    .map((w) => w.walkTypeId);

  const getStagesWithOpenItemCount = (updatedAreas) => {
    return stages.map((s) => {
      var count = countOpenItems(updatedAreas || planAreasPreview, s.id);
      return {
        ...s,
        label: s.name,
        value: s.name,
        incompleteItems: count,
      };
    });
  };

  const handleLoad = () => {
    stateManager.run(async () => {
      setInitialLoading(true);
      await handleGetProperty();
      await handleGetPropertyPreview();
      await handleGetPlanAreas({ setState: setPlanAreas });
      await handleGetPlanAreas({ setState: setPlanAreasBase });
      await getStages();
      await handleGetWalkTypes();
      await handleGetMaterialCategories();
      await handleGetPropertySections();
      await handleGetStackMeasurementTypes();
      await handleGetStackSizes();
      await handleGetOptionTypes();
      await handleGetOptionsByProperty();
      setInitialLoading(false);
    });
  };

  const updateQueue = (id) => {
    const queue = id
      ? quickModeQueue.filter((item) => item.itemId !== id)
      : getQueueItems(planAreasPreview, stageId);
    setQuickModeQueue(queue);
  };

  const resolveNextItem = () => {
    if (!quickModeQueue.length) {
      createToast(
        "All items are complete for " + stageName,
        TOAST_TYPES.SUCCESS
      );
      handleItemEditReset();
      setQuickMode(false);
      return;
    }
    planItemEdit.reset();
    const queue = [...quickModeQueue];
    const nextItem = queue.shift();

    setSelectedPlanItem(nextItem);
    setQuickModeQueue(queue);
    if (quickModeIndex !== nextItem.areaIndex) {
      if (virtuosoRef.current) {
        virtuosoRef.current.scrollToIndex({
          index: nextItem.areaIndex,
          align: "start",
          behavior: "auto",
        });
      }
      setQuickModeIndex(nextItem.areaIndex);
    }
  };

  const updateMatchingStacks = async (stackData) => {
    const matches = getMatchingItems(planAreasPreview, stackData, [
      "itemName",
      "measurementBackValue",
      "measurementSideValue",
    ]);
    matches.forEach(async (m) => {
      m.cadImageX = stackData.cadImageX;
      m.cadImageY = stackData.cadImageY;

      await handleUpdateWalkItem({
        formData: m,
      });
    });
  };

  const handleItemEditReset = () => {
    planItemEdit.reset();
    setSelectedPlanItem(PLANITEM_FORM_INITIAL_STATE);
  };

  const handleUpdateWalkItemAndMatchingItems = async (itemUpdate) => {
    await handleUpdateWalkItem(itemUpdate);
    if (STACK_STAGES.includes(stageId)) {
      await updateMatchingItems(itemUpdate);
    }
    const { formData } = itemUpdate;
    const id = itemIsIncomplete(formData, stageId) ? null : formData.itemId;
    updateQueue(id);
    handleItemEditReset();
  };

  //if same item is in two stages (e.g., rough and box & wrap) then apply changes to the matching item(s)
  const updateMatchingItems = async ({ formData, resetForm }) => {
    const matchingItems = getMatchingItems(planAreasPreview, formData, [
      "itemName",
      "measurementBackValue",
      "measurementSideValue",
    ]);

    for (const match of matchingItems) {
      const matchFormData = {
        ...formData,
        itemId: match.itemId,
        planAreaId: match.planAreaId,
        propertyPlanAreaId: match.propertyPlanAreaId,
        stageId: match.stageId,
        walkTypeId: match.walkTypeId,
      };

      await handleUpdateWalkItem({
        formData: matchFormData,
        resetForm: resetForm,
      });
    }
  };

  const handleUpdateStack = async (newPos = {}) => {
    stateManager.run(async () => {
      planItemEdit.update({ ...newPos });
      const stack = { ...selectedPlanItem, ...planItemEdit.edits, ...newPos };
      if (quickMode) {
        await handleUpdateWalkItem({
          formData: stack,
          resetForm: handleItemEditReset,
        });
        updateMatchingStacks(stack);
        resolveNextItem();
      }
    });
  };

  const handleClickQuick = () => {
    if (quickMode) {
      setQuickModeIndex(null);
      if (selectedPlanItem.itemId) {
        const queue = [...quickModeQueue];
        queue.unshift({ ...selectedPlanItem });
        setQuickModeQueue(queue);
      }
      return setQuickMode(false);
    }
    setQuickMode(true);
    resolveNextItem();
  };

  //call these
  const handleRefreshOptionsData = async () => {
    stateManager.run(async () => {
      refreshData();
      handleGetOptionsByProperty();
    });
  };

  const handleSetActiveTab = (tab) => {
    if (tab === activeTab) {
      return;
    }
    setSelectedPlanItem(PLANITEM_FORM_INITIAL_STATE);
    setActiveTab(tab);
  };

  const handleDeleteOption = async (option) => {
    stateManager.run(async () => {
      await optionService.delete(option.optionId);
      handleGetOptionsByProperty();
    });
  };

  //drag
  const handleReorderItems = reorderItems({
    planId: property.planId,
    propertyId,
    apiReorder: planService.reorderPropertyPlanItems,
    areaIdParam: "propertyPlanAreaId",
    refreshData,
  });

  useEffect(() => {
    handleLoad();
  }, []);

  const invokeRevert = (planId, stageIds) => {
    stateManager.run(async () => {
      await revertPropertyItems({
        propertyId,
        planId,
        stageIds,
        refreshData,
      });
      handleLoad();
    });
  };

  const refreshPlanAreas = () => {
    stateManager.run(async () => {
      refreshData();
    });
  };

  const refreshPlanAreasAndBoundaries = () => {
    stateManager.run(async () => {
      const response = await cadBoundaryService.getAll({
        propertyId,
        stageTypeId:
          stageId === STAGE_TYPES.boxAndWrap ? STAGE_TYPES.roughIn : stageId,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setCadBoundaries(response.data);

        setRefreshCadImage(!refreshCadImage);
        refreshData();
      }
    });
  };

  const leftColumnOptionsContent = (
    <Fragment>
      <MiSpecsTitle
        icon={
          <StageIcon
            steps={STEPS}
            stepIndex={stages.findIndex((item) => item.name === activeTab)}
          />
        }
      >
        {activeTab} Spec Sheet
      </MiSpecsTitle>
      <MiSpecsSubTitle>Add Options for {stageName}</MiSpecsSubTitle>
      <AvailableOptionsList
        stageId={stageId}
        walkTypeIds={walkTypeIds}
        handleUpdatePropertyOptions={handleUpdatePropertyOptions}
        propertyOptions={propertyOptions}
        propertyOptionsLoading={stateManager.isBusy()}
      />
      <div style={styles.listContainer}>
        <div style={styles.listTitle}>Custom Options</div>
        <MiPlanOptions
          optionName="Custom"
          refreshData={refreshData}
          deleteOption={handleDeleteOption}
          planOptions={planOptions}
          optionTypes={optionTypes}
          saveDraft={saveDraft}
          floors={[]}
          propertySections={propertySections}
          saveAreaActionAdd={saveAreaActionAdd}
          planAreas={planAreas}
          planAreasBase={planAreasBase}
          updateOptionsAfterSubmission={updateOptionsAfterSubmission}
          planAreaSelections={planAreaSelections}
          saveAreaActionRemove={saveAreaActionRemove}
          saveAreaActionUpdate={saveAreaActionUpdate}
          saveOptionRequest={saveOptionRequest}
          materialCategories={materialCategories}
          propertyOptionsLoading={stateManager.isBusy()}
          handleUpdatePropertyOptions={handleUpdatePropertyOptions}
          planItemAction
          walkTypes={walkTypes.filter((w) => w.stageTypeId === stageId)}
          setPreviewTab={setPreviewTab}
          edit={planOptionEdit}
        />
      </div>
    </Fragment>
  );

  const getAllMeasurementTypes = () => {
    const back = measurementTypesBack || [];
    const side = measurementTypesSide || [];
    const walls = back.concat(side);
    return walls;
  };

  const getRoughInStacks = () => {
    if (!planAreasPreview || !planAreasPreview.length) {
      return [];
    }
    const stacks = planAreasPreview?.reduce((acc, area) => {
      if (area?.items?.length > 0) {
        const addStacks = area.items.filter(
          (i) =>
            walkTypes.find((t) => t.walkTypeId === i.walkTypeId) &&
            i.measurementBackTypeId
        );

        const newValue = acc.concat(addStacks);
        return newValue;
      }

      return acc;
    }, []);

    return stacks;
  };

  const leftColumnRoughInContent = (
    <RoughInCanvas
      propertyId={propertyId}
      stageTypeId={STAGE_TYPES.roughIn}
      handleUpdateStack={handleUpdateStack}
      stacks={getRoughInStacks()}
      edit={planItemEdit}
      getStageCadBoundaries={cadBoundaryService.getAll}
      setCadBoundaries={setCadBoundaries}
      cadBoundaries={cadBoundaries}
      measurementTypes={getAllMeasurementTypes()}
      refreshCadImage={refreshCadImage}
    />
  );

  const handleGoBack = () => {
    router.navigate(`/homes/${propertyId}/Details`);
  };

  const updatePlanItemImageQuickMode = async (params) => {
    if (!selectedPlanItem.itemId) {
      resolveNextItem();
    }
    planItemEdit.update({ ...params });
    await handleUpdateWalkItem({
      formData: { ...selectedPlanItem, ...params },
      resetForm: handleItemEditReset,
    });
    resolveNextItem();
  };

  useEffect(() => {
    setQuickModeIndex(null);
    setQuickMode(false);
    updateQueue();
  }, [activeTab]);

  useEffect(() => {
    if (!stageId) {
      return;
    }
    document.body.addEventListener("paste", handlePasteImage);
    document.body.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.removeEventListener("paste", handlePasteImage);
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, [quickMode, selectedPlanItem, stageId]);

  useEffect(() => {
    if (!quickMode) {
      updateQueue();
    }
  }, [initialLoading, planAreasPreview]);

  const handlePasteImage = (e) => {
    if (!quickMode) {
      return;
    }

    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") == -1) {
        continue;
      }
      const blob = items[i].getAsFile();

      getImageBase64FromFile(blob, (x) => handleUpdateImage(x, "top"));
    }
  };

  const handleUpdateImage = (imageData, position) => {
    updatePlanItemImageQuickMode({
      [`${position}Image`]: imageData,
    });
  };

  const handleKeyDown = (e) => {
    if (
      (e.metaKey || e.getModifierState(KEY_MODIFIERS.control)) &&
      e.which === KEY_CODES.q
    ) {
      handleClickQuick();
    }
    if (quickMode && e.which === KEY_CODES.rightArrow) {
      resolveNextItem();
    }
  };

  const getTabContent = () => {
    switch (activeTab) {
      case "Rough In":
        return leftColumnRoughInContent;
      case "Box and Wrap":
        return leftColumnRoughInContent;
      case "Top Out":
        return leftColumnOptionsContent;
      case "Trim":
        return leftColumnOptionsContent;
      default:
        return leftColumnRoughInContent;
    }
  };

  return (
    <Fragment>
      {stateManager.statusTag("homeSpecStatus")}
      <MiBuilderLayout
        innerRef={quickModeRef}
        quickMode={quickMode}
        rightColumnOn={true}
        loading={stateManager.isBusy()}
        header={
          <MiHeader
            color={THEME.BLUE_PRIMARY}
            title={`${property?.addressLine1}, ${property?.cityName}, ${property?.state}`}
            inverse={false}
            icon="arrow_back"
            backAction={handleGoBack}
            fieldSet={getFieldSet("", property)}
          >
            <MiMenuSystem
              canEdit
              icon={mdiCog}
              options={[
                {
                  name: "Publish",
                  action: () => setPublishModal(true),
                },
                {
                  name: "Import CAD Images",
                  action: () => setImportCadModal(true),
                },
                {
                  name: "Import Stacks",
                  action: () => setImportStacksModal(true),
                },
                {
                  name: "Import Rough",
                  action: () => setImportRoughModal(true),
                },
                {
                  name: "Revert to Plan",
                  action: () => setRefreshModal(true),
                },
              ]}
              iconStyles={{ color: "black", height: 30, width: 30 }}
            />
          </MiHeader>
        }
        stepsBar={
          <MiStepsBar
            steps={stages ? getStagesWithOpenItemCount() : stages}
            propertyId={propertyId}
            setActiveTab={handleSetActiveTab}
            activeTab={activeTab}
          />
        }
        rightColumn={(props) => (
          <div>
            <MiPreviewTabs
              tabs={[{ label: "Plan Items" }]}
              previewTab={previewTab}
              setPreviewTab={setPreviewTab}
            />
            <MiPreviewTabs.TabPanel value={previewTab} index={0}>
              <HomeSpecPreview
                {...props}
                quickMode={quickMode}
                planItemEdit={planItemEdit}
                handleClickQuick={handleClickQuick}
                virtuosoRef={virtuosoRef}
                propertySections={propertySections}
                submitPlanItem={handleUpdateWalkItemAndMatchingItems}
                deleteOrRestorePlanItem={handleDeleteOrRestorePlanItem}
                stages={stages}
                walkTypes={walkTypes.filter((w) => w.stageTypeId === stageId)}
                setMaterialsModalOpen={setMaterialsModalOpen}
                setImagePreviewed={setImagePreviewed}
                handleSavePlanArea={handleSavePropertyPlanArea}
                planAreas={planAreasPreview}
                planAreasLoading={stateManager.isBusy()}
                itemDragging={itemDragging}
                setItemDragging={setItemDragging}
                reorderItems={handleReorderItems}
                stackSizes={stackSizes}
                measurementTypesBack={measurementTypesBack}
                measurementTypesSide={measurementTypesSide}
                handleDeletePlanArea={handleDeletePlanArea}
                handleRestorePlanArea={handleRestorePlanArea}
                isStacksContent={STACK_STAGES.includes(stageId)}
                cadBoundaries={cadBoundaries}
                stageId={stageId}
                selectedPlanItem={selectedPlanItem}
                setSelectedPlanItem={setSelectedPlanItem}
              />
            </MiPreviewTabs.TabPanel>
          </div>
        )}
      >
        {!initialLoading && getTabContent()}
      </MiBuilderLayout>
      {publishModal && (
        <PublishModal
          setPublishModal={setPublishModal}
          property={property}
          propertyId={propertyId}
          publish={propertyService.publish}
        />
      )}
      {materialsModalOpen && (
        <MiMaterialsModal
          edit={planItemEdit}
          materialCategories={materialCategories}
          setMaterialsModalOpen={setMaterialsModalOpen}
        />
      )}
      {imagePreviewed && (
        <MiImagePreviewModal
          imagePreviewed={imagePreviewed}
          setImagePreviewed={setImagePreviewed}
        />
      )}
      {refreshModal && (
        <RefreshModal
          invokeRefresh={invokeRevert}
          refreshModal={refreshModal}
          setRefreshModal={setRefreshModal}
          property={property}
          propertyId={propertyId}
        />
      )}
      {importCadModal && (
        <ImportCadModal
          propertyId={propertyId}
          setImportCadModal={setImportCadModal}
          invokeRefresh={refreshPlanAreas}
          stages={stages}
        />
      )}
      {importRoughModal && (
        <ImportRoughModal
          propertyId={propertyId}
          setImportRoughModal={setImportRoughModal}
          invokeRefresh={refreshPlanAreasAndBoundaries}
        />
      )}
      {importStacksModal && (
        <ImportStacksModal
          propertyId={propertyId}
          setImportStacksModal={setImportStacksModal}
          invokeRefresh={refreshPlanAreasAndBoundaries}
        />
      )}
    </Fragment>
  );
};

HomeSpecsInternal.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const HomeSpecs = (props) => {
  return <HomeSpecsInternal {...props} />;
};

export default HomeSpecs;

const styles = {
  listTitle: {
    color: "#32C5FF",
    padding: "24px 8px 6px",
  },
};
