import React, { useState, useEffect } from "react";
import {
  MiPageHeader,
  MiChip,
  MiIcon,
  MiModalConfirm,
  MiSnackbar,
  MiFields,
  MiFloatingAction,
  MiButton,
  MiIconButton,
  MiInputTextbox,
  MiList,
} from "@miview/components";
import {
  QUOTE_STATUS,
  HTTP_STATUSES,
  PAGINATION,
  QUOTE_ATTACHMENT_DISCRIMINATOR,
  en,
} from "@miview/constants";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme, TextField } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { pricingService, accountService } from "@miview/api";
import {
  mdiArrowLeft,
  mdiDelete,
  mdiDownload,
  mdiTrashCanOutline,
  mdiGestureTap,
  mdiCheck,
} from "@mdi/js";
import {
  useRouter,
  useComponentState,
  useEdit,
  useDebounce,
} from "@miview/hooks";
import {
  createFilter,
  base64toBlob,
  openInNewTab,
  getImageBase64FromFile,
  createUUID,
} from "@miview/utils";
import { CancelQuoteModal, QuoteStatus } from "../../components";
import { PriceCompareTable } from "./PriceCompareTable";

const useStyles = makeStyles()((theme) => ({
  pageHeaderDetails: {
    height: 20,
    marginTop: 10,
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.medium,
    color: theme.palette.secondary.bluegrey,
    backgroundColor: theme.palette.light.greyAccent,
    marginLeft: 10,
  },
}));

const uuid = createUUID();

export const QuoteDetails = () => {
  const [quote, setQuote] = useState({});
  const [account, setAccount] = useState({ name: "" });
  const [contacts, setContacts] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [quoteItems, setQuoteItems] = useState([]);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isRequestManuallyModalOpen, setIsRequestManuallyModalOpen] =
    useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [itemSearchTerm, setItemSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [quoteAttachment, setQuoteAttachment] = useState();
  const [addItemValue, setAddItemValue] = useState("");
  const [isCompareMode, setIsCompareMode] = useState(false);

  const debouncedItemSearchTerm = useDebounce(itemSearchTerm, 300);
  const edit = useEdit(quote);
  const attachmentEdit = useEdit(quoteAttachment);
  const theme = useTheme();
  const { classes } = useStyles();
  const stateManager = useComponentState();
  const router = useRouter();

  useEffect(() => {
    quote.accountId && searchItems(debouncedItemSearchTerm);
  }, [debouncedItemSearchTerm]);

  useEffect(() => {
    fetchQuote();
  }, []);

  const fetchQuote = async () => {
    stateManager.run(async () => {
      const response = await pricingService.quotes.get(router.params.id);
      if (response.status === HTTP_STATUSES.OK) {
        setQuote(response.data);
        setQuoteItems(response.data.quoteItems);
        setQuoteAttachment(
          response.data.attachments?.find(
            (e) =>
              e.discriminator ===
              QUOTE_ATTACHMENT_DISCRIMINATOR.QUOTE_ATTACHMENT
          )
        );
        fetchAccountById(response.data.accountId);
        fetchContactsByAccountId(response.data.accountId);
      }
    });
  };

  const fetchContactsByAccountId = async (accountId) => {
    stateManager.run(async () => {
      const params = {};
      params.filters = createFilter().equals({
        accountId: accountId,
      });
      const response = await accountService.contacts.getAll({ ...params });
      if (response.status === HTTP_STATUSES.OK) {
        setContacts(response.data);
      }
    });
  };

  const fetchAccountById = async (accountId) => {
    stateManager.run(async () => {
      const response = await accountService.accounts.get(accountId);
      if (response.status === HTTP_STATUSES.OK) {
        setAccount(response.data);
      }
    });
  };

  const handleDeleteItem = async () => {
    stateManager.run(async () => {
      const response = await pricingService.quotes.removeItem({
        quoteItemId: selectedRow.id,
        successMessage: en.itemDeletedSuccessful,
      });
      if (response.status === HTTP_STATUSES.NO_CONTENT) {
        const updatedQuoteItems = quoteItems.filter(
          (x) => x.id != selectedRow.id
        );
        setQuoteItems(updatedQuoteItems);
      }
      closeDeleteModal();
    });
  };

  const searchItems = (search) => {
    stateManager.abort(uuid);
    stateManager.run(async (signal) => {
      const response = await accountService.itemCost.getAll(quote.accountId, {
        sort: [{ field: "itemName", sort: "asc" }],
        params: { pageSize: 20, search },
        signal,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setSearchResults(response.data);
      }
    }, uuid);
  };

  const handleAddAttachment = async () => {
    if (attachmentEdit.getValue("base64Attachment") === undefined) {
      return;
    }
    stateManager.run(async () => {
      if (quoteAttachment) {
        const response = await pricingService.quoteAttachments.delete({
          id: quoteAttachment.id,
        });
        if (response.status !== HTTP_STATUSES.NO_CONTENT) {
          return;
        }
        setQuoteAttachment();
      }

      if (attachmentEdit.getValue("base64Attachment")) {
        const response = await pricingService.quotes.createAttachment(
          quote.id,
          {
            ...quoteAttachment,
            ...attachmentEdit.edits,
            discriminator: QUOTE_ATTACHMENT_DISCRIMINATOR.QUOTE_ATTACHMENT,
          }
        );
        if (response.status === HTTP_STATUSES.CREATED) {
          setQuoteAttachment(response.data);
          attachmentEdit.reset();
        }
      } else {
        attachmentEdit.reset();
      }
    });
  };

  const updateQuoteAndAttachment = async () => {
    handleAddAttachment();
    handleUpdateQuote();
  };

  const handleUpdateQuote = async () => {
    stateManager.run(async () => {
      const payload = { ...edit.edits };
      const response = await pricingService.quotes.update({
        id: quote.id,
        request: payload,
        successMessage: en.quoteSaveSuccessful,
      });
      if (response.status === HTTP_STATUSES.OK) {
        setQuote(response.data);
        handleCancelClick();
      }
    });
  };

  const handleDownload = async () => {
    const response = await pricingService.quotes.getQuoteExcel({
      quoteId: quote.id,
      successMessage: en.downloadSuccessful,
    });

    if (response.status === HTTP_STATUSES.OK) {
      downloadExcelInBrowser(response.data);
    }
  };
  const cancelQuote = async (reason) => {
    const response = await pricingService.quotes.update({
      id: router.params.id,
      request: { status: QUOTE_STATUS.CANCELLED, cancelReason: reason },
      successMessage: en.quoteCancelled,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setQuote(response.data);
      closeModals();
    }
  };

  const updateQuoteStatus = async (quoteStatus, sucessMessage) => {
    const response = await pricingService.quotes.update({
      id: router.params.id,
      request: { ...edit.edits, status: quoteStatus },
      successMessage: sucessMessage,
    });
    if (response.status === HTTP_STATUSES.OK) {
      setQuote(response.data);
      edit.reset();
      closeModals();
    }
  };

  const downloadExcelInBrowser = (data) => {
    const outputFilename = `${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  const handleAddItem = async (item) => {
    if (!item) {
      return;
    }
    stateManager.run(async () => {
      const payload = {
        accountId: quote.accountId,
        itemId: item.itemId,
        displaySequence:
          Math.max(...quoteItems.map((o) => o.displaySequence)) + 1,
      };
      const response = await pricingService.quotes.createItem({
        quoteId: quote.id,
        data: payload,
        successMessage: `${item.itemName} added successfully`,
      });

      if (response.status === HTTP_STATUSES.CREATED) {
        const updatedQuoteItems = [...quoteItems, response.data];
        setQuoteItems(updatedQuoteItems);
        setAddItemValue("");
      }
    });
  };

  const openDeleteModal = (row) => {
    setSelectedRow(row);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedRow(null);
  };

  const openCancelModal = () => {
    setIsCancelModalOpen(true);
  };

  const closeModals = () => {
    setIsCancelModalOpen(false);
    setIsRequestManuallyModalOpen(false);
    setIsAcceptModalOpen(false);
  };

  const renderBackArrow = () => {
    return (
      <MiIcon
        path={mdiArrowLeft}
        size={1}
        color={theme.palette.primary.main}
        style={{ cursor: "pointer" }}
        onClick={router.goBack}
      />
    );
  };

  const pageHeaderDetails = () => {
    return (
      <>
        <MiChip
          label={quote.quoteNumber}
          className={classes.pageHeaderDetails}
        />

        <MiChip
          label={` ${quoteItems.length} Item(s)`}
          className={classes.pageHeaderDetails}
        />
        <QuoteStatus
          value={quote.status}
          pageHeaderDetails={classes.pageHeaderDetails}
        />
      </>
    );
  };

  const handleItemUpdate = async (updatedItem) => {
    const payload = {
      sku: updatedItem.sku,
      quotePrice: updatedItem.quotePrice,
    };

    const response = await pricingService.quotes.updateItem({
      quoteItemId: updatedItem.id,
      data: payload,
      successMessage: "Quote item saved",
    });
    if (response.status === HTTP_STATUSES.OK) {
      return response.data;
    }
  };

  const columns = [
    {
      field: "itemName",
      headerName: en.itemNumber,
      flex: 1,
    },
    {
      field: "description",
      headerName: en.description,
      flex: 1,
    },
    {
      field: "sku",
      headerName: en.sku,
      flex: 1,
      editable: quote.status === QUOTE_STATUS.REQUESTED,
      renderCell: (params) => {
        if (quote.status === QUOTE_STATUS.REQUESTED) {
          return <MiInputTextbox minWidth={1} value={params.value} disabled />;
        }
        return <div>{params.value}</div>;
      },
    },
    {
      field: "unitofmeasure",
      headerName: en.unitOfMeasure,
      flex: 1,
    },
    {
      field: "currentPrice",
      headerName: en.currentCost,
      flex: 1,
      renderCell: (params) => {
        return <div>${params.value}</div>;
      },
    },
    {
      field: "quotePrice",
      headerName: en.newCost,
      flex: 1,
      type: "number",
      editable: quote.status === QUOTE_STATUS.REQUESTED,
      renderCell: (params) => {
        if (quote.status === QUOTE_STATUS.REQUESTED) {
          return (
            <MiInputTextbox minWidth={1} value={`$${params.value}`} disabled />
          );
        }
        return <div>${params.value}</div>;
      },
    },
    {
      field: "id",
      flex: 1,
      headerName: "",
      align: "right",
      hide: quote.status !== QUOTE_STATUS.DRAFT,
      renderCell: (row) => {
        return (
          <MiIcon
            path={mdiDelete}
            color={theme.palette.primary.red}
            onClick={() => {
              openDeleteModal(row);
            }}
          />
        );
      },
    },
  ];

  const isFormValid = () => {
    return (
      edit.allFilled(
        "description",
        "effectiveDate",
        "softExpirationDate",
        "externalQuoteNumber"
      ) && quoteAttachment
    );
  };

  const renderActions = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {quote.status === QUOTE_STATUS.DRAFT && (
          <MiIconButton onClick={handleDownload}>
            <MiIcon path={mdiDownload} />
          </MiIconButton>
        )}
        {quote.status === QUOTE_STATUS.REQUESTED && (
          <MiButton
            inverse
            onClick={() => setIsAcceptModalOpen(true)}
            color={theme.palette.primary.main}
            title={en.markQuoteAcceptedButton}
            disabled={editing || !isFormValid()}
          />
        )}
        {quote.status === QUOTE_STATUS.DRAFT && (
          <MiButton
            inverse
            onClick={() => setIsRequestManuallyModalOpen(true)}
            color={theme.palette.primary.main}
            title={en.markQuoteRequestedButton}
          />
        )}
        {quote.status === QUOTE_STATUS.REQUESTED && (
          <MiButton
            inverse
            onClick={openCancelModal}
            color={theme.palette.primary.main}
            title={en.markQuoteCancelledButton}
          />
        )}
        <MiButton
          inverse
          onClick={() => setIsCompareMode(!isCompareMode)}
          color={theme.palette.primary.main}
          title="Price Compare"
        />
      </div>
    );
  };

  const getAttachmentUri = () => {
    const uri = attachmentEdit.getValue("uri");
    if (uri) {
      return uri;
    }

    const attachment = attachmentEdit.getValue("base64Attachment");
    if (attachment) {
      const mimeRegex = /:(.*);/;
      const mimeType = attachment.match(mimeRegex)[1];
      const data = attachment.split(",")[1];
      const chunkedBinary = base64toBlob(data, mimeType);
      return URL.createObjectURL(chunkedBinary);
    }
  };

  const viewAttachment = () => {
    const uri = getAttachmentUri();
    if (!uri) {
      return;
    }
    openInNewTab(uri);
  };

  const FIELDSET_COLUMNS = [
    {
      id: 1,
      value: edit.getValue("contactId"),
      label: en.recipient,
      type: "select",
      options: contacts,
      valueField: "id",
      labelField: "displayName",
      onChange: (e) => edit.update({ contactId: e.target.value }),
    },
    {
      id: 2,
      required: true,
      value: edit.getValue("description"),
      label: en.description,
      xs: 3,
      onChange: (e) => edit.update({ description: e.target.value }),
    },
    {
      id: 3,
      required: true,
      value: edit.getValue("effectiveDate"),
      label: en.effective,
      type: "date",
      textColor: theme.palette.primary.main,
      onChange: (value) => edit.update({ effectiveDate: value }),
    },
    {
      id: 4,
      required: true,
      value: edit.getValue("softExpirationDate"),
      label: en.expiration,
      type: "date",
      textColor: theme.palette.primary.main,
      onChange: (value) => edit.update({ softExpirationDate: value }),
    },
    {
      id: 5,
      required: true,
      value: edit.getValue("externalQuoteNumber"),
      label: en.supplierQuoteNumber,
      onChange: (e) => edit.update({ externalQuoteNumber: e.target.value }),
    },
    {
      id: 6,
      required: true,
      filename: attachmentEdit.getValue("displayName"),
      label: en.attachment,
      type: "file",
      onChange: (e) => {
        const file = e.target.files[0];
        if (file) {
          getImageBase64FromFile(file, (base64, filename) =>
            attachmentEdit.update({
              base64Attachment: base64,
              displayName: filename,
            })
          );
        }
      },
      onClear: () =>
        attachmentEdit.update({
          base64Attachment: null,
          displayName: null,
        }),
      onView: viewAttachment,
    },
  ];

  const handleCancelClick = () => {
    edit.reset();
    attachmentEdit.reset();
    setEditing(false);
  };

  const closeCancelModal = () => {
    setIsCancelModalOpen(false);
  };

  const renderCancelQuoteModal = () => {
    return (
      <CancelQuoteModal
        isCancelModalOpen={isCancelModalOpen}
        closeCancelModal={closeCancelModal}
        cancelQuote={cancelQuote}
      />
    );
  };

  return (
    <>
      {stateManager.statusTag("quoteDetailsTag")}
      <>
        <MiPageHeader
          title={"Request Quote - " + account.name}
          color={theme.palette.primary.main}
          leftIcon={renderBackArrow()}
          details={pageHeaderDetails()}
          loading={stateManager.isBusy()}
          action={renderActions()}
        />
        <MiFields
          columns={FIELDSET_COLUMNS}
          edit={edit}
          backgroundColor={theme.palette.light.grey}
          disabled={!editing}
        />
        {quote?.status === QUOTE_STATUS.DRAFT && (
          <Autocomplete
            getOptionLabel={(item) => item.itemName || ""}
            value={addItemValue}
            label={en.items}
            inputValue={itemSearchTerm}
            onInputChange={(_, value) => setItemSearchTerm(value)}
            onChange={(_, value) => {
              setAddItemValue(value);
              return handleAddItem(value);
            }}
            options={searchResults}
            renderInput={(params) => (
              <TextField {...params} label={en.addItems} />
            )}
            disabled={false}
            clearOnBlur={false}
            freeSolo={false}
            required
            isOptionEqualToValue={(option, value) =>
              option.itemId === value.itemId
            }
            autoHighlight
          />
        )}
        {isCompareMode ? (
          <PriceCompareTable quoteItems={quoteItems} />
        ) : (
          <MiList
            data={quoteItems}
            columns={columns}
            editMode="row"
            processRowUpdate={handleItemUpdate}
            experimentalFeatures={{ newEditingApi: true }}
            hideFilters
            disableColumnMenu
            paginationMode={PAGINATION.CLIENT}
          />
        )}
        <MiModalConfirm
          open={isDeleteModalOpen}
          title={en.deleteQuoteItem}
          handleNegative={closeDeleteModal}
          handlePositive={handleDeleteItem}
          description={en.deleteQuoteItemAlert}
          color={theme.palette.primary.red}
          icon={mdiTrashCanOutline}
        />
        {renderCancelQuoteModal()}
        <MiModalConfirm
          open={isRequestManuallyModalOpen}
          title={en.requestedManually}
          handleNegative={() => setIsRequestManuallyModalOpen(false)}
          handlePositive={() =>
            updateQuoteStatus(
              QUOTE_STATUS.REQUESTED,
              en.quoteMarkedRequestedManually
            )
          }
          description={en.requestManuallyAlert}
          color={theme.palette.primary.grey}
          icon={mdiGestureTap}
        />
        <MiModalConfirm
          open={isAcceptModalOpen}
          title={en.acceptQuote}
          handleNegative={() => setIsAcceptModalOpen(false)}
          handlePositive={() =>
            updateQuoteStatus(QUOTE_STATUS.ACCEPTED, en.acceptQuoteMessage)
          }
          description={en.acceptQuoteAlert}
          color={theme.palette.primary.green}
          icon={mdiCheck}
        />

        <MiSnackbar
          visible={editing}
          justifyCenter={false}
          cancelTitle={en.cancel}
          handleCancelClick={handleCancelClick}
          saveTitle={en.save}
          handleSaveClick={updateQuoteAndAttachment}
        />
        {(quote?.status === QUOTE_STATUS.DRAFT ||
          quote?.status === QUOTE_STATUS.REQUESTED) &&
          !editing && (
            <MiFloatingAction
              title={en.edit}
              handleClick={() => setEditing(true)}
            />
          )}
      </>
    </>
  );
};
