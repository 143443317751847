import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Icon from "@mdi/react";
import {
  mdiOpenInNew,
  mdiProgressCheck,
  mdiMinusCircleOffOutline,
  mdiArchiveOutline,
  mdiPencil,
  mdiTrashCanOutline,
  mdiAccountCheck,
  mdiAccountOffOutline,
} from "@mdi/js";
import { ROW_ACTIONS, en } from "@miview/constants";
import { MiOpenLinkInNewTab } from "../index.ts";

const useStyles = makeStyles()((theme, { disable }) => ({
  textSpace: {
    marginLeft: 13,
    fontSize: theme.fontSize.medium,
    color: disable
      ? theme.palette.secondary.grey
      : theme.palette.secondary.bluegrey,
  },
  Box: {
    height: 45,
  },
}));

const types = {
  [ROW_ACTIONS.openInNewTab]: {
    icon: mdiOpenInNew,
    text: en.openInNewTab,
    isNewTab: true,
  },
  [ROW_ACTIONS.active]: {
    icon: mdiProgressCheck,
    text: en.makeActive,
  },
  [ROW_ACTIONS.inActive]: {
    icon: mdiMinusCircleOffOutline,
    text: en.makeInActive,
  },
  [ROW_ACTIONS.archive]: {
    icon: mdiArchiveOutline,
    text: en.archive,
  },
  [ROW_ACTIONS.edit]: {
    icon: mdiPencil,
    text: en.edit,
  },
  [ROW_ACTIONS.delete]: {
    icon: mdiTrashCanOutline,
    text: en.delete,
  },
  [ROW_ACTIONS.active2]: {
    icon: mdiAccountCheck,
    text: en.makeActive,
  },
  [ROW_ACTIONS.inActive2]: {
    icon: mdiAccountOffOutline,
    text: en.makeInActive,
  },
};

export const MiMoreActionItem = React.memo(({ type, disable, to }) => {
  const { classes } = useStyles({ disable });
  const theme = useTheme();

  const renderActionItem = () => {
    return (
      <Box display="flex" alignItems="center" className={classes.Box}>
        <Icon
          path={types[type].icon}
          color={
            disable ? theme.palette.secondary.grey : theme.palette.dark.bluegrey
          }
          size={1}
        />
        <Typography className={classes.textSpace}>
          {types[type].text}
        </Typography>
      </Box>
    );
  };

  const renderAction = () => {
    const item = renderActionItem();
    if (types[type].isNewTab) {
      return <MiOpenLinkInNewTab to={to}>{item}</MiOpenLinkInNewTab>;
    }
    return item;
  };

  return <>{renderAction()}</>;
});

MiMoreActionItem.propTypes = {
  type: PropTypes.string.isRequired,
  disable: PropTypes.bool,
  to: PropTypes.string,
};
