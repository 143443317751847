import React from "react";
import PropTypes from "prop-types";
import { Grid, Container } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import CustomerInfoForm from "../../../../components/CustomerInfoForm";
import TermsAndRatesForm from "../../../../components/TermsAndRatesForm";

const useStyles = makeStyles()(() => ({
  maxWidthContainer: {
    marginTop: "25px",
    padding: "0px 20px 70px 40px",
  },
}));

const CreatePayment = ({
  isValid,
  paymentTerms,
  edit,
  setDuplicateAccounts,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <Container className={classes.maxWidthContainer}>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <CustomerInfoForm
              editMode={true}
              edit={edit}
              isMissingRequiredField={!isValid}
              setDuplicateAccounts={setDuplicateAccounts}
            />
          </Grid>
          <Grid item xs={6}>
            <TermsAndRatesForm
              editMode={true}
              edit={edit}
              paymentTerms={paymentTerms}
              isMissingRequiredField={!isValid}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

CreatePayment.propTypes = {
  paymentTerms: PropTypes.array.isRequired,
};
export default CreatePayment;
