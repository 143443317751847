import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const peopleService = {
  getAll: async ({ params, filters, signal } = {}) => {
    const options = await apiOptions({
      url: `${API_V3}/persons`,
      method: "get",
      params,
      filters,
      signal,
    });

    return await apiRequest(options);
  },
};
