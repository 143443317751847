import React, { useEffect, useState } from "react";
import { MiTable, MiLoader } from "@miview/components";
import { integrationService } from "@miview/api";
import { IMPORT_STATUSES, HTTP_STATUSES } from "@miview/constants";
import { createFilter } from "@miview/utils";

const COLUMNS = [
  {
    hideable: false,
    groupable: false,
    field: "addressLine1",
    headerName: "Address",
    flex: 1,
  },
  {
    field: "builderName",
    headerName: "Builder",
    flex: 1,
  },
  {
    field: "communityName",
    headerName: "Community",
    flex: 1,
  },
];

export const AcceptedWork = ({ apiEntity, show }) => {
  const [work, setWork] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const filterHelper = createFilter();

  useEffect(() => {
    fetchAcceptedWork();
  }, [show]);

  const fetchAcceptedWork = async () => {
    try {
      setIsLoading(true);
      const params = {
        pageSize: 999,
      };
      const filters = filterHelper.equals({
        acceptStatus: IMPORT_STATUSES.ACCEPTED,
      });
      const response = await integrationService[apiEntity].getAll(
        params,
        filters
      );
      if (response.status === HTTP_STATUSES.OK) {
        setWork(response.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <MiLoader type={"linear"} />}
      <div style={{ padding: 10 }}>
        <MiTable columns={COLUMNS} rows={work} />
      </div>
    </>
  );
};
