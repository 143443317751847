import { useState, useEffect } from "react";
import { useComponentState } from "../useComponentState/useComponentState";
import { paySchedulesService, payPeriodService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import moment from "moment";
import { createFilter } from "@miview/utils";

export const usePayPeriods = () => {
  const [payPeriods, setPayPeriods] = useState([]);
  const [selectedPayPeriod, setSelectedPayPeriod] = useState();
  const [selectedMultiPayPeriod, setSelectedMultiPayPeriod] = useState([]);

  const stateManager = useComponentState();

  useEffect(() => {
    fetchPayPeriods();
  }, []);

  const fetchPayPeriods = async () => {
    stateManager.run(async () => {
      const response = await paySchedulesService.getAll();

      if (response.status === HTTP_STATUSES.OK) {
        const [paySchedule] = response.data;
        await fetchPayPeriodsBySchedule(paySchedule.id);
      }
    });
  };

  const fetchPayPeriodsBySchedule = async (payScheduleId) => {
    const fromDate = moment().subtract(31, "days").format("YYYY-MM-DD");
    const toDate = moment().add(31, "days").format("YYYY-MM-DD");

    const filters = createFilter().inRange({
      payDate: [fromDate, toDate],
    });
    const response = await payPeriodService.getAll({
      payScheduleId,
      sort: [{ field: "payDate", sort: "desc" }],
      filters,
    });

    if (response.status === HTTP_STATUSES.OK) {
      const formattedPayPeriods = response.data.map((payPeriod) => {
        return {
          ...payPeriod,
          value: payPeriod.payDate,
          label: `${renderDate(
            payPeriod.startDate,
            "MM/DD/YYYY"
          )} - ${renderDate(payPeriod.endDate, "MM/DD/YYYY")}`,
          secondaryInfo: `${renderDate(payPeriod.payDate, "MM/DD/YYYY")}`,
        };
      });
      setPayPeriods(formattedPayPeriods);
      const nextImmediatePayPeriod = formattedPayPeriods.findLast((x) =>
        moment(x.value).isSameOrAfter(moment(), "day")
      );
      setSelectedPayPeriod(nextImmediatePayPeriod?.value);
    }
  };

  const renderDate = (val, dateFormat) => {
    return val ? moment(val).utc().format(dateFormat) : "";
  };

  return {
    payPeriods,
    selectedPayPeriod,
    setSelectedPayPeriod,
    selectedMultiPayPeriod,
    setSelectedMultiPayPeriod,
  };
};
