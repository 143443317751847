import React, { useState, useEffect } from "react";
import { walkService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { useEdit, useComponentState } from "@miview/hooks";
import {
  MiModal,
  MiDatePickerInput,
  MiInputSelectBox,
} from "@miview/components";
import { useTheme } from "@mui/material/styles";

const optionSort = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

export const AssignWorkModal = (props) => {
  const { open, onClose, walkId, walkDetails } = props;
  const [assignableWorkerOptions, setAssignableWorkerOptions] = useState([]);

  const theme = useTheme();
  const edit = useEdit(walkDetails);
  const stateManager = useComponentState();

  useEffect(() => {
    getAssignableUsers();
  }, [walkId]);

  useEffect(() => {
    if (!open) {
      edit.reset();
    }
  }, [open]);

  const getAssignableUsers = async () => {
    stateManager.run(async () => {
      const response = await walkService.getAssignableUsers(walkId);
      if (response.status === HTTP_STATUSES.OK) {
        setAssignableWorkerOptions(
          response.data
            .map((s) => ({
              label: `${s.name} - ${s.titleName}`,
              value: s.userId,
            }))
            .sort(optionSort)
        );
      }
    });
  };

  const submitDueDateChange = async () => {
    stateManager.run(async () => {
      const response = await walkService.assignUserToWalk(walkId, {
        ...walkDetails,
        ...edit.edits,
      });
      if (response.status === HTTP_STATUSES.OK) {
        onClose();
      }
    });
  };

  return (
    <MiModal
      isCloseButton
      title="Assign"
      open={open}
      onClose={onClose}
      actions={[
        {
          onClick: onClose,
          color: theme.palette.primary.grey,
          name: "Cancel",
        },
        {
          onClick: submitDueDateChange,
          color: theme.palette.primary.main,
          name: "Save",
          inverse: true,
          disabled:
            !edit.getValue("scheduledStartDate") || stateManager.isBusy(),
        },
      ]}
      loading={stateManager.isBusy()}
    >
      <div style={{ margin: 16 }}>
        <MiDatePickerInput
          type="date"
          value={edit.getValue("scheduledStartDate")}
          handleChange={(val) => edit.update({ scheduledStartDate: val })}
          labelText="Scheduled Date"
          labelTextColor={theme.palette.secondary.grey}
          inputFormat="MM/DD/YYYY"
          dateFormat="YYYY-MM-DDT00:00:00Z"
          allowSameDateSelection
          required
        />
      </div>
      <div style={{ margin: 16 }}>
        <MiInputSelectBox
          menuItems={assignableWorkerOptions}
          labelText="Assign To"
          labelTextColor={theme.palette.secondary.grey}
          value={edit.getValue("assignedUserId") || ""}
          handleChange={(e) => edit.update({ assignedUserId: e.target.value })}
        />
      </div>
    </MiModal>
  );
};
