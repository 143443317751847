import React, { useEffect } from "react";
import { MiTHEME } from "@miview/theme";
import { paySchedulesService } from "@miview/api";
import { MiInputTextbox, MiCheckbox, MiModal } from "@miview/components";
import { createToast } from "@miview/toast";
import { TOAST_TYPES, HTTP_STATUSES } from "@miview/constants";
import { useComponentState, useEdit } from "@miview/hooks";
import { useTheme } from "@mui/material/styles";

export const CreateEditPayScheduleModal = (props) => {
  const { open, schedule, onClose } = props;

  const stateManager = useComponentState();
  const edit = useEdit(schedule);
  const theme = useTheme();

  const isEdit = !!schedule;

  useEffect(() => {
    edit.reset();
  }, [open, schedule]);

  const addPaySchedule = async () => {
    stateManager.run(async () => {
      const response = await paySchedulesService.create({
        ...edit.edits,
        active: true,
        settings: "",
        payDateSettings: "",
      });
      if (response.status === HTTP_STATUSES.OK) {
        createToast("Pay Schedule Created Successfully", TOAST_TYPES.SUCCESS);
        onClose();
      }
    });
  };

  const updatePaySchedule = async () => {
    stateManager.run(async () => {
      const response = await paySchedulesService.update({
        id: schedule.id,
        request: {
          ...edit.edits,
          active: true,
        },
      });
      if (response.status === HTTP_STATUSES.OK) {
        createToast("Pay Schedule Edited Successfully", TOAST_TYPES.SUCCESS);
        onClose();
      }
    });
  };

  const actions = [
    {
      onClick: onClose,
      color: theme.palette.primary.grey,
      name: "Cancel",
      inverse: false,
    },
    {
      onClick: isEdit ? updatePaySchedule : addPaySchedule,
      color: theme.palette.primary.green,
      name: "Submit",
      inverse: true,
      disabled: stateManager.isBusy() || !edit.getValue("name"),
    },
  ];

  return (
    <MiModal
      title={isEdit ? "Edit Pay Schedule" : "Add Pay Schedule"}
      open={open}
      onClose={onClose}
      actions={actions}
    >
      <MiInputTextbox
        labelText="Name"
        style={{ width: "500px" }}
        labelTextColor={MiTHEME.palette.secondary.grey}
        handleChange={(e) => edit.update({ name: e.target.value })}
        value={edit.getValue("name")}
        required
      />
      <MiCheckbox
        label="Default"
        style={{ paddingLeft: "10px" }}
        checked={edit.getValue("isDefault")}
        onChange={() => edit.update({ isDefault: !edit.getValue("isDefault") })}
      />
    </MiModal>
  );
};
