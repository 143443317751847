import React from "react";
import { Popover, Box } from "@mui/material";

export const MiPopover = ({
  children,
  boxMinWidth,
  boxMaxHeight,
  anchorTarget,
  onClose,
}) => {
  return (
    <Popover
      open={Boolean(anchorTarget)}
      anchorEl={anchorTarget}
      style={{ marginTop: "5px" }}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box sx={{ minWidth: boxMinWidth, maxHeight: boxMaxHeight }}>
        {children}
      </Box>
    </Popover>
  );
};

MiPopover.defaultProps = {
  boxMinWidth: "500px",
  boxMaxHeight: "500px",
};
