import React, { useState, useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import { SubHeader } from ".";
import en from "../locale/en.json";
import { MiInputTextbox, MiInputSelectBox } from "@miview/components";
import { makeStyles } from 'tss-react/mui';
import { accountService } from "@miview/api";
import { createFilter } from "@miview/utils";
import { HTTP_STATUSES, ACCOUNT_STATUS } from "@miview/constants";
import { useDebounce } from "@miview/hooks";

const useStyles = makeStyles()(() => ({
  formAlign: {
    marginTop: 15,
    paddingRight: 10,
  },
}));

const BasicInfoForm = ({
  editMode,
  edit,
  isMissingRequiredField,
  setDuplicateAccounts,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [accountNameHelperText, setAccountNameHelperText] = useState("");
  const debouncedAccountName = useDebounce(edit.getValue("name"), 300);

  useEffect(() => {
    validateAccountName(edit.getValue("name"));
  }, [debouncedAccountName]);

  const verifyDuplicateAccountsByName = async (accountName) => {
    const filters = createFilter().equals({
      name: accountName,
    });
    const response = await accountService.accounts.getAll({ filters });
    if (response.status === HTTP_STATUSES.OK) {
      const accounts = response.data;
      const accountNames = accounts
        .filter((x) => x.id != edit.getValue("id"))
        .map((x) => x.name);

      if (accountNames.length) {
        setAccountNameHelperText(en.duplicateSupplierName);
        setDuplicateAccounts(true);
      } else {
        setAccountNameHelperText("");
        setDuplicateAccounts(false);
      }
    }
  };

  const validateAccountName = async (accountName) => {
    if (!accountName) {
      setAccountNameHelperText("Enter a valid Name");
      return;
    }
    verifyDuplicateAccountsByName(accountName);
  };

  const alternateIdNumberError =
    isMissingRequiredField && !edit.getValue("alternateIdNumber");
  const codeError = isMissingRequiredField && !edit.getValue("code");
  const statusError = isMissingRequiredField && !edit.getValue("status");

  const accountStatusOptions = [
    { label: ACCOUNT_STATUS.active, value: ACCOUNT_STATUS.active },
    { label: ACCOUNT_STATUS.archived, value: ACCOUNT_STATUS.archived },
    { label: ACCOUNT_STATUS.inactive, value: ACCOUNT_STATUS.inactive },
    { label: ACCOUNT_STATUS.incomplete, value: ACCOUNT_STATUS.incomplete },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <SubHeader text={en.basicInformation} />
      </Grid>

      <Grid item xs={6} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={
            editMode && accountNameHelperText !== ""
              ? theme.palette.secondary.red
              : theme.palette.primary.main
          }
          labelText={en.accountName}
          required
          helperText={editMode && accountNameHelperText}
          error={editMode && accountNameHelperText !== ""}
          disabled={!editMode}
          value={edit.getValue("name")}
          handleChange={(e) => edit.update({ name: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} className={classes.formAlign}>
        <MiInputSelectBox
          labelTextColor={theme.palette.primary.main}
          labelText={en.status}
          disabled={!editMode}
          menuItems={accountStatusOptions}
          required
          error={statusError}
          disableNone={true}
          displayEmpty={true}
          isPlaceholder={true}
          value={edit.getValue("status") || ""}
          handleChange={(e) => {
            edit.update({ status: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={theme.palette.primary.main}
          labelText={en.shortName}
          required
          helperText={codeError ? "Enter a short name" : ""}
          error={codeError}
          disabled={!editMode}
          value={edit.getValue("code")}
          handleChange={(e) => edit.update({ code: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} className={classes.formAlign}>
        <MiInputTextbox
          labelTextColor={
            alternateIdNumberError
              ? theme.palette.secondary.red
              : theme.palette.primary.main
          }
          labelText={en.alternateIdNumber}
          required
          error={alternateIdNumberError}
          helperText={alternateIdNumberError ? "Enter a valid ID" : ""}
          disabled={!editMode}
          type={"number"}
          value={edit.getValue("alternateIdNumber")}
          handleChange={(e) =>
            edit.update({ alternateIdNumber: e.target.value })
          }
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(BasicInfoForm);
