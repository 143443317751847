import { apiOptions, apiRequest } from "../../utils";
import { API_HEADER, PRICINGSERVICE_BASE_URL, API } from "../../constants";

export const itemCost = {
  getAll: async ({ accountId, filters, sort, params, signal }) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/accounts/${accountId}/itemprices`,
      method: "get",
      headers: API_HEADER,
      params,
      filters,
      sort,
      signal,
    });
    return apiRequest(options);
  },

  getPriceHistoryById: async (accountId, itemId) => {
    const options = await apiOptions({
      url: `${PRICINGSERVICE_BASE_URL}${API}/accounts/${accountId}/items/${itemId}/pricehistory`,
      method: "get",
      headers: API_HEADER,
    });
    return apiRequest(options);
  },
};
