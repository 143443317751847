import React, { createContext, useCallback } from "react";
import { configurationService } from "@miview/api";
import { appStorage } from "@miview/utils";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";

export const AppInsightsContext = createContext(null);

const onErrorComponent = () => <h1>Something went wrong.</h1>;

export const AppInsightsProvider = ({ children, reactPlugin, history }) => {
  const getAppInsightsConnectionString = useCallback(async () => {
    const connectionResponse = await configurationService.getConnectionString();
    appStorage.setItem("connectionString", connectionResponse.data);
    runAppInsights();
  }, []);
  const runAppInsights = () => {
    try {
      const appInsightsInstance = new ApplicationInsights({
        config: {
          connectionString: appStorage.getItem("connectionString"),
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: history },
          },
        },
      });
      appInsightsInstance.loadAppInsights();
      appInsightsInstance.trackPageView();
      appInsightsInstance.addTelemetryInitializer((envelope) => {
        envelope.data.applicationName = "MiView";
        envelope.tags["ai.cloud.role"] = "MiView";
      });

      window.onunhandledrejection = (event) =>
        appInsightsInstance.trackException({ exception: event.reason.stack });
    } catch (e) {
      console.log(e);
    }
  };

  if (appStorage.getItem("connectionString")) {
    runAppInsights();
  }

  return (
    <AppInsightsContext.Provider value={getAppInsightsConnectionString}>
      <AppInsightsErrorBoundary
        onError={onErrorComponent}
        appInsights={reactPlugin}
      >
        {children}
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};
