import { fileService } from "../../index.js";
import { appStorage } from "@miview/utils";
import { CDN_STORAGE_PARAM, CDN_DEFAULT } from "@miview/constants";

export const getAndStoreCDN = async () => {
  const cdnExists = await appStorage.getItem(CDN_STORAGE_PARAM);

  if (!cdnExists) {
    const cdn = await fileService.getCDNURI();
    await appStorage.setItem(CDN_STORAGE_PARAM, cdn.data || CDN_DEFAULT);
  }
};
