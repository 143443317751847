export const breakpoint = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const zIndex = {
  base: 1000,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
};

export const shadows = {
  floating: "0px 0px 7px 3px rgba(0,0,0,0.2)",
  sm: "0px 2px 2px 0px rgba(0,0,0,0.2)",
};
