import React from "react";
import { MiTable, MiIcon } from "@miview/components";
import { mdiDelete, mdiPencil } from "@mdi/js";

const StageWalkFailureReasons = (props) => {
  const { failureReasons, openDeleteReasonModal, handleEditModal } = props;

  const getRowActions = (row) => {
    return [
      {
        text: "Delete",
        onClick: () => openDeleteReasonModal(row.failureTypeId),
        renderIcon: () => <MiIcon path={mdiDelete} size={"20px"} />,
      },
      {
        text: "EDIT",
        onClick: () => handleEditModal(row),
        renderIcon: () => <MiIcon path={mdiPencil} size={"20px"} />,
      },
    ];
  };

  const columns = [
    {
      field: "failureTypeName",
      headerName: "Failure Types",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "stageStatusName",
      headerName: "Status Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "walkStatusName",
      headerName: "Work Status",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "reviewedWorkStatusName",
      headerName: "Reviewed Work Status",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
  ];

  return (
    <div>
      <MiTable
        style={{ minHeight: "100vh" }}
        columns={columns}
        rows={failureReasons}
        getRowActions={getRowActions}
      />
    </div>
  );
};

export default StageWalkFailureReasons;
