import React from "react";
import { MiCard, MiModal, MiDetailFields } from "@miview/components";
import { useTheme } from "@mui/styles";
import { mdiAccount } from "@mdi/js";
import { useEdit } from "@miview/hooks";
import { NOTIFICATION_SUBSCRIPTIONS } from "@miview/constants";

const EditContactModal = ({
  showEditContactModal,
  selectedContact,
  closeEditContactModal,
  handleUpdatePropertyContact,
}) => {
  const theme = useTheme();

  const edit = useEdit(selectedContact);

  const detailFields = [
    {
      label: "Notifications",
      value: edit.getValue("enabledEmails"),
      fieldType: "multiSelect",
      options: NOTIFICATION_SUBSCRIPTIONS,
      setValue: (e) => edit.update({ enabledEmails: e.target.value }),
      width: "100%",
    },
  ];

  const handleUpdate = async () => {
    await handleUpdatePropertyContact({ ...edit.edits });
    edit.reset();
  };

  return (
    <MiModal
      open={showEditContactModal}
      title="Manage Notifications"
      onClose={closeEditContactModal}
      width={"100%"}
      maxWidth={600}
      actions={[
        {
          onClick: closeEditContactModal,
          color: theme.palette.primary.grey,
          name: "Cancel",
        },
        {
          onClick: handleUpdate,
          color: theme.palette.primary.blue,
          name: "Save",
          inverse: true,
        },
      ]}
    >
      <MiCard
        color={theme.palette.secondary.teal}
        icon={mdiAccount}
        title={`${selectedContact.fullName} - ${selectedContact.title}`}
        fieldset={[
          { icon: "email", value: selectedContact.email },
          {
            icon: "phone",
            value: selectedContact.cell
              ? selectedContact.cell
              : selectedContact.work,
          },
        ]}
      />
      <MiDetailFields
        headerProps={{
          icon: "list",
          title: "Builder Email Templates",
        }}
        detailFields={detailFields}
      />
    </MiModal>
  );
};

export default EditContactModal;
