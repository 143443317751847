import React from "react";
import {
  AccountManagement,
  ContactManagement,
  WarehouseLocationManagement,
} from "../../views/applicationSettings";
import {
  DRHorton,
  HistoryDetails,
  Integrations,
} from "../../views/integrations";
import { PERMISSIONS } from "@miview/constants";

export const adminSettingsRoutes = [
  {
    path: "/settings/accountmanagement",
    name: "Account Types",
    element: <AccountManagement />,
    permissionName: PERMISSIONS.CAN_MANAGE_ACCOUNTS,
  },
  {
    path: "/settings/contactmanagement",
    name: "Contact Types",
    element: <ContactManagement />,
  },
  {
    path: "/settings/warehouselocationmanagement",
    name: "Warehouse Locations",
    element: <WarehouseLocationManagement />,
  },
  {
    path: "/settings/integrations",
    name: "Integrations",
    exact: true,
    element: <Integrations />,
  },
  {
    path: "/settings/integrations/drhorton/:activeTab",
    name: "D.R. Horton",
    exact: true,
    element: <DRHorton />,
  },
  {
    path: "/settings/integrations/drhorton/:activeTab/:id",
    name: "History Detail",
    exact: true,
    element: <HistoryDetails />,
  },
];
