import { React, useState } from "react";
import { createUUID } from "@miview/utils";

export const useComponentState = () => {
  const [abortControllersByUuid, setAbortControllersByUuid] = useState({});

  const run = async (task, uuid) => {
    if (!uuid) {
      uuid = createUUID();
    }
    const abortController = new AbortController();
    setAbortControllersByUuid((prev) => {
      return {
        ...prev,
        [uuid]: abortController,
      };
    });
    const result = await task(abortController.signal);
    setAbortControllersByUuid((prev) => {
      delete prev[uuid];
      return { ...prev };
    });

    return result;
  };

  const abort = (uuid) => {
    const item = abortControllersByUuid[uuid];
    if (item) {
      item.abort();
    }
  };

  const isBusy = () => {
    return Object.keys(abortControllersByUuid).length > 0;
  };

  const getStatus = () => {
    if (isBusy()) {
      return "UPDATING";
    }
    return "READY";
  };

  const statusTag = (id) => {
    return (
      <span
        id={id}
        style={{
          visibility: "hidden",
          height: "0",
          width: "0",
          position: "absolute",
        }}
      >
        {getStatus()}
      </span>
    );
  };

  return { run, statusTag, isBusy, abort };
};
