import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { createTheme } from "@miview/theme";
import { Typography } from "@mui/material";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, { props }) => ({
  mainContainer: {
    display: "flex",
    backgroundColor: props.backgroundColor || theme.palette.primary.grey,
    padding: "5px",
    alignItems: "center",
    height: props.containerHeight || "50px",
    flex: 1,
    borderRadius: "3px",
    justifyContent: "space-between",
    color: theme.palette.primary.white,
    fontSize: theme.fontSize.primary,
    fontWeight: theme.fontWeight.primary,
  },
  stepsWrapper: {
    display: "flex",
    height: "85%",
    alignItems: "center",
    minWidth: "60%",
    justifyContent: "center",
  },
  stepsContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor:
      props.stepperBackgroundColor || theme.palette.darkAccent.grey,
    padding: "3px 10px",
    height: props.boxHeight || "100%",
    borderRadius: "3px",
    alignItems: "center",
    overflowX: "hidden",
    width: props.stepperWidth || "90%",
  },
  stepNumber: {
    fontSize: "13px",
    padding: "3px 10px",
    marginRight: "5px",
    borderRadius: "3px",
  },
  stepContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    paddingRight: 15,
  },
}));

export const MiStepperCustom = (props) => {
  const {
    renderNextButton,
    renderPrevButton,
    items,
    activeIndex = 0,
    activeColor,
    handleChange,
    backgroundColor,
    stepperBackgroundColor,
    previousStepsSelectable = false,
    indexCount = true,
    stepperWidth,
    boxHeight,
    containerHeight,
  } = props;
  const activeStepRef = useRef();
  const theme = createTheme();
  const { classes } = useStyles({
    props: {
      backgroundColor,
      stepperBackgroundColor: stepperBackgroundColor,
      stepperWidth,
      boxHeight,
      containerHeight,
    },
  });

  const getStepNumberBackgroundColor = (isActive) => {
    if (isActive) {
      return activeColor || theme.palette.primary.main;
    }
    return theme.palette.primary.white;
  };

  useEffect(() => {
    activeStepRef.current.scrollIntoView({ behavior: "smooth" });
  }, [activeIndex]);

  const getStepNumberTextColor = (isActive) => {
    if (!isActive) {
      return activeColor || theme.palette.primary.main;
    }
    return theme.palette.primary.white;
  };

  return (
    <div className={classes.mainContainer}>
      <div>{renderPrevButton()}</div>
      <div className={classes.stepsWrapper}>
        <div className={classes.stepsContainer}>
          {items.map((item, i) => {
            return (
              <div
                className={classes.stepContainer}
                style={{
                  opacity: i < activeIndex ? 0.5 : 1,
                  pointerEvents:
                    i < activeIndex && !previousStepsSelectable && "none",
                }}
                onClick={() => handleChange(i)}
                key={i}
                ref={activeIndex === i ? activeStepRef : null}
              >
                <div
                  className={classes.stepNumber}
                  style={{
                    backgroundColor: getStepNumberBackgroundColor(
                      i === activeIndex
                    ),
                    color: getStepNumberTextColor(i === activeIndex),
                  }}
                >
                  {i + 1}
                </div>
                <div>
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: theme.fontWeight.medium,
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: theme.fontSize.xsmall,
                      fontWeight: theme.fontWeight.regular,
                    }}
                  >
                    {item.subLabel}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
        {indexCount && (
          <div
            style={{
              marginLeft: "10px",
              minWidth: "60px",
            }}
          >
            {activeIndex + 1} of {items.length}
          </div>
        )}
      </div>
      <div style={{ paddingRight: "5px" }}>{renderNextButton()}</div>
    </div>
  );
};

MiStepperCustom.propTypes = {
  renderNextButton: PropTypes.func.isRequired,
  renderPrevButton: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};
