import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const uiService = {
  getLanguages: async () => {
    const options = await apiOptions({
      url: `${API_V3}/ui/languages`,
      method: "get",
    });
    return apiRequest(options);
  },

  getLayouts: async () => {
    const options = await apiOptions({
      url: `${API_V3}/ui/layouts`,
      method: "get",
    });
    return apiRequest(options);
  },

  getLayout: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/ui/layouts/${id}`,
      method: "get",
    });
    return apiRequest(options);
  },

  createLayout: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/ui/layouts`,
      method: "post",
      data: request,
    });
    return apiRequest(options);
  },

  updateLayout: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/ui/layouts/${id}`,
      method: "post",
      data: request,
    });
    return apiRequest(options);
  },

  deleteLayout: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/ui/layouts/${id}`,
      method: "delete",
    });
    return apiRequest(options);
  },
};
