import React from "react";
import { styled } from "@mui/material";

export const NewColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const StyledColumn = (props) => {
  return <NewColumn>{props.children}</NewColumn>;
};
