import React, { useState } from "react";
import { Chip, MiLink, MiPageHeader, MiList, MiIcon } from "@miview/components";
import { payActivityService } from "@miview/api";
import { useComponentState } from "@miview/hooks";
import { getCurrencyString } from "@miview/utils";
import { useTheme, alpha } from "@mui/material/styles";
import {
  PAY_ACTIVITY_STATUSES,
  PAY_ACTIVITY_STATUS_ID,
  PAY_ACTIVITY_STATUS_SPECS,
} from "@miview/constants";
import { mdiCashSync } from "@mdi/js";

const PayActivity = () => {
  const [payActivity, setPayActivity] = useState([]);

  const stateManager = useComponentState();
  const theme = useTheme();

  const getColor = (val) => {
    return PAY_ACTIVITY_STATUS_SPECS[val].color;
  };

  const getText = (val) => {
    return PAY_ACTIVITY_STATUS_ID[val];
  };

  const columns = [
    {
      field: "payeeName",
      headerName: "Name",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1.2,
    },
    {
      field: "addressLine1",
      headerName: "Address",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (p) => {
        return (
          <MiLink to={`/homes/${p.row.propertyId}/Details`} title={p.value} />
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 0.6,
    },
    {
      field: "communityName",
      headerName: "Community",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "typeName",
      headerName: "Type",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "recordDate",
      headerName: "Date",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "statusId",
      headerName: "Status",
      width: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      type: "singleSelect",
      valueOptions: Object.values(PAY_ACTIVITY_STATUSES).map((e) => ({
        value: e,
        label: getText(e),
      })),
      renderCell: ({ value }) => {
        return (
          <Chip
            text={getText(value)}
            style={{
              display: "flex",
              borderRadius: "30px",
              height: "30px",
              minWidth: "80px",
              justifyContent: "center",
              padding: "0 7px 0 7px",
            }}
            color={alpha(getColor(value), 0.2)}
            textColor={getColor(value)}
          />
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      headerAlign: "left",
      align: "right",
      flex: 0.6,
      valueFormatter: (params) => {
        return getCurrencyString(params.value);
      },
    },
  ];

  const renderHeaderIcon = () => (
    <MiIcon path={mdiCashSync} size={1} color={theme.palette.primary.main} />
  );

  const getPayActivity = async (params) => {
    return stateManager.run(async () => {
      return await payActivityService.getAll(params);
    });
  };

  return (
    <>
      <MiPageHeader
        title={"Pay Activities"}
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
      />
      {stateManager.statusTag("payActivityList")}
      <MiList
        data={payActivity}
        setData={setPayActivity}
        dataProvider={getPayActivity}
        defaultPageSize={50}
        columns={columns}
        hideFilters
      />
    </>
  );
};

export default PayActivity;
