import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiButton } from "@miview/components";
import { MiTHEME } from "@miview/theme";
import {
  AttachFile as AttachFileIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { pricingService } from "@miview/api";

const useStyles = makeStyles()((theme) => ({
  title: {
    color: theme.palette.secondary.bluegrey,
    fontSize: 18,
    fontWeight: 500,
  },
  description: {
    color: theme.palette.dark.bluegrey,
    fontSize: 13,
    textAlign: "center",
    paddingTop: 0,
    paddingBottom: 15,
  },
  textCenter: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
    borderRadius: "50%",
    marginTop: 15,
  },
  actionbtn: {
    justifyContent: "flex-end",
    paddingBottom: 10,
  },
  paper: {
    maxWidth: 420,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding: 15,
  },
  fileList: {
    padding: 0,
    listStyle: "none",
    width: "100%",
    marginBottom: 20,
    "& li": {
      border: `1px solid ${theme.palette.lightAccent.grey}`,
      padding: 10,
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "5px",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.fontSize.medium,
    "& svg": {
      marginRight: 15,
      color: theme.palette.lightTwo.bluegrey,
    },
  },
  closeFileIcon: {
    padding: 0,
    color: theme.palette.secondary.red,
  },
  content: {
    padding: 8,
  },
  progresStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h5": {
      color: theme.palette.primary.grey,
    },
  },
  width100: {
    width: "100%",
    maxWidth: 270,
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.light.teal,
  },

  primaryColor: {
    backgroundColor: theme.palette.primary.teal,
  },

  browseFiles: {
    width: "100%",
    padding: "30px 0 20px",
    textAlign: "center",
  },

  ctrlVStyle: {
    color: theme.palette.dark.bluegrey,
  },
}));

const FileUploadModal = ({
  open,
  onCancel,
  onDone,
  quoteId,
  setAttachmentName,
  attachmentData,
}) => {
  const { classes } = useStyles();
  const [progress, setProgress] = React.useState(0);
  const hiddenFileInput = React.useRef(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [existingData, setExistingData] = useState([]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    setAttachmentName(e.target.files[0].name);
    setFileName(e.target.files[0].name);
    setFile(e.target.files[0]);
  };

  const handleClearUpload = () => {
    setAttachmentName("");
    setFileName("");
    setProgress(0);
  };

  const handleCancel = () => {
    handleClearUpload();
    onCancel();
  };

  const handlePaste = (e) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      setAttachmentName(fileObject.name);
      setFileName(fileObject.name);
      setFile(fileObject);
    } else {
      alert(
        "No image data was found in your clipboard. Copy an image first or take a screenshot."
      );
    }
  };

  const handleUpload = async () => {
    if (fileName.length > 0) {
      if (attachmentData?.length > 0) {
        await Promise.all(
          attachmentData?.map(async (item) => {
            await pricingService.quotes.removeAttahcment(quoteId, item.id);
          })
        );
      }
      if (existingData?.length > 0) {
        await Promise.all(
          existingData?.map(async (item) => {
            await pricingService.quotes.removeAttahcment(quoteId, item.id);
          })
        );
      }
      let config = {
        onUploadProgress: (progressEvent) =>
          setProgress(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          ),
      };

      const resp = await pricingService.quotes.createAttahcment(
        quoteId,
        file,
        fileName,
        config
      );
      if (resp) {
        setExistingData([resp]);
        onDone();
      }
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      classes={{ paper: classes.paper }}
      scroll="body"
    >
      <DialogTitle className={classes.content}>
        <Typography className={classes.title}>Upload Files</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <ul className={classes.fileList}>
          <li>
            <div className={classes.flex}>
              <AttachFileIcon />{" "}
              <Typography component="div" className={classes.filename}>
                {fileName}
              </Typography>
            </div>
            <IconButton className={classes.closeFileIcon} size="large">
              <CloseIcon onClick={handleClearUpload} />{" "}
            </IconButton>
          </li>
        </ul>

        {progress > 0 && (
          <div className={classes.progresStyle}>
            <Typography component="h5">0%</Typography>
            <LinearProgress
              classes={{
                barColorPrimary: classes.primaryColor,
                bar2Indeterminate: classes.primaryColor,
              }}
              className={classes.width100}
              variant="determinate"
              value={progress}
            />
            <Typography component="h5">100%</Typography>
          </div>
        )}

        <div className={classes.browseFiles}>
          <MiButton
            title="Browse Files"
            onClick={handleClick}
            style={{
              fontSize: MiTHEME.fontSize.medium,
              color: MiTHEME.palette.primary.white,
              backgroundColor: MiTHEME.palette.primary.teal,
              height: "36px",
              lineHeight: "36px",
              padding: "6px 14px",
              minWidth: "132px",
              borderRadius: "4px",
              margin: "10px auto",
            }}
          />
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
          <div onPaste={handlePaste} className={classes.ctrlVStyle}>
            (Ctrl + V will paste)
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actionbtn}>
        <MiButton
          style={{
            fontSize: MiTHEME.fontSize.medium,
            color: MiTHEME.palette.primary.bluegrey,
            height: "36px",
            lineHeight: "36px",
            padding: "6px 14px",
            minWidth: "132px",
          }}
          title="Cancel"
          onClick={handleCancel}
        />
        <MiButton
          style={{
            fontSize: MiTHEME.fontSize.medium,
            color: MiTHEME.palette.primary.white,
            backgroundColor: MiTHEME.palette.primary.teal,
            height: "36px",
            lineHeight: "36px",
            padding: "6px 14px",
            minWidth: "132px",
          }}
          title="Done"
          onClick={handleUpload}
        />
      </DialogActions>
    </Dialog>
  );
};

FileUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default React.memo(FileUploadModal);
