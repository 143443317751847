import React, { useState } from "react";
import {
  MiLink,
  MiTable,
  MiInputTextbox,
  MiIcon,
  MiButton,
} from "@miview/components";
import CommunityPlat from "./CommunityPlat";
import { mdiFormatListBulleted, mdiMap, mdiMagnify } from "@mdi/js";

const PropertiesCollapse = (props) => {
  const {
    properties,
    imageUrl,
    communityId,
    refresh,
    searchTerm,
    setSearchTerm,
  } = props;

  const [listViewActive, setListViewActive] = useState(true);

  const columns = [
    {
      field: "addressLine1",
      headerName: "Address",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (p) => (
        <MiLink title={p.value} to={"/homes/" + p.row.propertyId} />
      ),
    },
    {
      field: "builder",
      headerName: "Builder",
      headerAlign: "left",
      align: "left",
      flex: 1,
      resizable: false,
      sortable: false,
    },
    {
      field: "planName",
      headerName: "Plan",
      headerAlign: "left",
      flex: 1,
      align: "left",
      resizable: false,
      sortable: false,
    },
  ];

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <MiButton
          icon={mdiFormatListBulleted}
          onClick={() => setListViewActive(true)}
        />
        <MiButton icon={mdiMap} onClick={() => setListViewActive(false)} />
      </div>
      {listViewActive ? (
        <>
          <MiInputTextbox
            placeholder="Search"
            value={searchTerm}
            handleChange={(e) => setSearchTerm(e.target.value)}
            styles={{ marginBottom: 4, width: "90%", marginTop: -24 }}
            onClick={() => setSearchTerm("")}
            InputProps={{ startAdornment: <MiIcon path={mdiMagnify} /> }}
          />
          <MiTable
            style={{ height: "100vh" }}
            rows={properties}
            columns={columns}
            getRowId={(row) => row.propertyId}
            pageSize={15}
            pagination
            disableColumnMenu
            initialState={{
              sorting: {
                sortModel: [{ field: "addressLine1", sort: "asc" }],
              },
            }}
          />
        </>
      ) : (
        <CommunityPlat
          imageUrl={imageUrl}
          properties={properties}
          communityId={communityId}
          refresh={refresh}
        />
      )}
    </>
  );
};

export default PropertiesCollapse;
