import React, { memo } from "react";
import { getImageBase64FromFile } from "@miview/utils";
import { THEME } from "./theme";

const MiMessageReplyOptions = (props) => {
  const { setFileURL, checkIsValidImageFile } = props;

  return (
    <div style={horizontalBarStyle}>
      <div style={{ padding: 6 }}>
        <label style={attachbuttonWrapper} htmlFor="file-input">
          <i
            style={{ ...clickable, padding: 0, transform: "skewX(-15deg)" }}
            className="material-icons"
            onClick={() => null}
          >
            attach_file
          </i>
        </label>
        <input
          type="file"
          id="file-input"
          style={{ display: "none" }}
          onChange={(e) => {
            if (e.target.files) {
              getImageBase64FromFile(e.target.files[0], setFileURL);
              checkIsValidImageFile(e.target.files[0]);
            }
          }}
        />
      </div>
    </div>
  );
};

const horizontalBarStyle = {
  height: 25,
  backgroundColor: "rgb(241,241,241)",
  borderBottomRightRadius: "10px",
  padding: 5,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flew-start",
  alignItems: "center",
  maxWidth: "70%",
};
const clickable = {
  cursor: "pointer",
  color: THEME.GREY_MEDIUM_LIGHT,
  fontSize: "1.2rem",
  padding: 6,
};
const attachbuttonWrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: 0,
  margin: 0,
};

export default memo(MiMessageReplyOptions);
