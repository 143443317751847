/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiInputSelectBox, MiTable } from "@miview/components";
import { AccountNumber, TdInputBox } from "../../../components";
import {
  RequisitionFooter,
  SelectedFilterBoxLayout,
} from "./Components/RequisitionComponents";
import { BolderText } from "../../../components/TableTdComponents";
import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import SearchRenderItems from "./SearchRenderItems";
import { RequisitionService } from "@miview/api";
import { useEdit } from "@miview/hooks";
import TdSearchInput from "./Components/TdSearchInput";
import { HTTP_STATUSES } from "@miview/constants";
import moment from "moment";
import { getRandomNumber } from "../../../utils";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: "10px 0",
  },

  removeIcon: {
    background: "none!important",
    "& svg": {
      fontSize: 18,
    },
  },

  borderedTable: {
    marginTop: 10,
    "& .MuiDataGrid-cell": {
      border: `1px solid ${theme.palette.accent.grey}!important`,
    },
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: theme.palette.lightOne.grey,
      minHeight: 56,
    },
    "& .MuiDataGrid-autoHeight": {
      minHeight: "auto",
    },
  },
  searchBoxStyle: {
    margin: "0px 0 20px",
  },

  closeSearchStyle: {
    padding: "0 10px",
    background: "none",
    "&:hover": {
      background: "none",
    },
  },
  customFullTextCell: {
    fontWeight: "normal",
    whiteSpace: "normal",
  },
}));

const DrawerItems = ({
  handleSearchListShow,
  searchText,
  setSearchText,
  masterItems = [],
  addTemplateItems,
  templateItems = [],
  setMasterItems,
  removeTemplateItems,
  templateData = {},
  setShowTemplate,
  setTemplateItems,
  onDeleteViewModal,
  onDiscardViewModal,
  getFolderItems,
  templateEdit,
  CloseAll: closeAll,
  showTemplate,
}) => {
  const initialValue = {
    number: getRandomNumber("template"),
    type: "Template",
    name: "",
    templateFolderId: "",
    scheduledDate: moment(new Date()).format("MM/DD/YYYY"),
    requestedBy: 1,
    description: "",
    stage: "7",
    deliveryLocationId: 4,
    issueDate: moment(new Date())?.format("MM/DD/YYYY"),
    requestedDeliveryDate: moment(new Date())?.format("MM/DD/YYYY"),
    status: "Draft",
    requestedBillingDate: moment(new Date())?.format("MM/DD/YYYY"),
    reason: "",
  };
  const theme = useTheme();
  const { classes } = useStyles();
  const [folders, setFolders] = useState([]);
  const edit = useEdit(initialValue);
  const [isMissingRequiredField, setIsMissingRequiredField] = useState(false);
  const requiredFields = ["name", "templateFolderId"];
  useEffect(() => {
    if (Object?.keys(templateData)?.length > 0) {
      let mapped = templateItems.map((item) => ({ [item.id]: item.quantity }));
      let newObj = Object.assign({}, ...mapped);
      const totalData = { ...newObj, ...templateData };
      edit.update(totalData);
    }
  }, [templateData, templateItems]);

  useEffect(() => {
    if (showTemplate.open === false) {
      edit.reset();
    }
  }, [showTemplate]);

  const handleSearchClear = () => {
    setSearchText("");
    setMasterItems([]);
  };

  const getFolder = async () => {
    const resp = await RequisitionService.templateFolders.getAll("", {
      parentId: null,
    });
    if (resp?.status < HTTP_STATUSES.BAD_REQUEST) {
      const temp = resp?.data;
      temp?.forEach((item) => {
        item.value = item?.id;
        item.label = item?.name;
      });
      setFolders(temp);
    }
  };

  useEffect(() => {
    getFolder();
  }, []);

  const renderNewTemplateFilter = () => {
    return (
      <SelectedFilterBoxLayout>
        <Grid container spacing={6}>
          <Grid item md={3} lg={3}>
            <TdInputBox
              labelText="Template Name"
              name="name"
              placeholder="Enter Name"
              variant="outlined"
              fullWidth
              required
              error={isMissingRequiredField && !edit.getValue("name")}
              helperText={isMissingRequiredField && "This is a required field"}
              labelTextColor={
                isMissingRequiredField && !edit.getValue("name")
                  ? theme.palette.secondary.red
                  : theme.palette.primary.main
              }
              value={edit.getValue("name")}
              handleChange={(e) => edit.update({ name: e.target.value })}
            />
          </Grid>
          <Grid item md={3} lg={3}>
            <TdInputBox
              labelText="Description"
              name="description"
              placeholder="Enter Description"
              variant="outlined"
              fullWidth
              value={edit.getValue("description") || ""}
              handleChange={(e) => edit.update({ description: e.target.value })}
            />
          </Grid>
          <Grid item md={3} lg={3}>
            <MiInputSelectBox
              labelText="Folder Location"
              name="templateFolderId"
              variant="outlined"
              fullWidth
              menuItems={folders}
              defaultValue="0"
              required
              error={
                isMissingRequiredField && !edit.getValue("templateFolderId")
              }
              helperText={
                isMissingRequiredField && !edit.getValue("templateFolderId")
                  ? "This is a required field"
                  : ""
              }
              labelTextColor={
                isMissingRequiredField && !edit.getValue("templateFolderId")
                  ? theme.palette.secondary.red
                  : theme.palette.primary.main
              }
              value={edit.getValue("templateFolderId") || ""}
              handleChange={(e) =>
                edit.update({ templateFolderId: e.target.value })
              }
            />
          </Grid>
          <Grid item md={3} lg={3}>
            <TdInputBox
              labelText="Tag"
              name="reason"
              placeholder="Enter Tag"
              variant="outlined"
              fullWidth
              value={edit.getValue("reason") || ""}
              handleChange={(e) => edit.update({ reason: e.target.value })}
            />
          </Grid>
        </Grid>
      </SelectedFilterBoxLayout>
    );
  };

  const renderSearchBox = () => {
    return (
      <div className={classes.searchBoxStyle}>
        <TdSearchInput
          value={searchText}
          placeholder="Search for Item"
          variant="outlined"
          fullWidth
          handleChange={handleSearchListShow}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchText.length > 0 && (
              <InputAdornment position="end">
                <IconButton
                  className={classes.closeSearchStyle}
                  onClick={handleSearchClear}
                  size="large"
                >
                  {" "}
                  <CloseIcon />{" "}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      field: "itemName",
      headerName: "Item",
      minWidth: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <AccountNumber value={params?.value?.toString()} />;
      },
    },
    {
      field: "itemDescription",
      headerName: "Description",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <BolderText value={params?.value?.toString()} />;
      },
    },
    {
      field: "categoryName",
      headerName: "Product Line",
      minWidth: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Typography className={classes.customFullTextCell}>
            {params?.value?.toString()}
          </Typography>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      minWidth: 160,
      width: 160,
      maxWidth: 160,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <TdInputBox
            value={edit.getValue(params?.row?.id) || ""}
            handleChange={(e) =>
              edit.update({ [params?.row?.id]: e.target.value })
            }
          />
        );
      },
    },
    {
      field: "close",
      headerName: "",
      disableColumnMenu: true,
      minWidth: 60,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            className={classes.removeIcon}
            onClick={() => removeTemplateItems(params?.row?.id, templateItems)}
          >
            <CloseIcon />
          </Button>
        );
      },
    },
  ];

  const renderItemTable = () => {
    return (
      <div className={classes.borderedTable}>
        <MiTable
          columns={columns}
          rows={templateItems}
          disableColumnMenu={true}
          hideFooter={true}
          autoHeight={true}
          sx={{
            minHeight: "inherit",
            border: "inherit",
            "& .MuiDataGrid-columnHeader": {
              background: theme.palette.lightShade.grey,
              minHeight: 60,
            },
            "& .MuiDataGrid-footerContainer p": {
              marginBottom: "0px",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
          }}
        />
      </div>
    );
  };

  const addItems = (requisitionId, data) => {
    return RequisitionService.requisitions.createItem(requisitionId, data);
  };
  const editItems = (requisitionId, itemId, data) => {
    return RequisitionService.requisitions.updateItem(
      requisitionId,
      itemId,
      data
    );
  };

  const itemFormat = (item) => {
    return {
      itemId: item?.itemId,
      notes: item?.notes,
      status: item?.status,
      quantity: edit.getValue(item?.id),
      displaySequence: item?.displaySequence,
      id: item?.id,
      requisitionOrderId: item?.requisitionOrderId,
      itemName: item?.itemName,
      itemDescription: item?.itemDescription,
      categoryId: item?.categoryId,
      categoryName: item?.categoryName,
    };
  };

  const handleSave = async () => {
    if (edit.allFilled(...requiredFields)) {
      setIsMissingRequiredField(false);
      const formData = {
        number: edit.getValue("number"),
        type: "Template",
        name: edit.getValue("name"),
        templateFolderId: edit.getValue("templateFolderId"),
        scheduledDate: moment(edit.getValue("scheduledDate")).format(
          "MM/DD/YYYY"
        ),
        requestedBy: edit.getValue("requestedBy"),
        description: edit.getValue("description"),
        stage: edit.getValue("stage"),
        deliveryLocationId: edit.getValue("deliveryLocationId"),
        issueDate: moment(edit.getValue("scheduledDate"))?.format("MM/DD/YYYY"),
        requestedDeliveryDate: moment(edit.getValue("scheduledDate"))?.format(
          "MM/DD/YYYY"
        ),
        status: edit.getValue("status"),
        requestedBillingDate: moment(edit.getValue("scheduledDate"))?.format(
          "MM/DD/YYYY"
        ),
        reason: edit.getValue("reason"),
      };
      try {
        if (!templateEdit) {
          const resp = await RequisitionService.requisitions.create(
            formData,
            "Template Created Successfully",
            "Error Occurred While Creating"
          );
          if (resp.status < HTTP_STATUSES.BAD_REQUEST) {
            await Promise.all(
              templateItems?.map(async (item) => {
                let items = itemFormat(item);
                addItems(resp?.data?.id, items);
              })
            );
          }
        } else {
          const resp = await RequisitionService.requisitions.update(
            templateData?.id,
            formData,
            "Template Updated Successfully",
            "Error Occurred While Updating"
          );
          if (resp.status < HTTP_STATUSES.BAD_REQUEST) {
            await Promise.all(
              templateItems?.map(async (item) => {
                let items = itemFormat(item);
                editItems(resp?.data?.id, item?.id, items);
              })
            );
          }
        }
      } finally {
        getFolderItems();
        closeAll();
        setTimeout(() => {
          setShowTemplate({ open: false });
          setTemplateItems([]);
        }, 2000);
      }
    } else {
      setIsMissingRequiredField(true);
    }
  };

  const submitForm = () => {
    handleSave();
  };

  return (
    <>
      <div className={classes.root}>
        {renderNewTemplateFilter()}
        {renderItemTable()}
        {renderSearchBox()}
        {masterItems.length ? (
          <SearchRenderItems
            masterItems={masterItems}
            addTemplateItems={addTemplateItems}
          />
        ) : (
          ""
        )}
      </div>
      <RequisitionFooter
        value="1"
        templateData={templateData}
        handleDiscard={onDiscardViewModal}
        handleSave={submitForm}
        handleDelete={onDeleteViewModal}
      />
    </>
  );
};

export default DrawerItems;
