import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_HOLIDAY = `${API_V3}/holidays`;

export const holidayService = {
  getAll: async () => {
    const options = await apiOptions({
      url: API_HOLIDAY,
      method: "get",
    });
    return await apiRequest(options);
  },
  create: async (request) => {
    const options = await apiOptions({
      url: API_HOLIDAY,
      method: "post",
      data: request,
    });
    return await apiRequest(options);
  },
  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_HOLIDAY}/${id}`,
      method: "patch",
      data: request,
    });
    return await apiRequest(options);
  },
  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_HOLIDAY}/${id}`,
      method: "delete",
    });
    return await apiRequest(options);
  },
};
