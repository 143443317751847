import React from "react";
import { ImportedWork } from "../../views/Scheduling";
import {
  Communities,
  Community,
  HomeSpecs,
  Properties,
  Property,
  MasterSpecs,
  PlanDetails,
  Plans,
  Materials,
  MaterialDetails,
  Builder,
  Builders,
} from "../../views";
import { PERMISSIONS } from "@miview/constants";

export const schedulingRoutes = [
  {
    path: "/scheduling/communities",
    exact: true,
    name: "Communities",
    element: <Communities />,
    permissionName: PERMISSIONS.CAN_CREATE_COMMUNITIES,
  },
  {
    path: "/scheduling/homes",
    exact: true,
    name: "Properties",
    element: <Properties />,
    permissionName: PERMISSIONS.CAN_VIEW_PROPERTIES,
  },
  {
    path: "/scheduling/homes/:propertyId/specs/:stepIndex",
    name: "Home Specifications",
    element: <HomeSpecs />,
    permissionName: PERMISSIONS.CAN_VIEW_PROPERTIES,
  },
  {
    path: "/scheduling/homes/:propertyId/specs",
    name: "Home Specifications",
    element: <HomeSpecs />,
    permissionName: PERMISSIONS.CAN_VIEW_PROPERTIES,
  },
  {
    path: "/scheduling/homes/:id/:activeTab?",
    name: "Property Details",
    element: <Property />,
    permissionName: PERMISSIONS.CAN_VIEW_PROPERTIES,
  },
  {
    path: "/scheduling/communities/:id",
    name: "Community",
    element: <Community />,
    permissionName: PERMISSIONS.CAN_VIEW_COMMUNITIES,
  },
  {
    path: "/scheduling/materials",
    exact: true,
    name: "Materials",
    element: <Materials />,
    permissionName: PERMISSIONS.CAN_VIEW_MATERIALS,
  },
  {
    path: "/scheduling/materials/:id",
    exact: true,
    name: "Material Details",
    element: <MaterialDetails />,
    permissionName: PERMISSIONS.CAN_VIEW_MATERIALS,
  },
  {
    path: "/scheduling/builders",
    exact: true,
    name: "Builders",
    element: <Builders />,
    permissionName: PERMISSIONS.CAN_CREATE_BUILDERS,
  },
  {
    path: "/scheduling/builder/:id",
    name: "Builder",
    element: <Builder />,
    permissionName: PERMISSIONS.CAN_VIEW_BUILDERS,
  },
  {
    path: "/scheduling/plans/:planId/specs/:stepIndex",
    name: "Master Spec Builder",
    element: <MasterSpecs />,
    permissionName: PERMISSIONS.CAN_VIEW_PLAN,
  },
  {
    path: "/scheduling/plans/:planId/specs",
    name: "Master Spec Builder",
    element: <MasterSpecs />,
    permissionName: PERMISSIONS.CAN_VIEW_PLAN,
  },
  {
    path: "/scheduling/plans/:id",
    name: "Plan Details",
    element: <PlanDetails />,
    permissionName: PERMISSIONS.CAN_VIEW_PLAN,
  },
  {
    path: "/scheduling/plans",
    exact: true,
    name: "Plans",
    element: <Plans />,
    permissionName: PERMISSIONS.CAN_VIEW_PLAN,
  },
  {
    path: "/scheduling/importedwork",
    exact: true,
    name: "Imported Work",
    element: <ImportedWork />,
  },
];
