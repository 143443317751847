import React, { useState, useEffect } from "react";
import { MiChip } from "../index.ts";
import { createTheme } from "@miview/theme";
import PropTypes from "prop-types";

const types = {
  RADIO: "RADIO",
  CHECKBOX: "CHECKBOX",
};

export const MiMultiselectChip = (props) => {
  const {
    items,
    selectedItems,
    type = types.CHECKBOX,
    handleChange,
    activeColor,
    color,
    size,
    chipStyle,
    style,
    valueField = "label",
    labelField = valueField,
  } = props;

  const [selected, setSelected] = useState(selectedItems || []);
  const theme = createTheme();

  useEffect(() => {
    setSelected(selectedItems);
  }, [selectedItems]);

  const onChange = (items, clickedItem) => {
    if (handleChange) {
      handleChange(items, clickedItem);
    } else {
      setSelected(items);
    }
  };

  const isUnselected = (item) => {
    const items = selected.filter(
      (selectedItem) => item[valueField] !== selectedItem[valueField]
    );

    if (items.length < selected.length) {
      onChange(items);
      return true;
    }
    return false;
  };

  const handleClick = (item) => {
    if (isUnselected(item)) {
      return;
    }
    if (`${type}`.toUpperCase() === types.RADIO) {
      onChange([item]);
    } else {
      onChange([...selected, item], item);
    }
  };

  const isItemSelected = (item) => {
    const index = selected.findIndex(
      (selectedItem) => selectedItem[valueField] === item[valueField]
    );
    return index !== -1;
  };

  const getChipBackgroundColor = (isActive) => {
    if (isActive) {
      return activeColor || theme.palette.primary.main;
    }
    return color || "";
  };

  return (
    <div
      style={{
        display: "flex",
        ...style,
      }}
    >
      {items.map((item, i) => {
        const isActive = isItemSelected(item);
        return (
          <MiChip
            onClick={() => handleClick(item)}
            label={item[labelField]}
            key={i}
            style={{
              margin: 4,
              backgroundColor: getChipBackgroundColor(isActive),
              color: isActive && "#FFF",
              ...chipStyle,
            }}
            size={size}
          />
        );
      })}
    </div>
  );
};

MiMultiselectChip.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.oneOf(Object.values(types)),
  handleChange: PropTypes.func,
  activeColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  chipStyle: PropTypes.object,
  style: PropTypes.object,
  valueField: PropTypes.string,
  labelField: PropTypes.string,
};
