import React from "react";
import PropTypes from "prop-types";

export const StageIcon = ({ steps, stepIndex }) => {
  const step = steps[stepIndex];

  if (step) {
    const Icon = step.icon;

    return <Icon style={{ color: step.color }} />;
  }
};

StageIcon.propTypes = {
  steps: PropTypes.array,
  stepIndex: PropTypes.string,
};
