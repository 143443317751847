import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MiButton } from "@miview/components";

const useStyles = makeStyles()((theme) => ({
  boxStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    maxWidth: 290,
    boxShadow: "0px 2px 10px rgba(207, 216, 220, 0.29)",
    borderRadius: 15,
    border: "0.25px solid #ECEFF1",
    margin: "0 20px",
    padding: "35px 10px",
    "& h4": {
      fontWeight: theme.fontWeight.medium,
      fontSize: theme.fontSize.large,
      marginBottom: 10,
      color: theme.palette.secondary.bluegrey,
    },
    "& p": {
      marginBottom: 20,
      color: theme.palette.medium.bluegrey,
      fontSize: theme.fontSize.medium,
      minHeight: 50,
    },
  },
  boxIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 136,
    height: 136,
    borderRadius: "50%",
    backgroundColor: (props) => props.color,
    marginBottom: 20,
  },
}));

const RequestCardCreate = ({
  title,
  message,
  buttonName,
  onClick,
  icon,
  color,
  buttoncolor,
}) => {
  const { classes } = useStyles({ color: color });
  const theme = useTheme();
  return (
    <Box className={classes.boxStyle}>
      <Box className={classes.boxIcon}>
        <div>{icon}</div>
      </Box>
      <Typography variant="h4">{title}</Typography>
      <Typography component="p">{message}</Typography>
      <MiButton
        title={buttonName}
        inverse
        onClick={onClick}
        color={buttoncolor}
        style={{
          borderRadius: 4,
          width: 194,
          margin: "0 auto",
          fontWeight: theme.fontWeight.medium,
          height: 34,
        }}
      />
    </Box>
  );
};

export default RequestCardCreate;
