import React, { useState } from "react";
import CreateMaterialCategoryModal from "./CreateMaterialCategoryModal";
import EditMaterialCategoryModal from "./EditMaterialCategoryModal";
import DeleteMaterialCategoryModal from "./DeleteMaterialCategoryModal";
import { materialCategoryService } from "@miview/api";
import { useTheme } from "@mui/material/styles";
import { MiPageHeader, MiButton, MiList, MiIcon } from "@miview/components";
import { mdiSelectGroup, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";

const MaterialCategories = () => {
  const [materials, setMaterials] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [refresh, setRefresh] = useState(false);

  const theme = useTheme();

  const closeCreateMaterialCategoryModal = () => {
    setIsCreateModalOpen(false);
    setRefresh(!refresh);
  };

  const closeEditMaterialCategoryModal = () => {
    setIsEditModalOpen(false);
    setSelectedRow({});
    setRefresh(!refresh);
  };

  const closeDeleteMaterialCategoryModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedRow({});
    setRefresh(!refresh);
  };

  const columns = [
    {
      field: "name",
      align: "left",
      headerName: "Category",
      renderCell: (p) => p.value,
      flex: 1,
    },
    {
      field: "displayOrder",
      align: "left",
      headerName: "Display Order",
      renderCell: (p) => p.displayOrder,
      sortable: true,
      flex: 1,
    },
  ];

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => {
          setSelectedRow(row);
          setIsDeleteModalOpen(true);
        },
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
      {
        text: "EDIT",
        onClick: () => {
          setSelectedRow(row);
          setIsEditModalOpen(true);
        },
        renderIcon: () => <MiIcon path={mdiPencil} size={1} />,
      },
    ];
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiSelectGroup}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  const actionButton = () => {
    return (
      <MiButton
        title="Material Category"
        icon={mdiPlus}
        inverse={true}
        onClick={() => setIsCreateModalOpen(true)}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <div>
      <MiPageHeader
        title="Material Categories"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
      />
      <MiList
        data={materials}
        setData={setMaterials}
        dataProvider={materialCategoryService.getAll}
        getRowActions={getRowActions}
        actionIconStyle={{ color: theme.palette.secondary.grey }}
        columns={columns}
        refresh={refresh}
        defaultPageSize={50}
      />
      <CreateMaterialCategoryModal
        open={isCreateModalOpen}
        onClose={closeCreateMaterialCategoryModal}
      />
      <EditMaterialCategoryModal
        open={isEditModalOpen}
        onClose={closeEditMaterialCategoryModal}
        selection={selectedRow}
      />
      <DeleteMaterialCategoryModal
        open={isDeleteModalOpen}
        onClose={closeDeleteMaterialCategoryModal}
        selection={selectedRow}
      />
    </div>
  );
};

export default MaterialCategories;
