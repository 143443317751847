import { apiOptions, apiRequest } from "../../index.js";
import { API_HEADERS_TEMP, API_INTEGRATION_SERVICE_URL } from "../../constants";

const API_MAPPING_TYPES = `/mappingtypes`;

export const mappingTypes = {
  getAll: async (params, filters) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_MAPPING_TYPES}`,
      method: "get",
      params,
      filters,
      headers: API_HEADERS_TEMP,
    });

    return apiRequest(options);
  },
  get: async (id) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_MAPPING_TYPES}/${id}`,
      method: "get",
      headers: API_HEADERS_TEMP,
    });
    return apiRequest(options);
  },
  getOptions: async (id) => {
    const options = await apiOptions({
      url: `${API_INTEGRATION_SERVICE_URL}${API_MAPPING_TYPES}/${id}/options`,
      method: "get",
      headers: API_HEADERS_TEMP,
    });
    return apiRequest(options);
  },
};
