import React from "react";
import { MiModalConfirm } from "@miview/components";
import { useTheme } from "@mui/material";
import { tradeService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const DeleteTradeModal = (props) => {
  const { open, onClose, selection } = props;

  const theme = useTheme();

  const handleDelete = async () => {
    const response = await tradeService.delete({ id: selection.id });
    if (response.status === HTTP_STATUSES.OK) {
      onClose();
    }
  };

  return (
    <MiModalConfirm
      titleColor={theme.palette.primary.red}
      title={"Confirm Delete"}
      open={open}
      description={`Delete the following trade: ${selection.name}?`}
      handlePositive={handleDelete}
      handleNegative={onClose}
    ></MiModalConfirm>
  );
};
