import { useEffect, useState } from "react";
import { combineFilters, createFilter } from "@miview/utils";
import { useDebounce } from "../useDebounce";

export const useFilter = ({ type, initialFilter }) => {
  const [filterHelper] = useState(createFilter(type));
  const [combinedFilters, setCombinedFilters] = useState(initialFilter || {});
  const debounce = useDebounce(combinedFilters, 300);

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const reset = () => {
    filterHelper.reset();
    setCombinedFilters(initialFilter || {});
  };

  const contains = (fieldValuePair) => {
    filterHelper.contains(fieldValuePair);
    updateCombinedFilters();
  };

  const startsWith = (fieldValuePair) => {
    filterHelper.startsWith(fieldValuePair);
    updateCombinedFilters();
  };

  const endsWith = (fieldValuePair) => {
    filterHelper.endsWith(fieldValuePair);
    updateCombinedFilters();
  };

  const equals = (fieldValuePair) => {
    filterHelper.equals(fieldValuePair);
    updateCombinedFilters();
  };

  const greaterThanOrEqual = (fieldValuePair) => {
    filterHelper.greaterThanOrEqual(fieldValuePair);
    updateCombinedFilters();
  };

  const lessThanOrEqual = (fieldValuePair) => {
    filterHelper.lessThanOrEqual(fieldValuePair);
    updateCombinedFilters();
  };

  const inRange = (fieldValuePair) => {
    filterHelper.inRange(fieldValuePair);
    updateCombinedFilters();
  };

  const removeField = (field) => {
    filterHelper.removeField(field);
    updateCombinedFilters();
  };

  const updateCombinedFilters = () => {
    setCombinedFilters(combineFilters(initialFilter, filterHelper.getFilter()));
  };

  const getFilter = () => combinedFilters;

  return {
    reset,
    contains,
    startsWith,
    endsWith,
    equals,
    greaterThanOrEqual,
    lessThanOrEqual,
    inRange,
    removeField,
    getFilter,
    debounce,
  };
};
