import React, { useState, useEffect } from "react";
import { MiTable, MiIcon } from "@miview/components";
import { mdiDelete } from "@mdi/js";
import { useComponentState } from "@miview/hooks";
import { toList } from "@miview/utils";
import { HTTP_STATUSES } from "@miview/constants";
import { systemTypeService, tradeService } from "@miview/api";

export const StageWalkItemFailureReasons = (props) => {
  const { failureReasons, openDeleteReasonModal } = props;
  const stateManager = useComponentState();
  const [causeIdObjs, setCauseIdObjs] = useState([]);
  const [trades, setTrades] = useState([]);

  useEffect(() => {
    getCauseIds();
    getTrades();
  }, []);

  const getCauseIds = async () => {
    return stateManager.run(async () => {
      const response = await systemTypeService.getSystemTypesByName({
        name: "IssueType",
      });

      if (response.status === HTTP_STATUSES.OK) {
        setCauseIdObjs(toList(response.data, "systemTypeId", "mainValue"));
      }
    });
  };

  const getTrades = async () => {
    return stateManager.run(async () => {
      const response = await tradeService.getAll();

      if (response.status === HTTP_STATUSES.OK) {
        setTrades(toList(response.data, "id", "name"));
      }
    });
  };

  const getRowActions = (row) => {
    return [
      {
        text: "Delete",
        onClick: () => openDeleteReasonModal(row.id),
        renderIcon: () => <MiIcon path={mdiDelete} size={"20px"} />,
      },
    ];
  };

  const columns = [
    {
      field: "description",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "causeId",
      headerName: "Cause ID",
      headerAlign: "left",
      align: "left",
      flex: 1,
      valueGetter: ({ value }) =>
        causeIdObjs.find((elem) => elem.value === value)?.text,
    },
    {
      field: "defaultFaultTradeId",
      headerName: "Default Trade",
      headerAlign: "left",
      align: "left",
      flex: 1,
      valueGetter: ({ value }) =>
        trades.find((elem) => elem.value === value)?.text,
    },
    {
      field: "descriptionSpanish",
      headerName: "Spanish Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
  ];
  return (
    <div>
      <MiTable
        style={{ minHeight: "100vh" }}
        columns={columns}
        rows={failureReasons}
        getRowActions={getRowActions}
      />
    </div>
  );
};
