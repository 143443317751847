import React from "react";
import { MiModal, MiIcon } from "@miview/components";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import en from "../../../locale/en.json";
import { CreateRequestCard } from "../../../components";
import { mdiFileDocumentEditOutline, mdiTextBoxMultiple } from "@mdi/js";

const useStyles = makeStyles()(() => ({
  dialogContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingTop: "25px",
    paddingBottom: "45px",
  },
}));

const NewHomesSelectModal = ({
  open,
  onCancel,
  onRequisitionBlank,
  onTemplateExisting,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <MiModal
      open={open}
      onClose={onCancel}
      scroll="body"
      title={en.createANewRequisition}
      maxWidth="md"
      isCloseButton={true}
      modalTitleStyle={{
        color: theme.palette.secondary.bluegrey,
        fontSize: theme.fontSize.headerSmall,
        fontWeight: 500,
      }}
    >
      <div className={classes.dialogContent}>
        <CreateRequestCard
          icon={
            <MiIcon
              path={mdiFileDocumentEditOutline}
              size={3}
              color={theme.palette.primary.teal}
            />
          }
          color={theme.palette.light.main}
          title={en.createABlankRequest}
          message={en.createANewRequestMessage}
          buttonName={en.fromBlank}
          buttoncolor={theme.palette.primary.teal}
          onClick={onRequisitionBlank}
        />
        <CreateRequestCard
          icon={
            <MiIcon
              path={mdiTextBoxMultiple}
              size={3}
              color={theme.palette.primary.indigo}
            />
          }
          color={theme.palette.indigo.light}
          title={en.createFromExistingTemple}
          message={en.createFromExistingTempleMessage}
          buttonName={en.fromTemplate}
          buttoncolor={theme.palette.primary.indigo}
          onClick={onTemplateExisting}
        />
      </div>
    </MiModal>
  );
};

export default React.memo(NewHomesSelectModal);
