import React, { memo } from "react";
import { THEME } from "./theme";

const MiMessageOptions = (props) => {
  const { replyButtonClick, hideDialog } = props;
  const verticalBarStyle = {
    width: 30,
    maxWidth: 30,
    backgroundColor: "white",
    position: "absolute",
    top: -45,
    left: 20,
    border: "1px solid rgb(221,221,221)",
    padding: 5,
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  };
  const clickable = {
    cursor: "pointer",
    color: THEME.GREY_MEDIUM_LIGHT,
    fontSize: "1.2rem",
    padding: 6,
  };

  return (
    <div style={verticalBarStyle}>
      <i
        style={clickable}
        className="material-icons"
        onClick={() => {
          replyButtonClick();
          hideDialog();
        }}
      >
        reply
      </i>
    </div>
  );
};

export default memo(MiMessageOptions);
