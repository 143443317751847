import React, { Fragment } from "react";
import { Menu } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

const useToolIconsStyles = makeStyles()(() => ({
  container: {
    marginRight: 24,
    display: "flex",
    alignItems: "center",
  },
  spacer: {
    flex: 1,
  },
}));

export const ToolIcons = ({ children }) => {
  const { classes } = useToolIconsStyles();

  return (
    <div className={classes.container}>
      <div className={classes.spacer} />
      {children}
    </div>
  );
};

const useToolIconStyles = makeStyles()(() => ({
  icon: { color: "grey", margin: "0px 10px", cursor: "pointer" },
  popup: {
    marginTop: 8,
    marginLeft: 8,
  },
}));

export const ToolIcon = ({ icon, menu, label, onClick }) => {
  const { classes } = useToolIconStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    onClick();

    if (menu) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Icon = icon;

  return (
    <Fragment>
      <Icon className={classes.icon} onClick={handleClick} aria-label={label} />
      {menu && (
        <Menu
          className={classes.popup}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          getContentAnchorEl={null}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {menu()}
        </Menu>
      )}
    </Fragment>
  );
};

ToolIcon.defaultProps = {
  onClick: () => null,
};
