import React from "react";
import { Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import { ORIENTATION } from "@miview/constants";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";

const IconContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  right: "5px",
  color: theme.palette?.primary.main,
  "&:hover": {
    backgroundColor: theme.palette?.light.teal,
  },
}));

const TabContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette?.primary.white,
}));

const Wrapper = styled("div")(({ orientation }) => ({
  height: "100%",
  display: "flex",
  flexDirection: orientation === ORIENTATION.VERTICAL ? "row" : "column",
  "& [class^='MuiTabs-flexContainer']": {
    maxWidth: "200px",
  },
}));

const MuiTabs = styled(Tabs)(({ theme, indicatorcolor }) => ({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    height: 3,
    backgroundColor: indicatorcolor || theme.palette?.primary.main,
  },
}));

const MuiTab = styled(Tab)(({ theme }) => ({
  fontSize: 13,
  minWidth: "120px",
  textTransform: "none",
  '&[class*="MuiTab-labelIcon"]': {
    minHeight: "unset",
  },
  "&:focus": {
    outline: 2,
  },
  "&.Mui-selected": {
    color: theme.palette.primary.black,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const MiTab = (props) => {
  const {
    tabs = [],
    onTabChange,
    currenttab,
    renderTabContent,
    tabIndicatorColor,
    handleRemoveTab,
    orientation = ORIENTATION.HORIZONTAL,
  } = props;

  const currentTabValue = currenttab || tabs[0].value || 0;

  const handleChange = (e, newValue) => {
    onTabChange && onTabChange(newValue);
  };

  const renderIcon = (tab) => {
    if (!tab.renderIcon) {
      return null;
    }
    return (
      <IconContainer
        onClick={(e) => {
          e.stopPropagation();
          handleRemoveTab(tab.value);
        }}
      >
        <CloseIcon />
      </IconContainer>
    );
  };

  return (
    <Wrapper orientation={orientation} data-testid="MiTab">
      <TabContainer>
        <MuiTabs
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          value={currentTabValue}
          indicatorcolor={tabIndicatorColor}
        >
          {tabs.map((tab, index) => {
            return (
              <MuiTab
                label={tab.label}
                key={index}
                value={tab.value || index}
                icon={renderIcon(tab)}
                disabled={!!tab.disabled}
              />
            );
          })}
        </MuiTabs>
      </TabContainer>
      {renderTabContent && renderTabContent(currentTabValue)}
    </Wrapper>
  );
};

MiTab.propTypes = {
  /**
   * array of tab config with label and value
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      iconPosition: PropTypes.oneOf(["start", "end", ""]),
      renderIcon: PropTypes.func,
    })
  ),
  /**
   * value of the current active tab
   */
  currenttab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   *  Orientation of the tabs
   */
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  /**
   *  callback on tab change with the tab value
   */
  onTabChange: PropTypes.func,
  /**
   * overriding styles
   */
  style: PropTypes.object,
  /**
   * callback with tab value as input and return current tab content json
   */
  renderTabContent: PropTypes.func,
  /**
   * color of the active tab indicator
   */
  tabIndicatorColor: PropTypes.string,
  /**
   * Tab content container full width
   */
  contentFullWidth: PropTypes.bool,
};
