import React from "react";
import { Grid, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import { MiButton } from "../index.ts";
import { MiTHEME } from "@miview/theme";

const StyledGrid = styled(Grid)({
  backgroundColor: MiTHEME.palette.primary.overlay,
  display: "flex",
  position: "fixed",
  width: "100%",
  flex: 1,
  padding: 15,
  bottom: 0,
  right: 0,
  zIndex: 1201,
});

const RightButtonGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
  marginLeft: 20,
});

export const MiSnackbar = (props) => {
  const {
    visible = false,
    handleSaveClick,
    saveTitle,
    buttonTwoStyles,
    buttonTwoType,
    disableCancel,
    disableSave,
    cancelTitle,
    handleCancelClick,
    buttonTwoColor,
    justifyCenter,
  } = props;

  const styles = {
    buttons: {
      width: 100,
      padding: 20,
      ...buttonTwoStyles,
    },
  };
  const theme = useTheme();

  if (!visible) {
    return null;
  }

  return (
    <StyledGrid
      justifyContent={justifyCenter ? "center" : "flex-end"}
      sx={{
        "@keyframes slide": {
          from: { opacity: 0 },
          to: { opacity: 1 },
        },
        animation: "slide 0.8s ease-in-out",
      }}
    >
      <Grid>
        <MiButton
          title={cancelTitle}
          color={theme.palette.primary.bluegrey}
          style={styles.buttons}
          backgroundColor={theme.palette.primary.white}
          onClick={handleCancelClick}
          disabled={disableCancel}
        />
      </Grid>
      <RightButtonGrid>
        <MiButton
          title={saveTitle}
          inverse={true}
          color={buttonTwoColor}
          style={styles.buttons}
          type={buttonTwoType || "button"}
          onClick={handleSaveClick}
          disabled={disableSave}
        />
      </RightButtonGrid>
    </StyledGrid>
  );
};

MiSnackbar.propTypes = {
  visible: PropTypes.bool,
  handleSaveClick: PropTypes.func,
  saveTitle: PropTypes.string,
  buttonTwoStyles: PropTypes.object,
  buttonTwoType: PropTypes.string,
  disableCancel: PropTypes.bool,
  disableSave: PropTypes.bool,
  cancelTitle: PropTypes.string,
  handleCancelClick: PropTypes.func,
  buttonLeftStyles: PropTypes.object,
};

MiSnackbar.defaultProps = {
  saveTitle: "Save",
  cancelTitle: "Cancel",
  buttonTwoStyles: {},
  buttonTwoColor: MiTHEME.palette.secondary.green,
  justifyCenter: true,
};
