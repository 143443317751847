import React, { useState, useEffect } from "react";
import { toList } from "../../../utils";
import { useTheme } from "@mui/material";
import {
  propertySectionService,
  propertySectionCategoryService,
} from "@miview/api";
import {
  MiPageHeader,
  MiButton,
  MiList,
  MiLink,
  MiModal,
  MiDetailFields,
  MiIcon,
} from "@miview/components";
import { useEdit } from "@miview/hooks";
import { HTTP_STATUSES } from "@miview/constants";
import { mdiVectorSquareClose, mdiPlus, mdiDelete } from "@mdi/js";
import AreaDeleteModal from "./AreaDeleteModal";

const ManageAreas = () => {
  const [newPropertySectionInProgress, setNewPropertySectionInProgress] =
    useState(false);
  const [propertySections, setPropertySections] = useState();
  const [propertySectionCategories, setPropertySectionCategories] = useState(
    []
  );

  const [refresh, setRefresh] = useState(false);
  const [isAreaDeleteModalOpen, setIsAreaDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const theme = useTheme();
  const edit = useEdit({
    name: "",
    description: "",
    weight: "",
    propertySectionCategoryId: "",
    isDefault: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await propertySectionCategoryService.getAll({
        params: { pageSize: 9999 },
        sort: [{ field: "name", sort: "asc" }],
      });
      if (response.status === HTTP_STATUSES.OK) {
        setPropertySectionCategories(toList(response.data, "id", "name"));
      }
    };
    fetchData();
  }, []);

  const saveNewPropertySection = async () => {
    await propertySectionService.create(edit.edits);

    toggleModal();
    setRefresh(!refresh);
  };

  const openAreaDeleteModal = (row) => {
    setSelectedRow(row);
    setIsAreaDeleteModalOpen(true);
  };

  const closeAreaDeleteModal = () => {
    setIsAreaDeleteModalOpen(false);
    setSelectedRow({});
    setRefresh(!refresh);
  };

  const toggleModal = () => {
    setNewPropertySectionInProgress(!newPropertySectionInProgress);
    edit.reset();
  };

  const getRowActions = (row) => {
    return [
      {
        text: "DELETE",
        onClick: () => {
          openAreaDeleteModal(row);
        },
        renderIcon: () => <MiIcon path={mdiDelete} size={1} />,
      },
    ];
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (p) => {
        return (
          <MiLink
            style={{ marginLeft: "0px", padding: 0, whiteSpace: "normal" }}
            to={`/areas/${p.id}`}
            title={p.value}
          />
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      align: "left",
      flex: 1.5,
      sortable: true,
    },
    {
      field: "weight",
      headerName: "Weight",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
    },
    {
      field: "propertySectionCategory",
      headerName: "Category",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
    },
    {
      field: "isDefault",
      headerName: "Default",
      headerAlign: "center",
      align: "center",
      renderCell: (c) =>
        c.value ? <i className="material-icons">check</i> : "",
      flex: 1,
    },
  ];

  const manageAreaFields = [
    {
      label: "Name",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "50%",
    },
    {
      label: "Description",
      value: edit.getValue("description"),
      required: true,
      setValue: (e) => edit.update({ description: e }),
      width: "50%",
    },
    {
      label: "Weight",
      value: edit.getValue("weight"),
      setValue: (e) => edit.update({ weight: e }),
      width: "50%",
    },
    {
      label: "Category",
      fieldType: "select",
      options: propertySectionCategories,
      value: edit.getValue("propertySectionCategoryId"),
      setValue: (e) => edit.update({ propertySectionCategoryId: e }),
      width: "50%",
    },
    {
      label: "Default",
      fieldType: "checkbox",
      value: edit.getValue("isDefault"),
      setValue: (e) => edit.update({ isDefault: e }),
      width: "50%",
    },
  ];

  const actionButton = () => {
    return (
      <MiButton
        title="Area"
        icon={mdiPlus}
        inverse={true}
        onClick={toggleModal}
        color={theme.palette.primary.main}
      />
    );
  };

  const renderHeaderIcon = () => {
    return (
      <MiIcon
        path={mdiVectorSquareClose}
        size={1}
        color={theme.palette.primary.main}
      />
    );
  };

  return (
    <div>
      <MiPageHeader
        title="Areas"
        leftIcon={renderHeaderIcon()}
        color={theme.palette.primary.main}
        action={actionButton()}
      />
      <MiList
        data={propertySections}
        setData={setPropertySections}
        dataProvider={propertySectionService.getAll}
        columns={columns}
        disableColumnMenu
        refresh={refresh}
        getRowActions={getRowActions}
        defaultPageSize={50}
        selection={selectedRow}
      />
      <MiModal
        key={0}
        maxWidth={800}
        title="Create Area"
        open={newPropertySectionInProgress}
        onClose={toggleModal}
        actions={[
          {
            name: "Cancel",
            style: { minWidth: 90 },
            onClick: toggleModal,
            color: theme.palette.medium.grey,
            inverse: false,
          },
          {
            name: "Save",
            onClick: saveNewPropertySection,
            style: { minWidth: 90 },
            color: theme.palette.primary.green,
            inverse: true,
            disabled: !edit.allFilled("name", "description"),
          },
        ]}
      >
        <MiDetailFields detailFields={manageAreaFields} />
      </MiModal>
      <AreaDeleteModal
        open={isAreaDeleteModalOpen}
        onClose={closeAreaDeleteModal}
        selection={selectedRow}
      />
    </div>
  );
};

export default ManageAreas;
