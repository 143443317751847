import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import en from "../locale/en.json";
import { ACCOUNT_STATUS } from "@miview/constants";

const useStyles = makeStyles()((theme, { props }) => {
  return {
    root: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 500,
      padding: "6px 14px",
      backgroundColor: props.backgroundColor,
      color: props.color,
    },
    text: {
      fontSize: theme.fontSize.xsmall,
      fontWeight: theme.fontWeight.regular,
    },
  };
});

const StatusChip = ({ status }) => {
  const theme = useTheme();
  const types = {
    [ACCOUNT_STATUS.active]: {
      backgroundColor: theme.palette.light.green,
      color: theme.palette.medium.green,
      txt: en.active,
    },
    [ACCOUNT_STATUS.inactive]: {
      backgroundColor: theme.palette.lightAccent.grey,
      color: theme.palette.secondary.grey,
      txt: en.inActive,
    },
    [ACCOUNT_STATUS.archived]: {
      backgroundColor: theme.palette.light.blue,
      color: theme.palette.primary.blue,
      txt: en.archived,
    },
    [ACCOUNT_STATUS.incomplete]: {
      backgroundColor: theme.palette.light.red,
      color: theme.palette.secondary.red.secondary,
      txt: en.inComplete,
    },
  };
  const { classes } = useStyles({ props: types[status] });

  return (
    <Box className={`${classes.root} ${classes[status]}`}>
      <Typography className={classes.text}>
        {types[status]?.txt || ""}
      </Typography>
    </Box>
  );
};

export default React.memo(StatusChip);
