import React, { useState, useEffect } from "react";
import { MiTab, MiDetailFields } from "@miview/components";
import {
  bonusSettingsService,
  payActivityTypeService,
  accountServiceLegacy as accountService,
  stageTypeService,
} from "@miview/api";
import { TabulatedPage } from "../../Lists/TabulatedPage";
import { useRouter, useEdit, useComponentState } from "@miview/hooks";
import { createToast } from "@miview/toast";
import {
  onStatus,
  condition,
  includesIssueType,
  InitialStatus,
  TOAST_TYPES,
  HTTP_STATUSES,
  STAGE_STATUS_OPTIONS,
} from "@miview/constants";
const BonusSettingsEdit = () => {
  const [payActivityTypeSelect, setPayActivityTypeSelect] = useState([]);
  const [walkTypeSelect, setWalkTypeSelect] = useState([]);
  const [titleSelect, setTitleSelect] = useState([]);
  const [stageTypeSelect, setStageTypeSelect] = useState([]);
  const [formData, setFormData] = useState({});
  const [fetchedData, setFetchedData] = useState({});
  const [editToggle, setEditToggle] = useState(false);
  const [activeTab, setActiveTab] = useState(global.ACTIVE_TAB.DETAILS);

  const stateManager = useComponentState();
  const { goBack, navigate, params } = useRouter();
  const edit = useEdit(formData);

  const getPayActivityTypes = () => {
    stateManager.run(async () => {
      const response = await payActivityTypeService.getAll({
        params: { pageSize: 9999 },
        sort: [{ field: "name", sort: "asc" }],
      });
      const types = response.data.map((t) => ({ value: t.id, label: t.name }));
      setPayActivityTypeSelect(types);
    });
  };

  const getWalkTypes = () => {
    stateManager.run(async () => {
      const response = await bonusSettingsService.getWalkTypes();
      const types = response.data
        .map((t) => ({
          value: t.walkTypeId,
          label: `${t.walkTypeName} (${t.stageTypeName})`,
        }))
        .sort((x, y) => (x.label > y.label ? 1 : -1));
      setWalkTypeSelect(types);
    });
  };

  const getTitles = () => {
    stateManager.run(async () => {
      const accountId = localStorage.getItem("accountId");
      const response = await accountService.getTitles({
        id: accountId,
        params: { pageSize: 9999 },
        sort: [{ field: "name", sort: "asc" }],
      });
      if (response.status === HTTP_STATUSES.OK) {
        const titles = response.data.map((t) => ({
          value: t.id,
          label: t.name,
        }));
        setTitleSelect(titles);
      }
    });
  };

  const getStageTypes = () => {
    stateManager.run(async () => {
      const response = await stageTypeService.getAll();
      if (response.status === HTTP_STATUSES.OK) {
        const types = response.data.map((t) => ({
          value: t.id,
          label: t.name,
        }));
        setStageTypeSelect(types);
      }
    });
  };

  const stageStatusSelect = STAGE_STATUS_OPTIONS.map((itm) => ({
    value: itm.value,
    label: itm.text,
  })).sort((x, y) => (x.label > y.label ? 1 : -1));

  const getData = async () => {
    const response = await bonusSettingsService.getBuilderSettings();
    if (response.status === HTTP_STATUSES.OK && response.data) {
      setFetchedData(response.data);
      setEditToggle(true);

      const findObj = response.data.PayByWalkType?.find(
        (ele) => params.id === ele.description
      );

      if (findObj) {
        edit.update({
          percent:
            findObj.percent === "" ? "" : (findObj.percent * 100).toFixed(2),
          referencePercent:
            findObj.referencePercent === ""
              ? ""
              : (findObj.referencePercent * 100).toFixed(2),
          userAdditionalPayPercent:
            findObj.userAdditionalPayPercent === undefined ||
            findObj.userAdditionalPayPercent === ""
              ? ""
              : (findObj.userAdditionalPayPercent * 100).toFixed(2),
        });

        setFormData(findObj);
      } else {
        edit.update({
          description: params.id,
          isEnabled: true,
          canDispute: false,
          payActivityTypeId: "",
          payActivityBonusValueType: "",
          titleId: "",
          walkTypeId: "",
          onStatus: "",
          onStageEvent: "",
          stageTypeId: "",
          skip: "0",
          times: "0",
          condition: "",
          includesIssueType: "",
          issueLimitPerArea: "",
          initialStatus: "",
          amount: "",
          percent: "",
          referencePayActivityTypeId: "",
          referencePayActivityBonusValueType: "",
          referencePercent: "",
          statusChangeOnWalkTypeId: "",
          statusChangeOnWalkTypeStatus: "",
          statusChangeOnStageStatus: "",
          userAdditionalPayPercent: "",
        });
        setEditToggle(true);
      }
    }
  };

  useEffect(() => {
    getPayActivityTypes();
    getTitles();
    getStageTypes();
    getWalkTypes();
    getData();
  }, []);

  const checkDuplicateDescription = () => {
    return fetchedData.PayByWalkType?.filter(
      (ele) => ele.description !== params.id
    ).some((ele) => ele.description === edit.getValue("description"));
  };

  const updateData = () => {
    const regex = /^\s{0,50}$/;
    if (
      edit.getValue("description").length === 0 ||
      regex.test(edit.getValue("description"))
    ) {
      createToast("Description cannot be blank", TOAST_TYPES.ERROR);
      return;
    }
    if (edit.getValue("payActivityTypeId").length === 0) {
      createToast("Pay Activity Type cannot be blank", TOAST_TYPES.ERROR);
      return;
    }
    if (
      edit.getValue("onStatus") !== "" &&
      edit.getValue("walkTypeId") === ""
    ) {
      createToast("Work Type cannot be blank", TOAST_TYPES.ERROR);
      return;
    }
    if (
      edit.getValue("onStageEvent") !== "" &&
      edit.getValue("stageTypeId") === ""
    ) {
      createToast("Stage Type cannot be blank", TOAST_TYPES.ERROR);
      return;
    }
    if (
      edit.getValue("payActivityBonusValueType").length === 0 ||
      regex.test(edit.getValue("payActivityBonusValueType"))
    ) {
      createToast("Rule Identifier cannot be blank", TOAST_TYPES.ERROR);
      return;
    }
    if (edit.getValue("titleId").length === 0) {
      createToast("Title cannot be blank", TOAST_TYPES.ERROR);
      return;
    }
    if (edit.getValue("initialStatus").length === 0) {
      createToast("Initial Status cannot be blank", TOAST_TYPES.ERROR);
      return;
    }
    if (edit.getValue("skip").length === 0) {
      createToast(
        "Number of times to skip before recording cannot be blank",
        TOAST_TYPES.ERROR
      );
      return;
    }
    if (edit.getValue("times").length === 0) {
      createToast("Times to record per job cannot be blank", TOAST_TYPES.ERROR);
      return;
    }
    if (checkDuplicateDescription()) {
      createToast("Duplicate Description", TOAST_TYPES.ERROR);
      return;
    }
    const filterData =
      fetchedData.PayByWalkType?.filter(
        (ele) => ele.description !== params.id
      ) || [];

    const parsePercent = () => {
      if (isNaN(parseFloat(edit.getValue("percent")))) {
        return "";
      } else {
        return (parseFloat(edit.getValue("percent")) / 100).toFixed(4);
      }
    };

    const parseAmount = () => {
      if (isNaN(parseFloat(edit.getValue("amount")))) {
        return "";
      } else {
        return parseFloat(edit.getValue("amount"));
      }
    };

    const parseReferencePercent = () => {
      if (isNaN(parseFloat(edit.getValue("referencePercent")))) {
        return "";
      } else {
        return (parseFloat(edit.getValue("referencePercent")) / 100).toFixed(4);
      }
    };
    const parseUserAdditionalPayPercent = () => {
      if (isNaN(parseFloat(edit.getValue("userAdditionalPayPercent")))) {
        return "";
      } else {
        return (
          parseFloat(edit.getValue("userAdditionalPayPercent")) / 100
        ).toFixed(4);
      }
    };

    const newobj = {
      PayByWalkType: [
        ...filterData,
        {
          description: edit.getValue("description").trim(),
          displayText: edit.getValue("displayText")?.trim(),
          isEnabled: edit.getValue("isEnabled"),
          canDispute: edit.getValue("canDispute"),
          payActivityTypeId: edit.getValue("payActivityTypeId"),
          payActivityName: edit.getValue("payActivityName"),
          payActivityBonusValueType: edit
            .getValue("payActivityBonusValueType")
            .trim(),
          titleId: edit.getValue("titleId"),
          titleName: edit.getValue("titleName"),
          walkTypeId: edit.getValue("walkTypeId"),
          onStatus: edit.getValue("onStatus"),
          onStageEvent: edit.getValue("onStageEvent"),
          stageTypeId: edit.getValue("stageTypeId"),
          skip: edit.getValue("skip"),
          times: edit.getValue("times"),
          condition: edit.getValue("condition"),
          includesIssueType: edit.getValue("includesIssueType"),
          issueLimitPerArea: edit.getValue("issueLimitPerArea"),
          initialStatus: edit.getValue("initialStatus"),
          amount: parseAmount(),
          percent: parsePercent(),
          referencePayActivityTypeId: edit.getValue(
            "referencePayActivityTypeId"
          ),
          referencePayActivityBonusValueType: edit
            .getValue("referencePayActivityBonusValueType")
            .trim(),
          referencePercent: parseReferencePercent(),
          statusChangeOnWalkTypeId: edit.getValue("statusChangeOnWalkTypeId"),
          statusChangeOnWalkTypeStatus: edit.getValue(
            "statusChangeOnWalkTypeStatus"
          ),
          statusChangeOnStageStatus: edit.getValue("statusChangeOnStageStatus"),
          userAdditionalPayPercent: parseUserAdditionalPayPercent(),
        },
      ],
    };
    const finalObj = { ...fetchedData, ...newobj };
    let stringify = JSON.stringify(finalObj);
    let addQuotes = "'" + stringify + "'";
    bonusSettingsService.putBuilderSettings(addQuotes).then(() => {
      navigate("/paysettings");
    });
  };
  const tabs = [
    {
      label: "Details",
      value: global.ACTIVE_TAB.DETAILS,
    },
  ];

  const detailFields = [
    {
      label: "Description",
      value: edit.getValue("description"),
      required: true,
      setValue: (e) => edit.update({ description: e }),
      width: "50%",
    },
    {
      label: "Display Text",
      value: edit.getValue("displayText"),
      setValue: (e) => edit.update({ displayText: e }),
      width: "50%",
    },
    {
      label: "Enabled",
      fieldType: "checkbox",
      value: !!edit.getValue("isEnabled"),
      setValue: (e) => edit.update({ isEnabled: e }),
      width: "50%",
      required: true,
    },
    {
      label: "Can Be Disputed",
      fieldType: "checkbox",
      value: !!edit.getValue("canDispute"),
      setValue: (e) => edit.update({ canDispute: e }),
      width: "50%",
      required: true,
    },
    {
      label: "Pay Activity Type",
      fieldType: "select",
      options: payActivityTypeSelect,
      value: edit.getValue("payActivityTypeId"),
      required: true,
      setValue: (e) => {
        const payActivityName = payActivityTypeSelect.find(
          (obj) => obj.value === e
        );
        edit.update({
          payActivityTypeId: e,
          payActivityName: payActivityName?.label,
        });
      },
      width: "50%",
    },
    {
      label: "On Work Event",
      value: edit.getValue("onStatus"),
      fieldType: "select",
      options: onStatus,
      selectNone: true,
      setValue: (e) => {
        if (e === "") {
          edit.update({
            walkTypeId: e,
            onStatus: e,
          });
          return;
        }
        edit.update({ onStatus: e });
      },
      width: "50%",
    },
    {
      label: "Work Type",
      value: edit.getValue("walkTypeId"),
      fieldType: "select",
      options: walkTypeSelect,
      setValue: (e) => edit.update({ walkTypeId: e }),
      readOnly: edit.getValue("onStatus") === "",
      required: edit.getValue("onStatus") !== "",
      width: "50%",
    },
    {
      label: "On Stage Status",
      value: edit.getValue("onStageEvent"),
      fieldType: "select",
      options: stageStatusSelect,
      selectNone: true,
      setValue: (e) => {
        if (e === "") {
          edit.update({
            stageTypeId: e,
            onStageEvent: e,
          });
          return;
        }
        edit.update({ onStageEvent: e });
      },
      width: "50%",
    },
    {
      label: "Stage Type",
      value: edit.getValue("stageTypeId"),
      fieldType: "select",
      options: stageTypeSelect,
      required: edit.getValue("onStageEvent") !== "",
      setValue: (e) => edit.update({ stageTypeId: e }),
      readOnly: edit.getValue("onStageEvent") === "",
      width: "50%",
    },
    {
      label: "Rule Identifier",
      value: edit.getValue("payActivityBonusValueType"),
      required: true,
      setValue: (e) => edit.update({ payActivityBonusValueType: e }),
      width: "50%",
    },
    {
      label: "Payee Title",
      value: edit.getValue("titleId"),
      fieldType: "select",
      options: titleSelect,
      required: true,
      setValue: (e) => {
        const titleLabel = titleSelect.find((obj) => obj.value === e);
        edit.update({
          titleId: e,
          titleName: titleLabel?.label,
        });
      },
      width: "50%",
    },
    {
      label: "Issue Limit Per Area",
      value: edit.getValue("issueLimitPerArea"),
      setValue: (e) => {
        const regex = /^\d{0,5}$/;
        if (regex.test(e)) {
          edit.update({ issueLimitPerArea: e });
        }
      },
      width: "50%",
    },
    {
      label: "Initial Status",
      value: edit.getValue("initialStatus"),
      fieldType: "select",
      options: InitialStatus,
      required: true,
      setValue: (e) => edit.update({ initialStatus: e }),
      width: "50%",
    },
    {
      label: "Number of times to skip before recording",
      value: edit.getValue("skip"),
      required: true,
      setValue: (e) => {
        const regex = /^\d{0,3}$/;
        if (regex.test(e)) {
          edit.update({ skip: e });
        }
      },
      width: "50%",
    },
    {
      label: "Times to record per job",
      value: edit.getValue("times"),
      required: true,
      setValue: (e) => {
        const regex = /^\d{0,3}$/;
        if (regex.test(e)) {
          edit.update({ times: e });
        }
      },
      width: "50%",
    },
    {
      label: "On condition",
      value: edit.getValue("condition"),
      fieldType: "select",
      options: condition,
      setValue: (e) => edit.update({ condition: e }),
      width: "50%",
    },
    {
      label: "When Job Includes Issue Type",
      value: edit.getValue("includesIssueType"),
      fieldType: "select",
      options: includesIssueType,
      setValue: (e) => edit.update({ includesIssueType: e }),
      width: "50%",
    },
    {
      label: "Amount ($)",
      value: edit.getValue("amount"),
      setValue: (e) => {
        const regex = /^[-]?\d{0,5}[.]?\d{0,2}$/;
        if (regex.test(e)) {
          edit.update({ amount: e });
        }
      },
      width: "50%",
    },
    {
      label: "Percent (%)",
      value: edit.getValue("percent"),
      setValue: (e) => {
        const regex = /^[-]?\d{0,5}[.]?\d{0,2}$/;
        if (regex.test(e)) {
          edit.update({ percent: e });
        }
      },
      width: "50%",
    },
    {
      label: "Percent of User Additional Pay(%)",
      value: edit.getValue("userAdditionalPayPercent"),
      setValue: (e) => {
        const regex = /^[-]?\d{0,5}[.]?\d{0,2}$/;
        if (regex.test(e)) {
          edit.update({ userAdditionalPayPercent: e });
        }
      },
      width: "50%",
    },
    {
      label: "Set Payable: On Stage Status",
      value: edit.getValue("statusChangeOnStageStatus"),
      fieldType: "select",
      options: stageStatusSelect,
      setValue: (e) => edit.update({ statusChangeOnStageStatus: e }),
      width: "50%",
    },
    {
      label: "Set Payable: Walk Type Name",
      value: edit.getValue("statusChangeOnWalkTypeId"),
      fieldType: "select",
      options: walkTypeSelect,
      required: true,
      setValue: (e) => edit.update({ statusChangeOnWalkTypeId: e }),
      width: "50%",
    },
    {
      label: "Set Payable: Walk Type Event",
      fieldType: "select",
      value: edit.getValue("statusChangeOnWalkTypeStatus"),
      options: onStatus,
      setValue: (e) => edit.update({ statusChangeOnWalkTypeStatus: e }),
      width: "50%",
    },
    {
      label: "Reference Percent (%)",
      value: edit.getValue("referencePercent"),
      setValue: (e) => {
        const regex = /^[-]?\d{0,5}[.]?\d{0,2}$/;
        if (regex.test(e)) {
          edit.update({ referencePercent: e });
        }
      },
      width: "50%",
    },
    {
      label: "Reference Rule Identifier",
      value: edit.getValue("referencePayActivityBonusValueType"),
      setValue: (e) => {
        const regex = /^[\w\s-]{0,100}$/;
        if (regex.test(e)) {
          edit.update({ referencePayActivityBonusValueType: e });
        }
      },
      width: "50%",
    },
    {
      label: "Reference Pay Activity Type",
      value: edit.getValue("referencePayActivityTypeId"),
      fieldType: "select",
      options: payActivityTypeSelect,
      setValue: (e) => edit.update({ referencePayActivityTypeId: e }),
      width: "50%",
    },
  ];

  return (
    <TabulatedPage
      toggleEdit={editToggle}
      cancelEdit={goBack}
      onSave={() => {
        updateData();
      }}
      pageTitle={edit.getValue("description")}
      fieldSet={[]}
      renderTabs={() => (
        <MiTab tabs={tabs} currenttab={activeTab} onTabChange={setActiveTab} />
      )}
      renderPage={() =>
        activeTab == global.ACTIVE_TAB.DETAILS && (
          <MiDetailFields detailFields={detailFields} />
        )
      }
    />
  );
};

export default BonusSettingsEdit;
