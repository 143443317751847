import React from "react";
import { THEME } from "@miview/theme";
import Icon from "@mui/material/Icon";
import { Chip, Timeline } from "../index.ts";

export const Activity = (props) => {
  const { rows, dateField, name, description, highlight } = props;
  const renderFrontCard = (e) => {
    return (
      <div style={styles.row}>
        {e.icon && (
          <div style={styles.circle}>
            <Icon>{e.icon}</Icon>
          </div>
        )}
        <div style={styles.frontStyle}>
          <div style={{ marginRight: 5 }}>{`${e.id}: `}</div>
          {name && <div style={{ marginRight: 10 }}>{e[name]}</div>}
          {description && <Chip text={e[description]} />}
        </div>
      </div>
    );
  };

  const renderCard = (item) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>{renderFrontCard(item)}</div>
        {item[highlight]}
      </div>
    );
  };

  return (
    <div style={{ ...styles.container, ...styles.borderBottom }}>
      {rows?.length ? (
        <Timeline
          renderCard={renderCard}
          data={rows}
          dateField={dateField}
          sortOrder={"asc"}
        />
      ) : null}
    </div>
  );
};

const styles = {
  container: {
    color: "white",
    flexDirection: "column",
    position: "relative",
    backgroundColor: "#FFF",
    margin: 10,
    padding: 10,
    width: "100%",

    marginHorizontal: 10,
    paddingBottom: 5,
  },
  borderBottom: {
    borderBottomColor: THEME.GREY_LIGHT_BACKGROUND,
    borderBottomWidth: 1,
  },
  frontStyle: {
    display: "flex",
    flex: 2,
    flexDirection: "row",
    alignItems: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
};
