import React from "react";
import { AppHeaderDropdown } from "@coreui/react";
import { makeStyles } from "tss-react/mui";
import { useRouter } from "@miview/hooks";

const redirectToLogin = (router) => {
  if (
    window.location.href.indexOf("redirect") > -1 ||
    window.location.href.indexOf("login") > -1
  ) {
    return;
  }

  localStorage.setItem("redirectingToLogin", true);

  let path = window.location.pathname;
  let search = "";
  if (path.length > 2) {
    const redirect = encodeURIComponent(path);
    search =
      redirect === "undefined" ? "" : "?redirect=" + encodeURIComponent(path);
  }
  window.location.href = "/login" + search;
  router.push({ pathname: "/login", search: search });
};

const getToken = (router) => {
  const token = localStorage.getItem("token");
  const tokenExpires = localStorage.getItem("tokenExpires");
  const expired = new Date().toISOString() > tokenExpires;
  if (expired || !token) {
    redirectToLogin(router);
  }
  return token;
};

const useStyles = makeStyles()((theme) => ({
  toolBar: {
    display: "flex",
    padding: 0,
    height: 55,
  },
  notificationBell: {
    verticalAlign: 9,
    padding: 8,
    color: theme.palette.accent.grey,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingBottom: 2,
    width: "100%",
    maxWidth: "80vw",
  },
  iconStyle: {
    color: theme.palette.accent.grey,
  },
}));

const DefaultHeader = () => {
  const { classes } = useStyles();
  const router = useRouter();

  //TODO: update this redirect once AD is implemented
  getToken(router);

  return (
    <div className={classes.toolBar}>
      <div>
        <AppHeaderDropdown
          direction="down"
          style={{ marginRight: "10px", marginTop: "-10px" }}
        ></AppHeaderDropdown>
      </div>
    </div>
  );
};

DefaultHeader.propTypes = {};

export default DefaultHeader;
