/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  DataGridPremium as DataGrid,
  LicenseInfo,
  GridOverlay,
  useGridApiRef,
  GridFooterContainer,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-premium";
import PropTypes from "prop-types";
import { MoreVert } from "@mui/icons-material";
import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MUI_DATAGRID_KEY, GridOperators } from "@miview/constants";
import { MiTableCheckBox } from "./MiTableCheckBox";
import { mdiFormatListBulleted } from "@mdi/js";
import { MiPlaceHolderList } from "../index.ts";
import {
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import TablePagination from "@mui/material/TablePagination";
import { MiActionMenu } from "./../MiActionMenu/MiActionMenu";

LicenseInfo.setLicenseKey(MUI_DATAGRID_KEY);

const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    width: "100%",
    flex: 1,
    alignSelf: "stretch",
    "&.MuiDataGrid-root": {
      border: "none",
      fontSize: theme.fontSize.medium,
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 1,
      backgroundColor: "white",
    },
    "& .MuiDataGrid-columnSeparator": {
      backgroundColor: "white",
      marginRight: 1,
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: "white",
      alignItems: "right",
      marginRight: 1,
    },
    "& .MuiDataGrid-menuIcon": {
      backgroundColor: "white",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: theme.palette.primary.main,
      fontWeight: theme.fontWeight.bold,
    },
    "& .inActiveRow": {
      background: theme.palette.light.grey,
    },
    "& .groupedRow": {
      background: alpha(theme.palette.primary.main, 0.1),
      borderRadius: 4,
      "& .MuiDataGrid-cellCheckbox": {
        visibility: "hidden",
      },
    },
    "& .nestedGroupedRow": {
      background: theme.palette.light.grey,
      borderRadius: 4,
    },
    "& .MuiDataGrid-cell": {
      color: theme.palette.secondary.bluegrey,
      fontWeight: theme.fontWeight.regular,
    },
    "& .inActiveRow .MuiDataGrid-cell": {
      color: theme.palette.secondary.grey,
    },
    "& .groupedRow .MuiDataGrid-cell": {
      color: theme.palette.primary.main,
    },
    "& .nestedGroupedRow .MuiDataGrid-cell": {
      color: theme.palette.primary.main,
    },
    "& .MuiToolbar-root > p": {
      marginBottom: 0,
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiDataGrid-columnHeader": {
      "&[data-field='closeIcon']": {
        visibility: "hidden",
      },
    },
    "& .MuiDataGrid-aggregationColumnHeaderLabel": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-main": {
      minHeight: 200,
    },
  },
}));

const EmptyListOverlay = ({ emptyListProps, hideHelper }) => {
  return (
    <GridOverlay>
      <MiPlaceHolderList hideHelper={hideHelper} {...emptyListProps} />
    </GridOverlay>
  );
};

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const handleChangeRowsPerPage = (event) => {
    apiRef.current.setPageSize(parseInt(event.target.value, 10));
    apiRef.current.setPage(0);
  };

  const displayPageSize = (data) => {
    return `Page: ${data.page + 1} \u00A0 Total Page: ${Math.ceil(
      data.count / pageSize
    )}`;
  };
  return (
    <GridFooterContainer>
      <div></div>
      <TablePagination
        component="div"
        showFirstButton
        labelDisplayedRows={displayPageSize}
        showLastButton
        rowsPerPage={pageSize}
        color="primary"
        count={pageCount}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={(event, value) => apiRef.current.setPage(value)}
      />
    </GridFooterContainer>
  );
};

export const MiTable = (props) => {
  const {
    tableContainerStyle,
    columns,
    getRowActions,
    actionIconStyle,
    moreVertColor,
    miCheckboxSelection,
    isMiCheckboxEnabled,
    onMiSelectionChange,
    miSelectedRowIds,
    apiRef,
    getRowId,
    customFooter,
    toolbarProps = {
      visible: true,
      columnsButton: true,
      filtersButton: true,
      densityButton: true,
      exportButton: true,
    },
    pagination,
    columnVisibilityModel,
    onColumnVisibilityModelChange,
    leftToolbarComponent,
    rightToolbarComponent,
    hideFooter = true,
    components = {},
    gridOperators = [GridOperators.AND],
    hideHelper,
    emptyListProps = {
      icon: mdiFormatListBulleted,
      text: "There are no records to display",
      helperText: "To create, click Add New",
    },
    ...rest
  } = props;
  const [columnsApiRef, setColumnsApiRef] = useState([]);
  const [colDefs, setColDefs] = useState(columns);
  const [selectedActionRow, setSelectedActionRow] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(
    columnVisibilityModel || {}
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { classes } = useStyles();
  const apiRefLocal = useGridApiRef();
  const apiRefInstance = apiRef ? apiRef : apiRefLocal;

  useEffect(() => {
    const orderedColumns = columns.sort((a, b) => {
      return columnsApiRef.indexOf(a.field) - columnsApiRef.indexOf(b.field);
    });
    const updatedDefs = [...orderedColumns];

    if (getRowActions) {
      updatedDefs.push({
        headerName: "",
        field: "",
        sortable: false,
        filterable: false,
        hideable: false,
        disableColumnMenu: true,
        resizable: false,
        disableExport: true,
        align: "right",
        renderCell: ({ row, rowNode }) => {
          const rowInfo = Object.keys(row).length ? row : rowNode;
          return (
            <div
              onClick={(e) => handleActionsIconSelect(e, rowInfo)}
              style={
                row?.disabledMoreVert
                  ? { pointerEvents: "none", opacity: "0.5" }
                  : { cursor: "pointer", color: moreVertColor }
              }
            >
              <MoreVert style={actionIconStyle} />
              {rowInfo.id === selectedActionRow?.id && renderActions()}
            </div>
          );
        },
      });
    }

    if (miCheckboxSelection) {
      updatedDefs.unshift({
        headerName: "",
        field: "",
        sortable: false,
        disableColumnMenu: true,
        align: "center",
        renderCell: ({ row }) => (
          <MiTableCheckBox
            row={row}
            isEnabled={isMiCheckboxEnabled}
            getRowId={getRowId}
            selectedRowIds={miSelectedRowIds}
            handleChange={onMiSelectionChange}
          />
        ),
      });
    }
    setColDefs(updatedDefs);
  }, [selectedActionRow, miSelectedRowIds, columns]);

  useEffect(() => {
    setVisibleColumns(columnVisibilityModel);
  }, [columnVisibilityModel]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleVisiblityChange = (newModel) => {
    if (onColumnVisibilityModelChange) {
      onColumnVisibilityModelChange(newModel);
    } else {
      setVisibleColumns(newModel);
    }
  };

  const onColumnOrderChange = () => {
    const { columns } = apiRefInstance.current.exportState();
    setColumnsApiRef(columns.orderedFields);
  };

  const defaultToolbar = () => {
    const {
      visible,
      columnsButton,
      filtersButton,
      densityButton,
      exportButton,
    } = toolbarProps;
    return visible ? (
      <GridToolbarContainer style={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex", flex: 1 }}>
          {leftToolbarComponent}
          {columnsButton ? <GridToolbarColumnsButton /> : null}
          {filtersButton ? <GridToolbarFilterButton /> : null}
          {densityButton ? <GridToolbarDensitySelector /> : null}
          {exportButton ? <GridToolbarExport /> : null}
        </div>
        {rightToolbarComponent}
      </GridToolbarContainer>
    ) : null;
  };

  const renderActions = () => {
    if (!selectedActionRow) {
      return;
    }
    const rowActions = getRowActions(selectedActionRow);
    if (!rowActions.length) {
      return;
    }

    return (
      <MiActionMenu
        open={open}
        selectedActionRow={selectedActionRow}
        rowActions={rowActions}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    );
  };

  const handleActionsIconSelect = (e, row) => {
    if (row?.id === selectedActionRow?.id) {
      setSelectedActionRow(null);
      setAnchorEl(null);
    } else {
      setSelectedActionRow(row);
      setAnchorEl(e.currentTarget);
    }
  };

  const renderDataGrid = () => {
    if (!columns) {
      return null;
    }
    return (
      <div style={{ display: "flex", height: "100%" }}>
        <div
          style={{ ...tableContainerStyle, flexGrow: 1 }}
          data-testid="MiTable"
        >
          <DataGrid
            className={classes.tableContainer}
            {...rest}
            components={{
              ...components,
              Toolbar: defaultToolbar,
              NoRowsOverlay: () =>
                EmptyListOverlay({ emptyListProps, hideHelper }),
              Footer: customFooter && CustomPagination,
            }}
            apiRef={apiRefInstance}
            columns={colDefs}
            getRowId={getRowId}
            disableSelectionOnClick
            hideFooter={!pagination && hideFooter}
            pagination={pagination}
            componentsProps={{
              filterPanel: {
                linkOperators: gridOperators,
              },
            }}
            columnVisibilityModel={visibleColumns}
            onColumnVisibilityModelChange={handleVisiblityChange}
            onColumnOrderChange={onColumnOrderChange}
          />
        </div>
      </div>
    );
  };

  return renderDataGrid();
};

MiTable.propTypes = {
  /**
   * Container styles wrapping the DataGrid
   */
  tableContainerStyle: PropTypes.object,
  /**
   * * "id" property is required for every row data
   *
   * * Data that needs to be displayed in the table
   * * Ex: [{"name": "Ron J", "id": 1}, {"name": "David C", "id": 2}]
   */
  rows: PropTypes.arrayOf(PropTypes.object),
  /**
   * <div> Takes array of field configurations
   *   <div>{
   *       <div>"field": "fieldName",</div>
   *       <div>"id": "unique identifier",</div>
   *       <div>"headerName": "Header Name",</div>
   *       <div>"renderCell": ```({row, value}) => <component /> ```</div>
   *       <div>"renderHeader": ```({colDef}) => <component /> ``` </div>
   *   <div>}</div>
   *   </div>
   *
   *
   * * renderCell example:  ```({row, value}) => <component />```
   * * renderHeader example:  ```({colDef}) => <component />```
   *
   * <div>Example: [<div>{"field": "id", "headerName": "User ID", width: "150"},</div> <div>{"field": "name", "headerName": "Full Name", width: "150"}</div>]
   * </div>
   */
  columns: PropTypes.arrayOf(PropTypes.object),
  /**
   * The autoPageSize prop allows to auto-scale the pageSize to match the container height
   * and the max number of rows that can be displayed without a vertical scroll bar. By default, this feature is on
   */
  autoPageSize: PropTypes.bool,
  /**
   * controls the height of the row
   */
  rowHeight: PropTypes.number,
  /**
   *  enables checkboxes for each row
   */
  checkboxSelection: PropTypes.bool,
  /**
   * calls this func with array of row ids selected
   */
  onSelectionModelChange: PropTypes.func,

  /**
   * Row action menu items
   */
  getRowActions: PropTypes.func,
  /**
   *
   * As part of the customization API, the grid allows you to override internal components with the components prop.
   * The prop accepts an object of type GridSlotsComponent.
   * Override options are [here](https://mui.com/api/data-grid/data-grid/#slots).
   *
   *  example:
      ```components={{
      ColumnMenu: MyCustomColumnMenu
    }}```
   */
  toolbarProps: PropTypes.object,
  /**
   * callback function on table row click
   *
   * ```
   * onRowClick={(params) => {
   *
   * }}
   * ```
   */
  onRowClick: PropTypes.func,
  /**
   * styles for the action icon
   */
  actionIconStyle: PropTypes.object,
  /**This would be an array of operators.For ex: ['and','or'] */
  gridOperators: PropTypes.array,
};
