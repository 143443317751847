import React, { useState } from "react";
import {
  MiInputTextbox,
  MiBox,
  MiButton,
  MiSwitch,
  MiSnackbar,
  MiFloatingAction,
  MiIcon,
} from "@miview/components";
import { alpha } from "@mui/material";
import { useEdit } from "@miview/hooks";
import { integrationService } from "@miview/api";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { INTEGRATIONS } from "@miview/constants";
import { mdiCloseCircle, mdiCheckCircle } from "@mdi/js";

const useStyles = makeStyles()(() => ({
  fieldRows: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
    paddingLeft: 50,
    paddingRight: 50,
    marginBottom: 30,
  },
  inputFieldContainer: {
    marginTop: 5,
    width: "40%",
    minWidth: 200,
  },
}));

export const ConnectApi = (props) => {
  const { data, fetchData } = props;
  const [editing, setEditing] = useState(false);
  const [isConnectionValid, setIsConnectionValid] = useState(null);
  const edit = useEdit(data);
  const { classes } = useStyles();
  const theme = useTheme();

  const handleChange = (field, value) => {
    edit.update({ [field]: value });
  };

  const isFormValid = () => {
    return !(
      !edit.getValue("endpoint") ||
      !edit.getValue("authKey") ||
      !edit.getValue("notifyEmail")
    );
  };

  const validForm = isFormValid();

  const handleCancel = () => {
    edit.reset();
    setEditing(false);
  };

  const handleSave = async () => {
    const response = await integrationService.integrations.update(
      INTEGRATIONS.DRHORTON,
      edit.edits
    );
    if (response.status === 200) {
      setEditing(false);
      fetchData();
    }
  };

  const testConnection = async () => {
    const response = await integrationService.integrations.testConnection(
      INTEGRATIONS.DRHORTON
    );
    setIsConnectionValid(response.status === 200);
  };

  const renderConnectionStatusIcon = () => {
    if (isConnectionValid === null) {
      return null;
    }
    let icon = mdiCloseCircle;
    let color = theme.palette.primary.red;
    if (isConnectionValid) {
      icon = mdiCheckCircle;
      color = theme.palette.primary.green;
    }
    return <MiIcon path={icon} color={color} size={1} />;
  };

  return (
    <div>
      <MiBox>
        <div className={classes.fieldRows}>
          <div className={classes.inputFieldContainer}>
            <MiInputTextbox
              required={true}
              disabled={!editing}
              labelText={"API URL"}
              value={edit.getValue("endpoint")}
              handleChange={(e) => handleChange("endpoint", e.target.value)}
              labelTextColor={theme.palette.primary.main}
            />
          </div>
          <div className={classes.inputFieldContainer}>
            <MiInputTextbox
              required={true}
              disabled={!editing}
              labelText={"API Token"}
              type={"password"}
              value={edit.getValue("authKey")}
              handleChange={(e) => handleChange("authKey", e.target.value)}
              labelTextColor={theme.palette.primary.main}
            />
          </div>
          <div style={{ marginTop: 20, display: "flex", alignItems: "center" }}>
            <MiButton
              title={"Test API Connection"}
              color={theme.palette.primary.main}
              backgroundColor={alpha(theme.palette.primary.main, 0.2)}
              disabled={!validForm || editing}
              onClick={testConnection}
            />
            {renderConnectionStatusIcon()}
          </div>
        </div>
        <div className={classes.fieldRows}>
          <div className={classes.inputFieldContainer}>
            <MiInputTextbox
              required={true}
              disabled={!editing}
              labelText={"Error Notification Email"}
              value={edit.getValue("notifyEmail")}
              handleChange={(e) => handleChange("notifyEmail", e.target.value)}
              labelTextColor={theme.palette.primary.main}
            />
          </div>
          <MiSwitch
            label={"Enable"}
            labelPlacement={"end"}
            value={edit.getValue("isEnabled")}
            handleChange={(e) => handleChange("isEnabled", e.target.checked)}
            color={theme.palette.primary.main}
            disabled={!editing}
          />
        </div>
      </MiBox>
      <MiSnackbar
        visible={editing}
        justifyCenter={false}
        handleSaveClick={handleSave}
        handleCancelClick={handleCancel}
        saveTitle={"Save"}
        disableSave={!validForm}
      />
      {!editing && (
        <MiFloatingAction title={"Edit"} handleClick={() => setEditing(true)} />
      )}
    </div>
  );
};
