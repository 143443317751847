import { propertyItemService, planService } from "@miview/api";

const postMaterialToItem = async (materialId, item) => {
  await propertyItemService.update(item.itemId, {
    ...item,
    materialId,
  });
};

const findAllRelatedItems = (planAreas, formData) => {
  const area = planAreas.find((a) => a.planAreaId === formData.areaId);
  const { propertySectionId, name } = area;
  const { itemName, walkTypeId, itemId } = formData;

  const relatedAreas = planAreas.filter(
    (a) => a.name === name && a.propertySectionId === propertySectionId
  );

  if (relatedAreas?.length) {
    let bulkRelatedItems = [];

    relatedAreas.map((a) => {
      const relatedItems = a.walkItems.filter(
        (i) =>
          i.itemId !== itemId &&
          i.itemName === itemName &&
          i.walkTypeId === Number(walkTypeId)
      );

      if (relatedItems?.length) {
        bulkRelatedItems = bulkRelatedItems.concat(relatedItems);
      }

      return null;
    });

    return bulkRelatedItems || [];
  }

  return [];
};

export const updateWalkItem =
  ({ propertyId, property, refreshData, planAreas }) =>
  async ({ formData }) => {
    try {
      const requestPayload = { ...formData };
      if (requestPayload.quantity === 0) {
        requestPayload.quantity = undefined;
      }

      if (formData.itemId) {
        await propertyItemService.update(formData.itemId, requestPayload);
      } else {
        await planService.createPropertyPlanItem(
          property.planId,
          propertyId,
          requestPayload
        );
      }

      if (formData.applyToAll) {
        const relatedItems = findAllRelatedItems(planAreas, formData);

        await Promise.all(
          relatedItems.map((item) =>
            postMaterialToItem(formData.materialId, item)
          )
        );
      }

      refreshData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
