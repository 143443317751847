import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import ReactQuill, { Quill } from "react-quill";
import "./quill.css";

const useStyles = makeStyles()((theme) => ({
  editorRoot: {
    "& .ql-snow .ql-stroke": {
      stroke: theme.palette.primary.grey,
    },

    "& .ql-snow .ql-fill, & .ql-snow .ql-stroke.ql-fill": {
      fill: theme.palette.primary.grey,
    },

    "& .ql-snow.ql-toolbar button.ql-active .ql-stroke, & .ql-picker-label:hover .ql-stroke, & .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke":
      {
        stroke: theme.palette.primary.white,
      },

    "& .ql-snow .ql-picker": {
      color: theme.palette.primary.white,
    },

    "& .ql-snow.ql-toolbar button:hover .ql-stroke": {
      color: theme.palette.primary.white,
      stroke: `${theme.palette.primary.white}!important`,
    },

    "& .quill ": {
      display: "flex",
      flexDirection: "column-reverse",
      width: "100%",
    },

    "& .ql-toolbar.ql-snow": {
      border: "none",
      backgroundColor: theme.palette.dark.grey,
      fontFamily: "inherit",
    },

    "& .ql-container.ql-snow": {
      border: "none",
      background: theme.palette.darkAccent.grey,
      minHeight: 150,
      color: theme.palette.primary.white,
      fontFamily: "inherit",
      fontSize: theme.fontSize.medium,
      borderRadius: "4px 4px 0px 0px",
    },

    "& .ql-container *": {
      color: theme.palette.primary.white,
    },

    "& .ql-snow .ql-picker-options": {
      backgroundColor: theme.palette.dark.grey,
      border: `1px solid ${theme.palette.darkLight.grey} !important`,
      padding: 15,
      borderRadius: 10,
      top: "auto!important",
      bottom: 25,
    },

    "& .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label": {
      borderColor: "transparent",
    },

    "& .ql-tooltip": {
      backgroundColor: theme.palette.dark.grey,
      boxShadow: "none",
    },

    "& .ql-snow .ql-tooltip input[type=text]": {
      color: theme.palette.primary.grey,
    },
    "& .ql-custom": {
      color: theme.palette.primary.grey,
      "&:hover": {
        color: `${theme.palette.primary.white}!important`,
      },
    },
  },

  editorLabel: {
    color: theme.palette.primary.white,
    "& span": {
      color: theme.palette.primary.red,
    },
  },

  editorRootView: {
    "& .ql-disabled.ql-container.ql-snow": {
      border: "none",
      fontFamily: "inherit",
      fontSize: "inherit",
    },
    "& .ql-editor": {
      padding: 0,
    },
  },
}));

export const MiRichTextEditor = ({ value, onChange }) => {
  const { classes } = useStyles();

  const icons = Quill.import("ui/icons");
  icons["custom"] = '<i class="fa fa-file-excel-o" aria-hidden="true"></i>';

  return (
    <div className={classes.editorRoot}>
      <label className={classes.editorLabel}>Message</label>
      <ReactQuill
        value={value}
        onChange={onChange}
        modules={{
          toolbar: {
            container: [
              "bold",
              "italic",
              "underline",
              "blockquote",
              { list: "bullet" },
              { list: "ordered" },
              { color: [] },
              "link",
              "custom",
            ],
          },
        }}
      />
    </div>
  );
};

export const MiViewEditor = ({ value }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.editorRootView}>
      <ReactQuill value={value} readOnly={true} modules={{ toolbar: false }} />
    </div>
  );
};

MiRichTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
