import RestaurantIcon from "@mui/icons-material/Restaurant";
import BathtubIcon from "@mui/icons-material/Bathtub";
import HouseIcon from "@mui/icons-material/House";
import DriveEtaIcon from "@mui/icons-material/DriveEta";

export const CATEGORIES = [
  { id: 193, name: "KITCHEN", icon: RestaurantIcon },
  { id: 195, name: "BATH", icon: BathtubIcon },
  { id: 194, name: "GAR / UTIL", icon: DriveEtaIcon },
  { id: 196, name: "GENERAL", icon: HouseIcon },
];
