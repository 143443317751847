import React, { useRef, useEffect } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { getImageBase64FromFile, getImageUrl } from "@miview/utils";
import { VALID_FILE_TYPES_FOR_IMAGES_UPLOAD } from "@miview/constants";
import { useCdn } from "@miview/hooks";

export const ImageContainer = ({
  handleUpdateImage,
  imageSrc,
  classes,
  idx,
}) => {
  const nvRef = useRef(null);
  const inputRef = useRef(null);
  const cdn = useCdn();

  useEffect(() => {
    const onPasteImageHandler = (pasteEvent) => {
      const items = pasteEvent.clipboardData.items;

      for (const item of items) {
        if (VALID_FILE_TYPES_FOR_IMAGES_UPLOAD.includes(item.type)) {
          const blob = item.getAsFile();
          getImageBase64FromFile(blob, handleUpdateImage);
          break;
        }
      }
    };
    const currentNvRef = nvRef.current;
    currentNvRef?.removeEventListener("paste", onPasteImageHandler, true);
    currentNvRef?.addEventListener("paste", onPasteImageHandler, true);
    return () => {
      currentNvRef?.removeEventListener("paste", onPasteImageHandler, true);
    };
  }, [handleUpdateImage]);

  const handleCreateImage = (e) => {
    getImageBase64FromFile(e.target.files[0], handleUpdateImage);
  };

  const renderImage = () => {
    if (imageSrc) {
      return (
        <img alt="" id="image-container" src={getImageUrl(imageSrc, cdn)} />
      );
    } else {
      return (
        <div
          id="image-container"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 7,
          }}
        >
          <CameraAltIcon className={classes.altIcon} />
        </div>
      );
    }
  };

  const renderRemoveImageLink = () => {
    if (imageSrc) {
      return (
        <div
          className={classes.removalLabel}
          onClick={() => {
            handleUpdateImage(null);
            inputRef.current.value = "";
          }}
          style={{ position: "relative", left: "5px" }}
        >
          Remove
        </div>
      );
    } else
      return (
        <div className={classes.removalLabel} style={{ height: "18px" }} />
      );
  };

  return (
    <div tabIndex="-1" ref={nvRef} className={classes.ImageFocus}>
      <div>
        <div className={classes.clipboardLabel} style={{ marginLeft: "8px" }}>
          Image
        </div>
        <input
          ref={inputRef}
          className={
            imageSrc ? classes.selectionStyles : classes.selectedStyles
          }
          type="file"
          id={idx}
          accept={VALID_FILE_TYPES_FOR_IMAGES_UPLOAD}
          onChange={handleCreateImage}
        />
        <label htmlFor={idx} className={classes.selectionLabel}>
          <div
            className={classes.selectedImageStyles}
            style={{ marginLeft: "8px" }}
          >
            {renderImage()}
          </div>
        </label>
        {renderRemoveImageLink()}
      </div>
    </div>
  );
};
