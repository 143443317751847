import React, { Fragment, useState, useCallback, useEffect } from "react";
import {
  DetailsPage,
  AreasPage,
  OptionsPage,
  ImageModalContainer,
  ConfirmDeleteModal,
  MasterSpecsHeader,
  MasterSpecsStepsBar,
  MasterSpecPreview,
} from "./components";
import {
  MiBuilderLayout,
  MiPreviewTabs,
} from "../../components/Standard/MiView";
import { useMasterSpecsLoader, usePlanAreas } from "./hooks";
import { Grid } from "@mui/material";
import { reorderItems } from "../../utils";
import { useRouter } from "@miview/hooks";
import { planItemService } from "@miview/api";

const MasterSpecsInternal = () => {
  const router = useRouter();
  const { planId } = router.params;

  //master specs
  const [previewTab, setPreviewTab] = useState(0);
  const [activeTab, setActiveTab] = useState("Details");
  const [rightColumnOn, setRightColumnOn] = useState(true);
  const [imagePreviewed, setImagePreviewed] = useState("");

  //areas
  const planAreaHook = usePlanAreas(planId);

  useEffect(() => {
    if (planId) {
      planAreaHook.refreshData();
    }
  }, [planId]);

  //drag
  const [itemDragging, setItemDragging] = useState(false);
  const handleReorderItems = reorderItems({
    planId,
    apiReorder: planItemService.reorder,
    areaIdParam: "planAreaId",
    refreshData: planAreaHook.refreshData,
  });

  const {
    planEdit,
    loadingMessage,
    materialBrands,
    materialCategories,
    propertySections,
    floors,
    builders,
    communities,
    handleOnChangePlan,
    handleSavePlan,
    stages,
    measurementTypesSide,
    measurementTypesBack,
    stackSizes,
    walkTypes,
    planOptions,
    setPlanOptions,
    optionTypes,
  } = useMasterSpecsLoader();

  const handleSetActiveTab = (tab) => {
    if (tab === activeTab) {
      return;
    }
    setActiveTab(tab);
  };

  const saveOptionRequest = () => null;

  const handleSavePlanDetails = useCallback(() => {
    handleSavePlan();
    setActiveTab("Areas");
  }, [planEdit.edits]);

  const getTabContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <DetailsPage
            rightColumnOn={rightColumnOn}
            setRightColumnOn={setRightColumnOn}
            edit={planEdit}
            builders={builders}
            communities={communities}
            handleOnChangeDetails={handleOnChangePlan}
            handleSaveDetails={handleSavePlanDetails}
          />
        );
      case "Areas":
        return (
          <AreasPage
            {...planAreaHook}
            propertySections={propertySections}
            rightColumnOn={rightColumnOn}
            setRightColumnOn={setRightColumnOn}
            planEdit={planEdit}
            floors={floors}
            materialBrands={materialBrands}
            materialCategories={materialCategories}
          />
        );
      case "Options":
        return (
          <OptionsPage
            rightColumnOn={rightColumnOn}
            floors={floors}
            saveOptionRequest={saveOptionRequest}
            setRightColumnOn={setRightColumnOn}
            setPreviewTab={setPreviewTab}
            materialCategories={materialCategories}
            materialBrands={materialBrands}
            propertySections={propertySections}
            refreshData={planAreaHook.refreshData}
            planAreas={planAreaHook.planAreas}
            planEdit={planEdit}
            planOptions={planOptions}
            setPlanOptions={setPlanOptions}
            optionTypes={optionTypes}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <MiBuilderLayout
        rightColumnOn={rightColumnOn}
        loading={loadingMessage}
        header={<MasterSpecsHeader />}
        stepsBar={
          <MasterSpecsStepsBar
            setActiveTab={handleSetActiveTab}
            activeTab={activeTab}
          />
        }
        rightColumn={(props) => (
          <div>
            <MiPreviewTabs
              tabs={[{ label: "Plan Items" }]}
              previewTab={previewTab}
              setPreviewTab={setPreviewTab}
            />
            <MiPreviewTabs.TabPanel value={previewTab} index={0}>
              <MasterSpecPreview
                {...props}
                setPlanAreasPreview={planAreaHook.setPlanAreasPreview}
                planAreas={planAreaHook.planAreas}
                planAreasPreview={planAreaHook.planAreasPreview || []}
                walkTypes={walkTypes}
                activeTab={activeTab}
                itemDragging={itemDragging}
                setItemDragging={setItemDragging}
                reorderItems={handleReorderItems}
                handleSavePlanArea={planAreaHook.handleSavePlanArea}
                setImagePreviewed={setImagePreviewed}
                propertySections={propertySections}
                materialCategories={materialCategories}
                materialBrands={materialBrands}
                stages={stages}
                floors={floors}
                measurementTypesBack={measurementTypesBack}
                measurementTypesSide={measurementTypesSide}
                stackSizes={stackSizes}
                refreshData={planAreaHook.refreshData}
                planId={planId}
              />
            </MiPreviewTabs.TabPanel>
          </div>
        )}
      >
        <Grid>{getTabContent()}</Grid>
      </MiBuilderLayout>
      <ImageModalContainer
        imagePreviewed={imagePreviewed}
        setImagePreviewed={setImagePreviewed}
      />
      <ConfirmDeleteModal />
    </Fragment>
  );
};

MasterSpecsInternal.propTypes = {};

const MasterSpecs = (props) => {
  return <MasterSpecsInternal {...props} />;
};

export default MasterSpecs;
