import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

export const payActivityTypeService = {
  getAll: async ({ filters, sort, params, signal }) => {
    const options = await apiOptions({
      url: `${API_V3}/payactivitytypes`,
      method: "get",
      signal,
      params,
      filters,
      sort,
    });
    return apiRequest(options);
  },
  create: async (request) => {
    const options = await apiOptions({
      url: `${API_V3}/payactivitytypes`,
      method: "post",
      data: request,
    });
    return await apiRequest(options);
  },
  update: async (id, request) => {
    const options = await apiOptions({
      url: `${API_V3}/payactivitytypes/${id}`,
      method: "patch",
      data: request,
    });
    return await apiRequest(options);
  },
  delete: async (id) => {
    const options = await apiOptions({
      url: `${API_V3}/payactivitytypes/${id}`,
      method: "delete",
    });
    return await apiRequest(options);
  },
};
