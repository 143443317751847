import { useEffect, useState } from "react";
import { messageService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

export const useMessages = (messageContext, id) => {
  const [messages, setMessages] = useState([]);
  const [status, setStatus] = useState("Loading...");

  const getMessages = async () => {
    const paging = { pageSize: 20, orderBy: "messageId", descending: true };
    switch (messageContext) {
      case global.MESSAGE_CONTEXT.USER:
        return messageService.getAll(paging);
      case global.MESSAGE_CONTEXT.HOME:
        return messageService.getAll(paging, {
          relatedEntityId: id,
          entityTypeId: 197,
        });
      case global.MESSAGE_CONTEXT.JOB:
        return messageService.getAll(paging, {
          relatedEntityId: id,
          entityTypeId: 199,
        });
    }
    return [];
  };

  const handleGetMessages = async () => {
    const response = await getMessages();
    if (response.status === HTTP_STATUSES.OK) {
      setMessages(response.data);
      setStatus(null);
    }
  };

  useEffect(() => {
    handleGetMessages();
  }, []);

  return { messages, status, handleGetMessages };
};
