import React from "react";
import { MiImagePreviewModal } from "../../../components/Standard/MiView";

export const ImageModalContainer = ({ imagePreviewed, setImagePreviewed }) => {
  if (imagePreviewed) {
    return (
      <MiImagePreviewModal
        imagePreviewed={imagePreviewed}
        setImagePreviewed={setImagePreviewed}
      />
    );
  }

  return null;
};
