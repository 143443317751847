import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Session } from "@miview/components";
import { ADMIN_NAV } from "./routes/adminSettings/";
import { MiTHEME } from "@miview/theme";
import { cog } from "@miview/assets";
import { APPLIST } from "@miview/constants";
import DefaultHeader from "./containers/DefaultLayout/DefaultHeader";
import CssBaseline from "@mui/material/CssBaseline";

const MAIN_APP = {
  logo: cog,
  name: "App Settings",
  url: "/settings/accountmanagement",
};

const renderRightIcons = () => {
  return <DefaultHeader />;
};

export const createMuiTheme = createTheme({
  ...MiTHEME,
  palette: {
    ...MiTHEME.palette,
  },
  spacing: (factor) => `${0.25 * factor}`,
});

export const AppProvider = ({ children }) => {
  return <ThemeProvider theme={createMuiTheme}>{children}</ThemeProvider>;
};

export const AdminRoutes = () => {
  return (
    <AppProvider>
      <CssBaseline />
      <Session
        nav={ADMIN_NAV}
        mainApp={MAIN_APP}
        apps={APPLIST}
        renderRightIcons={renderRightIcons}
      />
    </AppProvider>
  );
};
