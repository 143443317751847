import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(
  (theme, { bgColor, maxWidth, height, padding, boxShadow }) => {
    return {
      container: {
        padding: padding !== undefined ? padding : 12,
        margin: 8,
        borderRadius: 6,
        backgroundColor: bgColor || theme.palette?.primary?.white,
        boxShadow: boxShadow || "0px 1px 2px 0px rgba(0,0,0,.2)",
        height: height,
        maxWidth: maxWidth,
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
          padding: 4,
        },
      },
    };
  }
);

export const MiBox = ({
  children,
  backgroundColor,
  height,
  padding,
  boxShadow,
  styles,
  margin,
  ...rest
}) => {
  const { classes } = useStyles({
    bgColor: backgroundColor,
    height,
    padding,
    margin,
    boxShadow,
  });
  return (
    <Box {...rest} className={classes.container} style={styles}>
      {children}
    </Box>
  );
};
