import { apiOptions, apiRequest } from "../utils";
import { API_V3 } from "../constants";

export const issueStatusService = {
  getAll: async (params) => {
    const options = await apiOptions({
      url: `${API_V3}/issuestatuses`,
      method: "get",
      params,
    });

    return apiRequest(options);
  },
};
