import React, { useEffect, useState } from "react";
import { formatDateTime } from "../../../helpers/dateTools";
import { walkService } from "@miview/api";
import { getCounterInfo, createFilter } from "@miview/utils";
import {
  DueCounter,
  MiTable,
  MiLoader,
  MiLink,
  MiSectionHeader,
} from "@miview/components";
import {
  HTTP_STATUSES,
  WALK_RECORD_TYPES,
  INSPECTIONJOBS,
  JOBS,
  IN_PROGRESS,
  NOT_STARTED,
  IN_PROGRESS_LABEL,
  NOT_STARTED_LABEL,
} from "@miview/constants";
import { useTheme } from "@mui/material/styles";
import { useComponentState } from "@miview/hooks";
import moment from "moment";

export const WalkHistoryCollapse = (props) => {
  const { IsJobs, PropertyId, activeTab } = props;
  const [walks, setWalks] = useState([]);
  const theme = useTheme();
  const stateManager = useComponentState();

  const sortWalks = (walks) => {
    return walks.sort((x, y) => {
      if (x.walkDueDate < y.walkDueDate) {
        return -1;
      }
      if (x.walkDueDate > y.walkDueDate) {
        return 1;
      }
      return 0;
    });
  };

  const walkFilter = createFilter().equals({
    propertyId: PropertyId,
    recordTypeId: [WALK_RECORD_TYPES.WALK],
  });

  const jobFilter = createFilter().equals({
    propertyId: PropertyId,
    recordTypeId:
      activeTab === INSPECTIONJOBS
        ? [
            WALK_RECORD_TYPES.INSPECTIONPREP,
            WALK_RECORD_TYPES.TOPOFF,
            WALK_RECORD_TYPES.POSTINSPECTION,
          ]
        : [WALK_RECORD_TYPES.JOB, WALK_RECORD_TYPES.PUNCH],
  });

  const getWalks = () => {
    stateManager.run(async () => {
      const response = await walkService.getAll({
        params: { pageSize: 200 },
        filters: walkFilter,
      });
      if (response.status === HTTP_STATUSES.OK) {
        const ordered = sortWalks(response.data);
        setWalks(ordered);
      }
    });
  };

  const getJobs = () => {
    stateManager.run(async () => {
      const response = await walkService.getAll({
        params: { pageSize: 200 },
        filters: jobFilter,
      });
      if (response.status === HTTP_STATUSES.OK) {
        const ordered = sortWalks(response.data);
        setWalks(ordered);
      }
    });
  };

  useEffect(() => {
    if (!IsJobs) {
      getWalks();
    } else {
      getJobs();
    }
  }, []);

  const renderTimeLeft = (e) => {
    if (e.status === "Cancelled" || !e.walkDueDate) {
      return "--";
    }

    const { days, hours } = getCounterInfo(e.walkDueDate, e.walkEndDate);

    const endDate = e.walkEndDate ? new Date(e.walkEndDate) : new Date();
    const isLate = new Date(e.walkDueDate) < endDate;
    const color = isLate ? theme.palette.primary.red : null;

    return (
      <span>
        {isLate ? "-" : ""}
        <DueCounter
          days={days}
          hours={hours}
          daysColor={color}
          hoursColor={color}
        />
      </span>
    );
  };

  const getSelectedDate = (date) => {
    if (!date) {
      return null;
    }
    return moment.parseZone(date);
  };

  const columns = [
    {
      field: "name",
      headerName: activeTab === "Walks" ? "Walk" : "Job",
      headerAlign: "left",
      align: "left",
      flex: 0.9,
      renderCell: ({ row, value }) => {
        return (
          <MiLink
            to={`/walks/${row.id}`}
            style={{
              marginLeft: 0,
              paddingLeft: 0,
              whiteSpace: "normal",
            }}
            title={value}
          />
        );
      },
    },
    {
      field: "stageTypeName",
      headerName: "Stage",
      headerAlign: "left",
      align: "left",
      flex: 0.7,
    },
    {
      field: "walkTypeName",
      headerName: (IsJobs && "Job Type") || "Walk Type",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ value, row }) => {
        return (
          <div>
            {row.attemptNumber > 1
              ? value + " (#" + row.attemptNumber + ")"
              : value}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      renderCell: ({ row }) => {
        if (row.status === IN_PROGRESS) {
          return IN_PROGRESS_LABEL;
        } else if (row.status === NOT_STARTED) {
          return NOT_STARTED_LABEL;
        } else {
          return row.status;
        }
      },
      align: "left",
      flex: 1,
    },
    {
      field: "scheduledStartDate",
      headerName: "Scheduled Date",
      headerAlign: "left",
      align: "left",
      flex: 0.95,
      valueFormatter: ({ value }) => formatDateTime(getSelectedDate(value)),
    },
    {
      field: "walkEndDate",
      headerName: "End Date",
      headerAlign: "left",
      align: "left",
      flex: 0.95,
      valueFormatter: ({ value }) => formatDateTime(getSelectedDate(value)),
    },
    {
      field: "renderTimeLeft",
      headerName: "Time Left",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row }) => {
        return <div>{renderTimeLeft(row)}</div>;
      },
    },
    {
      field: "assignedUserName",
      headerName: "Assigned User",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
  ];

  const SectionHeaderTitle = () => {
    switch (activeTab) {
      case JOBS:
        return "Jobs have not been created for this Home.  Please schedule a stage to generate jobs.";
      case INSPECTIONJOBS:
        return "Insp. Jobs have not been created for this Home.  Please schedule a stage to generate jobs.";
      default:
        return "Walks have not been created for this Home.  Please schedule a stage to generate walks.";
    }
  };

  const renderTable = () => {
    if (stateManager.isBusy()) {
      return <MiLoader />;
    }
    if (!walks?.length) {
      return (
        <div style={{ paddingTop: 10 }}>
          <MiSectionHeader title={SectionHeaderTitle()} />
        </div>
      );
    }
    return (
      <MiTable
        tableContainerStyle={{
          width: "100%",
          minHeight: "100px",
        }}
        columns={columns}
        rows={walks}
        style={{ minHeight: "100px" }}
        toolbarProps={{ visible: false }}
        headerHeight={40}
        autoHeight={true}
        hideFooter={false}
        pagination
        disableColumnMenu
      />
    );
  };

  return (
    <React.Fragment>
      {stateManager.statusTag("propertyWorkListStatus")}
      {renderTable()}
    </React.Fragment>
  );
};
