import { apiOptions, apiRequest } from "../../utils";
import { API_V3 } from "../../constants";

const API_EXTENSIONS = `${API_V3}/extensions`;
const API_EMAILTEMPLATE = "/emailtemplate";
const API_COUNTS = "/count";

export const extensionService = {
  getAll: async (params, filters) => {
    const options = await apiOptions({
      url: API_EXTENSIONS,
      params,
      filters,
    });
    return apiRequest(options);
  },

  get: async (id) => {
    const options = await apiOptions({
      url: `${API_EXTENSIONS}/${id}`,
    });
    return apiRequest(options);
  },

  patch: async (id, data) => {
    const options = await apiOptions({
      url: `${API_EXTENSIONS}/${id}`,
      method: "patch",
      data,
    });
    return apiRequest(options);
  },

  create: async (id, data) => {
    const options = await apiOptions({
      url: `${API_EXTENSIONS}`,
      params: { userId: id },
      method: "post",
      data,
    });
    return apiRequest(options);
  },

  emailTemplate: async (data) => {
    const options = await apiOptions({
      url: `${API_EXTENSIONS}${API_EMAILTEMPLATE}`,
      method: "post",
      data,
    });
    return apiRequest(options);
  },

  getCounts: async (params) => {
    const options = await apiOptions({
      url: `${API_EXTENSIONS}${API_COUNTS}`,
      params,
    });
    return apiRequest(options);
  },
};
