import { useContext } from "react";
import {
  PlanAreasContext,
  PlanItemFormContext,
  PlanOptionsContext,
} from "../../../context";

export const usePlanAreas = () => {
  return {
    ...useContext(PlanAreasContext),
  };
};

export const usePlanOptions = () => {
  return {
    ...useContext(PlanOptionsContext),
  };
};

export const usePlanItemForm = () => {
  return {
    ...useContext(PlanItemFormContext),
  };
};

export * from "./useResetPage";
export * from "./usePlanOptionUpdate";
