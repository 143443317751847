import React, { useState, useEffect } from "react";
import { toList } from "@miview/utils";
import { walkTypeService } from "@miview/api";
import { useEdit, useComponentState } from "@miview/hooks";
import { MiCheckbox, MiInputSelectBox, MiModal } from "@miview/components";
import { HTTP_STATUSES } from "@miview/constants";
import { useTheme } from "@mui/material";

export const CreateWalkTypePredecessorModal = (props) => {
  const { isVisible, close, walkTypeId } = props;
  const [walkTypes, setWalkTypes] = useState([]);

  const edit = useEdit();
  const stateManager = useComponentState();
  const theme = useTheme();

  const getWalkTypes = () => {
    stateManager.run(async () => {
      const response = await walkTypeService.getWalkTypes({ pageSize: 9999 });
      if (response.status === HTTP_STATUSES.OK) {
        const mapped = toList(
          response.data,
          "walkTypeId",
          "walkTypeName"
        ).filter((e) => e.value !== walkTypeId);
        setWalkTypes(mapped);
      }
    });
  };

  useEffect(() => {
    getWalkTypes();
  }, []);

  const isValid = () => {
    return edit.allFilled("predecessorId");
  };

  const saveNewPredecessor = () => {
    stateManager.run(async () => {
      const response = await walkTypeService.createPredecessor(
        walkTypeId,
        edit.edits
      );
      if (response.status === HTTP_STATUSES.OK) {
        edit.reset();
        close(true, response.data);
      }
    });
  };

  const closeModal = () => {
    edit.reset();
    close(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {stateManager.statusTag("createWalkTypePredecessorStatus")}
      <MiModal
        title="Create New Prerequisite Walk Type"
        open={isVisible}
        onClose={closeModal}
        actions={[
          {
            name: "Cancel",
            onClick: closeModal,
            color: theme.palette.medium.grey,
            inverse: true,
          },
          {
            name: "Save",
            onClick: saveNewPredecessor,
            color: theme.palette.primary.main,
            inverse: true,
            disabled: !isValid(),
          },
        ]}
      >
        <MiInputSelectBox
          labelText="Prerequisite"
          required
          labelField="text"
          defaultOption="--"
          menuItems={walkTypes}
          value={edit.getValue("predecessorId")}
          handleChange={(e) => edit.update({ predecessorId: e.target.value })}
          styles={{ width: "100%" }}
        />
        <MiCheckbox
          label="Is Required"
          checked={edit.getValue("isCompletionRequired")}
          onChange={() =>
            edit.update({
              isCompletionRequired: !edit.getValue("isCompletionRequired"),
            })
          }
        />
      </MiModal>
    </>
  );
};
