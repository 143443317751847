import React, { useEffect, useState } from "react";
import {
  MiHeader,
  MiDetailsSideBar,
} from "../../../components/Standard/MiView";
import {
  MiButton,
  MiSnackbar,
  MiTab,
  MiDetailFields,
  MiCard,
} from "@miview/components";
import { WalkTemplateItems } from "./WalkTemplateItems";
import { THEME } from "../../../components/Standard/theme";
import { useTheme } from "@mui/material";
import { walkTypeTemplateService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";
import { useEdit, useRouter, useComponentState } from "@miview/hooks";
import { mdiViewList } from "@mdi/js";

const WalkTemplateDetails = () => {
  const [activeTab, setActiveTab] = useState("Details");
  const [walkTemplate, setWalkTemplate] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [walkTypes, setWalkTypes] = useState([]);

  const edit = useEdit(walkTemplate);
  const router = useRouter();
  const stateManager = useComponentState();
  const theme = useTheme();

  const { id: walkTypeTemplateId } = router.params;

  const tabs = [
    { value: "Details", label: "Details" },
    { value: "Items", label: "Template Items" },
  ];

  const getTemplate = () => {
    stateManager.run(async () => {
      const response = await walkTypeTemplateService.get(walkTypeTemplateId);
      if (response.status === HTTP_STATUSES.OK) {
        setWalkTemplate(response.data);
      }
    });
  };

  const getWalkTypes = () => {
    stateManager.run(async () => {
      const response = await walkTypeTemplateService.getWalkTypes(
        walkTypeTemplateId
      );
      if (response.status === HTTP_STATUSES.OK) {
        setWalkTypes(response.data);
      }
    });
  };

  useEffect(() => {
    getTemplate();
    getWalkTypes();
  }, []);

  const saveEdits = () => {
    stateManager.run(async () => {
      const response = await walkTypeTemplateService.update(
        walkTypeTemplateId,
        edit.edits
      );
      if (response.status === HTTP_STATUSES.OK) {
        setWalkTemplate({ ...walkTemplate, ...edit.edits });
        setEditMode(!editMode);
        edit.reset();
      }
    });
  };

  const cancelEdit = () => {
    setEditMode(false);
    edit.reset();
  };

  const handleUpdateTemplateProperties = async () => {
    stateManager.run(async () => {
      await walkTypeTemplateService.publishTemplateChanges(walkTypeTemplateId);
    });
  };

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const detailFields = [
    {
      label: "Name",
      value: edit.getValue("name"),
      required: true,
      setValue: (e) => edit.update({ name: e }),
      width: "50%",
      clickButton: toggleEdit,
      readOnly: !editMode,
    },
  ];

  return (
    <>
      {stateManager.statusTag("walkTemplateDetailStatus")}
      <MiHeader
        color={theme.palette.primary.blue}
        title={edit.getValue("name")}
        inverse={false}
        icon="arrow_back"
        backAction={router.goBack}
        fieldSet={[{ label: "Name", value: edit.getValue("name") }]}
      >
        <MiButton
          title="Publish Template Changes"
          inverse={false}
          color={theme.palette.accent.purple}
          onClick={handleUpdateTemplateProperties}
        />
      </MiHeader>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          width: "100%",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: "1rem",
          height: "73vh",
        }}
      >
        <div style={{ ...THEME.BOX_MAIN, width: "70%" }}>
          <div
            style={{
              width: "100%",
              marginBottom: 20,
              paddingTop: 5,
            }}
          >
            <MiTab
              tabs={tabs}
              currenttab={activeTab}
              onTabChange={(value) => setActiveTab(value)}
            />
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            {activeTab === "Details" && (
              <>
                <MiDetailFields
                  headerProps={{
                    icon: "list",
                    title: "Detail Fields",
                  }}
                  detailFields={detailFields}
                />
                <MiSnackbar
                  visible={editMode}
                  handleCancelClick={cancelEdit}
                  handleSaveClick={saveEdits}
                  disableSave={!edit.getValue("name")}
                />
              </>
            )}
            {activeTab === "Items" && (
              <WalkTemplateItems templateId={walkTypeTemplateId} />
            )}
          </div>
        </div>
        {activeTab === "Details" && (
          <MiDetailsSideBar
            style={{ maxWidth: 400 }}
            titleWidth={200}
            sidebarTitle="Types Using This Template"
          >
            <>
              {walkTypes.length > 0 &&
                walkTypes.map((walkType, key) => {
                  return (
                    <div key={"walkType" + key} style={{ paddingBottom: 7 }}>
                      <MiCard
                        color={theme.palette.secondary.grey}
                        icon={mdiViewList}
                        title={walkType.walkTypeName}
                      />
                    </div>
                  );
                })}
            </>
          </MiDetailsSideBar>
        )}
      </div>
    </>
  );
};

export default WalkTemplateDetails;
