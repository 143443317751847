import React, { memo } from "react";
import PropTypes from "prop-types";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { MiMenuSystem, THEME } from "./MiView";
import { useAreaStyles } from "./MiAreaStyles";
import { useCdn } from "@miview/hooks";

export const createBackgroundColor = (isDeleted, isRule) => {
  if (isDeleted) {
    return THEME.RED_PRIMARY;
  }

  if (isRule) {
    return THEME.ORANGE_SECONDARY;
  }

  return THEME.BLUE_SECONDARY;
};

const MiArea = ({
  name,
  menuOptions,
  imageUrl,
  onDoubleClick,
  canEdit,
  isDeleted,
  isRule,
  hideImage,
  setImagePreviewed,
}) => {
  const { classes } = useAreaStyles();
  const cdn = useCdn();

  const renderImage = () => {
    if (imageUrl) {
      return (
        <img
          style={{ maxHeight: "100%", maxWidth: "100%" }}
          src={`${cdn}${imageUrl}`}
          onClick={() => setImagePreviewed(imageUrl)}
        />
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 18,
          }}
        >
          <CameraAltIcon className={classes.areaImageIcon} />
        </div>
      );
    }
  };

  return (
    <div className={classes.areaHeader} onDoubleClick={onDoubleClick}>
      <div
        className={classes.iconWrapper}
        style={{
          backgroundColor: createBackgroundColor(isDeleted, isRule),
        }}
      />
      <div className={classes.areaLabel}>
        <div
          className={classes.areaLabelText}
          style={{ textDecoration: isDeleted ? "line-through" : "none" }}
        >
          {name}
        </div>
        {!hideImage && (
          <div className={classes.areaLabelImage}>{renderImage()}</div>
        )}
        <div className={classes.areaLabelIcon}>
          {canEdit && <MiMenuSystem options={menuOptions} />}
        </div>
      </div>
    </div>
  );
};

export default memo(MiArea);

MiArea.propTypes = {
  name: PropTypes.string,
  menuOptions: PropTypes.array,
  imageUrl: PropTypes.string,
  onDoubleClick: PropTypes.func,
  canEdit: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isRule: PropTypes.bool,
  hideImage: PropTypes.bool,
  setImagePreviewed: PropTypes.func,
  planId: PropTypes.number,
  areaPlanId: PropTypes.number,
};
