import { appStorage } from "@miview/utils";
import { useEffect, useState } from "react";

export const usePermissions = () => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const handleSetPermissions = async () => {
      const storedPermissions = await appStorage.getItem("permissions");
      setPermissions(storedPermissions?.split(",") || []);
    };
    handleSetPermissions();
  }, []);

  const hasPermission = (permissionName) => {
    return permissions.some((permission) => permission === permissionName);
  };

  return { hasPermission };
};
