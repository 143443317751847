import React, { useEffect, useState } from "react";
import { walkTypeService } from "@miview/api";
import { useComponentState, useEdit } from "@miview/hooks";
import { HTTP_STATUSES, TOAST_TYPES } from "@miview/constants";
import { MiLoader, MiSnackbar, MiInputTextbox } from "@miview/components";
import { createToast } from "@miview/toast";
import { createUUID } from "@miview/utils";

const uuidGet = createUUID();
const uuidUpdate = createUUID();

export const PayCalculationCollapse = (props) => {
  const { walkTypeId } = props;
  const [payCalculation, setPayCalculation] = useState("");
  const [editMode, setEditMode] = useState(false);
  const stateManager = useComponentState();
  const edit = useEdit({ calculation: payCalculation });

  const getPayCalculation = () => {
    stateManager.abort(uuidGet);
    stateManager.run(async (signal) => {
      const response = await walkTypeService.getPayCalculation(
        walkTypeId,
        signal
      );

      if (response.status === HTTP_STATUSES.OK) {
        setPayCalculation(response.data);
        edit.reset();
      }
    }, uuidGet);
  };

  const handleValueChange = (e) => {
    edit.update({ calculation: e.target.value });
  };

  const cancelEdit = () => {
    edit.reset();
    setEditMode(false);
  };

  const saveEdits = async () => {
    if (!edit.isAnyModified()) {
      setEditMode(false);
      return;
    }

    stateManager.abort(uuidUpdate);
    stateManager.run(async (signal) => {
      const response = await walkTypeService.updatePayCalculation(
        walkTypeId,
        edit.getValue("calculation"),
        signal
      );
      if (response.status === HTTP_STATUSES.OK) {
        createToast(
          "Pay Calculation Formula Successfully Updated",
          TOAST_TYPES.SUCCESS
        );
        setEditMode(false);
        refresh();
      }
    }, uuidUpdate);
  };

  const refresh = () => {
    getPayCalculation();
  };

  useEffect(() => {
    getPayCalculation();
  }, []);

  const renderSection = () => {
    if (stateManager.isBusy()) {
      return <MiLoader />;
    }

    return (
      <>
        <div onClick={() => setEditMode(!editMode)}>
          <MiInputTextbox
            labelText="Pay Formula"
            type="textbox"
            value={edit.getValue("calculation")}
            handleChange={handleValueChange}
            readOnly={!editMode}
            multiline
          />
        </div>
        <MiSnackbar
          visible={editMode}
          handleCancelClick={cancelEdit}
          handleSaveClick={saveEdits}
        />
      </>
    );
  };

  return (
    <>
      {stateManager.statusTag("payCalculationStatus")}
      {renderSection()}
    </>
  );
};
