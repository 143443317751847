import React from "react";
import { MiModalConfirm } from "@miview/components";
import { useTheme } from "@mui/material";
import { propertySectionService } from "@miview/api";
import { HTTP_STATUSES } from "@miview/constants";

const AreaDeleteModal = (props) => {
  const { open, onClose, selection } = props;

  const theme = useTheme();

  const handleDelete = async () => {
    const response = await propertySectionService.delete({
      id: selection.id,
    });
    if (response.status === HTTP_STATUSES.OK) {
      onClose();
    }
  };

  return (
    <MiModalConfirm
      titleColor={theme.palette.primary.red}
      title={"Confirm Delete"}
      open={open}
      description={`Delete the following Area: ${selection.name}?`}
      handlePositive={handleDelete}
      handleNegative={onClose}
    ></MiModalConfirm>
  );
};

export default AreaDeleteModal;
